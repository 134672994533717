import React, { FC, useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';

import { Box, makeStyles, ThemeProvider } from '@knockrentals/knock-shared-web';
import { LeasingBinder } from './LeasingBinder';
import { Property } from './models';
import { ErrorAlert } from '../ErrorAlert';
import { LoadingOverlay } from '../LoadingOverlay/LoadingOverlay';

const useStyles = makeStyles(() => ({
  leasingBinder: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '960px'
  },

  warningBox: {
    '& .MuiAlert-root': {
      padding: '6px 16px'
    }
  },

  loadingIndicator: {
    zIndex: 1300
  }
}));

export const DRAWER_LABEL = 'leasing binder drawer';

export interface LeasingBinderDrawerProps {
  open: boolean;
  getProperties: () => Promise<any>;
  managerCommunityIds: number[];
  onClose: () => void;
  currentProperty?: Property;
  prospectStatus?: string;
  bookTourAction?: () => void;
  availableUnitsAction?: (property: Property) => void;
  markAsLostCall?: boolean;
  defaultPropertyId?: number;
  attachBrochure?: (propertyId: number) => void;
  isEmailAvailable?: () => boolean;
  isNewAvailabilityDrawerEnable?: boolean;
}

export const LeasingBinderDrawer: FC<LeasingBinderDrawerProps> = ({
  open,
  getProperties,
  managerCommunityIds,
  onClose,
  currentProperty,
  prospectStatus,
  bookTourAction,
  availableUnitsAction,
  markAsLostCall,
  defaultPropertyId,
  isNewAvailabilityDrawerEnable,
  attachBrochure,
  isEmailAvailable
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [properties, setProperties] = useState<Property[]>([]);
  const [hasRetrievedProperties, setHasRetrievedProperties] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const getPropertiesForBinder = async () => {
      try {
        setErrorMessage('');
        setIsLoading(true);

        const response = await getProperties();

        const propertiesForBinder = response.data.communities.map(
          (property: Property) => {
            if (property.property_id === currentProperty?.property_id) {
              currentProperty.property_forwarding_numbers =
                response.data.property_forwarding_numbers[property.property_id];
              currentProperty.property_office_hours =
                response.data.property_office_hours[property.property_id];
              currentProperty.property_touring_hours =
                response.data.property_touring_hours[property.property_id];
              currentProperty.property_custom_fees =
                response.data.property_custom_fees[property.property_id];
            }

            return {
              ...property,
              property_forwarding_numbers:
                response.data.property_forwarding_numbers[property.property_id],
              property_office_hours:
                response.data.property_office_hours[property.property_id],
              property_touring_hours:
                response.data.property_touring_hours[property.property_id],
              property_custom_fees:
                response.data.property_custom_fees[property.property_id]
            };
          }
        );

        setProperties(
          propertiesForBinder.sort(
            (lValue: Property, rValue: Property) =>
              lValue.location.name
                .trim()
                .localeCompare(rValue.location.name.trim()),
            undefined,
            { sensitivity: 'base' }
          )
        );

        setHasRetrievedProperties(true);
      } catch (error) {
        setErrorMessage(
          'An unexpected error occurred while retrieving leasing binder data. Please try again later.'
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (open && !hasRetrievedProperties) {
      getPropertiesForBinder();
    }
    setIsOpen(open);
  }, [open, currentProperty, hasRetrievedProperties, getProperties]);

  const handleBookTourAction = () => {
    if (bookTourAction) {
      handleCloseDrawer({}, 'close');
      bookTourAction();
    }
  };

  const handleAttachBrochureAction = (propertyId: number) => {
    if (attachBrochure) {
      handleCloseDrawer({}, 'close');
      attachBrochure(propertyId);
    }
  };

  const handleAvailableUnitsAction = (property: Property) => {
    if (availableUnitsAction) {
      if (!isNewAvailabilityDrawerEnable) {
        handleCloseDrawer({}, 'close');
      }
      availableUnitsAction(property);
    }
  };

  const handleCloseDrawer = (_event: object, reason: string) => {
    if (reason === 'backdropClick' || reason === 'close') {
      setHasRetrievedProperties(false);
      onClose();
    }
  };

  const checkEmailAvailable = () => {
    if (isEmailAvailable) {
      return isEmailAvailable();
    } else {
      return false;
    }
  };

  return (
    <ThemeProvider>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleCloseDrawer}
        aria-label={DRAWER_LABEL}
        disableEnforceFocus
      >
        <LoadingOverlay open={isLoading} className={classes.loadingIndicator} />

        {errorMessage ? (
          <Box className={classes.warningBox}>
            <ErrorAlert alertMessage={errorMessage} />
          </Box>
        ) : (
          <LeasingBinder
            markAsLostCall={markAsLostCall}
            defaultPropertyId={defaultPropertyId}
            properties={properties}
            managerCommunityIds={managerCommunityIds}
            onClose={() => handleCloseDrawer({}, 'close')}
            currentProperty={currentProperty}
            prospectStatus={prospectStatus}
            className={classes.leasingBinder}
            bookTourAction={bookTourAction ? handleBookTourAction : undefined}
            availableUnitsAction={
              availableUnitsAction ? handleAvailableUnitsAction : undefined
            }
            attachBrochure={
              attachBrochure ? handleAttachBrochureAction : undefined
            }
            isEmailAvailable={checkEmailAvailable()}
          />
        )}
      </Drawer>
    </ThemeProvider>
  );
};
