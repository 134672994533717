/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('propertyAppointmentDisplay', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/community/property-appointment-display.html',
      controller: 'PropertyAppointmentDisplayController',
      scope: {
        appointment: '=',
        propertyData: '=',
        onAppointmentCanceled: '=?'
      }
    };
  });

  app.controller('PropertyAppointmentDisplayController', [
    '$scope',
    '$modal',
    '$mdToast',
    'directionsService',
    'confirmationModalFactory',
    'appointmentsApi',
    'timeService',
    function (
      $scope,
      $modal,
      $mdToast,
      directionsService,
      confirmationModalFactory,
      appointmentsApi,
      timeService
    ) {
      var self = this;

      $scope.startTime = timeService.get(
        $scope.appointment.start_time,
        null,
        $scope.propertyData.location.timezone
      );

      $scope.viewDirections = function () {
        directionsService.openDirectionsPage(
          $scope.propertyData.location.address.raw
        );
      };

      $scope.tryCancelAppointment = function () {
        var message = 'Are you sure you wish to cancel your appointment?';
        var modal = confirmationModalFactory.createModal(
          'Cancel appointment',
          message,
          self._cancelAppointment,
          { cancelText: 'No' }
        );

        $modal.open(modal);
      };

      self._cancelAppointment = function () {
        return appointmentsApi
          .cancelAppointment($scope.appointment.id)
          .then(function () {
            if ($scope.onAppointmentCanceled) {
              $scope.onAppointmentCanceled();
            }

            $mdToast.showSimple('Appointment canceled!');
          });
      };
    }
  ]);
})(window.angular);
