/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('imageService', [
    'imageBucket',
    function (imageBucket) {
      var self = this;

      self.createBucketUrl = function (filename, ext) {
        ext = ext || 'png';

        return imageBucket + '/' + filename + '.' + ext;
      };

      return self;
    }
  ]);
})(window.angular);
