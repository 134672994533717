import React, { FC } from 'react';

export const NoQuickRepliesIcon: FC = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_860_47960)">
      <path
        d="M15.7987 16.25L3.99158 27.7357C3.8762 27.8481 3.79765 27.9912 3.76583 28.147C3.73402 28.3028 3.75038 28.4642 3.81284 28.611C3.8753 28.7577 3.98107 28.8832 4.11678 28.9715C4.25249 29.0598 4.41206 29.107 4.57533 29.1071H9.20127V38.75L21.0084 27.2643C21.1238 27.1519 21.2024 27.0088 21.2342 26.853C21.266 26.6972 21.2496 26.5358 21.1872 26.389C21.1247 26.2423 21.0189 26.1168 20.8832 26.0285C20.7475 25.9402 20.5879 25.893 20.4247 25.8929H15.7987V16.25Z"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.295 17.5C1.479 14.8489 2.26435 12.2746 3.59148 9.97221C4.9186 7.66986 6.75265 5.7 8.95449 4.21204C11.1563 2.72409 13.6681 1.75714 16.2993 1.38454C18.9305 1.01194 21.6121 1.24347 24.1405 2.06159C26.6688 2.8797 28.9777 4.26289 30.8919 6.10624C32.8061 7.9496 34.2754 10.2047 35.1882 12.7005C36.101 15.1962 36.4335 17.8671 36.1603 20.5105C35.8872 23.1539 35.0156 25.7003 33.6117 27.9567L38.75 38.75L27.96 33.6117C27.0209 34.1926 26.03 34.6853 25 35.0833"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_860_47960">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="matrix(-1 0 0 1 40 0)"
        />
      </clipPath>
    </defs>
  </svg>
);
