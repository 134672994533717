/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
import filter from 'lodash/filter';
(function (angular) {
  'use strict';

  var app = angular.module('knock-ProspectHistory');

  app.factory('addResidentModalService', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openNewResidentModal = function ($event, propertyId, propertyUnit) {
        return $mdDialog.show({
          controller: 'AddResidentModalController',
          templateUrl: '/angular/views/prospectHistory/add-resident-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          targetEvent: $event,
          locals: {
            propertyId: propertyId,
            propertyUnit: propertyUnit
          }
        });
      };

      return self;
    }
  ]);

  app.controller('AddResidentModalController', [
    '$scope',
    '$q',
    '$mdDialog',
    '$mdToast',
    'appDataService',
    'teamApi',
    'propertyId',
    'propertyUnit',
    'defaultProfileImageUrl',
    'prospectHistoryService',
    'residentService',
    'companyApi',
    'conversationsService',
    'userService',
    function (
      $scope,
      $q,
      $mdDialog,
      $mdToast,
      appDataService,
      teamApi,
      propertyId,
      propertyUnit,
      defaultProfileImageUrl,
      prospectHistoryService,
      residentService,
      companyApi,
      conversationsService,
      userService
    ) {
      var self = this;
      var currentUser = userService.getScopedUser();

      $scope.defaultImageUrl = defaultProfileImageUrl;

      $scope.data = {
        isLoading: true,
        properties: {
          multiFamily: [],
          singleFamily: []
        },
        communityListings: null,
        noProperties: false
      };

      $scope.editor = {
        isSaving: false,
        resident: {
          manager_id: currentUser.id
        },
        selectedProperty: null,
        selectedCommunityListing: null
      };

      $scope.cancel = function () {
        $mdDialog.cancel();
      };

      $scope.selectedPropertyChanged = function () {
        var selectedProperty = $scope.editor.selectedProperty.Property;

        $scope.editor.resident.property_id = selectedProperty.id;

        $scope.editor.selectedCommunityListing = null;

        if (
          selectedProperty.type === 'community' &&
          !$scope.hideResidentsForMultiFamily
        ) {
          self._getCommunityListings(selectedProperty.id);
        } else {
          $scope.editor.communityListings = null;
        }
      };

      $scope.saveResident = function () {
        self._setResidentPropertyId();

        $scope.editor.isSaving = true;

        residentService
          .saveNewResident($scope.editor.resident)
          .success(self._saveResidentSuccess)
          .finally(function () {
            $scope.editor.isSaving = false;
          });
      };

      self._setResidentPropertyId = function () {
        $scope.editor.resident.property_id =
          $scope.editor.selectedProperty.id ||
          $scope.editor.selectedProperty.Property.id;
      };

      self._saveResidentSuccess = function (response) {
        $mdToast.showSimple('Saved!');
        $mdDialog.hide(response.resident);
      };

      self._initialize = function () {
        $scope.data.isLoading = true;

        var initPromises = [
          self._getProperties(),
          self._getCompanyPreferences()
        ];

        $q.all(initPromises).finally(function () {
          $scope.data.isLoading = false;
        });
      };

      self._getProperties = function () {
        var properties = appDataService.data.properties;

        var multiFamilyFilter = function (property) {
          return property.Property.type === 'multi-family';
        };
        var singleFamilyFilter = function (property) {
          return property.Property.type === 'single-family';
        };

        $scope.data.properties.multiFamily = filter(
          properties,
          multiFamilyFilter
        );
        $scope.data.properties.singleFamily = filter(
          properties,
          singleFamilyFilter
        );

        $scope.data.noProperties =
          $scope.data.properties.multiFamily.length +
            $scope.data.properties.singleFamily.length ===
          0;

        if (propertyId) {
          var selectedPropertyResult = find(
            properties,
            function (propertyResult) {
              return propertyResult.Property.id === propertyId;
            }
          );

          $scope.editor.selectedProperty = selectedPropertyResult.Property;
          $scope.editor.propertyNotEditable = true;

          $scope.editor.resident.property_id = propertyId;
        }

        if (propertyUnit) {
          $scope.editor.resident.property_unit = propertyUnit;
        }
      };

      self._getCommunityListings = function (communityId) {
        var includeInactive = false;

        return prospectHistoryService
          .getCommunityListings(communityId, includeInactive)
          .success(function (response) {
            $scope.data.communityListings = response.listings;
          });
      };

      self._getCompanyPreferences = function () {
        return companyApi.getCompanyPreferences().success(function (response) {
          $scope.hideResidentsForMultiFamily =
            !response.preferences.multi_family_residents_enabled;
        });
      };

      self._initialize();
    }
  ]);
})(window.angular);
