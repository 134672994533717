/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
require('../../core/scripts/calendar/calendarDataRepository.service');
require('../../core/scripts/calendar/calendarApi.service');
require('../../core/scripts/calendar/managerCalendarEvents.service');
require('../../core/scripts/calendar/managerCalendarEvents.service');
require('../../core/scripts/calendar/managerCalendar.service');
require('../../core/scripts/calendar/managerCalendar.controller');
require('../../core/scripts/calendar/managerCalendar.directives');
require('../../core/scripts/calendar/fullCalendar.service');
require('../../core/scripts/calendar/fullCalendar.directive');
require('../../core/scripts/calendar/connectCalendarSelector.directive');
require('../../core/scripts/calendar/teamMemberAppointments.directive');
require('../../core/scripts/calendar/calendarSettingsModal.controller');
require('../../core/scripts/calendar/newEventModal.controller');
require('../../core/scripts/calendar/cancelAppointmentModal.controller');
require('../../core/scripts/calendar/confirmAppointmentModal.controller');
require('../../core/scripts/calendar/confirmedAppointmentFunctions.directive');
require('../../core/scripts/calendar/syncCalendar.controller');
require('../../core/scripts/calendar/calendarReminderPopover.controller');
require('../../core/scripts/calendar/calendarVisitPopover.controller');

require('../../core/scripts/calendar/newAppointmentModal.controller');
require('../../core/scripts/calendar/changeTimeModal.controller');
