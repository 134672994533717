/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const focusOn = function ($timeout) {
    return {
      restrict: 'A',
      link: function ($scope, $element, $attr) {
        $scope.$watch($attr.focusOn, function (_focusVal) {
          $timeout(function () {
            _focusVal ? $element[0].focus() : $element[0].blur();
          });
        });
      }
    };
  };

  focusOn.$inject = ['$timeout'];
  app.directive('focusOn', focusOn);
})(window.angular);
