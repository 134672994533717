/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import map from 'lodash/map';
import size from 'lodash/size';
import slice from 'lodash/slice';
import transform from 'lodash/transform';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import remove from 'lodash/remove';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import forEach from 'lodash/forEach';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('conversationInbox', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/conversation-inbox.html',
      controller: 'ConversationInboxController',
      scope: {}
    };
  });

  const ConversationInboxController = function (
    $rootScope,
    $routeParams,
    $scope,
    $q,
    $mdToast,
    $location,
    $timeout,
    $anchorScroll,
    appDataService,
    apiEvents,
    timedSyncService,
    unreadCountsService,
    conversationsService,
    prospectHistoryService,
    propertyApi,
    userInteractionsService,
    historyService,
    userService
  ) {
    var self = this;

    self._deviceWidth = window.screen.width;
    self._disableInboxScroll = false;
    self._lastMessageId = null;
    self._lastMessageConversationId = null;

    var currentUser = userService.getScopedUser();
    var tabs = ['prospect', 'resident'];
    var eventHandlers = [];

    self.addEventHandler = function (event, handler) {
      eventHandlers.push($rootScope.$on(event, handler));
    };

    $scope.isRenter = currentUser && currentUser.type === 'renter';
    $scope.currentConversation = {};

    $scope.viewState = {
      selectedConversations: {
        prospects: {
          allSelected: false,
          selectedIds: []
        },
        residents: {
          allSelected: false,
          selectedIds: []
        }
      }
    };

    $scope.$on('$destroy', function () {
      forEach(eventHandlers, function (destroy) {
        destroy();
      });
    });

    self.addEventHandler(
      conversationsService.events.changeInboxTab,
      function (event, messageType) {
        $scope.data.tabIndex = indexOf(tabs, messageType);

        if (!self._disableInboxScroll) {
          $timeout(self._scrollToConversationRow, 500);
        }
      }
    );

    self.addEventHandler('viewProfileDetails', function (event, conversation) {
      $scope.openDetailsView(conversation);
    });

    self.addEventHandler('$routeChangeSuccess', function () {
      self._setIsConversationPage();
      self._disableInboxScroll = true;
    });

    self.addEventHandler(
      conversationsService.events.toggleInboxUser,
      function (event, managerId, showUnreadOnly) {
        var isSameManager =
          $scope.filters.selectedManager.Manager.id === managerId;

        $scope.filters.unreadOnly = showUnreadOnly || $scope.filters.unreadOnly;

        if (isSameManager) {
          if ($scope.filters.unreadOnly) {
            $scope.reloadConversations();
          }
        }

        if (!isSameManager) {
          $scope.filters.selectedManager = find(
            $scope.data.teamMembers,
            function (member) {
              return member.Manager.id === managerId;
            }
          );

          $scope.changeSelectedManager();
        }
      }
    );

    self.addEventHandler(conversationsService.events.openSearch, function () {
      angular.element('#searchInput').focus();
    });

    var handleNewMessage = function (event, message) {
      if (self._lastMessageId === message.id) {
        return;
      }

      self._lastMessageId = message.id;

      var existingThread = find($scope.data.conversations.prospects, {
        id: message.stream_id
      });
      existingThread =
        existingThread ||
        find($scope.data.conversations.residents, { id: message.stream_id });

      var isReadable = message.type === 'chat' || message.type === 'call';
      var isUnread =
        message.source === 'automated' && existingThread
          ? existingThread.isUnread
          : $scope.isRenter ||
            (message.sender_type !== 'manager' &&
              conversationsService.isUnread(
                { last_message: message, id: message.stream_id },
                $scope.filters.selectedManager.Manager.id
              ));

      self._lastMessageConversationId = message.stream_id;

      var userType = self._getUserType();

      if (
        existingThread &&
        isReadable &&
        $scope.filters.pagination[userType].start === 0
      ) {
        existingThread.last_message = message;
        existingThread.last_message_time = message.created_time;
        existingThread.isUnread = isUnread;

        var conversationArray =
          existingThread.type === 'resident'
            ? $scope.data.conversations.residents
            : $scope.data.conversations.prospects;

        remove(conversationArray, function (stream) {
          return stream.id === existingThread.id;
        });

        conversationArray.unshift(existingThread);
      }

      if (!existingThread) {
        var nonManagerParticipant = find(
          message.participants,
          function (participant) {
            return participant.type !== 'manager';
          }
        );

        var streamType = nonManagerParticipant
          ? nonManagerParticipant.type
          : null;

        $timeout(function () {
          $scope.reloadConversations(streamType);
        }, 1000);
      }
    };

    self.addEventHandler(
      conversationsService.events.newMessage,
      handleNewMessage
    );
    self.addEventHandler(
      conversationsService.events.newTeamMessage,
      handleNewMessage
    );

    self.addEventHandler(
      prospectHistoryService.events.prospectDeleted,
      function () {
        $scope.reloadConversations('prospect');
      }
    );

    self.addEventHandler(
      prospectHistoryService.events.prospectsBatchDeleted,
      function () {
        $scope.reloadConversations('prospect');
      }
    );

    self.addEventHandler(
      userInteractionsService.events.appointmentUpdated,
      function () {
        $scope.reloadConversations('prospect');
      }
    );

    self.addEventHandler(
      userInteractionsService.events.appointmentTransferred,
      function () {
        $timeout(function () {
          $scope.reloadConversations('prospect');
        }, 1000);
      }
    );

    self.addEventHandler(
      conversationsService.events.updateTeamUnreadCounts,
      function (event, counts) {
        self._processUnreadCounts(counts);
      }
    );

    self._processUnreadCounts = function (counts) {
      $scope.data.managerUnreadCounts = counts;
      $scope.data.managerUnreadTotals = reduce(
        $scope.data.managerUnreadCounts,
        function (unreadTotals, count, managerId) {
          unreadTotals[managerId] =
            (count && count.unread_messages && count.unread_messages.total) ||
            0;

          return unreadTotals;
        },
        {}
      );
    };

    self._setIsConversationPage = function () {
      $scope.data.isConversationPage =
        $location.path().indexOf('/conversation/') === 0;
    };

    $scope.filters = {
      searchText: '',
      selectedManager: null,
      pagination: {
        prospects: {
          start: 0,
          count: 20,
          allLoaded: false
        },
        residents: {
          start: 0,
          count: 20,
          allLoaded: false
        }
      },
      unreadOnly: !!$routeParams.unreadOnly,
      hasMore: true
    };

    $scope.data = {
      isConversationPage: false,
      isLoaded: false,
      isLoadingPrev: false,
      isLoadingNext: false,
      isSearchEnabled: false,
      teamMembers: null,
      conversations: {
        prospects: [],
        residents: []
      },
      tabIndex: 0,
      listings: null,
      communities: null,
      properties: {},
      searchResults: {},
      renterUnreadCount: 0,
      managerUnreadCounts: {},
      managerUnreadTotals: {},
      userTypes: ['prospects', 'residents']
    };

    $scope.getMyUnreadCounts = function (type) {
      if (isEmpty($scope.filters.selectedManager)) {
        return 0;
      }

      var unreadCounts =
        $scope.data.managerUnreadCounts[
          $scope.filters.selectedManager.Manager.id
        ];

      if (isEmpty(unreadCounts)) {
        return 0;
      }

      return unreadCounts.unread_messages[type].length;
    };

    $scope.getManagerUnreadCount = function (managerId) {
      return $scope.data.managerUnreadTotals[managerId];
    };

    self._navigateBack = function () {
      historyService.backToLastWithout('/conversation/');
    };

    $scope.sortAfterCurrentManager = function (manager) {
      if (manager.ManagerInfo.id === currentUser.id) {
        return -1;
      }

      return manager.ManagerInfo.first_name;
    };

    $scope.changeSelectedManager = function () {
      $scope.data.isLoaded = false;

      conversationsService.openManagerInbox(
        $scope.filters.selectedManager.Manager.id,
        $scope.filters.unreadOnly
      );
    };

    $scope.toggleUnreadOnly = function (userType) {
      $scope.data.isLoaded = false;
      $scope.data.isLoadingMore = true;

      $scope.filters.pagination[userType].start = 0;
      $scope.reloadConversations();
    };

    $scope.enableSearch = function () {
      if ($scope.data.isSearchEnabled) {
        return;
      }

      $scope.data.isSearchEnabled = true;
      $scope.data.didSearch = false;
      $scope.data.didSearchMessages = false;
      $scope.data.searchResults = {};

      document.addEventListener('keydown', $scope.disableSearchListener);
      document.addEventListener('click', $scope.disableSearchListener);
    };

    $scope.disableSearch = function () {
      $scope.filters.searchText = '';

      $scope.data.isSearchEnabled = false;
      $scope.data.searchResults = {};

      document.removeEventListener('keydown', $scope.disableSearchListener);
      document.removeEventListener('click', $scope.disableSearchListener);
    };

    $scope.disableSearchListener = function (event) {
      if (event.keyCode === 27) {
        $scope.$apply($scope.disableSearch);
      }
      if (event.type === 'click' && $scope.filters.searchText === '') {
        $scope.$apply($scope.disableSearch);
      }
    };

    $scope.searchTextChanged = function () {
      if ($scope.filters.searchText[0] === '(') {
        $scope.filters.searchText = $scope.filters.searchText.replace(
          /[^a-zA-Z0-9_-]+/g,
          ''
        );
      }

      if (isEmpty($scope.filters.searchText)) {
        $scope.disableSearch();
        return;
      }

      if (!$scope.data.isSearchEnabled) {
        $scope.enableSearch();
      }

      if (self.queuedSearch) {
        $timeout.cancel(self.queuedSearch);
      }

      self.queuedSearch = $timeout($scope.trySearch, 200);
    };

    $scope.trySearch = function () {
      if (isEmpty($scope.filters.searchText)) {
        return;
      }

      self.lastSearchText = $scope.filters.searchText;

      $scope.data.didSearch = true;
      $scope.data.didSearchMessages = false;
      $scope.data.searchResults.streams = [];

      $scope.data.didSearchMessages = true;
      $scope.data.searchResults.isSearchingMessages = true;

      $scope.data.searchResults.isSearchingProspects = true;

      self._fireSearch();
      self._fireProspectSearch();
    };

    $scope.reloadConversations = function (streamType, ignoreSyncTimeout) {
      var isManager = $scope.currentUser.type === 'manager';
      var isOtherUser =
        isManager &&
        $scope.filters.selectedManager.Manager.id !== $scope.currentUser.id;

      var syncFunction = function () {
        if (isOtherUser) {
          self._getTeamMemberConversations(streamType);
        } else {
          self._getConversations(streamType);
        }
      };

      if (streamType && !ignoreSyncTimeout) {
        timedSyncService.requestSync(
          'inbox-' + $scope.currentUser.id + '-' + streamType,
          syncFunction,
          5000
        );
      } else {
        syncFunction();
      }
    };

    $scope.loadMore = function (userType) {
      $scope.data.isLoadingMore = true;

      $scope.filters.pagination[userType].count += 20;
      $scope.reloadConversations();
    };

    $scope.showMoreProspects = function () {
      $scope.data.searchResults.prospects = self.allProspectResults;
      $scope.didTruncateProspectResults = false;
    };

    $rootScope.$on('streamCarouselService.hideCarousel', function () {
      if ($scope.filters.unreadOnly) {
        var userType = $scope.data.userTypes[$scope.data.tabIndex];
        $scope.toggleUnreadOnly(userType);
      }
    });

    self._getUserType = function () {
      return $scope.data.userTypes[$scope.data.tabIndex];
    };

    self._checkSearch = function () {
      if (!!$location.search().openSearch) {
        $timeout(function () {
          angular.element('#searchInput').focus();
        }, 0);
      }
    };

    self._fireSearch = function () {
      var searchText =
        $scope.filters.searchText.indexOf('@') > -1
          ? $scope.filters.searchText
          : $scope.filters.searchText.replace(/[^a-zA-Z0-9_-]+/g, ' ');
      conversationsService
        .searchChats(searchText)
        .success(self._searchChatsSuccess);
    };

    self._fireProspectSearch = function () {
      var searchText =
        $scope.filters.searchText.indexOf('@') > -1
          ? $scope.filters.searchText
          : $scope.filters.searchText.replace(/[^a-zA-Z0-9_-]+/g, ' ');

      conversationsService
        .searchProspects(searchText)
        .success(self._searchProspectsSuccess);
    };

    self._searchChatsSuccess = function (response) {
      var results = response.results;

      if (results.query_string !== $scope.filters.searchText) {
        $scope.data.searchResults.isSearchingMessages = false;
        return;
      }

      var messagesByStreams = groupBy(results.hits, function (result) {
        return result.hit.stream_id;
      });

      var streamIds = keys(messagesByStreams);

      conversationsService
        .getStreamsByIds(streamIds)
        .success(function (response) {
          var streamsById = keyBy(response.streams, 'id');

          $scope.data.searchResults.streams = transform(
            messagesByStreams,
            function (result, streamMessageResults, key) {
              var streamResults = {
                stream: streamsById[key],
                hits: streamMessageResults
              };

              result.push(streamResults);
            },
            []
          );

          $scope.data.searchResults.isSearchingMessages = false;
        });
    };

    self._searchProspectsSuccess = function (response) {
      var results = response.results;

      if (results.query_string !== $scope.filters.searchText) {
        $scope.data.searchResults.isSearchingProspects = false;
        return;
      }

      self.allProspectResults = results.hits;
      $scope.data.searchResults.prospects = slice(
        self.allProspectResults,
        0,
        3
      );

      $scope.didTruncateProspectResults =
        size($scope.data.searchResults.prospects) !==
        size(self.allProspectResults);
      $scope.data.searchResults.isSearchingProspects = false;
    };

    self._initialize = function () {
      self._processUnreadCounts(unreadCountsService.getCachedUnreadCounts());
      self._setIsConversationPage();

      $scope.currentUser = userService.getScopedUser();

      var initPromises = [
        $routeParams.userId
          ? self._getTeamMemberConversations(null, $routeParams.userId)
          : self._getConversations()
      ];

      if ($scope.currentUser.type === 'manager') {
        self._setTeamMembers();
        initPromises.push(self._getCommunities());
      }

      $q.all(initPromises)
        .then(function () {
          self._checkSearch();
        })
        .finally(self._loadFinished);
    };

    self._scrollToConversationRow = function () {
      var conversationId = $routeParams.chatThreadId;
      $location.hash('conversation-' + conversationId);
      $anchorScroll();

      $location.hash(null);
    };

    self._loadFinished = function () {
      $scope.data.isLoaded = true;
      $scope.data.isLoadingMore = false;
      $scope.data.isLoadingPrev = false;
      $scope.data.isLoadingNext = false;
    };

    self._setTeamMembers = function () {
      $scope.data.teamMembers = appDataService.getTeamMembers();

      var userId = $routeParams.userId
        ? Number($routeParams.userId)
        : $scope.currentUser.id;

      $scope.filters.selectedManager = find(
        $scope.data.teamMembers,
        function (teamMember) {
          return teamMember.Manager.id === userId;
        }
      );
    };

    self._getConversations = function (streamType) {
      var start = $scope.filters.pagination.prospects.start;
      var count = $scope.filters.pagination.prospects.count;

      var unreadOnly = $scope.filters.unreadOnly;

      var excludeSpam = $routeParams.internal === 'true' ? false : true;

      var conversationPromises = [];

      if (!streamType || streamType === 'prospect') {
        conversationPromises.push(
          conversationsService.getConversations(
            'prospect',
            start,
            count,
            unreadOnly,
            excludeSpam
          )
        );
      }

      if (!streamType || streamType === 'resident') {
        var residentStart = $scope.filters.pagination.residents.start;
        var residentCount = $scope.filters.pagination.residents.count;
        conversationPromises.push(
          conversationsService.getConversations(
            'resident',
            residentStart,
            residentCount,
            unreadOnly,
            excludeSpam
          )
        );
      }

      return $q
        .all(conversationPromises)
        .then(function (response) {
          self._getConversationsSuccess(response, streamType);
        })
        .finally(self._loadFinished);
    };

    self._getConversationsSuccess = function (response, streamType) {
      var requiredProperties = [];

      var responseIndexes = streamType
        ? [streamType + 's']
        : ['prospects', 'residents'];

      forEach(responseIndexes, function (responseStreamType) {
        var streamTypeData =
          response[indexOf(responseIndexes, responseStreamType)];

        $scope.data.conversations[responseStreamType] = !isEmpty(streamTypeData)
          ? streamTypeData.data.streams || streamTypeData.data.chats
          : [];
        $scope.filters.pagination[responseStreamType].allLoaded =
          self._isAllLoaded(responseStreamType);

        $scope.viewState.selectedConversations[responseStreamType].selectedIds =
          [];
        $scope.viewState.selectedConversations[responseStreamType].allSelected =
          false;

        requiredProperties = requiredProperties.concat(
          map($scope.data.conversations[responseStreamType], 'property_id')
        );
      });

      requiredProperties = filter(
        compact(uniq(requiredProperties)),
        function (propertyId) {
          return !$scope.data.properties[propertyId];
        }
      );

      if (requiredProperties.length > 0) {
        return self._getProperties(requiredProperties);
      }
    };

    self._getProperties = function (propertyIds) {
      return propertyApi
        .getPropertiesById(propertyIds)
        .then(function (response) {
          $scope.data.properties = merge(
            $scope.data.properties,
            keyBy(response.data.properties, 'id')
          );
        });
    };

    self._isAllLoaded = function (userType) {
      return (
        $scope.data.conversations[userType].length %
          $scope.filters.pagination[userType].count !==
        0
      );
    };

    self._getCommunities = function () {
      return conversationsService
        .getCommunities()
        .success(self._getCommunitiesSuccess);
    };

    self._getCommunitiesSuccess = function (response) {
      $scope.data.communities = keyBy(response.communities, 'id');
    };

    self._getTeamMemberConversations = function (streamType, userId) {
      var managerId =
        $routeParams.userId ||
        userId ||
        $scope.filters.selectedManager.Manager.id;

      var prospectStart = $scope.filters.pagination.prospects.start;
      var prospectCount = $scope.filters.pagination.prospects.count;

      var residentStart = $scope.filters.pagination.residents.start;
      var residentCount = $scope.filters.pagination.residents.count;

      var unreadOnly = $scope.filters.unreadOnly;

      var excludeSpam = $routeParams.internal === 'true' ? false : true;

      var conversationPromises = [];

      if (!streamType || streamType === 'prospect') {
        conversationPromises.push(
          conversationsService.getTeamMemberConversations(
            managerId,
            'prospect',
            prospectStart,
            prospectCount,
            unreadOnly,
            excludeSpam
          )
        );
      }

      if (!streamType || streamType === 'resident') {
        conversationPromises.push(
          conversationsService.getTeamMemberConversations(
            managerId,
            'resident',
            residentStart,
            residentCount,
            unreadOnly,
            excludeSpam
          )
        );
      }

      return $q
        .all(conversationPromises)
        .then(function (response) {
          self._getConversationsSuccess(response, streamType);
        })
        .finally(self._loadFinished);
    };

    self._initialize();
  };
  ConversationInboxController.$inject = [
    '$rootScope',
    '$routeParams',
    '$scope',
    '$q',
    '$mdToast',
    '$location',
    '$timeout',
    '$anchorScroll',
    'appDataService',
    'apiEvents',
    'timedSyncService',
    'unreadCountsService',
    'conversationsService',
    'prospectHistoryService',
    'propertyApi',
    'userInteractionsService',
    'historyService',
    'userService'
  ];
  app.controller('ConversationInboxController', ConversationInboxController);
})(window.angular);
