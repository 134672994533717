/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isUndefined from 'lodash/isUndefined';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('profileEditorDisplay', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/profile/profile-editor-display.html'
    };
  });

  app.directive('profileContactEditor', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/profile/profile-contact-editor.html',
      controller: [
        '$scope',
        '$rootScope',
        'ProfileService',
        'phoneVerificationModalFactory',
        'apiEvents',
        function (
          $scope,
          $rootScope,
          ProfileService,
          phoneVerificationModalFactory,
          apiEvents
        ) {
          var self = this;

          $scope.numberOnlyRegex = /^\d+(\.\d{2})?$/;

          self._setupName = function () {
            if ($scope.profile) {
              $scope.name =
                $scope.profile.first_name + ' ' + $scope.profile.last_name;

              if ($scope.name.length > 20) {
                $scope.name = $scope.name.substr(0, 20) + '...';
              }
            }
          };

          $scope.$watch('profile.first_name', self._setupName);
          $scope.$watch('profile.last_name', self._setupName);

          $scope.$watch('profile', function () {
            if (!self._originalPhoneNumber && $scope.profile) {
              self._originalPhoneNumber = $scope.profile.phone_number;

              if ($scope.profile.verification_method) {
                if ($scope.profile.verification_method === 'none') {
                  $scope.isPendingVerification = true;
                } else {
                  $scope.isVerified = true;
                }
              }

              self._setupName();
            }
          });

          $scope.$watch('editor', function () {
            if ($scope.editor && !$scope.editor.first_name) {
              $scope.addEditorField('first_name');
              $scope.addEditorField('last_name');
              $scope.addEditorField('phone_number');
            }
          });

          $scope.$watch('isVerified', function (isVerified) {
            if (!isUndefined(isVerified)) {
              $rootScope.$emit(apiEvents.phoneVerificationUpdated, isVerified);
            }
          });

          $scope.checkVerificationValidity = function () {
            var phoneHasChanged =
              $scope.profile.phone_number !== self._originalPhoneNumber;

            if ($scope.isVerified) {
              $scope.isVerified = !phoneHasChanged;
            }

            if ($scope.isPendingVerification) {
              $scope.isPendingVerification = !phoneHasChanged;
            }

            self._originalPhoneNumber = $scope.profile.phone_number;
          };

          $scope.tryPhoneVerification = function () {
            if ($scope.isVerified) {
              return;
            }

            if ($scope.isPendingVerification) {
              self._openVerificationConfirmationModal();
            } else {
              self._openPhoneVerificationModal();
            }
          };

          self._openPhoneVerificationModal = function () {
            phoneVerificationModalFactory
              .openVerificationModal($scope.profile.phone_number)
              .then(
                function (response) {
                  if (response.success) {
                    $scope.isPendingVerification = true;
                    self._openVerificationConfirmationModal();
                  } else {
                    $scope.saveErrorMessage =
                      'There was an error sending your verification code. Please try again later.';
                  }
                },
                function () {
                  $scope.saveErrorMessage =
                    'There was an error sending your verification code. Please try again later.';
                }
              );
          };

          self._openVerificationConfirmationModal = function () {
            phoneVerificationModalFactory
              .openVerificationConfirmationModal($scope.profile.phone_number)
              .then(function (response) {
                $scope.saveErrorMessage = null;

                $scope.isVerified = response.confirmed;
                $scope.isPendingVerification = !$scope.isVerified;

                if (!$scope.isVerified) {
                  $scope.saveErrorMessage =
                    'There was an error validating your phone number. Either the code has expired or was incorrect.';
                } else {
                  $scope.profile.verification_method = 'text';
                  ProfileService.updateProfile($scope.profile);
                }
              });
          };
        }
      ]
    };
  });

  app.directive('profilePhotoEditor', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/profile/profile-photo-editor.html',
      controller: [
        '$scope',
        'photoApi',
        'photoDropService',
        'defaultProfileImageUrl',
        'userService',
        function (
          $scope,
          photoApi,
          photoDropService,
          defaultProfileImageUrl,
          userService
        ) {
          var self = this;

          $scope.photoKey = userService.getUser().type + '-profile';

          $scope.$watch('profile', function () {
            self._syncPhotoDropService($scope.profile);
          });

          self._syncPhotoDropService = function (profile) {
            photoDropService.setCollection($scope.photoKey, [
              { url: profile.photo }
            ]);
          };

          $scope.cancelPhotoChange = function () {
            $scope.toggleEdit('photo');
            self._syncPhotoDropService($scope.profile);
          };

          $scope.savePhotoChange = function () {
            $scope.photoErrorMessage = null;

            var localPhotos = photoDropService.getCollectionLocalPhotos(
              $scope.photoKey
            );
            var remotePhotos = photoDropService.getCollectionRemotePhotos(
              $scope.photoKey
            );

            if (localPhotos.length <= 0 && remotePhotos.length <= 0) {
              self._setProfilePhoto(defaultProfileImageUrl);
              return;
            }

            if (localPhotos.length > 0) {
              $scope.isUploadingPhoto = true;
              photoApi
                .uploadPhoto($scope.photoKey, localPhotos[0])
                .success(self._uploadPhotoSuccess)
                .error(self._uploadPhotoError);
            } else {
              $scope.cancelPhotoChange();
            }
          };

          self._uploadPhotoSuccess = function (response) {
            self._setProfilePhoto(response.photos[0].url);
          };

          self._setProfilePhoto = function (photo) {
            $scope.editor.photo.editedValue = photo;
            $scope.toggleEdit('photo', true);

            self._syncPhotoDropService($scope.profile);
          };

          self._uploadPhotoError = function () {
            $scope.isUploadingPhoto = false;
            $scope.photoErrorMessage =
              'There was an error uploading your profile photo. Please try again later.';
          };
        }
      ]
    };
  });
})();
