import { api as baseApi } from 'app/services/api';
import { CallSettingsProps } from './entities';

export const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCallSettings: build.query<CallSettingsProps, void>({
      query: () => ({
        url: '/manager/browser-calling/settings',
        method: 'GET'
      }),
      transformResponse: (response: any) => {
        return {
          phoneNumbers: (response.phone_numbers as string[]) || [],
          outboundPhoneNumber:
            (response.outbound_phone_number as string) || null
        };
      }
    })
  })
});
