import React, { useMemo } from 'react';
import { makeStyles, NamedColors, Text } from '@knockrentals/knock-shared-web';
import { colors } from '../../commonStyles/commonStyles';
import {
  Chip,
  Typography,
  Box,
  TextField,
  FormHelperText
} from '@material-ui/core';
import { BlueSwitch } from '../../Switch';
import { useCompleteDrawerContext } from './CompleteTourDrawerContextProvider';
import { NextStepEnum } from './types';

const useStyles = makeStyles({
  text: {
    color: colors.secondaryText,
    fontSize: '14px'
  },
  chip: {
    padding: '4px 6px',
    margin: '4px',
    color: colors.defaultText,
    fontSize: '13px',
    borderRadius: '16px',
    border: `1px solid ${NamedColors.slate[200]}`,
    '&:hover': {
      backgroundColor: `${NamedColors.denim[100]} !important`,
      border: `1px solid ${NamedColors.denim[500]}`
    },
    '&:focus': {
      backgroundColor: 'inherit !important',
      border: `1px solid ${NamedColors.slate[200]}`
    }
  },
  typographyBody2: {
    color: colors.secondaryText
  },
  selectedChip: {
    padding: '4px 6px',
    margin: '4px',
    color: colors.defaultText,
    fontSize: '13px',
    borderRadius: '16px',
    backgroundColor: `${NamedColors.denim[100]} !important`,
    border: `1px solid ${NamedColors.denim[500]}`
  },
  scheduleBox: {
    display: 'flex',
    '& .MuiSwitch-track': {
      backgroundColor: colors.snackbarBackground
    }
  },
  tourSubTitle: {
    color: colors.secondaryText,
    fontWeight: 400,
    marginTop: '8px'
  },
  textfield: {
    width: '100%',
    marginBottom: 0,
    '& .MuiInputBase-root': {
      border: `none`,
      color: colors.defaultText,
      backgroundColor: '#FAFAFA !important',
      '&.MuiInputLabel-root.Mui-focused': {
        color: colors.secondaryText,
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: '0.15px'
      }
    },
    '& .MuiInputLabel-formControl.MuiInputLabel-filled.MuiInputLabel-shrink': {
      color: colors.secondaryText
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: `1px solid ${NamedColors.slate[300]}`
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: `1px solid ${NamedColors.slate[300]}`
    },
    '& .MuiInputLabel-formControl': {
      top: '26px',
      left: '0',
      color: '#888888',
      fontSize: '16px',
      fontWeight: 400
    }
  },
  textFieldError: {
    width: '100%',
    marginBottom: 0,
    '& .MuiInputBase-root': {
      border: `none`,
      color: colors.defaultText,
      backgroundColor: '#FAFAFA !important',
      '&.MuiInputLabel-root.Mui-focused': {
        color: colors.secondaryText,
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: '0.15px'
      }
    },
    '& .MuiInputLabel-formControl.MuiInputLabel-filled.MuiInputLabel-shrink': {
      color: colors.secondaryText
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: `2px solid ${NamedColors.carrot[600]}`
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: `2px solid ${NamedColors.carrot[600]}`
    },
    '& .MuiInputLabel-formControl': {
      top: '26px',
      left: '0',
      color: '#888888',
      fontSize: '16px',
      fontWeight: 400
    }
  },
  helperBox: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '5px'
  },
  formHelperText: {
    color: colors.secondaryText
  },
  errorText: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    'font-size': '12px',
    'line-height': '20px',
    'letter-spacing': '0.4px',
    marginLeft: '6px',
    marginTop: '8px',
    width: '100%'
  }
});

const nextSteps: NextStepEnum[] = Object.values(NextStepEnum);
const CUSTOM_NOTE_CHARACTER_LIMIT = 500;

const NextStep: React.FC = () => {
  const classes = useStyles();

  const {
    scheduleTour,
    prospectInterest,
    tourResult,
    note,
    nextSteps: steps,
    setData,
    finishButtonDisableClicked,
    step
  } = useCompleteDrawerContext();

  const handleSelectedSteps = (step: NextStepEnum) => {
    let newSteps: string[] = [...(steps ? steps : [])];
    if (steps.includes(step)) {
      newSteps.splice(steps.indexOf(step), 1);
    } else {
      newSteps.push(step);
    }
    setData({
      nextSteps: newSteps
    });
  };

  const shouldShowError = useMemo(() => {
    return (
      finishButtonDisableClicked &&
      steps?.length === 0 &&
      prospectInterest !== 'lost' &&
      tourResult !== 'no_show' &&
      step === 3
    );
  }, [finishButtonDisableClicked, steps, prospectInterest, tourResult, step]);

  return (
    <Box data-testid="next-steps-step">
      {prospectInterest === 'lost' && (
        <Typography variant="body2" className={classes.typographyBody2}>
          Once the tour is complete, you will continue to mark the prospect as
          lost.
        </Typography>
      )}
      {tourResult === 'visited' &&
        prospectInterest === 'still_interested' &&
        nextSteps.map((step) => (
          <Chip
            className={
              steps.includes(step) ? classes.selectedChip : classes.chip
            }
            key={step}
            label={step}
            variant="outlined"
            onClick={() => handleSelectedSteps(step)}
          />
        ))}
      {shouldShowError && (
        <Text color="error" className={classes.errorText}>
          Please select at least one next step for the prospect.
        </Text>
      )}
      {steps?.includes(NextStepEnum.Other) && step === 3 && (
        <>
          <Box>
            <TextField
              label="Custom Note"
              className={
                finishButtonDisableClicked && !note
                  ? classes.textFieldError
                  : classes.textfield
              }
              multiline
              variant="filled"
              value={note}
              data-testid="custom-note"
              inputProps={{
                maxLength: CUSTOM_NOTE_CHARACTER_LIMIT
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setData({ note: event.target.value })
              }
            />
          </Box>
          <Box className={classes.helperBox}>
            {note?.length >= 375 && (
              <FormHelperText className={classes.formHelperText}>{`${
                note?.length || 0
              }/${CUSTOM_NOTE_CHARACTER_LIMIT}`}</FormHelperText>
            )}
            {finishButtonDisableClicked && !note && (
              <Text color="error" className={classes.errorText}>
                Please add a custom next step note.
              </Text>
            )}
          </Box>
        </>
      )}
      {tourResult === 'no_show' && prospectInterest === 'still_interested' && (
        <Box className={classes.scheduleBox}>
          <Box>
            <Typography variant="subtitle1">Schedule Tour</Typography>
            <Typography
              variant="body2"
              classes={{ body2: classes.tourSubTitle }}
            >
              Turning this on will prompt you to schedule another tour once this
              one is complete.
            </Typography>
          </Box>
          <Box>
            <BlueSwitch
              disabled={false}
              onChange={(_, checked: boolean) => {
                setData({ scheduleTour: checked });
              }}
              checked={scheduleTour}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NextStep;
