/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  var app = angular.module('knockApp');

  const ClickTrackingController = function (apiBase, $location) {
    var self = this;

    self._initialize = function () {
      var searchParams = $location.search();

      if (searchParams.pid && searchParams.event) {
        apiBase
          .post('/prospect/' + searchParams.pid + '/click-event', {
            click_event_type: searchParams.event,
            url: searchParams.url
          })
          .then(function () {
            apiBase
              .get(
                '/prospect/' + searchParams.pid + '/application-url/redirect'
              )
              .then(function (response) {
                if (response.status === 200) {
                  window.location.href = response.data.url;
                } else {
                  $location.url('/');
                }
              });
          });
      } else {
        $location.url('/');
      }
    };

    self._initialize();
  };

  ClickTrackingController.$inject = ['apiBase', '$location'];

  app.controller('ClickTrackingController', ClickTrackingController);
})(window.angular);
