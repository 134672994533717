/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('stringArrayInput', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/stringArrayInput/string-array-input.html',
      controller: 'StringArrayInputController',
      scope: {
        inputs: '=?',
        inputTitle: '@'
      }
    };
  });

  app.controller('StringArrayInputController', [
    '$scope',
    function ($scope) {
      $scope.inputs = isEmpty($scope.inputs) ? [{ value: '' }] : $scope.inputs;
      $scope.inputType = 'email';

      $scope.addInput = function () {
        $scope.inputs.push({
          value: ''
        });
      };

      $scope.removeInput = function (index) {
        if ($scope.inputs.length === 1) {
          $scope.inputs[0].value = '';
          return;
        }

        $scope.inputs.splice(index, 1);
      };
    }
  ]);
})(window.angular);
