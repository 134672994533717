/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectReportFilters', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/propertyReports/prospect-report-filters.html',
      controller: 'ProspectReportFiltersController'
    };
  });

  const ProspectReportFiltersController = function ($scope, $routeParams) {
    var self = this;

    $scope.showEditProspects = true;

    self._initialize = function () {
      if ($routeParams.internal === 'true') {
        $scope.showEditProspects = true;
      }
    };

    self._initialize();
  };

  ProspectReportFiltersController.$inject = ['$scope', '$routeParams'];

  app.controller(
    'ProspectReportFiltersController',
    ProspectReportFiltersController
  );
})(window.angular);
