import { getAuthToken } from './tokenService';

import { APP_CONFIG } from 'APP_CONFIG';

const API_HOST = APP_CONFIG.API.host as string;

export enum TourType {
  Agent = 'agent',
  Self = 'self',
  Video = 'video'
}

/**
 * To be updated if the APIv2 AppointmentTourTypes enum changes.
 * Exported for Tests
 */
export const _APIv2TourTypeMap = {
  [TourType.Agent]: null,
  [TourType.Self]: 'self_guided',
  [TourType.Video]: 'live_video'
};

export interface TourTypesEnabled {
  liveVideoToursEnabled: boolean;
  inPersonToursEnabled: boolean;
  selfGuidedToursEnabled: boolean;
}

const getAvailableAppointmentsTimeForProperty = async (
  propertyId: number,
  tourType: TourType,
  renterId?: number
): Promise<string[]> => {
  const params = new URLSearchParams();
  if (renterId) {
    params.set('renterId', String(renterId));
  }

  const apiv2TourType = _APIv2TourTypeMap[tourType];
  if (apiv2TourType) {
    params.set('tourType', apiv2TourType);
  }

  try {
    const uri = `${API_HOST}/properties/${propertyId}/available-times?${params.toString()}`;
    const resp = await fetch(uri, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`
      }
    });

    // Error handling
    if (resp.status !== 200) {
      let error;
      try {
        error = await resp.json();
      } catch (e) {
        throw new Error(`Bad response: HTTP ${resp.status}`);
      }
      throw new Error(error.message || 'Bad message');
    }

    const data = await resp.json();
    if (
      data.available_times &&
      (data.available_times.acceptable_times ||
        data.available_times.reviewable_times)
    ) {
      const allTimes: string[] = data.available_times.acceptable_times
        .concat(data.available_times.reviewable_times)
        .sort();
      const result = allTimes.filter((item: string, index: number) => {
        return item ? allTimes.indexOf(item) === index : false;
      });

      return result;
    } else {
      throw new Error('Invalid data');
    }
  } catch (e) {
    console.error(
      `Failed to fetch available times for propertyId ${propertyId}`,
      e
    );
    throw new Error(
      `Failed to fetch available times: ${(e as Error).toString()}`
    );
  }
};

const getTourTypesEnabled = async (
  propertyId: number
): Promise<TourTypesEnabled> => {
  try {
    const uri = `${API_HOST}/properties/${propertyId}/tour-types-enabled`;
    const resp = await fetch(uri, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`
      }
    });

    // Error handling
    if (resp.status !== 200) {
      let error;
      try {
        error = await resp.json();
      } catch (e) {
        throw new Error(`Bad response: HTTP ${resp.status}`);
      }
      throw new Error(error.message || 'Bad message');
    }

    const data = await resp.json();
    const tourTypesEnabled = data.tour_types_enabled;

    if (tourTypesEnabled) {
      const result: TourTypesEnabled = {
        inPersonToursEnabled: tourTypesEnabled.in_person_tours_enabled,
        liveVideoToursEnabled: tourTypesEnabled.live_video_tours_enabled,
        selfGuidedToursEnabled: tourTypesEnabled.self_guided_tours_enabled
      };
      return result;
    } else {
      throw new Error('Invalid data');
    }
  } catch (e) {
    console.error(
      `Failed to fetch tour type preferences for propertyId ${propertyId}`,
      e
    );
    throw new Error(
      `Failed to fetch tour type preferences: ${(e as Error).toString()}`
    );
  }
};

export enum RequestAppointmentResponse {
  // successfully confirmed appointment
  Confirmed = 'confirmed',
  // successfully requested appointment
  Requested = 'requested',
  // no appointment requested or created.
  NoOp = 'no_op'
}

const requestAppointment = async (
  propertyId: number,
  prospectKnockId: string,
  requestedTimes: string[],
  referral: boolean = false,
  tourType: string | null
): Promise<RequestAppointmentResponse> => {
  try {
    const uri = `${API_HOST}/appointments/request`;
    const resp = await fetch(uri, {
      method: 'POST',
      body: JSON.stringify({
        property_id: propertyId,
        prospect_knock_id: prospectKnockId,
        requested_times: requestedTimes.map((time) => ({ start_time: time })),
        referral,
        tour_type: tourType
      }),
      headers: {
        Authorization: `Bearer ${getAuthToken()}`
      }
    });

    // Error handling
    if (resp.status !== 200) {
      let error;
      try {
        error = await resp.json();
      } catch (e) {
        throw new Error(`Bad response: HTTP ${resp.status}`);
      }
      throw new Error(error.message || 'Bad message');
    }

    const data = await resp.json();

    if (data.status_code !== 'ok') {
      if (data.reasons && data.reasons.length > 0) {
        const errorText = data.reasons
          .map((reason: { info?: string }) => reason.info)
          .filter((info: string | undefined) => Boolean(info))
          .join(', ');
        throw new Error(errorText);
      } else {
        throw new Error('Unknown error');
      }
    }

    if (data.result) {
      const result = data.result;
      if (result.appointment) {
        return RequestAppointmentResponse.Confirmed;
      } else if (result.appointment_request) {
        return RequestAppointmentResponse.Requested;
      } else {
        return RequestAppointmentResponse.NoOp;
      }
    } else {
      return RequestAppointmentResponse.NoOp;
    }
  } catch (e) {
    console.error(
      `Failed to request appointment for propertyId ${propertyId}`,
      e
    );
    throw new Error(
      `Failed to fetch appointment for propertyId ${propertyId}: ${(
        e as Error
      ).toString()}`
    );
  }
};

export default {
  getAvailableAppointmentsTimeForProperty,
  getTourTypesEnabled,
  requestAppointment
};
