/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import sortBy from 'lodash/sortBy';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Todo');

  app.directive('todoModule', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/todo/modules/todo.html',
      scope: {
        data: '=',
        profile: '=',
        now: '='
      }
    };
  });

  app.directive('statsModule', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/todo/modules/stats.html',
      scope: {
        data: '='
      },
      controller: [
        '$rootScope',
        '$scope',
        '$mdDialog',
        function ($rootScope, $scope, $mdDialog) {
          $scope.getScoreClass = function () {
            var score = $scope.data.engagementScore;

            return score > 0.9 ? 'good' : score > 0.8 ? 'okay' : 'bad';
          };

          $scope.openAnalytics = function () {
            $rootScope.$emit('open-analytics');
          };

          $scope.openEngagementScoreDetailsModal = function () {
            return $mdDialog.show({
              templateUrl:
                '/angular/views/todo/engagement-score-details-modal.html',
              parent: angular.element(document.body),
              clickOutsideToClose: true,
              bindToController: true,
              controller: [
                '$rootScope',
                '$scope',
                '$mdDialog',
                '$location',
                'greenProspects',
                'redProspects',
                'redProspectIds',
                function (
                  $rootScope,
                  $scope,
                  $mdDialog,
                  $location,
                  greenProspects,
                  redProspects,
                  redProspectIds
                ) {
                  $scope.close = $mdDialog.cancel;
                  $scope.greenProspects = greenProspects;
                  $scope.redProspects = redProspects;
                  $scope.redProspectIds = redProspectIds || [];

                  const redProspectIdList = $scope.redProspectIds.join(',');

                  $scope.viewRedProspects = function () {
                    $location
                      .url(
                        $rootScope.featureFlags.PROSPECT_PAGE_REDESIGN ||
                          localStorage.getItem('ProspectResidentBeta') ===
                            'true'
                          ? '/prospects'
                          : '/reports/prospects'
                      )
                      .search({ f_prospectIds: redProspectIdList });
                    $mdDialog.hide();
                  };
                }
              ],
              locals: {
                greenProspects: $scope.data.greenProspects,
                redProspects: $scope.data.redProspects,
                redProspectIds: $scope.data.redProspectIds
              }
            });
          };
        }
      ]
    };
  });

  app.directive('toursModule', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/todo/modules/tours.html',
      scope: {
        data: '='
      },
      controller: [
        '$scope',
        'conversationsService',
        'timeService',
        function ($scope, conversationsService, timeService) {
          $scope.displayedToursLimit = 3;

          $scope.openStream = function (streamId) {
            conversationsService.openThreadById(streamId, true);
          };

          var startTimeOrder = function (appointment) {
            return timeService.get(appointment.startTime).valueOf();
          };

          $scope.sorted = function (appointments) {
            return sortBy(appointments, startTimeOrder);
          };
        }
      ]
    };
  });

  app.directive('renewalsModule', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/todo/modules/renewals.html',
      scope: {
        data: '='
      },
      controller: [
        '$rootScope',
        '$scope',
        'conversationsService',
        function ($rootScope, $scope, conversationsService) {
          $scope.upcomingRenewalsLimit = 3;

          $scope.residentPageRedesignEnabled =
            $rootScope.featureFlags.RESIDENT_PAGE_REDESIGN ||
            localStorage.getItem('ProspectResidentBeta') === 'true';
          $scope.openStream = function (streamId) {
            conversationsService.openThreadById(streamId, true);
          };
        }
      ]
    };
  });

  app.directive('onlineApplicationsModule', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/todo/modules/online-applications.html',
      scope: {
        data: '='
      },
      controller: [
        '$scope',
        'conversationsService',
        function ($scope, conversationsService) {
          $scope.onlineApplicationsLimit = 5;

          $scope.openStream = function (streamId) {
            conversationsService.openThreadById(streamId, true);
          };
        }
      ]
    };
  });

  app.directive('messagesModule', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/todo/modules/messages.html',
      scope: {
        data: '='
      },
      controller: [
        '$scope',
        'conversationsService',
        function ($scope, conversationsService) {
          $scope.openManagerInbox = function (manager) {
            conversationsService.openManagerInbox(
              manager.managerInfo.manager_id,
              true
            );
          };
        }
      ]
    };
  });

  // Module-specific filters

  const upcomingTourTime = function (timeService) {
    return function (timeString) {
      var now = timeService.get();
      var time = timeService.get(timeString);

      var isToday = now.date() === time.date() && now.week() === time.week();

      if (isToday) {
        return 'Today';
      } else {
        return time.format('M/D');
      }
    };
  };

  upcomingTourTime.$inject = ['timeService'];

  app.filter('upcomingTourTime', upcomingTourTime);

  app.filter('lengthSafeName', function () {
    return function (name, maxLength) {
      maxLength = maxLength || 20;

      if (name.length > maxLength) {
        return name.substring(0, maxLength) + '...';
      } else {
        return name;
      }
    };
  });
})(window.angular);
