/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectEventApplication', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/prospectEvents/prospect-event-application.html',
      scope: {
        event: '=',
        prospect: '=',
        manager: '=',
        property: '='
      },
      controller: ['$scope', function ($scope) {}]
    };
  });
})(window.angular);
