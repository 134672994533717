/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('applicationNotificationModalService', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.displayModal = function (data) {
        return $mdDialog.show({
          locals: {
            notification: data
          },
          templateUrl:
            '/angular/views/notifications/application-notification-modal.html',
          clickOutsideToClose: !data.askForRefresh,
          escapeToClose: !data.askForRefresh,
          controller: 'ApplicationNotificationModalController'
        });
      };

      return self;
    }
  ]);

  app.controller('ApplicationNotificationModalController', [
    '$window',
    '$scope',
    '$mdDialog',
    'notification',
    function ($window, $scope, $mdDialog, notification) {
      $scope.viewState = {
        text: notification.text,
        bullets: notification.bullets,
        title: notification.title,
        level: notification.level,
        askForRefresh: notification.askForRefresh,
        buttonLabel: notification.buttonLabel
      };

      $scope.refresh = function () {
        $window.location.reload();
      };

      $scope.hide = function () {
        $mdDialog.hide();
      };

      $scope.cancel = function () {
        $mdDialog.cancel();
      };
    }
  ]);
})(window.angular);
