/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import remove from 'lodash/remove';
import map from 'lodash/map';

(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('messagingEmail', [
    function () {
      return {
        restrict: 'E',
        templateUrl: '/angular/views/messaging/messaging-email.html',
        controller: 'MessagingEmailController',
        scope: {
          recipientType: '=',
          recipients: '=',
          onSendMessage: '=',
          onCancelMessage: '=',
          emailAttachments: '=',
          messageSubject: '=',
          messageHtml: '=',
          leasingTeamId: '='
        }
      };
    }
  ]);

  const MessagingEmailController = function (
    $window,
    $mdDialog,
    $mdToast,
    $rootScope,
    $scope,
    $auth,
    conversationsService,
    attachmentService,
    appDataService,
    userService,
    managerApi,
    propertyApi,
    quotesService
  ) {
    var self = this;

    var MAX_FILE_SIZE = 1024 * 1024 * 25; // 25MB

    $scope.data = {
      target: 'email',
      uploadProgress: 0,
      showFormatting: null,
      maxCharacters: 10000,
      placeHolder: 'Enter an email message here'
    };

    $scope.managerInfo = {};
    $scope.mergeTags = [];
    $scope.enableRichTextEditor =
      $rootScope.appPreferences.company.enable_rich_text_editor;
    $scope.newUnitsFeature = $rootScope.featureFlags.NEW_UNITS;
    $scope.token = $auth.getToken();

    $scope.isCompanyQuickRepliesEnabled =
      $rootScope.featureFlags.COMPANY_QUICK_REPLIES;

    $scope.getManagerQuickReplies = () =>
      managerApi.getManagerQuickRepliesBulkMessaging($scope.leasingTeamId);

    $scope.updateManagerQuickReply = managerApi.updateManagerQuickReply;
    $scope.deleteManagerQuickReply = managerApi.deleteManagerQuickReply;
    $scope.properties = appDataService
      .getProperties()
      .sort((property1, property2) =>
        property1.Property.data.location.name.localeCompare(
          property2.Property.data.location.name
        )
      );

    self._initialize = function () {
      $scope.data.showFormatting =
        conversationsService.getToggleFormatting('open-formatting');

      if ($scope.data.showFormatting === null) {
        $scope.toggleFormatting();
      }

      if (
        $scope.emailAttachments.local &&
        $scope.emailAttachments.local.length > 0
      ) {
        self._uploadAttachments();
      }

      self._getMergeTags();
      self._getManagerInfo();
    };

    self._getMergeTags = function () {
      const [{ targetType } = {}] = $scope.recipients;
      $scope.mergeTags = appDataService.getEmailMergeTags(targetType);

      $scope.mergeTags.forEach((mergeTag) => {
        mergeTag.value = `#${mergeTag.name}`;
      });
    };

    self._getManagerInfo = function () {
      const teamMembers = appDataService.getTeamMembers().map((member) => {
        return {
          id: member.Manager.id,
          name:
            member.ManagerInfo.first_name + ' ' + member.ManagerInfo.last_name
        };
      });

      $scope.managerInfo = teamMembers.find(
        (member) => member.id === userService.getScopedUser().id
      );
    };

    $scope.toggleFormatting = function () {
      $scope.data.showFormatting = !$scope.data.showFormatting;
      $scope.textControlsDisplay();
    };
    $scope.generateQuote = function (unitListing) {
      quotesService.createQuote(
        unitListing,
        $scope.prospect,
        $scope.data.unitListings
      );
    };
    $scope.pasteLink = function (data, flag) {
      if (flag === 'listing') {
        $scope.$apply(() => {
          self._appendShortUrl(data, 'listing');
        });
      } else {
        const urlType =
          data.Property.type === 'multi-family' ? 'community' : 'listing';
        $scope.$apply(() => {
          self._appendShortUrl(data.Property.data, urlType);
        });
      }
    };
    $scope.getPropertyUnitListings = function (propertyId) {
      return propertyApi.getPropertyUnitListings(propertyId);
    };
    self._appendShortUrl = function (propertyData, propertyType) {
      if ($scope.prospect) {
        return prospectsApi
          .getProspectsUniqueShortUrl(
            $scope.prospect.id,
            propertyType,
            propertyData.id
          )
          .then(function (response) {
            $scope.appendText(response.data.short_url);
          });
      } else {
        $scope.appendText(propertyData.social.shortlink);
      }
    };

    $scope.appendText = function (text, fromQuickReply) {
      $scope.messageHtml = $scope.messageHtml || '';

      if (fromQuickReply) {
        $scope.$apply(() => {
          $scope.messageHtml += text;
        });
      } else {
        text = text.replace(/(?:\r\n|\r|\n)/g, '<br />');
        $scope.messageHtml = $scope.messageHtml.trim() + ' ' + text + ' ';
      }
    };

    $scope.textControlsDisplay = function () {
      conversationsService.storeToggleFormatting($scope.data.showFormatting);
    };

    $scope.updateMessage = function (message) {
      $scope.$apply(() => {
        $scope.messageHtml = message;
      });
    };

    $scope.handleSelectedAttachments = function (element) {
      var selected = { files: element };

      if (selected.files.length === 0) {
        return;
      }

      $scope.emailAttachments.local = map(selected.files, function (file) {
        file.isLocal = true;
        file.mimetype = file.type;
        file.url = $window.URL.createObjectURL(file);

        return file;
      });

      self._uploadAttachments();
    };

    self._uploadAttachments = function () {
      var files = $scope.emailAttachments.local;
      var tooLargeAttachments = remove(files, function (file) {
        return file.size > MAX_FILE_SIZE;
      });

      if (tooLargeAttachments.length > 0) {
        $scope.emailAttachments.local = [];
        $mdToast.showSimple(
          'One or more files are too large. Must be 10MB or less per file.'
        );
        return;
      }

      attachmentService.uploadByFile(
        files,
        self._uploadSuccess,
        self._uploadProgress,
        self._uploadError
      );
    };

    self._uploadSuccess = function (response) {
      var json = JSON.parse(response.target.responseText);

      $scope.emailAttachments.remote = $scope.emailAttachments.remote.concat(
        map(json.attachments, function (attachment) {
          attachment.type = attachmentService.getAttachmentType(
            attachment.mimetype
          );
          attachment.isRenaming = false;
          attachment.newFilename = attachment.filename;
          attachment.editedFilename = attachment.filename;
          attachment.canEditFilename = attachment.type === 'pdf';

          return attachment;
        })
      );

      $scope.emailAttachments.local = [];

      $scope.$apply();
    };

    self._uploadProgress = function (progressEvent) {
      $scope.data.uploadProgress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      $scope.$apply();
    };

    self._uploadError = function () {
      $mdToast.showSimple('There was an error uploading attachments.');
    };

    $scope.removeAttachment = function (attachmentToRemove) {
      attachmentService.deleteAttachment(attachmentToRemove.delete_token);

      remove($scope.emailAttachments.remote, function (remoteAttachment) {
        return (
          remoteAttachment.delete_token === attachmentToRemove.delete_token
        );
      });
    };

    $scope.cancelMessage = function () {
      if ($scope.onCancelMessage) {
        $scope.onCancelMessage($scope.data.target);
      }
    };

    $scope.trySendMessage = function () {
      // prevent the user from sending a rich text email
      // that contains one or more drag and drop attachments,
      // but no actual text
      if ($scope.enableRichTextEditor) {
        let text = $scope.messageHtml || '';
        text = text.replace(/(<([^>]+)>|&nbsp;)/g, '').trim();
        if (text.length === 0) {
          $mdToast.showSimple('Email must contain text');
          return;
        }
      }
      if (
        $scope.messageHtml === '' &&
        $scope.emailAttachments.remote.length === 0
      ) {
        $mdToast.showSimple('Email must contain text or attachment');
        return;
      }

      if ($scope.onSendMessage) {
        $scope.onSendMessage($scope.data.target);
      }
    };

    self._initialize();
  };

  MessagingEmailController.$inject = [
    '$window',
    '$mdDialog',
    '$mdToast',
    '$rootScope',
    '$scope',
    '$auth',
    'conversationsService',
    'attachmentService',
    'appDataService',
    'userService',
    'managerApi',
    'propertyApi',
    'quotesService'
  ];

  app.controller('MessagingEmailController', MessagingEmailController);
})();
