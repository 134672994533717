/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular, $) {
  'use strict';

  var app = angular.module('knock-EmojiPicker', []);

  app.controller('emojiPickerController', [
    '$rootScope',
    '$scope',
    'userService',
    function ($rootScope, $scope, userService) {
      var user = userService.getUser();

      if (user) {
        $scope.isRenter = user.type === 'renter';
      }

      $rootScope.$on('messageMode', function (event, data) {
        $scope.messageMode = data;
      });
    }
  ]);

  app.directive('emojiPicker', [
    function () {
      return {
        restrict: 'A',
        controller: 'emojiPickerController',
        link: function (scope, element) {
          var $element = $(element);
          var $buttonWrap = $('.emoji-button-wrap');
          var emojiPickerWidth = 300;
          var emojiPickerHeight = 250;

          $element.css({
            position: 'relative'
          });

          var textarea = $element.find('textarea');
          var pickerButtonMarkup;

          // For the enhanced emoji button we need to include the actual markup for the button and icon, since a React component isn't going to be
          // rendered by jQuery. What is included below is the typical HTML rendered for a Material UI button that has an SVG inside.
          if (
            scope.emojiPickerContext === 'ConversationArea' ||
            scope.emojiPickerContext === 'MessagingSMS'
          ) {
            pickerButtonMarkup =
              '<div class="new-emoji-picker-button">' +
              '  <button class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary" tabindex="0" type="button">' +
              '    <span class="MuiButton-label">' +
              '     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
              '       <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.4375C14.6599 18.4375 18.4375 14.6599 18.4375 10C18.4375 5.3401 14.6599 1.5625 10 1.5625C5.3401 1.5625 1.5625 5.3401 1.5625 10C1.5625 14.6599 5.3401 18.4375 10 18.4375Z" stroke="#353950" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
              '       <path d="M7.1875 7.46875C7.34283 7.46875 7.46875 7.59467 7.46875 7.75C7.46875 7.90533 7.34283 8.03125 7.1875 8.03125C7.03217 8.03125 6.90625 7.90533 6.90625 7.75C6.90625 7.59467 7.03217 7.46875 7.1875 7.46875" stroke="#353950" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
              '       <path d="M12.8125 7.46875C12.9678 7.46875 13.0938 7.59467 13.0938 7.75C13.0938 7.90533 12.9678 8.03125 12.8125 8.03125C12.6572 8.03125 12.5312 7.90533 12.5312 7.75C12.5312 7.59467 12.6572 7.46875 12.8125 7.46875" stroke="#353950" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
              '      <path d="M14.5361 12.25C13.6815 13.9746 11.9232 15.0656 9.99856 15.0656C8.07389 15.0656 6.3156 13.9746 5.46106 12.25" stroke="#353950" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
              '     </svg><span>Emojis</span>' +
              '    </span>' +
              '    <span class="MuiTouchRipple-root"></span>' +
              '  </button>' +
              '</div>';
          } else {
            pickerButtonMarkup =
              '<div class="emoji-picker-button"><i class="fa fa-smile-o fa-2x"></i></div>';
          }

          var pickerButton = $(pickerButtonMarkup)
            .click(function (e) {
              textarea.emojiPicker('toggle');

              $('.emojiPicker')
                .css({
                  left: e.pageX.toString() + 'px',
                  top: (e.pageY - emojiPickerHeight).toString() + 'px',
                  //make sure menu will toggle above everything
                  zIndex: 99999999
                })
                // multiple textarea DOM and scope pathing
                .on('click', function () {
                  if (typeof textarea[1] !== 'undefined') {
                    scope.messageText = textarea[1].value;
                  } else {
                    if (typeof scope.message !== 'undefined') {
                      scope.message.text = textarea[0].value;
                    } else {
                      scope.messageText = textarea[0].value;

                      if (typeof scope.data.messageText !== 'undefined') {
                        scope.data.messageText = textarea[0].value;
                      }
                    }
                  }
                  scope.$apply();
                });
            })
            .css({
              cursor: 'pointer'
            });

          textarea.emojiPicker({
            height: emojiPickerHeight.toString() + 'px',
            width: emojiPickerWidth.toString() + 'px',
            button: false
          });

          if (
            !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            if (scope.emojiPickerContext === 'MessagingSMS') {
              var $newPickerContainer = $('.emoji-picker-container');
              $newPickerContainer.append(pickerButton);
            } else {
              if ($buttonWrap.length > 0) {
                $buttonWrap.append(pickerButton);
              } else {
                $element.append(pickerButton);
              }
            }
          }

          scope.$watch('messageMode', function (next) {
            if (scope.emojiPickerContext === 'ConversationArea') {
              var $currentPickerButton = $('.new-emoji-picker-button');
              var $newPickerContainer = $('.emoji-picker-container');

              // If the user is on the SMS tab in the conversation area footer in the guest card, we put the emoji picker button in the proper place
              // among the footer buttons. Otherwise we need to move it out of the footer since that element could potentially be hidden if the user
              // selects the Call tab. We can't use the button wrap logic at the top of this directive because the SMS footer isn't going to be in
              // the DOM at first render, so we need to make the picker button placement driven by tab selection.
              if (next === 'sms') {
                $currentPickerButton.appendTo($newPickerContainer);
              } else {
                $currentPickerButton.appendTo($element);
              }
            }
          });
        }
      };
    }
  ]);
})(window.angular, window.jQuery);
