import * as React from 'react';

import { Box } from '@knockrentals/knock-shared-web';

export interface TabPanelProps {
  index: number;
  value: number;
  children?: React.ReactNode;
  className?: string;
}

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  className,
  ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box className={className ? className : undefined}>{children}</Box>
    )}
  </div>
);
