/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('conversationInboxPagination', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/conversations/conversation-inbox-pagination.html',
      controller: 'ConversationInboxPaginationController',
      scope: {
        streamType: '=',
        isLoadingNext: '=',
        isLoadingPrev: '=',
        conversations: '=',
        inboxPagination: '=',
        onReloadConversations: '='
      }
    };
  });

  const ConversationInboxPaginationController = function ($scope) {
    $scope.loadFirst = function (userType) {
      if ($scope.isLoadingPrev || $scope.isLoadingNext) {
        return;
      }

      $scope.isLoadingPrev = true;

      $scope.conversations[userType] = [];
      $scope.inboxPagination[userType].start = 0;

      $scope.onReloadConversations(userType.slice(0, -1), true);
    };

    $scope.loadPrev = function (userType) {
      if ($scope.isLoadingPrev) {
        return;
      }

      $scope.isLoadingPrev = true;

      $scope.conversations[userType] = [];
      $scope.inboxPagination[userType].start = Math.max(
        0,
        $scope.inboxPagination[userType].start - 20
      );

      $scope.onReloadConversations(userType.slice(0, -1), true);
    };

    $scope.loadNext = function (userType) {
      if ($scope.isLoadingNext || $scope.inboxPagination[userType].allLoaded) {
        return;
      }

      $scope.isLoadingNext = true;

      $scope.conversations[userType] = [];
      $scope.inboxPagination[userType].start += 20;

      $scope.onReloadConversations(userType.slice(0, -1), true);
    };
  };

  ConversationInboxPaginationController.$inject = ['$scope'];

  app.controller(
    'ConversationInboxPaginationController',
    ConversationInboxPaginationController
  );
})(window.angular);
