import keys from 'lodash/keys';
(function (angular) {
  var app = angular.module('knock-UserInteractions');

  app.directive('prospectStatusSelect', function () {
    return {
      restrict: 'E',
      template:
        '<md-input-container>' +
        '<label>Status</label>' +
        '<md-select ng-model="ngModel" ng-change="statusChanged()">' +
        '<md-option ng-repeat="status in statuses | orderBy:sort" ng-value="status">{{status | prospectStatus}}</md-option>' +
        '</md-select>' +
        '</md-input-container>',
      scope: {
        ngModel: '=',
        onChanged: '=?',
        includeMetaStatuses: '=?',
        selectedProspects: '=?'
      },
      controller: [
        '$scope',
        '$filter',
        'userInteractionsService',
        'prospectLossReasonsService',
        'prospectStatusMap',
        'managerApi',
        function (
          $scope,
          $filter,
          userInteractionsService,
          prospectLossReasonsService,
          prospectStatusMap,
          managerApi
        ) {
          var self = this;
          var metaStatuses = ['all', 'active', 'archived', 'not_prospect'];
          var prospectStatusFilter = $filter('prospectStatus');

          $scope.onChanged = $scope.onChanged || function () {};

          $scope.statusChanged = function () {
            if (
              prospectLossReasonsService.statusIsLost($scope.ngModel) &&
              $scope.selectedProspects
            ) {
              prospectLossReasonsService
                .openBatchProspectsLossReasonsModal(
                  $scope.selectedProspects,
                  $scope.ngModel
                )
                .then(function () {
                  $scope.onChanged($scope.ngModel);
                });
              return;
            }

            $scope.onChanged($scope.ngModel);
          };

          $scope.sort = function (status) {
            if (status === 'active') {
              return 'AAAAAAA';
            }

            if (status === 'not_prospect') {
              return 'ZZZZZZZ';
            }

            if (status === 'archived') {
              return 'ZZZZZZZZ';
            }

            return prospectStatusFilter(status);
          };

          self._initialize = function () {
            if ($scope.includeMetaStatuses) {
              $scope.statuses = metaStatuses.concat(keys(prospectStatusMap));
            } else if ($scope.status) {
              userInteractionsService
                .getProspectStatusTransitions($scope.status)
                .success(self._getPossibleStatusesSuccess);
            } else {
              $scope.statuses = keys(prospectStatusMap);
            }

            self.checkIfAnyPropertiesUseOnSiteAndFilterStatuses();
          };

          self._getPossibleStatusesSuccess = function (response) {
            $scope.statuses = response.statuses;

            if ($scope.includeMetaStatuses) {
              $scope.statuses = metaStatuses.concat($scope.statuses);
            }
          };

          self.checkIfAnyPropertiesUseOnSiteAndFilterStatuses = function () {
            managerApi.getMyCommunities().then(function (response) {
              const communities = response.data.communities;

              const anyCommunitiesUseOnSiteAsLeasingProvider = communities.some(
                function (community) {
                  return community.leasing?.provider === 'On-Site';
                }
              );

              if (!anyCommunitiesUseOnSiteAsLeasingProvider) {
                const onSiteOnlyStatuses = [
                  'application-in-progress',
                  'applied-pending-signature'
                ];

                $scope.statuses = $scope.statuses.filter((s) => {
                  return !onSiteOnlyStatuses.includes(s);
                });
              }
            });
          };

          self._initialize();
        }
      ]
    };
  });
})(window.angular);
