import React, { FC, useEffect, useState } from 'react';

import {
  Box,
  Card,
  Text,
  makeStyles,
  NamedColors,
  CardContent
} from '@knockrentals/knock-shared-web';
import { NotesCard } from './NotesCard';
import { NotesSection, Property } from '../models';
import {
  NotePinIcon,
  MapPinIcon,
  UnitsIcon,
  BuildingNatureIcon,
  BlankNotesTabIcon
} from '../../icons';
import LeasingBinderNoContent from '../LeasingBinderNoContent';

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px'
  },

  columns: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px'
  },

  column: {
    width: '100%',
    display: 'flex',
    gap: '16px',
    alignItems: 'flex-start'
  },

  card: {
    border: `1px solid ${NamedColors.slate[100]}`,
    marginBottom: '16px',
    width: '33.3%',
    '&:last-child': {
      marginBottom: 0
    }
  },

  noticeSection: {
    backgroundColor: NamedColors.banana[50],
    border: `1px solid ${NamedColors.banana[200]}`,
    width: '100%'
  },

  sectionHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '14px'
  },

  sectionContent: {
    display: 'flex',
    marginBottom: '10px',

    '&:last-child': {
      marginBottom: 0
    },

    '& svg': {
      marginRight: '10px',
      marginTop: '4px'
    }
  },

  noteContent: {
    wordBreak: 'break-all'
  }
}));

export interface LeasingBinderNotesProps {
  property: Property;
}

export const LeasingBinderNotes: FC<LeasingBinderNotesProps> = ({
  property
}) => {
  const classes = useStyles();
  const [columnSections, setColumnSections] = useState<NotesSection[]>([]);

  const getSectionIcon = (key: string) => {
    let icon: React.ReactNode;

    switch (key) {
      case 'location':
        icon = <MapPinIcon />;
        break;
      case 'community':
        icon = <BuildingNatureIcon />;
        break;
      case 'units':
        icon = <UnitsIcon />;
        break;
    }

    return icon;
  };

  useEffect(() => {
    const newSections = new Array<NotesSection>();

    let keys = new Array<string>();
    let section: NotesSection;

    if (property.key_selling_points) {
      keys = ['location', 'community', 'units'];
    }

    for (const key of keys) {
      if (
        property.key_selling_points[key].length > 0 &&
        property.key_selling_points[key].find((item: string) =>
          item ? item.trim() : false
        )
      ) {
        section = {
          title: key.toUpperCase(),
          icon: getSectionIcon(key),
          items: property.key_selling_points[key]
        };
        newSections.push(section);
      }
    }

    setColumnSections(newSections);
  }, [property]);

  return (
    <Box className={classes.mainContainer} data-testid="NotesTab">
      {!property.notes &&
      columnSections.length === 0 &&
      columnSections.length === 0 ? (
        <LeasingBinderNoContent
          icon={<BlankNotesTabIcon />}
          primaryText="No notes or selling tips yet!"
        />
      ) : (
        <>
          {property.notes && (
            <Card
              className={`${classes.card} ${classes.noticeSection}`}
              elevation={0}
            >
              <CardContent>
                <Box className={classes.sectionHeader}>
                  <Text variant="body2">NOTICE</Text>
                  <NotePinIcon />
                </Box>

                <Text variant="body1" className={classes.noteContent}>
                  {property.notes}
                </Text>
              </CardContent>
            </Card>
          )}

          <Box className={classes.columns}>
            <Box className={classes.column}>
              {columnSections.map((section: NotesSection) => (
                <NotesCard
                  key={section.title}
                  section={section}
                  className={classes.card}
                />
              ))}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
