/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('PhoneVerificationConfirmationModalController', [
    '$scope',
    '$modalInstance',
    'verificationApi',
    'phoneNumber',
    'responsePromise',
    function (
      $scope,
      $modalInstance,
      verificationApi,
      phoneNumber,
      responsePromise
    ) {
      $scope.phoneNumber = phoneNumber;
      $scope.verificationCode = '';

      $scope.confirmVerificationCode = function (verificationCode) {
        $scope.isVerifying = true;
        verificationApi
          .confirmVerificationCode(verificationCode)
          .success(function (response) {
            responsePromise.resolve(response);
          })
          .error(function (error, status) {
            responsePromise.reject(error);
          })
          .finally(function () {
            $modalInstance.close();
          });
      };

      $scope.resendSMSVerificationCode = function () {
        $scope.isSendingCode = true;
        verificationApi
          .verifySMS($scope.phoneNumber)
          .success(function () {
            $scope.resendSuccessMessage = 'Code was successfully resent!';
          })
          .error(function (error) {
            $scope.resendErrorMessage =
              'There was an error resending your verification code.';
          })
          .finally(function () {
            $scope.isSendingCode = false;
          });
      };

      $scope.resendPhoneVerificationCode = function () {
        $scope.isSendingCode = true;
        verificationApi
          .verifyCall($scope.phoneNumber)
          .success(function () {
            $scope.resendSuccessMessage = 'Code was successfully resent!';
          })
          .error(function (error) {
            $scope.resendErrorMessage =
              'There was an error resending your verification code.';
          })
          .finally(function () {
            $scope.isSendingCode = false;
          });
      };

      $scope.cancelVerification = function () {
        $modalInstance.close('canceled');
      };
    }
  ]);
})();
