/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('videoCallApi', [
    'apiBase',
    function (apiBase) {
      return {
        getVideoCall: function (videoCallId) {
          return apiBase.get('/video-call/' + videoCallId);
        },
        startVideoCall: function (videoCallId) {
          return apiBase.post('/video-call/' + videoCallId + '/start');
        },
        joinVideoCall: function (videoCallId, participantDisplayName) {
          return apiBase.post('/video-call/' + videoCallId + '/join', {
            username: participantDisplayName
          });
        },
        endVideoCall: function (videoCallId) {
          return apiBase.post('/video-call/' + videoCallId + '/end');
        }
      };
    }
  ]);
})(window.angular);
