/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isNull from 'lodash/isNull';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.filter('minutesHours', [
    function () {
      return function (inputMinutes) {
        if (inputMinutes < 60) {
          return inputMinutes + ' minute';
        }

        var hours = Math.floor(inputMinutes / 60);
        var minutes = inputMinutes % 60;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        return hours + ':' + minutes;
      };
    }
  ]);

  app.directive('autoRespondEditor', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/profile/managerProfile/auto-respond-editor.html',
      scope: {
        onSaveStart: '=',
        onSaveSuccess: '='
      },
      controller: [
        '$scope',
        '$timeout',
        'managerApi',
        'timeService',
        function ($scope, $timeout, managerApi, timeService) {
          var self = this;

          if ($scope.onSaveStart) {
            var originalSave = $scope.onSaveStart;

            $scope.onSaveStart = function () {
              originalSave();
              $scope.saveAutoRespondPreferences();
            };
          }

          var defaultConcurrentShowings = 2;
          var defaultAdjacentShowingsIntervalMinutes = 15;
          var defaultAvailabilityBufferHours = 1;
          var defaultTourDurationMinutes = 15;

          $scope.checkboxes = {
            allShowings: true,
            adjacentShowings: true,
            concurrentShowings: true,
            availabilityBuffer: true
          };

          $scope.help = {
            allShowings:
              'This will automatically accept appointments for times that you are available. This takes into ' +
              'account your Hours, Sync Calendar, and any other appointments you have made using Knock.',
            concurrentShowings:
              'This allows you to "double up" appointments by automatically accepting appointments ' +
              'at times you are already showing a particular property. For example, if you schedule ' +
              'an appointment at 8:00am with Renter A, Renter B will be able to automatically schedule ' +
              'at 8:00 am as well.',
            adjacentShowings:
              'This allows you to set "back-to-back" appointments by automatically accepting appointments ' +
              'immediately before or after times you are already showing a particular property. For example' +
              ', if you schedule an appointment at 8:00am with Renter A, Renter B will be able to ' +
              'automatically schedule at 7:45am or 8:15am.',
            visitorMaximum:
              'This limits the number of visitors that can schedule appointments for any given 15-minute time slot',
            availabilityBuffer:
              'This prevents renters from scheduling without notice. So, with a 2-hour buffer, a ' +
              'renter visiting your listing page at 3pm would not be able to request showings before 5pm.',
            tourDuration:
              'This sets the default time allotted for each tour appointment'
          };

          self._loadManagerPreferences = function () {
            managerApi
              .getManagerPreferences()
              .success(self._loadManagerPreferencesSuccess);
          };

          self._loadManagerPreferencesSuccess = function (response) {
            var autoRespondPreferences = {};
            var existingPreferences = response.manager_preferences;

            autoRespondPreferences.all_showings =
              existingPreferences.all_showings;

            if (isNull(autoRespondPreferences.all_showings)) {
              autoRespondPreferences.all_showings = true;
            }

            $scope.checkboxes.allShowings = autoRespondPreferences.all_showings;

            // Convert the interval value to an integer
            if (existingPreferences.adjacent_showing_interval) {
              var intervalMoment = timeService.get(
                existingPreferences.adjacent_showing_interval,
                'HH:mm:ss'
              );
              var minutes =
                intervalMoment.hours() * 60 + intervalMoment.minutes();

              autoRespondPreferences.adjacent_showing_interval = minutes;
            } else {
              $scope.checkboxes.adjacentShowings = false;
            }

            // Convert the interval value to an integer
            if (existingPreferences.tour_duration) {
              var tourDurationMoment = timeService.get(
                existingPreferences.tour_duration,
                'HH:mm:ss'
              );
              var tourDurationMinutes =
                tourDurationMoment.hours() * 60 + tourDurationMoment.minutes();

              autoRespondPreferences.tour_duration = tourDurationMinutes;
            }

            if (existingPreferences.availability_buffer) {
              // If availability_buffer is 24 hours (the max), the string looks like '1 day, 00:00'
              var bufferString = existingPreferences.availability_buffer;
              var bufferRegex = /day/;
              var bufferHours;

              if (bufferRegex.test(bufferString)) {
                bufferHours = 24;
              } else {
                var bufferMoment = timeService.get(bufferString, 'HH:mm:ss');
                bufferHours = bufferMoment.hours();
              }

              autoRespondPreferences.availability_buffer = bufferHours;
            } else {
              $scope.checkboxes.availabilityBuffer = false;
            }

            if (existingPreferences.concurrent_showing_visitors) {
              autoRespondPreferences.concurrent_showing_visitors =
                existingPreferences.concurrent_showing_visitors;
            } else {
              $scope.checkboxes.concurrentShowings = false;
            }

            $scope.autoRespondPreferences = autoRespondPreferences;

            $scope.stepReady = true;
            $scope.adjacentIntervalStep =
              $scope.autoRespondPreferences.tour_duration;

            self._setupCheckboxWatches();
          };

          self._setupCheckboxWatches = function () {
            $scope.$watch('checkboxes.allShowings', function (isChecked) {
              $scope.autoRespondPreferences.all_showings = isChecked;
            });

            $scope.$watch('checkboxes.adjacentShowings', function (isChecked) {
              if (isChecked) {
                $scope.autoRespondPreferences.adjacent_showing_interval =
                  $scope.autoRespondPreferences.adjacent_showing_interval ||
                  defaultAdjacentShowingsIntervalMinutes;
              } else {
                $scope.autoRespondPreferences.adjacent_showing_interval = null;
              }
            });

            $scope.$watch(
              'checkboxes.availabilityBuffer',
              function (isChecked) {
                if (isChecked) {
                  $scope.autoRespondPreferences.availability_buffer =
                    $scope.autoRespondPreferences.availability_buffer ||
                    defaultAvailabilityBufferHours;
                } else {
                  $scope.autoRespondPreferences.availability_buffer = null;
                }
              }
            );

            $scope.$watch(
              'checkboxes.concurrentShowings',
              function (isChecked) {
                if (isChecked) {
                  $scope.autoRespondPreferences.concurrent_showing_visitors =
                    $scope.autoRespondPreferences.concurrent_showing_visitors ||
                    defaultConcurrentShowings;
                } else {
                  $scope.autoRespondPreferences.concurrent_showing_visitors =
                    null;
                }
              }
            );
          };

          $scope.updateAdjacentInterval = function () {
            $scope.autoRespondPreferences.adjacent_showing_interval =
              $scope.autoRespondPreferences.tour_duration;
            $scope.adjacentIntervalStep =
              $scope.autoRespondPreferences.tour_duration;

            $scope.stepReady = false;
            $timeout(function () {
              $scope.stepReady = true;
            });
          };

          $scope.saveAutoRespondPreferences = function () {
            $scope.isSavingAutoRespond = true;

            $scope.saveAutoRespondSuccessMessage = null;
            $scope.saveAutoRespondErrorMessage = null;

            managerApi
              .postManagerPreferences($scope.autoRespondPreferences)
              .success(self._saveAutoRespondSuccess)
              .error(self._saveAutoRespondError)
              .finally(function () {
                $scope.isSavingAutoRespond = false;
              });
          };

          self._saveAutoRespondSuccess = function () {
            $scope.saveAutoRespondSuccessMessage =
              'Auto respond preferences saved successfully!';

            if ($scope.onSaveSuccess) {
              $scope.onSaveSuccess();
            }
          };

          self._saveAutoRespondError = function () {
            $scope.saveAutoRespondErrorMessage =
              'There was an error saving your auto respond preferences. Please try again later.';
          };

          self._loadManagerPreferences();
        }
      ]
    };
  });
})();
