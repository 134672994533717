(function (angular) {
  var app = angular.module('knockApp');

  /**
   * @deprecated 5/31/2019 - 6/14/2019 sprint
   * Please use rentMatrixService instead.
   */
  app.factory('OBSOLETE_rentMatrixService', [
    'apiBase',
    '$mdDialog',
    function (apiBase, $mdDialog) {
      var self = this;

      self.getHasRentMatrix = function (propertyId) {
        return apiBase.get('/property/' + propertyId + '/rentmatrix');
      };

      self.getRentMatrix = function (listingId) {
        return apiBase
          .get('/listing/' + listingId + '/rentmatrix')
          .then((response) => response)
          .catch((e) => {
            // catch any specific error messages being sent by APIv2-
            // particularly RealPage timeout error messages- and throw them on
            // to be handled by consuming function
            const message =
              e.data && e.data.message ? e.data.message : e.statusText;
            console.error(`Error fetching rent matrix: ${message}`);
            throw Error(message);
          });
      };

      self.openMatrixModal = function (propertyId, listingId, clickable) {
        return $mdDialog.show({
          templateUrl:
            '/angular/views/properties/OBSOLETE_rent-matrix-modal.html',
          controller: 'OBSOLETE_RentMatrixModalController',
          clickOutsideToClose: true,
          locals: {
            propertyId: propertyId,
            listingId: listingId,
            clickable: clickable || false
          }
        });
      };

      return self;
    }
  ]);

  /**
   * @deprecated 5/31/2019 - 6/14/2019 sprint
   * Please use RentMatrixService instead.
   */
  app.controller('OBSOLETE_RentMatrixModalController', [
    '$scope',
    '$mdDialog',
    '$moment',
    'OBSOLETE_rentMatrixService',
    'communityApi',
    'propertyApi',
    'propertyId',
    'listingId',
    'clickable',
    '_',
    function (
      $scope,
      $mdDialog,
      $moment,
      OBSOLETE_rentMatrixService,
      communityApi,
      propertyApi,
      propertyId,
      listingId,
      clickable,
      _
    ) {
      var self = this;

      $scope.data = {
        listingId: null,
        listings: [],
        headers: [],
        clickable: clickable,
        isLoading: true,
        error: false
      };

      self._initialize = function () {
        propertyApi.getProperty(propertyId).then(function (response) {
          communityApi
            .getCommunityListings(response.data.property.data.id, false, false)
            .then(function (communityResponse) {
              $scope.data.listings = _.sortBy(
                _.map(communityResponse.data.listings, function (listing) {
                  var listingTitle =
                    '$' +
                    listing.leasing.monthlyRent +
                    ' - ' +
                    listing.floorplan.bedrooms +
                    'bd ' +
                    listing.floorplan.bathrooms +
                    'ba - ' +
                    listing.floorplan.name +
                    ' Unit ' +
                    listing.location.address.unit;

                  if (_.get(listing, 'external.building.number')) {
                    listingTitle =
                      listingTitle +
                      ' - Building ' +
                      listing.external.building.number;
                  }

                  return {
                    title: listingTitle,
                    id: listing.id,
                    monthlyRent: listing.leasing.monthlyRent
                  };
                }),
                'monthlyRent'
              );

              if ($scope.data.listings.length > 0) {
                $scope.data.listingId = listingId || $scope.data.listings[0].id;
                $scope.getMatrix();
              }
            });
        });
      };

      $scope.getMatrix = function () {
        $scope.data.isLoading = true;
        $scope.data.error = false;

        var nowMoment = $moment();

        OBSOLETE_rentMatrixService.getRentMatrix($scope.data.listingId)
          .then((response) => {
            if (response.data.matrix.length === 0) {
              $scope.data.error =
                'No Rent Matrix data could be located for this unit. Please ensure your PMS Rent Matrix integration is properly enabled.';
              return;
            }

            var futureLeaseDates = _.filter(
              response.data.matrix,
              function (dateCol) {
                return $moment(dateCol.lease_date).isSameOrAfter(
                  nowMoment,
                  'day'
                );
              }
            );

            $scope.data.headers = _.map(futureLeaseDates, function (dateCol) {
              return dateCol.lease_date;
            });

            var termCount = response.data.matrix[0].terms.length;

            $scope.data.rows = _.map(_.range(termCount), function (index) {
              return _.map(futureLeaseDates, function (dateCol) {
                return _.sortBy(dateCol.terms, 'lease_term').reverse()[index];
              });
            });

            $scope.data.isLoading = false;
          })
          .catch((e) => {
            // Surface message for RealPage timeout errors
            if (e.message && e.message.toLowerCase().includes('realpage')) {
              $scope.data.error = e;
            } else {
              $scope.data.error =
                'Error retrieving Rent Matrix data for this unit.';
            }
          })
          .finally(() => {
            $scope.data.isLoading = false;
          });
      };

      $scope.selectRentCell = function (row, term, index) {
        var startDate = $scope.data.headers[index];
        var leaseTerm = term.lease_term;
        var rent = term.rent;

        $mdDialog.hide({
          startDate: startDate,
          leaseTerm: leaseTerm,
          rent: rent,
          listingId: $scope.data.listingId
        });
      };

      self._initialize();
    }
  ]);
})(window.angular);
