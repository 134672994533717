/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('TranscriptQueryService', [
    function () {
      return {
        getTranscriptQuery: function () {
          return `
              query GetCallTranscript($threadId: Int!) {
                callTranscript(threadId: $threadId) {
                  recordingUrl
                  annotations {
                    greeting
                    name
                    preferences
                    prospectInfo
                    prospectInterest
                    prospectSource
                    followup
                  }
                  utterances {
                    speaker
                    time
                    transcript
                  }
                }
              }
              `;
        }
      };
    }
  ]);
})();
