/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('deviceApi', [
    'apiBase',
    function (apiBase) {
      return {
        getMyDevices: function () {
          return apiBase.get('/me/devices');
        },
        updateDevice: function (deviceId, device) {
          return apiBase.put('/devices/' + deviceId, device);
        },
        revokeDeviceAccess: function (deviceId) {
          return apiBase.delete('/auth/refresh/' + deviceId);
        }
      };
    }
  ]);
})();
