/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('messagingModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openProspectMessageModal = function (recipients) {
        var dialog = {
          controller: 'ProspectMessageController',
          templateUrl: '/angular/views/messaging/prospect-send-message.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          locals: {
            recipients: recipients
          }
        };

        return $mdDialog.show(dialog);
      };

      self.openResidentMessageModal = function (recipients, priority) {
        var dialog = {
          controller: 'ResidentMessageController',
          templateUrl: '/angular/views/messaging/resident-send-message.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          locals: {
            priority: priority,
            recipients: recipients
          }
        };

        return $mdDialog.show(dialog);
      };

      self.openTodoResidentMessageModal = function (recipients, priority) {
        const dialog = {
          controller: 'ResidentMessageController',
          templateUrl:
            '/angular/views/messaging/todo-resident-send-message.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          locals: {
            priority: priority,
            recipients: recipients
          }
        };

        return $mdDialog.show(dialog);
      };

      return self;
    }
  ]);
})(window.angular);
