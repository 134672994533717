/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import size from 'lodash/size';
import each from 'lodash/each';
import map from 'lodash/map';
import filter from 'lodash/filter';
import every from 'lodash/every';
import forEach from 'lodash/forEach';
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.controller('ShareListingModalController', [
    '$scope',
    '$filter',
    '$modalInstance',
    '$location',
    'listing',
    'shareApi',
    function ($scope, $filter, $modalInstance, $location, listing, shareApi) {
      var listingId = listing.id;
      var self = this;
      var phoneFilter = $filter('phoneNumber');
      var barePhoneRegex = /^\d{10}$/;
      var emailRegex = /^.+@.+\..+$/;

      $scope.textNumber = '';
      $scope.recipientEmail = '';

      $scope.sendEmail = function () {
        if (!emailRegex.test($scope.recipientEmail)) {
          $scope.alertMessage = 'Invalid email address.';
          return;
        }

        $scope.isSendingEmail = true;
        shareApi
          .sendEmail($scope.recipientEmail, listingId)
          .success(function () {
            $scope.alertMessage =
              "We've sent an email to " + $scope.recipientEmail + '!';
          })
          .error(function () {
            $scope.alertMessage =
              'There was an error sending your email. Please try again later.';
          })
          .finally(function () {
            $scope.isSendingEmail = false;
          });
      };

      $scope.dismiss = function () {
        $modalInstance.dismiss();
      };

      self._makeShortLink = function () {
        // If we haven't generated a short link for this listing before, create one.
        if (listing.social.shortlink) {
          $scope.shortLink = listing.social.shortlink;
        } else {
          shareApi.makeListingShortLink(listingId).success(function (res) {
            $scope.shortLink = res.link.url;
          });
        }
      };

      self._makeShortLink();
    }
  ]);

  app.controller('DeactivateListingDialogController', [
    '$scope',
    '$mdDialog',
    '$moment',
    'managerApi',
    'listingApi',
    'listing',
    function ($scope, $mdDialog, $moment, managerApi, listingApi, listing) {
      var self = this;

      $scope.viewState = {
        allSelected: true
      };

      self.initialize = function () {
        $scope.shouldCancelAppointments = false;

        self.loadProspects();
        self.getUpcomingAppointmentsCount();
      };

      $scope.allSelectedChanged = function () {
        forEach($scope.prospects, function (prospect) {
          prospect.shouldNotify = $scope.viewState.allSelected;
        });
      };

      $scope.prospectSelectedChanged = function () {
        $scope.viewState.allSelected = every($scope.prospects, 'shouldNotify');
      };

      $scope.deactivate = function () {
        $scope.isDeactivating = true;

        var prospectsToNotify = filter($scope.prospects, 'shouldNotify');
        var prospectIdsToNotify = map(prospectsToNotify, 'id');

        managerApi
          .deactivateListing(
            listing.id,
            $scope.managerMessage,
            !$scope.shouldCancelAppointments,
            prospectIdsToNotify
          )
          .success($mdDialog.hide)
          .finally(function () {
            $scope.isDeactivating = false;
          });
      };

      $scope.dismiss = function () {
        $mdDialog.cancel();
      };

      self.loadProspects = function () {
        $scope.isLoadingProspects = true;

        var start = $moment().subtract(1, 'months');

        return listingApi
          .getListingProspects(listing.id, start)
          .success(function (response) {
            $scope.prospects = map(response.prospects, 'Prospect');

            each($scope.prospects, function (prospect) {
              prospect.shouldNotify = true;
            });
          })
          .finally(function () {
            $scope.isLoadingProspects = false;
          });
      };

      self.getUpcomingAppointmentsCount = function () {
        $scope.isLoadingAppointmentsCount = true;

        var start = $moment();

        return listingApi
          .getListingAppointments(listing.id, start, null, ['confirmed'])
          .success(function (response) {
            $scope.upcomingAppointmentsCount = size(response.appointments);
          })
          .finally(function () {
            $scope.isLoadingAppointmentsCount = false;
          });
      };

      self.initialize();
    }
  ]);

  app.filter('prospectSummary', function () {
    return function (prospect) {
      return (
        prospect.profile.first_name +
        ' ' +
        prospect.profile.last_name +
        ' - ' +
        prospect.profile.email
      );
    };
  });

  app.controller('newListingModalController', [
    '$scope',
    '$modalInstance',
    '$location',
    function ($scope, $modalInstance, $location) {
      $scope.newListing = function () {
        $location.url('/manager/listing/create');
        $modalInstance.close('new listing');
      };

      $scope.newCommunity = function () {
        $location.url('/manager/community/create');
        $modalInstance.close('new community');
      };

      $scope.dismiss = function () {
        $modalInstance.dismiss('closed');
      };
    }
  ]);
})();
