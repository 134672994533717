import React, { FC } from 'react';

import { Paper } from '@material-ui/core';
import { palette } from '@knockrentals/knock-shared-web';
// TODO - import DataTable from @knockrentals/knock-shared-web.
// Fixed columns is broken in the most current version of material-table and
// needs to be pinned to 1.69.1
import DataTable, { Column, Options } from 'material-table';
import { Relationship } from './Relationship';
import { ProfileStatus } from './ProfileStatus';
import { parseLastActiveDate } from './last-active-date';
import { ConnectedProfile, ConnectedProfiles } from '../../../../ts/models';

const columns: string[][] = [
  ['COMMUNITY', 'community', '234px'],
  ['RELATIONSHIP', 'relationship', '142px'],
  ['STATUS', 'status', '128px'],
  ['LAST ACTIVE', 'lastActive', '128x']
];

const getColumn = (column: string[]): Column<any> => {
  const [title, field, width] = column;
  return {
    align: 'left',
    field,
    title,
    width
  };
};

const getConnectedProfilesTableData = (profile: ConnectedProfile) => {
  return {
    community: profile.communityName,
    relationship: (
      <Relationship
        type={profile.personType}
        guestcardUrl={profile.guestcardUrl || ''}
      />
    ),
    status: (
      <ProfileStatus
        statusLabel={profile.statusLabel}
        todoStatus={profile.todoStatus}
      />
    ),
    lastActive: parseLastActiveDate(profile.lastActive || '')
  };
};

const options: Options<any> = {
  draggable: false,
  grouping: false,
  headerStyle: {
    color: palette.text ? palette.text.secondary : '',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '32px'
  },
  maxBodyHeight: '246px',
  minBodyHeight: '246px',
  padding: 'dense',
  paging: false,
  rowStyle: {
    fontSize: '14px'
  },
  search: false,
  sorting: false,
  tableLayout: 'fixed',
  toolbar: false
};

interface ConnectedProfilesDataTableProps {
  connectedProfiles: ConnectedProfiles;
}

export const ConnectedProfilesDataTable: FC<
  ConnectedProfilesDataTableProps
> = ({ connectedProfiles }) => {
  return (
    <div className={'connected-profiles-data-table'}>
      <DataTable
        columns={columns.map(getColumn)}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />
        }}
        data={connectedProfiles.map(getConnectedProfilesTableData)}
        options={options}
      />
    </div>
  );
};
