/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import includes from 'lodash/includes';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import compact from 'lodash/compact';
import cloneDeep from 'lodash/cloneDeep';

(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('communityModelService', [
    'amenitiesModelService',
    'DebugInfoService',
    '$rootScope',
    function (amenitiesModelService, debugInfoService, $rootScope) {
      var CommunityModel = function () {
        this.debug = debugInfoService.getDebugInfoObject();

        this.location = {
          name: '',
          propertyType: '',
          address: {
            unit: '',
            house: '',
            street: '',
            city: '',
            state: 'WA',
            zip: ''
          }
        };

        this.social = {
          website: '',
          facebook: '',
          twitter: ''
        };

        this.floorplans = [];

        this.featured_amenities = {
          laundry: {
            types: [
              { name: 'In Unit', available: false },
              { name: 'Shared', available: false }
            ]
          },
          parking: {
            types: [
              { name: 'Garage', available: false },
              { name: 'Covered', available: false },
              { name: 'Off-Street', available: false },
              { name: 'On-Street', available: false }
            ]
          },
          pool: {
            types: [
              { name: 'Private Pool', available: false },
              { name: 'Shared Pool', available: false }
            ]
          },
          gym: {
            types: [{ name: 'Fitness Center', available: false }]
          },
          pets: {
            types: [
              { name: 'Small Dogs', available: false },
              { name: 'Large Dogs', available: false },
              { name: 'Cats', available: false }
            ]
          }
        };

        this.pets = {
          allowed: {
            small_dogs: false,
            large_dogs: false,
            cats: false
          },
          notes: ''
        };

        this.description = {
          short: '',
          full: ''
        };

        this.extra = {
          isSmokingAllowed: null,
          hasWheelchairAccess: null,
          yearBuilt: '',
          sharedUnit: false
        };

        this.utilities = {
          types: [
            { name: 'Water', included: false },
            { name: 'Sewage', included: false },
            { name: 'Garbage', included: false },
            { name: 'Gas', included: false },
            { name: 'Electricity', included: false },
            { name: 'Cable', included: false },
            { name: 'Internet', included: false }
          ],
          estimatedCost: ''
        };

        this.parking = {
          types: [
            { name: 'Garage', available: false },
            { name: 'Covered', available: false },
            { name: 'Off-Street', available: false },
            { name: 'On-Street', available: false }
          ],
          notes: ''
        };

        this.furnishing = [
          { name: 'Furnished', available: false },
          { name: 'Unfurnished', available: false }
        ];

        this.laundry = {
          types: [
            { name: 'In Unit', available: false },
            { name: 'Shared', available: false }
          ],
          notes: ''
        };

        this.appliances = [
          { name: 'Dishwasher', available: false },
          { name: 'Refrigerator', available: false },
          { name: 'Garbage disposal', available: false },
          { name: 'Microwave', available: false },
          { name: 'Range/Oven', available: false }
        ];

        this.views = [
          { name: 'City', available: false },
          { name: 'Mountain', available: false },
          { name: 'Water', available: false },
          { name: 'Park', available: false }
        ];

        this.leasing = {
          application: {
            fee: '',
            instructions: ''
          },
          terms: {
            deposit: '',
            includeUpcharges: false,
            leaseLengths: [],
            notes: ''
          }
        };

        // feature-flagged alternative structure for lease length options,
        // including customizable upcharges per lease length
        if ($rootScope.featureFlags.QUOTE_UPCHARGES) {
          this.leasing.terms.leaseLengths = [
            {
              leaseLength: 'Month to month',
              isAvailable: false,
              upchargeAmount: 0
            },
            {
              leaseLength: '2',
              lengthUnit: 'months',
              isAvailable: false,
              upchargeAmount: 0
            },
            {
              leaseLength: '3',
              lengthUnit: 'months',
              isAvailable: false,
              upchargeAmount: 0
            },
            {
              leaseLength: '4',
              lengthUnit: 'months',
              isAvailable: false,
              upchargeAmount: 0
            },
            {
              leaseLength: '5',
              lengthUnit: 'months',
              isAvailable: false,
              upchargeAmount: 0
            },
            {
              leaseLength: '6',
              lengthUnit: 'months',
              isAvailable: false,
              upchargeAmount: 0
            },
            {
              leaseLength: '7',
              lengthUnit: 'months',
              isAvailable: false,
              upchargeAmount: 0
            },
            {
              leaseLength: '8',
              lengthUnit: 'months',
              isAvailable: false,
              upchargeAmount: 0
            },
            {
              leaseLength: '9',
              lengthUnit: 'months',
              isAvailable: false,
              upchargeAmount: 0
            },
            {
              leaseLength: '10',
              lengthUnit: 'months',
              isAvailable: false,
              upchargeAmount: 0
            },
            {
              leaseLength: '11',
              lengthUnit: 'months',
              isAvailable: false,
              upchargeAmount: 0
            },
            {
              leaseLength: '12',
              lengthUnit: 'months',
              isAvailable: false,
              upchargeAmount: 0
            },
            {
              leaseLength: '12+',
              lengthUnit: 'months',
              isAvailable: false,
              upchargeAmount: 0
            }
          ];
        } else {
          this.leasing.terms.leaseLengths = [
            { leaseLength: 'Month to month', isAvailable: false },
            { leaseLength: '3', lengthUnit: 'months', isAvailable: false },
            { leaseLength: '6', lengthUnit: 'months', isAvailable: false },
            { leaseLength: '9', lengthUnit: 'months', isAvailable: false },
            { leaseLength: '9+', lengthUnit: 'months', isAvailable: false },
            { leaseLength: '12', lengthUnit: 'months', isAvailable: false },
            { leaseLength: '12+', lengthUnit: 'months', isAvailable: false }
          ];
        }

        this.amenities = amenitiesModelService.getNewModel();

        this.photos = [];

        this.videos = [{ url: '', thumb_url: '' }];
      };

      var CommunityModelService = {};

      CommunityModelService.getNewModel = function () {
        return new CommunityModel();
      };

      CommunityModelService.mapStoredCommunityToViewModel = function (
        community
      ) {
        var mappedCommunity = cloneDeep(community);
        var communityModel = CommunityModelService.getNewModel();

        mappedCommunity.amenities = mappedCommunity.amenities || {};
        mappedCommunity.featured_amenities =
          mappedCommunity.featured_amenities || {};

        mappedCommunity.laundry = community.laundry || {};
        mappedCommunity.parking = community.parking || {};
        mappedCommunity.utilities = community.utilities || {};
        mappedCommunity.leasing = community.leasing || communityModel.leasing;

        mappedCommunity.leasing.terms = community.leasing.terms || {};

        // includeeUpcharges is a checkbox setting that determines whether
        // an upcharge is pre-included or added to the base rent amount for quotes;
        // only available if the QUOTE_UPCHARGES feature flag is enabled
        if ($rootScope.featureFlags.QUOTE_UPCHARGES) {
          mappedCommunity.leasing.terms.includeUpcharges =
            community.leasing.terms.includeUpcharges || false;
        }
        community.amenities = community.amenities || {};
        community.featured_amenities = community.featured_amenities || {};

        community.laundry = community.laundry || {};
        community.parking = community.parking || {};
        community.utilities = community.utilities || {};

        mappedCommunity.leasing.terms.leaseLengths =
          _mapLeaseLengthsToUpchargeModel(
            community.leasing.terms.leaseLengths,
            communityModel.leasing.terms.leaseLengths
          );
        mappedCommunity.amenities.exterior = _mapCollectionToViewModel(
          community.amenities.exterior,
          communityModel.amenities.exterior
        );
        mappedCommunity.amenities.heatingAndCooling = _mapCollectionToViewModel(
          community.amenities.heatingAndCooling,
          communityModel.amenities.heatingAndCooling
        );
        mappedCommunity.amenities.wiring = _mapCollectionToViewModel(
          community.amenities.wiring,
          communityModel.amenities.wiring
        );
        mappedCommunity.amenities.additional = _mapCollectionToViewModel(
          community.amenities.additional,
          communityModel.amenities.additional
        );
        mappedCommunity.amenities.security = _mapCollectionToViewModel(
          community.amenities.security,
          communityModel.amenities.security
        );
        mappedCommunity.amenities.recreation = _mapCollectionToViewModel(
          community.amenities.recreation,
          communityModel.amenities.recreation
        );
        mappedCommunity.views = _mapCollectionToViewModel(
          community.views,
          communityModel.views
        );
        mappedCommunity.appliances = _mapCollectionToViewModel(
          community.appliances,
          communityModel.appliances
        );
        mappedCommunity.furnishing = _mapCollectionToViewModel(
          community.furnishing,
          communityModel.furnishing
        );
        mappedCommunity.laundry.types = _mapCollectionToViewModel(
          community.laundry.types,
          communityModel.laundry.types
        );
        mappedCommunity.parking.types = _mapCollectionToViewModel(
          community.parking.types,
          communityModel.parking.types
        );
        mappedCommunity.utilities.types = _mapCollectionToViewModel(
          community.utilities.types,
          communityModel.utilities.types,
          'included'
        );
        mappedCommunity.featured_amenities.laundry = _mapCollectionToViewModel(
          community.featured_amenities.laundry,
          communityModel.featured_amenities.laundry
        );
        mappedCommunity.featured_amenities.parking = _mapCollectionToViewModel(
          community.featured_amenities.parking,
          communityModel.featured_amenities.parking
        );
        mappedCommunity.featured_amenities.pool = _mapCollectionToViewModel(
          community.featured_amenities.pool,
          communityModel.featured_amenities.pool
        );
        mappedCommunity.featured_amenities.gym = _mapCollectionToViewModel(
          community.featured_amenities.gym,
          communityModel.featured_amenities.gym
        );
        mappedCommunity.featured_amenities.pets = _mapCollectionToViewModel(
          community.featured_amenities.pets,
          communityModel.featured_amenities.pets
        );

        mappedCommunity.photos = compact(community.photos);

        return mappedCommunity;
      };

      function _mapCollectionToViewModel(
        theArray,
        modelCollection,
        availableFieldName
      ) {
        var newCollection = [];
        availableFieldName = availableFieldName || 'available';

        if (isUndefined(theArray)) {
          return modelCollection;
        }

        if (!isString(theArray[0])) {
          // Ensure we have all fields that are on the model
          if (theArray.length < modelCollection.length) {
            forEach(modelCollection, function (modelItem) {
              var existing = find(theArray, function (arrayItem) {
                return arrayItem.name === modelItem.name;
              });

              if (!existing) {
                var newItem = {
                  name: modelItem.name
                };

                newItem[availableFieldName] = false;
                theArray.push(newItem);
              }
            });
          }

          return theArray;
        }

        modelCollection.forEach(function (modelItem) {
          if (!includes(theArray, modelItem.name)) {
            newCollection.push(modelItem);
          } else {
            var model = { name: modelItem.name };
            model[availableFieldName] = true;

            newCollection.push(model);
          }
        });

        return newCollection;
      }

      function _mapLeaseLengthsToUpchargeModel(theArray, modelCollection) {
        // map stored lease length options to whichever structure is displayed
        // based on QUOTE_UPCHARGES feature flag; this function will handle
        // mapping in either direction if the flag is toggled on or off
        let newCollection = [];

        if (
          isUndefined(theArray) ||
          theArray === null ||
          theArray.length === 0
        ) {
          return modelCollection;
        }

        modelCollection.forEach(function (modelItem) {
          let newModelItem = modelItem;

          const oldLeaseLength = find(theArray, function (arrayItem) {
            return arrayItem.leaseLength === newModelItem.leaseLength;
          });

          if (oldLeaseLength && oldLeaseLength.isAvailable) {
            newModelItem.isAvailable = true;
          }
          if (
            oldLeaseLength &&
            oldLeaseLength.hasOwnProperty('upchargeAmount') &&
            newModelItem.hasOwnProperty('upchargeAmount')
          ) {
            newModelItem.upchargeAmount = oldLeaseLength.upchargeAmount;
          }
          newCollection.push(newModelItem);
        });

        return newCollection;
      }

      return CommunityModelService;
    }
  ]);
})();
