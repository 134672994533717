import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import './_TablePaginationControls.scss';

export const getMaxPageCount = (totalRows: number, rowsPerPage: number) => {
  return Math.ceil(totalRows / rowsPerPage);
};

interface ResultsNavigationProps {
  onChangeResultsPageNavigation(value: number): void;
  pageNumber: number;
  rowsPerPage: number;
  totalRows: number;
}

const ResultsNavigation: FC<ResultsNavigationProps> = ({
  onChangeResultsPageNavigation,
  pageNumber,
  rowsPerPage,
  totalRows
}) => {
  const handleOnClick = (increment: number) => () => {
    onChangeResultsPageNavigation(pageNumber + increment);
  };

  return (
    <span className="results-navigation">
      <IconButton disabled={pageNumber === 1} onClick={handleOnClick(-1)}>
        <ChevronLeft fontSize="large" />
      </IconButton>
      <IconButton
        disabled={totalRows <= pageNumber * rowsPerPage}
        onClick={handleOnClick(1)}
      >
        <ChevronRight fontSize="large" />
      </IconButton>
    </span>
  );
};

export default ResultsNavigation;
