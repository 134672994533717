/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('knockKanban', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/kanban/knock-kanban.html',
      controller: 'KnockKanbanController',
      transclude: true,
      replace: true
    };
  });

  const KnockKanbanController = function ($scope) {
    var self = this;
  };

  KnockKanbanController.$inject = ['$scope'];

  app.controller('KnockKanbanController', KnockKanbanController);
})(window.angular);
