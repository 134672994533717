import React, { FC, useState, useEffect } from 'react';
import { Chip, Theme } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ExpandMore } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import classnames from 'classnames';

import { Box, makeStyles, NamedColors } from '@knockrentals/knock-shared-web';
import { colors, useCommonStyles } from '../../commonStyles/commonStyles';

const useStyles = makeStyles((theme: Theme) => ({
  dateFieldContainer: {
    display: 'flex',
    gap: '8px'
  },

  datePicker: {
    marginTop: 0,

    '& .MuiInputLabel-formControl': {
      color: colors.secondaryText,
      fontSize: '12px',
      fontWeight: 400,
      left: 0,
      marginBottom: 0
    },

    '& .MuiInputBase-formControl': {
      borderColor: NamedColors.slate[300]
    },

    '& .MuiInputBase-root.Mui-focused': {
      borderColor: NamedColors.denim[300]
    },

    '& .MuiInputBase-root': {
      color: theme.palette.text.primary
    },

    '& .MuiIconButton-label': {
      color: NamedColors.slate[800]
    },

    '& .MuiFormHelperText-root': {
      marginLeft: '4px'
    }
  },

  rangeOptions: {
    marginTop: '16px'
  }
}));

export interface DateRangeFilterOption {
  label: string;
  value: string;
  default?: boolean;
}

export interface DateRangeParameters {
  startDate: Date | null;
  endDate: Date | null;
  range: string;
}

interface SelectDateRangeProps {
  startDate: Date | null;
  endDate: Date | null;
  range: string;
  rangeOptions: DateRangeFilterOption[];
  onChange: (data: DateRangeParameters) => void;
  className?: string;
  resetDateError?: boolean;
  startDateMessageError?: string;
  endDateMessageError?: string;
  allowEmptyDates?: boolean;
  id?: string;
}

const SelectDateRange: FC<SelectDateRangeProps> = ({
  startDate,
  endDate,
  range,
  rangeOptions,
  onChange,
  className,
  resetDateError,
  startDateMessageError,
  endDateMessageError,
  allowEmptyDates
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    if (date || (allowEmptyDates && date === null)) {
      setStartDateError(false);
    } else {
      setStartDateError(true);
    }

    onChange({
      startDate: date,
      endDate,
      range
    });
  };
  useEffect(() => {
    if (resetDateError) {
      setStartDateError(false);
      setEndDateError(false);
    }
  }, [resetDateError]);

  const handleEndDateChange = (date: MaterialUiPickersDate) => {
    if (date || (allowEmptyDates && date === null)) {
      setEndDateError(false);
    } else {
      setEndDateError(true);
    }

    onChange({
      startDate,
      endDate: date,
      range
    });
  };

  const handleRangeChange = (option: string) => {
    setStartDateError(false);
    setEndDateError(false);

    onChange({
      startDate: null,
      endDate: null,
      range: option
    });
  };

  return (
    <Box
      className={className ? className : undefined}
      data-testid="date-range-container"
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box className={classes.dateFieldContainer}>
          <KeyboardDatePicker
            margin="normal"
            label="Start Date"
            format="MM/dd/yyyy"
            error={startDateError || !!startDateMessageError}
            helperText={
              startDateMessageError !== undefined
                ? startDateMessageError
                : startDateError
                ? 'Please specify a start date'
                : ''
            }
            value={startDate}
            keyboardIcon={<ExpandMore />}
            KeyboardButtonProps={{
              size: 'small',
              'aria-label': 'start date'
            }}
            InputLabelProps={{
              shrink: true
            }}
            className={classes.datePicker}
            onChange={handleStartDateChange}
            inputProps={{ 'data-testid': 'start-date' }}
          />

          <KeyboardDatePicker
            margin="normal"
            label="End Date"
            format="MM/dd/yyyy"
            error={endDateError || !!endDateMessageError}
            helperText={
              endDateMessageError !== undefined
                ? endDateMessageError
                : endDateError
                ? 'Please specify an end date'
                : ''
            }
            value={endDate}
            keyboardIcon={<ExpandMore />}
            KeyboardButtonProps={{
              size: 'small',
              'aria-label': 'end date'
            }}
            InputLabelProps={{
              shrink: true
            }}
            className={classes.datePicker}
            onChange={handleEndDateChange}
            inputProps={{ 'data-testid': 'end-date' }}
          />
        </Box>
      </MuiPickersUtilsProvider>

      {rangeOptions.length > 0 && (
        <Box className={classes.rangeOptions}>
          {rangeOptions.map((option: DateRangeFilterOption) => {
            return (
              <Chip
                key={option.value}
                label={option.label}
                clickable={true}
                className={classnames(
                  commonClasses.chip,
                  option.value === range ? commonClasses.selectedChip : ''
                )}
                data-testid={`${option.label}-date-option`}
                onClick={() => handleRangeChange(option.value)}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default SelectDateRange;
