import React from 'react';

export const MenuBurgerIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.29163 15.2083H17.7083M2.29163 10.2083H17.7083M2.29163 5.20833H17.7083"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
