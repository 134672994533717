/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('listingIndexRow', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/listingIndex/listing-index-row.html',
      scope: {
        listing: '=',
        hideResidentsForMultiFamily: '=',
        onDeactivate: '=',
        onStatusChange: '=',
        onTransfer: '=',
        teamMembers: '=',
        activeManager: '=',
        coverPhotoPicker: '=',
        companyPreferences: '='
      },
      controller: 'ListingIndexRowController'
    };
  });

  app.controller('ListingIndexRowController', [
    '$rootScope',
    '$scope',
    '$q',
    '$modal',
    '$mdDialog',
    '$location',
    'userService',
    'timeService',
    '$mdToast',
    '$mdMenu',
    '$moment',
    'listingApi',
    'residentsApi',
    'communityApi',
    'shareApi',
    'listingResidentsModalFactory',
    'editFloorplanModalService',
    'screeningModalFactory',
    'apiEvents',
    'primeTimeDialogFactory',
    'trafficReportInfoModalFactory',
    'prospectHistoryService',
    function (
      $rootScope,
      $scope,
      $q,
      $modal,
      $mdDialog,
      $location,
      userService,
      timeService,
      $mdToast,
      $mdMenu,
      $moment,
      listingApi,
      residentsApi,
      communityApi,
      shareApi,
      listingResidentsModalFactory,
      editFloorplanModalService,
      screeningModalFactory,
      apiEvents,
      primeTimeDialogFactory,
      trafficReportInfoModalFactory,
      prospectHistoryService
    ) {
      var self = this;

      $scope.manager = {
        active: $scope.activeManager
      };

      $scope.FACEBOOK_SHARE_URL =
        'https://www.facebook.com/sharer/sharer.php?u=';
      $scope.TWITTER_SHARE_URL = 'http://twitter.com/share?url=';
      $scope.LINKEDIN_SHARE_URL =
        'http://www.linkedin.com/shareArticle?mini=true&url=';
      $scope.GOOGLE_SHARE_URL = 'https://plus.google.com/share?url=';

      $scope.originalActiveManagerId = $scope.activeManager.managerId;

      $scope.coverPhoto = $scope.listing.coverPhoto || $scope.listing.photos[0];

      $scope.isExternalFeed = !isEmpty($scope.listing.external);

      var hasLastActivated =
        $scope.listing.status_updates &&
        $scope.listing.status_updates.last_activated;

      $scope.activatedTime = hasLastActivated
        ? $scope.listing.status_updates.last_activated
        : $scope.listing.debug.created_time;

      var residentUpdatedHandler = $rootScope.$on(
        prospectHistoryService.events.residentUpdated,
        function (event, resident) {
          if (resident.listing_id === $scope.listing.id) {
            self._setListingInfoIcons();
          }
        }
      );

      var residentDeletedHandler = $rootScope.$on(
        prospectHistoryService.events.residentDeleted,
        function (event, resident) {
          if (resident.listing_id === $scope.listing.id) {
            self._setListingInfoIcons();
          }
        }
      );

      var availableTimeUpdatedHandler = $rootScope.$on(
        editFloorplanModalService.events.availableTimeUpdated,
        function (event, updatedListing) {
          if (
            updatedListing.id === $scope.listing.id &&
            updatedListing.leasing.availableDate !==
              $scope.listing.leasing.availableDate
          ) {
            self._getAvailableDate(updatedListing.leasing.availableDate);
          }
        }
      );

      $scope.$on('$destroy', function () {
        residentUpdatedHandler();
        residentDeletedHandler();
        availableTimeUpdatedHandler();
      });

      $scope.setPendingStatus = function (status) {
        $scope.listing.status = status;

        listingApi
          .setListingInfoStatus($scope.listing.id, status)
          .success(function () {
            $mdToast.showSimple('Set listing status to ' + status);
            $rootScope.$emit(apiEvents.listingInfoPendingStatusChanged, status);
            $scope.onStatusChange();
          });
      };

      self._initialize = function () {
        $scope.showInterestedRenters = false;

        self._makeShortLink();
        self._setListingInfoIcons();
        self._getAvailableDate($scope.listing.leasing.availableDate);
      };

      self._getAvailableDate = function (availableDate) {
        var availableDateMoment = $moment(availableDate);

        if (availableDateMoment.isBefore(timeService.now())) {
          $scope.availableDate = 'Today';
        } else {
          $scope.availableDate = availableDateMoment.format('M/D');
        }
      };

      self._getPrimeTimeCount = function () {
        listingApi
          .getListingPrimeTimeCount($scope.listing.id)
          .success(function (response) {
            $scope.listing.hasPrimeTime = response.prime_time_count > 0;
          });
      };

      self._makeShortLink = function () {
        // If we haven't generated a short link for this listing before, create one.
        if ($scope.listing.social.shortlink) {
          $scope.shortLink = $scope.listing.social.shortlink;
        } else {
          shareApi
            .makeListingShortLink($scope.listing.id)
            .success(function (res) {
              $scope.shortLink = res.link.url;
            });
        }

        if (
          $scope.listing.social.community_shortlink &&
          $scope.listing.community_id
        ) {
          $scope.communityShortLink = $scope.listing.social.community_shortlink;
        }
      };

      self._setListingInfoIcons = function () {
        residentsApi
          .getListingResidents($scope.listing.id, 'subscribed')
          .then(function (response) {
            $scope.listingHasResidents = response.data.residents.length > 0;
          });
      };

      $scope.openScreeningModal = function () {
        screeningModalFactory.openScreeningModal();
      };

      $scope.toggleShowListingProspects = function () {
        $scope.showListingProspects = !$scope.showListingProspects;
      };

      $scope.editListing = function ($event) {
        if ($scope.listing.floorplan.community_id) {
          editFloorplanModalService
            .openModal($event, JSON.parse(JSON.stringify($scope.listing)))
            .then(function (listing) {
              $scope.listing = listing;
            });
        } else {
          var url = '/listing/' + $scope.listing.id + '/edit';
          $location.url(url);
        }
      };

      $scope.shareListing = function () {
        $modal.open({
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl:
            '/angular/views/listing/listingIndex/shareListingModal.html',
          controller: 'ShareListingModalController',
          size: 'sm',
          resolve: {
            listing: function () {
              return $scope.listing;
            },
            manager: function () {
              return userService.getUser();
            }
          }
        });
      };

      $scope.socialMediaShareListing = function (
        width,
        height,
        baseURL,
        listingURL
      ) {
        var leftPosition, topPosition;
        leftPosition = window.screen.width / 2 - (width / 2 + 10);
        topPosition = window.screen.height / 2 - (height / 2 + 50);
        var windowFeatures =
          'status=no,height=' +
          height +
          ',width=' +
          width +
          ',resizable=yes,left=' +
          leftPosition +
          ',top=' +
          topPosition +
          ',screenX=' +
          leftPosition +
          ',screenY=' +
          topPosition +
          ',toolbar=no,menubar=no,scrollbars=no,location=no,directories=no';
        window.open(baseURL + listingURL, 'sharer', windowFeatures);
        return false;
      };

      $scope.editResidents = function () {
        listingResidentsModalFactory
          .openModal($scope.listing.id)
          .then(function (residents) {
            $scope.listingHasResidents = residents.length > 0;
          });
      };

      $scope.shareListingCraigslist = function () {
        $modal.open({
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl:
            '/angular/views/listing/listingIndex/craigslist-share-modal.html',
          controller: 'CraigslistShareModalController',
          size: 'lg',
          resolve: {
            listing: function () {
              return $scope.listing;
            }
          }
        });
      };

      $scope.deactivateListing = function () {
        var deactivateListingPromise = $mdDialog.show({
          templateUrl:
            '/angular/views/listing/directives/deactivate-listing-dialog.html',
          controller: 'DeactivateListingDialogController',
          locals: {
            listing: $scope.listing
          }
        });

        deactivateListingPromise.then($scope.onDeactivate);
      };

      $scope.editPrimeTimes = function () {
        primeTimeDialogFactory
          .openPrimeTimeDialog($scope.listing)
          .finally(self._getPrimeTimeCount);
      };

      $scope.updateSubscribers = function ($event) {
        trafficReportInfoModalFactory.openModal($event, $scope.listing);
      };

      $scope.generateListingPDF = function () {
        window.open($scope.listing.pdf);
      };

      $scope.openCoverPicker = function () {
        $scope.coverPhotoPicker.images = $scope.listing.photos;
        $scope.coverPhotoPicker.isVisible = true;

        $scope.coverPhotoPicker.onImagePicked = $scope.onImagePicked;
      };

      $scope.onImagePicked = function (image) {
        $scope.isSavingCoverPhoto = true;
        $scope.listing.coverPhoto = image;

        listingApi
          .updateListing($scope.listing.id, $scope.listing)
          .success(function () {
            $scope.coverPhoto = $scope.listing.coverPhoto;
          })
          .finally(function () {
            $scope.isSavingCoverPhoto = false;
          });
      };

      $scope.fullName = function (user) {
        return user.info.first_name + ' ' + user.info.last_name;
      };

      $scope.$watch(
        'manager.active',
        function (newActiveManager, oldActiveManager) {
          if (_activeManagerChanged(newActiveManager, oldActiveManager)) {
            $mdMenu.hide();
            self._tryChangeActiveManager(oldActiveManager, newActiveManager);
          }
        }
      );

      function _activeManagerChanged(newActiveManager, oldActiveManager) {
        return (
          newActiveManager &&
          oldActiveManager &&
          $scope.originalActiveManagerId !== newActiveManager.managerId
        );
      }

      self._tryChangeActiveManager = function (
        oldActiveManager,
        newActiveManager
      ) {
        $modal.open({
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl:
            '/angular/views/listing/listingIndex/transfer-listing-modal.html',
          controller: 'TransferListingModalController',
          size: 'sm',
          backdrop: 'static',
          resolve: {
            listing: function () {
              return $scope.listing;
            },
            oldActiveManagerId: function () {
              return oldActiveManager.managerId;
            },
            newActiveManagerId: function () {
              return newActiveManager.managerId;
            },
            onCancel: function () {
              return function () {
                $scope.manager.active = oldActiveManager;
              };
            },
            onSuccess: function () {
              return function (response) {
                $scope.originalActiveManagerId = newActiveManager.managerId;

                var args = {
                  listingId: response.listing_id,
                  newManagerId: response.new_manager_id,
                  oldManagerId: oldActiveManager.managerId
                };

                $scope.$emit(apiEvents.changedListingManager, args);
                $scope.onTransfer();
              };
            }
          }
        });
      };

      self._initialize();
    }
  ]);
})(window.angular);
