/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import includes from 'lodash/includes';
import every from 'lodash/every';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';
import forEachRight from 'lodash/forEachRight';
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('conversation', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/conversation.html',
      controller: 'ConversationController',
      replace: true,
      scope: {
        conversation: '=',
        prospect: '=',
        resident: '=?',
        teamMembers: '=',
        managerCommunityIds: '='
      }
    };
  });

  app.controller('ConversationController', [
    '$window',
    '$rootScope',
    '$timeout',
    '$scope',
    '$mdDialog',
    'conversationsService',
    'chatTrackingService',
    'conversationPresenceService',
    'userInteractionsService',
    'addAttachmentDialogFactory',
    'streamCarouselService',
    'voiceApi',
    '$moment',
    'ngAudio',
    'uploadService',
    'api',
    'userService',
    '_',
    'prospectHistoryService',
    function (
      $window,
      $rootScope,
      $timeout,
      $scope,
      $mdDialog,
      conversationsService,
      chatTrackingService,
      conversationPresenceService,
      userInteractionsService,
      addAttachmentDialogFactory,
      streamCarouselService,
      voiceApi,
      $moment,
      ngAudio,
      uploadService,
      api,
      userService,
      _,
      prospectHistoryService
    ) {
      var self = this;

      chatTrackingService.hide();
      $rootScope.$on('SalesForceWidgetMounted', () => {
        chatTrackingService.hide();
      });

      $scope.$watch(
        'conversation',
        function (newConversation, oldConversation) {
          if (newConversation && oldConversation) {
            self._initialize();
          }
        }
      );

      var newMessageHandler = $rootScope.$on(
        conversationsService.events.newMessage,
        function (event, newMessage) {
          self._onNewMessage(newMessage);
        }
      );

      var newTeamMessageHandler = $rootScope.$on(
        conversationsService.events.newTeamMessage,
        function (event, newMessage) {
          if (
            newMessage.sender_id !== $scope.data.thisUser.id ||
            newMessage.sender_type === $scope.data.thisUser.type
          ) {
            self._onNewMessage(newMessage);
          }
        }
      );

      var startTypingHandler = $rootScope.$on(
        conversationsService.events.startTyping,
        function (event, participant) {
          if (
            participant.type !== 'manager' ||
            $scope.conversation.type === 'direct'
          ) {
            $scope.typingIndicators.showLeft = true;
          } else {
            $scope.typingIndicators.showRight = true;
          }

          self._scrollToBottom();
        }
      );

      var stopTypingHandler = $rootScope.$on(
        conversationsService.events.stopTyping,
        function (event, participant) {
          if (
            participant.type !== 'manager' ||
            $scope.conversation.type === 'direct'
          ) {
            $scope.typingIndicators.showLeft = false;
          } else {
            $scope.typingIndicators.showRight = false;
          }
        }
      );

      var messageStatusTimeoutPromises = {};

      var messageStatusUpdateHandler = $rootScope.$on(
        conversationsService.events.messageStatusUpdated,
        function (event, messageId, target, updatedDelivery) {
          var message = find($scope.conversation.messages, { id: messageId });
          $timeout.cancel(messageStatusTimeoutPromises[messageId]);

          if (!isUndefined(message)) {
            if (message.sender_type !== 'manager') {
              // we don't keep track of delivery statuses for non-manager messages
              // if in the future we want to do this, we'll need to look over ManagerEmailTask again
              // so that it correctly applies a `delivery` object to the message before sending it over the wire
              return;
            }

            message.delivery[target] = updatedDelivery;
            return;
          }

          self._refreshMessageStatus($scope.conversation.id, messageId, target);
        }
      );

      self._refreshMessageStatus = function (
        conversationId,
        messageId,
        target
      ) {
        // We're killing this for now. Let's see what happens.
        // var broadcastMessageStatus = function (response) {
        //     var targetDelivery = response.message.delivery[target];
        //
        //     $rootScope.$emit(conversationsService.events.messageStatusUpdated, messageId, target, targetDelivery);
        //
        //     if (targetDelivery.status !== 'sending') {
        //         $timeout.cancel(messageStatusTimeoutPromises[messageId]);
        //     }
        // };
        //
        // var getAndBroadcastMessageStatus = function () {
        //     conversationsService.getMessage(conversationId, messageId).success(broadcastMessageStatus);
        // };
        //
        // messageStatusTimeoutPromises[messageId] = $timeout(getAndBroadcastMessageStatus, 1000, true);
      };

      var presenceLoadedMembersHandler = $rootScope.$on(
        conversationPresenceService.events.subscriptionSucceeded,
        function (event, data) {
          $scope.data.userIsOnline =
            data.renterId &&
            !isEmpty(
              find(data.members.members, function (member, typeId) {
                return typeId === 'renter-' + data.renterId;
              })
            );
        }
      );

      var presenceMemberAddedHandler = $rootScope.$on(
        conversationPresenceService.events.memberAdded,
        function (event, data) {
          $scope.data.userIsOnline =
            data.member.id !== $scope.data.thisUser.type_id;
        }
      );

      var presenceMemberRemovedHandler = $rootScope.$on(
        conversationPresenceService.events.memberRemoved,
        function (event, data) {
          if (data.member.id !== $scope.data.thisUser.type_id) {
            $scope.data.userIsOnline = false;
          }
        }
      );

      var refreshConversationHandler = $rootScope.$on(
        conversationsService.events.refreshConversation,
        function () {
          self._reloadConversation();
        }
      );

      $scope.$on('$destroy', function () {
        newMessageHandler();
        newTeamMessageHandler();
        startTypingHandler();
        stopTypingHandler();
        messageStatusUpdateHandler();

        presenceLoadedMembersHandler();
        presenceMemberAddedHandler();
        presenceMemberRemovedHandler();
        refreshConversationHandler();
      });

      self._scrollToBottom = function () {
        var conversationMessagesDiv = angular.element('.conversation-messages');

        $timeout(
          function () {
            conversationMessagesDiv.scrollTop(
              conversationMessagesDiv.prop('scrollHeight') * 100
            );
          },
          100,
          true
        );
      };

      self._reloadConversation = function () {
        conversationsService
          .getConversation($scope.conversation.id)
          .success(self._reloadConversationSuccess);
      };

      self._reloadConversationSuccess = function (response) {
        $scope.conversation = response.stream;

        self._findFirstMessage();
        self._processTimestamps();

        const currentCarousel = streamCarouselService.state;

        if (
          currentCarousel &&
          currentCarousel.streamIds &&
          isArray(currentCarousel.streamIds) &&
          currentCarousel.streamIds.length > 1
        ) {
          streamCarouselService.state.streamData = $scope.conversation;
        }
      };

      $scope.data = {
        chatAreaHasFocus: true,
        replyingTo: null,
        messageText: '',
        messageSubject: '',
        thisUser: null,
        participantsById: null,
        isTyping: false,
        isSendingMessage: false,
        inTransitMessages: [],
        userIsOnline: false,
        attachments: [],
        sendAsManagerId: undefined
      };

      $scope.typingIndicators = {
        showLeft: false,
        showRight: false
      };

      $scope.retryTarget = function (message, target) {
        if (!message.delivery[target].can_retry) {
          return;
        }

        message.delivery[target].isRetrying = true;

        conversationsService
          .retryMessage(message, target)
          .success(function (response) {
            message.delivery[target].status =
              response.message.delivery[target].status;
          })
          .finally(function () {
            message.delivery[target].isRetrying = false;
          });
      };

      $scope.targetFailed = function (message, target) {
        return message.delivery[target].status === 'failed';
      };

      var stopTypingTimeout = null;

      $scope.setReplyingTo = function (message) {
        $scope.data.replyingToMessage = message;

        if (message) {
          var subject = message.subject || $scope.conversation.subject;
          subject = subject.indexOf('Re: ') === 0 ? subject : 'Re: ' + subject;
          $scope.data.messageSubject = subject;
        }
      };

      $scope.fireStartTyping = function () {
        if (!$scope.conversation) {
          return;
        }

        if (!$scope.data.isTyping) {
          $scope.data.isTyping = true;
          conversationsService.sendStartTyping();
        } else {
          $timeout.cancel(stopTypingTimeout);
        }

        stopTypingTimeout = $timeout(
          function () {
            $scope.data.isTyping = false;
            conversationsService.sendStopTyping();
          },
          1000,
          true
        );
      };

      $scope.sendMessage = function (target, emailRecipients) {
        self._prepareSendingMessage();

        $scope.data.messageHtml = null;

        if (target === 'email') {
          $scope.data.messageHtml = $scope.data.messageText;
        }

        var replyingToMessageId = $scope.data.replyingToMessage
          ? $scope.data.replyingToMessage.id
          : null;

        conversationsService
          .sendMessage(
            $scope.conversation.id,
            $scope.data.messageText,
            $scope.data.messageHtml,
            $scope.data.messageSubject,
            $scope.data.attachments,
            [target],
            $scope.data.sendAsManagerId || $scope.data.thisUser.id,
            replyingToMessageId,
            emailRecipients
          )
          .then(self._sendMessageSuccess)
          .finally(function () {
            $scope.data.isSendingMessage = false;

            if (target === 'email') {
              prospectHistoryService
                .updateProspect({
                  id: $scope.prospect.id,
                  ai_email_enabled: false
                })
                .then(function () {
                  $scope.data.initialAiEmailEnabled = false;
                });
            }

            if (
              $scope.data.otherUser &&
              $scope.data.otherUser.type !== 'manager'
            ) {
              conversationsService.clearDraft(
                $scope.data.otherUser.type,
                $scope.data.otherUser.id,
                target
              );

              self._updateEngagementSettings();
            }
          });

        $scope.data.messageText = '';
      };

      $scope.sendMessageSuccess = function (response) {
        self._sendMessageSuccess(response.data);
      };

      self._updateEngagementSettings = function () {
        if (
          $scope.data.otherUser.type === 'prospect' &&
          $scope.prospect.enable_cheatproof_engagement_score
        ) {
          conversationsService
            .getProspectEngagementSettings($scope.data.otherUser.id)
            .then(function (response) {
              $scope.prospect.disable_follow_ups =
                response.data.engagement_settings.disable_follow_ups;
              $scope.prospect.disable_is_excluded =
                response.data.engagement_settings.disable_is_excluded;
              $scope.prospect.disable_lost_status =
                response.data.engagement_settings.disable_lost_status;
            });
        }
      };
      $rootScope.$on('setSendAsManagerId', function (event, data) {
        $scope.data.sendAsManagerId = data.userId;
        if (data.applyTrigger) {
          $scope.$apply(() => {
            $scope.showMessagingAs = self._showMessagingAs();
          });
        } else {
          $scope.showMessagingAs = self._showMessagingAs();
        }
      });
      $rootScope.$on(
        'prospectTransferred',
        function (event, prospectId, newManagerId) {
          return userInteractionsService
            .getManager(newManagerId)
            .then(function (response) {
              var manager = response.data.info[0];

              $scope.data.thisUser = {
                info: {
                  first_name: manager.first_name,
                  last_name: manager.last_name,
                  photo: manager.photo
                },
                id: manager.manager_id,
                type: 'manager',
                type_id: 'manager-' + manager.manager_id
              };
            })
            .finally(function () {
              $scope.showMessagingAs = self._showMessagingAs();
            });
        }
      );

      self._prepareSendingMessage = function () {
        $scope.data.isSendingMessage = true;
        $scope.data.inTransitMessages.push({
          id: 'transit',
          sender_id: $scope.data.thisUser.id,
          sender_type: $scope.data.thisUser.type,
          created_time: $moment().format(),
          type: 'chat',
          text: $scope.data.messageText,
          attachments: $scope.data.attachments,
          inTransit: true
        });

        self._processTimestamps();
        self._scrollToBottom();
      };

      self._sendMessageSuccess = function () {
        $scope.data.isSendingMessage = false;
        $scope.data.attachments = [];

        self._findFirstMessage();
        self._processTimestamps();
      };

      self._showMessagingAs = function () {
        return (
          $scope.currentUser.type === 'manager' &&
          parseInt($scope.data.thisUser.id) !==
            parseInt($scope.currentUser.id) &&
          parseInt($scope.data.sendAsManagerId) !==
            parseInt($scope.currentUser.id)
        );
      };

      self._initialize = function () {
        $scope.currentUser = userService.getScopedUser();

        $scope.data.thisUser = conversationsService.getMyUser(
          $scope.conversation
        );
        $scope.data.otherUser = conversationsService.getOtherUser(
          $scope.conversation
        );

        if ($scope.data.otherUser.type === 'prospect') {
          conversationsService.subscribeToInfoChannels(
            $scope.conversation.id,
            $scope.prospect.renter_id
          );
        }

        $scope.$on('getTranscribedStatus', function (e, isTranscribed) {
          $scope.isTranscribed = isTranscribed;
        });

        $scope.showMessagingAs = self._showMessagingAs();

        forEach($scope.conversation.messages, function (message) {
          if (isEmpty(message.delivery)) {
            return;
          }

          if (
            message.delivery.email &&
            message.delivery.email.status === 'sending'
          ) {
            self._refreshMessageStatus(
              $scope.conversation.id,
              message.id,
              'email'
            );
          } else if (
            message.delivery.sms &&
            message.delivery.sms.status === 'sending'
          ) {
            self._refreshMessageStatus(
              $scope.conversation.id,
              message.id,
              'sms'
            );
          }
        });

        $rootScope.$emit(
          conversationsService.events.changeInboxTab,
          $scope.conversation.type
        );

        self._findFirstMessage();
        self._processTimestamps();

        $timeout(
          function () {
            self._scrollToBottom();
          },
          1,
          true
        );

        angular.element('#chat-area').focus();
      };

      self._onNewMessage = function (newMessage) {
        var isSameThread = newMessage.stream_id === $scope.conversation.id;
        var alreadyHaveMessage = find($scope.conversation.messages, {
          id: newMessage.id
        });

        if (isSameThread && !alreadyHaveMessage) {
          $scope.conversation.messages.push(newMessage);

          if ($scope.data.inTransitMessages.length > 0) {
            $scope.data.inTransitMessages.shift();
          }

          self._scrollToBottom();

          if (newMessage.is_partial) {
            self._reloadConversation();
          } else {
            self._markAsRead();

            self._processTimestamps();
            self._findFirstMessage();
          }
        }

        if (isSameThread) {
          var deliveryTarget = isEmpty(newMessage.targets)
            ? null
            : newMessage.targets[0];

          if (
            deliveryTarget &&
            newMessage.delivery[deliveryTarget].status === 'sending'
          ) {
            self._refreshMessageStatus(
              newMessage.stream_id,
              newMessage.id,
              deliveryTarget
            );
          }
        }
      };

      self._onEditedMessage = function (editedMessage) {
        var isSameThread = editedMessage.stream_id === $scope.conversation.id;

        if (isSameThread) {
          var messageToUpdate = find($scope.conversation.messages, {
            id: editedMessage.id
          });
          assign(messageToUpdate, editedMessage);
        }
      };

      self._findFirstMessage = function () {
        var firstRenterMessage = find(
          $scope.conversation.messages,
          function (message) {
            return (
              message.sender_type !== 'manager' &&
              message.type !== 'notification'
            );
          }
        );

        var firstManagerMessage = find(
          $scope.conversation.messages,
          function (message) {
            return (
              message.sender_type === 'manager' &&
              message.type !== 'notification' &&
              message.sender_id === $scope.currentUser.id
            );
          }
        );

        var firstOtherManagerMessage = find(
          $scope.conversation.messages,
          function (message) {
            return (
              message.sender_type === 'manager' &&
              message.sender_id !== $scope.currentUser.id
            );
          }
        );

        if (firstRenterMessage) {
          $scope.firstRenterMessageId = firstRenterMessage.id;
        }
        if (firstManagerMessage) {
          $scope.firstManagerMessageId = firstManagerMessage.id;
        }
        if (firstOtherManagerMessage) {
          $scope.firstOtherManagerMessageId = firstOtherManagerMessage.id;
        }
      };

      self._markAsRead = function () {
        var ignoreUnreadCounts = true;
        $timeout(function () {
          conversationsService.markAsReadByIds(
            [$scope.conversation.id],
            ignoreUnreadCounts
          );
        }, 1000);
      };

      self._processTimestamps = function () {
        var lastTimestampTime = null;
        var lastUserId = null;
        var lastUserType = null;

        forEachRight($scope.conversation.messages, function (message) {
          if (isNull(lastTimestampTime)) {
            message.showTimestamp = true;
            lastTimestampTime = $moment(message.created_time);
            lastUserId = message.sender_id;
            lastUserType = message.sender_type;

            return;
          }

          var createdTimeMoment = $moment(message.created_time);
          var isWithinMinute =
            lastTimestampTime.diff(createdTimeMoment, 'seconds') <= 60;
          var isSameUser =
            lastUserId === message.sender_id &&
            lastUserType === message.sender_type;

          var messageWasDelivered = true;

          if (!isEmpty(message.targets)) {
            var targets = filter(message.targets, function (target) {
              return message.delivery && message.delivery[target];
            });

            var deliveryStatuses = map(targets, function (target) {
              return message.delivery[target].status;
            });

            messageWasDelivered = every(deliveryStatuses, function (status) {
              return includes(['delivered', 'sent'], status);
            });
          }

          message.showTimestamp =
            !messageWasDelivered || !isWithinMinute || !isSameUser;
          lastTimestampTime = createdTimeMoment;
          lastUserId = message.sender_id;
          lastUserType = message.sender_type;
        });
      };

      self._initialize();
    }
  ]);

  app.filter('links', [
    'MessageFormatterService',
    function (MessageFormatterService) {
      return function (input) {
        return MessageFormatterService.convertToFriendlyUrl(input, false);
      };
    }
  ]);

  app.filter('lineBreaks', function () {
    return function (input) {
      var lineBreakRegex = /(?:\r\n|\r|\n)/g;
      var replacement = '<br />';
      return input ? input.replace(lineBreakRegex, replacement) : '';
    };
  });
})(window.angular);
