/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('analyticsApi', [
    'apiBase',
    function (apiBase) {
      return {
        getSubscribers: function (listingId) {
          return apiBase.get('/analytics/report/' + listingId + '/subscribers');
        },
        createSubscriber: function (listingId, subscriber) {
          return apiBase.post(
            '/analytics/report/' + listingId + '/subscriber',
            subscriber
          );
        },
        updateSubscriber: function (listingId, subscriberId, subscriber) {
          return apiBase.put(
            '/analytics/report/' + listingId + '/subscriber/' + subscriberId,
            subscriber
          );
        },
        deleteSubscriber: function (listingId, subscriberId) {
          return apiBase.delete(
            '/analytics/report/' + listingId + '/subscriber/' + subscriberId
          );
        },
        getGuestToken: function () {
          return apiBase.get('/me/analytics/guest_token');
        }
      };
    }
  ]);
})();
