(function (angular) {
  const app = angular.module('knockApp');

  app.directive('prospectDetailsCustomFields', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/prospects/prospect-details-custom-fields.html',
      scope: {
        fields: '=',
        prospect: '='
      },
      controller: [
        '$scope',
        function ($scope) {
          $scope.setCustomFieldCheckboxValue = function (fieldId, fieldValue) {
            if ($scope.prospect.stored_custom_fields[fieldId]) {
              const currentSelected = $scope.prospect.stored_custom_fields[
                fieldId
              ].value.find((fieldData) => fieldData === fieldValue);
              if (currentSelected) {
                $scope.prospect.stored_custom_fields[fieldId].value =
                  $scope.prospect.stored_custom_fields[fieldId].value.filter(
                    (fieldData) => fieldData !== fieldValue
                  );
              } else {
                $scope.prospect.stored_custom_fields[fieldId].value = [
                  ...$scope.prospect.stored_custom_fields[fieldId].value,
                  fieldValue
                ];
              }
            } else {
              $scope.setCustomFieldValue(fieldId, [fieldValue]);
            }
          };

          $scope.setCustomFieldValue = function (fieldId, fieldValue) {
            $scope.prospect.stored_custom_fields[fieldId] = {
              field_id: fieldId,
              value: fieldValue
            };
          };

          $scope.customFieldCheckboxStatus = function (fieldId, choiceValue) {
            return !!(
              $scope.prospect.stored_custom_fields &&
              $scope.prospect.stored_custom_fields[fieldId] &&
              !!~$scope.prospect.stored_custom_fields[fieldId].value.indexOf(
                choiceValue
              )
            );
          };

          $scope.$on(
            'setInitialStoredProspectValue',
            function (e, fieldId, fieldValue) {
              $scope.setCustomFieldValue(fieldId, fieldValue);
            }
          );
        }
      ]
    };
  });
})(window.angular);
