/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('choosePropertyDialogFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openChoosePropertyDialog = function () {
        return $mdDialog.show({
          controller: 'ChoosePropertyController',
          templateUrl:
            '/angular/views/chooseProperty/choose-property-dialog.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true
        });
      };

      return self;
    }
  ]);

  const ChoosePropertyController = function (
    $scope,
    $q,
    $mdDialog,
    listingApi,
    appDataService
  ) {
    var self = this;

    self._initialize = function () {
      $scope.teamMembers = appDataService.getTeamMembers();

      var initPromises = [self._getListingsByManager()];

      $q.all(initPromises).finally(function () {
        $scope.isLoaded = true;
      });
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.selectListing = function (listing) {
      $mdDialog.hide(listing);
    };

    self._getListingsByManager = function () {
      return listingApi.getMyActiveListings().success(function (response) {
        $scope.listingsByManager = response.listings;
      });
    };

    self._initialize();
  };

  ChoosePropertyController.$inject = [
    '$scope',
    '$q',
    '$mdDialog',
    'listingApi',
    'appDataService'
  ];

  app.controller('ChoosePropertyController', ChoosePropertyController);
})(window.angular);
