import {
  AvailableQuickReply,
  CompanyQuickReply,
  ManagerInfo,
  ManagerQuickReply,
  QuickReply
} from '../components/QuickReplies/models';

export const getEmptyQuickReply = (managerInfo: ManagerInfo): QuickReply => {
  return {
    manager_id: managerInfo.id,
    manager_name: managerInfo.name,
    message: '',
    message_id: '',
    message_sms: '',
    message_to_display: '',
    photo: '',
    title: ''
  };
};

export const processEmailMessage = (msg: string = '') => {
  if (!msg) {
    return msg;
  }

  const doc = new DOMParser().parseFromString(msg, 'text/html');
  if (Array.from(doc.body.childNodes).some((node) => node.nodeType === 1)) {
    // don't update contents if contents already contain HTML
    return msg;
  }

  // if contents don't contain HTML replace newlines with br tags to preserve formatting
  return msg.replace(/(?:\r\n|\r|\n)/g, '<br />');
};

export const getCleanMessage = (msg?: string) => {
  let cleanMsg = '';

  if (msg) {
    const doc = new DOMParser().parseFromString(msg, 'text/html');
    cleanMsg = doc.body.textContent || '';
  }

  return cleanMsg.trim();
};

export const getAvailableQuickReplies = (
  quick_replies: ManagerQuickReply[],
  messageMode: string
): AvailableQuickReply[] => {
  const concatMessages = quick_replies.reduce(
    (map: QuickReply[], element: ManagerQuickReply) => {
      return map.concat(
        element.messages.filter((item) => {
          return (
            (messageMode === 'email' && item.message) ||
            (messageMode === 'sms' && item.message_sms)
          );
        })
      );
    },
    []
  );

  const finalQuickReplies = concatMessages.map((item: AvailableQuickReply) => {
    item.message = processEmailMessage(item.message);
    return {
      ...item,
      message_to_display:
        messageMode === 'email'
          ? getCleanMessage(item.message)
          : item.message_sms
    };
  });

  return finalQuickReplies;
};

export const getAvailableCompanyQuickReplies = (
  companyQuickReplies: CompanyQuickReply[] = [],
  isEmailMode: boolean
): AvailableQuickReply[] =>
  companyQuickReplies.reduce(
    (replies: AvailableQuickReply[], companyQuickReply: CompanyQuickReply) => {
      if (isEmailMode) {
        companyQuickReply.message = processEmailMessage(
          companyQuickReply.message
        );
      }

      const messageContent = isEmailMode
        ? companyQuickReply.message
        : companyQuickReply.message_sms;

      if (messageContent) {
        replies.push({
          ...companyQuickReply,
          message_to_display: isEmailMode
            ? getCleanMessage(messageContent)
            : messageContent
        });
      }

      return replies;
    },
    []
  );
