/* Inject interceptors into the $httpProvider so we can catch anything
going over the network for logging, injection, etc */
export default function configureNetworkInterception(app: any) {
  app.config([
    '$httpProvider',
    function ($httpProvider: any) {
      $httpProvider.interceptors.push('cacheInterceptor');
      $httpProvider.interceptors.push('apiLoggingInterceptor');
      $httpProvider.interceptors.push('authAsLeasingTeamInterceptor');
    }
  ]);
}
