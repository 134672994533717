import React, { FC } from 'react';
import { Card, CardContent, Chip, Button } from '@material-ui/core';
import dayjs from 'dayjs';

import {
  makeStyles,
  Box,
  Text,
  NamedColors
} from '@knockrentals/knock-shared-web';
import {
  MoneyIcon,
  BedIcon,
  BathIcon,
  RealEstateIcon,
  AvailableIcon,
  UnitLinkIcon,
  QuoteIcon
} from '../../icons';
import { UnitType } from './types';
import {
  displayPrice,
  differnceInDateFromToday
} from '../../../utilities/userUtilities';
import { colors } from '../../commonStyles/commonStyles';
import LabelWithIcon from './LabelWithIcon';
import TooltipContainer from '../../molecules/TooltipContainer/TooltipContainer';

const useStyles = makeStyles({
  card: {
    width: '298px',
    boxShadow: 'none',
    border: `1px solid ${NamedColors.slate[200]}`,
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '8px'
    }
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  flexBox: {
    display: 'flex'
  },
  contentBox: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    marginTop: '16px'
  },
  goodMatchChip: {
    background: '#DCF6EE',
    color: NamedColors.grass[900],
    fontSize: '13px',
    letterSpacing: '0.16px'
  },
  greatMatchChip: {
    background: '#DBF0FE',
    color: NamedColors.sky[900],
    fontSize: '13px',
    letterSpacing: '0.16px'
  },
  iconButton: {
    padding: 0
  },
  unitName: {
    color: colors.defaultText,
    fontSize: '14px',
    fontWeight: 600,
    marginTop: '10px'
  },
  actionBox: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '10px'
  },

  actionButton: {
    '& .MuiButton-label': {
      display: 'flex',
      gap: '6px',
      color: NamedColors.denim[600],
      fontSize: '13px',
      fontWeight: 600
    },

    '&.MuiButton-root:hover': {
      backgroundColor: NamedColors.denim[50]
    }
  },

  propertyName: {
    color: '#999',
    fontSize: '12px'
  }
});

interface UnitCardProps {
  unit: UnitType;
  onCopyUnitLink: (unit: UnitType) => void;
  onCreateQuote: (unit: UnitType) => void;
}

const UnitCard: FC<UnitCardProps> = ({
  unit,
  onCopyUnitLink,
  onCreateQuote
}) => {
  const classes = useStyles();
  const price =
    unit.displayPrice && Number(unit.displayPrice) > 0
      ? displayPrice(Number(unit.displayPrice))
      : '';

  const showAvailableDate = (date: string) => {
    if (differnceInDateFromToday(date) <= 0) {
      return 'Available Now';
    } else {
      return `Available ${dayjs(date).format('MM/DD/YYYY')}`;
    }
  };

  let availableDate = '';

  if (unit.availableOn) {
    availableDate = showAvailableDate(unit.availableOn);
  }

  const quoteActionTooltip = unit.hasGuestCard
    ? 'Create Quote'
    : 'Refer in order to create a quote';

  return (
    <Card className={classes.card} data-testid="unit-card">
      <CardContent>
        <Box className={classes.headerBox}>
          <Box className={classes.flexBox}>
            <Box>
              <Text variant="body2" className={classes.propertyName}>
                {unit.propertyName}
              </Text>
              <Text className={classes.unitName}>{unit.name}</Text>
            </Box>
          </Box>
          {unit.preferenceMatchCount === 4 && (
            <Chip className={classes.greatMatchChip} label="Great Match"></Chip>
          )}
          {(unit.preferenceMatchCount === 3 ||
            unit.preferenceMatchCount === 2) && (
            <Chip className={classes.goodMatchChip} label="Good Match"></Chip>
          )}
        </Box>
        <Box className={classes.contentBox}>
          {price && (
            <Box className={classes.flexBox}>
              <LabelWithIcon icon={<MoneyIcon />} label={price} />
            </Box>
          )}
          {unit.bedrooms && (
            <Box className={classes.flexBox}>
              <LabelWithIcon
                icon={<BedIcon />}
                label={
                  unit.bedrooms === 1
                    ? `${unit.bedrooms} bed`
                    : `${unit.bedrooms} beds`
                }
              />
            </Box>
          )}
          {unit.bathrooms && (
            <Box className={classes.flexBox}>
              <LabelWithIcon
                icon={<BathIcon />}
                label={`${unit.bathrooms} bath`}
              />
            </Box>
          )}
          {unit.area && (
            <Box className={classes.flexBox}>
              <LabelWithIcon
                icon={<RealEstateIcon />}
                label={`${unit.area} Sqft`}
              />
            </Box>
          )}
          {availableDate && (
            <Box className={classes.flexBox}>
              <LabelWithIcon icon={<AvailableIcon />} label={availableDate} />
            </Box>
          )}
        </Box>
        <Box className={classes.actionBox}>
          <TooltipContainer tooltip="Copy Unit Link">
            <Button
              data-testid="copy-link-button"
              className={classes.actionButton}
              onClick={() => onCopyUnitLink(unit)}
            >
              <UnitLinkIcon /> Copy Link
            </Button>
          </TooltipContainer>

          <TooltipContainer tooltip={quoteActionTooltip}>
            <Button
              data-testid="create-quote-button"
              className={classes.actionButton}
              onClick={() => onCreateQuote(unit)}
            >
              <QuoteIcon /> Quote
            </Button>
          </TooltipContainer>
        </Box>
      </CardContent>
    </Card>
  );
};
export default UnitCard;
