import range from 'lodash/range';
import find from 'lodash/find';

(function (angular) {
  const app = angular.module('knockApp');

  const unitQuotesService = function (
    $q,
    apiBase,
    $mdDialog,
    attachmentService
  ) {
    const self = this;

    self.events = {
      quoteAdded: 'quoteAdded'
    };

    /**
     * Retrieves move-in/out dates that are blacked out.
     * @param {string} knockCommunityId - (Required) Community Id
     * @param {string} month - Month for move-in/out
     * @param {string} year - Year for for move-in/out
     */
    self.getBlackoutDates = (knockCommunityId, month, year) => {
      const config = {
        params: {
          month,
          year
        }
      };

      return apiBase.get(`/blackout_dates/${knockCommunityId}`, config);
    };

    /**
     * Retrieves concessions.
     * @param {string} prospectId - (Required) Prospect's Id
     * @param {string} knockCommunityId - (Required) Community Id
     * @param {string} leaseStartDate - (Required) Start of lease date
     * @param {string} leaseTermMonths - (Required) Lease term in months. Must be between 1-12
     * @param {string} unitId - (Required) Knock Unit Id
     * @param {string} type - Vendor quote type
     */
    self.getConcessions = (
      prospectId,
      knockCommunityId,
      leaseStartDate,
      leaseTermMonths,
      unitId,
      type
    ) => {
      const payload = {
        prospect_id: prospectId,
        knock_community_id: knockCommunityId,
        leas_start_date: leaseStartDate,
        leasetermmonths: leaseTermMonths,
        unit_id: unitId,
        type
      };

      return apiBase.post('/concessions', payload);
    };

    self.openGenerateUnitQuoteModal = function (
      prospect,
      unit,
      lease,
      deposit,
      isSendQuoteDisabled,
      hasRentMatrix,
      useManualPricing
    ) {
      return $mdDialog.show({
        controller: 'GenerateUnitQuoteModalController',
        templateUrl: '/angular/views/quotes/generate-unit-quote-modal.html',
        clickOutsideToClose: true,
        locals: {
          prospect: prospect,
          unit: unit,
          lease: lease,
          deposit: deposit,
          isSendQuoteDisabled: isSendQuoteDisabled,
          hasRentMatrix: hasRentMatrix,
          useManualPricing: useManualPricing
        }
      });
    };

    self.regenerateQuoteURL = function (prospectQuoteID) {
      return apiBase.put('/quotes/' + prospectQuoteID + '/url');
    };

    self.uploadQuote = function (quoteUrl) {
      const defer = $q.defer();

      attachmentService.uploadByUrl(
        quoteUrl,
        function (response) {
          const data = JSON.parse(response.target.responseText);
          if (!data || !data.attachments || data.attachments.length > 1) {
            console.error('Invalid upload response from uploadByUrl', data);
            defer.reject(quoteUrl);
            return;
          }
          const attachment = data.attachments[0];
          defer.resolve(attachment);
        },
        null,
        function (error) {
          console.error('Unable to upload quoteUrl due to ', error);
          defer.reject(quoteUrl);
        }
      );

      return defer.promise;
    };

    self.postQuote = function (
      prospectId,
      unitId,
      monthlyRent,
      leaseStartDate,
      leaseTermMonths,
      leaseEndDate,
      deposit,
      quoteExpirationHours,
      upchargeAmount,
      quoteUpchargesEnabled,
      concessions
    ) {
      const payload = {
        prospect_id: prospectId,
        unit_id: unitId,
        monthly_rent: monthlyRent,
        lease_start_date: leaseStartDate,
        lease_term_months: leaseTermMonths,
        lease_end_date: leaseEndDate,
        deposit: deposit,
        quote_expiration_hours: quoteExpirationHours,
        upcharge_amount: upchargeAmount,
        quote_upcharges_enabled: quoteUpchargesEnabled,
        concessions
      };

      return apiBase.post('/quotes', payload);
    };

    self.sendQuote = function (quoteId, target) {
      return apiBase.post('/quotes/' + quoteId + '/send', { target });
    };

    self.getProspectQuotes = function (prospectId) {
      return apiBase.get('/prospects/' + prospectId + '/quotes');
    };

    self.getProperty = function (propertyId) {
      return apiBase.get('/properties/' + propertyId);
    };

    return self;
  };

  unitQuotesService.$inject = [
    '$q',
    'apiBase',
    '$mdDialog',
    'attachmentService'
  ];

  app.factory('unitQuotesService', unitQuotesService);

  const GenerateUnitQuoteModalController = function (
    $rootScope,
    $scope,
    $moment,
    $mdDialog,
    $mdToast,
    quotesService,
    unitQuotesService,
    prospect,
    unit,
    lease,
    deposit,
    isSendQuoteDisabled,
    hasRentMatrix,
    appDataService
  ) {
    const DEPOSIT_REGEXP = /^[0-9]{0,4}$/;

    $scope.data = {
      unit: unit,
      prospect: prospect,
      lease: lease,
      leaseSelections: {
        startDate:
          lease && lease.startDate
            ? $moment(lease.startDate).add(12, 'hours').format()
            : null,
        termLength: lease && lease.termLength ? lease.termLength : 12,
        termLengthOptions: range(1, 25),
        endDate: null,
        minEndDate: null,
        maxEndDate: null,
        deposit: deposit
      },
      realpage: prospect.integrations.some(function (integration, key) {
        return integration['vendor'] === 'realpage';
      }),
      quote: null,
      isGeneratingQuote: false,
      isUploadingQuote: false,
      error: false,
      hasRentMatrix: hasRentMatrix,
      upchargeAmount: null,
      propertyLeaseLengths: null,
      baseMonthlyRent: null,
      displayedMonthlyRent: null,
      includeUpcharges: null,
      quoteUpchargesEnabled: false,
      isSendQuoteDisabled: isSendQuoteDisabled
    };

    const self = this;

    /*
    we are replacing the monthlyRent scope field that displays on quote modals with
    displayedMonthlyRent here. if the QUOTE_UPCHARGES flag is enabled, we also
    need to track baseMonthlyRent, as both will be displayed on the modal.
    the includeUpcharges flag determines how these amounts are calculated;
    when includeUpcharge is true, baseMonthlyRent represents the listed unit price,
    and displayedMonthlyRent = baseMonthlyRent + upchargeAmount;
    when false, displayedMonthlyRent represents the listed unit price,
    and baseMonthlyRent = displayedMonthlyRent - upchargeAmount
    */

    self._initialize = () => {
      $scope.data.baseMonthlyRent = _getMonthlyRent();
      $scope.data.displayedMonthlyRent = $scope.data.baseMonthlyRent;
      if ($rootScope.featureFlags.QUOTE_UPCHARGES) {
        $scope.quoteUpchargesEnabled = true;
        const propertyLeasing = appDataService.getProperty(prospect.property_id)
          .Property.data.leasing;
        if (propertyLeasing.hasOwnProperty('terms')) {
          $scope.data.propertyLeaseLengths = propertyLeasing.terms.leaseLengths;
          if (propertyLeasing.terms.includeUpcharges) {
            $scope.data.includeUpcharges =
              propertyLeasing.terms.includeUpcharges;
          }
        }
      }
    };

    self._formatLeaseDate = (leaseDate) => {
      const timezone = $moment.tz.guess();
      const timezoneDate = $moment.tz(leaseDate, timezone);
      return $moment(timezoneDate).format('YYYY-MM-DD');
    };

    $scope.$watchGroup(
      ['data.leaseSelections.startDate', 'data.leaseSelections.termLength'],
      function () {
        if (
          $scope.data.leaseSelections.startDate &&
          $scope.data.leaseSelections.termLength
        ) {
          $scope.data.leaseSelections.endDate = $moment(
            $scope.data.leaseSelections.startDate
          )
            .add($scope.data.leaseSelections.termLength, 'months')
            .subtract(1, 'days')
            .toDate();

          $scope.data.leaseSelections.minEndDate = $moment(
            $scope.data.leaseSelections.startDate
          )
            .add($scope.data.leaseSelections.termLength, 'months')
            .subtract(1, 'months')
            .toDate();

          $scope.data.leaseSelections.maxEndDate = $moment(
            $scope.data.leaseSelections.startDate
          )
            .add($scope.data.leaseSelections.termLength + 1, 'months')
            .toDate();
        }

        // update base and displayed monthy rent amounts based on changes
        // to upcharge amount if the QUOTE_UPCHARGES flag is enabled
        if (
          $scope.quoteUpchargesEnabled &&
          $scope.data.leaseSelections.termLength &&
          $scope.data.baseMonthlyRent
        ) {
          const mappedTermLengths =
            quotesService.getMappedUpchargeLeaseTermLengths();

          if ($scope.data.propertyLeaseLengths) {
            const termLength = find(
              $scope.data.propertyLeaseLengths,
              function (leaseOption) {
                if ($scope.data.leaseSelections.termLength > 12) {
                  return leaseOption.leaseLength === '12+';
                }
                return (
                  $scope.data.leaseSelections.termLength ===
                  mappedTermLengths[leaseOption.leaseLength]
                );
              }
            );

            if (termLength) {
              if (
                termLength.isAvailable &&
                termLength.upchargeAmount &&
                termLength.upchargeAmount !== 0
              ) {
                $scope.data.upchargeAmount = Number(termLength.upchargeAmount);
                $scope.data.displayedMonthlyRent = Number(
                  $scope.data.displayedMonthlyRent
                );
                $scope.data.baseMonthlyRent = Number(
                  $scope.data.baseMonthlyRent
                );
                if ($scope.data.includeUpcharges) {
                  $scope.data.displayedMonthlyRent =
                    $scope.data.baseMonthlyRent + $scope.data.upchargeAmount;
                } else {
                  $scope.data.baseMonthlyRent =
                    $scope.data.displayedMonthlyRent -
                    $scope.data.upchargeAmount;
                }
              } else {
                if ($scope.data.includeUpcharges) {
                  $scope.data.displayedMonthlyRent =
                    $scope.data.baseMonthlyRent;
                } else {
                  $scope.data.baseMonthlyRent =
                    $scope.data.displayedMonthlyRent;
                }
                $scope.data.upchargeAmount = null;
              }
            }
          }
        }
      }
    );

    $scope.generateQuote = function () {
      if (!$scope.validateDeposit()) {
        return;
      }
      $scope.data.error = false;
      $scope.data.isGeneratingQuote = true;

      const quoteExpirationHours = $rootScope.appPreferences.company
        .quote_expiration_hours
        ? $rootScope.appPreferences.company.quote_expiration_hours
        : 48;

      unitQuotesService
        .postQuote(
          $scope.data.prospect.id,
          unit.id,
          $scope.data.displayedMonthlyRent,
          self._formatLeaseDate($scope.data.leaseSelections.startDate),
          parseInt($scope.data.leaseSelections.termLength),
          self._formatLeaseDate($scope.data.leaseSelections.endDate),
          $scope.data.leaseSelections.deposit,
          quoteExpirationHours,
          $scope.data.upchargeAmount,
          $scope.quoteUpchargesEnabled
        )
        .then((response) => {
          if (
            response.status !== 201 ||
            !response.data ||
            !response.data.quote
          ) {
            console.error('Error generating quote:', response.data);
            $scope.data.error = 'Error generating quote for prospect.';
            $scope.data.isGeneratingQuote = false;
            return;
          }

          $scope.data.quote = response.data.quote;
          $scope.data.isGeneratingQuote = false;
          $mdToast.showSimple('Quote generated!');
          $rootScope.$emit(
            unitQuotesService.events.quoteAdded,
            $scope.data.quote
          );
        })
        .catch((error) => {
          console.error('Error generating quote:', error);
          $scope.data.error = 'Error generating quote!';
          $scope.data.isGeneratingQuote = false;
        });
    };

    $scope.sendQuote = function () {
      if ($scope.data.isUploadingQuote) {
        return;
      }

      $scope.data.isUploadingQuote = true;
      unitQuotesService
        .uploadQuote($scope.data.quote.url)
        .then((attachment) => {
          $scope.data.isUploadingQuote = false;
          $rootScope.$emit('attachmentAdded', {
            attachment: attachment,
            stream_id: prospect.stream_id
          });
          $mdDialog.hide(attachment);
        })
        .catch((error) => {
          console.error('Error sending quote:', error);
          $scope.data.isUploadingQuote = false;
          $scope.data.error = 'Error sending quote!';
        });
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.validateDeposit = function () {
      $scope.data.error = false;
      if (
        $scope.data.realpage &&
        !DEPOSIT_REGEXP.test($scope.data.leaseSelections.deposit)
      ) {
        $scope.data.error =
          'Deposit must be 1-4 integers, and exclude any non-numeric characters such as $ , or .';
        return false;
      }
      return true;
    };

    $scope.showEndDatePicker = function () {
      if (
        (!$scope.data.quote && !$scope.data.lease) ||
        ($scope.data.hasRentMatrix && !$scope.data.quote) ||
        ($scope.data.hasRentMatrix && !$scope.data.lease)
      )
        return true;

      return false;
    };

    function _getMonthlyRent() {
      if (lease && lease.monthlyRent) {
        return lease.monthlyRent;
      }
      if (!unit) {
        return null;
      }
      return unit.displayPrice && !isNaN(unit.displayPrice)
        ? Number(unit.displayPrice)
        : null;
    }

    self._initialize();
  };

  GenerateUnitQuoteModalController.$inject = [
    '$rootScope',
    '$scope',
    '$moment',
    '$mdDialog',
    '$mdToast',
    'quotesService',
    'unitQuotesService',
    'prospect',
    'unit',
    'lease',
    'deposit',
    'isSendQuoteDisabled',
    'hasRentMatrix',
    'appDataService'
  ];
  app.controller(
    'GenerateUnitQuoteModalController',
    GenerateUnitQuoteModalController
  );
})(window.angular);
