import { titleService } from '../../../../Core/Services/TitleService';

export default function runHandleDocTileChange(app: any) {
  app.run([
    '$rootScope',
    function ($rootScope: any) {
      $rootScope.$on(
        '$routeChangeStart',
        function (event: any, nextRoute: any) {
          var defaultTitle = 'Knock | Better leasing results';
          titleService.setTitle(nextRoute.title || defaultTitle);
        }
      );
    }
  ]);
}
