import { denim, makeStyles } from '@knockrentals/knock-shared-web';
import React, { FC, useState } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';
import CustomRenderOption from './CustomRenderOption';
import { GlobalNavLeasingTeam } from './GlobalNavHeaderNew';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    '& .MuiFormControl-root': {
      paddingBottom: '4px'
    },
    '& .MuiInputBase-root': {
      width: '240px',
      height: '40px',
      borderRadius: '4px',
      padding: '0px',
      margin: '15px 20px 0 20px'
    },
    '& #global-nav-header-leasing-team-selection': { paddingLeft: '8px' },
    '& .MuiAutocomplete-inputRoot:focus-within': {
      borderBottom: '2px solid #97A6FC',
      background: '#f5f6ff'
    },
    '& .MuiIconButton-root': { right: '8px', top: '4px' },
    '& .MuiInputBase-root,  .MuiOutlinedInput-input': {
      outline: 'none',
      borderRadius: '4px',
      background: '#F5F5F5',
      '&:focus': { background: '#f5f6ff' }
    },
    '& input, input:hover': { cursor: 'pointer' },
    '& fieldset, fieldset:hover, .MuiInputBase-root': {
      border: 'none'
    },
    '& .MuiSvgIcon-root, #global-nav-header-leasing-team-selection': {
      color: '#000000'
    },
    '& .MuiSvgIcon-root .MuiInputBase-input,  #global-nav-header-leasing-team-selection':
      {
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Open Sans',
        lineHeight: '19px',
        letterSpacing: 'normal'
      },
    '& .MuiIconButton-root[title=Clear]': { display: 'none' }
  },
  option: {
    backgroundColor: 'transparent',
    display: 'inherit',
    padding: 0,

    '&[data-focus=true]': {
      backgroundColor: 'inherit'
    },

    '&:hover': {
      backgroundColor: denim[50]
    }
  }
});

interface LeasingTeamSelectorProps {
  currentLeasingTeam: any;
  leasingTeams: any[];
  leasingTeamsBadgeCounts: any;
  className?: string;
  label?: string;
  callback?: () => void;
}

const LeasingTeamSelector: FC<LeasingTeamSelectorProps> = ({
  currentLeasingTeam,
  leasingTeams,
  leasingTeamsBadgeCounts,
  className,
  label,
  callback
}) => {
  const classes = useStyles();
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <div
      className={
        (focused ? 'focused ' : '') +
        classes.container +
        (className ? ` ${className}` : '')
      }
    >
      <Autocomplete
        popupIcon={<ExpandMoreIcon />}
        autoComplete={true}
        selectOnFocus={false}
        classes={{ option: classes.option }}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option: GlobalNavLeasingTeam) =>
          option.id === currentLeasingTeam.id
        }
        id="global-nav-header-leasing-team-selection"
        noOptionsText={'No leasing teams'}
        options={leasingTeams}
        value={currentLeasingTeam}
        renderOption={(option) => {
          return (
            <CustomRenderOption
              callback={callback}
              option={option}
              badgeCount={
                (leasingTeamsBadgeCounts &&
                  leasingTeamsBadgeCounts[option.publicId]) ||
                0
              }
            />
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={label ? 'filled' : 'outlined'}
            label={label}
            onBlur={() => setFocused(false)}
            onFocus={() => setFocused(true)}
          />
        )}
      />
    </div>
  );
};
export default LeasingTeamSelector;
