/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('activityMessage', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/conversations/messages/activity-message.html',
      scope: {
        message: '=',
        currentUser: '=',
        teamMembers: '='
      },
      controller: [
        '$scope',
        function ($scope) {
          $scope.addedBy = $scope.teamMembers[$scope.message.manager_id];
        }
      ]
    };
  });
})(window.angular);
