/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('notificationRow', [
    'notificationsService',
    '$timeout',
    function (notificationsService, $timeout) {
      return {
        restrict: 'E',
        templateUrl:
          '/angular/views/directives/notifications/notification-row.html',
        link: function (scope, element) {
          $timeout(
            function () {
              var $element = angular.element(element);

              $element.find('a').click(function () {
                notificationsService.togglePanel();
                scope.$apply();
              });
            },
            1,
            true
          );
        }
      };
    }
  ]);

  app.directive('notificationImage', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/notification-image.html',
      scope: {
        url: '='
      }
    };
  });

  app.directive('notificationFunctions', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/notification-functions.html',
      controller: [
        '$scope',
        'notificationsApi',
        function ($scope, notificationsApi) {
          $scope.archiveNotification = function (notification) {
            notification.isArchived = true;
            notificationsApi.archiveNotification(notification.id);
          };
        }
      ]
    };
  });

  app.directive('managerAppointmentRequested', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/manager-appointment-requested.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('managerExpiringRequest', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/manager-expiring-request.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('managerAppointmentConfirmation', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/manager-appointment-confirmation.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('managerAppointmentRequestCancelled', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/manager-appointment-request-cancelled.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('managerTransferredListing', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/manager-transferred-listing.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('managerTransferredAppointment', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/manager-transferred-appointment.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('managerAppointmentCancellation', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/manager-appointment-cancellation.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('managerNewRentalApplication', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/manager-new-rental-application.html',
      scope: {
        notification: '='
      },
      controller: [
        '$scope',
        'conversationsService',
        function ($scope, conversationsService) {
          $scope.openInteractionsWindow = function () {
            var asModal = true;
            conversationsService.openThreadById(
              $scope.notification.args.renter.stream_id,
              asModal
            );
          };
        }
      ]
    };
  });

  app.directive('managerProspectCreated', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/manager-prospect-created.html',
      scope: {
        notification: '='
      },
      controller: [
        '$scope',
        'conversationsService',
        function ($scope, conversationsService) {
          $scope.openThread = function () {
            var asModal = true;
            conversationsService.openThreadById(
              $scope.notification.args.prospect.stream_id,
              asModal
            );
          };
        }
      ]
    };
  });

  app.directive('managerLeadCreated', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/manager-lead-created.html',
      scope: {
        notification: '='
      },
      controller: [
        '$scope',
        'conversationsService',
        function ($scope, conversationsService) {
          $scope.openThread = function () {
            var asModal = true;
            conversationsService.openThreadById(
              $scope.notification.args.lead.stream_id,
              asModal
            );
          };
        }
      ]
    };
  });

  app.directive('prospectAppointmentConfirmation', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/prospect-appointment-confirmation.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('prospectAppointmentsProposed', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/prospect-appointments-proposed.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('prospectAppointmentChanged', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/prospect-appointment-changed.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('prospectAppointmentCancellation', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/prospect-appointment-cancellation.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('prospectRequestDenied', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/prospect-request-denied.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('prospectRequestedApplicationInstructions', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/prospect-requested-application-instructions.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('prospectListingDeactivated', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/prospect-listing-deactivated.html',
      scope: {
        notification: '='
      }
    };
  });

  // remove the following in 2017 after renter/visitor/lead notifications are old and tired
  app.directive('renterAppointmentConfirmation', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/renter-appointment-confirmation.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('renterAppointmentsProposed', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/renter-appointments-proposed.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('renterAppointmentChanged', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/renter-appointment-changed.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('renterAppointmentCancellation', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/renter-appointment-cancellation.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('renterRequestDenied', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/renter-request-denied.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('renterRequestedApplicationInstructions', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/renter-requested-application-instructions.html',
      scope: {
        notification: '='
      }
    };
  });

  app.directive('renterListingDeactivated', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/directives/notifications/renter-listing-deactivated.html',
      scope: {
        notification: '='
      }
    };
  });
})(window.angular);
