import { Amenity, Property } from '../components/LeasingBinder/models';

export const propertyHasNotes = (property: Property) => {
  if (property.notes) {
    return true;
  }

  if (property.key_selling_points) {
    const keys = Object.keys(property.key_selling_points);

    for (const key of keys) {
      if (property.key_selling_points[key].length > 0) {
        return true;
      }
    }
  }

  return false;
};

export const getEmptyProperty = (): Property => {
  return {
    property_id: 0,
    amenities: {
      additional: [],
      exterior: [],
      recreation: [],
      security: []
    },
    appliances: [],
    description: {
      full: '',
      short: ''
    },
    featured_amenities: {
      gym: {
        types: []
      },
      laundry: {
        types: []
      },
      parking: {
        types: []
      },
      pets: {
        types: []
      },
      pool: {
        types: []
      }
    },
    key_selling_points: {
      location: [],
      community: [],
      units: []
    },
    laundry: {
      notes: '',
      types: []
    },
    leasing: {
      application: {
        fee: '',
        instructions: '',
        link: ''
      },
      terms: {
        deposit: '',
        leaseLengths: []
      }
    },
    location: {
      name: '',
      address: {
        street: '',
        city: '',
        state: '',
        zip: ''
      },
      yearBuilt: 0,
      numberOfUnits: 0,
      floors: 0
    },
    notes: '',
    parking: {
      notes: '',
      types: []
    },
    pets: {
      allowed: {},
      deposit: '',
      rent: '',
      fee: '',
      notes: ''
    },
    property_custom_fees: [],
    property_forwarding_numbers: {
      forwarding_number: '',
      resident_forwarding_number: ''
    },
    property_office_hours: [],
    property_touring_hours: [],
    social: {
      facebook: '',
      knock_email: '',
      shortlink: '',
      website: ''
    },
    utilities: {
      estimatedCost: '',
      types: []
    }
  };
};

export const getEmptyUnit = () => {
  return {
    id: '',
    name: '',
    displayPrice: '',
    buildingId: '',
    buildingName: '',
    bedrooms: 0,
    bathrooms: '',
    area: '',
    layoutName: '',
    availableOn: '',
    occupied: false,
    noticeGiven: false,
    leased: false,
    available: false,
    reserved: false,
    layoutId: '',
    match: '',
    propertyId: 0,
    propertyName: '',
    hasGuestCard: false
  };
};

export const getMockProperty = (): Property => {
  const mockProperty = getEmptyProperty();

  mockProperty.property_id = 1;

  mockProperty.notes =
    'These are sample notes for the leasing binder Notes tab';
  mockProperty.key_selling_points = {
    location: ['Close to everything', 'Super quiet'],
    community: ['This is a great place to live'],
    units: []
  };

  mockProperty.description.short = 'This is the property description';
  mockProperty.featured_amenities.gym.types = new Array<Amenity>();
  mockProperty.featured_amenities.gym.types.push({
    name: 'Fitness Center',
    available: true
  });
  mockProperty.featured_amenities.laundry.types = new Array<Amenity>();
  mockProperty.featured_amenities.laundry.types.push({
    name: 'In Unit',
    available: true
  });
  mockProperty.featured_amenities.parking.types = new Array<Amenity>();
  mockProperty.featured_amenities.parking.types.push({
    name: 'Covered',
    available: true
  });
  mockProperty.featured_amenities.pets.types = new Array<Amenity>();
  mockProperty.featured_amenities.pets.types.push({
    name: 'Cats',
    available: true
  });
  mockProperty.property_forwarding_numbers.forwarding_number = '+12061112222';
  mockProperty.property_forwarding_numbers.resident_forwarding_number =
    '+12063334444';
  mockProperty.location.name = 'Skylark Tower';
  mockProperty.location.address.street = '123 Anystreet';
  mockProperty.location.address.city = 'Anytown';
  mockProperty.location.address.state = 'WA';
  mockProperty.location.address.zip = '98103';
  mockProperty.location.yearBuilt = 2020;
  mockProperty.location.numberOfUnits = 45;
  mockProperty.social.website = 'https://example.com';
  mockProperty.property_office_hours.push({
    end_time: '17:00:00',
    start_time: '09:00:00',
    timezone: 'US/Pacific',
    weekday: 2
  });
  mockProperty.property_touring_hours.push({
    end_time: '18:00:00',
    start_time: '10:00:00',
    timezone: 'US/Pacific',
    weekday: 6
  });

  mockProperty.amenities.recreation = [
    { name: 'Tennis court', available: true }
  ];
  mockProperty.amenities.security = [{ name: 'Gated entry', available: true }];
  mockProperty.parking.types.push({ name: 'Garage', available: true });
  mockProperty.pets.allowed['Large dogs'] = true;
  mockProperty.pets.deposit = '50';
  mockProperty.pets.fee = '25';
  mockProperty.pets.rent = '10';
  mockProperty.appliances = [{ name: 'Refrigerator', available: true }];
  mockProperty.amenities.exterior = [{ name: 'Garden', available: true }];
  mockProperty.laundry.types.push({ name: 'In Unit', available: true });

  mockProperty.leasing.application = { fee: '40', instructions: '', link: '' };
  mockProperty.leasing.terms = { deposit: '10', leaseLengths: [] };
  mockProperty.leasing.terms.leaseLengths.push({
    isAvailable: false,
    leaseLength: '3',
    lengthUnit: 'months'
  });
  mockProperty.leasing.terms.leaseLengths.push({
    isAvailable: true,
    leaseLength: '6',
    lengthUnit: 'months'
  });
  mockProperty.leasing.terms.leaseLengths.push({
    isAvailable: true,
    leaseLength: '9',
    lengthUnit: 'months'
  });
  mockProperty.leasing.terms.leaseLengths.push({
    isAvailable: true,
    leaseLength: '12',
    lengthUnit: 'months'
  });
  mockProperty.utilities = {
    estimatedCost: '50',
    types: [
      { included: true, name: 'Water' },
      { included: false, name: 'Sewage' }
    ]
  };
  mockProperty.property_custom_fees.push({
    amount: '42',
    name: 'Some custom fee'
  });

  return mockProperty;
};
