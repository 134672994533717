import find from 'lodash/find';
import map from 'lodash/map';
import some from 'lodash/some';

(function (angular) {
  var app = angular.module('knockApp');

  app.directive('prospectEventVisit', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospectEvents/prospect-event-visit.html',
      scope: {
        event: '=',
        prospect: '=',
        manager: '=',
        property: '='
      },
      controller: [
        '$q',
        '$rootScope',
        '$scope',
        '$modal',
        '$mdToast',
        'appointmentsApi',
        'userInteractionsService',
        'shownUnitsModalService',
        'confirmationModalFactory',
        'unitApi',
        'visitsService',
        function (
          $q,
          $rootScope,
          $scope,
          $modal,
          $mdToast,
          appointmentsApi,
          userInteractionsService,
          shownUnitsModalService,
          confirmationModalFactory,
          unitApi,
          visitsService
        ) {
          var self = this;

          $scope.data = {
            newUnitsFeature: $rootScope.featureFlags.NEW_UNITS || false
          };

          this.$onInit = function () {
            self._updateUnitsString();

            $scope.isYardi =
              $scope.prospect.integrations &&
              some($scope.prospect.integrations, { vendor: 'yardi' });
          };

          var shownUnitsAddedDestroy = $rootScope.$on(
            visitsService.events.shownUnitsAdded,
            function (_e, data) {
              if (data.event.id === $scope.event.id) {
                $scope.event.visit_property_units = data.shownUnits;
                self._updateUnitsString();
              }
            }
          );

          self._updateUnitsString = function () {
            $scope.unitsString = map(
              $scope.event.visit_property_units,
              (item) =>
                item.property_unit ? item.property_unit.unit : item.name
            ).join(', ');
          };

          self._initializeShownUnits = function () {
            // format visit_property_units to display unit name
            const params = {
              propertyId: $scope.prospect.property_id
            };

            if (!$scope.units)
              $scope.units = unitApi.getUnits(params).then(function (response) {
                const shownUnits = [];
                map($scope.event.visit_property_units, function (propertyUnit) {
                  const shownUnit = find(response.data.units, [
                    'id',
                    propertyUnit.unit_id
                  ]);
                  shownUnits.push(shownUnit);
                });
                $scope.event.visit_property_units = shownUnits;
                self._updateUnitsString();
              });
          };

          $scope.$on('$destroy', function () {
            shownUnitsAddedDestroy();
          });

          $scope.openShownUnitsModal = function () {
            shownUnitsModalService
              .openShownUnitsModal($scope.prospect, $scope.event)
              .then(function (shownUnits) {
                $scope.event.visit_property_units = shownUnits;
                visitsService.onShownUnitsAdded($scope.event.id, shownUnits);
                self._updateUnitsString();
              });
          };

          $scope.tryDeleteVisit = function () {
            var message = 'Are you sure you want to remove this visit?';
            var modalOptions = {
              cancelClass: 'btn-warning',
              confirmClass: 'btn-primary',
              alertClass: 'alert-success'
            };

            var modal = confirmationModalFactory.createModal(
              'Remove Visit',
              message,
              function () {
                $scope.isRequesting = true;

                visitsService.deleteVisit($scope.event.id).then(function () {
                  $scope.event.isDeleted = true;
                });
              },
              modalOptions
            );

            $modal.open(modal);
          };

          $scope.tryTransferAppointment = function () {
            if (!$scope.event.appointment_id) {
              return;
            }

            var getDataPromises = [
              appointmentsApi.getAppointment($scope.event.appointment_id),
              visitsService.getVisit($scope.event.id)
            ];

            $q.all(getDataPromises).then(function (responses) {
              var appointment = responses[0].data.appointment;
              var visit = responses[1].data.visit;

              visit.prospect = appointment.prospect;

              userInteractionsService.openTransferAppointmentModal(
                responses[0].data.appointment,
                responses[1].data.visit
              );
            });
          };
        }
      ]
    };
  });
})(window.angular);
