import { NamedColors } from '@knockrentals/knock-shared-web';
import { Switch, withStyles } from '@material-ui/core';

export const BlueSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: NamedColors.denim[500]
    },
    '&$checked + $track': {
      backgroundColor: NamedColors.denim[500]
    }
  },
  checked: {},
  track: {}
})(Switch);
