import React from 'react';

export const BlankAmenitiesTabIcon = () => (
  <svg
    width="184"
    height="180"
    viewBox="0 0 184 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.378 7.05351C91.0695 -8.03666 56.7246 1.88225 41.6663 29.208C36.1778 39.1679 34.009 50.0655 34.7914 60.6249C23.1758 64.1962 12.8797 72.109 6.5477 83.5995C-6.40688 107.108 2.13628 136.689 25.6294 149.671L69.5312 173.931C93.0243 186.913 122.571 178.379 135.526 154.871C137.489 151.308 138.959 147.605 139.957 143.842C155.061 147.256 171.203 140.492 179.055 126.244C188.577 108.965 182.297 87.2215 165.029 77.6792L150.027 69.3893C154.636 45.9229 143.916 21.1658 121.87 8.98354L118.378 7.05351Z"
      fill="#EBEEFE"
    />
    <path
      d="M72.5 124.4V114.8C72.5 112.149 74.7386 110 77.5 110C80.2614 110 82.5 112.149 82.5 114.8V124.4"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M95 71.6003V124.4"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60 124.4V71.6003"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.1166 59.8525L55 71.5997H100L93.8833 59.8525C93.036 58.2262 91.3041 57.1992 89.41 57.1997H65.59C63.6958 57.1992 61.964 58.2262 61.1166 59.8525Z"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.5 57.1999V52.3999"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55 124.399H130"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120 109.999C120 112.65 117.761 114.799 115 114.799C112.239 114.799 110 112.65 110 109.999C110.567 104.15 112.262 98.4533 115 93.1995C117.738 98.4533 119.433 104.15 120 109.999V109.999Z"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M115 114.8V124.4"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70 100.4H85"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70 90.7996H85"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70 81.2H85"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
