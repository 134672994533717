(function (angular) {
  var app = angular.module('knockApp');

  const salesforceSupportService = function ($rootScope) {
    var self = this;

    self._mountInterval = null;

    self.initSalesforceObserver = () => {
      const observer = new MutationObserver(function (mutations, observer) {
        mutations.forEach((mutation) => {
          if (mutation.removedNodes) {
            mutation.removedNodes.forEach((removedNode) => {
              if (removedNode.nodeType === Node.ELEMENT_NODE) {
                if (removedNode.classList.contains('embeddedServiceSidebar')) {
                  // if the embeddedServiceSidebar is removed we need to reattach the draggable handlers
                  self.makeEmbeddedServiceHelpElementDraggable(
                    '.dockableContainer'
                  );
                  self.makeEmbeddedServiceHelpElementDraggable(
                    '.sidebarHeader'
                  );
                }
              }
            });
          }
        });
      });

      observer.observe(document.body, { childList: true, subtree: false });
    };

    self.waitForElement = (selector) => {
      return new Promise((resolve) => {
        if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
        }

        // using the Mutation Observer to check when the new element if on the page
        const observer = new MutationObserver((mutations) => {
          if (document.querySelector(selector)) {
            observer.disconnect();
            resolve(document.querySelector(selector));
          }
        });

        observer.observe(document.body, {
          childList: true,
          subtree: true
        });
      });
    };

    self.makeEmbeddedServiceHelpElementDraggable = function (selector) {
      self.waitForElement(selector).then((elm) => {
        // movement logic - may be improved
        const moveChatButton = (e) => {
          const chatButtonLocator = document.querySelector(selector);
          chatButtonLocator.style.bottom = null;
          chatButtonLocator.style.right = null;
          chatButtonLocator.style.top = e.clientY + 'px';
          chatButtonLocator.style.left = e.clientX + 'px';
        };

        const mouseUp = () => {
          window.removeEventListener('mousemove', moveChatButton, true);
        };

        const mouseDown = (e) => {
          window.addEventListener('mousemove', moveChatButton, true);
        };

        document
          .querySelector(selector)
          .addEventListener('mousedown', mouseDown, false);

        window.addEventListener('mouseup', mouseUp, false);
      });
    };

    var initESW = function (gslbBaseURL) {
      window.embedded_svc.settings.language = 'en-US';
      window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      window.embedded_svc.settings.entryFeature = 'LiveAgent';
      window.embedded_svc.settings.defaultMinimizedText = 'Chat';

      window.embedded_svc.settings.prepopulatedPrechatFields = {
        FirstName: self._data.profile.first_name,
        LastName: self._data.profile.last_name,
        Email: self._data.profile.email,
        Subject: 'Knock - Chat Request'
      };

      window.embedded_svc.settings.extraPrechatFormDetails = [
        {
          transcriptFields: ['Knock_Company_Id__c'],
          value: self._data.master.company_id,
          displayToAgent: true
        }
      ];

      window.embedded_svc.settings.extraPrechatInfo = [
        {
          entityName: 'Contact',
          showOnCreate: false,
          linkToEntityName: 'Case',
          linkToEntityField: 'ContactId',
          saveToTranscript: 'ContactId',
          entityFieldMaps: [
            {
              isExactMatch: true,
              fieldName: 'Email',
              doCreate: true,
              doFind: true,
              label: 'Email'
            }
          ]
        },
        {
          entityName: 'Account',
          showOnCreate: false,
          linkToEntityName: 'Case',
          linkToEntityField: 'AccountId',
          saveToTranscript: 'AccountId',
          entityFieldMaps: [
            {
              isExactMatch: true,
              fieldName: 'Knock_Company_Id__c',
              doCreate: false,
              doFind: true,
              label: 'Knock Company Id'
            }
          ]
        }
      ];

      window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      window.embedded_svc.settings.entryFeature = 'LiveAgent';

      window.embedded_svc.init(
        process.env.REACT_APP_RP_SALESFORCE_URL,
        process.env.REACT_APP_RP_SALESFORCE_SITE,
        gslbBaseURL,
        process.env.REACT_APP_RP_SALESFORCE_ID,
        'Knock_Chat_Deployment',
        {
          baseLiveAgentContentURL:
            process.env.REACT_APP_RP_SALESFORCE_LIVE_AGENT_CONTENT_URL,
          deploymentId: process.env.REACT_APP_RP_SALESFORCE_DEPLOYMENT_ID,
          buttonId: process.env.REACT_APP_RP_SALESFORCE_BUTTON_ID,
          baseLiveAgentURL: process.env.REACT_APP_RP_SALESFORCE_LIVE_AGENT_URL,
          eswLiveAgentDevName:
            'EmbeddedServiceLiveAgent_Parent04IDv0000004CgbMAE_188ca25e6fa',
          isOfflineSupportEnabled: false
        }
      );
      if (self._mountInterval === null) {
        self._mountInterval = setInterval(() => {
          const chatButton = document.getElementsByClassName(
            'embeddedServiceHelpButton'
          );
          if (chatButton && chatButton.length > 0) {
            $rootScope.$broadcast('SalesForceWidgetMounted');
            clearInterval(self._mountInterval);
          }
        }, 500);
      }
    };

    $rootScope.$on('SalesForceWidgetMounted', () => {
      self.initSalesforceObserver();
      self.makeEmbeddedServiceHelpElementDraggable('.helpButton');
      self.makeEmbeddedServiceHelpElementDraggable('.dockableContainer');
      self.makeEmbeddedServiceHelpElementDraggable('.sidebarHeader');
    });

    self.show = () => {
      const chatButton = document.getElementsByClassName(
        'embeddedServiceHelpButton'
      );

      if (chatButton && chatButton.length > 0) {
        chatButton[0].style.display = 'block';
      }
    };

    self.hide = () => {
      const chatButton = document.getElementsByClassName(
        'embeddedServiceHelpButton'
      );
      if (chatButton && chatButton.length > 0) {
        chatButton[0].style.display = 'none';
      }
    };

    self.initializeSalesforce = function () {
      var s = document.createElement('script');
      s.setAttribute('src', process.env.REACT_APP_RP_SALESFORCE_SRC);

      s.onload = function () {
        let baseURL = null;

        // If we are running in production, set a proper base URL
        if (
          process.env.REACT_APP_RP_SALESFORCE_SRC.startsWith(
            'https://service.force.com'
          )
        ) {
          baseURL = 'https://service.force.com';
        }

        initESW(baseURL);
      };

      document.body.appendChild(s);
    };

    self._isInitialized = false;
    self._data = {};

    self.initialize = function (data) {
      if (self._isInitialized) {
        return;
      }

      self._data = data;
      self.initializeSalesforce();
      self._isInitialized = true;
    };

    return self;
  };

  app.factory('salesforceSupportService', [
    '$rootScope',
    salesforceSupportService
  ]);
})(window.angular);
