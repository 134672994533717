import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { HelpMenu, AppMenu, ProfileMenu } from './Menus';
import { TopNavBarProps } from './types';

const TopNavBar: FC<TopNavBarProps> = ({
  userProfile,
  logout,
  openKnockAnalytics,
  openClawApp,
  isClawAvailable,
  openProfileAccount,
  hideAnalytics,
  hideAccountViews
}) => {
  return (
    <Box className="top-nav-bar" marginLeft="auto" marginRight={2}>
      <HelpMenu />
      <AppMenu
        openKnockAnalytics={openKnockAnalytics}
        openClawApp={openClawApp}
        isClawAvailable={isClawAvailable}
        hideAnalytics={hideAnalytics}
      />
      <ProfileMenu
        userProfile={userProfile}
        logout={logout}
        openProfileAccount={openProfileAccount}
        hideAccountViews={hideAccountViews}
      />
    </Box>
  );
};

export default TopNavBar;
