import React, { FC, SetStateAction, Dispatch, useState } from 'react';

import { TourType } from '../../../services/appointmentService';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Text
} from '@knockrentals/knock-shared-web';
import { makeStyles, Dialog, Divider } from '@material-ui/core';

import TourTypeSelector from './TourTypeSelector';
import TourAvailabilitySelector from './TourAvailabilitySelector';

import './AppointmentScheduleDialog.scss';

const useScheduleCardStyles = makeStyles(() => ({
  root: {
    minWidth: '550px',
    maxWidth: '575px',
    overflowY: 'auto'
  }
}));
const useScheduleHeaderStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const useContentStyles = makeStyles(() => ({
  tourTypeContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  tourAvailabilityPaper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRow: '1fr 1fr',
    gridColumnGap: '5px'
  },
  tourAvailabilityInputs: {
    margin: '0',
    width: '100%'
  },
  sectionHeader: {
    paddingBottom: '16px',
    display: 'block'
  },
  sectionDivider: {
    marginTop: '16px',
    marginBottom: '16px'
  },
  actionContainerStyles: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '6px',
    width: '100%'
  }
}));
export type ContentStyles = ReturnType<typeof useContentStyles>;

export interface AppointmentScheduleDialogProps {
  // List of iso date strings that are schedulable for a property.
  availableTimes: string[];
  // Sets the tour type
  setTourType: Dispatch<SetStateAction<TourType | null>>;
  tourType: TourType;
  // Trigger schedule appointment
  scheduleAppointment: () => void;
  isSchedulingAppointment: boolean;

  cancel: () => void;

  setStartTime: Dispatch<SetStateAction<string | null>>;
  startTime: string | null;
  // Is dialog open?
  isOpen: boolean;
  // Is blocked on network?
  isLoading: boolean;
  inPersonToursEnabled: boolean;
  selfGuidedToursEnabled: boolean;
  liveVideoToursEnabled: boolean;
  propertyName: string | null | undefined;
}

const AppointmentScheduleDialog: FC<AppointmentScheduleDialogProps> = (
  props
) => {
  const {
    tourType,
    setTourType,
    startTime,
    setStartTime,
    isOpen,
    isLoading,
    inPersonToursEnabled,
    selfGuidedToursEnabled,
    liveVideoToursEnabled,
    propertyName = 'sister property',
    scheduleAppointment,
    isSchedulingAppointment,
    cancel,
    availableTimes
  } = props;

  const cardStyles = useScheduleCardStyles();
  const headerStyles = useScheduleHeaderStyles();
  const contentStyles = useContentStyles();

  return (
    <Dialog className="appointment-schedule-dialog" open={isOpen}>
      <Card className={cardStyles.root}>
        <CardContent className={headerStyles.root}>
          <Text variant="h6">Schedule with {propertyName}</Text>
        </CardContent>
        <CardContent>
          <TourTypeSelector
            contentStyles={contentStyles}
            isLoading={isLoading}
            tourType={tourType}
            inPersonToursEnabled={inPersonToursEnabled}
            selfGuidedToursEnabled={selfGuidedToursEnabled}
            liveVideoToursEnabled={liveVideoToursEnabled}
            setTourType={setTourType}
          />
          <Divider className={contentStyles.sectionDivider} />
          <TourAvailabilitySelector
            contentStyles={contentStyles}
            isLoading={isLoading}
            availableTimes={availableTimes}
            setStartTime={setStartTime}
            startTime={startTime}
          />
        </CardContent>
        <CardActions>
          <div className={contentStyles.actionContainerStyles}>
            <CancelButton onCancel={() => cancel()}>Cancel</CancelButton>
            <Button
              fullWidth
              disabled={
                isSchedulingAppointment ||
                availableTimes.length < 1 ||
                startTime === null
              }
              onClick={() => scheduleAppointment()}
            >
              Book Tour
            </Button>
          </div>
        </CardActions>
      </Card>
    </Dialog>
  );
};

const CancelButton: FC<{
  onCancel: Function;
}> = (props) => {
  const { onCancel } = props;
  const [isShowingCancelWarning, setIsShowingCancelWarning] = useState(false);

  return (
    <>
      <Button
        color="secondary"
        variant="text"
        fullWidth
        onClick={() => setIsShowingCancelWarning(true)}
      >
        {props.children}
      </Button>
      <Dialog open={isShowingCancelWarning}>
        <Card>
          <CardHeader title="Cancel Booking?" />
          <CardContent>
            <Text variant="body2">
              Are you sure you want to cancel booking? You will not be able to
              book an appointment for this property later.
            </Text>
          </CardContent>
          <CardActions>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%'
              }}
            >
              <Button
                color="secondary"
                variant="outlined"
                data-testid="continue-booking"
                style={{ marginRight: '12px' }}
                onClick={() => setIsShowingCancelWarning(false)}
              >
                Continue booking
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => onCancel()}
              >
                Yes, discard appointment
              </Button>
            </div>
          </CardActions>
        </Card>
      </Dialog>
    </>
  );
};

export default AppointmentScheduleDialog;
