import React from 'react';

export const MonitorIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 2.75C1.5 2.19772 1.94772 1.75 2.5 1.75H15.5C16.0523 1.75 16.5 2.19772 16.5 2.75V10.75C16.5 11.3023 16.0523 11.75 15.5 11.75H2.5C1.94772 11.75 1.5 11.3023 1.5 10.75V2.75Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 14.25H12"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 11.75V14.25"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 2.75C1.5 2.19772 1.94772 1.75 2.5 1.75H15.5C16.0523 1.75 16.5 2.19772 16.5 2.75V10.75C16.5 11.3023 16.0523 11.75 15.5 11.75H2.5C1.94772 11.75 1.5 11.3023 1.5 10.75V2.75Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 14.25H12"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 11.75V14.25"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
