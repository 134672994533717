import React, { FC } from 'react';
import { ThemeProvider } from '@knockrentals/knock-shared-web';
import CreateQuoteView from './CreateQuoteView';
import { QuoteSummaryView } from './QuoteSummaryView';
import { getUpchargeAmount } from './utils';
import { Dialog } from '@material-ui/core';
import { Lease, Quote, Unit, UpchargeDetails } from '../../ts/models';
import './_CreateQuote.scss';

export interface CreateQuoteProps {
  attachQuoteErrorMessage: string;
  attachQuoteToMessage: (quote: Quote) => void;
  blackoutDatesMap: { [key: string]: string };
  cancelCreateQuote: () => void;
  createQuote: (
    selectedConcessions: {},
    leaseStartDate: string,
    leaseTermMonths: number,
    leaseEndDate: string,
    upchargeAmount: number
  ) => void;
  createQuoteErrorMessage: string;
  fetchQuoteConcessions: (
    leaseStartDate: string | undefined,
    leaseTermLength: number
  ) => void;
  handleGenerateQuote: (unit: {}) => void;
  hasValidRentMatrix: boolean;
  isCreateQuoteDialogOpen: boolean;
  isRealPageIntegration: boolean;
  lease: Lease;
  prospect: {};
  quote: Quote;
  quoteConcessions: {
    concessions: { id: string; description: string }[];
    concessionsStatusMessage: string;
  };
  unit: Unit;
  upchargeDetails: UpchargeDetails;
}

export const CreateQuote: FC<CreateQuoteProps> = ({
  attachQuoteErrorMessage,
  blackoutDatesMap,
  cancelCreateQuote,
  createQuote,
  createQuoteErrorMessage,
  fetchQuoteConcessions,
  attachQuoteToMessage,
  handleGenerateQuote,
  hasValidRentMatrix,
  isCreateQuoteDialogOpen,
  isRealPageIntegration,
  lease,
  quote,
  quoteConcessions,
  unit,
  upchargeDetails
}) => {
  const handleOnCloseDialog = () => cancelCreateQuote();

  const openRentMatrix = () => {
    handleOnCloseDialog();
    handleGenerateQuote(unit);
  };

  return (
    <div className="create-quote">
      <Dialog
        className="create-quote-dialog"
        fullWidth
        onClose={handleOnCloseDialog}
        open={isCreateQuoteDialogOpen}
      >
        {quote ? (
          <QuoteSummaryView
            attachQuoteErrorMessage={attachQuoteErrorMessage}
            attachQuoteToMessage={attachQuoteToMessage}
            handleOnClose={handleOnCloseDialog}
            quote={quote}
            unit={unit}
          />
        ) : (
          <CreateQuoteView
            blackoutDatesMap={blackoutDatesMap}
            createQuote={createQuote}
            createQuoteErrorMessage={createQuoteErrorMessage}
            fetchQuoteConcessions={fetchQuoteConcessions}
            getLeaseTermUpchargeAmount={getUpchargeAmount(upchargeDetails)}
            handleOnClose={handleOnCloseDialog}
            hasValidRentMatrix={hasValidRentMatrix}
            isRealPageIntegration={isRealPageIntegration}
            lease={lease}
            openRentMatrix={openRentMatrix}
            quoteConcessions={quoteConcessions}
            unit={unit}
          />
        )}
      </Dialog>
    </div>
  );
};

export default (props: CreateQuoteProps) => (
  <ThemeProvider>
    <CreateQuote {...props} />
  </ThemeProvider>
);
