import React, { FC } from 'react';

export const DocumentIcon: FC = () => (
  <svg
    className="document-icon"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.875 17.3125C16.875 17.9338 16.3713 18.4375 15.75 18.4375H2.25C1.62868 18.4375 1.125 17.9338 1.125 17.3125V2.6875C1.125 2.06618 1.62868 1.5625 2.25 1.5625H13.5338C13.8274 1.56233 14.1095 1.677 14.3198 1.882L16.5353 4.04425C16.7525 4.25597 16.875 4.54643 16.875 4.84975V17.3125Z"
      stroke="#697FF7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.53369 7.1875H13.5337"
      stroke="#697FF7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.53369 10.5625H13.5337"
      stroke="#697FF7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.53369 13.9375H9.03369"
      stroke="#697FF7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
