/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('streamRepositoryService', [
    'apiBase',
    'prospectsApi',
    'residentsApi',
    '$rootScope',
    'appDataService',
    function (apiBase, prospectsApi, residentsApi, $rootScope, appDataService) {
      let self = this;
      self._streamCache = {};
      self._prospectCache = {};
      self._residentCache = {};

      self.getStream = function (streamId, fromCache) {
        if (!streamId) {
          throw 'invalid stream id: ' + streamId;
        }

        if (fromCache && self._streamCache[streamId]) {
          return self._streamCache[streamId];
        }

        return apiBase.get(
          '/chats/' +
            streamId +
            '?include_merge_tag_values=true' +
            `&include_unsubscribe_status=true`
        );
      };

      self.preloadStream = function (streamId) {
        console.log(`preloadStream() streamId: ${streamId}`);

        if (!streamId) {
          throw 'invalid stream id: ' + streamId;
        }

        var getStreamPromise = self.getStream(streamId);
        self._streamCache[streamId] = getStreamPromise;

        return getStreamPromise
          .then(function (response) {
            var stream = response.data.stream;

            if (stream.type === 'prospect') {
              var prospectParticipant = find(stream.participants, {
                type: 'prospect'
              });

              if (prospectParticipant) {
                self.preloadProspect(prospectParticipant.id);
              }
            }

            if (stream.type === 'resident') {
              var residentParticipant = find(stream.participants, {
                type: 'resident'
              });

              if (residentParticipant) {
                self.preloadResident(residentParticipant.id);
              }
            }
          })
          .catch(function (response) {
            let errorMessage = `streamRepository -> preloadStream() -> getStream() -> streamId: ${streamId} -> ${
              response.status
            } ${response.statusText} ${JSON.stringify(response.data)}`;
            throw new Error(errorMessage);
          });
      };

      self.preloadProspect = function (prospectId) {
        console.log(`preloadProspect() prospectId: ${prospectId}`);
        if (!prospectId) {
          throw 'invalid prospect id: ' + prospectId;
        }

        var getProspectPromise = self.getProspect(prospectId);
        self._prospectCache[prospectId] = getProspectPromise;

        return getProspectPromise;
      };

      self.getProspect = function (prospectId, fromCache) {
        if (!prospectId) {
          throw 'invalid prospect id: ' + prospectId;
        }

        if (fromCache && self._prospectCache[prospectId]) {
          return self._prospectCache[prospectId];
        }

        return prospectsApi.getProspect(prospectId);
      };

      self.preloadResident = function (residentId) {
        console.log(`preloadResident() residentId: ${residentId}`);
        if (!residentId) {
          throw 'invalid resident id: ' + residentId;
        }

        var getResidentPromise = self.getResident(residentId);
        self._residentCache[residentId] = getResidentPromise;

        return getResidentPromise;
      };

      self.getResident = function (residentId, fromCache) {
        if (!residentId) {
          throw 'invalid resident id: ' + residentId;
        }

        if (fromCache && self._residentCache[residentId]) {
          return self._residentCache[residentId];
        }

        return residentsApi.getResident(residentId);
      };

      self.clearCache = function () {
        self._streamCache = {};
      };

      self.createStreamBatch = function (streamIds) {
        return apiBase.post('/chats-batch/identifier', {
          stream_ids: streamIds
        });
      };

      self.getStreamBatch = function (batchId) {
        return apiBase.get('/chats-batch/' + batchId);
      };

      self.updateCensoredStatus = function (
        visibility,
        streamId,
        messageId,
        type
      ) {
        const interRoute = type === 'call' ? '/call/' : '/messages/';

        if (!streamId) {
          throw 'invalid stream id: ' + streamId;
        }

        if (!messageId) {
          throw 'invalid message id: ' + messageId;
        }

        return apiBase.put(
          '/chats/' + streamId + interRoute + messageId + '/censor',
          { is_censored: visibility === 'hide' ? true : false }
        );
      };

      return self;
    }
  ]);
})(window.angular);
