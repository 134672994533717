import React, { FC } from 'react';
import classnames from 'classnames';
import { Chip } from '@material-ui/core';
import { Box, denim, makeStyles, Text } from '@knockrentals/knock-shared-web';
import { QUICK_REPLY_TYPES } from '../models';

const useStyles = makeStyles((theme) => ({
  quickReplyChip: {
    margin: '6px',
    '& .MuiTypography-root': {
      color: theme.palette.text.primary
    },
    '&.current ': {
      backgroundColor: denim[200],
      '& .MuiTypography-root': {
        color: denim[500]
      }
    }
  },
  quickReplyChips: {
    padding: '16px'
  }
}));

export const CHIP_LABELS = {
  [QUICK_REPLY_TYPES.COMPANY_QUICK_REPLIES]: 'Company QRs',
  [QUICK_REPLY_TYPES.PROPERTY_QUICK_REPLIES]: 'Property QRs'
};

interface QuickReplyChipsProps {
  currentReplyType: QUICK_REPLY_TYPES;
  setCurrentReplyType: (type: QUICK_REPLY_TYPES) => void;
}

const QuickReplyChips: FC<QuickReplyChipsProps> = ({
  currentReplyType,
  setCurrentReplyType
}) => {
  const classes = useStyles();

  const getChipSubclass = (type: QUICK_REPLY_TYPES) => {
    if (type === currentReplyType) {
      return 'current';
    }
  };

  const renderChipLabel = (type: QUICK_REPLY_TYPES) => (
    <Text variant="body2">{CHIP_LABELS[type]}</Text>
  );

  const renderChip = (type: QUICK_REPLY_TYPES) => (
    <Chip
      clickable
      className={classnames(classes.quickReplyChip, getChipSubclass(type))}
      label={renderChipLabel(type)}
      onClick={() => setCurrentReplyType(type)}
    />
  );

  return (
    <Box className={classes.quickReplyChips}>
      {renderChip(QUICK_REPLY_TYPES.COMPANY_QUICK_REPLIES)}
      {renderChip(QUICK_REPLY_TYPES.PROPERTY_QUICK_REPLIES)}
    </Box>
  );
};

export default QuickReplyChips;
