/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('renterSignup', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/signup/renter-signup.html',
      scope: {
        onSignup: '='
      },
      controller: [
        '$scope',
        'signupService',
        'localCache',
        'cacheKeys',
        function ($scope, signupService, localCache, cacheKeys) {
          var self = this;
          var errorMessages = signupService.getErrorMessages();

          $scope.renter = {
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            type: 'renter'
          };

          $scope.facebookSignup = function () {
            self.socialSignup('facebook');
          };

          $scope.linkedInSignup = function () {
            self.socialSignup('linkedin');
          };

          self.socialSignup = function (provider) {
            $scope.socialErrorMessage = null;

            signupService
              .socialSignup(provider)
              .then(function (response) {
                localCache.put(cacheKeys.managerOnboardingSlide, 'start');
                $scope.onSignup(response);
              })
              .catch(function (response) {
                if (signupService.isPrivateBrowsingError(response)) {
                  $scope.socialErrorMessage =
                    errorMessages.privateBrowsingErrorMessage;
                  return;
                }

                $scope.socialErrorMessage = errorMessages.errorMessage;
              });
          };

          $scope.emailSignup = function () {
            $scope.emailErrorMessage = null;
            $scope.isSigningUp = true;

            signupService
              .emailSignup({ user: $scope.renter })
              .then(function (response) {
                $scope.isSigningUp = false;
                $scope.onSignup(response);
              })
              .catch(function (response) {
                $scope.isSigningUp = false;

                if (response.status === 409) {
                  $scope.emailErrorMessage =
                    errorMessages.usernameTakenErrorMessage;
                  return;
                }

                if (signupService.isPrivateBrowsingError(response)) {
                  $scope.emailErrorMessage =
                    errorMessages.privateBrowsingErrorMessage;
                  return;
                }

                $scope.emailErrorMessage = errorMessages.errorMessage;
              });
          };
        }
      ]
    };
  });
})();
