import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
(function (angular) {
  var app = angular.module('knockApp');

  app.factory('managerCalendarService', [
    '$modal',
    '$q',
    '$popover',
    '$interval',
    'timeService',
    'calendarDataRepository',
    'userService',
    function (
      $modal,
      $q,
      $popover,
      $interval,
      timeService,
      calendarDataRepository,
      userService
    ) {
      var self = this;

      self.eventElements = {};

      self.mapTeamPrimeTimes = function (primeTimes) {
        return groupBy(primeTimes, 'manager_id');
      };

      self.mapTeamRequests = function (requests) {
        var requestsByManager = groupBy(requests, function (request) {
          return request.AppointmentRequest.manager_id;
        });

        forEach(requestsByManager, function (requestArray, managerId) {
          requestsByManager[managerId] = groupBy(
            requestArray,
            function (request) {
              return self.getRequestsObjectKey(
                request.AppointmentRequest.prospect_id,
                request.AppointmentRequest.listing_id
              );
            }
          );
        });

        return requestsByManager;
      };

      self.mapTeamProposals = function (proposals) {
        var proposalsByManager = groupBy(proposals, function (proposal) {
          return proposal.AppointmentRequest.manager_id;
        });

        forEach(proposalsByManager, function (proposalArray, managerId) {
          proposalsByManager[managerId] = groupBy(
            proposalArray,
            function (proposal) {
              return self.getRequestsObjectKey(
                proposal.AppointmentRequest.prospect_id,
                proposal.AppointmentRequest.listing_id
              );
            }
          );
        });

        return proposalsByManager;
      };

      self.mapTeamAppointments = function (appointments) {
        return groupBy(appointments, function (appointment) {
          return appointment.Appointment.manager_id;
        });
      };

      self.mapTeamEvents = function (events) {
        return groupBy(events, function (event) {
          return event.manager_id;
        });
      };

      self.mapSyncedCalendarEvents = function (syncedEvents) {
        var mappedEvents = {};

        forEach(syncedEvents, function (event) {
          mappedEvents[event.calendar_id] = (
            mappedEvents[event.calendar_id] || []
          ).concat(event);
        });

        return mappedEvents;
      };

      self.mapTeamAppointment = function (teamAppointment) {
        var event = self.mapAppointment(
          teamAppointment.Appointment || teamAppointment
        );
        event.editable = self._validateIsEditable(event);

        return event;
      };

      self.mapExternalEvent = function (externalEvent) {
        var status = 'external';
        var appointmentClass = 'calendar-event-external';
        var managerColor = calendarDataRepository.getColorForManager(
          userService.getScopedUser().id
        );

        var isAllDay = isUndefined(externalEvent.start.dateTime);
        var start = isAllDay
          ? externalEvent.start.date
          : externalEvent.start.dateTime;
        var end = isAllDay
          ? externalEvent.end.date
          : externalEvent.end.dateTime;

        return {
          id: externalEvent.id,
          title: externalEvent.title,
          allDay: isAllDay,
          start: timeService.get(start).format(),
          end: timeService.get(end).format(),
          className: appointmentClass,
          status: status,
          borderColor: managerColor,
          editable: false
        };
      };

      self.mapOfficeHourDay = function (officeHourDay, managerId) {
        var dayOfWeek = timeService.get(officeHourDay.start_time).day();
        var startTime = timeService
          .get(officeHourDay.start_time)
          .format('HH:mm');
        var endTime = timeService.get(officeHourDay.end_time).format('HH:mm');

        var color = managerId
          ? calendarDataRepository.getColorForManager(parseInt(managerId))
          : null;

        return {
          start: startTime,
          end: endTime,
          rendering: 'inverse-background',
          className: managerId
            ? 'calendar-event-officehours-team calendar-event-officehours'
            : 'calendar-event-officehours',
          title: 'Office hours',
          id: 'officeHours',
          dow: [dayOfWeek],
          editable: false,
          color: color
        };
      };

      self.mapVisit = function (visit) {
        var visitClass = 'calendar-visit-event';
        var prospectName =
          visit.prospect.profile.first_name +
          ' ' +
          visit.prospect.profile.last_name;
        var title = visit.appointment_id
          ? prospectName
          : 'Walk-in - ' + prospectName;

        var managerColor = calendarDataRepository.getColorForManager(
          visit.manager_id
        );

        return {
          id: 'visit-' + visit.id,
          propertyId: visit.prospect ? visit.prospect.property_id : null,
          visit: visit,
          title: title,
          allDay: false,
          start: timeService.get(visit.start_time).format(),
          end: timeService.get(visit.end_time).format(),
          className: visitClass,
          editable: false,
          color: managerColor
        };
      };

      self.mapTeamVisit = function (visitWithManagerInfo) {
        return self.mapVisit(visitWithManagerInfo.Visit);
      };

      self.mapAppointment = function (appointment) {
        var status = appointment.status || 'primetime';

        var appointmentClass = 'calendar-event-' + status;

        var title = self.getTitleFromStatus(status, appointment);

        var managerColor = calendarDataRepository.getColorForManager(
          appointment.manager_id
        );

        if (status === 'primetime') {
          var listing = calendarDataRepository.getListing(
            appointment.listing_id
          );

          if (listing) {
            var address = listing.location.address;
            var floorplan = listing.floorplan;
            var leasing = listing.leasing;

            title =
              '$' +
              leasing.monthlyRent +
              ' - ' +
              floorplan.bedrooms +
              'bd ' +
              floorplan.bathrooms +
              'ba - ' +
              address.street +
              ', ' +
              address.city +
              ', ' +
              address.state +
              ' ' +
              address.zip;
          } else {
            title = 'Could not load listing data.';
          }
        }

        return {
          // pending appointment requests share the same appointment id, to
          // prevent issues related to duplicate ids we will use a hyphenated
          // id for pending appointments
          id:
            appointment.status === 'pending'
              ? `${appointment.id}-${appointment.request_time_id}`
              : appointment.id,
          appointmentId: appointment.id,
          requestTimeId: appointment.request_time_id,
          listingId: appointment.listing_id,
          propertyId: appointment.prospect
            ? appointment.prospect.property_id
            : null,
          title: title,
          tourType: appointment.tour_type,
          allDay: false,
          start: timeService.get(appointment.start_time).format(),
          end: timeService.get(appointment.end_time).format(),
          className: appointmentClass,
          status: status,
          editable:
            appointment.status !== 'pending' &&
            self._validateIsEditable(appointment),
          color: managerColor
        };
      };

      self._validateIsEditable = function (appointment) {
        return (
          appointment.status !== 'primetime' &&
          appointment.tour_type !== 'self_guided'
        );
      };

      self.mapGroupedReminders = function (groupedReminders, dateKey) {
        var managerId = groupedReminders[0].assigned_manager_id;

        return {
          id: 'reminders-' + managerId + '-' + dateKey,
          title: 'Follow up (' + groupedReminders.length + ')',
          allDay: true,
          editable: false,
          start: timeService.get(dateKey).format(),
          reminders: groupedReminders,
          color: calendarDataRepository.getColorForManager(managerId)
        };
      };

      self.mapCalendarEvent = function (calendarEvent) {
        var isBusyEvent = calendarEvent.show_as_busy;

        var managerColor = calendarDataRepository.getColorForManager(
          calendarEvent.manager_id
        );
        var busyBackgroundColor = '#E6E6E6';
        var recurrenceEnd = calendarEvent.recurrence_end
          ? timeService.get(calendarEvent.recurrence_end).format()
          : undefined;

        return {
          id: 'event-' + calendarEvent.event_id,
          databaseId: calendarEvent.event_id,
          reminderType: calendarEvent.type,
          title: calendarEvent.title,
          allDay: calendarEvent.all_day,
          start: timeService.get(calendarEvent.start_time).format(),
          end: timeService.get(calendarEvent.end_time).format(),
          start_time: calendarEvent.start_time,
          end_time: calendarEvent.end_time,
          all_day: calendarEvent.all_day,
          show_as_busy: calendarEvent.show_as_busy,
          recurrence_frequency: calendarEvent.recurrence_frequency,
          recurrence_interval: calendarEvent.recurrence_interval,
          recurrence_end: recurrenceEnd,
          recurrence_days_of_week: calendarEvent.recurrence_days_of_week,
          recur_on_monthday: calendarEvent.recur_on_monthday,
          extra: calendarEvent.extra || {},
          className: isBusyEvent ? 'calendar-event-busy' : 'calendar-event',
          color: isBusyEvent ? busyBackgroundColor : managerColor,
          borderColor: isBusyEvent ? managerColor : busyBackgroundColor,
          editable: false
        };
      };

      self.getRequestsObjectKey = function (prospectId, listingId) {
        return prospectId + '_' + listingId;
      };

      self.getTitleFromStatus = function (status, appointment) {
        switch (status) {
          case 'pending':
            return 'Pending request';
          case 'confirmed':
            return self._getAppointmentTitle(appointment);
          case 'primetime':
            return 'Prime time';
          default:
            return '';
        }
      };

      self._getAppointmentTitle = function (appointment) {
        var property = appointment.prospect
          ? calendarDataRepository.getProperty(appointment.prospect.property_id)
          : null;

        if (!property) {
          return 'Tour';
        }

        var prospectName = appointment.prospect
          ? appointment.prospect.profile.first_name +
            ' ' +
            appointment.prospect.profile.last_name
          : 'Tour';

        if (property.type === 'multi-family') {
          return prospectName;
        }

        var address = property.data.location.address;
        return (
          prospectName +
          ' - ' +
          address.street +
          ', ' +
          address.city +
          ', ' +
          address.state +
          ' ' +
          address.zip
        );
      };

      self.newEventSource = function (id, events, eventDataTransform) {
        return {
          id: id,
          events: events,
          eventDataTransform: eventDataTransform
        };
      };

      self.renderPlaceholderEvent = function (startTime, calendarElement) {
        var start = timeService.get(startTime).format();
        var end = timeService.get(startTime).add(15, 'minutes').format();

        var placeholderEvent = {
          id: 'placeholder_event_' + start,
          title: 'New event',
          allDay: false,
          start: start,
          end: end,
          className: 'calendar-event-placeholder',
          editable: true
        };

        calendarElement.fullCalendar('renderEvent', placeholderEvent);

        return placeholderEvent;
      };

      self.attachVisitPopover = function (
        $scope,
        visitCalendarEvent,
        element,
        view,
        calendarElement
      ) {
        var popoverOptions = {
          title: visitCalendarEvent.visit.appointment_id
            ? 'Appointment'
            : 'Walk-in',
          contentTemplate: 'calendar-visit-popover.html',
          placement: 'body',
          container: false,
          autoClose: true
        };

        popoverOptions.title +=
          ' - ' +
          timeService.get(visitCalendarEvent.visit.start_time).format('h:mma');

        return self._attachPopover(
          popoverOptions,
          $scope,
          visitCalendarEvent,
          element,
          view,
          calendarElement
        );
      };

      self.attachReminderPopover = function (
        $scope,
        reminderCalendarEvent,
        element,
        view,
        calendarElement
      ) {
        var popoverOptions = {
          title: 'Follow up',
          contentTemplate: 'calendar-reminder-popover.html',
          placement: 'body',
          container: false,
          autoClose: true
        };

        return self._attachPopover(
          popoverOptions,
          $scope,
          reminderCalendarEvent,
          element,
          view,
          calendarElement
        );
      };

      self.attachEventPopover = function (
        $scope,
        event,
        element,
        view,
        calenderElement
      ) {
        var className = event.className[0] || '';

        if (
          className !== 'calendar-event-officehours' &&
          className !== 'calendar-event-external' &&
          className !== 'calendar-event-now' &&
          className !== 'calendar-event-busy' &&
          className !== 'calendar-event-placeholder'
        ) {
          var title = '';
          var property = calendarDataRepository.getProperty(event.propertyId);

          if (property) {
            var address = property.data.location.address;
            title =
              address.street +
              ', ' +
              address.city +
              ', ' +
              address.state +
              ' ' +
              address.zip;
          } else {
            title = 'Could not load property data.';
          }

          if (title.length > 35) {
            title = title.substr(0, 35) + '...';
          }

          var popoverOptions = {
            title: title,
            contentTemplate:
              className === 'calendar-event-primetime'
                ? 'calendar-primetime-popover.html'
                : 'calendar-popover.html',
            placement: '',
            container: false,
            autoClose: true
          };

          if (view.name === 'agendaWeek') {
            // Ensure the popover isn't rendered off of the calendar; move it depending on day of week
            var dayOfWeek = event.start.day();

            if (dayOfWeek < 3) {
              // Wednesday
              popoverOptions.placement = 'right';
            } else {
              popoverOptions.placement = 'left';
            }
          } else if (view.name === 'agendaDay') {
            popoverOptions.placement = 'top';
          } else {
            // Append to the body of the page for anything other than the week view
            popoverOptions.container = 'body';
          }

          var eventPopover = $popover(element, popoverOptions);
          eventPopover.$scope.event = event;
          eventPopover.$scope.closePopovers = $scope.closePopovers;
          eventPopover.$scope.popoverElement = eventPopover;

          var eventElement = angular.element(element);

          eventElement.click(function (e) {
            e.stopPropagation();
            $scope.calendarUIEvents.onEventClicked(
              event,
              calenderElement,
              view,
              eventElement
            );
            $scope.closePopovers(eventPopover);
          });

          return eventPopover;
        }
      };

      self._attachPopover = function (
        popoverOptions,
        $scope,
        calendarEvent,
        element,
        view,
        calendarElement
      ) {
        if (view.name === 'agendaWeek') {
          // Ensure the popover isn't rendered off of the calendar; move it depending on day of week
          var dayOfWeek = calendarEvent.start.day();

          if (dayOfWeek < 3) {
            // Wednesday
            popoverOptions.placement = 'right';
          } else {
            popoverOptions.placement = 'left';
          }
        } else if (view.name === 'agendaDay') {
          popoverOptions.placement = 'top';
        } else {
          // Append to the body of the page for anything other than the week view
          popoverOptions.container = 'body';
        }

        var eventPopover = $popover(element, popoverOptions);
        eventPopover.$scope.event = calendarEvent;
        eventPopover.$scope.closePopovers = $scope.closePopovers;
        eventPopover.$scope.popoverElement = eventPopover;

        var eventElement = angular.element(element);

        eventElement.click(function (e) {
          e.stopPropagation();
          $scope.calendarUIEvents.onEventClicked(
            calendarEvent,
            calendarElement,
            view,
            eventElement
          );
          $scope.closePopovers(eventPopover);
        });

        return eventPopover;
      };

      self.setRequestHighlighted = function (request) {
        self.highlightedRequest = request;

        var requestEventElement = self.eventElements[request.id];

        if (requestEventElement) {
          requestEventElement.toggleClass('highlighted');
        }
      };

      self.clearHighlightedRequest = function (request) {
        if (
          self.highlightedRequest &&
          request.id === self.highlightedRequest.id
        ) {
          self.highlightedRequest = null;

          var requestEventElement = self.eventElements[request.id];

          if (requestEventElement) {
            requestEventElement.removeClass('highlighted');
          }
        }
      };

      return self;
    }
  ]);
})(window.angular);
