/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('amenitiesModelService', [
    function () {
      var AmenitiesModel = function () {
        this.exterior = [
          { name: 'Private pool', available: false },
          { name: 'Balcony/patio', available: false },
          { name: 'Deck', available: false },
          { name: 'Yard', available: false },
          { name: 'Yard-Fenced', available: false },
          { name: 'Pond', available: false },
          { name: 'Lake', available: false },
          { name: 'Porch', available: false },
          { name: 'Garden', available: false },
          { name: 'Sprinkler system', available: false },
          { name: 'Greenhouse', available: false }
        ];

        this.heatingAndCooling = [
          { name: 'Heat: forced air', available: false },
          { name: 'Central AC', available: false },
          { name: 'Air conditioning', available: false },
          { name: 'Ceiling fans', available: false },
          { name: 'Double pane / Storm windows', available: false }
        ];

        this.wiring = [
          { name: 'Cable-ready', available: false },
          { name: 'High-speed internet', available: false },
          { name: 'Wired', available: false }
        ];

        this.additional = [
          { name: 'Hardwood floor', available: false },
          { name: 'Tile floor', available: false },
          { name: 'Granite countertop', available: false },
          { name: 'Fireplace', available: false },
          { name: 'Vaulted ceiling', available: false },
          { name: 'Jacuzzi', available: false },
          { name: 'Sauna', available: false },
          { name: 'Hot tub / Spa', available: false },
          { name: 'Skylights', available: false },
          { name: 'Non-Smoking Property', available: false }
        ];

        this.security = [
          { name: 'Elevator', available: false },
          { name: 'Security system', available: false },
          { name: 'Controlled access', available: false },
          { name: 'Gated entry', available: false },
          { name: 'Doorman', available: false },
          { name: 'Disability access', available: false }
        ];

        this.recreation = [
          { name: 'Shared pool', available: false },
          { name: 'Fitness center', available: false },
          { name: 'Clubhouse', available: false },
          { name: 'Playground', available: false },
          { name: 'Barbecue', available: false },
          { name: 'Business center', available: false },
          { name: 'Tennis court', available: false },
          { name: 'Basketball court', available: false },
          { name: 'Sports court', available: false },
          { name: 'Golf course', available: false },
          { name: 'Dock', available: false }
        ];
      };

      var AmenitiesModelService = {};

      AmenitiesModelService.getNewModel = function () {
        return new AmenitiesModel();
      };

      return AmenitiesModelService;
    }
  ]);
})();
