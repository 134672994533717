import React, { FC } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';

import { makeStyles, NamedColors } from '@knockrentals/knock-shared-web';
const useStyles = makeStyles({
  reasonSkeletonContainer: {
    display: 'flex',
    width: '328px',
    alignItems: 'center',
    margin: '14px 0 0 0'
  },
  reasonSkeletonCircle: {
    backgroundColor: NamedColors.slate[100],
    '&::after': {
      background: `linear-gradient(90deg, transparent, ${NamedColors.slate[300]}, transparent)`
    }
  },
  reasonSkeletonLine: {
    width: '270px',
    marginLeft: '10px',
    borderRadius: '16px',
    backgroundColor: NamedColors.slate[100],
    '&::after': {
      background: `linear-gradient(90deg, transparent, ${NamedColors.slate[300]}, transparent)`
    }
  }
});
interface CheckboxSkeletonProps {
  animate: boolean;
}

const CheckboxSkeleton: FC<CheckboxSkeletonProps> = ({ animate }) => {
  const classes = useStyles();
  return (
    <Box className={classes.reasonSkeletonContainer}>
      <Skeleton
        animation={animate && 'wave'}
        className={classes.reasonSkeletonCircle}
        variant="circle"
        width={24}
        height={24}
      />
      <Skeleton
        animation={animate && 'wave'}
        variant="rect"
        className={classes.reasonSkeletonLine}
        height={24}
      />
    </Box>
  );
};

export default CheckboxSkeleton;
