/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import keys from 'lodash/keys';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  var contactTypeMap = {
    call: 'Call',
    chat: 'Chat',
    email: 'Email',
    facebook: 'Facebook',
    internet: 'Internet',
    'knock-schedule': 'Knock Schedule',
    sms: 'SMS',
    'walk-in': 'Walk in',
    form: 'Form'
  };

  app.factory('contactTypes', [
    function () {
      return {
        get: function () {
          // These are the contact types that are supported for prospect creation via the web app

          return [
            'call',
            'chat',
            'email',
            'sms',
            'walk-in'

            // Hidden as this option can only be set via the Syndication API
            // 'form'
          ];
        },
        getAll: function () {
          // These are all of the contact types supported by Knock (e.g. created via integration)

          return keys(contactTypeMap);
        }
      };
    }
  ]);

  app.filter('contactType', [
    function () {
      return function (contactType) {
        return contactTypeMap[contactType];
      };
    }
  ]);
})(window.angular);
