/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import each from 'lodash/each';
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('uploadService', [
    '$auth',
    function ($auth) {
      var self = this;

      self.upload = function (
        url,
        key,
        files,
        uploadComplete,
        uploadProgress,
        uploadFailed
      ) {
        var formData = self._createFormData(key, files);
        var xhr = new XMLHttpRequest();

        if (uploadComplete) {
          xhr.addEventListener('load', uploadComplete, false);
        }

        if (uploadProgress) {
          xhr.upload.addEventListener('progress', uploadProgress, false);
        }

        if (uploadFailed) {
          xhr.addEventListener('error', uploadFailed, false);
        }

        var token = $auth.getToken();

        xhr.open('POST', url);
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        xhr.send(formData);
      };

      self._createFormData = function (key, files) {
        var uploadForm = new FormData();
        uploadForm.append('key', key);

        var fileOrder = map(files, function (photo, index) {
          return { i: index, photo: photo.name };
        });

        uploadForm.append('order', JSON.stringify(fileOrder));
        uploadForm.append('key', key);

        files.forEach(function (file) {
          uploadForm.append(file.name, file);
        });

        return uploadForm;
      };

      return self;
    }
  ]);

  app.factory('attachmentService', [
    '$auth',
    'api',
    function ($auth, api) {
      var self = this;

      self.attachmentUploadEndpoint = api.host + '/upload/attachment';

      self.attachmentTypes = {
        image: 'image',
        video: 'video',
        pdf: 'pdf',
        unsupported: 'unsupported'
      };

      self.uploadByUrl = function (
        url,
        uploadComplete,
        uploadProgress,
        uploadFailed
      ) {
        var xhr = new XMLHttpRequest();

        var formData = new FormData();
        formData.append('attachmentUrl', url);

        if (uploadComplete) {
          xhr.addEventListener('load', uploadComplete, false);
        }

        if (uploadProgress) {
          xhr.upload.addEventListener('progress', uploadProgress, false);
        }

        if (uploadFailed) {
          xhr.addEventListener('error', uploadFailed, false);
        }

        var token = $auth.getToken();

        xhr.open('POST', self.attachmentUploadEndpoint);
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        xhr.send(formData);
      };

      self.uploadByFile = function (
        files,
        uploadComplete,
        uploadProgress,
        uploadFailed
      ) {
        var xhr = new XMLHttpRequest();

        var formData = new FormData();

        each(files, function (file) {
          formData.append(file.name, file);
        });

        if (uploadComplete) {
          xhr.addEventListener('load', uploadComplete, false);
        }

        if (uploadProgress) {
          xhr.upload.addEventListener('progress', uploadProgress, false);
        }

        if (uploadFailed) {
          xhr.addEventListener('error', uploadFailed, false);
        }

        var token = $auth.getToken();

        xhr.open('POST', self.attachmentUploadEndpoint);
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        xhr.send(formData);
      };

      self.deleteAttachment = function (token) {
        var xhr = new XMLHttpRequest();
        xhr.open('DELETE', self.attachmentUploadEndpoint + '/' + token);
        xhr.setRequestHeader('Authorization', 'Bearer ' + $auth.getToken());
        xhr.send();
      };

      self.getAttachmentType = function (mimetype) {
        if (self.isImageAttachment(mimetype)) {
          return self.attachmentTypes.image;
        }

        if (self.isVideoAttachment(mimetype)) {
          return self.attachmentTypes.video;
        }

        if (self.isPDFAttachment(mimetype)) {
          return self.attachmentTypes.pdf;
        }

        return self.attachmentTypes.unsupported;
      };

      self.isImageAttachment = function (mimetype) {
        return mimetype.search('image/.*') === 0;
      };

      self.isVideoAttachment = function (mimetype) {
        return mimetype.search('video/.*') === 0;
      };

      self.isPDFAttachment = function (mimetype) {
        return mimetype === 'application/pdf';
      };

      self.isUnsupportedAttachment = function (mimetype) {
        return (
          !self.isImageAttachment(mimetype) &&
          !self.isVideoAttachment(mimetype) &&
          !self.isPDFAttachment(mimetype)
        );
      };

      return self;
    }
  ]);
})(window.angular);
