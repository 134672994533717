/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const prospectTransferModalFactory = function ($mdDialog) {
    var self = this;

    self.openTransferModal = function (prospect, noHubAccounts) {
      return $mdDialog.show({
        controller: 'ProspectTransferModalController',
        templateUrl: '/angular/views/prospects/prospect-transfer-modal.html',
        clickOutsideToClose: true,
        locals: {
          prospect: prospect,
          noHubAccounts: noHubAccounts || false
        }
      });
    };

    return self;
  };
  prospectTransferModalFactory.$inject = ['$mdDialog'];
  app.factory('prospectTransferModalFactory', prospectTransferModalFactory);

  const ProspectTransferModalController = function (
    $q,
    $scope,
    $mdDialog,
    $mdToast,
    appDataService,
    userInteractionsService,
    prospect,
    noHubAccounts
  ) {
    var self = this;
    var teamMembers = noHubAccounts
      ? filter(appDataService.getTeamMembers(), function (member) {
          return !member.Manager.is_hub_account;
        })
      : appDataService.getTeamMembers();

    $scope.data = {
      teamMembers: sortBy(teamMembers, 'ManagerInfo.first_name'),
      prospect: prospect,
      selectedManagerId: null,
      isSaving: false
    };

    self._initialize = function () {
      var selectedManager = find($scope.data.teamMembers, function (member) {
        return member.Manager.id === $scope.data.prospect.assigned_manager_id;
      });

      $scope.data.selectedManagerId = selectedManager
        ? selectedManager.Manager.id
        : null;
    };

    $scope.transferProspect = function () {
      $scope.data.isSaving = true;

      var transferPromises = [
        userInteractionsService.transferProspect(
          $scope.data.prospect.id,
          $scope.data.selectedManagerId
        ),
        userInteractionsService.transferProspectAppointments(
          $scope.data.prospect.id,
          $scope.data.selectedManagerId
        )
      ];

      $q.all(transferPromises).then(
        function () {
          $mdToast.showSimple('Prospect transferred!');
          $mdDialog.hide($scope.data.selectedManagerId);
        },
        function () {
          $mdToast.showSimple('Error transferring prospect.');
          $scope.data.isSaving = false;
        }
      );
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    self._initialize();
  };

  ProspectTransferModalController.$inject = [
    '$q',
    '$scope',
    '$mdDialog',
    '$mdToast',
    'appDataService',
    'userInteractionsService',
    'prospect',
    'noHubAccounts'
  ];
  app.controller(
    'ProspectTransferModalController',
    ProspectTransferModalController
  );
})(window.angular);
