import React, { FC } from 'react';
import { Collapse } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { makeStyles } from '@material-ui/core/styles';

export interface ErrorAlertProps {
  alertMessage: string;
}

const useStyles = makeStyles((theme) => ({
  errorAlert: {
    '&.MuiAlert-root': {
      padding: '0 8px 0 10px',
      marginBottom: '20px'
    },
    '& .MuiAlert-icon': {
      fontSize: '24px'
    },
    '& .MuiAlert-message': {
      color: theme.palette.text.primary
    }
  }
}));

const ErrorAlert: FC<ErrorAlertProps> = ({ alertMessage }) => {
  const classes = useStyles();

  return (
    <Collapse in={Boolean(alertMessage)} mountOnEnter unmountOnExit>
      <Alert
        data-testid="error-alert"
        className={classes.errorAlert}
        severity="error"
      >
        {alertMessage}
      </Alert>
    </Collapse>
  );
};

export default ErrorAlert;
