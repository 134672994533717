/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Authentication');

  app.factory('renterAuthModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openRenterAuthModal = function (form, renterInfo) {
        return $mdDialog.show({
          controller: 'RenterAuthController',
          templateUrl: '/angular/views/authentication/renter-auth-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            initialForm: form,
            renterInfo: renterInfo
          }
        });
      };

      return self;
    }
  ]);

  app.controller('RenterAuthController', [
    '$scope',
    '$location',
    '$interpolate',
    '$mdDialog',
    'authenticationService',
    'initialForm',
    'renterInfo',
    function (
      $scope,
      $location,
      $interpolate,
      $mdDialog,
      authenticationService,
      initialForm,
      renterInfo
    ) {
      var self = this;

      $scope.initialForm = initialForm;

      $scope.forms = {
        formIndex: 0,
        isLoading: false,
        login: {
          username: '',
          password: '',
          socialLoginError: false,
          emailError: false
        },
        signup: {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          socialLoginError: false,
          emailError: false
        }
      };

      self._initialize = function () {
        if ($scope.initialForm) {
          $scope.forms.formIndex = $scope.initialForm === 'login' ? 1 : 0;
        }

        if (renterInfo) {
          $scope.forms.signup.firstName = renterInfo.first_name;
          $scope.forms.signup.lastName = renterInfo.last_name;
          $scope.forms.signup.email = renterInfo.email;
        }
      };

      $scope.cancel = function () {
        $mdDialog.cancel();
      };

      $scope.openRequestManagerAccountModal = function () {
        $mdDialog.cancel();
        authenticationService.openRequestManagerAccountModal();
      };

      $scope.openManagerAuthModal = function () {
        $mdDialog.cancel();
        authenticationService.openManagerAuthModal();
      };

      $scope.forgotPassword = function () {
        $mdDialog.cancel();
        authenticationService.openForgotPasswordModal(
          'renter',
          $scope.forms.login.username
        );
      };

      $scope.showSignup = function () {
        $scope.forms.formIndex = 0;
      };

      $scope.showLogin = function () {
        $scope.forms.formIndex = 1;
      };

      $scope.emailLogin = function () {
        self._clearFormErrors();

        $scope.forms.isLoading = true;

        authenticationService
          .emailLogin(
            $scope.forms.login.username,
            $scope.forms.login.password,
            'renter'
          )
          .then(self._emailLoginSuccess)
          .catch(self._emailLoginError);
      };

      self._emailLoginSuccess = function () {
        authenticationService.handleAuthenticatedRedirection();
        $mdDialog.hide();
      };

      self._emailLoginError = function (response) {
        $scope.forms.isLoading = false;
        $scope.forms.login.emailError = true;

        self._processErrorMessage('login', response);
      };

      $scope.emailSignup = function () {
        self._clearFormErrors();

        $scope.forms.isLoading = true;

        var emailPromise = authenticationService.emailSignup(
          $scope.forms.signup.firstName,
          $scope.forms.signup.lastName,
          $scope.forms.signup.email,
          $scope.forms.signup.password,
          'renter'
        );

        emailPromise
          .then(self._emailSignupSuccess)
          .catch(self._emailSignupError);
      };

      self._emailSignupSuccess = function () {
        authenticationService.handleAuthenticatedRedirection();
        $mdDialog.hide();
      };

      self._emailSignupError = function (response) {
        $scope.forms.isLoading = false;
        $scope.forms.signup.emailError = true;

        self._processErrorMessage('signup', response);
      };

      $scope.socialLogin = function (provider) {
        self._clearFormErrors();

        $scope.forms.isLoading = true;

        authenticationService
          .socialLogin(provider)
          .then(self._socialLoginSuccess)
          .catch(self._socialLoginError);
      };

      self._socialLoginSuccess = function () {
        authenticationService.handleAuthenticatedRedirection();
        $mdDialog.hide();
      };

      self._socialLoginError = function (response) {
        $scope.forms.isLoading = false;

        var form = $scope.forms.formIndex === 0 ? 'signup' : 'login';
        $scope.forms[form].socialLoginError = true;

        self._processErrorMessage(form, response);
      };

      self._processErrorMessage = function (form, response) {
        var errorMessages = authenticationService.getErrorMessages(form);

        if (authenticationService.isPrivateBrowsingError(response)) {
          $scope.forms[form].errorMessage =
            errorMessages.privateBrowsingErrorMessage;
          return;
        }

        if (response.status === 409) {
          $scope.forms[form].errorMessage =
            errorMessages.usernameTakenErrorMessage;
          return;
        }

        if (response.status === 401) {
          $scope.forms[form].errorMessage = errorMessages.invalidLoginMessage;
          return;
        }

        if (
          response.status === 403 &&
          response.data.error &&
          response.data.error.type === 'email_hidden'
        ) {
          $scope.forms[form].errorMessage = errorMessages.emailHidden;
          return;
        }

        if (response.status === 403) {
          $scope.forms[form].errorMessage =
            errorMessages.migratedUserLoginErrorMessage;
          return;
        }

        var existingAccountProvider =
          authenticationService.getExistingAccountProvider(response);
        if (existingAccountProvider) {
          $scope.forms[form].errorMessage = $interpolate(
            errorMessages.useSocialLoginMessageTemplate
          )({ provider: existingAccountProvider });
          return;
        }

        $scope.forms[form].errorMessage = errorMessages.errorMessage;
      };

      self._clearFormErrors = function () {
        $scope.forms.login.socialLoginError = false;
        $scope.forms.signup.socialLoginError = false;
        $scope.forms.login.emailError = false;
        $scope.forms.signup.emailError = false;
      };

      self._initialize();
    }
  ]);
})(window.angular);
