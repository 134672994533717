/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import remove from 'lodash/remove';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('photoDropService', [
    '$rootScope',
    function ($rootScope) {
      var photoDropService = {
        collections: {}
      };

      photoDropService.setCollection = function (key, collection) {
        photoDropService.collections[key] = collection;
      };

      photoDropService.getCollection = function (key) {
        if (!(key in photoDropService.collections)) {
          photoDropService.setCollection(key, []);
        }

        return photoDropService.collections[key];
      };

      photoDropService.getCollectionLocalPhotos = function (key) {
        var allPhotos = photoDropService.getCollection(key);
        var fileObjects = [];

        allPhotos.forEach(function (photo) {
          if (photo instanceof File) {
            // jshint ignore: line
            fileObjects.push(photo);
          }
        });

        return fileObjects;
      };

      photoDropService.getCollectionRemotePhotos = function (key) {
        var allPhotos = photoDropService.getCollection(key);
        var uploadedObjects = [];

        allPhotos.forEach(function (photo) {
          if (!(photo instanceof File)) {
            // jshint ignore: line
            uploadedObjects.push(photo);
          }
        });

        return uploadedObjects;
      };

      photoDropService.deleteCollection = function (key) {
        delete photoDropService.collections[key];
        $rootScope.$broadcast(key + '-deleted');
      };

      return photoDropService;
    }
  ]);

  app.directive('photoDrop', function () {
    return {
      restrict: 'E',
      scope: {
        photosKey: '=',
        allowMultiple: '=?'
      },
      controller: 'PhotoDropController',
      controllerAs: 'photoDropCtrl',
      templateUrl: '/angular/views/directives/photoDrop.html'
    };
  });

  app.controller('PhotoDropController', [
    '$scope',
    '$rootScope',
    '$window',
    'photoDropService',
    function ($scope, $rootScope, $window, photoDropService) {
      var self = this;

      self.photosKey = angular.isDefined($scope.photosKey)
        ? $scope.photosKey
        : 'default';
      self.allowMultiple = angular.isDefined($scope.allowMultiple)
        ? $scope.allowMultiple
        : true;
      self.photos = photoDropService.getCollection(self.photosKey);

      var photoDeletedHandler = $rootScope.$on(
        self.photosKey + '-deleted',
        function () {
          self.photos = photoDropService.getCollection(self.photosKey);
        }
      );

      $scope.$on('$destroy', photoDeletedHandler);

      self.removePhoto = function (photo) {
        // call revokeObjectURL because mozilla says we 'should':
        // https://developer.mozilla.org/en-US/docs/Using_files_from_web_applications#Using_object_URLs
        $window.URL.revokeObjectURL(photo);
        remove(self.photos, photo);
      };

      var handleDroppedPhotos = function (photo) {
        if (!angular.isDefined(photo)) {
          return;
        }

        photo.url = $window.URL.createObjectURL(photo);
        self.photos.push(photo);
      };

      var addPhotos = function (photos) {
        if (
          !self.allowMultiple &&
          (photos.length > 1 || self.photos.length === 1)
        ) {
          while (self.photos.length > 0) {
            self.photos.pop();
          }
        }

        for (var i = 0; i < photos.length; i++) {
          var photoToAdd = photos[i];
          $scope.$apply(handleDroppedPhotos(photoToAdd));

          // If we don't allow multiple photos, break after the first one is added
          if (!self.allowMultiple) {
            break;
          }
        }
      };

      $scope.addPhotos = function (element) {
        addPhotos(element.files);
      };

      var stopProp = function (e) {
        e.stopPropagation();
        e.preventDefault();
      };

      var endDrag = function () {
        $scope.$apply(($scope.class = 'drag-exit'));
      };

      var dragOver = function (e) {
        stopProp(e);
        $scope.$apply(($scope.class = 'drag-enter'));
      };

      var dragLeave = function (e) {
        stopProp(e);
        endDrag();
      };

      var droppedPhotosHandler = function (e) {
        stopProp(e);
        endDrag();

        var dt = e.dataTransfer;

        addPhotos(dt.files);
      };

      var photoBox = document.getElementById('photo-box');
      photoBox.addEventListener('dragover', dragOver, false);
      photoBox.addEventListener('dragleave', dragLeave, false);
      photoBox.addEventListener('drop', droppedPhotosHandler, false);

      var fileSelect = document.getElementById('fileSelect');
      var fileElem = document.getElementById('fileElem');

      fileSelect.addEventListener(
        'click',
        function (e) {
          if (fileElem) {
            fileElem.click();
          }
          e.preventDefault();
        },
        false
      );
    }
  ]);
})();
