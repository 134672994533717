import { createEntityAdapter } from '@reduxjs/toolkit';

export interface LeasingTeamMember {
  Manager: {
    id: number;
  };
  ManagerInfo: {
    first_name: string;
    last_name: string;
  };
  TeammateMetadata: {};
}

export interface People {
  field: string;
  person_type: string;
  property_id: number | null;
  query_string: string;
  scope: string;
  use_exact_hits: boolean;
}

interface Hit {
  highlight: null;
  hit: {
    assigned_manager_id: number;
    assigned_team_id: number;
    created_time: string;
    email: string;
    id: number;
    is_deleted: boolean;
    name: string;
    phone_number: string;
    photo: string;
    property_id: number;
    stream_id: string;
    type: string;
  };

  id: string;
  index: string;
  score: number;
  status_code: string;
}

export interface SearchPeopleResult {
  results: {
    hits: Hit[];
    query_string: string;
    scope: string;
    total: number;
  };
}

export const leasingTeamMemberAdapter = createEntityAdapter<LeasingTeamMember>({
  selectId: (entity) => entity.Manager.id
});
