import React from 'react';

export const BlankOverviewTabIcon = () => (
  <svg
    width="184"
    height="180"
    viewBox="0 0 184 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.378 7.05351C91.0695 -8.03666 56.7246 1.88225 41.6663 29.208C36.1778 39.1679 34.009 50.0655 34.7914 60.6249C23.1758 64.1962 12.8797 72.109 6.54772 83.5995C-6.40686 107.108 2.1363 136.689 25.6294 149.671L69.5312 173.931C93.0243 186.913 122.571 178.379 135.526 154.871C137.489 151.308 138.959 147.605 139.957 143.842C155.061 147.256 171.203 140.492 179.055 126.244C188.577 108.965 182.297 87.2215 165.029 77.6792L150.027 69.3893C154.636 45.9229 143.916 21.1658 121.87 8.98354L118.378 7.05351Z"
      fill="#EBEEFE"
    />
    <path
      d="M120 61.9999V124.4H65V61.9999M120 61.9999H65M120 61.9999L115 52.3999H70L65 61.9999M55 124.4H130M100 117.2C100 113.224 96.6421 110 92.5 110C88.3579 110 85 113.224 85 117.2V124.4H100V117.2Z"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.5 70.4004C78.1904 70.4004 78.75 70.9376 78.75 71.6004"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.25 71.6004C76.25 70.9376 76.8096 70.4004 77.5 70.4004"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.5 72.8003C76.8096 72.8003 76.25 72.2631 76.25 71.6003"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.75 71.6003C78.75 72.2631 78.1904 72.8003 77.5 72.8003"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.5 84.7998C78.1904 84.7998 78.75 85.3371 78.75 85.9998"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.25 85.9998C76.25 85.3371 76.8096 84.7998 77.5 84.7998"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.5 87.201C76.8096 87.201 76.25 86.6637 76.25 86.001"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.75 86.001C78.75 86.6637 78.1904 87.201 77.5 87.201"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.5 99.2004C78.1904 99.2004 78.75 99.7377 78.75 100.4"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.25 100.4C76.25 99.7377 76.8096 99.2004 77.5 99.2004"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.5 101.6C76.8096 101.6 76.25 101.063 76.25 100.4"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.75 100.4C78.75 101.063 78.1904 101.6 77.5 101.6"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.5 70.4004C93.1904 70.4004 93.75 70.9376 93.75 71.6004"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.25 71.6004C91.25 70.9376 91.8096 70.4004 92.5 70.4004"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.5 72.8003C91.8096 72.8003 91.25 72.2631 91.25 71.6003"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M93.75 71.6003C93.75 72.2631 93.1904 72.8003 92.5 72.8003"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.5 84.7998C93.1904 84.7998 93.75 85.3371 93.75 85.9998"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.25 85.9998C91.25 85.3371 91.8096 84.7998 92.5 84.7998"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.5 87.201C91.8096 87.201 91.25 86.6637 91.25 86.001"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M93.75 86.001C93.75 86.6637 93.1904 87.201 92.5 87.201"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.5 99.2004C93.1904 99.2004 93.75 99.7377 93.75 100.4"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.25 100.4C91.25 99.7377 91.8096 99.2004 92.5 99.2004"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.5 101.6C91.8096 101.6 91.25 101.063 91.25 100.4"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M93.75 100.4C93.75 101.063 93.1904 101.6 92.5 101.6"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M107.5 70.4004C108.19 70.4004 108.75 70.9376 108.75 71.6004"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.25 71.6004C106.25 70.9376 106.81 70.4004 107.5 70.4004"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M107.5 72.8003C106.81 72.8003 106.25 72.2631 106.25 71.6003"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.75 71.6003C108.75 72.2631 108.19 72.8003 107.5 72.8003"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M107.5 84.7998C108.19 84.7998 108.75 85.3371 108.75 85.9998"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.25 85.9998C106.25 85.3371 106.81 84.7998 107.5 84.7998"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M107.5 87.201C106.81 87.201 106.25 86.6637 106.25 86.001"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.75 86.001C108.75 86.6637 108.19 87.201 107.5 87.201"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M107.5 99.2004C108.19 99.2004 108.75 99.7377 108.75 100.4"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.25 100.4C106.25 99.7377 106.81 99.2004 107.5 99.2004"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M107.5 101.6C106.81 101.6 106.25 101.063 106.25 100.4"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.75 100.4C108.75 101.063 108.19 101.6 107.5 101.6"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
