import React from 'react';

export const IcardIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 6H13.5M10.5 8H14.5M6.5 7.5C7.4665 7.5 8.25 6.7165 8.25 5.75C8.25 4.7835 7.4665 4 6.5 4C5.5335 4 4.75 4.7835 4.75 5.75C4.75 6.7165 5.5335 7.5 6.5 7.5ZM3.5 11C3.5 9.34315 4.84315 8 6.5 8C8.15685 8 9.5 9.34315 9.5 11H3.5ZM15.5 2H2.5C1.94772 2 1.5 2.44772 1.5 3V13C1.5 13.5523 1.94772 14 2.5 14H4.5C4.5 13.4477 4.94772 13 5.5 13C6.05228 13 6.5 13.4477 6.5 14H11.5C11.5 13.4477 11.9477 13 12.5 13C13.0523 13 13.5 13.4477 13.5 14H15.5C16.0523 14 16.5 13.5523 16.5 13V3C16.5 2.44772 16.0523 2 15.5 2Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
