import React, {
  ChangeEvent,
  FC,
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { AddCircleOutline } from '@material-ui/icons';
import dayjs from 'dayjs';

import {
  makeStyles,
  NamedColors,
  Button,
  Text,
  InputCheckbox
} from '@knockrentals/knock-shared-web';
import {
  EnvelopeIcon,
  PeopleStackIcon,
  PhoneSMSIcon,
  SMSOptInIcon,
  ProspectsIcon,
  NoSearchResultsIcon
} from '../icons';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  selectAllResidents,
  resetSelectedResidents
} from '../../../../app/features/residents/selectedResidents';
import { useCommonStyles } from '../commonStyles/commonStyles';
import { useAngularContext } from '../AngularContextProvider';
import ActionBar, { IAction } from '../Prospects/ActionBar';
import ResidentRow from './ResidentTable/ResidentRow';
import { useResidents } from '../../../../app/services/residents/hooks';
import { ProspectActions } from '../Prospects/enums';
import { Resident } from '../../../../app/services/residents/entities';
import ResidentTextActionModal from './ResidentTextActionModal';
import BulkEmailModal from '../Prospects/BulkEmailModal';
import SmsOptInModal from './SmsOptInModal';
import AddResidentModal from './ResidentModals/AddResidentModal';
import ChipSelect from '../molecules/ChipSelect/ChipSelect';
import ChipSelectDateRange from '../molecules/ChipSelectDateRange/ChipSelectDateRange';
import {
  setFilters,
  setProperties,
  setBuildings,
  setStatuses,
  setLeaseEnd,
  statusFilters,
  leaseEndFilters,
  initialResidentFilter
} from '../../../../app/features/residents/filter';
import { FilterOption, SelectedFilterOption } from '../../../../app/common';
import {
  setPage,
  setRowsPerPage,
  setSort
} from '../../../../app/features/residents/filter';
import { BulkAction } from '../../../../app/features/prospects/selectedProspects';
import SnackbarMessage from '../molecules/Snackbar/SnackbarMessage';

const useStyles = makeStyles(() => ({
  mainContainer: {
    padding: '24px 24px 0 24px'
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  actionBar: {
    display: 'flex',
    marginTop: '16px'
  },

  tableContainer: {
    marginTop: '16px',
    border: `1px solid ${NamedColors.slate[200]}`,
    borderRadius: '8px'
  },

  table: {
    minWidth: '1500px',

    '& .MuiTableCell-head': {
      paddingBottom: '10px',
      paddingTop: '10px'
    },

    '& .MuiTableCell-body': {
      paddingBottom: '7px',
      paddingTop: '7px'
    },

    '& .MuiTableCell-body.rowLoader': {
      paddingBottom: '15px',
      paddingTop: '15px'
    }
  },

  loadingIndicator: {
    borderRadius: '10px',
    backgroundColor: NamedColors.slate[200],
    height: '20px',

    '&::after': {
      background: `linear-gradient(90deg, transparent, ${NamedColors.slate[300]}, transparent)`
    }
  },

  paginationLoadingIndicator: {
    height: '24px'
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '4px',
    paddingTop: '4px',

    '& .MuiSelect-root': {
      paddingTop: '7px'
    },

    '& .MuiSelect-icon': {
      top: 'auto'
    }
  },

  paginationLoader: {
    marginBottom: '14px',
    marginTop: '14px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '400px'
  },

  rowsPerPageMenu: {
    '&:focus, &:hover, &.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: NamedColors.denim[50]
    },

    '&.MuiListItem-button:hover': {
      backgroundColor: NamedColors.denim[50]
    },

    '&.MuiListItem-root': {
      paddingRight: '16px'
    }
  }
}));
interface FilterDateError {
  startDateError?: string;
  endDateError?: string;
}

const SMS_OPTIN_SUBJECT = 'Opt in to Receive Texts';
const SMS_OPTIN_CONTENT =
  'To allow us to send text messages, follow this link: #SMSOptinLink';

const Residents: FC = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [showSmsOptInModal, setShowSmsOptInModal] = useState<boolean>(false);
  const [enableSmsOptIn, setEnableSmsOptIn] = useState<boolean>(false);
  const [defaultEmailSubject, setDefaultEmailSubject] = useState<string>('');
  const [defaultEmailContent, setDefaultEmailContent] = useState<string>('');
  const [disableCCSupport, setDisableCCSupport] = useState<boolean>(false);
  const [showAddResidentModal, setShowAddResidentModal] = useState(false);
  const [buildingFilters, setBuildingFilters] = useState<FilterOption[]>([]);
  const [showTextModal, setShowTextModal] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string | null>(null);

  const [selectedResidentsData, setSelectedResidentsData] = useState<
    BulkAction[]
  >([]);

  const {
    propertyFilters,
    allowCreateResident,
    openCarousel,
    timeService,
    removeQueryStringParam,
    emailDomainBlocklist
  } = useAngularContext();

  const { residents, pageData, residentsLoading, refetch } = useResidents();

  const { selectedResidents, selectAll, selectAllIndeterminate } =
    useAppSelector((state) => state.selectedResidents);

  const {
    properties,
    buildings,
    statuses,
    leaseEndAfter,
    leaseEnd,
    leaseEndRange,
    page,
    payload
  } = useAppSelector((state) => state.residentFilter);

  const [filterDateError, setFilterDateError] = useState<FilterDateError>({});

  const dispatch = useAppDispatch();

  const selectedResidentsForBulkAction = residents.filter((resident) => {
    return selectedResidents[resident.id];
  });

  const selectedResidentsCount = Object.keys(selectedResidents || {}).length;
  const loaders = Array(10).fill(1);
  const currentColumnName = payload.sort_column || '';
  const currentColumnOrder = payload.sort_direction || 'asc';

  const checkHasDefaultFilters = () => {
    const defaultFilters = { ...initialResidentFilter };
    const currentFilters = {
      properties,
      buildings,
      statuses,
      leaseEndAfter,
      leaseEnd,
      leaseEndRange,
      page,
      payload
    };

    return JSON.stringify(currentFilters) === JSON.stringify(defaultFilters);
  };

  const hasDefaultFilters = checkHasDefaultFilters();
  const noResultsIcon = hasDefaultFilters ? (
    <ProspectsIcon />
  ) : (
    <NoSearchResultsIcon />
  );
  const noResultsHeader = hasDefaultFilters ? 'No Residents Yet' : 'Whoops!';
  const noResultsText = hasDefaultFilters
    ? 'Once you start getting new residents,\ntheir information will show up here.'
    : "We couldn't find any residents that match your search.";

  const resizeTableContainer = useCallback(() => {
    const tableContainer = document.getElementById('tbl-container');

    if (tableContainer) {
      const top = tableContainer.getBoundingClientRect().top || 0;
      const tableData = document.getElementById('tbl-data');
      const tableHeight = tableData?.getBoundingClientRect().height || 0;
      const height = window.innerHeight - top - 84;

      // We only want to calculate an actual height when there might be more rows than space to show them and we need a scrollbar. For all other
      // cases, such as when loading data or having a small enough number of rows to comfortably show in the page, a value of 'auto' is ideal.
      if (
        (tableHeight > height || residents.length > 11) &&
        !residentsLoading
      ) {
        tableContainer.setAttribute('style', `height: ${height}px;`);
      } else {
        tableContainer.setAttribute('style', `height: auto;`);
      }
    }
  }, [residents.length, residentsLoading]);

  const selectedResidentsRef = useRef(selectedResidents);

  useEffect(() => {
    const records = setupSelectedResidents();
    selectedResidentsRef.current = selectedResidents;
    setSelectedResidentsData(records);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResidents]);

  useEffect(() => {
    if (!residentsLoading) {
      let newBuildingFilters: FilterOption[] = [];

      if (residents.length > 0) {
        // Each time there are resident records we need to build a list of buildings to filter by, which come directly from those records until we
        // have a dedicated endpoint to fetch them. We take the current building list and reduce it to a set of key/value pairs, so the check for
        // duplicates below will be fast. The list will be cumulative as the user applies various filters to the residents list, as a convenience.
        const currentBuildingFilters =
          buildingFilters.reduce<SelectedFilterOption>(
            (source, buildingFilter) => ({
              ...source,
              [buildingFilter.value]: {
                label: buildingFilter.value,
                value: buildingFilter.value,
                index: buildingFilter.index
              }
            }),
            {}
          );

        residents.forEach((resident: Resident, index: number) => {
          if (resident.building && !currentBuildingFilters[resident.building]) {
            currentBuildingFilters[resident.building] = {
              label: resident.building,
              value: resident.building,
              index
            };
          }
        });

        newBuildingFilters = Object.keys(currentBuildingFilters)
          .map((key: string) => currentBuildingFilters[key])
          .sort((lValue: FilterOption, rValue: FilterOption) =>
            lValue.label.localeCompare(rValue.label)
          );

        setBuildingFilters(newBuildingFilters);
      }
    }

    if (enableSmsOptIn) {
      setDefaultEmailSubject(SMS_OPTIN_SUBJECT);
      setDefaultEmailContent(SMS_OPTIN_CONTENT);
    }

    // This rule is disabled because we don't want to include the buildings variable. Its contents are going to be used to set its new value, and
    // including it will cause endless render cycles.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residents, residentsLoading]);

  useEffect(() => {
    resizeTableContainer();

    const container = document.getElementById('view-content');
    const resize = new ResizeObserver(() => {
      resizeTableContainer();
    });

    if (container) {
      resize.observe(container);
    }
    return () => {
      resize.disconnect();
    };
  }, [resizeTableContainer]);

  const removeQueryParamFilters = () => {
    if (window.location.search.indexOf('f_residentIds') > -1) {
      removeQueryStringParam('f_residentIds');
    }

    if (window.location.search.indexOf('batchId') > -1) {
      removeQueryStringParam('batchId');
    }
  };

  const setupSelectedResidentsData = (smsOptIn?: boolean) => {
    let selectedResidents = selectedResidentsForBulkAction;

    if (smsOptIn) {
      selectedResidents = selectedResidentsForBulkAction.filter(
        (resident: Resident) =>
          resident.profile.phone?.canReceiveSMS &&
          resident.smsConsent?.status !== 'granted'
      );
    }

    const records = selectedResidents.map((resident: Resident) => {
      return {
        id: resident.id,
        name: resident.profile.fullName,
        email: resident.profile.email,
        streamId: resident.streamId,
        isOnlyReceiveText:
          !resident.profile.email && resident.smsConsent?.status === 'granted'
      };
    });

    setSelectedResidentsData(records);
  };

  const setupSelectedResidents = (smsOptIn?: boolean) => {
    let selectedResidents = selectedResidentsForBulkAction;

    if (smsOptIn) {
      selectedResidents = selectedResidentsForBulkAction.filter(
        (resident: Resident) =>
          resident.profile.phone?.canReceiveSMS &&
          resident.smsConsent?.status !== 'granted'
      );
    }

    const records = selectedResidents.map((resident: Resident) => {
      return {
        id: resident.id,
        name: resident.profile.fullName,
        email: resident.profile.email,
        streamId: resident.streamId,
        isOnlyReceiveText:
          !resident.profile.email && resident.smsConsent?.status === 'granted'
      };
    });

    return records;
  };

  const handleSelectAll = () => {
    if (selectAll) {
      dispatch(resetSelectedResidents());
    } else {
      dispatch(
        selectAllResidents(
          residents.reduce(
            (source, resident) => ({
              ...source,
              [resident.id]: {
                id: resident.id,
                name: resident.profile.fullName
              }
            }),
            {}
          )
        )
      );
    }
  };

  const handleCloseAlert = () => {
    setSnackMessage(null);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    dispatch(setPage(page));
    dispatch(resetSelectedResidents());
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(setRowsPerPage(Number(event.target.value)));
    dispatch(resetSelectedResidents());
  };

  const handleSort =
    (columnName: string) => (_event: React.MouseEvent<unknown>) => {
      let columnOrder: 'asc' | 'desc';

      if (columnName === currentColumnName) {
        if (currentColumnOrder === 'asc') {
          columnOrder = 'desc';
        } else {
          columnOrder = 'asc';
        }
      } else {
        columnOrder = 'asc';
      }

      dispatch(setSort({ columnName, columnOrder }));
    };

  const handleApplyPropertyFilter = (selections: FilterOption[]) => {
    removeQueryParamFilters();

    dispatch(setProperties(selections));
    dispatch(resetSelectedResidents());
  };

  const handleApplyBuildingFilter = (selections: FilterOption[]) => {
    removeQueryParamFilters();

    dispatch(setBuildings(selections));
    dispatch(resetSelectedResidents());
  };

  const handleApplyStatusFilter = (selections: FilterOption[]) => {
    removeQueryParamFilters();

    dispatch(setStatuses(selections));
    dispatch(resetSelectedResidents());
  };

  const handleApplyLeaseEndDateFilter = (
    startDate: Date | null,
    endDate: Date | null,
    range: string
  ) => {
    const newStartDate = startDate
      ? timeService.get(startDate.toDateString()).format()
      : null;
    const newEndDate = endDate
      ? timeService.get(endDate.toDateString()).format()
      : null;

    removeQueryParamFilters();

    dispatch(
      setLeaseEnd({
        after: newStartDate,
        before: newEndDate,
        range: range
      })
    );
    dispatch(resetSelectedResidents());
    setFilterDateError({});
  };

  const handleStackAction = () => {
    const streamIds: string[] = residents
      .filter((resident: Resident) => selectedResidents[resident.id])
      .map((res: Resident) => res.streamId);
    openCarousel(streamIds);
  };

  const handleEmailAction = () => {
    setDefaultEmailSubject('');
    setDefaultEmailContent('');
    setDisableCCSupport(false);
    setupSelectedResidents();

    setShowEmailModal(true);
  };

  const handleTextAction = () => {
    setShowTextModal(!showTextModal);
  };

  const handleCloseEmailModal = (fetchData?: boolean, smsOpen = false) => {
    setShowEmailModal(false);
    setEnableSmsOptIn(false);

    if (fetchData && !smsOpen) {
      refetch();
      dispatch(resetSelectedResidents());
    }
    if (smsOpen) {
      setShowTextModal(true);
    }
  };

  const handleSmsOptInAction = () => {
    setShowSmsOptInModal(!showSmsOptInModal);
  };

  const handleCloseSmsOptInModal = (insertLink?: boolean) => {
    setShowSmsOptInModal(!showSmsOptInModal);

    if (insertLink) {
      setEnableSmsOptIn(true);
      setDefaultEmailSubject(SMS_OPTIN_SUBJECT);
      setDefaultEmailContent(SMS_OPTIN_CONTENT);
      setDisableCCSupport(true);
      setupSelectedResidentsData(true);

      setShowEmailModal(true);
    }
  };

  const handleCloseTextModal = (fetchData?: boolean, emailOpen = false) => {
    setShowTextModal(false);

    if (fetchData && !emailOpen) {
      refetch();
      dispatch(resetSelectedResidents());
    }
    if (emailOpen) {
      handleEmailAction();
    }
  };

  const handleCloseAddResidentModal = (fetchData?: boolean) => {
    setShowAddResidentModal(false);

    if (fetchData) {
      refetch();
      dispatch(resetSelectedResidents());
    }
  };

  const handleResetFilters = () => {
    dispatch(setFilters(initialResidentFilter));
  };

  const dateValidator = (startDate: Date | null, endDate: Date | null) => {
    let isValid = true;

    if (startDate === null && endDate === null) {
      return isValid;
    }

    let errorState: FilterDateError = {};

    if (startDate !== null && !dayjs(startDate).isValid()) {
      isValid = false;
      errorState.startDateError = 'Please provide a valid date';
    }

    if (endDate !== null && !dayjs(endDate).isValid()) {
      isValid = false;
      errorState.endDateError = 'Please provide a valid date';
    }
    setFilterDateError(errorState);
    return isValid;
  };

  const handleCancelDateFilter = () => {
    setFilterDateError({});
  };

  const filters = [
    <ChipSelect
      defaultFilter={propertyFilters}
      filterState={Object.keys(properties).map(
        (key: string) => properties[key]
      )}
      chipLabel="Property"
      optionLabelSelector={(nodes: FilterOption[]) => nodes[0].label}
      onApply={handleApplyPropertyFilter}
      manualSelection={true}
      hideSelectAll={true}
    />,
    <ChipSelect
      defaultFilter={buildingFilters}
      filterState={Object.keys(buildings).map((key: string) => buildings[key])}
      chipLabel="Building"
      optionLabelSelector={(nodes: FilterOption[]) => nodes[0].label}
      onApply={handleApplyBuildingFilter}
      manualSelection={true}
      hideSelectAll={true}
    />,
    <ChipSelect
      defaultFilter={statusFilters}
      filterState={Object.keys(statuses).map((key: string) => statuses[key])}
      chipLabel="Stage"
      optionLabelSelector={(nodes: FilterOption[]) => nodes[0].label}
      onApply={handleApplyStatusFilter}
    />,
    <ChipSelectDateRange
      filterOptions={leaseEndFilters}
      startDate={leaseEndAfter ? dayjs(leaseEndAfter).toDate() : null}
      endDate={leaseEnd ? dayjs(leaseEnd).toDate() : null}
      range={leaseEndRange}
      chipLabel="Lease End"
      onApply={handleApplyLeaseEndDateFilter}
      useFutureDates={true}
      disableApply={false}
      allowEmptyDates
      dateValidator={dateValidator}
      onChange={handleCancelDateFilter}
      onCancel={handleCancelDateFilter}
      {...filterDateError}
    />
  ];

  const selectedResidentsForBulkEmailCount = residents.filter(
    (resident: Resident) =>
      selectedResidents[resident.id] && resident.profile?.email
  ).length;

  const selectedResidentsForBulkSMSCount = residents.filter(
    (resident: Resident) =>
      selectedResidents[resident.id] &&
      resident.smsConsent?.status === 'granted'
  ).length;

  const numberOfResidentsForSmsOptin = residents.filter(
    (resident: Resident) =>
      selectedResidents[resident.id] &&
      resident.profile?.email &&
      resident.profile?.phone?.canReceiveSMS &&
      resident.smsConsent?.status !== 'granted'
  ).length;

  const noSelectionsTooltip = 'No residents selected';

  const actions: IAction[] = [
    {
      label: 'Stack',
      value: ProspectActions.Stack,
      selectedItemsCount: selectedResidentsCount,
      icon: <PeopleStackIcon />,
      onClick: handleStackAction,
      tooltip:
        selectedResidentsCount === 0
          ? noSelectionsTooltip
          : 'Quickly navigate between selected resident cards',
      id: 'stack-action'
    },
    {
      label: 'Email',
      value: ProspectActions.EMail,
      disabled: selectedResidentsForBulkEmailCount === 0,
      selectedItemsCount: selectedResidentsForBulkEmailCount,
      icon: <EnvelopeIcon />,
      onClick: handleEmailAction,
      tooltip:
        selectedResidentsCount === 0
          ? noSelectionsTooltip
          : selectedResidentsCount > 0 &&
            selectedResidentsForBulkEmailCount === 0
          ? 'Residents cannot receive email'
          : 'Email residents',
      id: 'email-action'
    },
    {
      label: 'Text',
      value: ProspectActions.Text,
      selectedItemsCount: selectedResidentsForBulkSMSCount,
      icon: <PhoneSMSIcon />,
      onClick: handleTextAction,
      tooltip:
        selectedResidentsCount === 0
          ? noSelectionsTooltip
          : selectedResidentsCount > 0 && selectedResidentsForBulkSMSCount === 0
          ? 'Residents cannot receive SMS'
          : 'Text residents',
      id: 'sms-action'
    },
    {
      label: 'SMS Opt In',
      value: ProspectActions.SMSOptIn,
      disabled: numberOfResidentsForSmsOptin === 0,
      selectedItemsCount: numberOfResidentsForSmsOptin,
      icon: <SMSOptInIcon />,
      onClick: handleSmsOptInAction,
      tooltip:
        selectedResidentsCount === 0
          ? noSelectionsTooltip
          : numberOfResidentsForSmsOptin === 0
          ? 'Residents are opted In or cannot receive SMS'
          : 'Send SMS Opt In email',
      id: 'sms-optin-action'
    }
  ];

  interface HeadCell {
    field: string;
    label: string;
    sortable?: boolean;
    hide?: boolean;
    disablePadding?: boolean;
  }

  const headCells: HeadCell[] = [
    { field: 'name', disablePadding: true, label: 'Name', sortable: true },
    { field: '', label: 'Contact' },
    { field: 'todo_status', label: 'To Do', sortable: true },
    { field: 'status', label: 'Stage', sortable: true },
    { field: 'manager', label: 'Owner', sortable: true },
    {
      field: 'last_contact',
      label: 'Last Contact',
      sortable: true
    },
    {
      field: 'property',
      label: 'Property',
      hide: propertyFilters.length <= 1
    },
    { field: 'building', label: 'Building', sortable: true },
    { field: 'unit', label: 'Unit', sortable: true },
    { field: 'lease_start', label: 'Lease Start', sortable: true },
    { field: 'lease_end', label: 'Lease End', sortable: true }
  ];

  const visibleColumns =
    headCells.filter((item: HeadCell) => !item.hide).length + 1;

  return (
    <Box
      className={classes.mainContainer}
      data-testid="resident-page-container"
    >
      {snackMessage && (
        <SnackbarMessage
          handleCloseAlert={handleCloseAlert}
          snackMessage={snackMessage}
        />
      )}
      {showEmailModal && (
        <BulkEmailModal
          open={showEmailModal}
          closeModal={handleCloseEmailModal}
          records={selectedResidentsData}
          context="Residents"
          defaultSubject={defaultEmailSubject}
          defaultContent={defaultEmailContent}
          emailDomainBlocklist={emailDomainBlocklist}
          disableCCSupport={disableCCSupport}
          setSnackMessage={setSnackMessage}
        />
      )}

      <AddResidentModal
        open={showAddResidentModal}
        closeModal={handleCloseAddResidentModal}
      />
      <ResidentTextActionModal
        closeModal={handleCloseTextModal}
        openModal={showTextModal}
      />

      <SmsOptInModal
        openModal={showSmsOptInModal}
        closeModal={handleCloseSmsOptInModal}
      />

      <Box className={classes.header}>
        <Text variant="h5" className={commonClasses.headerText}>
          Residents
        </Text>

        {allowCreateResident && (
          <Button
            id="add-resident-button"
            onClick={() => setShowAddResidentModal(true)}
            data-testid="add-resident-button"
            className={`${commonClasses.button} ${commonClasses.buttonWithIcon} ${commonClasses.primaryButton}`}
          >
            <AddCircleOutline />
            Resident
          </Button>
        )}
      </Box>

      <ActionBar
        filters={filters}
        actions={actions}
        className={classes.actionBar}
      />

      <Paper elevation={0} className={classes.tableContainer}>
        <TableContainer id={'tbl-container'}>
          <Table size="medium" className={classes.table}>
            <TableHead
              className={commonClasses.tableHead}
              id="resident-table-header"
            >
              <TableRow>
                <TableCell
                  padding="checkbox"
                  className={`${commonClasses.inputCheckbox} ${commonClasses.stickyColumnOne}`}
                >
                  <InputCheckbox
                    color="primary"
                    inputProps={{
                      'aria-label': 'select-all-residents'
                    }}
                    checked={selectAll}
                    indeterminate={selectAllIndeterminate}
                    onChange={handleSelectAll}
                  />
                </TableCell>

                {headCells.map((headCell, index) => {
                  if (headCell.hide) {
                    return null;
                  } else {
                    const labelElement = (
                      <Text variant="subtitle2">{headCell.label}</Text>
                    );

                    return (
                      <TableCell
                        key={index}
                        className={`${
                          index === 0 ? commonClasses.stickyColumnTwo : ''
                        }`}
                        padding={!!headCell.disablePadding ? 'none' : 'default'}
                      >
                        {headCell.sortable && (
                          <TableSortLabel
                            active={currentColumnName === headCell.field}
                            direction={
                              currentColumnName === headCell.field
                                ? currentColumnOrder
                                : 'asc'
                            }
                            onClick={handleSort(headCell.field)}
                          >
                            {labelElement}
                          </TableSortLabel>
                        )}

                        {!headCell.sortable && labelElement}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>

            <TableBody id={'tbl-data'}>
              {residentsLoading ? (
                <>
                  {loaders.map((_item: number, index: number) => (
                    <TableRow key={index} data-testid="loader-row">
                      <TableCell colSpan={16} className="rowLoader">
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          className={classes.loadingIndicator}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {residents.length === 0 && (
                    <TableRow data-testid="prospect-row-no-data">
                      <TableCell colSpan={visibleColumns}>
                        <Box className={commonClasses.noResultsContainer}>
                          {noResultsIcon}

                          <Text
                            variant="h6"
                            className={commonClasses.noResultsHeader}
                          >
                            {noResultsHeader}
                          </Text>
                          <Text
                            variant="body2"
                            className={commonClasses.noResultsText}
                          >
                            {noResultsText}
                          </Text>

                          {!hasDefaultFilters && (
                            <Button
                              color="primary"
                              onClick={handleResetFilters}
                              data-testid="reset-button"
                              variant="text"
                            >
                              Reset Filters
                            </Button>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}

                  {residents.map((resident: Resident) => (
                    <ResidentRow
                      key={resident.id}
                      resident={resident}
                      selected={!!selectedResidents[resident.id]}
                    />
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box className={classes.paginationContainer}>
          {residentsLoading ? (
            <Box className={classes.paginationLoader}>
              <Skeleton
                variant="rect"
                animation="wave"
                className={`${classes.loadingIndicator} ${classes.paginationLoadingIndicator}`}
              />
            </Box>
          ) : (
            <TablePagination
              rowsPerPageOptions={[100, 500, 1000]}
              component="div"
              count={pageData.count}
              rowsPerPage={payload.per_page}
              page={page - 1}
              labelDisplayedRows={({ from, to, count }) =>
                `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`
              }
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              classes={{
                menuItem: classes.rowsPerPageMenu
              }}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default Residents;
