/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('toggleBrowserNotifications', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/browserNotifications/toggle-browser-notifications.html',
      controller: 'ToggleBrowserNotificationsController'
    };
  });

  const ToggleBrowserNotificationsController = function (
    $scope,
    $mdToast,
    browserNotificationsService
  ) {
    var self = this;

    $scope.desktopNotificationsUnavailable = null;

    self.initialize = function () {
      self.notificationsRequestStatus();
    };

    self.notificationsRequestStatus = function () {
      if (!('serviceWorker' in navigator)) {
        $scope.desktopNotificationsEnabled = false;
        $scope.desktopNotificationsUnavailable = true;
      } else {
        try {
          navigator.permissions
            .query({ name: 'notifications' })
            .then(function (result) {
              if (
                result.state === 'granted' &&
                browserNotificationsService.getTokenSentToServer()
              ) {
                $scope.desktopNotificationsEnabled = true;
              } else {
                $scope.desktopNotificationsEnabled = false;
              }
            });
        } catch (e) {
          $scope.desktopNotificationsEnabled = false;
          console.log(e);
        }
      }
    };

    self.saveNotificationPreference = function () {
      $mdToast.show(
        $mdToast
          .simple()
          .content(
            $scope.desktopNotificationsEnabled
              ? 'Notifications on'
              : 'Notifications off'
          )
          .position('top right')
      );
    };

    self.saveNotificationPreferenceError = function () {
      $mdToast.show(
        $mdToast
          .simple()
          .content('Notifications setting could not be saved')
          .position('top right')
      );
    };

    $scope.browserNotificationsToggle = function () {
      browserNotificationsService
        .toggleNotifications($scope.desktopNotificationsEnabled)
        .then(function () {
          $scope.desktopNotificationsEnabled =
            !$scope.desktopNotificationsEnabled;
          self.saveNotificationPreference();
        })
        .catch(function (err) {
          console.log(err);
          $scope.desktopNotificationsEnabled = false;
          $scope.desktopNotificationsUnavailable = true;
          self.saveNotificationPreferenceError();
        });
    };

    self.initialize();

    return self;
  };

  ToggleBrowserNotificationsController.$inject = [
    '$scope',
    '$mdToast',
    'browserNotificationsService'
  ];

  app.controller(
    'ToggleBrowserNotificationsController',
    ToggleBrowserNotificationsController
  );
})(window.angular);
