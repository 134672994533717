/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('pricingApi', [
    'apiBase',
    function (apiBase) {
      return {
        getPropertyPricing: function (propertyId) {
          return apiBase
            .get('/admin/pricing/properties?pid=' + propertyId)
            .then((response) => {
              if (response.status < 200 || response.status > 299) {
                throw new Error(response.statusText);
              } else {
                return response;
              }
            })
            .catch((e) => {
              console.error(
                `Error fetching pricing data for property ${propertyId}: ${e}`
              );
              throw new Error(e);
            });
        }
      };
    }
  ]);
})(window.angular);
