import includes from 'lodash/includes';
import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import moment from 'moment';
import some from 'lodash/some';

(function (angular) {
  var app = angular.module('knockApp');

  const shownUnitsModalService = function ($mdDialog) {
    var self = this;

    self.openShownUnitsModal = function (prospect, event) {
      return $mdDialog.show({
        controller: 'ShownUnitsModalController',
        templateUrl: '/angular/views/userInteractions/shown-units-modal.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          prospect: prospect,
          prospectEvent: event
        }
      });
    };

    return self;
  };
  shownUnitsModalService.$inject = ['$mdDialog'];
  app.factory('shownUnitsModalService', shownUnitsModalService);

  const ShownUnitsModalController = function (
    $q,
    $rootScope,
    $scope,
    $mdDialog,
    $mdToast,
    appDataService,
    prospectsApi,
    appointmentsApi,
    visitsApi,
    visitsService,
    propertyApi,
    prospect,
    prospectEvent,
    unitApi
  ) {
    var self = this;

    $scope.data = {
      prospect: prospect,
      property: appDataService.getProperty(prospect.property_id),
      useNewUnitsFeature: $rootScope.featureFlags.NEW_UNITS || false,
      layoutsById: {},
      buildingsById: {},
      showBuildings: false
    };

    $scope.filters = {
      layouts: [],
      buildings: [],
      occupied: [
        { label: 'All', value: null },
        { label: 'Occupied', value: true },
        { label: 'Vacant', value: false }
      ],
      type: [
        { label: 'All', value: null },
        { label: 'Model', value: 'model' },
        { label: 'Residential', value: 'residential' },
        { label: 'Commercial', value: 'commercial' },
        { label: 'Down', value: 'down' },
        { label: 'Employee', value: 'employee' },
        { label: 'Construction', value: 'construction' },
        { label: 'Office', value: 'office' },
        { label: 'Other', value: 'other' }
      ],
      selections: {
        layoutId: null,
        occupied: false,
        buildingId: null,
        type: null
      }
    };

    $scope.editor = {
      state: 'selecting'
    };

    $scope.isSaving = false;
    $scope.isLoading = false;

    self._initialize = function () {
      return $scope.data.useNewUnitsFeature
        ? self._getAvailableUnits()
        : self._getAvailablePropertyUnits();
    };

    // Filters for displaying table values
    $scope.displayableValue = function (value, fallback) {
      if (!value && !fallback) {
        return '-';
      }

      return value ? value : fallback;
    };

    $scope.displayableBuildingName = function (unit) {
      let buildingName = '-';
      if (unit && unit.buildingId) {
        buildingName = get(
          $scope,
          `data.buildingsById.${unit.buildingId}.name`,
          '-'
        );
      }
      return buildingName;
    };

    $scope.displayableLayoutName = function (unit) {
      let layoutName = '-';
      if (unit && unit.layoutId) {
        layoutName = get($scope, `data.layoutsById.${unit.layoutId}.name`, '-');
      }
      return layoutName;
    };

    $scope.displayableOccupancy = function (unit) {
      if (!unit) {
        return '-';
      }
      return unit.occupied ? 'Occupied' : 'Vacant';
    };

    $scope.displayablePrice = (unit) => {
      if (!unit) {
        return null;
      }
      return unit.displayPrice && !isNaN(unit.displayPrice)
        ? Number(unit.displayPrice)
        : null;
    };

    // Enable custom sorts for st-table
    $scope.getters = {
      area: function (unit) {
        return $scope.displayableValue(unit.area, '-');
      },
      availableOn: function (unit) {
        if (!unit || !unit.availableOn || !moment(unit.availableOn).isValid) {
          return '-';
        }
        return moment(unit.availableOn);
      },
      bedrooms: function (unit) {
        return `${unit.bedrooms} bd, ${unit.bathrooms} ba`;
      },
      buildingName: function (unit) {
        return $scope.displayableBuildingName(unit);
      },
      name: function (unit) {
        return $scope.displayableValue(unit.name, 'None');
      },
      layoutName: function (unit) {
        return $scope.displayableLayoutName(unit);
      },
      occupancy: function (unit) {
        return $scope.displayableOccupancy(unit);
      },
      price: function (unit) {
        return $scope.displayablePrice(unit);
      },
      type: function (unit) {
        // if unit.type is '-', we want to display it at the bottom of the list
        // on initial alphabetical sort
        const type = $scope.displayableValue(unit.type, '-');
        return type === '-' ? 'zzz' : type;
      }
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.setEditorState = function (newState) {
      $scope.editor.state = newState;
    };

    $scope.saveSelectedUnits = function () {
      const selectedListings = function (unitListing) {
        return unitListing.selected;
      };

      const addedListings = function (unitListing) {
        return !unitListing.locked;
      };

      const selectedUnitListings = filter(
        $scope.unitListings,
        selectedListings
      );
      const addedUnitListings = filter(selectedUnitListings, addedListings);

      if (addedUnitListings.length === 0) {
        if (
          $scope.data.prospect.integrations &&
          some($scope.data.prospect.integrations, { vendor: 'yardi' })
        ) {
          $scope.editor.state = 'warning';
        } else {
          $mdDialog.cancel();
        }

        return;
      }

      // ids for appointments
      const selectedUnitListingIds = map(selectedUnitListings, 'listing_id');

      // ids for legacy unit listing / property unit
      const selectedPropertyUnitIds = map(
        selectedUnitListings,
        'property_unit_id'
      );

      // new unit service ids
      const selectedUnitIds = map(selectedUnitListings, 'id');
      const savePromises = [];

      if (prospectEvent.event_type === 'appointment') {
        savePromises.push(
          self._setAppointmentPropertyUnits(selectedUnitListingIds)
        );
      } else if (prospectEvent.event_type === 'visit') {
        if ($scope.data.useNewUnitsFeature) {
          savePromises.push(self._setVisitUnits(selectedUnitIds));
        } else {
          savePromises.push(
            self._setVisitPropertyUnits(selectedPropertyUnitIds)
          );
        }
      }

      if (
        $scope.data.property.Property.preferences.require_prospect_floorplan
      ) {
        savePromises.push(self._setProspectPreferredFloorplan());
      }

      $scope.isSaving = true;

      $q.all(savePromises)
        .then(
          function () {
            $mdDialog.hide(selectedUnitListings);
          },
          function () {
            $mdToast.showSimple('Error saving shown units.');
          }
        )
        .finally(function () {
          $scope.isSaving = false;
        });
    };

    $scope.filtersChanged = function () {
      return $scope.data.useNewUnitsFeature
        ? self._getAvailableUnits()
        : self._getAvailablePropertyUnits();
    };

    // New Units flow
    self._getAvailableUnits = () => {
      $scope.isLoading = true;
      // the API response includes "occupied" as a key, instead of "occupancy"
      // but the endpoint filters by "occupancy"- so include both
      const occupancyValue = !$scope.filters.selections.occupied
        ? $scope.filters.selections.occupied === false
          ? 'vacant'
          : null
        : 'occupied';

      const params = {
        propertyId: prospect.property_id,
        occupied: $scope.filters.selections.occupied,
        occupancy: occupancyValue,
        buildingId: $scope.filters.selections.buildingId,
        layoutId: $scope.filters.selections.layoutId,
        type: $scope.filters.selections.type
      };
      unitApi
        .getUnits(params)
        .success(self._getAvailableUnitsSuccess)
        .error(self._loadError)
        .finally(() => ($scope.isLoading = false));
    };

    self._getAvailableUnitsSuccess = function (response) {
      if (!!response.layouts.length && $scope.filters.layouts.length < 1) {
        $scope.data.layoutsById = keyBy(response.layouts, 'id');
        $scope.filters.layouts = response.layouts.map((layout) => ({
          label: `${layout.name} - ${layout.bedrooms || null} bd - ${
            layout.bathrooms || null
          } ba - ${layout.area || null} sq.ft. `,
          value: layout.id
        }));
      }

      $scope.data.showBuildings = !!response.buildings.length;
      if (!!response.buildings.length && $scope.filters.buildings.length < 1) {
        $scope.data.buildingsById = keyBy(response.buildings, 'id');
        $scope.filters.buildings = response.buildings.map((building) => ({
          label: building.name,
          value: building.id
        }));
      }

      const selectedUnitIds = map(
        prospectEvent.visit_property_units ||
          prospectEvent.appointment_property_units,
        'unit_id'
      );

      const selectedUnit = function (unit) {
        unit.selected = includes(selectedUnitIds, unit.id);
        unit.locked = unit.selected;
        return unit;
      };
      $scope.unitListings = map(response.units, selectedUnit);
      $scope.unitListingsCopy = $scope.unitListings.slice();
    };

    // Legacy Property Units workflow
    self._getAvailablePropertyUnits = () => {
      $scope.isLoading = true;
      propertyApi
        .getPropertyUnitListings(prospect.property_id)
        .success(self._getAvailablePropertyUnitsSuccess)
        .error(self._loadError)
        .finally(() => ($scope.isLoading = false));
    };

    self._getAvailablePropertyUnitsSuccess = function (response) {
      var selectedPropertyUnitIds = map(
        prospectEvent.visit_property_units ||
          prospectEvent.appointment_property_units,
        'property_unit_id'
      );

      var selectedUnitListing = function (unitListing) {
        unitListing.selected = includes(
          selectedPropertyUnitIds,
          unitListing.property_unit_id
        );
        unitListing.locked = unitListing.selected;
        return unitListing;
      };

      $scope.unitListings = map(response.unit_listings, selectedUnitListing);
      $scope.unitListingsCopy = $scope.unitListings.slice();
    };

    self._setAppointmentPropertyUnits = function (selectedUnitListingIds) {
      return appointmentsApi.setShownListingsForAppointment(
        prospectEvent.id,
        selectedUnitListingIds
      );
    };

    self._setVisitUnits = function (selectedUnitIds) {
      return visitsApi
        .setVisitShownUnits(prospectEvent.id, selectedUnitIds)
        .then(function () {
          visitsService.onShownUnitsAdded(prospectEvent.id, selectedUnitIds);
        });
    };

    self._setVisitPropertyUnits = function (selectedPropertyUnitIds) {
      return visitsApi
        .setVisitPropertyUnits(prospectEvent.id, selectedPropertyUnitIds)
        .then(function () {
          visitsService.onShownUnitsAdded(
            prospectEvent.id,
            selectedPropertyUnitIds
          );
        });
    };

    self._setProspectPreferredFloorplan = function () {
      return prospectsApi.updateProspect($scope.data.prospect.id, {
        preferences: $scope.data.prospect.preferences
      });
    };

    self._initialize();
  };

  ShownUnitsModalController.$inject = [
    '$q',
    '$rootScope',
    '$scope',
    '$mdDialog',
    '$mdToast',
    'appDataService',
    'prospectsApi',
    'appointmentsApi',
    'visitsApi',
    'visitsService',
    'propertyApi',
    'prospect',
    'prospectEvent',
    'unitApi'
  ];
  app.controller('ShownUnitsModalController', ShownUnitsModalController);
})(window.angular);
