/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const marketingBannerService = function (
    $rootScope,
    $auth,
    $timeout,
    $route,
    apiBase,
    localStorageService
  ) {
    var self = this;

    $rootScope.$on('$routeChangeStart', function (event, nextRoute) {
      self.state.visible = nextRoute.showMarketingBanner && self.shouldShow();
    });

    self.state = {
      visible: false,
      text: '',
      backgroundColor: '',
      url: '',
      id: '',
      height: 50
    };

    self.initialize = function () {
      if ($auth.isAuthenticated()) {
        return;
      }

      if (
        !$route.current ||
        ($route.current && $route.current.loadedTemplateUrl == '/404.html')
      ) {
        return;
      }

      self.getMarketingMessage().then(function (response) {
        if (!response.data.message) {
          return;
        }

        self.state.text = response.data.message.text;
        self.state.backgroundColor = response.data.message.background_color;
        self.state.url = response.data.message.url;
        self.state.id = response.data.message.message_id;

        self.state.visible =
          $route.current.$$route.showMarketingBanner &&
          self.state.text &&
          !localStorageService.get(self._getHasViewedKey());
      });
    };

    self.getMarketingMessage = function () {
      return apiBase.get('/globals/marketing-message');
    };

    self._getHasViewedKey = function () {
      return 'knock-marketing-banner-viewed-' + self.state.id;
    };

    self.shouldShow = function () {
      return (
        !$auth.isAuthenticated() &&
        self.state.text &&
        !localStorageService.get(self._getHasViewedKey())
      );
    };

    self.close = function () {
      self.state.visible = false;

      localStorageService.set(self._getHasViewedKey(), true);
    };

    self.initialize();

    return self;
  };

  marketingBannerService.$inject = [
    '$rootScope',
    '$auth',
    '$timeout',
    '$route',
    'apiBase',
    'localStorageService'
  ];
  app.factory('marketingBannerService', marketingBannerService);
  app.directive('marketingBanner', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/landing/marketing-banner.html',
      controller: 'MarketingBannerController',
      scope: {}
    };
  });

  const MarketingBannerController = function ($scope, marketingBannerService) {
    $scope.banner = marketingBannerService.state;
    $scope.close = marketingBannerService.close;
  };

  MarketingBannerController.$inject = ['$scope', 'marketingBannerService'];
  app.controller('MarketingBannerController', MarketingBannerController);
})(window.angular);
