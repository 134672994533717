import angular from 'angular';
import map from 'lodash/map';
import some from 'lodash/some';

const app = angular.module('knock-Todo');

app.directive('residentTodoCard', function () {
  return {
    restrict: 'E',
    templateUrl: '/angular/views/todo/resident-todo-card.html',
    controller: 'ResidentTodoCardController',
    scope: {
      residents: '=',
      icon: '@?',
      iconText: '@?',
      iconColor: '=?'
    }
  };
});

app.controller('ResidentTodoCardController', [
  '$scope',
  'propertyReportsService',
  'streamCarouselService',
  'todoService',
  'messagingModalFactory',
  '$mdDialog',
  '$rootScope',
  'managerApi',
  'attachmentService',
  'conversationsService',
  '$auth',
  'appDataService',
  'userService',
  function (
    $scope: any,
    propertyReportsService: any,
    streamCarouselService: any,
    todoService: any,
    messagingModalFactory: any,
    $mdDialog: any,
    $rootScope: any,
    managerApi: any,
    attachmentService: any,
    conversationsService: any,
    $auth: any,
    appDataService: any,
    userService: any
  ): void {
    $scope.updateManagerQuickReply = managerApi.updateManagerQuickReply;
    $scope.saveQuickReply = managerApi.updateManagerQuickReply;
    $scope.deleteManagerQuickReply = managerApi.deleteManagerQuickReply;
    $scope.handleAttachments = attachmentService.uploadByFile;
    $scope.deleteAttachment = attachmentService.deleteAttachment;
    $scope.sendBatchMessage = conversationsService.sendMessageBatch;
    $scope.deleteQuickReply = managerApi.deleteManagerQuickReply;
    $scope.emailDomainBlocklist = $rootScope.emailDomainBlocklist;

    $scope.subject = 'Lease Renewal';
    $scope.title = `Send individual emails to residents in ${$scope.residents[0]['property_unit']}`;

    $scope.token = $auth.getToken();
    $scope.managerInfo = {};
    $scope.mergeTags = [];

    $scope.getManagerQuickReplies = function () {
      return managerApi.getManagerQuickRepliesBulkMessaging(
        appDataService.getCurrentLeasingTeamId()
      );
    };

    $scope.preSelectedResidents = $scope.residents.map((resident: any) => {
      return {
        id: resident.id,
        name: resident.name,
        email: resident.profile.email,
        streamId: resident.stream_id
      };
    });

    $scope.cheatProofEngagementScoreEnabled =
      $rootScope.appPreferences.company.enable_cheatproof_engagement_score;
    $scope.isCompanyQuickRepliesEnabled =
      $rootScope.featureFlags.COMPANY_QUICK_REPLIES;

    $scope.emailAttachments = $scope.emailAttachments || {
      local: [],
      remote: []
    };

    $scope.openModal = false;
    $scope.iconColor = $scope.iconColor || 'red';

    $scope.viewResidents = function () {
      propertyReportsService.viewResidentDetails(
        $scope.residents.map((res: any): any => res.id)
      );
    };

    $scope.closeModal = () => {
      $scope.$apply(() => {
        $scope.openModal = false;
      });
    };

    const teamMembers = appDataService.getTeamMembers().map((member: any) => {
      return {
        id: member.Manager.id,
        name: member.ManagerInfo.first_name + ' ' + member.ManagerInfo.last_name
      };
    });
    $scope.managerInfo = teamMembers.find(
      (member: any) => member.id === userService.getScopedUser().id
    );

    const tags = appDataService.getEmailMergeTags('prospect');

    $scope.mergeTags = tags.map((tag: any) => {
      tag.value = `#${tag.name}`;
      return Object.assign({}, tag);
    });

    $scope.openUnitResidentEmailModal = function ($event: any) {
      $event.stopPropagation();

      if ($event.target.textContent === 'View') {
        return;
      }

      let recipients = map($scope.residents, function (resident) {
        return {
          id: resident.id,
          threadId: resident.stream_id,
          info: resident.profile,
          name: resident.name,
          addedBy: resident.added_by,
          sms_consent: resident.sms_consent,
          property_unit: resident.property_unit,
          is_included: true
        };
      });
      if (
        !some(recipients, function (recipient) {
          return recipient.info.email && recipient.info.email !== '';
        })
      ) {
        const alert = $mdDialog.alert({
          title: 'No Email Addresses Found',
          content:
            'No residents in this unit have email addresses in the system.',
          ok: 'Close'
        });
        return $mdDialog.show(alert);
      } else {
        $scope.openModal = true;
      }
    };

    $scope.onOpenCarousel = function ($event: any): any {
      $event.stopPropagation();

      (async function openCarousel() {
        if ($event.target.textContent === 'View') {
          return;
        }
        await streamCarouselService.showCarousel(
          $scope.residents.map((res: any): any => res.stream_id)
        );
        todoService.reloadDashboard();
      })();
    };
  }
]);
