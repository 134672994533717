/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('signupService', [
    '$rootScope',
    '$auth',
    'apiBase',
    '$location',
    'apiEvents',
    'chatTrackingService',
    function (
      $rootScope,
      $auth,
      apiBase,
      $location,
      apiEvents,
      chatTrackingService
    ) {
      var self = this;

      var privateBrowsingRegex = /QuotaExceededError/;

      self.getErrorMessages = function () {
        return {
          errorMessage: "We're having a problem creating your account.",
          usernameTakenErrorMessage:
            'There is already an account using that email address.',
          privateBrowsingErrorMessage:
            'An error occurred. Please ensure private browsing is turned off'
        };
      };

      self.isPrivateBrowsingError = function (response) {
        return privateBrowsingRegex.test(response.message);
      };

      self.socialSignup = function (provider) {
        return $auth
          .authenticate(provider, { client_type: 'web' })
          .then(self._emitLoginEvent);
      };

      self.emailSignup = function (user) {
        return $auth.signup(user).then(function (response) {
          $auth.setToken(response.data.token);
          self._emitLoginEvent();
        });
      };

      self.emailSignupNoRedirect = function (user) {
        return apiBase
          .post('/auth/register', user)
          .success(function (response) {
            $auth.setToken(response.token, $location.path());
            $rootScope.$emit(apiEvents.profileUpdated);
            self._emitLoginEvent();
          });
      };

      self._emitLoginEvent = function () {
        $rootScope.$emit(apiEvents.loggedIn);
      };

      return self;
    }
  ]);
})();
