import React, { FC, useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';

import {
  Box,
  makeStyles,
  Text,
  NamedColors
} from '@knockrentals/knock-shared-web';
import { LeasingCard } from './LeasingCard';
import {
  Property,
  UtilityType,
  LeasingLength,
  LeasingSection,
  LeasingSubsection
} from '../models';
import {
  LeaseLengthIcon,
  LeaseDocumentIcon,
  DollarIcon,
  LeasingUtilitiesIcon,
  LeasingPetIcon,
  GreenCheckIcon,
  BlankLeasingTabIcon
} from '../../icons';
import LeasingBinderNoContent from '../LeasingBinderNoContent';

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px'
  },

  columns: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    width: '100%'
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '33.33%'
  },

  card: {
    border: `1px solid ${NamedColors.slate[100]}`,
    marginBottom: '16px',

    '&:last-child': {
      marginBottom: 0
    },

    '& .MuiCardContent-root:last-child': {
      paddingBottom: '16px'
    },

    '& .MuiListItem-root:last-child': {
      paddingBottom: 0
    }
  },

  utilitiesCard: {
    '& .leasing-card-header': {
      marginBottom: 0
    }
  },

  utilitiesCost: {
    marginTop: '8px'
  },

  chip: {
    backgroundColor: NamedColors.grass[50],
    fontSize: '13px',
    height: 'auto',
    marginRight: '8px',
    marginTop: '8px',
    textTransform: 'capitalize',

    '& .MuiChip-label': {
      padding: '6px 10px 6px 10px',
      whiteSpace: 'pre-wrap'
    },

    '&:last-child': {
      marginRight: 0
    },

    '& svg': {
      marginLeft: '8px',
      minWidth: '16px',
      width: '16px'
    },

    '& svg path': {
      stroke: NamedColors.grass[900]
    }
  },

  subsection: {
    marginBottom: '12px',

    '&:last-child': {
      marginBottom: 0
    }
  },

  subsectionTitle: {
    color: '#999999',
    marginBottom: '2px'
  },

  subsectionContent: {
    wordBreak: 'break-all'
  }
}));

interface LeasingBinderLeasingProps {
  property: Property;
}

interface SectionType {
  [key: string]: LeasingSection[];
}

export const LeasingBinderLeasing: FC<LeasingBinderLeasingProps> = ({
  property
}) => {
  const classes = useStyles();
  const [columnSections, setColumnSections] = useState<SectionType>({});

  const getFormattedCurrencyValue = (value: string): string => {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    const valueAsNumber = Number(value);

    if (Number.isNaN(valueAsNumber)) {
      return value;
    } else {
      return currencyFormatter.format(valueAsNumber);
    }
  };

  useEffect(() => {
    const getSection = (key: string) => {
      let section: LeasingSection | undefined;

      switch (key) {
        case 'leaseOverview':
          if (property.leasing.terms && property.leasing.terms.leaseLengths) {
            let hasLeaseTerms = false;
            let leaseLengthText = '';

            property.leasing.terms.leaseLengths.forEach(
              (leaseLength: LeasingLength) => {
                if (leaseLength.isAvailable) {
                  hasLeaseTerms = true;

                  leaseLengthText +=
                    leaseLengthText === ''
                      ? `${leaseLength.leaseLength}`
                      : `, ${leaseLength.leaseLength}`;
                }
              }
            );

            if (hasLeaseTerms) {
              if (leaseLengthText !== 'Month to month') {
                leaseLengthText += ' months';
              }

              section = {
                title: 'LEASING OVERVIEW',
                icon: <LeaseLengthIcon />,
                content: [
                  {
                    title: 'Terms',
                    content: leaseLengthText
                  }
                ]
              };
            }
          }

          break;
        case 'initialFees':
          if (
            property.leasing.application.fee ||
            property.leasing.terms.deposit
          ) {
            section = {
              title: 'INITIAL FEES',
              icon: <LeaseDocumentIcon />,
              content: new Array<LeasingSubsection>()
            };

            if (property.leasing.application.fee) {
              section.content.push({
                title: 'Application Fee',
                content: getFormattedCurrencyValue(
                  property.leasing.application.fee
                )
              });
            }

            if (property.leasing.terms.deposit) {
              section.content.push({
                title: 'Security Deposit',
                content: getFormattedCurrencyValue(
                  property.leasing.terms.deposit
                )
              });
            }
          }
          break;
        case 'utilities':
          if (
            property.utilities.estimatedCost ||
            (property.utilities.types &&
              property.utilities.types.find(
                (util: UtilityType) => util.included
              ))
          ) {
            const newUtilities = { ...property.utilities };

            if (property.utilities.estimatedCost) {
              newUtilities.estimatedCost = getFormattedCurrencyValue(
                newUtilities.estimatedCost
              );
            }

            section = {
              title: 'UTILITIES',
              icon: <LeasingUtilitiesIcon />,
              content: new Array<LeasingSubsection>(),
              utilitiesContent: newUtilities
            };
          }

          break;
        case 'pets':
          if (
            property.pets.deposit ||
            property.pets.fee ||
            property.pets.rent
          ) {
            section = {
              title: 'PET FEES',
              icon: <LeasingPetIcon />,
              content: new Array<LeasingSubsection>()
            };

            if (property.pets.deposit) {
              section.content.push({
                title: 'Pet Deposit',
                content: getFormattedCurrencyValue(property.pets.deposit)
              });
            }

            if (property.pets.fee) {
              section.content.push({
                title: 'Pet Fee (Non Refundable)',
                content: getFormattedCurrencyValue(property.pets.fee)
              });
            }

            if (property.pets.rent) {
              section.content.push({
                title: 'Pet Rent',
                content: getFormattedCurrencyValue(property.pets.rent)
              });
            }
          }

          break;
        case 'customFees':
          if (
            property.property_custom_fees &&
            property.property_custom_fees.length > 0
          ) {
            section = {
              title: 'CUSTOM FEES',
              icon: <DollarIcon />,
              content: new Array<LeasingSubsection>()
            };

            for (const fee of property.property_custom_fees) {
              section.content.push({
                title: fee.name,
                content: getFormattedCurrencyValue(fee.amount)
              });
            }
          }

          break;
      }

      return section;
    };

    const leftSections: SectionType = {};
    const keys = [
      'leaseOverview',
      'utilities',
      'customFees',
      'initialFees',
      'pets'
    ];
    let section: LeasingSection | undefined;
    let index = 0;

    for (const key of keys) {
      section = getSection(key);

      if (section) {
        if (leftSections[index % 3] === undefined) {
          leftSections[index % 3] = [];
        }
        leftSections[index % 3].push(section);
        index++;
      }
    }

    setColumnSections(leftSections);
  }, [property]);

  const getSectionMarkup = (section: LeasingSection) => {
    if (
      section.title === 'LEASING OVERVIEW' ||
      section.title === 'INITIAL FEES' ||
      section.title === 'PET FEES' ||
      section.title === 'CUSTOM FEES'
    ) {
      return (
        <LeasingCard
          title={section.title}
          icon={section.icon}
          className={classes.card}
          key={section.title}
        >
          <>
            {section.content.map((subsection: LeasingSubsection) => (
              <Box key={subsection.title} className={classes.subsection}>
                <Text
                  variant="caption"
                  className={classes.subsectionTitle}
                  variantMapping={{ caption: 'p' }}
                >
                  {subsection.title}
                </Text>

                <Text variant="body2" className={classes.subsectionContent}>
                  {subsection.content}
                </Text>
              </Box>
            ))}
          </>
        </LeasingCard>
      );
    }

    if (section.title === 'UTILITIES') {
      return (
        <LeasingCard
          title={section.title}
          icon={section.icon}
          className={`${classes.card} ${classes.utilitiesCard}`}
          key={section.title}
        >
          <>
            {section.utilitiesContent?.estimatedCost && (
              <Box className={classes.utilitiesCost}>
                <Text
                  variant="caption"
                  className={classes.subsectionTitle}
                  variantMapping={{ caption: 'p' }}
                >
                  Total Cost
                </Text>

                <Text variant="body2">
                  {section.utilitiesContent?.estimatedCost}
                </Text>
              </Box>
            )}
            <Box>
              {section.utilitiesContent?.types?.map(
                (utility: UtilityType, index: number) =>
                  utility.included && (
                    <Chip
                      key={index}
                      icon={<GreenCheckIcon />}
                      label={utility.name}
                      classes={{ root: classes.chip }}
                    />
                  )
              )}
            </Box>
          </>
        </LeasingCard>
      );
    }
  };

  return (
    <Box className={classes.mainContainer} data-testid="LeasingTab">
      {Object.keys(columnSections).length === 0 ? (
        <LeasingBinderNoContent
          icon={<BlankLeasingTabIcon />}
          primaryText="No leasing information yet!"
        />
      ) : (
        <Box className={classes.columns}>
          {Object.keys(columnSections).map((key: string) => (
            <Box className={classes.column}>
              {columnSections[key].map((section: LeasingSection) =>
                getSectionMarkup(section)
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
