/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.filter('availableDate', [
    '$moment',
    function ($moment) {
      return function (input, short) {
        var inputMoment = $moment(input);
        var nowMoment = $moment().endOf('day');

        if (inputMoment.isBefore(nowMoment)) {
          return 'Today';
        }

        if (short) {
          return inputMoment.format('MMM D');
        }

        return inputMoment.format('MMM Do, YYYY');
      };
    }
  ]);
})(window.angular);
