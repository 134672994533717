/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  const confirmationModalFactory = function ($sce) {
    return {
      createModal: function (title, message, confirmHandler, options) {
        return {
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl: '/angular/views/directives/confirmationModal.html',
          controller: 'ConfirmationModalController',
          resolve: {
            options: function () {
              var modalDefinition = {
                title: title,
                message: $sce.trustAsHtml(message),
                confirmHandler: confirmHandler,
                confirmText: 'Yes',
                cancelText: 'Cancel',
                cancelHandler: null,
                confirmClass: 'btn-warning',
                cancelClass: 'btn-primary',
                alertClass: 'alert-warning'
              };

              if (options) {
                modalDefinition.confirmText = options.confirmText || 'Yes';
                modalDefinition.cancelText = options.cancelText || 'Cancel';
                modalDefinition.cancelHandler = options.cancelHandler || null;
                modalDefinition.confirmClass =
                  options.confirmClass || 'btn-warning';
                modalDefinition.cancelClass =
                  options.cancelClass || 'btn-primary';
                modalDefinition.alertClass =
                  options.alertClass || 'alert-warning';
              }

              return modalDefinition;
            }
          }
        };
      }
    };
  };

  confirmationModalFactory.$inject = ['$sce'];

  app.factory('confirmationModalFactory', confirmationModalFactory);
})();
