/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('exportCalendarButton', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/util/exportCalendarButton/export-calendar-button.html',
      scope: {},
      controller: [
        '$scope',
        '$modal',
        function ($scope, $modal) {
          $scope.openExportCalendarModal = function () {
            $modal.open({
              // (BS) TODO: There is a bug in our angular dependencies that affects
              // event handling when animations are used with modals. Disabling
              // animations as a workaround, to permanently fix this we will need
              // to update our version of angular or rewrite this modal as an
              // mdDialog.
              animation: false,
              templateUrl:
                '/angular/views/util/exportCalendarButton/exportCalendarModal.html',
              controller: 'ExportCalendarModalController',
              size: 'lg'
            });
          };
        }
      ]
    };
  });

  app.controller('ExportCalendarModalController', [
    '$scope',
    '$modalInstance',
    'userCalendarApi',
    function ($scope, $modalInstance, userCalendarApi, provider) {
      var self = this;

      $scope.closeModal = function () {
        $modalInstance.dismiss('closed');
      };

      $scope.selectText = function ($event) {
        $event.target.select();
      };

      self._loadCalendarUrl = function () {
        $scope.isLoadingUrl = true;
        userCalendarApi
          .getCalendarUrl()
          .success(self._setCalendarUrl)
          .error(self._loadCalendarError)
          .finally(function () {
            $scope.isLoadingUrl = false;
          });
      };

      self._setCalendarUrl = function (response) {
        $scope.calendarUrl = response.url;
      };

      self._loadCalendarError = function () {
        $scope.errorMessage =
          'There was an error retrieving your calendar URL. Please try again later.';
      };

      self._loadCalendarUrl();
    }
  ]);
})();
