/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('userListingCard', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/user/user-listing-card.html',
      scope: {
        listingId: '=?',
        prospectId: '=',
        onRenterLoaded: '=?',
        onListingLoaded: '=?'
      },
      controller: [
        '$scope',
        '$rootScope',
        '$window',
        'listingApi',
        'prospectsApi',
        function ($scope, $rootScope, $window, listingApi, prospectsApi) {
          var self = this;

          $scope.loadingPercentage = 0;

          $scope.viewListing = function () {
            if ($scope.listingId) {
              $window.open('/listing/' + $scope.listingId);
            }
          };

          self._loadListingData = function () {
            if (!isEmpty($scope.listingId)) {
              listingApi
                .getListing($scope.listingId)
                .success(self._loadListingDataSuccess);
            } else {
              $scope.loadingPercentage += 50;
            }
          };

          self._loadListingDataSuccess = function (response) {
            $scope.listing = response.listing;
            self._setupListing();
          };

          self._setupListing = function () {
            if ($scope.listing) {
              if ($scope.listing.coverPhoto) {
                $scope.listingPhoto = $scope.listing.coverPhoto.thumbUrl;
              } else if (
                $scope.listing.photos &&
                $scope.listing.photos.length > 0
              ) {
                $scope.listingPhoto =
                  $scope.listing.photos[0].thumbUrl ||
                  $scope.listing.photos[0].url;
              } else {
                $scope.listingPhoto = '/images/no-image.png';
              }
            }

            $scope.loadingPercentage += 50;

            if ($scope.onListingLoaded) {
              $scope.onListingLoaded($scope.listing);
            }
          };

          self._loadProspect = function () {
            if (!isUndefined($scope.prospectId)) {
              prospectsApi
                .getProspect($scope.prospectId)
                .success(self._loadRenterDataSuccess);
            } else {
              $scope.loadingPercentage += 50;
            }
          };

          self._loadRenterDataSuccess = function (response) {
            $scope.renter = response.renter_info;
            $scope.loadingPercentage += 50;

            if ($scope.onRenterLoaded) {
              $scope.onRenterLoaded($scope.renter);
            }
          };

          self._loadListingData();
          self._loadProspect();
        }
      ]
    };
  });
})();
