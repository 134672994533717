/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('signupModalService', [
    '$modal',
    function ($modal) {
      var signupModalService = {};

      signupModalService.openModal = function (message, restrictToSignupType) {
        var options = {
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl: '/angular/views/signup/signup-modal.html',
          controller: 'signupModalController',
          size: 'sm',
          resolve: {
            message: function () {
              return message;
            },
            restrictToSignupType: function () {
              return restrictToSignupType;
            }
          }
        };

        return $modal.open(options);
      };

      return signupModalService;
    }
  ]);
})();
