/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('phoneVerificationModalFactory', [
    '$modal',
    '$q',
    function ($modal, $q) {
      var self = this;

      self.verificationModal = {
        // (BS) TODO: There is a bug in our angular dependencies that affects
        // event handling when animations are used with modals. Disabling
        // animations as a workaround, to permanently fix this we will need
        // to update our version of angular or rewrite this modal as an
        // mdDialog.
        animation: false,
        templateUrl: '/angular/views/directives/phoneVerificationModal.html',
        controller: 'PhoneVerificationModalController',
        resolve: {
          phoneNumber: function () {
            return self.phoneNumber;
          },
          responsePromise: function () {
            return self.verificationResponsePromise;
          }
        }
      };

      self.verificationConfirmationModal = {
        // (BS) TODO: There is a bug in our angular dependencies that affects
        // event handling when animations are used with modals. Disabling
        // animations as a workaround, to permanently fix this we will need
        // to update our version of angular or rewrite this modal as an
        // mdDialog.
        animation: false,
        templateUrl:
          '/angular/views/directives/phoneVerificationConfirmationModal.html',
        controller: 'PhoneVerificationConfirmationModalController',
        resolve: {
          phoneNumber: function () {
            return self.phoneNumber;
          },
          responsePromise: function () {
            return self.confirmationResponsePromise;
          }
        }
      };

      return {
        openVerificationModal: function (phoneNumber) {
          self.phoneNumber = phoneNumber;
          self.verificationResponsePromise = $q.defer();

          $modal.open(self.verificationModal);

          return self.verificationResponsePromise.promise;
        },
        openVerificationConfirmationModal: function (phoneNumber) {
          self.phoneNumber = phoneNumber;
          self.confirmationResponsePromise = $q.defer();

          $modal.open(self.verificationConfirmationModal);

          return self.confirmationResponsePromise.promise;
        }
      };
    }
  ]);
})();
