import React, { FC } from 'react';
import {
  makeStyles,
  NamedColors,
  Box,
  Text
} from '@knockrentals/knock-shared-web';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  StepButton
} from '@material-ui/core';
import CompleteTourBox from './CompleteTourCard';
import ProspectInterestBox from './ProspectInterestBox';
import ShowUnitsStep from './ShowUnitsStep';
import { SkipIcon } from '../../icons';
import NextStep from './NextStep';
import { useCompleteDrawerContext } from './CompleteTourDrawerContextProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  skipButton: {
    marginTop: '28px',
    '@media (min-height:400px) and (max-height:700px)': {
      marginLeft: '-230px'
    }
  },
  skipButtonWithFinishButton: {
    marginTop: '28px',
    '@media (min-height:400px) and (max-height:700px)': {
      marginTop: '28px',
      marginLeft: '-224px'
    },
    '@media (min-height:700px) and (max-height:800px)': {
      marginTop: '28px',
      marginLeft: '-224px'
    },
    '@media (min-height:851px) and (max-height:900px)': {
      marginTop: '28px'
    },
    '@media (min-height:800px) and (max-height:850px)': {
      marginTop: '28px'
    }
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },

  icon: {
    color: NamedColors.denim[600]
  },

  stepper: {
    '& .MuiStepContent-root': {
      marginLeft: '12px'
    },
    '& .MuiStepConnector-vertical': {
      marginLeft: '12px'
    },
    '& .MuiStepIcon-root': {
      color: NamedColors.slate[400]
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: NamedColors.denim[600]
    },
    '& .MuiSvgIcon-root': {
      height: '24px',
      width: '24px'
    },
    '& .MuiStepLabel-label': {
      fontFamily: 'Open Sans',
      color: NamedColors.slate[800],
      fontWeight: 400,
      '&.MuiStepLabel-active': {
        fontWeight: `${600} !important `
      },
      '&.MuiStepLabel-completed': {
        fontWeight: `${400} !important `
      }
    }
  },

  errorText: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    'font-size': '12px',
    'line-height': '20px',
    'letter-spacing': '0.4px',
    marginLeft: '6px',
    marginTop: '8px',
    position: 'absolute',
    '@media (min-height:400px) and (max-height:800px)': {
      position: 'relative',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      'font-size': '12px',
      'line-height': '20px',
      'letter-spacing': '0.4px',
      marginLeft: '6px',
      marginTop: '4px'
    }
  },
  stepLabelError: {
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: '#ee0033'
    },
    '& .MuiStepIcon-root': {
      color: '#ee0033'
    }
  },
  stepLabel: {},
  errorTextAtLevel2: {
    '@media (min-height:400px) and (max-height:800px)': {
      top: '-15px'
    }
  },
  errorTextAtLevel2WithUnits: {
    '@media (min-height:400px) and (max-height:800px)': {
      top: '-1px'
    }
  }
}));

const CompleteTourStepper: FC = () => {
  const classes = useStyles();

  const {
    step,
    tourResult,
    shownUnits,
    prospectInterest,
    infoPanelData: { shownUnitsRequired },
    setData,
    nextStepsCompleted,
    finishButtonDisableClicked,
    unitsError
  } = useCompleteDrawerContext();

  return (
    <Box className={classes.root} data-testid="Complete-Tour-Stepper-box">
      <Stepper
        activeStep={step}
        orientation="vertical"
        className={classes.stepper}
        data-testid="Complete-Tour-Stepper"
      >
        <Step
          key={'Tour Results'}
          data-testid="complete-tour-step"
          active
          completed={!!tourResult}
          expanded
        >
          <StepLabel
            data-testid="complete-tour-stepper-label"
            className={
              finishButtonDisableClicked && step === 0
                ? classes.stepLabelError
                : classes.stepLabel
            }
          >
            Tour Results
          </StepLabel>
          <StepContent data-testid="complete-tour-step-content">
            <CompleteTourBox />
            {finishButtonDisableClicked && step === 0 && (
              <Text
                color="error"
                display="inline"
                className={classes.errorText}
              >
                Please mark the tour as either Visited or No Show.
              </Text>
            )}
          </StepContent>
        </Step>
        <Step
          key={'Shown Units'}
          data-testid="complete-tour-step"
          active={step === 1 || shownUnits.length > 0}
          completed={shownUnits.length > 0}
          expanded={tourResult === 'visited'}
        >
          {step > 1 && tourResult === 'no_show' ? (
            <StepLabel
              data-testid="complete-tour-stepper-label"
              icon={<SkipIcon />}
            >
              {'Shown Units'}
            </StepLabel>
          ) : (
            <StepLabel
              data-testid="complete-tour-stepper-label"
              className={
                unitsError ? classes.stepLabelError : classes.stepLabel
              }
            >
              {'Shown Units'}
            </StepLabel>
          )}
          <StepContent data-testid="complete-tour-step-content">
            {tourResult === 'visited' ? (
              <>
                <ShowUnitsStep />
                {unitsError && (
                  <Text
                    color="error"
                    display="inline"
                    className={`${classes.errorText} ${
                      !shownUnitsRequired
                        ? classes.errorTextAtLevel2
                        : classes.errorTextAtLevel2WithUnits
                    }`}
                  >
                    Please add at least one shown units.
                  </Text>
                )}
                {!shownUnitsRequired && step === 1 && (
                  <Button
                    onClick={() =>
                      setData({
                        shownUnitsSkipped: true
                      })
                    }
                    className={
                      finishButtonDisableClicked
                        ? classes.skipButtonWithFinishButton
                        : classes.skipButton
                    }
                    color="primary"
                  >
                    Skip
                  </Button>
                )}
              </>
            ) : (
              <></>
            )}
          </StepContent>
        </Step>
        <Step
          key={'Prospect Interest'}
          data-testid="complete-tour-step"
          completed={!!prospectInterest}
          active={step > 1}
        >
          <StepLabel
            data-testid="complete-tour-stepper-label"
            className={
              finishButtonDisableClicked && step === 2
                ? classes.stepLabelError
                : classes.stepLabel
            }
          >
            Prospect Interest
          </StepLabel>
          <StepContent data-testid="complete-tour-step-content">
            <ProspectInterestBox />
            {finishButtonDisableClicked && step === 2 && (
              <Text
                color="error"
                display="inline"
                className={classes.errorText}
              >
                Please select whether the prospect is still interested or if
                they should be marked as lost.
              </Text>
            )}
          </StepContent>
        </Step>
        <Step key={'next-step'} data-testid="complete-tour-step" expanded>
          <StepButton completed={nextStepsCompleted}>
            <StepLabel
              data-testid="complete-tour-stepper-label"
              className={
                finishButtonDisableClicked && step === 3 && !nextStepsCompleted
                  ? classes.stepLabelError
                  : classes.stepLabel
              }
            >
              Next Steps
            </StepLabel>
          </StepButton>
          <StepContent data-testid="complete-tour-step-content">
            <NextStep />
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  );
};

export default CompleteTourStepper;
