import React, { FC } from 'react';

import {
  Button,
  makeStyles,
  NamedColors,
  ThemeProvider
} from '@knockrentals/knock-shared-web';
import { AttachmentIcon } from '../icons/AttachmentIcon';

const useStyles = makeStyles({
  button: {
    color: NamedColors.slate[800],

    '& svg': {
      marginRight: '8px'
    },

    '&:focus': {
      color: NamedColors.slate[800],

      '& svg path': {
        stroke: NamedColors.slate[800]
      }
    }
  }
});

export interface AttachmentsButtonProps {
  onClick: () => void;
}

export const AttachmentsButton: FC<AttachmentsButtonProps> = ({ onClick }) => {
  const classes = useStyles();

  const handleClick = () => {
    onClick();
  };

  return (
    <ThemeProvider>
      <Button variant="text" className={classes.button} onClick={handleClick}>
        <AttachmentIcon useNewDesign={true} /> Attachments
      </Button>
    </ThemeProvider>
  );
};
