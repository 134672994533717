import React, { FC, useMemo, useEffect } from 'react';

import { Box, Chip, Slide, TextField } from '@material-ui/core';
import {
  makeStyles,
  Text,
  NamedColors,
  Tooltip
} from '@knockrentals/knock-shared-web';
import { useMarkAsLostContext } from './MarkAsLostDrawerContextProvider';
import { colors } from '../../commonStyles/commonStyles';
import RichTextEditor from '../../RichTextEditor';
import { getAuthToken } from 'LegacyAngularApp/legacy-angular-app/services/tokenService';
import { NearbyCommunity } from 'LegacyAngularApp/legacy-angular-app/services/nearbyCommunitiesService';

const errorColor = '#F44336';

const useStyles = makeStyles({
  title: {
    fontSize: '16px',
    fontWeight: 600,
    color: colors.defaultText
  },
  composeEmailTitle: {
    fontSize: '16px',
    fontWeight: 600,
    color: colors.defaultText,
    paddingTop: '16px'
  },

  emailMessageContainer: {
    marginBottom: '0.5rem',

    '& .cke_editable': {
      fontSize: '16px !important'
    },

    '& .cke': {
      border: `1px solid ${colors.chipBackground} !important`,
      borderRadius: '8px !important'
    },

    '& .cke_inner': {
      display: 'block',
      padding: 0,
      borderRadius: '8px !important'
    },

    '& .cke.cke_focus': {
      borderColor: `${colors.chipBackground}`
    },

    '& a.cke_button': {
      padding: '4px 4px !important'
    },

    '& .cke_contents': {
      borderRadius: '8px !important'
    },

    '& .cke_bottom': {
      display: 'none'
    },

    '& .cke_top': {
      borderTopLeftRadius: '8px !important',
      borderTopRightRadius: '8px !important'
    }
  },
  emailFieldError: {
    '& .cke.cke_chrome, & .cke.cke_chrome.cke_focus': {
      border: `1px solid ${errorColor} !important`,
      borderRadius: '8px !important'
    }
  },
  subTitle: {
    fontSize: '14px',
    color: colors.secondaryText,
    paddingTop: '8px'
  },
  textField: {
    width: '100%',
    marginTop: '8px',
    paddingBottom: '16px',

    '&.MuiFormControl-root': {
      marginBottom: 0
    },

    '& .MuiInputLabel-formControl': {
      top: '23px',
      left: '0px',
      color: colors.disabled
    },

    '& .MuiFormLabel-root.Mui-focused': {
      color: `${colors.disabled} !important`
    },

    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none'
    },

    '& .MuiFilledInput-underline:after': {
      borderBottom: `1px solid ${NamedColors.slate[300]}`
    },

    '& .MuiFilledInput-multiline': {
      padding: '20px 12px 10px'
    },

    '& .MuiFilledInput-root': {
      borderColor: NamedColors.slate[300],
      background: NamedColors.slate[50],
      borderBottom: `1px solid ${NamedColors.slate[300]}`,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none'
    },

    '& .MuiInputBase-input': {
      color: colors.defaultText
    },

    '& .MuiInputBase-root.Mui-error': {
      borderBottom: `2px solid ${errorColor}`
    }
  },
  chipBox: {
    padding: '16px 0px 16px 0px',
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
    maxWidth: '720px'
  },
  chip: {
    fontSize: '13px',
    fontWeight: 600,
    color: NamedColors.denim[600],
    backgroundColor: NamedColors.slate[50],
    margin: '5',
    cursor: 'pointer',
    '&:hover': {
      textDecorationLine: 'underline',
      backgroundColor: NamedColors.denim[50],
      color: NamedColors.denim[600]
    },
    '&:focus': {
      textDecorationLine: 'underline',
      backgroundColor: NamedColors.denim[50],
      color: NamedColors.denim[600]
    }
  },
  tooltip: {
    fontSize: '10px'
  },
  emailError: {
    color: errorColor,
    fontSize: '12px',
    fontWeight: 400,
    paddingTop: '4px',
    paddingLeft: '12px'
  },
  emailSubjectError: {
    color: errorColor,
    fontSize: '12px',
    fontWeight: 400,
    marginTop: '-6px',
    paddingLeft: '12px'
  }
});
export interface InformationalEmailStepProps {
  mergeTags: [];
}

interface SelectedCommunityType {
  propertyName: string;
  propertyId: number;
}

const InformationalEmailStep: FC<InformationalEmailStepProps> = ({
  mergeTags
}) => {
  const {
    slideDirection,
    selectedCommunities,
    communities,
    openLeasingBinder,
    setEmailMessage,
    emailMessage,
    setEmailSubject,
    emailSubject,
    isEmailMessageEmpty,
    setIsEmailMessageEmpty,
    isSubjectEmpty,
    setIsSubjectEmpty
  } = useMarkAsLostContext();
  const handleEmailMessageChange = (editorContent: string) => {
    setEmailMessage(editorContent);
    setIsEmailMessageEmpty(editorContent.trim() === '');
  };
  const classes = useStyles();
  const selectedCommunity = useMemo(() => {
    return communities
      ?.filter(
        (community: NearbyCommunity) =>
          selectedCommunities.indexOf(community.property.id) > -1
      )
      ?.map((community: NearbyCommunity) => {
        return {
          propertyName: community.property.name,
          propertyId: community.property.id
        };
      });
  }, [selectedCommunities, communities]);

  useEffect(() => {
    setIsEmailMessageEmpty(false);
  }, [setIsEmailMessageEmpty]);

  return (
    <Slide direction={slideDirection} in={true}>
      <Box>
        <Text className={classes.title}>Communities</Text>
        <Box className={classes.chipBox}>
          {selectedCommunity &&
            selectedCommunity.map((community: SelectedCommunityType) => (
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                arrow
                title="Open Leasing Binder"
                aria-label="open-leasing-binder"
                key={community.propertyId}
              >
                <Chip
                  label={community.propertyName}
                  className={classes.chip}
                  component="a"
                  clickable
                  onClick={() => {
                    openLeasingBinder(community.propertyId);
                  }}
                />
              </Tooltip>
            ))}
        </Box>
        <Box>
          <Text className={classes.composeEmailTitle}>Compose Email</Text>
          <Text className={classes.subTitle}>
            Send a pre-populated email to this prospect with information about
            each property selected above.
          </Text>
        </Box>
        <Box>
          <TextField
            value={emailSubject}
            label="Subject"
            className={classes.textField}
            multiline={true}
            inputProps={{ maxLength: 500, 'data-testid': 'note' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmailSubject(event.target.value);
              setIsSubjectEmpty(event.target.value.trim() === '');
            }}
            defaultValue="Sorry to see you go!"
            error={isSubjectEmpty}
            variant="filled"
          />
          {isSubjectEmpty && (
            <p className={classes.emailSubjectError}>Please add a subject</p>
          )}
        </Box>
        <Box className={classes.emailMessageContainer}>
          <RichTextEditor
            content={emailMessage}
            onChange={handleEmailMessageChange}
            token={getAuthToken() || ''}
            height="357px"
            mergeTags={mergeTags}
          />
        </Box>
        {isEmailMessageEmpty && (
          <p className={classes.emailError}>Please add a message</p>
        )}
      </Box>
    </Slide>
  );
};
export default InformationalEmailStep;
