/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('messagingPreferenceEditor', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/profile/managerProfile/messaging-preference-editor.html',
      controller: [
        '$scope',
        'managerApi',
        function ($scope, managerApi) {
          var self = this;

          $scope.checkboxes = {
            nonAppointmentMessages: true
          };

          $scope.help = {
            nonAppointmentMessages:
              'Would you like to be able to receive messages from renters without their having requested an appointment?'
          };

          self._loadManagerPreferences = function () {
            managerApi
              .getManagerPreferences()
              .success(self._loadManagerPreferencesSuccess);
          };

          self._loadManagerPreferencesSuccess = function (response) {
            var messagingPreferences = {};
            var existingPreferences = response.manager_preferences;

            messagingPreferences.acceptsNonAppointmentMessages =
              existingPreferences.accepts_non_appointment_messages;
            $scope.checkboxes.nonAppointmentMessages =
              messagingPreferences.acceptsNonAppointmentMessages;

            $scope.messagingPreferences = messagingPreferences;

            self._setupCheckboxWatches();
          };

          self._setupCheckboxWatches = function () {
            $scope.$watch(
              'checkboxes.nonAppointmentMessages',
              function (isChecked) {
                $scope.messagingPreferences.acceptsNonAppointmentMessages =
                  isChecked;
              }
            );
          };

          $scope.saveMessagingPreferences = function () {
            $scope.isSavingMessagingPreference = true;

            $scope.saveMessagingPreferencesSuccessMessage = null;
            $scope.saveMessagingPreferencesErrorMessage = null;

            managerApi
              .setManagerMessagingPreferences(
                $scope.messagingPreferences.acceptsNonAppointmentMessages
              )
              .success(self._saveMessagingPreferencesSuccess)
              .error(self._saveMessagingPreferencesError)
              .finally(function () {
                $scope.isSavingMessagingPreference = false;
              });
          };

          self._saveMessagingPreferencesSuccess = function () {
            $scope.saveMessagingPreferencesSuccessMessage =
              'Messaging preferences saved successfully!';
          };

          self._saveMessagingPreferencesError = function () {
            $scope.saveMessagingPreferencesErrorMessage =
              'There was an error saving your messaging preferences. Please try again later.';
          };

          self._loadManagerPreferences();
        }
      ]
    };
  });
})();
