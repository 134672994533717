/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('schedulingManager', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/scheduling/scheduling-manager.html',
      controller: 'SchedulingManagerController',
      scope: {
        listingId: '=',
        hideContact: '=?',
        chatThreadId: '=?'
      }
    };
  });

  app.controller('SchedulingManagerController', [
    '$scope',
    '$routeParams',
    '$q',
    'listingApi',
    'propertyApi',
    'schedulingRequirementsService',
    'userService',
    'conversationsService',
    function (
      $scope,
      $routeParams,
      $q,
      listingApi,
      propertyApi,
      schedulingRequirementsService,
      userService,
      conversationsService
    ) {
      var self = this;
      var user = userService.getUser();

      $scope.isManager = user && user.type === 'manager';

      if (!$scope.listingId) {
        throw 'listing ID is required';
      }

      $scope.messaging = {
        text: '',
        isNewThread: false,
        isAllowed: !$scope.isManager
      };

      $scope.openChatThread = function () {
        if (!schedulingRequirementsService.requireLoggedIn()) {
          return;
        }

        schedulingRequirementsService
          .ensureRenterPhoneNumber()
          .then(function () {
            if (!$scope.chatThreadId) {
              $scope.messaging.isNewThread = true;
            } else {
              conversationsService.openThreadById($scope.chatThreadId);
            }
          });
      };

      $scope.sendNewMessage = function () {
        $scope.isSendingMessage = true;

        conversationsService
          .createThread(
            'manager',
            $scope.managerInfo.manager_id,
            $scope.listingId
          )
          .success(function (response) {
            conversationsService
              .sendMessage(
                response.stream_id,
                $scope.messaging.text,
                null,
                null,
                null,
                ['sms']
              )
              .then(function () {
                conversationsService.openThreadById(response.stream_id);
                $scope.isSendingMessage = false;
              });
          });
      };

      self._initialize = function () {
        $scope.isNewMessage = false;
        $scope.isLoadingManager = true;

        var initPromises = [self._getPropertyOwner()];

        $q.all(initPromises).then(function () {
          $scope.isLoadingManager = false;
        });
      };

      self._getPropertyOwner = function () {
        if ($routeParams.appointmentId) {
          return listingApi
            .getListingManager($scope.listingId, $routeParams.appointmentId)
            .then(function (response) {
              $scope.managerInfo = response.data.manager_info;
            }, self._getListingManagerError);
        } else {
          return listingApi
            .getListing($scope.listingId)
            .then(function (response) {
              return response.data.listing.property_id;
            })
            .then(function (propertyId) {
              return propertyApi.getPropertyOwner(propertyId);
            })
            .then(function (response) {
              $scope.managerInfo = response.data.property_owner;
            }, self._getListingManagerError);
        }
      };

      self._getListingManagerError = function () {
        $scope.loadError = true;
      };

      self._initialize();
    }
  ]);
})(window.angular);
