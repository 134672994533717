export default function configureImageSanitization(app: any) {
  app.config([
    '$compileProvider',
    function ($compileProvider: any) {
      // this is needed for using the photoDrop directive, adds 'blob' to original white list
      $compileProvider.imgSrcSanitizationWhitelist(
        /^\s*(https?|ftp|mailto|blob):/
      );
    }
  ]);
}
