import React, { FC, useState, useRef } from 'react';
import {
  Popover,
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  IconButton,
  ListItemAvatar,
  Avatar
} from '@material-ui/core';
import { MenuSquareIcon } from '../../../icons';
import { Tooltip, NamedColors } from '@knockrentals/knock-shared-web';
import { useCommonStyles } from '../../../commonStyles/commonStyles';

const useStyles = makeStyles({
  boxStyle: {
    width: '320px',
    padding: '16px 0 8px 0'
  },
  appMenuTitle: {
    color: 'rgba(0, 0, 0, 0.64)',
    fontWeight: 'normal',
    padding: '18px'
  },
  avatarColorDefault: {
    color: NamedColors.denim[300],
    backgroundColor: NamedColors.denim[100]
  }
});

interface AppMenuList {
  id: number;
  isAvailable: boolean;
  openApp: () => void;
  testId: string;
  icon: React.ReactNode;
  primaryText: string;
}
interface AppMenuProps {
  appMenuList: AppMenuList[];
}

export const AppMenu: FC<AppMenuProps> = ({ appMenuList }) => {
  const [open, setOpen] = useState(false);
  const appMenuRef = useRef<HTMLButtonElement>(null);
  const tooltipTitle = 'Knock Product Suite';

  const { boxStyle, appMenuTitle, avatarColorDefault } = useStyles();
  const commonClasses = useCommonStyles();

  const redirectUserToDifferentApp = (callback: () => void) => {
    setOpen(!open);
    callback();
  };

  return (
    <>
      <Tooltip title={tooltipTitle} arrow={true} placement="bottom">
        <IconButton
          className={commonClasses.menuIconButton}
          aria-describedby="popover-app-menu"
          data-testid="app-menu-button"
          ref={appMenuRef}
          onClick={() => setOpen(!open)}
          component="span"
        >
          <MenuSquareIcon />
        </IconButton>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={appMenuRef.current}
        onClose={() => setOpen(!open)}
        role="popover-app-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box className={boxStyle}>
          <Typography variant="overline" className={appMenuTitle}>
            KNOCK PRODUCT SUITE
          </Typography>

          <List>
            {appMenuList.map(
              (appMenuListItem: any) =>
                appMenuListItem.isAvailable && (
                  <ListItem
                    onClick={() =>
                      redirectUserToDifferentApp(appMenuListItem.openApp)
                    }
                    data-testid={appMenuListItem.testId}
                    key={appMenuListItem.id}
                  >
                    <ListItemAvatar>
                      <Avatar classes={{ colorDefault: avatarColorDefault }}>
                        <appMenuListItem.icon />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText primary={appMenuListItem.primaryText} />
                  </ListItem>
                )
            )}
          </List>
        </Box>
      </Popover>
    </>
  );
};
