import React from 'react';

export const ExcludePersonIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3547_6765)">
      <path
        d="M0.625 14.3758C0.625633 13.4459 0.856703 12.5306 1.29755 11.7118C1.73839 10.893 2.37527 10.1963 3.15124 9.68379C3.92721 9.17132 4.81808 8.85913 5.7442 8.77514C6.67033 8.69115 7.60283 8.83797 8.45833 9.20247"
        stroke="#212121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 7.50085C8.14848 7.50085 9.6875 5.96183 9.6875 4.06335C9.6875 2.16488 8.14848 0.625854 6.25 0.625854C4.35152 0.625854 2.8125 2.16488 2.8125 4.06335C2.8125 5.96183 4.35152 7.50085 6.25 7.50085Z"
        stroke="#212121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.375 19.3741C17.1364 19.3741 19.375 17.1356 19.375 14.3741C19.375 11.6127 17.1364 9.37415 14.375 9.37415C11.6136 9.37415 9.375 11.6127 9.375 14.3741C9.375 17.1356 11.6136 19.3741 14.375 19.3741Z"
        stroke="#212121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.84 17.9083L17.91 10.8383"
        stroke="#212121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3547_6765">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
