import React, { FC, forwardRef, useEffect, useState } from 'react';
import { Paper, Popover, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import DataTable, { Column, Options } from 'material-table';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Text,
  makeStyles,
  NamedColors,
  IconButton,
  Tooltip
} from '@knockrentals/knock-shared-web';
import {
  AvailableQuickReply,
  AvailableQuickReplies,
  QUICK_REPLY_TYPES
} from './models';
import NoQuickReplies from './NoQuickReplies';
import { ErrorAlert } from '../../components/ErrorAlert';
import { QuickReplyChips } from './QuickReplyChips';
import {
  getAvailableCompanyQuickReplies,
  getAvailableQuickReplies
} from '../../utilities/quickReplyUtilities';
import { getInitialsFromFullName } from '../../utilities/userUtilities';
import { AddCircleIcon, EditIcon } from '../icons';

export const COMPANY_QR_INITIALS = 'CR';
export const COMPANY_QR_TOOLTIP = 'Company quick reply';
export const EMPTY_MESSAGES = {
  [QUICK_REPLY_TYPES.COMPANY_QUICK_REPLIES]:
    'No Company quick replies to display',
  [QUICK_REPLY_TYPES.PROPERTY_QUICK_REPLIES]:
    'No Property quick replies to display'
};

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    minWidth: '400px'
  },

  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0)
  },

  header: {
    padding: '16px 24px'
  },

  footer: {
    boxShadow: '0 1px 0 0 #E0E0E0',
    padding: '12px 24px 12px 16px'
  },

  quickReplyList: {
    paddingTop: 0,

    '& .MuiListItemSecondaryAction-root': {
      right: 0
    }
  },

  dataTable: {
    '& table tbody tr:hover': {
      backgroundColor: `${NamedColors.denim[50]} !important`
    }
  },

  quickReplyItem: {
    boxShadow: '0 1px 0 0 #E0E0E0',
    height: '56px',

    '&.MuiListItem-gutters': {
      paddingLeft: '22px'
    },

    '&:last-child': {
      boxShadow: 'none'
    }
  },

  avatarContainer: {
    display: 'flex',
    justifyContent: 'center'
  },

  avatar: {
    background: NamedColors.denim[50],
    color: NamedColors.denim[500],
    fontSize: '18px'
  },

  title: {
    maxWidth: '175px', // This must match the width specified in the definition of the Title column
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  message: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    width: '250px'
  },

  addQuickReplyButton: {
    '& svg': {
      marginRight: '8px'
    }
  },

  warningBox: {
    margin: '0 auto',
    maxWidth: '360px'
  }
}));

export const EMAIL_REPLIES_HEADER_TEXT = 'Email Quick Replies';
export const SMS_REPLIES_HEADER_TEXT = 'SMS Quick Replies';
export const CLOSE_BUTTON_LABEL = 'Close the popup';
export const NEW_QUICK_REPLY_BUTTON_LABEL = 'New Quick Reply';

interface ColumnDefinition {
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right' | undefined;
  title: string;
  field?: string;
  type?:
    | 'string'
    | 'boolean'
    | 'numeric'
    | 'date'
    | 'datetime'
    | 'time'
    | 'currency';
  width?: string;
  headerStyle?: React.CSSProperties;
  sorting?: boolean;
  defaultSort?: 'desc' | 'asc' | undefined;
  customSort?: (
    data1: AvailableQuickReply,
    data2: AvailableQuickReply,
    type: 'row' | 'group'
  ) => number;
  render?: (rowData: AvailableQuickReply) => void;
}

interface QuickReplyListProps {
  isCompanyQuickRepliesEnabled: boolean;
  messageMode: string;
  getManagerQuickReplies: () => Promise<any>;
  onClose: (
    openQuickReplyModal: boolean,
    editMode: boolean,
    quickReply?: AvailableQuickReply,
    appendMessage?: boolean
  ) => void;
  anchorEl: HTMLButtonElement | null;
}

export const QuickReplyList: FC<QuickReplyListProps> = ({
  anchorEl,
  getManagerQuickReplies,
  isCompanyQuickRepliesEnabled,
  messageMode,
  onClose
}) => {
  const classes = useStyles();
  const [headerText, setHeaderText] = useState<string>('');
  const [currentReplyType, setCurrentReplyType] = useState<QUICK_REPLY_TYPES>(
    QUICK_REPLY_TYPES.PROPERTY_QUICK_REPLIES
  );
  const [availableQuickReplies, setAvailableQuickReplies] =
    useState<AvailableQuickReplies>({
      [QUICK_REPLY_TYPES.COMPANY_QUICK_REPLIES]: [],
      [QUICK_REPLY_TYPES.PROPERTY_QUICK_REPLIES]: []
    });
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const tableOptions: Options<AvailableQuickReply> = {
    draggable: false,
    grouping: false,
    headerStyle: {
      borderBottom: `1px solid ${NamedColors.slate[600]}`,
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
      paddingBottom: '12px',
      position: 'sticky',
      top: 0
    },
    padding: 'dense',
    paging: false,
    rowStyle: {
      height: '65px'
    },
    search: false,
    sorting: true,
    toolbar: false,
    thirdSortClick: false,
    // These options tell the table component to use a fixed size for its body, which allows it to scroll while keeping the table header sticky and
    // the popover footer always visible
    minBodyHeight: '35vh',
    maxBodyHeight: '35vh'
  };

  useEffect(() => {
    const getQuickReplies = async () => {
      try {
        setErrorMessage('');
        setHeaderText(
          messageMode === 'email'
            ? EMAIL_REPLIES_HEADER_TEXT
            : SMS_REPLIES_HEADER_TEXT
        );
        setLoading(true);
        setOpen(true);
        const response = await getManagerQuickReplies();
        const { company_quick_replies = {}, quick_replies } =
          response.data || {};

        const companyQuickReplies = isCompanyQuickRepliesEnabled
          ? getAvailableCompanyQuickReplies(
              company_quick_replies.quick_replies,
              messageMode === 'email'
            )
          : [];

        setAvailableQuickReplies({
          [QUICK_REPLY_TYPES.COMPANY_QUICK_REPLIES]: companyQuickReplies,
          [QUICK_REPLY_TYPES.PROPERTY_QUICK_REPLIES]: getAvailableQuickReplies(
            quick_replies,
            messageMode
          )
        });

        if (isCompanyQuickRepliesEnabled && companyQuickReplies.length) {
          setCurrentReplyType(QUICK_REPLY_TYPES.COMPANY_QUICK_REPLIES);
        }

        setLoading(false);
      } catch (err) {
        setErrorMessage(
          'An unexpected error occurred while finding your quick replies. Please try again later.'
        );
        setLoading(false);
      }
    };

    if (anchorEl) {
      getQuickReplies();
    }
  }, [
    anchorEl,
    getManagerQuickReplies,
    messageMode,
    isCompanyQuickRepliesEnabled
  ]);

  const handleClose = (
    openQuickReplyModal: boolean = false,
    editMode: boolean = false,
    quickReply?: AvailableQuickReply,
    appendMessage?: boolean
  ) => {
    setOpen(false);

    if (onClose) {
      onClose(openQuickReplyModal, editMode, quickReply, appendMessage);
    }
  };

  const handleRowClick = (
    event?: React.MouseEvent,
    rowData?: AvailableQuickReply,
    _toggleDetailPanel?: (panelIndex?: number) => void
  ) => {
    if (rowData) {
      handleClose(false, false, rowData, true);
    }
  };

  const getColumns = (): ColumnDefinition[] => {
    return [
      {
        align: 'center',
        title: 'Owner',
        field: 'manager_id',
        type: 'string',
        headerStyle: {
          paddingLeft: '24px',
          paddingRight: 0
        },
        sorting: true,
        customSort: (data1, data2) => {
          const data1managerName = data1.manager_name || '';
          const data2managerName = data2.manager_name || '';

          if (data1managerName === data2managerName) {
            return (data1.title || '').localeCompare(
              data2.title || '',
              undefined,
              { sensitivity: 'base' }
            );
          } else {
            return data1managerName.localeCompare(data2managerName, undefined, {
              sensitivity: 'base'
            });
          }
        },
        render: (rowData: AvailableQuickReply) => {
          const tooltipTitle =
            currentReplyType === QUICK_REPLY_TYPES.COMPANY_QUICK_REPLIES
              ? COMPANY_QR_TOOLTIP
              : rowData.manager_name || '';

          return (
            <Tooltip
              title={tooltipTitle}
              arrow={true}
              placement="bottom"
              PopperProps={{
                disablePortal: true,
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: '0, -5'
                  }
                }
              }}
            >
              <span className={classes.avatarContainer}>
                <Avatar className={classes.avatar}>
                  {currentReplyType === QUICK_REPLY_TYPES.COMPANY_QUICK_REPLIES
                    ? COMPANY_QR_INITIALS
                    : getInitialsFromFullName(rowData.manager_name || '')}
                </Avatar>
              </span>
            </Tooltip>
          );
        }
      },
      {
        title: 'Title',
        field: 'title',
        type: 'string',
        width: '175px',
        sorting: true,
        defaultSort: 'asc',
        customSort: (data1, data2) => {
          return (data1.title || '').localeCompare(
            data2.title || '',
            undefined,
            { sensitivity: 'base' }
          );
        },
        render: (rowData: AvailableQuickReply) => {
          return (
            <Text variant="subtitle2" className={classes.title}>
              {rowData.title}
            </Text>
          );
        }
      },
      {
        title: 'Content',
        field: 'message_to_display',
        type: 'string',
        width: '250px',
        sorting: true,
        customSort: (data1, data2) => {
          return (data1.message_to_display || '').localeCompare(
            data2.message_to_display || '',
            undefined,
            { sensitivity: 'base' }
          );
        },
        render: (rowData: AvailableQuickReply) => {
          return (
            <Text variant="caption" className={classes.message}>
              {rowData.message_to_display}
            </Text>
          );
        }
      },
      {
        align: 'center',
        title: 'Actions',
        field: undefined,
        type: undefined,
        sorting: false,
        render: (rowData: AvailableQuickReply) => {
          if (!rowData.manager_id) {
            return null;
          }

          return (
            <IconButton
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                handleClose(true, true, rowData);
              }}
            >
              <EditIcon />
            </IconButton>
          );
        }
      }
    ];
  };

  const getColumnHeaderConfig = (
    column: ColumnDefinition
  ): Column<AvailableQuickReply> => {
    const {
      align,
      title,
      field,
      type,
      width,
      headerStyle,
      sorting,
      defaultSort,
      customSort,
      render
    } = column;

    return {
      align: align ? align : 'left',
      title,
      field,
      type,
      width,
      headerStyle,
      cellStyle: {
        fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif'
      },
      sorting,
      defaultSort,
      customSort,
      render
    };
  };

  const hasCompanyQuickReplies =
    availableQuickReplies[QUICK_REPLY_TYPES.COMPANY_QUICK_REPLIES].length > 0;

  const hasAvailableQuickReplies =
    hasCompanyQuickReplies ||
    availableQuickReplies[QUICK_REPLY_TYPES.PROPERTY_QUICK_REPLIES].length > 0;

  const getEmptyDataSourceMessage = () => {
    if (isCompanyQuickRepliesEnabled) {
      return EMPTY_MESSAGES[currentReplyType];
    }
  };

  return (
    <Popover
      id="quick-replies-list"
      open={open}
      anchorEl={anchorEl}
      onClose={() => handleClose()}
      disableScrollLock={true}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      style={{ zIndex: 15001 }}
      PaperProps={{
        classes: {
          root: classes.mainContainer
        }
      }}
    >
      <Box>
        <Box className={classes.header}>
          <Text variant="h6">{headerText}</Text>

          <IconButton
            aria-label={CLOSE_BUTTON_LABEL}
            className={classes.closeButton}
            onClick={() => handleClose()}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        {!hasAvailableQuickReplies && !isLoading && !errorMessage ? (
          <NoQuickReplies handleClose={handleClose} />
        ) : (
          <>
            {errorMessage ? (
              <Box className={classes.warningBox}>
                <ErrorAlert alertMessage={errorMessage} />
              </Box>
            ) : (
              <>
                {isCompanyQuickRepliesEnabled && (
                  <>
                    <Divider light />
                    <QuickReplyChips
                      currentReplyType={currentReplyType}
                      setCurrentReplyType={setCurrentReplyType}
                    />
                  </>
                )}

                <Box className={classes.dataTable}>
                  <DataTable
                    columns={getColumns().map(getColumnHeaderConfig)}
                    components={{
                      Container: (props) => <Paper {...props} elevation={0} />
                    }}
                    isLoading={isLoading}
                    data={availableQuickReplies[currentReplyType]}
                    localization={{
                      body: {
                        emptyDataSourceMessage: getEmptyDataSourceMessage()
                      }
                    }}
                    options={tableOptions}
                    onRowClick={handleRowClick}
                    icons={{
                      SortArrow: forwardRef((props, ref) => (
                        <ArrowDownward {...props} ref={ref} />
                      ))
                    }}
                  />
                </Box>

                <Box className={classes.footer}>
                  <Button
                    variant="text"
                    className={classes.addQuickReplyButton}
                    onClick={() => handleClose(true)}
                  >
                    <AddCircleIcon />
                    {NEW_QUICK_REPLY_BUTTON_LABEL}
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Popover>
  );
};
