import { api as baseApi } from 'app/services/api';

export const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUnits: build.query<any, any>({
      query: (propertyId) => {
        return {
          url: `/property/${propertyId}/units`
        };
      },
      transformResponse: (data: any) => {
        return {
          data: data.units || []
        };
      }
    })
  })
});
