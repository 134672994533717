import React, { FC } from 'react';
import classnames from 'classnames';

export const getFormattedNumberString = (value: number) => {
  const commaFormattedString = new Intl.NumberFormat('en-US').format(value);
  const delimiter = '.';
  const [dollars = '0', cents = '00'] = commaFormattedString.split(delimiter);
  return `${dollars}${delimiter}${cents.padEnd(2, '0')}`;
};

export interface CurrencyDisplayProps {
  className?: string;
  value: number;
}

const CurrencyDisplay: FC<CurrencyDisplayProps> = ({ className, value }) => (
  <span
    className={classnames('currency-display', className)}
  >{`$${getFormattedNumberString(value)}`}</span>
);

export default CurrencyDisplay;
