import dayjs, { Dayjs } from 'dayjs';

export enum LastActive {
  TODAY = 'Today',
  UNKNOWN = 'Unknown',
  YESTERDAY = 'Yesterday'
}

const isToday = (dayjsDate: Dayjs) => dayjsDate.isSame(dayjs(), 'day');

const isYesterday = (dayjsDate: Dayjs) =>
  dayjsDate.isSame(dayjs().subtract(1, 'day'), 'day');

export const parseLastActiveDate = (date: string): string => {
  const dayjsDate = dayjs(date);

  if (!dayjsDate.isValid() || new Date(date).toString() === 'Invalid Date') {
    return LastActive.UNKNOWN;
  }

  if (isToday(dayjsDate)) {
    return LastActive.TODAY;
  }

  if (isYesterday(dayjsDate)) {
    return LastActive.YESTERDAY;
  }

  return dayjs(date).format('MM-DD-YYYY');
};
