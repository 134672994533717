/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('hideImgOnErr', function () {
    return {
      restrict: 'A',
      link: function (scope, element) {
        element.bind('error', function () {
          element.css({
            display: 'none'
          });
        });
      }
    };
  });
})(window.angular);
