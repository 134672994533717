/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('toggleBrowserAudioNotifications', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/browserNotifications/toggle-browser-audio-notifications.html',
      controller: 'ToggleBrowserAudioNotificationsController'
    };
  });

  const ToggleBrowserAudioNotificationsController = function (
    $scope,
    $mdToast,
    appDataService,
    browserNotificationsService
  ) {
    var self = this;

    $scope.data.preferences = appDataService.data.preferences;

    $scope.browserAudioNotificationsToggle = function () {
      browserNotificationsService
        .toggleAudioNotifications()
        .then(function () {
          self.saveAudioNotificationPreference();
        })
        .catch(function (err) {
          console.log(err);
          self.saveAudioNotificationPreferenceError();
        });
    };

    self.saveAudioNotificationPreference = function () {
      $mdToast.show(
        $mdToast
          .simple()
          .content(
            $scope.data.preferences.audioNotificationsEnabled
              ? 'Audio notifications on'
              : 'Audio notifications off'
          )
          .position('top right')
      );
    };

    self.saveAudioNotificationPreferenceError = function () {
      $mdToast.show(
        $mdToast
          .simple()
          .content('Notifications setting could not be saved')
          .position('top right')
      );
    };

    return self;
  };

  ToggleBrowserAudioNotificationsController.$inject = [
    '$scope',
    '$mdToast',
    'appDataService',
    'browserNotificationsService'
  ];

  app.controller(
    'ToggleBrowserAudioNotificationsController',
    ToggleBrowserAudioNotificationsController
  );
})(window.angular);
