/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import some from 'lodash/some';
import remove from 'lodash/remove';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('primeTimeDialogFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openPrimeTimeDialog = function (listing, start, end) {
        if (!listing) {
          throw 'listing is required';
        }

        return $mdDialog.show({
          controller: 'PrimeTimeController',
          templateUrl:
            '/angular/views/listing/listingIndex/addPrimeTimeModal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            listing: listing,
            start: start,
            end: end
          }
        });
      };

      return self;
    }
  ]);

  app.controller('PrimeTimeController', [
    '$scope',
    '$q',
    '$mdDialog',
    '$moment',
    'timeService',
    'managerApi',
    'listingApi',
    'managerCalendarEventsService',
    'listing',
    'start',
    'end',
    function (
      $scope,
      $q,
      $mdDialog,
      $moment,
      timeService,
      managerApi,
      listingApi,
      managerCalendarEventsService,
      listing,
      start,
      end
    ) {
      var self = this;

      $scope.addingNewPrimeTime = false;
      $scope.showSettings = false;
      $scope.isSaving = false;

      $scope.listing = listing;

      $scope.settings = {
        allowOtherRequests: true,
        instantBook: true,
        showAsBusy: true,
        maximumVisitors: 1,
        tourDuration: 15,
        ignoreMinimumBooking: false
      };

      self._initialize = function () {
        var initPromises = [
          self._getManagerPreferences(),
          self._getListingPrimeTime()
        ];

        $q.all(initPromises).finally(function () {
          $scope.isLoaded = true;
        });
      };

      self._getManagerPreferences = function () {
        return managerApi.getManagerPreferences().success(function (response) {
          if (response.manager_preferences.tour_duration) {
            var tourDurationMoment = timeService.get(
              response.manager_preferences.tour_duration,
              'HH:mm:ss'
            );
            $scope.settings.tourDuration =
              tourDurationMoment.hours() * 60 + tourDurationMoment.minutes();
          } else {
            $scope.settings.tourDuration = 15;
          }
        });
      };

      self._getListingPrimeTime = function () {
        return listingApi
          .getListingPrimeTime(listing.id)
          .success(function (response) {
            var listingPrimeTime = response.prime_time;
            var primeTimes = response.times;

            $scope.settings.instantBook = listingPrimeTime.instant_book;
            $scope.settings.maximumVisitors = listingPrimeTime.maximum_visitors;
            $scope.settings.showAsBusy = listingPrimeTime.show_as_busy;
            $scope.settings.allowOtherRequests =
              listingPrimeTime.allow_non_prime_times;
            $scope.settings.ignoreMinimumBooking =
              listingPrimeTime.ignore_minimum_booking_notices;

            $scope.primeTimes = self._setupPrimeTimes(primeTimes);
            $scope.dayChoices = self._setupDayChoices();

            $scope.addingNewPrimeTime = isEmpty($scope.primeTimes);

            if (start) {
              var startMoment = timeService.get(start);

              if (end) {
                $scope.selectDay(
                  startMoment,
                  startMoment,
                  timeService.get(end)
                );
              } else {
                $scope.selectDay(startMoment, startMoment);
              }
            }
          });
      };

      self._setupPrimeTimes = function (primeTimes) {
        return map(primeTimes, function (primeTime) {
          return new PrimeTimeInterval(
            primeTime.start_time,
            primeTime.end_time
          );
        });
      };

      self._setupDayChoices = function () {
        var dayChoices = [];

        var start = timeService.now().startOf('day');
        var end = timeService.clone(start).add(2, 'weeks');

        var primeTimesRange = timeService.range(start, end);

        primeTimesRange.by('days', function (dayMoment) {
          dayChoices.push(dayMoment);
        });

        return dayChoices;
      };

      $scope.selectDay = function (day, start, end) {
        var startTime = start
          ? timeService.get(start)
          : timeService.clone(day).startOf('day').add(8, 'hours');
        var endTime = end
          ? timeService.get(end)
          : timeService.clone(day).startOf('day').add(18, 'hours');
        var newPrimeTime = new PrimeTimeInterval(startTime, endTime);

        $scope.primeTimes.push(newPrimeTime);
        $scope.addingNewPrimeTime = false;
      };

      $scope.removePrimeTime = function (primeTime) {
        remove($scope.primeTimes, primeTime);
      };

      $scope.dismiss = function () {
        $mdDialog.hide();
      };

      $scope.save = function () {
        function mapPrimeTimes(primeTime) {
          var startTime = angular.copy(primeTime.start());
          var endTime = angular.copy(primeTime.end());

          var minuteDifference = $moment
            .duration(endTime.diff(startTime))
            .asMinutes();

          // If we disable instant book, we need to ensure at least 3 time slots are available
          primeTime.isTooShort =
            !$scope.settings.instantBook &&
            minuteDifference < $scope.settings.tourDuration * 3;
          primeTime.isZeroLength = minuteDifference <= 0;

          return {
            start_time: primeTime.start().format(),
            end_time: primeTime.end().format(),
            isTooShort: primeTime.isTooShort,
            isZeroLength: primeTime.isZeroLength,
            isInvalidWithTourDuration: primeTime.isInvalidWithTourDuration
          };
        }

        var timesToSave = map($scope.primeTimes, mapPrimeTimes);

        var hasInvalidTimes =
          some(timesToSave, 'isTooShort') || some(timesToSave, 'isZeroLength');

        if (hasInvalidTimes) {
          return;
        }

        $scope.isSaving = true;

        var payload = {
          prime_time: {
            allow_non_prime_times: $scope.settings.allowOtherRequests,
            instant_book: $scope.settings.instantBook,
            show_as_busy: $scope.settings.showAsBusy,
            maximum_visitors: $scope.settings.maximumVisitors,
            ignore_minimum_booking_notices: $scope.settings.ignoreMinimumBooking
          },
          times: timesToSave
        };

        listingApi
          .postListingPrimeTime(listing.id, payload)
          .success(function () {
            $scope.isSaving = false;
            managerCalendarEventsService.primeTimesWereModified(
              listing,
              timesToSave
            );
            $mdDialog.hide();
          });
      };

      function PrimeTimeInterval(start, end) {
        var startTime = timeService.get(start);
        var endTime = timeService.get(end);

        this.date = timeService.clone(startTime).startOf('day');
        this.interval = this._setInterval(startTime, endTime);

        this.adjustToTourDuration();
      }

      var primeTimeInterval = PrimeTimeInterval.prototype;

      primeTimeInterval.start = function () {
        return this._toTime(this.interval[0]);
      };

      primeTimeInterval.end = function () {
        return this._toTime(this.interval[1]);
      };

      primeTimeInterval.adjustToTourDuration = function () {
        var start = this.start();
        var end = this.end();

        var differenceInMinutes = end.diff(start, 'minutes');

        this.isInvalidWithTourDuration =
          differenceInMinutes % $scope.settings.tourDuration !== 0;

        if (this.isInvalidWithTourDuration) {
          var minutesToAdd =
            Math.ceil(differenceInMinutes / $scope.settings.tourDuration) *
            $scope.settings.tourDuration;
          var nextDivisibleEndTime = $moment(start).add(
            minutesToAdd,
            'minutes'
          );

          this.interval = primeTimeInterval._setInterval(
            start,
            nextDivisibleEndTime
          );
        }
      };

      primeTimeInterval._setInterval = function (start, end) {
        function timeToInt(moment) {
          return moment.hour() * 60 + moment.minute();
        }

        return [timeToInt(start), timeToInt(end)];
      };

      primeTimeInterval._toTime = function (timeInteger) {
        var minutes = timeInteger % 60;
        var hours = (timeInteger - minutes) / 60;

        return $moment(this.date.hour(hours).minute(minutes));
      };

      self._initialize();
    }
  ]);
})(window.angular);
