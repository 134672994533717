/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import filter from 'lodash/filter';
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.factory('conversationTeamInboxSettingsFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function () {
        return $mdDialog.show({
          controller: 'ConversationTeamInboxSettingsController',
          templateUrl:
            '/angular/views/conversations/conversation-team-inbox-settings.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false
        });
      };

      return self;
    }
  ]);

  const ConversationTeamInboxSettingsController = function (
    $q,
    $scope,
    $mdDialog,
    $mdToast,
    appDataService,
    conversationsService,
    unreadCountsService,
    userService
  ) {
    var self = this;

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.save = function () {
      $scope.isSaving = true;

      var payload = map($scope.inboxSettings, function (setting, managerId) {
        return {
          id: managerId,
          display: setting.show_in_sidebar
        };
      });

      conversationsService
        .updateTeamInboxSettings(payload)
        .success(function () {
          $mdDialog.hide();
          $mdToast.showSimple('Inbox settings updated!');
        })
        .error(function () {
          $mdToast.showSimple(
            'There was an error updating your inbox settings'
          );
        })
        .finally(function () {
          $scope.isSaving = false;
        });
    };

    self._initialize = function () {
      self._setTeamMembers();

      var initPromises = [self._getTeamMemberDisplaySettings()];

      $q.all(initPromises).finally(function () {
        $scope.isLoaded = true;
      });
    };

    self._setTeamMembers = function () {
      var currentUser = userService.getScopedUser();

      $scope.teamMembers = filter(
        appDataService.getTeamMembers(),
        function (member) {
          return member.Manager.id !== currentUser.id;
        }
      );
    };

    self._getTeamMemberDisplaySettings = function () {
      return unreadCountsService
        ._getTeamUnreadCounts()
        .success(function (response) {
          $scope.inboxSettings = response.unread_message_counts_by_manager_id;
        });
    };

    self._initialize();
  };

  ConversationTeamInboxSettingsController.$inject = [
    '$q',
    '$scope',
    '$mdDialog',
    '$mdToast',
    'appDataService',
    'conversationsService',
    'unreadCountsService',
    'userService'
  ];

  app.controller(
    'ConversationTeamInboxSettingsController',
    ConversationTeamInboxSettingsController
  );
})(window.angular);
