/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('listingAmenities', function () {
    return {
      restrict: 'E',
      scope: {
        listing: '='
      },
      templateUrl: '/angular/views/listing/directives/amenities.html'
    };
  });

  app.directive('editListingAmenities', function () {
    return {
      restrict: 'E',
      scope: {
        listing: '=',
        completionHandler: '=',
        continueHandler: '='
      },
      templateUrl: '/angular/views/listing/directives/edit-amenities.html',
      controller: 'EditListingAmenitiesController'
    };
  });

  app.controller('EditListingAmenitiesController', [
    '$scope',
    'editListingCompletionHandler',
    function ($scope, editListingCompletionHandler) {
      var self = this;

      self.completionHandler = editListingCompletionHandler.amenities;

      $scope.$watch('basicsForm.$invalid', function () {
        self.completionHandler.isComplete = !$scope.amenitiesForm.$invalid;
      });
    }
  ]);
})();
