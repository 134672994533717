/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('residentEventReminder', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/residentEvents/resident-event-reminder.html',
      scope: {
        reminder: '=',
        resident: '='
      },
      controller: [
        '$rootScope',
        '$scope',
        '$mdDialog',
        '$moment',
        '$mdToast',
        'userInteractionsService',
        'timeService',
        function (
          $rootScope,
          $scope,
          $mdDialog,
          $moment,
          $mdToast,
          userInteractionsService,
          timeService
        ) {
          $scope.eventTime = $moment($scope.reminder.event_time)
            .add(12, 'hours')
            .format();
          $scope.showFollowUpModal = false;
          $scope.timeService = timeService;

          $scope.tryRemoveFollowUp = function () {
            var confirm = $mdDialog
              .confirm()
              .title('Are you sure you want to delete this follow up reminder?')
              .ok('Confirm')
              .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
              userInteractionsService
                .deleteFollowUp($scope.resident, $scope.reminder)
                .then(function () {
                  $scope.reminder.isDeleted = true;
                });
            });
          };

          $scope.tryEditFollowUpDate = function () {
            $scope.followUpData = [
              {
                id: $scope.resident.id,
                name: `${$scope.resident.profile.first_name} ${$scope.resident.profile.last_name}`,
                reminderDate: $scope.reminder.calendar_event.date,
                reminderTime:
                  $scope.reminder.calendar_event.extra?.follow_up_time,
                note: $scope.reminder.calendar_event.description,
                mode: 'edit'
              }
            ];

            $scope.showFollowUpModal = true;
          };

          $scope.closeActivityModal = function () {
            $scope.$apply(() => {
              $scope.showFollowUpModal = false;
            });
          };

          $scope.updateFollowUpSuccess = function (reload) {
            $scope.closeActivityModal();

            if (reload) {
              $rootScope.$emit('reloadResidentDetails');
              $mdToast.showSimple('Reminder updated!');
            }
          };

          $scope.editActivity = function (data) {
            $scope.reminder.calendar_event.date = data.reminder_time;
            $scope.reminder.calendar_event.description = data.message;

            return userInteractionsService.updateFollowUp(
              $scope.resident,
              $scope.reminder
            );
          };
        }
      ]
    };
  });
})(window.angular);
