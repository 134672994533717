/* eslint-disable */
import moment from 'moment';

(function (angular) {
  'use strict';

  var app = angular.module('knock-PropertyReports');

  const ProspectPageController = function (
    $rootScope,
    $scope,
    $location,
    $routeParams,
    appDataService,
    conversationsService,
    userService,
    $auth,
    managerApi,
    attachmentService,
    streamCarouselService,
    timeService,
    chatTrackingService,
    visitsService,
    shownUnitsModalService
  ) {
    if (
      !$rootScope.featureFlags.PROSPECT_PAGE_REDESIGN &&
      localStorage.getItem('ProspectResidentBeta') !== 'true'
    ) {
      if ($location.search) {
        $location.url(`/reports/prospects${window.location.search}`);
      } else {
        $location.url('/reports/prospects');
      }

      return;
    }

    chatTrackingService.hide();

    $rootScope.$on('SalesForceWidgetMounted', () => {
      chatTrackingService.hide();
    });

    $scope.properties = appDataService.getProperties();
    $scope.sources = appDataService.getSources();
    $scope.managers = appDataService.getTeamMembers();
    $scope.timeService = timeService;
    $scope.referralsEnabled =
      $rootScope.appPreferences.company.enable_property_referrals;
    $scope.cheatProofEngagementScoreEnabled =
      $rootScope.appPreferences.company.enable_cheatproof_engagement_score;

    $scope.isDemandXEnabled = $rootScope.appPreferences.company.enable_demand_x;
    $scope.isDemandXPriorityEnabled = $rootScope.featureFlags.DEMAND_X_PRIORITY;
    $scope.isDemandXDemoEnabled = $rootScope.featureFlags.DEMAND_X_DEMO;
    $scope.isBulkCommunicationEnhancementEnabled =
      $rootScope.featureFlags.BULK_COMMUNICATION_ENHANCEMENT;

    $scope.isAgentAttributionEnable = $rootScope.featureFlags.AGENT_ATTRIBUTION;

    $scope.currentCompanyId = appDataService.data.currentCompany.id;

    $scope.token = $auth.getToken();
    $scope.managerInfo = {};
    $scope.mergeTags = [];
    $scope.isCompanyQuickRepliesEnabled =
      $rootScope.featureFlags.COMPANY_QUICK_REPLIES;
    $scope.isInternal = $routeParams.internal === 'true';

    $scope.saveQuickReply = managerApi.updateManagerQuickReply;
    $scope.deleteQuickReply = managerApi.deleteManagerQuickReply;
    $scope.handleAttachments = attachmentService.uploadByFile;
    $scope.deleteAttachment = attachmentService.deleteAttachment;
    $scope.sendBatchMessage = conversationsService.sendMessageBatch;

    $scope.leasingTeamId = appDataService.getCurrentLeasingTeamId();
    $scope.emailAttachments = {
      local: [],
      remote: []
    };
    $scope.emailDomainBlocklist = $rootScope.emailDomainBlocklist;

    $scope.openProspectStreamModal = function (streamId) {
      conversationsService.openThreadById(streamId, true);
    };

    $scope.openCarousel = function (streamIds) {
      if (streamIds.length > 0) {
        streamCarouselService.showCarousel(streamIds);
      }
    };

    $scope.removeQueryStringParam = function (paramName) {
      if (paramName) {
        $location.search({ [paramName]: null });
        $scope.$apply();
      }
    };
    $scope.getManagerQuickReplies = function () {
      return managerApi.getManagerQuickRepliesBulkMessaging(
        $scope.leasingTeamId
      );
    };

    $scope.saveWalkinDetails = function (
      prospect_id,
      selectedUser,
      walkinDate,
      walkinTime
    ) {
      const visitTime = `${walkinDate}T${moment(walkinTime).format('HH:mmZ')}`;
      return visitsService.createVisit(
        prospect_id,
        visitTime,
        null,
        selectedUser
      );
    };

    $scope.openShownUnits = function (prospect, visit) {
      return shownUnitsModalService.openShownUnitsModal(prospect, visit);
    };

    $scope.generateTimeOptions = function (date) {
      /**
       * Format selected date in mm/dd/yyyy
       */
      const selectedFormattedDate = timeService.get(date).format('MM/DD/YYYY');
      /**
       * Format current date into similar format as our selected date.
       */
      const currentFormattedDate = timeService.get().format('MM/DD/YYYY');
      let timeSlots = [];
      let timeWalker = timeService.get().startOf('day').hour(7);
      /**
       * If our current date is the same as the selected date, then we cut off time selection at our current time.
       */
      const todayEnd =
        selectedFormattedDate === currentFormattedDate
          ? timeService.get()
          : timeService.get().startOf('day').hour(22);
      while (timeWalker.isBefore(todayEnd)) {
        timeSlots.push({
          label: timeService.clone(timeWalker).format('h:mm a'),
          value: timeService.clone(timeWalker)
        });
        timeWalker.add(5, 'minutes');
      }

      return timeSlots;
    };

    let self = this;

    self._getManagerInfo = function () {
      const teamMembers = appDataService.getTeamMembers().map((member) => {
        return {
          id: member.Manager.id,
          name:
            member.ManagerInfo.first_name + ' ' + member.ManagerInfo.last_name
        };
      });

      $scope.managerInfo = teamMembers.find(
        (member) => member.id === userService.getScopedUser().id
      );
    };
    self._getMergeTags = function () {
      const tags = appDataService.getEmailMergeTags('prospect');

      $scope.mergeTags = tags.map((tag) => {
        tag.value = `#${tag.name}`;
        return Object.assign({}, tag);
      });
    };

    self._initialize = function () {
      self._getManagerInfo();
      self._getMergeTags();
    };

    self._initialize();
  };

  ProspectPageController.$inject = [
    '$rootScope',
    '$scope',
    '$location',
    '$routeParams',
    'appDataService',
    'conversationsService',
    'userService',
    '$auth',
    'managerApi',
    'attachmentService',
    'streamCarouselService',
    'timeService',
    'chatTrackingService',
    'visitsService',
    'shownUnitsModalService'
  ];

  app.controller('ProspectPageController', ProspectPageController);
})(window.angular);
