import React from 'react';

export const ParkingIcon = () => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0625 11.625C16.8239 11.625 19.0625 9.38642 19.0625 6.625C19.0625 3.86357 16.8239 1.625 14.0625 1.625C11.3011 1.625 9.0625 3.86357 9.0625 6.625C9.0625 9.38642 11.3011 11.625 14.0625 11.625Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8125 6.625H14.0625C14.394 6.625 14.712 6.4933 14.9464 6.25888C15.1808 6.02447 15.3125 5.70652 15.3125 5.375C15.3125 5.04348 15.1808 4.72553 14.9464 4.49112C14.712 4.2567 14.394 4.125 14.0625 4.125H12.8125V9.125"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.77246 12.3392L4.23079 9.08917C4.36532 8.73228 4.60544 8.42488 4.91915 8.20796C5.23285 7.99103 5.60523 7.87488 5.98663 7.875H6.56246"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5625 17.7075V19.1242C1.5625 19.4557 1.6942 19.7736 1.92862 20.008C2.16303 20.2424 2.48098 20.3742 2.8125 20.3742C3.14402 20.3742 3.46197 20.2424 3.69638 20.008C3.9308 19.7736 4.0625 19.4557 4.0625 19.1242V17.8742"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8125 17.7075V19.1242C17.8125 19.4557 17.6808 19.7736 17.4464 20.008C17.212 20.2424 16.894 20.3742 16.5625 20.3742C16.231 20.3742 15.913 20.2424 15.6786 20.008C15.4442 19.7736 15.3125 19.4557 15.3125 19.1242V17.8742"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.0625 12.25H3.4375C2.77446 12.25 2.13858 12.5134 1.66973 12.9823C1.20089 13.4511 0.9375 14.087 0.9375 14.75V16.625C0.9375 16.9565 1.0692 17.2745 1.30362 17.5089C1.53803 17.7433 1.85598 17.875 2.1875 17.875H17.1875C17.519 17.875 17.837 17.7433 18.0714 17.5089C18.3058 17.2745 18.4375 16.9565 18.4375 16.625V14.75C18.4389 14.3112 18.3238 13.8798 18.1042 13.5"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 15.375C3.57741 15.375 3.4375 15.2351 3.4375 15.0625C3.4375 14.8899 3.57741 14.75 3.75 14.75"
      stroke="#353950"
      strokeWidth="1.5"
    />
    <path
      d="M3.75 15.375C3.92259 15.375 4.0625 15.2351 4.0625 15.0625C4.0625 14.8899 3.92259 14.75 3.75 14.75"
      stroke="#353950"
      strokeWidth="1.5"
    />
    <path
      d="M15.625 15.375C15.4524 15.375 15.3125 15.2351 15.3125 15.0625C15.3125 14.8899 15.4524 14.75 15.625 14.75"
      stroke="#353950"
      strokeWidth="1.5"
    />
    <path
      d="M15.625 15.375C15.7976 15.375 15.9375 15.2351 15.9375 15.0625C15.9375 14.8899 15.7976 14.75 15.625 14.75"
      stroke="#353950"
      strokeWidth="1.5"
    />
  </svg>
);
