import React from 'react';

export const BlankNotesTabIcon = () => (
  <svg
    width="184"
    height="180"
    viewBox="0 0 184 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.378 7.05351C91.0694 -8.03666 56.7246 1.88225 41.6663 29.208C36.1777 39.1679 34.0089 50.0655 34.7914 60.6249C23.1757 64.1962 12.8797 72.109 6.54766 83.5995C-6.40692 107.108 2.13624 136.689 25.6293 149.671L69.5312 173.931C93.0243 186.913 122.571 178.379 135.526 154.871C137.489 151.308 138.959 147.605 139.957 143.842C155.061 147.256 171.203 140.492 179.055 126.244C188.577 108.965 182.297 87.2215 165.029 77.6792L150.027 69.3893C154.636 45.9229 143.916 21.1658 121.87 8.98354L118.378 7.05351Z"
      fill="#EBEEFE"
    />
    <path
      d="M105 72.5366H117.5C120.261 72.5366 122.5 74.7752 122.5 77.5366V110.467C122.5 111.792 121.973 113.063 121.037 114L108.963 126.073C108.026 127.01 106.755 127.536 105.43 127.537H67.5C64.7386 127.537 62.5 125.298 62.5 122.537V77.5366C62.5 74.7752 64.7386 72.5366 67.5 72.5366H80"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102.5 127.537V112.537C102.5 109.775 104.739 107.537 107.5 107.537H122.5"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.5 67.5366C96.6421 67.5366 100 64.1788 100 60.0366C100 55.8945 96.6421 52.5366 92.5 52.5366C88.3579 52.5366 85 55.8945 85 60.0366C85 64.1788 88.3579 67.5366 92.5 67.5366Z"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.5 85.0366V67.5366"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75 97.5366H110"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75 112.537H90"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
