/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Todo');

  app.directive('todoIcon', function () {
    return {
      restrict: 'E',
      template:
        '<div class="todo-icon-container" tooltip="{{status.explanation || \'Good to go!\'}}" tooltip-placement="right"><div class="todo-icon" ng-class="status.color"></div></div>',
      scope: {
        status: '='
      }
    };
  });
})(window.angular);
