import React, { FC, useEffect } from 'react';
import { ThemeProvider } from '@knockrentals/knock-shared-web';
import { NearbyCommunitiesSearchPreferences } from '../../services/nearbyCommunitiesService';
import {
  useNearbyCommunities,
  NearbyCommunitiesProvider
} from './useNearbyCommunities';
import nearbyCommunitiesService from '../../services/nearbyCommunitiesService';

import NearbyCommunitiesList from './NearbyCommunitiesList';
import ReferralDialogContainer from './ReferralDialogContainer';
import AppointmentScheduleDialogContainer from './AppointmentScheduleDialog';

export interface NearbyCommunityContainerProps {
  isActive: boolean;
  preferences?: NearbyCommunitiesSearchPreferences;
  propertyId: number;
  prospectId: number;
  onReferral: () => void;
  setIsConnectedProfilesDialogActive: (isActive: boolean) => void;
  shouldShowConnectedProfiles: boolean;
}

/**
 * NearbyCommunitiesContainer:
 * Fetches nearby communities when is active and mounted.
 * Will refetch if propertyId, prospectId, or preferences change.
 * Wraps a NearbyCommunitiesList that consumes useNearbyCommunities.
 */
const NearbyCommunitiesContainer: FC<NearbyCommunityContainerProps> = (
  props
) => {
  const { propertyId, prospectId, preferences, isActive } = props;

  const { fetchNearbyCommunities, isFetchingNearbyCommunities } =
    useNearbyCommunities();

  const initialNearbyCommunitiesFetch = () => {
    if (isActive && !isFetchingNearbyCommunities) {
      fetchNearbyCommunities(preferences);
    }
  };
  useEffect(initialNearbyCommunitiesFetch, [
    prospectId,
    preferences,
    propertyId,
    isActive
  ]);

  return <NearbyCommunitiesList />;
};

export default (props: NearbyCommunityContainerProps) => (
  <ThemeProvider>
    <NearbyCommunitiesProvider
      initialState={{
        nearbyCommunitiesService,
        sourceProspectId: props.prospectId,
        sourcePropertyId: props.propertyId,
        onReferral: props.onReferral,
        setIsConnectedProfilesDialogActive:
          props.setIsConnectedProfilesDialogActive,
        shouldShowConnectedProfiles: props.shouldShowConnectedProfiles
      }}
    >
      <NearbyCommunitiesContainer {...props} />
      <ReferralDialogContainer />
      <AppointmentScheduleDialogContainer />
    </NearbyCommunitiesProvider>
  </ThemeProvider>
);
