/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('dashboardApi', [
    'apiBase',
    function (apiBase) {
      return {
        getDashboardData: function (modules) {
          const params = {
            m: modules,
            bypassAnalytics: true
          };

          return apiBase.get('/dashboard', { params: params });
        },
        getDashboardAnalyticsData: function (bypassEngagement = false) {
          const params = { bypassEngagement };

          return apiBase.get('/dashboard/analytics', { params: params });
        }
      };
    }
  ]);
})();
