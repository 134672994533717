/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('calendar', function () {
    return {
      restrict: 'E',
      scope: {
        start: '=',
        end: '=',
        intervalDuration: '=',
        events: '=',
        options: '=',
        eventClickHandler: '='
      },
      templateUrl: '/angular/views/directives/calendar.html',
      controller: 'CalendarController'
    };
  });

  app.controller('CalendarController', [
    '$scope',
    'timeService',
    '$location',
    function ($scope, timeService, $location) {
      $scope.days = days(timeService, $scope.start, $scope.end);
      $scope.intervals = dayIntervals(
        timeService,
        $scope.intervalDuration,
        $scope.options.hourStart,
        $scope.options.hourEnd - 1
      );

      $scope.scrollToEvent = function (eventId) {
        $location.hash(eventId);
      };

      $scope.$watch('events', function () {
        $scope.eventMap = eventMap(timeService, $scope.events);
      });
    }
  ]);

  var days = function (timeService, start, end) {
    /*
     * Create an array of of dates for each day in the range [start, end]
     */

    var days = [];
    var range = timeService.now().range(start, end);

    range.by('days', function (day) {
      days.push(day.toDate());
    });

    return days;
  };

  var dayIntervals = function (
    timeService,
    intervalDuration,
    startHourInt,
    endHourInt
  ) {
    /*
     * Create an array of times separated by the given duration for a single day.
     */

    var intervals = [];
    var intervalStart = timeService.now().startOf('day');
    var intervalEnd = timeService.get(intervalStart).add(intervalDuration);

    var dayStart = timeService.now().startOf('day').hour(startHourInt);
    var dayEnd = timeService.now().endOf('day').hour(endHourInt);

    var dayRange = timeService.range(dayStart, dayEnd);

    dayRange.by(
      timeService.range(intervalStart, intervalEnd),
      function (interval) {
        intervals.push(interval.toDate());
      }
    );

    return intervals;
  };

  var eventMap = function (timeService, events) {
    /*
     * For each of the event types (appointment, request), add the
     * events to a map keyed by the the start of the day that the event takes
     * place on. This is used to line up the events with their respective day-columns
     */

    var eventMap = {};
    events.appointments.forEach(function (event) {
      var eventDayStart = timeService
        .get(event.start_time)
        .startOf('day')
        .toDate(); //jshint ignore:line
      if (!(eventDayStart in eventMap)) {
        eventMap[eventDayStart] = [];
      }
      eventMap[eventDayStart].push(event);
    });

    events.requests.forEach(function (event) {
      var eventDayStart = timeService
        .get(event.start_time)
        .startOf('day')
        .toDate(); //jshint ignore:line
      if (!(eventDayStart in eventMap)) {
        eventMap[eventDayStart] = [];
      }
      eventMap[eventDayStart].push(event);
    });

    return eventMap;
  };
})();
