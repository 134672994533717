(function (angular) {
  var app = angular.module('knockApp');

  app.factory('unitApi', [
    'apiBase',
    function (apiBase) {
      return {
        getUnits: function (params) {
          const {
            propertyId,
            beds,
            baths,
            status,
            occupancy,
            buildingId,
            layoutId,
            notice,
            type,
            visibility,
            layoutIds
          } = params;

          var url = '/property/' + propertyId + '/units';
          var filtersArray = [];

          if (beds != null) {
            if (Array.isArray(beds)) {
              beds.forEach((bed) => {
                filtersArray.push('bedrooms=' + bed);
              });
            } else {
              filtersArray.push('bedrooms=' + beds);
            }
          }

          if (baths != null) {
            if (Array.isArray(baths)) {
              baths.forEach((bath) => {
                filtersArray.push('bathrooms=' + bath);
              });
            } else {
              filtersArray.push('bathrooms=' + baths);
            }
          }

          if (status != null) {
            filtersArray.push('status=' + status);
          }

          if (occupancy != null) {
            filtersArray.push('occupancy=' + occupancy);
          }

          if (buildingId != null) {
            if (Array.isArray(buildingId)) {
              buildingId.forEach((building_id) => {
                filtersArray.push('building_id=' + building_id);
              });
            } else {
              filtersArray.push('building_id=' + buildingId);
            }
          }

          if (layoutIds != null) {
            if (Array.isArray(layoutIds)) {
              layoutIds.forEach((layout_ids) => {
                filtersArray.push('layout_ids=' + layout_ids);
              });
            } else {
              filtersArray.push('layout_ids=' + layoutIds);
            }
          }

          if (layoutId != null) {
            filtersArray.push('layout_id=' + layoutId);
          }

          if (notice != null) {
            filtersArray.push('notice=' + notice);
          }

          if (type != null) {
            filtersArray.push('type=' + type);
          }

          if (visibility != null) {
            filtersArray.push('visibility=' + visibility);
          }

          if (filtersArray.length > 0) {
            url += '?';
            url += filtersArray.join('&');
          }

          return apiBase.get(url);
        },
        setAttributes: function (integrationId, unitId, attributes) {
          var url = `/property/${integrationId}/units/${unitId}/attributes`;
          return apiBase.put(url, attributes);
        },
        batchSetAttributes: function (propertyId, unitAttributes) {
          var url = '/property/' + propertyId + '/batch-units';
          return apiBase.patch(url, unitAttributes);
        },
        refresh: function (propertyId) {
          var url = '/property/' + propertyId + '/refresh';
          return apiBase.get(url);
        },
        getLayout: function (propertyId, layoutId) {
          var url = '/property/' + propertyId + '/layouts/' + layoutId;
          return apiBase.get(url);
        },
        getLayouts: function (propertyId) {
          var url = '/property/' + propertyId + '/layouts';
          return apiBase.get(url);
        },
        updateLayout: function (propertyId, layoutId, attributes) {
          var url = '/property/' + propertyId + '/layouts/' + layoutId;
          return apiBase.put(url, attributes);
        }
      };
    }
  ]);
})(window.angular);
