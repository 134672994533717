/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Authentication');

  app.controller('LoginPromptController', [
    '$scope',
    'authenticationService',
    function ($scope, authenticationService) {
      $scope.openRenterAuthModal = function () {
        authenticationService.openRenterAuthModal('login');
      };

      $scope.openManagerAuthModal = function () {
        authenticationService.openManagerAuthModal();
      };
    }
  ]);
})(window.angular);
