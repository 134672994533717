/**
 * This interceptor will add the X-Knock-Auth-As-Leasing-Team header to
 * requests if the ?lt query parameter is specified on the current page or
 * the leasing team id is present in session storage.
 */
(function () {
  const angular = window.angular;
  let app = angular.module('knockApp');
  app.factory('authAsLeasingTeamInterceptor', [
    function () {
      return {
        request: (config) => {
          // check if the ?lt query parameter is specified on the current page
          let queryParams = new URLSearchParams(window.location.search);
          let scopedLeasingTeamId = queryParams.get('lt');

          if (!scopedLeasingTeamId && !!window.location.hash) {
            let hashParams = new URLSearchParams(
              window.location.hash.replace('#', '?')
            );
            scopedLeasingTeamId = hashParams.get('lt');
          }

          if (scopedLeasingTeamId) {
            // if the ?lt query was specified store it in session storage
            window.sessionStorage.scopedLeasingTeamId = scopedLeasingTeamId;
          } else {
            // try to get the leasing team id from session storage
            scopedLeasingTeamId = window.sessionStorage.scopedLeasingTeamId;
          }

          if (scopedLeasingTeamId) {
            config.headers['X-Knock-Auth-As-Leasing-Team'] =
              scopedLeasingTeamId;
          }

          return config;
        }
      };
    }
  ]);
})();
