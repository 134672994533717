/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.filter('bedrooms', function () {
    return function (input) {
      return formatAndAppendNumber(input, ' bd');
    };
  });

  app.filter('bathrooms', function () {
    return function (input) {
      return formatAndAppendNumber(input, ' ba');
    };
  });

  app.filter('squareFeet', function () {
    return function (input) {
      return formatAndAppendNumber(input, ' sqft');
    };
  });

  // Filter for New Units floorplan attributes- bedrooms, bathrooms, and sqft
  app.filter('nuBedrooms', () => {
    return (input) => formatNewUnitsFloorplanAttr(input, 'bd');
  });

  app.filter('nuBathrooms', () => {
    return (input) => formatNewUnitsFloorplanAttr(input, 'ba');
  });

  app.filter('nuSquareFeet', () => {
    return (input) => formatNewUnitsFloorplanAttr(input, 'sq.ft.');
  });

  /*
     The regex uses 2 lookahead assertions: a positive one to look for any point in the string that has
     a multiple of 3 digits in a row after it, and a negative assertion to make sure that point only has
     exactly a multiple of 3 digits. The replacement expression puts a comma there.
     see: http://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
    */
  function commaSeparateNumber(val) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function formatAndAppendNumber(input, append) {
    if (!input) {
      return '0' + append;
    }

    input = parseFloat(input);
    if (isNaN(input) || input <= 0) {
      return '0' + append;
    }

    input = commaSeparateNumber(input);
    input = input + append;

    return input;
  }

  const formatNewUnitsFloorplanAttr = (input, append) => {
    if (input === null) {
      return `Null ${append}`;
    }
    return `${input} ${append}`;
  };
})();
