/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('kanbanHeader', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/kanban/kanban-header.html',
      controller: 'KanbanHeaderController',
      transclude: true,
      replace: true
    };
  });

  const KanbanHeaderController = function ($scope) {
    var self = this;
  };
  KanbanHeaderController.$inject = ['$scope'];

  app.controller('KanbanHeaderController', KanbanHeaderController);
})(window.angular);
