/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import range from 'lodash/range';
/* global angular */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Account');

  app.directive('accountPreferencesPrescreening', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/account/preferences/account-preferences-prescreening.html',
      controller: 'AccountPreferencesPrescreeningController',
      scope: {
        managerRequirements: '='
      }
    };
  });

  app.controller('AccountPreferencesPrescreeningController', [
    '$scope',
    '$location',
    function ($scope, $location) {
      var self = this;

      $scope.path = $location.path();
      $scope.incomeMultiplierOptions = range(2, 4.5, 0.5);

      $scope.help = {
        phoneNumbers: 'Require renters to have verified phone numbers',
        petPolicy: 'Require pet ownership screening',
        monthlyIncome: 'Require minimum monthly income',
        criminalHistory: 'Require criminal history screening',
        evictionHistory: 'Require eviction history screening',
        moveDates: 'Require expected move-in date'
      };

      self._initialize = function () {
        $scope.minimumIncomeCheckbox =
          $scope.managerRequirements.income_multiplier > 0;
        $scope.minimumIncomeMultiplier = 2;
      };

      $scope.$watch('minimumIncomeCheckbox', function (isChecked) {
        var minimumSafeRequirement = Math.max(
          $scope.managerRequirements.income_multiplier,
          $scope.minimumIncomeMultiplier
        );
        $scope.managerRequirements.income_multiplier = isChecked
          ? minimumSafeRequirement
          : 0;
      });

      $scope.showNextAddCustomScreeningBox = function () {
        var customRequirement = {};
        customRequirement.isEditing = true;
        customRequirement.desired_answer = true;
        customRequirement.isNew = true;
        if (!$scope.managerRequirements.custom_requirements)
          $scope.managerRequirements.custom_requirements = [];
        $scope.managerRequirements.custom_requirements.push(customRequirement);
      };

      $scope.removeCustomQuestion = function (custom) {
        remove($scope.managerRequirements.custom_requirements, custom);
      };

      $scope.startEditing = function (requirement) {
        requirement.isEditing = true;
        requirement.oldPrompt = requirement.prompt;
      };

      $scope.cancelEditing = function (requirement) {
        if (requirement.isNew) {
          $scope.removeCustomQuestion(requirement);
        } else {
          requirement.isEditing = false;
          requirement.prompt = requirement.oldPrompt;
        }
      };

      $scope.doneEditing = function (requirement) {
        if (isEmpty(requirement.prompt)) {
          $scope.removeCustomQuestion(requirement);
        } else {
          requirement.isEditing = false;
        }
      };

      self._initialize();
    }
  ]);
})(window.angular);
