/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('notificationsApi', [
    'apiBase',
    function (apiBase) {
      var getNotifications = function (params) {
        return apiBase.get('/notifications', { params: params });
      };

      return {
        lastNNotifications: function (n) {
          return getNotifications({ limit: n });
        },
        getPagedNotifications: function (pageIndex, limit) {
          return getNotifications({ limit: limit, offset: pageIndex * limit });
        },
        readNotification: function (notificationId) {
          var url = '/notifications/' + notificationId + '/read';

          return apiBase.put(url);
        },
        readNotifications: function (notificationIds) {
          if (notificationIds.length <= 0) {
            return;
          }

          var payload = {
            ids: notificationIds
          };

          return apiBase.put('/notifications/read', payload);
        },
        archiveNotification: function (notificationId) {
          var url = '/notifications/' + notificationId + '/archive';

          return apiBase.put(url);
        },
        getPreferences: function () {
          return apiBase.get('/user/notifications/preferences');
        },
        getPreferencesById: function (managerId) {
          return apiBase.get(
            '/user/' + managerId + '/notifications/preferences'
          );
        },
        updatePreferences: function (
          updatedPreferences,
          unreadMessagePeriod,
          clientPreferences
        ) {
          var payload = {
            preferences: updatedPreferences,
            unread_message_period: unreadMessagePeriod,
            client_preferences: clientPreferences
          };

          return apiBase.put('/user/notifications/preferences', payload);
        },
        updatePreferencesById: function (
          managerId,
          updatedPreferences,
          unreadMessagePeriod,
          clientPreferences
        ) {
          var payload = {
            preferences: updatedPreferences,
            unread_message_period: unreadMessagePeriod,
            client_preferences: clientPreferences
          };

          return apiBase.put(
            '/user/' + managerId + '/notifications/preferences',
            payload
          );
        }
      };
    }
  ]);
})();
