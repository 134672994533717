import React, { FC, useMemo } from 'react';
import { Box, Chip, Link, Tooltip, TableCell } from '@material-ui/core';
import classnames from 'classnames';

import {
  IconButton,
  makeStyles,
  NamedColors,
  Text
} from '@knockrentals/knock-shared-web';
import {
  Layout,
  PropertyLayout,
  Prospect
} from '../../../../../app/services/prospects/entities';
import HotLead from './HotLead';
import { colors, useCommonStyles } from '../../commonStyles/commonStyles';
import { useAngularContext } from '../../AngularContextProvider';
import { useAppSelector } from '../../../../../app/hooks';
import { usePropertyLayouts } from 'app/services/prospects/hooks';
import {
  CloudUploadIcon,
  PersonSecureIcon,
  HourglassIcon,
  EnvelopeIcon,
  PhoneIcon,
  PhoneSMSIcon
} from '../../icons';

const useStyles = makeStyles(() => ({
  reduceText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  nameColumn: {
    maxWidth: '200px'
  },

  prospectName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover, &:visited': {
      color: NamedColors.denim[500]
    }
  },

  demandXNameColumn: {
    maxWidth: '240px'
  },

  nameColumnBox: {
    marginLeft: '24px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'flex'
  },

  hotLeadBox: {
    width: '20px',
    justifyContent: 'center',
    marginRight: '10px'
  },

  contactColumn: {
    width: '120px'
  },

  contactAction: {
    '&:hover': {
      backgroundColor: 'transparent'
    },

    '& svg': {
      width: '20px'
    }
  },

  contactActionEnabled: {
    '& svg path': {
      stroke: NamedColors.denim[600]
    }
  },

  contactActionDisabled: {
    '& svg path': {
      stroke: NamedColors.slate[200]
    }
  },

  todoColumn: {
    width: '100px'
  },

  todoIcon: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    display: 'flex',
    justifySelf: 'center'
  },

  todoNeedsContact: {
    backgroundColor: colors.error
  },

  todoNeedsContactSoon: {
    backgroundColor: NamedColors.banana[500]
  },

  todoGreen: {
    backgroundColor: NamedColors.grass[600]
  },

  chip: {
    fontSize: '13px',

    '& .MuiChip-label': {
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  },

  statusNew: {
    backgroundColor: NamedColors.apple[100],
    color: NamedColors.apple[900]
  },

  statusOpen: {
    backgroundColor: NamedColors.grass[100],
    color: NamedColors.grass[900]
  },

  statusWaiting: {
    backgroundColor: NamedColors.banana[100],
    color: NamedColors.banana[900]
  },

  statusBooked: {
    backgroundColor: NamedColors.sky[100],
    color: NamedColors.sky[900]
  },

  statusNoShow: {
    backgroundColor: NamedColors.carrot[100],
    color: NamedColors.carrot[900]
  },

  statusApplied: {
    backgroundColor: NamedColors.violet[100],
    color: NamedColors.violet[900]
  },

  statusOther: {
    backgroundColor: NamedColors.slate[100],
    color: NamedColors.slate[900]
  },

  propertyColumn: {
    maxWidth: '150px'
  },

  ownerColumn: {
    maxWidth: '150px'
  },

  lastContactColumn: {
    display: 'inline-block'
  },

  detailsColumn: {
    '& span': {
      marginRight: '4px',

      '&:last-child': {
        marginRight: 0
      },

      '& svg': {
        height: '18px'
      }
    }
  }
}));

interface ProspectCellsProps {
  prospect: Prospect;
  selected: boolean;
  isHotLead?: boolean;
}

const ProspectCells: FC<ProspectCellsProps> = ({
  prospect,
  selected,
  isHotLead
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const {
    properties,
    openProspectStreamModal,
    isDemandXEnabled,
    isDemandXDemoEnabled
  } = useAngularContext();
  const { floorplans } = useAppSelector((state) => state.prospectFilter);
  const { layouts } = usePropertyLayouts();

  const layoutName = useMemo(() => {
    if (Object.keys(floorplans).length > 0) {
      const layout: (string | undefined)[] = layouts.reduce(
        (layouts: (string | undefined)[], propertyLayout: PropertyLayout) => {
          const name = propertyLayout.layouts.map((layout: Layout) => {
            if (prospect.preferences.floorplan === layout.id) {
              return layout.name;
            }
            return undefined;
          });

          layouts.push(
            ...name.filter((name: string | undefined) => name !== undefined)
          );
          return layouts;
        },
        []
      );

      if (layout.length > 0) {
        return layout[0];
      }
    }
    return '-';
  }, [layouts, floorplans, prospect.preferences.floorplan]);

  return useMemo(() => {
    const handleContactActionClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      const messageMode = event.currentTarget.getAttribute('data-message-mode');

      window.sessionStorage.setItem(
        'conversationMessageMode',
        messageMode || ''
      );
      openProspectStreamModal(prospect.streamId);
    };

    const getTodoClass = (color: string) => {
      let className = `${classes.todoIcon}`;

      switch (color) {
        case 'green':
          className += ` ${classes.todoGreen}`;
          break;
        case 'red':
          className += ` ${classes.todoNeedsContact}`;
          break;
        case 'yellow':
          className += ` ${classes.todoNeedsContactSoon}`;
          break;
      }

      return className;
    };

    const getStatusInfo = (status: string) => {
      const statusInfo = {
        className: `${classes.chip} `,
        label: ''
      };

      switch (status) {
        case 'new':
          statusInfo.className += classes.statusNew;
          statusInfo.label = 'New';

          break;
        case 'open':
          statusInfo.className += classes.statusOpen;
          statusInfo.label = 'Open';

          break;
        case 'waiting':
          statusInfo.className += classes.statusWaiting;
          statusInfo.label = 'Waiting';

          break;
        case 'booked':
          statusInfo.className += classes.statusBooked;
          statusInfo.label = 'Booked';

          break;
        case 'visited':
          statusInfo.className += classes.statusBooked;
          statusInfo.label = 'Visited';

          break;
        case 'no-show':
          statusInfo.className += classes.statusNoShow;
          statusInfo.label = 'No Show';

          break;
        case 'in-review':
        case 'applied-leased':
        case 'applied-lost':
          statusInfo.className += classes.statusApplied;
          statusInfo.label = 'Applied';

          break;
        case 'cancelled-manager':
        case 'cancelled-renter':
          statusInfo.className += classes.statusOther;
          statusInfo.label = 'Cancelled';

          break;
        case 'application-in-progress':
          statusInfo.className += classes.statusApplied;
          statusInfo.label = 'Application in Progress';

          break;
        case 'applied-pending-signature':
          statusInfo.className += classes.statusApplied;
          statusInfo.label = 'Applied - Pending Signature';

          break;
        case 'lost':
          statusInfo.className += classes.statusOther;
          statusInfo.label = 'Lost';

          break;
        default:
          statusInfo.className += classes.statusOther;
          statusInfo.label = status;

          break;
      }

      return statusInfo;
    };

    const statusInfo = getStatusInfo(prospect.status);
    const toDoTooltip = prospect.todoStatus.explanation || 'Good to go!';
    const pmsExportedTooltip = prospect.pmsId
      ? 'Prospect has been exported'
      : 'Prospect has not been exported';
    const idVerifiedTooltip = prospect.profile.idVerified
      ? 'ID verified'
      : 'ID not verified';
    const isWaitlistTooltip = prospect.isWaitlist
      ? 'Prospect is on a waitlist'
      : 'Prospect is not on a waitlist';

    return (
      <>
        <TableCell
          padding="none"
          data-testid="name-cell"
          className={`${
            isDemandXEnabled || isDemandXDemoEnabled
              ? classes.demandXNameColumn
              : classes.nameColumn
          } ${classes.reduceText} ${commonClasses.stickyRowTwo} ${
            selected ? commonClasses.selectedRow : ''
          }`}
        >
          <Box className={classes.nameColumnBox}>
            {(isDemandXEnabled || isDemandXDemoEnabled) && (
              <Box className={classes.hotLeadBox}>
                <HotLead
                  isHotLead={
                    !!isHotLead ||
                    (isDemandXDemoEnabled &&
                      prospect.profile.firstName.toLowerCase().startsWith('k'))
                  }
                />
              </Box>
            )}

            <Link
              className={classes.prospectName}
              onClick={() => openProspectStreamModal(prospect.streamId)}
              variant="subtitle2"
              href="#"
              title={prospect.profile.fullName}
            >
              {prospect.profile.fullName}
            </Link>
          </Box>
        </TableCell>
        <TableCell
          className={classes.contactColumn}
          data-testid="contact-cell"
          id="contact-cell"
        >
          <IconButton
            size="small"
            onClick={handleContactActionClick}
            disabled={!prospect.profile.phone?.canReceiveCall}
            className={classnames(
              classes.contactAction,
              prospect.profile.phone?.canReceiveCall
                ? classes.contactActionEnabled
                : classes.contactActionDisabled
            )}
            data-message-mode="call"
          >
            <PhoneIcon />
          </IconButton>

          <IconButton
            size="small"
            onClick={handleContactActionClick}
            disabled={prospect.smsConsent?.status !== 'granted'}
            className={classnames(
              classes.contactAction,
              prospect.smsConsent?.status === 'granted'
                ? classes.contactActionEnabled
                : classes.contactActionDisabled
            )}
            data-message-mode="sms"
          >
            <PhoneSMSIcon />
          </IconButton>

          <IconButton
            size="small"
            onClick={handleContactActionClick}
            disabled={!prospect.profile.email}
            className={classnames(
              classes.contactAction,
              prospect.profile.email
                ? classes.contactActionEnabled
                : classes.contactActionDisabled
            )}
            data-message-mode="email"
          >
            <EnvelopeIcon />
          </IconButton>
        </TableCell>
        <TableCell className={classes.todoColumn} data-testid="todo-cell">
          <Tooltip title={toDoTooltip} aria-label={toDoTooltip}>
            <Box
              component="span"
              className={getTodoClass(prospect.todoStatus.color)}
            />
          </Tooltip>
        </TableCell>
        <TableCell data-testid="status-cell">
          <Chip
            size="small"
            className={statusInfo.className}
            label={statusInfo.label}
          />
        </TableCell>
        <TableCell data-testid="moving-cell">
          <Text variant="body2">{prospect.profile.targetMoveDate}</Text>
        </TableCell>
        <TableCell data-testid="bedrooms-cell">
          <Text variant="body2" className={commonClasses.capitalize}>
            {prospect.preferences.bedrooms}
          </Text>
        </TableCell>

        {Object.keys(floorplans).length > 0 && (
          <TableCell data-testid="floorplan-cell">
            <Text variant="body2">{layoutName}</Text>
          </TableCell>
        )}

        {properties.length > 1 && (
          <TableCell
            data-testid="property-cell"
            className={`${classes.propertyColumn} ${classes.reduceText}`}
          >
            <Text
              variant="body2"
              variantMapping={{ body2: 'span' }}
              title={prospect.property.name}
            >
              {prospect.property.name}
            </Text>
          </TableCell>
        )}
        <TableCell
          className={`${classes.ownerColumn} ${classes.reduceText}`}
          data-testid="owner-cell"
        >
          <Text
            variant="body2"
            variantMapping={{ body2: 'span' }}
            title={prospect.manager.fullName}
          >
            {prospect.manager.fullName}
          </Text>
        </TableCell>
        <TableCell data-testid="lastcontact-cell">
          <>
            {prospect.hasAppointments ? (
              <i className="fa fa-calendar fa-fw" />
            ) : (
              ''
            )}

            {prospect.isDeleted ? <i className="fa fa-trash fa-fw" /> : ''}

            <Text variant="body2" className={classes.lastContactColumn}>
              {prospect.lastContactedTimeDescription}
              {prospect.lastContactedTime ? ' ago' : ''}
            </Text>
          </>
        </TableCell>
        <TableCell data-testid="followups-cell">
          <Text variant="body2">{prospect.followUpCount}</Text>
        </TableCell>
        <TableCell data-testid="created-cell">
          <Text variant="body2">{prospect.createdTime}</Text>
        </TableCell>
        <TableCell data-testid="source-cell">
          <Text variant="body2">{prospect.source}</Text>
        </TableCell>
        <TableCell data-testid="details-cell">
          <span className={classes.detailsColumn}>
            <Tooltip title={pmsExportedTooltip} aria-label={pmsExportedTooltip}>
              <span
                className={
                  !prospect.pmsId ? commonClasses.disabledIcon : undefined
                }
              >
                <CloudUploadIcon />
              </span>
            </Tooltip>

            <Tooltip title={idVerifiedTooltip} aria-label={idVerifiedTooltip}>
              <span
                className={
                  prospect.profile.idVerified !== 'scanned_verified'
                    ? commonClasses.disabledIcon
                    : undefined
                }
              >
                <PersonSecureIcon useNewDesign={true} />
              </span>
            </Tooltip>

            <Tooltip title={isWaitlistTooltip} aria-label={isWaitlistTooltip}>
              <span
                className={
                  !prospect.isWaitlist ? commonClasses.disabledIcon : undefined
                }
              >
                <HourglassIcon />
              </span>
            </Tooltip>
          </span>
        </TableCell>
      </>
    );
  }, [
    prospect,
    classes,
    commonClasses,
    floorplans,
    properties.length,
    openProspectStreamModal,
    selected,
    layoutName,
    isDemandXEnabled,
    isHotLead,
    isDemandXDemoEnabled
  ]);
};

export default ProspectCells;
