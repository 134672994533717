/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import forEach from 'lodash/forEach';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('mapService', [
    function () {
      return {
        computeZoom: function (accuracy) {
          if (accuracy <= 0) {
            return 14;
          }
          /*
                 This may need to be played around with a bit, but the
                 basic idea is that a zoom level of 13 looks seems good
                 for a location accuracy of 20 meters, which is what I'm
                 getting by default. With the function below, the zoom will
                 switch to 12 with accuracies larger than 90 meters, and
                 go down to 11 with accuracies larger than around 1800 meters.
                 */
          var log20 = function (n) {
            return Math.log(n) / Math.log(20);
          };

          return 13 + Math.round(log20(20 / accuracy));
        },
        pan: function (mapInstance, latitude, longitude, zoom) {
          mapInstance.panTo({ lat: latitude, lng: longitude });

          if (zoom) {
            mapInstance.setZoom(zoom);
          }
        },
        zoomToContext: function (
          mapInstance,
          markers,
          centerPoint,
          ignorePadding
        ) {
          if (!mapInstance) {
            return;
          }

          if (centerPoint) {
            this.center = centerPoint;
          }

          if (markers.length === 0) {
            return;
          }

          var bounds = new google.maps.LatLngBounds(); // jshint ignore:line

          forEach(markers, function (marker) {
            bounds.extend(
              new google.maps.LatLng(marker.latitude, marker.longitude)
            ); // jshint ignore:line

            if (!ignorePadding) {
              var center = bounds.getCenter();
              var corner = bounds.getNorthEast();

              if (
                center.lat() - corner.lat() < 0.01 &&
                center.lng() - corner.lng() < 0.01
              ) {
                bounds.extend(
                  new google.maps.LatLng(
                    center.lat() + 0.01,
                    center.lng() + 0.01
                  )
                ); // jshint ignore:line
              }
            }
          });

          mapInstance.fitBounds(bounds);
        },
        getViewport: function (mapInstance) {
          // Retrieve current viewport
          var bounds = mapInstance.getBounds();
          var sw = bounds.getSouthWest();
          var ne = bounds.getNorthEast();

          // [[west, north], [east, south]]
          return [
            [ne.lng(), ne.lat()],
            [sw.lng(), sw.lat()]
          ];
        }
      };
    }
  ]);
})();
