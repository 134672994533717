import React, { FC } from 'react';
import { Chip, makeStyles } from '@material-ui/core';

import { Box, NamedColors } from '@knockrentals/knock-shared-web';
import { colors } from '../../commonStyles/commonStyles';

const useStyles = makeStyles({
  selectChip: {
    alignItems: 'center',
    backgroundColor: colors.chipBackground,
    border: `1px solid ${colors.chipBackground}`,
    borderRadius: '16px',
    flexDirection: 'row',
    height: '32px',
    padding: '4px',

    '& .MuiChip-label': {
      fontSize: '13px',
      paddingLeft: '6px'
    },

    '& .MuiSvgIcon-root': {
      fill: NamedColors.denim[600],

      '&:hover': {
        fill: NamedColors.denim[600]
      }
    },

    '&:hover': {
      backgroundColor: NamedColors.denim[100]
    },

    '&:focus': {
      backgroundColor: colors.chipBackground
    }
  }
});

interface ChipCloseProps {
  chipLabel: string;
  onClose: () => void;
  className?: string;
}

const ChipClose: FC<ChipCloseProps> = ({ chipLabel, onClose, className }) => {
  const classes = useStyles();

  return (
    <Box className={className ? className : undefined}>
      <Chip
        className={classes.selectChip}
        label={chipLabel}
        onDelete={onClose}
      />
    </Box>
  );
};

export default ChipClose;
