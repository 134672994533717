import React, { FC } from 'react';
import { TextField, TextFieldProps, makeStyles } from '@material-ui/core';
import { colors } from '../commonStyles/commonStyles';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
    position: 'relative'
  },
  textField: {
    height: '28px',
    border: 'none',
    '& .MuiFilledInput-root': {
      border: 'none'
    },
    '& .MuiInputBase-root': {
      border: 'none',
      paddingLeft: '0px'
    },
    top: '-40px',
    marginTop: '40px',
    '& .MuiInputBase-input': {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px'
    }
  },
  label: {
    left: '0px',
    top: '8px',
    height: '0px',
    fontWeight: 400,
    '&.MuiInputLabel-shrink:not(.Mui-focused)': {
      color: colors.secondaryText
    }
  },
  input: {
    border: 'none',
    color: colors.defaultText,
    backgroundColor: colors.inputBackground,
    paddingLeft: '0px !important',
    '& .MuiFilledInput-root': {
      border: 'none',
      backgroundColor: colors.inputBackground
    },
    '& .MuiInputBase-root': {
      border: 'none',
      paddingLeft: '0px !important',
      '&.Mui-focused': {
        border: 'none',
        backgroundColor: colors.inputBackground
      }
    },
    '& .MuiInputBase-input': {
      '&.Mui-disabled': {
        color: colors.disabled
      }
    },
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  }
});

const FormTextField: FC<TextFieldProps> = (props) => {
  const classes = useStyles();

  const className = `${classes.textField} ${
    props.className ? props.className : ''
  }`;

  return (
    <TextField
      className={className}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        classes: {
          root: classes.label
        }
      }}
      {...props}
    />
  );
};

export default FormTextField;
