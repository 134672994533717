(function (angular) {
  const app = angular.module('knock-PropertyReports');

  app.directive('propertyReportPagination', function () {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl:
        '/angular/views/propertyReports/property-report-pagination.html',
      controller: 'PropertyReportPaginationController',
      scope: {
        tableData: '=',
        totalRows: '='
      },
      link: function (scope, element, _attrs, _ctrl, transclude) {
        const tableScope = scope.$parent;

        tableScope.tablePagination = {
          getTableDataRange: scope.getTableDataRange,
          handleBatchOptionChange: scope.handleBatchOptionChange,
          isBatchSelectChecked: scope.isBatchSelectChecked,
          isBatchSelectIndeterminate: scope.isBatchSelectIndeterminate
        };

        transclude(tableScope, function (clone, scope) {
          element.append(clone);
        });
      }
    };
  });

  const PropertyReportPaginationController = function ($scope, $rootScope) {
    $scope.currentResultsPageNumber = 1;
    $scope.rowsPerPage = 500;
    $scope.isPaginationEnabled =
      $rootScope.featureFlags.PROSPECT_RESIDENT_PAGINATION;

    $scope.$on('updatePaginationParams', function (e, pageNumber) {
      $scope.currentResultsPageNumber = pageNumber;
    });

    $scope.isBatchSelectIndeterminate = () => {
      const selectedRowsCount = $scope.tableData.filter(
        (row) => row.isSelected
      ).length;
      return (
        selectedRowsCount !== 0 && selectedRowsCount !== $scope.tableData.length
      );
    };

    $scope.isBatchSelectChecked = () =>
      $scope.tableData.length === 0
        ? false
        : $scope.tableData.every((row) => row.isSelected);

    $scope.handleBatchOptionChange = (selectedBatchOption) => {
      $scope.$apply(() => {
        const isSelected = !!selectedBatchOption;
        const shouldUpdateAllRows = selectedBatchOption !== 'All on Page';
        const rows = shouldUpdateAllRows
          ? $scope.tableData
          : $scope.getTableDataRange();
        rows.forEach((row) => (row.isSelected = isSelected));
      });
    };

    $scope.onChangeResultsPageNavigation = (pageNumber) => {
      if ($scope.isPaginationEnabled) {
        const offset = (pageNumber - 1) * $scope.rowsPerPage;

        $rootScope.$broadcast(
          'getPaginationParams',
          $scope.rowsPerPage,
          offset,
          pageNumber
        );
      }

      $scope.$apply(() => {
        $scope.currentResultsPageNumber = pageNumber;
      });
    };

    $scope.onChangeRowsPerPage = (rowsPerPage, allRowsSelected) => {
      if ($scope.isPaginationEnabled) {
        $rootScope.$broadcast('getRowCount', rowsPerPage, allRowsSelected);
      }

      $scope.$apply(() => {
        $scope.rowsPerPage = rowsPerPage;
        $scope.allRowsSelected = allRowsSelected;
        $scope.currentResultsPageNumber = 1;
      });
    };

    $scope.getTablePaginationRangeBounds = () => {
      const { currentResultsPageNumber, rowsPerPage } = $scope;
      const previousPage = currentResultsPageNumber - 1;
      const currentRangeMaxRows = rowsPerPage * currentResultsPageNumber;

      return {
        rowStart: rowsPerPage * previousPage + 1,
        rowEnd: Math.min($scope.totalRows, currentRangeMaxRows)
      };
    };

    $scope.getTableDataRange = () => {
      const { rowStart, rowEnd: endIndex } =
        $scope.getTablePaginationRangeBounds();
      const startIndex = rowStart - 1;

      if ($scope.isPaginationEnabled) {
        return $scope.tableData;
      } else {
        return $scope.tableData.slice(startIndex, endIndex);
      }
    };
  };

  PropertyReportPaginationController.$inject = ['$scope', '$rootScope'];

  app.controller(
    'PropertyReportPaginationController',
    PropertyReportPaginationController
  );
})(window.angular);
