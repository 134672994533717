import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  FilterOption,
  SelectedFilterOption,
  SetDateRangeFilterAction
} from '../../common';
import {
  getFilterOptions,
  getSelectedFilters
} from '../../utils/prospectUtils';
import { DateRangeFilterOption } from '../../../LegacyAngularApp/legacy-angular-app/components/molecules/SelectDateRange/SelectDateRange';
import { SortParameters } from '../prospects/filter';

export interface ResidentFilterPayload {
  property_ids: string[];
  buildings?: string[];
  statuses?: string[];
  lease_end_after?: string;
  lease_end?: string;
  batch_id?: string;
  resident_ids?: number[];
  offset: number;
  per_page: number;
  sort_column?: string;
  sort_direction?: 'asc' | 'desc';
  cacheId?: string;
}

export interface ResidentFilter {
  properties: SelectedFilterOption;
  buildings: SelectedFilterOption;
  statuses: SelectedFilterOption;
  leaseEndAfter: string;
  leaseEnd: string;
  leaseEndRange: string;
  page: number;
  payload: ResidentFilterPayload;
}

export const statusFilters: FilterOption[] = [
  { label: 'Current', value: 'current', index: 1 },
  { label: 'Undecided', value: 'undecided', index: 2 },
  { label: 'Renewing', value: 'renewing', index: 3 },
  { label: 'On Notice', value: 'on-notice', index: 4 },
  { label: 'Transferring', value: 'transfer', index: 5 },
  { label: 'Former', value: 'former', index: 6 }
];

export const leaseEndFilters: DateRangeFilterOption[] = [
  { label: 'Next 7 days', value: '7day' },
  { label: 'Next month', value: '1month' },
  { label: 'Next 2 months', value: '2month' }
];

export const initialResidentFilterPayload = {
  property_ids: ['all'],
  buildings: undefined,
  statuses: undefined,
  lease_end_after: undefined,
  lease_end: undefined,
  resident_ids: undefined,
  offset: 0,
  per_page: 500,
  sort_column: undefined,
  sort_direction: undefined,
  cacheId: undefined
};

export const initialResidentFilter: ResidentFilter = {
  properties: {},
  buildings: {},
  statuses: {},
  leaseEndAfter: '',
  leaseEnd: '',
  leaseEndRange: '',
  page: 1,
  payload: initialResidentFilterPayload
};

const buildResidentRequestPayload = (
  filters: ResidentFilter
): ResidentFilterPayload => {
  const properties = getFilterOptions(filters.properties);
  const buildings = getFilterOptions(filters.buildings);
  const statuses = getFilterOptions(filters.statuses);
  return {
    property_ids: properties && properties.length > 0 ? properties : ['all'],
    buildings: buildings && buildings.length > 0 ? buildings : undefined,
    statuses: statuses && statuses.length > 0 ? statuses : undefined,
    lease_end_after: filters.leaseEndAfter
      ? dayjs(filters.leaseEndAfter).startOf('day').format('YYYY-MM-DD')
      : undefined,
    lease_end: filters.leaseEnd
      ? dayjs(filters.leaseEnd).endOf('day').format('YYYY-MM-DD')
      : undefined,
    offset: 0,
    per_page: filters.payload.per_page,
    sort_column: filters.payload.sort_column,
    sort_direction: filters.payload.sort_direction
  };
};

export const residentFilterSlice = createSlice({
  name: 'residentFilter',
  initialState: initialResidentFilter,
  reducers: {
    setFilters: (
      state,
      action: PayloadAction<Omit<ResidentFilter, 'payload'>>
    ) => {
      state.properties = action.payload.properties;
      state.buildings = action.payload.buildings;
      state.statuses = action.payload.statuses;
      state.leaseEndAfter = action.payload.leaseEndAfter;
      state.leaseEnd = action.payload.leaseEnd;
      state.leaseEndRange = action.payload.leaseEndRange;
      state.payload = initialResidentFilterPayload;
    },
    setProperties: (state, action: PayloadAction<FilterOption[]>) => {
      state.properties = getSelectedFilters(action.payload);
      state.page = 1;
      state.payload = buildResidentRequestPayload({ ...state });
    },
    setBuildings: (state, action: PayloadAction<FilterOption[]>) => {
      state.buildings = getSelectedFilters(action.payload);
      state.page = 1;
      state.payload = buildResidentRequestPayload({ ...state });
    },
    setStatuses: (state, action: PayloadAction<FilterOption[]>) => {
      state.statuses = getSelectedFilters(action.payload);
      state.page = 1;
      state.payload = buildResidentRequestPayload({ ...state });
    },
    setLeaseEnd: (state, action: PayloadAction<SetDateRangeFilterAction>) => {
      state.leaseEndAfter = action.payload.after;
      state.leaseEnd = action.payload.before;
      state.leaseEndRange = action.payload.range;
      state.page = 1;
      state.payload = buildResidentRequestPayload({ ...state });
    },
    setPage: (state, action: PayloadAction<number>) => {
      let newPage: number;
      let newOffset: number;

      if (action.payload + 1 > state.page) {
        newPage = state.page + 1;
        newOffset = state.payload.offset + state.payload.per_page;
      } else {
        newPage = state.page - 1;
        newOffset = state.payload.offset - state.payload.per_page;
      }

      state.page = newPage;
      state.payload = { ...state.payload, offset: newOffset };
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.page = 1;

      state.payload = {
        ...state.payload,
        offset: 0,
        per_page: action.payload,
        cacheId: new Date().getTime().toString()
      };
    },
    setSort: (state, action: PayloadAction<SortParameters>) => {
      state.payload = {
        ...state.payload,
        sort_column: action.payload.columnName,
        sort_direction: action.payload.columnOrder
      };
    }
  }
});

export const {
  setFilters,
  setProperties,
  setBuildings,
  setStatuses,
  setLeaseEnd,
  setPage,
  setRowsPerPage,
  setSort
} = residentFilterSlice.actions;

export default residentFilterSlice.reducer;
