import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SearchPathIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon fontSize="large" {...props}>
      <path
        d="M12.5009 12.5008L16.6661 16.6666M8.66639 13.9995C11.6118 13.9995 13.9995 11.6118 13.9995 8.66638C13.9995 5.72098 11.6118 3.33325 8.66639 3.33325C5.72098 3.33325 3.33325 5.72098 3.33325 8.66638C3.33325 11.6118 5.72098 13.9995 8.66639 13.9995Z"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
