(function (angular) {
  var app = angular.module('knockApp');

  const shareSMSOptInModalFactory = function ($mdDialog) {
    var self = this;

    self.openShareSMSOptIn = function (isResident, recipientId) {
      return $mdDialog.show({
        controller: 'ShareSMSOptInModalController',
        templateUrl: '/angular/views/conversations/share-sms-opt-in-modal.html',
        clickOutsideToClose: true,
        locals: {
          isResident,
          recipientId
        }
      });
    };

    return self;
  };
  shareSMSOptInModalFactory.$inject = ['$mdDialog'];
  app.factory('shareSMSOptInModalFactory', shareSMSOptInModalFactory);

  const ShareSMSOptInModalController = function (
    $scope,
    $mdDialog,
    $mdToast,
    prospectsApi,
    residentsApi,
    isResident,
    recipientId
  ) {
    $scope.data = {
      isGettingLink: false
    };

    $scope.recipientType = isResident ? 'resident' : 'prospect';

    $scope.getAndInsertLink = function () {
      $scope.data.isGettingLink = true;

      const recipientApi = isResident ? residentsApi : prospectsApi;

      recipientApi
        .getProspectSMSOptInUrl(recipientId)
        .then((response) => {
          const { consent_url } = response.data || {};
          if (!consent_url) {
            throw new Error('Missing consent url');
          }
          $mdDialog.hide(response.data.consent_url);
        })
        .catch(() => {
          $scope.data.isGettingLink = false;
          $mdToast.showSimple('Error getting SMS opt-in URL');
        });
    };
  };

  ShareSMSOptInModalController.$inject = [
    '$scope',
    '$mdDialog',
    '$mdToast',
    'prospectsApi',
    'residentsApi',
    'isResident',
    'recipientId'
  ];
  app.controller('ShareSMSOptInModalController', ShareSMSOptInModalController);
})(window.angular);
