export default function runAuthCheck(app: any) {
  app.run([
    '$auth',
    '$location',
    function ($auth: any, $location: any) {
      var lastAuthCheck = $auth.isAuthenticated();

      window.addEventListener(
        'storage',
        function (e: any) {
          var isAuthenticated = $auth.isAuthenticated();

          if (lastAuthCheck !== isAuthenticated && document.hidden) {
            $location.path('/');
            window.location.reload();
          }

          lastAuthCheck = isAuthenticated;
        },
        false
      );
    }
  ]);
}
