import React, { createContext, FC, useContext } from 'react';

type Props = { context: any };

const CompleteTourDrawerContext = createContext<any>({});

export const CompleteTourDrawerProvider: FC<Props> = ({
  context,
  children
}) => {
  return (
    <CompleteTourDrawerContext.Provider value={context}>
      {children}
    </CompleteTourDrawerContext.Provider>
  );
};

export const useCompleteDrawerContext = () =>
  useContext(CompleteTourDrawerContext);
