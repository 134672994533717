/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import remove from 'lodash/remove';
import map from 'lodash/map';
import forEach from 'lodash/forEach';

(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('messagingSms', [
    function () {
      return {
        restrict: 'E',
        templateUrl: '/angular/views/messaging/messaging-sms.html',
        controller: 'MessagingSmsController',
        scope: {
          recipients: '=',
          onSendMessage: '=',
          onCancelMessage: '=',
          smsAttachments: '=',
          messageText: '=',
          leasingTeamId: '='
        }
      };
    }
  ]);

  const MessagingSmsController = function (
    $window,
    $mdDialog,
    $rootScope,
    $scope,
    attachmentService,
    appDataService,
    userService,
    managerApi,
    propertyApi,
    quotesService
  ) {
    var self = this;

    /*
      Carrier Dependant...
      ...
      10MB is what was coded before...
      ...
      We have had tickets regarding this issue with Twilio hand-off, raised in opex
      ...
      In looking at this in May 2020, the following was discovered:
      https://www.verizonwireless.com/support/knowledge-base-14641/
      ...
      360KB for Basic 1xRTT 1x
      500KB for Basic Rev 0 EVDO 1X
      1.2MB / 3.5MB (image/video) Basic Rev A EVDO or 4G LTE  3G / 4G / 4GLTE
      1.2MB / 3.5MB (image/video) 5G
    */
    var MAX_FILE_SIZE = 1258291; // 1.2MB == Math.floor(1024 * 1024 * 1.2)

    $scope.data = {
      target: 'sms',
      uploadProgress: 0,
      maxCharacters: 320,
      unconsentedRecipients: [],
      placeHolder: 'Enter an SMS message here',
      isSending: false
    };

    $scope.isCompanyQuickRepliesEnabled =
      $rootScope.featureFlags.COMPANY_QUICK_REPLIES;

    $scope.newUnitsFeature = $rootScope.featureFlags.NEW_UNITS;
    $scope.emojiPickerContext = 'MessagingSMS';
    $scope.managerInfo = {};
    $scope.getManagerQuickReplies = () =>
      managerApi.getManagerQuickRepliesBulkMessaging($scope.leasingTeamId);

    $scope.updateManagerQuickReply = managerApi.updateManagerQuickReply;
    $scope.deleteManagerQuickReply = managerApi.deleteManagerQuickReply;
    $scope.properties = appDataService
      .getProperties()
      .sort((property1, property2) =>
        property1.Property.data.location.name.localeCompare(
          property2.Property.data.location.name
        )
      );

    self._isMissingConsent = function (recipient) {
      return (
        recipient.info.phone &&
        (!recipient.sms_consent || recipient.sms_consent.status !== 'granted')
      );
    };

    self._initialize = function () {
      var recipientsMissingConsent = [];

      forEach($scope.recipients, function (recipient) {
        if (self._isMissingConsent(recipient)) {
          recipientsMissingConsent.push(recipient);
        }
      });

      $scope.unconsentedRecipients = recipientsMissingConsent;

      if (
        $scope.smsAttachments.local &&
        $scope.smsAttachments.local.length > 0
      ) {
        self._uploadAttachments();
      }

      self._getManagerInfo();
    };
    $scope.generateQuote = function (unitListing) {
      quotesService.createQuote(
        unitListing,
        $scope.prospect,
        $scope.data.unitListings
      );
    };
    $scope.pasteLink = function (data, flag) {
      if (flag === 'listing') {
        self._appendShortUrl(data, 'listing');
      } else {
        const urlType =
          data.Property.type === 'multi-family' ? 'community' : 'listing';
        self._appendShortUrl(data.Property.data, urlType);
      }
    };
    $scope.getPropertyUnitListings = function (propertyId) {
      return propertyApi.getPropertyUnitListings(propertyId);
    };
    self._appendShortUrl = function (propertyData, propertyType) {
      if ($scope.prospect) {
        return prospectsApi
          .getProspectsUniqueShortUrl(
            $scope.prospect.id,
            propertyType,
            propertyData.id
          )
          .then(function (response) {
            $scope.$apply(() => {
              $scope.appendText(response.data.short_url);
            });
          });
      } else {
        $scope.$apply(() => {
          $scope.appendText(propertyData.social.shortlink);
        });
      }
    };

    $scope.appendText = function (text, fromQuickReply) {
      if (fromQuickReply) {
        $scope.$apply(() => {
          $scope.messageText += text;
        });
      } else {
        $scope.messageText = $scope.messageText.trim() + ' ' + text + ' ';
      }
    };

    $scope.handleSelectedAttachments = function (element) {
      var selected = { files: element };

      if (selected.files.length === 0) {
        return;
      }

      $scope.smsAttachments.local = map(selected.files, function (file) {
        file.isLocal = true;
        file.mimetype = file.type;
        file.url = $window.URL.createObjectURL(file);

        return file;
      });

      self._uploadAttachments();
    };

    self._getManagerInfo = function () {
      const teamMembers = appDataService.getTeamMembers().map((member) => {
        return {
          id: member.Manager.id,
          name:
            member.ManagerInfo.first_name + ' ' + member.ManagerInfo.last_name
        };
      });

      $scope.managerInfo = teamMembers.find(
        (member) => member.id === userService.getScopedUser().id
      );
    };

    self._uploadAttachments = function () {
      var files = $scope.smsAttachments.local;
      var tooLargeAttachments = remove(files, function (file) {
        return file.size > MAX_FILE_SIZE;
      });

      if (tooLargeAttachments.length > 0) {
        $scope.smsAttachments.local = [];
        $mdToast.showSimple(
          'One or more files are too large. Must be 10MB or less per file.'
        );
        return;
      }

      attachmentService.uploadByFile(
        files,
        self._uploadSuccess,
        self._uploadProgress,
        self._uploadError
      );
    };

    self._uploadSuccess = function (response) {
      var json = JSON.parse(response.target.responseText);

      $scope.smsAttachments.remote = $scope.smsAttachments.remote.concat(
        map(json.attachments, function (attachment) {
          attachment.type = attachmentService.getAttachmentType(
            attachment.mimetype
          );
          attachment.isRenaming = false;
          attachment.newFilename = attachment.filename;
          attachment.editedFilename = attachment.filename;
          attachment.canEditFilename = attachment.type === 'pdf';

          return attachment;
        })
      );

      $scope.smsAttachments.local = [];

      $scope.$apply();
    };

    self._uploadProgress = function (progressEvent) {
      $scope.data.uploadProgress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      $scope.$apply();
    };

    self._uploadError = function () {
      $mdToast.showSimple('There was an error uploading attachments.');
    };

    $scope.removeAttachment = function (attachmentToRemove) {
      attachmentService.deleteAttachment(attachmentToRemove.delete_token);

      remove($scope.smsAttachments.remote, function (remoteAttachment) {
        return (
          remoteAttachment.delete_token === attachmentToRemove.delete_token
        );
      });
    };

    $scope.sendSmsConsentInvitations = function () {
      $scope.onCancelMessage('sms');
      $scope.onCancelMessage('email');

      setTimeout(function () {
        angular.element('.consent-button-select').triggerHandler('click');
      }, 1500);

      setTimeout(function () {
        angular.element('.consent-button-send').triggerHandler('click');
      }, 1510);
    };

    $scope.cancelMessage = function () {
      $scope.onCancelMessage($scope.data.target);
    };

    $scope.trySendMessage = function () {
      if (
        $scope.messageText === '' &&
        $scope.smsAttachments.remote.length === 0
      ) {
        return;
      }

      $scope.data.isSending = true;
      $scope.onSendMessage($scope.data.target);
    };

    self._initialize();
  };
  MessagingSmsController.$inject = [
    '$window',
    '$mdDialog',
    '$rootScope',
    '$scope',
    'attachmentService',
    'appDataService',
    'userService',
    'managerApi',
    'propertyApi',
    'quotesService'
  ];

  app.controller('MessagingSmsController', MessagingSmsController);
})();
