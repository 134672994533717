import React, { FC } from 'react';

import HomeIcon from './HomeIcon';
import { Text } from '@knockrentals/knock-shared-web';

const Resubscribe: FC = () => {
  return (
    <div className="unsubscribe-content">
      <div className="icon-container">
        <HomeIcon />
      </div>
      <Text variant="h4" style={{ textAlign: 'center' }}>
        Welcome home!
      </Text>
      <Text
        variant="subtitle1"
        color="secondary"
        style={{ textAlign: 'center' }}
      >
        We'll keep you posted on updates and special offers.
      </Text>
    </div>
  );
};

export default Resubscribe;
