/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-MarketingTools');

  app.directive('marketingToolsManagerRelay', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/marketingTools/marketing-tools-manager-relay.html',
      controller: 'MarketingToolsManagerRelayController',
      scope: {
        manager: '=',
        relays: '='
      }
    };
  });

  app.controller('MarketingToolsManagerRelayController', [
    '$scope',
    function ($scope) {
      $scope.viewState = {
        copied: {
          ils: false,
          craigslist: false
        }
      };
    }
  ]);
})(window.angular);
