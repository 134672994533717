/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('subscriptionsApi', [
    'apiBase',
    function (apiBase) {
      return {
        getSubscriptionPlans: function () {
          return apiBase.get('/subscription/plans');
        },
        getCurrentUserSubscription: function () {
          return apiBase.get('/subscription');
        },
        changeSubscriptionPlan: function (newSubscriptionPlanId) {
          var payload = {
            newSubscriptionPlanId: newSubscriptionPlanId
          };

          return apiBase.put('/subscription', payload);
        },
        registerCreditCardToken: function (cardTokenId) {
          var payload = {
            cardTokenId: cardTokenId
          };

          return apiBase.post('/subscription/payment/card', payload);
        },
        getUserCreditCards: function () {
          return apiBase.get('/subscription/payment/card');
        },
        getUserInvoices: function () {
          return apiBase.get('/subscription/invoices');
        },
        getUpcomingInvoice: function () {
          return apiBase.get('/subscription/invoices/upcoming');
        },
        getInvoiceById: function (invoiceId) {
          return apiBase.get('/subscription/invoices/' + invoiceId);
        },
        getSubscriptionLimits: function () {
          return apiBase.get('/subscription/limits');
        }
      };
    }
  ]);
})();
