/* eslint-disable */

import * as uis from '../../../services';
import { APP_CONFIG } from 'APP_CONFIG';

(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('uisService', [
    'userService',
    function (userService) {
      var self = this;

      self.init = function ({ user, additionalData = {} }) {
        uis.init({
          userId: user.id,
          host: APP_CONFIG.UIS.host,
          token: APP_CONFIG.UIS.token,
          env: process.env.NODE_ENV,
          product: 'lma',
          user: { ...user },
          additional: additionalData && { ...additionalData }
        });
      };

      self.tagEvent = function (
        featureProduct,
        featureClicked,
        featureParent,
        featureGrandparent,
        value
      ) {
        uis.tagEvent({
          featureProduct,
          featureClicked,
          featureParent,
          featureGrandparent,
          value
        });
      };

      return self;
    }
  ]);
})();
