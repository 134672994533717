import React, { FC } from 'react';

export const LeaseDocumentIcon: FC = () => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.375 15.375C20.375 16.3639 20.0818 17.3306 19.5323 18.1529C18.9829 18.9751 18.202 19.616 17.2884 19.9944C16.3748 20.3728 15.3695 20.4719 14.3996 20.2789C13.4296 20.086 12.5387 19.6098 11.8395 18.9105C11.1402 18.2113 10.664 17.3204 10.4711 16.3505C10.2781 15.3805 10.3772 14.3752 10.7556 13.4616C11.134 12.548 11.7749 11.7671 12.5972 11.2177C13.4194 10.6682 14.3861 10.375 15.375 10.375"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.375 11.625L15.8167 16.1825C15.7587 16.2406 15.6898 16.2866 15.6141 16.318C15.5383 16.3495 15.457 16.3656 15.375 16.3656C15.293 16.3656 15.2117 16.3495 15.1359 16.318C15.0602 16.2866 14.9913 16.2406 14.9333 16.1825L13.5 14.75"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 20.375H2.8725C2.54098 20.375 2.22303 20.2433 1.98861 20.0089C1.75419 19.7745 1.6225 19.4565 1.6225 19.125V2.875C1.6225 2.54348 1.75419 2.22554 1.98861 1.99112C2.22303 1.7567 2.54098 1.625 2.8725 1.625H16C16.3315 1.625 16.6495 1.7567 16.8839 1.99112C17.1183 2.22554 17.25 2.54348 17.25 2.875V9.125"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.49249 12.0658H5.99249C5.82673 12.0658 5.66776 12 5.55055 11.8828C5.43334 11.7656 5.36749 11.6066 5.36749 11.4408V9.56583"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.11749 7.69083L7.86749 4.56583L11.6175 7.69083"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
