import React, { FC } from 'react';

export const ProspectNumberIcon: FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 8H14.5M11.5 10H15.5M7.5 9.5C8.4665 9.5 9.25 8.7165 9.25 7.75C9.25 6.7835 8.4665 6 7.5 6C6.5335 6 5.75 6.7835 5.75 7.75C5.75 8.7165 6.5335 9.5 7.5 9.5ZM4.5 13C4.5 11.3431 5.84315 10 7.5 10C9.15685 10 10.5 11.3431 10.5 13H4.5ZM16.5 4H3.5C2.94772 4 2.5 4.44772 2.5 5V15C2.5 15.5523 2.94772 16 3.5 16H5.5C5.5 15.4477 5.94772 15 6.5 15C7.05228 15 7.5 15.4477 7.5 16H12.5C12.5 15.4477 12.9477 15 13.5 15C14.0523 15 14.5 15.4477 14.5 16H16.5C17.0523 16 17.5 15.5523 17.5 15V5C17.5 4.44772 17.0523 4 16.5 4Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
