/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import each from 'lodash/each';
import find from 'lodash/find';
import every from 'lodash/every';
import size from 'lodash/size';
import some from 'lodash/some';
import sample from 'lodash/sample';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('schedulingRequirementsService', [
    '$q',
    'ProfileService',
    'userPhoneModalService',
    'signupModalService',
    'userService',
    function (
      $q,
      ProfileService,
      userPhoneModalService,
      signupModalService,
      userService
    ) {
      return {
        ensureRenterPhoneNumber: function () {
          var deferredEnsurance = $q.defer();

          ProfileService.getProfile(true).success(function (response) {
            var profile = response.profile;

            if (isEmpty(profile.phone)) {
              var phonePromise = userPhoneModalService.openModal().result;
              phonePromise.then(function () {
                deferredEnsurance.resolve();
              });
            } else {
              deferredEnsurance.resolve();
            }
          });

          return deferredEnsurance.promise;
        },
        requireLoggedIn: function () {
          if (isEmpty(userService.getUser())) {
            var signupMessage =
              'Sign up today to start booking appointments instantly!';
            signupModalService.openModal(signupMessage, 'renter');

            return false;
          }

          return true;
        }
      };
    }
  ]);

  app.directive('chooseTimesSchedule', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/scheduling/scheduling-select-times.html',
      scope: {
        availableTimesByDay: '=',
        listingId: '=',
        isRescheduling: '=',
        isAutoAccept: '=',
        allSlotsFilled: '=',
        timeSelections: '=',
        selectedSlot: '=',
        selectedDay: '=',
        hasSelected: '=',
        onAppointmentRequested: '=?',
        onAppointmentBooked: '=?',
        onFireReschedule: '=?'
      },
      controller: 'SelectTimesController'
    };
  });

  app.directive('autoAcceptSchedule', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/scheduling/scheduling-select-times.html',
      scope: {
        availableTimesByDay: '=',
        listingId: '=',
        isRescheduling: '=',
        isAutoAccept: '=',
        allSlotsFilled: '=',
        timeSelections: '=',
        selectedSlot: '=',
        selectedDay: '=',
        hasSelected: '=',
        onAppointmentRequested: '=?',
        onAppointmentBooked: '=?',
        onFireReschedule: '=?'
      },
      controller: 'SelectTimesController'
    };
  });

  app.directive('primeTimeSchedule', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/scheduling/scheduling-select-times.html',
      scope: {
        availableTimesByDay: '=',
        listingId: '=',
        isRescheduling: '=',
        isAutoAccept: '=',
        allSlotsFilled: '=',
        timeSelections: '=',
        selectedSlot: '=',
        selectedDay: '=',
        hasSelected: '=',
        onAppointmentRequested: '=?',
        onAppointmentBooked: '=?',
        onFireReschedule: '=?'
      },
      controller: 'SelectTimesController'
    };
  });

  app.controller('SelectTimesController', [
    '$scope',
    '$location',
    '$anchorScroll',
    '$q',
    'timeService',
    'appointmentsApi',
    'listingApi',
    'userService',
    'signupModalService',
    'ProfileService',
    'schedulingRequirementsService',
    'localCache',
    'appointmentRequirementsModalService',
    function (
      $scope,
      $location,
      $anchorScroll,
      $q,
      timeService,
      appointmentsApi,
      listingApi,
      userService,
      signupModalService,
      ProfileService,
      schedulingRequirementsService,
      localCache,
      appointmentRequirementsModalService
    ) {
      var self = this;

      self.timeSelectionsCacheKey =
        'request-time-selections-' + $scope.listingId;

      self.initialize = function () {
        $scope.timeSelections = self._getCachedTimeSelections();
        $scope.allSlotsFilled = $scope.calculateIfFinished();
        $scope.hasSelected = $scope.calculateIfHasSelected();

        $scope.selectedSlot = null;
        $scope.selectedDay = null;

        if (isEmpty($scope.timeSelections)) {
          if ($scope.isAutoAccept) {
            $scope.timeSelections = [{ selection: null }];

            $scope.selectedSlot = $scope.timeSelections[0];
          } else {
            $scope.timeSelections = [
              { selection: null },
              { selection: null },
              { selection: null }
            ];
          }
        } else {
          if ($scope.isAutoAccept) {
            $scope.selectedSlot = $scope.timeSelections[0];
          }
        }

        listingApi
          .getListingManagerMessagingPreferences($scope.listingId)
          .success(function (response) {
            $scope.acceptsNewMessages =
              response.preferences.accepts_non_appointment_messages;
          });

        listingApi
          .getListingManagerPreferences($scope.listingId)
          .success(function (response) {
            var tourDuration = timeService.get(
              response.preferences.tour_duration,
              'HH:mm:ss'
            );
            self.tourDurationMinutes =
              tourDuration.hours() * 60 + tourDuration.minutes();
          });
      };

      self._processTourTimes = function () {
        $scope.days = map(
          $scope.availableTimesByDay,
          function (dayAvailableTimes) {
            // Turn all of the times into moment objects
            var dayAvailableMoments = map(
              dayAvailableTimes,
              function (availableTime) {
                return timeService.get(
                  availableTime,
                  null,
                  $scope.$parent.listing.location.timezone
                );
              }
            );

            // ex: Wed 21st
            var dayLabel = sample(dayAvailableMoments).format('MMM Do');
            var dayOfWeekLabel = sample(dayAvailableMoments).format('ddd');

            return {
              dayOfWeekLabel: dayOfWeekLabel,
              label: dayLabel,
              times: dayAvailableMoments
            };
          }
        );

        var sampleTime = sample(sample($scope.availableTimesByDay));
        var listingTimezone = $scope.$parent.listing.location.timezone;
        $scope.timezoneAbbreviation = timeService.getTimezoneAbbreviation(
          sampleTime,
          listingTimezone
        );
      };

      $scope.$watch('availableTimesByDay', function () {
        if ($scope.availableTimesByDay) {
          self._processTourTimes();
        }
      });

      $scope.isTimeSelected = function (time) {
        return some($scope.timeSelections, { selection: time });
      };

      $scope.selectSlot = function (slot) {
        $scope.selectedSlot = slot;
      };

      $scope.selectDay = function (day) {
        $scope.selectedDay = day;
      };

      $scope.selectTime = function (time) {
        if ($scope.selectedSlot) {
          $scope.selectedSlot.selection = time;
          $scope.clearFinishedSelection();

          $scope.allSlotsFilled = $scope.calculateIfFinished();
          $scope.hasSelected = true;

          $location.hash('top');
          $anchorScroll();
        } else {
          throw 'Cannot select time without a slot selection!';
        }
      };

      $scope.clearFinishedSelection = function () {
        if (!$scope.isAutoAccept) {
          $scope.selectedSlot = null;
        }
        $scope.selectedDay = null;
        $scope.selectedHour = null;
        $scope.allSlotsFilled = false;

        self._clearCachedTimeSelections();
      };

      $scope.calculateIfFinished = function () {
        return (
          size($scope.timeSelections) > 0 &&
          every($scope.timeSelections, 'selection')
        );
      };

      $scope.calculateIfHasSelected = function () {
        return (
          size($scope.timeSelections) > 0 &&
          some($scope.timeSelections, 'selection')
        );
      };

      self._getRequestTimes = function () {
        return map($scope.timeSelections, function (timeSlot) {
          var start = timeSlot.selection.format();
          var end = timeService
            .get(start)
            .add(self.tourDurationMinutes, 'minutes')
            .format();

          return { start_time: start, end_time: end };
        });
      };

      self._cacheTimeSelections = function () {
        localCache.putSession(
          self.timeSelectionsCacheKey,
          $scope.timeSelections
        );
      };

      self._getCachedTimeSelections = function () {
        var cachedTimeSelections = localCache.get(self.timeSelectionsCacheKey);
        return map(cachedTimeSelections, function (selection) {
          var selectedTime = timeService.get(selection.selection);

          if (!selectedTime.isValid()) {
            selectedTime = null;
          }

          return { selection: selectedTime };
        });
      };

      self._clearCachedTimeSelections = function () {
        localCache.del(self.timeSelectionsCacheKey);
      };

      self.initialize();
    }
  ]);

  app.directive('queuedRequestDisplay', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/scheduling/queued-request-display.html',
      controller: []
    };
  });

  app.directive('bookedAppointmentDisplay', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/scheduling/scheduling-booked-success.html',
      scope: {
        appointment: '=',
        listing: '=',
        onAppointmentCanceled: '=',
        onStartReschedule: '='
      },
      controller: [
        '$scope',
        '$modal',
        '$routeParams',
        'confirmationModalFactory',
        'appointmentsApi',
        'directionsService',
        'timeService',
        'loginModalService',
        'propertyApi',
        function (
          $scope,
          $modal,
          $routeParams,
          confirmationModalFactory,
          appointmentsApi,
          directionsService,
          timeService,
          loginModalService,
          propertyApi
        ) {
          var self = this;

          var isListingShowing = function (shownUnit) {
            return shownUnit.unit_listing.listing_id === $scope.listing.id;
          };

          $scope.appointmentStart = timeService.get(
            $scope.appointment.start_time,
            null,
            $scope.listing.location.timezone
          );
          $scope.hasAppointmentAtThisListing = some(
            $scope.appointment.shown_units,
            isListingShowing
          );
          $scope.isOnlyPropertyAppointment =
            size($scope.appointment.shown_units) === 0;

          self._initialize = function () {
            propertyApi
              .getProperty($scope.listing.property_id)
              .success(function (response) {
                $scope.property = response.property;
              });

            propertyApi
              .getPropertyUnitListings($scope.listing.property_id)
              .success(function (response) {
                var propertyUnitListings = response.unit_listings;
                $scope.thisUnitListing = find(propertyUnitListings, {
                  listing_id: $scope.listing.id
                });
              });
          };

          $scope.addListingToAppointment = function (unitListing) {
            appointmentsApi
              .addListingToAppointment(
                $scope.appointment.id,
                unitListing.listing_id
              )
              .success(function () {
                unitListing.wasAddedToAppointment = true;
              });
          };

          $scope.removeListingFromAppointment = function (unitListing) {
            appointmentsApi
              .removeListingFromAppointment(
                $scope.appointment.id,
                unitListing.listing_id
              )
              .success(function () {
                unitListing.wasAddedToAppointment = false;
              });
          };

          $scope.viewDirections = function () {
            var address = $scope.listing.location.address.raw;
            directionsService.openDirectionsPage(address);
          };

          $scope.tryCancelAppointment = function () {
            var message = 'Are you sure you wish to cancel your appointment?';
            var modal = confirmationModalFactory.createModal(
              'Cancel appointment',
              message,
              self._cancelAppointment,
              { cancelText: 'No' }
            );

            $modal.open(modal);
          };

          $scope.openLoginModal = function () {
            loginModalService.openModal();
          };

          self._cancelAppointment = function () {
            appointmentsApi
              .cancelAppointment(
                $scope.appointment.id,
                $routeParams.appointmentId
              )
              .success(self._cancelAppointmentSuccess);
          };

          self._cancelAppointmentSuccess = function () {
            if ($scope.onAppointmentCanceled) {
              $scope.onAppointmentCanceled();
            }
          };
        }
      ]
    };
  });

  app.directive('visitedAppointmentDisplay', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/listing/listingDetails/visited-appointment-display.html',
      scope: {
        appointment: '=',
        listing: '='
      },
      controller: [
        '$scope',
        '$route',
        '$modal',
        'appointmentsApi',
        'prospectsApi',
        'timeService',
        function (
          $scope,
          $route,
          $modal,
          appointmentsApi,
          prospectsApi,
          timeService
        ) {
          $scope.appointmentStart = timeService.get(
            $scope.appointment.start_time,
            null,
            $scope.listing.location.timezone
          );

          prospectsApi
            .getProspect($scope.appointment.prospect_id)
            .success(function (response) {
              $scope.prospect = response.prospect;
            });

          $scope.tryResendApplication = function () {
            $scope.resentApplication = true;
            appointmentsApi.setRenterApplied($scope.appointment.id);
          };

          $scope.tryApplyNow = function () {
            var modalInstance = $modal.open({
              // (BS) TODO: There is a bug in our angular dependencies that affects
              // event handling when animations are used with modals. Disabling
              // animations as a workaround, to permanently fix this we will need
              // to update our version of angular or rewrite this modal as an
              // mdDialog.
              animation: false,
              templateUrl:
                '/angular/views/listing/listingDetails/renter-application-modal.html',
              controller: 'RenterApplicationModalController',
              size: 'sm',
              resolve: {
                appointment: function () {
                  return $scope.appointment;
                }
              }
            });

            modalInstance.result.then(function () {
              $route.reload();
            });
          };

          $scope.tryExpressDisinterest = function () {
            var modalInstance = $modal.open({
              // (BS) TODO: There is a bug in our angular dependencies that affects
              // event handling when animations are used with modals. Disabling
              // animations as a workaround, to permanently fix this we will need
              // to update our version of angular or rewrite this modal as an
              // mdDialog.
              animation: false,
              templateUrl:
                '/angular/views/listing/listingDetails/renter-not-interested-modal.html',
              controller: 'RenterNotInterestedModalController',
              size: 'sm',
              resolve: {
                appointment: function () {
                  return $scope.appointment;
                }
              }
            });

            modalInstance.result.then(function () {
              $route.reload();
            });
          };
        }
      ]
    };
  });

  app.controller('RenterNotInterestedModalController', [
    '$scope',
    '$modalInstance',
    'appointmentsApi',
    'appointment',
    function ($scope, $modalInstance, appointmentsApi, appointment) {
      $scope.setRenterNotInterested = function () {
        $scope.settingNotInterested = true;

        appointmentsApi
          .setRenterNotInterested(appointment.id)
          .success(function () {
            $scope.settingNotInterested = false;
            $modalInstance.close();
          });
      };

      $scope.dismiss = function () {
        $modalInstance.dismiss();
      };
    }
  ]);

  app.controller('RenterApplicationModalController', [
    '$scope',
    '$modalInstance',
    'appointmentsApi',
    'appointment',
    function ($scope, $modalInstance, appointmentsApi, appointment) {
      $scope.sendApplication = function () {
        $scope.sendingApplication = true;

        appointmentsApi
          .setRenterApplied(appointment.id)
          .success(function (response) {
            var application = response.application;
            if (isEmpty(application.Application)) {
              $scope.friendlyMessage =
                'Thank you for your interest. The manager will follow up with you shortly with application instructions.';
            } else if (!application.sent) {
              $scope.errorMessage =
                'There was an error sending your application.';
            } else {
              $modalInstance.close();
            }
          })
          .error(function () {
            $scope.errorMessage =
              'There was an error sending your application.';
          })
          .finally(function () {
            $scope.sendingApplication = false;
          });
      };

      $scope.dismiss = function () {
        if (isEmpty($scope.friendlyMessage)) {
          $modalInstance.dismiss();
        } else {
          $modalInstance.close();
        }
      };
    }
  ]);

  app.directive('requestedAppointmentDisplay', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/scheduling/scheduling-request-success.html',
      scope: {
        requestedTimes: '=',
        listing: '=',
        onRequestsCanceled: '=?',
        onStartReschedule: '=?'
      },
      controller: [
        '$scope',
        '$modal',
        'confirmationModalFactory',
        'appointmentsApi',
        'timeService',
        function (
          $scope,
          $modal,
          confirmationModalFactory,
          appointmentsApi,
          timeService
        ) {
          var self = this;

          each($scope.requestedTimes, function (requested) {
            requested.start_time = timeService.get(
              requested.start_time,
              null,
              $scope.listing.location.timezone
            );
          });

          $scope.tryCancelRequests = function () {
            var message =
              'Are you sure you wish to cancel your appointment request?';
            var modal = confirmationModalFactory.createModal(
              'Cancel appointment request',
              message,
              self._cancelRequests,
              { cancelText: 'No' }
            );

            $modal.open(modal);
          };

          self._cancelRequests = function () {
            appointmentsApi
              .cancelRequests($scope.requestedTimes[0].id)
              .success(self._cancelRequestsSuccess)
              .error(self._cancelRequestsError);
          };

          self._cancelRequestsSuccess = function () {
            if ($scope.onRequestsCanceled) {
              $scope.onRequestsCanceled($scope.requestedTimes);
            }
          };

          self._cancelRequestsError = function () {
            $scope.errorMessage =
              'There was an error canceling your requests! Please try again later.';
          };
        }
      ]
    };
  });

  app.directive('proposedAppointmentsDisplay', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/listing/listingDetails/proposed-appointments-display.html',
      scope: {
        proposedTimes: '=',
        listing: '=',
        onProposalConfirmed: '=?'
      },
      controller: [
        '$scope',
        '$modal',
        '$routeParams',
        'confirmationModalFactory',
        'appointmentsApi',
        'timeService',
        function (
          $scope,
          $modal,
          $routeParams,
          confirmationModalFactory,
          appointmentsApi,
          timeService
        ) {
          var self = this;

          each($scope.proposedTimes, function (proposed) {
            proposed.start_time = timeService.get(
              proposed.start_time,
              null,
              $scope.listing.location.timezone
            );
          });

          $scope.tryBookProposal = function (selectedProposal) {
            self.selectedProposal = selectedProposal;
            var title = 'Book Appointment';
            var message = 'Accept this proposed time to book your appointment?';

            var modal = confirmationModalFactory.createModal(
              title,
              message,
              self._acceptProposal,
              { cancelText: 'No' }
            );

            $modal.open(modal);
          };

          self._acceptProposal = function () {
            appointmentsApi
              .confirmAppointmentProposal(
                self.selectedProposal.appointment_request_time_id,
                $routeParams.appointmentId
              )
              .success(self._acceptProposalSuccess)
              .error(self._acceptProposalError);
          };

          self._acceptProposalSuccess = function (response) {
            if (
              $scope.onProposalConfirmed &&
              response.appointment.status === 'confirmed'
            ) {
              $scope.onProposalConfirmed([response.appointment]);
            }
          };

          self._acceptProposalError = function () {
            $scope.errorMessage =
              'There was an error canceling your requests! Please try again later.';
          };
        }
      ]
    };
  });

  app.directive('schedulingErrors', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/listing/listingDetails/scheduling-errors.html',
      scope: {
        errors: '=',
        listingId: '=',
        requirements: '='
      },
      controller: [
        '$scope',
        'appointmentRequirementsModalService',
        function ($scope, appointmentRequirementsModalService) {
          $scope.openRequirementsModal = function () {
            appointmentRequirementsModalService
              .openModal($scope.listingId, $scope.requirements)
              .result.then(function () {
                $scope.errors = null;
              });
          };
        }
      ]
    };
  });

  app.factory('appointmentBookedModalService', [
    '$modal',
    function ($modal) {
      var self = this;

      self.openModal = function (appointment, listing) {
        $modal.open({
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl:
            '/angular/views/listing/listingDetails/appointment-booked-modal.html',
          controller: 'AppointmentBookedModalController',
          size: 'lg',
          resolve: {
            appointment: function () {
              return appointment;
            },
            listing: function () {
              return listing;
            }
          }
        });
      };

      return self;
    }
  ]);

  app.factory('appointmentRequestQueuedModalService', [
    '$modal',
    function ($modal) {
      var self = this;

      self.openModal = function (queuedRequest, isExistingQueuedRequest) {
        $modal.open({
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl:
            '/angular/views/listing/listingDetails/appointment-request-queued-modal.html',
          size: 'lg',
          resolve: {
            queuedRequest: function () {
              return queuedRequest;
            },
            isExistingQueuedRequest: function () {
              return isExistingQueuedRequest;
            }
          },
          controller: [
            '$scope',
            '$modalInstance',
            'appointmentsApi',
            'queuedRequest',
            'isExistingQueuedRequest',
            function (
              $scope,
              $modalInstance,
              appointmentsApi,
              queuedRequest,
              isExistingQueuedRequest
            ) {
              $scope.isExistingQueuedRequest = isExistingQueuedRequest;

              $scope.resendVerificationEmail = function () {
                appointmentsApi.resendQueuedRequestEmail(queuedRequest.id);
                $scope.dismiss();
              };

              $scope.dismiss = function () {
                $modalInstance.dismiss();
              };
            }
          ]
        });
      };

      return self;
    }
  ]);

  app.controller('AppointmentBookedModalController', [
    '$scope',
    '$modalInstance',
    '$moment',
    'appointment',
    'listing',
    'userCalendarApi',
    'listingApi',
    'conversationsService',
    function (
      $scope,
      $modalInstance,
      $moment,
      appointment,
      listing,
      userCalendarApi,
      listingApi,
      conversationsService
    ) {
      $scope.appointment = appointment;
      $scope.listing = listing;

      var listingTimezone = $scope.listing.location.timezone;
      $scope.formattedStartTime = $moment($scope.appointment.start_time)
        .tz(listingTimezone)
        .format('h:mm a z - ddd, MMM Do');

      $scope.dismiss = function () {
        $modalInstance.dismiss();
      };

      $scope.openChatThread = function () {
        conversationsService.openThreadById($scope.appointment.stream_id);
        $scope.dismiss();
      };

      $scope.isLoadingUrl = true;
      $scope.isLoadingManagerInfo = true;

      userCalendarApi
        .getCalendarUrl()
        .success(function (response) {
          $scope.icsUrl = response.url;
        })
        .error(function () {
          $scope.loadError =
            'There was an error loading your calendar URL. Please try again later.';
        })
        .finally(function () {
          $scope.isLoadingUrl = false;
        });

      userCalendarApi
        .getGoogleCalendarAppointmentLink($scope.appointment.id)
        .success(function (response) {
          $scope.googleLink = response.link;
        });

      listingApi
        .getListingManager($scope.listing.id)
        .success(function (response) {
          $scope.managerInfo = response.manager_info;
        })
        .finally(function () {
          $scope.isLoadingManagerInfo = false;
        });
    }
  ]);
})(window.angular);
