/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
/*Prospects*/
require('../../core/scripts/prospects/alternativeContactModal.controller');
require('../../core/scripts/prospects/prospectCard.directive');
require('../../core/scripts/prospects/prospectContactForm.directive');
require('../../core/scripts/prospects/viewThreadModal.controller');
require('../../core/scripts/prospects/prospectAppointmentModal.controller');
require('../../core/scripts/prospects/prospectResponseTime.filter');
require('../../core/scripts/prospects/prospectStatus.filter');
require('../../core/scripts/prospects/propertySelect.directive');
require('../../core/scripts/prospects/prospectSummary.controller');
require('../../core/scripts/prospects/userList.filter');
require('../../core/scripts/prospects/prospectDetails.directive');
require('../../core/scripts/prospects/prospectDetailsCustomFields.directive');
require('../../core/scripts/prospects/stateSelector.directive');
require('../../core/scripts/prospects/prospectUnitPreference.directive');
require('../../core/scripts/prospects/prospectLeasedDateModal.directive');
require('../../core/scripts/prospects/newProspect.service');
require('../../core/scripts/prospects/shownUnitsModal.service');
require('../../core/scripts/prospects/visits.service');
require('../../core/scripts/prospects/completeAppointmentModal.controller');
require('../../core/scripts/prospects/prospectExportStatus.directive');
require('../../core/scripts/prospects/prospectExportIndicator.directive');
require('../../core/scripts/prospects/syncProspectButton.directive');
require('../../core/scripts/prospects/prospectExcludedButton.directive');
require('../../core/scripts/prospects/prospectSMSOptInStatus.directive');
require('../../core/scripts/prospects/prospectTransferModal.controller');
require('../../core/scripts/prospects/prospectPrint.controller');
require('../../core/scripts/prospects/prospectsMissingFieldsForm.directive');
require('../../core/scripts/prospects/prospectIdVerified.directive.js');
require('../../core/scripts/prospects/prospectPrintableCommunication.controller');
require('../../core/scripts/prospects/prospectPrintableEvents.controller');
require('../../core/scripts/prospects/prospectPrintableNotes.controller');
require('../../core/scripts/prospects/prospectPrintableDetails.controller');
require('../../core/scripts/prospects/prospectPage.controller');

/*Prospect events*/
require('../../core/scripts/prospectEvents/prospectEvent.directive');
require('../../core/scripts/prospectEvents/prospectEventApplication.directive');
require('../../core/scripts/prospectEvents/prospectEventAppointment.directive');
require('../../core/scripts/prospectEvents/prospectEventAppointmentRequest.directive');
require('../../core/scripts/prospectEvents/prospectEventLease.directive');
require('../../core/scripts/prospectEvents/prospectEventQuote.directive');
require('../../core/scripts/prospectEvents/prospectEventReminder.directive');
require('../../core/scripts/prospectEvents/prospectEventVisit.directive');
require('../../core/scripts/prospectEvents/prospectEventCreationSource.directive');

/*Prospects view*/
require('../../core/scripts/prospectHistory/prospectHistory.module');
require('../../core/scripts/prospectHistory/prospectHistory.service');
require('../../core/scripts/prospectHistory/addResidentModal.controller');
require('../../core/scripts/prospectHistory/editProspectsModal.controller');
require('../../core/scripts/prospectHistory/listingResidentsModal.controller');
