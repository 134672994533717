import React, { FC } from 'react';
import { Chip, Theme } from '@material-ui/core';

import {
  Box,
  Card,
  CardContent,
  makeStyles,
  NamedColors,
  Text
} from '@knockrentals/knock-shared-web';
import { AmenitiesSection } from '../models';

const useStyles = makeStyles((theme: Theme) => ({
  sectionHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '6px'
  },

  chip: {
    backgroundColor: NamedColors.slate[50],
    fontSize: '13px',
    height: 'auto',
    marginRight: '8px',
    marginTop: '8px',
    textTransform: 'capitalize',

    '& .MuiChip-label': {
      padding: '6px 10px 6px 10px',
      whiteSpace: 'pre-wrap'
    },

    '&:last-child': {
      marginRight: 0
    }
  },

  notes: {
    marginTop: '14px',
    paddingLeft: '12px',
    paddingRight: '12px'
  },

  additionalInfo: {
    color: theme.palette.text.secondary,
    marginBottom: 0,
    marginTop: '10px',
    paddingLeft: '12px',
    paddingRight: '12px'
  }
}));

interface AmenitiesCardProps {
  section: AmenitiesSection;
  className?: string;
}

export const AmenitiesCard: FC<AmenitiesCardProps> = ({
  section,
  className
}) => {
  const classes = useStyles();

  return (
    <Card
      key={section.title}
      className={className ? className : undefined}
      elevation={0}
    >
      <CardContent>
        <Box className={classes.sectionHeader}>
          <Text variant="body2">{section.title}</Text>
          {section.icon}
        </Box>

        {section.items.map((item: string, index: number) => (
          <Chip key={index} label={item} className={classes.chip} />
        ))}

        {section.notes && (
          <Text variant="body2" className={classes.notes}>
            {section.notes}
          </Text>
        )}

        {section.additionalInfo && (
          <Text
            variant="caption"
            variantMapping={{ caption: 'div' }}
            className={classes.additionalInfo}
          >
            {section.additionalInfo}
          </Text>
        )}
      </CardContent>
    </Card>
  );
};
