/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  angular.module('knock-Conversations', [
    'angular-momentjs',
    'pusher-angular',
    'satellizer'
  ]);
})(window.angular);
