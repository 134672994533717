/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular, $) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('conversationMessageText', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/conversations/conversation-message-text.html',
      scope: {
        message: '=',
        isEmail: '=',
        onSetReplyingTo: '='
      },
      controller: 'ConversationMessageTextController'
    };
  });

  app.controller('ConversationMessageTextController', [
    '$scope',
    function ($scope) {}
  ]);
})(window.angular, window.jQuery);
