import React from 'react';

export const PinIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9C9.38071 9 10.5 7.88071 10.5 6.5C10.5 5.11929 9.38071 4 8 4C6.61929 4 5.5 5.11929 5.5 6.5C5.5 7.88071 6.61929 9 8 9Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.5C10.7614 1.5 13 3.73858 13 6.5C13 8.77133 9.61733 14.4667 8.41667 16.2767C8.32403 16.4163 8.16759 16.5003 8 16.5003C7.83241 16.5003 7.67597 16.4163 7.58333 16.2767C6.38267 14.4667 3 8.77133 3 6.5C3 3.73858 5.23858 1.5 8 1.5Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
