import React from 'react';

export const FitnessIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 11.5H15.75"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.75 17.5C6.35218 17.5 5.97064 17.342 5.68934 17.0607C5.40804 16.7794 5.25 16.3978 5.25 16V7C5.25 6.60218 5.40804 6.22064 5.68934 5.93934C5.97064 5.65804 6.35218 5.5 6.75 5.5C7.14782 5.5 7.52936 5.65804 7.81066 5.93934C8.09196 6.22064 8.25 6.60218 8.25 7V16C8.25 16.3978 8.09196 16.7794 7.81066 17.0607C7.52936 17.342 7.14782 17.5 6.75 17.5Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.25 11.5H0.75"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 8.5C3.35218 8.5 2.97064 8.65804 2.68934 8.93934C2.40804 9.22064 2.25 9.60218 2.25 10V13C2.25 13.3978 2.40804 13.7794 2.68934 14.0607C2.97064 14.342 3.35218 14.5 3.75 14.5C4.14782 14.5 4.52936 14.342 4.81066 14.0607C5.09196 13.7794 5.25 13.3978 5.25 13V10C5.25 9.60218 5.09196 9.22064 4.81066 8.93934C4.52936 8.65804 4.14782 8.5 3.75 8.5Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.25 17.5C17.6478 17.5 18.0294 17.342 18.3107 17.0607C18.592 16.7794 18.75 16.3978 18.75 16V7C18.75 6.60218 18.592 6.22064 18.3107 5.93934C18.0294 5.65804 17.6478 5.5 17.25 5.5C16.8522 5.5 16.4706 5.65804 16.1893 5.93934C15.908 6.22064 15.75 6.60218 15.75 7V16C15.75 16.3978 15.908 16.7794 16.1893 17.0607C16.4706 17.342 16.8522 17.5 17.25 17.5Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.75 11.5H23.25"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.25 8.5C20.6478 8.5 21.0294 8.65804 21.3107 8.93934C21.592 9.22064 21.75 9.60218 21.75 10V13C21.75 13.3978 21.592 13.7794 21.3107 14.0607C21.0294 14.342 20.6478 14.5 20.25 14.5C19.8522 14.5 19.4706 14.342 19.1893 14.0607C18.908 13.7794 18.75 13.3978 18.75 13V10C18.75 9.60218 18.908 9.22064 19.1893 8.93934C19.4706 8.65804 19.8522 8.5 20.25 8.5Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
