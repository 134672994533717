/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';
  var app = angular.module('knockApp');

  app.directive('schedulingContainer', [
    function () {
      return {
        restrict: 'E',
        templateUrl:
          '/angular/views/listing/listingDetails/scheduling-container.html',
        controller: [
          '$rootScope',
          '$scope',
          'renterApi',
          'userService',
          'apiEvents',
          function ($rootScope, $scope, renterApi, userService, apiEvents) {
            var isInactive = function () {
              var user = userService.getUser();
              return (
                !isEmpty(user) && user.type === 'renter' && !user.is_active
              );
            };

            $scope.shouldPromptRenterActivation = isInactive();

            var profileUpdatedHandler = $rootScope.$on(
              apiEvents.profileUpdated,
              function () {
                $scope.shouldPromptRenterActivation = isInactive();
              }
            );

            var accountActivatedHandler = $rootScope.$on(
              apiEvents.accountActivated,
              function () {
                $scope.shouldPromptRenterActivation = false;
              }
            );

            $scope.$on('$destroy', function () {
              profileUpdatedHandler();
              accountActivatedHandler();
            });

            $scope.hideActivationBox = function () {
              $scope.scheduling.showActivationBox = false;
            };
          }
        ]
      };
    }
  ]);

  app.directive('imageCarousel', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/listing/listingDetails/listing-image-carousel.html',
      scope: {
        images: '=',
        openLargePhotoModal: '='
      },
      controller: 'PhotoCarouselController'
    };
  });

  app.directive('largeImageCarousel', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/listingDetails/large-image-carousel.html',
      scope: {
        images: '=',
        isPicker: '=?',
        onImagePicked: '=?'
      },
      controller: 'PhotoCarouselController'
    };
  });
})(window.angular);
