import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import map from 'lodash/map';

(function (angular) {
  var app = angular.module('knockApp');

  const UnitsPageController = function (
    $rootScope,
    $scope,
    timeService,
    $moment,
    appDataService,
    unitApi,
    $mdToast,
    pricingApi,
    rentMatrixService
  ) {
    var self = this;

    var key = {
      enter: 13,
      esc: 27
    };

    const batchVisibilitySuccessNowHiddenMessage = 'All units are now hidden';
    const batchVisibilitySuccessNowVisibleMessage =
      'All units are no longer hidden';

    /**
     * Filters the rent matrix object if it has no keys or if the dates are before the available on date.
     */
    function _getAvailableRentMatrix(originalRentMatrix, availableOn) {
      var rentMatrixKeys =
        originalRentMatrix != null ? Object.keys(originalRentMatrix) : [];
      var rentMatrix = originalRentMatrix != null ? {} : null;

      for (var i = 0; i < rentMatrixKeys.length; i++) {
        var rentMatrixKey = rentMatrixKeys[i];
        var rentMatrixTime = new Date(rentMatrixKey).getTime();
        var availableOnTime =
          availableOn != null ? new Date(availableOn).getTime() : null;

        if (availableOnTime == null || rentMatrixTime >= availableOnTime) {
          rentMatrix[rentMatrixKey] = originalRentMatrix[rentMatrixKey];
        }
      }

      var hasItems = rentMatrix != null && Object.keys(rentMatrix).length;
      return hasItems ? rentMatrix : null;
    }

    $scope.unitBeingEdited = null;

    $scope.onSetUnitPrice = function () {
      this.unit.inputPrice = this.unit.inputPrice.replace(/([^0-9]*)/g, '');
    };

    $scope.onEnterUnitPrice = function ($event, unit) {
      if ($event.which === key.enter) {
        $scope.onSaveUnitPrice(unit);
      }
    };

    $scope.onEscUnitPrice = function ($event) {
      if ($event.which === key.esc) {
        $scope.onCancelEditingUnitPrice();
      }
    };

    $scope.onSaveUnitPrice = function (unit) {
      unit.savingPriceIsLoading = true;
      var newPrice = parseFloat(unit.inputPrice);

      function onSaveUnitPriceSuccess() {
        unit.savingPriceIsLoading = false;
        unit.savingPriceHasFailed = false;
        unit.knockPrice = unit.inputPrice;
        unit.displayPrice = unit.inputPrice;
        $scope.onCancelEditingUnitPrice();
        $mdToast.show(
          $mdToast.simple(`You have successfully saved unit ${unit.name}.`)
        );
      }

      function onSaveUnitPriceFailure() {
        unit.savingPriceIsLoading = false;
        unit.savingPriceHasFailed = true;
        $mdToast.show(
          $mdToast.simple(`We were unable to save unit ${unit.name}.`)
        );
      }

      function onInvalidInputFailure() {
        unit.savingPriceIsLoading = false;
        unit.savingPriceHasFailed = true;
        $mdToast.show(
          $mdToast.simple(
            'Please enter a valid number for ' + unit.name + "'s price. "
          )
        );
      }

      if (isNaN(newPrice)) {
        return onInvalidInputFailure();
      }

      return unitApi
        .setAttributes(unit.integrationId, unit.id, {
          knockPrice: newPrice
        })
        .then(onSaveUnitPriceSuccess, onSaveUnitPriceFailure)
        .catch((e) => {
          console.error(`Error saving unit id ${unit.id}: ${e}`);
          $mdToast.show(
            $mdToast.simple(`We were unable to save unit ${unit.name}.`)
          );
        });
    };

    $scope.onCancelEditingUnitPrice = function () {
      if ($scope.unitBeingEdited != null) {
        $scope.unitBeingEdited.savingPriceHasFailed = false;
        $scope.unitBeingEdited.isEditing = false;
      }
    };

    $scope.onEditUnitPrice = function (unitToEdit) {
      if ($scope.unitBeingEdited != null) {
        $scope.unitBeingEdited.isEditing = false;
      }
      $scope.unitBeingEdited = unitToEdit;
      unitToEdit.isEditing = true;
    };

    $scope.onSaveUnitVisibility = function (unit) {
      if (unit.savingVisibilityIsLoading) return;

      unit.savingVisibilityIsLoading = true;
      const newVisibility = unit.hidden;

      function onSaveUnitVisibilitySuccess() {
        $scope.batchUnitVisibilitySettings.hidden =
          $scope.getVisibilityIndeterminateState();

        unit.savingVisibilityIsLoading = false;
        unit.savingVisibilityHasFailed = false;
        unit.hidden = newVisibility;
        const message = unit.hidden
          ? `Unit ${unit.name} is now hidden`
          : `Unit ${unit.name} is no longer hidden`;
        $mdToast.show($mdToast.simple(message));
      }

      function onSaveUnitVisibilityFailure() {
        unit.savingVisibilityIsLoading = false;
        unit.savingVisibilityHasFailed = true;
        $mdToast.show(
          $mdToast.simple('We were unable to save ' + unit.name + '.')
        );
      }

      return unitApi
        .setAttributes(unit.integrationId, unit.id, {
          hidden: newVisibility
        })
        .then(onSaveUnitVisibilitySuccess, onSaveUnitVisibilityFailure);
    };

    $scope.batchUnitVisibilitySettings = {
      savingIsLoading: false,
      savingHasFailed: false,
      hidden: false
    };

    $scope.onBatchSaveUnitVisibility = function ($event) {
      $event.stopPropagation();
      if ($scope.batchUnitVisibilitySettings.savingIsLoading) return;

      $scope.batchUnitVisibilitySettings.savingIsLoading = true;
      const newVisibility = !$scope.batchUnitVisibilitySettings.hidden;

      function onSaveUnitVisibilitySuccess(response) {
        if (response.status !== 204) {
          // catch any error responses
          return onSaveUnitVisibilityFailure();
        }

        $scope.data.table = $scope.data.table.map((unit) => {
          unit.hidden = newVisibility;
          return unit;
        });

        $scope.batchUnitVisibilitySettings.savingIsLoading = false;
        $scope.batchUnitVisibilitySettings.savingHasFailed = false;
        $scope.batchUnitVisibilitySettings.hidden = newVisibility;

        const message = newVisibility
          ? batchVisibilitySuccessNowHiddenMessage
          : batchVisibilitySuccessNowVisibleMessage;

        $mdToast.show($mdToast.simple(message));
      }

      function onSaveUnitVisibilityFailure() {
        $scope.batchUnitVisibilitySettings.savingIsLoading = false;
        $scope.batchUnitVisibilitySettings.savingHasFailed = true;
        $mdToast.show(
          $mdToast.simple('We were unable to save the visibility setting.')
        );
      }
      const unitAttributes = $scope.data.table.map((unit) => {
        return {
          id: unit.id,
          hidden: newVisibility
        };
      });

      const currentPropertyId = $scope.filters.selections.propertyId;

      return unitApi
        .batchSetAttributes(currentPropertyId, unitAttributes)
        .then(onSaveUnitVisibilitySuccess, onSaveUnitVisibilityFailure);
    };

    $scope.getVisibilityIndeterminateState = function () {
      // checkbox for batch visibility setting is considered checked if all units are hidden
      return $scope.data.table.every((unit) => unit.hidden === true);
    };

    $scope.hasValidRentMatrix = function (unit) {
      const now = $moment();
      if (unit && unit.rentMatrix) {
        for (const key of Object.keys(unit.rentMatrix)) {
          if (now.isSameOrBefore(key, 'day')) {
            return true;
          }
        }
      }
      return false;
    };

    $scope.onOpenRentMatrixModal = function (unit) {
      rentMatrixService.openMatrixModal(unit);
    };

    $scope.onRefresh = function (propertyId) {
      function onRefreshSuccess() {
        localStorage.setItem('lastRefreshed', Date.now());
        $mdToast.show(
          $mdToast.simple().content(self.successRefreshMessage).hideDelay(5000)
        ); // 5 seconds since message long
      }

      function onRefreshFailure() {
        $mdToast.show($mdToast.simple(self.failedRefreshMessage));
      }

      return unitApi
        .refresh(propertyId)
        .then(onRefreshSuccess, onRefreshFailure);
    };

    self._initialize = function () {
      $scope.filters.selections.propertyId =
        $scope.filters.properties[0].Property.id;

      $scope.filtersChanged();
    };

    $scope.filters = {
      properties: appDataService.getProperties(),
      beds: [
        { label: 'All', value: null },
        { label: 'Studio', value: [0] },
        { label: '1bd', value: [1] },
        { label: '2bd', value: [2] },
        { label: '3bd+', value: [3] }
      ],
      baths: [
        { label: 'All', value: null },
        { label: '1ba', value: [1] },
        { label: '2ba', value: [2] },
        { label: '3ba+', value: [3] }
      ],
      layouts: [],
      status: [
        { label: 'All', value: null },
        { label: 'Available', value: 'available' },
        { label: 'Leased', value: 'leased' },
        { label: 'Reserved', value: 'reserved' },
        { label: 'Other', value: 'other' }
      ],
      buildings: [],
      visibility: [
        { label: 'All', value: null },
        { label: 'Hidden', value: 'Hidden' },
        { label: 'Public', value: 'Public' }
      ],
      occupancy: [
        { label: 'All', value: null },
        { label: 'Occupied', value: 'occupied' },
        { label: 'Vacant', value: 'vacant' }
      ],
      notice: [
        { label: 'All', value: null },
        { label: 'On Notice', value: 'on_notice' },
        { label: 'Not On Notice', value: 'no_notice' }
      ],
      type: [
        { label: 'All', value: null },
        { label: 'Model', value: 'model' },
        { label: 'Residential', value: 'residential' },
        { label: 'Commercial', value: 'commercial' },
        { label: 'Down', value: 'down' },
        { label: 'Employee', value: 'employee' },
        { label: 'Construction', value: 'construction' },
        { label: 'Office', value: 'office' },
        { label: 'Other', value: 'other' }
      ],
      selections: {
        propertyId: null,
        beds: null,
        baths: null,
        layoutId: null,
        status: null,
        visibility: null,
        occupancy: null,
        buildingId: null,
        notice: null,
        type: null
      }
    };

    $scope.data = {
      units: [],
      unitsCopy: [],
      buildingsById: {},
      layoutsById: {},
      isLoading: true,
      newUnitsFeature: $rootScope.featureFlags.NEW_UNITS || false,
      communityLastUpdated: null
    };

    $scope.allowPriceOverride = false;

    $scope.canRefresh = function () {
      if (localStorage.getItem('lastRefreshed')) {
        var timeSinceLastRefresh =
          Date.now() - localStorage.getItem('lastRefreshed');
        return timeSinceLastRefresh > self.refreshTimeout;
      }
      return true;
    };

    self.successRefreshMessage =
      'Refreshing your units from PMS! This can take up to 5 minutes, ' +
      'you can navigate away from this page. After 5 minutes, refresh your ' +
      'browser on the units page for the latest.';

    self.failedRefreshMessage =
      'Failed to refresh units. Please try again later.';

    self.refreshTimeout = 300000; // 5 minute upper bound timeout to allow for units sync to finish

    $scope.filtersChanged = function () {
      self._getPropertyPricing();
      self._getUnits();
    };

    $scope.displayableValue = function (value, fallback) {
      return value != null ? value : fallback;
    };

    $scope.displayableLayoutName = function (unit) {
      let layoutName = '-';
      if (unit && unit.layoutId) {
        layoutName = get($scope, `data.layoutsById.${unit.layoutId}.name`, '-');
      }
      return layoutName;
    };

    $scope.displayableBuildingName = function (unit) {
      let buildingName = '-';
      if (unit && unit.buildingId) {
        buildingName = get(
          $scope,
          `data.buildingsById.${unit.buildingId}.name`,
          '-'
        );
      }
      return buildingName;
    };

    $scope.displayableOccupancy = function (unit) {
      if (!unit) {
        return '-';
      }
      return unit.occupied ? 'Occupied' : 'Vacant';
    };

    $scope.displayablePrice = function (unit) {
      if (!unit) {
        return null;
      }
      return unit.displayPrice && !isNaN(unit.displayPrice)
        ? Number(unit.displayPrice)
        : null;
    };

    $scope.displayableStatus = (unit) => {
      if (unit.leased) {
        return 'Leased';
      }
      if (unit.available) {
        return 'Available';
      }
      if (unit.reserved) {
        return 'Reserved';
      }
      return 'Other';
    };

    $scope.displayablePropertyName = (property) => {
      return get(property, 'Property.data.location.name', '-');
    };

    // Enable custom sort for st-table
    $scope.getters = {
      buildingName: function (unit) {
        return $scope.displayableBuildingName(unit);
      },
      layoutName: function (unit) {
        return $scope.displayableLayoutName(unit);
      },
      occupied: function (unit) {
        return $scope.displayableOccupancy(unit);
      },
      status: function (unit) {
        // Enable custom sort for status column, which relies on 3+ properties
        return $scope.displayableStatus(unit);
      },
      type: function (unit) {
        // If the unit.type is null, or has been changed to '-',
        // put it at the bottom of the list on initial alphabetical sort
        // Note- this doesn't change the value of unit.type, just sort order
        if (unit.type === '-' || unit.type === null) {
          return 'zzz';
        }
        return unit.type;
      },
      price: function (unit) {
        return $scope.displayablePrice(unit);
      }
    };

    self._getUnits = function () {
      $scope.data.isLoading = true;

      function onFetchUnitsSuccess(response) {
        $scope.data.layoutsById = keyBy(response.data.layouts, 'id');
        $scope.data.buildingsById = keyBy(response.data.buildings, 'id');

        $scope.data.table = map(response.data.units, function (unit) {
          let unitModifiedDate = unit.modifiedAt || unit.createdAt;
          unitModifiedDate = unitModifiedDate
            ? $moment
                .utc(unit.modifiedAt)
                .tz($moment.defaultZone.name)
                .format('MMM D, YYYY hh:mma z')
            : null;
          if (unitModifiedDate === 'Invalid Date') {
            unitModifiedDate = null;
          }

          return {
            id: unit.id,
            name: unit.name,
            bedrooms: unit.bedrooms,
            bathrooms: unit.bathrooms,
            area: unit.area,
            layoutId: unit.layoutId,
            buildingId: unit.buildingId,
            leased: unit.leased,
            available: unit.available,
            reserved: unit.reserved,
            knockPrice: unit.knockPrice,
            displayPrice: unit.displayPrice,
            availableOn: unit.availableOn,
            hidden: unit.hidden,
            occupied: unit.occupied,
            notice: unit.noticeGiven,
            type: unit.type,
            integrationId: unit.integrationId,
            rentMatrix: _getAvailableRentMatrix(
              unit.rentMatrix,
              unit.availableOn
            ),
            isEditingPrice: false,
            savingPriceIsLoading: false,
            savingPriceHasFailed: false,
            inputPrice:
              unit.displayPrice && !isNaN(unit.displayPrice)
                ? Number(unit.displayPrice)
                : null,
            isEditingVisibility: false,
            savingVisibilityIsLoading: false,
            savingVisibilityHasFailed: false,
            modifiedAt: unitModifiedDate
          };
        });

        if (!$scope.data.communityLastUpdated && response.data.units) {
          const unitsDates = response.data.units
            .filter((unit) => unit.modifiedAt)
            .map((unit) => $moment.utc(unit.modifiedAt));
          const maxDate = $moment.max(...unitsDates);
          if (maxDate > 0) {
            $scope.data.communityLastUpdated = $moment(maxDate)
              .tz($moment.defaultZone.name)
              .format('MMM D, YYYY h:mma z');
          }
        }

        $scope.data.safeTable = $scope.data.table.slice();

        $scope.filters.buildings = map(
          response.data.buildings,
          function (building) {
            return {
              label: building.name,
              value: building.id
            };
          }
        );

        $scope.filters.layouts = map(response.data.layouts, function (layout) {
          return {
            label: layout.name,
            value: layout.id
          };
        });

        $scope.batchUnitVisibilitySettings.hidden =
          $scope.getVisibilityIndeterminateState();
        $scope.data.isLoading = false;
      }

      function onFetchUnitsFailure() {
        $scope.data.table = [];
        $scope.data.safeTable = [];
        $scope.data.isLoading = false;
      }

      const params = {
        propertyId: $scope.filters.selections.propertyId,
        beds: $scope.filters.selections.beds,
        baths: $scope.filters.selections.baths,
        status: $scope.filters.selections.status,
        occupancy: $scope.filters.selections.occupancy,
        buildingId: $scope.filters.selections.buildingId,
        layoutId: $scope.filters.selections.layoutId,
        notice: $scope.filters.selections.notice,
        type: $scope.filters.selections.type,
        visibility: $scope.filters.selections.visibility
      };
      return unitApi
        .getUnits(params)
        .then(onFetchUnitsSuccess, onFetchUnitsFailure);
    };

    self._getPropertyPricing = function () {
      var propertyId = $scope.filters.selections.propertyId;
      if (!propertyId) {
        $scope.allowPriceOverride = false;
        console.warn('Cannot fetch pricing data without valid propertyId.');
        return;
      }

      pricingApi
        .getPropertyPricing(propertyId)
        .then((response) => {
          let canOverride = false;
          const { pricing_data: pricingData } = response.data;
          if (!pricingData || !pricingData.length) {
            $scope.allowPriceOverride = canOverride;
            return;
          }

          var property = pricingData[0];
          if (!property) {
            $scope.allowPriceOverride = canOverride;
            return;
          }

          for (let i = 0; i < property.integrations.length; i++) {
            const integration = property.integrations[i];
            const useManualPricing = integration.preferences.use_manual_pricing;

            if (!!useManualPricing) {
              canOverride = !!useManualPricing;
              break;
            }
          }
          $scope.allowPriceOverride = canOverride;
        })
        .catch((e) => {
          $scope.allowPriceOverride = false;
          console.error(
            `Error fetching units page pricing data for property ${propertyId}: ${e}`
          );
        });
    };

    self._initialize();
  };

  UnitsPageController.$inject = [
    '$rootScope',
    '$scope',
    'timeService',
    '$moment',
    'appDataService',
    'unitApi',
    '$mdToast',
    'pricingApi',
    'rentMatrixService'
  ];

  app.controller('UnitsPageController', UnitsPageController);
})(window.angular);
