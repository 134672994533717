import React, { FC, useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';

import { makeStyles } from '@knockrentals/knock-shared-web';
import ProspectFilters from './ProspectFilters';

const useStyles = makeStyles(() => ({
  filters: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '450px'
  }
}));

export const DRAWER_LABEL = 'prospect filters drawer';

export interface ProspectFiltersDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const ProspectFiltersDrawer: FC<ProspectFiltersDrawerProps> = ({
  open,
  onClose
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleApplyFilters = () => {
    onClose();
  };

  const handleCloseDrawer = (_event: object, reason: string) => {
    if (reason === 'backdropClick' || reason === 'close') {
      onClose();
    }
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={handleCloseDrawer}
      aria-label={DRAWER_LABEL}
    >
      <ProspectFilters
        onApply={handleApplyFilters}
        onCancel={() => handleCloseDrawer({}, 'close')}
        className={classes.filters}
      />
    </Drawer>
  );
};
