import React, { ChangeEvent, FC, useMemo } from 'react';
import { TableRow } from '@material-ui/core';

import { makeStyles, NamedColors } from '@knockrentals/knock-shared-web';
import SelectionCell from '../../Prospects//ProspectTable/SelectionCell';
import ResidentCells from './ResidentCells';
import { useAppDispatch } from '../../../../../app/hooks';
import {
  addSelectedResident,
  removeSelectedResident
} from '../../../../../app/features/residents/selectedResidents';
import { Resident } from '../../../../../app/services/residents/entities';

const useStyles = makeStyles(() => ({
  selectedRow: {
    backgroundColor: NamedColors.denim[50]
  }
}));

interface ResidentRowProps {
  resident: Resident;
  selected: boolean;
}

const ResidentRow: FC<ResidentRowProps> = ({ resident, selected }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  return useMemo(() => {
    const handleSelectRow = (event: ChangeEvent<HTMLInputElement>) => {
      const residentIdValue = event.target.attributes.getNamedItem('id')?.value;

      if (residentIdValue) {
        const residentId = Number(residentIdValue);

        if (event.target.checked) {
          dispatch(
            addSelectedResident({
              id: residentId,
              name: resident.profile.fullName
            })
          );
        } else {
          dispatch(removeSelectedResident(residentId));
        }
      }
    };

    const label = `select-resident-${resident.profile.fullName}`;

    return (
      <TableRow
        key={resident.id}
        className={selected ? classes.selectedRow : undefined}
        data-testid="resident-row"
      >
        <SelectionCell
          id={resident.id.toString()}
          label={label}
          selected={selected}
          onSelect={handleSelectRow}
        />
        <ResidentCells resident={resident} selected={selected} />
      </TableRow>
    );
  }, [resident, selected, classes.selectedRow, dispatch]);
};

export default ResidentRow;
