import { EntityId } from '@reduxjs/toolkit';
import { api } from './api';
import { useCallback, useMemo } from 'react';
import { leasingTeamMemberAdapter } from './entities';

export const useLeasingTeamMembers = () => {
  const { useGetMembersQuery, useSearchPeopleMutation } = api;
  const {
    data: members,
    isLoading: areMembersLoading,
    error: membersLoadingError
  } = useGetMembersQuery();
  const { selectAll, selectTotal, selectById } =
    leasingTeamMemberAdapter.getSelectors();
  const [searchPeople] = useSearchPeopleMutation();
  const store = members || leasingTeamMemberAdapter.getInitialState();
  return {
    areMembersLoading,
    membersLoadingError,
    allLeasingTeamMembers: useMemo(() => selectAll(store), [selectAll, store]),
    selectLeasingTeamMemberById: useCallback(
      (id: EntityId) => selectById(store, id),
      [selectById, store]
    ),
    leasingTeamMembersCount: useMemo(
      () => selectTotal(store),
      [selectTotal, store]
    ),
    searchPeople
  };
};
