import React from 'react';

export const FlowerIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.09253 5.52084C5.09159 6.9329 5.68777 8.27951 6.73378 9.22805C7.7798 10.1766 9.17813 10.6386 10.5834 10.5C13.1918 10.1751 15.1361 7.93658 15.0925 5.30834V1.77084C15.0925 1.53411 14.9588 1.3177 14.747 1.21183C14.5353 1.10596 14.2819 1.1288 14.0925 1.27084L12.5925 2.39584L10.8425 1.08334C10.3981 0.75001 9.78697 0.75001 9.34253 1.08334L7.59253 2.39584L6.09253 1.27084C5.90314 1.1288 5.64976 1.10596 5.43802 1.21183C5.22628 1.3177 5.09253 1.53411 5.09253 1.77084V5.52084Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0925 10.5208V19.2708"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.59371 15.0733C7.74928 14.268 6.64197 13.377 5.12049 13.083C3.599 12.7891 2.23948 13.2036 2.08392 14.0089C1.92835 14.8141 3.03566 15.7052 4.55714 15.9991C6.07863 16.2931 7.43815 15.8785 7.59371 15.0733Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6288 16.0831C17.1503 15.7892 18.2576 14.8981 18.102 14.0929C17.9465 13.2876 16.5869 12.8731 15.0654 13.167C13.544 13.461 12.4367 14.352 12.5922 15.1573C12.7478 15.9625 14.1073 16.3771 15.6288 16.0831Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
