/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('userCalendarApi', [
    'apiBase',
    function (apiBase) {
      return {
        getCalendarUrl: function () {
          return apiBase.get('/calendar/future');
        },
        getGoogleCalendarAppointmentLink: function (appointmentId) {
          return apiBase.get('/appointments/' + appointmentId + '/ics/google');
        }
      };
    }
  ]);
})();
