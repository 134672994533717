import React, { useMemo, useState, useCallback, useEffect, FC } from 'react';
import {
  MenuItem,
  Checkbox,
  Badge,
  Menu,
  Button,
  makeStyles,
  Theme
} from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

import { Box, Text, NamedColors } from '@knockrentals/knock-shared-web';
import { colors, useCommonStyles } from '../../commonStyles/commonStyles';

const useStyles = makeStyles((theme: Theme) => ({
  listPadding: {
    padding: '8px 19px 8px 10px !important'
  },

  menu: {
    border: `1px solid ${NamedColors.slate[200]}`,
    borderRadius: '4px',
    marginTop: '4px'
  },

  selectChip: {
    height: '32px',
    padding: '4px',
    color: theme.palette.text.primary,
    backgroundColor: NamedColors.slate[100],
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '16px',
    border: `1px solid ${NamedColors.slate[100]}`,
    minWidth: '72px',

    '&:hover': {
      backgroundColor: NamedColors.denim[100]
    },

    '&:disabled': {
      color: colors.disabled,
      backgroundColor: 'inherit',
      border: 'none',

      '& > .MuiButton-label > svg': {
        color: colors.disabled
      }
    }
  },

  selectChipOutlined: {
    backgroundColor: NamedColors.denim[100],
    borderColor: NamedColors.denim[500]
  },

  badgeIcon: {
    marginLeft: '6px',

    '& > span': {
      backgroundColor: NamedColors.denim[600],
      fontSize: '1rem',
      fontWeight: 600,
      position: 'relative',
      transform: 'initial'
    }
  },

  filterName: {
    fontSize: '13px',
    marginLeft: '6px'
  },

  checkbox: {
    '&.Mui-checked': {
      color: '#697FF7'
    }
  },

  filterText: {
    marginRight: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  bottomBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '68px',
    boxShadow: '0 0 5px -2px #888',
    padding: '0 16px'
  },

  optionsContainer: {
    maxHeight: 390,
    maxWidth: 280,
    overflow: 'auto'
  },

  keyboardArrow: {
    color: '#5C5C5C',
    margin: '6px 3px 6px 3px'
  },

  tooltip: {
    fontSize: '10px',
    fontWeight: 600,
    padding: '4px 8px',
    width: '230px',
    top: '-25px'
  }
}));

interface ChipSelectStatusProps {
  filterOptions: string[];
  filterState: string[];
  chipLabel: string;
  onApply: (filters: string[]) => void;
  isDisabled?: boolean;
  className?: string;
  cheatProofEngagementScoreEnabled?: boolean;
}

const ChipSelectStatus: FC<ChipSelectStatusProps> = ({
  filterOptions,
  filterState,
  chipLabel,
  onApply,
  isDisabled = false,
  className
}: ChipSelectStatusProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  useEffect(() => {
    setSelectedFilters([...filterState]);
  }, [filterState]);

  const handleFilterOptionClick = useCallback(
    (newFilter: string) => {
      if (newFilter === 'All Active') {
        if (selectedFilters.includes(newFilter)) {
          setSelectedFilters([]);
        } else {
          setSelectedFilters(filterOptions.slice(0, 9));
        }
      } else {
        let newSelectedFilters = [...selectedFilters];

        if (selectedFilters.includes('All Active')) {
          newSelectedFilters = newSelectedFilters.filter(
            (item) => item !== 'All Active'
          );
        }

        if (selectedFilters.includes(newFilter)) {
          newSelectedFilters = newSelectedFilters.filter(
            (item) => item !== newFilter
          );
        } else {
          newSelectedFilters.push(newFilter); // TODO
        }

        setSelectedFilters(newSelectedFilters);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFilters]
  );

  const selectedFilterCount = useMemo(() => {
    if (selectedFilters.length > 1) {
      return `+${selectedFilters.length - 1}`;
    }
  }, [selectedFilters]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedFilters([...filterState]);
  };

  const handleResetFilters = () => {
    setSelectedFilters(filterOptions.slice(0, 9));
  };

  const handleApplyFilters = () => {
    setAnchorEl(null);
    onApply(selectedFilters);
  };

  const getFilterLabel = (filter: string) => {
    return filter;
  };

  const showFilterLabel = selectedFilters.length > 0;
  const showFilterCount =
    selectedFilters.length > 1 && !selectedFilters.includes(filterOptions[0]);
  return (
    <Box
      className={className ? className : undefined}
      data-testid="status-action-chip"
    >
      <Button
        data-testid="status-action-chip-button"
        disabled={isDisabled}
        variant={anchorEl ? 'outlined' : 'text'}
        onClick={handleOpen}
        classes={{
          root: classes.selectChip,
          outlined: classes.selectChipOutlined
        }}
      >
        <Text variant="body2" className={classes.filterName}>
          {showFilterLabel
            ? `${chipLabel}: ${getFilterLabel(selectedFilters[0])}`
            : chipLabel}
        </Text>

        {showFilterCount ? (
          <Badge
            className={classes.badgeIcon}
            badgeContent={selectedFilterCount}
            color="primary"
          />
        ) : null}
        {anchorEl ? (
          <KeyboardArrowUp className={classes.keyboardArrow} />
        ) : (
          <KeyboardArrowDown className={classes.keyboardArrow} />
        )}
      </Button>

      <Menu
        classes={{ paper: classes.menu }}
        elevation={0}
        MenuListProps={{ role: 'listbox', disablePadding: true }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className={classes.optionsContainer}>
          {filterOptions.map((filter, index) => (
            <MenuItem
              key={index}
              classes={{ root: classes.listPadding }}
              onClick={() => handleFilterOptionClick(filter)}
            >
              <Checkbox
                className={classes.checkbox}
                data-testid="chip-select-menu-checkbox"
                size="medium"
                checked={selectedFilters.indexOf(filter) > -1}
              />

              <Text variant="body1" className={classes.filterText}>
                {filter}
              </Text>
            </MenuItem>
          ))}
        </div>

        <div className={classes.bottomBox}>
          <Button
            data-testid="reset-button"
            variant="text"
            color="primary"
            onClick={handleResetFilters}
          >
            Reset
          </Button>

          <Button
            variant="contained"
            color="primary"
            data-testid="apply-button"
            className={commonClasses.primaryButton}
            onClick={handleApplyFilters}
          >
            Apply
          </Button>
        </div>
      </Menu>
    </Box>
  );
};

export default ChipSelectStatus;
