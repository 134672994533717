/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('conversationProspectSearchResult', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/conversations/conversation-prospect-search-result.html',
      controller: 'ConversationProspectSearchResultController',
      replace: true,
      scope: {
        prospectResult: '=',
        teamMembers: '='
      }
    };
  });

  app.controller('ConversationProspectSearchResultController', [
    '$scope',
    '$moment',
    'conversationsService',
    function ($scope, $moment, conversationsService) {
      $scope.hit = $scope.prospectResult.hit;
      $scope.highlight = $scope.prospectResult.highlight;

      var owner = find($scope.teamMembers, function (member) {
        return member.Manager.id === $scope.hit.assigned_manager_id;
      });

      if (owner) {
        $scope.owner = owner.ManagerInfo;
      }

      $scope.openConversation = function () {
        conversationsService.openThreadById($scope.hit.stream_id, true);
      };
    }
  ]);

  app.directive('conversationMessageSearchResult', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/conversations/conversation-message-search-result.html',
      controller: 'ConversationMessageSearchResultController',
      replace: true,
      scope: {
        streamResult: '='
      }
    };
  });

  app.controller('ConversationMessageSearchResultController', [
    '$scope',
    '$moment',
    'conversationsService',
    function ($scope, $moment, conversationsService) {
      $scope.stream = $scope.streamResult.stream;
      $scope.hits = $scope.streamResult.hits;

      var managerParticipant = find($scope.stream.participants, {
        type: 'manager'
      });
      $scope.ownerInfo = managerParticipant.info
        ? managerParticipant.info
        : null;

      $scope.otherParticipant = find(
        $scope.stream.participants,
        function (participant) {
          return participant.type !== 'manager';
        }
      );

      var startOfCreatedDay = $moment($scope.stream.last_message_time).startOf(
        'day'
      );
      var startOfToday = $moment().startOf('day');

      $scope.isToday = startOfCreatedDay.isSame(startOfToday);

      $scope.openConversation = function () {
        conversationsService.openThreadById(
          $scope.streamResult.stream.id,
          true
        );
      };
    }
  ]);
})(window.angular);
