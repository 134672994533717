/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import filter from 'lodash/filter';
import size from 'lodash/size';
import some from 'lodash/some';
import sample from 'lodash/sample';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import transform from 'lodash/transform';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('newAppointmentModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      return {
        openModal: function (
          calendar,
          startTime,
          endTime,
          prospectData,
          appointmentRequest
        ) {
          var newAppointmentDialog = {
            controller: 'NewAppointmentModalController',
            templateUrl: '/angular/views/calendar/new-appointment-modal.html',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
              calendar: calendar,
              startTime: startTime,
              endTime: endTime,
              prospectData: prospectData || {},
              appointmentRequest: appointmentRequest
            }
          };

          return $mdDialog.show(newAppointmentDialog);
        }
      };
    }
  ]);

  const NewAppointmentModalController = function (
    $q,
    $scope,
    $mdToast,
    $mdDialog,
    $interpolate,
    $window,
    appDataService,
    appointmentsApi,
    newAppointmentModalFactory,
    listingApi,
    leasingTeamApi,
    timeService,
    calendar,
    startTime,
    endTime,
    prospectData,
    appointmentRequest,
    calendarApiService,
    userService,
    contactTypes
  ) {
    var self = this;

    self.currentManager = userService.getUser();

    $scope.noEmail = false;

    self._initialize = function () {
      $scope.isInitializing = true;

      $scope.timeChoices = self.generateTimeChoices(startTime);

      $scope.activeOptionsView = $scope.optionsView.manual;
      $scope.contactTypes = contactTypes.get();

      $scope.appointmentRequest = appointmentRequest || {
        date: timeService.now().toDate(),
        startTime: undefined,
        endTime: undefined,
        description: '',
        title: '',
        renter: {
          firstName: prospectData.firstName || '',
          lastName: prospectData.lastName || '',
          email: prospectData.email || '',
          phone: prospectData.phone || {},
          targetMoveDate: prospectData.targetMoveDate || null
        },
        prospectId: prospectData.id,
        source: prospectData.source || '',
        managerId: prospectData.managerId || self.currentManager.id,
        firstContactType: prospectData.firstContactType || 'knock-schedule'
      };

      if (!isEmpty(prospectData.mappedProspect)) {
        $scope.appointmentRequest.renter = prospectData.mappedProspect;
        $scope.appointmentRequest.prospectId = prospectData.mappedProspect.id;
      }

      if (startTime) {
        var start = timeService.get(startTime);
        var end = timeService.get(startTime).add(15, 'minutes');

        if (endTime) {
          end = timeService.get(endTime);
        }

        $scope.appointmentRequest.date = start.toDate();

        $scope.appointmentRequest.startTime = find(
          $scope.timeChoices,
          function (timeChoice) {
            return (
              timeChoice.hour() === start.hour() &&
              timeChoice.minute() === start.minute()
            );
          }
        );

        $scope.appointmentRequest.endTime = find(
          $scope.timeChoices,
          function (timeChoice) {
            return (
              timeChoice.hour() === end.hour() &&
              timeChoice.minute() === end.minute()
            );
          }
        );
      } else {
        if (!isEmpty($scope.appointmentRequest.startTime)) {
          $scope.appointmentRequest.startTime = find(
            $scope.timeChoices,
            function (choice) {
              return (
                choice.format() === $scope.appointmentRequest.startTime.format()
              );
            }
          );
        } else {
          $scope.appointmentRequest.startTime = $scope.timeChoices[0];
        }

        if (!isEmpty($scope.appointmentRequest.endTime)) {
          $scope.appointmentRequest.endTime = find(
            $scope.timeChoices,
            function (choice) {
              return (
                choice.format() === $scope.appointmentRequest.endTime.format()
              );
            }
          );
        } else {
          $scope.appointmentRequest.endTime = $scope.timeChoices[1];
        }
      }

      if (!isEmpty(prospectData)) {
        // clear all times if this appointment is being created for a lead
        $scope.appointmentRequest.date = undefined;
        $scope.appointmentRequest.startTime = undefined;
        $scope.appointmentRequest.endTime = undefined;
      }

      self._setTeamMembers();
      self._setAppointmentSources();

      var promises = [self.getListings()];

      $q.all(promises).finally(function () {
        $scope.isInitializing = false;
      });
    };

    $scope.$watch('appointmentRequest.date', function (newDate) {
      if (isUndefined(newDate)) {
        return;
      }

      $scope.timeChoices = self.generateTimeChoices(newDate);

      var start = $scope.appointmentRequest.startTime || $scope.timeChoices[0];
      var end = $scope.appointmentRequest.endTime || $scope.timeChoices[1];

      $scope.appointmentRequest.startTime = find(
        $scope.timeChoices,
        function (timeChoice) {
          return (
            timeChoice.hour() === start.hour() &&
            timeChoice.minute() === start.minute()
          );
        }
      );

      $scope.appointmentRequest.endTime = find(
        $scope.timeChoices,
        function (timeChoice) {
          return (
            timeChoice.hour() === end.hour() &&
            timeChoice.minute() === end.minute()
          );
        }
      );
    });

    $scope.$watch('appointmentRequest.startTime', function (newStartTime) {
      if (isUndefined(newStartTime)) {
        return;
      }

      $scope.appointmentRequest.endTime =
        $scope.appointmentRequest.endTime || timeService.clone(newStartTime);

      if (!$scope.appointmentRequest.endTime.isAfter(newStartTime)) {
        var newEndTime = timeService.clone(newStartTime).add(15, 'minutes');
        $scope.appointmentRequest.endTime = find(
          $scope.timeChoices,
          function (choice) {
            return choice.format() === newEndTime.format();
          }
        );
      }
    });

    $scope.$watch('appointmentRequest.endTime', function (newEndTime) {
      if (isUndefined(newEndTime)) {
        return;
      }

      $scope.appointmentRequest.startTime =
        $scope.appointmentRequest.startTime || timeService.clone(newEndTime);

      if (!$scope.appointmentRequest.startTime.isBefore(newEndTime)) {
        var newStartTime = timeService
          .clone(newEndTime)
          .subtract(15, 'minutes');
        $scope.appointmentRequest.startTime = find(
          $scope.timeChoices,
          function (choice) {
            return choice.format() === newStartTime.format();
          }
        );
      }
    });

    $scope.dateFormat = 'MM/dd/yyyy';
    $scope.dateOpened = false;

    $scope.optionsView = {
      busy: 'busy',
      manual: 'manual'
    };

    $scope.sortAfterCurrentManager = function (manager) {
      if (manager.id === self.currentManager.id) {
        return -1;
      }

      return manager.name;
    };

    $scope.sortListingByManagerName = function (managerListing) {
      if (managerListing.manager.id === self.currentManager.id) {
        return -1;
      }

      return managerListing.manager.name;
    };

    $scope.addAppointment = function () {
      $scope.appointmentErrorMessage = null;
      $scope.addingAppointment = true;

      if ($scope.activeOptionsView === $scope.optionsView.manual) {
        self.addManualAppointment();
      }
    };

    $scope.sortByStatus = function (listing) {
      return listing.status === 'active' ? 0 : 1;
    };

    self.addManualAppointment = function () {
      var listingId = $scope.appointmentRequest.listingId;

      var managerId = $scope.appointmentRequest.managerId;
      var renterInfo = $scope.appointmentRequest.renter;
      var prospectId = $scope.appointmentRequest.prospectId;
      var startTime = self._combineDateAndTime(
        $scope.appointmentRequest.date,
        $scope.appointmentRequest.startTime
      );
      var endTime = self._combineDateAndTime(
        $scope.appointmentRequest.date,
        $scope.appointmentRequest.endTime
      );

      function addManualAppointment() {
        var source = $scope.appointmentRequest.source;
        var firstContactType = $scope.appointmentRequest.firstContactType;

        if ($scope.noEmail) {
          renterInfo.email = null;
        }

        calendarApiService
          .addManualAppointment(
            managerId,
            listingId,
            renterInfo,
            startTime,
            endTime,
            source,
            prospectId,
            firstContactType
          )
          .success(self._handleAddAppointmentSuccess)
          .error(self._handleAddAppointmentError)
          .finally(function () {
            $scope.addingAppointment = false;
          });
      }

      if (timeService.get(startTime).isBefore(timeService.now())) {
        var confirmDialog = $mdDialog
          .confirm()
          .title('You are creating an appointment in the past')
          .content('Are you sure you want to continue?')
          .ok('Yes')
          .cancel('Whoops');

        $mdDialog
          .show(confirmDialog)
          .then(addManualAppointment)
          .catch(function () {
            newAppointmentModalFactory.openModal(
              calendar,
              startTime,
              endTime,
              prospectData,
              $scope.appointmentRequest
            );
          });
      } else {
        addManualAppointment();
      }
    };

    self._combineDateAndTime = function (date, time) {
      var datePart = timeService.get(date).format('YYYY-MM-DD');
      var timePart = timeService.get(time).format().split('T')[1];
      return datePart + 'T' + timePart;
    };

    $scope.dismiss = function () {
      $mdDialog.cancel();
    };

    self.getListings = function () {
      return listingApi.getMyActiveListings().success(self._mapListings);
    };

    self._setTeamMembers = function () {
      var members = appDataService.getTeamMembers();

      var memberNames = transform(
        members,
        function (result, member) {
          result[member.Manager.id] =
            member.ManagerInfo.first_name + ' ' + member.ManagerInfo.last_name;
        },
        {}
      );

      $scope.teamMembers = map(members, function (member) {
        var memberId = member.Manager.id;
        return {
          id: memberId,
          name: memberNames[memberId]
        };
      });
    };

    self._setAppointmentSources = function () {
      $scope.sourceOptions = map(appDataService.getSources(), 'source_name');
      $scope.appointmentRequest.source =
        $scope.appointmentRequest.source || 'Knock';

      forEach($scope.sourceOptions, function (option) {
        if (option === prospectData.source) {
          $scope.appointmentRequest.source = option;
        }
      });
    };

    self._mapListings = function (response) {
      $scope.teamListings = map(
        response.listings,
        function (memberListings, managerId) {
          var listings = sortBy(memberListings.listings, function (listing) {
            return listing.leasing.monthlyRent;
          });

          var mappedListings = map(listings, function (listing) {
            var title = listing.floorplan.community_id
              ? self._getCommunityListingTitle(listing)
              : self._getSingleFamilyListingTitle(listing);

            return {
              id: listing.id,
              title: title,
              status: listing.status,
              rent: listing.leasing.monthlyRent
            };
          });

          var manager = find($scope.teamMembers, { id: parseInt(managerId) });

          return {
            listings: mappedListings,
            manager: manager
          };
        }
      );
    };

    self._getCommunityListingTitle = function (listing) {
      var hasUnit =
        listing.location.address.unit &&
        listing.location.address.unit !== 'None';

      var template = hasUnit
        ? '{{monthlyRent | noFractionCurrency}} - Unit {{unit}} - {{name}}'
        : '{{monthlyRent | noFractionCurrency}} - {{bedrooms | bedrooms}} - {{name}}';

      var titleExp = $interpolate(template);
      var title = titleExp({
        monthlyRent: listing.leasing.monthlyRent,
        unit: listing.location.address.unit,
        name: listing.floorplan.name,
        bedrooms: listing.floorplan.bedrooms
      });

      return title;
    };

    self._getSingleFamilyListingTitle = function (listing) {
      var titleExp = $interpolate(
        '{{monthlyRent | noFractionCurrency}} - {{bedrooms | bedrooms}} - {{address}}'
      );

      return titleExp({
        monthlyRent: listing.leasing.monthlyRent,
        bedrooms: listing.floorplan.bedrooms,
        address: listing.location.address.raw
      });
    };

    self._handleAddAppointmentSuccess = function (response) {
      $mdDialog.hide(response.appointment);
      $mdToast.show($mdToast.simple().content('Appointment created!'));

      if (!isEmpty($scope.appointmentRequest.prospectId)) {
        $window.location.reload();
      }
    };

    self._handleAddAppointmentError = function () {
      $mdToast.show(
        $mdToast
          .simple()
          .content('There was an error creating your appointment.')
      );
    };

    self.generateTimeChoices = function (startTime) {
      var timeSlots = [];
      var timeWalker = timeService.get(startTime).startOf('day').hour(7);
      var todayEnd = timeService.get(startTime).startOf('day').hour(22);

      while (timeWalker.isBefore(todayEnd)) {
        timeSlots.push(timeService.clone(timeWalker));
        timeWalker.add(5, 'minutes');
      }

      return timeSlots;
    };

    self._initialize();
  };

  NewAppointmentModalController.$inject = [
    '$q',
    '$scope',
    '$mdToast',
    '$mdDialog',
    '$interpolate',
    '$window',
    'appDataService',
    'appointmentsApi',
    'newAppointmentModalFactory',
    'listingApi',
    'leasingTeamApi',
    'timeService',
    'calendar',
    'startTime',
    'endTime',
    'prospectData',
    'appointmentRequest',
    'calendarApiService',
    'userService',
    'contactTypes'
  ];

  app.controller(
    'NewAppointmentModalController',
    NewAppointmentModalController
  );

  app.factory('appointmentProposalsModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      return {
        createModal: function (property, prospectId, onProposalSuccess) {
          return $mdDialog.show({
            controller: 'AppointmentProposalsModalController',
            templateUrl:
              '/angular/views/calendar/appointment-proposals-modal.html',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
              property: property || null,
              prospectId: prospectId,
              onProposalSuccess: onProposalSuccess
            }
          });
        }
      };
    }
  ]);

  app.controller('AppointmentProposalsModalController', [
    '$scope',
    '$q',
    '$mdDialog',
    'timeService',
    'calendarApiService',
    'managerApi',
    'property',
    'prospectId',
    'onProposalSuccess',
    function (
      $scope,
      $q,
      $mdDialog,
      timeService,
      calendarApiService,
      managerApi,
      property,
      prospectId,
      onProposalSuccess
    ) {
      var self = this;

      $scope.property = property;
      $scope.prospectId = prospectId;

      self.initialize = function () {
        $scope.allSlotsFilled = false;
        $scope.timeSelections = [
          { selection: null },
          { selection: null },
          { selection: null }
        ];

        managerApi
          .getManagerPreferencesById($scope.property.owning_manager_id)
          .success(function (response) {
            var preferences = response.manager_preferences;

            var tourDuration = timeService.get(
              preferences.tour_duration,
              'HH:mm:ss'
            );
            self.tourDurationMinutes =
              tourDuration.hours() * 60 + tourDuration.minutes();
            self.numberOfSchedulingDays = preferences.number_of_scheduling_days;

            var availableTimes = self._generateAvailableTimes();
            self._mapAvailableTimes(availableTimes);
          });
      };

      self._generateAvailableTimes = function () {
        var availableTimes = [];

        for (var i = 0; i < self.numberOfSchedulingDays; i++) {
          var dayMoment = timeService.now().add(i, 'days').startOf('day');
          var dayChoices = self.generateTimeChoicesForDay(dayMoment);

          availableTimes = availableTimes.concat(dayChoices);
        }

        return availableTimes;
      };

      self.generateTimeChoicesForDay = function (dayMoment) {
        var timeSlots = [];
        var timeWalker = timeService.get(dayMoment).hour(7);
        var todayEnd = timeService.get(dayMoment).hour(22);

        while (timeWalker.isBefore(todayEnd)) {
          timeSlots.push(timeService.clone(timeWalker));
          timeWalker.add(15, 'minutes');
        }

        return timeSlots;
      };

      self._mapAvailableTimes = function (availableTimes) {
        var now = timeService.now();

        var afterNowFilter = function (time) {
          return timeService.get(time).isAfter(now);
        };

        function adjustToLocale(time) {
          return timeService.get(
            time,
            null,
            $scope.property.data.location.timezone
          );
        }

        var localeAdjustedTimes = map(availableTimes, adjustToLocale);

        $scope.availableTimesSlots = self._groupTimeSlots(
          localeAdjustedTimes.filter(afterNowFilter)
        );

        self._processTourTimes();

        $scope.isLoadingTimes = false;
      };

      self._groupTimeSlots = function (times) {
        return groupBy(times, function (time) {
          return time.year().toString() + '_' + time.dayOfYear();
        });
      };

      self._processTourTimes = function () {
        $scope.days = map(
          $scope.availableTimesSlots,
          function (dayAvailableTimes) {
            // Turn all of the times into moment objects
            var dayAvailableMoments = map(
              dayAvailableTimes,
              function (availableTime) {
                return timeService.get(
                  availableTime,
                  null,
                  $scope.property.data.location.timezone
                );
              }
            );

            // ex: Wed 21st
            var dayLabel = sample(dayAvailableMoments).format('ddd Do');

            return {
              label: dayLabel,
              times: dayAvailableMoments
            };
          }
        );

        var sampleTime = sample(sample($scope.availableTimesByDay));
        var propertyTimezone = $scope.property.data.location.timezone;
        $scope.timezoneAbbreviation = timeService.getTimezoneAbbreviation(
          sampleTime,
          propertyTimezone
        );
      };

      $scope.$watch('availableTimesByDay', function () {
        if ($scope.availableTimesByDay) {
          self._processTourTimes();
        }
      });

      $scope.isTimeSelected = function (time) {
        return some($scope.timeSelections, { selection: time });
      };

      $scope.selectSlot = function (slot) {
        $scope.selectedSlot = slot;
      };

      $scope.selectDay = function (day) {
        $scope.selectedDay = day;
      };

      $scope.selectTime = function (time) {
        if ($scope.selectedSlot) {
          $scope.selectedSlot.selection = time;
          $scope.clearFinishedSelection();

          $scope.allSlotsFilled = $scope.calculateIfFinished();
        } else {
          throw 'Cannot select time without a slot selection!';
        }
      };

      $scope.clearFinishedSelection = function () {
        if (!$scope.isAutoAccept) {
          $scope.selectedSlot = null;
        }
        $scope.selectedDay = null;
        $scope.selectedHour = null;
        $scope.allSlotsFilled = false;
      };

      $scope.calculateIfFinished = function () {
        return (
          size($scope.timeSelections) > 0 &&
          filter($scope.timeSelections, 'selection').length > 0
        );
      };

      $scope.submitAppointmentProposals = function () {
        if ($scope.calculateIfFinished()) {
          $scope.isSendingProposals = true;

          var proposalTimes = self._getProposalTimes();

          calendarApiService
            .proposeAppointmentTimes(
              $scope.prospectId,
              proposalTimes,
              $scope.managerMessage
            )
            .success(self.proposeAppointmentTimesSuccess)
            .error(self.proposeAppointmentTimesError)
            .finally(function () {
              $scope.isSendingProposals = false;
            });
        }
      };

      $scope.dismiss = function () {
        $mdDialog.cancel();
      };

      self._getProposalTimes = function () {
        var validSelections = filter($scope.timeSelections, 'selection');

        return map(validSelections, function (timeSlot) {
          var start = timeSlot.selection.format();
          var end = timeService
            .get(start)
            .add(self.tourDurationMinutes, 'minutes')
            .format();

          return { start_time: start, end_time: end };
        });
      };

      self.proposeAppointmentTimesSuccess = function () {
        if (onProposalSuccess) {
          onProposalSuccess();
        }
        $scope.dismiss();
      };

      self.proposeAppointmentTimesError = function (error, status) {
        if (status === 400) {
          $scope.schedulingErrors = error.reasons;
        }
      };

      self.initialize();
    }
  ]);
})(window.angular);
