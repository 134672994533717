import { api as baseApi } from 'app/services/api';
import { ResidentFilterPayload } from 'app/features/residents/filter';
import {
  mapResident,
  ResidentData,
  AddResidentResponseData,
  AddResidentData
} from './entities';

export const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getResidents: build.query<ResidentData, Partial<ResidentFilterPayload>>({
      query: (data) => {
        let url = `/me/property-reports/v2/residents?offset=${data.offset}&per_page=${data.per_page}`;

        if (data.property_ids) {
          data.property_ids.forEach((property: string | number) => {
            url += `&property_ids=${property}`;
          });
        }

        if (data.buildings) {
          data.buildings.forEach((building: string) => {
            url += `&buildings=${building}`;
          });
        }

        if (data.statuses) {
          const filterByCurrentStatus = data.statuses.indexOf('current') > -1;
          let undecidedOnlyFlag = false;
          let includeUndecidedFlag = false;

          data.statuses.forEach((status: string) => {
            if (status === 'undecided') {
              if (filterByCurrentStatus) {
                includeUndecidedFlag = true;
              } else {
                undecidedOnlyFlag = true;
                url += '&statuses=current';
              }
            } else {
              url += `&statuses=${status}`;
            }
          });

          if (undecidedOnlyFlag) {
            url += '&undecided_only=true';
          }

          if (includeUndecidedFlag) {
            url += '&include_undecided=true';
          }
        }

        if (data.lease_end_after) {
          url += `&lease_end_after=${data.lease_end_after}`;
        }

        if (data.lease_end) {
          url += `&lease_end=${data.lease_end}`;
        }

        if (data.resident_ids) {
          data.resident_ids.forEach((residentId: number) => {
            url += `&resident_ids=${residentId.toString()}`;
          });
        }

        if (data.sort_column) {
          url += `&sort_column=${data.sort_column}`;
        }

        if (data.sort_direction) {
          url += `&sort_direction=${data.sort_direction}`;
        }

        if (data.batch_id) {
          url += `&batch_id=${data.batch_id}`;
        }

        return {
          url,
          method: 'GET',
          data
        };
      },
      transformResponse(response: any) {
        const residents = response.resident_reports.map((resident: any) =>
          mapResident(resident)
        );
        return {
          residents,
          pageData: {
            count: response.total_count
          }
        };
      }
    }),
    addResident: build.mutation<
      AddResidentResponseData,
      Partial<AddResidentData>
    >({
      query(body) {
        return {
          url: `/residents`,
          method: 'POST',
          data: body
        };
      }
    })
  })
});
