/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('cancelAppointmentModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.createModal = function (prospectProfile, confirmHandler) {
        return $mdDialog.show({
          controller: 'CancelAppointmentModalController',
          templateUrl: '/angular/views/calendar/cancel-appointment-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            prospectProfile: prospectProfile || null,
            confirmHandler: confirmHandler || null
          }
        });
      };

      return self;
    }
  ]);

  app.factory('rejectRequestsModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      return {
        createModal: function (prospectProfile, confirmHandler, openProposal) {
          return $mdDialog.show({
            controller: 'RejectAppointmentModalController',
            templateUrl: '/angular/views/calendar/reject-requests-modal.html',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
              prospectProfile: prospectProfile || null,
              confirmHandler: confirmHandler,
              openProposal: openProposal
            }
          });
        }
      };
    }
  ]);

  app.controller('CancelAppointmentModalController', [
    '$scope',
    '$mdDialog',
    'prospectProfile',
    'confirmHandler',
    function ($scope, $mdDialog, prospectProfile, confirmHandler) {
      $scope.prospectName =
        prospectProfile.first_name + ' ' + prospectProfile.last_name;

      $scope.cancelAppointment = function () {
        if (confirmHandler) {
          confirmHandler($scope.message);
        }

        $mdDialog.hide($scope.message);
      };
      $scope.dismiss = function () {
        $mdDialog.cancel();
      };
    }
  ]);

  app.controller('RejectAppointmentModalController', [
    '$scope',
    '$mdDialog',
    'prospectProfile',
    'confirmHandler',
    'openProposal',
    function (
      $scope,
      $mdDialog,
      prospectProfile,
      confirmHandler,
      openProposal
    ) {
      $scope.prospectName =
        prospectProfile.first_name + ' ' + prospectProfile.last_name;

      $scope.rejectAppointment = function () {
        confirmHandler($scope.message);
        $mdDialog.cancel();
      };
      $scope.dismiss = function () {
        $mdDialog.cancel();
      };
      $scope.openProposalModal = function () {
        openProposal();
        $mdDialog.cancel();
      };
    }
  ]);
})();
