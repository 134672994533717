/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('renterCard', function () {
    return {
      restrict: 'E',
      scope: {
        renter: '='
      },
      templateUrl: '/angular/views/directives/renterCard.html'
    };
  });
})();
