import React, { FC } from 'react';
import classnames from 'classnames';

export interface PersonSecureIconProps {
  className?: string;
  height?: string;
  strokeColor?: string;
  width?: string;
  useNewDesign?: boolean;
}

export const PersonSecureIcon: FC<PersonSecureIconProps> = ({
  className,
  height = '15',
  strokeColor = '#8D90A5',
  width = '14',
  useNewDesign
}) => {
  if (useNewDesign) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4375 8.45275C18.4382 13.394 14.8721 17.614 10 18.4375C5.1279 17.614 1.56183 13.394 1.5625 8.45275V2.6875C1.5625 2.06618 2.06618 1.5625 2.6875 1.5625H17.3125C17.9338 1.5625 18.4375 2.06618 18.4375 2.6875V8.45275Z"
          stroke="#353950"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 10C11.398 10 12.5312 8.86672 12.5312 7.46875C12.5312 6.07078 11.398 4.9375 10 4.9375C8.60203 4.9375 7.46875 6.07078 7.46875 7.46875C7.46875 8.86672 8.60203 10 10 10Z"
          stroke="#353950"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0403 13.5623C13.2715 12.0364 11.7087 11.0737 10 11.0737C8.29139 11.0737 6.72852 12.0364 5.95978 13.5623"
          stroke="#353950"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        className={classnames('person-secure-icon', className)}
        width={width}
        height={height}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
      >
        <path
          clipRule="evenodd"
          d="M13.316 6.582a7.587 7.587 0 0 1-6.323 7.483A7.587 7.587 0 0 1 .67 6.583v-4.32c0-.466.377-.844.843-.844h10.96c.465 0 .842.378.842.843v4.32Z"
          stroke={strokeColor}
          strokeWidth="1.124"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          clipRule="evenodd"
          d="M6.993 7.742a1.897 1.897 0 1 0 0-3.794 1.897 1.897 0 0 0 0 3.794Z"
          stroke={strokeColor}
          strokeWidth="1.124"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.02 10.412a3.39 3.39 0 0 0-6.055 0"
          stroke={strokeColor}
          strokeWidth="1.124"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
};
