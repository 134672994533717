/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('callMessage', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/messages/call-message.html',
      scope: {
        message: '=',
        developerName: '=',
        stream: '=',
        isTranscribed: '='
      },
      controller: [
        '$rootScope',
        '$scope',
        '$routeParams',
        'relayApi',
        'transcriptApi',
        'streamRepositoryService',
        'appDataService',
        function (
          $rootScope,
          $scope,
          $routeParams,
          relayApi,
          transcriptApi,
          streamRepositoryService,
          appDataService
        ) {
          $scope.possibleOutcomes = [
            'answered',
            'missed - voicemail',
            'missed - no voicemail'
          ];
          $scope.teamMembers = appDataService.getTeamMembers();
          $scope.callDetails = null;
          $scope.showCallDetails = false;
          $scope.isLoadingCallDetails = false;
          $scope.currentCallOutcome = null;
          $scope.transcriptResponse = null;
          $scope.utterances = [];
          $scope.isLoadingAttempted = false;
          $scope.isInternal = $routeParams.internal === 'true';
          $scope.isCallRecording = $scope.message.type === 'call';
          $scope.isTranscriptionsLoading = false;
          $scope.showVoiceTranscription =
            $rootScope.featureFlags.SHOW_VOICE_TRANSCRIPTIONS;
          $scope.disableModifyCallOutcome =
            $rootScope.appPreferences.company
              .enable_cheatproof_engagement_score && !$scope.isInternal;

          $scope.toggleCallDetails = function () {
            $scope.callDetails = null;

            $scope.showCallDetails = !$scope.showCallDetails;

            if ($scope.showCallDetails) {
              $scope.loadCallDetails();
            }
          };

          $scope.loadCallDetails = function () {
            $scope.isLoadingCallDetails = true;

            relayApi
              .getPhoneCall($scope.message.call_sid)
              .then(function (response) {
                $scope.isLoadingCallDetails = false;
                $scope.callDetails = response.data.call;

                if ($scope.callDetails) {
                  $scope.currentCallOutcome = $scope.callDetails.outcome;
                }
              });
          };

          $scope.isOutcomeModifiable = function () {
            if (!$scope.callDetails) {
              return false;
            }

            const isGuessAPossibleOutcome = $scope.possibleOutcomes.includes(
              $scope.callDetails.outcome_guess
            );
            if (!isGuessAPossibleOutcome) {
              return false;
            }

            return $scope.callDetails.is_review_needed;
          };

          $scope.changeCallOutcome = function (newOutcome) {
            $scope.isLoadingCallDetails = true;

            relayApi
              .updatePhoneCallOutcome($scope.message.call_sid, newOutcome)
              .then(function (response) {
                $scope.message.outcome = newOutcome;
                $scope.loadCallDetails();
              });
          };

          $scope.getManagerName = function (managerId) {
            if ($scope.teamMembers.length !== 0) {
              const manager = $scope?.teamMembers?.find((teamMember) => {
                return teamMember?.ManagerInfo?.manager_id === managerId;
              });
              if (!managerId || !manager || !manager.ManagerInfo) {
                return 'Property';
              }

              return `${manager.ManagerInfo.first_name} ${manager.ManagerInfo.last_name}`;
            }
          };

          $scope.totalCallDuration = function (
            call_duration,
            transferred_call_recordings
          ) {
            const transferred_call_duration =
              transferred_call_recordings?.reduce((i, obj) => {
                return i + obj?.transfer_call_leg_duration ?? 0;
              }, 0);
            const recording_duration =
              call_duration + transferred_call_duration;
            if (!recording_duration) {
              return '';
            }

            var duration = +recording_duration;
            var minutes = Math.floor(duration / 60);
            var seconds = duration % 60;

            var lpad = function (num) {
              return '' + (num >= 10 ? num : '0' + num);
            };

            var sMinutes = lpad(minutes);
            var sSeconds = lpad(seconds);

            return sMinutes + ':' + sSeconds;
          };

          $scope.messageCallDuration = function (recording_duration) {
            if (!recording_duration) {
              return '';
            }

            var duration = +recording_duration;
            var minutes = Math.floor(duration / 60);
            var seconds = duration % 60;

            var lpad = function (num) {
              return '' + (num >= 10 ? num : '0' + num);
            };

            var sMinutes = lpad(minutes);
            var sSeconds = lpad(seconds);

            return sMinutes + ':' + sSeconds;
          };

          $scope.toggleVisibility = function (visibility, streamId, message) {
            const { id: messageId, type } = message || {};

            streamRepositoryService
              .updateCensoredStatus(visibility, streamId, messageId, type)
              .then((res) => {
                $scope.message.is_censored =
                  res.data?.message?.is_censored || false;
              })
              .catch((err) => console.log(err));
          };

          $scope.openTranscript = function (isRetry) {
            const transcriptionsFromStorage = sessionStorage.getItem(
              $scope.message.call_sid
            );
            const transcriptions = transcriptionsFromStorage
              ? JSON.parse(transcriptionsFromStorage)
              : null;
            $scope.isTranscriptionsLoading = true;
            if (!transcriptions || isRetry) {
              transcriptApi
                .getTranscriptData($scope.message.thread_message_id)
                .then(function (response) {
                  $scope.transcriptResponse = response;
                  $scope.utterances =
                    $scope.transcriptResponse.callTranscript.utterances;
                  $scope.isTranscriptionsLoading = false;
                })
                .catch(function (err) {
                  console.log(err);
                })
                .finally(function () {
                  sessionStorage.setItem(
                    $scope.message.call_sid,
                    JSON.stringify($scope.utterances)
                  );
                });
            } else {
              $scope.utterances = transcriptions;
              $scope.isTranscriptionsLoading = false;
            }
          };
        }
      ]
    };
  });
})(window.angular);
