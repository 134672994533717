/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import map from 'lodash/map';
import range from 'lodash/range';
/* global angular */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Account');

  app.directive('accountPreferencesScheduling', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/account/preferences/account-preferences-scheduling.html',
      controller: 'AccountPreferencesSchedulingController',
      scope: {
        settings: '='
      }
    };
  });

  app.controller('AccountPreferencesSchedulingController', [
    '$scope',
    '$moment',
    'timeService',
    function ($scope, $moment, timeService) {
      var self = this;

      self._generateAppointmentBufferDurationOptions = function () {
        var minutes = range(0, 61, 15);

        return map(minutes, function (minute) {
          return $moment.duration(minute, 'minutes');
        });
      };

      $scope.minimumBookingOptions = range(1, 25);
      $scope.appointmentBufferDurationOptions =
        self._generateAppointmentBufferDurationOptions();
      $scope.schedulingDayOptions = range(7, 29);
      $scope.tourDurationOptions = [
        { label: '15 MINUTES', value: 15 },
        { label: '20 MINUTES', value: 20 },
        { label: '30 MINUTES', value: 30 },
        { label: '45 MINUTES', value: 45 },
        { label: '60 MINUTES', value: 60 }
      ];

      self._initialize = function () {
        $scope.$watch('settings.all_showings', function () {
          if ($scope.settings.all_showings) {
            $scope.settings.adjacent_showing_interval =
              $scope.settings.tour_duration;
            $scope.settings.concurrent_showing_visitors =
              $scope.settings.concurrent_showing_visitors || 1;
          }
        });

        if (!isNumber($scope.settings.tour_duration)) {
          var tourDurationMoment = timeService.get(
            $scope.settings.tour_duration,
            'HH:mm:ss'
          );
          $scope.settings.tour_duration =
            tourDurationMoment.hours() * 60 + tourDurationMoment.minutes();
        }

        if (!isNumber($scope.settings.availability_buffer)) {
          if (isString($scope.settings.availability_buffer)) {
            // If availability_buffer is 24 hours (the max), the string looks like '1 day, 00:00'
            var bufferString = $scope.settings.availability_buffer;
            var bufferRegex = /day/;
            var bufferHours;

            if (bufferRegex.test(bufferString)) {
              bufferHours = 24;
            } else {
              var bufferMoment = timeService.get(bufferString, 'HH:mm:ss');
              bufferHours = bufferMoment.hours();
            }

            $scope.settings.availability_buffer = bufferHours;
          } else {
            $scope.settings.availability_buffer = 1;
          }
        }

        $scope.settings.pre_appointment_buffer =
          self._findBufferDurationFromPythonInterval(
            $scope.settings.pre_appointment_buffer
          );
        $scope.settings.post_appointment_buffer =
          self._findBufferDurationFromPythonInterval(
            $scope.settings.post_appointment_buffer
          );
      };

      $scope.setAllShowings = function (value) {
        $scope.settings.all_showings = value;
      };

      self._findBufferDurationFromPythonInterval = function (pythonInterval) {
        var momentDuration = $moment.duration(
          pythonInterval.replace(/ days?, /, '.')
        );

        var bufferDuration = find(
          $scope.appointmentBufferDurationOptions,
          function (duration) {
            return duration.toString() === momentDuration.toString();
          }
        );

        return bufferDuration
          ? bufferDuration
          : $scope.appointmentBufferDurationOptions[0];
      };

      self._initialize();
    }
  ]);
})(window.angular);
