import React from 'react';

export const PersonWithFlagIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7673 17.5C15.1372 15.7057 13.4444 14.5036 11.5427 14.5H11.5387C9.63715 14.5037 7.94455 15.7058 7.31467 17.5M5.90933 15.5587C4.44771 15.2646 2.99296 16.0929 2.5 17.5M17.5 17.5V2.5H9L11 4.5L9 6.5H17.5M11.5387 13.5C13.0574 13.5 14.2887 12.2688 14.2887 10.75C14.2887 9.23122 13.0574 8 11.5387 8C10.0199 8 8.78867 9.23122 8.78867 10.75C8.78867 12.2688 10.0199 13.5 11.5387 13.5ZM5.31667 14.5C6.42124 14.5 7.31667 13.6046 7.31667 12.5C7.31667 11.3954 6.42124 10.5 5.31667 10.5C4.2121 10.5 3.31667 11.3954 3.31667 12.5C3.31667 13.6046 4.2121 14.5 5.31667 14.5Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
