import React, { FC } from 'react';
import classnames from 'classnames';

export interface PhoneTextIconProps {
  className?: string;
  height?: string;
  strokeColor?: string;
  width?: string;
}

export const PhoneTextIcon: FC<PhoneTextIconProps> = ({
  className,
  height = '15',
  strokeColor = '#8D90A5',
  width = '15'
}) => (
  <svg
    className={classnames('phone-text-icon', className)}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.898 9.007v3.522c0 .848-.687 1.536-1.535 1.536H2.69a1.536 1.536 0 0 1-1.536-1.536V3.8c0-.849.687-1.537 1.536-1.537h1.414M1.154 11.536h6.744"
      stroke={strokeColor}
      strokeWidth="1.124"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M12.113 1.42H7.477c-.932 0-1.687.754-1.687 1.685v2.53c0 .93.755 1.685 1.687 1.685h2.107v2.108l2.108-2.108h.421c.931 0 1.686-.754 1.686-1.686V3.105c0-.931-.755-1.686-1.686-1.686Z"
      stroke={strokeColor}
      strokeWidth="1.124"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.687 4.159a.21.21 0 1 0 0 .422.21.21 0 0 0 0-.422M9.795 4.159a.21.21 0 1 0 0 .422.21.21 0 0 0 0-.422M11.902 4.159a.21.21 0 1 0 0 .421.21.21 0 0 0 0-.421"
      stroke={strokeColor}
      strokeWidth="1.124"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
