import React from 'react';

export const HelpCenterIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.125 10.125C10.125 8.7443 11.2443 7.62502 12.625 7.62502C14.0057 7.62502 15.125 8.7443 15.125 10.125C15.125 11.5057 14.0057 12.625 12.625 12.625M12.4368 14.5631L12.7864 15.0802M12.625 2.62502C9.44944 2.62044 6.5214 4.33913 4.97733 7.11402C3.43326 9.88892 3.51628 13.2831 5.19417 15.9792L2.625 21.375L8.02 18.805C11.1751 20.7675 15.2255 20.5233 18.122 18.196C21.0185 15.8687 22.1292 11.9659 20.8923 8.46218C19.6554 4.95848 16.3406 2.61805 12.625 2.62502ZM12.625 14.5C12.4524 14.5 12.3125 14.6399 12.3125 14.8125C12.3125 14.9851 12.4524 15.125 12.625 15.125C12.7976 15.125 12.9375 14.9851 12.9375 14.8125C12.9375 14.6399 12.7976 14.5 12.625 14.5Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
