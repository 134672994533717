// knock brand colors
export const red = '#ED1E79';
export const green = '#25B786';
export const yellow = '#FBB03B';
export const blue_light = '#3FA9F5';
export const blue_dark = '#103659';
export const white = '#FFFFFF';
export const gray_ultra_light = '#F8F9FA';

// primary component colors
export const color__primary = blue_light;
export const color__secondary = blue_dark;
export const color__tertiary = red;
export const container__background = gray_ultra_light;

export const text__primary_gray_dark = '#535353';
export const text__secondary_gray_medium = '#FEFEFE';
export const text__tertiary_gray_light = '#ADB5BD'; // footer text

// status colors
// material defaults in comments
export const status__info_blue = blue_light; // 33b5e5
export const status__success_green = green; // 00C851
export const status__warning_yellow = yellow; // ffbb33
export const status__danger_red = '#ff4444';
