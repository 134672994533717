import { createMuiTheme } from '@material-ui/core/styles';
import * as colors from './colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.color__primary
    },
    secondary: {
      main: colors.color__secondary
    },
    error: {
      main: colors.status__danger_red
    },
    background: {
      // default: colors.container__background
      default: colors.white
    }
  },
  typography: {
    fontFamily: "'Poppins', 'Helvetica', 'Arial', sans-serif",
    fontSize: 12,
    button: {
      textTransform: 'none',
      fontWeight: 300
    },
    caption: {
      fontSize: '.6rem'
    }
  }
});

export default theme;
