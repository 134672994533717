/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.factory('transferProspectModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openTransferProspectModal = function (prospect, newManagerId) {
        return $mdDialog.show({
          controller: 'TransferProspectController',
          templateUrl:
            '/angular/views/userInteractions/transfer-prospect-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            prospect: prospect,
            newManagerId: newManagerId
          }
        });
      };

      return self;
    }
  ]);

  const TransferProspectController = function (
    $q,
    $scope,
    $mdDialog,
    $mdToast,
    appDataService,
    prospect,
    newManagerId,
    userInteractionsService,
    $rootScope
  ) {
    var self = this;

    $scope.data = {
      teamMembers: [],
      selectedTeamMember: null
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.tryTransfer = function () {
      var promises = [];

      var newManagerId = $scope.data.selectedTeamMember.ManagerInfo.manager_id;
      var transferProspectPromise = userInteractionsService.transferProspect(
        $scope.prospect.id,
        newManagerId
      );

      promises.concat(transferProspectPromise);

      if ($scope.data.confirmProspectAndToursTransfer) {
        var transferAppointmentsPromise =
          userInteractionsService.transferProspectAppointments(
            $scope.prospect.id,
            newManagerId
          );

        promises.concat(transferAppointmentsPromise);
      }

      $q.all(promises).then(self._transferSuccess, self._transferError);
    };

    self._transferSuccess = function () {
      $mdToast.showSimple('Transferred!');
      $mdDialog.hide();
      setTimeout(() => {
        $rootScope.$emit('TransferGuestCard', newManagerId);
      }, 1000);
    };

    self._transferError = function () {
      $mdToast.showSimple('Error transferring');
    };

    self._initialize = function () {
      $scope.data.isLoading = true;
      $scope.prospect = prospect;

      self._setTeamMembers();
    };

    self._setTeamMembers = function () {
      $scope.data.teamMembers = appDataService.getTeamMembers();

      $scope.data.selectedTeamMember = find(
        $scope.data.teamMembers,
        function (member) {
          return (
            member.Manager.id ===
            (newManagerId ? newManagerId : $scope.prospect.assigned_manager_id)
          );
        }
      );
    };

    self._initialize();
  };

  TransferProspectController.$inject = [
    '$q',
    '$scope',
    '$mdDialog',
    '$mdToast',
    'appDataService',
    'prospect',
    'newManagerId',
    'userInteractionsService',
    '$rootScope'
  ];

  app.controller('TransferProspectController', TransferProspectController);
})(window.angular);
