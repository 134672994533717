import React from 'react';

export const PieLineIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49732 15L10.144 12.3533C10.2378 12.2595 10.365 12.2068 10.4976 12.2068C10.6303 12.2068 10.7575 12.2595 10.8513 12.3533L12.144 13.646C12.2378 13.7398 12.365 13.7926 12.4976 13.7926C12.6303 13.7926 12.7575 13.7398 12.8513 13.646L15.4973 11M7.49731 4.5C9.15417 4.5 10.4973 5.84315 10.4973 7.5C10.4973 9.15685 9.15417 10.5 7.49731 10.5C5.84046 10.5 4.49731 9.15685 4.49731 7.5C4.49731 5.84315 5.84046 4.5 7.49731 4.5ZM7.49731 4.5L7.49732 7.5L9.61865 9.62133M12.9973 5.5H14.9973M12.9973 7.5H14.9973M3.49731 17.5H16.4973C17.0496 17.5 17.4973 17.0523 17.4973 16.5V3.5C17.4973 2.94772 17.0496 2.5 16.4973 2.5H3.49731C2.94503 2.5 2.49731 2.94772 2.49731 3.5V16.5C2.49731 17.0523 2.94503 17.5 3.49731 17.5Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
