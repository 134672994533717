/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('CalendarReminderPopoverController', [
    '$scope',
    'propertyReportsService',
    'conversationsService',
    function ($scope, propertyReportsService, conversationsService) {
      var self = this;
      var event = $scope.$parent.event;

      $scope.data = {
        reminders: event.reminders,
        recipients: [],
        recipientsCopy: [],
        prospectCount: 0,
        residentCount: 0
      };

      $scope.openEntityStreamModal = function (entity) {
        conversationsService.openThreadById(entity.stream_id, true);
      };

      $scope.viewProspects = function () {
        var prospectIds = _.reduce(
          $scope.data.recipients,
          function (result, entity) {
            if (entity.type == 'prospect') {
              result.push(entity.id);
            }
            return result;
          },
          []
        );

        propertyReportsService.viewProspectDetails(prospectIds);
      };

      self._initialize = function () {
        var prospectIds = _.reduce(
          $scope.data.reminders,
          function (result, reminder) {
            if (reminder.hasOwnProperty('prospect_id')) {
              result.push(reminder.prospect_id);
            }
            return result;
          },
          []
        );

        var residentIds = _.reduce(
          $scope.data.reminders,
          function (result, reminder) {
            if (reminder.hasOwnProperty('resident_id')) {
              result.push(reminder.resident_id);
            }
            return result;
          },
          []
        );

        if (prospectIds.length > 0) {
          $scope.data.prospectCount = prospectIds.length;
          propertyReportsService
            .getProspectReports(
              null,
              null,
              null,
              null,
              null,
              true,
              null,
              prospectIds,
              null
            )
            .then(function (response) {
              $scope.data.recipients.push(
                ..._.map(response.data.prospects_report, self._mapProspect)
              );
            });
        }

        if (residentIds.length > 0) {
          $scope.data.residentCount = residentIds.length;
          propertyReportsService
            .getResidentReports('all', null, null, null, residentIds)
            .then(function (response) {
              $scope.data.recipients.push(
                ..._.map(response.data.resident_reports, self._mapResident)
              );
            });
        }

        $scope.data.recipientsCopy = $scope.data.recipients.slice();
      };

      self._mapProspect = function (prospect) {
        return {
          id: prospect.id,
          type: 'prospect',
          stream_id: prospect.stream_id,
          assigned_manager_id: prospect.assigned_manager_id,
          profile: {
            first_name: prospect.profile.first_name,
            last_name: prospect.profile.last_name
          },
          last_contacted_time: prospect.last_contacted_time,
          status: prospect.status
        };
      };

      self._mapResident = function (resident) {
        return {
          id: resident.id,
          type: 'resident',
          stream_id: resident.stream_id,
          assigned_manager_id: resident.assigned_manager_id,
          profile: {
            first_name: resident.profile.first_name,
            last_name: resident.profile.last_name
          },
          last_contacted_time: resident.last_contacted_time,
          status: resident.status
        };
      };

      self._initialize();
    }
  ]);
})(window.angular);
