/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('pagePreferencesService', [
    '$location',
    'apiBase',
    function ($location, apiBase) {
      var self = this;

      self.urlEncodePagePreferences = function (preferences) {
        $location.search(preferences);
      };

      self.urlDecodePagePreferences = function () {
        return $location.search();
      };

      return self;
    }
  ]);
})(window.angular);
