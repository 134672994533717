import React, { FC } from 'react';

export const SendMessageIcon: FC = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.06925 12.7218C1.51253 12.7218 1.02314 12.353 0.869644 11.8179C0.716149 11.2827 0.935673 10.7106 1.40775 10.4155L15.27 1.75003C15.6902 1.48705 16.2263 1.4979 16.6355 1.77766C17.0448 2.05743 17.2495 2.553 17.157 3.04003L14.457 17.4198C14.3654 17.9059 13.9955 18.292 13.5137 18.4042C13.032 18.5165 12.5295 18.3336 12.2325 17.938L8.325 12.7218H2.06925Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.32495 12.7217H6.44995V16.5999C6.44992 17.0623 6.70566 17.4867 7.11445 17.7027C7.52323 17.9188 8.01797 17.8909 8.39995 17.6304L10.7835 16.0059L8.32495 12.7217Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.32422 12.7218L16.6342 1.7793"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
