/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import isUndefined from 'lodash/isUndefined';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('trafficReportInfoModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function ($event, listing) {
        return $mdDialog.show({
          controller: 'TrafficReportInfoController',
          templateUrl: '/angular/views/listingIndex/traffic-report-info.html',
          parent: angular.element(document.body),
          locals: { listing: listing },
          targetEvent: $event,
          clickOutsideToClose: false, // set to false so that only $scope.close() can resolve current listing info
          escapeToClose: false // set to false so that only $scope.close() can resolve current listing info
        });
      };

      return self;
    }
  ]);

  app.controller('TrafficReportInfoController', [
    '$rootScope',
    '$scope',
    '$mdDialog',
    '$mdToast',
    'analyticsApi',
    'listing',
    function ($rootScope, $scope, $mdDialog, $mdToast, analyticsApi, listing) {
      $scope.editingSubscriber = null;
      $scope.isLoading = true;

      analyticsApi
        .getSubscribers(listing.id)
        .success(function (response) {
          $scope.subscribers = response.subscribers || [];
        })
        .finally(function () {
          $scope.isLoading = false;
        });

      $scope.close = function () {
        var response = {
          subscribers: $scope.subscribers
        };

        $mdDialog.hide(response);
      };

      $scope.saveSubscriber = function () {
        var isCreating = isUndefined($scope.editingSubscriber.id);

        if (isCreating) {
          analyticsApi
            .createSubscriber(listing.id, $scope.editingSubscriber)
            .success(function (response) {
              $scope.subscribers.push(response.subscriber);
              $scope.editingSubscriber = null;
              $mdToast.showSimple('Saved!');
            })
            .error(function (response, status) {
              if (status === 409) {
                var subscriber_id = response.subscriber.id;
                // email already exists, un-delete it
                analyticsApi
                  .updateSubscriber(
                    listing.id,
                    subscriber_id,
                    $scope.editingSubscriber
                  )
                  .success(function (updateResponse) {
                    $scope.subscribers.push(updateResponse.subscriber);

                    $scope.subscribers = uniq(
                      $scope.subscribers,
                      function (subscriber) {
                        return subscriber.AnalyticsSubscriber.email;
                      }
                    );

                    $scope.editingSubscriber = null;
                    $mdToast.showSimple('Saved!');
                  });
              }
            });
        } else {
          analyticsApi
            .updateSubscriber(
              listing.id,
              $scope.editingSubscriber.id,
              $scope.editingSubscriber
            )
            .success(function () {
              $scope.editingSubscriber = null;
              $mdToast.showSimple('Saved!');
            });
        }
      };

      $scope.editSubscriber = function (subscriber) {
        $scope.editingSubscriber = subscriber;
      };

      $scope.deleteSubscriber = function (subscriber) {
        var deletedSubscriber = cloneDeep(subscriber);
        analyticsApi
          .deleteSubscriber(listing.id, subscriber.AnalyticsSubscriber.id)
          .success(function () {
            remove($scope.subscribers, deletedSubscriber);

            var undoToast = $mdToast.simple().content('Deleted').action('Undo');

            $mdToast.show(undoToast).then(function (response) {
              if (response === 'ok') {
                analyticsApi
                  .updateSubscriber(listing.id, deletedSubscriber)
                  .success(function () {
                    $scope.subscribers.unshift(deletedSubscriber);
                    $mdToast.showSimple('Restored!');
                  });
              }
            });
          });
      };

      $scope.addNewSubscriber = function () {
        $scope.editingSubscriber = {};
      };
    }
  ]);
})(window.angular);
