import React, { FC } from 'react';
import { makeStyles, Chip, Theme } from '@material-ui/core';

import { GlobalNavLeasingTeam } from './GlobalNavHeaderNew';
import { NamedColors, white } from '@knockrentals/knock-shared-web';

interface CustomRenderOptionProps {
  option: GlobalNavLeasingTeam;
  badgeCount: number;
  callback?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  leasingTeamOption: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px'
  },

  chip: {
    background: NamedColors.carrot[600],
    color: white,
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    marginLeft: '8px',
    padding: '0 6px',
    borderRadius: '64px'
  }
}));

const CustomRenderOption: FC<CustomRenderOptionProps> = ({
  option,
  badgeCount,
  callback
}) => {
  const classes = useStyles();

  return (
    <a
      href={`/dashboard?lt=${option.publicId}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
      onClick={callback}
    >
      <div className={classes.leasingTeamOption}>
        <div>{option.name}</div>

        {badgeCount > 0 && (
          <Chip
            size="small"
            label={badgeCount}
            classes={{ root: classes.chip }}
          />
        )}
      </div>
    </a>
  );
};

export default CustomRenderOption;
