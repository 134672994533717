/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('residentsApi', [
    'apiBase',
    function (apiBase) {
      return {
        createNewResident: function (payload) {
          return apiBase.post('/residents', payload);
        },
        getResident: function (residentId) {
          return apiBase.get('/residents/' + residentId);
        },
        getResidents: function () {
          return apiBase.get('/me/residents/');
        },
        getListingResidents: function (listingId, subscribedStatus) {
          var params = {};

          if (subscribedStatus) {
            params.is_subscribed = subscribedStatus === 'subscribed';
          }

          return apiBase.get('/listing/' + listingId + '/residents', {
            params: params
          });
        },
        updateResident: function (residentId, payload) {
          return apiBase.put('/residents/' + residentId, payload);
        },
        transferResident: function (residentId, newManagerId) {
          var payload = {
            newManagerId: newManagerId
          };

          return apiBase.put('/residents/' + residentId + '/transfer', payload);
        },
        deleteResident: function (residentId) {
          return apiBase.delete('/residents/' + residentId);
        },
        subscribeResident: function (residentId, propertyUnitId) {
          var payload = {
            property_unit_id: propertyUnitId
          };

          return apiBase.put(
            '/residents/' + residentId + '/subscribe',
            payload
          );
        },
        unsubscribeResident: function (residentId) {
          return apiBase.delete('/residents/' + residentId + '/subscription');
        },
        getProspectSMSOptInUrl: function (residentId) {
          return apiBase.get(`/residents/${residentId}/sms-consent-url`);
        },
        getResidentSMSConsentData: function (residentStreamId) {
          return apiBase.get('/residents/' + residentStreamId + '/sms-consent');
        },
        grantResidentSMSConsent: function (
          residentStreamId,
          consentOrigin,
          residentPhoneNumber
        ) {
          var payload = {
            consent_origin: consentOrigin,
            phone_number: residentPhoneNumber
          };

          return apiBase.post(
            '/residents/' + residentStreamId + '/sms-consent',
            payload
          );
        },
        revokeSMSConsent: function (residentStreamId) {
          return apiBase.delete(
            '/residents/' + residentStreamId + '/sms-consent'
          );
        },
        declineSMSConsent: function (residentStreamId) {
          return apiBase.put(
            '/residents/' + residentStreamId + '/sms-consent/decline'
          );
        },
        sendBulkResidentSMSConsentRequests: function (residentIds) {
          var payload = {
            resident_ids: residentIds
          };

          return apiBase.post('/residents/request-sms-consent', payload);
        },
        addNewResidentPackages: function (residentId, packagesData) {
          var payload = {
            packages: packagesData
          };

          return apiBase.post(
            '/residents/' + residentId + '/packages',
            payload
          );
        },
        claimResidentPackage: function (residentId, packageId, claimedBy) {
          var payload = {
            claimedBy: claimedBy
          };

          return apiBase.post(
            '/residents/' + residentId + '/packages/' + packageId + '/claim',
            payload
          );
        },
        addNewResidentActivity: function (
          residentId,
          activityType,
          activityMessage,
          reminderTime
        ) {
          var payload = {
            type: activityType,
            message: activityMessage,
            reminder_time: reminderTime
          };

          return apiBase.post(
            '/residents/' + residentId + '/activity',
            payload
          );
        },
        updateResidentReminder: function (
          residentId,
          reminderId,
          date,
          description
        ) {
          var payload = {
            date: date,
            description: description
          };

          return apiBase.put(
            '/residents/' + residentId + '/reminders/' + reminderId,
            payload
          );
        },
        deleteResidentReminder: function (residentId, reminderId) {
          return apiBase.delete(
            '/residents/' + residentId + '/reminders/' + reminderId
          );
        },
        getResidentEngagementSettings: function (residentId) {
          return apiBase.get(`/residents/${residentId}/engagement/settings`);
        },
        getResidentsData: function (
          offset,
          rowsPerPage,
          filters,
          sort_column,
          sort_direction
        ) {
          const {
            stage,
            building,
            undecidedOnly,
            propertyId,
            residentIds,
            batchId
          } = filters;
          const sortDirection = sort_direction || 'asc';
          let url = `/me/property-reports/v2/residents?offset=${offset}&per_page=${rowsPerPage}&undecided_only=${undecidedOnly}&sort_column=${sort_column}&sort_direction=${sortDirection}&property=${propertyId}&resident_ids=${residentIds}`;
          if (stage) {
            url = `/me/property-reports/v2/residents?offset=${offset}&per_page=${rowsPerPage}&undecided_only=${undecidedOnly}&sort_column=${sort_column}&sort_direction=${sortDirection}&stage=${stage}&property=${propertyId}&resident_ids=${residentIds}`;
          }

          if (building) {
            url = `/me/property-reports/v2/residents?offset=${offset}&per_page=${rowsPerPage}&undecided_only=${undecidedOnly}&sort_column=${sort_column}&sort_direction=${sortDirection}&building=${building}&property=${propertyId}&resident_ids=${residentIds}`;
          }

          if (building && stage) {
            url = `/me/property-reports/v2/residents?offset=${offset}&per_page=${rowsPerPage}&undecided_only=${undecidedOnly}&sort_column=${sort_column}&sort_direction=${sortDirection}&building=${building}&stage=${stage}&property=${propertyId}&resident_ids=${residentIds}`;
          }
          if (batchId) {
            url += `&batch_id=${batchId}`;
          }

          return apiBase.get(url);
        }
      };
    }
  ]);
})();
