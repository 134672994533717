/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('transcriptApi', [
    '$auth',
    'ANALYTICS_GRAPHQL_API',
    'TranscriptQueryService',
    function ($auth, ANALYTICS_GRAPHQL_API, TranscriptQueryService) {
      return {
        getTranscriptData: async function (threadMessageId) {
          var config = {
            operationName: 'GetCallTranscript',
            query: TranscriptQueryService.getTranscriptQuery(),
            variables: {
              threadId: threadMessageId
            }
          };

          try {
            const resp = await fetch(ANALYTICS_GRAPHQL_API, {
              method: 'POST',
              body: JSON.stringify(config),
              headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${$auth.getToken()}`
              }
            });

            if (!resp.ok) {
              throw new Error('catch error throw after');
            }

            const { data } = await resp.json();

            return data;
          } catch (e) {
            throw new Error('Unable to fetch transcript data');
          }
        }
      };
    }
  ]);
})(window.angular);
