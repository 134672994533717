/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
/**
 * Bootstrap / Angular Dependencies
 */
require('@priotas/angular-bootstrap-slider');

require('satellizer/satellizer');

require('angular-resource');
require('angular-animate');
require('angular-touch');
require('angular-route');
require('angular-messages');
require('angular-local-storage');
require('angular-bootstrap-checkbox');
/** angular-simple-logger is required in angular-google-maps */
require('angular-simple-logger');
require('angular-google-maps');
require('legacy-third-party-modules/angular-payments/lib/angular-payments');
require('angular-ui-bootstrap/ui-bootstrap-tpls');
require('ng-mask/dist/ngMask');
require('angular-aria');
require('angular-material/angular-material');
require('angular-smart-table/dist/smart-table');
require('angular-audio/app/angular.audio');
require('angular-location-update');
require('isteven-angular-multiselect/isteven-multi-select');
require('angular-zendesk-widget');
require('legacy-third-party-modules/angular-lodash-loader/src/nglodash');
require('legacy-third-party-modules/angular-momentjs');
require('pusher-angular/lib/pusher-angular');

/**
 * Text Angular / Rangy
 * Text Angular depends on rangy.
 */
window.rangy = require('rangy');
require('rangy/lib/rangy-classapplier');
require('rangy/lib/rangy-highlighter');
require('rangy/lib/rangy-selectionsaverestore');
require('rangy/lib/rangy-serializer');
require('rangy/lib/rangy-textrange');

/**
 * lowercase polyfill required for textAngular as it has not been maintained up to angular 1.7.8
 * @param text
 */
angular.lowercase = angular.$$lowercase;
require('textangular/dist/textAngular-sanitize');
require('textangular/dist/textAngularSetup');
require('textangular/dist/textAngular');

require('ng-file-upload/dist/ng-file-upload');
require('angulartics/src/angulartics');
require('angulartics/src/angulartics-ga');
require('angulartics/src/angulartics-gtm');
require('angulartics/src/angulartics-scroll');
require('ng-clip');
require('ng-device-detector');

require('legacy-third-party-modules/angular-strap.min');
require('legacy-third-party-modules/angular-strap.tpl.min');

/*Sortable*/
require('sortablejs/ng-sortable');
