/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('residentService', [
    '$rootScope',
    '$moment',
    'residentsApi',
    'addResidentModalService',
    function ($rootScope, $moment, residentsApi, addResidentModalService) {
      var self = this;

      self.events = {
        residentReloaded: 'residentReloaded',
        residentUpdated: 'residentUpdated'
      };

      self.renewalThresholdDays =
        $rootScope.appPreferences.company.resident_renewal_outreach_threshold;

      self.statusLabels = {
        current: 'Current',
        former: 'Former',
        'on-notice': 'On Notice',
        renewing: 'Renewing',
        transfer: 'Transferring',
        undecided: 'Undecided'
      };

      self.withinThreshold = function (resident) {
        return self.getDaysUntilLeaseEnd(resident) <= self.renewalThresholdDays;
      };

      self.getDaysUntilLeaseEnd = function (resident) {
        return $moment(resident.lease_end).diff($moment(), 'days');
      };

      self.renderStatusFor = function (resident) {
        if (resident.status === 'current' && self.withinThreshold(resident)) {
          return 'Undecided';
        }

        return self.statusLabels[resident.status];
      };

      self.updateResident = function (resident) {
        return residentsApi
          .updateResident(resident.id, resident)
          .success(function (response) {
            $rootScope.$emit(self.events.residentUpdated, response.resident);
          });
      };

      self.deleteResident = function (resident) {
        return residentsApi.deleteResident(resident.id).success(function () {
          $rootScope.$emit(self.events.residentDeleted, resident);
        });
      };

      self.addNewResident = function (propertyId, propertyUnit) {
        return addResidentModalService.openNewResidentModal(
          null,
          propertyId,
          propertyUnit
        );
      };

      self.saveNewResident = function (resident) {
        return residentsApi
          .createNewResident(resident)
          .success(function (response) {
            $rootScope.$emit(self.events.residentUpdated, response.resident);
          });
      };

      return self;
    }
  ]);
})(window.angular);
