/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import filter from 'lodash/filter';
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('propertyInfo', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/listingDetails/property-info.html',
      scope: {
        property: '='
      },
      controller: [
        '$scope',
        'petsEditorService',
        'listingApi',
        'communityApi',
        '$routeParams',
        '$window',
        function (
          $scope,
          petsEditorService,
          listingApi,
          communityApi,
          $routeParams,
          $window
        ) {
          var self = this;

          $scope.openApplicationURL = function () {
            var lowerCaseDomain = $scope.applicationURL.toLowerCase();
            var applicationUrl = $scope.applicationURL;

            var startsWithHttp = lowerCaseDomain.indexOf('http://') === 0;
            var startsWithHttps = lowerCaseDomain.indexOf('https://') === 0;

            if (!startsWithHttp && !startsWithHttps) {
              applicationUrl = 'http://' + applicationUrl;
            }

            $window.open(applicationUrl, '_blank');
          };

          self._initialize = function () {
            if ($scope.property.community_id) {
              self._getCommunity($scope.property.community_id);
            } else {
              $scope.videos = $scope.property.videos;
              self._getApplication($routeParams.listingId);
            }

            if ($routeParams.listingId) {
              self._getListingInfoStatus($routeParams.listingId);
            }

            $scope.amenityStrings = {
              Appliances: self._createAmenityString($scope.property.appliances),
              Furnishing: self._createAmenityString($scope.property.furnishing),
              'Heating/cooling': self._createAmenityString(
                $scope.property.amenities.heatingAndCooling
              ),
              Wiring: self._createAmenityString(
                $scope.property.amenities.wiring
              ),
              Views: self._createAmenityString($scope.property.views),
              'Security & Access': self._createAmenityString(
                $scope.property.amenities.security
              ),
              'Facilities & Recreation': self._createAmenityString(
                $scope.property.amenities.recreation
              ),
              Exterior: self._createAmenityString(
                $scope.property.amenities.exterior
              ),
              'Additional features': self._createAmenityString(
                $scope.property.amenities.additional
              )
            };

            $scope.petsString = petsEditorService.reduceRenterInfoPetTypes(
              $scope.property.pets.allowed
            );
            $scope.parkingString = self._createAmenityString(
              $scope.property.parking.types
            );
            $scope.laundryString = self._createAmenityString(
              $scope.property.laundry.types
            );
            $scope.coveredUtilitiesString = self._createAmenityString(
              $scope.property.utilities.types,
              'included'
            );
            $scope.leasingOptionsString = self._createLeasingOptionsString(
              $scope.property.leasing.terms.leaseLengths
            );
          };

          self._getListingInfoStatus = function (listingId) {
            return listingApi
              .getListingInfoStatus(listingId)
              .success(function (response) {
                $scope.isPending = response.status === 'pending';
              });
          };

          self._getApplication = function (listingId) {
            listingApi
              .getListingApplication(listingId)
              .success(function (response) {
                if (response.application) {
                  $scope.applicationInstructions =
                    response.application.instructions || '';
                  $scope.applicationURL = response.application.link || '';
                } else {
                  $scope.applicationInstructions = '';
                  $scope.applicationURL = '';
                }
              });
          };

          self._getCommunity = function (communityId) {
            communityApi.getCommunity(communityId).success(function (response) {
              $scope.applicationInstructions =
                response.community.leasing.application.instructions || '';
              $scope.applicationURL =
                response.community.leasing.application.link || '';
              $scope.videos = response.community.videos;
            });
          };

          self._createAmenityString = function (types, availableString) {
            return map(
              filter(types, availableString || 'available'),
              'name'
            ).join(', ');
          };

          self._createLeasingOptionsString = function (leaseLengths) {
            var availableOptions = filter(leaseLengths, 'isAvailable');
            var stringParts = map(availableOptions, function (option) {
              var part = option.leaseLength;
              if (option.lengthUnit) {
                part += ' ' + option.lengthUnit;
              }

              return part;
            });

            return stringParts.join(', ');
          };

          self._initialize();
        }
      ]
    };
  });

  // REFERENCE: https://stackoverflow.com/a/24291287
  app.filter('linkyUnsanitized', [
    '$sanitize',
    function ($sanitize) {
      var LINKY_URL_REGEXP =
          /((ftp|https?):\/\/|(mailto:)?[A-Za-z0-9._%+-]+@)\S*[^\s.;,(){}<>]/,
        MAILTO_REGEXP = /^mailto:/,
        APPOINTMENT_REQUEST_REDIRECT_REGEXP = /(\/manager\/calendar)/;

      return function (text, target) {
        function addText(text) {
          if (!text) {
            return;
          }
          html.push(text);
        }

        function addLink(url, text) {
          html.push('<a ');
          if (angular.isDefined(target)) {
            html.push('target="');
            html.push(target);
            html.push('" ');
          }
          html.push('href="');
          html.push(url);
          html.push('">');
          // This if statement adds a "click here" action link for appointment request notifications
          // where manager users have manual scheduling turned on. This shouldn't impact any other
          // notification workflows
          if (text.search(APPOINTMENT_REQUEST_REDIRECT_REGEXP) !== -1) {
            addText('click here');
          } else {
            addText(text);
          }
          html.push('</a>');
        }

        if (!text) {
          return text;
        }

        var match;
        var raw = text;
        var html = [];
        var url;
        var i;

        while ((match = raw.match(LINKY_URL_REGEXP))) {
          // We can not end in these as they are sometimes found at the end of the sentence
          url = match[0];
          // if we did not match ftp/http/mailto then assume mailto
          if (match[2] == match[3]) url = 'mailto:' + url;
          i = match.index;
          addText(raw.substr(0, i));
          addLink(url, match[0].replace(MAILTO_REGEXP, ''));
          raw = raw.substring(i + match[0].length);
        }

        addText(raw);
        return html.join('');
      };
    }
  ]);
})(window.angular);
