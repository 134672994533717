/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import some from 'lodash/some';
import every from 'lodash/every';
import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('AppointmentRequirementsModalController', [
    '$q',
    '$scope',
    '$modalInstance',
    'ProfileService',
    'listingId',
    'requirements',
    'listingApi',
    'renterApi',
    'localCache',
    function (
      $q,
      $scope,
      $modalInstance,
      ProfileService,
      listingId,
      requirements,
      listingApi,
      renterApi,
      localCache
    ) {
      var self = this;

      self.initialize = function () {
        $scope.isInitializing = true;

        renterApi
          .getRenterInfo()
          .success(function (response) {
            $scope.renterInfo = response.renter_info;

            if (isEmpty($scope.renterInfo.pets)) {
              $scope.renterInfo.pets = {};
            }

            self._setupForm();
          })
          .finally(function () {
            $scope.isInitializing = false;
            if ($scope.passedRequirements) {
              $modalInstance.close('passed');
            }
          });
      };

      self._setupForm = function () {
        $scope.reasonsByPolicy = groupBy(requirements, 'policy');
        $scope.needs = {};

        if (
          'phone_numbers' in $scope.reasonsByPolicy &&
          isEmpty($scope.renterInfo.phone)
        ) {
          $scope.needs.phoneNumber = true;
        }

        if ('income_multiplier' in $scope.reasonsByPolicy) {
          var incomePolicy = $scope.reasonsByPolicy.income_multiplier[0];
          $scope.minimumMonthlyIncome =
            incomePolicy.rent * incomePolicy.multiplier;
          $scope.incomeMultiplier = incomePolicy.multiplier;
          $scope.renterCanAffordListing = localCache.get(
            self.getCanAffordCacheKey()
          );
          $scope.needs.monthlyIncome = !$scope.renterCanAffordListing;
        }

        if ('pet_policy' in $scope.reasonsByPolicy) {
          $scope.renterInfo.pets = !isEmpty($scope.renterInfo.pets)
            ? $scope.renterInfo.pets
            : { none: true };
          var renterPets = omit($scope.renterInfo.pets, 'notes');

          var isFalsey = function (value) {
            return value === false;
          };

          var hasProhibitedPet = function (petPolicy) {
            var prohibitedPet = petPolicy.pet;
            return (
              !(prohibitedPet in renterPets) ||
              renterPets[prohibitedPet] === true
            );
          };

          var invalidNone = every(renterPets, isFalsey);
          $scope.needs.petInfo =
            some($scope.reasonsByPolicy.pet_policy, hasProhibitedPet) ||
            invalidNone;

          $scope.$watch(
            'renterInfo.pets',
            function (newRenterPets, oldRenterPets) {
              renterPets = omit(newRenterPets, 'notes');
              $scope.appointmentRequirementsForm.$invalid = every(
                renterPets,
                isFalsey
              );

              // prevent infinite iterations
              if (newRenterPets.none !== oldRenterPets.none) {
                return;
              }

              $scope.renterInfo.pets.none = every(
                renterPets,
                function (hasPet, pet) {
                  return pet === 'none' || hasPet === false;
                }
              );
            },
            true
          );

          $scope.clearSelectedPets = function () {
            $scope.renterInfo.pets.cats = false;
            $scope.renterInfo.pets.small_dogs = false;
            $scope.renterInfo.pets.large_dogs = false;
          };
        }

        if ('criminal_check' in $scope.reasonsByPolicy) {
          $scope.needs.criminalCheck = $scope.renterInfo.is_criminal !== false;
          $scope.renterInfo.is_criminal =
            $scope.renterInfo.is_criminal || false;
        }

        if ('eviction_check' in $scope.reasonsByPolicy) {
          $scope.needs.evictionCheck = $scope.renterInfo.was_evicted !== false;
          $scope.renterInfo.was_evicted =
            $scope.renterInfo.was_evicted || false;
        }

        if ('move_dates' in $scope.reasonsByPolicy) {
          $scope.needs.moveDate = isEmpty($scope.renterInfo.target_move_date);
        }

        $scope.passedRequirements = !some($scope.needs);
      };

      $scope.clearErrorMessage = function () {
        $scope.errorMessage = null;
      };

      $scope.dismiss = function () {
        $modalInstance.dismiss('closed');
      };

      $scope.saveAppointmentRequirements = function () {
        if ($scope.renterCanAffordListing) {
          $scope.renterInfo.income = $scope.minimumMonthlyIncome;
          localCache.put(self.getCanAffordCacheKey(), true, 3600, 'seconds');
        } else {
          $scope.renterInfo.income = 0;
        }

        $scope.isSavingInfo = true;

        $scope.errorMessage = null;

        ProfileService.updateProfile($scope.renterInfo)
          .success(function () {
            $modalInstance.close('saved');
          })
          .error(function () {
            $scope.errorMessage = "We're having a problem saving your info.";
          })
          .finally(function () {
            $scope.isSavingInfo = false;
          });
      };

      self.getCanAffordCacheKey = function () {
        return 'canAfford-' + listingId;
      };

      self.initialize();
    }
  ]);
})();
