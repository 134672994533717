/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Voice');

  app.factory('voiceApi', [
    'apiBase',
    function (apiBase) {
      return {
        placeCall: function (recipientType, recipientId, streamId) {
          var payload = {
            recipient_type: recipientType,
            recipient_id: recipientId,
            stream_id: streamId
          };

          return apiBase.post('/relay/voice/calls', payload);
        },
        endCall: function (callSid) {
          return apiBase.post('/relay/voice/calls/hangup/' + callSid);
        },
        getCallStatus: function (callSid) {
          return apiBase.get('/relay/voice/calls/status/' + callSid);
        },
        generateVoiceClientToken: function () {
          return apiBase.post('/relay/voice/token');
        },
        getClientHoldStatus: function () {
          return apiBase.get('/relay/voice/calls/browser-client-hold');
        }
      };
    }
  ]);
})(window.angular);
