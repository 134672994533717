/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('propertySelect', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospects/property-select.html',
      controller: 'PropertySelectController',
      scope: {
        propertyId: '=',
        teamMembers: '=?',
        excludeDeleted: '=?'
      }
    };
  });

  const PropertySelectController = function (
    $scope,
    $q,
    appDataService,
    $interpolate,
    teamApi
  ) {
    var self = this;

    $scope.viewState = {
      properties: {}
    };

    self._initialize = function () {
      $scope.teamMembers = appDataService.getTeamMembers();

      var properties = appDataService.getProperties();

      var mfProperties = map(
        filter(properties, function (property) {
          return property.ManagerCommunity;
        }),
        self._mapMfProperty
      );

      var sfProperties = map(
        filter(properties, function (property) {
          return property.ManagerListing && !property.ManagerCommunity;
        }),
        self._mapSfProperty
      );

      $scope.viewState.properties = {
        multiFamily: mfProperties,
        singleFamily: reduce(
          sfProperties,
          function (result, property) {
            result[property.manager.Manager.id] = result[
              property.manager.Manager.id
            ] || { manager: property.manager, properties: [] };
            result[property.manager.Manager.id].properties.push(property);

            return result;
          },
          {}
        )
      };

      var matchingPropertyResult = find(properties, function (propertyResult) {
        return propertyResult.Property.id === $scope.propertyId;
      });

      $scope.property = matchingPropertyResult
        ? matchingPropertyResult.property
        : null;
    };

    self._mapMfProperty = function (property) {
      var propertyManager = self._getTeamMemberByManagerId(
        property.Property.owning_manager_id
      );

      return {
        manager: propertyManager,
        title: property.Property.data.location.name,
        id: property.Property.id,
        communityId: property.ManagerCommunity.community_id,
        type: 'mf'
      };
    };

    self._mapSfProperty = function (property) {
      var propertyManager = self._getTeamMemberByManagerId(
        property.Property.owning_manager_id
      );

      return {
        manager: propertyManager,
        title: self._getSingleFamilyListingTitle(property.Property.data),
        id: property.Property.id,
        listingId: property.ManagerListing.listing_id,
        type: 'sf'
      };
    };

    self._getTeamMemberByManagerId = function (managerId) {
      return find($scope.teamMembers, function (member) {
        return member.Manager.id === managerId;
      });
    };

    self._getCommunityListingTitle = function (listing) {
      var hasUnit =
        listing.location.address.unit &&
        listing.location.address.unit !== 'None';

      var template = hasUnit
        ? '{{monthlyRent | noFractionCurrency}} - Unit {{unit}} - {{name}}'
        : '{{monthlyRent | noFractionCurrency}} - {{bedrooms | bedrooms}} - {{name}}';

      var titleExp = $interpolate(template);

      return titleExp({
        monthlyRent: listing.leasing.monthlyRent,
        unit: listing.location.address.unit,
        name: listing.floorplan.name,
        bedrooms: listing.floorplan.bedrooms
      });
    };

    self._getSingleFamilyListingTitle = function (listing) {
      var titleExp = $interpolate(
        '{{monthlyRent | noFractionCurrency}} - {{bedrooms | bedrooms}} - {{address}}'
      );

      return titleExp({
        monthlyRent: listing.leasing.monthlyRent,
        bedrooms: listing.floorplan.bedrooms,
        address: listing.location.address.raw
      });
    };

    self._initialize();
  };

  PropertySelectController.$inject = [
    '$scope',
    '$q',
    'appDataService',
    '$interpolate',
    'teamApi'
  ];

  app.controller('PropertySelectController', PropertySelectController);
})(window.angular);
