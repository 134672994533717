import React from 'react';

export const MapPinIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.375 8.5V4.875C20.3751 4.36376 20.0639 3.904 19.5892 3.71416L14.5892 1.71416C14.2912 1.59499 13.9588 1.59499 13.6608 1.71416L8.33917 3.84333C8.0412 3.9625 7.70881 3.9625 7.41084 3.84333L2.48251 1.87166C2.28941 1.79427 2.07046 1.81803 1.89846 1.93504C1.72646 2.05205 1.62395 2.24697 1.62501 2.455V14.4325C1.62492 14.9437 1.93616 15.4035 2.41084 15.5933L7.41084 17.5933C7.70881 17.7125 8.0412 17.7125 8.33917 17.5933L10.74 16.6325"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.875 3.9325V17.6825"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.125 1.625V7.875"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.625 13.8742C16.7976 13.8742 16.9375 14.0141 16.9375 14.1867"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3125 14.1867C16.3125 14.0141 16.4524 13.8742 16.625 13.8742"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.625 14.5C16.4524 14.5 16.3125 14.3601 16.3125 14.1875"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9375 14.1867C16.9375 14.3593 16.7976 14.4992 16.625 14.4992"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.625 10.4367C18.6961 10.4367 20.375 12.1156 20.375 14.1867C20.375 15.7875 18.135 18.8333 17.1175 20.135C16.9991 20.2866 16.8174 20.3752 16.625 20.3752C16.4326 20.3752 16.2509 20.2866 16.1325 20.135C15.115 18.8342 12.875 15.7875 12.875 14.1867C12.875 12.1156 14.5539 10.4367 16.625 10.4367V10.4367Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
