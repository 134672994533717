/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('PastNotificationsController', [
    '$scope',
    'notificationsApi',
    'notificationsService',
    function ($scope, notificationsApi, notificationsService) {
      var self = this;

      self.lastPage = 0;
      $scope.maxResultsPerPage = 25;

      $scope.notifications = [];

      $scope.nextPage = function () {
        self.lastPage++;
        self._getPage();
      };

      self._getPage = function () {
        $scope.isLoadingPage = true;
        notificationsApi
          .getPagedNotifications(self.lastPage, $scope.maxResultsPerPage)
          .success(self._getPageSuccess)
          .error(self._getPageError)
          .finally(function () {
            $scope.isLoadingPage = false;
          });
      };

      self._getPageSuccess = function (response) {
        if (!response.notifications || response.notifications.length <= 0) {
          $scope.noMoreResults = true;
          return;
        }

        var pagedNotifications = map(
          response.notifications,
          function (rawNotification) {
            return notificationsService.createNotificationModel(
              rawNotification
            );
          }
        );

        $scope.notifications = $scope.notifications.concat(pagedNotifications);
      };

      self._getPageError = function () {
        $scope.pageLoadError =
          'There was an error loading more results. Please try again later.';
      };

      self._getPage();
    }
  ]);
})();
