import React, { FC, useEffect, useState } from 'react';
import { Drawer, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import dayjs, { Dayjs } from 'dayjs';

import {
  Box,
  Button,
  makeStyles,
  Text,
  ThemeProvider,
  white,
  Tooltip,
  NamedColors
} from '@knockrentals/knock-shared-web';
import { colors } from '../../commonStyles/commonStyles';
import AvailabilityTable from './AvailabilityTable';
import {
  BuildingType,
  FilterOption,
  MenuOption,
  PropertyResponse,
  ProspectType,
  RealPageIntegrationPreferences,
  UnitType
} from './types';
import UnitLinkAlert from './UnitLinkAlert';
import {
  availDateCheck,
  checkBedValueWithUnit,
  checkFloorplanMatch,
  checkUnitValueInBudget,
  optionLabelSelector,
  transformedFilterState
} from './Utils';
import {
  bathsFilterOptions,
  bedFilterOptions,
  matchFilterOptions,
  noticeFilterOptions,
  occupancyFilterOptions
} from './Filters';
import AvailabilityFilters from './AvailabilityFilters';
import SuggestedMatches from './SuggestedMatches';
import CreateQuotePromptModal from './CreateQuotePromptModal';
import CreateReferralModal from './CreateReferralModal';
import { getEmptyUnit } from '../../../utilities/propertyUtilities';
import { Property } from '../../LeasingBinder/models';
import { QuoteSummary } from '../../CreateQuote/QuoteSummary';
import { ConnectedProfile, Quote } from '../../../ts/models';
import { ShareBrochureIcon } from '../../icons/ShareBrochureIcon';
import { InactiveShareBrochureIcon } from '../../icons/InactiveShareBrochureIcon';

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px',
    width: '100%',
    flex: 1,
    '& .MuiPaper-root': {
      '@media (min-height:700px) and (max-height:800px)': {
        maxHeight: '95%'
      },
      '@media (min-height:900px) and (max-height:900px)': {
        maxHeight: '88%'
      },
      '@media (min-height:800px) and (max-height:900px)': {
        maxHeight: '97%'
      },
      '& .MuiTableContainer-root': {
        '@media (min-height:600px) and (max-height:700px)': {
          minHeight: '300px',
          marginBottom: '10px'
        },
        '@media (min-height:700px) and (max-height:800px)': {
          maxHeight: '100%'
        },
        '@media (min-height:800px) and (max-height:900px)': {
          maxHeight: '97%'
        }
      }
    }
  },

  containerWithUnits: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px',
    flex: 1,
    '@media (min-height:600px) and (max-height:800px)': {
      overflowY: 'scroll'
    },
    '& .MuiPaper-root': {
      '@media (min-height:700px) and (max-height:800px)': {
        maxHeight: '90%'
      },
      '@media (min-height:900px) and (max-height:900px)': {
        maxHeight: '95%'
      },
      '& .MuiTableContainer-root': {
        '@media (min-height:600px) and (max-height:700px)': {
          minHeight: '280px',
          marginBottom: '50px'
        },
        '@media (min-height:700px) and (max-height:800px)': {
          minHeight: '280px',
          marginBottom: '50px'
        },
        '@media (min-height:800px) and (max-height:900px)': {
          maxHeight: '95%',
          marginBottom: '5px'
        }
      }
    }
  },
  paper: {
    maxWidth: '1460px',
    '@media (max-width: 600px)': {
      maxWidth: '450px'
    },
    '@media (min-width: 601px) and (max-width: 960px)': {
      maxWidth: '640px'
    },
    '@media (min-width: 961px) and (max-width: 1280px)': {
      maxWidth: '960px'
    },
    '@media (min-width: 1281px) and (max-width: 1440px)': {
      maxWidth: '1340px'
    },
    '@media (min-width: 1441px)': {
      maxWidth: '1600px'
    }
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px'
  },
  title: {
    color: colors.defaultText,
    fontSize: '18px',
    lineHeight: '24px',
    textTransform: 'none',
    fontWeight: 600,
    marginTop: '5px'
  },
  availabilityBackButton: {
    color: colors.defaultText,
    fontSize: '18px',
    lineHeight: '24px',
    textTransform: 'none',
    fontWeight: 600
  },
  icon: {
    color: colors.secondaryText
  },
  alertBox: {
    position: 'absolute',
    zIndex: 5,
    justifyContent: 'center',
    marginLeft: '330px',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    borderRadius: '10px',
    '& .MuiAlert-icon': {
      marginTop: '5px'
    }
  },
  alertTitle: {
    fontWeight: 600,
    marginTop: '5px'
  },
  closeButton: {
    padding: '6px 12px 10px 12px'
  },
  batchChip: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '40px'
  },
  chipStyle: {
    padding: '4px'
  },
  footer: {
    boxShadow: '0 -4px 16px -2px #18274B05, 0 -2px 12px -4px #18274B0A',
    padding: '16px 24px 16px 24px',
    position: 'sticky',
    bottom: 0,
    width: '100%',
    backgroundColor: white,
    borderTop: `2px solid ${NamedColors.slate[200]}`,
    '@media (max-height: 800px)': {},
    zIndex: 50
  },
  footerWithUnits: {
    boxShadow: '0 -4px 16px -2px #18274B05, 0 -2px 12px -4px #18274B0A',
    padding: '16px 24px 16px 24px',
    position: 'sticky',
    bottom: 0,
    width: '100%',
    backgroundColor: white,
    borderTop: `2px solid ${NamedColors.slate[200]}`,
    '@media (min-height:700px) and (max-height:800px)': {
      position: 'fixed'
    },
    '@media (min-height:600px) and (max-height:700px)': {
      position: 'fixed'
    },
    zIndex: 50
  },

  shareBroucherButton: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '4px',
    color: NamedColors.denim[600],
    '&:hover': {
      backgroundColor: '#697ff714',
      boxShadow: 'none'
    }
  },

  disabledShareBroucherButton: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none'
  },
  buttonWrap: {
    display: 'inline-block',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: `${NamedColors.slate[50]} !important`,
      boxShadow: 'none'
    }
  },
  tooltip: {
    fontSize: '10px'
  }
});

interface AvailabilityDrawerProps {
  open: boolean;
  onClose: (isBackButtonClick: boolean) => void;
  currentProperty: Property;
  getUnitsApi: any;
  showAvailabilityBackButton?: boolean;
  prospect: ProspectType;
  getUnitLink: any;
  properties: PropertyResponse[];
  vendorProspectId: number;
  isConnectedProfilesEnabled: boolean;
  hasRealPageIntegration: boolean;
  prospectIntegrationsApi: any;
  unitQuotesService: any;
  createQuote: (unit: UnitType, prospectId?: number) => void;
  attachQuoteToMessage: (data: any) => void;
  isSendQuoteDisabled: boolean;
  attachBrochure: (propertyId: number) => void;
  closeLeasingBinder: () => void;
  connectedProfiles: any;
}

const AvailabilityDrawer: FC<AvailabilityDrawerProps> = ({
  open,
  onClose,
  currentProperty,
  getUnitsApi,
  showAvailabilityBackButton,
  prospect,
  getUnitLink,
  properties,
  vendorProspectId,
  isConnectedProfilesEnabled,
  hasRealPageIntegration,
  prospectIntegrationsApi,
  unitQuotesService,
  createQuote,
  attachQuoteToMessage,
  isSendQuoteDisabled,
  attachBrochure,
  closeLeasingBinder,
  connectedProfiles: connectedProfs
}) => {
  const classes = useStyles();

  const defaultFilters = [
    {
      id: 'startDate',
      type: 'Avail Date',
      value: dayjs().toDate(),
      label: 'Start Date'
    },
    {
      id: 'endDate',
      type: 'Avail Date',
      value: dayjs().add(2, 'month').toDate(),
      label: 'End Date'
    },
    { id: 'range', type: 'Avail Date', value: '+2month', label: 'Range' }
  ];

  const getSelectedPropertyDetails = () => {
    const property = properties?.find((property) => {
      return (
        property?.ManagerCommunity?.property_id === currentProperty.property_id
      );
    });

    return {
      label: property?.Property?.data?.location.name ?? '',
      value:
        property?.ManagerCommunity.property_id?.toString() ??
        currentProperty.property_id.toString(),
      id:
        property?.ManagerCommunity?.property_id?.toString() ??
        currentProperty.property_id.toString(),
      type: 'Property'
    };
  };

  const [units, setUnits] = useState<UnitType[]>([]);
  const [buildings, setBuildings] = useState<BuildingType>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [unitName, setUnitName] = useState<string>();
  const [filteredData, setFilteredData] = useState<UnitType[]>([...units]);
  const [rows, setRows] = useState<UnitType[]>([...units]);
  const [currentUnit, setCurrentUnit] = useState<UnitType>(getEmptyUnit());
  const [connectedProfiles, setConnectedProfiles] = useState<
    ConnectedProfile[]
  >([]);

  const [createQuotePromptOpen, setCreateQuotePromptOpen] =
    useState<boolean>(false);
  const [createReferralModalOpen, setCreateReferralModalOpen] =
    useState<boolean>(false);
  const [realPageIntegrationPreferences, setRealPageIntegrationPreferences] =
    useState<RealPageIntegrationPreferences>({
      pmcId: 0,
      siteId: 0,
      unifiedQuotes: false
    });
  const [isQuoteSummaryModalOpen, setIsQuoteSummaryModalOpen] =
    useState<boolean>(false);
  const [unifiedQuote, setUnifiedQuote] = useState<any>({});
  const [quoteErrorMessage, setQuoteErrorMessage] = useState<string>('');
  const [filterState, setFilterState] = useState<MenuOption[]>([
    ...defaultFilters
  ]);
  const [propertyList, setPropertyList] = useState<MenuOption[]>([
    {
      label: getSelectedPropertyDetails()?.label ?? '',
      id: getSelectedPropertyDetails()?.id ?? '',
      value: getSelectedPropertyDetails()?.value ?? '',
      type: 'Property'
    }
  ]);
  const [propertyBuildings, setPropertyBuildings] = useState<any[]>([]);
  const [propertyFloorplans, setPropertyFloorplan] = useState<any[]>([]);
  const [shouldShowUnits, setShouldShowUnits] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);

  const [propertyUnits, setPropertyUnits] = useState<{
    [key: string]: any;
  }>({});
  const [propertyUnitsLoaded, setPropertyUnitsLoaded] =
    useState<boolean>(false);

  const isEmailAvailable = prospect && prospect.profile.email;

  let bedroomPreferences: Number[] = [];

  if (prospect && prospect.preferences?.bedrooms) {
    bedroomPreferences = prospect.preferences?.bedrooms.map(
      (bedroom: string) => {
        const str = bedroom.split('bd');
        return Number(str[0]);
      }
    );
  }

  const isPreferenceUndeterminedStatus = React.useMemo(() => {
    if (!prospect) {
      return true;
    }
    const {
      preferences: { bedrooms, preferred_layout_id, min_price, max_price },
      profile: { target_move_date }
    } = prospect;

    return (
      !preferred_layout_id &&
      !bedrooms?.length &&
      !target_move_date &&
      !min_price &&
      !max_price
    );
  }, [prospect]);

  const handleClose = (isBackButtonClick: boolean) => {
    setDrawerOpen(false);
    setTimeout(() => {
      onClose(isBackButtonClick);
    }, 1000);
  };

  const shouldHaveMatchedUnits = () => {
    setShouldShowUnits(true);
  };

  const handleCopyUnitLink = async (unit: UnitType) => {
    setUnitName(unit.name);
    setShowAlert(true);

    const { data } = await getUnitLink(
      prospect.id,
      'community',
      prospect.community.id,
      {
        params: {
          doorway: 'schedule',
          unit_id: unit.id
        }
      }
    );

    navigator.clipboard.writeText(data.short_url);
  };

  const handleCreateQuote = (unit: UnitType) => {
    setCurrentUnit(unit);

    if (unit.hasGuestCard) {
      // We only launch the prompt for the Unified quoting widget if the given property has that setting enabled, and that is where the prospect
      // is located
      if (
        realPageIntegrationPreferences.unifiedQuotes &&
        unit.propertyName === currentProperty.location.name
      ) {
        setCreateQuotePromptOpen(true);
      } else {
        const profile = connectedProfiles.find(
          (profile: ConnectedProfile) =>
            profile.communityName === unit.propertyName
        );

        if (profile && profile.personId) {
          createQuote(unit, profile.personId);
        } else {
          createQuote(unit);
        }
      }
    } else {
      setCreateReferralModalOpen(true);
    }
  };

  const handleCreateQuotePrompt = (quote: Quote) => {
    setUnifiedQuote(quote);
    setCreateQuotePromptOpen(false);
    setIsQuoteSummaryModalOpen(true);
  };

  const handleCloseCreateReferral = (prospectId?: number) => {
    setCreateReferralModalOpen(false);

    // The existence of a prospectId value indicates the user did submit the referral versus simply closing the modal, so if it's not there we take
    // no action
    if (prospectId !== undefined) {
      if (
        realPageIntegrationPreferences.unifiedQuotes &&
        currentUnit.propertyName === currentProperty.location.name
      ) {
        setCreateQuotePromptOpen(true);
      } else {
        createQuote({ ...currentUnit }, prospectId);
      }

      setUnits(getUpdatedUnits(units, currentUnit.propertyName));
      setFilteredData(getUpdatedUnits(filteredData, currentUnit.propertyName));
    }
  };

  const handleAttachQuoteToMessage = async (quote: any) => {
    setQuoteErrorMessage('');

    try {
      const attachment = await unitQuotesService.uploadQuote(quote.url);

      attachQuoteToMessage({
        attachment: attachment,
        stream_id: prospect.stream_id
      });
    } catch (error) {
      setQuoteErrorMessage(
        'An unexpected error occurred attaching the quote to the message. Please try again later.'
      );
    }
  };

  const handleCancelUnifiedQuote = () => {
    setIsQuoteSummaryModalOpen(false);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  const preferenceMatchCount = (unit: UnitType) => {
    const availabilityMatchCount = availDateCheck(unit, prospect) ? 1 : 0;
    const layoutCount = checkFloorplanMatch(unit, prospect) ? 1 : 0;
    const priceMatchCount = checkUnitValueInBudget(unit, prospect) ? 1 : 0;
    const bedroomPreferenceCount = checkBedValueWithUnit(
      unit,
      bedroomPreferences
    )
      ? 1
      : 0;

    return (
      availabilityMatchCount +
      layoutCount +
      priceMatchCount +
      bedroomPreferenceCount
    );
  };

  const addCountAndStatus = (unit: UnitType) => {
    if (prospect && isPreferenceUndeterminedStatus) {
      unit.preferenceUndeterminedStatus = true;
    } else if (prospect && !isPreferenceUndeterminedStatus) {
      unit.preferenceMatchCount = preferenceMatchCount(unit);
    }

    return unit;
  };

  const getCurrentProspectProfile = (): ConnectedProfile => {
    const prospectProperty = properties.find(
      (property: PropertyResponse) =>
        property.Property.id === prospect.property_id
    );

    const propertyName = prospectProperty
      ? prospectProperty.Property.data.location.name
      : '';

    return {
      communityName: propertyName,
      lastActive: '',
      guestcardUrl: '',
      personType: 'prospect',
      status: '',
      statusLabel: '',
      personId: prospect.id
    };
  };

  const getUpdatedUnits = (units: UnitType[], propertyName: string) => {
    return units.map((unit: UnitType) => {
      let hasGuestCard = unit.hasGuestCard;

      if (unit.propertyName === propertyName) {
        hasGuestCard = true;
      }

      return { ...unit, hasGuestCard };
    });
  };

  const mapUnits = (data: any, profiles: ConnectedProfile[]) => {
    const availableUnits = data.units.map((unit: UnitType) => {
      const selectedPropertyDetails = getSelectedPropertyDetails();

      unit.propertyId = selectedPropertyDetails
        ? Number(selectedPropertyDetails.id)
        : 0;
      unit.propertyName = selectedPropertyDetails?.label ?? '';
      unit.hasGuestCard = !!profiles.find(
        (profile: ConnectedProfile) =>
          profile.communityName === currentProperty.location.name
      );
      unit.displayPrice = unit.displayPrice === '0' ? '' : unit.displayPrice;
      return addCountAndStatus(unit);
    });

    setUnits(availableUnits);
  };

  const modifiedProperties = properties?.map((property) => {
    return {
      label: property.Property.data.location.name,
      id: property.ManagerCommunity.property_id.toString(),
      value: property.ManagerCommunity.property_id.toString(),
      type: 'Property'
    };
  });
  const calculateDateRange = (
    startDate: Dayjs | null,
    endDate: Dayjs | null,
    availableOn: Dayjs | null,
    rangeFilter: string | null
  ) => {
    if (startDate && endDate) {
      const checkToday =
        rangeFilter ||
        startDate.isSame(dayjs(), 'day') ||
        endDate.isSame(dayjs(), 'day')
          ? availableOn?.isBefore(dayjs(), 'day') ||
            availableOn?.isSame(dayjs(), 'day')
          : false;

      return (
        checkToday ||
        ((startDate.isSame(availableOn, 'day') ||
          startDate.isBefore(availableOn, 'day')) &&
          (endDate.isSame(availableOn, 'day') ||
            endDate.isAfter(availableOn, 'day')))
      );
    } else if (startDate) {
      const checkToday =
        startDate.isSame(dayjs(), 'day') || startDate.isBefore(dayjs(), 'day')
          ? availableOn?.isBefore(dayjs(), 'day')
          : false;

      return (
        checkToday ||
        startDate.isSame(availableOn, 'day') ||
        startDate.isBefore(availableOn, 'day')
      );
    } else if (endDate) {
      return (
        endDate.isSame(availableOn, 'day') ||
        endDate.isAfter(availableOn, 'day')
      );
    }
  };

  const filterData = (allUnits: UnitType[]) => {
    const filter = transformedFilterState(filterState);
    const allPropertyId = propertyList.map((Property) => Property.id);

    return allUnits.filter((row: UnitType) => {
      const matchBedrooms =
        filter.bedrooms.length === 0 ||
        filter.bedrooms.toString().includes(row?.bedrooms?.toString());
      const matchBathrooms =
        filter.bathrooms.length === 0 ||
        filter.bathrooms.includes(row?.bathrooms?.toString());
      const matchMatchColum =
        filter.match.length === 0 ||
        filter.match.includes(row.match ? row.match : '');
      const matchFloorplan =
        filter.layoutId.length === 0 ||
        filter.layoutId.includes(row.layoutId ? row.layoutId : '');
      const matchBuilding =
        filter.building.length === 0 ||
        filter.building.includes(row.buildingId ? row.buildingId : '');
      const minPriceFilter = filter.price.find((f) => f.id === 'minPrice');
      const maxPriceFilter = filter.price.find((f) => f.id === 'maxPrice');
      const startDate = filter.availDate.find((f) => f.id === 'startDate');
      const endDate = filter.availDate.find((f) => f.id === 'endDate');
      const availableOn = row?.availableOn ? dayjs(row?.availableOn) : null;
      const startDateObj = startDate?.value ? dayjs(startDate?.value) : null;
      const endDateObj = endDate?.value ? dayjs(endDate?.value) : null;
      const rangeFilter = filter.availDate.find((f) => f.id === 'range')?.value;
      const isPropertyMatched =
        row?.propertyId || allPropertyId.length > 0 ? true : false;

      const isDateInRange = calculateDateRange(
        startDateObj,
        endDateObj,
        availableOn,
        rangeFilter
      );

      const isOccupied =
        filter.occupancy.length === 0 ||
        (filter.occupancy.includes('occupied') && row.occupied);
      const isVacant =
        filter.occupancy.length === 0 ||
        (filter.occupancy.includes('vacant') && !row.occupied);
      const isOnNotice =
        filter.notice.length === 0 ||
        (filter.notice.includes('on_notice') && row.noticeGiven);
      const isNotOnNotice =
        filter.notice.length === 0 ||
        (filter.notice.includes('no_notice') && !row.noticeGiven);
      if (minPriceFilter && maxPriceFilter) {
        const minPrice = Number(minPriceFilter.value);
        const maxPrice = Number(maxPriceFilter.value);
        return (
          Number(row.displayPrice) >= minPrice &&
          Number(row.displayPrice) <= maxPrice &&
          matchBedrooms &&
          matchBathrooms &&
          matchMatchColum &&
          matchFloorplan &&
          matchBuilding &&
          isDateInRange &&
          isPropertyMatched &&
          (isOccupied || isVacant) &&
          (isOnNotice || isNotOnNotice)
        );
      } else if (minPriceFilter) {
        const minPrice = Number(minPriceFilter.value);
        return (
          Number(row.displayPrice) >= minPrice &&
          matchBedrooms &&
          matchBathrooms &&
          matchMatchColum &&
          matchFloorplan &&
          matchBuilding &&
          isDateInRange &&
          (isOccupied || isVacant) &&
          (isOnNotice || isNotOnNotice)
        );
      } else if (maxPriceFilter) {
        const maxPrice = Number(maxPriceFilter.value);
        return (
          Number(row.displayPrice) <= maxPrice &&
          matchBedrooms &&
          matchBathrooms &&
          matchMatchColum &&
          matchFloorplan &&
          matchBuilding &&
          isDateInRange &&
          (isOccupied || isVacant) &&
          (isOnNotice || isNotOnNotice)
        );
      }

      return (
        matchBedrooms &&
        matchBathrooms &&
        matchMatchColum &&
        matchFloorplan &&
        matchBuilding &&
        isDateInRange &&
        (isOccupied || isVacant) &&
        (isOnNotice || isNotOnNotice)
      );
    });
  };

  const addMatchToUnits = (unit: UnitType) => {
    const matchCount = preferenceMatchCount(unit);

    if (unit.match) {
      return unit.match;
    } else {
      if (isPreferenceUndeterminedStatus) {
        return 'Undetermined';
      }
      if (matchCount === 4) {
        return 'Great Match';
      } else if (matchCount === 3 || matchCount === 2) {
        return 'Good Match';
      } else if (matchCount === 1) {
        return 'Close Match';
      } else if (matchCount === 0) {
        return 'No Match';
      }
    }
  };

  const formatConnectedProfiles = () => {
    let profiles: ConnectedProfile[] = [getCurrentProspectProfile()];

    profiles = [
      ...profiles,
      ...connectedProfs
        .filter((profile: any) => profile.guestcardUrl)
        .map((profile: any) => {
          return {
            communityName: profile.communityName,
            lastActive: '',
            guestcardUrl: '',
            personType: 'prospect',
            status: '',
            statusLabel: '',
            personId: profile.personId
          };
        })
    ];
    setConnectedProfiles(profiles);
    return profiles;
  };

  const fetchDataForProperties = async () => {
    const propertyArray =
      propertyList.length === 0 ? modifiedProperties : propertyList;

    try {
      setLoading(true);

      let profiles = [getCurrentProspectProfile()];

      if (isConnectedProfilesEnabled) {
        profiles = formatConnectedProfiles();
      }

      let propertiesUnits: { [key: string]: any } = {};
      const propertyPromises = propertyArray.map(async (propertyFilter) => {
        const property = Number(propertyFilter.value);

        try {
          let data = propertyUnits[property];
          if (!data) {
            const response = await getUnitsApi({ propertyId: property });
            data = response.data;
            propertiesUnits[property] = data;
          }

          const modifiedLayouts = data?.layouts?.map((layout: any) => {
            return {
              label: layout.name,
              id: property,
              value: layout.id,
              type: 'Floorplan',
              propertyId: property,
              propertyName: propertyFilter.label
            };
          });

          const modifiedBuildings = data?.buildings?.map((building: any) => {
            return {
              ...building,
              label: building.name,
              value: building.id,
              id: property,
              type: 'Building',
              propertyId: property,
              propertyName: propertyFilter.label
            };
          });

          const modifiedUnits = data?.units?.map((unit: any) => {
            unit.propertyId = propertyFilter.id;
            unit.propertyName = propertyFilter.label;
            unit.hasGuestCard = !!profiles.find(
              (profile: ConnectedProfile) =>
                profile.communityName === propertyFilter.label
            );

            return {
              ...unit,
              propertyId: property,
              propertyName: propertyFilter.label
            };
          });

          return {
            units: modifiedUnits,
            layouts: modifiedLayouts,
            buildings: modifiedBuildings,
            propertyId: property,
            propertyName: propertyFilter.label
          };
        } catch (error) {
          console.error('Error fetching data for property:', property, error);
          return null;
        }
      });

      const results = await Promise.all(propertyPromises);
      const allUnits: UnitType[] = [];
      const allLayouts: MenuOption[] = [];
      const allBuildings: MenuOption[] = [];

      results.forEach((result) => {
        if (result) {
          allUnits.push(...result.units);
          allLayouts.push(...result.layouts);
          allBuildings.push(...result.buildings);
        }
      });

      let newBuildings: BuildingType = {};

      allBuildings.forEach((building: MenuOption) => {
        if (building.label) {
          if (building.value) {
            newBuildings[building.value] = building.label;
          }
        }
      });

      setBuildings(newBuildings);
      setPropertyBuildings(allBuildings);
      setPropertyFloorplan(allLayouts);
      if (Object.keys(propertiesUnits).length > 0) {
        await setPropertyUnits((prevState) => ({
          ...prevState,
          ...propertiesUnits
        }));
      }
      setPropertyUnitsLoaded(true);
      const filteredFilterState: MenuOption[] = [];
      const removedItems: MenuOption[] = [];

      filterState.reduce((_, filterItem) => {
        if (filterItem.type === 'Floorplan' || filterItem.type === 'Building') {
          if (
            allLayouts?.some(
              (layoutItem: MenuOption) => layoutItem.value === filterItem.value
            ) ||
            allBuildings?.some(
              (buildingItem: MenuOption) =>
                buildingItem.value === filterItem.value
            )
          ) {
            filteredFilterState.push(filterItem);
          } else {
            removedItems.push(filterItem);
          }
        } else {
          filteredFilterState.push(filterItem);
        }
        return null;
      }, null);

      if (removedItems.length) {
        setFilterState([...filteredFilterState]);
      }

      const allUnitsWithMatch = allUnits?.map((unit: UnitType) => {
        const match = addMatchToUnits(unit);
        const modifiedUnits = addCountAndStatus(unit);

        return {
          ...modifiedUnits,
          match: match,
          displayPrice: unit.displayPrice === '0' ? '' : unit.displayPrice
        };
      });

      const filtered = filterData(allUnitsWithMatch);

      setFilteredData(filtered);
      setRows(allUnitsWithMatch);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data for properties:', error);
    }
  };

  const attachMultipleBrochure = (propertyList: any) => {
    if (propertyList.length === 0) {
      const allPropertySelect = properties.map((Property) => Property.Property);
      propertyList = allPropertySelect;
    }

    const attachBrochurPromise = propertyList.map(async (property: any) => {
      try {
        new Promise((resolve, reject) => {
          attachBrochure(property.id);
        });
      } catch (error) {
        console.error(`Error making API call for ID ${property.id}:`, error);
      }
    });

    Promise.all(attachBrochurPromise).then((values) => {
      onClose(true);
      closeLeasingBinder();
    });
  };

  useEffect(() => {
    const defaultProperty = properties.find(
      (property) =>
        property.ManagerCommunity.property_id === currentProperty.property_id
    );

    if (defaultProperty) {
      const defaultPropertyItem = {
        label: defaultProperty.Property.data.location.name,
        id: defaultProperty.ManagerCommunity.property_id.toString(),
        value: defaultProperty.ManagerCommunity.property_id.toString(),
        type: 'Property'
      };

      setPropertyList([defaultPropertyItem]);
    }
  }, [currentProperty.property_id, properties]);

  useEffect(() => {
    if (open) {
      fetchDataForProperties();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyList, prospect, open]);

  useEffect(() => {
    const filteredUnits = filterData(rows?.length > 0 ? rows : units);

    setFilteredData(filteredUnits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState, prospect]);

  useEffect(() => {
    const fetchUnitsData = async () => {
      try {
        setLoading(true);

        let data = propertyUnits[currentProperty.property_id];
        if (!data) {
          const response = await getUnitsApi({
            propertyId: currentProperty.property_id
          });
          data = response.data;
          setPropertyUnits((prevState) => ({
            ...prevState,
            [currentProperty.property_id]: data
          }));
        }

        if (data.units.length > 0) {
          let profiles = [getCurrentProspectProfile()];

          if (isConnectedProfilesEnabled) {
            profiles = formatConnectedProfiles();
          }

          let newBuildings: BuildingType = {};

          data.buildings.forEach((building: BuildingType) => {
            if (building.name) {
              newBuildings[building.id] = building.name;
            }
          });

          setBuildings(newBuildings);
          const selectedPropertyDetails = getSelectedPropertyDetails();

          setPropertyBuildings(
            data?.buildings?.map((building: any) => {
              return {
                label: building.name,
                id: currentProperty.property_id,
                value: building.id,
                type: 'Building',
                propertyName: selectedPropertyDetails.label,
                propertyId: Number(selectedPropertyDetails.value)
              };
            })
          );

          setPropertyFloorplan(
            data?.layouts?.map((layout: any) => {
              return {
                label: layout.name,
                id: currentProperty.property_id,
                value: layout.id,
                type: 'Floorplan',
                propertyName: selectedPropertyDetails.label,
                propertyId: Number(selectedPropertyDetails.value)
              };
            })
          );

          mapUnits(data, profiles);
        } else {
          setUnits([]);
          setBuildings({});
        }

        setLoading(false);
      } catch (err) {
        setUnits([]);
        setBuildings({});
        setLoading(false);

        console.error(err);
      }
    };

    if (currentProperty.property_id && open && propertyUnitsLoaded) {
      fetchUnitsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentProperty.property_id,
    getUnitsApi,
    open,
    prospect,
    propertyUnitsLoaded
  ]);

  useEffect(() => {
    const fetchRealPageIntegrationPreferences = async (propertyId: string) => {
      try {
        const response =
          await prospectIntegrationsApi.getRealPageIntegrationPreferences(
            propertyId
          );

        const prefs: RealPageIntegrationPreferences = {
          pmcId: response.data.preferences.pmc_id,
          siteId: response.data.preferences.site_id,
          unifiedQuotes: response.data.preferences.unified_quotes
        };

        setRealPageIntegrationPreferences(prefs);
      } catch (error) {
        console.log(
          'Error fetching RealPage integration preferences: %o',
          error
        );
      }
    };

    if (hasRealPageIntegration && open) {
      fetchRealPageIntegrationPreferences(prospect.community.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospect.community.id, open]);

  const filters: FilterOption[] = [
    {
      label: 'Property',
      menu: modifiedProperties,
      onApply: (val: MenuOption[]) => {
        setUnits([]);
        setFilteredData([]);
        setPropertyList(val);
      },
      labelForAll: 'All'
    },
    {
      label: 'Match',
      menu: matchFilterOptions,
      onApply: (val: MenuOption[]) => {
        setFilterState(val);
      },
      labelForAll: 'All Units'
    },
    {
      label: 'Beds',
      menu: bedFilterOptions,
      onApply: (val: MenuOption[]) => {
        setFilterState(val);
      },
      labelForAll: 'All Beds'
    },
    {
      label: 'Floorplan',
      menu: propertyFloorplans?.reduce((result, obj) => {
        const existingGroup = result.find(
          (item: any) => item.id === obj?.propertyId
        );

        if (existingGroup) {
          existingGroup.data.push(obj);
        } else {
          result.push({
            id: obj.propertyId,
            propertyName: obj.propertyName,
            data: [obj]
          });
        }
        return result;
      }, []),
      onApply: (val: MenuOption[]) => {
        setFilterState(val);
      },
      subSection: true,
      labelForAll: 'All Flooplans'
    },
    {
      label: 'Price',
      menu: [],
      onApply: (val: MenuOption[]) => {
        const minPrice = val.find((price) => price.id === 'minPrice');
        const maxPrice = val.find((price) => price.id === 'maxPrice');
        const updatedFilterState = [...filterState];
        const existingMinPrice = updatedFilterState.find(
          (price) => price.id === 'minPrice'
        );
        const existingMaxPrice = updatedFilterState.find(
          (price) => price.id === 'maxPrice'
        );
        if (!maxPrice?.value && existingMaxPrice) {
          updatedFilterState.splice(
            updatedFilterState.indexOf(existingMaxPrice),
            1
          );
        } else if (existingMaxPrice) {
          existingMaxPrice.value = maxPrice?.value;
        } else if (maxPrice?.value) {
          updatedFilterState.push(maxPrice);
        }

        if (!minPrice?.value && existingMinPrice) {
          updatedFilterState.splice(
            updatedFilterState.indexOf(existingMinPrice),
            1
          );
        } else if (existingMinPrice) {
          existingMinPrice.value = minPrice?.value;
        } else if (minPrice?.value) {
          updatedFilterState.push(minPrice);
        }

        setFilterState(updatedFilterState);
      }
    },
    {
      label: 'Avail Date',
      menu: [],
      onApply: (val: MenuOption[]) => {
        const selectedStartDate = val.find(
          (filter) => filter.id === 'startDate'
        );
        const selectedEndDate = val.find((filter) => filter.id === 'endDate');
        const selectedRange = val.find((filter) => filter.id === 'range');

        const updatedFilterState = [...filterState];

        const existingStartDate = updatedFilterState.find(
          (filter) => filter.id === 'startDate'
        );
        const existingEndDate = updatedFilterState.find(
          (filter) => filter.id === 'endDate'
        );
        const existingRange = updatedFilterState.find(
          (filter) => filter.id === 'range'
        );

        if (!selectedStartDate?.value && existingStartDate) {
          updatedFilterState.splice(
            updatedFilterState.indexOf(existingStartDate),
            1
          );
        } else if (existingStartDate) {
          existingStartDate.value = selectedStartDate?.value;
        } else if (selectedStartDate?.value) {
          updatedFilterState.push(selectedStartDate);
        }

        if (!selectedEndDate?.value && existingEndDate) {
          updatedFilterState.splice(
            updatedFilterState.indexOf(existingEndDate),
            1
          );
        } else if (existingEndDate) {
          existingEndDate.value = selectedEndDate?.value;
        } else if (selectedEndDate?.value) {
          updatedFilterState.push(selectedEndDate);
        }

        if (selectedRange) {
          if (existingRange) {
            existingRange.value = selectedRange.value;
          } else {
            updatedFilterState.push(selectedRange);
          }
        } else if (existingRange) {
          updatedFilterState.splice(
            updatedFilterState.indexOf(existingRange),
            1
          );
        }
        if (
          !selectedEndDate?.value &&
          !selectedStartDate?.value &&
          !selectedRange
        ) {
          updatedFilterState.push(...defaultFilters);
        }

        setFilterState(updatedFilterState);
      }
    },
    {
      label: 'Baths',
      menu: bathsFilterOptions,
      onApply: (val: MenuOption[]) => {
        setFilterState(val);
      },
      labelForAll: 'All Baths'
    },
    {
      label: 'Building',
      menu: propertyBuildings.reduce((result, obj) => {
        const existingGroup = result.find(
          (item: any) => item.id === obj?.propertyId
        );

        if (existingGroup) {
          existingGroup.data.push(obj);
        } else {
          result.push({
            id: obj.propertyId,
            propertyName: obj.propertyName,
            data: [obj]
          });
        }
        return result;
      }, []),
      isDisabled: propertyBuildings.length <= 0,
      onApply: (val: MenuOption[]) => {
        setFilterState(val);
      },
      subSection: true,
      labelForAll: 'All Buildings'
    },
    {
      label: 'Occupancy',
      menu: occupancyFilterOptions,
      onApply: (val: MenuOption[]) => {
        setFilterState(val);
      },
      labelForAll: 'All'
    },

    {
      label: 'Notice',
      menu: noticeFilterOptions,
      onApply: (val: MenuOption[]) => {
        setFilterState(val);
      },
      labelForAll: 'All'
    }
  ];

  return (
    <ThemeProvider>
      <Drawer
        anchor="right"
        open={drawerOpen}
        disableEnforceFocus
        classes={{ paper: classes.paper }}
        onClose={handleClose}
      >
        <Box
          className={
            shouldShowUnits ? classes.containerWithUnits : classes.container
          }
        >
          <Box>
            <Box className={classes.headerBox}>
              {showAvailabilityBackButton ? (
                <Button
                  data-testid="back-button"
                  variant="text"
                  onClick={() => handleClose(true)}
                  className={classes.availabilityBackButton}
                  startIcon={<ArrowBackIcon />}
                >
                  Availability
                </Button>
              ) : (
                <Text className={classes.title}>Availability</Text>
              )}
              {showAlert && (
                <UnitLinkAlert unitName={unitName} closeAlert={closeAlert} />
              )}

              <IconButton
                data-testid="close-button"
                onClick={() => handleClose(false)}
                className={classes.closeButton}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>
            </Box>
            <Box className={classes.batchChip}>
              {filters?.map((filter, index) => {
                return (
                  <AvailabilityFilters
                    key={index}
                    filterValues={filter}
                    filterState={filterState}
                    optionLabelSelector={optionLabelSelector}
                    className={classes.chipStyle}
                    selectedProperty={getSelectedPropertyDetails()}
                    properties={propertyList}
                  />
                );
              })}
            </Box>

            {(units?.length > 0 || filteredData?.length > 0) && (
              <SuggestedMatches
                units={filteredData?.length > 0 ? filteredData : units}
                onCopyUnitLink={handleCopyUnitLink}
                onCreateQuote={handleCreateQuote}
                shouldHaveMatchedUnits={shouldHaveMatchedUnits}
              />
            )}
          </Box>

          <AvailabilityTable
            units={units}
            loading={loading}
            buildings={buildings}
            prospect={prospect}
            onCopyUnitLink={handleCopyUnitLink}
            filteredData={filteredData}
            filterState={filterState}
            propertyFilter={propertyList}
            onCreateQuote={handleCreateQuote}
          />
        </Box>
        <Box
          className={shouldShowUnits ? classes.footerWithUnits : classes.footer}
        >
          {isEmailAvailable ? (
            <Button
              className={`${classes.shareBroucherButton}`}
              onClick={() => attachMultipleBrochure(propertyList)}
              data-testid="attach-broucher"
              startIcon={<ShareBrochureIcon />}
            >
              Share Brochure ({propertyList.length || properties.length})
            </Button>
          ) : (
            <Tooltip
              title="No prospect email on file"
              arrow
              classes={{ tooltip: classes.tooltip }}
            >
              <div className={classes.buttonWrap}>
                <Button
                  disabled
                  className={`${classes.disabledShareBroucherButton}`}
                  data-testid="attach-broucher-disable"
                  startIcon={<InactiveShareBrochureIcon />}
                >
                  Share Brochure
                </Button>
              </div>
            </Tooltip>
          )}
        </Box>
      </Drawer>

      <CreateQuotePromptModal
        open={createQuotePromptOpen}
        onSubmit={handleCreateQuotePrompt}
        onClose={() => setCreateQuotePromptOpen(false)}
        prospectIntegrationsApi={prospectIntegrationsApi}
        prospectId={prospect.id}
        propertyId={prospect.community.id}
        propertyName={currentUnit.propertyName}
        vendorProspectId={vendorProspectId}
        vendorPropertyId={realPageIntegrationPreferences.siteId}
        vendorCompanyId={realPageIntegrationPreferences.pmcId}
      />

      <CreateReferralModal
        open={createReferralModalOpen}
        onClose={handleCloseCreateReferral}
        prospectId={prospect.id}
        propertyId={currentUnit.propertyId}
        propertyName={currentUnit.propertyName}
      />

      <QuoteSummary
        attachQuoteErrorMessage={quoteErrorMessage}
        attachQuoteToMessage={handleAttachQuoteToMessage}
        isCreateQuoteDialogOpen={isQuoteSummaryModalOpen}
        cancelUnifiedQuote={handleCancelUnifiedQuote}
        quote={unifiedQuote}
        unit={currentUnit}
        isSendQuoteDisabled={isSendQuoteDisabled}
      />
    </ThemeProvider>
  );
};
export default AvailabilityDrawer;
