/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('PhotoCarouselController', [
    '$scope',
    function ($scope) {
      $scope.currentImageIndex = 0;

      $scope.nextImage = function () {
        if ($scope.currentImageIndex >= $scope.images.length - 1) {
          $scope.currentImageIndex = 0;
        } else {
          $scope.currentImageIndex++;
        }
      };

      $scope.previousImage = function () {
        if ($scope.currentImageIndex > 0) {
          $scope.currentImageIndex--;
        } else {
          $scope.currentImageIndex = $scope.images.length - 1;
        }
      };

      $scope.changeImageIndex = function (newIndex) {
        if (newIndex < 0) {
          newIndex = 0;
        }

        if (newIndex >= $scope.images.length) {
          newIndex = $scope.images.length - 1;
        }

        $scope.currentImageIndex = newIndex;
      };
    }
  ]);
})(window.angular);
