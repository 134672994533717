import React, { FC } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { GuestcardIcon } from '../../../icons';
import classnames from 'classnames';

import { PersonType } from '../../../../ts/models';

export enum GuestCardUrlTitle {
  UNAUTHORIZED = 'Unauthorized',
  NONE = ''
}
interface RelationshipProps {
  guestcardUrl: string;
  type: PersonType;
}

const useStyles = makeStyles((theme) => ({
  relationshipLink: {
    color: theme.palette.text.primary,
    '&:active': {
      color: theme.palette.text.primary
    },
    '&:focus': {
      textDecoration: 'none'
    },
    '&:hover': {
      color: theme.palette.text.primary
    },
    '&:link': {
      color: theme.palette.text.primary
    },
    '&:visited': {
      color: theme.palette.text.primary,
      textDecoration: 'none'
    }
  },
  disabled: {
    color: theme.palette.action.disabled,
    pointerEvents: 'none',
    textDecoration: 'none',
    '&:active': {
      color: theme.palette.action.disabled
    },
    '&:focus': {
      textDecoration: 'none'
    },
    '&:hover': {
      color: theme.palette.action.disabled,
      cursor: 'default'
    },
    '&:link': {
      color: theme.palette.action.disabled,
      cursor: 'default',
      textDecoration: 'none'
    },
    '&:visited': {
      color: theme.palette.action.disabled
    },
    '& $relationshipLinkIcon': {
      '& path': {
        stroke: theme.palette.action.disabled
      }
    }
  },
  relationshipLinkIcon: {
    verticalAlign: 'middle',
    '& path': {
      stroke: theme.palette.secondary.light
    }
  },
  relationshipType: {
    marginLeft: '10px',
    textTransform: 'capitalize'
  }
}));

export const Relationship: FC<RelationshipProps> = ({ guestcardUrl, type }) => {
  const classes = useStyles();
  return (
    <div
      className="relationship-wrapper"
      title={
        guestcardUrl ? GuestCardUrlTitle.NONE : GuestCardUrlTitle.UNAUTHORIZED
      }
    >
      <Link
        className={classnames(
          classes.relationshipLink,
          !guestcardUrl && classes.disabled
        )}
        href={guestcardUrl || ''}
        rel="noopener noreferrer"
        target="_blank"
        underline={!guestcardUrl ? 'none' : 'hover'}
      >
        <GuestcardIcon
          className={classes.relationshipLinkIcon}
          height="16"
          width="16"
        />
        <span className={classes.relationshipType}>{type}</span>
      </Link>
    </div>
  );
};
