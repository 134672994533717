import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface BulkAction {
  id: number;
  name: string;
  email: string;
  streamId: string;
  isOnlyReceiveText?: boolean;
}

// This additional interface allows the follow up modal to handle a bulk add operation in the new prospects page, as well as adding a follow up from
// the guest card
export interface FollowUpAction extends BulkAction {
  mode?: string;
  reminderDate?: string;
  reminderTime?: string;
  note?: string;
}

export interface SelectedProspect {
  [key: string]: boolean;
}

export interface SelectedProspects {
  selectedProspects: SelectedProspect;
  selectAll: boolean;
  selectAllIndeterminate: boolean;
}

export const initialSelectedProspects: SelectedProspects = {
  selectedProspects: {},
  selectAll: false,
  selectAllIndeterminate: false
};

export const selectedProspects = createSlice({
  name: 'selectedProspects',
  initialState: initialSelectedProspects,
  reducers: {
    addSelectedProspect: (state, action: PayloadAction<string>) => {
      state.selectedProspects[action.payload] = true;

      if (state.selectAll) {
        state.selectAllIndeterminate = true;
      }
    },
    removeSelectedProspect: (
      state,
      action: PayloadAction<string | number[]>
    ) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((itemId) => {
          delete state.selectedProspects[itemId];
        });
      } else {
        delete state.selectedProspects[action.payload];
      }

      if (state.selectAll) {
        state.selectAllIndeterminate = true;
      }
    },
    selectAllProspects: (state, action: PayloadAction<SelectedProspect>) => {
      state.selectedProspects = action.payload;
      state.selectAll = true;
      state.selectAllIndeterminate = false;
    },
    resetSelectedProspects: (state) => {
      state.selectedProspects = {};
      state.selectAll = false;
      state.selectAllIndeterminate = false;
    }
  }
});

export const {
  addSelectedProspect,
  removeSelectedProspect,
  selectAllProspects,
  resetSelectedProspects
} = selectedProspects.actions;

export default selectedProspects.reducer;
