export const generateClockTimes = (
  interval: number,
  startHour: number,
  startMinutes: number = 0,
  endRange: number,
  today?: boolean
) => {
  let hours = [];
  let hour = today ? new Date() : new Date(0, 0, 0, startHour, startMinutes);

  if (today) {
    hour.setMinutes(Math.ceil(hour.getMinutes() / interval) * interval);
  }
  while (hour.getHours() < endRange) {
    let horaString = hour.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit'
    });
    hours.push(horaString.toLocaleLowerCase());
    hour.setMinutes(hour.getMinutes() + interval);
  }
  return hours;
};

export const extractAvailableTimes = (
  availableTimes: string[],
  timeZone?: string
): {
  availableTimesMap: Record<string, Date[]>;
  minDate: Date;
  maxDate: Date;
} => {
  let availableTimesMap: Record<string, Date[]> = {};
  let minDate: Date | null = null;
  let maxDate: Date | null = null;

  if (availableTimes.length === 0) {
    return {
      availableTimesMap,
      minDate: minDate || new Date(),
      maxDate: maxDate || new Date()
    };
  }

  for (let timeString of availableTimes) {
    const datetime = new Date(timeString);

    // Get min/max date
    if (!minDate) {
      minDate = datetime;
    } else if (datetime < minDate) {
      minDate = datetime;
    }
    if (!maxDate) {
      maxDate = datetime;
    } else if (datetime > minDate) {
      maxDate = datetime;
    }

    const dateString = timeZone
      ? datetime.toLocaleDateString(undefined, {
          timeZone,
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })
      : datetime.toDateString();
    // Add times to specific date keys
    if (availableTimesMap[dateString] === undefined) {
      availableTimesMap[dateString] = [];
    }
    availableTimesMap[dateString].push(datetime);
  }

  return {
    availableTimesMap,
    minDate: minDate || new Date(),
    maxDate: maxDate || new Date()
  };
};
