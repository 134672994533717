(function (angular) {
  var app = angular.module('knockApp');

  app.directive('propertyFloorplanSelect', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/properties/property-floorplan-select.html',
      replace: true,
      scope: {
        useNewUnitsFeature: '<',
        propertyId: '=',
        preferences: '='
      },
      controller: [
        '$scope',
        'propertyApi',
        'unitApi',
        function ($scope, propertyApi, unitApi) {
          var self = this;
          $scope.data = {
            floorplans: [],
            layouts: []
          };

          $scope.$watch('propertyId', function () {
            if ($scope.propertyId) {
              self._getFloorplans();
            }
          });

          self._getFloorplans = function () {
            $scope.isLoading = true;

            if ($scope.useNewUnitsFeature) {
              return unitApi
                .getLayouts($scope.propertyId)
                .then((response) => {
                  $scope.data.layouts = response.data.layouts;
                })
                .catch((error) =>
                  console.log('Error fetching New Unit layouts: ', error)
                )
                .finally(() => ($scope.isLoading = false));
            } else {
              // TODO: Remove legacy floorplan logic
              return propertyApi
                .getPropertyFloorplans($scope.propertyId)
                .then(function (response) {
                  $scope.data.floorplans = response.data.floorplans;
                })
                .catch((error) =>
                  console.log('Error fetching Property Floorplans: ', error)
                )
                .finally(() => ($scope.isLoading = false));
            }
          };
        }
      ]
    };
  });
})(window.angular);
