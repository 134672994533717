/**
 * Partial Stream that has participants.
 * @typedef {Object} Stream
 * @property {Participant[] | undefined} participants
 * @property {string} id
 *
 * Partial participant type
 * @typedef {Object} Participant
 * @property {email_unsubscribe_status | undefined} [UnsubscribeStatus]
 * @property {"manager"|"resident"|"prospect"} type
 *
 * Unsubscribe Status
 * @typedef {Object} UnsubscribeStatus
 * @property {boolean} opted_out
 * @property {string} reason
 */

(function (angular) {
  let app = angular.module('knockApp');
  app.factory('unsubscribeHelper', [
    function () {
      return {
        /**
         * @param {Stream} stream
         * @returns {UnsubscribeStatus} unsubscribe status of non-manager participant
         */
        getUnsubscribeStatusFromStream(stream) {
          if (stream && stream.participants) {
            const person = stream.participants.find(
              (participant) => participant.type !== 'manager'
            );
            if (person && person.info && person.info.email_unsubscribe_status) {
              return person.info.email_unsubscribe_status;
            }
          }
          return null;
        },

        /**
         * Given a stream and an unsubscribeStatus, insert the unsubscribe status into the
         * prospect/resident participant of the stream via mutation.
         *
         * If stream is malformed or nully, this function will be a no-op.
         *
         * @param {Stream} stream
         * @param {UnsubscribeStatus} unsubscribeStatus
         */
        setUnsubscribeStatusForStream(stream, unsubscribeStatus) {
          if (stream && stream.participants) {
            const person = stream.participants.find(
              (participant) => participant.type !== 'manager'
            );
            if (person && person.info) {
              person.info.email_unsubscribe_status = { ...unsubscribeStatus };
            }
          }
          return null;
        }
      };
    }
  ]);
})(window.angular);
