/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.filter('markdownLite', function () {
    return function (input) {
      // matches [link text](link url)
      var mdLinkRegex = /\[([^\]]+?)\]\((http\:\/\/|https\:\/\/)*([^\)]+)\)/g;

      return input.replace(
        mdLinkRegex,
        '<a href="http://$3" target="_blank">$1</a>'
      );
    };
  });
})(window.angular);
