/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('petsEditorService', [
    function () {
      var self = this;

      self.reduceRenterInfoPetTypes = function (pets) {
        var petStringParts = map(pets, function (isAllowed, type) {
          switch (type) {
            case 'cats':
              return isAllowed ? 'Cats' : '';
            case 'small_dogs':
              return isAllowed ? 'Dogs under 25lbs' : '';
            case 'large_dogs':
              return isAllowed ? 'Dogs over 25lbs' : '';
            default:
              return '';
          }
        });

        remove(petStringParts, function (part) {
          return isEmpty(part);
        });

        return petStringParts.join(', ') || 'None';
      };

      return self;
    }
  ]);
})(window.angular);
