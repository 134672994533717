/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('helpHover', function () {
    return {
      restrict: 'E',
      scope: {
        text: '@'
      },
      template:
        '<span class="help-hover" tooltip="{{text}}"><i class="fa fa-question-circle"></i></span>'
    };
  });
})(window.angular);
