/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.factory('transferAppointmentModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openTransferAppointmentModal = function (
        appointment,
        newManagerId,
        visit
      ) {
        return $mdDialog.show({
          controller: 'TransferAppointmentController',
          templateUrl:
            '/angular/views/userInteractions/transfer-appointment-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            appointment: appointment,
            newManagerId: newManagerId,
            visit: visit
          }
        });
      };

      return self;
    }
  ]);

  const TransferAppointmentController = function (
    $q,
    $scope,
    $mdDialog,
    $mdToast,
    appDataService,
    appointment,
    newManagerId,
    visit,
    userInteractionsService
  ) {
    var self = this;

    $scope.data = {
      teamMembers: [],
      selectedTeamMember: null
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.tryTransfer = function () {
      var promises = [];

      var transferAppointmentPromise =
        userInteractionsService.transferAppointment(
          $scope.appointment,
          $scope.data.selectedTeamMember.ManagerInfo,
          visit
        );

      promises.concat(transferAppointmentPromise);

      if ($scope.data.confirmTourAndProspectTransfer) {
        var newManagerId =
          $scope.data.selectedTeamMember.ManagerInfo.manager_id;
        var transferProspectPromise = userInteractionsService.transferProspect(
          $scope.appointment.prospect_id,
          newManagerId
        );

        promises.concat(transferProspectPromise);
      }

      $q.all(promises).then(self._transferSuccess, self._transferError);
    };

    self._transferSuccess = function () {
      $mdToast.showSimple('Transferred!');
      $mdDialog.hide();
    };

    self._transferError = function () {
      $mdToast.showSimple('Error transferring');
    };

    self._initialize = function () {
      $scope.appointment = appointment;

      self._setTeamMembers();
    };

    self._setTeamMembers = function () {
      $scope.data.teamMembers = appDataService.getTeamMembers();

      $scope.data.selectedTeamMember = find(
        $scope.data.teamMembers,
        function (member) {
          return (
            member.Manager.id ===
            (newManagerId ? newManagerId : $scope.appointment.manager_id)
          );
        }
      );
    };

    self._initialize();
  };

  TransferAppointmentController.$inject = [
    '$q',
    '$scope',
    '$mdDialog',
    '$mdToast',
    'appDataService',
    'appointment',
    'newManagerId',
    'visit',
    'userInteractionsService'
  ];

  app.controller(
    'TransferAppointmentController',
    TransferAppointmentController
  );
})(window.angular);
