import { APP_CONFIG } from 'APP_CONFIG';

export default function configureGoogleMaps(app: any) {
  app.config([
    'uiGmapGoogleMapApiProvider',
    function (uiGmapGoogleMapApiProvider: any) {
      uiGmapGoogleMapApiProvider.configure({
        key: APP_CONFIG.REACT_APP_GOOGLE_MAPS_API_KEY,
        v: '3.17',
        libraries: 'geometry,visualization'
      });
    }
  ]);
}
