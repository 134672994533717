/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
'use strict';

angular.module('knockApp').filter('yesNo', [
  function () {
    return function (input) {
      return input == true ? 'Yes' : 'No';
    };
  }
]);
