import React, { FC, ReactElement } from 'react';
import { Tooltip } from '@material-ui/core';

interface TooltipContainerProps {
  children: ReactElement;
  tooltip?: string;
  arrow?: boolean;
  arrowPlacement?:
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;
  style?: string;
}

const TooltipContainer: FC<TooltipContainerProps> = ({
  tooltip,
  children,
  arrow = true,
  arrowPlacement = 'bottom',
  style
}) => {
  if (tooltip) {
    return (
      <Tooltip
        disableTouchListener={true}
        title={tooltip}
        aria-label={tooltip}
        arrow={arrow}
        placement={arrowPlacement}
        classes={{ popper: style }}
      >
        <span>{children}</span>
      </Tooltip>
    );
  } else {
    return <>{children}</>;
  }
};

export default TooltipContainer;
