export default function runHandleLeadTrackingService(app: any) {
  app.run([
    '$rootScope',
    '$auth',
    'userService',
    'leadTrackingService',
    function (
      $rootScope: any,
      $auth: any,
      userService: any,
      leadTrackingService: any
    ) {
      $rootScope.$on('$routeChangeSuccess', function () {
        if (
          !$auth.isAuthenticated() ||
          userService.getUser().type !== 'manager'
        ) {
          leadTrackingService.trackView();
        }
      });
    }
  ]);
}
