/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('LoginController', [
    '$rootScope',
    '$location',
    '$scope',
    '$auth',
    'signupModalService',
    'loginService',
    'apiEvents',
    'authenticationService',
    function (
      $rootScope,
      $location,
      $scope,
      $auth,
      signupModalService,
      loginService,
      apiEvents,
      authenticationService
    ) {
      var self = this;

      $scope.forgotPassword = false;

      $scope.dismiss = $scope.dismiss || function () {};

      if (!$scope.switchToSignup) {
        $scope.switchToSignup = function () {
          signupModalService.openModal();
        };
      }

      $scope.onLoggedIn = function () {
        if ($auth.isAuthenticated()) {
          $scope.dismiss();
          authenticationService.handleAuthenticatedRedirection();
        }
      };

      $scope.onToggleForgotPassword = function (userType, username) {
        const correctType =
          ['manager', 'renter'].indexOf(
            $scope.forgotPasswordParams.userType.toLowerCase()
          ) !== -1;
        if (
          !correctType ||
          $scope.forgotPasswordParams.username.trim().split(' ').length > 1
        ) {
          return;
        }

        $scope.forgotPassword = !$scope.forgotPassword;

        if ($scope.forgotPassword) {
          $scope.forgotPasswordParams = {
            userType: userType,
            username: username
          };
        }
      };

      $scope.sendResetPasswordRequest = function () {
        $scope.isSendingForgotPassword = true;
        $scope.forgotPasswordErrorMessage = null;
        $scope.forgotPasswordSuccessMessage = null;

        loginService
          .sendForgotPasswordRequest(
            $scope.forgotPasswordParams.userType,
            $scope.forgotPasswordParams.username
          )
          .success(self._sendResetPasswordRequestSuccess)
          .error(self._sendResetPasswordRequestError)
          .finally(function () {
            $scope.isSendingForgotPassword = false;
          });
      };

      self._sendResetPasswordRequestSuccess = function () {
        $scope.forgotPasswordSuccessMessage =
          "We've sent an email to the email address on file for " +
          $scope.forgotPasswordParams.username +
          '. Click the link contained in the email to continue.';
        $scope.passwordResetSent = true;
      };

      self._sendResetPasswordRequestError = function () {
        $scope.forgotPasswordErrorMessage =
          'There was an error sending your request. Please try again later.';
      };

      if (!$auth.isAuthenticated()) {
        $rootScope.$emit(apiEvents.loggedOut);
      }
    }
  ]);
})();
