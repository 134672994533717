import React from 'react';

export const InfoIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 14.75V9.75"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9792 7.25C10.8067 7.25 10.6667 7.11009 10.6667 6.9375C10.6667 6.76491 10.8067 6.625 10.9792 6.625"
      stroke="#4A61E8"
      strokeWidth="1.5"
    />
    <path
      d="M10.9583 7.25C11.1309 7.25 11.2708 7.11009 11.2708 6.9375C11.2708 6.76491 11.1309 6.625 10.9583 6.625"
      stroke="#4A61E8"
      strokeWidth="1.5"
    />
    <path
      d="M11 20.375C16.1777 20.375 20.375 16.1777 20.375 11C20.375 5.82233 16.1777 1.625 11 1.625C5.82233 1.625 1.625 5.82233 1.625 11C1.625 16.1777 5.82233 20.375 11 20.375Z"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
