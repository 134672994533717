import React, { FC } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
  DatePickerProps,
  KeyboardDatePicker
} from '@material-ui/pickers';

import { colors } from '../commonStyles/commonStyles';
import { Box, SvgIcon } from '@material-ui/core';
import { makeStyles, NamedColors } from '@knockrentals/knock-shared-web';
import classNames from 'classnames';
import { NewCalendarIcon } from '../icons';

const DatePicker: FC<DatePickerProps> = ({
  label,
  name,
  onChange,
  value,
  ...other
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiDatePicker
        className="date-picker"
        name={name}
        label={label}
        onChange={onChange}
        value={value}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles({
  datePicker: {
    margin: '-1px 6px 10px 0px',
    '& .MuiInputLabel-formControl': {
      left: '10px',
      top: '5px',
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontWeight: 400
    },
    '& .MuiInput-input': {
      color: colors.defaultText
    },
    '& .MuiInputBase-root': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    '& .MuiInputBase-formControl.Mui-error': {
      borderBottom: `2px solid ${colors.error}`,
      color: colors.error,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none'
    },
    '& .MuiInputBase-formControl': {
      borderColor: NamedColors.slate[300],
      background: NamedColors.slate[50],
      height: '57px',
      borderBottom: `1px solid #8D90A5`,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      top: '20px',
      left: '10px',
      fontSize: '12px',
      '& .date-label': {
        color: colors.secondaryText
      }
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
      top: '20px',
      left: '10px',
      fontSize: '12px'
    },
    '& .MuiInputBase-input': {
      padding: '23px 0 7px 3px'
    },
    '& .MuiButtonBase-root .MuiSvgIcon-root': {
      color: '#353950'
    },
    '& .MuiIconButton-root': {
      padding: '8px'
    },
    '& .MuiSvgIcon-root': {
      height: '24px',
      width: '24px'
    }
  },
  textInputError: {
    '& .MuiFormLabel-root.Mui-focused': {
      color: colors.inputError
    }
  },
  label: {
    left: '4px',
    top: '8px',
    height: '0px',
    color: colors.secondaryText,
    fontWeight: 400
  },
  errorFormLabel: {
    top: '9px',
    left: 2,
    fontWeight: 400,
    color: colors.inputError,
    height: '0px'
  }
});

export const StyledDatePicker: FC<DatePickerProps> = ({
  name,
  label,
  onChange,
  value,
  error,
  ...other
}) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={classNames(classes.datePicker, {
          [classes.textInputError]: error
        })}
        margin="normal"
        label={
          <Box
            className={`${
              error ? classes.errorFormLabel : classes.label
            } date-label`}
          >
            {label}
          </Box>
        }
        fullWidth
        format="MM/dd/yyyy"
        error={error}
        value={value}
        helperText=""
        keyboardIcon={
          <SvgIcon>
            <NewCalendarIcon />
          </SvgIcon>
        }
        onChange={onChange}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
