import { api as baseApi } from 'app/services/api';
import {
  AvailableTimes,
  AvailableTimesParams,
  TourTypesEnabled
} from './entities';

export const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPropertyTourTypes: build.query<TourTypesEnabled, number>({
      query: (propertyId) => ({
        url: `/properties/${propertyId}/tour-types-enabled`,
        method: 'GET',
        noAuthAsLeasingTeam: true
      }),
      transformResponse: (data: any) => {
        const tourTypesEnabled = data.tour_types_enabled;
        return {
          inPersonToursEnabled: tourTypesEnabled.in_person_tours_enabled,
          liveVideoToursEnabled: tourTypesEnabled.live_video_tours_enabled,
          selfGuidedToursEnabled: tourTypesEnabled.self_guided_tours_enabled
        };
      }
    }),
    getAvailableAppointmentsTimeForProperty: build.query<
      AvailableTimes,
      AvailableTimesParams
    >({
      query: ({ propertyId, params }) => {
        const urlParams = new URLSearchParams();
        Object.keys(params).map((param: string) => {
          if (params[param]) {
            urlParams.set(param, params[param]);
          }
          return param;
        });
        return {
          url: `/properties/${propertyId}/available-times?${urlParams.toString()}`
        };
      },
      transformResponse: (data: any) => {
        const available_times = data.available_times;
        return {
          availableTimes: available_times.acceptable_times || []
        };
      }
    })
  })
});
