import React, { FC } from 'react';
import { withStyles } from '@material-ui/core';

import {
  Card,
  CardContent as MuiCardContent,
  Text as KnockText
} from '@knockrentals/knock-shared-web';

import { ConnectedProfilesDataTable } from './ConnectedProfilesDataTable';

const CardContent = withStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    padding: '16px'
  }
}))(MuiCardContent);

const Text = withStyles({
  root: {
    fontSize: '12px',
    lineHeight: '19.5px'
  }
})(KnockText);

interface DialogCardProps {
  connectedProfiles: any;
}

const DialogCard: FC<DialogCardProps> = ({ connectedProfiles = [] }) => (
  <Card elevation={0}>
    <ConnectedProfilesDataTable connectedProfiles={connectedProfiles} />
    <CardContent>
      <Text variant="body1">
        Connected profiles are guestcards for the same prospect at a sister
        community. You must be a member of the leasing team to open a guestcard
        at another property.
      </Text>
    </CardContent>
  </Card>
);

export default DialogCard;
