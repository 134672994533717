import dayjs from 'dayjs';
import { UnitType, ProspectType, ConvertedFilters, MenuOption } from './types';

export const availDateCheck = (unit: UnitType, prospect: ProspectType) => {
  if (!prospect?.profile?.target_move_date) {
    return false;
  }

  const availDate = (
    dayjs(unit.availableOn) < dayjs() ? dayjs() : dayjs(unit.availableOn)
  ).format('YYYY-MM-DD');

  const moveInDate = dayjs(prospect.profile.target_move_date).format(
    'YYYY-MM-DD'
  );

  const daysDifference = dayjs(moveInDate).diff(dayjs(availDate), 'days');

  return (
    (daysDifference >= 0 && daysDifference <= 30) ||
    (daysDifference >= -14 && daysDifference <= 0)
  );
};
export const checkFloorplanMatch = (unit: UnitType, prospect: ProspectType) => {
  if (unit.layoutId) {
    return prospect?.preferences?.preferred_layout_id === unit.layoutId;
  }
};
export const checkUnitValueInBudget = (
  unit: UnitType,
  prospect: ProspectType
) => {
  if (prospect?.preferences?.min_price && prospect?.preferences?.max_price) {
    return (
      Number(unit.displayPrice) >= Number(prospect?.preferences.min_price) &&
      Number(unit.displayPrice) <= Number(prospect?.preferences.max_price)
    );
  } else if (
    prospect?.preferences?.min_price &&
    !prospect?.preferences?.max_price
  ) {
    return (
      Number(unit.displayPrice) >= Number(prospect?.preferences?.min_price)
    );
  } else if (
    prospect?.preferences?.max_price &&
    !prospect?.preferences?.min_price
  ) {
    return (
      Number(unit.displayPrice) <= Number(prospect?.preferences?.max_price)
    );
  } else {
    return false;
  }
};
export const checkBedValueWithUnit = (
  unit: UnitType,
  bedroomPreferences: Number[]
) => {
  if (unit.bedrooms) {
    return bedroomPreferences.includes(unit.bedrooms);
  }
};

export const transformedFilterState = (filterState: any) => {
  const convertedFilters: ConvertedFilters = {
    bedrooms: [],
    bathrooms: [],
    layoutId: [],
    building: [],
    match: [],
    price: [],
    availDate: [],
    occupancy: [],
    notice: []
  };

  filterState.forEach((filter: any) => {
    if (filter.type === 'Beds') {
      convertedFilters.bedrooms.push(filter?.value);
    } else if (filter.type === 'Baths') {
      convertedFilters.bathrooms.push(filter?.value);
    } else if (filter.type === 'Floorplan') {
      convertedFilters.layoutId.push(filter?.value);
    } else if (filter.type === 'Building') {
      convertedFilters.building.push(filter?.value);
    } else if (filter.type === 'Match') {
      convertedFilters.match.push(filter?.id);
    } else if (filter.type === 'Occupancy') {
      convertedFilters.occupancy.push(filter?.value);
    } else if (filter.type === 'Notice') {
      convertedFilters.notice.push(filter?.value);
    } else if (filter.type === 'Avail Date') {
      if (filter.id === 'endDate') {
        convertedFilters.availDate.push({
          id: filter.id,
          value: filter?.value
        });
      }
      if (filter.id === 'startDate') {
        convertedFilters.availDate.push({
          id: filter.id,
          value: filter?.value
        });
      }
      if (filter.id === 'range') {
        convertedFilters.availDate.push({
          id: filter.id,
          value: filter?.value
        });
      }
    } else if (filter.type === 'Price') {
      if (filter.id === 'minPrice') {
        convertedFilters.price.push({ id: filter.id, value: filter?.value });
      }
      if (filter.id === 'maxPrice') {
        convertedFilters.price.push({ id: filter.id, value: filter?.value });
      }
    }
  });
  return convertedFilters;
};

export const optionLabelSelector = (
  nodes: MenuOption[],
  useShortLabel?: boolean
) => {
  let label = '';
  if (nodes.length > 0) {
    if (nodes.length === 1) {
      label = nodes[0].label;
    } else {
      label = nodes.map((item) => item.label)[0];
    }
  }

  return label;
};
