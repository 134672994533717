import React, { FC, useMemo, useState, useEffect, useCallback } from 'react';

import { makeStyles, Box, Text } from '@knockrentals/knock-shared-web';
import UnitCard from './UnitCard';
import { UnitType } from './types';
import { colors } from '../../commonStyles/commonStyles';
import UnitLinkAlert from './UnitLinkAlert';

const useStyles = makeStyles({
  cardContainer: {
    display: 'flex',
    gap: '16px',
    marginTop: '14px'
  },

  title: {
    color: colors.disabled,
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'uppercase'
  }
});

interface SuggestedMatchesProps {
  units: UnitType[];
  onCopyUnitLink: (unit: UnitType) => void;
  onCreateQuote: (unit: UnitType) => void;
  shouldHaveMatchedUnits: () => void;
}

const SuggestedMatches: FC<SuggestedMatchesProps> = ({
  units,
  onCopyUnitLink,
  onCreateQuote,
  shouldHaveMatchedUnits
}) => {
  const classes = useStyles();
  const [unitAlert, setUnitAlert] = useState<string>('');

  const displayableStatus = (unit: UnitType) => {
    if (unit.leased) {
      return (unit.unitStatus = 'Leased');
    }
    if (unit.available) {
      return (unit.unitStatus = 'Available');
    }
    if (unit.reserved) {
      return (unit.unitStatus = 'Reserved');
    }
    return (unit.unitStatus = 'Other');
  };

  const filterForStatus = useCallback((unit: UnitType) => {
    const currentFilterStatus = displayableStatus(unit);

    if (
      currentFilterStatus === 'Reserved' ||
      currentFilterStatus === 'Leased' ||
      currentFilterStatus === 'Other'
    ) {
      return false;
    } else return true;
  }, []);

  const matchedUnits = useMemo(() => {
    let suggestedUnits: UnitType[] = [];
    if (units.length > 0) {
      suggestedUnits = units
        .filter(
          (unit: UnitType) =>
            unit.preferenceMatchCount &&
            unit.preferenceMatchCount >= 2 &&
            filterForStatus(unit)
        )
        .sort(
          (unitA: UnitType, unitB: UnitType) =>
            unitB.preferenceMatchCount! - unitA.preferenceMatchCount!
        );
      if (suggestedUnits.length > 3) {
        suggestedUnits = suggestedUnits.slice(0, 3);
      }
    }
    return suggestedUnits;
  }, [units, filterForStatus]);

  const handleCopyUnitLink = async (unit: UnitType) => {
    onCopyUnitLink(unit);
  };

  const handleCreateQuote = async (unit: UnitType) => {
    onCreateQuote(unit);
  };

  useEffect(() => {
    if (matchedUnits && matchedUnits.length > 0) {
      shouldHaveMatchedUnits();
    }
  }, [matchedUnits, shouldHaveMatchedUnits]);

  return matchedUnits && matchedUnits.length > 0 ? (
    <Box>
      {unitAlert && (
        <UnitLinkAlert
          unitName={unitAlert}
          closeAlert={() => setUnitAlert('')}
        />
      )}
      <Text className={classes.title}>SUGGESTED MATCHES</Text>
      <Box className={classes.cardContainer}>
        {matchedUnits.map((unit: UnitType) => (
          <UnitCard
            key={unit.id}
            unit={unit}
            onCopyUnitLink={handleCopyUnitLink}
            onCreateQuote={handleCreateQuote}
          />
        ))}
      </Box>
    </Box>
  ) : null;
};
export default SuggestedMatches;
