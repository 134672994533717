import React, { FC } from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import { CurrencyDisplay } from '../Currency';

const getCumulativeMonthlyRent = (
  monthlyRent: string,
  upchargeAmount: number
) => {
  return Number(monthlyRent) + upchargeAmount;
};

export interface UnitDetailProps {
  buildingName: string;
  monthlyRent: string;
  unitName: string;
  upchargeAmount: number;
}

const UnitDetails: FC<UnitDetailProps> = ({
  buildingName = 'N/A',
  monthlyRent,
  unitName,
  upchargeAmount
}) => {
  return (
    <section className="unit-details">
      <Typography variant="subtitle2">Unit Details</Typography>
      <List className="unit-details-list" dense>
        <ListItem disableGutters>
          <Typography variant="body2">
            <span className="unit-details-title">Apt #</span>
            <span className="unit-details-value">{unitName}</span>
          </Typography>
        </ListItem>

        <ListItem disableGutters>
          <Typography variant="body2">
            <span className="unit-details-title">Building</span>
            <span className="unit-details-value">{buildingName}</span>
          </Typography>
        </ListItem>

        <ListItem disableGutters>
          <Typography variant="body2">
            <span className="unit-details-title">Monthly Rent</span>
            <CurrencyDisplay
              className="unit-details-value"
              value={getCumulativeMonthlyRent(monthlyRent, upchargeAmount)}
            />
          </Typography>
        </ListItem>
      </List>
    </section>
  );
};

export default UnitDetails;
