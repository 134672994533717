import React, { FC } from 'react';

export const EmojiPickerIcon: FC = () => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 20.375C16.1777 20.375 20.375 16.1777 20.375 11C20.375 5.82233 16.1777 1.625 11 1.625C5.82233 1.625 1.625 5.82233 1.625 11C1.625 16.1777 5.82233 20.375 11 20.375Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.875 8.1875C8.04759 8.1875 8.1875 8.32741 8.1875 8.5C8.1875 8.67259 8.04759 8.8125 7.875 8.8125C7.70241 8.8125 7.5625 8.67259 7.5625 8.5C7.5625 8.32741 7.70241 8.1875 7.875 8.1875"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.125 8.1875C14.2976 8.1875 14.4375 8.32741 14.4375 8.5C14.4375 8.67259 14.2976 8.8125 14.125 8.8125C13.9524 8.8125 13.8125 8.67259 13.8125 8.5C13.8125 8.32741 13.9524 8.1875 14.125 8.1875"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.04 13.5C15.0905 15.4162 13.1369 16.6285 10.9983 16.6285C8.85981 16.6285 6.90615 15.4162 5.95667 13.5"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
