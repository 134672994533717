import React, { FC, ReactNode } from 'react';
import { Collapse, Box } from '@material-ui/core';
import { Alert, AlertTitle, Color } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

export interface ErrorAlertProps {
  alertMessage: string;
  alertTitle?: string;
  noCollapse?: boolean;
  children?: ReactNode;
  severity?: Color;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  errorAlert: {
    '&.MuiAlert-root': {
      padding: '0 8px 0 10px',
      marginBottom: '20px'
    },
    '& .MuiAlert-icon': {
      fontSize: '24px'
    },
    '& .MuiAlert-message': {
      color: theme.palette.text.primary
    }
  }
}));

const ErrorAlert: FC<ErrorAlertProps> = ({
  alertMessage,
  alertTitle,
  noCollapse,
  className,
  severity,
  children
}) => {
  const classes = useStyles();

  const alertComponent = (
    <Alert
      className={className || classes.errorAlert}
      severity={severity || 'error'}
    >
      <Box>
        {!!alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
        {alertMessage}
      </Box>
      {children}
    </Alert>
  );

  return noCollapse ? (
    alertComponent
  ) : (
    <Collapse in={Boolean(alertMessage)} mountOnEnter unmountOnExit>
      {alertComponent}
    </Collapse>
  );
};

export default ErrorAlert;
