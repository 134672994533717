import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const InboxIcon: React.FC = () => {
  return (
    <SvgIcon fontSize="large">
      <g clipPath="url(#clip0_506_1186)">
        <path d="M19.9083 4.85825C19.8837 4.84177 19.8547 4.83297 19.825 4.83297C19.7953 4.83297 19.7663 4.84177 19.7417 4.85825L11.6167 12.9833C11.4048 13.1959 11.1527 13.3643 10.8752 13.4788C10.5977 13.5932 10.3002 13.6514 10 13.6499C9.392 13.6487 8.80866 13.4094 8.375 12.9833L0.258333 4.89159C0.233666 4.8751 0.204666 4.86631 0.175 4.86631C0.145334 4.86631 0.116334 4.8751 0.0916667 4.89159C0.0686326 4.90067 0.0482066 4.91532 0.0322117 4.93423C0.0162167 4.95313 0.00515031 4.9757 0 4.99992V14.9999C0 15.4419 0.175595 15.8659 0.488155 16.1784C0.800716 16.491 1.22464 16.6666 1.66667 16.6666H18.3333C18.7754 16.6666 19.1993 16.491 19.5118 16.1784C19.8244 15.8659 20 15.4419 20 14.9999V4.99992C20.0007 4.96983 19.9923 4.94024 19.976 4.91497C19.9596 4.88971 19.9361 4.86995 19.9083 4.85825Z" />
        <path d="M9.25833 12.1333C9.45586 12.3252 9.72042 12.4325 9.99583 12.4325C10.2712 12.4325 10.5358 12.3252 10.7333 12.1333L18.8083 4.05825C18.8627 4.00359 18.9008 3.93493 18.9185 3.8599C18.9361 3.78487 18.9326 3.70641 18.9083 3.63325C18.8167 3.33325 18.5583 3.33325 18.3333 3.33325H1.66667C1.43333 3.33325 1.18333 3.33325 1.09167 3.63325C1.06739 3.70641 1.06389 3.78487 1.08154 3.8599C1.0992 3.93493 1.13732 4.00359 1.19167 4.05825L9.25833 12.1333Z" />
      </g>
      <defs>
        <clipPath id="clip0_506_1186">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
