import React, { FC, useState, useEffect } from 'react';

import { Box, Slide } from '@material-ui/core';
import { makeStyles, Text } from '@knockrentals/knock-shared-web';
import { useMarkAsLostContext } from './MarkAsLostDrawerContextProvider';
import ReferralCommunitiesTable from './ReferralCommunitiesTable';
import Option from './Option';

const useStyles = makeStyles({
  title: {
    fontSize: '14px'
  }
});

interface CrossSellRadius {
  [index: string]: number;
}

const SelectNearbyPropertyStep: FC = () => {
  const {
    slideDirection,
    selectedCommunities,
    setCreateReferral,
    createReferral,
    sendInfoMail,
    setSendInfoMail,
    prospect,
    getMyCommunities
  } = useMarkAsLostContext();
  const classes = useStyles();

  const [showCrossSellRadius, setShowCrossSellRadius] =
    useState<boolean>(false);

  const [radiusDistance, setRadiusDistance] = useState<Number>();

  useEffect(() => {
    getMyCommunities().then((myCommunitiesObject: any) => {
      const crossSellRadius: CrossSellRadius =
        myCommunitiesObject.data.cross_sell_radius;

      const propertyId = prospect.property_id;

      if (
        crossSellRadius &&
        crossSellRadius[propertyId] &&
        crossSellRadius[propertyId] > 0
      ) {
        setRadiusDistance(crossSellRadius[propertyId]);
        setShowCrossSellRadius(true);
      }
    });
  }, [getMyCommunities, prospect.property_id]);

  return (
    <Slide direction={slideDirection} in={true}>
      <Box>
        <Box>
          <Text className={classes.title} color="secondary">
            Select the communities below that you would like to refer this
            prospect to.{' '}
            {showCrossSellRadius && (
              <>
                The communities shown are within the {radiusDistance} mile range
                set by your admin.
              </>
            )}
          </Text>
        </Box>
        <ReferralCommunitiesTable />
        <Option
          title="Create Referral"
          checked={createReferral}
          disabled={selectedCommunities.length === 0}
          subtitle="Turning this on will add a step to create a guestcard for each property selected above."
          toggle={(
            _: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
          ) => {
            setCreateReferral(checked);
          }}
        />
        <Option
          title="Send Informational Email"
          disabled={selectedCommunities.length === 0 || !prospect.profile.email}
          tooltip={
            selectedCommunities.length > 0 && !prospect.profile.email
              ? 'No prospect email on file'
              : undefined
          }
          checked={sendInfoMail}
          subtitle="Turning this on will add a step to send an email to this prospect with information about each property selected above."
          toggle={(
            _: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
          ) => {
            setSendInfoMail(checked);
          }}
        />
      </Box>
    </Slide>
  );
};
export default SelectNearbyPropertyStep;
