/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('notificationBannerBatchMessage', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/notifications/notification-banner-batch-message.html',
      scope: {
        data: '='
      },
      replace: true,
      controller: [
        '$scope',
        'conversationsService',
        'notificationBannersService',
        'streamCarouselService',
        function (
          $scope,
          conversationsService,
          notificationBannersService,
          streamCarouselService
        ) {
          $scope.close = function () {
            conversationsService.markBatchMessageClosed($scope.data.batchId);
            notificationBannersService.removeNotification(
              $scope.data.batchId,
              notificationBannersService.notificationTypes.batchMessageProgress
            );
          };

          $scope.openFailedRecipientsCarousel = function () {
            streamCarouselService.showCarousel(
              $scope.data.failedStreamIds,
              null,
              null
            );
          };
        }
      ]
    };
  });
})(window.angular);
