import angular from 'angular';
import Pusher from 'pusher-js';

const app = angular.module('knock-Conversations');

app.provider('pusherInstanceService', [
  function () {
    const self = this;

    let pusherKey = null;
    let pusherAuthEndpoint = null;

    self.setPusherSettings = function (key, authEndpoint) {
      pusherKey = key;
      pusherAuthEndpoint = authEndpoint;
    };

    self.$get = [
      '$pusher',
      '$auth',
      'userService',
      function ($pusher, $auth, userService) {
        const self = this;

        self._pusherClient = null;

        self.throwIfPusherNotConfigured = function () {
          if (!pusherKey || !pusherAuthEndpoint) {
            throw new Error('pusher settings not configured!');
          }
        };

        self.initializeClient = function () {
          self.throwIfPusherNotConfigured();

          const pusherOptions = {
            authEndpoint: pusherAuthEndpoint,
            auth: {
              headers: {
                Authorization: 'Bearer ' + $auth.getToken()
              }
            }
          };

          const scopedLeasingTeamId = userService.getScopedLeasingTeamId();

          if (scopedLeasingTeamId) {
            pusherOptions.auth.headers['X-Knock-Auth-As-Leasing-Team'] =
              scopedLeasingTeamId;
          }

          self._pusherClient = $pusher(new Pusher(pusherKey, pusherOptions));
        };

        self.getInstance = function () {
          self.throwIfPusherNotConfigured();

          if (!self._pusherClient) {
            self.initializeClient();
          }

          return self._pusherClient;
        };

        return self;
      }
    ];
  }
]);
