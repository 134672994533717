import React, { FC } from 'react';

export const AvailabilityIcon: FC = () => (
  <svg
    width={22}
    height={20}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 3.125V7.29167C13.5 7.63684 13.2202 7.91667 12.875 7.91667H9.125C8.77982 7.91667 8.5 7.63684 8.5 7.29167V3.125"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.25 4.16675L11 1.04175L14.75 4.16675"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.125 14.375V18.5417C19.125 18.8868 18.8452 19.1667 18.5 19.1667H14.75C14.4048 19.1667 14.125 18.8868 14.125 18.5417V14.375"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.875 15.4167L16.625 12.2917L20.375 15.4167"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.875 14.375V18.5417C7.875 18.8868 7.59518 19.1667 7.25 19.1667H3.5C3.15482 19.1667 2.875 18.8868 2.875 18.5417V14.375"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.625 15.4167L5.375 12.2917L9.125 15.4167"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
