import { api } from './api';
import { store } from '../../store';

import { getQueryStringParameter } from '../../utils/prospectUtils';

export const useResidents = (searchIds?: string[]) => {
  const { useGetResidentsQuery, useAddResidentMutation } = api;

  const newPayload = { ...store.getState().residentFilter.payload };
  const residentIdsParam = getQueryStringParameter(
    window.location.search,
    'f_residentIds'
  );

  if (residentIdsParam || searchIds) {
    newPayload.resident_ids = searchIds
      ? searchIds.map((id) => Number(id))
      : residentIdsParam
          .split(',')
          .map((id) => Number(id))
          .filter((id) => !isNaN(id));
  }

  const batchIdParam = getQueryStringParameter(
    window.location.search,
    'batchId'
  );

  if (batchIdParam || residentIdsParam || searchIds) {
    newPayload.batch_id = batchIdParam;
    newPayload.property_ids = ['all'];
    newPayload.buildings = undefined;
    newPayload.statuses = undefined;
    newPayload.lease_end_after = undefined;
    newPayload.lease_end = undefined;
  }

  const { data, error, isFetching, refetch } = useGetResidentsQuery(newPayload);
  const [addResident] = useAddResidentMutation();

  const residents = data ? data.residents : [];
  const pageData = data
    ? data.pageData
    : { count: 0, page: 1, rowsPerPage: 500 };

  return {
    residentsLoading: isFetching,
    residentsLoadingError: error,
    residents,
    pageData,
    refetch,
    addResident
  };
};
