import React, { FC } from 'react';
import { createPortal } from 'react-dom';

interface InsideBodyPortalProps {
  children: React.ReactNode;
}

const InsideBodyPortal: FC<InsideBodyPortalProps> = ({ children }) => {
  return <>{createPortal(children, window.document.body)}</>;
};

export default InsideBodyPortal;
