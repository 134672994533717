/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('urlValidatorService', function () {
    var self = this;
    self.URL_REGEX =
      /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

    self.validate = function (url) {
      return self.URL_REGEX.test(url);
    };

    return self;
  });

  app.directive('urlValidator', [
    'urlValidatorService',
    function (urlValidatorService) {
      return {
        require: 'ngModel',
        restrict: '',
        link: function (scope, elm, attrs, ctrl) {
          ctrl.$validators.url = function (modelValue) {
            if (!modelValue) {
              return true;
            }

            return urlValidatorService.validate(modelValue);
          };
        }
      };
    }
  ]);
})();
