import React, { FC } from 'react';
import { CircularProgress } from '@knockrentals/knock-shared-web';
import { Snackbar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  loadingQuotesToastBox: {
    display: 'flex'
  },
  loadingQuotesTest: {
    marginTop: '5px',
    marginLeft: '20px',
    fontSize: 'large',
    fontFamily: 'Open Sans',
    letterSpacing: '0.25px'
  },
  circularProgressClass: {
    color: 'white'
  }
});

const vertical = 'bottom',
  horizontal = 'left';

const ToastMessageLoading = () => {
  const { loadingQuotesToastBox, loadingQuotesTest, circularProgressClass } =
    useStyles();

  return (
    <div className={loadingQuotesToastBox}>
      <CircularProgress className={circularProgressClass} />{' '}
      <div className={loadingQuotesTest}> Loading Quote</div>
    </div>
  );
};

const LoadingQuoteToast: FC = () => {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={true}
        message={<ToastMessageLoading />}
      />
    </>
  );
};

export default LoadingQuoteToast;
