import React from 'react';

export const TrainingIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 19.8125H15.75M12 16.6875V19.8125M3.45833 16.6875H20.5417C21.0019 16.6875 21.375 16.3144 21.375 15.8542V5.02083C21.375 4.5606 21.0019 4.1875 20.5417 4.1875H3.45833C2.9981 4.1875 2.625 4.5606 2.625 5.02083V15.8542C2.625 16.3144 2.9981 16.6875 3.45833 16.6875Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
