import React, { FC, useState, useEffect } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';

import { CircularProgressButton } from '../CircularProgressButton';
import { Concessions } from './Concessions';
import { LeaseDetails } from './LeaseDetails';
import { Lease, Unit } from '../../ts/models';
import {
  QuoteErrorMessages,
  validateLeaseDates
} from './LeaseDetails/validation';
import { getInitialEndDate } from './LeaseDetails/utils';
import { ErrorAlert } from './ErrorAlert';
import UnitDetails from './UnitDetails';

export const ConcessionsStatusMessage = {
  NEED_INPUT:
    'Please complete the lease details section in order to generate available concessions.',
  LOADING: 'Loading',
  UNAVAILABLE:
    'There are no available concessions for the selected lease term.',
  ERROR:
    'Sorry! We ran into an issue generating available concessions. Please try again later.'
};

export interface CreateQuoteViewProps {
  blackoutDatesMap: { [key: string]: string };
  createQuote: (
    selectedConcessions: {},
    leaseStartDate: string,
    leaseTermMonths: number,
    leaseEndDate: string,
    upchargeAmount: number
  ) => void;
  createQuoteErrorMessage: string;
  fetchQuoteConcessions: (
    leaseStartDate: string,
    leaseTermLength: number
  ) => void;
  getLeaseTermUpchargeAmount: (selectedLeaseTerm: number) => number;
  handleOnClose: () => void;
  hasValidRentMatrix: boolean;
  isRealPageIntegration: boolean;
  lease: Lease;
  openRentMatrix: () => void;
  quoteConcessions: {
    concessions: { id: string; description: string }[];
    concessionsStatusMessage: string;
  };
  unit: Unit;
  upchargeAmount?: number;
}

const CreateQuoteView: FC<CreateQuoteViewProps> = ({
  blackoutDatesMap,
  createQuote,
  createQuoteErrorMessage,
  fetchQuoteConcessions,
  getLeaseTermUpchargeAmount,
  handleOnClose,
  hasValidRentMatrix,
  isRealPageIntegration,
  lease,
  openRentMatrix,
  quoteConcessions,
  unit
}) => {
  const getEndDateInitialState = () => {
    if (hasValidRentMatrix) {
      return getInitialEndDate(
        lease.startDate,
        lease.termLength,
        blackoutDatesMap
      );
    }
  };

  const [errorMessages, setErrorMessages] = useState<QuoteErrorMessages>({});
  const [selectedConcessions, setSelectedConcessions] = useState({});
  const [leaseStartDate, setLeaseStartDate] = useState(lease.startDate);
  const [leaseEndDate, setLeaseEndDate] = useState<string | undefined>(
    getEndDateInitialState()
  );
  const [selectedLeaseTerm, setSelectedLeaseTerm] = useState(
    lease.termLength || 12
  );

  const [isCreatingQuote, setIsCreatingQuote] = useState(false);

  useEffect(() => {
    if (isRealPageIntegration && leaseStartDate && selectedLeaseTerm) {
      fetchQuoteConcessions(leaseStartDate, selectedLeaseTerm);
    }
  }, [
    fetchQuoteConcessions,
    isRealPageIntegration,
    leaseStartDate,
    selectedLeaseTerm
  ]);

  useEffect(() => {
    if (createQuoteErrorMessage) {
      setIsCreatingQuote(false);
    }
  }, [createQuoteErrorMessage]);

  const upchargeAmount = getLeaseTermUpchargeAmount(selectedLeaseTerm);

  const handleCreateQuote = () => {
    const { errorMessages } = validateLeaseDates(leaseStartDate, leaseEndDate);

    if (errorMessages) {
      setErrorMessages(errorMessages);
      return;
    }

    if (leaseStartDate && selectedLeaseTerm && leaseEndDate) {
      setIsCreatingQuote(true);
      createQuote(
        selectedConcessions,
        leaseStartDate,
        selectedLeaseTerm,
        leaseEndDate,
        upchargeAmount
      );
    }
  };

  return (
    <React.Fragment>
      <DialogTitle disableTypography>
        <Typography variant="h6">New Quote</Typography>
      </DialogTitle>

      <DialogContent className="create-quote-dialog-content" dividers>
        <ErrorAlert alertMessage={createQuoteErrorMessage} />

        <UnitDetails
          buildingName={unit.buildingName}
          monthlyRent={lease.monthlyRent || unit.displayPrice}
          upchargeAmount={upchargeAmount}
          unitName={unit.name}
        />

        <LeaseDetails
          blackoutDatesMap={blackoutDatesMap}
          errorMessages={errorMessages}
          hasValidRentMatrix={hasValidRentMatrix}
          leaseEndDate={leaseEndDate}
          leaseStartDate={leaseStartDate}
          openRentMatrix={openRentMatrix}
          setErrorMessages={setErrorMessages}
          setLeaseStartDate={setLeaseStartDate}
          selectedLeaseTerm={selectedLeaseTerm}
          setLeaseEndDate={setLeaseEndDate}
          setSelectedLeaseTerm={setSelectedLeaseTerm}
        />
        {isRealPageIntegration && (
          <Concessions
            concessions={quoteConcessions.concessions}
            concessionsStatusMessage={quoteConcessions.concessionsStatusMessage}
            selectedConcessions={selectedConcessions}
            setSelectedConcessions={setSelectedConcessions}
          />
        )}
      </DialogContent>

      <DialogActions className="create-quote-dialog-actions">
        <Button color="primary" onClick={handleOnClose} variant="outlined">
          Cancel
        </Button>
        <CircularProgressButton
          shouldShowProgress={isCreatingQuote}
          onClick={handleCreateQuote}
          progressText="Creating..."
        >
          Create
        </CircularProgressButton>
      </DialogActions>
    </React.Fragment>
  );
};

export default CreateQuoteView;
