/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('craigslistShareModalFactory', [
    '$modal',
    function ($modal) {
      var self = this;

      self.openCraigslistModal = function (listing) {
        $modal.open({
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl:
            '/angular/views/listing/listingIndex/craigslist-share-modal.html',
          controller: 'CraigslistShareModalController',
          size: 'lg',
          resolve: {
            listing: function () {
              return listing;
            }
          }
        });
      };

      return self;
    }
  ]);

  const CraigslistShareModalController = function (
    $scope,
    $location,
    $modalInstance,
    $window,
    craigslistApi,
    listing
  ) {
    var self = this;

    $scope.isLoading = true;

    $scope.data = {
      templateBody: ''
    };

    self._initialize = function () {
      craigslistApi.getListingAdBody(listing.id).then(function (response) {
        $scope.data.templateBody = response.data.template_body;
        $scope.isLoading = false;
      });
    };

    $scope.dismiss = function () {
      $modalInstance.dismiss();
    };

    $scope.openPostToClUrl = function () {
      $window.open('https://post.craigslist.org');
    };

    self._initialize();
  };

  CraigslistShareModalController.$inject = [
    '$scope',
    '$location',
    '$modalInstance',
    '$window',
    'craigslistApi',
    'listing'
  ];

  app.controller(
    'CraigslistShareModalController',
    CraigslistShareModalController
  );
})(window.angular);
