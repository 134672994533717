import React, { FC } from 'react';

export const FloorplanIcon: FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.125 9.4375V17.875C11.125 18.1857 10.8732 18.4375 10.5625 18.4375H2.125C1.81434 18.4375 1.5625 18.1857 1.5625 17.875V10C1.5625 9.68934 1.81434 9.4375 2.125 9.4375H6.0625"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 9.4375V2.125C4.375 1.81434 4.62684 1.5625 4.9375 1.5625H12.8125C13.1232 1.5625 13.375 1.81434 13.375 2.125V8.875C13.375 9.18566 13.1232 9.4375 12.8125 9.4375H9.4375"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.375 5.5H17.875C18.1857 5.5 18.4375 5.75184 18.4375 6.0625V12.8125C18.4375 13.1232 18.1857 13.375 17.875 13.375H11.125"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5625 13.9375H5.5V18.4375"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 16.1875H1.5625"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
