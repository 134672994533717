/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('navbarLinksController', [
    '$scope',
    '$window',
    '$auth',
    '$rootScope',
    '$routeParams',
    '$location',
    'renterAuthModalFactory',
    'managerAuthModalFactory',
    'ProfileService',
    'requestAccountModalFactory',
    'loginModalService',
    'signupModalService',
    'userService',
    'managerApi',
    'leasingTeamApi',
    'subscriptionsApi',
    'companyApi',
    'renterProfileEditorModalService',
    'apiEvents',
    'authenticationService',
    function (
      $scope,
      $window,
      $auth,
      $rootScope,
      $routeParams,
      $location,
      renterAuthModalFactory,
      managerAuthModalFactory,
      ProfileService,
      requestAccountModalFactory,
      loginModalService,
      signupModalService,
      userService,
      managerApi,
      leasingTeamApi,
      subscriptionsApi,
      companyApi,
      renterProfileEditorModalService,
      apiEvents,
      authenticationService
    ) {
      var self = this;
      $scope.visibleModals = {};

      $rootScope.$on('$routeChangeStart', function () {
        self._initialize();
      });

      self._initialize = function () {
        $scope.isOnboarding = $location.path() === '/manager/welcome';
        $scope.isRenterLandingPage = $location.path() === '/';
        $scope.isManagerLandingPage =
          $location.path().indexOf('/manager/landing') > -1;
        $scope.isLoginPage = $location.path().indexOf('/login') > -1;
      };

      self._initialize();

      $scope.getLinkClass = function (path) {
        var currentPath = $location.path();
        return currentPath.substr(currentPath.lastIndexOf(path)) === path
          ? 'active'
          : '';
      };

      $scope.isLanding = function () {
        var path = $location.path();

        return (
          path.indexOf('/product') > -1 ||
          path.indexOf('/pricing') > -1 ||
          path === '/'
        );
      };

      $scope.isInSearch = function () {
        return $location.path().indexOf('/search') > -1;
      };

      $scope.openLoginModal = function () {
        loginModalService.openModal();
      };

      $scope.openSignupModal = function () {
        signupModalService.openModal();
      };

      $scope.openRequestAccountModal = function () {
        requestAccountModalFactory.openRequestAccountModal();
      };

      $scope.openRenterAuthModal = function () {
        renterAuthModalFactory.openRenterAuthModal();
      };

      $scope.openManagerAuthModal = function () {
        managerAuthModalFactory.openManagerAuthModal();
      };

      $scope.requestAccountModal = function () {
        requestAccountModalFactory.openRequestAccountModal();
      };

      $scope.openRenterProfileEditor = function () {
        renterProfileEditorModalService.openModal();
      };

      $scope.teamMembers = [];

      $scope.$watch('navbarProfile', function () {
        $scope.isRenter =
          $scope.navbarProfile && $scope.navbarProfile.type === 'renter';
        $scope.isManager =
          $scope.navbarProfile && $scope.navbarProfile.type === 'manager';

        if ($scope.isManager) {
          // TODO IDENTITY
          $scope.isTeamAdmin = true;

          $scope.isSubscriptionOwner = true;

          $scope.host = $location.host();

          managerApi.getMyKnockEmail().success(function (response) {
            var emailRelay = response.knock_email.email_relay;
            $scope.knock_mailer_id = emailRelay
              ? emailRelay.username
              : undefined;
          });
        }

        $scope.isUnauthenticated = !$scope.navbarProfile;
      });

      $scope.memberLogin = function (member) {
        teamApi
          .memberLogin(member.manager_id)
          .success(function (res) {
            $auth.setToken(res.token);
            $window.location.reload();
          })
          .error(function () {
            $scope.memberLoginError = 'unable to login, please try again later';
          });
      };

      $rootScope.$on(apiEvents.profileUpdated, function () {
        self._loadProfile();
      });

      $rootScope.$on(apiEvents.loggedOut, function () {
        $scope.navbarProfile = null;
      });

      self._loadProfile = function () {
        try {
          ProfileService.getProfile().success(self._loadProfileSuccess);
        } catch (error) {
          $scope.navbarProfile = null;
        }
      };

      self._loadProfileSuccess = function (response) {
        if (response.profile) {
          response.profile.type = authenticationService.getUserType();
          $scope.navbarProfile = response.profile;
        }
      };

      self._loadProfile();
    }
  ]);

  app.directive('navbarLinks', [
    '$window',
    function ($window) {
      return {
        restrict: 'E',
        controller: 'navbarLinksController',
        controllerAs: 'navbarCtrl',
        templateUrl: '/angular/views/navigation/navbar.html',
        link: function (scope) {
          if (scope.isRenterLandingPage || scope.isManagerLandingPage) {
            var windowElement = angular.element($window);

            windowElement.on('scroll', function () {
              scope.$apply(function () {
                scope.scroll = windowElement.scrollTop();
              });
            });
          }
        }
      };
    }
  ]);

  app.directive('loginTopbar', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/navigation/login-topbar.html'
    };
  });

  app.directive('linksNotAuthenticated', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/navigation/links-not-logged-in.html',
      controller: [
        '$scope',
        function ($scope) {
          $scope.openMenu = function ($mdOpenMenu, ev) {
            $mdOpenMenu(ev);
          };
        }
      ]
    };
  });

  app.directive('linksRenter', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/navigation/links-renter.html'
    };
  });

  /*app.directive('linksManager', function(){
        return {
            restrict: 'E',
            templateUrl: '/angular/views/navigation/links-manager.html',
            controller: ['$scope', '$modal', 'managerPreferencesModalFactory', 'syncCalendarModalFactory',
                function($scope, $modal, managerPreferencesModalFactory, syncCalendarModalFactory){
                $scope.openCalendarSync = function(){
                    syncCalendarModalFactory.openCalendarSyncModal();
                };

                $scope.openPreferences = function(tabId) {
                    managerPreferencesModalFactory.openManagerPreferencesModal(tabId);
                };
            }]
        };
    });*/

  app.directive('notificationsDropdown', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/user/notifications-dropdown.html',
      controller: 'NotificationsDropdownController'
    };
  });

  app.controller('NotificationsDropdownController', [
    '$window',
    '$rootScope',
    '$scope',
    '$location',
    'notificationsService',
    'notificationsApi',
    'pageTitleService',
    function (
      $window,
      $rootScope,
      $scope,
      $location,
      notificationsService,
      notificationsApi,
      pageTitleService
    ) {
      var self = this;
      self._maxVisibleNotifications = 5;
      self._lastTitle = $window.document.title;

      $scope.notifications = [];
      $scope.unreadCount = 0;
      $scope.showingNotifications = false;

      $scope.dropdownToggled = function (isOpen) {
        if (!isOpen) {
          $scope.readAllNotifications();
        }

        $scope.showingNotifications = isOpen;
      };

      $scope.showNotificationHistory = function () {
        $location.url('/notifications');
      };

      $scope.readAllNotifications = function () {
        if ($scope.unreadCount > 0) {
          $scope.unreadCount = 0;

          forEach($scope.notifications, function (notification) {
            notification.isRead = true;
          });

          self._updateUnreadCount();

          var notificationIds = map($scope.notifications, 'id');
          notificationsApi.readNotifications(notificationIds);
        }
      };

      self._updateUnreadCount = function () {
        $scope.unreadCount = filter($scope.notifications, {
          isRead: false
        }).length;
        pageTitleService.setNotificationBadgeCount($scope.unreadCount);
      };

      self._initialize = function () {
        notificationsService.registerNotificationCallback(
          self._onNewNotification
        );
        notificationsApi
          .lastNNotifications(self._maxVisibleNotifications)
          .success(self._getLastNotificationsSuccess);
      };

      self._onNewNotification = function (rawNotification) {
        $scope.$apply(function () {
          var notification =
            notificationsService.createNotificationModel(rawNotification);
          $scope.notifications.unshift(notification);

          self._updateUnreadCount();
        });
      };

      self._getLastNotificationsSuccess = function (response) {
        var sortedNotifications = sortBy(
          response.notifications,
          'created_time'
        ).reverse();

        forEach(sortedNotifications, function (notificationResponse) {
          var notification =
            notificationsService.createNotificationModel(notificationResponse);
          $scope.notifications.push(notification);
        });

        self._updateUnreadCount();
      };

      self._initialize();
    }
  ]);
})();
