import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const EllipseIcon: React.FC = () => {
  return (
    <SvgIcon fontSize="large">
      <circle cx="4" cy="4" r="3.5" fill="#D21A32" stroke="white" />
    </SvgIcon>
  );
};
