/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('localCache', [
    '$moment',
    '$interpolate',
    '$interval',
    'localStorageService',
    function ($moment, $interpolate, $interval, localStorageService) {
      var self = this;

      var weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
      self.maxAge = 4 * weekInMilliseconds;
      self.cachePrefix = 'localCache';
      self.keyTemplate = $interpolate(self.cachePrefix + '[{{ key }}]');

      var LocalCache = {};

      LocalCache.clear = function () {
        localStorageService.clearAll();
      };

      LocalCache.get = function (key) {
        var cacheKey = getLocalCacheKey(key);
        var localObject = localStorageService.get(cacheKey);

        if (isUndefined(localObject)) {
          return;
        }

        var cachedObject = JSON.parse(localObject);
        var cachedValue;

        if (!isFresh(cachedObject)) {
          cachedValue = undefined;
          localStorageService.remove(cacheKey);
        } else {
          cachedValue = cachedObject.value;
        }

        return cachedValue;
      };

      LocalCache.put = function (key, value, ttl, ttlUnit) {
        var cacheKey = getLocalCacheKey(key);
        var objectToCache = buildCacheObject(value, ttl, ttlUnit);

        localStorageService.add(cacheKey, objectToCache);
      };

      LocalCache.putSession = function (key, value, ttl, ttlUnit) {
        var cacheKey = getLocalCacheKey(key);
        var objectToCache = buildCacheObject(value, ttl, ttlUnit);

        localStorageService.add(cacheKey, objectToCache);
      };

      LocalCache.del = function (key) {
        localStorageService.remove(getLocalCacheKey(key));
      };

      function buildCacheObject(value, ttl, ttlUnit) {
        var _ttl = ttl || self.maxAge;
        var _ttlUnit = ttlUnit || 'milliseconds';

        var objectToCache = {};
        objectToCache.value = value;
        objectToCache.expires = $moment() + $moment.duration(_ttl, _ttlUnit);

        return JSON.stringify(objectToCache);
      }

      function getLocalCacheKey(key) {
        return self.keyTemplate({ key: key });
      }

      function isFresh(object) {
        return (
          object &&
          (isUndefined(object.expires) ||
            $moment().isBefore($moment(object.expires)))
        );
      }

      function garbageCollection() {
        var localCacheRegex = new RegExp(self.cachePrefix + '\\[(.*?)\\]');

        for (var key in localStorage) {
          if (localCacheRegex.test(key)) {
            var localCacheKey = key.replace(localCacheRegex, '$1');
            // LocalCache.get will remove the cached data if stale
            LocalCache.get(localCacheKey);
          }
        }
      }

      $interval(garbageCollection, 30000);

      return LocalCache;
    }
  ]);

  app.factory('HttpCache', [
    '$q',
    'localCache',
    function ($q, localCache) {
      var HttpCache = function (maxAge) {
        this.maxAge = maxAge;
      };

      HttpCache.prototype.get = function (url) {
        var response = localCache.get(url);

        if (isPromise(response)) {
          return undefined;
        }

        return response;
      };

      HttpCache.prototype.put = function (url, response) {
        localCache.put(url, response, this.maxAge, 'seconds');
      };

      HttpCache.prototype.remove = function (url) {
        localCache.del(url);
      };

      function isPromise(response) {
        return !isEmpty(response) && !isEmpty(response.$$state);
      }

      return HttpCache;
    }
  ]);
})(window.angular);
