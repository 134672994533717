/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import includes from 'lodash/includes';
/*global escape: true */

(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('connectCalendarSelector', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/calendar/connect-calendar-selector.html',
      scope: {
        hideHeader: '='
      },
      controller: [
        '$scope',
        '$rootScope',
        '$window',
        '$location',
        '$auth',
        '$modal',
        'authApi',
        'OauthProviderApi',
        'confirmationModalFactory',
        'managerApi',
        'apiEvents',
        'WEBFRONT_BASE_URL',
        function (
          $scope,
          $rootScope,
          $window,
          $location,
          $auth,
          $modal,
          authApi,
          OauthProviderApi,
          confirmationModalFactory,
          managerApi,
          apiEvents,
          WEBFRONT_BASE_URL
        ) {
          var self = this;

          self._initialize = function () {
            $scope.showUnsyncedCalendars = false;
            self._loadConnectedProviders();
          };

          $scope.openExportCalendarModal = function () {
            $modal.open({
              // (BS) TODO: There is a bug in our angular dependencies that affects
              // event handling when animations are used with modals. Disabling
              // animations as a workaround, to permanently fix this we will need
              // to update our version of angular or rewrite this modal as an
              // mdDialog.
              animation: false,
              templateUrl:
                '/angular/views/util/exportCalendarButton/exportCalendarModal.html',
              controller: 'ExportCalendarModalController',
              size: 'lg'
            });
          };

          $scope.connectToOauth = function (provider) {
            var callbackUrl = `${WEBFRONT_BASE_URL}/manager/calendar`;
            var authToken = $auth.getToken();

            var providerConfig = authApi.oauthConfigurations[provider];

            if (providerConfig && providerConfig.endpoint) {
              $window.location.href =
                providerConfig.endpoint +
                '?callback=' +
                callbackUrl +
                '&authToken=' +
                authToken;
            } else {
              throw 'Unsupported oauth provider type';
            }
          };

          $scope.tryDisconnectProvider = function (provider) {
            if ($scope.isProviderConnected(provider)) {
              var message =
                'Are you sure you wish to remove ' +
                provider +
                ' calendar sync from your account?';
              var modal = confirmationModalFactory.createModal(
                'Remove Calendar Sync',
                message,
                function () {
                  self._disconnectProvider(provider);
                }
              );

              $modal.open(modal);
            }
          };

          self._disconnectProvider = function (provider) {
            $scope.connectedProviders = null;

            OauthProviderApi.deleteUserProvider(provider).success(function () {
              $rootScope.$emit(apiEvents.calendarProviderUnsynced);
              self._loadConnectedProviders();
            });
          };

          $scope.isProviderConnected = function (provider) {
            return includes($scope.connectedProviders, provider);
          };

          self._loadConnectedProviders = function () {
            OauthProviderApi.getUserConnectedProviderTypes().success(function (
              response
            ) {
              $scope.connectedProviders = response.providers;
            });

            managerApi.getExternalCalendars().success(function (response) {
              $scope.externalCalendarsByProvider = response.calendars;
            });
          };

          $scope.toggleCalendarSync = function (provider, calendar) {
            if (calendar.synced) {
              managerApi
                .unsyncExternalCalendar(provider, calendar.id)
                .success(function () {
                  $rootScope.$emit(
                    apiEvents.unsyncedExternalCalendar,
                    provider,
                    calendar.id
                  );
                });
            } else {
              managerApi
                .syncExternalCalendar(provider, calendar.id)
                .success(function (response) {
                  var syncedEvents = response.synced_events;
                  $rootScope.$emit(
                    apiEvents.syncedExternalCalendar,
                    provider,
                    calendar.id,
                    syncedEvents
                  );
                });
            }
          };

          self._initialize();
        }
      ]
    };
  });
})();
