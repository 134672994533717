import React, { FC, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
  FormHelperText
} from '@material-ui/core';
import classNames from 'classnames';

import {
  makeStyles,
  Button,
  Box,
  Text,
  ThemeProvider,
  NamedColors
} from '@knockrentals/knock-shared-web';
import { CircularProgressButton } from './CircularProgressButton';
import { colors, useCommonStyles } from './commonStyles/commonStyles';
import { ActivityData } from '../../../app/services/prospects/entities';

const useStyles = makeStyles(() => ({
  paperWidth: {
    width: '600px'
  },

  dialogContent: {
    paddingBottom: 0,
    paddingTop: 0
  },

  dialogActions: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
    padding: '12px 16px 16px 16px'
  },

  textInput: {
    '& .MuiInputLabel-formControl': {
      left: '0',
      margin: 0,
      transform: 'none'
    },

    '&.MuiFormControl-root': {
      marginBottom: 0
    },

    '& .MuiInputBase-root': {
      marginTop: 0,

      '&.Mui-focused': {
        border: `2px solid ${NamedColors.denim[300]}`,
        borderRadius: '2px'
      },

      '&.Mui-error': {
        border: `2px solid ${colors.error}`,
        borderRadius: '2px'
      }
    },

    '& .MuiInputBase-input': {
      padding: '0 5px',
      color: NamedColors.slate[700],
      minHeight: '44px',
      lineHeight: '22px'
    },

    '& .MuiInputBase-formControl': {
      borderColor: NamedColors.slate[300],
      marginTop: '0 !important'
    },

    '& .MuiTypography-root': {
      fontSize: '14px'
    },

    '& .MuiFormHelperText-root': {
      color: colors.error,
      marginLeft: '4px'
    }
  },

  textInputError: {
    '& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-focused': {
      color: colors.error
    }
  },

  helperText: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  validationError: {
    color: colors.error
  },

  counter: {
    marginRight: '4px'
  }
}));

const MISSING_NOTE_ERROR = 'Please add a note';
const MAX_NOTE_LENGTH = 500;

interface NoteModalProps {
  open: boolean;
  closeModal: () => void;
  addActivity: (payload: ActivityData) => Promise<any>;
}

const NoteModal: FC<NoteModalProps> = ({ open, closeModal, addActivity }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);

  const handleClose = () => {
    closeModal();
  };

  const handleSubmit = async () => {
    try {
      if (!note) {
        setHasValidationError(true);
        return;
      }

      setLoading(true);

      await addActivity({
        prospect_ids: [],
        type: 'note',
        message: note,
        reminder_time: ''
      });

      setLoading(false);
      closeModal();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider>
      <Dialog
        classes={{ paperWidthSm: classes.paperWidth }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>New Note</DialogTitle>

        <DialogContent className={classes.dialogContent} dividers={false}>
          <Box>
            <TextField
              value={note}
              label={<Text variant="subtitle1">Note</Text>}
              className={classNames(classes.textInput, {
                [classes.textInputError]: hasValidationError
              })}
              multiline={true}
              fullWidth={true}
              inputProps={{ maxLength: 500, 'data-testid': 'note' }}
              InputLabelProps={{ shrink: true }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setNote(event.target.value)
              }
              error={hasValidationError}
            />
          </Box>

          <FormHelperText className={classes.helperText}>
            {hasValidationError ? (
              <Typography variant="caption" className={classes.validationError}>
                {MISSING_NOTE_ERROR}
              </Typography>
            ) : (
              '\u200B'
            )}

            {note.length >= 475 && (
              <Typography
                variant="caption"
                className={classes.counter}
                data-testid="note-length-count"
              >
                {note.length} / {MAX_NOTE_LENGTH}
              </Typography>
            )}
          </FormHelperText>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>

          <CircularProgressButton
            onClick={handleSubmit}
            shouldShowProgress={loading}
            progressText={'Saving...'}
            className={commonClasses.primaryButton}
          >
            Save
          </CircularProgressButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default NoteModal;
