import React, { FC } from 'react';
import { Backdrop } from '@material-ui/core';

import { CircularProgress, makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  progressIndicator: {
    color: '#fff'
  }
});

export interface LoadingOverlayProps {
  className?: string;
  open: boolean;
  progressClassName?: string;
}

export const LoadingOverlay: FC<LoadingOverlayProps> = ({
  progressClassName,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Backdrop
      style={{ position: 'absolute', opacity: 0.5 }}
      {...rest}
      data-testid="loading-overlay"
    >
      <CircularProgress
        className={
          classes.progressIndicator +
          (progressClassName ? ' ' + progressClassName : '')
        }
      />
    </Backdrop>
  );
};
