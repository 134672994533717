/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import uniq from 'lodash/uniq';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import has from 'lodash/has';
import forOwn from 'lodash/forOwn';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import isString from 'lodash/isString';
import includes from 'lodash/includes';
import isNull from 'lodash/isNull';
import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const ManagerCalendarController = function (
    $rootScope,
    $scope,
    $q,
    $modal,
    appDataService,
    localCache,
    timeService,
    userService,
    $timeout,
    $routeParams,
    confirmationModalFactory,
    newEventModalService,
    managerCalendarService,
    managerCalendarEventsService,
    conversationsService,
    prospectsApi,
    calendarApiService,
    calendarDataRepository,
    managerApi,
    teamApi,
    LoadingProgressService,
    apiEvents,
    changeTimeModalFactory,
    primeTimeDialogFactory
  ) {
    var self = this;
    var requiredListingIds = [];
    var requiredPropertyIds = [];

    if (!isEmpty($routeParams.renterId) && !isEmpty($routeParams.listingId)) {
      $rootScope.$emit(
        apiEvents.viewProspect,
        $routeParams.renterId,
        $routeParams.listingId
      );
    }

    $scope.closePopovers = function (clickedPopover) {
      forEach($scope.eventPopovers, function (popover) {
        if (popover) {
          var isSameAsClicked =
            clickedPopover &&
            popover.$scope.event.id === clickedPopover.$scope.event.id;
          if (!isSameAsClicked) {
            popover.hide();
          }
        }
      });
    };

    $scope.user = userService.getScopedUser();

    $scope.initializeSelectedManagers = function () {
      $scope.calendarSettings.selectedManagers = reduce(
        $scope.unsortedTeamMembers,
        function (selectedManagers, teamMember) {
          if (teamMember.managerId !== $scope.user.id) {
            selectedManagers[teamMember.managerId] =
              $scope.calendarSettings.allSelected;
          }

          return selectedManagers;
        },
        {}
      );
    };

    $scope.eventSources = [];
    $scope.eventPopovers = {};

    $scope.sidebarSettings = {
      start: timeService.now().format(),
      end: timeService.now().add(1, 'months').format()
    };

    $scope.sidebarData = {
      requestsByManager: null,
      appointmentsByManager: null,
      colorMap: {}
    };

    $scope.calendarSettings = {
      start: timeService.now().startOf('week').format(),
      end: timeService.now().endOf('week').format(),
      selectedManagers: null,
      showEveryone: true,
      allSelected: true
    };

    $scope.$watch(
      'calendarSettings.showEveryone',
      function (newSetting, oldSetting) {
        if (!isNull(newSetting) && newSetting !== oldSetting) {
          if (newSetting) {
            $scope.calendarSettings.allSelected = newSetting;
            $scope.initializeSelectedManagers();
          }

          managerApi
            .updateDisplayTeam($scope.calendarSettings.showEveryone)
            .success(function () {
              self._initialize();
            });
        }
      }
    );

    var subscribedEvents = [
      'manager-appointment-cancellation',
      'manager-appointment-confirmation',
      'manager-appointment-request-cancelled',
      'manager-appointment-requested'
    ];

    var eventHandlers = [];

    self.addEventHandler = function (event, handler) {
      eventHandlers.push($rootScope.$on(event, handler));
    };

    $scope.$on('$destroy', function () {
      forEach(eventHandlers, function (destroy) {
        destroy();
      });
    });

    self.addEventHandler('newNotification', function (event, rawNotification) {
      var eventType = rawNotification.event;

      if (includes(subscribedEvents, eventType)) {
        self._initialize();
      }
    });

    self.addEventHandler('unsyncedExternalCalendar', function () {
      self._initialize();
    });

    self.addEventHandler('syncedExternalCalendar', function () {
      self._initialize();
    });

    self.addEventHandler(apiEvents.calendarProviderUnsynced, function () {
      self._initialize();
    });

    self.addEventHandler(
      managerCalendarEventsService.eventTypes.refreshCalendar,
      function () {
        self._initialize();
      }
    );

    self.addEventHandler(
      managerCalendarEventsService.eventTypes.reminders.wasAdded,
      function () {
        self._initialize();
      }
    );

    self.addEventHandler(
      managerCalendarEventsService.eventTypes.managerProfile.wasUpdated,
      function () {
        self._initialize();
      }
    );

    self.addEventHandler(
      managerCalendarEventsService.eventTypes.calendarEvents.wasAdded,
      function () {
        self._initialize();
      }
    );

    self.addEventHandler(
      managerCalendarEventsService.eventTypes.primeTimes.wereModified,
      function () {
        self._initialize();
      }
    );

    self.addEventHandler(
      managerCalendarEventsService.eventTypes.visit.wasAdded,
      function () {
        self._initialize();
      }
    );

    self.addEventHandler(
      managerCalendarEventsService.eventTypes.visit.wasRemoved,
      function () {
        self._initialize();
      }
    );

    self.addEventHandler(
      managerCalendarEventsService.eventTypes.confirmedAppointment
        .changedManager,
      function (event, appointment) {
        delete $scope.eventPopovers[appointment.id];
      }
    );

    self.addEventHandler(
      managerCalendarEventsService.eventTypes.visitedAppointment.changedManager,
      function (event, appointment, newManager, visit) {
        delete $scope.eventPopovers['visit-' + visit.id];
      }
    );

    const calendarEventClasses = {
      event: 'calendar-event',
      busy: 'calendar-event-busy',
      confirmed: 'calendar-event-confirmed',
      now: 'calendar-event-now',
      pending: 'calendar-event-pending',
      primetime: 'calendar-event-primetime'
    };

    $scope.calendarUIEvents = {
      onViewRender: function (view) {
        if (!$scope.isCalendarInitialized) {
          return;
        }

        var intervalStart = view.intervalStart.format();
        var intervalEnd = view.intervalEnd.format();

        $scope.calendarSettings.start = timeService.get(intervalStart).format();
        $scope.calendarSettings.end = timeService.get(intervalEnd).format();

        var reloadPromises = [
          self._loadMyCalendar(),
          self._loadExternalCalendars(),
          calendarApiService.getProspects(
            $scope.calendarSettings.start,
            $scope.calendarSettings.end
          )
        ];

        $q.all(reloadPromises).finally(function () {
          self._loadPropertyData().then(function () {
            $rootScope.$emit(apiEvents.calendarEventsUpdated);
          });
        });
      },
      onEventRender: function (calendarEvent, eventElement) {
        if (calendarEvent.className[0] === calendarEventClasses.pending) {
          managerCalendarService.eventElements[calendarEvent.id] = eventElement;
        }
      },
      onEventAfterRender: function (calendarEvent, eventElement) {
        if (calendarEvent.className[0] === calendarEventClasses.busy) {
          self._renderCalendarToolTip(eventElement, 'Busy');
        }

        if (calendarEvent.className[0] === calendarEventClasses.confirmed) {
          const title = self._setupTourTypeToolTipMessage(calendarEvent);
          self._renderCalendarToolTip(eventElement, title);
        }
      },
      onEventClicked: function (
        calendarEvent,
        calendarElement,
        view,
        eventElement
      ) {
        var className = calendarEvent.className[0];
        var isCalendarEvent =
          className === calendarEventClasses.event ||
          className === calendarEventClasses.busy;

        if (isCalendarEvent && calendarEvent.extra.prospect_id) {
          prospectsApi
            .getProspect(calendarEvent.extra.prospect_id)
            .success(function (response) {
              conversationsService.openThreadById(
                response.prospect.stream_id,
                true
              );
            });
          return;
        }

        if (isCalendarEvent) {
          newEventModalService.openModal(calendarElement, null, calendarEvent);
          return;
        }

        if (className === calendarEventClasses.primetime) {
          primeTimeDialogFactory.openPrimeTimeDialog(
            calendarDataRepository.getListing(calendarEvent.listingId)
          );
          return;
        }

        if (!$scope.eventPopovers[calendarEvent.id]) {
          self._setupEventPopover(
            calendarEvent,
            calendarElement,
            view,
            eventElement
          );
        }
      },
      onCalendarUpdated: function () {
        self._initialize();
      },
      onEventTimeChanged: function (calendarEvent, delta, revertFunction) {
        var startTime = timeService.get(calendarEvent.start.format());
        var endTime = timeService.get(calendarEvent.end.format());

        var appointment = calendarDataRepository.getAppointment(
          calendarEvent.id
        );

        if (calendarEvent.className[0] !== calendarEventClasses.busy) {
          var result = changeTimeModalFactory.openChangeTimeModal(
            calendarEvent,
            appointment
          );

          result.then(function () {
            delete $scope.eventPopovers[calendarEvent.id];
          }, revertFunction);
        } else {
          calendarApiService.changeAppointmentTime(
            appointment,
            startTime,
            endTime
          );
        }
      },
      onDayClicked: function (date, calendar) {
        var startTime = date.format();
        var placeholderEvent = managerCalendarService.renderPlaceholderEvent(
          startTime,
          calendar
        );
        var isAllDay = !date.hasTime();

        $scope.eventPopovers = {}; // There seems to be a focus issue with reopening popovers after we click a day.
        // This will clear out the cached popovers and allow them to open again.

        newEventModalService
          .openModal(calendar, { start: startTime, isAllDay: isAllDay })
          .then(
            function () {
              calendar.fullCalendar('removeEvents', placeholderEvent.id);
            },
            function () {
              calendar.fullCalendar('removeEvents', placeholderEvent.id);
            }
          );
      },
      onRangeSelected: function (start, end, calendar) {
        start = timeService.get(start.format());
        end = timeService.get(end.format());

        var isDayClick = timeService.get(start).add(15, 'minutes').isSame(end);
        if (isDayClick) {
          return;
        }

        newEventModalService.openModal(calendar, {
          start: start,
          end: end,
          isAllDay: false
        });
      }
    };

    self._renderCalendarToolTip = function (eventElement, title) {
      $(eventElement).tooltip({
        title,
        container: 'body',
        placement: 'bottom'
      });
    };

    self._setupTourTypeToolTipMessage = function (calendarEvent) {
      const { tour_type } = calendarDataRepository.getAppointment(
        calendarEvent.id
      );

      switch (tour_type) {
        case 'live_video':
          return 'Live Video';
        case 'self_guided':
          return 'Self Guided Tour';
        default:
          return 'Guided Tour';
      }
    };

    self._setupEventPopover = function (
      calendarEvent,
      calendarElement,
      view,
      eventElement
    ) {
      var popover = null;

      if (
        isString(calendarEvent.id) &&
        calendarEvent.id.startsWith('reminders')
      ) {
        popover = managerCalendarService.attachReminderPopover(
          $scope,
          calendarEvent,
          eventElement,
          view,
          calendarElement
        );
      } else if (
        isString(calendarEvent.id) &&
        calendarEvent.id.startsWith('visit')
      ) {
        popover = managerCalendarService.attachVisitPopover(
          $scope,
          calendarEvent,
          eventElement,
          view,
          calendarElement
        );
      } else {
        popover = managerCalendarService.attachEventPopover(
          $scope,
          calendarEvent,
          eventElement,
          view,
          calendarElement
        );
      }

      if (popover) {
        $scope.eventPopovers[calendarEvent.id] = popover;

        popover.$promise.then(function () {
          $timeout(function () {
            popover.show();
          });
        });

        $scope.$apply();
      }
    };

    self._initialize = function () {
      $scope.eventSources = [];
      $scope.timezone = timeService.getFullTimezoneName();

      var progressService = self._setupProgressService();

      self._setTeamMembers();

      var initializePromises = [
        self._loadMyCalendar(),
        self._loadExternalCalendars(),
        self._loadMyTeamAppointments(),
        calendarApiService.getProspects(
          $scope.calendarSettings.start,
          $scope.sidebarSettings.end
        )
      ];

      progressService.go();

      return $q.all(initializePromises).then(function () {
        self._loadPropertyData().then(function () {
          $scope.isCalendarInitialized = true;
          $rootScope.$emit(apiEvents.calendarEventsUpdated);
        }, self._onDataLoadError);
      }, self._onDataLoadError);
    };

    self._setupProgressService = function () {
      var minLoadingPercentPercent = 5;
      $scope.loadingPercentage = minLoadingPercentPercent;

      var progressUpdate = function (newPercent) {
        $scope.loadingPercentage = Math.max(
          minLoadingPercentPercent,
          newPercent
        );
      };

      var progressService = new LoadingProgressService(
        minLoadingPercentPercent
      );
      progressService.registerUpdateFunc(progressUpdate);

      return progressService;
    };

    self._loadMyCalendar = function () {
      return managerApi
        .getManagerCalendar(
          $scope.calendarSettings.start,
          $scope.calendarSettings.end
        )
        .success(function (response) {
          requiredPropertyIds = requiredPropertyIds.concat(
            map(response.calendar.appointments, 'prospect.property_id')
          );
          requiredPropertyIds = requiredPropertyIds.concat(
            map(response.calendar.requests, 'prospect.property_id')
          );
          requiredPropertyIds = requiredPropertyIds.concat(
            map(
              response.calendar.team_appointments,
              function (teamAppointment) {
                return teamAppointment.Appointment.prospect.property_id;
              }
            )
          );
          requiredPropertyIds = requiredPropertyIds.concat(
            map(
              response.calendar.team_appointment_requests,
              function (teamAppointment) {
                return teamAppointment.AppointmentRequest.prospect.property_id;
              }
            )
          );
          requiredListingIds = requiredListingIds.concat(
            map(response.calendar.manager_listings_prime_times, 'listing_id')
          );
          requiredListingIds = requiredListingIds.concat(
            map(
              response.calendar.team_listing_prime_times,
              function (listingsPrimeTime) {
                return listingsPrimeTime.ListingPrimeTime.listing_id;
              }
            )
          );

          $scope.eventPopovers = {};
          $scope.calendarSettings.showEveryone = isNull(
            response.calendar.preferences.display_team
          )
            ? true
            : response.calendar.preferences.display_team;
          $scope.calendarSettings.allSelected =
            $scope.calendarSettings.showEveryone;

          $scope.calendarSettings.managerPreferences =
            response.calendar.preferences;

          var myAppointments = []
            .concat(response.calendar.appointments)
            .concat(response.calendar.requests);

          var primeTimes = map(
            response.calendar.manager_prime_times,
            function (primeTime) {
              var listingPrimeTime = find(
                response.calendar.manager_listings_prime_times,
                { id: primeTime.listing_prime_time_id }
              );
              return {
                id: 'primetime-' + primeTime.id,
                start_time: primeTime.start_time,
                end_time: primeTime.end_time,
                listing_id: listingPrimeTime.listing_id,
                type: 'primetime',
                busy: listingPrimeTime.show_as_busy,
                manager_id: $scope.user.id
              };
            }
          );

          var teamPrimeTimes = map(
            response.calendar.team_prime_times,
            function (primeTime) {
              var listingPrimeTime = find(
                response.calendar.team_listing_prime_times,
                function (teamListingPrimeTime) {
                  return (
                    teamListingPrimeTime.ListingPrimeTime.id ===
                    primeTime.listing_prime_time_id
                  );
                }
              );

              return {
                id: 'primetime-' + primeTime.id,
                start_time: primeTime.start_time,
                end_time: primeTime.end_time,
                listing_id: listingPrimeTime.ListingPrimeTime.listing_id,
                type: 'primetime',
                busy: listingPrimeTime.ListingPrimeTime.show_as_busy,
                manager_id: listingPrimeTime.ManagerListing.manager_id
              };
            }
          );

          myAppointments = myAppointments.concat(primeTimes);

          calendarDataRepository.setVisits(response.calendar.visits);
          calendarDataRepository.setAppointments(
            response.calendar.appointments
          );
          calendarDataRepository.setRequests(response.calendar.requests);
          calendarDataRepository.setPrimeTimes(primeTimes);
          calendarDataRepository.setEvents(response.calendar.events);

          calendarDataRepository.setTeamVisits(
            map(response.calendar.team_visits, function (teamVisit) {
              return teamVisit.Visit;
            })
          );

          calendarDataRepository.setTeamAppointments(
            map(
              response.calendar.team_appointments,
              function (teamAppointment) {
                return teamAppointment.Appointment;
              }
            )
          );

          calendarDataRepository.setTeamAppointmentRequests(
            map(
              response.calendar.team_appointment_requests,
              function (teamAppointmentRequest) {
                return teamAppointmentRequest.AppointmentRequest;
              }
            )
          );

          calendarDataRepository.setTeamPrimeTimes(teamPrimeTimes);

          var myRemindersEventSource = self._getMyRemindersEventSource(
            response.calendar.team_reminders
          );
          self._addEventSource(myRemindersEventSource);

          var myVisitsEventSource = managerCalendarService.newEventSource(
            'myVisits',
            response.calendar.visits,
            managerCalendarService.mapVisit
          );
          self._addEventSource(myVisitsEventSource);

          var myAppointmentsEventSource = managerCalendarService.newEventSource(
            'myAppointments',
            myAppointments,
            managerCalendarService.mapAppointment
          );
          self._addEventSource(myAppointmentsEventSource);

          var myEvents = filter(response.calendar.events, function (event) {
            return event.title !== 'Follow Up';
          });

          var myCalendarEventSource = managerCalendarService.newEventSource(
            'myEvents',
            myEvents,
            managerCalendarService.mapCalendarEvent
          );
          self._addEventSource(myCalendarEventSource);

          var officeHoursEventSource = managerCalendarService.newEventSource(
            'officeHours',
            response.calendar.office_hours,
            managerCalendarService.mapOfficeHourDay
          );
          self._addEventSource(officeHoursEventSource);

          var nowEvent = {
            id: 'now-event',
            title: '',
            start: timeService.get().format(),
            end: timeService.get().add(3, 'minutes').format(),
            className: calendarEventClasses.now,
            status: '',
            editable: false
          };

          var nowEventSource = managerCalendarService.newEventSource(
            'nowEvent',
            [nowEvent]
          );
          self._addEventSource(nowEventSource);

          if ($scope.calendarSettings.showEveryone) {
            var teamRequests = filter(
              response.calendar.team_appointment_requests,
              function (appointment) {
                var isManagerSelected =
                  $scope.calendarSettings.selectedManagers[
                    appointment.AppointmentRequest.manager_id
                  ];

                return isManagerSelected && appointment.status === 'pending';
              }
            );

            var teamAppointments = filter(
              response.calendar.team_appointments,
              function (appointment) {
                var isManagerSelected =
                  $scope.calendarSettings.selectedManagers[
                    appointment.Appointment.manager_id
                  ];

                return isManagerSelected;
              }
            );

            var teamVisits = filter(
              response.calendar.team_visits,
              function (visit) {
                var isManagerSelected =
                  $scope.calendarSettings.selectedManagers[
                    visit.Visit.manager_id
                  ];

                return isManagerSelected;
              }
            );

            var myTeamVisitsEventSource = managerCalendarService.newEventSource(
              'teamVisits',
              teamVisits,
              managerCalendarService.mapTeamVisit
            );
            self._addEventSource(myTeamVisitsEventSource);

            teamPrimeTimes = filter(teamPrimeTimes, function (primeTime) {
              return $scope
                .calendarSettings.selectedManagers[primeTime.manager_id];
            });

            var teamEvents = response.calendar.team_events;

            var remindersToShow = filter(
              response.calendar.team_reminders,
              function (reminder) {
                return $scope.calendarSettings.selectedManagers[
                  reminder.assigned_manager_id
                ];
              }
            );

            self._mapTeamMemberEventSources(
              teamAppointments,
              teamRequests,
              teamPrimeTimes,
              teamEvents,
              remindersToShow
            );
          }
        })
        .error(self._onDataLoadError);
    };

    self._getMyRemindersEventSource = function (reminders) {
      var myReminders = filter(reminders, function (reminder) {
        return reminder.assigned_manager_id === $scope.user.id;
      });

      var myRemindersByDate = groupBy(myReminders, function (reminder) {
        return reminder.calendar_event.date;
      });

      var mappedReminders = map(
        myRemindersByDate,
        managerCalendarService.mapGroupedReminders
      );

      return managerCalendarService.newEventSource(
        'myReminders',
        mappedReminders
      );
    };

    self._getTeamMemberRemindersEventSource = function (managerId, reminders) {
      var teamRemindersByDate = groupBy(reminders, function (reminder) {
        return reminder.calendar_event.date;
      });

      var mappedReminders = map(
        teamRemindersByDate,
        managerCalendarService.mapGroupedReminders
      );

      return managerCalendarService.newEventSource(
        'teamReminders' + managerId,
        mappedReminders
      );
    };

    self._mapTeamMemberEventSources = function (
      teamAppointments,
      teamRequests,
      teamPrimeTimes,
      teamEvents,
      teamReminders
    ) {
      var teamAppointmentsByManager =
        managerCalendarService.mapTeamAppointments(teamAppointments);
      var teamRequestsByManager =
        managerCalendarService.mapTeamRequests(teamRequests);
      var teamPrimeTimesByManager =
        managerCalendarService.mapTeamPrimeTimes(teamPrimeTimes);
      var teamEventsByManager =
        managerCalendarService.mapTeamEvents(teamEvents);

      forEach($scope.unsortedTeamMembers, function (teamMember) {
        if (teamMember.managerId === $scope.user.id) {
          return;
        }

        var requests = [];

        if (teamRequestsByManager[teamMember.managerId]) {
          forOwn(
            teamRequestsByManager[teamMember.managerId],
            function (requestsByRenter) {
              requests = requests.concat(requestsByRenter);
            }
          );
        }

        var appointments =
          teamAppointmentsByManager[teamMember.managerId] || [];
        var primeTimes = teamPrimeTimesByManager[teamMember.managerId] || [];
        var calendarEvents = filter(
          teamEventsByManager[teamMember.managerId] || [],
          function (event) {
            return event.title !== 'Follow Up';
          }
        );

        var events = []
          .concat(requests)
          .concat(appointments)
          .concat(primeTimes);

        var eventSource = managerCalendarService.newEventSource(
          'teamMember' + teamMember.managerId,
          events,
          function (event) {
            return managerCalendarService.mapTeamAppointment(event);
          }
        );

        eventSource.color = $scope.sidebarData.colorMap[teamMember.managerId];
        self._addEventSource(eventSource);

        var teamMemberCalendarEventSource =
          managerCalendarService.newEventSource(
            'teamMemberCalendarEvents' + teamMember.managerId,
            calendarEvents,
            managerCalendarService.mapCalendarEvent
          );
        teamMemberCalendarEventSource.color =
          $scope.sidebarData.colorMap[teamMember.managerId];
        self._addEventSource(teamMemberCalendarEventSource);

        var teamMemberReminders = filter(teamReminders, function (reminder) {
          return reminder.assigned_manager_id === teamMember.managerId;
        });

        var teamRemindersEventSource = self._getTeamMemberRemindersEventSource(
          teamMember.managerId,
          teamMemberReminders
        );
        self._addEventSource(teamRemindersEventSource);
      });
    };

    self._loadExternalCalendars = function () {
      return managerApi
        .getExternalCalendarEvents(
          $scope.calendarSettings.start,
          $scope.calendarSettings.end
        )
        .success(function (response) {
          var syncedProviderEvents = response.provider_events;

          forEach(syncedProviderEvents, function (providerEvents, providerId) {
            var mappedEvents =
              managerCalendarService.mapSyncedCalendarEvents(providerEvents);

            forEach(
              mappedEvents,
              function (syncedCalendarEvents, syncedCalendarId) {
                syncedCalendarEvents = filter(
                  syncedCalendarEvents,
                  function (event) {
                    return (
                      !has(event.start, 'dateTime') ||
                      event.start.dateTime !== event.end.dateTime
                    );
                  }
                );

                var eventSourceId = providerId + '_' + syncedCalendarId;
                var eventSource = managerCalendarService.newEventSource(
                  eventSourceId,
                  syncedCalendarEvents,
                  managerCalendarService.mapExternalEvent
                );

                self._addEventSource(eventSource);
              }
            );
          });
        })
        .error(function () {
          $rootScope.$emit(apiEvents.calendarProviderException);
        });
    };

    self._loadMyTeamAppointments = function () {
      return teamApi
        .getMyTeamAppointments(
          $scope.sidebarSettings.start,
          $scope.sidebarSettings.end
        )
        .success(function (response) {
          requiredPropertyIds = requiredPropertyIds.concat(
            map(response.appointments, function (appointment) {
              return appointment.Appointment.property_id;
            })
          );

          var requests = filter(
            response.appointment_requests,
            function (appointment) {
              return appointment.AppointmentRequest.status === 'pending';
            }
          );

          var proposals = filter(
            response.appointment_requests,
            function (appointment) {
              return appointment.AppointmentRequest.status === 'proposed';
            }
          );

          var upcoming = filter(response.appointments, function (appointment) {
            var startTime = timeService.get(appointment.Appointment.start_time);

            return (
              appointment.Appointment.status === 'confirmed' &&
              timeService.now().isBefore(startTime) &&
              appointment.Appointment.type !== 'busy'
            );
          });

          $scope.sidebarData.requestsByManager =
            managerCalendarService.mapTeamRequests(requests);
          $scope.sidebarData.appointmentsByManager =
            managerCalendarService.mapTeamAppointments(upcoming);
          $scope.sidebarData.proposalsByManager =
            managerCalendarService.mapTeamProposals(proposals);

          self._sortTeamMembers();
        })
        .error(self._onDataLoadError);
    };

    self._sortTeamMembers = function () {
      $scope.teamMembers = sortBy(
        $scope.unsortedTeamMembers,
        function (member) {
          // Put the current user first
          if (member.managerId === $scope.user.id) {
            return 'AAA';
          }

          // Put anyone with an appointment/request/proposal next
          var hasRequest =
            $scope.sidebarData.requestsByManager[member.managerId];
          var hasAppointment =
            $scope.sidebarData.appointmentsByManager[member.managerId];
          var hasProposal =
            $scope.sidebarData.proposalsByManager[member.managerId];

          if (hasRequest || hasAppointment || hasProposal) {
            return 'AAB' + member.firstName;
          }

          // The rest are sorted by name
          return 'Z' + member.firstName;
        }
      );
    };

    self._setTeamMembers = function () {
      var teamMembers = appDataService.getTeamMembers();

      var filteredManagers = filter(teamMembers, function (member) {
        return (
          !member.TeammateMetadata || !member.TeammateMetadata.streams_hidden
        );
      });

      $scope.unsortedTeamMembers = map(filteredManagers, function (member) {
        return {
          managerId: member.ManagerInfo.manager_id,
          firstName: member.ManagerInfo.first_name,
          lastName: member.ManagerInfo.last_name,
          color: member.ManagerInfo.color,
          accessLevel: 'admin'
        };
      });

      if (!$scope.calendarSettings.selectedManagers) {
        $scope.initializeSelectedManagers();
      }

      calendarDataRepository.setTeamMembers($scope.unsortedTeamMembers);
    };

    self._addEventSource = function (eventSource) {
      remove($scope.eventSources, function (existingEventSource) {
        return existingEventSource.id === eventSource.id;
      });

      $scope.eventSources.push(eventSource);
    };

    self._loadPropertyData = function () {
      return $q.all([
        self._loadRequiredListings(),
        self._loadRequiredProperties()
      ]);
    };

    self._loadRequiredListings = function () {
      requiredListingIds = uniq(requiredListingIds);

      return calendarApiService.getRequiredListings(requiredListingIds);
    };

    self._loadRequiredProperties = function () {
      requiredPropertyIds = uniq(requiredPropertyIds);

      return calendarApiService.getRequiredProperties(requiredPropertyIds);
    };

    self._onDataLoadError = function () {
      $scope.loadErrorMessage =
        'There was an error loading your calendar data. Please try again later.';
    };

    self._initialize();
  };

  ManagerCalendarController.$inject = [
    '$rootScope',
    '$scope',
    '$q',
    '$modal',
    'appDataService',
    'localCache',
    'timeService',
    'userService',
    '$timeout',
    '$routeParams',
    'confirmationModalFactory',
    'newEventModalService',
    'managerCalendarService',
    'managerCalendarEventsService',
    'conversationsService',
    'prospectsApi',
    'calendarApiService',
    'calendarDataRepository',
    'managerApi',
    'teamApi',
    'LoadingProgressService',
    'apiEvents',
    'changeTimeModalFactory',
    'primeTimeDialogFactory'
  ];

  app.controller('ManagerCalendarController', ManagerCalendarController);
})(window.angular);
