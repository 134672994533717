/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('listingReadyModalFactory', [
    '$modal',
    '$location',
    function ($modal, $location) {
      var self = this;

      self.openListingReadyModal = function (listing) {
        $modal.open({
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl: '/angular/views/listingIndex/listing-ready-modal.html',
          controller: 'ListingReadyModalController',
          size: 'lg',
          resolve: {
            listing: function () {
              return listing;
            }
          }
        });
        $location.url($location.path());
      };

      return self;
    }
  ]);

  app.controller('ListingReadyModalController', [
    '$scope',
    '$modalInstance',
    'craigslistShareModalFactory',
    'shareListingModalFactory',
    'listing',
    function (
      $scope,
      $modalInstance,
      craigslistShareModalFactory,
      shareListingModalFactory,
      listing
    ) {
      $scope.dismiss = function () {
        $modalInstance.dismiss();
      };

      $scope.openCraigslistModal = function () {
        craigslistShareModalFactory.openCraigslistModal(listing);
        $scope.dismiss();
      };

      $scope.openShareModal = function () {
        shareListingModalFactory.openShareModal(listing);
        $scope.dismiss();
      };
    }
  ]);
})(window.angular);
