/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('leadTrackingService', [
    '$q',
    '$routeParams',
    'apiBase',
    function ($q, $routeParams, apiBase) {
      var self = this;

      self.trackView = function () {
        var prospectId = self._getProspectId();
        var listingId = self._getListingId();
        var communityId = self._getCommunityId();
        var leasingTeamId = self._getLeasingTeamId();

        var deferred = $q.defer();

        if (isEmpty(prospectId)) {
          deferred.reject('prospect ID is required');
          return deferred.promise;
        }

        if (!isEmpty(listingId)) {
          return self._trackListingView(prospectId, listingId);
        }

        if (!isEmpty(communityId)) {
          return self._trackCommunityView(prospectId, communityId);
        }

        if (!isEmpty(leasingTeamId)) {
          return self._trackLeasingTeamView(prospectId, leasingTeamId);
        }

        deferred.reject('no trackable params found in route');
        return deferred.promise;
      };

      self._getProspectId = function () {
        return $routeParams.pid;
      };

      self._getListingId = function () {
        return $routeParams.listingId;
      };

      self._getCommunityId = function () {
        return $routeParams.communityId;
      };

      self._getLeasingTeamId = function () {
        return $routeParams.leasingTeamId;
      };

      self._trackListingView = function (prospectId, listingId) {
        return self._trackView('listing', listingId, prospectId);
      };

      self._trackCommunityView = function (prospectId, communityId) {
        return self._trackView('community', communityId, prospectId);
      };

      self._trackLeasingTeamView = function (prospectId, leasingTeamId) {
        return self._trackView('leasing-team', leasingTeamId, prospectId);
      };

      self._trackView = function (viewType, viewId, prospectId) {
        return apiBase.post(
          '/tracking/' + viewType + '/' + viewId + '/' + prospectId
        );
      };

      return self;
    }
  ]);
})(window.angular);
