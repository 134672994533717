/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  var residentStatusMap = {
    current: 'Current',
    former: 'Former',
    'on-notice': 'On Notice',
    renewing: 'Renewing'
  };

  app.constant('residentStatusMap', residentStatusMap);

  app.filter('residentStatus', [
    'residentStatusMap',
    function (residentStatusMap) {
      return function (status) {
        return residentStatusMap[status];
      };
    }
  ]);
})(window.angular);
