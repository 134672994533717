/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import size from 'lodash/size';
import filter from 'lodash/filter';
import find from 'lodash/find';
import some from 'lodash/some';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('syncCalendarModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openCalendarSyncModal = function () {
        $mdDialog.show({
          controller: 'SyncCalendarController',
          templateUrl: '/angular/views/calendar/sync-calendar.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true
        });
      };

      self.openIcsModal = function (provider) {
        $mdDialog.show({
          controller: 'IcsUrlController',
          templateUrl: '/angular/views/calendar/ics-sync.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: { provider: provider }
        });
      };

      return self;
    }
  ]);

  app.controller('SyncCalendarController', [
    '$rootScope',
    '$window',
    '$modal',
    '$location',
    '$scope',
    '$auth',
    '$mdDialog',
    'managerApi',
    'OauthProviderApi',
    'authApi',
    'confirmationModalFactory',
    'apiEvents',
    'syncCalendarModalFactory',
    function (
      $rootScope,
      $window,
      $modal,
      $location,
      $scope,
      $auth,
      $mdDialog,
      managerApi,
      OauthProviderApi,
      authApi,
      confirmationModalFactory,
      apiEvents,
      syncCalendarModalFactory
    ) {
      var self = this;

      $scope.close = function () {
        $mdDialog.hide();
      };

      $scope.openIcsModal = function (provider) {
        syncCalendarModalFactory.openIcsModal(provider);
      };

      $scope.connectToOauth = function (providerType) {
        var callbackUrl = encodeURI($location.absUrl());
        var authToken = $auth.getToken();

        var providerConfig = authApi.oauthConfigurations[providerType];

        if (providerConfig && providerConfig.endpoint) {
          self._redirectToProvider(providerConfig, callbackUrl, authToken);
        } else {
          throw 'Unsupported oauth provider type';
        }
      };

      $scope.tryDisconnectProvider = function (providerType) {
        if ($scope.isProviderConnected(providerType)) {
          $scope.close();

          var message =
            'Are you sure you wish to remove ' +
            providerType +
            ' calendar sync from your account?';
          var cancelHandler = function () {
            syncCalendarModalFactory.openCalendarSyncModal();
          };

          var modal = confirmationModalFactory.createModal(
            'Remove Calendar Sync',
            message,
            function () {
              self._disconnectProvider(providerType);
            },
            { cancelHandler: cancelHandler }
          );

          $modal.open(modal);
        }
      };

      self._disconnectProvider = function (providerType) {
        $scope.connectedProviders = null;

        OauthProviderApi.deleteUserProvider(providerType).success(function () {
          $rootScope.$emit(apiEvents.calendarProviderUnsynced);
          self._loadConnectedProviders();
        });
      };

      $scope.isProviderConnected = function (providerType) {
        return some($scope.connectedProviders, { provider_type: providerType });
      };

      $scope.connectedProviderText = function (providerType) {
        return $scope.providerNeedsResync(providerType) ? 'Resync' : 'Remove';
      };

      $scope.providerNeedsResync = function (providerType) {
        var provider = find($scope.connectedProviders, {
          provider_type: providerType
        });
        return provider && provider.has_sync_exception;
      };

      $scope.toggleCalendarSync = function (providerType, calendar) {
        if (calendar.synced) {
          managerApi
            .unsyncExternalCalendar(providerType, calendar.id)
            .success(function () {
              $rootScope.$emit(
                apiEvents.unsyncedExternalCalendar,
                providerType,
                calendar.id
              );
            });
        } else {
          managerApi
            .syncExternalCalendar(providerType, calendar.id)
            .success(function (response) {
              var syncedEvents = response.synced_events;
              $rootScope.$emit(
                apiEvents.syncedExternalCalendar,
                providerType,
                calendar.id,
                syncedEvents
              );
            });
        }
      };

      self._initialize = function () {
        self._loadConnectedProviders();
      };

      self._loadConnectedProviders = function () {
        OauthProviderApi.getUserConnectedProviders().success(function (
          response
        ) {
          $scope.connectedProviders = response.providers;
          $scope.providersNeedingResync = filter(
            $scope.connectedProviders,
            'has_sync_exception'
          );

          if (size($scope.connectedProviders) > 0) {
            self._loadProviderCalendars();
          }
        });
      };

      self._loadProviderCalendars = function () {
        return managerApi
          .getExternalCalendars()
          .success(function (response) {
            $scope.externalCalendarsByProvider = response.calendars;
          })
          .error(function () {
            $scope.hasSyncException = true;
          });
      };

      self._redirectToProvider = function (
        providerConfig,
        callbackUrl,
        authToken
      ) {
        $window.location.href =
          providerConfig.endpoint +
          '?callback=' +
          callbackUrl +
          '&authToken=' +
          authToken;
      };

      self._initialize();
    }
  ]);

  app.controller('IcsUrlController', [
    '$scope',
    '$mdDialog',
    'provider',
    'userCalendarApi',
    function ($scope, $mdDialog, provider, userCalendarApi) {
      var self = this;

      $scope.provider = provider;
      $scope.supportLinks = {
        'Mac calendar':
          'http://support.knockrentals.com/en/articles/77457-sync-knock-calendar-mac-calendar',
        Outlook:
          'http://support.knockrentals.com/en/articles/77423-sync-knock-outlook',
        'your calendar service':
          'http://support.knockrentals.com/en/collections/124371-calendar'
      };

      $scope.close = function () {
        $mdDialog.hide();
      };

      $scope.selectText = function ($event) {
        $event.target.select();
      };

      self._loadCalendarUrl = function () {
        $scope.isLoadingUrl = true;
        userCalendarApi
          .getCalendarUrl()
          .success(self._setCalendarUrl)
          .error(self._loadCalendarError)
          .finally(function () {
            $scope.isLoadingUrl = false;
          });
      };

      self._setCalendarUrl = function (response) {
        $scope.calendarUrl = response.url;
      };

      self._loadCalendarError = function () {
        $scope.errorMessage =
          'There was an error retrieving your calendar URL. Please try again later.';
      };

      self._loadCalendarUrl();
    }
  ]);
})(window.angular);
