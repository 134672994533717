/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('listingLeasingEditor', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/listing/listingEditor/listing-leasing-editor.html',
      controller: [
        '$scope',
        function ($scope) {
          $scope.availableDatePickerOpen = false;
          $scope.leaseMonthsInvalid = true;

          $scope.setLeaseMonthsDirty = function () {
            $scope.leaseMonthsDirty = true;
          };

          $scope.$watch(
            'listing.leasing.terms.leaseLengths',
            function (leaseLengths) {
              $scope.leaseMonthsInvalid = _hasNoTruthyValue(leaseLengths);
            },
            true
          );

          function _hasNoTruthyValue(toCheck) {
            return isEmpty(filter(toCheck, 'isAvailable'));
          }
        }
      ]
    };
  });
})();
