/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('customAmenity', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/listing/listingEditor/listing-editor-custom-amenity.html',
      controller: 'CustomAmenityController',
      scope: {
        onAddAmenity: '=',
        amenityType: '@'
      }
    };
  });

  app.controller('CustomAmenityController', [
    '$scope',
    function ($scope) {
      var self = this;
      $scope.maxLength = 50;
      $scope.isAddingAmenity = false;
      $scope.amenityTitle = '';

      $scope.startEditing = function () {
        $scope.isAddingAmenity = true;
      };

      $scope.save = function () {
        $scope.isAddingAmenity = false;

        if ($scope.amenityTitle && $scope.amenityTitle.length <= 50) {
          $scope.onAddAmenity(
            self._capitalizeFirstLetter($scope.amenityTitle),
            $scope.amenityType
          );
          $scope.amenityTitle = '';
        }
      };

      self._capitalizeFirstLetter = function (title) {
        return title.charAt(0).toUpperCase() + title.slice(1);
      };
    }
  ]);
})(window.angular);
