/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import size from 'lodash/size';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import filter from 'lodash/filter';
import every from 'lodash/every';
import omit from 'lodash/omit';
import includes from 'lodash/includes';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('schedulingSignup', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/scheduling/scheduling-requirements.html',
      scope: {
        propertyId: '=',
        listingId: '=',
        isRescheduling: '=',
        onFireReschedule: '=',
        timeSelections: '=',
        viewState: '=',
        onAppointmentRequested: '=',
        onAppointmentRequestQueued: '=',
        isAutoPrimeTime: '='
      },
      controller: [
        '$rootScope',
        '$anchorScroll',
        '$window',
        '$scope',
        '$route',
        '$q',
        '$interpolate',
        '$location',
        'userService',
        'signupService',
        'renterApi',
        'listingApi',
        'prospectsApi',
        'localCache',
        'schedulingService',
        'ProfileService',
        'loginModalService',
        'authApi',
        'apiEvents',
        'localStorageService',
        'cacheKeys',
        function (
          $rootScope,
          $anchorScroll,
          $window,
          $scope,
          $route,
          $q,
          $interpolate,
          $location,
          userService,
          signupService,
          renterApi,
          listingApi,
          prospectsApi,
          localCache,
          schedulingService,
          profileService,
          loginModalService,
          authApi,
          apiEvents,
          localStorageService,
          cacheKeys
        ) {
          var self = this;
          var user = userService.getUser();

          $scope.isLoggedIn = !isEmpty(user);
          $scope.saveStatusText = 'Requesting your tour...';

          $scope.signupUserInfo = {
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            type: 'renter',
            isActive: false
          };

          $scope.data = {
            profile: {}
          };

          $scope.nonProfileRequirements = {
            renterCanAffordListing: false
          };

          $scope.toggleRenterIncome = function () {
            if (
              !isUndefined($scope.nonProfileRequirements.renterCanAffordListing)
            ) {
              if ($scope.nonProfileRequirements.renterCanAffordListing) {
                $scope.data.profile.income = $scope.minimumMonthlyIncome;
                localCache.put(
                  self.getCanAffordCacheKey(),
                  true,
                  3600,
                  'seconds'
                );
              } else {
                localCache.del(self.getCanAffordCacheKey());
                $scope.data.profile.income = 0;
              }
              self._checkAgainstRequirements();
            } else {
              $scope.nonProfileRequirements.renterCanAffordListing = false;
            }
          };

          $scope.isManager = function () {
            var user = userService.getUser();

            return user && user.type === 'manager';
          };

          $scope.isContactInfoComplete = function () {
            if (!$scope.stillNeeds) {
              return false;
            }

            var requiredCompleted =
              $scope.isLoggedIn ||
              ($scope.appointmentRequirementsForm.email.$valid &&
                $scope.appointmentRequirementsForm.firstName.$valid &&
                $scope.appointmentRequirementsForm.lastName.$valid);

            return (
              requiredCompleted &&
              !$scope.stillNeeds.phone &&
              !$scope.stillNeeds.moveDate
            );
          };

          self.getCanAffordCacheKey = function () {
            return 'canAfford-' + $scope.listingId;
          };

          self._initialize = function () {
            var isRenter = user && user.type === 'renter';

            var initializePromises = [self._getListingRequirements()];

            if (isRenter) {
              initializePromises.push(self._getRenterInfo());
            } else {
              if (schedulingService.getProspectKnockId()) {
                initializePromises.push(self._getProspectInfo());
              }
            }

            $q.all(initializePromises).then(function () {
              self._setupForm();

              $scope.$watch(
                'data.profile',
                self._checkAgainstRequirements,
                true
              );
              $scope.$watch(
                'customRequirements',
                self._checkAgainstRequirements,
                true
              );
            });
          };

          self._getListingRequirements = function () {
            return listingApi
              .getListingRequirements($scope.listingId)
              .success(function (response) {
                $scope.listingRequirements = response.requirements;
                $scope.customRequirements = response.custom_requirements;
              });
          };

          self._getRenterInfo = function () {
            return renterApi.getRenterInfo().success(function (response) {
              $scope.data.profile = response.renter_info;

              if (isEmpty($scope.data.profile.pets)) {
                $scope.data.profile.pets = {
                  none: true
                };
              }
            });
          };

          self._getProspectInfo = function () {
            return prospectsApi
              .getProspectByKnockId(schedulingService.getProspectKnockId())
              .then(function (response) {
                $scope.data.profile = response.data.prospect.profile;

                if (
                  includes(
                    ['phone', 'sms', 'new', 'chat', 'email'],
                    $scope.data.profile.first_name.toLowerCase()
                  )
                ) {
                  $scope.data.profile.first_name = '';
                }

                if ($scope.data.profile.last_name.toLowerCase() === 'lead') {
                  $scope.data.profile.last_name = '';
                }

                if (isEmpty($scope.data.profile.pets)) {
                  $scope.data.profile.pets = {
                    none: true
                  };
                }
              });
          };

          self._checkAgainstRequirements = function () {
            $scope.stillNeeds = {};

            if ('phone_numbers' in $scope.reasonsByPolicy) {
              $scope.stillNeeds.phoneNumber = isEmpty(
                $scope.data.profile.phone
              );
            }

            if ('income_multiplier' in $scope.reasonsByPolicy) {
              $scope.stillNeeds.monthlyIncome =
                !$scope.nonProfileRequirements.renterCanAffordListing;
            }

            if ('pet_policy' in $scope.reasonsByPolicy) {
              var renterPets = omit($scope.data.profile.pets, 'notes');

              var isFalsey = function (value) {
                return value === false;
              };

              var hasProhibitedPet = function (petPolicy) {
                var prohibitedPet = petPolicy.pet;
                return renterPets[prohibitedPet] === true;
              };

              $scope.invalidNonePets =
                !('none' in renterPets) || every(renterPets, isFalsey);
              $scope.petConflicts = filter(
                $scope.reasonsByPolicy.pet_policy,
                hasProhibitedPet
              );
              $scope.stillNeeds.petInfo =
                some($scope.reasonsByPolicy.pet_policy, hasProhibitedPet) ||
                $scope.invalidNonePets;
            }

            if ('criminal_check' in $scope.reasonsByPolicy) {
              $scope.stillNeeds.criminalCheck =
                $scope.data.profile.is_criminal !== false;
            }

            if ('eviction_check' in $scope.reasonsByPolicy) {
              $scope.stillNeeds.evictionCheck =
                $scope.data.profile.was_evicted !== false;
            }

            if ('move_dates' in $scope.reasonsByPolicy) {
              $scope.stillNeeds.moveDate =
                !$scope.data.profile.target_move_date;
            }

            $scope.passedCustomRequirements = every(
              $scope.customRequirements,
              function (requirement) {
                return requirement.answer === requirement.desired_answer;
              }
            );

            $scope.passedRequirements =
              !some($scope.stillNeeds) && $scope.passedCustomRequirements;
            $scope.errorMessages = {};

            if (!$scope.passedRequirements) {
              if ($scope.stillNeeds.phoneNumber) {
                $scope.errorMessages.phoneNumber = [
                  'Manager requires a phone number.'
                ];
              }

              if ($scope.stillNeeds.monthlyIncome) {
                $scope.errorMessages.monthlyIncome = [
                  'Manager requires a monthly income of ' +
                    $scope.minimumMonthlyIncome
                ];
              }

              if ($scope.stillNeeds.petInfo) {
                if ($scope.invalidNonePets) {
                  $scope.errorMessages.petInfo = [
                    'Manager requires pet disclosure.'
                  ];
                } else {
                  $scope.errorMessages.petInfo = [];
                  forEach($scope.petConflicts, function (conflict) {
                    switch (conflict.pet) {
                      case 'cats':
                        $scope.errorMessages.petInfo.push(
                          'Property does not allow cats.'
                        );
                        break;
                      case 'small_dogs':
                        $scope.errorMessages.petInfo.push(
                          'Property does not allow small dogs.'
                        );
                        break;
                      case 'large_dogs':
                        $scope.errorMessages.petInfo.push(
                          'Property does not allow large dogs.'
                        );
                        break;
                    }
                  });
                }
              }

              if ($scope.stillNeeds.criminalCheck) {
                $scope.errorMessages.criminalCheck = [
                  'Property requires no criminal history.'
                ];
              }

              if ($scope.stillNeeds.evictionCheck) {
                $scope.errorMessages.evictionCheck = [
                  'Property requires no eviction history.'
                ];
              }

              if ($scope.stillNeeds.moveDate) {
                $scope.errorMessages.moveDate = [
                  'Manager requires a target move date.'
                ];
              }
            }
          };

          self._setupForm = function () {
            $scope.reasonsByPolicy = groupBy(
              $scope.listingRequirements,
              'policy'
            );
            $scope.needs = {};

            if ('phone_numbers' in $scope.reasonsByPolicy) {
              $scope.needs.phoneNumber = true;
            }

            if ('income_multiplier' in $scope.reasonsByPolicy) {
              var incomePolicy = $scope.reasonsByPolicy.income_multiplier[0];
              $scope.minimumMonthlyIncome =
                incomePolicy.rent * incomePolicy.multiplier;
              $scope.incomeMultiplier = incomePolicy.multiplier;

              $scope.needs.monthlyIncome = true;
              $scope.showRequirementsForm = true;
            }

            if ('pet_policy' in $scope.reasonsByPolicy) {
              $scope.data.profile.pets = !isEmpty($scope.data.profile.pets)
                ? $scope.data.profile.pets
                : { none: true };
              var renterPets = omit($scope.data.profile.pets, 'notes');

              $scope.needs.petInfo = true;
              var isFalsey = function (value) {
                return value === false;
              };

              $scope.$watch(
                'data.profile.pets',
                function (newRenterPets, oldRenterPets) {
                  renterPets = omit(newRenterPets, 'notes');
                  $scope.showRequirementsForm = true;
                  $scope.appointmentRequirementsForm.$invalid = every(
                    renterPets,
                    isFalsey
                  );

                  // prevent infinite iterations
                  if (newRenterPets.none !== oldRenterPets.none) {
                    return;
                  }

                  $scope.data.profile.pets.none = every(
                    renterPets,
                    function (hasPet, pet) {
                      return pet === 'none' || hasPet === false;
                    }
                  );
                },
                true
              );
            }

            if ('criminal_check' in $scope.reasonsByPolicy) {
              $scope.needs.criminalCheck = true;
              $scope.showRequirementsForm = true;
            }

            if ('eviction_check' in $scope.reasonsByPolicy) {
              $scope.needs.evictionCheck = true;
              $scope.showRequirementsForm = true;
            }

            if ('move_dates' in $scope.reasonsByPolicy) {
              $scope.needs.moveDate = true;
            }

            $scope.passedCustomRequirements = every(
              $scope.customRequirements,
              function (requirement) {
                return requirement.answer === requirement.desired_answer;
              }
            );

            $scope.showRequirementsForm =
              $scope.showRequirementsForm || !$scope.passedCustomRequirements;

            self._checkAgainstRequirements();
          };

          $scope.clearSelectedPets = function () {
            $scope.data.profile.pets.cats = false;
            $scope.data.profile.pets.small_dogs = false;
            $scope.data.profile.pets.large_dogs = false;
            $scope.data.profile.pets.none = true;
          };

          $scope.submitRequest = function () {
            $scope.showErrors = true;

            self._checkAgainstRequirements();

            if (
              !$scope.passedRequirements ||
              $scope.appointmentRequirementsForm.$invalid
            ) {
              return;
            }

            var choseAllTimes =
              size($scope.timeSelections) > 0 &&
              every($scope.timeSelections, 'selection');

            if (!choseAllTimes) {
              $scope.signupError = 'needsThreeTimes';
              return;
            }

            $scope.isRequesting = true;
            $scope.signupError = null;

            self._sendRequest();
          };

          self._sendRequest = function () {
            schedulingService
              .requestAppointment(
                $scope.timeSelections,
                $scope.propertyId,
                [$scope.listingId],
                $scope.isRescheduling,
                $scope.data.profile
              )
              .success(function (response) {
                $window.scrollTo(0, 0);

                if (response.status_code === 'queued_request') {
                  $scope.onAppointmentRequestQueued(response.queued_request);
                } else if (response.status_code === 'existing_queued_request') {
                  var isExistingQueuedRequest = true;
                  $scope.onAppointmentRequestQueued(
                    response.queued_request,
                    isExistingQueuedRequest
                  );
                } else if (response.status_code === 'requests_rejected') {
                  $scope.signupError = 'availableTimesConflict';
                } else if (response.status_code !== 'ok') {
                  $scope.signupError = 'requestAppointment';
                } else {
                  $scope.onAppointmentRequested(response.result);
                }
              })
              .error(function () {
                $scope.signupError = 'requestAppointment';
              })
              .finally(function () {
                $scope.isRequesting = false;
                $scope.isRescheduling = false;

                $location.hash('top');
                $anchorScroll();
              });
          };

          $scope.socialSignup = function (provider) {
            self._storeTimes();

            localStorageService.set(
              cacheKeys.loginRedirectPath,
              $location.path()
            );

            signupService.socialSignup(provider).then(function () {
              $route.reload();
              $rootScope.$emit(apiEvents.profileUpdated);
            }, self.handleLoginError);
          };

          self.handleLoginError = function (res) {
            var statusCode = res.status;
            if (statusCode === 409) {
              $scope.signupError = 'alreadyExists';
            } else {
              $scope.signupError = 'generic';
            }

            $scope.isRequesting = false;
          };

          $scope.openLoginModal = function () {
            self._storeTimes();
            loginModalService.openModal();
          };

          self._storeTimes = function () {
            schedulingService.timeCache.setChosenTimes(
              $scope.timeSelections,
              $scope.listingId
            );
          };

          self._initialize();
        }
      ]
    };
  });
})(window.angular);
