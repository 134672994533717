export enum NextStepEnum {
  StillLooking = 'Still looking',
  NeedsToDiscussWithRoommate = 'Needs to discuss with roommate',
  NeedsToSeeExactUnit = 'Needs to see exact unit',
  NotReadyToLease = 'Not ready to lease',
  NeedsToSellHouse = 'Needs to sell house',
  ProspectHasFurtherQuestions = 'Prospect has further questions',
  ProspectPlansToApply = 'Prospect plans to apply',
  ProspectWantsToScheduleAnotherAppointment = 'Prospect wants to scheduled another appointment',
  WaitingOnJobApproval = 'Waiting on job approval',
  Other = 'Other'
}
