import React from 'react';

export const PeopleStackIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1065_50910)">
      <path
        d="M14.4057 5.01917L18.3941 5.87917C18.7162 5.94924 18.9973 6.14427 19.1758 6.42145C19.3542 6.69862 19.4153 7.03528 19.3457 7.3575L16.9774 18.29C16.9073 18.6121 16.7123 18.8932 16.4351 19.0717C16.158 19.2501 15.8213 19.3112 15.4991 19.2417L9.68492 17.9817"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.20589 6.30882L9.09164 3.7952C9.53014 3.65542 9.99889 3.89759 10.1387 4.33609L13.7849 15.7749C13.9247 16.2134 13.6825 16.6821 13.244 16.8219L5.35823 19.3355C4.91973 19.4754 4.45098 19.2332 4.31123 18.7947L0.664977 7.35588C0.525227 6.91738 0.767393 6.4486 1.20589 6.30882Z"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.54066 12.3701C6.39224 12.7377 5.16326 12.1048 4.79564 10.9564C4.42803 9.80793 5.061 8.57895 6.20942 8.21134C7.35784 7.84372 8.58683 8.47669 8.95444 9.62511C9.32205 10.7735 8.68908 12.0025 7.54066 12.3701Z"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.43206 16.2519C5.42568 15.4889 5.63355 14.7599 6.027 14.1654C6.42045 13.5708 6.98011 13.14 7.62881 12.9324C8.27751 12.7247 8.9833 12.7504 9.64887 13.006C10.3144 13.2615 10.907 13.7343 11.3449 14.3592"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.45911 5.27169V1.86919C4.47152 1.54228 4.60744 1.23224 4.83936 1.00153C5.07136 0.770815 5.38211 0.636578 5.70911 0.625854H13.0824C13.4197 0.621252 13.7457 0.747186 13.9923 0.977337C14.2389 1.20749 14.3871 1.52405 14.4057 1.86085V13.0475C14.4075 13.3527 14.297 13.6479 14.0953 13.8769C13.8935 14.1059 13.6146 14.2526 13.3116 14.2892"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1065_50910">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
