/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import groupBy from 'lodash/groupBy';
import size from 'lodash/size';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.controller('ListingDetailsController', [
    '$q',
    '$rootScope',
    '$scope',
    '$routeParams',
    '$filter',
    '$timeout',
    '$interval',
    '$location',
    '$anchorScroll',
    '$moment',
    '$window',
    'pageTitleService',
    '$auth',
    'loginService',
    '$pusher',
    'pusherKey',
    'listingApi',
    'appointmentsApi',
    'userService',
    'timeService',
    'directionsService',
    'appointmentBookedModalService',
    'localStorageService',
    'schedulingService',
    'appointmentRequestQueuedModalService',
    'chatTrackingService',
    '_',
    function (
      $q,
      $rootScope,
      $scope,
      $routeParams,
      $filter,
      $timeout,
      $interval,
      $location,
      $anchorScroll,
      $moment,
      $window,
      pageTitleService,
      $auth,
      loginService,
      $pusher,
      pusherKey,
      listingApi,
      appointmentsApi,
      userService,
      timeService,
      directionsService,
      appointmentBookedModalService,
      localStorageService,
      schedulingService,
      appointmentRequestQueuedModalService,
      chatTrackingService,
      _
    ) {
      chatTrackingService.hide();
      $rootScope.$on('SalesForceWidgetMounted', () => {
        chatTrackingService.hide();
      });
      var self = this;
      var dateFilter = $filter('date');
      var availableTimesIntervalMS = 1000 * 30;

      self.listingId = $routeParams.listingId;

      self._pusherInstance = null;

      var schedulingViewStates = {
        loading: 'loading',
        autoAccept: 'autoAccept',
        primeTime: 'primeTime',
        chooseTimes: 'chooseTimes',
        queuedRequest: 'queuedRequest',
        showBookedAppointment: 'showBookedAppointment',
        showRequestedAppointment: 'showRequestedAppointment',
        showProposedAppointments: 'showProposedAppointments',
        showVisitedAppointment: 'showVisitedAppointment',
        showDeactivatedListing: 'showDeactivatedListing',
        noSchedulingData: 'noSchedulingData'
      };

      $scope.scheduling = {
        hasSelected: false,
        isComplete: false,
        timeSelections: [],
        showActivationBox: false,
        selectedSlot: null,
        selectedDay: null
      };

      $scope.map = {
        center: {
          latitude: 42,
          longitude: -120
        },
        zoom: 15,
        options: {
          scrollwheel: false
        }
      };

      $scope.schedulingView = { state: schedulingViewStates.loading };

      $scope.isRescheduling = false;

      $scope.photoModal = {
        isLargePhotoModalVisible: false
      };

      var startTime = timeService.now().startOf('day').format();
      var endTime = timeService.now().endOf('day').add(2, 'weeks').format();

      $scope.openLargePhotoModal = function () {
        $scope.photoModal.isLargePhotoModalVisible = true;
      };

      $scope.onAppointmentRequestQueued = function (
        queuedRequest,
        isExistingQueuedRequest
      ) {
        var pusher = self._getPusherInstance();
        pusher.subscribe(queuedRequest.pusher_channel);
        pusher.bind('identity-verified', function (message) {
          $auth.setToken(message.access_token);
          $window.location.reload();
        });

        $scope.requestsCanceled = false;
        $scope.appointmentCanceled = false;

        self._removeAppointmentIdParam();

        appointmentRequestQueuedModalService.openModal(
          queuedRequest,
          isExistingQueuedRequest
        );

        $scope.schedulingView.state = schedulingViewStates.queuedRequest;
      };

      $scope.onAppointmentRequested = function (requestResult) {
        $scope.requestsCanceled = false;
        $scope.appointmentCanceled = false;

        self._removeAppointmentIdParam();

        if (requestResult) {
          self._openBookedModal(requestResult);
        }
        self._loadExistingAppointments();
      };

      $scope.onPrimeTimeRequested = function (request) {
        $scope.requestsCanceled = false;
        $scope.appointmentCanceled = false;

        self._removeAppointmentIdParam();

        self._openBookedModal(requestResult);
        self._loadExistingAppointments();
      };

      self._openBookedModal = function (requestResult) {
        if (requestResult.appointment) {
          appointmentBookedModalService.openModal(
            requestResult.appointment,
            $scope.listing
          );
        }
      };

      $scope.onRequestsCanceled = function () {
        $scope.requestedTimes = null;
        $scope.requestsCanceled = true;

        self._loadAvailableTimes();
      };

      $scope.onAppointmentCanceled = function () {
        $scope.appointment = null;
        $scope.appointmentCanceled = true;

        self._loadAvailableTimes();
      };

      $scope.onStartReschedule = function () {
        $scope.isRescheduling = true;

        self._loadAvailableTimes();
      };

      $scope.onFireReschedule = function () {
        var cancelPromise;

        if ($scope.requestedTimes && !isEmpty($scope.requestedTimes)) {
          cancelPromise = appointmentsApi.cancelRequests(
            $scope.requestedTimes[0].id
          );
        } else if ($scope.appointment) {
          cancelPromise = appointmentsApi.cancelAppointment(
            $scope.appointment.id
          );
        } else {
          throw 'Cannot fire reschedule without any appointments or requests!';
        }

        self._removeAppointmentIdParam();

        $scope.isRescheduling = false;
        return cancelPromise;
      };

      $scope.cancelReschedule = function () {
        $scope.isRescheduling = false;

        self._loadExistingAppointments();
      };

      $scope.viewDirections = function () {
        if ($scope.listing) {
          var address = $scope.listing.location.address.raw;
          directionsService.openDirectionsPage(address);
        }
      };

      self._initialize = function () {
        self.user = userService.getUser();
        $scope.userIsManager = self.user && self.user.type === 'manager';

        var initPromises = [self._getListing(), self._getCompany()];

        $q.all(initPromises);
      };

      self._getCompany = function () {
        return listingApi
          .getListingLeasingTeam(self.listingId)
          .success(function (response) {
            $scope.leasingTeamPublicId = response.leasing_team.public_id;
            $scope.hideUnitNumbers =
              response.leasing_team.preferences.hide_unit_numbers;
            $scope.showPriceRange =
              response.leasing_team.preferences.show_listing_price_ranges;
          });
      };

      self._getListing = function () {
        return listingApi
          .getListing(self.listingId)
          .success(self._getListingSuccess)
          .error(self._getListingError);
      };

      self._getListingSuccess = function (response) {
        $scope.listing = response.listing;

        var numBed = $scope.listing.floorplan.bedrooms + ' bd ';
        var numBath = $scope.listing.floorplan.bathrooms + 'ba';
        var singleFamilyListingTitle =
          '$' +
          $scope.listing.leasing.monthlyRent +
          ' ' +
          numBed +
          numBath +
          ' | ' +
          $scope.listing.location.address.raw;
        var multiFamilyListingTitle =
          $scope.listing.location.name +
          ' - ' +
          numBed +
          numBath +
          ' - Schedule a tour!';

        var pageTitle = isEmpty($scope.listing.community_id)
          ? singleFamilyListingTitle
          : multiFamilyListingTitle;
        pageTitleService.setPageTitle(pageTitle);

        var availableDate = self.determineAvailableDate(
          $scope.listing.leasing.availableDate
        );

        if (timeService.now().isAfter(availableDate)) {
          $scope.listingAvailableDate = 'Today';
        } else {
          $scope.listingAvailableDate = dateFilter(
            availableDate.format(),
            'EEEE, MMM. d'
          );
        }

        if (!$scope.listing) {
          self._getListingError();
          return;
        }

        $scope.map.center = {
          latitude: $scope.listing.location.geo.coordinates[1],
          longitude: $scope.listing.location.geo.coordinates[0]
        };

        $scope.map.marker = {
          coords: {
            latitude: $scope.listing.location.geo.coordinates[1],
            longitude: $scope.listing.location.geo.coordinates[0]
          },
          icon: '/images/marker.png',
          id: 'listingMarker'
        };

        // If the listing is part of a community, show the name field
        if ($scope.listing.floorplan.community_id) {
          $scope.communityName = $scope.listing.location.name;
        }

        // Now that we have the listing, load up the appointments or available times
        if (!isEmpty(self.user) || !isEmpty($routeParams.appointmentId)) {
          self._loadExistingAppointments();
        } else {
          self._loadAvailableTimes();

          $interval(
            function () {
              if (self._shouldRefresh()) {
                self._loadAvailableTimes();
              }
            },
            availableTimesIntervalMS,
            0,
            true
          );
        }

        // Anchor scroll in case we came to look at the listing details.
        $timeout(function () {
          $anchorScroll();
        }, 500);
      };

      self._shouldRefresh = function () {
        if (
          $scope.schedulingView.state === schedulingViewStates.noSchedulingData
        ) {
          return false;
        }

        if ($scope.scheduling.timeSelections.length > 1) {
          return (
            isEmpty($scope.scheduling.selectedSlot) &&
            !$scope.scheduling.hasSelected
          );
        } else {
          return (
            !$scope.scheduling.hasSelected &&
            isEmpty($scope.scheduling.selectedDay)
          );
        }
      };

      self.determineAvailableDate = function (availableDateString) {
        availableDateString = availableDateString || '';
        var availableDatePart = availableDateString.split('T')[0];
        return timeService.get(availableDatePart);
      };

      self._getListingError = function () {
        $scope.listingLoadError = true;
      };

      self._loadExistingAppointments = function () {
        self.user = userService.getUser();

        $scope.userIsManager = self.user && self.user.type === 'manager';

        if (!isEmpty(self.user) || !isEmpty($routeParams.appointmentId)) {
          $scope.schedulingView.state = schedulingViewStates.loading;

          var appointmentId = $routeParams.appointmentId;

          var query = {
            listingId: self.listingId,
            appointmentId: appointmentId
          };

          appointmentsApi
            .getAppointments(query)
            .success(self._loadExistingAppointmentsSuccess);
        }
      };

      self._loadExistingAppointmentsSuccess = function (response) {
        var validAppointments = filter(
          response.appointments,
          function (appointment) {
            return appointment.status !== 'cancelled';
          }
        );

        var appointments = sortBy(validAppointments, 'start_time').reverse();

        var requests = filter(response.requests, { status: 'pending' });
        var proposals = filter(response.requests, { status: 'proposed' });

        var appointment = appointments ? appointments[0] : null;

        var queuedRequest = localStorageService.get('queued-request');

        var isUpcomingAppointment =
          appointment && timeService.now().isBefore(appointment.start_time);
        var isRenterInterested = appointment && appointment.renter_interested;

        if ($scope.userIsManager) {
          self._loadAvailableTimes();
          return;
        }

        if (queuedRequest && !isEmpty(queuedRequest)) {
          localStorageService.remove('queued-request');

          schedulingService
            .requestQueuedAppointment(
              queuedRequest.id,
              queuedRequest.listing_id
            )
            .success(function (response) {
              $window.scrollTo(0, 0);
              $scope.onAppointmentRequested(response.requests);
            })
            .error(function (error, status) {
              $scope.signupError =
                status === 409
                  ? 'availableTimesConflict'
                  : 'requestAppointment';
            })
            .finally(function () {
              $scope.isRequesting = false;
              $scope.isRescheduling = false;
            });

          return;
        }

        if (isUpcomingAppointment) {
          $scope.appointment = appointment;
          $scope.schedulingView.state =
            schedulingViewStates.showBookedAppointment;
          return;
        }

        if (isRenterInterested) {
          $scope.appointment = appointment;
          $scope.schedulingView.state =
            schedulingViewStates.showVisitedAppointment;
          return;
        }

        // If we have requests but no booked appointment, show the 'Requested' view
        if (requests && !isEmpty(requests)) {
          $scope.requestedTimes = requests;
          $scope.schedulingView.state =
            schedulingViewStates.showRequestedAppointment;
          return;
        }

        if (proposals && !isEmpty(proposals)) {
          $scope.requestedTimes = proposals;
          $scope.schedulingView.state =
            schedulingViewStates.showProposedAppointments;
          return;
        }

        if ($scope.listing.status === 'inactive') {
          $scope.schedulingView.state =
            schedulingViewStates.showDeactivatedListing;
        } else {
          self._loadAvailableTimes();
        }
      };

      self._loadAvailableTimes = function () {
        $scope.schedulingView.state = schedulingViewStates.loading;

        listingApi
          .getAvailableTimes(self.listingId, startTime, endTime)
          .success(self._getAvailableTimesSuccess)
          .error(self._getAvailableTimesError);
      };

      self._getAvailableTimesSuccess = function (response) {
        var now = timeService.now();

        var afterNowFilter = function (time) {
          return timeService.get(time).isAfter(now);
        };

        var appointmentWindowFilter = function (time) {
          var moment = timeService.get(time);
          return moment.isAfter(now);
        };

        function adjustToLocale(time) {
          return timeService.get(time, null, $scope.listing.location.timezone);
        }

        var localeAdjustedPrimeTimeSlots = map(
          response.prime_time_slots,
          adjustToLocale
        );
        var localeAdjustedAutoAcceptSlots = map(
          response.acceptable_slots,
          adjustToLocale
        );
        var localeAdjustedTourSlots = map(
          response.requestable_slots,
          adjustToLocale
        );

        $scope.primeTimeSlots = self._groupTimeSlots(
          localeAdjustedPrimeTimeSlots.filter(afterNowFilter)
        );
        $scope.autoAcceptSlots = self._groupTimeSlots(
          localeAdjustedAutoAcceptSlots.filter(appointmentWindowFilter)
        );
        $scope.tourSlots = self._groupTimeSlots(
          localeAdjustedTourSlots.filter(appointmentWindowFilter)
        );

        $scope.hasPrimeTimeSlots = size($scope.primeTimeSlots) > 0;
        $scope.hasAutoAcceptSlots = size($scope.autoAcceptSlots) > 0;
        $scope.hasTourSlots = size($scope.tourSlots) > 0;

        // Priority: prime times, auto accept times, tour slots
        if (!isEmpty($scope.primeTimeSlots)) {
          $scope.allowNonPrimeTimes = response.allow_non_prime_times;
          $scope.isPrimeTimeInstantBook = response.prime_time_instant_book;
          $scope.schedulingView.state = schedulingViewStates.primeTime;
          return;
        }

        if (!isEmpty($scope.autoAcceptSlots)) {
          $scope.schedulingView.state = schedulingViewStates.autoAccept;
          return;
        }

        if (!isEmpty($scope.tourSlots)) {
          $scope.schedulingView.state = schedulingViewStates.chooseTimes;
          return;
        }

        $scope.schedulingView.state = schedulingViewStates.noSchedulingData;
      };

      self._groupTimeSlots = function (times) {
        return groupBy(times, function (time) {
          return time.year().toString() + '_' + time.dayOfYear();
        });
      };

      self._getAvailableTimesError = function () {
        $scope.schedulingView.state = schedulingViewStates.noSchedulingData;
      };

      self._removeAppointmentIdParam = function () {
        if ($routeParams.appointmentId) {
          $location.search('appointmentId', null);
        }
      };

      self._getPusherInstance = function () {
        self._pusherInstance =
          self._pusherInstance || $pusher(new Pusher(pusherKey));

        return self._pusherInstance;
      };

      self._initialize();
    }
  ]);
})();
