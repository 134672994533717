import React from 'react';

export const PeopleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M0.75 20.25C0.75 16.5221 3.77208 13.5 7.5 13.5C11.2279 13.5 14.25 16.5221 14.25 20.25M15.813 15.068C17.5079 14.4433 19.4011 14.6865 20.8832 15.7191C22.3652 16.7518 23.249 18.4437 23.25 20.25M7.5 12C9.77817 12 11.625 10.1532 11.625 7.875C11.625 5.59683 9.77817 3.75 7.5 3.75C5.22183 3.75 3.375 5.59683 3.375 7.875C3.375 10.1532 5.22183 12 7.5 12ZM17.727 13.5C19.591 13.5 21.102 11.989 21.102 10.125C21.102 8.26104 19.591 6.75 17.727 6.75C15.863 6.75 14.352 8.26104 14.352 10.125C14.352 11.989 15.863 13.5 17.727 13.5Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
