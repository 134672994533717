/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function ($) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('datepickerDate', [
    'timeService',
    function (timeService) {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elm, attrs, ngModel) {
          ngModel.$formatters.push(function (value) {
            if (value) {
              return timeService.get(value).toDate();
            }
          });
        }
      };
    }
  ]);
})(window.jQuery);
