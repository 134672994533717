import { EntityState } from '@reduxjs/toolkit';
import { api as baseApi } from 'app/services/api';
import {
  LeasingTeamMember,
  leasingTeamMemberAdapter,
  SearchPeopleResult,
  People
} from './entities';

type Response = {
  status_code: string;
  members: LeasingTeamMember[];
};

export const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMembers: build.query<EntityState<LeasingTeamMember>, void>({
      query: () => ({ url: '/me/leasing-team/members' }),
      transformResponse(response: Response) {
        return leasingTeamMemberAdapter.addMany(
          leasingTeamMemberAdapter.getInitialState(),
          response.members
        );
      }
    }),
    searchPeople: build.mutation<SearchPeopleResult, Partial<People>>({
      query: (body) => ({
        url: `/search/people`,
        method: 'POST',
        data: body
      })
    })
  })
});
