import React, { FC } from 'react';
import {
  Box,
  Button,
  Text,
  makeStyles,
  Avatar,
  denim
} from '@knockrentals/knock-shared-web';
import { NoQuickRepliesIcon, AddCircleIcon } from '../icons';

const useStyles = makeStyles((theme) => ({
  noReplyContainerBox: {
    padding: '36px'
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'center'
  },
  textBox: {
    maxWidth: '312px',
    marginTop: '24px'
  },
  buttonBox: {
    marginTop: '24px',
    textAlign: 'center'
  },
  addQuickReplyButton: {
    '& svg': {
      marginRight: '8px'
    }
  },
  quickReplyText: {
    fontStyle: 'normal',
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  avatar: {
    background: denim[50],
    height: '75px',
    width: '81px'
  }
}));

interface NoQuickRepliesProps {
  handleClose: (flag: boolean) => void;
}

const NoQuickReplies: FC<NoQuickRepliesProps> = ({ handleClose }) => {
  const {
    noReplyContainerBox,
    iconBox,
    textBox,
    buttonBox,
    addQuickReplyButton,
    quickReplyText,
    avatar
  } = useStyles();

  return (
    <Box className={noReplyContainerBox}>
      <Box className={iconBox}>
        <Avatar classes={{ root: avatar }}>
          <NoQuickRepliesIcon />
        </Avatar>
      </Box>

      <Box className={textBox}>
        <Text variant="subtitle2" className={quickReplyText}>
          Quick Replies make it easier and more efficient to respond to
          prospects!
        </Text>
      </Box>

      <Box className={buttonBox}>
        <Button
          variant="text"
          className={addQuickReplyButton}
          onClick={() => handleClose(true)}
        >
          <AddCircleIcon />
          New Quick Reply
        </Button>
      </Box>
    </Box>
  );
};
export default NoQuickReplies;
