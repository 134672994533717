/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import angular from 'angular';
import configureTheming from './legacy-angular-app/appSetup/config/configureTheming';
import configureNetworkInterception from './legacy-angular-app/appSetup/config/configureNetworkInterception';
import configureTextAngular from './legacy-angular-app/appSetup/config/configureTextAngular';
import configureLocalStorage from './legacy-angular-app/appSetup/config/configureLocalStorage';
import configureImageSanitization from './legacy-angular-app/appSetup/config/configureImageSanitization';
import configurePusher from './legacy-angular-app/appSetup/config/configurePusher';
import configureRoutes from './legacy-angular-app/appSetup/config/configureRoutes';
import configureGoogleMaps from './legacy-angular-app/appSetup/config/configureGoogleMaps';
import configureZeroClipboard from './legacy-angular-app/appSetup/config/configureZeroClipboard';
import configureAuthorization from './legacy-angular-app/appSetup/config/configureAuthorization';
import { loadReactComponents } from './legacy-angular-app/appSetup/load-react-components';
import runInitializeAppDataService from './legacy-angular-app/appSetup/runBlocks/runInitializeAppDataService';
import runHandleAuthService from './legacy-angular-app/appSetup/runBlocks/runHandleAuthService';
import runHandleLeadTrackingService from './legacy-angular-app/appSetup/runBlocks/runHandleLeadTrackingService';
import runHandleDocTileChange from './legacy-angular-app/appSetup/runBlocks/runHandleDocTitleChange';
import runOnRouteChangeStart from './legacy-angular-app/appSetup/runBlocks/runOnRouteChangeStart';
import runAuthCheck from './legacy-angular-app/appSetup/runBlocks/runAuthCheck';
import runHandleRouteSecurity from './legacy-angular-app/appSetup/runBlocks/runHandleRouteSecurity';
import runInitializeFirebase from './legacy-angular-app/appSetup/runBlocks/runInitializeFirebase';

const knockApp = angular.module('knockApp', [
  'ngAnimate',
  'ngResource',
  'ngRoute',
  'ngSanitize',
  'ngTouch',
  'ngMessages',
  'ng.deviceDetector',
  'uiGmapgoogle-maps',
  'angularLodash',
  'ui.bootstrap',
  'isteven-multi-select',
  'ng-sortable',
  'mgcrea.ngStrap.tooltip',
  'mgcrea.ngStrap.popover',
  'ui.bootstrap-slider',
  'ui.checkbox',
  'satellizer',
  'angular-momentjs',
  'angulartics',
  'angulartics.google.analytics',
  'pusher-angular',
  'ngClipboard',
  'angularPayments',
  'ngMask',
  'ngMaterial',
  'LocalStorageModule',
  'knock-ResidentHistory',
  'knock-ProspectHistory',
  'knock-PropertyReports',
  'knock-UserInteractions',
  'knock-EmojiPicker',
  'knock-ColorPicker',
  'knock-Conversations',
  'knock-Authentication',
  'knock-Account',
  'knock-MarketingTools',
  'knock-Todo',
  'knock-Voice',
  'ngLocationUpdate',
  'ngAudio',
  'textAngular',
  'ngFileUpload'
]);

knockApp.controller('MyAppController', [function () {}]);

configureTheming(knockApp);
configureNetworkInterception(knockApp);
configureTextAngular(knockApp);
configureLocalStorage(knockApp);
configureImageSanitization(knockApp);
configurePusher(knockApp);

knockApp.config([
  '$provide',
  '$controllerProvider',
  '$compileProvider',
  '$filterProvider',
  ($provide, $controllerProvider, $compileProvider, $filterProvider) => {
    $provide.value('$controllerProvider', $controllerProvider);
    $provide.value('$factoryProvider', {
      register: (name, constructor) => {
        $provide.factory(name, constructor);
      }
    });
    $provide.value('$serviceProvider', {
      register: (name, constructor) => {
        $provide.service(name, constructor);
      }
    });
    $provide.value('$directiveProvider', {
      register: (name, constructor) => {
        $compileProvider.directive(name, constructor);
      }
    });
    $provide.value('$filterProvider', $filterProvider);
  }
]);

loadReactComponents(knockApp);

// Ensures angular will load the first route on load despite ng-view being inside of the viewport directive
knockApp.run(['$route', angular.noop]);

/**
 * Routing for angular
 */
configureRoutes(knockApp);

configureGoogleMaps(knockApp);
configureZeroClipboard(knockApp);
configureAuthorization(knockApp);

runInitializeAppDataService(knockApp);
runHandleAuthService(knockApp);
runHandleLeadTrackingService(knockApp);
runHandleDocTileChange(knockApp);
runOnRouteChangeStart(knockApp);

runAuthCheck(knockApp);
runHandleRouteSecurity(knockApp);
runInitializeFirebase(knockApp);
