/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knock-ProspectHistory');

  app.factory('listingResidentsModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function (listingId) {
        return $mdDialog.show({
          controller: 'ListingResidentsController',
          templateUrl:
            '/angular/views/prospectHistory/listing-residents-modal.html',
          parent: angular.element(document.body),
          locals: { listingId: listingId }
        });
      };

      return self;
    }
  ]);

  app.controller('ListingResidentsController', [
    '$scope',
    '$q',
    '$mdDialog',
    '$mdToast',
    'listingId',
    'prospectHistoryService',
    'residentsApi',
    'residentService',
    'listingApi',
    function (
      $scope,
      $q,
      $mdDialog,
      $mdToast,
      listingId,
      prospectHistoryService,
      residentsApi,
      residentService,
      listingApi
    ) {
      var self = this;

      $scope.activeViews = {
        currentResidents: 'current-residents',
        selectResidents: 'select-residents',
        editingResident: 'editing-resident'
      };

      $scope.data = {
        isLoaded: false,
        activeView: $scope.activeViews.currentResidents,
        residents: [],
        unassignedPropertyResidents: [],
        editingResident: null,
        listingInfo: {
          residentNotificationMethod: 'none',
          remindResidents: false
        },
        property: null
      };

      $scope.activeViewTitle = function () {
        switch ($scope.data.activeView) {
          case $scope.activeViews.currentResidents:
            return 'Current Residents';

          case $scope.activeViews.selectResidents:
            return 'Select Residents';
        }
      };

      $scope.saveListingInfo = function () {
        prospectHistoryService
          .saveListingInfo(
            listingId,
            $scope.data.listingInfo.remindResidents,
            $scope.data.listingInfo.residentNotificationMethod
          )
          .success(function () {
            $mdToast.showSimple('Settings saved!');
          });
      };

      $scope.tryToggleSubscribeResident = function (resident) {
        if (resident.is_subscribed) {
          // The click event fired to subscribe the resident
          $scope.trySubscribeResident(resident);
        } else {
          $scope.tryUnsubscribeResident(resident);
        }
      };

      $scope.trySubscribeResident = function (resident) {
        // this can occur when a resident was added to the system without specifying an actual unit number
        var residentUnitMisconfigured =
          resident.unit && isEmpty(resident.unit.unit);

        if (
          resident.property_unit_id &&
          resident.property_unit_id !== $scope.data.listingPropertyUnit.id &&
          !residentUnitMisconfigured
        ) {
          resident.needsConfirmation = resident.hasConfirmation ? false : true;
        }

        if (resident.needsConfirmation) {
          return;
        }

        residentsApi
          .subscribeResident(resident.id, $scope.data.listingPropertyUnit.id)
          .success(function () {
            resident.property_unit_id = $scope.data.listingPropertyUnit.id;
            resident.unit = $scope.data.listingPropertyUnit;
            resident.is_subscribed = true;
            resident.canUndoSubcription = true;

            $scope.data.subscribedResidents.push(resident);

            $mdToast.showSimple('Assigned to listing!');
          });
      };

      $scope.tryUnsubscribeResident = function (resident) {
        resident.is_subscribed = false;
        resident.needsConfirmation = false;

        remove($scope.data.subscribedResidents, { id: resident.id });

        if (resident.canUndoSubscription) {
          // This resident didn't need confirmation or needed confirmation and was subsequently subscribed,
          // as opposed to needing confirmation and consequently not being subscribed

          residentsApi.unsubscribeResident(resident.id).success(function () {
            resident.property_unit_id = null;
            resident.unit = {};
            resident.canUndoSubscription = false;

            $mdToast.showSimple('Unassigned from listing');
          });
        }
      };

      $scope.addNewResident = function () {
        residentService
          .addNewResident(
            $scope.data.listingProperty.id,
            $scope.data.listingPropertyUnit
          )
          .then(self._addNewResidentSuccess);
      };

      $scope.close = function () {
        $mdDialog.hide($scope.data.subscribedResidents);
      };

      self._initialize = function () {
        $scope.data.isLoaded = false;

        var initPromises = [
          self._getListingInfo(),
          self._getListingPropertyUnit()
        ];

        $q.all(initPromises).finally(self._loadFinished);
      };

      self._loadFinished = function () {
        $scope.data.isLoaded = true;
      };

      self._getListingInfo = function () {
        return prospectHistoryService
          .getListingInfo(listingId)
          .success(self._getListingInfoSuccess);
      };

      self._getListingPropertyUnit = function () {
        listingApi
          .getListingPropertyUnit(listingId)
          .success(function (response) {
            $scope.data.listingPropertyUnit = response.property_unit;
            $scope.data.listingProperty = response.listing_property;

            $scope.data.isLoadingPropertyResidents = true;

            prospectHistoryService
              .getPropertyResidents($scope.data.listingProperty.id)
              .success(self._getPropertyResidentsSuccess);
          });
      };

      self._getPropertyResidentsSuccess = function (response) {
        var assignablePropertyResident = function (resident) {
          resident.canUndoSubscription =
            resident.property_unit_id === $scope.data.listingPropertyUnit.id;

          return (
            resident.is_synced === false &&
            (!resident.is_subscribed || resident.canUndoSubscription)
          );
        };

        $scope.data.assignablePropertyResidents = filter(
          response.residents,
          assignablePropertyResident
        );
        $scope.data.subscribedResidents = filter(
          $scope.data.assignablePropertyResidents,
          'is_subscribed'
        );

        $scope.data.isLoadingPropertyResidents = false;
      };

      self._addNewResidentSuccess = function () {
        prospectHistoryService.openListingResidentsModal(listingId);
      };

      self._getListingInfoSuccess = function (response) {
        $scope.data.listingInfo.residentNotificationMethod =
          response.listing_info.occupant_notification_method;
        $scope.data.listingInfo.remindResidents =
          response.listing_info.remind_occupants;
      };

      self._initialize();
    }
  ]);
})(window.angular);
