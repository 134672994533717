import React, { ChangeEvent, FC } from 'react';
import { Checkbox, ListItem, FormControlLabel } from '@material-ui/core';
export interface ConcessionItemProps {
  concessionId: string;
  description: string;
  handleOnChange: (e: ChangeEvent<{ name: string; checked: boolean }>) => void;
  isChecked: boolean;
}

const ConcessionItem: FC<ConcessionItemProps> = ({
  concessionId,
  description,
  handleOnChange,
  isChecked
}) => (
  <ListItem key={concessionId} dense>
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          className="concession-checkbox"
          color="primary"
          disableRipple
          edge="start"
          inputProps={{ 'aria-labelledby': 'description' }}
          name={concessionId}
          onChange={handleOnChange}
          tabIndex={0}
        />
      }
      label={description}
    />
  </ListItem>
);

export default ConcessionItem;
