/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('marketingToolsApi', [
    'apiBase',
    function (apiBase) {
      return {
        setCraigslistCredentials: function (payload) {
          return apiBase.post(
            '/analytics/craigslist/account-credentials',
            payload
          );
        },

        getCommunitiesToCraigslistLogins: function () {
          return apiBase.get('/me/analytics/craigslist/community-accounts');
        },
        deleteCraigslistCredentials: function (id) {
          return apiBase.delete(
            '/analytics/craigslist/account-credentials/' + id
          );
        }
      };
    }
  ]);
})();
