import angular from 'angular';

const app = angular.module('knockApp');

app.filter('userListResident', [
  function () {
    return function (users: any, maxVisible: number): string {
      const maxVisibleCount = maxVisible || 2;

      const formattedUserNames = users
        .slice(0, maxVisibleCount)
        .map((user: any): string => `${user.name}`);

      const formattedUserNameList = formattedUserNames.join(', ');

      if (maxVisibleCount < users.length) {
        const formattedResidentTodoList = (
          users.length - maxVisibleCount
        ).toString();
        return `${formattedUserNameList} and ${formattedResidentTodoList} more`;
      }

      return formattedUserNameList;
    };
  }
]);
