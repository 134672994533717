/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import every from 'lodash/every';
import range from 'lodash/range';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('listingBasicsEditor', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/listing/listingEditor/listing-basics-editor.html',
      controller: [
        '$scope',
        'CountryStatesListService',
        'floorplanModelService',
        function ($scope, CountryStatesListService, floorplanModelService) {
          $scope.stateSelectionDropdownOpen = false;
          $scope.unitTypeDropdownOpen = false;
          $scope.bedroomSelectionDropdownOpen = false;
          $scope.bathroomSelectionDropdownOpen = false;

          $scope.usStates =
            CountryStatesListService.getUnitedStateStatesArray();
          $scope.canadaStates = CountryStatesListService.getCanadaStateArray();
          $scope.unitTypes = floorplanModelService.getPossibleUnitTypes();

          $scope.bedroomRange = range(9);
          $scope.bathroomRange = range(1, 8.5, 0.5);

          $scope.selectState = function ($event, state) {
            $event.preventDefault();
            $scope.listing.location.address.state = state;
            $scope.stateSelectionDropdownOpen = false;
          };

          $scope.selectUnitType = function ($event, type) {
            $event.preventDefault();
            $scope.listing.floorplan.unitType = type;
            $scope.unitTypeDropdownOpen = false;
          };

          $scope.selectBedrooms = function ($event, bedroomCount) {
            $event.preventDefault();
            $scope.listing.floorplan.bedrooms = bedroomCount;
            $scope.bedroomSelectionDropdownOpen = false;
          };

          $scope.selectBathrooms = function ($event, bathroomCount) {
            $event.preventDefault();
            $scope.listing.floorplan.bathrooms = bathroomCount;
            $scope.bathroomSelectionDropdownOpen = false;
          };

          $scope.$watch(
            'listing.pets.allowed',
            function (newPetsAllowed, oldPetsAllowed) {
              if (newPetsAllowed.none !== oldPetsAllowed.none) {
                return;
              }

              $scope.listing.pets.allowed.none = every(
                $scope.listing.pets.allowed,
                function (allowed, pet) {
                  return pet === 'none' || allowed === false;
                }
              );
            },
            true
          );

          $scope.$watch(
            'listing.pets.allowed.large_dogs',
            function (newLargeDogsAllowed) {
              if (newLargeDogsAllowed) {
                $scope.listing.pets.allowed.small_dogs = true;
              }
            }
          );

          $scope.$watch(
            'listing.pets.allowed.small_dogs',
            function (newSmallDogsAllowed) {
              if (newSmallDogsAllowed) {
                return;
              }
              if (
                !newSmallDogsAllowed &&
                $scope.listing.pets.allowed.large_dogs
              ) {
                $scope.listing.pets.allowed.small_dogs = true;
              }
            }
          );

          $scope.clearSelectedPets = function () {
            $scope.listing.pets.allowed.cats = false;
            $scope.listing.pets.allowed.small_dogs = false;
            $scope.listing.pets.allowed.large_dogs = false;
            $scope.listing.pets.allowed.none = true;
          };
        }
      ]
    };
  });
})(window.angular);
