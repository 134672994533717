import React from 'react';

export const AvailableIcon = () => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.50134 7.5H16.5013M5.50134 5V1.5M12.5013 5V1.5M2.50134 16.5H15.5013C16.0536 16.5 16.5013 16.0523 16.5013 15.5V4.5C16.5013 3.94772 16.0536 3.5 15.5013 3.5H2.50134C1.94906 3.5 1.50134 3.94772 1.50134 4.5V15.5C1.50134 16.0523 1.94906 16.5 2.50134 16.5Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
