/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('loginService', [
    '$rootScope',
    '$auth',
    'userService',
    'apiEvents',
    function ($rootScope, $auth, userService, apiEvents) {
      var self = this;

      var privateBrowsingRegex = /QuotaExceededError/;
      var useSocialLoginRegex = /use social login/;

      self.getErrorMessageStrings = function () {
        return {
          errorMessage: "We're having a problem authenticating your account.",
          invalidLoginMessage: 'Invalid username / password combination.',
          useSocialLoginMessageTemplate:
            'Looks like you used {{provider}} to register. Please login with that option.',
          privateBrowsingErrorMessage:
            'An error occurred. Please ensure private browsing is turned off'
        };
      };

      self.isPrivateBrowsingError = function (response) {
        return privateBrowsingRegex.test(response.message);
      };

      self.getExistingAccountProvider = function (response) {
        if (useSocialLoginRegex.test(response.data.error_message)) {
          var providerId = response.data.provider_id;

          if (providerId === 'facebook') {
            return 'Facebook';
          }

          if (providerId === 'linkedin') {
            return 'LinkedIn';
          }
        }

        return null;
      };

      self.socialLogin = function (provider) {
        return $auth
          .authenticate(provider, { client_type: 'web' })
          .then(self._emitLoginEvent);
      };

      self.usernameLogin = function (user) {
        return $auth.login(user).then(self._emitLoginEvent);
      };

      self.sendForgotPasswordRequest = function (userType, username) {
        return userService.sendForgotPasswordRequest(userType, username);
      };

      self._emitLoginEvent = function () {
        $rootScope.$emit(apiEvents.loggedIn);
      };

      return self;
    }
  ]);
})();
