import React, { FC } from 'react';
import { makeStyles, MenuItem, Select } from '@material-ui/core';
import './_BatchSelectCheckbox.scss';

export const BATCH_OPTIONS = ['All on Page', 'All'];

const useStyles = makeStyles({
  batchSelect: {
    '&.MuiInputBase-root': {
      border: 'none',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  batchSelectItem: {
    '& .MuiMenuItem-root': {
      minHeight: '36px',
      fontSize: '12px'
    },
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    '& .MuiListItem-root:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }
});

interface BatchSelectProps {
  onChange(value: unknown): void;
  onClose: () => void;
}

const BatchSelect: FC<BatchSelectProps> = ({ onChange, onClose }) => {
  const classes = useStyles();
  return (
    <div className="batch-select">
      <Select
        className={classes.batchSelect}
        MenuProps={{ className: classes.batchSelectItem }}
        onChange={onChange}
        open={true}
        onClose={onClose}
        value=""
      >
        {BATCH_OPTIONS.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default BatchSelect;
