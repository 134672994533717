import React from 'react';

const SuccessIcon = () => (
  <svg
    width="238"
    height="234"
    viewBox="0 0 238 234"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M153.257 9.14793C117.769 -10.4355 73.1245 2.45799 53.541 37.9464C46.4036 50.8805 43.5802 65.0308 44.5923 78.7408C29.4921 83.3825 16.1052 93.6616 7.86988 108.585C-8.97764 139.116 2.11466 177.523 32.6451 194.371L89.8211 225.922C120.352 242.77 158.759 231.677 175.607 201.147C178.159 196.522 180.07 191.716 181.369 186.831C201.005 191.269 222.001 182.479 232.215 163.969C244.599 141.528 236.446 113.297 214.004 100.913L194.467 90.132C200.468 59.6592 186.544 27.5163 157.894 11.7066L153.257 9.14793Z"
      fill="#F0F2FF"
    />
    <path
      d="M96.7725 103.603L110.986 123.5C111.571 124.319 112.336 124.993 113.223 125.47C114.109 125.948 115.093 126.215 116.099 126.253C117.105 126.29 118.106 126.097 119.026 125.687C119.945 125.277 120.758 124.662 121.403 123.888L167.971 68"
      stroke="#435CEF"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M140.065 81.2687C130.835 75.5568 119.86 73.3528 109.14 75.0586C98.421 76.7644 88.6718 82.2663 81.6709 90.561C74.67 98.8557 70.8838 109.39 71.0027 120.244C71.1217 131.098 75.1379 141.547 82.319 149.686C89.5 157.825 99.3674 163.112 110.122 164.583C120.876 166.053 131.8 163.609 140.903 157.696C150.005 151.783 156.679 142.796 159.707 132.372C162.735 121.949 161.916 110.785 157.398 100.915"
      stroke="#435CEF"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SuccessIcon;
