/* eslint-disable */
(function () {
  'use strict';

  const app = angular.module('knockApp');

  const GetAppointmentController = function (
    $window,
    $routeParams,
    appointmentsApi
  ) {
    const appointmentUuid = $routeParams.appointmentUuid;
    const redirectToUrl = (res) =>
      ($window.location.href = res.live_video_tour_url);
    appointmentsApi.getLiveVideoTourUrl(appointmentUuid).success(redirectToUrl);
  };
  GetAppointmentController.$inject = [
    '$window',
    '$routeParams',
    'appointmentsApi'
  ];

  app.controller('GetAppointmentController', GetAppointmentController);
})();
