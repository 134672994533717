import React, { FC } from 'react';

import {
  Button,
  makeStyles,
  NamedColors,
  ThemeProvider
} from '@knockrentals/knock-shared-web';
import { FloorplanIcon } from '../icons/FloorplanIcon';

const useStyles = makeStyles({
  button: {
    color: NamedColors.slate[800],

    '& svg': {
      marginRight: '8px'
    },

    '&:focus': {
      color: NamedColors.slate[800],

      '& svg path': {
        stroke: NamedColors.slate[800]
      }
    }
  }
});

export const AvailabilityButton: FC = () => {
  const classes = useStyles();

  return (
    <ThemeProvider>
      <Button variant="text" className={classes.button}>
        <FloorplanIcon /> Availability
      </Button>
    </ThemeProvider>
  );
};
