require('../initializeAngularApp');
const angular = require('angular');

const BASE_HTML = `
    <div class="no-js" ng-controller="MyAppController">
        <div class="page-container container">
            <div class="viewport" />
        </div>
        <div id="notificationAudio" />
    </div>
`;

export default class LegacyApp {
  private _element: any = null;
  private _current: any = null;

  private _getAppElement() {
    if (this._element == null) {
      this._element = document.createElement('div');
      this._element.innerHTML = BASE_HTML;
    }
    return this._element;
  }

  public async launch() {
    const appElement = this._getAppElement();
    const legacyAngularApp = document.getElementById('legacyAngularApp');

    if (legacyAngularApp != null && this._current == null) {
      this._current = angular.bootstrap(appElement, ['knockApp'], {
        strictDi: true
      });
    }

    if (legacyAngularApp != null) {
      legacyAngularApp.appendChild(appElement);
    }
  }
}

export const legacyApp = new LegacyApp();
