/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular, $) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.factory('addAttachmentDialogFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openAddAttachmentDialog = function (
        conversation,
        prospect,
        message,
        target,
        localAttachments,
        remoteAttachments,
        urls
      ) {
        return $mdDialog.show({
          controller: 'AddAttachmentDialogController',
          templateUrl:
            '/angular/views/conversations/add-attachment-dialog.html',
          clickOutsideToClose: true,
          locals: {
            conversation: conversation,
            prospect: prospect,
            message: message || '',
            target: target || 'sms',
            localAttachments: localAttachments || [],
            remoteAttachments: remoteAttachments || [],
            urls: urls || []
          }
        });
      };

      return self;
    }
  ]);

  const AddAttachmentDialogController = function (
    $scope,
    $mdDialog,
    conversationsService,
    conversation,
    prospect,
    message,
    target,
    localAttachments,
    remoteAttachments,
    urls
  ) {
    var self = this;

    self._messageTargetCharacterLimits = {
      sms: 150
    };

    $scope.data = {
      attachments: {
        local: localAttachments,
        remote: remoteAttachments
      },
      urls: urls,
      conversation: conversation,
      prospect: prospect,
      message: {
        target: target,
        text: ''
      },
      maxCharacters: self._messageTargetCharacterLimits.sms
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.sendMessageWithAttachments = function () {
      $scope.data.message.isSending = true;
      $scope.data.message.text = $scope.data.message.text || '';

      var target = $scope.data.message.target;

      conversationsService
        .sendMessage(
          $scope.data.conversation.id,
          $scope.data.message.text,
          $scope.data.message.html,
          null,
          $scope.data.attachments.remote,
          [target]
        )
        .then(function (response) {
          $mdDialog.hide(response.data);
        })
        .finally(function () {
          $scope.data.message.isSending = false;
        });
    };

    $scope.onEmailAttachmentAdded = function (attachments) {
      if ($scope.data.message.target !== 'email') {
        return;
      }
      $mdDialog.hide(attachments);
    };
  };

  AddAttachmentDialogController.$inject = [
    '$scope',
    '$mdDialog',
    'conversationsService',
    'conversation',
    'prospect',
    'message',
    'target',
    'localAttachments',
    'remoteAttachments',
    'urls'
  ];

  app.controller(
    'AddAttachmentDialogController',
    AddAttachmentDialogController
  );
})(window.angular, window.jQuery);
