/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
'use strict';

angular.module('knockApp').filter('ucwords', function (capitalizeFilter) {
  return function (words) {
    if (words === undefined || words === null || words === '') {
      return '';
    }

    var separator = ' ';
    if (words.indexOf(separator) <= 0) {
      return capitalizeFilter(words);
    }

    var splittedWords = words.split(separator);

    // turns each word into capitalized
    // remove empty element
    return splittedWords
      .map(capitalizeFilter)
      .filter(function (e) {
        return e !== '';
      })
      .join(separator);
  };
});
