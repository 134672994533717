/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import chunk from 'lodash/chunk';
import some from 'lodash/some';
import map from 'lodash/map';
import compact from 'lodash/compact';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Account');

  app.directive('accountProfile', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/account/account-profile.html',
      controller: 'AccountProfileController',
      scope: {}
    };
  });

  app.controller('AccountProfileController', [
    '$scope',
    '$q',
    '$mdToast',
    'unifiedLoginLink',
    'accountViewService',
    '$rootScope',
    '$timeout',
    function (
      $scope,
      $q,
      $mdToast,
      unifiedLoginLink,
      accountViewService,
      $rootScope,
      $timeout
    ) {
      var self = this;
      const defaultImageFileName = 'profile_placeholder.png';

      $scope.data = {
        username: '',
        knockEmail: '',
        localProfilePhoto: null
      };

      $scope.browserCallSettings = {
        outboundPhoneNumber: null,
        phoneNumbers: [],
        error: false
      };

      $scope.setCallSettings = function (
        outboundPhoneNumber,
        phoneNumbers,
        error
      ) {
        $scope.browserCallSettings = {
          outboundPhoneNumber,
          phoneNumbers,
          error
        };
        $timeout(() => $scope.$apply());
      };

      $scope.newNavigation = $rootScope.featureFlags.LMA_NEW_NAVIGATION;

      $scope.setProfileColor = (color) => {
        $scope.profile.color = color;
        $scope.$apply(() => $scope.saveProfile());
      };

      $scope.isDefaultIMGPresent = (photo) => {
        if (photo) {
          return photo.indexOf(defaultImageFileName) !== -1;
        } else {
          return false;
        }
      };

      $scope.$watch('data.localProfilePhoto', function () {
        $scope.isDefaultIMGPresentFlag = $scope.isDefaultIMGPresent(
          $scope?.profile?.photo
        );

        if (
          $scope.data.localProfilePhoto &&
          $scope.data.localProfilePhoto.length > 0
        ) {
          $scope.profile.photo = $scope.data.localProfilePhoto[0].url;

          if ($scope.data.localProfilePhoto[0].isLocal) {
            $scope.isUploadingPhoto = true;

            accountViewService
              .updateProfilePhoto(
                $scope.data.localProfilePhoto[0],
                $scope.profile
              )
              .then(function (updatedProfile) {
                $scope.profile = updatedProfile;
                $mdToast.showSimple('Profile photo updated!');
              });
          }
        }
      });

      $scope.openImagePrompt = function () {
        throw 'not implemented. should be overriden by image upload directive';
      };

      $scope.changePassword = function () {
        accountViewService.openChangePasswordModal();
      };

      /* Enable function as setter function for phoneNumber field  while converting to react */
      /* $scope.setPhoneNumber = function (phoneNumber) {
        $scope.profile.phone.phone_number = phoneNumber;
      }; */

      $scope.saveProfile = function () {
        $scope.invalidEmailsMessage = '';

        $scope.profile.cc_emails = compact(
          map($scope.data.ccEmailInputs, function (ccEmailInput) {
            return ccEmailInput.value;
          })
        );

        var invalidEmails = some($scope.profile.cc_emails, function (ccEmail) {
          return !accountViewService.validateEmail(ccEmail);
        });

        if (invalidEmails) {
          $scope.invalidEmailsMessage = 'One or more email address is invalid.';
          return;
        }

        var mailerConflict = some($scope.profile.cc_emails, function (ccEmail) {
          return !accountViewService.validateMailerConflict(ccEmail);
        });

        if (mailerConflict) {
          $scope.invalidEmailsMessage =
            'You cannot use your Knock mailer address as a CC email';
          return;
        }

        $scope.data.ccEmailInputs = map(
          $scope.profile.cc_emails,
          function (ccEmail) {
            return { value: ccEmail };
          }
        );

        if ($scope.data.ccEmailInputs.length === 0) {
          $scope.data.ccEmailInputs = [{ value: '' }];
        }

        $scope.data.isSaving = true;

        accountViewService
          .updateProfile(
            $scope.profile,
            $scope.data.username,
            $scope.browserCallSettings
          )
          .then(self._updateProfileSuccess, self._updateProfileError)
          .finally(function () {
            $scope.data.isSaving = false;
          });
      };

      self._updateProfileSuccess = function () {
        $mdToast.showSimple('Profile saved!');
      };

      self._updateProfileError = function (error) {
        if (error.status === 409) {
          $mdToast.showSimple(
            'The username "' + $scope.data.username + '" is taken!'
          );
        } else {
          $mdToast.showSimple('There was an error saving your profile.');
        }
      };

      self._initialize = function () {
        $scope.unifiedLoginLink = unifiedLoginLink;
        var initPromises = [
          self._getProfile(),
          self._getAvailableColors(),
          self._getUsername(),
          self._getKnockEmail()
        ];

        $q.all(initPromises);
      };

      self._getProfile = function () {
        return accountViewService.getProfile().success(self._getProfileSuccess);
      };

      self._getProfileSuccess = function (response) {
        $scope.profile = response.profile;

        $scope.data.ccEmailInputs = map(
          $scope.profile.cc_emails,
          function (ccEmail) {
            return {
              value: ccEmail
            };
          }
        );
      };

      self._getUsername = function () {
        return accountViewService
          .getUsername()
          .success(self._getUsernameSuccess);
      };

      self._getUsernameSuccess = function (response) {
        $scope.data.username = response.user.username;
      };

      self._getAvailableColors = function () {
        return accountViewService
          .getAvailableColors()
          .success(self._getAvailableColorsSuccess);
      };

      self._getAvailableColorsSuccess = function (response) {
        $scope.data.palette = chunk(response.available_colors, 4);
      };

      self._getKnockEmail = function () {
        return accountViewService
          .getKnockEmail()
          .success(self._getKnockEmailSuccess);
      };

      self._getKnockEmailSuccess = function (response) {
        $scope.data.knockEmail = response.knock_email.email_relay.email;
      };

      self._initialize();
    }
  ]);
})(window.angular);
