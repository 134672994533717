import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton
} from '@material-ui/core';
import {
  makeStyles,
  Button,
  Box,
  Text,
  black
} from '@knockrentals/knock-shared-web';

import { colors, useCommonStyles } from '../../commonStyles/commonStyles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  paperWidth: {
    width: '444px',
    hight: '208px'
  },
  dialogContentText: {
    letterSpacing: '0.5px'
  },
  dialogActions: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
    padding: '12px 16px 16px 16px'
  },
  icon: {
    fontSize: '26px'
  },
  dialogBox: {
    color: colors.secondaryText,
    display: 'flex',
    justifyContent: 'space-between'
  },
  dialogTitle: {
    paddingBottom: '5px',
    paddingRight: '12px',
    paddingTop: '12px'
  },
  dialogText: {
    color: black,
    paddingTop: '12px'
  }
}));

interface CloseConfirmationModalProps {
  closeModal: () => void;
  closeDrawerFromCloseConfirmationModal: () => void;
}

const CloseConfirmationModal: FC<CloseConfirmationModalProps> = ({
  closeModal,
  closeDrawerFromCloseConfirmationModal
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const handleClose = () => {
    closeModal();
  };

  const handleCloseAllTabs = () => {
    closeDrawerFromCloseConfirmationModal();
  };

  return (
    <Dialog open={true} classes={{ paperWidthSm: classes.paperWidth }}>
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.dialogBox}>
          <Text variant="h6" className={classes.dialogText}>
            Cancel Loss
          </Text>
          <IconButton data-testid="close-button" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers={false}>
        <Text variant="body1" className={classes.dialogContentText}>
          Are you sure you want to exit the lost prospect flow? Doing so will
          not make any changes to the prospect's status or guestcard.
        </Text>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" onClick={handleClose}>
          No, Continue
        </Button>
        <Button
          className={commonClasses.primaryButton}
          onClick={handleCloseAllTabs}
        >
          Yes, Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloseConfirmationModal;
