import React, { FC } from 'react';
import classnames from 'classnames';

export interface ReceiptIconProps {
  className?: string;
  height?: string;
  strokeColor?: string;
  width?: string;
}

export const ReceiptIcon: FC<ReceiptIconProps> = ({
  className,
  height = '15',
  strokeColor = '#8D90A5',
  width = '15'
}) => (
  <svg
    className={classnames('receipt-icon', className)}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12.503 14.065V2.262a.843.843 0 0 0-.843-.843H3.23a.843.843 0 0 0-.844.843v11.803l1.686-1.686 1.686 1.686 1.686-1.686 1.687 1.686 1.686-1.686 1.686 1.686Z"
      stroke={strokeColor}
      strokeWidth="1.124"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.072 4.37h3.372M4.072 6.899h3.372M4.072 9.428h3.372M10.606 3.948a.21.21 0 1 1 0 .422.21.21 0 0 1 0-.422M10.606 6.477a.21.21 0 1 1 0 .422.21.21 0 0 1 0-.422M10.606 9.007a.21.21 0 1 1 0 .42.21.21 0 0 1 0-.42"
      stroke={strokeColor}
      strokeWidth="1.124"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
