/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Voice');

  app.directive('callerIdDisplay', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/voice/voice-caller-id-display.html',
      scope: {
        phone: '='
      }
    };
  });
})(window.angular);
