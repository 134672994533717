/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import partition from 'lodash/partition';
import filter from 'lodash/filter';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import {
  getNbaHotLead,
  getNextBestActionScoreByProspects
} from 'LegacyAngularApp/legacy-angular-app/services/demandXService';
import { find, forEach, indexOf } from 'lodash';

(function (angular) {
  'use strict';

  var app = angular.module('knock-Todo');

  const TodoDashboardController = function (
    $rootScope,
    $scope,
    $moment,
    $q,
    appDataService,
    todoService,
    ProfileService,
    timeService,
    dashboardApi,
    teamApi,
    conversationTeamInboxSettingsFactory,
    apiEvents,
    userService,
    conversationsService,
    prospectStatusMap
  ) {
    var self = this;

    var reloadHandler = $rootScope.$on(
      todoService.events.reloadDashboard,
      function () {
        self._initialize();
      }
    );

    $scope.$on('$destroy', function () {
      reloadHandler();
    });
    const currentDate = $moment();

    $scope.data = {
      isLoaded: false,
      user: userService.getScopedUser(),
      userProfile: null,
      now: timeService.get(),
      todo: {
        isApplicationTodosEnabled: false,
        managerProspects: [],
        totals: {
          newProspects: 0,
          pastDueProspects: 0,
          followUpResidentCount: 0,
          scheduledToursCount: 0
        },
        managerId: 0,
        managerUnreadMessageTotal: 0,
        unitResidents: {},
        yardiInsufficientPermissions: false
      },
      stats: {
        engagementScore: 0,
        leadsThisWeek: 0,
        toursThisWeek: 0,
        leasesThisWeek: 0,
        residentEngagementScore: null,
        isLoaded: false
      },
      tours: {
        upcomingAppointments: []
      },
      renewals: {
        upcomingRenewals: []
      },
      messages: {
        managerUnreadMessageCounts: []
      },
      onlineApplications: {
        prospects: []
      },
      followUpResidents: {}
    };

    $scope.isLmaDashboardRedesignEnabled =
      $rootScope.featureFlags.LMA_DASHBOARD_REDESIGN || false;

    $scope.openTeamInboxSettings = function () {
      return conversationTeamInboxSettingsFactory.openModal().then(function () {
        self._initialize();
      });
    };

    $scope.sortByManager = function (managerProspects) {
      if (managerProspects.managerInfo.manager_id === $scope.data.user.id) {
        return 'AAAAAAA';
      }

      return (
        managerProspects.managerInfo.first_name +
        managerProspects.managerInfo.last_name
      );
    };

    self._initialize = function () {
      $scope.data.isLoaded = false;

      var initPromises = [self._getDashboardData(), self._getProfile()];

      $q.all(initPromises)
        .then(async function (responses) {
          var dashboardData = responses[0].data.data;

          var managersInfoById = keyBy(
            map(appDataService.getTeamMembers(), 'ManagerInfo'),
            'manager_id'
          );
          $scope.data.userProfile = responses[1].profile;

          const selfScheduledIds = map(
            filter(dashboardData.tours.upcoming_appointments, (appointment) => {
              return appointment.tour_type === 'self_guided';
            }),
            (appointment) => appointment.prospect_id
          );

          const selfScheduledProspects = filter(
            dashboardData.todo.prospects,
            (prospect) => indexOf(selfScheduledIds, prospect.id) !== -1
          );

          forEach(selfScheduledProspects, (prospect) => {
            prospect.withSelfScheduledTour = true;
            return prospect;
          });

          await self._setupTodoData(dashboardData.todo, managersInfoById);
          self._setupToursData(dashboardData.tours, managersInfoById);
          self._setupMessagesData(dashboardData.messages, managersInfoById);

          if (dashboardData.online_applications) {
            $scope.data.todo.isApplicationTodosEnabled =
              dashboardData.online_applications.prospects &&
              dashboardData.online_applications.prospects.length > 0;
            self._setupOnlineApplicationsData(
              dashboardData.online_applications
            );
          }

          if (dashboardData.renewals)
            self._setupRenewalsData(dashboardData.renewals);

          self._setupEngagementStatsData(dashboardData.stats);

          if (dashboardData.stats)
            self._setupResidentStatsData(dashboardData.stats);

          $rootScope.$emit(apiEvents.gotSidebarTodoData, dashboardData.todo);

          self._getDashboardAnalyticsData().then((response) => {
            var dashboardData = response.data.data;
            self._setupConversionStatsData(dashboardData.stats);

            $scope.data.stats.isLoaded = true;
          });
        })
        .finally(function () {
          $scope.data.isLoaded = true;
        });
    };

    self._setupTodoData = async function (todoData, managersInfoById) {
      var todoProspects = filter(todoData.prospects, { color: 'red' });
      var newProspects = filter(todoProspects, { status: 'new' });
      var unassignedProspects = filter(todoProspects, {
        owned_by_hub_account: true
      });
      var partitionedProspects = partition(
        todoProspects,
        'needs_tour_completion'
      );

      // We want all prospects needing tour completion to show up on
      // that list, but still exclude the red ones from the other headers:
      var prospectsNeedingTourCompletion = filter(todoData.prospects, {
        needs_tour_completion: true
      });
      var remainingProspects = partitionedProspects[1];

      var pastDueProspects = filter(remainingProspects, function (prospect) {
        if (
          prospect.status !== 'new' &&
          prospect.needs_visit_follow_up === false
        ) {
          if (prospect.next_reminder_time) {
            const nextReminderTime = $moment(
              prospect.next_reminder_time,
              'YYYY-MM-DD'
            );

            return currentDate.isSame(nextReminderTime, 'day');
          }
          return true;
        }
      });

      var applicationPendingCompletionProspects = filter(
        todoData.prospects,
        function (prospect) {
          return (
            prospect.status === 'in-review' ||
            prospect.status === 'application-in-progress' ||
            prospect.status === 'applied-pending-signature'
          );
        }
      );

      var visitFollowupProspects = filter(
        remainingProspects,
        function (prospect) {
          return (
            prospect.status !== 'new' && prospect.needs_visit_follow_up === true
          );
        }
      );

      var staleProspects = filter(remainingProspects, { liveness: 'stale' });

      const selfScheduledTours = filter(todoData.prospects, {
        withSelfScheduledTour: true
      });

      if ('yardi_insufficient_permissions' in todoData)
        $scope.data.todo.yardiInsufficientPermissions =
          todoData.yardi_insufficient_permissions;

      $scope.data.todo.unitResidents = todoData.resident_units;
      $scope.data.todo.managerId = $scope.data.user.id;

      const followUpResidents = Object.values($scope.data.todo.unitResidents)
        .flatMap((communityData) =>
          Object.values(communityData).flatMap((unitsData) =>
            Object.values(unitsData).flatMap((residents) =>
              residents
                .map((unit) => {
                  // Check if next_reminder_time is defined and not null
                  if (unit.next_reminder_time) {
                    const nextReminderTime = $moment(
                      unit.next_reminder_time,
                      'YYYY-MM-DD'
                    );

                    if (currentDate.isSame(nextReminderTime, 'day')) {
                      return {
                        added_by: unit.added_by,
                        managerInfo: managersInfoById[unit.added_by],
                        unitObject: unit
                      };
                    }
                  }
                  return null;
                })
                .filter(Boolean)
            )
          )
        )
        .reduce((groups, item) => {
          const { added_by, unitObject, managerInfo } = item;
          const group = groups.find((group) => group.added_by === added_by);
          if (!group) {
            groups.push({ added_by, managerInfo, residents: [unitObject] });
          } else {
            group.residents.push(unitObject);
          }
          return groups;
        }, []);
      $scope.data.todo.followUpResidents = followUpResidents;

      $scope.data.todo.totals = {
        newProspects: newProspects.length,
        pastDueProspects: pastDueProspects.length,
        visitFollowupProspects: visitFollowupProspects.length,
        staleProspects: staleProspects.length,
        unassignedProspects: unassignedProspects.length,
        prospectsNeedingTourCompletion: prospectsNeedingTourCompletion.length,
        applicationPendingCompletionProspects:
          applicationPendingCompletionProspects.length,
        unitsNeedingFollowup: Object.keys($scope.data.todo.unitResidents)
          .length,
        followUpResidentCount: followUpResidents.length,
        scheduledToursCount: selfScheduledTours.length
      };

      $scope.data.todo.openManagerInbox = function (managerId) {
        $scope.$apply(() => {
          conversationsService.openManagerInbox(managerId, true);
        });
      };

      $scope.data.todo.renderEmptyListMessage =
        $scope.data.todo.totals.newProspects +
          $scope.data.todo.totals.visitFollowupProspects +
          $scope.data.todo.totals.pastDueProspects +
          $scope.data.todo.totals.staleProspects +
          $scope.data.todo.totals.prospectsNeedingTourCompletion +
          $scope.data.todo.totals.unitsNeedingFollowup ===
          0 && !$scope.data.todo.yardiInsufficientPermissions;

      $scope.data.todo.managerProspects = reduce(
        managersInfoById,
        function (result, managerInfo, managerId) {
          var managerIdInt = parseInt(managerId, 10);
          var managerNewProspects = filter(newProspects, {
            assigned_manager_id: parseInt(managerId)
          });
          var managerProspects = {
            newProspects: groupBy(managerNewProspects, 'first_contact_type'),
            hasNewProspects: managerNewProspects.length > 0,
            prospectsNeedingTourCompletion: filter(
              prospectsNeedingTourCompletion,
              { assigned_manager_id: managerIdInt }
            ),
            pastDueProspects: filter(pastDueProspects, {
              assigned_manager_id: managerIdInt
            }),
            visitFollowupProspects: filter(visitFollowupProspects, {
              assigned_manager_id: managerIdInt
            }),
            staleProspects: filter(staleProspects, {
              assigned_manager_id: managerIdInt
            }),
            unassignedProspects: filter(unassignedProspects, {
              assigned_manager_id: managerIdInt
            }),
            managerInfo: managerInfo,
            applicationPendingCompletionProspects: filter(
              applicationPendingCompletionProspects,
              { assigned_manager_id: managerIdInt }
            ),
            selfScheduledTours: filter(selfScheduledTours, {
              assigned_manager_id: managerIdInt
            })
          };

          result.push(managerProspects);

          return result;
        },
        []
      );

      //DemandX
      if ($rootScope.appPreferences.company.enable_demand_x) {
        $scope.data.todo.managerProspects = await self._getHotLeads(
          $scope.data.todo.managerProspects
        );
      }
    };

    self._setupStatsData = function (statsData) {
      $scope.data.stats.engagementScore = statsData.engagement_score;
      $scope.data.stats.greenProspects = statsData.green_prospects;
      $scope.data.stats.redProspects = statsData.red_prospects;
      $scope.data.stats.leadsThisWeek = statsData.leads_this_week;
      $scope.data.stats.toursThisWeek = statsData.tours_this_week;
      $scope.data.stats.leasesThisWeek = statsData.leases_this_week;
    };

    self._setupEngagementStatsData = function (statsData) {
      $scope.data.stats.engagementScore = statsData.engagement_score;
      $scope.data.stats.greenProspects = statsData.green_prospects;
      $scope.data.stats.redProspects = statsData.red_prospects;
      $scope.data.stats.redProspectIds = statsData.red_prospect_ids;
    };

    self._setupConversionStatsData = function (statsData) {
      $scope.data.stats.leadsThisWeek = statsData.leads_this_week;
      $scope.data.stats.toursThisWeek = statsData.tours_this_week;
      $scope.data.stats.leasesThisWeek = statsData.leases_this_week;
    };

    self._setupResidentStatsData = function (residentStatsData) {
      $scope.data.stats.residentEngagementScore =
        residentStatsData.resident_engagement_score;
    };

    self._setupToursData = function (toursData, managersInfoById) {
      var mapUpcomingAppointment = function (appointment) {
        return {
          startTime: appointment.start_time,
          prospectName: appointment.prospect_name,
          streamId: appointment.stream_id,
          managerInfo: managersInfoById[appointment.manager_id]
        };
      };

      $scope.data.tours.upcomingAppointments = map(
        toursData.upcoming_appointments,
        mapUpcomingAppointment
      );
    };

    self._setupMessagesData = function (messagesData, managersInfoById) {
      var total = 0;
      var managerUnreadMessageCounts = [];

      if (messagesData.unread_message_counts_by_manager_id) {
        for (const [manager_id, unread_message_dictionary] of Object.entries(
          messagesData.unread_message_counts_by_manager_id
        )) {
          const manager_total_unread_count = unread_message_dictionary['total']
            ? unread_message_dictionary['total']
            : 0;
          total += manager_total_unread_count;

          const manager_info_and_count = {
            count: manager_total_unread_count,
            managerInfo: managersInfoById[manager_id]
          };

          managerUnreadMessageCounts.push(manager_info_and_count);
        }
      }

      $scope.data.todo.managerUnreadMessageTotal = total;
      $scope.data.messages.managerUnreadMessageTotal = total;
      $scope.data.messages.managerUnreadMessageCounts =
        managerUnreadMessageCounts;
    };

    self._setupOnlineApplicationsData = function (onlineApplicationsData) {
      const mappedOnlineApplicationProspect = function (prospect) {
        return {
          id: prospect.id,
          name: prospect.name,
          applicationDate: prospect.application_date,
          streamId: prospect.stream_id,
          status: prospect.status,
          statusDisplay: prospectStatusMap[prospect.status]
        };
      };

      $scope.data.onlineApplications.prospects = map(
        onlineApplicationsData.prospects,
        mappedOnlineApplicationProspect
      );
    };

    self._setupRenewalsData = function (renewalsData) {
      var mappedRenewal = function (renewal) {
        return {
          residentName: renewal.resident_name,
          leaseEnd: renewal.lease_end,
          streamId: renewal.stream_id
        };
      };

      $scope.data.renewals.upcomingRenewals = map(
        renewalsData.upcoming_renewals,
        mappedRenewal
      );
    };

    self._getProfile = function () {
      return ProfileService.getProfile();
    };

    self._getDashboardData = function () {
      return dashboardApi.getDashboardData(null);
    };

    self._getDashboardAnalyticsData = function () {
      return dashboardApi.getDashboardAnalyticsData(true);
    };

    self._getHotLeads = async function (managerProspects) {
      let prospects = reduce(
        managerProspects,
        (allProspects, currentManagerProspects, index) => {
          const {
            newProspects,
            pastDueProspects,
            prospectsNeedingTourCompletion,
            staleProspects,
            unassignedProspects,
            visitFollowupProspects
          } = currentManagerProspects;

          //New prospects requires a different way because is an object
          const newProspectsKeys = Object.keys(newProspects);
          if (newProspectsKeys.length > 0) {
            for (let i = 0; i < newProspectsKeys.length; i++) {
              allProspects.push(
                ...newProspects[newProspectsKeys[i]].map((newProspect) => ({
                  prospect_id: newProspect.id,
                  property_id: newProspect.property_id
                }))
              );
            }
          }

          //The rest can be merge in a single array
          allProspects.push(
            ...[
              ...pastDueProspects,
              ...prospectsNeedingTourCompletion,
              ...staleProspects,
              ...unassignedProspects,
              ...visitFollowupProspects
            ].map((newProspect) => ({
              prospect_id: newProspect.id,
              property_id: newProspect.property_id
            }))
          );

          return allProspects;
        },
        []
      );

      const uniqueProspectIds = new Set();
      prospects = prospects.filter((prospect) => {
        if (uniqueProspectIds.has(prospect.prospect_id)) {
          return false;
        }
        uniqueProspectIds.add(prospect.prospect_id);
        return true;
      });

      try {
        const nextBestActionScores = [];
        const batchPromises = [];

        const batchSize = 250;
        for (let i = 0; i < prospects.length; i += batchSize) {
          const prospectBatch = prospects.slice(i, i + batchSize);
          batchPromises.push(
            getNextBestActionScoreByProspects(
              appDataService.data.currentCompany.id,
              prospectBatch
            )
              .then((nbaScoresBatch) => {
                nextBestActionScores.push(...nbaScoresBatch);
              })
              .catch((err) => {
                // we can ignore 404 errors as this result just means the set of
                // prospect records does not have DemandX data associated with it
                if (err.response.status !== 404) {
                  throw err;
                }
              })
          );
        }

        await Promise.all(batchPromises);

        //Add hot lead prop to the prospect
        const setHotLead = (prospect) => {
          prospect.hotLead = true;
          return prospect;
        };

        //Filter hotleads from the response
        const filterHotLeads = (prospectValue) =>
          nextBestActionScores.find((nextBestActionScore, index) => {
            if (!nextBestActionScore) {
              return false;
            }
            const exists = nextBestActionScore.prospectId === prospectValue.id;

            //If the prospect exists in the response, it should be removed
            //to reduce the time for each call of the filter
            if (exists) {
              nextBestActionScores.splice(index, 1);
            }
            return (
              exists &&
              getNbaHotLead(
                nextBestActionScore,
                $rootScope.featureFlags.DEMAND_X_PRIORITY
              )
            );
          });

        //sort hot leads
        const sortHotLeads = (prospectA, prospectB) => {
          if (prospectA.hotLead && prospectB.hotLead) {
            return 0;
          }
          if (prospectA.hotLead && !prospectB.hotLead) {
            return -1;
          }
          return 1;
        };

        managerProspects = reduce(
          managerProspects,
          (allProspects, currentManagerProspects, index) => {
            let {
              newProspects,
              pastDueProspects,
              prospectsNeedingTourCompletion,
              staleProspects,
              unassignedProspects,
              visitFollowupProspects
            } = currentManagerProspects;

            const newProspectsKeys = Object.keys(newProspects);
            if (newProspectsKeys.length > 0) {
              for (let i = 0; i < newProspectsKeys.length; i++) {
                const hotLeadNewProspects =
                  newProspects[newProspectsKeys[i]].filter(filterHotLeads);
                hotLeadNewProspects.forEach(setHotLead);
                console.log(newProspectsKeys[i], hotLeadNewProspects);
                if (hotLeadNewProspects.length > 0) {
                  newProspects[newProspectsKeys[i]] =
                    newProspects[newProspectsKeys[i]].sort(sortHotLeads);
                }
              }
            }

            const pastDueHotLeads = pastDueProspects.filter(filterHotLeads);
            pastDueHotLeads.forEach(setHotLead);
            pastDueProspects = pastDueProspects.sort(sortHotLeads);

            const hotLeadsNeedingTourCompletion =
              prospectsNeedingTourCompletion.filter(filterHotLeads);
            hotLeadsNeedingTourCompletion.forEach(setHotLead);
            prospectsNeedingTourCompletion =
              prospectsNeedingTourCompletion.sort(sortHotLeads);

            const staleHotLeads = staleProspects.filter(filterHotLeads);
            staleHotLeads.forEach(setHotLead);
            staleProspects = staleProspects.sort(sortHotLeads);

            const unassignedHotLeads =
              unassignedProspects.filter(filterHotLeads);
            unassignedHotLeads.forEach(setHotLead);
            unassignedProspects = unassignedProspects.sort(sortHotLeads);

            const visitFollowupHotLeads =
              visitFollowupProspects.filter(filterHotLeads);
            visitFollowupHotLeads.forEach(setHotLead);
            visitFollowupProspects = visitFollowupProspects.sort(sortHotLeads);

            allProspects.push(currentManagerProspects);

            return allProspects;
          },
          []
        );
      } catch (error) {
        console.error('DemandX Error:', error);
      }
      return managerProspects;
    };

    self._initialize();
  };
  TodoDashboardController.$inject = [
    '$rootScope',
    '$scope',
    '$moment',
    '$q',
    'appDataService',
    'todoService',
    'ProfileService',
    'timeService',
    'dashboardApi',
    'teamApi',
    'conversationTeamInboxSettingsFactory',
    'apiEvents',
    'userService',
    'conversationsService',
    'prospectStatusMap'
  ];
  app.controller('TodoDashboardController', TodoDashboardController);
})(window.angular);
