import React, { FC } from 'react';
import { useNearbyCommunities } from './useNearbyCommunities';

import ReferralDialog from './ReferralDialog';

const ReferralDialogContainer: FC<any> = (props) => {
  const { onReferral } = props;
  const {
    sourceProspectId,
    isReferralDialogOpen,
    referralTargetPropertyId,
    nearbyCommunities,
    closeReferralDialog,
    submitReferral,
    popupSnackbar,
    isReferring
  } = useNearbyCommunities();

  const nearbyCommunity = nearbyCommunities
    ? nearbyCommunities.find(
        (community) => community.property.id === referralTargetPropertyId
      )
    : undefined;

  return (
    <ReferralDialog
      submit={submitReferral}
      cancel={closeReferralDialog}
      isOpen={isReferralDialogOpen}
      isReferring={isReferring}
      nearbyCommunity={nearbyCommunity}
      sourceProspectId={sourceProspectId}
      popupSnackbar={popupSnackbar}
      onReferral={onReferral}
    />
  );
};

export default ReferralDialogContainer;
