/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  var rootUrlPath = window.location.pathname.substring(1).split('/')[0];

  window.addEventListener('load', function () {
    if (rootUrlPath !== 'community' && rootUrlPath !== 'listing') {
      if (
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        require('jquery-emoji-picker/css/jquery.emojipicker.css');
        require('jquery-emoji-picker/css/jquery.emojipicker.a.css');
        require('jquery-emoji-picker/js/jquery.emojipicker');
        require('jquery-emoji-picker/js/jquery.emojipicker.a');
      }
    }
  });
})();
