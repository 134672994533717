import React from 'react';

export const EnvelopeIcon = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.625 5.25C1.625 4.42157 2.29657 3.75 3.125 3.75H18.875C19.7034 3.75 20.375 4.42157 20.375 5.25V14.75C20.375 15.5784 19.7034 16.25 18.875 16.25H3.125C2.29657 16.25 1.625 15.5784 1.625 14.75V5.25Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0723 8.3125L17.2498 11.25"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.9275 8.3125L4.75 11.25"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.0664 4.17834L12.1389 9.64501C11.4533 10.1184 10.5463 10.1184 9.86061 9.64501L1.93311 4.17834"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
