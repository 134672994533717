/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.filter('activityHeader', [
    '$moment',
    function ($moment) {
      return function (activity, manager) {
        if (isEmpty(activity) || isEmpty(manager)) {
          return;
        }

        if (activity.type === 'reminder') {
          return (
            manager.first_name +
            ' added a reminder for ' +
            $moment(activity.reminder_time).format('MMM Do, YYYY')
          );
        }

        if (activity.type === 'note') {
          return manager.first_name + ' added a note';
        }

        if (activity.type === 'email') {
          return manager.first_name + ' added an email';
        }

        if (activity.type === 'call') {
          return manager.first_name + ' added a call';
        }

        if (activity.type === 'incoming_call') {
          return manager.first_name + ' received a call';
        }

        if (activity.type === 'voicemail') {
          return manager.first_name + ' added a voicemail';
        }

        return manager.first_name + ' added an activity';
      };
    }
  ]);
})(window.angular);
