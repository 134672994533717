/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('chatAttachment', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/messages/chat-attachment.html',
      scope: {
        attachment: '=',
        hideControls: '=?',
        message: '='
      },
      controller: [
        '$scope',
        '$sce',
        '$routeParams',
        'attachmentService',
        function ($scope, $sce, $routeParams, attachmentService) {
          $scope.isInternal = $routeParams.internal === 'true';

          $scope.isValidAttachment = function () {
            return (
              $scope.isImageAttachment() ||
              $scope.isVideoAttachment() ||
              $scope.isPDFAttachment() ||
              $scope.isUnsupportedAttachment()
            );
          };

          $scope.isImageAttachment = function () {
            if ($scope.attachment.mimetype) {
              return attachmentService.isImageAttachment(
                $scope.attachment.mimetype
              );
            }
            return $scope.attachment.type === 'image';
          };

          $scope.isVideoAttachment = function () {
            if ($scope.attachment.mimetype) {
              return attachmentService.isVideoAttachment(
                $scope.attachment.mimetype
              );
            }
            return $scope.attachment.type === 'video';
          };

          $scope.isPDFAttachment = function () {
            if ($scope.attachment.mimetype) {
              return attachmentService.isPDFAttachment(
                $scope.attachment.mimetype
              );
            }
            return $scope.attachment.type === 'pdf';
          };

          $scope.isUnsupportedAttachment = function () {
            if ($scope.attachment.mimetype) {
              return attachmentService.isUnsupportedAttachment(
                $scope.attachment.mimetype
              );
            }
            return false; // only display unsupported attachments if mimetype is present
          };

          $scope.videoTimestamp = function (currentTime, duration) {
            var roundedCurrentTime = Math.round(currentTime);
            var roundedDuration = Math.round(duration);

            var currentTimeMinutes = Math.floor(roundedCurrentTime / 60);
            var currentTimeSeconds =
              roundedCurrentTime - currentTimeMinutes * 60;

            var durationMinutes = Math.floor(roundedDuration / 60);
            var durationSeconds = roundedDuration - durationMinutes * 60;

            var formatTime = function (minutes, seconds) {
              var formattedSeconds = ('0' + seconds).slice(-2);
              return minutes + ':' + formattedSeconds;
            };

            return (
              formatTime(currentTimeMinutes, currentTimeSeconds) +
              ' / ' +
              formatTime(durationMinutes, durationSeconds)
            );
          };

          $scope.$watch('attachment.url', function (url) {
            $scope.getSafeUrl = $sce.trustAsResourceUrl(url);
          });
        }
      ]
    };
  });
})(window.angular);
