import React, { FC, ReactNode } from 'react';
import './_Modal.scss';

interface ModalProps {
  children?: ReactNode;
  style?: React.CSSProperties;
}

const Modal: FC<ModalProps> = ({ children, style }) => (
  <div className="react-modal-background">
    <div className="react-modal-container" style={style}>
      {children}
    </div>
  </div>
);

export default Modal;
