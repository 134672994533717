import React, { ChangeEvent, useState, useMemo, useEffect } from 'react';
import {
  Box,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import { useMarkAsLostContext } from './MarkAsLostDrawerContextProvider';
import { StyledDatePicker } from '../../DatePicker/DatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  getCurrentFutureDate,
  ReferralFollowUpProps
} from './MarkAsLostDrawer';
import dayjs from 'dayjs';

import { makeStyles, NamedColors } from '@knockrentals/knock-shared-web';
import { colors } from '../../commonStyles/commonStyles';

const useStyles = makeStyles({
  formControl: {
    width: '100%'
  },
  textField: {
    border: 'none',
    '& .MuiFilledInput-root': {
      border: 'none'
    },
    '& .MuiInputBase-root': {
      border: 'none',
      paddingLeft: '0px'
    },
    top: '-40px',
    marginTop: '16px',
    '& .MuiInputBase-input': {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px'
    }
  },
  label: {
    left: '0px',
    top: '8px',
    height: '0px',
    fontWeight: 400,
    '&.MuiInputLabel-shrink:not(.Mui-focused)': {
      color: colors.secondaryText
    }
  },
  input: {
    border: 'none',
    color: colors.defaultText,
    backgroundColor: colors.inputBackground,
    paddingLeft: '0px !important',
    '& .MuiFilledInput-root': {
      border: 'none',
      backgroundColor: colors.inputBackground
    },
    '& .MuiInputBase-root': {
      border: 'none',
      paddingLeft: '0px !important',
      '&.Mui-focused': {
        border: 'none',
        backgroundColor: colors.inputBackground
      }
    },

    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  },
  formLabel: {
    top: '9px',
    left: '-3px',
    fontWeight: 400,
    color: colors.secondaryText,
    height: '0px'
  },
  selectField: {
    width: '100%',
    border: 'none !important',
    color: 'black !important',
    backgroundColor: colors.inputBackground,
    '& .MuiFormLabel-filled': {
      left: '-3px'
    },
    '& .MuiSelect-root': {
      paddingLeft: '1px'
    },
    '& .MuiInputLabel-formControl': {
      left: '0',
      margin: 0,
      transform: 'none',
      border: 'none !important'
    },
    '& .MuiInputBase-formControl': {
      borderColor: NamedColors.slate[300],
      margin: '0px !important'
    },
    '& .MuiTypography-root': {
      fontSize: '14px'
    },
    '& .MuiFormHelperText-root': {
      color: colors.inputError,
      marginLeft: '4px'
    }
  },
  container: {
    paddingTop: '16px'
  },
  noteField: {
    height: '28px'
  }
});

const ReferralOptions = () => {
  const classes = useStyles();
  const { referralFollowUp, setReferralFollowUp, referralFollowUpTimes } =
    useMarkAsLostContext();
  const { date, time, note, dateError } = referralFollowUp;

  const [noteFocused, setNoteFocused] = useState(false);
  const handleChangeDate = (date: MaterialUiPickersDate) => {
    let dateError = !dayjs(date).isValid()
      ? 'Please add a valid follow up date'
      : undefined;
    if (dayjs(date).isValid()) {
      const now = dayjs(new Date());
      if (dayjs(date).diff(now, 'hour') <= -24) {
        dateError = 'Please add a valid follow up date';
      }
    }
    setReferralFollowUp((prevState: ReferralFollowUpProps) => ({
      ...prevState,
      date: new Date(date || ''),
      dateError
    }));
  };

  const handleChange = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    e.persist();
    setReferralFollowUp((prevState: ReferralFollowUpProps) => ({
      ...prevState,
      [e.target.name || '']: e.target.value
    }));
  };

  const noteLabel = useMemo<string>(() => {
    if (note || noteFocused) {
      return 'Note';
    }
    return 'Follow Up Note';
  }, [note, noteFocused]);

  useEffect(() => {
    if (dateError) {
      setReferralFollowUp((prevState: ReferralFollowUpProps) => ({
        ...prevState,
        date: getCurrentFutureDate(),
        dateError: undefined
      }));
    }
    //We only want to detect the dateError on first render
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6}>
          <StyledDatePicker
            id="followUpDate"
            minDate={new Date()}
            name="followUpDate"
            label="Date"
            value={date}
            onChange={handleChangeDate}
            error={!!dateError}
            helperText={dateError}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="times-label" className={classes.formLabel}>
              Time
            </InputLabel>
            <Select
              labelId="times-label"
              id="times"
              name="time"
              onChange={handleChange}
              className={classes.selectField}
              inputProps={{ 'data-testid': 'time' }}
              value={time}
            >
              {referralFollowUpTimes.map((time: string) => (
                <MenuItem key={time} value={time}>
                  {time}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            className={`${classes.formControl} ${classes.noteField}`}
          >
            <TextField
              id="note"
              label={noteLabel}
              onFocus={() => setNoteFocused(true)}
              onBlur={() => setNoteFocused(false)}
              name="note"
              variant="filled"
              className={classes.textField}
              onChange={handleChange}
              InputProps={{
                className: classes.input
              }}
              InputLabelProps={{
                classes: {
                  root: classes.label
                }
              }}
              value={note}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReferralOptions;
