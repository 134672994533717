/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('managerCalendarEventsService', [
    '$rootScope',
    function ($rootScope) {
      var self = this;

      self.eventTypes = {
        refreshCalendar: 'refreshCalendar',
        calendarWasUpdated: 'calendarWasUpdated',
        pendingRequest: {
          wasAccepted: 'calendarPendingRequestAccepted',
          wasCanceled: 'calendarPendingRequestCanceled'
        },
        primeTimes: {
          wereModified: 'calendarPrimeTimesWereModified'
        },
        appointmentProposals: {
          wereProposed: 'calendarAppointmentsWereProposed'
        },
        confirmedAppointment: {
          wasAddedManually: 'calendarConfirmedAppointmentAddedManually',
          wasCanceled: 'calendarConfirmedAppointmentCanceled',
          changedTime: 'calendarConfirmedAppointmentChangedTime',
          changedManager: 'calendarConfirmedAppointmentChangedManager'
        },
        visitedAppointment: {
          changedManager: 'calendarVisitedAppointmentChangedManager'
        },
        calendarEvents: {
          wasAdded: 'calendarEventAdded'
        },
        reminders: {
          wasAdded: 'reminderActivityWasAdded',
          wasUpdated: 'reminderActivityWasUpdated',
          wasDeleted: 'reminderActivityWasDeleted'
        },
        managerProfile: {
          wasUpdated: 'calendarManagerProfileWasUpdated'
        },
        eventSource: {
          wasRemoved: 'calendarEventSourceWasRemoved',
          wasAdded: 'calendarEventSourceWasAdded'
        },
        visit: {
          wasAdded: 'calendarEventVisitWasAdded',
          wasRemoved: 'calendarEventVisitWasRemoved'
        }
      };

      self._fireEvent = function (eventType, args) {
        args.splice(0, 0, eventType);
        $rootScope.$emit.apply($rootScope, args);

        var updateArgs = angular.copy(args);
        updateArgs.splice(0, 0, 'calendarWasUpdated');
        $rootScope.$emit.apply($rootScope, updateArgs);
      };

      self.refreshCalendar = function () {
        self._fireEvent(self.eventTypes.refreshCalendar, []);
      };

      self.pendingRequestWasAccepted = function (request) {
        self._fireEvent(self.eventTypes.pendingRequest.wasAccepted, [request]);
      };

      self.pendingRequestWasCanceled = function (request) {
        self._fireEvent(self.eventTypes.pendingRequest.wasCanceled, [request]);
      };

      self.primeTimesWereModified = function (listing, newPrimeTimes) {
        self._fireEvent(self.eventTypes.primeTimes.wereModified, [
          listing,
          newPrimeTimes
        ]);
      };

      self.confirmedAppointmentWasAddedManually = function (appointment) {
        self._fireEvent(self.eventTypes.confirmedAppointment.wasAddedManually, [
          appointment
        ]);
      };

      self.confirmedAppointmentWasCanceled = function (appointment) {
        self._fireEvent(self.eventTypes.confirmedAppointment.wasCanceled, [
          appointment
        ]);
      };

      self.confirmedAppointmentChangedTime = function (appointment, newTimes) {
        self._fireEvent(self.eventTypes.confirmedAppointment.changedTime, [
          appointment,
          newTimes
        ]);
      };

      self.confirmedAppointmentChangedManager = function (
        appointment,
        newManager
      ) {
        self._fireEvent(self.eventTypes.confirmedAppointment.changedManager, [
          appointment,
          newManager
        ]);
      };

      self.visitedAppointmentChangedManager = function (
        appointment,
        newManager,
        visit
      ) {
        self._fireEvent(self.eventTypes.visitedAppointment.changedManager, [
          appointment,
          newManager,
          visit
        ]);
      };

      self.newAppointmentTimesWereProposed = function (proposals, oldRequests) {
        self._fireEvent(self.eventTypes.appointmentProposals.wereProposed, [
          proposals,
          oldRequests
        ]);
      };

      self.reminderWasAdded = function (reminderActivity) {
        self._fireEvent(self.eventTypes.reminders.wasAdded, [reminderActivity]);
      };

      self.managerProfileWasUpdated = function (managerInfo) {
        self._fireEvent(self.eventTypes.managerProfile.wasUpdated, [
          managerInfo
        ]);
      };

      self.visitWasAdded = function (visit) {
        self._fireEvent(self.eventTypes.visit.wasAdded, [visit]);
      };

      self.visitWasRemoved = function (visitId) {
        self._fireEvent(self.eventTypes.visit.wasRemoved, [visitId]);
      };

      self.eventSourceWasRemoved = function (eventSourceId) {
        self._fireEvent(self.eventTypes.eventSource.wasRemoved, [
          eventSourceId
        ]);
      };

      self.eventSourceWasAdded = function (eventSourceId) {
        self._fireEvent(self.eventTypes.eventSource.wasAdded, [eventSourceId]);
      };

      return self;
    }
  ]);
})(window.angular);
