import React, { FC } from 'react';
import classnames from 'classnames';
import { Theme } from '@material-ui/core';

import {
  Button,
  makeStyles,
  NamedColors,
  ThemeProvider
} from '@knockrentals/knock-shared-web';
import { SendMessageIcon } from '../icons/SendMessageIcon';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: NamedColors.white,

    '& svg': {
      marginRight: '8px'
    },

    '&:focus': {
      color: NamedColors.white
    }
  },

  disabled: {
    '& svg path': {
      stroke: theme.palette.action.disabled
    }
  }
}));

export interface SendButtonProps {
  disable: boolean;
  onClick?: () => void;
}

export const SendButton: FC<SendButtonProps> = ({ disable, onClick }) => {
  const classes = useStyles();

  const handleClick = () => {
    if (!disable && onClick) {
      onClick();
    }
  };

  return (
    <ThemeProvider>
      <Button
        variant="contained"
        color="primary"
        className={classnames(classes.button, { [classes.disabled]: disable })}
        onClick={handleClick}
        disabled={disable}
      >
        <SendMessageIcon /> Send
      </Button>
    </ThemeProvider>
  );
};
