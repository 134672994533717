/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('ideNavigationLink', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/inlineDocumentEditor/ide-navigation-link.html',
      scope: {
        label: '@',
        scrollToId: '@',
        isCompleted: '='
      },
      controller: [
        '$scope',
        '$location',
        '$anchorScroll',
        'appDataService',
        function ($scope, $location, $anchorScroll, appDataService) {
          $scope.scrollToSection = function () {
            $location.hash($scope.scrollToId);

            $anchorScroll.yOffset = 60;
            $anchorScroll();
          };
          $scope.isMultiteam = appDataService.isMultiteamUser();
        }
      ]
    };
  });

  app.directive('idePreviewLink', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/inlineDocumentEditor/ide-preview-link.html',
      scope: {
        onClick: '=',
        label: '@?'
      }
    };
  });
})();
