/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
require('../../core/scripts/userInteractions/userInteractions.module');
require('../../core/scripts/userInteractions/userInteractions.service');
require('../../core/scripts/userInteractions/userInteractionsActivityHeader.filter');
require('../../core/scripts/userInteractions/userInteractionsPanel.directive');
require('../../core/scripts/userInteractions/userInteractionsResidentDetails.directive');
require('../../core/scripts/userInteractions/userInteractionsAppointment.directive');
require('../../core/scripts/userInteractions/userInteractionsUserCard.directive');
require('../../core/scripts/userInteractions/userInteractionsStatusSelect.directive');
require('../../core/scripts/userInteractions/userInteractionsSourceSelect.directive');
require('../../core/scripts/userInteractions/userInteractionsManagerSelect.directive');
require('../../core/scripts/userInteractions/appointmentCard.directive');
require('../../core/scripts/userInteractions/transferAppointmentModal.controller');
require('../../core/scripts/userInteractions/transferProspectModal.controller');
require('../../core/scripts/userInteractions/transferResidentModal.controller');
require('../../core/scripts/userInteractions/availableUnits.directive');
require('../../core/scripts/userInteractions/availableUnitsModal.directive');
require('../../core/scripts/userInteractions/assignPropertyModal.service');
require('../../core/scripts/userInteractions/prospectLossReasons.directive');
require('../../core/scripts/userInteractions/prospectLossReasons.service');
require('../../core/scripts/userInteractions/userInteractionsResidentPackageModal.service');
