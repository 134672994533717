/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import countBy from 'lodash/countBy';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const ResidentMessageController = function (
    $q,
    $mdDialog,
    $mdToast,
    $rootScope,
    $scope,
    recipients,
    priority,
    appDataService,
    conversationsService
  ) {
    var self = this;

    $scope.emailAttachments = $scope.emailAttachments || {
      local: [],
      remote: []
    };

    $scope.smsAttachments = $scope.smsAttachments || {
      local: [],
      remote: []
    };

    $scope.message = {
      subject: '',
      text: '',
      html: ''
    };

    $scope.data = {
      isSending: false,
      leasingTeamId: null,
      priority: priority,
      recipients: recipients,
      nonSendableCounts: {
        sms: 0,
        email: 0
      },
      hideSms: false,
      hideEmail: false,
      showFallbackSms: false,
      showFallbackEmail: false,
      updateContactTime: false,
      sendingManager: null
    };

    self._initialize = function () {
      self._getLeasingTeamId();
      self._getNonSendableCounts();
      self._getManagerName();
    };

    self._getLeasingTeamId = function () {
      $scope.data.leasingTeamId = appDataService.getCurrentLeasingTeamId();
    };

    self._getNonSendableCounts = function () {
      $scope.data.nonSendableCounts.sms =
        countBy($scope.data.recipients, self._canRecipientReceiveSMS).false ||
        0;

      $scope.data.nonSendableCounts.email =
        countBy($scope.data.recipients, self._canRecipientReceiveEmail).false ||
        0;

      $scope.data.nonSendableCounts.emailAndSMS =
        countBy($scope.data.recipients, function (recipient) {
          return (
            !self._canRecipientReceiveSMS(recipient) &&
            !self._canRecipientReceiveEmail(recipient)
          );
        }).true || 0;
    };

    self._getManagerName = function () {
      const manager = appDataService.getTeamMember(
        $scope.data.recipients[0]['addedBy']
      );
      if (manager) {
        $scope.data.sendingManager = `${manager.ManagerInfo.first_name} ${manager.ManagerInfo.last_name}`;
      }
    };

    self._canRecipientReceiveSMS = function (recipient) {
      return (
        !!recipient.info.phone &&
        recipient.info.phone.can_receive_sms &&
        !!recipient.sms_consent &&
        recipient.sms_consent.status === 'granted'
      );
    };

    self._canRecipientReceiveEmail = function (recipient) {
      return !!recipient.info.email;
    };

    self._isFallbackRecipient = function (recipient) {
      return priority === 'sms'
        ? !self._canRecipientReceiveSMS(recipient)
        : !self._canRecipientReceiveEmail(recipient);
    };

    self._emptyMessageType = function (target) {
      if (target === 'sms') {
        $scope.message.text = '';
      } else {
        $scope.message.html = '';
        $scope.message.subject = '';
      }
    };

    self._tryCloseModal = function () {
      if (
        ($scope.data.hideSms && $scope.data.hideEmail) ||
        $scope.data.fallbackRecipients.length === 0 ||
        $scope.data.updateContactTime
      ) {
        $mdDialog.hide();
      }
    };

    $scope.cancelMessageType = function (target) {
      if ($scope.data.showFallbackEmail || $scope.data.showFallbackSms) {
        if (target === 'email') {
          $scope.data.hideEmail = true;
        } else {
          $scope.data.hideSms = true;
        }
        if (
          $scope.data.priority === 'sms' &&
          $scope.data.recipientsCopy.length === 0
        ) {
          $scope.data.hideSms = true;
        }
        if (
          $scope.data.priority === 'email' &&
          $scope.data.recipientsCopy.length === 0
        ) {
          $scope.data.hideEmail = true;
        }
      } else {
        $scope.data.hideEmail = true;
        $scope.data.hideSms = true;
      }
      self._tryCloseModal();
    };

    $scope.toggleRecipient = (recipient) => {
      if (recipient.is_included) {
        $scope.data.recipientsCopy = $scope.data.recipientsCopy.filter(
          (rec) => rec.id !== recipient.id
        );
        recipient.is_included = false;
      } else {
        $scope.data.recipientsCopy.push(recipient);
        recipient.is_included = true;
      }
    };

    $scope.data.recipientsCopy = cloneDeep(recipients);
    $scope.data.fallbackRecipients = remove(
      $scope.data.recipientsCopy,
      function (recipient) {
        return self._isFallbackRecipient(recipient);
      }
    );

    var tense =
      $scope.data.fallbackRecipients.length > 1
        ? ' residents are not able to receive '
        : ' resident is unable to receive ';

    var formattedPriority = $scope.data.priority === 'email' ? 'E-mail' : 'SMS';

    $scope.data.fallback = $scope.data.priority === 'email' ? 'sms' : 'email';
    $scope.data.heading =
      'Send ' +
      formattedPriority +
      ' to ' +
      $scope.data.recipientsCopy.length +
      ' residents';
    $scope.data.backupHeading =
      $scope.data.fallbackRecipients.length +
      tense +
      $scope.data.priority +
      '. Send ' +
      $scope.data.fallback +
      ' instead?';

    $scope.data.smsBackHeading =
      $scope.data.fallbackRecipients.length +
      tense +
      $scope.data.priority +
      '.';

    $scope.toggleFallback = function (target) {
      if (target === 'sms') {
        $scope.data.showFallbackSms = !$scope.data.showFallbackSms;
      } else {
        $scope.data.showFallbackEmail = !$scope.data.showFallbackEmail;
      }
      self._tryCloseModal();
    };

    $scope.cancelMessage = function (target) {
      $scope.cancelMessageType(target);
    };

    $scope.sendMessage = function (target) {
      $scope.attachments =
        target === 'sms' ? $scope.smsAttachments : $scope.emailAttachments;

      $scope.data.isSending = true;

      var streamIds =
        target === priority
          ? map($scope.data.recipientsCopy, 'threadId')
          : map($scope.data.fallbackRecipients, 'threadId');

      conversationsService
        .sendMessageBatch(
          streamIds,
          $scope.message.text,
          $scope.message.html,
          $scope.message.subject,
          [target],
          $scope.attachments.remote,
          undefined,
          $scope.data.updateContactTime
        )
        .then(
          function () {
            $mdToast.showSimple('Messages sending!');

            self._emptyMessageType(target);
            $scope.cancelMessageType(target);

            $scope.data.isSending = false;
          },
          function () {
            $mdToast.showSimple('Error sending messages!');
            $scope.data.isSending = false;
          }
        );
    };

    self._initialize();
  };
  ResidentMessageController.$inject = [
    '$q',
    '$mdDialog',
    '$mdToast',
    '$rootScope',
    '$scope',
    'recipients',
    'priority',
    'appDataService',
    'conversationsService'
  ];

  app.controller('ResidentMessageController', ResidentMessageController);
})(window.angular);
