import React, { FC } from 'react';

export const BathIcon: FC = () => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.16667 9.5H15.5L15.06 12.1667C14.9024 13.0992 14.4195 13.9457 13.6969 14.5559C12.9744 15.1661 12.0591 15.5006 11.1133 15.5H4.88667C3.94094 15.5006 3.02558 15.1661 2.30306 14.5559C1.58055 13.9457 1.09762 13.0992 0.94 12.1667L0.5 9.5H4.16667"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66669 13.1667H4.66669C4.53462 13.1649 4.40844 13.1117 4.31504 13.0183C4.22165 12.9249 4.16841 12.7987 4.16669 12.6667V9.5C4.16669 9.23478 4.27204 8.98043 4.45958 8.79289C4.64712 8.60536 4.90147 8.5 5.16669 8.5H8.16669C8.4319 8.5 8.68626 8.60536 8.87379 8.79289C9.06133 8.98043 9.16669 9.23478 9.16669 9.5V12.6667C9.16496 12.7987 9.11173 12.9249 9.01833 13.0183C8.92493 13.1117 8.79876 13.1649 8.66669 13.1667Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 2.5C10.5 1.96957 10.7107 1.46086 11.0858 1.08579C11.4609 0.710714 11.9696 0.5 12.5 0.5C13.0304 0.5 13.5391 0.710714 13.9142 1.08579C14.2893 1.46086 14.5 1.96957 14.5 2.5V9.5"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 4.5C8.5 3.96957 8.71071 3.46086 9.08579 3.08579C9.46086 2.71071 9.96957 2.5 10.5 2.5C11.0304 2.5 11.5391 2.71071 11.9142 3.08579C12.2893 3.46086 12.5 3.96957 12.5 4.5H8.5Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
