export default function configureTextAngular(app: any) {
  const angular = require('angular');
  // Wrap the textAngular directive with config / mdDialog options.
  app.config([
    '$provide',
    function ($provide: any) {
      $provide.decorator('taOptions', [
        '$delegate',
        '$window',
        '$mdDialog',
        'taRegisterTool',
        'taSelection',
        'taToolFunctions',
        'taTranslations',
        function (
          $delegate: any,
          $window: any,
          $mdDialog: any,
          taRegisterTool: any,
          taSelection: any,
          taToolFunctions: any,
          taTranslations: any
        ) {
          $delegate.forceTextAngularSanitize = true;

          function showCustomLinkModal(url?: string) {
            url = url || 'http://';

            return $mdDialog.show({
              templateUrl: '/angular/views/conversations/add-url.html',
              parent: angular.element(document.body),
              clickOutsideToClose: true,
              controller: [
                '$scope',
                '$mdDialog',
                function ($scope: any, $mdDialog: any) {
                  $scope.data = {
                    url: url
                  };

                  $scope.save = function () {
                    $mdDialog.hide($scope.data.url);
                  };

                  $scope.cancel = function () {
                    $mdDialog.cancel();
                  };
                }
              ]
            });
          }

          var blockJavascript = function (link: any) {
            return link.toLowerCase().indexOf('javascript') !== -1;
          };

          var aOnSelectActionCustom = function (
            event: any,
            $element: any,
            editorScope: any
          ) {
            var modal = null;

            event.preventDefault();

            editorScope.displayElements.popover.css('width', '436px');

            var container = editorScope.displayElements.popoverContainer;
            container.empty();
            container.css('line-height', '28px');

            var link = angular.element(
              '<a href="' +
                $element.attr('href') +
                '" target="_blank">' +
                $element.attr('href') +
                '</a>'
            );
            link.css({
              display: 'inline-block',
              'max-width': '200px',
              overflow: 'hidden',
              'text-overflow': 'ellipsis',
              'white-space': 'nowrap',
              'vertical-align': 'middle'
            });

            container.append(link);

            var buttonGroup = angular.element(
              '<div class="btn-group pull-right">'
            );
            var reLinkButton = angular.element(
              '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1" unselectable="on" title="' +
                taTranslations.editLink.reLinkButton.tooltip +
                '"><i class="fa fa-edit icon-edit"></i></button>'
            );

            reLinkButton.on('click', function (event: any) {
              event.preventDefault();
              modal = showCustomLinkModal($element.attr('href'));
              modal.then(function (urlLink: any) {
                if (urlLink && urlLink !== '' && urlLink !== 'http://') {
                  $element.attr('href', urlLink);
                  editorScope.updateTaBindtaTextElement();
                }
                editorScope.hidePopover();
              });
            });

            buttonGroup.append(reLinkButton);

            var unLinkButton = angular.element(
              '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1" unselectable="on" title="' +
                taTranslations.editLink.unLinkButton.tooltip +
                '"><i class="fa fa-unlink icon-unlink"></i></button>'
            );

            unLinkButton.on('click', function (event: any) {
              event.preventDefault();
              $element.replaceWith($element.contents());
              editorScope.updateTaBindtaTextElement();
              editorScope.hidePopover();
            });

            buttonGroup.append(unLinkButton);

            var targetToggle = angular.element(
              '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1" unselectable="on">' +
                taTranslations.editLink.targetToggle.buttontext +
                '</button>'
            );

            if ($element.attr('target') === '_blank') {
              targetToggle.addClass('active');
            }

            targetToggle.on('click', function (event: any) {
              event.preventDefault();
              $element.attr(
                'target',
                $element.attr('target') === '_blank' ? '' : '_blank'
              );
              targetToggle.toggleClass('active');
              editorScope.updateTaBindtaTextElement();
            });

            buttonGroup.append(targetToggle);
            container.append(buttonGroup);

            editorScope.showPopover($element);
          };

          taRegisterTool('customP', {
            // buttontext: 'P',
            iconclass: 'fa fa-paragraph',
            tooltiptext: taTranslations.p.tooltip,
            action: function () {
              return this.$editor().wrapSelection('formatBlock', '<P>');
            },
            activeState: function () {
              return this.$editor().queryFormatBlockState('p');
            }
          });

          taRegisterTool('insertLinkModal', {
            tooltip: taTranslations.insertLink.tooltip,
            dialogPrompt: taTranslations.insertLink.dialogPrompt,
            tooltiptext: 'Insert / edit link',
            iconclass: 'fa fa-link',
            action: function () {
              var self = this;
              var modal = null;

              function saveSelection() {
                if (window.getSelection) {
                  var sel: any = window.getSelection();
                  if (sel.getRangeAt && sel.rangeCount) {
                    return sel.getRangeAt(0);
                  }
                } else {
                  // @ts-ignore
                  if (document.selection && document.selection.createRange) {
                    // @ts-ignore
                    return document.selection.createRange();
                  }
                }
                return null;
              }

              function restoreSelection(range: any) {
                if (range) {
                  if (window.getSelection) {
                    var sel: any = window.getSelection();
                    sel.removeAllRanges();
                    sel.addRange(range);
                  } else if ((document as any).selection && range.select) {
                    range.select();
                  }
                }
              }

              var selection = saveSelection();

              if (
                taSelection.getSelectionElement().tagName &&
                taSelection.getSelectionElement().tagName.toLowerCase() === 'a'
              ) {
                modal = showCustomLinkModal(
                  taSelection.getSelectionElement().href
                );
              } else {
                modal = showCustomLinkModal();
              }

              modal.then(function (urlLink: any) {
                restoreSelection(selection);

                if (urlLink && urlLink !== '' && urlLink !== 'http://') {
                  if (!blockJavascript(urlLink)) {
                    return self
                      .$editor()
                      .wrapSelection('createLink', urlLink, true);
                  }
                }
              });
            },
            activeState: function (commonElement: any) {
              return commonElement ? commonElement[0].tagName === 'A' : false;
            },
            onElementSelect: {
              element: 'a',
              action: aOnSelectActionCustom
            }
          });

          return $delegate;
        }
      ]);
    }
  ]);
}
