/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('smsOptInStatus', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospects/sms-opt-in-status.html',
      scope: {
        person: '='
      },
      controller: [
        '$scope',
        '$mdDialog',
        'prospectsApi',
        'residentsApi',
        function ($scope, $mdDialog, prospectsApi, residentsApi) {
          var self = this;

          self._consentStatusMappings = {
            granted: {
              text: 'Yes',
              tooltip:
                'This person has opted in to receive telemarketing / promotional messages via SMS text message.'
            },
            pending: {
              text: 'No',
              tooltip:
                'This person has not yet opted in to receive telemarketing / promotional messages via SMS text message.'
            },
            stopped: {
              text: 'Stopped',
              tooltip:
                'This person has requested to stop receiving all SMS text messages.'
            },
            declined: {
              text: 'Declined',
              tooltip:
                'This person has declined opting in to SMS text messages.'
            }
          };

          $scope.data = {
            fields: {
              text: '',
              tooltip: ''
            }
          };

          $scope.openDisableConsentModal = function () {
            var stopConsentModal = $mdDialog
              .confirm()
              .title('Disable SMS Messages')
              .content(
                'This will prevent you from being able to send SMS text messages to this phone number. Are you sure?'
              )
              .ok('Yes')
              .cancel('Cancel');

            $mdDialog.show(stopConsentModal).then(self._disableSMSConsent);
          };

          self._initialize = function () {
            $scope.personType = $scope.person.assigned_manager_id
              ? 'prospect'
              : 'resident';

            $scope.data.fields = self._getTextAndTooltip();

            $scope.$watch(
              'person.sms_consent',
              function () {
                $scope.data.fields = self._getTextAndTooltip();
              },
              true
            );
          };

          self._getTextAndTooltip = function () {
            if (!$scope.person.sms_consent) {
              return self._consentStatusMappings.pending;
            }

            if ($scope.person.sms_consent.status === 'granted') {
              return self._consentStatusMappings.granted;
            }

            if ($scope.person.sms_consent.status === 'revoked') {
              return self._consentStatusMappings.stopped;
            }

            if ($scope.person.sms_consent.status === 'declined') {
              return self._consentStatusMappings.declined;
            }

            return self._consentStatusMappings.pending;
          };

          self._disableSMSConsent = function () {
            if ($scope.personType === 'prospect') {
              prospectsApi.revokeSMSConsent($scope.person.id);
            } else {
              residentsApi
                .revokeSMSConsent($scope.person.stream_id)
                .success(function () {
                  $scope.person.sms_consent.status = 'declined';
                });
            }
          };

          self._initialize();
        }
      ]
    };
  });
})(window.angular);
