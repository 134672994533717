/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import uniq from 'lodash/uniq';
import forEach from 'lodash/forEach';
('use strict');

/**
 * @ngdoc filter
 * @name knockApp.filter:joinunique
 * @function
 * @description
 * # joinUnique
 * Filter in the knockApp.
 */
angular.module('knockApp').filter('joinUnique', [
  function () {
    return function (input, delimiter) {
      var pets = [];
      forEach(input, function (v, k) {
        if (v) {
          pets.push(k);
        }
      });
      pets = uniq(pets);
      return pets.join(delimiter);
    };
  }
]);
