/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knock-MarketingTools');

  app.directive('marketingToolsResponder', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/marketingTools/marketing-tools-relays.html',
      controller: 'MarketingToolsRelayController',
      scope: {}
    };
  });

  const MarketingToolsRelayController = function (
    $scope,
    $q,
    appDataService,
    userService,
    managerApi,
    communityApi,
    relayApi,
    teamApi,
    companyApi
  ) {
    var self = this;
    var user = userService.getScopedUser();

    $scope.data = {
      communities: [],
      communityRelays: {},
      communityTemplates: {},
      teamMembers: [],
      teamMemberRelays: {}
    };

    self._initialize = function () {
      self._setTeamMembers();

      var initPromises = [
        self._getCommunities(),
        self._getCompanyPreferences()
      ];

      $q.all(initPromises).then(function () {
        self._getCommunityRelays();
        self._getCommunityTemplates();
        self._getTeamMemberRelays();
      });
    };

    self._getCommunities = function () {
      return managerApi.getMyCommunities().then(function (response) {
        $scope.data.communities = map(
          response.data.communities,
          function (community) {
            if (community.social.knock_email) {
              var emailParts = community.social.knock_email.split('@');
              community.social.craigslist_knock_email =
                emailParts[0] + '-cl@' + emailParts[1];
            }

            return community;
          }
        );
      });
    };

    self._getCommunityRelays = function () {
      var communityIds = map($scope.data.communities, 'id');

      return relayApi.getRelayBatch(communityIds).then(function (response) {
        $scope.data.communityRelays = keyBy(
          response.data.relays,
          'primary_key_str'
        );
      });
    };

    self._getCommunityTemplates = function () {
      var communityIds = map($scope.data.communities, 'id');

      return communityApi
        .getCommunityTemplates(communityIds)
        .then(function (response) {
          $scope.data.communityTemplates = keyBy(
            response.data.templates,
            'community_id'
          );
        });
    };

    self._setTeamMembers = function () {
      $scope.data.teamMembers = sortBy(
        appDataService.getTeamMembers(),
        function (member) {
          // Our user goes first
          if (member.Manager.id === user.id) {
            return 'AAAAAAAAAA';
          }

          return member.ManagerInfo.first_name + member.ManagerInfo.last_name;
        }
      );
    };

    self._getTeamMemberRelays = function () {
      return teamApi.getTeamMemberRelays().then(function (response) {
        $scope.data.teamMemberRelays = keyBy(
          map(response.data.relays, function (relay) {
            var emailParts = relay.email_relay.email.split('@');
            relay.email_relay.craigslist_knock_email =
              emailParts[0] + '-cl@' + emailParts[1];

            return relay;
          }),
          'primary_key_int'
        );
      });
    };

    self._getCompanyPreferences = function () {
      return companyApi.getCompanyPreferences().then(function (response) {
        $scope.data.companyPreferences = response.data.preferences;
      });
    };

    self._initialize();
  };

  MarketingToolsRelayController.$inject = [
    '$scope',
    '$q',
    'appDataService',
    'userService',
    'managerApi',
    'communityApi',
    'relayApi',
    'teamApi',
    'companyApi'
  ];

  app.controller(
    'MarketingToolsRelayController',
    MarketingToolsRelayController
  );
})(window.angular);
