/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import angular from 'angular';
import { APP_CONFIG } from '../../../../APP_CONFIG';
const app = angular.module('knockApp');
app.constant('api', APP_CONFIG.API);
app.constant('authApi', APP_CONFIG.AUTH_API);
app.constant('cacheKeys', APP_CONFIG.CACHE_KEYS);
app.constant('pusherKey', APP_CONFIG.PUSHER_KEY);
app.constant('globalAccessPrivileges', APP_CONFIG.GLOBAL_ACCESS_PRIVILEGES);
app.constant('defaultProfileImageUrl', APP_CONFIG.DEFAULT_PROFILE_IMAGE_URL);
app.constant('mobileDownloadLinks', APP_CONFIG.MOBILE_DOWNLOAD_LINKS);
app.constant('environment', APP_CONFIG.ENVIRONMENT);
app.constant('imageBucket', APP_CONFIG.IMAGE_BUCKET);
app.constant('videoBucket', APP_CONFIG.VIDEO_BUCKET);
app.constant('MAINTENANCE_MODE', APP_CONFIG.MAINTENANCE_MODE);
app.constant(
  'integrationSnippetSettings',
  APP_CONFIG.INTEGRATION_SNIPPET_SETTINGS
);
app.constant('ANALYTICS_BASE_URL', APP_CONFIG.ANALYTICS_BASE_URL);
app.constant('ADMIN_BASE_URL', APP_CONFIG.ADMIN_BASE_URL);
app.constant(
  'INBOUND_BROWSER_CALLING_BASE_URL',
  APP_CONFIG.INBOUND_BROWSER_CALLING_BASE_URL
);
app.constant('LOGIN_UI_BASE_URL', APP_CONFIG.LOGIN_UI_BASE_URL);
app.constant('WEBFRONT_BASE_URL', APP_CONFIG.WEBFRONT_BASE_URL);
app.constant('REALPAGE_LOGIN_URL', APP_CONFIG.REALPAGE_LOGIN_URL);
app.constant('AUTH_REDIRECT_URL', APP_CONFIG.AUTH_REDIRECT_URL);
app.constant('AUTH_CLIENT_ID', APP_CONFIG.AUTH_CLIENT_ID);
app.constant('UNIFIED_API_URL', APP_CONFIG.UNIFIED_API_URL);
app.constant('UNIFIED_SCOPE', APP_CONFIG.UNIFIED_SCOPE);

app.constant('CLAW_BASE_URL', APP_CONFIG.CLAW_BASE_URL);
app.constant('firebaseConfig', APP_CONFIG.FIREBASE);
app.constant(
  'LAUNCHDARKLY_CLIENTSIDE_ID',
  APP_CONFIG.LAUNCHDARKLY_CLIENTSIDE_ID
);
app.constant('UIS_TOKEN', APP_CONFIG.UIS.token);
app.constant('UIS_HOST', APP_CONFIG.UIS.host);
app.constant('ANALYTICS_GRAPHQL_API', APP_CONFIG.ANALYTICS_GRAPHQL_API);
app.constant('unifiedLoginLink', APP_CONFIG.REACT_APP_UNIFIED_LOGIN_URL);
app.constant('onsiteHost', APP_CONFIG.REACT_APP_ONSITE_HOST);
