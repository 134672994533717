(function (angular) {
  var app = angular.module('knockApp');

  const _getCookie = function (name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const [key, value] = cookies[i].trim().split('=');
      if (key === name) return value;
    }
  };

  const InternalLoginController = function (
    $routeParams,
    authenticationService
  ) {
    let authToken = $routeParams.token;
    if (!authToken) {
      // if no auth token was passed, try reading an auth token from cookies
      authToken = _getCookie('token');
    }
    authenticationService.loginWithToken(authToken);
  };
  InternalLoginController.$inject = ['$routeParams', 'authenticationService'];
  app.controller('InternalLoginController', InternalLoginController);
})(window.angular);
