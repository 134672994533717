/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import every from 'lodash/every';
import find from 'lodash/find';
import some from 'lodash/some';

(function (angular) {
  'use strict';

  var app = angular.module('knock-PropertyReports');

  app.directive('residentReportTable', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/residents/resident-report-table.html',
      controller: 'ResidentReportTableController'
    };
  });

  const ResidentReportTableController = function (
    $rootScope,
    $scope,
    conversationsService
  ) {
    // Wacky hack to sort units that have letters/numbers and just numbers differently
    $scope.columnOrder = 'desc';

    $scope.isPaginationEnabled =
      $rootScope.featureFlags.PROSPECT_RESIDENT_PAGINATION;

    var hasLettersInUnit = false;

    $scope.$watch('data.residents', function () {
      hasLettersInUnit = some($scope.data.residents, function (resident) {
        return (
          resident.residence.unit && resident.residence.unit[0].match(/[a-z]/i)
        );
      });
    });

    $scope.sortColumn = (column) => {
      if ($scope.isPaginationEnabled) {
        if (column === $scope.column) {
          if ($scope.columnOrder === 'asc') {
            $scope.columnOrder = 'desc';
          } else {
            $scope.columnOrder = 'asc';
          }
        } else {
          $scope.columnOrder = 'asc';
        }

        $rootScope.$broadcast('getSortParams', column, $scope.columnOrder);
      }

      $scope.column = column;
    };

    $scope.sortGetters = {
      property: function (resident) {
        return $scope.getResidentPropertyName(resident);
      },
      email: function (resident) {
        return resident.profile.email || '';
      },
      unit: function (resident) {
        if (hasLettersInUnit) {
          return resident.residence.unit || '';
        }
        var unit = resident.residence.unit || '';

        unit = unit.replace(/[^A-Za-z0-9]/g, '');
        unit = !isNaN(unit) ? parseInt(unit) : unit;

        return unit ? unit : 0;
      },
      building: function (resident) {
        return resident.building || '';
      },
      lease_start: function (resident) {
        return resident.lease_start || new Date('1/1/1970').toISOString();
      },
      lease_end: function (resident) {
        return resident.lease_end || new Date('1/1/1970').toISOString();
      },
      status: function (resident) {
        // Already processed for display:
        return resident.status || '';
      }
    };

    $scope.getResidentsCopy = $scope.tablePagination.getTableDataRange;

    $scope.getResidentPropertyName = function (resident) {
      var residentProperty = find($scope.filters.properties, {
        id: resident.residence.property_id
      });
      return residentProperty ? residentProperty.name : 'N/A';
    };

    $scope.openResidentStreamModal = function (resident) {
      conversationsService.openThreadById(resident.stream_id, true);
    };

    $scope.allResidentsSelectedChanged = function (selectedBatchOption) {
      $scope.tablePagination.handleBatchOptionChange(selectedBatchOption);

      $scope.data.allResidentsSelected = every(
        $scope.data.residentsCopy,
        'isSelected'
      );

      $scope.$apply(() => {
        $rootScope.$emit('allResidentsSelectedChanged');
      });
    };

    $scope.residentSelectedChanged = () => {
      $scope.data.allResidentsSelected = every(
        $scope.data.residentsCopy,
        'isSelected'
      );

      $rootScope.$emit('residentSelectedChanged');
    };
  };

  ResidentReportTableController.$inject = [
    '$rootScope',
    '$scope',
    'conversationsService'
  ];

  app.controller(
    'ResidentReportTableController',
    ResidentReportTableController
  );
})(window.angular);
