import React from 'react';

export const BlankLeasingTabIcon = () => (
  <svg
    width="184"
    height="180"
    viewBox="0 0 184 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.378 7.05351C91.0695 -8.03666 56.7246 1.88225 41.6663 29.208C36.1778 39.1679 34.009 50.0655 34.7914 60.6249C23.1758 64.1962 12.8797 72.109 6.54772 83.5995C-6.40686 107.108 2.1363 136.689 25.6294 149.671L69.5312 173.931C93.0243 186.913 122.571 178.379 135.526 154.871C137.489 151.308 138.959 147.605 139.957 143.842C155.061 147.256 171.203 140.492 179.055 126.244C188.577 108.965 182.297 87.2215 165.029 77.6792L150.027 69.3893C154.636 45.9229 143.916 21.1658 121.87 8.98354L118.378 7.05351Z"
      fill="#EBEEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.0466 94.3107C85.9402 91.3212 90.69 91.1851 93.7499 94.0041C96.5667 97.0636 96.4306 101.811 93.4433 104.704C90.5066 107.647 77.6033 110.144 77.6033 110.144C77.6033 110.144 80.1099 97.2474 83.0466 94.3107Z"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M94.9333 68.5602L107.063 56.4302C107.982 55.5112 109.229 54.9949 110.528 54.9949C111.828 54.9949 113.074 55.5112 113.993 56.4302L117.46 59.8969"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.7333 87.6202L117.46 59.8968C120.335 57.036 124.981 57.036 127.857 59.8968V59.8968C130.714 62.7735 130.714 67.4168 127.857 70.2935L100.13 98.0168L89.7333 87.6202Z"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M107.063 70.2939L117.46 80.6906"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M123.233 95.0003C123.233 108.47 124.65 116.084 117.373 123.36L113.6 127.13C113.364 127.363 113.045 127.494 112.713 127.494H56.25C55.7447 127.493 55.2894 127.189 55.0962 126.722C54.9029 126.255 55.0096 125.718 55.3666 125.36L57.3666 123.36C64.9166 115.81 63.2333 110.98 63.2333 70.7803C63.2333 60.287 71.06 54.667 73.2333 52.4937H88.06"
      stroke="#4A61E8"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
