import React from 'react';

export const PetIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7166 12.5C12.7166 10.7741 11.3174 9.375 9.59155 9.375C7.86566 9.375 6.46655 10.7741 6.46655 12.5C5.08584 12.5 3.96655 13.6193 3.96655 15C3.96655 16.3807 5.08584 17.5 6.46655 17.5C7.01705 17.4871 7.55671 17.3444 8.04155 17.0833C9.01289 16.5783 10.1694 16.5783 11.1407 17.0833C11.6259 17.3443 12.1658 17.4871 12.7166 17.5C14.0973 17.5 15.2166 16.3807 15.2166 15C15.2166 13.6193 14.0973 12.5 12.7166 12.5V12.5Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.71655 11.25C3.75209 11.25 4.59155 10.4105 4.59155 9.375C4.59155 8.33947 3.75209 7.5 2.71655 7.5C1.68102 7.5 0.841553 8.33947 0.841553 9.375C0.841553 10.4105 1.68102 11.25 2.71655 11.25Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.46655 6.25C7.50209 6.25 8.34155 5.41053 8.34155 4.375C8.34155 3.33947 7.50209 2.5 6.46655 2.5C5.43102 2.5 4.59155 3.33947 4.59155 4.375C4.59155 5.41053 5.43102 6.25 6.46655 6.25Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7166 6.25C13.7521 6.25 14.5916 5.41053 14.5916 4.375C14.5916 3.33947 13.7521 2.5 12.7166 2.5C11.681 2.5 10.8416 3.33947 10.8416 4.375C10.8416 5.41053 11.681 6.25 12.7166 6.25Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0916 11.25C18.1271 11.25 18.9666 10.4105 18.9666 9.375C18.9666 8.33947 18.1271 7.5 17.0916 7.5C16.056 7.5 15.2166 8.33947 15.2166 9.375C15.2166 10.4105 16.056 11.25 17.0916 11.25Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
