/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('emailValidatorService', function () {
    var self = this;
    self.EMAIL_REGEX = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    self.EMAIL_MAILER_REGEX = /^.*?mailer.knockrentals.com\s*$/;
    self.EMAIL_MAILER_REGEX2 = /^.*?m.knck.io\s*$/;

    self.validate = function (email) {
      return self.EMAIL_REGEX.test(email);
    };

    self.validateMailerConflict = function (email) {
      return (
        !self.EMAIL_MAILER_REGEX.test(email) &&
        !self.EMAIL_MAILER_REGEX2.test(email)
      );
    };

    return self;
  });

  app.directive('emailValidator', [
    'emailValidatorService',
    function (emailValidatorService) {
      return {
        require: 'ngModel',
        restrict: '',
        link: function (scope, elm, attrs, ctrl) {
          ctrl.$validators.email = function (modelValue) {
            return (
              ctrl.$isEmpty(modelValue) ||
              emailValidatorService.validate(modelValue)
            );
          };

          ctrl.$validators.mailerConflict = function (modelValue) {
            return (
              ctrl.$isEmpty(modelValue) ||
              emailValidatorService.validateMailerConflict(modelValue)
            );
          };
        }
      };
    }
  ]);

  app.directive('noMailerValidator', [
    'emailValidatorService',
    function (emailValidatorService) {
      return {
        require: 'ngModel',
        restrict: '',
        link: function (scope, element, attributes, controller) {
          controller.$validators.mailerConflict = function (modelValue) {
            return (
              controller.$isEmpty(modelValue) ||
              emailValidatorService.validateMailerConflict(modelValue)
            );
          };
        }
      };
    }
  ]);
})(window.angular);
