import React, { FC } from 'react';

interface VoiceAppOnlineBadgeIconProps {
  style: string;
}

export const VoiceAppOnlineBadgeIcon: FC<VoiceAppOnlineBadgeIconProps> = ({
  style
}) => (
  <svg
    className={style}
    width={11}
    height={11}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.58342 0C4.5121 0 3.46484 0.317682 2.57408 0.912873C1.68331 1.50806 0.989044 2.35403 0.57907 3.3438C0.169095 4.33356 0.061827 5.42267 0.27083 6.4734C0.479833 7.52413 0.99572 8.48929 1.75325 9.24683C2.51079 10.0044 3.47595 10.5202 4.52668 10.7293C5.57741 10.9383 6.66652 10.831 7.65628 10.421C8.64605 10.011 9.49202 9.31677 10.0872 8.426C10.6824 7.53524 11.0001 6.48798 11.0001 5.41667C11.0001 3.98008 10.4294 2.60233 9.41358 1.5865C8.39775 0.570683 7.02 0 5.58342 0ZM4.48038 7.99697C4.38403 7.99872 4.28828 7.98134 4.19868 7.94584C4.10909 7.91034 4.02742 7.85742 3.95842 7.79015L2.48114 6.31288C2.40857 6.24526 2.35037 6.16371 2.31 6.0731C2.26962 5.9825 2.24792 5.88469 2.24617 5.78551C2.24442 5.68634 2.26266 5.58782 2.29981 5.49585C2.33696 5.40388 2.39225 5.32033 2.46239 5.25019C2.53253 5.18005 2.61608 5.12475 2.70805 5.08761C2.80003 5.05046 2.89854 5.03221 2.99772 5.03396C3.0969 5.03571 3.1947 5.05742 3.28531 5.09779C3.37592 5.13816 3.45746 5.19637 3.52508 5.26894L4.50993 6.25379L7.6516 3.05303C7.7195 2.98334 7.80067 2.92796 7.89031 2.89015C7.97996 2.85233 8.07627 2.83285 8.17357 2.83285C8.27086 2.83285 8.36717 2.85233 8.45682 2.89015C8.54647 2.92796 8.62764 2.98334 8.69554 3.05303C8.83386 3.19152 8.91155 3.37926 8.91155 3.575C8.91155 3.77074 8.83386 3.95847 8.69554 4.09697L5.00235 7.79015C4.86179 7.92421 4.67462 7.99837 4.48038 7.99697Z"
      fill="#088D61"
    />
  </svg>
);
