/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('verificationApi', [
    'apiBase',
    function (apiBase) {
      return {
        verifySMS: function (phoneNumber) {
          var payload = {
            number_to_verify: phoneNumber // jshint ignore:line
          };

          return apiBase.post('/verify/sms/', payload);
        },
        verifyCall: function (phoneNumber) {
          var payload = {
            number_to_verify: phoneNumber // jshint ignore:line
          };

          return apiBase.post('/verify/call/', payload);
        },
        confirmVerificationCode: function (verificationCode) {
          var payload = {
            verification_code: verificationCode // jshint ignore:line
          };

          return apiBase.post('/verify/confirm/', payload);
        }
      };
    }
  ]);
})();
