/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('photoApi', [
    'apiBase',
    function (apiBase) {
      var self = this;

      self.uploadPhoto = function (key, photo) {
        var photos = [photo];
        return self.uploadPhotos(key, photos);
      };

      self.uploadPhotos = function (key, photos) {
        var photoForm = new FormData();
        photoForm.append('key', key);

        var photoOrder = map(photos, function (photo, index) {
          return { i: index, photo: photo.name };
        });

        photoForm.append('order', JSON.stringify(photoOrder));
        photoForm.append('key', key);

        photos.forEach(function (photo) {
          photoForm.append(photo.name, photo);
        });

        var url = '/upload/photo';
        return apiBase.post(url, photoForm, {
          transformRequest: angular.identity,
          headers: { 'Content-Type': undefined }
        });
      };

      return self;
    }
  ]);
})();
