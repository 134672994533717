/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import capitalize from 'lodash/capitalize';
import some from 'lodash/some';
import includes from 'lodash/includes';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import cloneDeep from 'lodash/cloneDeep';
import transform from 'lodash/transform';
import range from 'lodash/range';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.service('newEventModalService', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function (
        calendar,
        timeRange,
        calendarEvent,
        isAddingBusyTime
      ) {
        return $mdDialog.show({
          controller: 'NewEventModalController',
          templateUrl: '/angular/views/calendar/new-event-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            startTime: timeRange ? timeRange.start : null,
            endTime: timeRange ? timeRange.end : null,
            isAllDay: timeRange ? timeRange.isAllDay : false,
            calendar: calendar,
            calendarEvent: calendarEvent,
            isAddingBusyTime: isAddingBusyTime || false
          }
        });
      };

      return self;
    }
  ]);

  const NewEventModalController = function (
    $scope,
    $mdDialog,
    $moment,
    $routeParams,
    startTime,
    endTime,
    isAllDay,
    calendar,
    calendarEvent,
    isAddingBusyTime,
    prospectAppointmentModalFactory,
    timeService,
    managerCalendarEventsService,
    managerApi,
    choosePropertyDialogFactory,
    primeTimeDialogFactory,
    newProspectService,
    conversationsService,
    appDataService
  ) {
    var self = this;

    $scope.data = {
      teamMembers: [{ name: 'All', id: 'all' }].concat(
        sortBy(appDataService.getTeamMembers(), 'ManagerInfo.first_name').map(
          function (teamMember) {
            return {
              name:
                teamMember.ManagerInfo.first_name +
                ' ' +
                teamMember.ManagerInfo.last_name,
              id: teamMember.Manager.id
            };
          }
        )
      ),
      selectedTeamMemberId: 'all'
    };

    $scope.showEventForm = !isEmpty(calendarEvent) || isAddingBusyTime;
    $scope.isNewEvent = isEmpty(calendarEvent);
    $scope.isFutureEvent = timeService.get(startTime) > timeService.get();

    $scope.recurrenceFrequencies = ['never', 'daily', 'weekly', 'monthly'];
    $scope.recurrenceIntervals = range(1, 10);

    $scope.stopOnEnter = function (event) {
      if (event.keyCode === 13) {
        $scope.closeOpenedEditables();
      }
    };

    $scope.editables = {};
    $scope.editables.startTime = false;
    $scope.editables.endTime = false;
    $scope.editables.title = false;

    $scope.closeOpenedEditables = function () {
      var close = function (editables, isOpen, key) {
        editables[key] = false;
        return editables;
      };
      $scope.editables = transform($scope.editables, close, {});
    };

    $scope.edit = function (event, editable) {
      event.stopPropagation();

      $scope.closeOpenedEditables();
      $scope.editables[editable] = true;
    };

    self.initialize = function () {
      $scope.event = cloneDeep(calendarEvent) || {};
      $scope.event.isNewEvent = isUndefined($scope.event.id);
      $scope.event.title = $scope.event.title || 'Untitled event';
      $scope.event.show_as_busy = isUndefined($scope.event.show_as_busy)
        ? true
        : $scope.event.show_as_busy;
      $scope.event.all_day = isUndefined($scope.event.all_day)
        ? isAllDay
        : $scope.event.all_day;

      var matchesFrequency = function (frequency) {
        return frequency === $scope.event.recurrence_frequency;
      };

      $scope.event.recurrence_frequency =
        find($scope.recurrenceFrequencies, matchesFrequency) || 'never';
      $scope.event.recur_on_monthday = $scope.event.recur_on_monthday || true;

      $scope.event.recurrenceDays = selectedWeekdaysByName(
        $scope.event.recurrence_days_of_week
      );

      self.minStartTime = timeService
        .get($scope.event.start_time || startTime)
        .startOf('day');
      self.minEndTime = timeService.get(self.minStartTime).add(15, 'minutes');

      self.maxEndTime = timeService.get(self.minStartTime).endOf('day');
      self.maxStartTime = timeService
        .get(self.maxEndTime)
        .subtract(15, 'minutes');

      $scope.startTimeOptions = self._generateTimeChoices(
        self.minStartTime,
        self.maxStartTime
      );
      $scope.endTimeOptions = self._generateTimeChoices(
        self.minEndTime,
        self.maxEndTime
      );

      var equalsStartTime = function (time) {
        var timeMoment = $moment(time).format('hh:mma');
        return (
          timeMoment ===
          $moment($scope.event.start_time || startTime).format('hh:mma')
        );
      };

      var equalsEndTime = function (time) {
        var timeMoment = $moment(time).format('hh:mma');
        var safeEndTime = endTime || $moment(startTime).add(15, 'minutes');
        return (
          timeMoment ===
          $moment($scope.event.end_time || safeEndTime).format('hh:mma')
        );
      };

      if ($scope.event.all_day) {
        $scope.event.start_time = $scope.event.start_time || self.minStartTime;
        $scope.event.end_time = $scope.event.end_time || self.maxEndTime;
      } else {
        $scope.event.start_time = find(
          $scope.startTimeOptions,
          equalsStartTime
        );
        $scope.event.end_time = find($scope.endTimeOptions, equalsEndTime);
      }

      $scope.event.date = $moment($scope.event.start_time).toDate();

      $scope.recurrenceEnds = $scope.event.recurrence_end ? 'on' : 'never';

      self.originalRecurrenceFrequency = $scope.event.recurrence_frequency;
      delete $scope.event.source;
    };

    $scope.updateEndTimes = function () {
      var minEndTime = timeService
        .get($scope.event.start_time)
        .add(15, 'minutes');

      $scope.endTimeOptions = self._generateTimeChoices(
        minEndTime,
        self.maxEndTime
      );
      $scope.event.end_time = $moment.max(
        $scope.event.end_time,
        $scope.endTimeOptions[0]
      );
      $scope.isEditingStart = false;
    };

    $scope.dismiss = function () {
      $mdDialog.cancel();
    };

    $scope.openNewAppointmentModal = function () {
      $scope.dismiss();
      prospectAppointmentModalFactory
        .openAddProspectAppointmentModal(null, startTime, endTime)
        .then(function (prospect) {
          if ($routeParams.chatThreadId !== prospect.stream_id) {
            conversationsService.openThreadById(prospect.stream_id, true);
          }
        });
    };

    $scope.openNewVisitModal = function () {
      $scope.dismiss();
      newProspectService.addNewProspect(startTime);
    };

    $scope.openNewPrimeTimeModal = function () {
      choosePropertyDialogFactory
        .openChoosePropertyDialog()
        .then(function (selectedListing) {
          primeTimeDialogFactory
            .openPrimeTimeDialog(selectedListing, startTime, endTime)
            .then(
              function () {
                $mdDialog.hide();
              },
              function () {
                $mdDialog.cancel();
              }
            );
        });
    };

    $scope.trySaveEvent = function () {
      $scope.needsRecurrenceConfirmation =
        !$scope.isNewEvent && !self._recurrenceChanged();
      $scope.toSave = true;
      $scope.toDelete = false;

      if (!$scope.needsRecurrenceConfirmation) {
        $scope.saveEvent();
      }
    };

    $scope.saveEvent = function (updateMethod) {
      $scope.isSaving = true;

      $scope.event.recurrence_days_of_week = reduce(
        $scope.event.recurrenceDays,
        selectedIsoWeekdays,
        []
      );

      var selectedFrequency = $scope.event.recurrence_frequency;
      $scope.event.recurrence_frequency =
        selectedFrequency === 'never' ? undefined : selectedFrequency;
      $scope.event.recurrence_end =
        selectedFrequency === 'never' || $scope.recurrenceEnds === 'never'
          ? undefined
          : $scope.event.recurrence_end;

      $scope.event.date = $moment($scope.event.date).format('YYYY-MM-DD');

      if ($scope.event.isNewEvent) {
        $scope.event.manager_ids =
          $scope.data.selectedTeamMemberId === 'all'
            ? map($scope.data.teamMembers.slice(1), 'id')
            : [$scope.data.selectedTeamMemberId];
      }

      ($scope.isNewEvent
        ? self._createEvent()
        : self._updateEvent(updateMethod)
      )
        .success(function () {
          managerCalendarEventsService.refreshCalendar();
          $scope.dismiss();
        })
        .finally(function () {
          $scope.isSaving = false;
        });
    };

    self._updateEvent = function (updateMethod) {
      $scope.event.update_method = updateMethod;
      return managerApi.updateCalendarEvent(
        $scope.event.databaseId,
        $scope.event
      );
    };

    self._createEvent = function () {
      return managerApi.postCalendarEvent($scope.event);
    };

    self._generateTimeChoices = function (start, end) {
      var timeSlots = [];
      var timeWalker = timeService.get(start);
      var todayEnd = timeService.get(end);

      while (timeWalker.isBefore(todayEnd)) {
        timeSlots.push(timeService.clone(timeWalker));
        timeWalker.add(15, 'minutes');
      }

      return timeSlots;
    };

    $scope.tryDeleteEvent = function () {
      $scope.needsRecurrenceConfirmation =
        !$scope.isNewEvent && !self._recurrenceChanged();
      $scope.toSave = false;
      $scope.toDelete = true;

      if (!$scope.needsRecurrenceConfirmation) {
        $scope.confirmDelete();
      }
    };

    $scope.confirmDelete = function (deleteMethod) {
      $scope.deleteMethod = deleteMethod;
      $scope.isConfirmingDelete = true;
      $scope.needsRecurrenceConfirmation = false;

      var deleteTexts = {
        all: 'all events',
        this: 'this event',
        future: 'future events'
      };

      $scope.deleteText = deleteTexts[deleteMethod];
    };

    $scope.deleteEvent = function () {
      $scope.isDeleting = true;
      var recurrenceDate = timeService
        .get($scope.event.start_time)
        .format('YYYY-MM-DD');

      managerApi
        .deleteCalendarEvent(
          $scope.event.databaseId,
          recurrenceDate,
          $scope.deleteMethod
        )
        .success(function () {
          managerCalendarEventsService.refreshCalendar();
          $scope.dismiss();
        })
        .finally(function () {
          $scope.isDeleting = false;
        });
    };

    self._recurrenceChanged = function () {
      return (
        calendarEvent.recurrence_frequency !==
          $scope.event.recurrence_frequency ||
        calendarEvent.recurrence_interval !==
          $scope.event.recurrence_interval || // jshint ignore:line
        calendarEvent.recurrence_end !== $scope.event.recurrence_end || // jshint ignore:line
        calendarEvent.recurrence_days_of_week !==
          $scope.event.recurrence_days_of_week || // jshint ignore:line
        calendarEvent.recur_on_monthday !== $scope.event.recur_on_monthday
      ); // jshint ignore:line
    };

    // helper functions
    self.isoWeekdaysByName = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7
    };

    function selectedIsoWeekdays(result, isSelected, day) {
      return isSelected ? result.concat(self.isoWeekdaysByName[day]) : result;
    }

    function selectedWeekdaysByName(recurrenceDaysOfWeek) {
      return reduce(
        self.isoWeekdaysByName,
        function (result, isoWeekday, day) {
          result[day] = includes(recurrenceDaysOfWeek, isoWeekday);
          return result;
        },
        {}
      );
    }

    $scope.$watch(
      'event.recurrenceDays',
      function (recurrenceDays) {
        if (
          $scope.event.recurrence_frequency === 'weekly' &&
          !some(recurrenceDays)
        ) {
          var startWeekday = $moment($scope.event.start_time)
            .format('dddd')
            .toLowerCase();
          $scope.event.recurrenceDays[startWeekday] = true;
        }
      },
      true
    );

    $scope.$watch('event.recurrence_frequency', function (frequency) {
      if (frequency === 'weekly' && !some($scope.event.recurrenceDays)) {
        var startWeekday = $moment($scope.event.start_time)
          .format('dddd')
          .toLowerCase();
        $scope.event.recurrenceDays[startWeekday] = true;
      }
    });

    $scope.$watch('recurrenceEnds', function (recurrenceEnds) {
      if (recurrenceEnds === 'on') {
        $scope.event.recurrence_end =
          $scope.event.recurrence_end ||
          timeService.get().add(4, 'weeks').format('M/D/YYYY');
        $scope.event.recurrence_count = undefined;
      }

      if (recurrenceEnds === 'after') {
        $scope.event.recurrence_count = 35;
        $scope.event.recurrence_end = undefined;
      }

      if (recurrenceEnds === 'never') {
        $scope.event.recurrence_count = undefined;
        $scope.event.recurrence_end = undefined;
      }
    });

    self.initialize();
  };

  NewEventModalController.$inject = [
    '$scope',
    '$mdDialog',
    '$moment',
    '$routeParams',
    'startTime',
    'endTime',
    'isAllDay',
    'calendar',
    'calendarEvent',
    'isAddingBusyTime',
    'prospectAppointmentModalFactory',
    'timeService',
    'managerCalendarEventsService',
    'managerApi',
    'choosePropertyDialogFactory',
    'primeTimeDialogFactory',
    'newProspectService',
    'conversationsService',
    'appDataService'
  ];
  app.controller('NewEventModalController', NewEventModalController);

  app.filter('pluralizedFrequencies', function () {
    var pluralizedFrequencies = {
      daily: 'days',
      weekly: 'weeks',
      monthly: 'months'
    };

    return function (frequency) {
      return pluralizedFrequencies[frequency];
    };
  });

  const recurrenceSummary = function ($filter, $moment) {
    // helper functions and maps
    function getOrdinalSuffix(n) {
      // courtesy https://ecommerce.shopify.com/c/ecommerce-design/t/ordinal-number-in-javascript-1st-2nd-3rd-4th-29259
      var suffixes = ['th', 'st', 'nd', 'rd'];
      var mod100 = n % 100;
      return suffixes[(mod100 - 20) % 10] || suffixes[mod100] || suffixes[0];
    }

    var weekNames = ['first', 'second', 'third', 'fourth', 'fifth'];

    return function (event, recurrenceEnds) {
      if (isEmpty(event)) {
        return '';
      }

      var summary = '';

      if (
        isUndefined(event.recurrence_interval) ||
        event.recurrence_interval === 1
      ) {
        summary = summary.concat(capitalize(event.recurrence_frequency));
      } else {
        var pluralized = $filter('pluralizedFrequencies')(
          event.recurrence_frequency
        );
        summary = summary.concat(
          'Every ' + event.recurrence_interval + ' ' + pluralized
        );
      }

      if (event.recurrence_frequency === 'weekly') {
        var selectedDays = function (result, isSelected, day) {
          return isSelected ? result.concat(capitalize(day)) : result;
        };

        var days = reduce(event.recurrenceDays, selectedDays, []);
        summary = summary.concat(' on ' + days.join(', '));
      }

      if (event.recurrence_frequency === 'monthly') {
        if (event.recur_on_monthday === true) {
          var monthDay = $moment(event.start_time).format('D');
          summary = summary.concat(
            ' on the ' + monthDay + getOrdinalSuffix(monthDay)
          );
        }

        if (event.recur_on_monthday === false) {
          var startMoment = $moment(event.start_time);
          var monthWeek = Math.floor(startMoment.format('D') / 7);

          var namedMonthWeek = weekNames[monthWeek];
          var weekday = startMoment.format('dddd');

          summary = summary.concat(' on the ' + namedMonthWeek + ' ' + weekday);
        }
      }

      if (recurrenceEnds === 'after' && event.recurrence_count > 0) {
        summary = summary.concat(', ');
        summary = summary.concat(event.recurrence_count + ' times');
      }

      if (recurrenceEnds === 'on' && !isUndefined(event.recurrence_end)) {
        summary = summary.concat(', until ');
        summary = summary.concat(
          $moment(event.recurrence_end).format('MMM Do, YYYY')
        );
      }

      return summary;
    };
  };

  recurrenceSummary.$inject = ['$filter', '$moment'];
  app.filter('recurrenceSummary', recurrenceSummary);
})(window.angular);
