/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import range from 'lodash/range';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Account');

  app.directive('accountPreferencesHours', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/account/preferences/account-preferences-hours.html',
      controller: 'AccountPreferencesHoursController',
      scope: {
        hours: '='
      }
    };
  });

  app.controller('AccountPreferencesHoursController', [
    '$rootScope',
    '$scope',
    '$q',
    'apiEvents',
    'timeService',
    '$moment',
    'officeHoursModel',
    function (
      $rootScope,
      $scope,
      $q,
      apiEvents,
      timeService,
      $moment,
      officeHoursModel
    ) {
      var self = this;

      if (!$scope.hours) {
        throw 'hours required';
      }

      $scope.data = {
        activeTimezone: null,
        timezones: [],
        isUpdatingTimezone: false
      };

      $scope.onStartChanged = function (day) {
        day.endTimeChoices = filter(day.timeChoices, function (time) {
          return time > day.startTime;
        });

        if (day.endTime <= day.startTime) {
          day.endTime = $moment(day.startTime).add(15, 'minutes').format();
        }
      };

      $scope.timezoneChanged = function () {
        $scope.data.isUpdatingTimezone = true;

        timeService._setTimezone($scope.data.activeTimezone);
        var tzString = timeService.get().format('Z');

        forEach($scope.hours, function (day) {
          day.startTime = self._replaceTimezone(day.startTime, tzString);
          day.endTime = self._replaceTimezone(day.endTime, tzString);

          day.timeChoices = map(day.timeChoices, function (timeChoice) {
            return self._replaceTimezone(timeChoice, tzString);
          });

          day.endTimeChoices = map(day.endTimeChoices, function (timeChoice) {
            return self._replaceTimezone(timeChoice, tzString);
          });
        });

        $scope.data.isUpdatingTimezone = false;
      };

      self._replaceTimezone = function (timeString, newTzString) {
        return timeString.substr(0, timeString.length - 6) + newTzString;
      };

      self._initialize = function () {
        timeService.getActiveTimezone().then(function (activeTimezone) {
          $scope.data.activeTimezone = activeTimezone;
          $scope.data.timezones = timeService.getTimezones();
          $scope.hours = self._mapManagerHours();
        });
      };

      self._mapManagerHours = function () {
        var officeHoursMap = keyBy(
          map(range(1, 8), function (dayOfWeekInt) {
            var day = timeService.now().isoWeekday(dayOfWeekInt).startOf('day');
            var defaultStartTime = timeService
              .clone(day)
              .hour(9)
              .minute(0)
              .second(0);
            var defaultEndTime = timeService
              .clone(day)
              .hour(17)
              .minute(0)
              .second(0);

            var startTime = timeService
              .get(timeService.combineDateAndTime(day, defaultStartTime))
              .format();
            var endTime = timeService
              .get(timeService.combineDateAndTime(day, defaultEndTime))
              .format();

            var timeChoices = self._generateTimeChoices(day);

            return {
              weekday: day.isoWeekday(),
              startTime: startTime,
              endTime: endTime,
              isActive: false,
              timeChoices: timeChoices,
              endTimeChoices: filter(timeChoices, function (time) {
                return time > startTime;
              })
            };
          }),
          'weekday'
        );

        forEach($scope.hours, function (day) {
          var weekday = timeService.get(day.start_time).isoWeekday();
          var date = timeService.get().isoWeekday(weekday);

          var startTime = timeService
            .get(timeService.combineDateAndTime(date, day.start_time))
            .format();
          var endTime = timeService
            .get(timeService.combineDateAndTime(date, day.end_time))
            .format();

          var timeChoices = self._generateTimeChoices(date);

          officeHoursMap[weekday] = {
            weekday: weekday,
            startTime: startTime,
            endTime: endTime,
            isActive: true,
            timeChoices: timeChoices,
            endTimeChoices: filter(timeChoices, function (time) {
              return time > startTime;
            })
          };
        });

        return sortBy(values(officeHoursMap), function (day) {
          return timeService.get(day.startTime).isoWeekday();
        });
      };

      self._generateTimeChoices = function (day) {
        var timeSlots = [];
        var timeWalker = timeService.get(day).startOf('day').hour(7);
        var todayEnd = timeService.get(day).startOf('day').hour(22);

        while (timeWalker.isBefore(todayEnd)) {
          timeSlots.push(timeService.clone(timeWalker).format());
          timeWalker.add(15, 'minutes');
        }

        return timeSlots;
      };

      self._initialize();
    }
  ]);
})(window.angular);
