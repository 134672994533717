import React, { FC } from 'react';

interface VoiceAppOnLoadingBadgeIconProps {
  style: string;
}

export const VoiceAppOnLoadingBadgeIcon: FC<
  VoiceAppOnLoadingBadgeIconProps
> = ({ style }) => (
  <svg
    className={style}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2618_207)">
      <path
        d="M14.3084 13.4667C13.9104 13.0886 13.3824 12.8778 12.8334 12.8778C12.2845 12.8778 11.7564 13.0886 11.3584 13.4667L11.0418 13.7917C9.29487 12.3281 7.6803 10.7136 6.21675 8.96668L6.53342 8.64168C6.72718 8.44887 6.88093 8.21968 6.98585 7.96726C7.09076 7.71485 7.14477 7.4442 7.14477 7.17085C7.14477 6.8975 7.09076 6.62685 6.98585 6.37444C6.88093 6.12202 6.72718 5.89283 6.53342 5.70002L4.76676 3.93335C4.57509 3.73757 4.34626 3.58202 4.0937 3.47583C3.84113 3.36964 3.5699 3.31494 3.29592 3.31494C3.02194 3.31494 2.75071 3.36964 2.49815 3.47583C2.24558 3.58202 2.01676 3.73757 1.82509 3.93335L0.833422 4.90002C0.360402 5.37779 0.0658642 6.00356 -0.000884311 6.67257C-0.0676329 7.34157 0.0974594 8.0132 0.466755 8.57502C3.35841 12.9174 7.0866 16.64 11.4334 19.525C11.9964 19.8881 12.6661 20.0488 13.3325 19.9806C13.9989 19.9125 14.6223 19.6195 15.1001 19.15L16.0751 18.1833C16.4644 17.7928 16.683 17.2639 16.683 16.7125C16.683 16.1611 16.4644 15.6322 16.0751 15.2417L14.3084 13.4667Z"
        fill="#5C5C5C"
      />
      <path
        d="M10.0083 4.71667C10.0083 5.0482 10.14 5.36614 10.3744 5.60056C10.6088 5.83498 10.9268 5.96668 11.2583 5.96668C11.5898 5.96668 11.9078 5.83498 12.1422 5.60056C12.3766 5.36614 12.5083 5.0482 12.5083 4.71667C12.5083 4.38515 12.3766 4.06721 12.1422 3.83279C11.9078 3.59837 11.5898 3.46667 11.2583 3.46667C10.9268 3.46667 10.6088 3.59837 10.3744 3.83279C10.14 4.06721 10.0083 4.38515 10.0083 4.71667Z"
        fill="#5C5C5C"
      />
      <path
        d="M13.3416 4.71667C13.3416 5.0482 13.4732 5.36614 13.7077 5.60056C13.9421 5.83498 14.26 5.96668 14.5916 5.96668C14.9231 5.96668 15.241 5.83498 15.4754 5.60056C15.7099 5.36614 15.8416 5.0482 15.8416 4.71667C15.8416 4.38515 15.7099 4.06721 15.4754 3.83279C15.241 3.59837 14.9231 3.46667 14.5916 3.46667C14.26 3.46667 13.9421 3.59837 13.7077 3.83279C13.4732 4.06721 13.3416 4.38515 13.3416 4.71667Z"
        fill="#5C5C5C"
      />
      <path
        d="M16.675 4.71667C16.675 5.0482 16.8067 5.36614 17.0412 5.60056C17.2756 5.83498 17.5935 5.96668 17.925 5.96668C18.2566 5.96668 18.5745 5.83498 18.8089 5.60056C19.0434 5.36614 19.175 5.0482 19.175 4.71667C19.175 4.38515 19.0434 4.06721 18.8089 3.83279C18.5745 3.59837 18.2566 3.46667 17.925 3.46667C17.5935 3.46667 17.2756 3.59837 17.0412 3.83279C16.8067 4.06721 16.675 4.38515 16.675 4.71667Z"
        fill="#5C5C5C"
      />
    </g>
    <defs>
      <clipPath id="clip0_2618_207">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
