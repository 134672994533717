/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('upgradeModalService', [
    '$modal',
    function ($modal) {
      var UserModalService = {};

      UserModalService.openUpgradeToTeamModal = function () {
        var options = {
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl: '/angular/views/user/upgrade-to-team-modal.html',
          controller: 'upgradeModalController'
        };

        return $modal.open(options);
      };

      UserModalService.openUpgradeToProModal = function () {
        var options = {
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl: '/angular/views/user/upgrade-to-pro-modal.html',
          controller: 'upgradeModalController'
        };

        return $modal.open(options);
      };

      return UserModalService;
    }
  ]);
})();
