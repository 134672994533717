/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
(function (angular) {
  'use strict';

  var app = angular.module('knock-PropertyReports');

  app.directive('prospectReportTable', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/propertyReports/prospect-report-table.html',
      controller: 'ProspectReportTableController'
    };
  });

  const ProspectReportTableController = function ($scope, $filter, $rootScope) {
    $scope.columnName = '';
    $scope.columnOrder = '';

    $scope.sort = function (columnName) {
      if (columnName === $scope.columnName) {
        if ($scope.columnOrder === 'asc') {
          $scope.columnOrder = 'desc';
        } else {
          $scope.columnOrder = 'asc';
        }
      } else {
        $scope.columnOrder = 'asc';
      }

      $scope.columnName = columnName;
      $rootScope.$broadcast('getSortParams', columnName, $scope.columnOrder);
    };

    $scope.getColumnClass = function (columnName) {
      let className = 'pointer ';

      if (columnName === $scope.columnName) {
        if ($scope.columnOrder === 'asc') {
          className += 'sort-ascending';
        } else {
          className += 'sort-descending';
        }
      }

      return className.trim();
    };

    $scope.sortGetters = {
      targetMoveDate: function (prospect) {
        return (
          prospect.profile.target_move_date ||
          new Date('1/1/1970').toISOString()
        );
      },
      owner: function (prospect) {
        return prospect.manager ? prospect.manager.label : '';
      },
      isWaitlist: function (prospect) {
        return !!prospect.is_waitlist;
      },
      nextReminderTime: function (prospect) {
        return (
          prospect.next_reminder_time || new Date('1/1/1970').toISOString()
        );
      },
      beds: function (prospect) {
        if (
          !prospect.preferences ||
          !prospect.preferences.bedrooms ||
          prospect.preferences.bedrooms.length === 0
        ) {
          return '999999';
        }

        return sortBy(
          map(prospect.preferences.bedrooms, function (beds) {
            return beds === 'studio' ? '0' : parseInt(beds[0]);
          }),
          function (beds) {
            return parseInt(beds);
          }
        ).join('');
      },
      lastContactedTime: function (prospect) {
        return (
          prospect.last_contacted_time || new Date('1/1/1970').toISOString()
        );
      },
      createdDate: function (prospect) {
        return prospect.created_time || new Date('1/1/1970').toISOString();
      },
      prospectResponseTimeSortable: function (prospect) {
        return $filter('prospectResponseTimeSortable')(
          prospect.first_contact_response_time,
          prospect
        );
      }
    };
  };

  ProspectReportTableController.$inject = ['$scope', '$filter', '$rootScope'];

  app.controller(
    'ProspectReportTableController',
    ProspectReportTableController
  );

  app.filter('bedroomsList', function () {
    return function (bedroomsArray, long) {
      if (!bedroomsArray || bedroomsArray.length === 0) {
        return '';
      }

      return map(bedroomsArray, function (bedrooms) {
        if (bedrooms === 'studio') {
          if (long) {
            return 'Studio';
          }

          return 'S';
        }

        if (bedrooms === '1bd') {
          return '1';
        }

        if (bedrooms === '2bd') {
          return '2';
        }

        if (bedrooms === '3bd') {
          return '3+';
        }

        return '';
      }).join(', ');
    };
  });
})(window.angular);
