/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('appointmentRequirementsModalService', [
    '$modal',
    function ($modal) {
      var appointmentRequirementsModalService = {};

      appointmentRequirementsModalService.openModal = function (
        listingId,
        requirements
      ) {
        var defaultOptions = {
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl:
            '/angular/views/listing/listingDetails/appointment-requirements-modal.html',
          controller: 'AppointmentRequirementsModalController',
          size: 'sm',
          resolve: {
            listingId: function () {
              return listingId;
            },
            requirements: function () {
              return requirements;
            }
          }
        };

        return $modal.open(defaultOptions);
      };

      return appointmentRequirementsModalService;
    }
  ]);
})();
