import React, { FC, useState } from 'react';
import { PopupSnackbarType } from '../../hooks/usePopupSnackbar';
import PopupSnackbar from '../PopupSnackbar';
import {
  NearbyCommunity,
  ReferProspectPayload
} from '../../services/nearbyCommunitiesService';
import { Dialog, makeStyles, Paper } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';

import DateFnsUtils from '@date-io/date-fns';

import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Button,
  Text,
  InputText,
  InputCheckbox
} from '@knockrentals/knock-shared-web';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

export interface ReferralDialogProps {
  isOpen: boolean;
  isReferring: boolean;
  sourceProspectId: number;
  nearbyCommunity?: NearbyCommunity;
  cancel: Function;
  submit: (payload: ReferProspectPayload) => any;
  popupSnackbar: PopupSnackbarType;
  onReferral: () => void;
}
const ReferralDialog: FC<ReferralDialogProps> = (props) => {
  const { isOpen, nearbyCommunity, popupSnackbar } = props;

  return (
    <div>
      <Dialog open={isOpen}>
        {nearbyCommunity && (
          <ReferralContent {...props} nearbyCommunity={nearbyCommunity} />
        )}
      </Dialog>
      <PopupSnackbar {...popupSnackbar} />
    </div>
  );
};

const useNoteStyles = makeStyles({
  root: {
    marginBottom: 0
  }
});

const useDatePickerStyles = makeStyles((_) => ({
  paper: {
    marginLeft: '36px',
    display: 'flex',
    alignItems: 'center'
  },
  picker: {
    margin: '0'
  },
  icon: {
    marginRight: '5px'
  }
}));

type ContentProps = ReferralDialogProps & { nearbyCommunity: NearbyCommunity };
export const ReferralContent: FC<ContentProps> = (props) => {
  const { isReferring, nearbyCommunity, cancel, submit, popupSnackbar } = props;

  const datePickerStyles = useDatePickerStyles();
  const noteStyles = useNoteStyles();

  const [note, setNote] = useState('');
  const [tourBooking, setTourBooking] = useState(false);
  const [followUp, setFollowUp] = useState(false);
  const [followUpDate, setFollowUpDate] = useState<Date>(new Date());

  const onSubmit = () => {
    // validation
    if (note.length === 0) {
      popupSnackbar.setMessage('Note must contain text', 'warning');
      return;
    }

    if (followUp && !followUpDate) {
      popupSnackbar.setMessage('Must set a follow up date.', 'warning');
      return;
    }

    submit({
      note,
      followUpDate:
        followUp && followUpDate ? followUpDate.toISOString() : undefined,
      isScheduling: tourBooking
    });
  };

  return (
    <Card>
      <CardHeader title={`Refer to ${nearbyCommunity.property.name}`} />
      <CardContent>
        <div>
          <div>
            <Text variant="body1" color="textSecondary" display="inline">
              Referral Note
            </Text>
            <Text color="error" display="inline">
              {' '}
              *
            </Text>
          </div>
          <InputText
            className={noteStyles.root}
            id="referral-notes"
            name="note"
            fullWidth
            rows="6"
            rowsMax="6"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            multiline
          />
          <Text variant="caption" color="textSecondary">
            This text will be included as a Knock note on the new guestcard.
          </Text>
        </div>
        <br style={{ height: '25px' }} />
        <div>
          <Text variant="body1" color="textSecondary">
            Options
          </Text>
          <InputCheckbox
            id="set-tour-booking-radio"
            color="primary"
            onChange={(e) => setTourBooking(e.target.checked)}
            checked={tourBooking}
            label="Continue to Tour booking after referral"
          />
          <InputCheckbox
            id="schedule-follow-up-radio"
            color="primary"
            onChange={(e) => setFollowUp(e.target.checked)}
            checked={followUp}
            label="Schedule a follow up"
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Paper elevation={0} className={datePickerStyles.paper}>
              <CalendarToday
                className={datePickerStyles.icon}
                color={followUp ? 'secondary' : 'disabled'}
              />
              <DatePicker
                className={datePickerStyles.picker}
                id="follow-up-date"
                disabled={!followUp}
                value={followUpDate}
                disablePast
                onChange={(date) => date && setFollowUpDate(date)}
              />
            </Paper>
          </MuiPickersUtilsProvider>
        </div>
      </CardContent>
      <CardActions>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            id="referral-cancel"
            variant="text"
            color="secondary"
            onClick={() => cancel()}
          >
            Cancel
          </Button>
          <Button
            id="referral-submit"
            color="primary"
            onClick={onSubmit}
            disabled={isReferring || note.length === 0}
          >
            Submit
          </Button>
        </div>
      </CardActions>
    </Card>
  );
};

export default ReferralDialog;
