import React, { useState } from 'react';
import { Box, makeStyles, NamedColors } from '@knockrentals/knock-shared-web';
import { Button, Drawer, Typography, IconButton } from '@material-ui/core';

const useStyles = makeStyles({
  drawerButton: {
    color: NamedColors.slate[800],
    height: '32px',
    alignSelf: 'center',
    marginTop: '5px',
    '@media (max-width: 700px)': {
      marginTop: '-20px',
      marginBottom: '10px'
    },
    '@media (min-width: 720px)': {
      marginLeft: 'auto'
    }
  },
  flexBox: {
    display: 'flex',
    width: '298px'
  },
  drawerHeader: {
    width: '222px',
    marginTop: '15px'
  },
  drawerImg: {
    height: '18px',
    width: '18px'
  },
  parentDiv: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px'
  },
  statsContainer: {
    background: NamedColors.slate[50],
    height: '100%',
    padding: '24px'
  },
  muiDrawerPaper: {
    width: '298px'
  }
});
const StatsToursDrawer = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button
        data-testid="stats_and_tours_button"
        onClick={handleDrawer}
        className={classes.drawerButton}
        startIcon={
          <img
            alt="drawer icon"
            src="/images/dashboard/drawerIcon.svg"
            className={classes.drawerImg}
          />
        }
      >
        Stats and Tours
      </Button>
      <Drawer
        data-testid="drawer"
        classes={{
          paper: classes.muiDrawerPaper
        }}
        anchor={'right'}
        open={open}
        onClose={handleDrawer}
      >
        <Box className={classes.parentDiv}>
          <Box className={classes.flexBox}>
            <Typography variant="h6" className={classes.drawerHeader}>
              Stats and Tours
            </Typography>
            <IconButton data-testid="closeBtn" onClick={handleDrawer}>
              <img alt="close icon" src="/images/dashboard/close.svg" />
            </IconButton>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
export default StatsToursDrawer;
