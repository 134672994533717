import React from 'react';

export const CloudDownloadIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 12.875V20.375"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.125 17.25L11 20.375L7.875 17.25"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7501 14.125H16.3126C18.0618 14.128 19.6164 13.011 20.1718 11.3524C20.7272 9.69377 20.1585 7.86586 18.7603 6.81498C17.362 5.76409 15.4481 5.72617 14.0093 6.72086C13.4152 3.55769 10.516 1.36287 7.31033 1.64938C4.10463 1.93588 1.64064 4.61002 1.61687 7.82841C1.59311 11.0468 4.01734 13.757 7.21846 14.0909"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
