/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import range from 'lodash/range';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('starRating', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/reviews/star-rating.html',
      scope: {
        max: '=',
        rated: '=',
        locked: '=?',
        size: '=?'
      },
      controller: [
        '$scope',
        function ($scope) {
          $scope.hoveredStarIndex = -1;
          $scope.rating = 0;

          $scope.stars = range(0, $scope.max);

          $scope.starMouseOver = function (star, $index) {
            if (!$scope.locked) {
              $scope.hoveredStarIndex = $index;
            }
          };

          $scope.starsMouseLeave = function () {
            if (!$scope.locked) {
              $scope.hoveredStarIndex = -1;
            }
          };

          $scope.starClicked = function (star, $index) {
            if (!$scope.locked) {
              $scope.rated = $index + 1;
            }
          };
        }
      ]
    };
  });
})();
