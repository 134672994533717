/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isFunction from 'lodash/isFunction';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('ConfirmationModalController', [
    '$scope',
    '$modalInstance',
    'options',
    function ($scope, $modalInstance, options) {
      $scope.title = options.title;
      $scope.message = options.message;
      $scope.confirmText = options.confirmText;
      $scope.cancelText = options.cancelText;
      $scope.confirmClass = options.confirmClass;
      $scope.cancelClass = options.cancelClass;
      $scope.alertClass = options.alertClass;

      $scope.confirmHandler = function () {
        options.confirmHandler();
        $modalInstance.close('confirmed');
      };

      $scope.cancelHandler = function () {
        if (isFunction(options.cancelHandler)) {
          options.cancelHandler();
        }
        //$modalInstance.dismiss('canceled');
        $modalInstance.close('confirmed');
      };
    }
  ]);
})();
