/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-PropertyReports');

  app.directive('prospectReportKanban', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/propertyReports/prospect-report-kanban.html',
      controller: 'ProspectReportKanbanController'
    };
  });

  const ProspectReportKanbanController = function ($scope) {
    var self = this;
  };

  ProspectReportKanbanController.$inject = ['$scope'];

  app.controller(
    'ProspectReportKanbanController',
    ProspectReportKanbanController
  );
})(window.angular);
