interface ICalculateItemsToDisplay<T> {
  toDisplay: T[];
  toHide: number;
}

export const calculateItemsToDisplay = <T>(
  items: T[],
  containerId: string,
  gap: number = 50
): ICalculateItemsToDisplay<T> | null => {
  const container = window.document.getElementById(containerId);
  if (container) {
    const containerWidth =
      (container?.getBoundingClientRect().width || 0) - gap;

    const elements = container?.childNodes || [];
    const toDisplay: T[] = [];

    let toHide = 0;
    let totalWidth = 0;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLDivElement;
      totalWidth += element.getBoundingClientRect().width;

      if (totalWidth > containerWidth) {
        toHide = items.length - i;
        break;
      } else {
        toDisplay.push(items[i]);
      }
    }
    return { toDisplay, toHide };
  }

  return null;
};

export const sortTextBySize =
  (order: 'asc' | 'desc') => (A: string, B: string) => {
    if (A.length === B.length) {
      return 0;
    } else if (A.length > B.length) {
      return order === 'asc' ? 1 : -1;
    }
    return order === 'asc' ? -1 : 1;
  };
