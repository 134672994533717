import React, { useMemo, useState, useCallback, useEffect } from 'react';
import {
  MenuItem,
  Checkbox,
  Button,
  Badge,
  Menu,
  makeStyles
} from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

import { Box, NamedColors, Text } from '@knockrentals/knock-shared-web';
import { useCommonStyles } from '../../commonStyles/commonStyles';

const useStyles = makeStyles({
  listPadding: {
    padding: '8px 19px 8px 10px !important'
  },

  menuStyle: {
    border: '1px solid #E2E3E9',
    marginTop: '4px',
    minWidth: '190px'
  },

  selectChip: {
    height: '32px',
    padding: '4px',
    backgroundColor: '#EEEEF2',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '16px',
    border: '1px solid #EEEEF2',
    minWidth: '72px',

    '&:hover': {
      backgroundColor: NamedColors.denim[100]
    },

    '&:disabled': {
      color: '#999999',
      backgroundColor: 'inherit',
      border: 'none',

      '& > .MuiButton-label > svg': {
        color: '#999999'
      }
    }
  },

  selectChipOutlined: {
    backgroundColor: '#EBEEFE',
    borderColor: '#697FF7'
  },

  badgeIcon: {
    marginLeft: '6px',
    '& > span': {
      backgroundColor: '#4A61E8',
      fontSize: '1rem',
      fontWeight: 600,
      position: 'relative',
      transform: 'initial'
    }
  },

  filterName: {
    fontSize: '13px',
    marginLeft: '6px'
  },

  checkbox: {
    '&.Mui-checked': {
      color: '#697FF7'
    }
  },

  filterText: {
    fontFamily: 'Open Sans',
    fontSize: '1.3333rem',
    fontWeight: 400,
    letterSpacing: '0.04166rem',
    marginRight: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  resetButtonActive: {
    color: '#4A61E8'
  },

  bottomBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '68px',
    boxShadow: '0 0 5px -2px #888',
    padding: '0 16px'
  },

  optionsContainer: {
    maxHeight: 390,
    maxWidth: 280,
    overflow: 'auto'
  },

  keyboardArrow: {
    color: '#5C5C5C',
    margin: '6px 3px 6px 3px'
  }
});

interface ChipSelectProps<T> {
  defaultFilter: T[];
  filterState: T[];
  chipLabel: string;
  optionLabelSelector: (nodes: T[], useShortLabel?: boolean) => string;
  onApply: (filter: T[], manualSelection?: boolean) => void;
  manualSelection?: boolean;
  isDisabled?: boolean;
  hideSelectAll?: boolean;
  hideBadge?: boolean;
  useShortLabel?: boolean;
  resetButtonLabel?: string;
  className?: string;
}

const ChipSelect = <T extends object | string>({
  defaultFilter,
  filterState,
  chipLabel,
  optionLabelSelector,
  onApply,
  manualSelection = false,
  isDisabled = false,
  hideSelectAll = false,
  hideBadge = false,
  useShortLabel = false,
  resetButtonLabel,
  className
}: ChipSelectProps<T>) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [appliedFilters, setAppliedFilters] = useState<T[]>([]);
  const [manualSelectionMode, setManualSelectionMode] =
    useState(manualSelection);

  useEffect(() => {
    setAppliedFilters([...filterState]);
  }, [filterState]);

  useEffect(() => {
    setManualSelectionMode(manualSelection);
  }, [manualSelection]);

  const isFilterSelected = (filter: T) => {
    // String values are checked using a regular search of the list. For objects, we compare the stringified versions since in some cases
    // the object could have been re-created by the consumer, and so a straight reference comparison would fail.
    if (typeof filter === 'string') {
      return appliedFilters.includes(filter);
    } else {
      return !!appliedFilters.find(
        (item: T) => JSON.stringify(filter) === JSON.stringify(item)
      );
    }
  };

  const handleFilterOptionClick = useCallback(
    (newFilter: T) => {
      if (isFilterSelected(newFilter)) {
        if (appliedFilters.length === 1) {
          handleClearFilters();
        } else {
          setAppliedFilters(
            appliedFilters.filter((item) => {
              if (typeof item === 'string') {
                return item !== newFilter;
              } else {
                return JSON.stringify(item) !== JSON.stringify(newFilter);
              }
            })
          );

          setManualSelectionMode(true);
        }
      } else {
        setAppliedFilters([...appliedFilters, newFilter]);
        setManualSelectionMode(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appliedFilters]
  );

  const appliedFilterCount = useMemo(() => {
    if (appliedFilters.length > 1) {
      return `+${appliedFilters.length - 1}`;
    }
  }, [appliedFilters]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: Object, reason: string) => {
    setAnchorEl(null);

    // If we aren't saving filter selections, revert to the original state
    if (reason !== 'apply') {
      setAppliedFilters([...filterState]);
      setManualSelectionMode(manualSelection);
    }
  };

  const handleClearFilters = () => {
    setAppliedFilters([]);
    setManualSelectionMode(false);
  };

  const handleApplyFilters = () => {
    handleClose({}, 'apply');
    onApply(appliedFilters, manualSelectionMode);
  };

  const getFinalChipLabel = () => {
    let finalChipLabel = chipLabel;

    if (appliedFilters.length > 0) {
      if (chipLabel) {
        if (hideBadge) {
          finalChipLabel = `${chipLabel}: ${optionLabelSelector(
            appliedFilters,
            useShortLabel
          )}`;
        } else {
          finalChipLabel = `${chipLabel}: ${optionLabelSelector(
            [appliedFilters[0]],
            useShortLabel
          )}`;
        }
      } else {
        finalChipLabel = `${optionLabelSelector([appliedFilters[0]])}`;
      }
    }

    return finalChipLabel;
  };

  const finalChipLabel = getFinalChipLabel();

  return (
    <Box
      className={className ? className : undefined}
      data-testid={`${finalChipLabel.toLowerCase()}-chip`}
    >
      <Button
        data-testid={`${finalChipLabel.toLocaleLowerCase()}-chip-button`}
        disabled={isDisabled}
        variant={anchorEl ? 'outlined' : 'text'}
        onClick={handleClick}
        classes={{
          root: classes.selectChip,
          outlined: classes.selectChipOutlined
        }}
      >
        <Text variant="body2" className={classes.filterName}>
          {finalChipLabel}
        </Text>

        {appliedFilters.length > 1 && !hideBadge ? (
          <Badge
            className={classes.badgeIcon}
            badgeContent={appliedFilterCount}
            color="primary"
          />
        ) : null}

        {anchorEl ? (
          <KeyboardArrowUp className={classes.keyboardArrow} />
        ) : (
          <KeyboardArrowDown className={classes.keyboardArrow} />
        )}
      </Button>

      <Menu
        classes={{ paper: classes.menuStyle }}
        elevation={0}
        MenuListProps={{ role: 'listbox', disablePadding: true }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className={classes.optionsContainer}>
          {!hideSelectAll && (
            <MenuItem
              key="All"
              classes={{ root: classes.listPadding }}
              onClick={handleClearFilters}
            >
              <Checkbox
                className={classes.checkbox}
                size="medium"
                checked={!manualSelectionMode && appliedFilters.length === 0}
              />

              <Text variant="body1" className={classes.filterText}>
                All
              </Text>
            </MenuItem>
          )}

          {defaultFilter.map((filter, index) => (
            <MenuItem
              key={optionLabelSelector([filter]) + index}
              classes={{ root: classes.listPadding }}
              onClick={() => handleFilterOptionClick(filter)}
            >
              <Checkbox
                className={classes.checkbox}
                data-testid="chip-select-menu-checkbox"
                size="medium"
                checked={isFilterSelected(filter)}
              />

              <Text variant="body1" className={classes.filterText}>
                {optionLabelSelector([filter])}
              </Text>
            </MenuItem>
          ))}
        </div>

        <div className={classes.bottomBox}>
          <Button variant="text" color="primary" onClick={handleClearFilters}>
            {resetButtonLabel ? resetButtonLabel : 'Reset'}
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={commonClasses.primaryButton}
            onClick={handleApplyFilters}
          >
            Apply
          </Button>
        </div>
      </Menu>
    </Box>
  );
};

export default ChipSelect;
