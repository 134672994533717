import React from 'react';

export const AddCircleIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 6.625V13.375"
      stroke="#697FF7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.625 10H13.375"
      stroke="#697FF7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18.4375C14.6599 18.4375 18.4375 14.6599 18.4375 10C18.4375 5.3401 14.6599 1.5625 10 1.5625C5.3401 1.5625 1.5625 5.3401 1.5625 10C1.5625 14.6599 5.3401 18.4375 10 18.4375Z"
      stroke="#697FF7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
