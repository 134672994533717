import {
  formatDateString,
  getDurationFromNow
} from '../../../LegacyAngularApp/legacy-angular-app/utilities/timeUtilities';

export interface Resident {
  id: number;
  addedBy: number;
  todoStatus: {
    color: string;
    explanation: string;
  };
  streamId: string;
  building: string;
  profile: {
    firstName: string;
    lastName: string;
    fullName: string;
    formattedPhoneNumber: string;
    email: string;
    phone: {
      nationalFormat: string;
      canReceiveCall: boolean;
      canReceiveSMS: boolean;
    } | null;
  };
  managerInfo: {
    firstName: string;
    lastName: string;
  };
  residence: {
    unit: string;
    propertyId: number;
  };
  leaseStart: string;
  leaseEnd: string;
  smsConsent?: {
    status: string;
  };
  status: string;
  source: string;
  lastContactedTime: Date;
  lastContactedTimeDescription: string;
  isDeleted: boolean;
  isPmsResident: boolean;
}

export interface AddResidentData {
  manager_id: number;
  lease_start?: string;
  lease_end?: string;
  property_id: number;
  profile: {
    first_name: string;
    last_name: string;
    email?: string;
    phone?: {
      country_code: string;
      national_format: string;
      phone_number: string;
    };
  };
  unit: string;
}

export interface AddResidentResponseData {
  resident: {
    id: number;
    stream_id: string;
  };
}

export interface PageData {
  count: number;
}

export interface ResidentData {
  residents: Resident[];
  pageData: PageData;
}

export interface Attachment {
  filename: string;
  mimetype: string;
  size: string;
  delete_token: string;
  type: string;
  url: string;
}

export const mapResident = (item: any): Resident => {
  return {
    id: item.id,
    addedBy: item.added_by,
    todoStatus: item.todo_status,
    streamId: item.stream_id,
    building: item.building,
    profile: {
      firstName: item.profile.first_name,
      lastName: item.profile.last_name,
      fullName: `${item.profile.first_name} ${item.profile.last_name}`.trim(),
      formattedPhoneNumber: item.profile.formatted_phone_number,
      email: item.profile.email,
      phone: {
        nationalFormat: item.profile?.phone?.national_format,
        canReceiveCall: item.profile?.phone?.can_receive_call,
        canReceiveSMS: item.profile?.phone?.can_receive_sms
      }
    },
    managerInfo: {
      firstName: item.manager_info.first_name,
      lastName: item.manager_info.last_name
    },
    residence: {
      unit: item.residence.unit,
      propertyId: item.residence.property.id
    },
    leaseStart: item.lease_start ? formatDateString(item.lease_start) : 'n/a',
    leaseEnd: item.lease_end ? formatDateString(item.lease_end) : 'n/a',
    smsConsent: item.sms_consent,
    status: item.status,
    source: item.source,
    lastContactedTime: item.last_contacted_time,
    lastContactedTimeDescription: getDurationFromNow(
      item.last_contacted_time,
      true,
      true,
      true
    ),
    isDeleted: item.is_deleted,
    isPmsResident: false // TODO
  };
};

export interface Emoji {
  emoji: string;
  names: string[];
  activeSkinTone: string;
}
