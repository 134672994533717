/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.filter('timeOfDay', [
    '$moment',
    function ($moment) {
      return function (moment, emoji) {
        var startOfMorning = $moment().startOf('day').hour(0);
        var endOfMorning = $moment().startOf('day').hour(12);
        var isMorning = moment.isBetween(
          startOfMorning,
          endOfMorning,
          'minutes',
          '[)'
        );

        if (isMorning) {
          return emoji ? '&#127749;' : 'Morning';
        }

        var startOfAfternoon = $moment().startOf('day').hour(12);
        var endOfAfternoon = $moment().startOf('day').hour(17);
        var isAfternoon = moment.isBetween(
          startOfAfternoon,
          endOfAfternoon,
          'minutes',
          '[)'
        );

        if (isAfternoon) {
          return emoji ? '&#127774;' : 'Afternoon';
        }

        var startOfEvening = $moment().startOf('day').hour(17);
        var endOfEvening = $moment().startOf('day').hour(24);
        var isEvening = moment.isBetween(
          startOfEvening,
          endOfEvening,
          'minutes',
          '[)'
        );

        if (isEvening) {
          return emoji ? '&#127769;' : 'Evening';
        }

        return 'Day';
      };
    }
  ]);
})(window.angular);
