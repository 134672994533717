import React, { FC, useState } from 'react';
import { PageLoader, Alert, Text } from '@knockrentals/knock-shared-web';
import { Paper, Select, MenuItem, FormControl } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
  ContentStyles,
  AppointmentScheduleDialogProps
} from './AppointmentScheduleDialog';

export const extractAvailableTimes = (
  availableTimes: string[]
): {
  availableTimesMap: Record<string, Date[]>;
  minDate: Date;
  maxDate: Date;
} => {
  let availableTimesMap: Record<string, Date[]> = {};
  let minDate: Date | null = null;
  let maxDate: Date | null = null;

  if (availableTimes.length === 0) {
    return {
      availableTimesMap,
      minDate: minDate || new Date(),
      maxDate: maxDate || new Date()
    };
  }

  for (let timeString of availableTimes) {
    const datetime = new Date(timeString);

    // Get min/max date
    if (!minDate) {
      minDate = datetime;
    } else if (datetime < minDate) {
      minDate = datetime;
    }
    if (!maxDate) {
      maxDate = datetime;
    } else if (datetime > minDate) {
      maxDate = datetime;
    }

    const dateString = datetime.toDateString();
    // Add times to specific date keys
    if (availableTimesMap[dateString] === undefined) {
      availableTimesMap[dateString] = [];
    }
    availableTimesMap[dateString].push(datetime);
  }

  return {
    availableTimesMap,
    minDate: minDate || new Date(),
    maxDate: maxDate || new Date()
  };
};

type TourAvailabilitySelectorProps = {
  contentStyles: ContentStyles;
} & Pick<
  AppointmentScheduleDialogProps,
  'isLoading' | 'availableTimes' | 'setStartTime' | 'startTime'
>;

const TourAvailabilitySelector: FC<TourAvailabilitySelectorProps> = ({
  contentStyles,
  isLoading,
  availableTimes,
  setStartTime,
  startTime
}) => {
  // Need to separate times into separate dates and times.
  // availableTimesMap is a map of date to available times on a given date.
  const { availableTimesMap, minDate, maxDate } =
    extractAvailableTimes(availableTimes);

  // Pick the first available date. otherwise, default to none.
  const hasAvailabilityForTourType = availableTimes.length !== 0;
  const [tourDate, setTourDate] = useState<string | null>(
    hasAvailabilityForTourType ? Object.keys(availableTimesMap)[0] : null
  );

  return (
    <>
      <Text
        variant="overline"
        color="secondary"
        className={contentStyles.sectionHeader}
      >
        Tour Availability
      </Text>
      {isLoading ? (
        <PageLoader />
      ) : (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {!hasAvailabilityForTourType ? (
            <div>
              <Alert severity="info">
                No availability for the given tour type. Please select a
                different tour type.
              </Alert>
            </div>
          ) : (
            <Paper
              elevation={0}
              className={contentStyles.tourAvailabilityPaper}
            >
              <Text variant="body2" color="secondary">
                Date
              </Text>
              <Text variant="body2" color="secondary">
                Time
              </Text>
              <div>
                <DatePicker
                  id="appointment-date"
                  name="appointment-date-input"
                  className={contentStyles.tourAvailabilityInputs}
                  onChange={(e) => setTourDate(e ? e.toDateString() : null)}
                  minDate={minDate}
                  maxDate={maxDate}
                  value={tourDate}
                />
              </div>
              <div>
                <FormControl className={contentStyles.tourAvailabilityInputs}>
                  <Select
                    onChange={(e) =>
                      setStartTime(e ? (e.target.value as string) : null)
                    }
                    disabled={!tourDate}
                    value={startTime}
                    id="appointment-time"
                  >
                    {tourDate &&
                      availableTimesMap[tourDate].map((time, idx) => (
                        <MenuItem key={idx} value={time.toISOString()}>
                          {time.toLocaleTimeString(undefined, {
                            timeZoneName: 'short'
                          })}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </Paper>
          )}
        </MuiPickersUtilsProvider>
      )}
    </>
  );
};
export default TourAvailabilitySelector;
