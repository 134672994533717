import React, { FC, ChangeEvent } from 'react';

import { Typography } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { DatePicker } from '../../DatePicker';
import { CalendarIcon } from '../../icons';
import {
  LEASE_END_DATE,
  LEASE_START_DATE,
  QuoteErrorMessages
} from './validation';
import {
  formattedDateString,
  getInitialEndDate,
  getLeaseTermLength,
  getMaxEndDate,
  getMinEndDate,
  getMinStartDate,
  getTermLengthOptions
} from './utils';
import { getDate } from '../../../utilities/timeUtilities';

import OptionLabel, { createOptionLabel } from './OptionLabel';
import LeaseTermSelect from './LeaseTermSelect';

export interface LeaseDetailsOptionsProps {
  blackoutDatesMap: { [key: string]: string };
  errorMessages: QuoteErrorMessages;
  leaseEndDate?: string;
  leaseStartDate?: string;
  proRatedDays: number;
  setErrorMessages: (errorMessages: QuoteErrorMessages) => void;
  setLeaseStartDate: (dateString: string) => void;
  setLeaseEndDate: (dateString: string) => void;
  selectedLeaseTerm: number;
  setSelectedLeaseTerm: (leaseTerm: number) => void;
}

const LeaseDetailsOptions: FC<LeaseDetailsOptionsProps> = ({
  blackoutDatesMap,
  errorMessages,
  leaseStartDate,
  leaseEndDate,
  selectedLeaseTerm,
  setErrorMessages,
  setLeaseStartDate,
  setLeaseEndDate,
  setSelectedLeaseTerm,
  proRatedDays
}) => {
  const clearErrorMessage = (fieldName: string) => {
    const errors = {
      ...errorMessages,
      [fieldName]: ''
    };
    setErrorMessages(errors);
  };

  const updateLeaseEndDate = (startDate: string, leaseTerm: number) => {
    const endDate = getInitialEndDate(startDate, leaseTerm, blackoutDatesMap);
    setLeaseEndDate(endDate);
  };

  const updateSelectedTeaseTerm = (endDateString: string) => {
    const leaseTermDifference = getLeaseTermLength(
      leaseStartDate,
      endDateString
    );

    setSelectedLeaseTerm(leaseTermDifference);
  };

  const getParsedDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1);
    const day = date.getDate().toString();
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  const handleOnChangeStartDate = (startDate: MaterialUiPickersDate) => {
    const dateString = startDate ? getParsedDate(startDate) : '';
    setLeaseStartDate(dateString);

    if (dateString && selectedLeaseTerm) {
      updateLeaseEndDate(dateString, selectedLeaseTerm);
      if (errorMessages[LEASE_END_DATE]) {
        clearErrorMessage(LEASE_END_DATE);
      }
    }
  };

  const handleOnLeaseTermChange = (e: ChangeEvent<{ value: unknown }>) => {
    const { value } = e.target;
    setSelectedLeaseTerm(value as number);
    if (leaseStartDate) {
      updateLeaseEndDate(leaseStartDate, value as number);
    }
  };

  const handleOnChangeEndDate = (endDate: MaterialUiPickersDate) => {
    const dateString = endDate ? getParsedDate(endDate) : '';
    setLeaseEndDate(dateString);
    if (leaseStartDate) {
      updateSelectedTeaseTerm(dateString);
    }
  };

  const handleOnOpen = (fieldName: string) => {
    clearErrorMessage(fieldName);
  };

  const shouldDisableDate = (day: MaterialUiPickersDate) => {
    return Boolean(blackoutDatesMap[formattedDateString(day)]);
  };

  const hasBlackoutDates = Object.keys(blackoutDatesMap).length > 0;

  const leaseStartOptionLabel = createOptionLabel('Lease Start');
  const initialStartDate = getMinStartDate();

  const leaseEndOptionLabel = createOptionLabel('Lease End');
  const leaseMaxEndDate = getMaxEndDate(leaseStartDate, selectedLeaseTerm);
  const leaseMinEndDate = getMinEndDate(leaseStartDate, selectedLeaseTerm);
  const initialEndDate = getInitialEndDate(
    leaseStartDate,
    selectedLeaseTerm,
    blackoutDatesMap
  );

  const reformatLeaseDateValue = (dateString: string | undefined) => {
    if (!dateString) {
      return '';
    }
    const [year, month, day] = dateString.split('-');
    return `${month}/${day}/${year}`;
  };

  const formattedStartDate = reformatLeaseDateValue(leaseStartDate);
  const formattedEndDate = reformatLeaseDateValue(leaseEndDate);

  return (
    <div className="lease-details-options">
      {hasBlackoutDates && (
        <Typography variant="body2">
          Blackout dates limit lease start and end date selections. Pro-rated
          days may be added or subtracted to accommodate lease end dates.
        </Typography>
      )}
      <div className="lease-details-row">
        <DatePicker
          InputProps={{ endAdornment: <CalendarIcon /> }}
          format="MM/dd/yyyy"
          error={Boolean(errorMessages[LEASE_START_DATE])}
          helperText={errorMessages[LEASE_START_DATE]}
          label={leaseStartOptionLabel}
          name={LEASE_START_DATE}
          initialFocusedDate={getDate(initialStartDate)}
          minDate={getDate(initialStartDate)}
          onOpen={() => handleOnOpen(LEASE_START_DATE)}
          onChange={handleOnChangeStartDate}
          value={getDate(formattedStartDate)}
          disablePast
          data-testid="leaseStartDate"
        />

        <LeaseTermSelect
          onChange={handleOnLeaseTermChange}
          selectedLeaseTerm={selectedLeaseTerm}
          termLenthOptions={getTermLengthOptions()}
        />
      </div>
      <div className="lease-details-row">
        <DatePicker
          InputProps={{ endAdornment: <CalendarIcon /> }}
          format="MM/dd/yyyy"
          error={Boolean(errorMessages[LEASE_END_DATE])}
          helperText={errorMessages[LEASE_END_DATE]}
          name={LEASE_END_DATE}
          initialFocusedDate={getDate(initialEndDate)}
          label={leaseEndOptionLabel}
          maxDate={getDate(leaseMaxEndDate)}
          minDate={getDate(leaseMinEndDate)}
          onOpen={() => handleOnOpen(LEASE_END_DATE)}
          onChange={handleOnChangeEndDate}
          shouldDisableDate={shouldDisableDate}
          value={getDate(formattedEndDate)}
          disablePast
          data-testid="leaseEndDate"
        />
        <div className="pro-rated-days">
          <OptionLabel>Pro-rated Days</OptionLabel>
          <Typography variant="body1">
            {proRatedDays > 0 ? `+${proRatedDays}` : proRatedDays}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default LeaseDetailsOptions;
