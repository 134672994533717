(function (angular) {
  const app = angular.module('knockApp');

  app.directive('unsubscribeContainer', [
    () => ({
      restrict: 'E',
      templateUrl: '/angular/views/unsubscribe/unsubscribe.html',
      controller: 'unsubscribeController'
    })
  ]);

  const GetUnsubscribeController = function ($window, $routeParams) {};
  GetUnsubscribeController.$inject = ['$window', '$routeParams'];

  app.controller('unsubscribeController', GetUnsubscribeController);
})(window.angular);
