/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const CommunitySMSConsentModalController = function (
    $scope,
    $mdDialog,
    prospectId
  ) {
    $scope.prospectId = prospectId;
  };

  CommunitySMSConsentModalController.$inject = [
    '$scope',
    '$mdDialog',
    'prospectId'
  ];

  app.controller(
    'CommunitySMSConsentModalController',
    CommunitySMSConsentModalController
  );

  app.directive('smsConsentModal', function () {
    return {
      templateUrl:
        '/angular/views/leasingTeam/leasing-team-community-sms-consent.html',
      controller: 'LeasingTeamCommunityProspectSMSConsentController',
      scope: {
        prospectId: '=',
        keepPageTitle: '=',
        canRejectConsent: '='
      }
    };
  });
})(window.angular);
