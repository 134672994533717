import React, { FC } from 'react';
import { Provider } from 'react-redux';

import { AngularContextProvider } from '../AngularContextProvider';
import { ThemeProvider } from '@knockrentals/knock-shared-web';
import { store } from '../../../../app/store';
import { ManagerInfo } from '../QuickReplies/models';
import BulkEmailModal from '../Prospects/BulkEmailModal';
import { BulkAction } from '../../../../app/features/prospects/selectedProspects';
import SnackbarMessage from '../molecules/Snackbar/SnackbarMessage';

interface BulkEmailProps {
  mergeTags: [];
  token: string;
  cheatProofEngagementScoreEnabled: boolean;
  getManagerQuickReplies: () => Promise<any>;
  saveQuickReply: (managerId: number, payload: any) => void;
  deleteQuickReply: (
    managerId: number,
    messageId: string,
    useDeleteEndpoint: boolean
  ) => void;
  managerInfo: ManagerInfo;
  isCompanyQuickRepliesEnabled: boolean;
  handleAttachments: (
    files: any,
    uploadSuccess: any,
    uploadProgress: any,
    uploadError: any
  ) => void;
  deleteAttachment: (deleteToken: string) => Promise<any>;
  sendBatchMessage: (
    streamIds: [],
    messageText: string | null,
    messageHtml: string,
    messageSubject: string,
    targets: [],
    attachments: any,
    asManagerId: string | null
  ) => Promise<any>;
  closeModal: () => void;
  preSelectedResidents: BulkAction[];
  subject?: string;
  title?: string;
  openModal: boolean;
  emailDomainBlocklist: string[];
}

const BulkEmail: FC<BulkEmailProps> = ({
  mergeTags,
  token,
  managerInfo,
  getManagerQuickReplies,
  saveQuickReply,
  deleteQuickReply,
  isCompanyQuickRepliesEnabled,
  handleAttachments,
  deleteAttachment,
  sendBatchMessage,
  cheatProofEngagementScoreEnabled,
  closeModal,
  preSelectedResidents,
  title,
  subject,
  openModal,
  emailDomainBlocklist
}) => {
  const [snackMessage, setSnackMessage] = React.useState<string | null>(null);

  const handleClose = () => {
    closeModal();
  };
  const handleCloseAlert = () => {
    setSnackMessage(null);
  };

  return (
    <AngularContextProvider
      context={{
        token,
        mergeTags,
        managerInfo,
        getManagerQuickReplies,
        saveQuickReply,
        deleteQuickReply,
        isCompanyQuickRepliesEnabled,
        handleAttachments,
        deleteAttachment,
        sendBatchMessage,
        cheatProofEngagementScoreEnabled
      }}
    >
      <Provider store={store}>
        <ThemeProvider>
          {snackMessage && (
            <SnackbarMessage
              handleCloseAlert={handleCloseAlert}
              snackMessage={snackMessage}
            />
          )}
          {openModal && (
            <BulkEmailModal
              open={openModal}
              closeModal={handleClose}
              records={preSelectedResidents}
              context="Residents"
              emailDomainBlocklist={emailDomainBlocklist}
              dialogTitle={title}
              defaultSubject={subject}
              setSnackMessage={setSnackMessage}
            />
          )}
        </ThemeProvider>
      </Provider>
    </AngularContextProvider>
  );
};

export default BulkEmail;
