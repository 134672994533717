/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('managerHours', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/preferences/manager-hours.html',
      controller: 'ManagerHoursController',
      scope: {
        hours: '='
      }
    };
  });

  app.controller('ManagerHoursController', [
    '$rootScope',
    '$scope',
    '$q',
    'apiEvents',
    'timeService',
    'officeHoursModel',
    function (
      $rootScope,
      $scope,
      $q,
      apiEvents,
      timeService,
      officeHoursModel
    ) {
      var self = this;

      if (!$scope.hours) {
        throw 'hours required';
      }

      $scope.titleFormat = 'dddd';

      self._initialize = function () {
        $scope.hours = self._mapManagerHours();

        self._getTimezones();
      };

      self._mapManagerHours = function () {
        var officeHoursMap = keyBy(angular.copy(officeHoursModel), 'weekday');

        if ($scope.hours.length > 0) {
          $scope.hours.forEach(function (day) {
            var weekday = timeService.get(day.start_time).isoWeekday();
            var date = timeService.get().isoWeekday(weekday);

            var startTime = timeService.get(
              timeService.combineDateAndTime(date, day.start_time)
            );
            var endTime = timeService.get(
              timeService.combineDateAndTime(date, day.end_time)
            );

            officeHoursMap[weekday] = {
              startTime: startTime,
              endTime: endTime,
              isActive: true
            };
          });
        }

        return sortBy(values(officeHoursMap), function (day) {
          return timeService.get(day.startTime).isoWeekday();
        });
      };

      self._getTimezones = function () {
        $scope.timezones = timeService.getTimezones();

        var possiblyDeferredTimezone = timeService.getActiveTimezone();
        $q.when(possiblyDeferredTimezone).then(function (activeTimezone) {
          $scope.activeTimezone = activeTimezone;
        });

        $scope.$watch('activeTimezone', function (newTimezone, oldTimezone) {
          if (
            isEmpty(oldTimezone) ||
            isEmpty(newTimezone) ||
            newTimezone === oldTimezone
          ) {
            return;
          }

          timeService.setTimezone(newTimezone);

          each($scope.officeHours, function (hours) {
            hours.startTime = timeService.get(
              hours.startTime,
              null,
              newTimezone.id
            );
            hours.endTime = timeService.get(
              hours.endTime,
              null,
              newTimezone.id
            );
          });

          // need to emit change to root scope because angular still considers two moments w/different timezones to be equal
          $rootScope.$emit(apiEvents.userTimezoneChanged);
        });
      };

      self._initialize();
    }
  ]);
})(window.angular);
