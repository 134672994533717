/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectEventQuote', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospectEvents/prospect-event-quote.html',
      scope: {
        event: '=',
        prospect: '=',
        manager: '=',
        property: '='
      },
      controller: [
        '$rootScope',
        '$scope',
        '$mdToast',
        'quotesService',
        function ($rootScope, $scope, $mdToast, quotesService) {
          $scope.sendQuote = function () {
            if ($scope.isUploadingQuote || isEmpty($scope.event.url)) {
              return;
            }

            $scope.isUploadingQuote = true;

            quotesService
              .uploadQuote($scope.event.url)
              .then(function (attachment) {
                $scope.isUploadingQuote = false;

                $rootScope.$emit('attachmentAdded', {
                  attachment: attachment,
                  stream_id: $scope.prospect.stream_id
                });

                $mdToast.showSimple('Quote added as an attachment!');
              });
          };

          $scope.regenerateQuoteURL = function () {
            quotesService
              .regenerateQuoteURL($scope.event.id)
              .success(function (response) {
                $scope.event.url = response.url;
              });
          };
        }
      ]
    };
  });
})(window.angular);
