/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('typingIndicator', function () {
    return {
      restrict: 'E',
      template:
        '<div class="typing-indicator-wrapper" ng-show="isTyping"><div class="typing-indicator"><span></span><span></span><span></span></div></div>',
      scope: {
        isTyping: '='
      }
    };
  });
})(window.angular);
