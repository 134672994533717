/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Authentication');

  app.factory('requestAccountModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openRequestAccountModal = function () {
        return $mdDialog.show({
          controller: 'RequestAccountController',
          templateUrl:
            '/angular/views/authentication/request-account-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true
        });
      };

      return self;
    }
  ]);

  app.controller('RequestAccountController', [
    '$scope',
    '$mdDialog',
    'chatTrackingService',
    'authenticationService',
    function ($scope, $mdDialog, chatTrackingService, authenticationService) {
      var self = this;

      $scope.typeChoices = [
        'Leasing agent',
        'Property manager',
        'Marketing',
        'Owner',
        'Other'
      ];

      $scope.editor = {
        profile: {
          first_name: '',
          last_name: '',
          email: '',
          phone: {},
          company: '',
          type: ''
        },
        contact_preference: 'email',
        isRequesting: false,
        requestSuccess: false,
        requestError: false
      };

      $scope.close = function () {
        $mdDialog.cancel();
      };

      $scope.openSignInModal = function () {
        $mdDialog.cancel();
        authenticationService.openManagerAuthModal();
      };

      $scope.openSupport = function () {
        $mdDialog.cancel();
        chatTrackingService.show();
      };

      $scope.sendAccountRequest = function () {
        var isDemoRequest = false;
        self._sendRequest(isDemoRequest);
      };

      $scope.sendDemoRequest = function () {
        var isDemoRequest = true;
        self._sendRequest(isDemoRequest);
      };

      self._sendRequest = function (isDemoRequest) {
        if (!$scope.editor.profile.type) {
          $scope.missingAccountType = true;
          return;
        }

        if ($scope.editor.profile.type === 'Renter') {
          authenticationService.openRenterAuthModal(
            'signup',
            $scope.editor.profile
          );
          $mdDialog.cancel();
          return;
        }

        $scope.missingAccountType = false;

        $scope.editor.isRequesting = true;
        $scope.editor.requestError = false;

        // TODO something better
        $scope.editor.profile.phone_number =
          $scope.editor.profile.phone.phone_number;

        authenticationService
          .requestManagerAccount(
            $scope.editor.profile,
            $scope.editor.contact_preference,
            isDemoRequest
          )
          .success(self._sendRequestSuccess)
          .error(self._sendRequestError);
      };

      self._sendRequestSuccess = function () {
        $scope.editor.isRequesting = false;
        $scope.editor.requestSuccess = true;
      };

      self._sendRequestError = function () {
        $scope.editor.isRequesting = false;
        $scope.editor.requestError = true;
      };
    }
  ]);
})(window.angular);
