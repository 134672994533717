import React from 'react';

export const GreenCheckIcon = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.49865 13.9213L2.07798 9.5C1.34295 8.7241 1.35924 7.50405 2.11471 6.74804C2.87018 5.99202 4.09022 5.97487 4.86665 6.70934L7.67865 9.52134L12.9453 2.49934C13.599 1.62691 14.8362 1.44962 15.7087 2.10334C16.5811 2.75706 16.7584 3.99424 16.1047 4.86667L9.47332 13.71C9.12995 14.1677 8.60491 14.4538 8.0342 14.4944C7.46349 14.5349 6.90327 14.3259 6.49865 13.9213Z"
      stroke="#15986D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
