/* eslint-disable */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const windowManagementService = function (
    userService,
    INBOUND_BROWSER_CALLING_BASE_URL,
    WEBFRONT_BASE_URL,
    $rootScope
  ) {
    var self = this;

    /**
     * This function sets a token session cookie to be used by another Knock site within the domain.
     *
     * @param {string} tokenValue the value of the token
     * @param {string | undefined} tokenField the field to place the token in within the cookie.
     */
    self._setTokenCookie = function (tokenValue, tokenField = 'token') {
      const token = `${tokenField}=${tokenValue}`;
      // Grab the domain and tld from config so this can be tested in dev env
      // (because the config varies -- knockrentals.com vs knockrentals.test)
      // eslint-disable-next-line no-unused-vars
      const [subdom, dom, tld] = WEBFRONT_BASE_URL.split('.');

      const domain = `domain=${[dom, tld].join('.')}`;
      const path = `path=/`;

      // We're deliberately setting both path and domain and deliberately *not* setting expiration
      // * we need path and domain to be set this way so we can use this cookie across subdomains
      // * we need expiration unset so that the token is only a session cookie
      document.cookie = `${token}; ${domain}; ${path};`;
    };

    /**
     * This function opens a link that includes a short form auth token. If
     * the user is already authenticated with a short form token, their
     * existing token will be used. If not, a new short form access token will
     * be generated and included in the link. The link will be formatted as
     * follows:
     *
     * ${url}?${tokenField}=${accessToken}
     *
     * @param {string} url the URL of the resource to be loaded.
     * @param {string} tokenField the identifier that will be used for the auth
     * token.
     * @param {string | undefined} target the frame or window that will be used
     * to load the link.
     * @param {string | undefined} features a string containing a comma
     * separated list of window features, "name1=value1,name2=value2,...".
     * @param {boolean | undefined} useCookieForToken flag indicating whether the token should be included in a cookie when opening the link.
     */
    $rootScope.isWindowOpen = '';
    self.openLinkWithShortToken = function (
      url,
      tokenField,
      target,
      features,
      useCookieForToken
    ) {
      if (useCookieForToken) {
        self.openWithCookie(url, tokenField, target, features);
        return;
      }

      userService
        .getEffectiveAccessToken()
        .success((response, status_code) => {
          if (status_code === 200) {
            let queryParams = `${tokenField}=${response.access_token}`;
            let finalURL = url;
            const queryStringIndex = url.indexOf('?');

            if (queryStringIndex > -1) {
              queryParams = `${url.substring(
                queryStringIndex + 1
              )}&${queryParams}`;
              finalURL = url.substring(0, queryStringIndex);
            }
            $rootScope.isWindowOpen = window.open(
              `${finalURL}?${queryParams}`,
              target,
              features
            );
          } else {
            // If we didn't get a 200 response, open the link without inserting an auth token
            window.open(url, target, features);
          }
        })
        .catch((error) => {
          // If we caught an error, output the error and open the link without inserting an auth token.
          console.error(`Failed to get access token: ${error}`);
          window.open(url, target, features);
        });
    };

    /**
     * This function sets a token cookie and opens a link to another Knock site.
     *
     * @param {string} url the URL of the resource to be loaded
     * @param {string} tokenField the field to place the token in within the cookie
     * @param {string | undefined} target the frame or window that will be used to load the link
     * @param {string | undefined} features a string containing a comma separated list of window
     * features, "name1=value1,name2=value2,..."
     */
    self.openWithCookie = function (url, tokenField, target, features) {
      userService
        .getEffectiveAccessToken()
        .success((response, status_code) => {
          if (status_code === 200) {
            self._setTokenCookie(response.access_token, tokenField);
          }
        })
        .catch((error) => {
          console.error(`Failed to get access token: ${error}`);
        })
        .finally(() => {
          // Always open the new window, even if the cookie wasn't set
          window.open(url, target, features);
        });
    };

    self.openKnockVoice = function (streamId, status, useCookieForToken) {
      let url = `${INBOUND_BROWSER_CALLING_BASE_URL}/login`;

      if (streamId) {
        url = `${url}?streamId=${streamId}`;
      }
      if (status && $rootScope.featureFlags.LMA_VOICEAPP_STATUS_ENHANCEMENT) {
        url = streamId ? `${url}&status=${status}` : `${url}?status=${status}`;
      }
      self.openLinkWithShortToken(
        url,
        'token',
        'knock-voice-app',
        'height=601,width=518,status=yes,modal=yes,alwaysRaised=yes',
        useCookieForToken
      );
    };

    self.focusOnKnockVoice = function () {
      if ($rootScope.isWindowOpen !== '') {
        $rootScope.isWindowOpen.focus();
      }
    };

    return self;
  };

  windowManagementService.$inject = [
    'userService',
    'INBOUND_BROWSER_CALLING_BASE_URL',
    'WEBFRONT_BASE_URL',
    '$rootScope'
  ];

  app.factory('windowManagementService', windowManagementService);
})(window.angular);
