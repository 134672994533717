/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('bouncyHouseOverlay', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/util/bouncyHouseOverlay.html',
      scope: {
        savingText: '=savingText'
      },
      controller: [
        '$scope',
        'imageBucket',
        function ($scope, imageBucket) {
          $scope.imageBucket = imageBucket;
        }
      ]
    };
  });
})();
