/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('prospectIntegrationsApi', [
    'apiBase',
    function (apiBase) {
      return {
        addYardiIntegration: function (yardiCredential) {
          return apiBase.post('/integration/yardi', yardiCredential);
        },
        deleteYardiIntegration: function (communityId, password) {
          return apiBase.delete(
            '/integration/yardi/' + communityId + '?password=' + password
          );
        },
        getMyIntegrations: function () {
          return apiBase.get('/me/integration/');
        },
        getYardiMappings: function (communityId) {
          return apiBase.get('/integration/yardi/' + communityId + '/mappings');
        },
        getYardiEventResults: function (communityId) {
          return apiBase.get(
            '/integration/yardi/' + communityId + '/event-results'
          );
        },
        updateYardiSourceMapping: function (
          communityId,
          sourceId,
          yardiSourceId
        ) {
          var payload = {
            sourceId: sourceId,
            yardiSourceId: yardiSourceId
          };
          return apiBase.put(
            '/integration/yardi/' + communityId + '/mappings/source',
            payload
          );
        },
        updateYardiManagerMapping: function (
          communityId,
          managerId,
          yardiManagerId,
          yardiCredentialId
        ) {
          var payload = {
            managerId: managerId,
            yardiManagerId: yardiManagerId,
            yardiCredentialId: yardiCredentialId
          };
          return apiBase.put(
            '/integration/yardi/' + communityId + '/mappings/manager',
            payload
          );
        },
        addRealPageIntegration: function (realPageCredential) {
          return apiBase.post('/integration/realpage', realPageCredential);
        },
        deleteRealPageIntegration: function (communityId, password) {
          return apiBase.delete(
            '/integration/realpage/' + communityId + '?password=' + password
          );
        },
        getEntrataMappings: function (communityId) {
          return apiBase.get(
            '/integration/entrata/' + communityId + '/mappings'
          );
        },
        updateEntrataSourceMapping: function (
          communityId,
          sourceId,
          realPageSourceId
        ) {
          var payload = {
            sourceId: sourceId,
            entrataSourceId: realPageSourceId
          };
          return apiBase.put(
            '/integration/entrata/' + communityId + '/mappings/source',
            payload
          );
        },
        updateEntrataManagerMapping: function (
          communityId,
          managerId,
          entrataManagerId
        ) {
          var payload = {
            managerId: managerId,
            entrataManagerId: entrataManagerId
          };
          return apiBase.put(
            '/integration/entrata/' + communityId + '/mappings/manager',
            payload
          );
        },
        getEntrataPricingMatrix(propertyId, moveInDate, unitName) {
          const payload = {
            move_in_date: moveInDate,
            unit_name: unitName
          };
          return apiBase.post(
            `/property/${propertyId}/entrata-pricing-matrix`,
            payload
          );
        },
        getEntrataIntegrationPreferences: function (communityId) {
          return apiBase.get(
            '/integration/entrata/' + communityId + '/preferences'
          );
        },
        updateEntrataIntegrationPreferences: function (
          communityId,
          preferences
        ) {
          return apiBase.put(
            '/integration/entrata/' + communityId + '/preferences',
            preferences
          );
        },
        addEntrataIntegration: function (entrataCredential) {
          return apiBase.post('/integration/entrata', entrataCredential);
        },
        deleteEntrataIntegration: function (communityId, password) {
          return apiBase.delete(
            '/integration/entrata/' + communityId + '?password=' + password
          );
        },
        updateIngestionPreferences: function (vendor, communityId, preference) {
          return apiBase.put(
            '/integration/' +
              vendor +
              '/' +
              communityId +
              '/preference/ingestion',
            preference
          );
        },
        getRealPageMappings: function (communityId) {
          return apiBase.get(
            '/integration/realpage/' + communityId + '/mappings'
          );
        },
        updateRealPageSourceMapping: function (
          communityId,
          sourceId,
          realPageSourceId
        ) {
          var payload = {
            sourceId: sourceId,
            realpageSourceId: realPageSourceId
          };
          return apiBase.put(
            '/integration/realpage/' + communityId + '/mappings/source',
            payload
          );
        },
        updateRealPageManagerMapping: function (
          communityId,
          managerId,
          realPageManagerId
        ) {
          var payload = {
            managerId: managerId,
            realpageManagerId: realPageManagerId
          };
          return apiBase.put(
            '/integration/realpage/' + communityId + '/mappings/manager',
            payload
          );
        },
        updateRealPageContactTypeMapping: function (
          communityId,
          contactTypeId,
          realpageContactTypeId
        ) {
          var payload = {
            contactTypeId: contactTypeId,
            realpageContactTypeId: realpageContactTypeId
          };
          return apiBase.put(
            '/integration/realpage/' + communityId + '/mappings/contact_type',
            payload
          );
        },
        getRealPageIntegrationPreferences: function (communityId) {
          return apiBase.get(
            '/integration/realpage/' + communityId + '/preferences'
          );
        },
        postRealPageUnifiedQuotes: function (
          prospectId,
          communityId,
          realpageQuote
        ) {
          return apiBase.post('/realpage/quote', {
            prospect_id: prospectId,
            community_id: communityId,
            realpage_quote: realpageQuote
          });
        }
      };
    }
  ]);
})();
