import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Select,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  makeStyles,
  FormControlProps,
  SelectProps
} from '@material-ui/core';
import { NamedColors } from '@knockrentals/knock-shared-web';
import { colors } from '../commonStyles/commonStyles';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles({
  formLabel: {
    top: 20,
    left: 12,
    fontWeight: 400,
    fontSize: '12px'
  },
  textInput: {
    width: '100%',
    border: 'none !important',
    color: 'black !important',
    padding: '0px',
    '& svg': {
      fontSize: '20px',
      color: colors.secondaryText
    },
    '& .MuiInputLabel-formControl': {
      left: '12px',
      margin: 0,
      transform: 'none',
      border: 'none !important'
    },
    '& .MuiInputBase-formControl': {
      borderColor: NamedColors.slate[300],
      margin: '0px !important'
    },
    '& .MuiTypography-root': {
      fontSize: '14px'
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '4px'
    },
    '& .MuiListItem-button': {
      '&:hover': {
        backgroundColor: 'black',
        boxShadow: 'none'
      }
    },
    '& .MuiFilledInput-root': {
      backgroundColor: colors.inputBackground
    }
  },
  openedMenu: {
    backgroundColor: NamedColors.denim[50]
  },
  menuItemHover: {
    '&:hover': {
      backgroundColor: '#F5F6FF' // Change this to your desired color
    }
  }
});

export interface ISelectItem {
  label: string;
  value: string;
}

interface ISelectControl {
  id?: string;
  formControlProps?: FormControlProps;
  selectProps?: SelectProps;
  items: ISelectItem[];
  label: string;
  labelId?: string;
  helperText?: string;
  bottomOrigin?: boolean;
}

const SelectControl: FC<ISelectControl> = ({
  formControlProps,
  items,
  label,
  selectProps,
  helperText,
  labelId,
  id,
  bottomOrigin
}) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLElement | null>(null);
  const [top, setTop] = useState<number | null>(null);
  const formRef = useRef<HTMLElement | null>();

  //This allow the menu to be in the bottom of the input
  useEffect(() => {
    if (menuRef.current && bottomOrigin) {
      const els = menuRef.current.getElementsByClassName('MuiPaper-root');
      if (els.length > 0) {
        const menu = els[0] as HTMLElement;
        menu.style.top = `${top}px`;
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuRef, menuOpen]);
  useEffect(() => {
    if (formRef.current && bottomOrigin) {
      setTop(formRef.current?.getBoundingClientRect().top - 34.5);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      innerRef={formRef}
      data-testid={id}
      id={id}
      variant="filled"
      className={`${classes.textInput}`}
      {...formControlProps}
    >
      <InputLabel id={labelId} className={classes.formLabel}>
        {label}
      </InputLabel>
      <Select
        className={`${classes.textInput} ${menuOpen ? classes.openedMenu : ''}`}
        MenuProps={{
          ref: menuRef,
          TransitionProps: {
            onEnter: () => setMenuOpen(true),
            onExit: () => setMenuOpen(false)
          }
        }}
        IconComponent={ExpandMore}
        {...selectProps}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            classes={{ root: classes.menuItemHover }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={formControlProps?.error}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default SelectControl;
