/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('listingIndexNavigation', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/listingIndex/listing-index-navigation.html',
      controller: [
        '$scope',
        '$location',
        'accessControl',
        'upgradeModalService',
        'postFloorplanModalService',
        function (
          $scope,
          $location,
          accessControl,
          upgradeModalService,
          postFloorplanModalService
        ) {
          var self = this;

          $scope.currentTab = 'activeListings';

          $scope.editCommunity = function (community) {
            $location.url('/manager/community/' + community.id);
          };

          $scope.addFloorplan = function (community) {
            $location.url(
              '/manager/community/' + community.id + '/addFloorplan'
            );
          };

          $scope.editFloorplan = function (floorplan) {
            $location.url('/manager/community/floorplan/' + floorplan.id);
          };

          $scope.postFloorplanListing = function ($event, floorplan) {
            accessControl.canAddNewListing().then(function () {
              postFloorplanModalService.openModal(
                $event,
                floorplan,
                $scope.reloadData
              );
            }, self._openUpgradeUserSubscriptionModal);
          };

          self._openUpgradeUserSubscriptionModal = function () {
            upgradeModalService.openUpgradeToProModal();
          };
        }
      ]
    };
  });
})(window.angular);
