/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular, $) {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('MeetingPageController', [
    '$scope',
    '$window',
    '$location',
    '$interval',
    '$q',
    '$routeParams',
    '$moment',
    '$mdDialog',
    '$auth',
    'pageTitleService',
    'userService',
    'appointmentsApi',
    'videoCallApi',
    function (
      $scope,
      $window,
      $location,
      $interval,
      $q,
      $routeParams,
      $moment,
      $mdDialog,
      $auth,
      pageTitleService,
      userService,
      appointmentsApi,
      videoCallApi
    ) {
      const TwilioVideo = require('twilio-video');
      const flatMap = require('util').flatMap;

      var self = this;

      var videoCallDisplayId = $routeParams.videoCallDisplayId;
      var isExternal = $routeParams.isExternal === 'true';
      var user = userService.getUser();

      var activeRoom = null;

      var checkVideoCallStatusCount = 0;
      const checkVideoCallStatusMaxAttempts = 60;
      const checkVideoCallStatusIntervalMs = 10 * 1000;

      self._initialize = function () {
        $scope.videoCallDisplayId = videoCallDisplayId;
        $scope.sessionManager = null;
        $scope.isLoaded = false;
        $scope.isVideoCallJoined = false;
        $scope.participantName = null;

        $scope.videoInputOptions = [];
        $scope.audioInputOptions = [];
        $scope.audioOutputOptions = [];

        $scope.videoInputActiveDeviceLabel = null;
        $scope.audioInputActiveDeviceLabel = null;
        $scope.audioOutputActiveDeviceLabel = null;

        self._getVideoCall().then(function () {
          if (
            user &&
            user.type === 'manager' &&
            user.group_ids.includes($scope.videoCall.leasing_team.id)
          ) {
            $scope.sessionManager = user;
          }

          var pageTitle = 'Video Call - ' + $scope.videoCallDisplayId;
          pageTitleService.setPageTitle(pageTitle);

          $scope.isLoaded = true;

          if ($scope.sessionManager === null) {
            $interval(
              self._checkVideoCallStatus,
              checkVideoCallStatusIntervalMs
            ); // every 10 seconds
          }

          // setup device selections
          self._updateDeviceSelectionOptions();

          // Whenever a media device is added or removed, update the list.
          navigator.mediaDevices.ondevicechange =
            self._updateDeviceSelectionOptions;
        });
      };

      self._checkVideoCallStatus = function () {
        if (activeRoom !== null) {
          checkVideoCallStatusCount = 0;
          return;
        }

        if (checkVideoCallStatusCount < checkVideoCallStatusMaxAttempts) {
          console.log('Checking video call status...');
          self._getVideoCall();
          checkVideoCallStatusCount++;
        }
      };

      self._getVideoCall = function () {
        return videoCallApi
          .getVideoCall(videoCallDisplayId)
          .then(function (response) {
            $scope.videoCall = response.data.video_call;
          });
      };

      self._startVideoCall = function () {
        return videoCallApi
          .startVideoCall(videoCallDisplayId)
          .then(function (response) {
            $scope.twilioIdentity = response.data.identity;
            $scope.twilioToken = response.data.token;
          });
      };

      self._joinVideoCall = function () {
        var displayName = $scope.participantName;
        if (displayName.length < 3) {
          return false;
        }

        return videoCallApi
          .joinVideoCall(videoCallDisplayId, displayName)
          .then(function (response) {
            $scope.twilioIdentity = response.data.identity;
            $scope.twilioToken = response.data.token;
          });
      };

      self._endVideoCall = function () {
        return videoCallApi
          .endVideoCall(videoCallDisplayId)
          .then(function (response) {
            $scope.twilioIdentity = null;
            $scope.twilioToken = null;
            $scope.videoCall = response.data.video_call;
          });
      };

      $scope.startVideoCall = function () {
        if ($scope.sessionManager === null) {
          return;
        }

        self._startVideoCall().then(function () {
          $scope.videoCall.started_by = $scope.sessionManager.id;
          self._initializeTwilioVideoCall();
          $scope.isVideoCallJoined = true;
        });
      };

      $scope.joinVideoCall = function () {
        self._joinVideoCall().then(function () {
          self._initializeTwilioVideoCall();
          $scope.isVideoCallJoined = true;
        });
      };

      $scope.muteMyAudio = function () {
        self._muteOrUnmuteMyMedia('audio', 'mute');
        $scope.isMicrophoneMuted = true;
      };
      $scope.unmuteMyAudio = function () {
        self._muteOrUnmuteMyMedia('audio', 'unmute');
        $scope.isMicrophoneMuted = false;
      };

      $scope.muteMyVideo = function () {
        self._muteOrUnmuteMyMedia('video', 'mute');
        $scope.isCameraMuted = true;
      };
      $scope.unmuteMyVideo = function () {
        self._muteOrUnmuteMyMedia('video', 'unmute');
        $scope.isCameraMuted = false;
      };

      $scope.changeVideoInput = function (device) {
        if ($scope.videoInputActiveDeviceLabel !== device.label) {
          var video = document.querySelector('#my-media video');
          self._applyVideoInputDeviceSelection(device.deviceId, video);
          $scope.videoInputActiveDeviceLabel = device.label;
        }
      };

      $scope.changeAudioInput = function (device) {
        if ($scope.audioInputActiveDeviceLabel !== device.label) {
          var audio = document.querySelector('#my-media audio');
          self._applyAudioInputDeviceSelection(device.deviceId, audio);
          $scope.audioInputActiveDeviceLabel = device.label;
        }
      };

      $scope.changeAudioOutput = function (device) {
        if ($scope.audioOutputActiveDeviceLabel !== device.label) {
          var audio = document.querySelector('#my-media audio');
          self._applyAudioOutputDeviceSelection(device.deviceId, audio);
          $scope.audioOutputActiveDeviceLabel = device.label;
        }
      };

      $scope.leaveVideoCall = function () {
        $scope.isVideoCallJoined = false;

        if ($scope.sessionManager !== null) {
          self._endVideoCall();
        }

        if (activeRoom !== null) {
          activeRoom.disconnect();
        }
      };

      self._initialize();
      window.onbeforeunload = $scope.leaveVideoCall;

      self._initializeTwilioVideoCall = function () {
        var connectOptions = {
          name: videoCallDisplayId,
          logLevel: 'error'
        };

        // Join the Room with the token from the server and the LocalParticipant's Tracks.
        TwilioVideo.connect($scope.twilioToken, connectOptions).then(
          self._roomJoined,
          function (error) {
            console.log('Could not connect to Twilio: ' + error.message);
          }
        );
      };

      /**
       * FUNCTIONS PORTED FROM TWILIO VIDEO SAMPLE PROJECT
       * ADAPTED FROM: https://github.com/twilio/video-quickstart-js/blob/master/quickstart/src/index.js
       */
      // Get the Participant's Tracks.
      self._getTracks = function (participant) {
        return Array.from(participant.tracks.values())
          .filter(function (publication) {
            return publication.track;
          })
          .map(function (publication) {
            return publication.track;
          });
      };
      // Attach the Track to the DOM.
      self._attachTrack = function (track, container) {
        container.appendChild(track.attach());
      };

      // Attach array of Tracks to the DOM.
      self._attachTracks = function (tracks, container) {
        tracks.forEach(function (track) {
          self._attachTrack(track, container);
        });
      };

      // Detach given track from the DOM.
      self._detachTrack = function (track) {
        track.detach().forEach(function (element) {
          element.remove();
        });
      };

      // Detach the Participant's Tracks from the DOM.
      self._detachParticipantTracks = function (participant) {
        var tracks = self._getTracks(participant);
        tracks.forEach(self._detachTrack);
      };

      // Appends remoteParticipant name to the DOM.
      self._appendName = function (identity, container) {
        const name = document.createElement('p');
        name.id = `participantName-${identity}`;
        name.className = 'instructions';
        name.textContent = identity;
        container.appendChild(name);
      };

      // Removes remoteParticipant container from the DOM.
      self._removeName = function (participant) {
        if (participant) {
          let { identity } = participant;
          const container = document.getElementById(
            `participantContainer-${identity}`
          );
          container.parentNode.removeChild(container);
        }
      };

      // A new RemoteTrack was published to the Room.
      self._trackPublished = function (publication, container) {
        if (publication.isSubscribed) {
          self._attachTrack(publication.track, container);
        }
        publication.on('subscribed', function (track) {
          console.log('Subscribed to ' + publication.kind + ' track');
          self._attachTrack(track, container);
        });
        publication.on('unsubscribed', self._detachTrack);
      };

      // A RemoteTrack was unpublished from the Room.
      self._trackUnpublished = function (publication) {
        console.log(publication.kind + ' track was unpublished.');
      };

      // A new RemoteParticipant joined the Room
      self._participantConnected = function (participant, container) {
        let selfContainer = document.createElement('div');
        selfContainer.id = `participantContainer-${participant.identity}`;

        container.appendChild(selfContainer);
        self._appendName(participant.identity, selfContainer);

        participant.tracks.forEach(function (publication) {
          self._trackPublished(publication, selfContainer);
        });
        participant.on('trackPublished', function (publication) {
          self._trackPublished(publication, selfContainer);
        });
        participant.on('trackUnpublished', self._trackUnpublished);
      };

      // Successfully connected!
      self._roomJoined = function (room) {
        window.room = activeRoom = room;

        console.log("Joined as '" + $scope.twilioIdentity + "'");

        // Attach LocalParticipant's Tracks, if not already attached.
        var previewContainer = document.getElementById('my-media');
        if (!previewContainer.querySelector('video')) {
          self._attachTracks(
            self._getTracks(room.localParticipant),
            previewContainer
          );
        }

        // Identifying the local Camera that is currently in use
        room.localParticipant.videoTracks.forEach(function (videoTrack) {
          var videoMediaStreamTrack = videoTrack.track.mediaStreamTrack;
          if (videoMediaStreamTrack.enabled && !videoMediaStreamTrack.muted) {
            $scope.videoInputActiveDeviceLabel = videoMediaStreamTrack.label;
          }
        });

        // Identifying the local Microphone that is currently in use
        room.localParticipant.audioTracks.forEach(function (audioTrack) {
          var audioMediaStreamTrack = audioTrack.track.mediaStreamTrack;
          if (audioMediaStreamTrack.enabled && !audioMediaStreamTrack.muted) {
            $scope.audioInputActiveDeviceLabel = audioMediaStreamTrack.label;
          }
        });

        // Attach the Tracks of the Room's Participants.
        var participantMediaContainer =
          document.getElementById('participant-media');
        room.participants.forEach(function (participant) {
          console.log("Already in Room: '" + participant.identity + "'");
          self._participantConnected(participant, participantMediaContainer);
        });

        // When a Participant joins the Room, log the event.
        room.on('participantConnected', function (participant) {
          console.log("Joining: '" + participant.identity + "'");
          self._participantConnected(participant, participantMediaContainer);
        });

        // When a Participant leaves the Room, detach its Tracks.
        room.on('participantDisconnected', function (participant) {
          console.log(
            "RemoteParticipant '" + participant.identity + "' left the room"
          );
          self._detachParticipantTracks(participant);
          self._removeName(participant);

          if (
            $scope.sessionManager === null &&
            participant.identity === 'manager-' + $scope.videoCall.started_by
          ) {
            $scope.videoCall.started_by = null;
            $scope.leaveVideoCall();
            self._endVideoCall();
          }
        });

        room.on('trackSubscribed', function (track, publication, participant) {
          track.on('disabled', function () {
            self._detachTrack(track);
          });
          track.on('enabled', function () {
            self._attachTrack(track, participantMediaContainer);
          });
        });

        // Once the LocalParticipant leaves the room, detach the Tracks
        // of all Participants, including that of the LocalParticipant.
        room.on('disconnected', function () {
          console.log('Left');
          self._detachParticipantTracks(room.localParticipant);
          room.participants.forEach(self._detachParticipantTracks);
          room.participants.forEach(self._removeName);
          activeRoom = null;
        });
      };

      // @param {'audio'|'video'} kind - The type of media you want to mute/unmute
      // @param {'mute'|'unmute'} action - Whether you want to mute/unmute
      self._muteOrUnmuteMyMedia = function (kind, action) {
        if (activeRoom === null) {
          return;
        }
        const publications =
          kind === 'audio'
            ? activeRoom.localParticipant.audioTracks
            : activeRoom.localParticipant.videoTracks;
        publications.forEach(function (publication) {
          if (action === 'mute') {
            publication.track.disable();
          } else {
            publication.track.enable();
          }
        });
      };

      /**
       * Get the list of available media devices.
       * @returns {Promise<DeviceSelectionOptions>}
       * @typedef {object} DeviceSelectionOptions
       * @property {Array<MediaDeviceInfo>} audioinput
       * @property {Array<MediaDeviceInfo>} audiooutput
       * @property {Array<MediaDeviceInfo>} videoinput */
      self._getDeviceSelectionOptions = function () {
        return navigator.mediaDevices
          .enumerateDevices()
          .then(function (deviceInfos) {
            var kinds = ['audioinput', 'audiooutput', 'videoinput'];
            return kinds.reduce(function (deviceSelectionOptions, kind) {
              deviceSelectionOptions[kind] = deviceInfos.filter(function (
                deviceInfo
              ) {
                return deviceInfo.kind === kind;
              });
              return deviceSelectionOptions;
            }, {});
          });
      };

      /**
       * Build the list of available media devices. */
      self._updateDeviceSelectionOptions = function () {
        // before enumerating devices, get media permssions
        // NOTE: w/o media permissions, safari/ff does not return the labels
        // (like front camera, back camera) for the devices.
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(self._getDeviceSelectionOptions)
          .then(function (deviceSelectionOptions) {
            $scope.videoInputOptions = deviceSelectionOptions['videoinput'];
            $scope.audioInputOptions = deviceSelectionOptions['audioinput'];
            $scope.audioOutputOptions = deviceSelectionOptions['audiooutput'];
          });
      };

      /**
       * Apply the selected audio output device.
       * @param {string} deviceId
       * @param {HTMLAudioElement} audio
       * @returns {Promise<void>} */
      self._applyAudioOutputDeviceSelection = function (deviceId, audio) {
        return typeof audio.setSinkId === 'function'
          ? audio.setSinkId(deviceId)
          : Promise.reject(
              'This browser does not support setting an audio output device'
            );
      };

      /**
       * Apply the selected audio input device.
       * @param {string} deviceId
       * @returns {Promise<void>} */
      self._applyAudioInputDeviceSelection = function (deviceId, audio) {
        return TwilioVideo.createLocalAudioTrack({
          deviceId: {
            exact: deviceId // NOTE: on ios safari - it respects the deviceId only if its exact.
          }
        })
          .then(function (localTrack) {
            localTrack.attach(audio);
            if (activeRoom !== null) {
              self._switchLocalTracks(localTrack);
            }
          })
          .catch(function (error) {
            console.log('applyAudioInputDeviceSelection failed:', error);
          });
      };

      /**
       * Apply the selected video input device.
       * @param {string} deviceId
       * @returns {Promise<void>} */
      self._applyVideoInputDeviceSelection = function (deviceId, video) {
        return TwilioVideo.createLocalVideoTrack({
          deviceId: {
            exact: deviceId
          }
        })
          .then(function (localTrack) {
            localTrack.attach(video);
            if (activeRoom !== null) {
              self._switchLocalTracks(localTrack);
            }
          })
          .catch(function (error) {
            console.log('applyVideoInputDeviceSelection failed:', error);
          });
      };

      /**
       * Replace the existing LocalAudioTrack or LocalVideoTrack with
       * a new one in the Room.
       * @param {LocalAudioTrack|LocalVideoTrack} track - The LocalTrack you want to switch to
       * @returns {void} */
      self._switchLocalTracks = function (track) {
        activeRoom.localParticipant.tracks.forEach(function (trackPublication) {
          if (trackPublication.kind === track.kind) {
            trackPublication.track.stop();
            activeRoom.localParticipant.unpublishTrack(trackPublication.track);
          }
        });
        activeRoom.localParticipant.publishTrack(track);
      };
      /**
       * END OF FUNCTIONS PORTED FROM TWILIO VIDEO SAMPLE PROJECT
       */
    }
  ]);
})(window.angular, window.jQuery);
