/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import remove from 'lodash/remove';
(function (angular, $) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('attachmentsPicker', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/attachments-picker.html',
      controller: 'AttachmentsPickerController',
      scope: {
        attachments: '=',
        urls: '=?',
        onEmailAttachmentAdded: '=?',
        messageTarget: '='
      },
      link: function (scope, element) {
        var _onDragOver = function (event) {
          event.stopPropagation();
          event.preventDefault();

          scope.$apply(function () {
            scope.dropFieldClass = 'drag-over';
          });
        };

        var _onDragLeave = function (event) {
          event.stopPropagation();
          event.preventDefault();

          scope.$apply(function () {
            scope.dropFieldClass = 'drag-leave';
          });
        };

        var _onDragDrop = function (event) {
          event.stopPropagation();
          event.preventDefault();

          scope.$apply(function () {
            scope.handleSelectedAttachments(event.originalEvent.dataTransfer);
            scope.dropFieldClass = 'drag-leave';
          });
        };

        $(document).on('dragover', '#attachmentDrop', _onDragOver);
        $(document).on('dragleave', '#attachmentDrop', _onDragLeave);
        $(document).one('drop', '#attachmentDrop', _onDragDrop);
      }
    };
  });

  const AttachmentsPickerController = function (
    $scope,
    $timeout,
    $window,
    $mdToast,
    attachmentService,
    deviceDetector
  ) {
    var self = this;
    const MAX_FILE_SIZE = 1024 * 1024 * 25; // 25MB

    $scope.attachments = $scope.attachments || {
      local: [],
      remote: []
    };

    $scope.data = {
      urlToAdd: '',
      uploadProgress: 0,
      message: {
        target: $scope.messageTarget
      }
    };

    $scope.saveRename = function (attachment) {
      attachment.isRenaming = false;
      attachment.newFilename = attachment.editedFilename;
    };

    $scope.startRename = function (attachment) {
      attachment.isRenaming = true;
      attachment.editedFilename = attachment.newFilename;

      $timeout(
        function () {
          angular.element('#attachment' + attachment.delete_token).focus();
        },
        500,
        true
      );
    };

    $scope.cancelRename = function (attachment) {
      attachment.isRenaming = false;
      attachment.editedFilename = attachment.newFilename;
    };

    $scope.addURL = function () {
      if (!$scope.data.urlToAdd) {
        return;
      }

      self._addUrl($scope.data.urlToAdd);

      $scope.data.urlToAdd = '';
    };

    self._addUrl = function (url) {
      attachmentService.uploadByUrl(
        url,
        self._uploadSuccess,
        self._uploadProgress,
        self._uploadError
      );
    };

    $scope.removeAttachment = function (attachmentToRemove) {
      attachmentService.deleteAttachment(attachmentToRemove.delete_token);

      remove($scope.attachments.remote, function (remoteAttachment) {
        return (
          remoteAttachment.delete_token === attachmentToRemove.delete_token
        );
      });
    };

    $scope.openFilePrompt = function ($event) {
      if ($event.clientX === 0 && $event.clientY === 0) {
        // this click came from propagation of the event, return
        return;
      }

      var fileInput = angular.element('.attachment-file-input')[0];

      if (fileInput) {
        if (deviceDetector.browser === 'ie') {
          fileInput.click();
        } else {
          $timeout(function () {
            fileInput.click();
          });
        }
      }
    };

    $scope.handleSelectedAttachments = function (element) {
      if (element.files.length === 0) {
        return;
      }

      $scope.attachments.local = map(element.files, function (file) {
        file.isLocal = true;
        file.mimetype = file.type;
        file.url = $window.URL.createObjectURL(file);

        return file;
      });

      self._uploadAttachments();
    };

    self._initialize = function () {
      if ($scope.attachments.local && $scope.attachments.local.length > 0) {
        self._uploadAttachments();
      }

      if ($scope.urls && $scope.urls.length > 0) {
        forEach($scope.urls, function (url) {
          self._addUrl(url);
        });
      }
    };

    self._uploadAttachments = function () {
      var files = $scope.attachments.local;
      var tooLargeAttachments = remove(files, function (file) {
        return file.size > MAX_FILE_SIZE;
      });

      if (tooLargeAttachments.length > 0) {
        $scope.attachments.local = [];
        $mdToast.showSimple(
          'One or more files are too large. Must be 10MB or less per file.'
        );
        return;
      }

      attachmentService.uploadByFile(
        files,
        self._uploadSuccess,
        self._uploadProgress,
        self._uploadError
      );
    };

    self._uploadSuccess = function (response) {
      const json = JSON.parse(response.target.responseText);

      if (!json.attachments && json.message) {
        $mdToast.showSimple(json.message);
        return;
      }

      $scope.attachments.remote = $scope.attachments.remote.concat(
        map(json.attachments, function (attachment) {
          attachment.type = attachmentService.getAttachmentType(
            attachment.mimetype
          );
          attachment.isRenaming = false;
          attachment.newFilename = attachment.filename;
          attachment.editedFilename = attachment.filename;
          attachment.canEditFilename = attachment.type === 'pdf';

          return attachment;
        })
      );

      $scope.attachments.local = [];

      if ($scope.onEmailAttachmentAdded && $scope.messageTarget === 'email') {
        $scope.onEmailAttachmentAdded($scope.attachments.remote);
      }

      $scope.$apply();
    };

    self._uploadProgress = function (progressEvent) {
      $scope.data.uploadProgress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      $scope.$apply();
    };

    self._uploadError = function () {
      $mdToast.showSimple('There was an error uploading attachments.');
    };

    self._initialize();
  };

  AttachmentsPickerController.$inject = [
    '$scope',
    '$timeout',
    '$window',
    '$mdToast',
    'attachmentService',
    'deviceDetector'
  ];

  app.controller('AttachmentsPickerController', AttachmentsPickerController);
})(window.angular, window.jQuery);
