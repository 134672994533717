/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
(function (angular) {
  'use strict';

  var app = angular.module('knock-PropertyReports');

  app.directive('prospectReportStages', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/propertyReports/prospect-report-stages.html',
      controller: 'ProspectReportStagesController',
      scope: {
        data: '=',
        filters: '='
      }
    };
  });

  const ProspectReportStagesController = function ($scope) {
    var self = this;

    $scope.$watch('data.prospects', function () {
      self._initialize();
    });

    self._initialize = function () {
      var prospectsByStatus = groupBy(
        $scope.data.prospects,
        function (prospect) {
          return prospect.status;
        }
      );

      $scope.prospectsByStatus = map(
        prospectsByStatus,
        function (prospects, key) {
          return {
            prospects: prospects,
            key: key
          };
        }
      );

      $scope.prospectsReady = $scope.data.prospects;
    };
  };

  ProspectReportStagesController.$inject = ['$scope'];

  app.controller(
    'ProspectReportStagesController',
    ProspectReportStagesController
  );
})(window.angular);
