import React from 'react';

export const BuildingNatureIcon = () => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 19.6V17.2C6 16.5373 6.55964 16 7.25 16C7.94036 16 8.5 16.5373 8.5 17.2V19.6"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.625 6.4V19.6"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.875 19.6V6.4"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.15417 3.4632L1.625 6.4H12.875L11.3458 3.4632C11.134 3.05662 10.701 2.79986 10.2275 2.8H4.2725C3.79896 2.79986 3.366 3.05662 3.15417 3.4632Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 2.8V1.6"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.625 19.6H20.375"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.875 16C17.875 16.6627 17.3154 17.2 16.625 17.2C15.9346 17.2 15.375 16.6627 15.375 16C15.5167 14.5377 15.9406 13.1135 16.625 11.8C17.3094 13.1135 17.7333 14.5377 17.875 16V16Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.625 17.2V19.6"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.375 13.6H9.125"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.375 11.2H9.125"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.375 8.8H9.125"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
