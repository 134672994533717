/**
 * Assumes the auth token lives in localStorage and is configured
 * through satellizer.
 */
export const getAuthToken = (): string | null => {
  const token = localStorage.getItem('satellizer.knock_token');
  if (token) {
    // satellizer stores the token with quotes surrounding it. remove these.
    return token.replace(/"/g, '');
  }
  return null;
};
