import React, { FC } from 'react';
import classnames from 'classnames';
import { TodoStatus } from '../../../../ts/models';
import '../_ConnectedProfilesDialog.scss';

export interface ProfileStatusProps {
  statusLabel: string;
  todoStatus?: TodoStatus;
}

export const ProfileStatus: FC<ProfileStatusProps> = ({
  statusLabel,
  todoStatus
}) => (
  <span className={classnames('profile-status', todoStatus)}>
    {statusLabel}
  </span>
);
