import React, { FC } from 'react';
import { Snackbar, makeStyles } from '@material-ui/core';

import { CircularProgress, Box, Text } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  loadingContainer: {
    display: 'flex'
  },

  loadingMessage: {
    marginTop: '8px',
    marginLeft: '20px'
  },

  circularProgress: {
    color: 'white'
  }
});

const vertical = 'bottom';
const horizontal = 'left';

const DownloadingMessage = () => {
  const { loadingContainer, loadingMessage, circularProgress } = useStyles();

  return (
    <Box className={loadingContainer}>
      <CircularProgress className={circularProgress} />{' '}
      <Text className={loadingMessage}> Downloading CSV file </Text>
    </Box>
  );
};

const DownloadProspects: FC = () => {
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={true}
      message={<DownloadingMessage />}
    />
  );
};

export default DownloadProspects;
