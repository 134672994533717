/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('managerSignup', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/signup/manager-signup.html',
      scope: {
        onSignup: '='
      },
      controller: [
        '$scope',
        'signupService',
        'localCache',
        'cacheKeys',
        function ($scope, signupService, localCache, cacheKeys) {
          var errorMessages = signupService.getErrorMessages();

          $scope.manager = {
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            type: 'manager'
          };

          $scope.emailSignup = function () {
            $scope.emailErrorMessage = null;
            $scope.isSigningUp = true;

            var payload = {
              user: $scope.manager,
              plan: {
                companyName: ''
              }
            };

            signupService
              .emailSignup(payload)
              .then(function (response) {
                localCache.put(cacheKeys.managerOnboardingSlide, 'start');

                $scope.isSigningUp = false;
                $scope.onSignup(response);
              })
              .catch(function (response) {
                $scope.isSigningUp = false;

                if (response.status === 409) {
                  $scope.emailErrorMessage =
                    errorMessages.usernameTakenErrorMessage;
                  return;
                }

                if (signupService.isPrivateBrowsingError(response)) {
                  $scope.emailErrorMessage =
                    errorMessages.privateBrowsingErrorMessage;
                  return;
                }

                $scope.emailErrorMessage = isEmpty(response.data)
                  ? errorMessages.errorMessage
                  : response.data.message.message;
              });
          };
        }
      ]
    };
  });
})();
