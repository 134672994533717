import forEach from 'lodash/forEach';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import filter from 'lodash/filter';
(function (angular) {
  var app = angular.module('knockApp');

  app.factory('calendarDataRepository', [
    function () {
      var self = this;

      self.listings = {};
      self.inactiveListings = {};

      self.properties = {};

      self.teamMembers = [];

      self.visits = [];
      self.appointments = [];
      self.teamVisits = [];
      self.teamAppointments = [];
      self.teamAppointmentRequests = [];

      self.primeTimes = [];
      self.teamPrimeTimes = [];

      self.teamOpenHouses = [];

      self.prospects = [];

      self.colorMap = {};

      self.getRequests = function (prospectId) {
        return filter(self.requests, function (request) {
          return (
            request.prospectId === prospectId && request.status === 'pending'
          );
        });
      };

      self.setRequests = function (requests) {
        self.requests = requests;
      };

      self.getVisit = function (visitId) {
        return (
          find(self.visits, { id: visitId }) ||
          find(self.teamVisits, { id: visitId })
        );
      };

      self.getAppointment = function (appointmentId) {
        return (
          find(self.appointments, { id: appointmentId }) ||
          find(self.teamAppointments, { id: appointmentId })
        );
      };

      self.getAppointmentRequest = function (appointmentId, requestTimeId) {
        return (
          find(self.requests, {
            id: appointmentId,
            request_time_id: requestTimeId
          }) ||
          find(self.teamAppointmentRequests, {
            id: appointmentId,
            request_time_id: requestTimeId
          })
        );
      };

      self.addAppointment = function (appointment) {
        self.appointments.push(appointment);
      };

      self.setVisits = function (visits) {
        self.visits = visits;
      };

      self.setAppointments = function (appointments) {
        self.appointments = appointments;
      };

      self.setTeamVisits = function (teamVisits) {
        self.teamVisits = teamVisits;
      };

      self.setTeamAppointments = function (teamAppointments) {
        self.teamAppointments = teamAppointments;
      };

      self.setTeamAppointmentRequests = function (teamAppointmentRequests) {
        self.teamAppointmentRequests = teamAppointmentRequests;
      };

      self.setTeamPrimeTimes = function (primeTimes) {
        self.teamPrimeTimes = primeTimes;
      };

      self.getTeamPrimeTimes = function () {
        return self.teamPrimeTimes;
      };

      self.setPrimeTimes = function (primeTimes) {
        self.primeTimes = primeTimes || [];
      };

      self.setEvents = function (events) {
        self.events = events || [];
      };

      self.getPrimeTime = function (id) {
        return (
          find(self.primeTimes, { id: id }) ||
          find(self.teamPrimeTimes, { id: id })
        );
      };

      self.getListings = function () {
        return self.listings;
      };

      self.getListing = function (listingId) {
        return self.listings[listingId];
      };

      self.setListings = function (listings) {
        if (isEmpty(self.listings)) {
          self.listings = {};
        }

        merge(self.listings, listings);
      };

      self.getProperties = function () {
        return self.properties;
      };

      self.getProperty = function (propertyId) {
        return self.properties[propertyId];
      };

      self.setProperties = function (properties) {
        if (isEmpty(self.properties)) {
          self.properties = {};
        }

        merge(self.properties, properties);
      };

      self.getTeamMembers = function () {
        return self.teamMembers;
      };

      self.setTeamMembers = function (teamMembers) {
        self.teamMembers = teamMembers;
      };

      self.getColorForManager = function (managerId) {
        var teamMember = find(self.teamMembers, { managerId: managerId });

        return teamMember ? teamMember.color : null;
      };

      self.getProspect = function (prospectId) {
        return find(self.prospects, { id: prospectId });
      };

      self.setProspects = function (prospects) {
        if (!self.prospects || self.prospects.length === 0) {
          self.prospects = prospects;
        } else {
          var existingProspectsById = keyBy(self.prospects, 'id');

          forEach(prospects, function (prospect) {
            if (!(prospect.id in existingProspectsById)) {
              self.prospects.push(prospect);
            }
          });
        }
      };

      self.addProspect = function (prospect) {
        if (!self.getProspect(prospect.id)) {
          self.prospects.push(prospect);
        }
      };

      self.getMappedListing = function (listingId) {
        var listing = self.getListing(listingId);

        if (listing) {
          return {
            id: listing.id,
            leasing: listing.leasing,
            floorplan: listing.floorplan,
            photos: listing.photos,
            location: listing.location,
            coverPhoto: listing.coverPhoto
          };
        }
      };

      return self;
    }
  ]);
})(window.angular);
