import React, { FC } from 'react';
import { isEqual } from 'lodash';
import classNames from 'classnames';

import { MergeOptionType } from './MergeData';
import { PrimaryButton, SecondaryButton } from './MergeButtons';

import './_MergeModal.scss';

interface MergeProspectSelectionProps {
  proposedMaster: MergeOptionType;
  proposedSecondary: MergeOptionType | null;
  mergeOptions: MergeOptionType[] | null;
  setSecondary: (secondary: MergeOptionType | null) => void;
  nextStep: () => void;
  cancel: () => void;
}

const MergeProspectSelection: FC<MergeProspectSelectionProps> = ({
  proposedMaster,
  proposedSecondary,
  mergeOptions,
  setSecondary,
  nextStep,
  cancel
}) => {
  if (!mergeOptions || !mergeOptions.length) {
    return (
      <div className="react-modal-body">
        <div>
          There was a problem finding possible duplicates for this prospect.
        </div>
      </div>
    );
  }

  const { firstName, lastName, email, phoneNumber } = proposedMaster;
  const details =
    email && phoneNumber
      ? `${email}, ${phoneNumber}`
      : `${email}${phoneNumber}`;

  return (
    <React.Fragment>
      <div className="react-modal-body">
        <div className="merge-prospect-selection">
          <div style={{ marginBottom: '20px' }}>
            <div>Which guest card would you like to merge with</div>
            <div>
              <span className="merge-emphasized">
                {firstName} {lastName} ({details})
              </span>
              ?
            </div>
          </div>
          <div className="merge-options-container">
            <span className="merge-options-label">Suggested Duplicates</span>
            {mergeOptions.length && (
              <div className="merge-options-list">
                <table>
                  <tbody>
                    {mergeOptions.map((option: any) => {
                      const isSelected = isEqual(option, proposedSecondary);
                      return (
                        <tr
                          className={classNames('merge-option', {
                            selected: isSelected
                          })}
                          onClick={() =>
                            setSecondary(isSelected ? null : option)
                          }
                          key={option.id}
                        >
                          <td>{option.firstName}</td>
                          <td>{option.lastName}</td>
                          <td>{option.email}</td>
                          <td>{option.phoneNumber}</td>
                          <td>{option.source}</td>
                          <td>
                            <a
                              href={`/conversation/${option.streamId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-external-link" />
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="react-modal-footer">
        <SecondaryButton
          onClick={() => cancel()}
          className="merge-modal-button secondary"
          variant="outlined"
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          onClick={() => nextStep()}
          style={{ marginLeft: '10px' }}
          disabled={!proposedSecondary}
          className="merge-modal-button primary"
          variant="contained"
          color="primary"
        >
          Next
        </PrimaryButton>
      </div>
    </React.Fragment>
  );
};

export default MergeProspectSelection;
