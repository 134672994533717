/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectEventLease', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospectEvents/prospect-event-lease.html',
      scope: {
        event: '=',
        prospect: '=',
        manager: '=',
        property: '='
      },
      controller: [
        '$scope',
        '$moment',
        function ($scope, $moment) {
          // split string to extract only date portion
          if (typeof $scope.event.event_type === 'string') {
            $scope.event.event_time = $moment(
              $scope.event.event_time.split('T')[0]
            ).format('ddd MM/DD/YYYY');
          }
        }
      ]
    };
  });
})(window.angular);
