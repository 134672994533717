import React, { FC } from 'react';
import { withStyles } from '@material-ui/core';
import { Avatar as MuiAvatar, Text } from '@knockrentals/knock-shared-web';
import './_ConnectedProfilesDialog.scss';

const Avatar = withStyles((theme) => ({
  root: {
    fontSize: '20px'
  },
  colorDefault: {
    color: theme.palette.primary.main,
    backgroundColor: `#5A72F614`
  }
}))(MuiAvatar);

interface DialogProfileHeaderProps {
  firstName: string;
  lastName: string;
  connectedProfilesCount: number;
}

export const getConnectedProfileHeading = (count: number): string => {
  const isPlural = count > 1;
  return `${count} connected profile${isPlural ? 's' : ''}`;
};

export const getInitials = (
  firstName: string = '',
  lastName: string = ''
): string => {
  const initials = `${firstName[0] || ''}${lastName[0] || ''}`;
  return initials.toUpperCase();
};

const DialogProfileHeader: FC<DialogProfileHeaderProps> = ({
  firstName = '',
  lastName = '',
  connectedProfilesCount
}) => {
  return (
    <div
      className={'dialog-profile-header'}
      data-testid="dialog-profile-header"
    >
      <Avatar>{getInitials(firstName, lastName)}</Avatar>
      <div className={'dialog-profile-header-text'}>
        <Text className="prospect-name" variant="body1">
          {`${firstName} ${lastName}`}
        </Text>
        <Text className="connected-profile-count" variant="body2">
          {getConnectedProfileHeading(connectedProfilesCount)}
        </Text>
      </div>
    </div>
  );
};

export default DialogProfileHeader;
