// This file configures an injector that will insert the latest git commit as a
// query parameter to cause fetching of all angular templates/html files for
// a new deploy.
// This will prevent issues where the template and controller code are out of
// sync.
//
// Based on a solution by Hieu Pham:
// https://medium.com/angularjs-tricks/prevent-annoying-template-caching-in-angularjs-1-x-b706bf9c4056
//

(function () {
  const GIT_COMMIT = process.env.REACT_APP_GIT_COMMIT || 'NA';
  const angular = window.angular;
  let app = angular.module('knockApp');

  app.factory('cacheInterceptor', () => {
    return {
      request: (config) => {
        if (config.url.indexOf('angular/views') >= 0) {
          // Make sure we can support URLs that already have search params
          const parts = config.url.split('?');
          const searchParams =
            parts.length > 1
              ? new URLSearchParams(parts[1])
              : new URLSearchParams();

          searchParams.append('q', GIT_COMMIT);
          config.url = `${config.url}?${searchParams}`;
        }
        return config;
      }
    };
  });
})();
