import React, { createContext, FC, useContext } from 'react';

type Props = { context: any };

const MarkAsLostDrawerContext = createContext<any>({});

export const MarkAsLostDrawerProvider: FC<Props> = ({ context, children }) => {
  return (
    <MarkAsLostDrawerContext.Provider value={context}>
      {children}
    </MarkAsLostDrawerContext.Provider>
  );
};

export const useMarkAsLostContext = () => useContext(MarkAsLostDrawerContext);
