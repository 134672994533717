/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('prospectLeasedDateModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openLeasedDateModal = function (prospect) {
        return $mdDialog.show({
          controller: 'ProspectLeasedDateModalController',
          templateUrl:
            '/angular/views/prospects/prospect-leased-date-modal.html',
          clickOutsideToClose: false,
          locals: {
            prospect: prospect
          }
        });
      };

      return self;
    }
  ]);

  const ProspectLeasedDateModalController = function (
    $scope,
    $mdDialog,
    prospect,
    $moment
  ) {
    $scope.leasedDate = prospect.leased_date
      ? $moment(prospect.leased_date).format()
      : null;

    $scope.save = function () {
      $mdDialog.hide($scope.leasedDate);
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };
  };

  ProspectLeasedDateModalController.$inject = [
    '$scope',
    '$mdDialog',
    'prospect',
    '$moment'
  ];

  app.controller(
    'ProspectLeasedDateModalController',
    ProspectLeasedDateModalController
  );
})(window.angular);
