(function (angular) {
  var app = angular.module('knockApp');

  app.factory('userService', [
    '$window',
    '$auth',
    '$location',
    '$rootScope',
    'apiBase',
    'cacheKeys',
    'localStorageService',
    function (
      $window,
      $auth,
      $location,
      $rootScope,
      apiBase,
      cacheKeys,
      localStorageService
    ) {
      return {
        logout: function () {
          return apiBase.post('/auth/logout');
        },
        /**
         * Retrieves the logged in user.
         *
         * @returns the logged in user.
         */
        getUser: function () {
          var userPayload = $auth.getPayload();

          if (!userPayload) {
            return;
          }

          userPayload.sub.id = parseInt(userPayload.sub.id, 10);

          return userPayload.sub;
        },
        /**
         * Retrieves the scoped leasing team id if the ?lt parameter is
         * specified or a scoped leasing team id exists in session storage. If
         * the ?lt parameter is specified the scoped leasing team will be stored
         * in session storage. If ?lt is not present, then we also check for #lt
         *
         * @returns the scoped leasing team id or null.
         */
        getScopedLeasingTeamId: function () {
          // check if the ?lt query parameter is specified on the current page
          let queryParams = new URLSearchParams(window.location.search);
          let scopedLeasingTeamId = queryParams.get('lt');

          if (!scopedLeasingTeamId && !!window.location.hash) {
            let hashParams = new URLSearchParams(
              window.location.hash.replace('#', '?')
            );
            scopedLeasingTeamId = hashParams.get('lt');
          }

          if (scopedLeasingTeamId) {
            // if the ?lt query was specified store it in session storage
            window.sessionStorage.scopedLeasingTeamId = scopedLeasingTeamId;
          } else {
            // try to get the leasing team id from session storage
            scopedLeasingTeamId = window.sessionStorage.scopedLeasingTeamId;
          }

          return scopedLeasingTeamId ? scopedLeasingTeamId : null;
        },
        /**
         * Retrieves the logged in user. Replaces the user id with the
         * appropriate id based on the scoped leasing team id.
         *
         * @returns the scoped logged in user.
         */
        getScopedUser: function () {
          const user = this.getUser();
          const scopedLeasingTeamId = this.getScopedLeasingTeamId();

          if (scopedLeasingTeamId && $auth.isAuthenticated()) {
            const altIds = localStorageService.get(cacheKeys.altIds) || {};

            let userId;
            if (Object.values(altIds).includes(user.id)) {
              // as it is possible for another user's altIds to be stored locally, only use the
              // altIds in local storage if they include the logged in user's id
              userId = altIds[scopedLeasingTeamId];
            }

            if (userId) {
              user.id = userId;
            }
          }

          return user;
        },
        getRefreshToken: function (authToken) {
          return apiBase
            .post('/auth/exchange', { token: authToken })
            .success(function (response) {
              if (response.status_code === 'ok') {
                localStorageService.set(
                  cacheKeys.refreshToken,
                  response.refresh_token
                );
                return;
              }
              throw Error('Error fetching new refresh token');
            })
            .error(function (err) {
              console.warn(err);
              $location.path('/login');
            });
        },
        refreshAuthToken: function (refreshToken) {
          return apiBase
            .post('/auth/refresh', { refresh_token: refreshToken })
            .success(function (response, status_code) {
              if (status_code === 200) {
                $auth.setToken(response.access_token);
              } else {
                localStorage.clear();
                window.location.href = '/login';
              }
            })
            .error(function () {
              // Clear localStorage so that invalid tokens don't cause UI issues to persist
              localStorage.clear();
              $location.path('/login');
            });
        },
        /**
         * Exchanges an auth token for new auth and refresh tokens. This
         * function does not reload the current page.
         */
        exchangeToken: function (authToken) {
          return apiBase.post('/auth/exchange', { token: authToken }).then(
            function (response) {
              $auth.setToken(response.data.access_token);
              localStorageService.set(
                cacheKeys.refreshToken,
                response.data.refresh_token
              );
            },
            function () {
              // Clear localStorage so that invalid tokens don't cause UI
              // issues to persist.
              localStorage.clear();
              $location.path('/login');
            }
          );
        },
        /**
         * Exchanges an auth token for new auth and refresh tokens. This
         * function will also reload the page on token exchange.
         */
        exchangeOldToken: function (oldToken) {
          return apiBase.post('/auth/exchange', { token: oldToken }).then(
            function (response) {
              $auth.setToken(response.data.access_token);
              localStorageService.set(
                cacheKeys.refreshToken,
                response.data.refresh_token
              );
              $window.location.reload();
            },
            function () {
              localStorageService.clearAll();
              $window.location.reload();
            }
          );
        },
        getEffectiveAccessToken: function () {
          return apiBase.get('/auth/access-token');
        },
        resetPassword: function (payload) {
          return apiBase.post('/auth/password/reset', payload);
        },
        changePassword: function ({ username, newPassword, oldPassword }) {
          const payload = {
            username,
            new_password: newPassword,
            old_password: oldPassword
          };

          return apiBase.post('/auth/change-password', payload);
        },
        resetForgottenPassword: function (resetId, newPassword) {
          var payload = {
            resetId: resetId,
            newPassword: newPassword
          };

          return apiBase.post('/auth/password/forgot/reset', payload);
        },
        sendForgotPasswordRequest: function (userType, username) {
          var payload = {
            user_type: userType,
            username: username
          };

          return apiBase.post('/auth/password/forgot', payload);
        },
        getSlackAccessToken: function (code, state) {
          var params = {
            code: code,
            state: state
          };

          return apiBase.get('/auth/slack', { params: params });
        },
        setAltIds: function (altIds) {
          localStorageService.set(cacheKeys.altIds, altIds);
        },
        getVoiceAppStatus: function () {
          return apiBase.post('/relay/voice/get-user-status');
        }
      };
    }
  ]);
})(window.angular);
