import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Chip,
  DialogActions,
  Select,
  FormControl,
  Input,
  MenuItem,
  Typography,
  SvgIcon,
  Snackbar,
  IconButton,
  FormHelperText
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ExpandMore, Close } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import dayjs from 'dayjs';

import {
  makeStyles,
  NamedColors,
  Box,
  Button,
  black,
  white,
  ThemeProvider
} from '@knockrentals/knock-shared-web';
import { CircularProgressButton } from '../../CircularProgressButton';
import { colors, useCommonStyles } from '../../commonStyles/commonStyles';
import { isDateInvalid } from 'LegacyAngularApp/legacy-angular-app/utilities/timeUtilities';
import { NewCalendarIcon } from '../../icons';
import {
  BulkAction,
  FollowUpAction
} from '../../../../../app/features/prospects/selectedProspects';
import { ActivityData } from '../../../../../app/services/prospects/entities';
import { calculateItemsToDisplay, sortTextBySize } from './utils';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    padding: '16px 16px 0 16px'
  },

  dialogContent: {
    padding: '0 16px 0 16px'
  },

  formControl: {
    width: '100%',
    marginBottom: 0,

    '& .MuiInputBase-formControl': {
      marginTop: '5px !important'
    },

    '& .MuiInputBase-root': {
      borderBottom: 'none',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      background: white,
      margin: 0,
      padding: 0
    }
  },

  chipContainer: {
    borderBottom: `1px solid ${NamedColors.slate[300]}`,
    marginTop: '20px',
    paddingBottom: '4px',
    paddingLeft: '4px'
  },

  chips: {
    display: 'flex',
    overflow: 'hidden'
  },

  chip: {
    backgroundColor: colors.chipBackground,
    color: colors.defaultText,
    fontSize: '13px',
    marginRight: '8px',

    '&:last-child': {
      marginRight: 0
    },

    '& .MuiChip-labelSmall': {
      paddingLeft: '9px',
      paddingRight: '9px',
      paddingTop: '2px'
    }
  },

  dateContainer: {
    display: 'flex',
    gap: '10px'
  },

  datePicker: {
    width: '50%',
    margin: '0',
    padding: '0',
    maxHeight: '64px',

    '& .MuiInputLabel-formControl': {
      left: '10px',
      top: '21px',
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontWeight: 400,
      color: colors.defaultText
    },

    '& .MuiInputBase-formControl.Mui-error': {
      borderBottom: `2px solid ${colors.error}`,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none'
    },

    '& .MuiInputBase-formControl': {
      borderColor: NamedColors.slate[300],
      background: NamedColors.slate[50],
      height: '56px',
      borderBottom: `1px solid ${NamedColors.slate[300]}`,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none'
    },

    '& .MuiFormLabel-root.Mui-focused': {
      top: '30px',
      left: '7px',
      fontSize: '12px',
      color: colors.secondaryText
    },

    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
      top: '30px',
      left: '7px',
      fontSize: '12px',
      color: colors.secondaryText
    },

    '& .MuiInputBase-input': {
      color: colors.defaultText,
      padding: '23px 0 7px'
    },

    '& .MuiButtonBase-root .MuiSvgIcon-root': {
      color: '#353950'
    },

    '& .MuiIconButton-root': {
      padding: '8px'
    },

    '& .MuiSvgIcon-root': {
      height: '24px',
      width: '24px'
    }
  },

  textField: {
    width: '100%',
    marginTop: '8px',

    '&.MuiFormControl-root': {
      marginBottom: 0
    },

    '& .MuiInputLabel-formControl': {
      top: '23px',
      left: '0px',
      color: black
    },

    '& .MuiFormLabel-root.Mui-focused': {
      color: `${colors.secondaryText} !important`
    },

    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none'
    },

    '& .MuiFilledInput-underline:after': {
      borderBottom: `1px solid ${NamedColors.slate[300]}`
    },

    '& .MuiFilledInput-root': {
      borderColor: NamedColors.slate[300],
      background: NamedColors.slate[50],
      borderBottom: `1px solid ${NamedColors.slate[300]}`,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none'
    },

    '& .MuiInputBase-input': {
      color: colors.defaultText
    }
  },

  helperText: {
    display: 'flex',
    justifyContent: 'flex-end'
  },

  counter: {
    marginRight: '4px'
  },

  dialogActions: {
    alignItems: 'unset',
    gap: '8px',
    padding: '12px 16px 16px 16px'
  },

  cancelButton: {
    border: `1px solid ${NamedColors.denim[600]}`,
    color: NamedColors.denim[600],
    lineHeight: '24px'
  },

  alert: {
    backgroundColor: colors.snackbarBackground
  },

  alertIcon: {
    marginRight: '10px'
  }
}));

const MAX_NOTE_LENGTH = 500;

interface FollowUpModalProps {
  open: boolean;
  closeModal: (refetch?: boolean) => void;
  records: FollowUpAction[];
  saveActivityForProspects: (payload: ActivityData) => Promise<any>;
  timeService: any;
  hideNameChips?: boolean;
}

const FollowUpModal: FC<FollowUpModalProps> = ({
  open,
  closeModal,
  records,
  saveActivityForProspects,
  timeService,
  hideNameChips = false
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [note, setNote] = useState<string>('');
  const [snackMessage, setSnackMessage] = useState<string>('');
  const [recordsToDisplay, setRecordsToDisplay] = useState<string[]>([]);
  const [dateError, setDateError] = useState<string>('');
  const [timeError, setTimeError] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<boolean>(false);

  const initData = useCallback(async () => {
    setFiltered(false);
    if (records.length === 1 && records[0].mode === 'edit') {
      if (records[0].reminderDate && records[0].reminderTime) {
        const reminderDateParts = dayjs(records[0].reminderDate)
          .format('YYYY-MM-DD')
          .split('-');
        const reminderDate = new Date();

        reminderDate.setFullYear(Number(reminderDateParts[0]));
        reminderDate.setMonth(Number(reminderDateParts[1]) - 1);
        reminderDate.setDate(Number(reminderDateParts[2]));

        const timeZone = await timeService.getActiveTimezone();
        const reminderTimeFull = dayjs
          .utc(`${dayjs().format('YYYY-MM-DD')}T${records[0].reminderTime}`)
          .tz(timeZone.id)
          .format();

        // We remove the time zone info from the date string so when it is used to create a Date object, it uses that exact time value and doesn't
        // try to convert to local time, which could be different from the user's preferred time zone
        const timePortionIndex = reminderTimeFull.lastIndexOf('-');
        const reminderTime = new Date(
          reminderTimeFull.substring(0, timePortionIndex)
        );

        setEditMode(true);
        setSelectedDate(reminderDate);
        setSelectedTime(reminderTime);

        if (records[0].note) {
          const noteValue = /Follow up with .*?: ?(.*)/.exec(records[0].note);
          setNote(noteValue && noteValue[1] ? noteValue[1] : '');
        }
      }
    } else {
      const defaultRemindertDate = new Date();
      defaultRemindertDate.setDate(defaultRemindertDate.getDate() + 1);
      defaultRemindertDate.setHours(9, 0, 0);

      setSelectedDate(defaultRemindertDate);
      setSelectedTime(defaultRemindertDate);
      setNote('');
      setEditMode(false);
    }

    setDateError('');
    setTimeError('');
  }, [records, timeService]);

  useEffect(() => {
    if (open && records.length > 0) {
      if (!hideNameChips) {
        setRecordsToDisplay(
          records
            .map((record: BulkAction) => record.name)
            .sort(sortTextBySize('asc'))
        );
      }

      initData();
    }
  }, [open, records, hideNameChips, initData]);

  const getFollowUpDate = async () => {
    const date = selectedDate ?? new Date();
    const time = selectedTime ?? new Date();

    // Here we build the target date and time values for the reminder, regardless of time zone or DST
    const reminderDatePortion = `${date.getFullYear()}-${(
      Number(date.getMonth()) + 1
    )
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    const reminderTimePortion = `${time
      .getHours()
      .toString()
      .padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}:00`;

    // Next we get the user's preferred time zone and use that to build an ISO date string that has the desired date and time for the reminder. The
    // steps are:
    //     - Construct an ISO date string that has the proper time portion with an offset that reflects the moment in time when the user is creating
    //       the reminder record. It should reflect the correct offset based on whether DST is active at the time the user is creating the record.
    //       In order to do that, we use a date portion representing the current calendar day.
    //     - Take the desired date portion for the reminder and append the time portion from the ISO string from above. This now represents when the
    //       reminder should be set, taking DST status into account for when the reminder record is being created, not for the actual day the
    //       reminder is meant to occur.
    const timeZone = await timeService.getActiveTimezone();
    const today = dayjs(
      `${dayjs().format('YYYY-MM-DD')}T${reminderTimePortion}`
    )
      .tz(timeZone.id, true)
      .format();

    return `${reminderDatePortion}T${today.split('T')[1]}`;
  };

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (isDateInvalid(date)) {
      setDateError('Please add a valid follow up date');
      return;
    }

    setSelectedDate(date);
    setDateError('');
  };

  const handleTimeChange = (time: MaterialUiPickersDate) => {
    if (isDateInvalid(time)) {
      setTimeError('Please add a valid follow up time');
      return;
    }

    setSelectedTime(time);
    setTimeError('');
  };

  const handleSubmit = async () => {
    const followUpPayload: ActivityData = {
      prospect_ids: records.map((record: BulkAction) => record.id),
      type: 'reminder',
      message: note,
      reminder_time: await getFollowUpDate()
    };

    setIsLoading(true);

    try {
      const response = await saveActivityForProspects(followUpPayload);

      if (response && response.error) {
        setSnackMessage(
          'We were unable to set the follow up reminder. Please try again.'
        );
      } else {
        if (!editMode) {
          if (
            followUpPayload.prospect_ids &&
            followUpPayload.prospect_ids.length > 1
          ) {
            setSnackMessage(
              `Follow up reminder successfully set for ${followUpPayload.prospect_ids.length} prospects.`
            );
          } else {
            setSnackMessage(
              `Follow up reminder successfully set for ${records[0].name}.`
            );
          }
        }
      }
    } catch (error) {
      setSnackMessage(
        'We were unable to set the follow up reminder. Please try again.'
      );
    } finally {
      handleClose({}, 'submit');
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    handleClose({}, 'cancel');
  };

  const handleClose = (_event: Object, reason: string) => {
    closeModal(reason === 'submit');
  };

  const handleCloseAlert = () => {
    setSnackMessage('');
  };

  const updateChipsToDisplay = () => {
    if (recordsToDisplay.length !== records.length || filtered) {
      return;
    }

    const calculation = calculateItemsToDisplay<string>(
      recordsToDisplay,
      'chip-container'
    );

    if (calculation) {
      const { toDisplay, toHide } = calculation;
      setFiltered(true);
      setRecordsToDisplay([
        ...toDisplay,
        ...(toHide === recordsToDisplay.length
          ? [`${toHide} Prospect${toHide > 1 ? 's' : ''}`]
          : []),
        ...(toHide > 0 && toHide !== recordsToDisplay.length
          ? [`+${toHide}`]
          : [])
      ]);
    }
  };

  return (
    <>
      <Snackbar
        ContentProps={{
          classes: {
            root: classes.alert
          }
        }}
        data-testid="snack-alert-message"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!snackMessage}
        message={snackMessage}
        action={
          <IconButton
            className={classes.alertIcon}
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseAlert}
          >
            <Close />
          </IconButton>
        }
      />

      <Dialog
        aria-labelledby="follow-up-dialog"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle className={classes.dialogTitle}>
          Add a Follow Up Reminder
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {!hideNameChips && (
            <Box className={classes.chipContainer}>
              <FormControl className={classes.formControl}>
                <Typography
                  variant="caption"
                  id="chip-label"
                  variantMapping={{ caption: 'div' }}
                >
                  Prospects
                </Typography>

                <Select
                  IconComponent={() => null}
                  multiple
                  value={recordsToDisplay}
                  input={<Input />}
                  renderValue={(selected: any) => (
                    <div id="chip-container" className={classes.chips}>
                      {selected.map((value: string, index: number) => {
                        if (index + 1 === selected.length) {
                          updateChipsToDisplay();
                        }

                        return (
                          <Chip
                            key={index}
                            label={value}
                            className={classes.chip}
                            size="small"
                          />
                        );
                      })}
                    </div>
                  )}
                  disabled
                >
                  {recordsToDisplay.map((name: string, index: number) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box className={classes.dateContainer}>
              <KeyboardDatePicker
                className={classes.datePicker}
                margin="normal"
                id="follow-up-date-picker"
                label="Date"
                value={selectedDate}
                format="MM/dd/yyyy"
                disablePast={true}
                error={!!dateError}
                helperText={dateError}
                keyboardIcon={
                  <SvgIcon>
                    <NewCalendarIcon />
                  </SvgIcon>
                }
                KeyboardButtonProps={{
                  'aria-label': 'follow up date'
                }}
                onChange={handleDateChange}
                InputLabelProps={{ shrink: true }}
                inputProps={{ 'data-testid': 'follow-up-date' }}
              />

              <KeyboardTimePicker
                className={classes.datePicker}
                margin="normal"
                id="follow-up-time-picker"
                label="Time"
                value={selectedTime}
                error={!!timeError}
                helperText={timeError}
                keyboardIcon={<ExpandMore />}
                onChange={handleTimeChange}
                InputLabelProps={{ shrink: true }}
                inputProps={{ 'data-testid': 'follow-up-time' }}
              />
            </Box>
          </MuiPickersUtilsProvider>

          <TextField
            value={note}
            label="Note"
            className={classes.textField}
            multiline={true}
            inputProps={{ maxLength: 500, 'data-testid': 'note' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setNote(event.target.value)
            }
            error={false}
            variant="filled"
          />

          <FormHelperText className={classes.helperText}>
            {note.length >= 475 && (
              <Typography
                variant="caption"
                className={classes.counter}
                data-testid="note-length-count"
              >
                {note.length} / {MAX_NOTE_LENGTH}
              </Typography>
            )}
          </FormHelperText>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            type="button"
            onClick={handleCancel}
            variant="outlined"
            className={classes.cancelButton}
          >
            Cancel
          </Button>

          <CircularProgressButton
            className={commonClasses.primaryButton}
            disabled={!!dateError || !!timeError}
            onClick={handleSubmit}
            shouldShowProgress={isLoading}
            progressText={'Saving...'}
          >
            Save{hideNameChips ? '' : ` (${records.length})`}
          </CircularProgressButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

// We need a version of the modal component that is wrapped in the knock-shared-web theme provider for use on the guest card, in order to override
// unwanted styling from the legacy theme provider currently in the project. Once the guest card is fully React, this can go away.
const FollowUpModalGuestCard: FC<FollowUpModalProps> = (props) => {
  return (
    <ThemeProvider>
      <FollowUpModal {...props} />
    </ThemeProvider>
  );
};

export default FollowUpModal;
export { FollowUpModalGuestCard };
