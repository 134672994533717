import React from 'react';

export const FlagIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.625 20.375V1.625"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.625 15.7567L4.25333 14.9484C6.45593 14.2703 8.84389 14.5739 10.8067 15.7817C12.721 16.9616 15.0431 17.2828 17.2058 16.6667L19.695 15.955C20.0976 15.84 20.3751 15.472 20.375 15.0534V5.28087C20.3747 4.98679 20.2366 4.70984 20.0018 4.53271C19.7671 4.35558 19.4629 4.29876 19.18 4.3792L17.2058 4.94337C15.0438 5.56091 12.7218 5.24159 10.8067 4.06337C8.84389 2.85558 6.45593 2.55192 4.25333 3.23003L1.625 4.03753"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
