import React, { FC } from 'react';

export const BedIcon: FC = () => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 12.5C5.82843 12.5 6.5 11.8284 6.5 11C6.5 10.1716 5.82843 9.5 5 9.5C4.17157 9.5 3.5 10.1716 3.5 11C3.5 11.8284 4.17157 12.5 5 12.5Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9.5H13.6667C14.0203 9.5 14.3594 9.64048 14.6095 9.89052C14.8595 10.1406 15 10.4797 15 10.8333V12.5H8V9.5Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 12.5H1V15.5H15V12.5Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 15.5V9.5"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4267 3.94001C13.4411 4.00839 13.4403 4.07913 13.4241 4.14714C13.408 4.21515 13.377 4.27874 13.3334 4.33334C13.2869 4.38654 13.2295 4.429 13.165 4.45778C13.1006 4.48656 13.0306 4.50097 12.96 4.50001H10.0667C9.9961 4.50097 9.92614 4.48656 9.86167 4.45778C9.79721 4.429 9.73977 4.38654 9.69336 4.33334C9.64506 4.28103 9.60919 4.21849 9.58841 4.15039C9.56763 4.08229 9.56249 4.01037 9.57336 3.94001L9.94669 0.940008C9.95989 0.818618 10.0176 0.706436 10.1088 0.625161C10.1999 0.543886 10.3179 0.499293 10.44 0.500008H12.56C12.6821 0.499293 12.8002 0.543886 12.8913 0.625161C12.9824 0.706436 13.0402 0.818618 13.0534 0.940008L13.4267 3.94001Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 4.5V7.5"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
