import React, { FC } from 'react';
import { Dialog as MuiDialog, withStyles } from '@material-ui/core';
import { ThemeProvider } from '@knockrentals/knock-shared-web';

import DialogCard from './DialogCard';
import DialogTitle from './DialogTitle';

const Dialog = withStyles(() => ({
  paper: {
    height: '398px',
    minWidth: '624px',
    maxWidth: '624px'
  }
}))(MuiDialog);

interface ConnectedProfilesDialogProps {
  connectedProfiles: any;
  firstName: string;
  isActive: boolean;
  prospectId: number;
  lastName: string;
  setIsActive: (isActive: boolean) => void;
}

const ConnectedProfilesDialog: FC<ConnectedProfilesDialogProps> = ({
  connectedProfiles = [],
  firstName,
  isActive,
  lastName,
  setIsActive
}) => (
  <Dialog fullWidth onClose={() => setIsActive(false)} open={isActive}>
    <DialogTitle
      onClose={() => setIsActive(false)}
      firstName={firstName}
      lastName={lastName}
      connectedProfilesCount={connectedProfiles.length}
    />
    <DialogCard connectedProfiles={connectedProfiles} />
  </Dialog>
);

export default (props: ConnectedProfilesDialogProps) => (
  <ThemeProvider>
    <ConnectedProfilesDialog {...props} />
  </ThemeProvider>
);
