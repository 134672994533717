import React, { ChangeEvent, useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import MaskedInput from 'react-text-mask';

interface PhoneInputProps {
  onChange: (input: ChangeEvent<HTMLInputElement>) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ onChange, ...rest }) => {
  const mask = [
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];

  const [phone, setPhone] = useState('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
    setPhone(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && phone.length === 1) {
      event.preventDefault();
      setPhone('');
    }
  };

  return (
    <MaskedInput
      mask={mask}
      guide={false}
      value={phone}
      onChange={handleInputChange}
      render={(ref, props) => (
        <TextField
          inputRef={ref}
          name="phone"
          label={<Box className={props.classForLabel}>Phone</Box>}
          fullWidth
          margin="normal"
          variant="filled"
          inputProps={{ 'data-testid': 'phone-formatter' }}
          onKeyDown={handleKeyDown}
          {...props}
        />
      )}
      {...rest}
    />
  );
};

export default PhoneInput;
