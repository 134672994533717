/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('notificationMessage', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/conversations/messages/notification-message.html',
      scope: {
        message: '='
      },
      controller: [
        '$scope',
        function ($scope) {
          var pmsUsers = [
            'pms_amsi',
            'pms_entrata',
            'pms_mri',
            'pms_realpage',
            'pms_resman',
            'pms_yardi'
          ];
          var knockUsers = [
            'manager',
            'knockbot',
            'knock',
            'pms_amsi',
            'pms_entrata',
            'pms_mri',
            'pms_realpage',
            'pms_resman',
            'pms_yardi'
          ];

          $scope.isPmsUser = function (sender_type) {
            return pmsUsers.indexOf(sender_type) !== -1;
          };
          $scope.isKnockUser = function (sender_type) {
            return knockUsers.indexOf(sender_type) !== -1;
          };
        }
      ]
    };
  });
})(window.angular);
