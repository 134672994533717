/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  angular.module('knockApp').filter('externalFeed', [
    function () {
      return function (input) {
        var feedMapping = {
          appfolio_multi_family: 'Appfolio feed',
          buildium_community_listing: 'Buildium',
          amsi: 'AMSI Feed',
          'entrata-api': 'Entrata Feed',
          entrata: 'Entrata Feed',
          realpage_gateway: 'RealPage Feed',
          resman: 'Resman Feed',
          yardi: 'Yardi Feed'
        };

        return feedMapping[input];
      };
    }
  ]);
})(window.angular);
