(function (angular) {
  var app = angular.module('knockApp');

  const zendeskService = function (apiBase) {
    var self = this;

    self.show = () => {
      window.zE('messenger:set', 'zIndex', 9999);
    };

    self.hide = () => {
      window.zE('messenger:set', 'zIndex', -9999);
    };

    self.authenticateZendeskUser = function () {
      apiBase
        .get('/manager/zendesk-auth')
        .then((response) => {
          window.zE('messenger', 'loginUser', (callback) => {
            callback(response.data.token);
          });
        })
        .catch((err) => {
          console.log(`Failed to initialize Zendesk user: ${err}`);
        });
    };

    // The Zendesk widget is included outside of the angular application so it
    // will show by default. We need to hide the widget until we've had a chance
    // to initialize the chat tracking service.
    self.hide();

    self._isInitialized = false;

    self.initialize = function () {
      if (self._isInitialized) {
        return;
      }

      self._isInitialized = true;
      self.authenticateZendeskUser();
    };

    return self;
  };

  zendeskService.$inject = ['apiBase'];

  app.factory('zendeskService', zendeskService);
})(window.angular);
