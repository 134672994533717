import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ToDoIcon: React.FC = () => {
  return (
    <SvgIcon fontSize="large">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3854 3.33333H16.7708C17.5187 3.33333 18.125 3.93029 18.125 4.66667V18.6667C18.125 19.403 17.5187 20 16.7708 20H3.22917C2.48128 20 1.875 19.403 1.875 18.6667V4.66667C1.875 3.93029 2.48128 3.33333 3.22917 3.33333H6.61458C6.61458 1.49238 8.13029 0 10 0C11.8697 0 13.3854 1.49238 13.3854 3.33333ZM8.69792 11.9999C8.69792 11.6547 8.97774 11.3749 9.32292 11.3749H14.0625C14.4077 11.3749 14.6875 11.6547 14.6875 11.9999C14.6875 12.3451 14.4077 12.6249 14.0625 12.6249H9.32292C8.97774 12.6249 8.69792 12.3451 8.69792 11.9999ZM8.69792 16C8.69792 15.6548 8.97774 15.375 9.32292 15.375H14.0625C14.4077 15.375 14.6875 15.6548 14.6875 16C14.6875 16.3451 14.4077 16.625 14.0625 16.625H9.32292C8.97774 16.625 8.69792 16.3451 8.69792 16ZM6.61458 11.0417C6.0916 11.0417 5.65104 11.4616 5.65104 12C5.65104 12.5384 6.0916 12.9583 6.61458 12.9583C7.13756 12.9583 7.57812 12.5384 7.57812 12C7.57812 11.4616 7.13756 11.0417 6.61458 11.0417ZM8.69792 8.00008C8.69792 7.6549 8.97774 7.37508 9.32292 7.37508H14.0625C14.4077 7.37508 14.6875 7.6549 14.6875 8.00008C14.6875 8.34526 14.4077 8.62508 14.0625 8.62508H9.32292C8.97774 8.62508 8.69792 8.34526 8.69792 8.00008ZM6.61458 7.04167C6.0916 7.04167 5.65104 7.46163 5.65104 8C5.65104 8.53837 6.0916 8.95833 6.61458 8.95833C7.13756 8.95833 7.57812 8.53837 7.57812 8C7.57812 7.46163 7.13756 7.04167 6.61458 7.04167ZM6.61458 15.0417C6.0916 15.0417 5.65104 15.4616 5.65104 16C5.65104 16.5384 6.0916 16.9583 6.61458 16.9583C7.13756 16.9583 7.57812 16.5384 7.57812 16C7.57812 15.4616 7.13756 15.0417 6.61458 15.0417ZM9.99992 2.04163C9.47694 2.04163 9.03638 2.46159 9.03638 2.99996C9.03638 3.53833 9.47694 3.95829 9.99992 3.95829C10.5229 3.95829 10.9635 3.53833 10.9635 2.99996C10.9635 2.46159 10.5229 2.04163 9.99992 2.04163Z"
      />
    </SvgIcon>
  );
};
