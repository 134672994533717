import React, { createContext, FC, useContext } from 'react';

type Props = { context: any };

const AngularContext = createContext<any>({});

export const AngularContextProvider: FC<Props> = ({ context, children }) => {
  return (
    <AngularContext.Provider value={context}>
      {children}
    </AngularContext.Provider>
  );
};

export const useAngularContext = () => useContext(AngularContext);
