/* eslint-disable */
import unescape from 'lodash/unescape';
(function () {
  let app = angular.module('knockApp');

  app.factory('MessageFormatterService', [
    function () {
      return {
        /**
         * Converts any URL to a friendly URL. EX: https://www.google.com => <a href='https://www.google.com target=_blank>www.google.com</a>.
         * If thread.html has a value, we pull the body of the message in the paragraph tags, and replace the urls.
         * If thread.text has a value, we only replace the urls because there is not a full html string, just the message.
         * This code is also on the backend in api/messaging/services.py, _convert_to_friendly_url(), but is slightly different.
         * @param {string} msg - Email message.
         * @param {boolean} fromHtml - is this from HTML (Bulk) or from message text (Direct Email).
         */
        convertToFriendlyUrl: function (msg, fromHtml = false) {
          const protocolMatchPattern = /https?:?(?:\/\/)?|\?.*/g;
          const anchorTagPattern = '<a(?:\\s[^>]*)?>[^<]*</a>';
          const imageTagPattern = '|<img(?:\\s[^>]*)?>|';
          const emailPattern =
            '|(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])|';
          const protocolPattern =
            '(?<=\\b|\\d_)(?:((?:(?:(?:ht|f)tps?:(?:/{1,3}|[a-z0-9%])|[a-z0-9.-]+\\.';
          const topLevelDomainPattern =
            '(?:a(?:[cd]|e(?:ro)?|[fgil-oqr]|s(?:ia)?|[tuwxz])|b(?:[abd-h]|iz?|[jm-or-tvwyz])|c(?:at?|[cdf-ik-n]|o(?:m|op)?|[rsuvx-z])|d[dejkmoz]|e(?:c|du|[eghr-u])|f[i-kmor]|g(?:[abd-il-n]|ov|[p-uwy])|h[kmnrtu]|i(?:[delm]|n(?:fo|t)?|[oq-t])|j(?:[aem]|o(?:bs)?|p)|k[eg-imnprwyz]|l[a-cikr-vy]|m(?:[ac-egh]|il|[k-n]|o(?:bi)?|[p-t]|u(?:seum)?|[v-z])|n(?:a(?:me)?|c|et?|[fgilopruz])|o(?:m|rg)|p(?:[ae-hk-n]|ost|ro?|[stwy])|qa|r[eosuw]|s[a-eg-or-vx-z]|t(?:[cd]|el|[f-hj-p]|r(?:avel)?|[tvwz])|u[agksyz]|v[aceginu]|w[fs]|xxx|y[etu]|z[amw])';
          const happyPathPattern =
            ")\\b(?!/?@)/?(?:\\?[\\w?&=;+!'()*.~%/-]*)?)|cid:image\\d*\\.\\w+@[a-f0-9]{8}\\.[a-f0-9]{8})";
          const urlRegex = new RegExp(
            `${anchorTagPattern}${imageTagPattern}${emailPattern}${protocolPattern}${topLevelDomainPattern}/)(?:[^\\s()<>{}[\\]]+|\\([^\\s()]*\\([^\\s()]+\\)[^\\s()]*\\)|\\([^\\s()]+\\))+(?:\\([^\\s()]*\\([^\\s()]+\\)[^\\s()]*\\)|\\([^\\s()]+\\)|[^\\s\`!()[\\]{};:\'".,<>?«»“”‘’])|(?<!@)[a-z0-9]+(?:[.-][a-z0-9]+)*\\.${topLevelDomainPattern}${happyPathPattern}`,
            'gi'
          );

          const getFormattedMessageBody = (msgBody) => {
            return msgBody.replace(urlRegex, (match, capture) =>
              capture
                ? `<a href="${capture}" target="_blank">${capture.replace(
                    protocolMatchPattern,
                    ''
                  )}</a>`
                : match
            );
          };

          const replaceFunc = (match, openTag, msgBody, closeTag) => {
            return `${openTag}${getFormattedMessageBody(msgBody)}${closeTag}`;
          };

          const getCleanHtml = (msg) => {
            return msg.replaceAll(
              /(<p>)([^<]*(?:<(?!\/?p[\s>])[^<]*)*)(<\/p>)/g,
              replaceFunc
            );
          };

          //TODO - BT: Refactor out fromHtml once we deprecate message.text. We can get away with only using message.html
          return fromHtml ? getCleanHtml(msg) : getFormattedMessageBody(msg);
        },
        // some messages.text values contain html rather than plain text
        // APIv2 replaces <, >, &, " characters before saving message text to the threads db
        // message_text = escape(payload['text'])
        // in order to display text correctly we need replace them back and strip out all html
        decodeText: function (text) {
          if (!text) return '';
          text = unescape(text)
            .replace(/<style([\s\S]*?)<\/style>/gi, '')
            .replace(/<script([\s\S]*?)<\/script>/gi, '')
            .replace(/<\/div>/gi, '\n')
            .replace(/<\/li>/gi, '\n')
            .replace(/<li>/gi, '  *  ')
            .replace(/<\/ul>/gi, '\n')
            .replace(/<\/p>/gi, '\n')
            .replace(/<br\s*[\/]?>/gi, '\n');
          return text;
        },
        parseMessageHtml: function (msg) {
          msg = msg.replace(/(?:\\[rn])+/g, '');
          if (msg.indexOf('<body') === -1) {
            msg = `<body>${msg}</body>`;
          }
          if (msg.indexOf('<body') !== -1) {
            const pattern = /<body[^>]*>((.|[\n\r])*)<\/body>/im;
            const array_matches = pattern.exec(msg);
            if (array_matches) {
              return array_matches[1];
            } else {
              return (
                '<div><strong>This message may be incorrectly formatted or missing content: </strong></div><br />' +
                msg
              );
            }
          }
          return msg;
        }
      };
    }
  ]);
})();
