/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('activeAttachment', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/active-attachment.html',
      controller: 'ActiveAttachmentController',
      scope: {
        attachment: '=',
        onRemove: '='
      }
    };
  });

  const ActiveAttachmentController = function ($scope) {
    $scope.removeAttachment = function (attachmentToRemove) {
      $scope.onRemove(attachmentToRemove);
    };
  };

  ActiveAttachmentController.$inject = ['$scope'];

  app.controller('ActiveAttachmentController', ActiveAttachmentController);
})(window.angular);
