import React from 'react';

export const CalendarDarkIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 5C1.5 4.17157 2.17157 3.5 3 3.5H15C15.8284 3.5 16.5 4.17157 16.5 5V15C16.5 15.8284 15.8284 16.5 15 16.5H3C2.17157 16.5 1.5 15.8284 1.5 15V5Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 7.5H16.5"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 5V1.5"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 5V1.5"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.75 10C4.61193 10 4.5 10.1119 4.5 10.25C4.5 10.3881 4.61193 10.5 4.75 10.5C4.88807 10.5 5 10.3881 5 10.25C5 10.1119 4.88807 10 4.75 10"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.75 13.5C4.61193 13.5 4.5 13.6119 4.5 13.75C4.5 13.8881 4.61193 14 4.75 14C4.88807 14 5 13.8881 5 13.75C5 13.6119 4.88807 13.5 4.75 13.5"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 10C8.86193 10 8.75 10.1119 8.75 10.25C8.75 10.3881 8.86193 10.5 9 10.5C9.13807 10.5 9.25 10.3881 9.25 10.25C9.25 10.1119 9.13807 10 9 10"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 13.5C8.86193 13.5 8.75 13.6119 8.75 13.75C8.75 13.8881 8.86193 14 9 14C9.13807 14 9.25 13.8881 9.25 13.75C9.25 13.6119 9.13807 13.5 9 13.5"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.25 10C13.1119 10 13 10.1119 13 10.25C13 10.3881 13.1119 10.5 13.25 10.5C13.3881 10.5 13.5 10.3881 13.5 10.25C13.5 10.1119 13.3881 10 13.25 10"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.25 13.5C13.1119 13.5 13 13.6119 13 13.75C13 13.8881 13.1119 14 13.25 14C13.3881 14 13.5 13.8881 13.5 13.75C13.5 13.6119 13.3881 13.5 13.25 13.5"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
