import React from 'react';

export const NotePinIcon = () => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.125 6.63416H16.25C16.9404 6.63416 17.5 7.1938 17.5 7.88416V16.1167C17.4999 16.4479 17.3683 16.7657 17.1342 17L14.1158 20.0183C13.8815 20.2525 13.5638 20.3841 13.2325 20.3842H3.75C3.05964 20.3842 2.5 19.8245 2.5 19.1342V7.88416C2.5 7.1938 3.05964 6.63416 3.75 6.63416H6.875"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 20.3842V16.6342C12.5 15.9438 13.0596 15.3842 13.75 15.3842H17.5"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5.38416C11.0355 5.38416 11.875 4.54469 11.875 3.50916C11.875 2.47362 11.0355 1.63416 10 1.63416C8.96447 1.63416 8.125 2.47362 8.125 3.50916C8.125 4.54469 8.96447 5.38416 10 5.38416Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 9.75916V5.38416"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 12.8842H14.375"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 16.6342H9.375"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
