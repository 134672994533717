/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import remove from 'lodash/remove';
import includes from 'lodash/includes';
import isNull from 'lodash/isNull';
import every from 'lodash/every';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('CommunityEditorController', [
    'api',
    '$rootScope',
    '$scope',
    '$modal',
    '$routeParams',
    '$mdToast',
    '$location',
    'communityApi',
    'companyApi',
    'photoApi',
    'relayApi',
    'appDataService',
    'communityModelService',
    'floorplanModelService',
    'CountryStatesListService',
    'deleteResourceModalFactory',
    function (
      api,
      $rootScope,
      $scope,
      $modal,
      $routeParams,
      $mdToast,
      $location,
      communityApi,
      companyApi,
      photoApi,
      relayApi,
      appDataService,
      communityModelService,
      floorplanModelService,
      CountryStatesListService,
      deleteResourceModalFactory
    ) {
      var self = this;

      $scope.stateSelectionDropdownOpen = false;
      $scope.photoUploadEndpoint = api.host + '/upload/photo';

      $scope.usStates = CountryStatesListService.getUnitedStateStatesArray();
      $scope.canadaStates = CountryStatesListService.getCanadaStateArray();
      $scope.unitTypes = floorplanModelService.getPossibleUnitTypes();
      $scope.communityMailerRegex = /^[a-zA-Z0-9]*$/;

      $scope.isMultiteam = appDataService.isMultiteamUser();

      $scope.isNewNavActive = $rootScope.featureFlags.LMA_NEW_NAVIGATION;

      $scope.upchargeTooltip =
        'When selected, upcharge amount will be added to the base monthly rent and the resulting sum will be the total monthly rent amount displayed on quotes.';

      self._initialize = function () {
        companyApi.getCompanyPreferences().success(function (response) {
          $scope.allowFloorplans =
            response.preferences.allowed_property_type !== 'none';
        });

        if ($routeParams.communityId) {
          $scope.saveSuccess = $routeParams.created === 'true';

          $location.search('created', null);

          communityApi
            .getCommunity($routeParams.communityId)
            .success(function (response) {
              $scope.isEditing = true;
              $scope.community =
                communityModelService.mapStoredCommunityToViewModel(
                  response.community
                );

              const leasing = response.community.leasing;

              if (leasing.application.isDefaultApplicationUrlOverridden) {
                $scope.onlineLeasingProvider = leasing.application.link;
              } else if (leasing.provider === 'On-Site') {
                $scope.onlineLeasingProvider = 'https://www.on-site.com';
              } else if (leasing.provider === 'OneSite/OLL') {
                $scope.onlineLeasingProvider = 'https://leasing.realpage.com';
              } else {
                $scope.onlineLeasingProvider = leasing.application.link;
              }

              if (!$scope.community.videos) {
                $scope.community.videos = [{ url: '', thumb_url: '' }];
              }

              if ($scope.community.social.knock_email) {
                $scope.hasKnockEmail = true;
                $scope.communityMailerRegex = '';
              }
            })
            .error(function () {
              $scope.loadError = 'There was an error loading your community.';
            });
        } else {
          $scope.community = communityModelService.getNewModel();
        }
      };

      $scope.selectState = function ($event, state) {
        $event.preventDefault();
        $scope.community.location.address.state = state;
        $scope.stateSelectionDropdownOpen = false;
      };

      $scope.$watch(
        'community.leasing.terms.leaseLengths',
        function (newLeaseLengths, oldLeaseLengths) {
          if (
            isEmpty(newLeaseLengths) ||
            isEmpty(oldLeaseLengths) ||
            newLeaseLengths === oldLeaseLengths
          ) {
            // prevent infinite iterations
            return;
          }
        },
        true
      );

      $scope.$watch(
        'community.pets.allowed',
        function (newPetsAllowed, oldPetsAllowed) {
          if (
            isEmpty(newPetsAllowed) ||
            isEmpty(oldPetsAllowed) ||
            newPetsAllowed.none !== oldPetsAllowed.none
          ) {
            // prevent infinite iterations
            return;
          }

          var pets = filter(
            $scope.community.pets.allowed,
            function (allowed, pet) {
              return pet !== 'none';
            }
          );
          $scope.community.pets.allowed.none = every(pets, function (allowed) {
            return allowed === false;
          });
        },
        true
      );

      $scope.$watch(
        'community.pets.allowed.large_dogs',
        function (newLargeDogsAllowed) {
          if (!$scope.community) {
            return;
          }

          if (newLargeDogsAllowed) {
            $scope.community.pets.allowed.small_dogs = true;
          }
        }
      );

      $scope.$watch(
        'community.pets.allowed.small_dogs',
        function (newSmallDogsAllowed) {
          if (newSmallDogsAllowed || !$scope.community) {
            return;
          }
          if (
            !newSmallDogsAllowed &&
            $scope.community.pets.allowed.large_dogs
          ) {
            $scope.community.pets.allowed.small_dogs = true;
          }
        }
      );

      $scope.setFormScope = function (formScope) {
        self.formScope = formScope;
      };

      $scope.isKnockEmailAvailable = function () {
        $scope.isCheckingEmailAvailability = true;
        var knockEmail = $scope.community.social.knock_email;

        if (isEmpty(knockEmail)) {
          $scope.knockEmailIsAvailable = false;
          $scope.isCheckingEmailAvailability = false;
          return;
        }

        relayApi
          .getRelayEmail(knockEmail.toLowerCase())
          .success(function (response) {
            $scope.knockEmailIsAvailable = isNull(response.relay);
          })
          .finally(function () {
            $scope.isCheckingEmailAvailability = false;
          });
      };

      $scope.clearSelectedPets = function () {
        $scope.community.pets.allowed.cats = false;
        $scope.community.pets.allowed.small_dogs = false;
        $scope.community.pets.allowed.large_dogs = false;
        $scope.community.pets.allowed.none = true;
      };

      $scope.onAddAmenity = function (amenityTitle, amenityType) {
        var topLevelAmenities = [
          'utilities',
          'parking',
          'laundry',
          'appliances',
          'views',
          'furnishing'
        ];
        if (includes(topLevelAmenities, amenityType)) {
          var amenity = {
            name: amenityTitle
          };
          amenity[amenityType === 'utilities' ? 'included' : 'available'] =
            true;

          if (
            amenityType === 'appliances' ||
            amenityType === 'views' ||
            amenityType === 'furnishing'
          ) {
            $scope.community[amenityType].push(amenity);
          } else {
            $scope.community[amenityType].types.push(amenity);
          }
        } else {
          $scope.community.amenities[amenityType].push({
            name: amenityTitle,
            available: true
          });
        }
      };

      $scope.onAddFeaturedAmenity = function (amenityTitle, amenityType) {
        $scope.community.featured_amenities[amenityType].types.push({
          name: amenityTitle,
          available: true
        });
      };

      $scope.deleteCommunity = function () {
        var title = 'Delete community';
        var text =
          'Are you sure you wish to delete this community and all of its floorplans?';
        var supportMessage =
          'Please remove community: ' + $scope.community.location.name;

        deleteResourceModalFactory
          .confirmDeleteResource(title, text, supportMessage)
          .then(function (password) {
            communityApi.deleteCommunity($scope.community.id, password).then(
              function () {
                $location.url('/manager/listing');
              },
              function () {
                $mdToast.showSimple('Invalid password!');
                $scope.isDeleting = false;
              }
            );
          });
      };

      $scope.addFloorplan = function () {
        $scope.isAddingFloorplan = true;
        $scope.saveCommunity(true);
      };

      $scope.saveCommunity = function (openAddFloorplan) {
        $scope.isSaving = true;

        remove($scope.community.photos, { isLocal: true });

        if ($scope.isEditing) {
          communityApi
            .updateCommunity($scope.community.id, $scope.community)
            .success(function (response) {
              var knockEmail = response.community.social.knock_email;
              $scope.community.social.knock_email = knockEmail; // this one will have the full domain
              $scope.hasKnockEmail = !isEmpty(knockEmail);
              $scope.saveSuccess = true;

              if (openAddFloorplan) {
                self._openAddFloorplan($scope.community.id);
              }
            })
            .finally(function () {
              $scope.isSaving = false;
            });
        } else {
          communityApi
            .createCommunity($scope.community)
            .success(function (response) {
              $scope.community.social.knock_email =
                response.community.social.knock_email;

              if (openAddFloorplan) {
                self._openAddFloorplan(response.community_id);
              } else {
                $location.url(
                  '/manager/community/' +
                    response.community_id +
                    '?created=true'
                );
              }
            });
        }
      };

      self._openAddFloorplan = function (communityId) {
        $location.path('/manager/community/' + communityId + '/addFloorplan');
      };

      if ($location.url().indexOf('create') > -1) {
        appDataService.newUnitsRedirectTo404(self._initialize);
      } else {
        self._initialize();
      }
    }
  ]);
})(window.angular);
