/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('alternativeContactModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      return {
        openModal: function (prospectData, alternativeContactToUpdate) {
          var newAppointmentDialog = {
            controller: 'alternativeContactModalController',
            templateUrl:
              '/angular/views/prospects/alternative-contact-modal.html',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
              prospectData: prospectData || {},
              // Deep-copy so we get our own copy of the phone data:
              alternativeContactToUpdate: JSON.parse(
                JSON.stringify(alternativeContactToUpdate)
              )
            }
          };

          return $mdDialog.show(newAppointmentDialog);
        }
      };
    }
  ]);

  app.controller('alternativeContactModalController', [
    '$q',
    '$scope',
    '$mdToast',
    '$mdDialog',
    '$interpolate',
    '$window',
    'alternativeContactModalFactory',
    'prospectData',
    'alternativeContactToUpdate',
    'prospectsApi',
    function (
      $q,
      $scope,
      $mdToast,
      $mdDialog,
      $interpolate,
      $window,
      alternativeContactModalFactory,
      prospectData,
      alternativeContactToUpdate,
      prospectsApi
    ) {
      var self = this;

      self._initialize = function () {
        $scope.isCreating = !alternativeContactToUpdate;

        $scope.alternativeContact = alternativeContactToUpdate || {
          firstName: '',
          lastName: '',
          email: null,
          phone: {},
          relationshipToPrimary: ''
        };

        $scope.relationshipsToPrimary = [
          { value: 'spouse', display: 'Spouse' },
          { value: 'roommate', display: 'Roommate' },
          { value: 'child', display: 'Child' },
          { value: 'guarantor', display: 'Guarantor' },
          { value: 'other', display: 'Other' }
        ];
      };

      $scope.submitContact = function () {
        $scope.addingAlternativeContact = true;

        var responsePromise;

        if ($scope.isCreating) {
          responsePromise = prospectsApi.createProspectAlternativeContact(
            prospectData.id,
            $scope.alternativeContact.firstName,
            $scope.alternativeContact.lastName,
            $scope.alternativeContact.email,
            $scope.alternativeContact.phone.phone_number,
            $scope.alternativeContact.relationshipToPrimary
          );
        } else {
          responsePromise = prospectsApi.updateProfileAlternativeContact(
            $scope.alternativeContact.id,
            $scope.alternativeContact.firstName,
            $scope.alternativeContact.lastName,
            $scope.alternativeContact.email,
            $scope.alternativeContact.phone.phone_number,
            $scope.alternativeContact.relationshipToPrimary
          );
        }

        responsePromise
          .success(function (response) {
            $scope.addingAlternativeContact = false;
            $mdDialog.hide(response.alternative_contact);
            $mdToast.show(
              $mdToast.simple().content('Secondary contact saved!')
            );
          })
          .error(function () {
            $mdToast.show(
              $mdToast
                .simple()
                .content('There was an error saving the secondary contact.')
            );
          });
      };

      $scope.dismiss = function () {
        $mdDialog.cancel();
      };

      self._initialize();
    }
  ]);
})(window.angular);
