import { api as baseApi } from 'app/services/api';
import { ProspectFilterPayload } from '../../features/prospects/filter';
import {
  mapProspect,
  ProspectData,
  ActivityData,
  OwnerData,
  LostProspectsData,
  ProspectLossReasonData,
  AddProspectData,
  mapLayouts,
  PropertyLayout,
  DownloadProspectsRequest,
  DownloadProspectsResponse,
  ProspectExclusionData,
  DeleteProspectsData,
  IAppointmentStatus,
  ISetVisitShownUnits,
  IAddVisit,
  Prospect
} from './entities';
import { Property } from 'LegacyAngularApp/legacy-angular-app/components/LeasingBinder/models';

export const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProspects: build.query<ProspectData, ProspectFilterPayload>({
      query: (data) => ({
        url: '/me/property-reports/v2/prospects',
        method: 'POST',
        data
      }),
      transformResponse(response: any) {
        const prospects = response.prospect_report.map((prospect: any) =>
          mapProspect(prospect)
        );

        return {
          prospects,
          pageData: {
            count: response.page_data.count,
            page: response.page_data.page,
            rowsPerPage: response.page_data.per_page
          }
        };
      }
    }),
    getPropertyLayouts: build.query<PropertyLayout[], null>({
      query: () => ({
        url: '/properties/layouts',
        method: 'GET'
      }),
      transformResponse(response: any) {
        return mapLayouts(response.layouts);
      }
    }),
    downloadProspects: build.query<
      DownloadProspectsResponse,
      DownloadProspectsRequest
    >({
      query: (data) => ({
        url: '/me/property-reports/prospects/csv',
        method: 'POST',
        data
      })
    }),
    addActivityForProspects: build.mutation<
      ActivityData,
      Partial<ActivityData>
    >({
      query(data) {
        return {
          url: `/prospects/activity`,
          method: 'POST',
          data
        };
      }
    }),
    changeOwner: build.mutation<OwnerData, Partial<OwnerData>>({
      query(data) {
        return {
          url: `/prospects`,
          method: 'PUT',
          data
        };
      }
    }),
    getAllLossReasons: build.query<Array<string>, null>({
      query: (args) => ({
        url: '/prospects/loss_reasons',
        method: 'GET',
        args
      }),
      transformResponse(response: any) {
        return response?.loss_reasons;
      }
    }),
    setStatusAsLostForProspects: build.mutation<
      LostProspectsData,
      Partial<LostProspectsData>
    >({
      query(data) {
        return {
          url: `/prospects`,
          method: 'PUT',
          data
        };
      }
    }),
    setLostReasonForProspects: build.mutation<
      ProspectLossReasonData,
      Partial<ProspectLossReasonData>
    >({
      query(data) {
        return {
          url: `/prospects/loss_reasons`,
          method: 'POST',
          data
        };
      }
    }),
    addProspect: build.mutation<AddProspectData, Partial<AddProspectData>>({
      query(data) {
        return {
          url: `/prospects`,
          method: 'POST',
          data
        };
      }
    }),
    setNotAProspect: build.mutation<any, Partial<ProspectExclusionData>>({
      query(data) {
        return {
          url: `/prospects/excluded-reasons`,
          method: 'PUT',
          data
        };
      }
    }),
    deleteProspects: build.mutation<any, Partial<DeleteProspectsData>>({
      query(data) {
        return {
          url: `/prospects/archive`,
          method: 'POST',
          data
        };
      }
    }),
    nearbyProperties: build.query<Property[], number>({
      query: (props: any) => {
        const params = {
          get_community_data: true,
          get_property_agents: true
        };
        return {
          url: `/property/${props}/nearby-properties`,
          method: 'GET',
          params
        };
      },
      transformResponse(response: any) {
        const propertyAgents = response.property_agents || {};
        return (
          response.properties?.map((property: any) => ({
            ...property.community_data,
            agents: propertyAgents[property.id] || []
          })) || []
        );
      }
    }),
    addVisit: build.mutation<any, IAddVisit>({
      query: (data) => {
        return {
          url: `/visits`,
          method: 'POST',
          data
        };
      },
      transformResponse: (res: any) => {
        return res.visit;
      }
    }),
    setVisitShownUnits: build.mutation<any, ISetVisitShownUnits>({
      query: ({ units, visitId }) => {
        return {
          url: `/visits/${visitId}/shown-units`,
          method: 'POST',
          data: {
            shown_unit_ids: units
          }
        };
      }
    }),
    updateAppointmentStatus: build.mutation<any, IAppointmentStatus>({
      query: ({ status, appointmentId }) => {
        return {
          url: `/appointments/${appointmentId}/status`,
          method: 'PUT',
          data: {
            status
          }
        };
      }
    }),
    updateProspect: build.mutation<any, Prospect>({
      query: (prospect) => {
        return {
          url: '/prospects/' + prospect.id,
          method: 'PUT',
          data: prospect
        };
      }
    })
  })
});
