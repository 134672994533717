import React, { ChangeEvent, FC, useState } from 'react';
import { Checkbox, IconButton, makeStyles } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { ThemeProvider } from '@knockrentals/knock-shared-web';
import BatchSelect from './BatchSelect';
import './_BatchSelectCheckbox.scss';

const useStyles = makeStyles({
  batchSelectCheckbox: {
    '&.PrivateSwitchBase-root-2': {
      padding: 0
    },
    '&.MuiIconButton-colorSecondary:hover': {
      'background-color': 'transparent'
    },
    '&.MuiIconButton-root': {
      height: '18px',
      width: '18px',
      '&:hover': { 'background-color': 'transparent' }
    },
    '& .MuiSvgIcon-root': {
      height: 24,
      width: 24,
      zIndex: 'unset'
    }
  },
  dropDownArrow: {
    '&.MuiIconButton-root': {
      color: 'rgba(0, 0, 0, 0.54)',
      padding: 0
    },
    '&.MuiIconButton-root:hover': {
      'background-color': 'transparent'
    }
  }
});

interface BatchSelectCheckboxProps {
  isChecked: boolean;
  isIndeterminate: boolean;
  onChangeBatchOption(value: unknown): void;
}

export const BatchSelectCheckbox: FC<BatchSelectCheckboxProps> = ({
  isChecked,
  isIndeterminate,
  onChangeBatchOption
}) => {
  const [isSelectVisible, setIsSelectVisible] = useState(false);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    if (!checked || isIndeterminate) {
      onChangeBatchOption('');
      return;
    }

    setIsSelectVisible(true);
  };

  const handleBatchOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeBatchOption(e.target.value);
  };

  const toggleIsVisible = () => {
    setIsSelectVisible((prevState) => !prevState);
  };

  const classes = useStyles();

  return (
    <div className="batch-select-checkbox">
      <Checkbox
        indeterminate={isIndeterminate}
        checked={isChecked}
        className={classes.batchSelectCheckbox}
        disableRipple
        onChange={handleCheckboxChange}
      />

      <IconButton
        className={classes.dropDownArrow}
        disableRipple
        onClick={toggleIsVisible}
      >
        <ArrowDropDown fontSize="large" />
      </IconButton>

      {isSelectVisible && (
        <BatchSelect
          onChange={handleBatchOptionChange}
          onClose={() => setIsSelectVisible(false)}
        />
      )}
    </div>
  );
};

export default (props: BatchSelectCheckboxProps) => (
  <ThemeProvider>
    <BatchSelectCheckbox {...props} />
  </ThemeProvider>
);
