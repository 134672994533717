/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */

import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Todo');

  app.directive('prospectTodoCard', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/todo/prospect-todo-card.html',
      controller: 'ProspectTodoCardController',
      scope: {
        prospects: '=',
        icon: '@?',
        iconText: '@?',
        iconColor: '=?'
      }
    };
  });

  // #uis
  app.controller('ProspectTodoCardController', [
    '$rootScope',
    '$scope',
    'propertyReportsService',
    'streamCarouselService',
    'todoService',
    'uisService',
    function (
      $rootScope,
      $scope,
      propertyReportsService,
      streamCarouselService,
      todoService,
      uisService
    ) {
      $scope.iconColor = $scope.iconColor || 'red';

      $scope.viewProspects = function () {
        propertyReportsService.viewProspectDetails(map($scope.prospects, 'id'));
      };

      $scope.isDemandXDemoEnabled = $rootScope.featureFlags.DEMAND_X_DEMO;

      $scope.openCarousel = function ($event) {
        $event.stopPropagation();

        if ($event.target.textContent === 'View') {
          return;
        }

        streamCarouselService
          .showCarousel(map($scope.prospects, 'stream_id'))
          .then(function () {
            todoService.reloadDashboard();
          });
      };
    }
  ]);
})(window.angular);
