/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('communityManagementPanel', function () {
    return {
      restrict: 'E',
      scope: {
        community: '=',
        onDelete: '=',
        onPost: '='
      },
      controller: 'communityManagementPanelController',
      templateUrl:
        '/angular/views/listing/listingIndex/communityManagementPanel.html'
    };
  });

  app.controller('communityManagementPanelController', [
    '$scope',
    '$location',
    '$modal',
    'managerApi',
    'communityApi',
    'confirmationModalFactory',
    function (
      $scope,
      $location,
      $modal,
      managerApi,
      communityApi,
      confirmationModalFactory
    ) {
      var self = this;

      self._getFloorplans = function () {
        $scope.floorplans = null;

        managerApi
          .getCommunityFloorplans($scope.community.id)
          .success(self._getFloorplansSuccess)
          .error(self._getFloorplansError);
      };

      self._getFloorplansSuccess = function (response) {
        $scope.floorplans = response.floorplans;
      };

      self._getFloorplansError = function () {
        $scope.loadingError =
          'There was an error retrieving floorplans for this community. Please try again later.';
      };

      $scope.addFloorplan = function () {
        $location.url(
          '/manager/community/' + $scope.community.id + '/addFloorplan'
        );
      };

      $scope.editCommunity = function () {
        $location.url('/manager/community/' + $scope.community.id);
      };

      $scope.tryDeleteCommunity = function () {
        var communityName = $scope.community.location.name;

        var modal = confirmationModalFactory.createModal(
          'Delete ' + communityName,
          'Are you sure you wish to delete ' + communityName + '?',
          self._deleteCommunity,
          {}
        );

        $modal.open(modal);
      };

      self._deleteCommunity = function () {
        communityApi
          .deleteCommunity($scope.community.id)
          .success(self._deleteCommunitySuccess)
          .error(self._deleteCommunityError);
      };

      self._deleteCommunitySuccess = function () {
        $scope.onDelete($scope.community);
      };

      self._deleteCommunityError = function () {
        $scope.errorMessage =
          'There was an error deleting your community. Please try again later';
      };

      $scope.reloadData = function () {
        self._getFloorplans();
      };

      $scope.onFloorplanPosted = function () {
        $scope.onPost();
        $scope.reloadData();
      };

      self._getFloorplans();
    }
  ]);
})();
