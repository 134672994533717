/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';
  var app = angular.module('knockApp');

  app.directive('renterActivationContainer', [
    function () {
      return {
        restrict: 'E',
        scope: {
          onActivate: '=?',
          onCancel: '=?'
        },
        templateUrl:
          '/angular/views/listing/listingDetails/renter-activation-container.html',
        controller: 'RenterActivationContainerController'
      };
    }
  ]);

  app.controller('ActivateRenterAccountController', [
    '$scope',
    '$location',
    '$timeout',
    'userService',
    function ($scope, $location, $timeout, userService) {
      var user = userService.getUser();

      $scope.isLoggedIn = !isEmpty(user);
      $scope.needsActivation =
        $scope.isLoggedIn && user.type === 'renter' && !user.is_active;

      var redirect = function () {
        $timeout(function () {
          $location.path('/appointments');
        }, 5000);
      };

      if (!$scope.needsActivation) {
        redirect();
      }

      $scope.onActivate = function () {
        $scope.needsActivation = false;
        redirect();
      };

      $scope.onCancel = function () {
        $scope.needsActivation = false;
        $scope.cancelledActivation = true;
        redirect();
      };
    }
  ]);

  app.controller('RenterActivationContainerController', [
    '$rootScope',
    '$scope',
    '$mdToast',
    'renterApi',
    'apiEvents',
    'ProfileService',
    function (
      $rootScope,
      $scope,
      $mdToast,
      renterApi,
      apiEvents,
      profileService
    ) {
      $scope.showCreateAccountBox = false;

      $scope.targetMoveDate = '';
      $scope.password = {
        new: '',
        confirm: ''
      };

      profileService.getProfile().success(function (response) {
        $scope.targetMoveDate = response.profile.target_move_date || '';
      });

      $scope.dismiss = function () {
        if ($scope.onCancel) {
          $scope.onCancel();
        }
      };

      $scope.activateAccount = function (activateRenterForm) {
        if (activateRenterForm.$invalid) {
          return;
        }

        var password = $scope.password.new;
        var extra = {
          targetMoveDate: $scope.targetMoveDate
        };

        renterApi.activateAccount(password, extra).success(function () {
          $scope.showCreateAccountBox = false;
          $mdToast.showSimple('Activated!');
          $rootScope.$emit(apiEvents.accountActivated);

          if ($scope.onActivate) {
            $scope.onActivate();
          }
        });
      };
    }
  ]);
})(window.angular);
