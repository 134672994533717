/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('propertyApi', [
    'apiBase',
    function (apiBase) {
      return {
        getProperty: function (propertyId, shouldCache) {
          return apiBase.get('/properties/' + propertyId, {
            cache: shouldCache
          });
        },
        getPropertyOwner: function (propertyId) {
          return apiBase.get('/properties/' + propertyId + '/owner');
        },
        getPropertiesById: function (propertyIds) {
          return apiBase.post('/properties', { property_ids: propertyIds });
        },
        getPropertyUnits: function (propertyId) {
          return apiBase.get('/properties/' + propertyId + '/units');
        },
        getPropertyUnitListings: function (propertyId) {
          return apiBase.get('/properties/' + propertyId + '/unit-listings');
        },
        getPropertyFloorplans: function (propertyId) {
          return apiBase.get('/properties/' + propertyId + '/floorplans');
        },
        getMyProperties: function () {
          return apiBase.get('/me/properties');
        },
        getResidents: function (propertyId) {
          return apiBase.get('/properties/' + propertyId + '/residents');
        },
        getMyPropertiesDoorwaySettings: function () {
          return apiBase.get('/properties/doorway-settings');
        },
        updatePropertyDoorwaySettings: function (propertyId, settings) {
          return apiBase.put(
            '/properties/' + propertyId + '/doorway-settings',
            settings
          );
        },
        getPropertyAiConfig: function (propertyId) {
          return apiBase.get(`/properties/${propertyId}/ai-config`);
        },
        addLeasingBrouchre: function (propertyId, prospectId) {
          return apiBase.get(
            `/properties/${propertyId}/leasing-brochure?prospect_id=` +
              prospectId
          );
        },
        getNearbyProperties: function (
          propertyId,
          includePhoneNumbers,
          includePropertyHours,
          includeCustomFees
        ) {
          const params = {
            get_community_data: true
          };

          if (includePhoneNumbers) {
            params.get_forwarding_numbers = true;
          }
          if (includePropertyHours) {
            params.get_office_hours = true;
          }
          if (includeCustomFees) {
            params.get_custom_fees = true;
          }

          return apiBase.get(`/property/${propertyId}/nearby-properties`, {
            params
          });
        },
        getCallIntel: (propertyId) =>
          apiBase.get(`/properties/${propertyId}/call-intel`)
      };
    }
  ]);
})(window.angular);
