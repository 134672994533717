import React, { FC, useState } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@knockrentals/knock-shared-web';
import { createStyles, Theme } from '@material-ui/core/styles';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ProspectForm from './ProspectForm';
import { useAngularContext } from '../../AngularContextProvider';
import { useProspects } from 'app/services/prospects/hooks';
import { colors } from '../../commonStyles/commonStyles';
import { AddProspectData } from 'app/services/prospects/entities';
import { Prospect } from '../../../../../app/services/prospects/entities';

const { snackbarBackground } = colors;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperWidth: {
      width: '600px',
      maxHeight: '625px'
    },
    alert: {
      backgroundColor: snackbarBackground
    },
    alertIcon: {
      marginRight: '10px'
    }
  })
);

interface AddProspectModalProps {
  openAddProspectModal: boolean;
  closeModal: (withEffect?: boolean | undefined) => void;
}

const AddProspectModal: FC<AddProspectModalProps> = ({
  openAddProspectModal,
  closeModal
}) => {
  const { paperWidth, alert, alertIcon } = useStyles();

  const [snackMessage, setSnackMessage] = useState<string | null>(null);
  const {
    managers,
    properties,
    sources,
    openProspectStreamModal,
    openShownUnits
  } = useAngularContext();
  const { addProspect } = useProspects();
  const handleClose = () => {
    closeModal();
  };

  const handleCloseAlert = () => {
    setSnackMessage(null);
  };

  const modifiedProperties = properties?.map((property: any) => {
    return {
      value: property?.Property?.id,
      label: property?.Property?.data?.location?.name,
      preferences: property?.Property?.preferences || {}
    };
  });

  const managersToSelect = managers?.map((manager: any) => ({
    label: `${manager.ManagerInfo.first_name} ${manager.ManagerInfo.last_name}`,
    value: manager.ManagerInfo.manager_id?.toString()
  }));

  const sourcesData = sources?.map((source: any) => ({
    label: source?.source_name,
    value: source?.source_name
  }));

  const openUnits = async (prospect: Prospect, visit: any) => {
    try {
      await openShownUnits(prospect, visit);
      openProspectStreamModal(prospect?.stream_id);
    } catch (err) {
      openProspectStreamModal(prospect?.stream_id);
    }
  };

  return (
    <>
      <Snackbar
        ContentProps={{
          classes: {
            root: alert
          }
        }}
        data-testid="snack-alert-message"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!snackMessage}
        message={snackMessage}
        action={
          <IconButton
            className={alertIcon}
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseAlert}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      <Dialog
        open={openAddProspectModal}
        classes={{ paperWidthSm: paperWidth }}
        onClose={handleClose}
      >
        <DialogTitle>New Prospect</DialogTitle>
        <ProspectForm
          properties={modifiedProperties}
          owners={managersToSelect}
          handleClose={handleClose}
          sources={sourcesData}
          addProspect={(propspectData: AddProspectData) =>
            addProspect(propspectData)
          }
          setSnackMessage={setSnackMessage}
          openProspectStreamModal={openProspectStreamModal}
          openUnits={openUnits}
        />
      </Dialog>
    </>
  );
};

export default AddProspectModal;
