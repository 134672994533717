/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('loginHeader', [
    function () {
      return {
        restrict: 'E',
        templateUrl: '/angular/views/login/login-header.html'
      };
    }
  ]);

  app.directive('loginBody', [
    function () {
      return {
        restrict: 'E',
        templateUrl: '/angular/views/login/login-body.html'
      };
    }
  ]);

  app.directive('loginFooter', [
    function () {
      return {
        restrict: 'E',
        templateUrl: '/angular/views/login/login-footer.html'
      };
    }
  ]);
})();
