import React, { FC } from 'react';
import classnames from 'classnames';

export interface MessageBubbleDialogueTextIconProps {
  className?: string;
  height?: string;
  strokeColor?: string;
  width?: string;
}

export const MessageBubbleDialogueTextIcon: FC<
  MessageBubbleDialogueTextIconProps
> = ({ className, height = '15', strokeColor = '#8D90A5', width = '14' }) => (
  <svg
    className={classnames('message-bubble-dialogue-text-icon', className)}
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.292 4.79A5.115 5.115 0 1 0 2.14 9.225L.638 12.379l3.154-1.502c.58.362 1.228.603 1.904.71M4.01 4.791h4.215M4.01 7.32h1.686"
      stroke={strokeColor}
      strokeWidth="1.124"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M10.266 6.899a3.004 3.004 0 0 1 2.263 4.994l.377 2.172-1.967-1.213a3.015 3.015 0 1 1-.674-5.953h.001Z"
      stroke={strokeColor}
      strokeWidth="1.124"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.176 9.007H9.49M11.176 10.693H9.49"
      stroke={strokeColor}
      strokeWidth="1.124"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
