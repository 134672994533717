/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('confirmedAppointmentFunctions', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/calendar/confirmed-appointment-functions.html',
      scope: {
        appointment: '=',
        hideClose: '=?',
        onClose: '=?'
      },
      controller: [
        '$rootScope',
        '$scope',
        '$modal',
        '$mdDialog',
        'calendarApiService',
        'managerCalendarService',
        'calendarDataRepository',
        'cancelAppointmentModalFactory',
        'transferAppointmentModalFactory',
        'userService',
        'timeService',
        function (
          $rootScope,
          $scope,
          $modal,
          $mdDialog,
          calendarApiService,
          managerCalendarService,
          calendarDataRepository,
          cancelAppointmentModalFactory,
          transferAppointmentModalFactory,
          userService,
          timeService
        ) {
          var self = this;

          $scope.transferPopover = {
            template: 'more-popover.html',
            activeManager: null
          };

          $scope.user = userService.getUser();
          $scope.teamMembers = calendarDataRepository.getTeamMembers();
          $scope.activeManagerId = $scope.appointment.manager_id;
          $scope.transferPopover.activeManager = find($scope.teamMembers, {
            managerId: $scope.activeManagerId
          });

          $scope.formattedStartTime = timeService
            .get($scope.appointment.start_time)
            .format('h:mm a z - ddd, MMM Do');

          $scope.fullName = function (user) {
            return user.firstName + ' ' + user.lastName;
          };

          $scope.close = function () {
            if ($scope.onClose) {
              $scope.onClose();
            }
          };

          $scope.tryCancelAppointment = function () {
            cancelAppointmentModalFactory.createModal(
              $scope.appointment.prospect.profile,
              function (message) {
                calendarApiService.cancelAppointment(
                  $scope.appointment,
                  message
                );
              }
            );
          };

          $scope.$watch(
            'transferPopover.activeManager',
            function (newActiveManager, oldActiveManager) {
              var activeManagerChanged =
                newActiveManager &&
                oldActiveManager &&
                $scope.activeManagerId !== newActiveManager.managerId;
              if (activeManagerChanged) {
                self._tryChangeActiveManager(
                  oldActiveManager,
                  newActiveManager
                );
              }
            }
          );

          self._tryChangeActiveManager = function (
            oldActiveManager,
            newActiveManager
          ) {
            transferAppointmentModalFactory.openTransferAppointmentModal(
              $scope.appointment,
              newActiveManager.managerId
            );
          };
        }
      ]
    };
  });
})(window.angular);
