import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

const OptionLabel: FC = ({ children }) => (
  <Typography className="option-label" color="secondary" variant="subtitle2">
    {children}
  </Typography>
);

export const createOptionLabel = (labelText: string) => (
  <OptionLabel>{labelText}</OptionLabel>
);

export default OptionLabel;
