import React from 'react';

export const CallIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5332 19.6583L13.5432 19.6641C15.3665 20.825 17.7515 20.5634 19.2798 19.035L19.9248 18.39C20.5247 17.7894 20.5247 16.8164 19.9248 16.2158L17.2073 13.5C16.6067 12.9001 15.6338 12.9001 15.0332 13.5C14.7451 13.7884 14.3542 13.9505 13.9465 13.9505C13.5389 13.9505 13.1479 13.7884 12.8598 13.5L8.51151 9.15081C7.91166 8.55021 7.91166 7.57724 8.51151 6.97664C8.79994 6.68856 8.96202 6.29763 8.96202 5.88998C8.96202 5.48232 8.79994 5.09139 8.51151 4.80331L5.79484 2.08331C5.19424 1.48346 4.22128 1.48346 3.62068 2.08331L2.97568 2.72831C1.44758 4.25676 1.18571 6.64132 2.34568 8.46498L2.35234 8.47498C5.33019 12.8832 9.12566 16.6795 13.5332 19.6583Z"
      fill="#5C5C5C"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
