/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('prospectPrintableCommunication', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/prospects/prospect-printable-communication.html',
      controller: 'ProspectPrintableCommunication',
      replace: true,
      scope: {
        messages: '=',
        prospect: '=',
        manager: '='
      }
    };
  });
  app.controller('ProspectPrintableCommunication', [function () {}]);
})(window.angular);
