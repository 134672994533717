import React, { FC } from 'react';
import { Chip } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { makeStyles, Box } from '@knockrentals/knock-shared-web';
import { AttachmentIcon } from './icons';
import { colors } from './commonStyles/commonStyles';
import { Attachment } from '../ts/models';

const useStyles = makeStyles(() => ({
  attachmentBox: {
    display: 'flex',
    gap: '5px',
    marginTop: '10px',
    flexWrap: 'wrap',
    '& .MuiChip-clickable:hover': {
      backgroundColor: colors.chipBackground
    }
  },

  attachmentChip: {
    background: colors.chipBackground,
    borderRadius: '4px',
    color: colors.defaultText,
    fontSize: '13px',
    lineHeight: '18px',
    paddingLeft: '6px',

    '& .MuiChip-clickable:hover': {
      backgroundColor: colors.chipBackground
    }
  }
}));

interface ActiveAttachmentsProps {
  attachments: Attachment[];
  deleteAttachment: (attachment: Attachment) => void;
}

const ActiveAttachments: FC<ActiveAttachmentsProps> = ({
  attachments,
  deleteAttachment
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.attachmentBox}>
      {attachments.map((attachment: Attachment, index: number) => (
        <Chip
          icon={<AttachmentIcon useNewDesign={true} />}
          key={index}
          label={attachment.filename}
          deleteIcon={<Close />}
          onClick={() => window.open(attachment.url, '_blank')}
          onDelete={() => deleteAttachment(attachment)}
          className={classes.attachmentChip}
        />
      ))}
    </Box>
  );
};

export default ActiveAttachments;
