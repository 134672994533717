import React, { FC } from 'react';

import SuccessIcon from './SuccessIcon';
import { Text } from '@knockrentals/knock-shared-web';

const FeedbackSubmitted: FC = () => {
  return (
    <div className="unsubscribe-content">
      <div className="icon-container">
        <SuccessIcon />
      </div>
      <Text variant="h4">Thank you for your feedback!</Text>
      <Text variant="subtitle1" color="secondary">
        We hope to see you back soon.
      </Text>
    </div>
  );
};

export default FeedbackSubmitted;
