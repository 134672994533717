/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular, $) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('slideOutNav', [
    function () {
      return {
        restrict: 'E',
        templateUrl: '/angular/views/navigation/slide-out-nav.html',
        transclude: true,
        scope: {
          navSide: '=',
          isActive: '='
        },
        link: function (scope, element) {
          $(element)
            .find('a')
            .click(function () {
              scope.close();
            });
        },
        controller: [
          '$scope',
          function ($scope) {
            $scope.close = function () {
              $scope.isActive = false;
            };
          }
        ]
      };
    }
  ]);
})(window.angular, window.jQuery);
