/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('floorplanModelService', [
    'DebugInfoService',
    function (debugInfoService) {
      var FloorplanModel = function () {
        this.debug = debugInfoService.getDebugInfoObject();
        this.name = '';
        this.bedrooms = 1;
        this.bathrooms = 1;
        this.size = '';
        this.unitType = 'apartment';
        this.description = '';
        this.photos = [];
      };

      var FloorplanModelService = {};

      FloorplanModelService.getNewModel = function () {
        return new FloorplanModel();
      };

      FloorplanModelService.getPossibleUnitTypes = function () {
        return ['house', 'condo', 'townhouse', 'apartment'];
      };

      return FloorplanModelService;
    }
  ]);
})();
