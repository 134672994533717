import React, { FC } from 'react';
import classnames from 'classnames';
import { Theme } from '@material-ui/core';

import {
  Button,
  makeStyles,
  NamedColors,
  ThemeProvider
} from '@knockrentals/knock-shared-web';
import { PhoneActionIcon } from '../icons/PhoneActionIcon';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: NamedColors.slate[800],

    '& svg': {
      marginRight: '8px'
    },

    '&:focus': {
      color: NamedColors.slate[800],

      '& svg path': {
        stroke: NamedColors.slate[800]
      }
    }
  },

  disabled: {
    '& svg path': {
      stroke: theme.palette.action.disabled
    }
  }
}));

export interface SMSOptInButtonProps {
  disable: boolean;
  onClick: () => void;
}

export const SMSOptInButton: FC<SMSOptInButtonProps> = ({
  disable,
  onClick
}) => {
  const classes = useStyles();

  const handleClick = () => {
    if (!disable) {
      onClick();
    }
  };

  return (
    <ThemeProvider>
      <Button
        variant="text"
        className={classnames(classes.button, { [classes.disabled]: disable })}
        onClick={handleClick}
        disabled={disable}
      >
        <PhoneActionIcon /> SMS Opt In
      </Button>
    </ThemeProvider>
  );
};
