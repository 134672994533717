import React from 'react';

export const PhoneIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0267 15.9267L11.0347 15.9313C12.4933 16.86 14.4013 16.6508 15.624 15.428L16.14 14.912C16.6199 14.4315 16.6199 13.6532 16.14 13.1727L13.966 11C13.4855 10.5201 12.7071 10.5201 12.2267 11V11C11.9962 11.2308 11.6835 11.3604 11.3573 11.3604C11.0312 11.3604 10.7185 11.2308 10.488 11L7.00933 7.52068C6.52945 7.0402 6.52945 6.26183 7.00933 5.78135V5.78135C7.24008 5.55089 7.36973 5.23814 7.36973 4.91202C7.36973 4.58589 7.24008 4.27315 7.00933 4.04268L4.836 1.86668C4.35552 1.38681 3.57714 1.38681 3.09666 1.86668L2.58066 2.38268C1.35819 3.60545 1.14869 5.51309 2.07666 6.97202L2.082 6.98002C4.46427 10.5066 7.50065 13.5436 11.0267 15.9267V15.9267Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
