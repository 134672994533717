/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('toggle', [
    function () {
      return {
        restrict: 'E',
        scope: {
          isOn: '='
        },
        templateUrl: '/angular/views/directives/toggle.html',
        link: function (scope) {
          scope.toggle = scope.isOn ? 'On' : 'Off';

          scope.$watch('isOn', function (newValue) {
            scope.toggle = newValue ? 'On' : 'Off';
          });

          scope.$watch('toggle', function (newValue) {
            scope.isOn = newValue === 'On';
          });
        }
      };
    }
  ]);
})();
