/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.directive('userInteractionsAppointment', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/userInteractions/user-interactions-appointment.html',
      controller: 'UserInteractionsAppointmentController',
      scope: {
        appointment: '=',
        agent: '='
      }
    };
  });

  app.controller('UserInteractionsAppointmentController', [
    '$scope',
    '$q',
    'userInteractionsService',
    'conversationsService',
    function ($scope, $q, userInteractionsService, conversationsService) {
      var self = this;

      $scope.data = {
        isLoaded: false,
        listing: null
      };

      $scope.openAppointment = function () {
        conversationsService.openThreadById($scope.appointment.stream_id);
      };

      self._initialize = function () {
        var initPromises = [self._getListing()];

        $q.all(initPromises).finally(self._loadFinished);
      };

      self._loadFinished = function () {
        $scope.data.isLoaded = true;
      };

      self._getListing = function () {
        var listingId = $scope.appointment.listing_id;

        userInteractionsService
          .getListing(listingId)
          .success(self._getListingSuccess);
      };

      self._getListingSuccess = function (response) {
        $scope.data.listing = response.listing;
      };

      self._initialize();
    }
  ]);
})(window.angular);
