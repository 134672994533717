import React, { useState, useEffect } from 'react';
import AppointmentScheduleDialog from './AppointmentScheduleDialog';
import { useNearbyCommunities } from '../useNearbyCommunities';

const AppointmentScheduleDialogContainer = () => {
  const {
    scheduleAppointment,
    isSchedulingAppointment,
    isAppointmentSchedulingDialogOpen,
    availableTimes,
    getAvailableTimes,
    tourType,
    setTourType,
    tourTypesEnabled,
    getTourTypesEnabled,
    getPropertyName,
    referralTargetPropertyId,
    cancelAppointmentFlow
  } = useNearbyCommunities();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [startTime, setStartTime] = useState<string | null>(null);

  const [inPersonToursEnabled, setInPersonToursEnabled] =
    useState<boolean>(false);
  const [selfGuidedToursEnabled, setSelfGuidedToursEnabled] =
    useState<boolean>(false);
  const [liveVideoToursEnabled, setLiveVideoToursEnabled] =
    useState<boolean>(false);

  const fetchTourTypesEnabled = () => {
    if (referralTargetPropertyId) {
      setIsLoading(true);
      getTourTypesEnabled(referralTargetPropertyId).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const fetchAvailableTimes = () => {
    if (isAppointmentSchedulingDialogOpen) {
      setIsLoading(true);
      getAvailableTimes(tourType).finally(() => {
        setIsLoading(false);
      });
    }
  };

  useEffect(fetchAvailableTimes, [tourType, isAppointmentSchedulingDialogOpen]);
  useEffect(fetchTourTypesEnabled, [referralTargetPropertyId]);
  useEffect(() => {
    if (tourTypesEnabled) {
      setInPersonToursEnabled(tourTypesEnabled.inPersonToursEnabled);
      setSelfGuidedToursEnabled(tourTypesEnabled.selfGuidedToursEnabled);
      setLiveVideoToursEnabled(tourTypesEnabled.liveVideoToursEnabled);
    }
  }, [tourTypesEnabled]);

  if (!availableTimes || !tourType) {
    return null;
  }

  const propertyName = referralTargetPropertyId
    ? getPropertyName(referralTargetPropertyId)
    : null;

  return (
    <AppointmentScheduleDialog
      availableTimes={availableTimes}
      isOpen={isAppointmentSchedulingDialogOpen}
      isLoading={isLoading}
      isSchedulingAppointment={isSchedulingAppointment}
      inPersonToursEnabled={inPersonToursEnabled}
      selfGuidedToursEnabled={selfGuidedToursEnabled}
      liveVideoToursEnabled={liveVideoToursEnabled}
      propertyName={propertyName}
      startTime={startTime}
      setStartTime={setStartTime}
      tourType={tourType}
      setTourType={setTourType}
      scheduleAppointment={() => scheduleAppointment(startTime)}
      cancel={() => cancelAppointmentFlow()}
    />
  );
};

export default AppointmentScheduleDialogContainer;
