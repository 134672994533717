import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import classnames from 'classnames';
import dayjs from 'dayjs';

import {
  Box,
  Button,
  makeStyles,
  NamedColors,
  Text
} from '@knockrentals/knock-shared-web';
import {
  ProspectFilter,
  FilterOption,
  SelectedFilterOption,
  statusFilters,
  createdFilters,
  bedroomFilters,
  contactTypeFilters,
  referredByFilters,
  waitlistFilters,
  setFilters,
  getDefaultStatusFilters,
  initialProspectFilter,
  FloorPlanFilterOption,
  prospectRecordsFilters,
  toDoSortingFilters
} from '../../../../../app/features/prospects/filter';
import { usePropertyLayouts } from 'app/services/prospects/hooks';
import { useCommonStyles, colors } from '../../commonStyles/commonStyles';
import { useAppDispatch } from '../../../../../app/hooks';
import { useAngularContext } from '../../AngularContextProvider';
import { store } from '../../../../../app/store';
import SelectDateRange, {
  DateRangeParameters,
  DateRangeFilterOption
} from '../../molecules/SelectDateRange/SelectDateRange';
import { invalidDateRange } from '../../../utilities/timeUtilities';
import type { Layout, PropertyLayout } from 'app/services/prospects/entities';
import { resetSelectedProspects } from 'app/features/prospects/selectedProspects';

const useStyles = makeStyles(() => ({
  header: {
    borderBottom: `1px solid ${NamedColors.slate[200]}`,
    boxShadow: '0px 4px 16px -2px #18274B05, 0px 2px 12px -4px #18274B0A',
    padding: '16px 24px 16px 24px'
  },

  filtersContainer: {
    backgroundColor: NamedColors.slate[50],
    flexGrow: 2,
    overflowY: 'auto',
    padding: '24px'
  },

  sectionSummary: {
    '&.Mui-expanded': {
      minHeight: '48px'
    },

    '& .MuiAccordionSummary-content': {
      marginBottom: 0,
      marginTop: 0
    }
  },

  sectionHeader: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'space-between'
  },

  sectionDetails: {
    paddingTop: 0
  },

  selectedValue: {
    width: '240px',
    overflow: 'hidden',
    textAlign: 'right',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  chip: {
    backgroundColor: 'white',
    border: `1px solid ${NamedColors.slate[200]}`,
    fontSize: '13px',
    marginRight: '8px',
    marginTop: '8px',

    '&.MuiChip-clickable:hover': {
      backgroundColor: NamedColors.denim[100]
    },

    '&.MuiChip-clickable:focus': {
      backgroundColor: 'white'
    },

    '& .MuiChip-label': {
      paddingLeft: '10px',
      paddingRight: '10px'
    },

    '&:last-child': {
      marginRight: 0
    }
  },

  selectedChip: {
    backgroundColor: NamedColors.denim[100],
    border: `1px solid ${NamedColors.denim[600]}`,

    '&.MuiChip-clickable:hover, &.MuiChip-clickable:focus': {
      backgroundColor: NamedColors.denim[100]
    }
  },

  sectionLabel: {
    width: '60%'
  },

  floorPlanTitle: {
    color: colors.disabled,
    textAlign: 'left',
    marginBottom: '8px',
    textTransform: 'uppercase'
  },

  floorPlanContainer: {
    marginBottom: '24px',

    '&:last-child': {
      marginBottom: 0
    }
  },

  footer: {
    borderTop: `1px solid ${NamedColors.slate[200]}`,
    boxShadow: '0 -4px 16px -2px #18274B05, 0 -2px 12px -4px #18274B0A',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px 16px 24px'
  }
}));

interface ProspectFiltersProps {
  onApply: () => void;
  onCancel: () => void;
  className?: string;
}

const ProspectFilters: FC<ProspectFiltersProps> = ({
  onApply,
  onCancel,
  className
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [selectedStatuses, setSelectedStatuses] =
    useState<SelectedFilterOption>({});
  const [selectedCreatedAfter, setSelectedCreatedAfter] = useState<Date | null>(
    null
  );
  const [selectedCreatedBefore, setSelectedCreatedBefore] =
    useState<Date | null>(null);
  const [selectedCreatedRange, setSelectedCreatedRange] = useState<string>('');
  const [selectedProperties, setSelectedProperties] =
    useState<SelectedFilterOption>({});
  const [selectedManagers, setSelectedManagers] =
    useState<SelectedFilterOption>({});
  const [selectedContactTypes, setSelectedContactTypes] =
    useState<SelectedFilterOption>({});
  const [selectedReferredBy, setSelectedReferredBy] =
    useState<SelectedFilterOption>({});
  const [selectedBedrooms, setSelectedBedrooms] =
    useState<SelectedFilterOption>({});
  const [selectedFloorplans, setSelectedFloorplans] =
    useState<SelectedFilterOption>({});
  const [selectedToDo, setSelectedToDo] = useState<SelectedFilterOption>({});
  const [selectedTodoStatus, setSelectedTodoStatus] =
    useState<SelectedFilterOption>({});
  const [selectedIsWaitlist, setSelectedIsWaitlist] =
    useState<SelectedFilterOption>({});
  const [selectedProspectRecords, setSelectedProspectRecords] =
    useState<SelectedFilterOption>({});
  const [filtersLoaded, setFiltersLoaded] = useState<boolean>(false);
  const [floorplanFilters, setFloorplanFilters] = useState<
    FloorPlanFilterOption[]
  >([]);

  const currentFilters = store.getState().prospectFilter;
  const {
    propertyFilters,
    managerFilters,
    referralsEnabled,
    properties,
    removeQueryParamFilters
  } = useAngularContext();
  const { layouts, loadingLayouts } = usePropertyLayouts();
  const dispatch = useAppDispatch();

  const sections = [
    'Status',
    'Creation Date',
    'To Do',
    'Bedrooms',
    ...(floorplanFilters.length > 0 ? ['Floorplan'] : []), // Conditionally add 'Floorplan'
    'Owner',
    'Referred',
    'Contact',
    'Prospect Records',
    'Waitlist',
    'Property'
  ];

  // Only show the Referred By section if referrals (cross-sell) are enabled
  if (!referralsEnabled) {
    const referredIndex = sections.findIndex(
      (section) => section === 'Referred'
    );
    if (referredIndex > -1) {
      sections.splice(referredIndex, 1);
    }
  }

  const setDefaultFilters = useCallback((filters: ProspectFilter) => {
    setSelectedStatuses(filters.statuses);
    setSelectedCreatedAfter(dayjs(filters.createdAfter).toDate());
    setSelectedCreatedBefore(dayjs(filters.createdBefore).toDate());
    setSelectedCreatedRange(filters.createdRange);
    setSelectedProperties(filters.properties);
    setSelectedManagers(filters.managers);
    setSelectedContactTypes(filters.contactTypes);
    setSelectedReferredBy(filters.referredBy);
    setSelectedBedrooms(filters.bedrooms);
    setSelectedFloorplans(filters.floorplans);
    setSelectedToDo(filters.toDo);
    setSelectedIsWaitlist(filters.isWaitlist);
    setSelectedProspectRecords(filters.prospectRecords);
    setSelectedTodoStatus(filters.todoStatus ? filters.todoStatus : {});
  }, []);

  useEffect(() => {
    if (layouts.length > 0) {
      let index = 0;
      const floorPlanOptions: FloorPlanFilterOption[] = layouts.map(
        (l: PropertyLayout) => {
          const propertyName = properties.find(
            (p: any) => p.Property.id === l.propertyId
          ).Property.data.location.name;
          return {
            title: propertyName,
            options: l.layouts.map((layout: Layout) => {
              const option = {
                id: layout.id,
                value: layout.id,
                label: layout.name || 'N/A',
                index
              };
              index++;
              return option;
            })
          };
        }
      );

      setFloorplanFilters(
        floorPlanOptions.sort(
          (
            { title: titleA }: FloorPlanFilterOption,
            { title: titleB }: FloorPlanFilterOption
          ) => titleA.toUpperCase().localeCompare(titleB.toUpperCase())
        )
      );
    }
  }, [loadingLayouts, layouts, properties]);

  useEffect(() => {
    if (!filtersLoaded) {
      setDefaultFilters(currentFilters);
      setFiltersLoaded(true);
    }
  }, [currentFilters, filtersLoaded, setDefaultFilters]);

  const getNewFilters = (
    filters: SelectedFilterOption,
    option: FilterOption
  ) => {
    const newFilters = { ...filters };

    if (filters[option.value]) {
      delete newFilters[option.value];
    } else {
      newFilters[option.value] = option;
    }

    return newFilters;
  };

  const handleFilterChange = (section: string, option: FilterOption) => {
    switch (section) {
      case 'Status':
        let newStatuses = { ...selectedStatuses };

        if (option.value === 'all-active') {
          if (selectedStatuses[option.value]) {
            newStatuses = {};
          } else {
            newStatuses = getDefaultStatusFilters();
          }
        } else {
          if (selectedStatuses['all-active']) {
            delete newStatuses['all-active'];
          }

          if (selectedStatuses[option.value]) {
            delete newStatuses[option.value];
          } else {
            newStatuses[option.value] = option;
          }
        }

        setSelectedStatuses(newStatuses);
        break;
      case 'Property':
        setSelectedProperties(getNewFilters(selectedProperties, option));
        break;
      case 'Owner':
        setSelectedManagers(getNewFilters(selectedManagers, option));
        break;
      case 'Contact':
        setSelectedContactTypes(getNewFilters(selectedContactTypes, option));
        break;
      case 'Referred':
        setSelectedReferredBy(getNewFilters(selectedReferredBy, option));
        break;
      case 'Bedrooms':
        setSelectedBedrooms(getNewFilters(selectedBedrooms, option));
        break;
      case 'Floorplan':
        setSelectedFloorplans(getNewFilters(selectedFloorplans, option));
        break;
      case 'To Do':
        setSelectedTodoStatus(getNewFilters(selectedTodoStatus, option));
        break;
      case 'Prospect Records':
        setSelectedProspectRecords(
          getNewFilters(selectedProspectRecords, option)
        );
        break;
      case 'Waitlist':
        setSelectedIsWaitlist(getNewFilters(selectedIsWaitlist, option));
        break;
    }
  };

  const handleSelectDateRange = (data: DateRangeParameters) => {
    if (data.range !== selectedCreatedRange) {
      const now = new Date();

      switch (data.range) {
        case '7day':
          setSelectedCreatedAfter(dayjs().subtract(7, 'day').toDate());
          break;
        case '2week':
          setSelectedCreatedAfter(dayjs().subtract(14, 'day').toDate());
          break;
        case '1month':
          setSelectedCreatedAfter(dayjs().subtract(1, 'month').toDate());
          break;
      }

      setSelectedCreatedBefore(now);
      setSelectedCreatedRange(data.range);
    } else {
      setSelectedCreatedAfter(data.startDate);
      setSelectedCreatedBefore(data.endDate);
      setSelectedCreatedRange('');
    }
  };

  const handleReset = () => {
    setDefaultFilters(initialProspectFilter);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleApply = () => {
    removeQueryParamFilters();
    const newFilters = {
      statuses: selectedStatuses,
      createdAfter: '',
      createdBefore: '',
      createdRange: selectedCreatedRange,
      properties: selectedProperties,
      managers: selectedManagers,
      contactTypes: selectedContactTypes,
      referredBy: selectedReferredBy,
      bedrooms: selectedBedrooms,
      floorplans: selectedFloorplans,
      toDo: selectedToDo,
      prospectRecords: selectedProspectRecords,
      isWaitlist: selectedIsWaitlist,
      todoStatus: selectedTodoStatus
    };

    if (selectedCreatedAfter && selectedCreatedBefore) {
      newFilters.createdAfter = dayjs(selectedCreatedAfter).format();
      newFilters.createdBefore = dayjs(selectedCreatedBefore).format();
    }

    dispatch(setFilters(newFilters));
    dispatch(resetSelectedProspects());
    onApply();
  };

  const getFilterOptions = (section: string) => {
    switch (section) {
      case 'Status':
        return statusFilters;
      case 'Creation Date':
        return createdFilters;
      case 'Property':
        return propertyFilters;
      case 'Owner':
        return managerFilters;
      case 'Contact':
        return contactTypeFilters;
      case 'Referred':
        return referredByFilters;
      case 'Bedrooms':
        return bedroomFilters;
      case 'Floorplan':
        return floorplanFilters;
      case 'To Do':
        return toDoSortingFilters;
      case 'Prospect Records':
        return prospectRecordsFilters;
      case 'Waitlist':
        return waitlistFilters;
      default:
        return [];
    }
  };

  const getSelectedValueLabel = (section: string, index: number): string => {
    let currentSelections: SelectedFilterOption = {};
    let selectedValue = '';

    switch (section) {
      case 'Status':
        currentSelections = selectedStatuses;
        break;
      case 'Creation Date':
        currentSelections = getFilterLabelObject(selectedCreatedRange, index);
        break;
      case 'Property':
        currentSelections = selectedProperties;
        break;
      case 'Owner':
        currentSelections = selectedManagers;
        break;
      case 'Contact':
        currentSelections = selectedContactTypes;
        break;
      case 'Referred':
        currentSelections = selectedReferredBy;
        break;
      case 'Floorplan':
        currentSelections = selectedFloorplans;
        break;
      case 'Bedrooms':
        currentSelections = selectedBedrooms;
        break;
      case 'To Do':
        currentSelections = selectedTodoStatus;
        break;
      case 'Prospect Records':
        currentSelections = selectedProspectRecords;
        break;
      case 'Waitlist':
        currentSelections = selectedIsWaitlist;
        break;
    }
    const keys = Object.keys(currentSelections);

    if (keys.length > 0) {
      const selectedList = Object.entries(currentSelections).sort(
        ([, lValue], [, rValue]) => lValue.index - rValue.index
      );

      if (selectedList.length > 0) {
        selectedValue = selectedList[0][1].label;

        if (keys.length > 1 && selectedValue !== 'All Active') {
          selectedValue = `${selectedValue} +${keys.length - 1}`;
        }
      }
    }
    return selectedValue;
  };

  const isFilterOptionSelected = (section: string, value: string) => {
    switch (section) {
      case 'Status':
        return selectedStatuses[value];
      case 'Property':
        return selectedProperties[value];
      case 'Owner':
        return selectedManagers[value];
      case 'Contact':
        return selectedContactTypes[value];
      case 'Referred':
        return selectedReferredBy[value];
      case 'Bedrooms':
        return selectedBedrooms[value];
      case 'Floorplan':
        return selectedFloorplans[value];
      case 'To Do':
        return selectedTodoStatus[value];
      case 'Prospect Records':
        return selectedProspectRecords[value];
      case 'Waitlist':
        return selectedIsWaitlist[value];
      default:
        return false;
    }
  };

  const isApplyDisabled = invalidDateRange(
    selectedCreatedAfter,
    selectedCreatedBefore
  );

  const getFilterLabelObject = (option: string, index: number) => {
    let label = '';

    const selectedOption = createdFilters.find(
      (item: DateRangeFilterOption) => item.value === option
    );

    if (selectedOption) {
      label = selectedOption.label;
    }

    return {
      option: {
        label,
        value: option,
        index
      }
    };
  };

  return (
    <Box className={className ? className : undefined}>
      <Text variant="h6" className={classes.header}>
        Filters
      </Text>

      <Box className={classes.filtersContainer}>
        {sections.map((section: string, index: number) => {
          const filterOptions = getFilterOptions(section);
          let selectedValueLabel: string;
          let showFloorPlanTitle = false;
          const sectionId = section.toLowerCase().split(' ').join('-');
          selectedValueLabel = getSelectedValueLabel(section, index);
          if (section === 'Floorplan') {
            showFloorPlanTitle = filterOptions.length > 1;
          }

          return (
            <Accordion
              key={index}
              TransitionProps={{ mountOnEnter: true }}
              defaultExpanded={index < 2}
              id={section.toLocaleLowerCase() + '-section'}
            >
              <AccordionSummary
                className={`${classes.sectionSummary} ${commonClasses.iconButton}`}
                expandIcon={<ExpandMore />}
                id={section.toLocaleLowerCase() + '-section-button'}
              >
                <Box className={classes.sectionHeader}>
                  <Text variant="subtitle1" className={classes.sectionLabel}>
                    {section}
                  </Text>

                  {selectedValueLabel && (
                    <Text
                      variant="body2"
                      title={selectedValueLabel}
                      className={classes.selectedValue}
                    >
                      {selectedValueLabel}
                    </Text>
                  )}
                </Box>
              </AccordionSummary>

              <AccordionDetails className={classes.sectionDetails}>
                {section === 'Creation Date' ? (
                  <SelectDateRange
                    startDate={selectedCreatedAfter}
                    endDate={selectedCreatedBefore}
                    range={selectedCreatedRange}
                    rangeOptions={createdFilters}
                    onChange={handleSelectDateRange}
                    data-testid="prospect-creation-date-filter"
                  />
                ) : (
                  <Box
                    data-testid={`${section
                      .toLowerCase()
                      .split(' ')
                      .join('')}-section`}
                  >
                    {section === 'Floorplan'
                      ? filterOptions.map(
                          (
                            filterOption: FloorPlanFilterOption,
                            index: number
                          ) => {
                            const options = filterOption.options;
                            return (
                              <Box
                                className={classes.floorPlanContainer}
                                key={`floorplan-${index}`}
                              >
                                {showFloorPlanTitle && (
                                  <Text
                                    variant="body2"
                                    title={filterOption.title}
                                    className={classes.floorPlanTitle}
                                  >
                                    {filterOption.title}
                                  </Text>
                                )}

                                {options.map((filterOption: FilterOption) => {
                                  const selected = isFilterOptionSelected(
                                    section,
                                    filterOption.value
                                  );
                                  return (
                                    <Chip
                                      key={filterOption.value}
                                      data-testid={
                                        filterOption.label
                                          .toLowerCase()
                                          .split(' ')
                                          .join('') + '-floorplan-selected-chip'
                                      }
                                      label={filterOption.label}
                                      clickable={true}
                                      className={classnames(
                                        classes.chip,
                                        selected ? classes.selectedChip : ''
                                      )}
                                      onClick={() =>
                                        handleFilterChange(
                                          section,
                                          filterOption
                                        )
                                      }
                                    />
                                  );
                                })}
                              </Box>
                            );
                          }
                        )
                      : filterOptions.map((filterOption: FilterOption) => {
                          const selected = isFilterOptionSelected(
                            section,
                            filterOption.value
                          );
                          return (
                            <Chip
                              key={filterOption.value}
                              label={filterOption.label}
                              clickable={true}
                              className={classnames(
                                classes.chip,
                                selected ? classes.selectedChip : ''
                              )}
                              data-testid={
                                filterOption.label
                                  .toLowerCase()
                                  .split(' ')
                                  .join('') + `-${sectionId}-chip`
                              }
                              onClick={() =>
                                handleFilterChange(section, filterOption)
                              }
                            />
                          );
                        })}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>

      <Box className={classes.footer}>
        <Button
          variant="text"
          color="primary"
          onClick={handleReset}
          data-testid="reset-button"
        >
          Reset
        </Button>

        <Box>
          <Button
            variant="outlined"
            className={`${commonClasses.buttonSpacer} ${commonClasses.secondaryButton}`}
            onClick={handleCancel}
            data-testid="cancel-button"
          >
            Cancel
          </Button>

          <Button
            onClick={handleApply}
            disabled={isApplyDisabled}
            className={commonClasses.primaryButton}
            data-testid="apply-button"
          >
            Apply Filters
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ProspectFilters;
