import React, { FC, useRef, useState } from 'react';
import {
  Popover,
  Box,
  List,
  ListItem,
  IconButton,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { APP_CONFIG } from '../../../../../../APP_CONFIG';
import { HelpIcon } from '../../../icons';
import './MenuItem.scss';

const {
  REACT_APP_SUPPORT_URL,
  REACT_APP_TRAINING_URL,
  REACT_APP_RELEASE_NOTES_URL
} = APP_CONFIG;

const useStyles = makeStyles({
  boxStyle: {
    width: '209px'
  }
});

export const HelpMenu: FC = () => {
  const [open, setOpen] = useState(false);
  const helpRef = useRef<HTMLButtonElement>(null);
  const { boxStyle } = useStyles();

  return (
    <>
      <IconButton
        className="menu-icon-button"
        aria-describedby="popover-help"
        ref={helpRef}
        onClick={() => setOpen(!open)}
        component="span"
        data-testid="help-icon"
      >
        <HelpIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={helpRef.current}
        onClose={() => setOpen(!open)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        role="help-popover"
      >
        <Box className={boxStyle}>
          <List>
            <ListItem
              component="a"
              href={REACT_APP_SUPPORT_URL}
              target="_blank"
            >
              <ListItemText primary="Knock Help Center" />
            </ListItem>
            <ListItem
              component="a"
              href={REACT_APP_TRAINING_URL}
              target="_blank"
            >
              <ListItemText primary="Knock Training" />
            </ListItem>
            <ListItem
              component="a"
              href={REACT_APP_RELEASE_NOTES_URL}
              target="_blank"
            >
              <ListItemText primary="Knock Release Notes" />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </>
  );
};
