import React from 'react';

export const SMSOptInIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_512_13125)">
      <path
        d="M11.0417 12.2916V17.1C11.0417 17.3987 10.9828 17.6945 10.8685 17.9706C10.7542 18.2466 10.5866 18.4974 10.3754 18.7086C10.1641 18.9199 9.9133 19.0875 9.63729 19.2018C9.36127 19.3161 9.06544 19.375 8.76668 19.375H3.33335C3.03459 19.375 2.73876 19.3161 2.46274 19.2018C2.18673 19.0875 1.93593 18.9199 1.72468 18.7086C1.51343 18.4974 1.34585 18.2466 1.23152 17.9706C1.11719 17.6945 1.05835 17.3987 1.05835 17.1V3.84162C1.05835 3.23749 1.29777 2.65797 1.72417 2.23C2.15058 1.80203 2.72922 1.56049 3.33335 1.55829H7.70835"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.04163 15.625H11.0416"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8984 10.9225C16.6599 10.9225 18.8984 8.68397 18.8984 5.92255C18.8984 3.16112 16.6599 0.922546 13.8984 0.922546C11.137 0.922546 8.89844 3.16112 8.89844 5.92255C8.89844 8.68397 11.137 10.9225 13.8984 10.9225Z"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8984 3.42255V8.42255"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3984 5.92255H16.3984"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_512_13125">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
