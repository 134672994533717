export const legacyAngularRoutes = [
  {
    path: '/search',
    params: {
      redirectTo: '/'
    }
  },
  {
    path: '/',
    params: {
      redirectTo: '/login'
    }
  },
  {
    path: '/dashboard',
    params: {
      templateUrl: '/angular/views/todo/todo-dashboard.html',
      controller: 'TodoDashboardController',
      title: 'Knock | To-Do List',
      access: { requireAuth: true, authUser: ['manager'] }
    }
  },
  {
    path: '/auth',
    params: {
      templateUrl: '/angular/views/login/OBSOLETE_auth-redirect.html',
      controller: 'OBSOLETE_AuthRedirectController'
    }
  },
  {
    path: '/manager/account/:tab',
    params: {
      templateUrl: '/angular/views/account/account-view.html',
      controller: 'AccountViewController',
      access: { requireAuth: true, authUser: ['manager'] }
    }
  },
  {
    path: '/manager/marketing/:tab',
    params: {
      templateUrl: '/angular/views/marketingTools/marketing-tools-view.html',
      controller: 'MarketingToolsViewController',
      access: { requireAuth: true, authUser: ['manager'] }
    }
  },
  {
    path: '/settings',
    params: {
      templateUrl: '/angular/views/settings/settings.html',
      controller: 'SettingsController',
      controllerAs: 'settingsCtrl',
      access: { requireAuth: true, authUser: ['renter', 'manager'] },
      reloadOnSearch: false
    }
  },
  {
    path: '/listing/:listingId',
    params: {
      templateUrl: '/angular/views/listing/listingDetails/listingDetails.html',
      controller: 'ListingDetailsController',
      reloadOnSearch: false,
      useBrandedNavbar: true
    }
  },
  {
    path: '/manager/listing/preview',
    params: {
      templateUrl: '/angular/views/listing/listingDetails/listingDetails.html',
      controller: 'ListingPreviewController',
      reloadOnSearch: false
    }
  },
  {
    path: '/login',
    params: {
      templateUrl: '/angular/views/authentication/login-prompt.html',
      controller: 'ManagerAuthController',
      loginTopBar: true,
      hideNavigation: true
    }
  },
  {
    path: '/logout',
    params: {
      template: '',
      controller: 'LogoutController'
    }
  },
  {
    path: '/unsubscribe',
    params: {
      controller: 'unsubscribeController',
      templateUrl: '/angular/views/unsubscribe/unsubscribe.html',
      hideNavigation: true,
      loginTopBar: false
    }
  },
  {
    path: '/appointments',
    params: {
      redirectTo: '/logout'
    }
  },
  {
    path: '/queued-request',
    params: {
      template: '',
      controller: 'QueuedRequestController'
    }
  },
  {
    path: '/community/:communityId',
    params: {
      templateUrl: '/angular/views/community/community-details.html',
      controller: 'CommunityDetailsController',
      reloadOnSearch: false,
      useBrandedNavbar: true
    }
  },
  {
    path: '/manager/community/create',
    params: {
      templateUrl: '/angular/views/community/community-editor.html',
      controller: 'CommunityEditorController',
      access: { requireAuth: true, authUser: ['manager'] },
      reloadOnSearch: false
    }
  },
  {
    path: '/manager/community/:communityId',
    params: {
      templateUrl: '/angular/views/community/community-editor.html',
      controller: 'CommunityEditorController',
      access: { requireAuth: true, authUser: ['manager'] },
      reloadOnSearch: false
    }
  },
  {
    path: '/manager/community/:communityId/addFloorplan',
    params: {
      templateUrl: '/angular/views/floorplan/floorplan-editor.html',
      controller: 'FloorplanEditorController',
      access: { requireAuth: true, authUser: ['manager'] },
      reloadOnSearch: false
    }
  },
  {
    path: '/manager/community/floorplan/:floorplanId',
    params: {
      templateUrl: '/angular/views/floorplan/floorplan-editor.html',
      controller: 'FloorplanEditorController',
      access: { requireAuth: true, authUser: ['manager'] },
      reloadOnSearch: false
    }
  },
  {
    path: '/property/:propertyId/floorplan/:floorplanId',
    params: {
      templateUrl: '/angular/views/floorplan/floorplan-editor.html',
      controller: 'FloorplanEditorController',
      access: { requireAuth: true, authUser: ['manager'] },
      reloadOnSearch: false
    }
  },
  {
    path: '/manager/calendar',
    params: {
      templateUrl: '/angular/views/calendar/manager-calendar.html',
      access: { requireAuth: true, authUser: ['manager'] },
      title: 'Calendar | Knock',
      controller: 'ManagerCalendarController'
    }
  },
  {
    path: '/units',
    params: {
      templateUrl: '/angular/views/units/units-page.html',
      access: { requireAuth: true, authUser: ['manager'] },
      title: 'Units | Knock',
      controller: 'UnitsPageController'
    }
  },
  {
    path: '/reports/prospects',
    params: {
      templateUrl: '/angular/views/propertyReports/prospect-report-page.html',
      access: { requireAuth: true, authUser: ['manager'] },
      controller: 'ProspectReportPageController',
      title: 'Prospects | Knock',
      reloadOnSearch: false
    }
  },
  {
    path: '/prospects',
    params: {
      templateUrl: '/angular/views/prospects/prospect-page.html',
      access: { requireAuth: true, authUser: ['manager'] },
      controller: 'ProspectPageController',
      title: 'Prospects | Knock',
      reloadOnSearch: false
    }
  },
  {
    path: '/reports/residents',
    params: {
      templateUrl: '/angular/views/residents/resident-report-page.html',
      access: { requireAuth: true, authUser: ['manager'] },
      controller: 'ResidentReportPageController',
      title: 'Residents | Knock',
      reloadOnSearch: false
    }
  },
  {
    path: '/residents',
    params: {
      templateUrl: '/angular/views/residents/resident-page.html',
      access: { requireAuth: true, authUser: ['manager'] },
      controller: 'ResidentPageController',
      title: 'Residents | Knock',
      reloadOnSearch: false
    }
  },
  /*{ path: '/manager/login', params: {
        templateUrl: '/angular/views/auth/manager_login.html',
        controller: 'UserController',
        controllerAs: 'userCtrl'
     } }, */
  {
    path: '/manager/listing',
    params: {
      templateUrl: '/angular/views/listingIndex/active-ads-index.html',
      controller: 'ActiveAdsController',
      access: { requireAuth: true, authUser: ['manager'] },
      reloadOnSearch: false
    }
  },
  {
    path: '/manager/properties',
    params: {
      templateUrl: '/angular/views/listingIndex/properties-index.html',
      controller: 'PropertiesIndexController',
      access: { requireAuth: true, authUser: ['manager'] },
      reloadOnSearch: false
    }
  },
  {
    path: '/manager/listing/create',
    params: {
      templateUrl: '/angular/views/listing/listingEditor/listing-editor.html',
      controller: 'ListingEditorController',
      access: { requireAuth: true, authUser: ['manager'] },
      reloadOnSearch: false
    }
  },
  {
    path: '/listing/:listingId/edit',
    params: {
      templateUrl: '/angular/views/listing/listingEditor/listing-editor.html',
      controller: 'ListingEditorController',
      access: { requireAuth: true, authUser: ['manager'] },
      reloadOnSearch: false
    }
  },
  {
    path: '/calendar/listing/:listingId',
    params: {
      templateUrl: '/angular/views/calendar/listing.html',
      controller: 'ListingCalendarController',
      controllerAs: 'listingCalendarCtrl'
    }
  },
  {
    path: '/meet/:videoCallDisplayId',
    params: {
      templateUrl: '/angular/views/meetings/meeting-page.html',
      controller: 'MeetingPageController',
      reloadOnSearch: false
    }
  },
  {
    path: '/conversation/:chatThreadId',
    params: {
      templateUrl: '/angular/views/conversations/conversation-page.html',
      controller: 'ConversationPageController',
      access: { requireAuth: true },
      reloadOnSearch: false
    }
  },
  {
    path: '/prospect/:prospectKnockId/print',
    params: {
      templateUrl: '/angular/views/prospects/prospect-printable.html',
      controller: 'ProspectPrintController',
      access: { requireAuth: true },
      hideNavigation: true
    }
  },
  {
    path: '/notifications',
    params: {
      templateUrl: '/angular/views/user/pastNotifications.html',
      controller: 'PastNotificationsController'
    }
  },
  {
    path: '/change-password',
    params: {
      templateUrl: '/angular/views/user/change-password.html',
      controller: 'ChangePasswordController'
    }
  },
  {
    path: '/forgot',
    params: {
      templateUrl: '/angular/views/user/resetForgottenPassword.html',
      controller: 'ResetForgottenPasswordController'
    }
  },
  {
    path: '/activate',
    params: {
      templateUrl: '/angular/views/user/resetForgottenPassword.html',
      controller: 'ResetForgottenPasswordController'
    }
  },
  {
    path: '/leasing-team/redirect',
    params: {
      templateUrl: '/angular/views/leasingTeam/click-tracking.html',
      controller: 'ClickTrackingController',
      hideNavigation: true
    }
  },
  {
    path: '/leasing-team/:leasingTeamId/:safeName',
    params: {
      templateUrl: '/angular/views/leasingTeam/leasing-team-page.html',
      controller: 'LeasingTeamPageController',
      reloadOnSearch: false,
      useBrandedNavbar: true
    }
  },
  {
    path: '/prospects/:streamId/export-summary',
    params: {
      templateUrl:
        '/angular/views/prospects/prospect-request-response-summary.html',
      controller: 'ProspectExportSummaryController',
      access: { requireAuth: true },
      reloadOnSearch: false
    }
  },
  {
    path: '/leasing-team/:leasingTeamId',
    params: {
      templateUrl: '/angular/views/leasingTeam/leasing-team-page.html',
      controller: 'LeasingTeamPageController',
      reloadOnSearch: false,
      useBrandedNavbar: true
    }
  },
  {
    path: '/leasing-team/:leasingTeamId/:safeName/:prospectId/sms-consent',
    params: {
      templateUrl:
        '/angular/views/leasingTeam/leasing-team-community-sms-consent.html',
      controller: 'LeasingTeamCommunityProspectSMSConsentController',
      reloadOnSearch: false,
      hideNavigation: true
    }
  },
  {
    path: '/leasing-team/:leasingTeamId/:safeName/residents/:residentStreamId/sms-consent',
    params: {
      templateUrl:
        '/angular/views/leasingTeam/leasing-team-community-sms-consent.html',
      controller: 'LeasingTeamCommunityResidentSMSConsentController',
      reloadOnSearch: false,
      hideNavigation: true
    }
  },
  {
    path: '/leasing-team/:leasingTeamId/:safeName/:managerMailerId',
    params: {
      templateUrl: '/angular/views/leasingTeam/leasing-team-page.html',
      controller: 'LeasingTeamPageController',
      reloadOnSearch: false,
      useBrandedNavbar: true
    }
  },
  {
    path: '/companyProfile',
    params: {
      template: ' ',
      controller: 'CompanyProfileIndexController',
      access: { requireAuth: true, authUser: ['manager'] }
    }
  },
  {
    path: '/appointment-review/:appointmentId/edit',
    params: {
      templateUrl: '/angular/views/reviews/renter-review.html',
      controller: 'EditAppointmentReviewController'
    }
  },
  {
    path: '/internal',
    params: {
      template: '',
      reloadOnSearch: false,
      controller: 'InternalLoginController'
    }
  },
  {
    path: '/autologin',
    params: {
      template: '',
      reloadOnSearch: false,
      controller: 'InternalLoginController'
    }
  },
  {
    path: '/activate-account',
    params: {
      templateUrl: '/angular/views/renter/activate-account.html',
      controller: 'ActivateRenterAccountController'
    }
  },
  {
    path: '/headshots',
    params: {
      template: '<div } },>Redirecting...</div>',
      controller: 'HeadshotsController'
    }
  },
  {
    path: '/browser-notifications/:streamId',
    params: {
      template: '',
      controller: 'BrowserNotificationsController'
    }
  },
  {
    path: '/inbox/:userId?',
    params: {
      templateUrl: '/angular/views/conversations/conversation-inbox.html',
      controller: 'ConversationInboxController',
      reloadOnSearch: false
    }
  },
  {
    // this view is accessible to the public because unauthenticated prospects will be accessing the page.
    path: '/appt/:appointmentUuid',
    params: {
      template: '',
      controller: 'GetAppointmentController'
    }
  },
  {
    path: '/leasing-brochure',
    params: {
      templateUrl: '/angular/views/leasingBrochure/leasing-brochure.html',
      controller: 'LeasingBrochureController',
      hideNavigation: true,
      loginTopBar: false
    }
  }
];

/**
 * Unused or broken
 */

/*  { path: '/manager/old-doorway', params: {
    templateUrl: '/angular/views/company/company-integration.html',
    controller: 'CompanyIntegrationController',
    access: {requireAuth: true, authUser: ['manager']}
 } }, */
/*{ path: '/preferences', params: {
    templateUrl: '/angular/views/preferences.html',
    controller: 'PreferencesController',
    controllerAs: 'preferencesCtrl',
    resolve: {
        user: function (userService) {
            return userService.getUser();
        }
    } },
    access: {requireAuth: true, authUser: ['renter', 'manager']}
 */
/* { path: '/company/integration', params: {
     templateUrl: '/angular/views/company/company-integration.html',
     controller: 'CompanyIntegrationController',
     access: {requireAuth: true, authUser: ['manager']}
  } }, */
/* { path: '/company/me/edit', params: {
     templateUrl: '/angular/views/company/company-profile-editor.html',
     controller: 'CompanyProfileEditorController',
     access: {requireAuth: true, authUser: ['manager']},
     reloadOnSearch: false
  } }, */
/*    { path: '/company/:companyName', params: {
        templateUrl: '/angular/views/company/company-profile.html',
        controller: 'CompanyProfileController',
        reloadOnSearch: false,
        useBrandedNavbar: true
     } }, */
/* { path: '/company/integration/demo', params: {
     templateUrl: '/angular/views/company/company-integration-demo.html',
     controller: 'CompanyIntegrationDemoController'
  } }, */
/*   { path: '/company/:companyName/:managerMailerId', params: {
       templateUrl: '/angular/views/company/company-profile.html',
       controller: 'CompanyProfileController'
    } }, */
/*{ path: '/companyProfile/create', params: {
    templateUrl: '/angular/views/company/company-profile-editor.html',
    controller: 'CompanyProfileEditorController',
    access: {requireAuth: true, authUser: ['manager']}
 } }, */
/*{ path: '/companyProfile/edit', params: {
    templateUrl: '/angular/views/company/company-profile-editor.html',
    controller: 'CompanyProfileEditorController',
    access: {requireAuth: true, authUser: ['manager']}
 } }, */
/*{ path: '/manager/welcome', params: {
    templateUrl: '/angular/views/onboarding/manager-onboarding.html',
    access: {requireAuth: true, authUser: ['manager']},
    reloadOnSearch: false,
    hideNavigation: true
 } }, */
