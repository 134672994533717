import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { Text, makeStyles } from '@knockrentals/knock-shared-web';
import { useCommonStyles } from '../../commonStyles/commonStyles';
import StatsToursDrawer from './StatsToursDrawer';

interface TodoProps {}

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dashboardContainer: {
    padding: '24px 24px 53px'
  }
}));

const Todo: FC<TodoProps> = () => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  return (
    <Box className={classes.dashboardContainer}>
      <Box className={classes.header}>
        <Text variant="h5" className={commonClasses.headerText}>
          To Do List
        </Text>
        <StatsToursDrawer />
      </Box>
    </Box>
  );
};

export default Todo;
