(function (angular, fullStory) {
  var app = angular.module('knockApp');

  app.service('chatTrackingService', [
    '$rootScope',
    'apiBase',
    '_',
    'salesforceSupportService',
    'apiEvents',
    function ($rootScope, apiBase, _, salesforceSupportService, apiEvents) {
      var self = this;

      self._dataIsLoaded = false;
      self._restore_id = null;
      self._data = {
        manager: null,
        master: null,
        profile: null
      };
      self._hideTimeout = null;
      self._salesforceWidget = null;

      self._getChatWidget = function () {
        return self._salesforceWidget;
      };

      self.getUserData = function () {
        return apiBase
          .get('/manager/support-tracking-data')
          .then(function (response) {
            self._data = response.data;
            return apiBase.get('/manager/support-tracking-profile');
          })
          .then(function (response) {
            if (
              response != null &&
              response.data != null &&
              response.data.support_tracking_profile != null
            ) {
              self._restore_id =
                response.data.support_tracking_profile.restore_id;
            }
          })
          .finally(function () {
            self._updateUser();
            self._dataIsLoaded = true;
          });
      };

      self.trackUser = function () {
        if (self._dataIsLoaded) {
          return self._updateUser();
        }

        self.getUserData();
      };

      self._updateUser = function () {
        fullStory.identify(self._data.manager.id, {
          displayName: [
            self._data.profile.first_name,
            self._data.profile.last_name
          ].join(' '),
          email: self._data.profile.email,
          role: 'agent',
          company_name: (self._data.master || {}).username,
          company_id: (self._data.master || {}).company_id
        });
      };

      self.shutdown = function () {
        if (self._salesforceWidget) {
          // TODO: See if the Salesforce widget has this functionality
        }
      };

      self.show = function () {
        const chatWidget = self._getChatWidget();
        if (chatWidget && $rootScope.appPreferences.company.show_intercom) {
          chatWidget.show();
        }
      };

      self.hide = function () {
        const chatWidget = self._getChatWidget();
        if (chatWidget) {
          chatWidget.hide();
        }
      };

      self.showMessage = function (title, message) {
        // TODO: DeleteResourceModalController has a call to this function, we need to see if it's still used and if so how to achieve the same
        //       result with the Salesforce widget. It's likely no users have tried this since we stopped using FreshChat.
        // self._freshChatWidget.showMessage(title, message);
      };

      // Listen for receipt of the app data initialized broadcast by appData.service
      // initialize the chat widget and, if show_intercom is true, display the widget
      $rootScope.$on(apiEvents.appDataInitialized, function () {
        self.hide();

        self.getUserData().then(function () {
          if ($rootScope.appPreferences.company.show_intercom) {
            salesforceSupportService.initialize(self._data);
            self._salesforceWidget = salesforceSupportService;
          }
        });
      });

      return self;
    }
  ]);
})(window.angular, window.FS);
