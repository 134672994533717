/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
import transform from 'lodash/transform';
import truncate from 'lodash/truncate';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
(function (angular) {
  'use strict';

  angular
    .module('knockApp')
    .filter('capitalize', [
      function () {
        return function (input) {
          return capitalize(input);
        };
      }
    ])
    .filter('applicationStatus', [
      '$moment',
      function ($moment) {
        return function (input, appointment) {
          if (input === undefined || input === null || input === ' ') {
            return '';
          }

          if (
            input.toLocaleLowerCase() === 'visited' &&
            !isEmpty(appointment)
          ) {
            var isUpcomingAppointment = $moment(appointment.start_time).isAfter(
              $moment()
            );
            input = isUpcomingAppointment ? 'booked' : 'visited';
          }

          var str = String(input).replace(' ');
          return capitalize(str);
        };
      }
    ])
    .filter('cropText', [
      function () {
        return function (input, maxLength) {
          return trunc(input, maxLength);
        };
      }
    ])
    .filter('renterPets', [
      function () {
        return function (input) {
          var petsRenterHas = transform(
            input,
            function (ret, hasPet, pet) {
              if (hasPet && pet !== 'notes' && pet !== 'allowed') {
                ret.push(pet);
              }

              return ret;
            },
            []
          );

          var formattedPets = map(petsRenterHas, function (pet) {
            return capitalize(pet.replace(' '));
          });

          return formattedPets.join(', ');
        };
      }
    ]);
})(window.angular);
