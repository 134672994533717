/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import { titleService } from '../../../../../Core/Services/TitleService';

(function () {
  'use strict';

  var app = angular.module('knockApp');

  const LogoutController = function (
    $rootScope,
    $auth,
    $window,
    $location,
    localCache,
    cacheKeys,
    authenticationService,
    notificationsService,
    chatTrackingService,
    conversationsService,
    apiEvents,
    userService,
    browserNotificationsService
  ) {
    if ($auth.isAuthenticated()) {
      var clientLogoutEvent = notificationsService.clientEvents.userLoggedOut;

      var logoutEventId = Math.random();
      localCache.put('do-logout', logoutEventId);

      try {
        notificationsService.emitClientEvent(clientLogoutEvent, logoutEventId);

        authenticationService.unsubcribePusherClient();
      } catch (e) {}
    }

    userService.logout().then(function () {
      localCache.del(cacheKeys.loginRedirectPath);
      localCache.del(cacheKeys.refreshToken);

      $auth.logout().then(function () {
        $rootScope.$emit(apiEvents.profileUpdated);
        $rootScope.$emit(apiEvents.loggedOut);

        chatTrackingService.shutdown();

        localCache.clear();

        browserNotificationsService.deleteNotificationsToken();
        titleService.clearBadgeCount();

        $location.path('/login');
      });
    });
  };
  LogoutController.$inject = [
    '$rootScope',
    '$auth',
    '$window',
    '$location',
    'chatTrackingService',
    'localCache',
    'cacheKeys',
    'notificationsService',
    'conversationsService',
    'apiEvents',
    'userService',
    'browserNotificationsService'
  ];

  app.controller('LogoutController', LogoutController);
})();
