import React from 'react';

export const PhoneSMSIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.625 12.875V18.0983C11.625 19.3557 10.6057 20.375 9.34833 20.375H3.9025C2.645 20.375 1.62546 19.3558 1.625 18.0983V5.1525C1.62546 3.89486 2.64486 2.87546 3.9025 2.875H6"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.625 16.625H11.625"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.875 1.625H11C9.61929 1.625 8.5 2.74429 8.5 4.125V7.875C8.5 9.25571 9.61929 10.375 11 10.375H14.125V13.5L17.25 10.375H17.875C19.2557 10.375 20.375 9.25571 20.375 7.875V4.125C20.375 2.74429 19.2557 1.625 17.875 1.625Z"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3125 5.6875C11.1399 5.6875 11 5.82741 11 6C11 6.17259 11.1399 6.3125 11.3125 6.3125C11.4851 6.3125 11.625 6.17259 11.625 6C11.625 5.82741 11.4851 5.6875 11.3125 5.6875"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4375 5.6875C14.2649 5.6875 14.125 5.82741 14.125 6C14.125 6.17259 14.2649 6.3125 14.4375 6.3125C14.6101 6.3125 14.75 6.17259 14.75 6C14.75 5.82741 14.6101 5.6875 14.4375 5.6875"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5625 5.6875C17.3899 5.6875 17.25 5.82741 17.25 6C17.25 6.17259 17.3899 6.3125 17.5625 6.3125C17.7351 6.3125 17.875 6.17259 17.875 6C17.875 5.82741 17.7351 5.6875 17.5625 5.6875"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
