import React, { FC } from 'react';
interface HotLeadIconProps {
  [key: string]: any;
}

export const HotLeadIcon: FC<HotLeadIconProps> = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.067 8.762a3.485 3.485 0 0 1 .353 3.971 1.241 1.241 0 0 1-1.324-1.058 2.81 2.81 0 0 0-1.457 2.648A3.177 3.177 0 0 0 9.817 17.5a3.222 3.222 0 0 0 3.178-3.177c.117-3.134-1.703-4.833-3.928-5.561Z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15.745 6.5H15.5l-1.755 2.193a8.896 8.896 0 0 1 .24-6.194 8.933 8.933 0 0 0-7.394 4.652L5 5.6a7 7 0 1 0 10.745.9Z"
    />
  </svg>
);
