/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('excludedPhotosModalFactory', [
    '$modal',
    function ($modal) {
      var self = this;

      self.openModal = function (maxFiles, maxFileSize) {
        $modal.open({
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl:
            '/angular/views/imageUploader/excluded-photos-modal.html',
          controller: 'ExcludedPhotosModalController',
          resolve: {
            maxFiles: function () {
              return maxFiles;
            },
            maxFileSize: function () {
              return maxFileSize;
            }
          }
        });
      };

      return self;
    }
  ]);

  app.controller('ExcludedPhotosModalController', [
    '$scope',
    '$modalInstance',
    'maxFiles',
    'maxFileSize',
    function ($scope, $modalInstance, maxFiles, maxFileSize) {
      $scope.maxFiles = maxFiles;
      $scope.maxFileSize = maxFileSize;
      $scope.dismiss = function () {
        $modalInstance.dismiss();
      };
    }
  ]);
})(window.angular);
