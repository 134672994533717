import React, { FC } from 'react';

export const ApplicationIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        d="M8.02302 12.875L7.32402 13.574C6.69108 14.2069 5.83263 14.5625 4.93752 14.5625C4.04241 14.5625 3.18396 14.2069 2.55102 13.574C1.91808 12.941 1.5625 12.0826 1.5625 11.1875C1.5625 10.2924 1.91808 9.43393 2.55102 8.80099L6.13077 5.22049C6.73094 4.61896 7.53592 4.26596 8.38496 4.23197C9.234 4.19799 10.0646 4.48552 10.7109 5.03714C11.3573 5.58877 11.7717 6.36389 11.8716 7.20773C11.9714 8.05157 11.7493 8.90201 11.2495 9.58924M12.1135 3.17523L12.676 2.61273C13.3089 1.97979 14.1674 1.62421 15.0625 1.62421C15.9576 1.62421 16.816 1.97979 17.449 2.61273C18.0819 3.24567 18.4375 4.10412 18.4375 4.99923C18.4375 5.89434 18.0819 6.75279 17.449 7.38573L13.8692 10.9655C13.2689 11.5667 12.4639 11.9195 11.615 11.9533C10.766 11.9871 9.93555 11.6994 9.28938 11.1477C8.64322 10.5961 8.22889 9.82097 8.12913 8.97722C8.02937 8.13346 8.25151 7.28313 8.75121 6.59598"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
