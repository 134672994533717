import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    h1: {
      fontWeight: 300,
      fontSize: '4em',
      lineHeight: '125%',
      letterSpacing: '-1.5px'
    },
    h2: {
      fontWeight: 300,
      fontSize: '2.8125em',
      lineHeight: '125%',
      letterSpacing: '-0.5px'
    },
    h3: {
      fontWeight: 400,
      fontSize: '2.375em',
      lineHeight: '125%'
    },
    h4: {
      fontWeight: 400,
      fontSize: '2em',
      lineHeight: '125%'
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.625em',
      lineHeight: '124%',
      letterSpacing: '0.18px'
    },
    h6: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0.4px'
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0.2px'
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.1px'
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px'
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px'
    },
    button: {
      fontWeight: 600,
      textTransform: 'unset',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.4px'
    },
    caption: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px'
    },
    overline: {
      fontWeight: 700,
      fontSize: '12px',
      textTransform: 'uppercase',
      lineHeight: '16px',
      letterSpacing: '1.25px'
    }
  }
});

export default theme;
