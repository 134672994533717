import React from 'react';

export const GaugeIcon = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 3.75C8.49446 3.76893 6.09904 4.78253 4.34085 6.56776C2.58266 8.35299 1.60573 10.7636 1.62504 13.2692V15C1.62504 15.6904 2.18468 16.25 2.87504 16.25H19.125C19.8154 16.25 20.375 15.6904 20.375 15V13.2667C20.4138 8.05 16.2167 3.78943 11 3.75Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 16.25V15.625C8.5 14.2443 9.61929 13.125 11 13.125C12.3807 13.125 13.5 14.2443 13.5 15.625V16.25"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.875 7.5025L11 13.1275"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.0625 12.5C4.88991 12.5 4.75 12.6399 4.75 12.8125C4.75 12.9851 4.88991 13.125 5.0625 13.125C5.23509 13.125 5.375 12.9851 5.375 12.8125C5.375 12.6399 5.23509 12.5 5.0625 12.5"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.3125 9.3775C6.13991 9.3775 6 9.51741 6 9.69C6 9.86259 6.13991 10.0025 6.3125 10.0025C6.48509 10.0025 6.625 9.86259 6.625 9.69C6.625 9.51741 6.48509 9.3775 6.3125 9.3775"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9375 12.5C16.7649 12.5 16.625 12.6399 16.625 12.8125C16.625 12.9851 16.7649 13.125 16.9375 13.125C17.1101 13.125 17.25 12.9851 17.25 12.8125C17.25 12.6399 17.1101 12.5 16.9375 12.5"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6875 9.3775C15.5149 9.3775 15.375 9.51741 15.375 9.69C15.375 9.86259 15.5149 10.0025 15.6875 10.0025C15.8601 10.0025 16 9.86259 16 9.69C16 9.51741 15.8601 9.3775 15.6875 9.3775"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.8125 6.8775C8.63991 6.8775 8.5 7.01741 8.5 7.19C8.5 7.36259 8.63991 7.5025 8.8125 7.5025C8.98509 7.5025 9.125 7.36259 9.125 7.19C9.125 7.01741 8.98509 6.8775 8.8125 6.8775"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
