import React, { FC } from 'react';

import {
  Box,
  Text,
  Card,
  CardContent,
  makeStyles
} from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px'
  }
});

interface OverviewInfoCardProps {
  title: string;
  icon: React.ReactNode;
  className?: string;
}

export const OverviewInfoCard: FC<OverviewInfoCardProps> = ({
  title,
  icon,
  className,
  children
}) => {
  const classes = useStyles();

  return (
    <Card className={className ? className : undefined} elevation={0}>
      <CardContent>
        <Box className={`${classes.cardHeader} overview-card-header`}>
          <Text variant="body2">{title}</Text>
          {icon}
        </Box>

        {children}
      </CardContent>
    </Card>
  );
};
