export type MergeOptionType = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  source: string;
  streamId: string;
};

export type MergeProspectType = {
  address: string | null;
  address_id: number | null;
  alternative_contacts: number | null;
  assigned_manager_id: number | null;
  bedrooms: string[] | number | null;
  email: string | number | null;
  first_contact_type: string | null;
  first_name: string | number | null;
  id: number;
  is_waitlist: boolean | null;
  last_name: string | number | null;
  max_price: number | null;
  min_price: number | null;
  number_of_occupants: number | null;
  owner: string | null;
  pets: string | number | null;
  pets_id: string | null;
  phone_number: string | number | null;
  pms_id: string[];
  preferred_lease_term_months: number | null;
  // new units layouts
  preferred_layout?: string | null;
  preferred_layout_id?: string | null;
  // TODO: remove legacy floorplan fields
  preferred_property_floorplan: string | null;
  preferred_property_floorplan_id: number | null;
  preferred_property_unit: string | null;
  preferred_property_unit_id: number | null;
  preferred_unit?: string | null;
  preferred_unit_id?: string | null;
  source: string | null;
  status: string | null;
  target_move_date: string | null;
};

export type ProspectTypeKeys = keyof MergeProspectType;

export type MergeDefaultType = {
  address?: number | null;
  alternative_contacts?: number | null;
  bedrooms?: number | null;
  email?: number | null;
  first_contact_type?: number | null;
  first_name?: number | null;
  id?: number | null;
  is_waitlist?: number | null;
  last_name?: number | null;
  max_price?: number | null;
  min_price?: number | null;
  number_of_occupants?: number | null;
  owner?: number | null;
  pets?: number | null;
  phone_number?: number | null;
  pms_id?: number | null;
  preferred_lease_term_months?: number | null;
  // new units layout field
  preferred_layout?: number | null;
  // TODO: remove legacy floorplan fields
  preferred_property_floorplan?: number | null;
  preferred_property_unit?: number | null;
  source?: number | null;
  status?: number | null;
  target_move_date?: number | null;
};

export type MergeDataType = {
  statusCode: string;
  decisions: {
    master: MergeProspectType;
    secondary: MergeProspectType;
    defaults: MergeDefaultType;
    locked: string[];
  };
};

export type MergeDefaultsResponse = {
  config: any;
  headers: any;
  status: number;
  statusText: string;
  xhrStatus: string;
  data: MergeDataType;
};

export type MergeDefaultTypeKeys = keyof MergeDefaultType;

export const MergeFieldLabels = {
  address: 'Address',
  address_id: 'Address',
  alternative_contacts: 'Alternative Contacts',
  assigned_manager_id: 'Owner',
  bedrooms: 'Bedrooms',
  email: 'Email',
  first_contact_type: 'First Contact Type',
  first_name: 'First Name',
  id: 'ID',
  is_waitlist: 'Is Waitlist',
  last_name: 'Last Name',
  max_price: 'Max Budget',
  min_price: 'Min Budget',
  number_of_occupants: 'Occupants',
  owner: 'Owner',
  pets: 'Pets',
  pets_id: 'Pets',
  phone_number: 'Phone Number',
  pms_id: 'ID',
  preferred_lease_term_months: 'Desired Lease Term',
  // new units floorplan fields
  preferred_layout: 'Preferred Floorplan',
  preferred_layout_id: 'Preferred Floorplan',
  // TODO: remove legacy floorplan fields
  preferred_property_floorplan: 'Preferred Floorplan',
  preferred_property_floorplan_id: 'Preferred Floorplan',
  preferred_property_unit: 'Preferred Unit',
  preferred_property_unit_id: 'Preferred Unit',
  preferred_unit: 'Preferred Unit',
  preferred_unit_id: 'Preferred Unit',
  source: 'Source',
  status: 'Status',
  target_move_date: 'Move-In Date'
};
