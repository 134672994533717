import { api } from './api';

const useLeasingTeamBadges = () => {
  const { useGetBadgesQuery } = api;

  const { data } = useGetBadgesQuery();
  return {
    Badges: data
  };
};

export default useLeasingTeamBadges;
