/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Account');

  app.factory('accountChangePasswordModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openChangePasswordModal = function () {
        $mdDialog.show({
          controller: 'AccountChangePasswordController',
          templateUrl:
            '/angular/views/account/account-change-password-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true
        });
      };

      return self;
    }
  ]);

  app.controller('AccountChangePasswordController', [
    '$scope',
    '$mdDialog',
    '$mdToast',
    'accountViewService',
    function ($scope, $mdDialog, $mdToast, accountViewService) {
      var self = this;

      $scope.password = {
        old: '',
        new: '',
        confirm: '',
        errorMessage: '',
        isChanging: false
      };

      $scope.changePassword = function () {
        $scope.password.errorMessage = '';
        $scope.password.isChanging = true;

        accountViewService
          .changePassword($scope.password.old, $scope.password.new)
          .then(self._changePasswordSuccess)
          .catch(self._changePasswordError)
          .finally(function () {
            $scope.password.isChanging = false;
          });
      };

      self._changePasswordSuccess = function (response) {
        if (response.status !== 201) {
          throw response;
        }

        $mdDialog.hide();
        $mdToast.showSimple('Password updated!');
      };

      self._changePasswordError = function (response) {
        if (response.status === 401) {
          $scope.password.errorMessage =
            'The current password you provided does not match our records.';
        } else if (response.status === 400) {
          $scope.password.errorMessage = response.data.message;
        } else {
          $scope.password.errorMessage =
            "We're having a problem changing your password. Please try again later";
        }
      };

      $scope.cancel = function () {
        $mdDialog.cancel();
      };
    }
  ]);
})(window.angular);
