import React, { FC } from 'react';

export const AlertOnIcon: FC = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#EBEEFE" />
    <path
      d="M22 33.75C22.2609 34.6387 23.0763 35.2492 24.0025 35.2492C24.9287 35.2492 25.7441 34.6387 26.005 33.75M24 15V12.75M24 15C28.1421 15 31.5 18.3579 31.5 22.5C31.5 29.546 33 30.75 33 30.75H15C15 30.75 16.5 28.834 16.5 22.5C16.5 18.3579 19.8579 15 24 15Z"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
