/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('viewThreadModalService', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function (threadId) {
        return $mdDialog.show({
          templateUrl: '/angular/views/prospects/view-open-thread-modal.html',
          controller: 'ViewThreadModalController',
          locals: {
            threadId: threadId
          }
        });
      };

      return self;
    }
  ]);

  app.controller('ViewThreadModalController', [
    '$scope',
    '$mdDialog',
    'conversationsService',
    'threadId',
    function ($scope, $mdDialog, conversationsService, threadId) {
      $scope.goToConversation = function () {
        var viewAsModal = true;
        conversationsService.openThreadById(threadId, viewAsModal);
        $mdDialog.hide();
      };

      $scope.closeDialog = function () {
        $mdDialog.cancel();
      };
    }
  ]);
})(window.angular);
