import React, { FC } from 'react';

interface RangeProps {
  rangeBounds: { rowStart: number; rowEnd: number };
  total: number;
}

const RangeBounds: FC<RangeProps> = ({ rangeBounds, total }) => {
  return (
    <span className="range">{`${rangeBounds.rowStart.toLocaleString()}-${rangeBounds.rowEnd.toLocaleString()} of ${total.toLocaleString()}`}</span>
  );
};

export default RangeBounds;
