import { UpchargeDetails } from '../../ts/models';
import path from 'path';

export const getUpchargeAmount =
  (upchargeDetails: UpchargeDetails) => (selectedLeaseTerm: number) => {
    const { includeUpcharges, leaseLengths = [] } = upchargeDetails;

    if (!includeUpcharges) {
      return 0;
    }

    const { isAvailable, upchargeAmount } =
      leaseLengths.find((leaseLengthDetails) => {
        if (selectedLeaseTerm > 12) {
          return leaseLengthDetails.leaseLength === '12+';
        }

        if (selectedLeaseTerm === 1) {
          return leaseLengthDetails.leaseLength === 'Month to month';
        }

        return leaseLengthDetails.leaseLength === selectedLeaseTerm.toString();
      }) || {};

    if (!isAvailable || !upchargeAmount) {
      return 0;
    }

    return isAvailable && upchargeAmount ? upchargeAmount : 0;
  };

export const getShortFilename = (url: string) => {
  if (!url) {
    return;
  }
  const filename = path.basename(url);
  const shortName = filename.replace(
    /(^quote_)([A-Z\d]{8}-\d{10}-)(.*$)/,
    '$1$3'
  );
  return shortName;
};
