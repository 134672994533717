/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('customMaxlength', function () {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, ngModelCtrl) {
        var maxlength = Number(attrs.customMaxlength);
        function fromUser(text) {
          if (text.length > maxlength) {
            var transformedInput = text.substring(0, text.length);
            ngModelCtrl.$setViewValue(transformedInput);
            ngModelCtrl.$render();
            return transformedInput;
          }
          return text;
        }
        ngModelCtrl.$parsers.push(fromUser);
      }
    };
  });
})(window.angular, window.jQuery);
