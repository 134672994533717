import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export const PrimaryButton = withStyles({
  root: {
    backgroundColor: '#67C8A2',
    border: 'none',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#30BA8F',
      border: 'none',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#4FC199',
      border: 'none'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  }
})(Button);

export const SecondaryButton = withStyles({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #999999',
    boxShadow: 'none',
    color: '#999999',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#F0F0F0',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#E6E6E6'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  }
})(Button);
