import React, { FC } from 'react';
import classnames from 'classnames';

export interface AttachmentIconProps {
  className?: string;
  height?: string;
  strokeColor?: string;
  width?: string;
  useNewDesign?: boolean;
}

export const AttachmentIcon: FC<AttachmentIconProps> = ({
  className,
  height = '15',
  strokeColor = '#8D90A5',
  width = '14',
  useNewDesign
}) => {
  if (useNewDesign) {
    return (
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.4375 8.4252L10.2047 16.6587C8.22768 18.6357 5.02226 18.6357 3.04522 16.6587C1.06818 14.6817 1.06818 11.4762 3.04522 9.4992L9.80722 2.73795C10.6546 1.8606 11.9094 1.50873 13.0894 1.8176C14.2694 2.12646 15.191 3.04799 15.4998 4.22799C15.8087 5.40799 15.4568 6.66282 14.5795 7.5102L7.81822 14.2752C7.15618 14.9148 6.10368 14.9058 5.45265 14.255C4.80162 13.6042 4.79231 12.5517 5.43172 11.8895L12.1937 5.1252"
          stroke="#353950"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        className={classnames('attachment-icon', className)}
        width={width}
        height={height}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m13.251 6.562-6.17 6.17a3.794 3.794 0 1 1-5.364-5.365L6.784 2.3a2.53 2.53 0 1 1 3.576 3.576l-5.067 5.07a1.265 1.265 0 0 1-1.788-1.788l5.067-5.07"
          stroke={strokeColor}
          strokeWidth="1.124"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
};
