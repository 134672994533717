/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('showResponsive', [
    '$window',
    function ($window) {
      return {
        restrict: 'E',
        transclude: true,
        template:
          '<div class="show-responsive-container" ng-transclude ng-if="show"></div>',
        scope: {
          minWidth: '=',
          maxWidth: '='
        },
        link: function (scope) {
          var jqueryWindow = angular.element($window);
          scope.skipApply = true;

          function onWindowResize() {
            var windowWidth = window.innerWidth;
            var min = scope.minWidth || 0;

            var greaterThanMin = windowWidth > min;
            var hasMax = !!scope.maxWidth;

            scope.show =
              greaterThanMin && (!hasMax || windowWidth <= scope.maxWidth);
            if (!scope.skipApply) {
              scope.$apply();
            }
            scope.skipApply = false;
          }

          jqueryWindow.bind('resize', onWindowResize);

          onWindowResize();
        }
      };
    }
  ]);
})(window.angular);
