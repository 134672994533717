import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
  DialogActions,
  FormControl,
  Theme,
  Divider,
  FormGroup,
  Checkbox,
  Grid,
  ListItem,
  List,
  ListItemText,
  Snackbar,
  IconButton,
  Select,
  Input,
  MenuItem,
  Typography
} from '@material-ui/core';
import {
  makeStyles,
  NamedColors,
  Box,
  Button,
  white
} from '@knockrentals/knock-shared-web';
import CloseIcon from '@material-ui/icons/Close';
import { useAppSelector } from '../../../../../app/hooks';
import { useProspects, useProspectsReport } from 'app/services/prospects/hooks';
import { Prospect } from '../../../../../app/services/prospects/entities';
import { CircularProgressButton } from '../../CircularProgressButton';
import { colors } from '../../commonStyles/commonStyles';
import { calculateItemsToDisplay, sortTextBySize } from './utils';

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: '8px 16px',
    width: '600px',
    height: '600px'
  },
  dialogTitle: {
    padding: '16px 0 10px 16px',
    borderBottom: '1px solid #E2E3E9'
  },
  formControl: {
    width: '100%',
    marginBottom: '0px',
    '& .MuiInputBase-root': {
      border: 'none',
      background: white,
      margin: 0,
      padding: 0
    },
    '& .MuiInputLabel-formControl': {
      top: '23px',
      left: '0px',
      color: colors.secondaryText,
      fontSize: '14px',
      lineHeight: '12px'
    },
    '& .MuiSelect-select:focus': {
      background: 'white'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '13px'
  },
  chip: {
    margin: 2,
    color: colors.defaultText,
    fontSize: '12px',
    backgroundColor: '#EEEEF2'
  },
  actionBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    padding: '0 17px 12px 0'
  },
  CircularProgressButton: {
    background: '#DB0024',
    '&.MuiButton-containedPrimary:hover': {
      background: '#DB0024'
    }
  },
  cancelButton: {
    border: `1px solid ${NamedColors.denim[600]}`,
    color: NamedColors.denim[600],
    lineHeight: '24px'
  },
  subTitle: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    color: '#212121',
    marginTop: '12px'
  },
  list: {
    width: '100%',
    maxWidth: 360
  },
  listBox: {
    paddingLeft: '12px'
  },
  prospectChips: {
    margin: '6px',
    color: colors.defaultText,
    backgroundColor: colors.chipBackground
  },
  chipsSection: {
    marginBottom: '10px'
  },
  prospectChipLabel: {
    fontSize: '12px',
    fontWeight: 400
  },
  prospectLabel: {
    fontSize: '12px',
    paddingTop: '14px'
  },
  alertIcon: {
    marginRight: '10px'
  },
  alert: {
    backgroundColor: colors.snackbarBackground
  },
  chipContainer: {
    paddingBottom: '4px',
    paddingLeft: '4px'
  }
}));

interface MarkAsLostModalProps {
  closeModal: (withEffect?: boolean | undefined) => void;
  showMarkAsLostModal: boolean;
}

const MarkAsLostModal: FC<MarkAsLostModalProps> = ({
  closeModal,
  showMarkAsLostModal
}) => {
  const classes = useStyles();
  const { selectedProspects } = useAppSelector(
    (state) => state.selectedProspects
  );
  const {
    lossReasons,
    setStatusAsLostForProspects,
    setLostReasonForProspects
  } = useProspects();
  const { prospects } = useProspectsReport();
  const [checked, setChecked] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string | null>(null);
  const [recordsToDisplay, setRecordsToDisplay] = useState<string[]>([]);
  const [filtered, setFiltered] = useState<boolean>(false);

  const getSelectedProspects = () => {
    let lostProspects = prospects
      .filter((prospect: Prospect) => selectedProspects[prospect.id])
      .map((prospect: Prospect) => prospect.profile?.fullName)
      .sort(sortTextBySize('asc'));
    return lostProspects;
  };

  const lossReasonsLength = lossReasons && Math.ceil(lossReasons?.length / 2);

  const firstHalfLossReasons = lossReasons?.slice(0, lossReasonsLength);
  const secondHalfLossReasons = lossReasons?.slice(lossReasonsLength);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const submitHandler = async () => {
    setIsLoading(true);
    const lostProspectsPayload = {
      prospect_ids: Object.keys(selectedProspects),
      loss_reasons: [...checked]
    };
    const statusPayload = {
      prospectIds: Object.keys(selectedProspects),
      newStatus: 'lost'
    };
    const resultMarkLost = await setLostReasonForProspects(
      lostProspectsPayload
    );
    const resultSetMarkLost = await setStatusAsLostForProspects(statusPayload);

    if (
      resultMarkLost.hasOwnProperty('error') ||
      resultSetMarkLost.hasOwnProperty('error')
    ) {
      setSnackMessage(
        'We were unable to mark prospects as lost. Please try again.'
      );
    } else {
      if (lostProspectsPayload?.prospect_ids?.length === 1) {
        setSnackMessage(
          `${getSelectedProspects()[0]} has been marked as lost.`
        );
      } else {
        setSnackMessage(
          `${lostProspectsPayload?.prospect_ids?.length} prospects have been marked lost.`
        );
      }
    }
    setIsLoading(false);
    closeModal(true);
  };

  const handleCloseAlert = () => {
    setSnackMessage(null);
  };

  const handleClose = () => {
    closeModal();
  };

  useEffect(() => {
    setChecked([]);
  }, [showMarkAsLostModal]);

  useEffect(() => {
    if (showMarkAsLostModal) {
      setRecordsToDisplay([...getSelectedProspects()]);
      setFiltered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMarkAsLostModal]);

  const updateChipsToDisplay = () => {
    if (filtered) {
      return;
    }
    const calculation = calculateItemsToDisplay<string>(
      getSelectedProspects(),
      'chip-container'
    );

    if (calculation) {
      const { toDisplay, toHide } = calculation;
      setFiltered(true);
      setRecordsToDisplay([
        ...toDisplay,
        ...(toHide === recordsToDisplay.length
          ? [`${toHide} Prospect${toHide > 1 ? 's' : ''}`]
          : []),
        ...(toHide > 0 && toHide !== recordsToDisplay.length
          ? [`+${toHide}`]
          : [])
      ]);
    }
  };

  return (
    <>
      <Snackbar
        ContentProps={{
          classes: {
            root: classes.alert
          }
        }}
        data-testid="snack-alert-message"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!snackMessage}
        message={snackMessage}
        action={
          <IconButton
            className={classes.alertIcon}
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseAlert}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={showMarkAsLostModal}
        onClose={handleClose}
      >
        <DialogTitle className={classes.dialogTitle}>
          Mark Prospects as Lost
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.chipContainer}>
            <FormControl className={classes.formControl}>
              <Typography
                variant="caption"
                id="chip-label"
                variantMapping={{ caption: 'div' }}
              >
                Prospects
              </Typography>
              <Select
                IconComponent={() => null}
                multiple
                value={recordsToDisplay}
                input={<Input />}
                renderValue={(selected: any) => (
                  <div
                    data-testid="chip-container"
                    id="chip-container"
                    className={classes.chips}
                  >
                    {selected.map((value: string, index: number) => {
                      if (index + 1 === selected.length) {
                        updateChipsToDisplay();
                      }

                      return (
                        <Chip
                          key={index}
                          label={value}
                          className={classes.chip}
                          size="small"
                        />
                      );
                    })}
                  </div>
                )}
                disabled
              >
                {recordsToDisplay.map((name: string, index: number) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <Box className={classes.subTitle}>
            Select the reasons the prospect(s) did not continue to lease.
          </Box>
          <Box className={classes.listBox}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <List className={classes.list}>
                      {firstHalfLossReasons?.map((value: string) => {
                        const labelId = `checkbox-list-label-${value}`;
                        return (
                          <ListItem
                            key={value}
                            role={undefined}
                            onClick={handleToggle(value)}
                          >
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                              color="primary"
                            />
                            <ListItemText id={labelId} primary={value} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Grid xs={6} item>
                    <List className={classes.list}>
                      {secondHalfLossReasons?.map((value: string) => {
                        const labelId = `checkbox-list-label-${value}`;
                        return (
                          <ListItem
                            key={value}
                            role={undefined}
                            onClick={handleToggle(value)}
                          >
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disableRipple
                              color="primary"
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                            <ListItemText id={labelId} primary={value} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                </Grid>
              </FormGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className={classes.actionBox}>
            <Button
              type="button"
              onClick={handleClose}
              variant="outlined"
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <CircularProgressButton
              onClick={submitHandler}
              shouldShowProgress={isLoading}
              progressText={'Marking as Lost...'}
              className={classes.CircularProgressButton}
              disabled={checked?.length === 0}
            >
              Mark as Lost ({Object.keys(selectedProspects).length})
            </CircularProgressButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default MarkAsLostModal;
