import React, { FC } from 'react';
import { Box, Button, Card, CardHeader } from '@material-ui/core';
import { makeStyles, NamedColors } from '@knockrentals/knock-shared-web';
import { colors } from '../commonStyles/commonStyles';

const useStyles = makeStyles({
  cardBackground: {
    backgroundColor: NamedColors.banana[100],
    width: '600px',
    minHeight: '170px',
    margin: '20px 5px 25px 5px'
  },
  cardTitle: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.2px',
    color: NamedColors.banana[900]
  },
  cardSubTitle: {
    fontSize: '14px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '142%',
    letterSpacing: '0.25px',
    color: NamedColors.banana[900]
  },
  svgIcon: {
    alignSelf: 'baseline'
  },
  cardHeader: {
    '& .MuiCardHeader-avatar': {
      alignSelf: 'baseline',
      marginTop: '5px'
    }
  },
  name: {
    fontSize: '14px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    color: colors.secondaryText
  },
  phoneAndEmail: {
    fontSize: '12px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    color: colors.secondaryText
  },
  duplicateDetails: {
    paddingTop: '16px'
  },
  button: {
    '&:hover': {
      color: NamedColors.denim[600],
      backgroundColor: 'none'
    },
    '&:focus': {
      color: NamedColors.denim[600],
      backgroundColor: 'none',
      textDecoration: 'none'
    }
  }
});

interface DuplicateDetectionCardProps {
  duplicateDataDetails?: any;
}

const DuplicateDetectionCard: FC<DuplicateDetectionCardProps> = ({
  duplicateDataDetails
}) => {
  const classes = useStyles();
  const scopedLeasingTeamId = window.sessionStorage.scopedLeasingTeamId;
  const duplicateProspectDetails = duplicateDataDetails[0]?.hit;
  const openGuestcardUrl =
    `/conversation/${duplicateProspectDetails?.stream_id}` +
    (scopedLeasingTeamId ? `?lt=${scopedLeasingTeamId}` : '');

  return (
    <Card className={classes.cardBackground}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <img
            src="/images/DuplicateProspectIcon.svg"
            alt="Duplicate prospect"
            className={classes.svgIcon}
          />
        }
        title={
          <Box className={classes.cardTitle}>Duplicate Prospect Detected</Box>
        }
        action={
          <Button
            href={openGuestcardUrl}
            target="_blank"
            color="primary"
            className={classes.button}
          >
            View
          </Button>
        }
        subheader={
          <Box className={classes.cardSubTitle}>
            We can not create a new prospect because a prospect with the same
            contact information already exists.
            <Box className={classes.duplicateDetails}>
              {duplicateProspectDetails?.name && (
                <Box className={classes.name}>
                  {duplicateProspectDetails?.name}
                </Box>
              )}
              {duplicateProspectDetails?.email && (
                <Box className={classes.phoneAndEmail}>
                  {duplicateProspectDetails?.email}
                </Box>
              )}
              {duplicateProspectDetails?.phone_number && (
                <Box className={classes.phoneAndEmail}>
                  {`(${duplicateProspectDetails?.phone_number.slice(
                    2,
                    5
                  )}) ${duplicateProspectDetails?.phone_number.slice(
                    5,
                    8
                  )}-${duplicateProspectDetails?.phone_number.slice(8)}`}
                </Box>
              )}
            </Box>
          </Box>
        }
      />
    </Card>
  );
};

export default DuplicateDetectionCard;
