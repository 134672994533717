/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('upgradeModalController', [
    '$scope',
    '$modalInstance',
    '$location',
    function ($scope, $modalInstance, $location) {
      $scope.goToSubscriptions = function () {
        $modalInstance.dismiss();
        $location.url('subscription');
      };

      $scope.dismiss = function () {
        $modalInstance.dismiss('closed');
      };
    }
  ]);
})();
