/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('notificationBannerAppointment', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/notifications/notification-banner-appointment.html',
      scope: {
        appointment: '='
      },
      replace: true,
      controller: [
        '$scope',
        'conversationsService',
        'notificationBannersService',
        function ($scope, conversationsService, notificationBannersService) {
          $scope.openChatThread = function (newWindow) {
            if (newWindow) {
              conversationsService.openThreadInNewWindow(
                $scope.appointment.stream_id
              );
            } else {
              var openAsModal = true;
              conversationsService.openThreadById(
                $scope.appointment.stream_id,
                openAsModal
              );
            }
          };

          $scope.close = function () {
            notificationBannersService.removeNotification(
              $scope.appointment.stream_id,
              notificationBannersService.notificationTypes.appointment
            );
          };
        }
      ]
    };
  });
})(window.angular);
