/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('leasingTeamApi', [
    'apiBase',
    function (apiBase) {
      return {
        getMyLeasingTeam: function () {
          return apiBase.get('/me/leasing-team/');
        },
        getMyLeasingTeams: function () {
          return apiBase.get('/me/leasing-teams/');
        },
        getMyLeasingTeamMarketingSources: function () {
          return apiBase.get('/me/leasing-team/marketing-sources');
        },
        switchLeasingTeam: function (leasing_team_id) {
          return apiBase.post(
            `/me/leasing-teams/switch/${leasing_team_id}`,
            {}
          );
        },
        getMyLeasingTeamsBadges: function () {
          return apiBase.get('/me/leasing-teams/badges');
        }
      };
    }
  ]);
})(window.angular);
