import React, { FC, useEffect, useMemo, useState } from 'react';
import { Drawer, Divider, IconButton, Button } from '@material-ui/core';
import {
  Box,
  makeStyles,
  Text,
  ThemeProvider,
  NamedColors
} from '@knockrentals/knock-shared-web';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgressButton } from '../../CircularProgressButton';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import {
  PersonInCircleIcon,
  IcardIcon,
  BuildingBaseIcon,
  CalendarDarkIcon,
  PersonWithFlagIcon
} from '../../icons';
import InfoPanel from './InfoPanel';
import { Provider } from 'react-redux';
import { store } from 'app/store';
import { CompleteTourDrawerProvider } from './CompleteTourDrawerContextProvider';
import CompleteTourStepper from './CompleteTourStepper';
import { useProspects } from 'app/services/prospects/hooks';
import { IAddVisit } from 'app/services/prospects/entities';

dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles({
  drawer: {
    '& .MuiPaper-root': {
      width: '720px'
    }
  },
  container: {},
  title: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    color: NamedColors.slate[800],
    marginTop: '30px',
    marginLeft: '23px'
  },

  closeButton: {
    color: NamedColors.slate[800],
    margin: '24px 23px 0'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  divider: { border: '2px', color: NamedColors.slate[800], width: '100%' },
  actionBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '16px',
    padding: '0 0px 12px 0',
    width: '100%',
    '& button': {
      marginRight: '17px'
    }
  },
  sections: { height: '100%', overflowY: 'auto', overflowX: 'hidden' },
  disableButtonBox: {
    margin: '1px',
    color: '#9d9d9d',
    backgroundColor: '#eeedf2',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#eeedf2',
      border: 'none',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#eeedf2',
      border: 'none'
    },
    '&:focus': {
      boxShadow: 'none'
    }
  }
});

interface infoPanelDataProps {
  prospectName: string;
  tourType: string;
  tourProperty: string;
  tourDateAndTime: string;
  tourOwner: string;
  tourPropertyId: number;
  shownUnitsRequired: boolean;
  id: number;
  prospectId: number;
}

type TAction = 'mark-as-lost' | 'schedule-tour' | 'step';
interface CompleteTourDrawerProps {
  openCompleteTourDrawer: boolean;
  infoPanelData: infoPanelDataProps;
  onClose: (action?: TAction, id?: number) => void;
}

interface ITourData {
  step: number;
  tourResult?: 'no_show' | 'visited';
  shownUnits: string[];
  prospectInterest?: 'still_interested' | 'lost';
  nextSteps?: string[];
  note?: string;
  scheduleTour: boolean;
  showRequiredUnitError?: boolean;
  shownUnitsSkipped?: boolean;
}

const CompleteTourDrawer: FC<CompleteTourDrawerProps> = ({
  openCompleteTourDrawer,
  infoPanelData,
  onClose
}) => {
  const classes = useStyles();

  const { addVisit, setVisitShownUnits, updateAppointmentStatus } =
    useProspects();
  const [loading, setLoading] = useState<boolean>(false);

  const [tourData, setTourData] = useState<ITourData>({
    step: 0,
    shownUnits: [],
    scheduleTour: false,
    nextSteps: []
  });

  const {
    step,
    nextSteps,
    prospectInterest,
    tourResult,
    note,
    shownUnits,
    showRequiredUnitError
  } = tourData;

  const handleClose = (isSubmitted = false, action?: TAction) => {
    console.log('action', action);
    setIsOpen(false);
    const appointmentId = isSubmitted ? infoPanelData.id : undefined;
    onClose(action, appointmentId);
  };

  const handleDisableButton = () => {
    SetFinishButtonDisableClicked(true);
  };

  const tourDateAndTimeFormated =
    dayjs(infoPanelData.tourDateAndTime)
      .tz('America/Toronto')
      .format('ddd, MMM D @ h:mm a') || null;

  const tourTypeObj: any = {
    null: 'In Person Tour',
    self_guided: 'Self Guided Tour',
    live_video: 'Live Video Tour'
  };

  const InfoPanelData = [
    {
      icon: <IcardIcon />,
      title: infoPanelData.prospectName,
      id: 1
    },
    {
      icon: <PersonWithFlagIcon />,
      title: tourTypeObj[infoPanelData.tourType],
      id: 2
    },
    {
      icon: <BuildingBaseIcon />,
      title: infoPanelData.tourProperty,
      id: 3
    },
    {
      icon: <CalendarDarkIcon />,
      title: tourDateAndTimeFormated,
      id: 4
    },
    {
      icon: <PersonInCircleIcon />,
      title: infoPanelData.tourOwner,
      id: 5
    }
  ];

  const [isOpen, setIsOpen] = useState(false);

  const setData = (data: Partial<ITourData>) => {
    SetFinishButtonDisableClicked(false);
    data.showRequiredUnitError = false;
    switch (Object.keys(data)[0]) {
      case 'tourResult':
        data.shownUnits = [];
        data.prospectInterest = undefined;

        if (data.tourResult === 'no_show') {
          data.step = 2;
        } else {
          data.step = 1;
        }

        break;
      case 'shownUnits':
        const units = data.shownUnits?.length;
        if (units === 0) {
          if (
            tourData.shownUnits.length > 0 &&
            infoPanelData.shownUnitsRequired
          ) {
            data.showRequiredUnitError = true;
            data.step = 1;
            data.nextSteps = [];
            data.scheduleTour = false;
            data.note = '';
            data.prospectInterest = undefined;
          }
        } else if (step === 1) {
          data.step = 2;
        }
        break;
      case 'prospectInterest':
        data.nextSteps = [];
        data.scheduleTour = false;
        data.note = '';
        data.step = 3;

        break;

      default:
        if (tourData.step < 3) {
          data.step = tourData.step + 1;
        }
        break;
    }

    setTourData((prevState: ITourData) => ({ ...prevState, ...data }));
  };

  const [finishButtonDisableClicked, SetFinishButtonDisableClicked] =
    useState<boolean>(false);

  useEffect(() => setIsOpen(openCompleteTourDrawer), [openCompleteTourDrawer]);

  const nextStepsCompleted = useMemo(() => {
    if (nextSteps?.includes('Other') && !note) {
      return false;
    }
    return (
      step > 2 &&
      (prospectInterest === 'lost' ||
        nextSteps?.length !== 0 ||
        (tourResult === 'no_show' && prospectInterest === 'still_interested'))
    );
  }, [prospectInterest, nextSteps, tourResult, step, note]);

  const handleComplete = async () => {
    setLoading(true);

    try {
      const visit: IAddVisit = {
        appointment_id: infoPanelData.id,
        prospect_id: infoPanelData.prospectId,
        visit_time: infoPanelData.tourDateAndTime
      };

      if (tourResult === 'visited') {
        if (prospectInterest === 'still_interested') {
          let message = nextSteps?.join(', ') || '';
          visit.extra_status_message = message.replace(
            'Other',
            tourData.note || ''
          );
        }
        const visitRes = (await addVisit(visit)) as any;
        const visitId = visitRes.data?.id;

        if (shownUnits.length > 0) {
          await setVisitShownUnits({
            visitId,
            units: shownUnits
          });
        }
      } else {
        await updateAppointmentStatus({
          appointmentId: infoPanelData.id,
          status: 'no-show'
        });
      }
    } catch (e) {
      console.error('Error processing tour completition:', e);
    }

    if (prospectInterest === 'lost') {
      handleClose(true, 'mark-as-lost');
    } else if (tourResult === 'no_show' && tourData.scheduleTour) {
      handleClose(true, 'schedule-tour');
    } else {
      handleClose(true, 'step');
    }
  };

  const unitsError = useMemo<boolean>(() => {
    return (
      (finishButtonDisableClicked && shownUnits.length === 0 && step === 1) ||
      !!showRequiredUnitError
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourData, finishButtonDisableClicked]);

  return (
    <CompleteTourDrawerProvider
      context={{
        ...tourData,
        infoPanelData,
        nextStepsCompleted,
        setData,
        SetFinishButtonDisableClicked,
        finishButtonDisableClicked,
        unitsError
      }}
    >
      <ThemeProvider>
        <Drawer
          anchor="right"
          open={isOpen}
          className={classes.drawer}
          data-testid="complete-tour-drawer"
        >
          <Box className={classes.sections}>
            <Box className={classes.header} data-testid="header">
              <Box data-testid="complete-tour-drawer-box">
                <Text
                  variant="h4"
                  className={classes.title}
                  data-testid="complete-tour-title"
                >
                  Complete Tour
                </Text>
              </Box>
              <Box>
                <IconButton
                  data-testid="complete-tour-drawer-close-button"
                  onClick={() => handleClose()}
                  className={classes.closeButton}
                >
                  <CloseIcon fontSize="large" color="inherit" />
                </IconButton>
              </Box>
            </Box>
            <InfoPanel
              InfoPanelData={InfoPanelData}
              data-testid="complete-tour-drawer-info-panel"
            />
            <Divider className={classes.divider} />
            <CompleteTourStepper />
          </Box>
          <Box className={classes.actionBox}>
            <Divider className={classes.divider} />

            {!nextStepsCompleted ? (
              <Button
                data-testid="complete-tour-drawer-finish-disable-button"
                color="secondary"
                onClick={handleDisableButton}
                className={classes.disableButtonBox}
                variant="contained"
              >
                Finish
              </Button>
            ) : (
              <CircularProgressButton
                dataTestId="complete-tour-drawer-finish-button"
                disabled={!nextStepsCompleted}
                onClick={handleComplete}
                shouldShowProgress={loading}
                progressText="Finishing..."
              >
                Finish
              </CircularProgressButton>
            )}
          </Box>
        </Drawer>
      </ThemeProvider>
    </CompleteTourDrawerProvider>
  );
};

export default (props: CompleteTourDrawerProps) => {
  return (
    <Provider store={store}>
      <CompleteTourDrawer {...props} />
    </Provider>
  );
};
