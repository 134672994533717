/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const BrowserNotificationsController = function (
    $window,
    $mdDialog,
    browserNotificationsService
  ) {
    var self = this;

    self.openBrowserNotificationsModal = function () {
      return $mdDialog.show({
        controller: 'BrowserNotificationsModalController',
        templateUrl:
          '/angular/views/browserNotifications/browser-notifications-modal.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false
      });
    };

    self.openBrowserNotificationsModal();

    browserNotificationsService.playSound();

    return self;
  };

  BrowserNotificationsController.$inject = [
    '$window',
    '$mdDialog',
    'browserNotificationsService'
  ];
  app.controller(
    'BrowserNotificationsController',
    BrowserNotificationsController
  );

  const BrowserNotificationsModalController = function (
    $window,
    $scope,
    $routeParams,
    $mdDialog
  ) {
    var self = this;

    var streamId = $routeParams.streamId;

    $scope.close = function () {
      $mdDialog.hide();
      $window.close();
    };

    $scope.show = function () {
      self.openPopup(streamId);
      $mdDialog.hide();
      $window.close();
    };

    self.openPopup = function (streamId) {
      var width = screen.width / 1.25;
      var height = screen.height;
      var left = screen.width / 2 - width / 2;
      var top = screen.height / 2 - height / 2;
      console.log(width, height, left, top);
      $window.open(
        '/conversation/' + streamId,
        'Knock',
        'toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
          width +
          ', height=' +
          height +
          ', top=' +
          top +
          ', left=' +
          left
      );
    };

    return self;
  };

  BrowserNotificationsModalController.$inject = [
    '$window',
    '$scope',
    '$routeParams',
    '$mdDialog'
  ];
  app.controller(
    'BrowserNotificationsModalController',
    BrowserNotificationsModalController
  );
})(window.angular);
