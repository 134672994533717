/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('prospectsApi', [
    'apiBase',
    function (apiBase) {
      return {
        getProspects: function (
          createdStart,
          createdEnd,
          listingIds,
          managerIds,
          status,
          includeUnassigned
        ) {
          var active = status === 'active' || null;
          var inactive = status === 'inactive' || null;

          if (active || inactive) {
            status = null;
          }

          return apiBase.post('/me/prospects', {
            created_start: createdStart,
            created_end: createdEnd,
            listing_ids: listingIds,
            manager_ids: managerIds,
            status: status,
            active: active,
            inactive: inactive,
            include_unassigned: includeUnassigned
          });
        },
        getProspectSMSOptInUrl: function (prospectId) {
          return apiBase.get('/prospects/' + prospectId + '/sms-consent-url');
        },
        getProspectSMSConsentData: function (prospectId) {
          return apiBase.get('/prospects/' + prospectId + '/sms-consent');
        },
        grantProspectSMSConsent: function (
          prospectId,
          consentOrigin,
          prospectPhoneNumber
        ) {
          var payload = {
            consent_origin: consentOrigin,
            phone_number: prospectPhoneNumber
          };

          return apiBase.post(
            '/prospects/' + prospectId + '/sms-consent',
            payload
          );
        },
        revokeSMSConsent: function (prospectId) {
          return apiBase.delete('/prospects/' + prospectId + '/sms-consent');
        },
        declineSMSConsent: function (prospectId) {
          return apiBase.put(
            '/prospects/' + prospectId + '/sms-consent/decline'
          );
        },
        getProspectByKnockId: function (knockId) {
          return apiBase.get('/prospects/knock-id/' + knockId);
        },
        getRenterTokenWithProspectKnockID: function (knockId) {
          return apiBase.get('/prospects/knock-id/' + knockId + '/token');
        },
        createNewProspect: function (payload) {
          return apiBase.post('/prospects', payload);
        },
        getProspect: function (prospectId) {
          return apiBase.get('/prospects/' + prospectId);
        },
        getProspectDuplicates: function (prospectId) {
          return apiBase.get(`/prospect/${prospectId}/duplicate_prospect`);
        },
        getMergeDecisions: function (
          master_prospect_id,
          secondary_prospect_id
        ) {
          return apiBase.post(`/prospect-merge/decisions`, {
            master_prospect_id,
            secondary_prospect_id
          });
        },
        setMergeDecisions: function (
          master_prospect_id,
          secondary_prospect_id,
          defaults
        ) {
          return apiBase.post(`/prospect-merge`, {
            master_prospect_id,
            secondary_prospect_id,
            decisions: defaults
          });
        },
        updateProspect: function (prospectId, payload) {
          return apiBase.put('/prospects/' + prospectId, payload);
        },
        transferProspect: function (prospectId, newManagerId) {
          var payload = {
            newManagerId: newManagerId
          };

          return apiBase.put(
            '/prospects/' + prospectId + '/assigned-manager',
            payload
          );
        },
        transferProspectAppointments: function (prospectId, newManagerId) {
          var payload = {
            newManagerId: newManagerId
          };

          return apiBase.put(
            '/prospects/' + prospectId + '/appointments-manager',
            payload
          );
        },
        updateProspectsBatch: function (
          prospectIds,
          newAssignedManagerId,
          newPropertyId,
          newStatus,
          newSource,
          newReminder
        ) {
          var payload = {
            prospectIds: prospectIds,
            newAssignedManagerId: newAssignedManagerId,
            newPropertyId: newPropertyId,
            newStatus: newStatus,
            newSource: newSource,
            newReminder: newReminder
          };

          return apiBase.put('/prospects', payload);
        },
        updateProspectPropertyBatch: function (prospectIds, newPropertyId) {
          var payload = map(prospectIds, function (id) {
            return {
              id: id,
              property_id: newPropertyId
            };
          });

          return apiBase.put('/prospects', { prospects: payload });
        },
        updateProspectManagerBatch: function (prospectIds, newManagerId) {
          var payload = map(prospectIds, function (id) {
            return {
              id: id,
              assigned_manager_id: newManagerId
            };
          });

          return apiBase.put('/prospects', { prospects: payload });
        },
        updateProspectsSourceBatch: function (prospectIds, newSource) {
          var payload = {
            prospect_ids: prospectIds,
            source: newSource
          };

          return apiBase.post('/prospects/source', payload);
        },
        updateProspectsStatusBatch: function (prospectIds, newStatus) {
          var payload = {
            prospect_ids: prospectIds,
            status: newStatus
          };

          return apiBase.post('/prospects/status', payload);
        },
        getAllLossReasons: function () {
          return apiBase.get('/prospects/loss_reasons', { cache: true });
        },
        deleteProspect: function (prospectId) {
          return apiBase.delete('/prospects/' + prospectId);
        },
        deleteProspects: function (prospectIds, password) {
          return apiBase.post('/prospects/archive', {
            prospect_ids: prospectIds,
            password: password
          });
        },
        restoreProspect: function (prospectId) {
          return apiBase.put('/prospects/' + prospectId + '/restore');
        },
        addNewProspectNote: function (prospectId, noteText) {
          return apiBase.post('/prospects/' + prospectId + '/notes', {
            note: noteText
          });
        },
        updateProspectNote: function (prospectId, noteId, note) {
          return apiBase.put('/prospects/' + prospectId + '/notes/' + noteId, {
            note: note.text
          });
        },
        deleteProspectNote: function (prospectId, noteId) {
          return apiBase.delete(
            '/prospects/' + prospectId + '/notes/' + noteId
          );
        },
        setProspectStatus: function (prospectId, status) {
          var payload = {
            status: status
          };

          return apiBase.post('/prospects/' + prospectId + '/status', payload);
        },
        setProspectStatusBatch: function (prospectsIds, status) {
          return apiBase.post('/prospects/status', {
            prospect_ids: prospectsIds,
            status: status
          });
        },
        updateProspectFirstContactType: function (
          prospectId,
          firstContactType
        ) {
          var payload = {
            first_contact_type: firstContactType
          };

          return apiBase.put(
            '/prospects/' + prospectId + '/first-contact-type',
            payload
          );
        },
        addNewProspectActivity: function (
          prospectId,
          activityType,
          activityMessage,
          reminderTime
        ) {
          var payload = {
            type: activityType,
            message: activityMessage,
            reminder_time: reminderTime
          };

          return apiBase.post(
            '/prospects/' + prospectId + '/activity',
            payload
          );
        },
        updateProspectReminder: function (
          prospectId,
          reminderId,
          date,
          description
        ) {
          var payload = {
            date: date,
            description: description
          };

          return apiBase.put(
            '/prospects/' + prospectId + '/reminders/' + reminderId,
            payload
          );
        },
        deleteProspectReminder: function (prospectId, reminderId) {
          return apiBase.delete(
            '/prospects/' + prospectId + '/reminders/' + reminderId
          );
        },
        getProspectActivity: function (prospectId) {
          return apiBase.get('/prospects/' + prospectId + '/activity/');
        },
        getProspectLossReasons: function (prospectId) {
          return apiBase.get('/prospects/' + prospectId + '/loss_reasons/');
        },
        setProspectLossReasons: function (prospectId, lossReasons) {
          var payload = {
            loss_reasons: lossReasons
          };
          return apiBase.post(
            '/prospects/' + prospectId + '/loss_reasons/',
            payload
          );
        },
        setProspectLossReasonsBatch: function (prospectIds, lossReasons) {
          var payload = {
            loss_reasons: lossReasons,
            prospect_ids: prospectIds
          };
          return apiBase.post('/prospects/loss_reasons/', payload);
        },
        getPropertyProspectReports: function (
          createdStart,
          createdEnd,
          propertyId,
          managerIds,
          status,
          includeUnassigned
        ) {
          var active = status === 'active' || null;
          var inactive = status === 'inactive' || null;

          if (active || inactive) {
            status = null;
          }

          var params = {
            created_start: createdStart,
            created_end: createdEnd,
            property_id: propertyId,
            manager_ids: managerIds,
            status: status,
            active: active,
            inactive: inactive,
            include_unassigned: includeUnassigned
          };

          return apiBase.get('/me/property-reports/prospects', {
            params: params
          });
        },
        getPropertyResidentReports: function (
          propertyId,
          managerIds,
          leaseStart,
          leaseEnd
        ) {
          var params = {
            lease_start: leaseStart,
            lease_end: leaseEnd,
            property_id: propertyId,
            manager_ids: managerIds
          };

          return apiBase.get('/me/property-reports/residents', {
            params: params
          });
        },
        getStatusTransitions: function (status) {
          var params = {
            status: status
          };

          return apiBase.get('/prospects/statuses', { params: params });
        },
        setSourceBatch: function (prospectIds, source) {
          return apiBase.post('/prospects/source', {
            prospect_ids: prospectIds,
            source: source
          });
        },
        setProspectIntegrationMapping: function (
          prospectId,
          integrationVendor,
          integrationCredentialId
        ) {
          var payload = {
            integration_vendor: integrationVendor,
            integration_credential_id: integrationCredentialId
          };

          return apiBase.put(
            '/prospects/' + prospectId + '/integration-mapping',
            payload
          );
        },
        setProspectLeasedDate: function (prospectId, leasedDate) {
          var payload = {
            leased_date: leasedDate
          };

          return apiBase.put('/prospects/' + prospectId + '/leased', payload);
        },
        getProspectsUniqueShortUrl: function (
          prospectId,
          type,
          typeId,
          params
        ) {
          const url =
            '/prospects/' + prospectId + '/shorturl/' + type + '/' + typeId;
          return apiBase.get(url, params);
        },
        getProspectsCSV: function (
          listingIds,
          managerIds,
          status,
          prospectIds,
          includeUnassigned
        ) {
          var active = status === 'active' || null;
          var inactive = status === 'inactive' || null;

          if (active || inactive) {
            status = null;
          }

          return apiBase.post('/me/prospects/csv', {
            listing_ids: listingIds,
            manager_ids: managerIds,
            status: status,
            active: active,
            inactive: inactive,
            prospect_ids: prospectIds,
            include_unassigned: includeUnassigned
          });
        },
        addProspectActivityBatch: function (
          prospectIds,
          activityType,
          activityMessage,
          reminderTime
        ) {
          var payload = {
            type: activityType,
            message: activityMessage,
            reminder_time: reminderTime,
            prospect_ids: prospectIds
          };

          return apiBase.post('/prospects/activity', payload);
        },
        reportProspectExportFailure: function (failure) {
          return apiBase.post('/prospects/export-failure-report', failure);
        },
        getProspectAlternativeContacts: function (prospectId) {
          return apiBase.get(
            '/prospects/' + prospectId + '/alternative-contacts'
          );
        },
        createProspectAlternativeContact: function (
          prospectId,
          firstName,
          lastName,
          email,
          phoneNumber,
          relationshipToPrimary
        ) {
          var payload = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_number: phoneNumber,
            relationship_to_primary: relationshipToPrimary
          };

          return apiBase.post(
            '/prospects/' + prospectId + '/alternative-contact',
            payload
          );
        },
        updateProfileAlternativeContact: function (
          alternativeContactId,
          firstName,
          lastName,
          email,
          phoneNumber,
          relationshipToPrimary
        ) {
          var payload = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_number: phoneNumber,
            relationship_to_primary: relationshipToPrimary
          };

          return apiBase.put(
            '/profile/alternative-contact/' + alternativeContactId,
            payload
          );
        },
        deleteProfileAlternativeContact: function (alternativeContactId) {
          return apiBase.delete(
            '/profile/alternative-contact/' + alternativeContactId
          );
        },
        syncPMSProspect: function (prospectId, credentialId) {
          var payload = {
            credential_id: credentialId
          };

          return apiBase.post(
            '/prospects/' + prospectId + '/synchronize',
            payload
          );
        },
        setProspectExcluded: function (prospectId, excludedReasons) {
          return apiBase.put('/prospects/' + prospectId + '/excluded-reasons', {
            excluded_reasons: excludedReasons
          });
        },
        setProspectNotExcluded: function (prospectId) {
          return apiBase.delete(
            '/prospects/' + prospectId + '/excluded-reasons'
          );
        },
        getProspectEngagementSettings: function (prospectId) {
          return apiBase.get(
            '/prospects/' + prospectId + '/engagement-settings'
          );
        },
        getConnectedProfiles: function (prospectId) {
          return apiBase.get(
            '/prospects/' + prospectId + '/connected-profiles'
          );
        },
        getProspectSelfVerifyIdUrl: function (prospectId) {
          return apiBase.get(
            '/prospects/' + prospectId + '/self-verify-id-url'
          );
        },
        getProspectSelfieScanPDF: function (prospectId) {
          return apiBase.getArrayBuffer(
            '/prospects/' + prospectId + '/selfiescan'
          );
        }
      };
    }
  ]);
})(window.angular);
