import React, { FC, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import {
  Chip,
  InputLabel,
  Tab,
  Tabs,
  TextField,
  Theme
} from '@material-ui/core';
import { Tooltip } from '@knockrentals/knock-shared-web';
import { Close } from '@material-ui/icons';
import { AvailabilityIcon } from '../icons';

import {
  Box,
  Text,
  makeStyles,
  NamedColors,
  Button,
  IconButton,
  white
} from '@knockrentals/knock-shared-web';
import { LeasingBinderOverview } from './LeasingBinderOverview/LeasingBinderOverview';
import { TabPanel } from '../TabPanel';
import { Property } from './models';
import {
  getEmptyProperty,
  propertyHasNotes
} from '../../utilities/propertyUtilities';
import { LeasingBinderAmenities } from './LeasingBinderAmenities/LeasingBinderAmenities';
import { LeasingBinderLeasing } from './LeasingBinderLeasing/LeasingBinderLeasing';
import { LeasingBinderNotes } from './LeasingBinderNotes/LeasingBinderNotes';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { colors } from '../commonStyles/commonStyles';
import { BookTourIcon } from '../icons';
import { ShareBrochureIcon } from '../icons/ShareBrochureIcon';
import { InactiveShareBrochureIcon } from '../icons/InactiveShareBrochureIcon';
const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    color: theme.palette.text.primary,
    position: 'absolute',
    right: '8px',
    top: '8px',

    '&:focus': {
      outline: 'none'
    }
  },
  markAsLostCloseButtonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  markAsLostCloseButton: {
    marginLeft: '20px',
    '& .MuiSvgIcon-root': {
      color: colors.secondaryText
    }
  },
  markAsLostHeaderText: {
    padding: '16px 24px 16px 8px'
  },

  header: {
    display: 'flex',
    padding: '0 24px 8px 24px'
  },

  headerText: {
    padding: '16px 24px 16px 24px'
  },

  label: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.4px',
    lineHeight: '16px',
    marginBottom: 0
  },

  propertySelectorContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },

  prospectStatus: {
    marginLeft: '30px',
    width: '100px'
  },

  prospectStatusLabel: {
    color: theme.palette.text.secondary
  },

  statusChip: {
    fontSize: '13px',
    marginTop: '8px',
    textTransform: 'capitalize'
  },

  prospectNewStatus: {
    background: NamedColors.apple[100],
    color: NamedColors.apple[900]
  },

  prospectNoStatus: {
    background: NamedColors.slate[100],
    color: theme.palette.text.primary
  },

  tabsContainer: {
    backgroundColor: NamedColors.slate[50],
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    overflowY: 'auto'
  },

  shareBroucherButton: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '4px',
    color: NamedColors.denim[600],
    '&:hover': {
      backgroundColor: '#697ff714',
      boxShadow: 'none'
    }
  },

  disabledShareBroucherButton: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none'
  },

  tabs: {
    backgroundColor: white,
    boxShadow: '0 4px 16px -2px #18274b05, 0 2px 12px -4px #18274b0a'
  },

  tabPanels: {
    overflowY: 'auto'
  },

  tab: {
    minWidth: 'inherit',
    padding: '0 24px'
  },

  footer: {
    boxShadow: '0 -4px 16px -2px #18274B05, 0 -2px 12px -4px #18274B0A',
    display: 'flex',
    padding: '16px 24px 16px 24px'
  },

  actions: {
    marginLeft: 'auto'
  },

  tooltip: {
    fontSize: '10px'
  },

  primaryButton: {
    backgroundColor: theme.palette.primary.dark,

    '&:hover': {
      backgroundColor: NamedColors.denim[700]
    },

    '& svg path': {
      stroke: NamedColors.denim[100]
    },

    '& svg': {
      marginRight: '8px',
      marginLeft: '-4px'
    }
  },

  secondaryButton: {
    borderColor: theme.palette.primary.dark,
    padding: '3px 15px'
  },

  buttonSpacer: {
    marginRight: '14px'
  },

  buttonWrap: {
    display: 'inline-block',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: `${NamedColors.slate[50]} !important`,
      boxShadow: 'none'
    }
  }
}));

const usePropertySelectorStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    '&.MuiAutocomplete-inputRoot': {
      paddingTop: '3px',
      paddingBottom: '3px'
    }
  },

  endAdornment: {
    paddingRight: '5px',

    '& .MuiIconButton-root': {
      color: theme.palette.text.secondary
    },

    '& .MuiSvgIcon-root': {
      fontSize: '24px'
    }
  },

  option: {
    '&[aria-selected="true"], &[data-focus="true"][aria-selected="true"]': {
      backgroundColor: NamedColors.denim[100]
    },

    '&[data-focus="true"]': {
      backgroundColor: NamedColors.slate[100]
    }
  }
}));

export const LEASING_BINDER_LABEL = 'Leasing Binder';
export const CLOSE_BUTTON_LABEL = 'Close the binder';
export const PROSPECT_STATUS_LABEL = 'Prospect Status';

export interface LeasingBinderProps {
  properties: Property[];
  managerCommunityIds: number[];
  onClose?: () => void;
  currentProperty?: Property;
  prospectStatus?: string;
  className?: string;
  bookTourAction?: () => void;
  availableUnitsAction?: (property: Property) => void;
  markAsLostCall?: boolean;
  defaultPropertyId?: number;
  attachBrochure?: (propertyId: number) => void;
  isEmailAvailable: boolean;
}

export const LeasingBinder: FC<LeasingBinderProps> = ({
  properties,
  managerCommunityIds,
  onClose,
  currentProperty,
  prospectStatus,
  className,
  bookTourAction,
  availableUnitsAction,
  markAsLostCall,
  defaultPropertyId,
  attachBrochure,
  isEmailAvailable
}) => {
  const classes = useStyles();
  const propertySelectorClasses = usePropertySelectorStyles();

  const [selectedProperty, setSelectedProperty] = useState<Property>(
    getEmptyProperty()
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [showBookTourButton, setShowBookTourButton] = useState<boolean>(true);
  const [showAvailableUnitsButton, setShowAvailableUnitsButton] =
    useState<boolean>(true);

  let statusChipClass = classes.prospectNoStatus;

  if (prospectStatus) {
    switch (prospectStatus.toLowerCase()) {
      case 'new':
        statusChipClass = classes.prospectNewStatus;
        break;
    }
  }

  useEffect(() => {
    if (properties.length > 0) {
      let selectedProperty: Property;

      if (currentProperty) {
        selectedProperty = currentProperty;
      } else {
        selectedProperty = properties[0];
      }

      setSelectedProperty(selectedProperty);

      if (!propertyHasNotes(selectedProperty)) {
        setSelectedTabIndex(1);
      }
    }
  }, [properties, currentProperty]);

  useEffect(() => {
    if (properties.length > 0 && !!defaultPropertyId) {
      setSelectedProperty(
        properties.find(
          (property: Property) => property.property_id === defaultPropertyId
        ) || getEmptyProperty()
      );
    }
  }, [properties, defaultPropertyId]);

  const handleChangeTab = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const handleChangeProperty = (
    event: React.ChangeEvent<{}>,
    value: Property | null,
    _reason: string
  ) => {
    const property = properties.find(
      (item: Property) => item.property_id === value?.property_id
    );
    let managerCommunityId: number | undefined;

    if (managerCommunityIds) {
      managerCommunityId = managerCommunityIds.find(
        (item: number) => item === value?.property_id
      );
    }

    setSelectedProperty(property || getEmptyProperty());

    // We only want to show the available units button for properties that the current manager covers
    if (managerCommunityId === undefined) {
      setShowAvailableUnitsButton(false);
    } else {
      setShowAvailableUnitsButton(true);
    }

    // We only want to allow booking tours for the prospect's current property, not any others the manager may cover
    setShowBookTourButton(value?.property_id === currentProperty?.property_id);
  };

  return (
    <Box className={className ? className : undefined}>
      <Box className={classes.markAsLostCloseButtonContainer}>
        {markAsLostCall && (
          <IconButton
            aria-label={CLOSE_BUTTON_LABEL}
            className={classes.markAsLostCloseButton}
            onClick={onClose}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Text
          variant="h6"
          className={` ${
            markAsLostCall ? classes.markAsLostHeaderText : classes.headerText
          }`}
        >
          {LEASING_BINDER_LABEL}
        </Text>
      </Box>

      {onClose && !markAsLostCall && (
        <IconButton
          aria-label={CLOSE_BUTTON_LABEL}
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close fontSize="small" />
        </IconButton>
      )}

      <Box className={classes.header}>
        <Box className={classes.propertySelectorContainer}>
          <InputLabel
            className={classes.label}
            shrink={true}
            htmlFor="PropertySelector"
          >
            Property
          </InputLabel>

          <Autocomplete
            id="PropertySelector"
            clearOnEscape={true}
            autoComplete={true}
            data-testid="PropertySelector"
            aria-label="property selector"
            disableClearable={true}
            classes={propertySelectorClasses}
            getOptionLabel={(option) => option.location.name}
            options={properties}
            value={selectedProperty}
            onChange={handleChangeProperty}
            renderInput={(params) => <TextField {...params} label="" />}
          />
        </Box>

        {prospectStatus && (
          <Box
            className={classes.prospectStatus}
            data-testid="prospect-status-container"
          >
            <Text variant="caption" className={classes.prospectStatusLabel}>
              {PROSPECT_STATUS_LABEL}
            </Text>
            <Chip
              size="small"
              label={prospectStatus}
              className={`${classes.statusChip} ${statusChipClass}`}
            />
          </Box>
        )}
      </Box>

      <Box className={classes.tabsContainer}>
        <Tabs
          onChange={handleChangeTab}
          aria-label="leasing binder tabs"
          textColor="primary"
          indicatorColor="primary"
          className={classes.tabs}
          value={selectedTabIndex}
        >
          <Tab label="Notes" className={classes.tab} disableRipple={true} />
          <Tab label="Overview" className={classes.tab} disableRipple={true} />
          <Tab label="Amenities" className={classes.tab} disableRipple={true} />
          <Tab label="Leasing" className={classes.tab} disableRipple={true} />
        </Tabs>

        <Box className={classes.tabPanels}>
          <TabPanel value={selectedTabIndex} index={0}>
            <LeasingBinderNotes property={selectedProperty} />
          </TabPanel>

          <TabPanel value={selectedTabIndex} index={1}>
            <LeasingBinderOverview property={selectedProperty} />
          </TabPanel>

          <TabPanel value={selectedTabIndex} index={2}>
            <LeasingBinderAmenities property={selectedProperty} />
          </TabPanel>

          <TabPanel value={selectedTabIndex} index={3}>
            <LeasingBinderLeasing property={selectedProperty} />
          </TabPanel>
        </Box>
      </Box>

      {!markAsLostCall &&
        ((bookTourAction && showBookTourButton) ||
          isEmailAvailable ||
          (availableUnitsAction && showAvailableUnitsButton)) && (
          <Box className={classes.footer}>
            {isEmailAvailable ? (
              <Button
                className={`${classes.shareBroucherButton}`}
                onClick={() =>
                  attachBrochure
                    ? attachBrochure(selectedProperty.property_id)
                    : null
                }
                data-testid="attach-broucher"
                startIcon={<ShareBrochureIcon />}
              >
                Share Brochure
              </Button>
            ) : (
              <Tooltip
                title="No prospect email on file"
                arrow
                classes={{ tooltip: classes.tooltip }}
              >
                <div className={classes.buttonWrap}>
                  <Button
                    disabled
                    className={`${classes.disabledShareBroucherButton}`}
                    data-testid="attach-broucher"
                    startIcon={<InactiveShareBrochureIcon />}
                  >
                    Share Brochure
                  </Button>
                </div>
              </Tooltip>
            )}
            <Box className={classes.actions}>
              {bookTourAction && showBookTourButton && (
                <Button
                  variant={availableUnitsAction ? 'outlined' : undefined}
                  className={
                    availableUnitsAction
                      ? `${classes.buttonSpacer} ${classes.secondaryButton}`
                      : undefined
                  }
                  onClick={bookTourAction}
                  data-testid="book-tour-button"
                  startIcon={<BookTourIcon />}
                >
                  Book Tour
                </Button>
              )}
              {availableUnitsAction && showAvailableUnitsButton && (
                <Button
                  onClick={() => availableUnitsAction(selectedProperty)}
                  className={classes.primaryButton}
                  data-testid="available-units-button"
                >
                  <AvailabilityIcon />
                  View Availability
                </Button>
              )}
            </Box>
          </Box>
        )}
    </Box>
  );
};
