/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import countBy from 'lodash/countBy';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const ProspectMessageController = function (
    $window,
    $timeout,
    $mdDialog,
    $mdToast,
    $rootScope,
    $scope,
    appDataService,
    recipients,
    conversationsService
  ) {
    var self = this;

    $scope.emailAttachments = $scope.emailAttachments || {
      local: [],
      remote: []
    };

    $scope.message = {
      subject: '',
      text: '',
      html: ''
    };

    $scope.data = {
      isSending: false,
      leasingTeamId: null,
      recipients: recipients,
      nonSendableCounts: {
        email: 0
      },
      target: 'email'
    };

    self._initialize = function () {
      self._getLeasingTeamId();
      self._getNonSendableCounts();
      self._getValidRecipients();
    };

    self._getLeasingTeamId = function () {
      $scope.data.leasingTeamId = appDataService.getCurrentLeasingTeamId();
    };

    self._getNonSendableCounts = function () {
      $scope.data.nonSendableCounts.email =
        countBy($scope.data.recipients, self._canRecipientReceiveEmail).false ||
        0;
    };

    self._canRecipientReceiveEmail = function (recipient) {
      return !!recipient.info.email;
    };

    self._getValidRecipients = function () {
      $scope.data.recipientsCopy = cloneDeep(recipients);

      $scope.data.validRecipients = remove(
        $scope.data.recipientsCopy,
        function (recipient) {
          return self._canRecipientReceiveEmail(recipient);
        }
      );
    };

    $scope.cancelMessage = function () {
      $mdDialog.hide();
    };

    $scope.sendMessage = function (target) {
      if (target === 'email') {
        $scope.attachments = $scope.emailAttachments;
      }

      $scope.data.isSending = true;

      var streamIds = map($scope.data.validRecipients, 'threadId');

      conversationsService
        .sendMessageBatch(
          streamIds,
          $scope.message.text,
          $scope.message.html,
          $scope.message.subject,
          [target],
          $scope.attachments.remote
        )
        .then(
          function () {
            $mdToast.showSimple('Messages sending!');
            $mdDialog.hide();
            $scope.message.subject = '';
            $scope.message.text = '';
            $scope.message.html = '';
            $scope.data.isSending = false;
          },
          function () {
            $mdToast.showSimple('Error sending messages!');
            $scope.data.isSending = false;
          }
        );
    };

    self._initialize();
  };

  ProspectMessageController.$inject = [
    '$window',
    '$timeout',
    '$mdDialog',
    '$mdToast',
    '$rootScope',
    '$scope',
    'appDataService',
    'recipients',
    'conversationsService'
  ];

  app.controller('ProspectMessageController', ProspectMessageController);
})(window.angular);
