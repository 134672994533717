/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('managerLogin', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/login/manager-login-body.html',
      scope: {
        onLoggedIn: '=',
        onToggleForgotPassword: '='
      },
      controller: [
        '$scope',
        '$interpolate',
        'loginService',
        function ($scope, $interpolate, loginService) {
          var errorMessageStrings = loginService.getErrorMessageStrings();

          $scope.manager = {
            username: '',
            password: '',
            type: 'manager'
          };

          $scope.usernameLogin = function () {
            $scope.emailErrorMessage = null;
            $scope.isLoggingIn = true;

            loginService
              .usernameLogin($scope.manager)
              .then(function (response) {
                $scope.onLoggedIn(response);
              })
              .catch(function (response) {
                if (loginService.isPrivateBrowsingError(response)) {
                  $scope.emailErrorMessage =
                    errorMessageStrings.privateBrowsingErrorMessage;
                  return;
                }

                var existingAccountProvider =
                  loginService.getExistingAccountProvider(response);
                if (existingAccountProvider) {
                  $scope.emailErrorMessage = $interpolate(
                    errorMessageStrings.useSocialLoginMessageTemplate
                  )({ provider: existingAccountProvider });
                  return;
                }

                $scope.emailErrorMessage =
                  errorMessageStrings.invalidLoginMessage;
              })
              .finally(function () {
                $scope.isLoggingIn = false;
              });
          };
        }
      ]
    };
  });
})();
