/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.controller('ConversationPageController', [
    '$rootScope',
    '$q',
    '$scope',
    '$routeParams',
    '$location',
    'pageTitleService',
    'conversationsService',
    'userInteractionsService',
    'streamRepositoryService',
    'streamCarouselService',
    'userService',
    function (
      $rootScope,
      $q,
      $scope,
      $routeParams,
      $location,
      pageTitleService,
      conversationsService,
      userInteractionsService,
      streamRepositoryService,
      streamCarouselService,
      userService
    ) {
      var self = this;
      var currentUser = userService.getScopedUser();

      $scope.isRenter = currentUser && currentUser.type === 'renter';
      $scope.chatThreadId = $routeParams.chatThreadId;
      $scope.batchId = $routeParams.batchId;

      var appointmentTransferredHandler = $rootScope.$on(
        userInteractionsService.events.appointmentTransferred,
        function () {
          self._initialize();
        }
      );

      var participantUpdatedHandler = $rootScope.$on(
        conversationsService.events.participantUpdated,
        function (event, payload) {
          if (
            conversationsService.isParticipant(
              payload.participant_type,
              payload.participant_id,
              $scope.conversation
            )
          ) {
            self._initialize();
          }
        }
      );

      $scope.$on('$destroy', function () {
        appointmentTransferredHandler();
        participantUpdatedHandler();
      });

      self._initialize = function () {
        if ($routeParams.batchId) {
          streamRepositoryService
            .getStreamBatch($routeParams.batchId)
            .then(function (response) {
              streamCarouselService.showCarousel(
                response.data.stream_ids,
                $routeParams.batchId,
                $routeParams.chatThreadId
              );
            });
        }

        var initPromises = [self._getConversation()];

        $q.all(initPromises).finally(self._loadFinished);
      };

      self._loadFinished = function () {
        $scope.isLoaded = true;
      };

      self._getConversation = function () {
        return conversationsService
          .getConversation($scope.chatThreadId)
          .error(self._getConversationError)
          .success(self._getConversationSuccess);
      };

      self._getConversationSuccess = function (response) {
        $scope.conversation = response.stream;
        conversationsService.onThreadLoaded(response.stream.id);
        var prospectParticipant = find(
          response.stream.participants,
          function (participant) {
            return ['prospect', 'resident'].indexOf(participant.type) >= 0;
          }
        );

        if (prospectParticipant) {
          var pageTitle =
            prospectParticipant.info.first_name +
            ' ' +
            prospectParticipant.info.last_name +
            ' | Knock';
          pageTitleService.setPageTitle(pageTitle);
        }
      };

      self._getConversationError = function (response, statusCode) {
        if (statusCode === 302 && response.leasing_team) {
          $location.path(`/conversation/${$scope.chatThreadId}`);
          $location.search({ lt: response.leasing_team });
          self._initialize();
        } else {
          $scope.conversationLoadError = true;
        }
      };

      self._initialize();
    }
  ]);
})(window.angular);
