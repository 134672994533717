/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const LeasingTeamCommunityProspectSMSConsentController = function (
    $scope,
    $routeParams,
    $mdDialog,
    $mdToast,
    userService,
    pageTitleService,
    prospectsApi,
    chatTrackingService,
    $rootScope
  ) {
    var self = this;

    var prospectId = $scope.prospectId || $routeParams.prospectId;
    var user = userService.getUser();

    $scope.isManager = user && user.type === 'manager';

    self._initialize = function () {
      chatTrackingService.hide();
      $rootScope.$on('SalesForceWidgetMounted', () => {
        chatTrackingService.hide();
      });
      $scope.isLoading = true;

      if ($scope.isManager) {
        $scope.isLoading = false;
        $scope.hasConsent = false;

        $scope.communityName = 'Your Community';
        $scope.phone = {
          national_format: ''
        };

        return;
      }

      prospectsApi
        .getProspectSMSConsentData(prospectId)
        .success(function (response) {
          var consentData = response.data;

          $scope.hasConsent = consentData.has_consent;
          $scope.communityName = consentData.community_name;
          $scope.phone = {
            national_format: consentData.prospect_phone_number
          };

          if (!$scope.keepPageTitle) {
            pageTitleService.setPageTitle('Grant SMS Consent');
          }

          $scope.isLoading = false;
        });
    };

    $scope.phoneIsValid = function () {
      return $scope.phone.phone_number;
    };

    $scope.grantConsent = function () {
      if ($scope.isManager) {
        return;
      }

      $scope.isGrantingConsent = true;

      var prospectPhoneNumber = $scope.phone.national_format;

      prospectsApi
        .grantProspectSMSConsent(
          prospectId,
          'SMS consent form',
          prospectPhoneNumber
        )
        .success(
          function () {
            $scope.didGrantConsent = true;
            $scope.isGrantingConsent = false;

            if ($scope.canRejectConsent) {
              $mdDialog.hide();
            }
          },
          function () {
            $scope.isGrantingConsent = false;
            $mdToast.showSimple('There was an error submitting your form.');
          }
        );
    };

    $scope.rejectConsent = function () {
      if ($scope.isManager) {
        $mdDialog.cancel();
        return;
      }

      prospectsApi.declineSMSConsent(prospectId);
      $mdDialog.cancel();
    };

    self._initialize();
  };

  LeasingTeamCommunityProspectSMSConsentController.$inject = [
    '$scope',
    '$routeParams',
    '$mdDialog',
    '$mdToast',
    'userService',
    'pageTitleService',
    'prospectsApi',
    'chatTrackingService',
    '$rootScope'
  ];
  app.controller(
    'LeasingTeamCommunityProspectSMSConsentController',
    LeasingTeamCommunityProspectSMSConsentController
  );

  const LeasingTeamCommunityResidentSMSConsentController = function (
    $scope,
    $routeParams,
    $mdDialog,
    $mdToast,
    userService,
    pageTitleService,
    residentsApi
  ) {
    var self = this;

    var residentStreamId = $routeParams.residentStreamId;
    var user = userService.getUser();

    $scope.isManager = user && user.type === 'manager';

    self._initialize = function () {
      $scope.isLoading = true;

      if ($scope.isManager) {
        $scope.isLoading = false;
        $scope.hasConsent = false;

        $scope.communityName = 'Your Community';
        $scope.phone = {
          national_format: ''
        };

        return;
      }

      residentsApi
        .getResidentSMSConsentData(residentStreamId)
        .success(function (response) {
          var consentData = response.data;

          $scope.hasConsent = consentData.has_consent;
          $scope.communityName = consentData.community_name;
          $scope.phone = {
            national_format: consentData.resident_phone_number
          };

          if (!$scope.keepPageTitle) {
            pageTitleService.setPageTitle('Grant SMS Consent');
          }

          $scope.isLoading = false;
        });
    };

    $scope.phoneIsValid = function () {
      return $scope.phone.phone_number;
    };

    $scope.grantConsent = function () {
      if ($scope.isManager) {
        return;
      }

      $scope.isGrantingConsent = true;

      var residentPhoneNumber = $scope.phone.national_format;

      residentsApi
        .grantResidentSMSConsent(
          residentStreamId,
          'SMS consent form',
          residentPhoneNumber
        )
        .success(
          function () {
            $scope.didGrantConsent = true;
            $scope.isGrantingConsent = false;

            if ($scope.canRejectConsent) {
              $mdDialog.hide();
            }
          },
          function () {
            $scope.isGrantingConsent = false;
            $mdToast.showSimple('There was an error submitting your form.');
          }
        );
    };

    $scope.rejectConsent = function () {
      if ($scope.isManager) {
        $mdDialog.cancel();
        return;
      }

      residentsApi.declineSMSConsent(residentStreamId);
      $mdDialog.cancel();
    };

    self._initialize();
  };

  LeasingTeamCommunityResidentSMSConsentController.$inject = [
    '$scope',
    '$routeParams',
    '$mdDialog',
    '$mdToast',
    'userService',
    'pageTitleService',
    'residentsApi'
  ];
  app.controller(
    'LeasingTeamCommunityResidentSMSConsentController',
    LeasingTeamCommunityResidentSMSConsentController
  );
})(window.angular);
