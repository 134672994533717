/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('managerApi', [
    '$interpolate',
    'apiBase',
    function ($interpolate, apiBase) {
      return {
        getManager: function () {
          return apiBase.get('/manager/');
        },
        updateManagerBrowserCallingSettings: function (
          outboundPhoneNumber,
          phoneNumbers
        ) {
          return apiBase.post('/manager/browser-calling/settings', {
            outbound_phone_number: outboundPhoneNumber,
            phone_numbers: phoneNumbers
          });
        },
        getManagerInfoById: function (managerId) {
          return apiBase.get('/managers/info?id=' + managerId);
        },
        getManagersInfoById: function (managerIds) {
          var params = {
            id: managerIds
          };

          return apiBase.get('/managers/info', { params: params });
        },
        postManagerInfoById: function (managerId, managerInfo) {
          return apiBase.post('/manager/' + managerId + '/info', managerInfo);
        },
        getManagerPreferences: function () {
          return apiBase.get('/manager/preferences');
        },
        getManagerTemplates: function () {
          return apiBase.get('/me/templates');
        },
        updateManagerTemplates: function (payload) {
          return apiBase.put('/me/templates', payload);
        },
        postManagerPreferences: function (payload) {
          return apiBase.post('/manager/preferences', payload);
        },
        updateDisplayTeam: function (displayTeam) {
          return apiBase.put('/manager/preferences/displayTeam', {
            display_team: displayTeam
          });
        },
        getManagerPreferencesById: function (managerId) {
          return apiBase.get('/manager/' + managerId + '/preferences');
        },
        postManagerPreferencesById: function (managerId, preferences) {
          return apiBase.post(
            '/manager/' + managerId + '/preferences',
            preferences
          );
        },
        getManagerRequirements: function () {
          return apiBase.get('/me/requirements');
        },
        postManagerRequirements: function (managerRequirements) {
          return apiBase.post('/manager/requirements', managerRequirements);
        },
        getManagerRequirementsById: function (managerId) {
          return apiBase.get('/manager/' + managerId + '/requirements');
        },
        postManagerRequirementsById: function (managerId, managerRequirements) {
          return apiBase.post(
            '/manager/' + managerId + '/requirements',
            managerRequirements
          );
        },
        setManagerMessagingPreferences: function (shouldAccept) {
          var payload = {
            should_accept: shouldAccept
          };

          return apiBase.put('/manager/messaging/preferences', payload);
        },
        deactivateListing: function (
          listingId,
          message,
          keepAppointments,
          prospectIdsToNotify
        ) {
          var args = {
            manager_message: message,
            should_keep_appointments: keepAppointments,
            prospect_ids_to_notify_of_deactivation: prospectIdsToNotify
          };

          return apiBase.put('/listing/' + listingId + '/deactivate', args);
        },
        getManagerCalendar: function (start, end) {
          var params = {
            start: start,
            end: end
          };

          return apiBase.get('/manager/calendar', { params: params });
        },
        getManagerCalendarHours: function (managerId, start, end) {
          var params = {
            start: start,
            end: end
          };

          return apiBase.get('/manager/' + managerId + '/calendar/hours', {
            params: params
          });
        },
        postCalendarEvent: function (payload) {
          return apiBase.post('/manager/calendar/events', payload);
        },
        updateCalendarEvent: function (eventId, payload) {
          return apiBase.put('/manager/calendar/events/' + eventId, payload);
        },
        deleteCalendarEvent: function (eventId, recurrenceDate, deleteMethod) {
          var payload = {
            recurrence_date: recurrenceDate,
            delete_method: deleteMethod
          };

          return apiBase.delete('/manager/calendar/events/' + eventId, {
            params: payload
          });
        },
        getExternalCalendarEvents: function (start, end) {
          var params = {
            start: start,
            end: end
          };

          return apiBase.get('/manager/calendar/external/events', {
            params: params
          });
        },
        getManagerHours: function () {
          return apiBase.get('/manager/hours');
        },
        getManagerHoursById: function (managerId) {
          return apiBase.get('/manager/' + managerId + '/hours');
        },
        updateManagerHours: function (payload) {
          return apiBase.post('/manager/hours', payload);
        },
        updateManagerHoursById: function (managerId, payload) {
          return apiBase.post('/manager/' + managerId + '/hours', payload);
        },
        getMyCommunities: function (
          includePhoneNumbers,
          includePropertyHours,
          includeCustomFees,
          includeCrossSellRadius
        ) {
          const params = {};

          if (includePhoneNumbers) {
            params.get_forwarding_numbers = true;
          }
          if (includePropertyHours) {
            params.get_office_hours = true;
          }
          if (includeCustomFees) {
            params.get_custom_fees = true;
          }

          if (includeCrossSellRadius) {
            params.get_cross_sell_radius = true;
          }

          if (Object.keys(params).length > 0) {
            return apiBase.get('/me/communities', { params });
          } else {
            return apiBase.get('/me/communities');
          }
        },
        getMyFloorplans: function () {
          return apiBase.get('/manager/floorplans');
        },
        getCommunityFloorplans: function (communityId, status) {
          var url = '/manager/community/' + communityId + '/floorplan';

          return apiBase.get(url, {
            params: {
              status: status
            }
          });
        },
        deleteFloorplan: function (floorplanId, password) {
          return apiBase.delete(
            '/manager/community/floorplan/' +
              floorplanId +
              '?password=' +
              password
          );
        },
        getFloorplan: function (floorplanId) {
          return apiBase.get('/manager/community/floorplan/' + floorplanId);
        },
        updateFloorplan: function (floorplanId, payload) {
          return apiBase.put('/manager/community/floorplan/' + floorplanId, {
            floorplan: payload
          });
        },
        createFloorplanListing: function (floorplanId, leasingPayload) {
          return apiBase.post(
            '/manager/community/floorplan/' + floorplanId + '/listing',
            leasingPayload
          );
        },
        createChildManagerAccount: function (userPayload) {
          var signupPayload = {
            user: userPayload,
            plan: {
              companyName: ''
            }
          };

          return apiBase.post('/auth/register/child', signupPayload);
        },
        getExternalCalendars: function () {
          return apiBase.get('/manager/calendar/external');
        },
        getExternalCalendarsCount: function () {
          return apiBase.get('/manager/calendar/external/count');
        },
        syncExternalCalendar: function (provider, calendarId) {
          var escapedCalendarId = encodeURIComponent(calendarId);
          return apiBase.put(
            '/manager/calendar/external/' +
              provider +
              '/calendar/' +
              escapedCalendarId
          );
        },
        unsyncExternalCalendar: function (provider, calendarId) {
          var escapedCalendarId = encodeURIComponent(calendarId);
          return apiBase.delete(
            '/manager/calendar/external/' +
              provider +
              '/calendar/' +
              escapedCalendarId
          );
        },
        getPreferredTimezone: function () {
          return apiBase.get('/me/timezone');
        },
        setPreferredTimezone: function (timezone) {
          return apiBase.put('/me/timezone', { timezone: timezone });
        },
        getMyKnockEmail: function () {
          return apiBase.get('/me/knock-email');
        },
        getKnockEmailById: function (managerId) {
          return apiBase.get('/manager/' + managerId + '/knock-email');
        },
        updateAutoResponder: function (
          managerId,
          includeAllListings,
          reminderDelayMinutes
        ) {
          var payload = {
            includeAllListings: includeAllListings,
            reminderDelayMinutes: reminderDelayMinutes
          };

          return apiBase.put(
            '/manager/' + managerId + '/auto-responder',
            payload
          );
        },
        getSuggestions: function (query) {
          var params = {
            q: query
          };

          return apiBase.get('/managers/suggestions', { params: params });
        },
        sendFeatureFeedback: function (featureId) {
          return apiBase.post('/manager/feedback', { feature_id: featureId });
        },
        getManagerInfoByMailer: function (mailerId) {
          return apiBase.get('/manager/' + mailerId + '/info');
        },
        getManagerQuickReplies: function (propertyId) {
          return apiBase.get(`/me/quick-replies/${propertyId}`);
        },
        getManagerQuickRepliesBulkMessaging: function (leasingTeamId) {
          return apiBase.get(`/me/quick-replies/leasing-team/${leasingTeamId}`);
        },
        updateManagerQuickReply: function (managerId, payload) {
          return apiBase.post(
            '/manager/' + managerId + '/quick-replies',
            payload
          );
        },
        deleteManagerQuickReply: function (
          managerId,
          messageId,
          useDeleteEndpoint
        ) {
          var payload;

          // TODO: Once the enhanced quick replies feature is in use by all users, this extra logic can be removed and the DELETE endpoint can
          //       always be used
          if (useDeleteEndpoint) {
            payload = {
              data: {
                message_id: messageId
              }
            };

            return apiBase.delete(
              `/manager/${managerId}/quick-replies`,
              payload
            );
          } else {
            payload = {
              manager_id: managerId,
              message_id: messageId
            };

            return apiBase.put(`/manager/${managerId}/quick-replies`, payload);
          }
        }
      };
    }
  ]);
})(window.angular);
