/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('notificationsPanel', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/notifications/notifications-panel.html',
      controller: 'NotificationsPanelController'
    };
  });

  app.controller('NotificationsPanelController', [
    '$rootScope',
    '$scope',
    '$location',
    'apiEvents',
    'notificationsApi',
    'appDataService',
    'notificationsService',
    'pageTitleService',
    function (
      $rootScope,
      $scope,
      $location,
      apiEvents,
      notificationsApi,
      appDataService,
      notificationsService,
      pageTitleService
    ) {
      var self = this;
      var maxNotifications = 8;

      $scope.notifications = [];
      $scope.unreadCount = 0;

      $scope.isVisible = false;
      $scope.isMultiteam = appDataService.isMultiteamUser();

      var toggleNotificationHandler = $rootScope.$on(
        apiEvents.toggleNotifications,
        function () {
          $scope.toggleVisible();
        }
      );

      var closeNotificationHandler = $rootScope.$on(
        apiEvents.closeNotifications,
        function () {
          if ($scope.isVisible) {
            $scope.toggleVisible();
          }
        }
      );

      $scope.$on('$destroy', function () {
        toggleNotificationHandler();
        closeNotificationHandler();
      });

      $scope.toggleVisible = function () {
        $scope.isVisible = !$scope.isVisible;

        if (!$scope.isVisible) {
          $scope.readAllNotifications();
        }
      };

      $scope.showNotificationHistory = function () {
        $scope.toggleVisible();
        $location.url('/notifications');
      };

      $scope.readAllNotifications = function () {
        if ($scope.unreadCount > 0) {
          $scope.unreadCount = 0;

          forEach($scope.notifications, function (notification) {
            notification.isRead = true;
          });

          self._updateUnreadCount();

          var notificationIds = map($scope.notifications, 'id');
          notificationsApi.readNotifications(notificationIds);
        }
      };

      self._updateUnreadCount = function () {
        $scope.unreadCount = filter($scope.notifications, {
          isRead: false
        }).length;
        pageTitleService.setNotificationBadgeCount($scope.unreadCount);
      };

      self._initialize = function () {
        notificationsService.registerNotificationCallback(
          self._onNewNotification
        );

        self._getNotifications();
      };

      self._onNewNotification = function (rawNotification) {
        $scope.$apply(function () {
          var notification =
            notificationsService.createNotificationModel(rawNotification);
          $scope.notifications.unshift(notification);

          self._updateUnreadCount();
        });
      };

      self._getNotifications = function () {
        return notificationsApi
          .lastNNotifications(maxNotifications)
          .success(function (response) {
            var sortedNotifications = sortBy(
              response.notifications,
              'created_time'
            ).reverse();

            forEach(sortedNotifications, function (notificationResponse) {
              var notification =
                notificationsService.createNotificationModel(
                  notificationResponse
                );
              $scope.notifications.push(notification);
            });

            self._updateUnreadCount();
          });
      };

      self._initialize();
    }
  ]);
})(window.angular);
