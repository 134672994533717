/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular, $) {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('CommunityDetailsController', [
    '$scope',
    '$window',
    '$location',
    '$q',
    '$routeParams',
    '$moment',
    '$mdDialog',
    '$auth',
    'chatTrackingService',
    'pageTitleService',
    'userService',
    'communityApi',
    'appointmentsApi',
    'managerApi',
    'prospectsApi',
    'directionsService',
    'prospectHistoryService',
    '$rootScope',
    function (
      $scope,
      $window,
      $location,
      $q,
      $routeParams,
      $moment,
      $mdDialog,
      $auth,
      chatTrackingService,
      pageTitleService,
      userService,
      communityApi,
      appointmentsApi,
      managerApi,
      prospectsApi,
      directionsService,
      prospectHistoryService,
      $rootScope
    ) {
      var self = this;
      var communityId = $routeParams.communityId;
      var isExternal = $routeParams.isExternal === 'true';
      var companyName = $routeParams.companyName;
      var prospectKnockID = $routeParams.pid;
      var user = userService.getUser();

      $scope.photoModal = {
        isLargePhotoModalVisible: false
      };

      $scope.openLargePhotoModal = function () {
        $scope.photoModal.isLargePhotoModalVisible = true;
      };

      $scope.scheduleTour = function (listing) {
        var url = $scope.getListingUrl(listing.id);
        $window.open(url);
      };

      $scope.getDirections = function () {
        directionsService.openDirectionsPage(
          $scope.community.location.address.raw
        );
      };

      $scope.getListingUrl = function (listingId) {
        var queryParams = $location.search();

        if (isExternal) {
          queryParams.isExternal = isExternal;
          queryParams.companyName = companyName;
        }

        return '/listing/' + listingId + '?' + $.param(queryParams);
      };

      $scope.saveProspect = function () {
        $scope.isSaving = true;
        $scope.isNotSuccessful = false;

        $scope.newProspect.first_contact_type = 'chat';

        prospectHistoryService
          .createNewProspect($scope.newProspect)
          .success(function () {
            $scope.isSuccessful = true;
          })
          .error(function () {
            $scope.isNotSuccessful = true;
          })
          .finally(function () {
            $scope.isSaving = false;
          });
      };

      $scope.onAppointmentCanceled = function () {
        $scope.appointment = null;
      };

      self._initialize = function () {
        $scope.isLoaded = false;

        chatTrackingService.hide();
        $rootScope.$on('SalesForceWidgetMounted', () => {
          chatTrackingService.hide();
        });

        self._getCommunity().then(function () {
          var initPromises = [
            self._getCommunityManager(),
            self._getCommunityCompany(),
            self._getCommunityListings()
          ];

          if (user && user.type === 'renter') {
            initPromises.push(self._getUpcomingPropertyAppointments());
          }

          $q.all(initPromises).finally(function () {
            $scope.isLoaded = true;

            $scope.newProspect = {
              manager_id: $scope.managerInfo.manager_id,
              source: 'Knock',
              community_id: $scope.community.id,
              status: 'new'
            };
          });
        });

        self._validateSMSConsent();
      };

      self._getCommunity = function () {
        return communityApi.getCommunity(communityId).then(function (response) {
          $scope.community = response.data.community;

          var pageTitle =
            $scope.community.location.name + ' - Schedule a tour!';
          pageTitleService.setPageTitle(pageTitle);
        });
      };

      self._getCommunityManager = function () {
        return communityApi
          .getCommunityManager(communityId)
          .success(function (response) {
            $scope.managerInfo = response.manager_info;
          });
      };

      self._getCommunityCompany = function () {
        return communityApi
          .getCommunityCompany(communityId)
          .success(function (response) {
            $scope.company = response.company;
          });
      };

      self._getCommunityListings = function () {
        var includeInactive = false;
        var includeHidden = false;

        return communityApi
          .getCommunityListings(communityId, includeInactive, includeHidden)
          .success(function (response) {
            $scope.listings = response.listings;
          });
      };

      self._getUpcomingPropertyAppointments = function () {
        var query = {
          property_id: $scope.community.property_id,
          start: $moment().format(),
          status: 'confirmed'
        };

        return appointmentsApi.getAppointments(query).then(function (response) {
          if (response.data.appointments.length > 0) {
            $scope.appointment = response.data.appointments[0];
          }
        });
      };

      self._validateSMSConsent = function () {
        if (prospectKnockID) {
          return prospectsApi
            .getProspectByKnockId(prospectKnockID)
            .then(function (response) {
              $scope.prospect = response.data.prospect;

              $scope.shouldRequestSMSConsent =
                !$scope.prospect.sms_consent ||
                $scope.prospect.sms_consent.status !== 'granted';

              if ($scope.shouldRequestSMSConsent) {
                prospectsApi
                  .getRenterTokenWithProspectKnockID(prospectKnockID)
                  .then(function (response) {
                    $auth.setToken(response.data.access_token);
                    self._openSMSConsentModal();
                  });
              }
            });
        }
      };

      self._openSMSConsentModal = function () {
        if (!$scope.prospect.profile.phone_id) {
          return;
        }

        return $mdDialog.show({
          controller: 'CommunitySMSConsentModalController',
          templateUrl:
            '/angular/views/community/directives/community-sms-consent-modal.html',
          clickOutsideToClose: false,
          locals: {
            prospectId: $scope.prospect.id
          }
        });
      };

      self._initialize();
    }
  ]);
})(window.angular, window.jQuery);
