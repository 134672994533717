/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('EditAppointmentReviewController', [
    '$scope',
    '$routeParams',
    'appointmentsApi',
    'propertyApi',
    function ($scope, $routeParams, appointmentsApi, propertyApi) {
      var self = this;
      $scope.isLoading = true;

      self.initialize = function () {
        appointmentsApi
          .getReview($routeParams.appointmentId)
          .success(function (response) {
            if (isEmpty(response)) {
              return;
            }

            $scope.review = response.review.review;

            if (isEmpty($scope.review)) {
              $scope.review = {
                price: 'none',
                location: 'none',
                condition: 'none',
                amenities: 'none',
                layout: 'none',
                will_apply: null
              };
            }

            $scope.appointment = response.appointment;
            $scope.profile = response.appointment.prospect.profile;
            $scope.propertyData = response.property_data;
          })
          .finally(function () {
            $scope.isLoading = false;
          });
      };

      $scope.saveReview = function () {
        $scope.isSaving = true;

        appointmentsApi
          .saveReview(
            $routeParams.appointmentId,
            $routeParams.token,
            $scope.review
          )
          .success(function () {
            $scope.savedSuccessfully = true;
          })
          .error(function () {
            $scope.savedSuccessfully = false;
          })
          .finally(function () {
            $scope.isSaving = false;
          });
      };

      self.initialize();
    }
  ]);
})(window.angular);
