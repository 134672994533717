import React, { FC, useRef, useState } from 'react';
import {
  Popover,
  Box,
  List,
  ListItem,
  IconButton,
  ListItemText,
  makeStyles
} from '@material-ui/core';

import { HelpIcon } from '../../../icons';
import { Tooltip } from '@knockrentals/knock-shared-web';
import { useCommonStyles } from '../../../commonStyles/commonStyles';

const useStyles = makeStyles({
  boxStyle: {
    width: '258px',
    heigth: '124px'
  },
  dropDownIcon: {
    paddingRight: '20px'
  }
});

interface HelpMenuList {
  id: number;
  URL?: string;
  icon: React.ReactNode;
  primaryText: string;
}

interface HelpMenuProps {
  helpMenuList: HelpMenuList[];
}

export const HelpMenu: FC<HelpMenuProps> = ({ helpMenuList }) => {
  const [open, setOpen] = useState(false);
  const helpRef = useRef<HTMLButtonElement>(null);
  const { boxStyle, dropDownIcon } = useStyles();
  const tooltipTitle = 'Help';
  const commonClasses = useCommonStyles();

  return (
    <>
      <Tooltip title={tooltipTitle} arrow={true} placement="bottom">
        <IconButton
          className={commonClasses.menuIconButton}
          aria-describedby="popover-help"
          ref={helpRef}
          onClick={() => setOpen(!open)}
          component="span"
          data-testid="help-icon"
        >
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={helpRef.current}
        onClose={() => setOpen(!open)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        role="help-popover"
      >
        <Box className={boxStyle}>
          <List>
            {helpMenuList.map((helpMenuItem: any) => (
              <ListItem
                component="a"
                href={helpMenuItem.URL}
                target="_blank"
                key={helpMenuItem.id}
              >
                <div className={dropDownIcon}>
                  <helpMenuItem.icon />
                </div>
                <ListItemText primary={helpMenuItem.primaryText} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
};
