import React, { FC, useRef, useMemo } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import {
  CallIcon,
  VoiceAppCancelBadgeIcon,
  VoiceAppOnCallBadgeIcon,
  VoiceAppOnlineBadgeIcon,
  VoiceAppOnLoadingBadgeIcon
} from '../../../icons';
import { Tooltip } from '@knockrentals/knock-shared-web';
import { useCommonStyles } from '../../../commonStyles/commonStyles';

interface VoiceAppCallProps {
  openKnockVoice: () => void;
  isVoiceAppStatusFeatureEnable: boolean;
  voiceAppStatus: string | undefined;
  focusOnKnockVoice: () => void;
}

const useStyles = makeStyles({
  cancelIcon: {
    position: 'absolute',
    top: '11px',
    right: '11px'
  },
  tooltipStyle: {
    textAlign: 'center',
    display: 'block'
  }
});

export const VoiceAppCall: FC<VoiceAppCallProps> = ({
  openKnockVoice,
  isVoiceAppStatusFeatureEnable,
  voiceAppStatus,
  focusOnKnockVoice
}) => {
  const helpRef = useRef<HTMLButtonElement>(null);
  const commonClasses = useCommonStyles();

  const openVoiceAppCall = () => {
    if (voiceAppStatus && voiceAppStatus !== 'on_a_call') {
      if (voiceAppStatus === 'available') {
        focusOnKnockVoice();
      } else {
        openKnockVoice();
      }
    }
  };

  const tooltipTitle = useMemo(() => {
    if (!isVoiceAppStatusFeatureEnable) {
      return 'Open Voice App';
    } else if (
      isVoiceAppStatusFeatureEnable &&
      voiceAppStatus === 'available'
    ) {
      return 'You are online. Open Voice App to manage incoming calls';
    } else if (isVoiceAppStatusFeatureEnable && voiceAppStatus === 'away') {
      return 'You are offline. Open Voice App to manage incoming calls';
    } else if (
      isVoiceAppStatusFeatureEnable &&
      voiceAppStatus === 'on_a_call'
    ) {
      return 'You are on a call. Open Voice App to view call details and actions.';
    }
    if (
      voiceAppStatus !== 'available' &&
      voiceAppStatus !== 'on_a_call' &&
      voiceAppStatus !== 'away'
    ) {
      return 'We are determining your status.Open Voice App to manager incoming calls';
    }
  }, [voiceAppStatus, isVoiceAppStatusFeatureEnable]);
  const { cancelIcon, tooltipStyle } = useStyles();

  return (
    <>
      <Tooltip
        title={<span className={tooltipStyle}>{tooltipTitle}</span>}
        arrow={true}
        placement="bottom"
      >
        <IconButton
          className={commonClasses.menuIconButton}
          aria-describedby="popover-help"
          ref={helpRef}
          onClick={openVoiceAppCall}
          component="span"
          data-testid="help-icon"
        >
          <CallIcon />
          {isVoiceAppStatusFeatureEnable && voiceAppStatus === 'available' && (
            <VoiceAppOnlineBadgeIcon style={cancelIcon} />
          )}
          {isVoiceAppStatusFeatureEnable && voiceAppStatus === 'away' && (
            <VoiceAppCancelBadgeIcon style={cancelIcon} />
          )}
          {isVoiceAppStatusFeatureEnable && voiceAppStatus === 'on_a_call' && (
            <VoiceAppOnCallBadgeIcon style={cancelIcon} />
          )}
          {isVoiceAppStatusFeatureEnable &&
            voiceAppStatus !== 'available' &&
            voiceAppStatus !== 'on_a_call' &&
            voiceAppStatus !== 'away' && (
              <VoiceAppOnLoadingBadgeIcon style={cancelIcon} />
            )}
        </IconButton>
      </Tooltip>
    </>
  );
};
