import React from 'react';

const ConfirmationIcon = () => (
  <svg
    width="204"
    height="201"
    viewBox="0 0 204 201"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M131.364 7.85152C100.945 -8.93429 62.6782 2.11733 45.8924 32.536C39.7746 43.6223 37.3545 55.7511 38.222 67.5026C25.2789 71.4811 13.8045 80.2919 6.74562 93.0837C-7.69512 119.253 1.81256 152.173 27.9815 166.614L76.9895 193.658C103.158 208.099 136.079 198.591 150.52 172.422C152.708 168.458 154.346 164.338 155.459 160.151C172.29 163.955 190.287 156.421 199.042 140.556C209.656 121.32 202.668 97.1223 183.432 86.5077L166.686 77.2665C171.83 51.1469 159.895 23.5959 135.338 10.0447L131.364 7.85152Z"
      fill="#F0F2FF"
    />
    <path
      d="M86.5736 128.166H62.2602C58.9032 128.166 56.1819 125.666 56.1819 122.582V77.9126C56.1819 74.8288 58.9032 72.329 62.2602 72.329H135.2C138.557 72.329 141.279 74.8288 141.279 77.9126V100.247"
      stroke="#435CEF"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M139.905 74.3763L106.904 97.6937C102.085 101.099 95.3755 101.099 90.557 97.6937L57.5557 74.3763"
      stroke="#435CEF"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M98.7512 128.069C98.7512 130.901 99.3584 133.705 100.538 136.322C101.718 138.938 103.447 141.315 105.627 143.318C107.807 145.32 110.395 146.909 113.243 147.992C116.091 149.076 119.143 149.634 122.226 149.634C125.309 149.634 128.362 149.076 131.21 147.992C134.058 146.909 136.646 145.32 138.825 143.318C141.005 141.315 142.734 138.938 143.914 136.322C145.094 133.705 145.701 130.901 145.701 128.069C145.701 125.237 145.094 122.433 143.914 119.817C142.734 117.201 141.005 114.823 138.825 112.821C136.646 110.818 134.058 109.23 131.21 108.146C128.362 107.063 125.309 106.505 122.226 106.505C119.143 106.505 116.091 107.063 113.243 108.146C110.395 109.23 107.807 110.818 105.627 112.821C103.447 114.823 101.718 117.201 100.538 119.817C99.3584 122.433 98.7512 125.237 98.7512 128.069V128.069Z"
      stroke="#435CEF"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M105.629 143.312L138.819 112.82"
      stroke="#435CEF"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ConfirmationIcon;
