/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  var prospectStatusMap = {
    new: 'New',
    open: 'Open',
    'applied-lost': 'Applied (Lost)',
    'applied-leased': 'Applied (Leased)',
    'applied-pending-signature': 'Applied (Pending Signature)',
    lost: 'Lost',
    booked: 'Booked',
    visited: 'Visited',
    'cancelled-manager': 'Cancelled (Manager)',
    'cancelled-renter': 'Cancelled (Renter)',
    'no-show': 'No show',
    'in-review': 'Applied (In review)',
    waiting: 'Waiting',
    'application-in-progress': 'Application in progress'
  };

  app.constant('prospectStatusMap', prospectStatusMap);

  app.filter('prospectStatus', [
    'prospectStatusMap',
    function (prospectStatusMap) {
      return function (status) {
        if (status === 'all') {
          return 'All';
        }

        if (status === 'active') {
          return 'Active';
        }

        if (status === 'archived') {
          return 'Archived';
        }

        if (status === 'not_prospect') {
          return 'Not Prospect';
        }

        return prospectStatusMap[status];
      };
    }
  ]);
})(window.angular);
