/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const timedSyncService = function ($timeout) {
    var self = this;

    self._syncTimeouts = {};

    self.requestSync = function (syncId, syncFunction, timeoutMs) {
      if (!self._syncTimeouts[syncId]) {
        self._syncTimeouts[syncId] = { timeout: null, syncAgain: false };
      }

      var sync = self._syncTimeouts[syncId];

      if (sync.timeout) {
        sync.syncAgain = true;
        return;
      }

      sync.timeout = $timeout(function () {
        sync.timeout = null;

        if (sync.syncAgain) {
          sync.syncAgain = false;
          syncFunction();
        }
      }, timeoutMs);

      syncFunction();
    };

    return self;
  };
  timedSyncService.$inject = ['$timeout'];

  app.factory('timedSyncService', timedSyncService);
})(window.angular);
