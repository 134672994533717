import { api } from './api';

const useProperties = () => {
  const {
    useLazyGetPropertyTourTypesQuery,
    useLazyGetAvailableAppointmentsTimeForPropertyQuery
  } = api;

  const [getTourTypesEnabled] = useLazyGetPropertyTourTypesQuery();
  const [getAvailableAppointmentsTimeForProperty] =
    useLazyGetAvailableAppointmentsTimeForPropertyQuery();

  return {
    getTourTypesEnabled,
    getAvailableAppointmentsTimeForProperty
  };
};

export default useProperties;
