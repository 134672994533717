import React, { FC } from 'react';

import { Text } from '@knockrentals/knock-shared-web';
import { NearbyCommunity } from '../../services/nearbyCommunitiesService';
import { Tooltip } from '../Tooltip';
import { GuestAction } from './GuestAction';

interface Props {
  community: NearbyCommunity;
  setIsConnectedProfilesDialogActive: (isActive: boolean) => void;
  shouldShowConnectedProfiles: boolean;
  triggerReferral: (propertyId: number) => void;
}

/**
 * Shows the community and referring options.
 */
const NearbyCommunitiesListItem: FC<Props> = (props) => {
  const {
    community: { property },
    setIsConnectedProfilesDialogActive,
    shouldShowConnectedProfiles,
    triggerReferral
  } = props;

  const triggerPropertyReferral = () => triggerReferral(property.id);
  const propertyDistance =
    property.distance || property.distance === 0
      ? property.distance.toFixed(1)
      : '?';

  return (
    <div
      data-testid="nearby-community-item"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '8px'
      }}
    >
      {/* left part for property information */}
      <div
        style={{
          display: 'flex',
          flexFlow: 'column'
        }}
      >
        <Text variant="caption" color="secondary">
          {propertyDistance} {property.unit}
          {typeof property.available_units_count == 'number' ? (
            <> | {property.available_units_count} matches</>
          ) : (
            ''
          )}
        </Text>
        {property.url ? (
          <Tooltip title={'Open website'} placement="bottom">
            <a href={property.url} rel="noopener noreferrer" target="_blank">
              <Text variant="body2" color="primary">
                {property.name}
              </Text>
            </a>
          </Tooltip>
        ) : (
          <Tooltip title={'No community website'} placement="bottom">
            <span>
              <Text variant="body2" color="textPrimary">
                {property.name}
              </Text>
            </span>
          </Tooltip>
        )}
      </div>

      {/* Right button for referrals or prospect/resident view */}
      <div
        style={{
          alignSelf: 'center'
        }}
      >
        <GuestAction
          cannotReferReason={property.can_not_refer_reason}
          propertyName={property.name}
          setIsConnectedProfilesDialogActive={
            setIsConnectedProfilesDialogActive
          }
          shouldShowConnectedProfiles={shouldShowConnectedProfiles}
          triggerPropertyReferral={triggerPropertyReferral}
        />
      </div>
    </div>
  );
};

export default NearbyCommunitiesListItem;
