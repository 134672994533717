/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('loadingIndicator', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/util/loadingIndicator.html',
      scope: {
        isLoading: '='
      }
    };
  });

  app.directive('loadingBar', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/util/loading-bar.html',
      scope: {
        progressPercent: '=',
        hideText: '='
      }
    };
  });
})();
