/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
require('angular-material/angular-material.css');
require('isteven-angular-multiselect/isteven-multi-select.css');
require('textangular/dist/textAngular.css');

require('../core/scripts/config');
require('../core/scripts/events');

/*API*/
require('./api/apiDependencies');

/*Account*/
require('./core/accountDependencies');

/*Authentication*/
require('./core/authenticationDependencies');

/**
 * Browser Notification
 */
require('./core/browserNotificationDependencies');

/*Controls*/
require('../core/scripts/controls/dateSelector.directive');
require('../core/scripts/controls/currencyInput.directive');
require('../core/scripts/controls/userImage.directive');

/*Landing*/
require('../core/scripts/landing/headshots.controller');
require('../core/scripts/landing/marketingBanner.directive');

/*Appointments*/
require('./core/appointmentDependencies');

/*Data*/
require('./core/dataDependencies');

/*Choose property*/
require('../core/scripts/chooseProperty/chooseProperty.controller');

/*Color picker*/
require('../core/scripts/colorPicker/colorPicker.directive');

/*Community*/
require('./core/communityDependencies');

/*Conversations*/
require('./core/conversationDependencies');

/*Video Calls*/
require('./core/meetingDependencies');

/*Emoji*/
require('../core/scripts/emojiPicker/emojiPicker.module');

/*featureflag*/
require('../core/scripts/featureFlag/featureFlag.service');

/* EventTracking Service */
require('../core/scripts/tracking/uis.service');

/*Filters*/
require('../core/scripts/filters/timeOfDay.filter');

/*3rd-party tracking service*/
require('./core/thirdPartyTrackingDependencies');

/*Floorplan*/
require('../core/scripts/floorplan/floorplanEditor.controller');
require('../core/scripts/floorplan/floorplanModel.service');

/*Kanban*/
require('./core/kanbanDependencies');

/*Listing*/
require('./core/listingDependencies');

/*Login*/
require('./core/loginDependencies');

/*Messaging*/
require('../core/scripts/messaging/messagingModal.controller');
require('../core/scripts/messaging/prospectMessage.controller');
require('../core/scripts/messaging/residentMessage.controller');
require('../core/scripts/messaging/messagingEmail.directive');
require('../core/scripts/messaging/messagingSms.directive');

/*Inline document editor*/
require('../core/scripts/inlineDocumentEditor/inlineDocumentEditor.directives');
require('../core/scripts/inlineDocumentEditor/deleteResourceModal.service');

/*Image services*/
require('../core/scripts/image/image.service');
require('../core/scripts/image/hideImgOnErr.directive');

/*Image uploader*/
require('../core/scripts/imageUploader/upload.service');
require('../core/scripts/imageUploader/imageUploader.directive');
require('../core/scripts/imageUploader/excludedPhotosModal.controller');

/*Leasing team*/
require('../core/scripts/leasingTeam/leasingTeamPage.controller');
require('../core/scripts/leasingTeam/leasingTeamCommunitySMSConsent.controller');
require('../core/scripts/leasingTeam/clickTracking.controller');

/*Marketing tools*/
require('./core/marketingToolDependencies');

/*Navigation*/
require('./core/navigationDependencies');

/*Notifications*/
require('./core/notificationDependencies');

/**
 * Profile
 */
require('./core/profileDependencies');

/**
 * Property
 */
require('./core/propertyDependencies');

/* Prospects */
require('./core/prospectDependencies');

/*Quotes*/
require('../core/scripts/quotes/quotes.service');
require('../core/scripts/quotes/unitQuotes.service');

/*Residents*/
require('./core/residentDependencies');

/*Scheduling*/
require('./core/schedulingDependencies');

/*String array input*/
require('../core/scripts/stringArrayInput/stringArrayInput.directive');

/*Map*/
require('../core/scripts/map/mapUtilities.service');

/*Settings*/
require('../core/scripts/settings/settings.controller');
require('../core/scripts/settings/manager/adminTemplateEditor.directive');

/*Time*/
require('./core/timeDependencies');

/*Todolist*/
require('./core/todoListDependencies');

/*Units*/
require('./core/unitDependencies');

/*User interactions*/
require('./core/userInteractionDependencies');

/*User*/
require('./core/userDependencies');

/*Voice*/
require('./core/voiceDependencies');

/*Unsubscribe*/
require('./core/unsubscribeDependencies');

/*Utility*/
require('./core/utilityDependencies');
/*please make sure templates is always placed the bottom of the page*/
//require('./scripts/templates');
/* endbuild */

require('../core/styles/main.scss');

/*Leasing brochure workbench*/
require('../core/scripts/leasingBrochure/leasingBrochure.controller');
