/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import forEach from 'lodash/forEach';
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knock-ProspectHistory');

  const prospectHistoryService = function (
    $q,
    $routeParams,
    prospectsApi,
    residentsApi,
    communityApi,
    localStorageService,
    userService,
    $moment,
    viewReviewModalService,
    renterApi,
    historyService,
    addResidentModalService,
    conversationsService,
    listingApi,
    propertyApi,
    managerApi,
    listingResidentsModalFactory,
    editProspectsModalFactory,
    newAppointmentModalFactory,
    changeTimeModalFactory,
    $rootScope,
    apiEvents
  ) {
    var self = this;

    self.events = {
      prospectAppointmentCreated: 'prospectAppointmentCreated',
      prospectUpdated: 'prospect-updated',
      prospectsBatchUpdated: 'prospectsBatchUpdated',
      prospectsBatchLossReasonsUpdated: 'prospectsBatchLossReasonsUpdated',
      prospectDeleted: 'prospectDeleted',
      prospectRestored: 'prospectRestored',
      prospectsBatchDeleted: 'prospectsBatchDeleted',
      residentUpdated: 'residentUpdated',
      residentDeleted: 'residentDeleted',
      sentBatchMessage: 'sentBatchMessage'
    };

    $rootScope.$on(
      conversationsService.events.participantUpdated,
      function (event, payload) {
        if (payload.participant_type === 'prospect') {
          prospectsApi
            .getProspect(payload.participant_id)
            .then(function (response) {
              $rootScope.$emit(
                self.events.prospectUpdated,
                response.data.prospect
              );
            });
        }
      }
    );

    self.getPropertyProspectReports = function (
      startDate,
      endDate,
      propertyId,
      managerIds,
      status,
      includeUnassigned
    ) {
      return prospectsApi.getPropertyProspectReports(
        startDate,
        endDate,
        propertyId,
        managerIds,
        status,
        includeUnassigned
      );
    };

    self.getCommunityListings = function (communityId, includeInactive) {
      return communityApi.getCommunityListings(communityId, includeInactive);
    };

    self.getSavedFilters = function (view) {
      return localStorageService.get(view + 'Filters');
    };

    self.saveFilters = function (view, filters) {
      var propertyId = filters.selectedProperty.id;
      var managerId = filters.selectedManager
        ? filters.selectedManager.id
        : null;

      filters.propertyId = propertyId;
      filters.managerId = managerId;

      return localStorageService.set(view + 'Filters', filters);
    };

    self.getDefaultStartDateMoment = function () {
      return $moment().startOf('day').subtract(1, 'week');
    };

    self.getDefaultEndDateMoment = function () {
      return $moment().endOf('day').add(1, 'week');
    };

    self.getDefaultProspectStartDateMoment = function () {
      return $moment().startOf('day').subtract(30, 'days');
    };

    self.getDefaultProspectEndDateMoment = function () {
      return $moment().endOf('day');
    };

    self.getCurrentUser = function () {
      return userService.getUser();
    };

    self.getProspects = function (
      start,
      end,
      listingIds,
      managerIds,
      applicationStatus
    ) {
      return prospectsApi.getProspects(
        start,
        end,
        listingIds,
        managerIds,
        applicationStatus
      );
    };

    self.getResidents = function () {
      return residentsApi.getResidents();
    };

    self.getListingResidents = function (listingId, subscribedStatus) {
      return residentsApi.getListingResidents(listingId, subscribedStatus);
    };

    self.getPropertyResidents = function (propertyId) {
      return propertyApi.getResidents(propertyId);
    };

    self.getListingInfo = function (listingId) {
      return listingApi.getListingInfo(listingId);
    };

    self.getListing = function (listingId) {
      return listingApi.getListing(listingId);
    };

    self.saveListingInfo = function (
      listingId,
      remindResidents,
      residentNotificationMethod
    ) {
      var payload = {
        occupant_notification_method: residentNotificationMethod,
        remind_occupants: remindResidents
      };

      return listingApi.setListingInfo(listingId, payload);
    };

    self.openReviewModal = function (prospect, managerId, listingId) {
      var promises = [
        listingApi.getListing(listingId),
        managerApi.getManagerInfoById(managerId)
      ];

      $q.all(promises).then(function (response) {
        var listing = response[0].data.listing;
        var manager = response[1].data.info[0];

        viewReviewModalService.openModal(
          prospect.AppointmentReview.review,
          prospect.Appointment,
          manager,
          prospect.Profile,
          listing
        );
      });
    };

    self.sendMessage = function (threadId, message, targets) {
      return conversationsService.sendMessage(
        threadId,
        message,
        null,
        null,
        null,
        targets
      );
    };

    self.sendMessageBatch = function (
      streamIds,
      message,
      targets,
      attachments
    ) {
      return conversationsService.sendMessageBatch(
        streamIds,
        message,
        null,
        null,
        targets,
        attachments
      );
    };

    self.requestProspectCSV = function (
      start,
      end,
      listingIds,
      managerIds,
      selectedProspectIds,
      applicationStatus
    ) {
      return prospectsApi.getProspectsCSV(
        start,
        end,
        listingIds,
        managerIds,
        selectedProspectIds,
        applicationStatus
      );
    };

    self.viewProspect = function (prospect) {
      return conversationsService.openThreadById(prospect.stream_id);
    };

    self.viewResident = function (resident) {
      return conversationsService.openThreadById(resident.stream_id);
    };

    self.createNewProspect = function (prospect) {
      return prospectsApi
        .createNewProspect(prospect)
        .success(function (response) {
          $rootScope.$emit(self.events.prospectUpdated, response.prospect);
        });
    };

    self.updateProspect = function (prospect) {
      return prospectsApi
        .updateProspect(prospect.id, prospect)
        .success(function () {
          $rootScope.$emit(self.events.prospectUpdated, prospect);
        });
    };

    self.deleteProspect = function (prospectObject) {
      return prospectsApi
        .deleteProspect(prospectObject.id)
        .success(function () {
          $rootScope.$emit(self.events.prospectDeleted, prospectObject);
        });
    };

    self.restoreProspect = function (prospectId) {
      return prospectsApi
        .restoreProspect(prospectId)
        .success(function (response) {
          $rootScope.$emit(self.events.prospectRestored, response.prospect);
        });
    };

    self.openEditProspectsModal = function (checkedProspects) {
      return editProspectsModalFactory.openModal(checkedProspects);
    };

    self.setProspectManagerBatch = function (prospects, newManagerId) {
      forEach(prospects, function (prospect) {
        prospect.assigned_manager_id = newManagerId;
      });

      var prospectIds = map(prospects, 'id');

      return prospectsApi
        .updateProspectsBatch(prospectIds, newManagerId, null, null, null)
        .success(function () {
          self._prospectsBatchUpdated(prospectIds, 'managerId', newManagerId);
        });
    };

    self.setProspectPropertyBatch = function (prospects, newPropertyId) {
      var prospectIds = map(prospects, 'id');

      return prospectsApi
        .updateProspectsBatch(prospectIds, null, newPropertyId, null, null)
        .success(function () {
          self._prospectsBatchUpdated(prospectIds, 'property', newPropertyId);
        });
    };

    self.setProspectStatusBatch = function (prospects, newStatus) {
      forEach(prospects, function (prospect) {
        prospect.status = newStatus;
      });

      var prospectIds = map(prospects, 'id');

      return prospectsApi
        .updateProspectsBatch(prospectIds, null, null, newStatus, null)
        .success(function () {
          self._prospectsBatchUpdated(prospectIds, 'status', newStatus);
        });
    };

    self.setProspectSourceBatch = function (prospects, newSource) {
      forEach(prospects, function (prospect) {
        prospect.source = newSource;
      });

      var prospectIds = map(prospects, 'id');

      return prospectsApi
        .updateProspectsBatch(prospectIds, null, null, null, newSource)
        .success(function () {
          self._prospectsBatchUpdated(prospectIds, 'source', newSource);
        });
    };

    self.addProspectReminderBatch = function (prospects, reminder) {
      var prospectIds = map(prospects, 'id');

      return prospectsApi
        .updateProspectsBatch(prospectIds, null, null, null, null, reminder)
        .success(function () {
          self._prospectsBatchUpdated(prospectIds, 'reminder', reminder);
        });
    };

    self.archiveProspects = function (prospects, password) {
      var prospectIds = map(prospects, 'id');

      return prospectsApi
        .deleteProspects(prospectIds, password)
        .success(function () {
          self._prospectsBatchDeleted(prospectIds);
        });
    };

    self._prospectsBatchUpdated = function (prospectIds, updateType, newValue) {
      $rootScope.$emit(
        self.events.prospectsBatchUpdated,
        prospectIds,
        updateType,
        newValue
      );
    };

    self._prospectsBatchDeleted = function (prospectIds) {
      $rootScope.$emit(self.events.prospectsBatchDeleted, prospectIds);
      conversationsService.fireUpdateUnreadCounts();
    };

    self.openListingResidentsModal = function (listingId) {
      return listingResidentsModalFactory.openModal(listingId);
    };

    self.openNewAppointmentModal = function (prospectData) {
      return newAppointmentModalFactory
        .openModal(null, null, null, prospectData)
        .then(function (appointment) {
          $rootScope.$emit(self.events.prospectAppointmentCreated, appointment);
        });
    };

    self.openChangeAppointmentTimeModal = function (appointment, preferences) {
      return changeTimeModalFactory.openChangeTimeModal(
        null,
        appointment,
        preferences
      );
    };

    return self;
  };

  prospectHistoryService.$inject = [
    '$q',
    '$routeParams',
    'prospectsApi',
    'residentsApi',
    'communityApi',
    'localStorageService',
    'userService',
    '$moment',
    'viewReviewModalService',
    'renterApi',
    'historyService',
    'addResidentModalService',
    'conversationsService',
    'listingApi',
    'propertyApi',
    'managerApi',
    'listingResidentsModalFactory',
    'editProspectsModalFactory',
    'newAppointmentModalFactory',
    'changeTimeModalFactory',
    '$rootScope',
    'apiEvents'
  ];

  app.factory('prospectHistoryService', prospectHistoryService);
})(window.angular);
