import React, { FC } from 'react';

import {
  CanNotReferReason,
  CAN_NOT_REFER_REASON
} from '../../services/nearbyCommunitiesService';

import { ConnectedProfilesButton } from './ConnectedProfilesButton';
import { ReferIconButton } from './ReferIconButton';

interface ConnectedProfilesGuestText {
  [CAN_NOT_REFER_REASON.PROSPECT_EXISTS]: string;
  [CAN_NOT_REFER_REASON.RESIDENT_EXISTS]: string;
}

const connectedProfilesGuestText: ConnectedProfilesGuestText = {
  [CAN_NOT_REFER_REASON.PROSPECT_EXISTS]: 'Prospect',
  [CAN_NOT_REFER_REASON.RESIDENT_EXISTS]: 'Resident'
};

interface Props {
  cannotReferReason?: CanNotReferReason;
  shouldShowConnectedProfiles: boolean;
  propertyName: string;
  setIsConnectedProfilesDialogActive: (isActive: boolean) => void;
  triggerPropertyReferral: () => void;
}

export const GuestAction: FC<Props> = ({
  cannotReferReason,
  shouldShowConnectedProfiles,
  propertyName,
  setIsConnectedProfilesDialogActive,
  triggerPropertyReferral
}) => {
  if (!cannotReferReason) {
    return (
      <ReferIconButton
        isDisabled={false}
        handleOnClick={triggerPropertyReferral}
        tooltipText="Refer prospect"
      />
    );
  }

  switch (cannotReferReason) {
    case CAN_NOT_REFER_REASON.PROSPECT_EXISTS:
    case CAN_NOT_REFER_REASON.RESIDENT_EXISTS:
      const openConnectedProfilesDialog = () =>
        setIsConnectedProfilesDialogActive(true);

      const guestText =
        connectedProfilesGuestText[
          cannotReferReason as keyof ConnectedProfilesGuestText
        ];

      if (shouldShowConnectedProfiles) {
        return (
          <ConnectedProfilesButton
            buttonText={guestText}
            handleOnClick={openConnectedProfilesDialog}
            tooltipText="View Connected Profiles"
          />
        );
      }
      return (
        <ReferIconButton
          isDisabled={true}
          handleOnClick={triggerPropertyReferral}
          tooltipText={`This person is already a ${guestText.toLowerCase()} at ${propertyName}`}
        />
      );

    case CAN_NOT_REFER_REASON.NO_PHONE_NO_EMAIL:
      return (
        <ReferIconButton
          isDisabled={true}
          tooltipText="This prospect has no valid phone or email"
        />
      );

    default:
      console.warn(`Unhandled can_not_refer_reason: ${cannotReferReason}`);
      return (
        <ReferIconButton
          isDisabled={true}
          tooltipText="Cannot refer prospect"
        />
      );
  }
};
