/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('profileEditor', function () {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: '/angular/views/profile/profile-editor.html',
      controller: 'ProfileEditorController'
    };
  });

  app.controller('ProfileEditorController', [
    '$scope',
    '$timeout',
    '$routeParams',
    '$anchorScroll',
    'ProfileService',
    function ($scope, $timeout, $routeParams, $anchorScroll, ProfileService) {
      var self = this;

      $scope.showPhotoEditor = false;
      $scope.editor = $scope.editor || {};
      $scope.editor.isEditingField = false;
      $scope.editor.photo = {
        isEditing: false,
        editedValue: ''
      };

      self._loadProfile = function () {
        $scope.profileLoadError = false;
        $scope.profileAclError = false;

        ProfileService.getProfile()
          .success(self._loadProfileSuccess)
          .error(self._loadProfileError);

        $scope.showLoadingBar =
          $scope.profileAclError || $scope.profileLoadError;
      };

      self._loadRenterProfileSuccess = function (response) {
        $scope.profile = response.renter_info;
        $scope.isPublicProfile = true;
        $scope.isProfileOwner = response.is_profile_owner;
      };

      self._loadRenterPublicProfileError = function () {
        $scope.profileAclError = 'This profile is private';
      };

      self._loadProfileSuccess = function (response) {
        $scope.profile = response.profile;
        $scope.isPublicProfile = false;
        $scope.isProfileOwner = true;

        $timeout(function () {
          $anchorScroll();
        }, 1000);
      };

      self._loadProfileError = function () {
        $scope.profileLoadError =
          'There was an error loading your profile. Please try again later.';
      };

      $scope.addEditorField = function (fieldName, defaultValue) {
        $scope.editor[fieldName] = {
          isEditing: false,
          editedValue: defaultValue || ''
        };
      };

      $scope.toggleEdit = function (fieldName, save) {
        if (!$scope.isProfileOwner) {
          return;
        }

        var field = $scope.editor[fieldName];
        field.isEditing = !field.isEditing;

        if (field.isEditing) {
          field.editedValue = $scope.profile[fieldName];
        } else {
          if (save) {
            $scope.profile[fieldName] = field.editedValue;
            self._saveProfile();
          }
        }

        $scope.editor.isEditingField = field.isEditing;
      };

      self._saveProfile = function () {
        ProfileService.updateProfile($scope.profile)
          .success(self._saveProfileSuccess)
          .error(self._saveProfileError);
      };

      self._saveProfileSuccess = function () {
        $scope.showPhotoEditor = false;
        $scope.isUploadingPhoto = false;
      };

      self._saveProfileError = function () {
        $scope.isUploadingPhoto = false;
        $scope.saveErrorMessage =
          'There was an error saving your profile. Please try again later.';
      };

      self._loadProfile();
    }
  ]);
})();
