import React, { FC } from 'react';
import './_DuplicateAlert.scss';

interface DuplicateAlertProps {
  mergeOptions: any;
  toggleModal: () => void;
  toggleDuplicateAlert: () => void;
  showDuplicateAlert: boolean;
}

const DuplicateAlert: FC<DuplicateAlertProps> = ({
  mergeOptions,
  toggleModal,
  toggleDuplicateAlert,
  showDuplicateAlert
}) => {
  if (!mergeOptions || !mergeOptions.length || !showDuplicateAlert) {
    return null;
  }

  return (
    <div className="duplicate-alert-container">
      <div>
        <h2 className="duplicate-alert-title">Possible duplicate found.</h2>
        <p className="duplicate-alert-description">
          Another guest card has matching contact information. Consider merging
          or archiving duplicates to prevent errors.
        </p>
      </div>
      <div className="duplicate-alert-action-container">
        <button
          className="duplicate-alert-action"
          onClick={toggleDuplicateAlert}
        >
          Dismiss
        </button>
        <button className="duplicate-alert-action" onClick={toggleModal}>
          Review & Merge
        </button>
      </div>
    </div>
  );
};

export default DuplicateAlert;
