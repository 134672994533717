/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('DebugInfoService', [
    '$window',
    'userService',
    function ($window, userService) {
      return {
        getDebugInfoObject: function () {
          var currentUser = userService.getUser();

          return {
            user_agent: $window.navigator.userAgent,
            identifier: 'web_app',
            created_time: new Date(),
            creator: {
              id: currentUser.id,
              type: currentUser.type
            }
          };
        }
      };
    }
  ]);
})();
