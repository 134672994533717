/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import range from 'lodash/range';
import map from 'lodash/map';
import filter from 'lodash/filter';
(function (angular) {
  'use strict';

  var app = angular.module('knock-PropertyReports');

  app.directive('residentReportStages', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/residents/resident-report-stages.html',
      controller: 'ResidentReportStagesController',
      scope: {
        data: '=',
        filters: '='
      }
    };
  });

  const ResidentReportStagesController = function (
    $scope,
    $moment,
    residentService
  ) {
    var self = this;

    $scope.$watch('data.residents', function () {
      self._initialize();
    });

    self._initialize = function () {
      var nonCurrentResidents = filter(
        $scope.data.residents,
        function (resident) {
          return resident.status !== 'Current';
        }
      );

      var walkedDate = $moment();
      var endMonth = $moment(walkedDate).add(
        residentService.renewalThresholdDays,
        'days'
      );
      var yearDifferenceInMonths = (endMonth.year() - walkedDate.year()) * 12;
      var monthDifference =
        yearDifferenceInMonths + (endMonth.month() - walkedDate.month());

      $scope.nonCurrentResidents = map(range(monthDifference + 1), function () {
        var residents = filter(nonCurrentResidents, function (resident) {
          var leaseEndMoment = $moment(resident.lease_end);
          return (
            leaseEndMoment.month() === walkedDate.month() &&
            leaseEndMoment.year() === walkedDate.year()
          );
        });

        var month = {
          residents: residents,
          label: walkedDate.format('MMMM'),
          date: walkedDate.format()
        };

        walkedDate.add(1, 'month');

        return month;
      });

      $scope.currentResidents = filter(
        $scope.data.residents,
        function (resident) {
          return resident.status === 'Current';
        }
      );
    };
  };

  ResidentReportStagesController.$inject = [
    '$scope',
    '$moment',
    'residentService'
  ];

  app.controller(
    'ResidentReportStagesController',
    ResidentReportStagesController
  );
})(window.angular);
