import React, { FC } from 'react';
import { Backdrop, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TooltipContainer from '../molecules/TooltipContainer/TooltipContainer';

const useStyles = makeStyles((theme) => ({
  progressButtonBackdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  tooltip: {
    zIndex: 100000
  }
}));

export interface CircularProgressButtonProps {
  onClick: () => void;
  progressText: string;
  shouldShowProgress: boolean;
  disabled?: boolean;
  className?: string;
  dataTestId?: string;
  endIcon?: any;
  tooltipText?: string;
}

const CircularProgressButton: FC<CircularProgressButtonProps> = ({
  children,
  onClick,
  shouldShowProgress,
  progressText,
  className,
  disabled,
  dataTestId,
  endIcon,
  tooltipText
}) => {
  const classes = useStyles();
  const buttonContent = shouldShowProgress ? progressText : children;

  const getStartIcon = (isLoading: boolean) => {
    if (isLoading) {
      return <CircularProgress color="secondary" size={16} />;
    }
  };

  return (
    <React.Fragment>
      {tooltipText ? (
        <TooltipContainer
          tooltip={tooltipText}
          style={classes.tooltip}
          arrowPlacement="top"
        >
          <Button
            data-testid={dataTestId || 'circular-progress-button'}
            startIcon={getStartIcon(shouldShowProgress)}
            disabled={shouldShowProgress || disabled}
            color="primary"
            onClick={onClick}
            className={className ? className : ''}
            variant="contained"
            endIcon={
              endIcon !== undefined && !shouldShowProgress ? endIcon : undefined
            }
          >
            {buttonContent}
          </Button>
        </TooltipContainer>
      ) : (
        <Button
          data-testid={dataTestId || 'circular-progress-button'}
          startIcon={getStartIcon(shouldShowProgress)}
          disabled={shouldShowProgress || disabled}
          color="primary"
          onClick={onClick}
          className={className ? className : ''}
          variant="contained"
          endIcon={
            endIcon !== undefined && !shouldShowProgress ? endIcon : undefined
          }
        >
          {buttonContent}
        </Button>
      )}
      <Backdrop
        className={classes.progressButtonBackdrop}
        invisible
        open={shouldShowProgress}
      />
    </React.Fragment>
  );
};

export default CircularProgressButton;
