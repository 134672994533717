import React, { useState, useEffect, FC, useCallback, useMemo } from 'react';
import { Button, Menu, makeStyles } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import dayjs from 'dayjs';

import { Box, Text, NamedColors } from '@knockrentals/knock-shared-web';
import { colors, useCommonStyles } from '../../commonStyles/commonStyles';
import SelectDateRange, {
  DateRangeParameters,
  DateRangeFilterOption
} from '../SelectDateRange/SelectDateRange';
import { invalidDateRange } from 'LegacyAngularApp/legacy-angular-app/utilities/timeUtilities';

const useStyles = makeStyles({
  menu: {
    border: `1px solid ${NamedColors.slate[200]}`,
    borderRadius: '4px',
    marginTop: '4px',
    width: '450px'
  },

  selectChip: {
    alignItems: 'center',
    backgroundColor: NamedColors.slate[100],
    border: `1px solid ${NamedColors.slate[100]}`,
    borderRadius: '16px',
    flexDirection: 'row',
    height: '32px',
    minWidth: '72px',
    padding: '4px',

    '&:hover': {
      backgroundColor: NamedColors.denim[100]
    },

    '&:disabled': {
      backgroundColor: 'inherit',
      border: 'none',
      color: colors.disabled,

      '& > .MuiButton-label > svg': {
        color: colors.disabled
      }
    }
  },

  selectChipOutlined: {
    backgroundColor: NamedColors.denim[100],
    borderColor: NamedColors.denim[500]
  },

  filterName: {
    fontSize: '13px',
    marginLeft: '6px'
  },

  filterContainer: {
    padding: '16px'
  },

  dateSelector: {
    padding: '16px'
  },

  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '68px',
    padding: '0 16px'
  },

  cancelButton: {
    marginRight: '12px'
  },

  keyboardArrow: {
    color: '#5C5C5C',
    margin: '6px 3px'
  }
});

interface ChipSelectDateRangeProps {
  filterOptions: DateRangeFilterOption[];
  startDate: Date | null;
  endDate: Date | null;
  range: string;
  chipLabel: string;
  onApply: (
    startDate: Date | null,
    endDate: Date | null,
    range: string
  ) => void;
  className?: string;
  isDisabled?: boolean;
  useFutureDates?: boolean;
  disableApply?: boolean;
  startDateError?: string;
  endDateError?: string;
  allowEmptyDates?: boolean;
  dateValidator?: (startDate: Date | null, endDate: Date | null) => boolean;
  onChange?: (
    startDate?: Date | null,
    endDate?: Date | null,
    range?: string
  ) => void;
  onCancel?: () => void;
  handleChange?: (
    startDate: Date | null,
    endDate: Date | null,
    range: string
  ) => void;
}

const ChipSelectDateRange: FC<ChipSelectDateRangeProps> = ({
  filterOptions,
  startDate,
  endDate,
  range,
  chipLabel,
  onApply,
  className,
  isDisabled = false,
  useFutureDates = false,
  disableApply,
  startDateError,
  endDateError,
  onChange,
  dateValidator,
  allowEmptyDates,
  onCancel,
  handleChange
}: ChipSelectDateRangeProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [selectedRange, setSelectedRange] = useState<string>('');
  const [resetDateError, setResetDateError] = useState<boolean>(false);

  const setFieldDefaults = useCallback(() => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
    setSelectedRange(range);
    setResetDateError(true);
  }, [startDate, endDate, range]);

  useEffect(() => {
    setFieldDefaults();
    // We just want this to happen once, because when an extern prop change, resets the dates on edition.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onChange === undefined) {
      setFieldDefaults();
    }
    // We want this to happen when dates or range changes and the onChange is undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, range]);

  const setSelectedDates = (range: string) => {
    const now = new Date();

    switch (range) {
      case 'today':
        setSelectedStartDate(now);
        setSelectedEndDate(now);
        break;
      case '7day':
        if (useFutureDates) {
          setSelectedStartDate(now);
          setSelectedEndDate(dayjs().add(7, 'day').toDate());
        } else {
          setSelectedStartDate(dayjs().subtract(7, 'day').toDate());
          setSelectedEndDate(now);
        }

        break;
      case '2week':
        if (useFutureDates) {
          setSelectedStartDate(now);
          setSelectedEndDate(dayjs().add(14, 'day').toDate());
        } else {
          setSelectedStartDate(dayjs().subtract(14, 'day').toDate());
          setSelectedEndDate(now);
        }

        break;
      case '1month':
        if (useFutureDates) {
          setSelectedStartDate(now);
          setSelectedEndDate(dayjs().add(1, 'month').toDate());
        } else {
          setSelectedStartDate(dayjs().subtract(1, 'month').toDate());
          setSelectedEndDate(now);
        }

        break;
      case '2month':
        if (useFutureDates) {
          setSelectedStartDate(now);
          setSelectedEndDate(dayjs().add(2, 'month').toDate());
        } else {
          setSelectedStartDate(dayjs().subtract(2, 'month').toDate());
          setSelectedEndDate(now);
        }

        break;
      case '+2month':
        if (useFutureDates) {
          setSelectedStartDate(now);
          setSelectedEndDate(dayjs().add(2, 'month').toDate());
        } else {
          setSelectedStartDate(now);
          setSelectedEndDate(dayjs().add(2, 'month').toDate());
        }

        break;
    }
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: Object, reason: string) => {
    // Prevents the menu from being closed if the Tab key is pressed
    if (reason !== 'tabKeyDown') {
      if (reason !== 'apply') {
        setFieldDefaults();
      }
      setAnchorEl(null);
      if (onCancel !== undefined) {
        onCancel();
      }
    }
  };

  const handleReset = () => {
    const defaultOption = filterOptions.find(
      (item: DateRangeFilterOption) => !!item.default
    );
    setResetDateError(true);
    if (defaultOption) {
      setSelectedDates(defaultOption.value);
      setSelectedRange(defaultOption.value);
    } else {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      setSelectedRange('');
      onApply(null, null, '');
      handleClose({}, 'apply');
    }
  };

  const handleCancel = () => {
    handleClose({}, 'cancel');
  };

  const handleApply = () => {
    if (
      dateValidator !== undefined &&
      !dateValidator(selectedStartDate, selectedEndDate)
    ) {
      return;
    }
    if ((selectedStartDate && selectedEndDate) || dateValidator !== undefined) {
      onApply(selectedStartDate, selectedEndDate, selectedRange);
    }
    handleClose({}, 'apply');
  };

  const handleSelectDateRange = (data: DateRangeParameters) => {
    setResetDateError(false);
    if (data.range !== selectedRange) {
      setSelectedDates(data.range);
      setSelectedRange(data.range);
    } else {
      setSelectedStartDate(data.startDate);
      setSelectedEndDate(data.endDate);
      setSelectedRange('');
    }
    if (onChange !== undefined) {
      onChange();
    }
    if (handleChange !== undefined) {
      if (
        data.range !== selectedRange ||
        data.startDate !== selectedStartDate ||
        data.endDate !== selectedEndDate
      ) {
        handleChange(data.startDate, data.endDate, data.range);
      }
    }
  };

  const dateLabel = useMemo<string>(() => {
    let label = chipLabel;
    const selectedOption = filterOptions.find(
      (item: DateRangeFilterOption) => item.value === range
    );
    if (selectedOption) {
      label += `: ${selectedOption.label}`;
    } else if (startDate && endDate) {
      label += `: ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
    } else if (allowEmptyDates) {
      if (startDate) {
        label += `: >${startDate.toLocaleDateString()}`;
      } else if (endDate) {
        label += `: <${endDate.toLocaleDateString()}`;
      }
    }
    return label;
  }, [range, startDate, endDate, allowEmptyDates, chipLabel, filterOptions]);

  const isApplyDisabled = invalidDateRange(selectedStartDate, selectedEndDate);

  return (
    <Box
      className={className ? className : undefined}
      data-testid="range-date-selector"
    >
      <Button
        data-testid="rangedate-action-chip-button"
        disabled={isDisabled}
        variant={anchorEl ? 'outlined' : 'text'}
        onClick={handleOpen}
        classes={{
          root: classes.selectChip,
          outlined: classes.selectChipOutlined
        }}
      >
        <Text variant="body2" className={classes.filterName}>
          {dateLabel}
        </Text>

        {anchorEl ? (
          <KeyboardArrowUp className={classes.keyboardArrow} />
        ) : (
          <KeyboardArrowDown className={classes.keyboardArrow} />
        )}
      </Button>

      <Menu
        classes={{ paper: classes.menu }}
        elevation={0}
        MenuListProps={{ role: 'listbox', disablePadding: true }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          <Box>
            <SelectDateRange
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              range={selectedRange}
              rangeOptions={filterOptions}
              onChange={handleSelectDateRange}
              className={classes.dateSelector}
              resetDateError={resetDateError}
              startDateMessageError={startDateError}
              endDateMessageError={endDateError}
              allowEmptyDates={allowEmptyDates}
            />
          </Box>

          <Box className={classes.actionButtons}>
            <Button
              variant="text"
              color="primary"
              onClick={handleReset}
              data-testid="reset-button"
            >
              Reset
            </Button>

            <Box>
              <Button
                variant="outlined"
                color="primary"
                className={classes.cancelButton}
                onClick={handleCancel}
                data-testid="cancel-button"
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                disabled={
                  disableApply !== undefined ? disableApply : isApplyDisabled
                }
                className={commonClasses.primaryButton}
                onClick={handleApply}
                data-testid="apply-button"
              >
                Apply
              </Button>
            </Box>
          </Box>
        </div>
      </Menu>
    </Box>
  );
};

export default ChipSelectDateRange;
