import angular from 'angular';
import { isNull } from 'lodash';

var app = angular.module('knock-Authentication', []);

app.factory('authenticationService', [
  '$rootScope',
  '$auth',
  '$location',
  '$routeParams',
  'apiBase',
  'localStorageService',
  'forgotPasswordModalFactory',
  'requestAccountModalFactory',
  'renterAuthModalFactory',
  'managerAuthModalFactory',
  'cacheKeys',
  'userService',
  'localCache',
  'pusherInstanceService',
  'notificationsService',
  'conversationsService',
  'apiEvents',
  '$route',
  'appDataService',
  'LOGIN_UI_BASE_URL',
  'WEBFRONT_BASE_URL',
  function (
    $rootScope,
    $auth,
    $location,
    $routeParams,
    apiBase,
    localStorageService,
    forgotPasswordModalFactory,
    requestAccountModalFactory,
    renterAuthModalFactory,
    managerAuthModalFactory,
    cacheKeys,
    userService,
    localCache,
    pusherInstanceService,
    notificationsService,
    conversationsService,
    apiEvents,
    $route,
    appDataService,
    LOGIN_UI_BASE_URL,
    WEBFRONT_BASE_URL
  ) {
    var self = this;

    var privateBrowsingRegex = /QuotaExceededError/;
    var useSocialLoginRegex = /use social login/;

    self.pusher_client = null;

    self.events = {
      forceLogout: 'force-logout',
      userLoggedOut: 'client-logged-out'
    };

    self.isPrivateBrowsingError = function (response) {
      return privateBrowsingRegex.test(response.message);
    };

    self.getExistingAccountProvider = function (response) {
      if (!response.data) {
        return null;
      }

      if (useSocialLoginRegex.test(response.data.error_message)) {
        var providerId = response.data.provider_id;

        if (providerId === 'facebook') {
          return 'Facebook';
        }

        if (providerId === 'linkedin') {
          return 'LinkedIn';
        }
      }

      return null;
    };

    self.getErrorMessages = function (form) {
      if (form === 'login') {
        return {
          errorMessage: "We're having a problem authenticating your account.",
          invalidLoginMessage: 'Invalid username / password combination.',
          useSocialLoginMessageTemplate:
            'Looks like you used {{provider}} to register. Please login with that option.',
          privateBrowsingErrorMessage:
            'An error occurred. Please ensure private browsing is turned off',
          usernameTakenErrorMessage:
            'This email is already registered. Please log in with your email address',
          emailHidden:
            "Your Facebook permissions won't allow us to retrieve your email address. Please re-authenticate with Facebook login button above.",
          loginAttemptLimitErrorMessage:
            'Too many failed login attempts. Please try again later, reset your password, or contact Knock support.',
          migratedUserLoginErrorMessage:
            'Error logging in. If you have a RealPage account, try logging in from RealPage Unified Platform.'
        };
      }

      if (form === 'signup') {
        return {
          errorMessage: "We're having a problem creating your account.",
          usernameTakenErrorMessage:
            'There is already an account using that email address.',
          privateBrowsingErrorMessage:
            'An error occurred. Please ensure private browsing is turned off',
          emailHidden:
            "Your Facebook permissions won't allow us to retrieve your email address. Please re-authenticate with Facebook login button above."
        };
      }
    };

    self.isLoggedIn = function () {
      return $auth.isAuthenticated();
    };

    self.openRequestManagerAccountModal = function () {
      return requestAccountModalFactory.openRequestAccountModal();
    };

    self.openRenterAuthModal = function (form, renterInfo) {
      return renterAuthModalFactory.openRenterAuthModal(form, renterInfo);
    };

    self.openManagerAuthModal = function () {
      return managerAuthModalFactory.openManagerAuthModal();
    };

    self.requestManagerAccount = function (
      profile,
      contactPreference,
      isDemoRequest
    ) {
      var payload = {
        profile: profile,
        contact_preference: contactPreference,
        is_demo_request: isDemoRequest
      };

      return apiBase.post('/auth/request', payload);
    };

    self.socialLogin = function (provider) {
      return $auth.authenticate(provider, { client_type: 'web' });
    };

    self.getUserType = function () {
      var payload = $auth.getPayload();

      if (!payload) {
        return null;
      }

      return payload.sub.type;
    };

    self.emailSignup = function (firstName, lastName, email, password, type) {
      var user = {
        firstName: firstName,
        lastName: lastName,
        username: email,
        password: password,
        type: type
      };

      return $auth.signup({ user: user }).then(function (response) {
        $auth.setToken(response.data.token);
      });
    };

    self.emailLogin = function (username, password, type) {
      const user = {
        username,
        password,
        type
      };

      return apiBase.post('/auth/validate', user).then((response) => {
        if (response.status !== 200) {
          throw response;
        }

        const { access_token: accessToken, refresh_token: refreshToken } =
          response.data;

        if (accessToken) {
          $auth.setToken(accessToken);

          localStorageService.set(cacheKeys.refreshToken, refreshToken);
        }

        return response;
      });
    };

    //To avoid displaying incorrect data when an autologin occurs
    //the detection of a new knock token allows to reload the page to
    //refresh the data
    window.addEventListener('storage', (e) => {
      if (e.key === 'satellizer.knock_token') {
        if (e.oldValue !== e.newValue) {
          window.location.reload();
        }
      }
    });

    self.loginWithToken = function (newToken) {
      var currentToken = $auth.getToken();

      /**
       * If the user currently logged in is the user we're trying to log into. We do nothing and redirect.
       */
      if ($auth.isAuthenticated() && currentToken === newToken) {
        self.handleAuthenticatedRedirection();
        return;
      }

      /**
       * If the user currently logged in is not the user we're trying to log into. We log them out so
       * we can log the new user in.
       */
      if ($auth.isAuthenticated() && currentToken !== newToken) {
        self.unsubcribePusherClient();
        $auth.logout();
        localCache.clear();
      }

      $auth.setToken(newToken);

      /**
       * Check if the user is authenticated, if they're not the token is invalid.
       * If this throws an error the token is also invalid since there was potentially a parsing error.
       */
      var isTokenInvalid = false;
      try {
        if (!$auth.isAuthenticated()) {
          isTokenInvalid = true;
        }
      } catch (e) {
        isTokenInvalid = true;
      }

      /**
       * If the token is invalid, check to see if we have a current token.
       * If we have a current token, set that as our auth token.
       * If we don't have a current token, then we remove the current token.
       */
      if (isTokenInvalid) {
        if (currentToken != null) {
          $auth.setToken(currentToken);
        } else {
          $auth.removeToken();
        }
      }

      $rootScope.$emit(apiEvents.profileUpdated);

      var refreshToken = localCache.get(cacheKeys.refreshToken);

      if (!refreshToken) {
        userService.getRefreshToken(newToken);
      }

      /**
       * Re-initialize our pusher client and update pusher in
       * conversationService, notificationsService, and force logout
       */
      pusherInstanceService.initializeClient();
      conversationsService.updateSocketClient();
      notificationsService.updatePusher();
      self.updatePusher();

      /**
       * Re-initialize our app data service to update our "vital" app data with our
       * new token changes.
       */
      appDataService.initialize();

      self.handleAuthenticatedRedirection();
    };

    self.unsubcribePusherClient = function () {
      notificationsService.unsubscribe();
      conversationsService.unsubscribeSocketClient();
      self.unsubscribeForceLogout();
    };

    self.initialize = function () {
      try {
        if (!self.pusher_client) {
          self._setupPusherClient();
        }
      } catch (e) {
        console.error(`PUSHER FORCE LOGOUT ERROR: ${e}`);
      }
    };

    self.unsubscribeForceLogout = function () {
      if (isNull(self.pusher_client)) {
        return;
      }

      self.pusher_client.unsubscribe('authentication');
    };

    self.updatePusher = function () {
      self._setupPusherClient();
    };

    self._setupPusherClient = function () {
      self.pusher_client = pusherInstanceService.getInstance();

      self.pusher_client.subscribe('authentication');
      self.pusher_client.bind(
        self.events.forceLogout,
        self._onPusherForceLogout
      );
    };

    self._onPusherForceLogout = function (message) {
      const userId = userService.getUser().id;
      const managerIds = message.filters.managerIds;

      if (managerIds && managerIds.length && Array.isArray(managerIds)) {
        const logoutUser = managerIds.includes(String(userId));

        if (logoutUser) {
          $auth.logout();
          window.location.assign(
            `${LOGIN_UI_BASE_URL}/logout?redirect_url=${WEBFRONT_BASE_URL}/login`
          );
        }
      }
    };

    self.handleAuthenticatedRedirection = function () {
      var user = userService.getUser();

      function navigateTo(url) {
        $location.url(url);
      }

      /**
       * If we have an authenticated user we redirect!
       */
      if ($auth.isAuthenticated() && user != null) {
        var navigateToUrl = $routeParams.to;

        /**
         * If we have a to=some-url param on our url we redirect here.
         */
        if (navigateToUrl != null) {
          return navigateTo(navigateToUrl);
        }

        /**
         * If we have a redirect location
         */
        var redirectLocation = localCache.get(cacheKeys.loginRedirectPath);
        if (redirectLocation) {
          if (redirectLocation !== '/') {
            localCache.del(cacheKeys.loginRedirectPath);
            return navigateTo(redirectLocation);
          }
        }

        /**
         * If the user is a manager
         */
        if (user.type === 'manager') {
          return navigateTo('/dashboard');
        }
      }

      /**
       * We're not logged in so we redirect to home.
       */
      return navigateTo('/');
    };

    self.openForgotPasswordModal = function (userType, username) {
      return forgotPasswordModalFactory.openForgotPasswordModal(
        userType,
        username
      );
    };

    self.sendForgotPasswordRequest = function (userType, username) {
      var payload = {
        user_type: userType,
        username: username
      };

      return apiBase.post('/auth/password/forgot', payload);
    };

    self.authenticationFailure = function () {
      localStorageService.clearAll();
      $route.reload();
    };

    return self;
  }
]);
