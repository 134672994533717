import { SelectedFilterOption } from '../common';
import { FilterOption } from '../features/prospects/filter';

export const getFilterOptions = (
  filterOptions: SelectedFilterOption
): string[] | undefined => {
  const keys = Object.keys(filterOptions);
  let options: string[] | undefined;

  if (keys.length > 0) {
    options = keys.map((key: string) => {
      return filterOptions[key].value;
    });
  }

  return options;
};

export const getSelectedFilters = (filters: FilterOption[]) => {
  return filters.reduce((source, filterOption) => {
    return { ...source, [filterOption.value]: filterOption };
  }, {});
};

export const getQueryStringParameter = (queryString: string, key: string) => {
  const queryParams = new URLSearchParams(queryString);

  return queryParams.get(key) || '';
};
