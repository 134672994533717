import React, { FC } from 'react';

import { Box, makeStyles, Text } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles(() => ({
  content: {
    marginTop: '50px',
    textAlign: 'center'
  },

  primaryText: {
    marginTop: '20px'
  },

  secondaryText: {
    color: '#999999',
    marginTop: '20px'
  }
}));

interface LeasingBinderNoContentProps {
  icon: React.ReactNode;
  primaryText: string;
}

const LeasingBinderNoContent: FC<LeasingBinderNoContentProps> = ({
  icon,
  primaryText
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      {icon}
      <Text variant="h6" className={classes.primaryText}>
        {primaryText}
      </Text>
      <Text variant="body2" className={classes.secondaryText}>
        Contact an administrator to update this property’s information.
      </Text>
    </Box>
  );
};

export default LeasingBinderNoContent;
