/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import sample from 'lodash/sample';

(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('appLoading', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/data/app-loading.html',
      scope: {},
      transclude: true,
      controller: [
        '$scope',
        '$timeout',
        'appDataService',
        function ($scope, $timeout, appDataService) {
          var randomMessages = [
            'Have an awesome day!',
            'Life is good!',
            'Firing up the engines...',
            'Engaging thrusters...',
            "Knock, knock... Who's there? Knock."
          ];

          $scope.data = appDataService.data;

          $scope.viewState = {
            isLoading: appDataService.data.isLoading,
            isFading: false,
            randomMessage: sample(randomMessages)
          };

          $scope.reloadData = function () {
            appDataService.initialize();
          };

          $scope.$watch('data.isLoading', function (isLoading, wasLoading) {
            if ($scope.data.isError) {
              $scope.viewState.isLoading = false;
              return;
            }

            if (isLoading && !wasLoading) {
              $scope.viewState.isLoading = true;
              $scope.viewState.isFading = false;
            }

            if (!isLoading && wasLoading && !$scope.viewState.isFading) {
              $timeout(
                function () {
                  $scope.viewState.isFading = true;

                  $timeout(
                    function () {
                      $scope.viewState.isLoading = false;
                    },
                    1000,
                    true
                  );
                },
                1000,
                true
              );
            }
          });
        }
      ]
    };
  });
})(window.angular);
