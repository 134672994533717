export const matchFilterOptions = [
  {
    label: 'Great Match',
    id: 'Great Match',
    value: 'greatMatch',
    type: 'Match'
  },
  {
    label: 'Good Match',
    id: 'Good Match',
    value: 'goodMatch',
    type: 'Match'
  },
  {
    label: 'Close Match',
    id: 'Close Match',
    value: 'closeMatch',
    type: 'Match'
  },
  { label: 'No Match', id: 'No Match', value: 'noMatch', type: 'Match' },
  {
    label: 'Undetermined',
    id: 'Undetermined',
    value: 'undetermined',
    type: 'Match'
  }
];

export const noticeFilterOptions = [
  { label: 'On Notice', id: 'on_notice', value: 'on_notice', type: 'Notice' },
  {
    label: 'Not On Notice',
    id: 'no_notice',
    value: 'no_notice',
    type: 'Notice'
  }
];

export const bathsFilterOptions = [
  { label: '1 bath', id: '1Bath', value: '1', type: 'Baths' },
  { label: '1.5 baths', id: '1.5Bath', value: '1.5', type: 'Baths' },
  { label: '2 baths', id: '2Bath', value: '2', type: 'Baths' },
  { label: '2.5 baths', id: '2.5Bath', value: '2.5', type: 'Baths' },
  { label: '3+ baths', id: '3Bath', value: '3', type: 'Baths' }
];

export const bedFilterOptions = [
  { label: 'Studio', value: 0, type: 'Beds', id: 0 },
  { label: '1bd', value: 1, type: 'Beds', id: 1 },
  { label: '2bd', value: 2, type: 'Beds', id: 2 },
  { label: '3bd+', value: 3, type: 'Beds', id: 3 }
];

export const occupancyFilterOptions = [
  { label: 'Vacant', id: 'vacant', value: 'vacant', type: 'Occupancy' },
  { label: 'Occupied', id: 'occupied', value: 'occupied', type: 'Occupancy' }
];
