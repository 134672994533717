import React, { FC, useState, useRef } from 'react';
import {
  Popover,
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  IconButton,
  ListItemAvatar,
  Avatar
} from '@material-ui/core';
import { MenuIcon, AnalyticsIcon, ClawIcon } from '../../../icons';
import { AppMenuProps } from '../types';
import './MenuItem.scss';

const useStyles = makeStyles({
  boxStyle: {
    width: '320px',
    padding: '16px 0 8px 0'
  },
  appMenuTitle: {
    color: 'rgba(0, 0, 0, 0.64)',
    fontWeight: 'normal',
    padding: '18px'
  },
  avatarColorDefault: {
    color: '#697FF7',
    backgroundColor: '#EBEEFE'
  }
});

export const AppMenu: FC<AppMenuProps> = ({
  openKnockAnalytics,
  openClawApp,
  isClawAvailable,
  hideAnalytics
}) => {
  const [open, setOpen] = useState(false);
  const appMenuRef = useRef<HTMLButtonElement>(null);

  const { boxStyle, appMenuTitle, avatarColorDefault } = useStyles();

  const redirectUserToDifferentApp = (callback: () => void) => {
    setOpen(!open);
    callback();
  };

  return (
    <>
      <IconButton
        className="menu-icon-button"
        aria-describedby="popover-app-menu"
        data-testid="app-menu-button"
        ref={appMenuRef}
        onClick={() => setOpen(!open)}
        component="span"
      >
        <MenuIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={appMenuRef.current}
        onClose={() => setOpen(!open)}
        role="popover-app-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box className={boxStyle}>
          <Typography variant="overline" className={appMenuTitle}>
            KNOCK SUITE
          </Typography>

          <List>
            {isClawAvailable && (
              <ListItem
                onClick={() => redirectUserToDifferentApp(openClawApp)}
                data-testid="menu-item-claw"
              >
                <ListItemAvatar>
                  <Avatar classes={{ colorDefault: avatarColorDefault }}>
                    <ClawIcon />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText primary="Centralized Leasing" />
              </ListItem>
            )}

            {!hideAnalytics && (
              <ListItem
                onClick={() => redirectUserToDifferentApp(openKnockAnalytics)}
                data-testid="menu-item-analytics"
              >
                <ListItemAvatar>
                  <Avatar classes={{ colorDefault: avatarColorDefault }}>
                    <AnalyticsIcon />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText primary="Insights & Analytics" />
              </ListItem>
            )}
          </List>
        </Box>
      </Popover>
    </>
  );
};
