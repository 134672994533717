/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-MarketingTools');

  app.directive('marketingToolsCommunityTemplates', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/marketingTools/marketing-tools-community-templates.html',
      controller: 'MarketingToolsCommunityTemplatesController',
      scope: {
        community: '=',
        templates: '=',
        relays: '='
      }
    };
  });

  app.controller('MarketingToolsCommunityTemplatesController', [
    '$scope',
    '$q',
    '$moment',
    '$mdToast',
    'relayApi',
    'communityApi',
    function ($scope, $q, $moment, $mdToast, relayApi, communityApi) {
      $scope.EMAIL_MAX_LENGTH = 1500;
      $scope.AUTO_RESPONSE_MAX_LENGTH = 1500;
      $scope.SMS_MAX_LENGTH = 140;

      $scope.mailerDurations = [
        { minutes: null, text: 'Never', durationString: '0:00:00' },
        { minutes: 1, text: '1 minute', durationString: '0:01:00' },
        { minutes: 15, text: '15 minutes', durationString: '0:15:00' },
        { minutes: 30, text: '30 minutes', durationString: '0:30:00' },
        { minutes: 60, text: '1 hour', durationString: '1:00:00' },
        {
          minutes: 60 * 24,
          text: '24 hours',
          durationString: '1 day, 0:00:00'
        }
      ];

      $scope.data = {
        selectedDuration: null
      };

      $scope.save = function () {
        var duration = $moment.duration(
          $scope.data.selectedDuration
            ? $scope.data.selectedDuration.minutes
            : null,
          'minutes'
        );

        var savePromises = [
          communityApi.updateCommunityTemplate(
            $scope.community.id,
            $scope.templates
          )
        ];

        $q.all(savePromises).then(
          function () {
            $mdToast.showSimple('Templates updated!');
          },
          function () {
            $mdToast.showSimple('There was an error saving your templates.');
          }
        );
      };
    }
  ]);
})(window.angular);
