import React from 'react';

export const BuildingBaseIcon = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 3.39998V15.88H3.5V3.39998M14.5 3.39998H3.5M14.5 3.39998L13.5 1.47998H4.5L3.5 3.39998M1.5 15.88H16.5M10.5 14.44C10.5 13.6447 9.82843 13 9 13C8.17157 13 7.5 13.6447 7.5 14.44V15.88H10.5V14.44Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 5.08002C6.13807 5.08002 6.25 5.18747 6.25 5.32002"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.75 5.32002C5.75 5.18747 5.86193 5.08002 6 5.08002"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 5.56001C5.86193 5.56001 5.75 5.45256 5.75 5.32001"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 5.32001C6.25 5.45256 6.13807 5.56001 6 5.56001"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 7.95996C6.13807 7.95996 6.25 8.06741 6.25 8.19996"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.75 8.19996C5.75 8.06741 5.86193 7.95996 6 7.95996"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 8.44007C5.86193 8.44007 5.75 8.33262 5.75 8.20007"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 8.20007C6.25 8.33262 6.13807 8.44007 6 8.44007"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 10.84C6.13807 10.84 6.25 10.9475 6.25 11.08"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.75 11.08C5.75 10.9475 5.86193 10.84 6 10.84"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 11.32C5.86193 11.32 5.75 11.2126 5.75 11.08"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 11.08C6.25 11.2126 6.13807 11.32 6 11.32"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 5.08002C9.13807 5.08002 9.25 5.18747 9.25 5.32002"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 5.32002C8.75 5.18747 8.86193 5.08002 9 5.08002"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 5.56001C8.86193 5.56001 8.75 5.45256 8.75 5.32001"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.25 5.32001C9.25 5.45256 9.13807 5.56001 9 5.56001"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 7.95996C9.13807 7.95996 9.25 8.06741 9.25 8.19996"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 8.19996C8.75 8.06741 8.86193 7.95996 9 7.95996"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 8.44007C8.86193 8.44007 8.75 8.33262 8.75 8.20007"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.25 8.20007C9.25 8.33262 9.13807 8.44007 9 8.44007"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 10.84C9.13807 10.84 9.25 10.9475 9.25 11.08"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 11.08C8.75 10.9475 8.86193 10.84 9 10.84"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 11.32C8.86193 11.32 8.75 11.2126 8.75 11.08"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.25 11.08C9.25 11.2126 9.13807 11.32 9 11.32"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 5.08002C12.1381 5.08002 12.25 5.18747 12.25 5.32002"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.75 5.32002C11.75 5.18747 11.8619 5.08002 12 5.08002"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 5.56001C11.8619 5.56001 11.75 5.45256 11.75 5.32001"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.25 5.32001C12.25 5.45256 12.1381 5.56001 12 5.56001"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 7.95996C12.1381 7.95996 12.25 8.06741 12.25 8.19996"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.75 8.19996C11.75 8.06741 11.8619 7.95996 12 7.95996"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8.44007C11.8619 8.44007 11.75 8.33262 11.75 8.20007"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.25 8.20007C12.25 8.33262 12.1381 8.44007 12 8.44007"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 10.84C12.1381 10.84 12.25 10.9475 12.25 11.08"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.75 11.08C11.75 10.9475 11.8619 10.84 12 10.84"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 11.32C11.8619 11.32 11.75 11.2126 11.75 11.08"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.25 11.08C12.25 11.2126 12.1381 11.32 12 11.32"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
