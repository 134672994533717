import React, { FC } from 'react';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import { ConcessionsStatusMessage } from '../CreateQuoteView';

const useStyles = makeStyles((theme) => ({
  alert: {
    color: theme.palette.text.primary,
    padding: '0 16px'
  },

  needDataForConcessionsMessage: {
    backgroundColor: 'rgba(90, 114, 246, .08)'
  },

  noConcessionsMessage: {
    backgroundColor: 'rgba(141, 144, 165, .08)'
  }
}));

export interface ConcessionAlertProps {
  concessionsStatusMessage: string;
}

const ConcessionsAlert: FC<ConcessionAlertProps> = ({
  concessionsStatusMessage = ''
}) => {
  const classes = useStyles();

  const alertIcon =
    concessionsStatusMessage !== ConcessionsStatusMessage.ERROR
      ? false
      : undefined;
  const alertSeverity =
    concessionsStatusMessage === ConcessionsStatusMessage.ERROR
      ? 'error'
      : undefined;
  let alertClassName = '';

  if (concessionsStatusMessage === ConcessionsStatusMessage.NEED_INPUT) {
    alertClassName = classes.needDataForConcessionsMessage;
  } else if (
    concessionsStatusMessage === ConcessionsStatusMessage.UNAVAILABLE
  ) {
    alertClassName = classes.noConcessionsMessage;
  }

  return (
    <>
      {concessionsStatusMessage !== ConcessionsStatusMessage.LOADING &&
      concessionsStatusMessage !== '' ? (
        <Alert
          icon={alertIcon}
          severity={alertSeverity}
          className={`${classes.alert} ${alertClassName}`}
        >
          {concessionsStatusMessage}
        </Alert>
      ) : (
        ''
      )}
    </>
  );
};

export default ConcessionsAlert;
