import React, { FC } from 'react';

export const SoundOffIcon: FC = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#FDE2E5" />
    <path
      d="M22.816 28.735L26.1 30.825C26.5545 31.1659 27.1626 31.2207 27.6708 30.9666C28.179 30.7126 28.5 30.1932 28.5 29.625V24.375M28.5 17.625C28.5 17.0569 28.179 16.5375 27.6708 16.2834C27.1626 16.0293 26.5545 16.0841 26.1 16.425L19.5 20.625H16.5C15.6716 20.625 15 21.2966 15 22.125V25.125C15 25.9534 15.6716 26.625 16.5 26.625H17.125M15 31.875L33 16.875"
      stroke="#D21A32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
