/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Voice');

  app.directive('voiceCallButton', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/voice/voice-call-button.html',
      controller: 'VoiceCallButton',
      replace: true
    };
  });

  app.controller('VoiceCallButton', [
    '$scope',
    'voiceService',
    function ($scope, voiceService) {
      $scope.activeCall = voiceService.getActiveCallForStream(
        $scope.conversation.id
      );

      $scope.placeCall = function () {
        var source = $scope.data.otherUser.info.source;

        voiceService
          .placeCall(
            $scope.data.otherUser.type,
            $scope.data.otherUser.id,
            $scope.conversation.id,
            source
          )
          .then(function (activeCall) {
            $scope.activeCall = activeCall;
          });
      };

      $scope.endCall = function () {
        if (!$scope.activeCall.isActive || isEmpty($scope.activeCall)) {
          return;
        }

        voiceService.endCall($scope.activeCall.callId);
        $scope.activeCall = null;
      };
    }
  ]);
})(window.angular);
