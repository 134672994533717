import React, { FC } from 'react';

export const SettingsSliderIcon: FC<{ id?: string }> = ({ id }) => (
  <svg
    data-testid={id}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.62598 3.125C1.62598 2.29657 2.29755 1.625 3.12598 1.625H18.876C19.7044 1.625 20.376 2.29657 20.376 3.125V18.875C20.376 19.7034 19.7044 20.375 18.876 20.375H3.12598C2.29755 20.375 1.62598 19.7034 1.62598 18.875V3.125Z"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.626 14.125H16.626"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.37598 14.125H7.87598"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.626 14.125C11.626 15.1604 10.7867 15.9998 9.75125 16C8.71583 16.0002 7.87628 15.161 7.87598 14.1256C7.87567 13.0901 8.71472 12.2505 9.75014 12.25C10.2476 12.2498 10.7247 12.4472 11.0765 12.7989C11.4283 13.1505 11.626 13.6276 11.626 14.125V14.125Z"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.876 7.875H5.37598"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.626 7.875C16.626 6.83957 15.7867 6.00015 14.7513 6C13.7158 5.99985 12.8763 6.83902 12.876 7.87444C12.8757 8.90987 13.7147 9.74954 14.7501 9.75C15.7858 9.75 16.6255 8.91067 16.626 7.875Z"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
