/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('logger', function () {
    return function ($delegate) {
      return {
        log: function () {
          // TODO: log to some external service
          $delegate.log.apply(this, arguments);
        },
        info: function () {
          // TODO: log to some external service
          $delegate.info.apply(this, arguments);
        },
        error: function () {
          // TODO: log to some external service
          $delegate.error.apply(this, arguments);
        },
        warn: function () {
          // TODO: log to some external service
          $delegate.warn.apply(this, arguments);
        }
      };
    };
  });
})();
