/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectContactForm', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospects/prospect-contact-form.html',
      controller: 'ProspectContactFormController',
      scope: {
        listing: '='
      }
    };
  });

  app.controller('ProspectContactFormController', [
    '$scope',
    '$location',
    '$anchorScroll',
    '$q',
    'listingApi',
    'prospectsApi',
    'imageBucket',
    function (
      $scope,
      $location,
      $anchorScroll,
      $q,
      listingApi,
      prospectsApi,
      imageBucket
    ) {
      var self = this;

      $scope.imageBucket = imageBucket;

      $scope.submitProspect = function () {
        $location.hash('top');
        $anchorScroll();

        $scope.isCreatingProspect = true;
        $scope.errorCreatingProspect = false;

        if ($scope.listing.community_id) {
          $scope.prospect.community_id = $scope.listing.community_id;
        } else {
          $scope.prospect.listing_id = $scope.listing.id;
        }

        $scope.prospect.first_contact_type = 'chat';
        $scope.prospect.manager_id = self._listingManager.manager_id;

        prospectsApi
          .createNewProspect($scope.prospect)
          .success(function () {
            $scope.successCreatingProspect = true;
          })
          .error(function (error, status) {
            if (status === 409) {
              $scope.prospectAlreadyInSystem = true;
            } else {
              $scope.errorCreatingProspect = true;
            }
          })
          .finally(function () {
            $scope.isCreatingProspect = false;
          });
      };

      self._initialize = function () {
        var initPromises = [
          self._getListingInfoStatus(),
          self._getListingManager()
        ];

        $q.all(initPromises).finally(function () {
          $scope.isLoaded = true;
        });
      };

      self._getListingInfoStatus = function () {
        return listingApi
          .getListingInfoStatus($scope.listing.id)
          .success(function (response) {
            $scope.isPending = response.status === 'pending';
          });
      };

      self._getListingManager = function () {
        return listingApi
          .getListingManager($scope.listing.id)
          .success(function (response) {
            self._listingManager = response.manager_info;
          });
      };

      self._initialize();
    }
  ]);
})(window.angular);
