import { legacyAngularRoutes } from '../../legacyAngularRoutes';

export default function configureRoutes(app: any) {
  app.config([
    '$routeProvider',
    '$locationProvider',
    'MAINTENANCE_MODE',
    function (
      $routeProvider: any,
      $locationProvider: any,
      MAINTENANCE_MODE: any
    ) {
      if (MAINTENANCE_MODE) {
        $routeProvider
          .when('/', {
            templateUrl: '/angular/views/landing/maintenance.html',
            hideNavigation: true
          })
          .otherwise({
            templateUrl: '/angular/views/landing/maintenance.html',
            hideNavigation: true
          });

        $locationProvider.html5Mode(true);

        return;
      }

      legacyAngularRoutes.forEach((route) => {
        $routeProvider.when(route.path, route.params);
      });

      $routeProvider.otherwise({
        templateUrl: '/angular/views/404.html'
      });

      $locationProvider.html5Mode(true);
    }
  ]);
}
