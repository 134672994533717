/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('renterLogin', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/login/renter-login-body.html',
      scope: {
        onLoggedIn: '=',
        onToggleForgotPassword: '='
      },
      controller: [
        '$scope',
        '$interpolate',
        'loginService',
        function ($scope, $interpolate, loginService) {
          var self = this;
          var errorMessageStrings = loginService.getErrorMessageStrings();

          $scope.renter = {
            username: '',
            password: '',
            type: 'renter'
          };

          $scope.facebookLogin = function () {
            self.socialLogin('facebook');
          };

          $scope.linkedInLogin = function () {
            self.socialLogin('linkedin');
          };

          self.socialLogin = function (provider) {
            $scope.socialErrorMessage = null;

            loginService
              .socialLogin(provider)
              .then(function (response) {
                $scope.onLoggedIn(response);
              })
              .catch(function (response) {
                if (loginService.isPrivateBrowsingError(response)) {
                  $scope.socialErrorMessage =
                    errorMessageStrings.privateBrowsingErrorMessage;
                  return;
                }

                $scope.socialErrorMessage = errorMessageStrings.errorMessage;
              });
          };

          $scope.emailLogin = function () {
            $scope.emailErrorMessage = null;
            $scope.isLoggingIn = true;

            loginService
              .usernameLogin($scope.renter)
              .then(function (response) {
                $scope.onLoggedIn(response);
              })
              .catch(function (response) {
                if (loginService.isPrivateBrowsingError(response)) {
                  $scope.emailErrorMessage =
                    errorMessageStrings.privateBrowsingErrorMessage;
                  return;
                }

                var existingAccountProvider =
                  loginService.getExistingAccountProvider(response);
                if (existingAccountProvider) {
                  $scope.emailErrorMessage = $interpolate(
                    errorMessageStrings.useSocialLoginMessageTemplate
                  )({ provider: existingAccountProvider });
                  return;
                }

                $scope.emailErrorMessage =
                  errorMessageStrings.invalidLoginMessage;
              })
              .finally(function () {
                $scope.isLoggingIn = false;
              });
          };
        }
      ]
    };
  });
})();
