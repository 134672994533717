/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('directionsService', [
    '$window',
    function ($window) {
      var directionsService = {};

      directionsService.openDirectionsPage = function (address) {
        var parsedAddress = address.split(' ').join('+');

        $window.open(
          'https://www.google.com/maps/dir/Current+Location/' + parsedAddress
        );
      };

      return directionsService;
    }
  ]);
})(window.angular);
