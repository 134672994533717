import findIndex from 'lodash/findIndex';
import { STORAGE_CONVERSATION_MESSAGE_MODE_KEY } from '../../../constants/constants';

(function (angular) {
  var app = angular.module('knockApp');

  app.factory('streamCarouselService', [
    '$q',
    'streamRepositoryService',
    '$location',
    '$timeout',
    '_',
    'chatTrackingService',
    '$window',
    function (
      $q,
      streamRepositoryService,
      $location,
      $timeout,
      _,
      chatTrackingService,
      $window
    ) {
      var self = this;

      self._lastPath = null;
      self._carouselHiddenDefer = null;

      self.state = {
        streamIds: [],
        currentIndex: -1,
        streamData: null,
        batchId: null,
        isTransitioning: false,
        isError: false
      };

      self._lastParams = {};

      self.showCarousel = function (streamIds, batchId, startingStreamId) {
        chatTrackingService.hide();
        self._carouselHiddenDefer = $q.defer();

        self.state.streamIds = streamIds || [];
        self.state.currentIndex = 0;

        self._lastPath = $location.path();

        const urlParams = new URLSearchParams(window.location.search);
        self._lastParams = Object.fromEntries(urlParams.entries());

        if (streamIds.length <= 1) {
          self._initCurrent();
        } else {
          if (startingStreamId) {
            self.state.currentIndex = Math.max(
              findIndex(streamIds, function (streamId) {
                return streamId === startingStreamId;
              }),
              0
            );
          }

          if (batchId) {
            self.state.batchId = batchId;
            self._initCurrent();
          } else {
            streamRepositoryService
              .createStreamBatch(streamIds)
              .then(function (response) {
                if (response.status !== 200) {
                  console.error(
                    `showCarousel -> createStreamBatch() -> streamId: ${streamIds} -> response: ${response.status} - ${response.statusText}, message: ${response.data}`
                  );
                  self.state.isError = true;
                }
                self.state.batchId = response.data.batch_id;
                self._initCurrent();
              });
          }
        }

        return self._carouselHiddenDefer.promise;
      };

      self.hideCarousel = function () {
        self.state.streamIds = [];
        self.state.currentIndex = -1;
        self.state.streamData = null;
        self.state.batchId = null;
        self.state.isError = false;

        $location.update_path(self._lastPath, true);

        if (!self._lastPath.includes('conversation/')) {
          $location.search(self._lastParams);
        }

        streamRepositoryService.clearCache();

        self._carouselHiddenDefer.resolve();
      };

      self.onStreamArchived = function (streamId) {
        if (self.state.currentIndex === -1) {
          return;
        }

        if (self.state.streamIds.length === 0) {
          return;
        }

        if (self.state.streamIds[self.state.currentIndex] === streamId) {
          if (self.state.streamIds.length === 1) {
            self.hideCarousel();
            return;
          }

          self.next();
        }
      };

      self.next = function () {
        var nextIndex =
          self.state.currentIndex === self.state.streamIds.length - 1
            ? 0
            : self.state.currentIndex + 1;

        self.changeIndex(nextIndex);
      };

      self.previous = function () {
        var prevIndex =
          self.state.currentIndex === 0
            ? self.state.streamIds.length - 1
            : self.state.currentIndex - 1;

        self.changeIndex(prevIndex);
      };

      self.changeIndex = function (newIndex) {
        self.state.isTransitioning = true;
        self.state.isError = false;
        $timeout(function () {
          self.state.currentIndex = newIndex;
          self._initCurrent();
        }, 50);
      };

      self._initCurrent = function () {
        self.preloadNext();

        var currentStreamId = self.state.streamIds[self.state.currentIndex];

        $location.update_path('/conversation/' + currentStreamId, true);
        $location.search(
          self.state.batchId ? 'batchId=' + self.state.batchId : ''
        );

        streamRepositoryService
          .getStream(currentStreamId, true)
          .then(function (response) {
            self.state.streamData = response.data.stream;
            $timeout(function () {
              self.state.isTransitioning = false;
            }, 250);
          })
          .catch(function (response) {
            let errorMessage = `streamCarousel._initCurrent() -> getStream() -> streamId: ${currentStreamId} -> ${
              response.status
            } ${response.statusText} ${JSON.stringify(response.data)}`;
            self.state.isError = true;

            // We remove this item in case retrieving the given stream was done via clicking on a button in the
            // prospects page Contact column, so we don't inadvertently default to the wrong tab in the guest card
            // conversation area for a different prospect
            $window.sessionStorage.removeItem(
              STORAGE_CONVERSATION_MESSAGE_MODE_KEY
            );

            throw new Error(errorMessage);
          });
      };

      self.preloadNext = function () {
        var nextIndex =
          self.state.currentIndex === self.state.streamIds.length - 1
            ? 0
            : self.state.currentIndex + 1;

        streamRepositoryService.preloadStream(self.state.streamIds[nextIndex]);
      };

      return self;
    }
  ]);

  app.directive('streamCarousel', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/stream-carousel.html',
      controller: 'StreamCarouselController',
      scope: {}
    };
  });

  const StreamCarouselController = function (
    $rootScope,
    $scope,
    streamCarouselService
  ) {
    $scope.carousel = streamCarouselService.state;

    $scope.hideCarousel = function () {
      streamCarouselService.hideCarousel();
      $rootScope.$emit('streamCarouselService.hideCarousel');
    };

    $scope.next = function () {
      streamCarouselService.next();
    };

    $scope.previous = function () {
      streamCarouselService.previous();
    };
  };

  StreamCarouselController.$inject = [
    '$rootScope',
    '$scope',
    'streamCarouselService',
    '$window'
  ];

  app.controller('StreamCarouselController', StreamCarouselController);
})(window.angular);
