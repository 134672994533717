/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.filter('stringDate', [
    '$filter',
    function ($filter) {
      return function (stringDate, format) {
        var dateFilter = $filter('date');

        var date = new Date(stringDate);

        return dateFilter(date, format);
      };
    }
  ]);

  app.filter('moment', [
    '$moment',
    function ($moment) {
      return function (date, format) {
        if (!date) {
          return '';
        }

        if (!format) {
          return date;
        }

        return $moment(date).format(format);
      };
    }
  ]);
})();
