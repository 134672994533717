/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.filter('nonNegativeNumber', function () {
    return function (number) {
      return Math.max(0, number);
    };
  });
})(window.angular);
