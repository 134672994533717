import React, { FC } from 'react';

export const MoneyIcon: FC = () => (
  <svg
    width={18}
    height={16}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.81366 5.33333L4.18634 5.66667M13.8137 10.3333L14.1863 10.6667M4 5.25C4.13807 5.25 4.25 5.36193 4.25 5.5C4.25 5.63807 4.13807 5.75 4 5.75C3.86193 5.75 3.75 5.63807 3.75 5.5C3.75 5.36193 3.86193 5.25 4 5.25ZM14 10.25C14.1381 10.25 14.25 10.3619 14.25 10.5C14.25 10.6381 14.1381 10.75 14 10.75C13.8619 10.75 13.75 10.6381 13.75 10.5C13.75 10.3619 13.8619 10.25 14 10.25ZM2.5 13H15.5C16.0523 13 16.5 12.5523 16.5 12V4C16.5 3.44772 16.0523 3 15.5 3H2.5C1.94772 3 1.5 3.44772 1.5 4V12C1.5 12.5523 1.94772 13 2.5 13ZM9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
