import React, { FC } from 'react';
import { Box, SvgIcon } from '@material-ui/core';

import { makeStyles, NamedColors } from '@knockrentals/knock-shared-web';
import DarkTooltip from '../../Tooltips/DarkTooltip';
import { HotLeadIcon } from '../../icons';

interface HotLeadProps {
  isHotLead: boolean;
}

const HotLead: FC<HotLeadProps> = ({ isHotLead }) => {
  const useStyles = makeStyles(() => ({
    shadowBox: {
      width: '20px'
    },

    hotLeadIcon: {
      stroke: NamedColors.carrot[600]
    }
  }));

  const classes = useStyles();

  if (isHotLead) {
    return (
      <DarkTooltip
        data-testid="hot-lead"
        disableTouchListener
        arrow
        title="Hot Lead"
        aria-label="Hot Lead"
      >
        <SvgIcon className={classes.hotLeadIcon}>
          <HotLeadIcon />
        </SvgIcon>
      </DarkTooltip>
    );
  }

  return <Box className={classes.shadowBox} />;
};

export default HotLead;
