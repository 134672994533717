import React, { FC } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles, Text } from '@knockrentals/knock-shared-web';
import { BlueSwitch } from '../../Switch';
import { colors } from '../../commonStyles/commonStyles';
import TooltipContainer from '../../molecules/TooltipContainer/TooltipContainer';

const useStyles = makeStyles({
  optionTitle: {
    fontSize: '16px',
    fontWeight: 600,
    color: colors.defaultText
  },
  optionSubtitle: {
    fontSize: '14px',
    color: colors.secondaryText
  },
  optionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '32px'
  },
  optionDisabled: {
    color: colors.disabled
  }
});

interface OptionProps {
  title: string;
  subtitle: string;
  toggle: (evt: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
  checked?: boolean;
  tooltip?: string;
}

const Option: FC<OptionProps> = ({
  title,
  subtitle,
  toggle,
  disabled,
  checked,
  tooltip
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.optionContainer}>
      <Box>
        <Text
          className={`${classes.optionTitle} ${
            disabled ? classes.optionDisabled : ''
          }`}
        >
          {title}
        </Text>
        <Text className={classes.optionSubtitle}> {subtitle}</Text>
      </Box>
      <Box>
        <TooltipContainer tooltip={tooltip}>
          <BlueSwitch
            disabled={disabled}
            onChange={toggle}
            checked={checked || false}
          />
        </TooltipContainer>
      </Box>
    </Box>
  );
};

export default Option;
