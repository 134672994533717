/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('visitsApi', [
    'apiBase',
    function (apiBase) {
      return {
        getVisits: function () {
          return apiBase.get('/visits/');
        },
        createVisit: function (
          prospectId,
          visitTime,
          appointmentId,
          managerId
        ) {
          var payload = {
            prospect_id: prospectId,
            visit_time: visitTime,
            appointment_id: appointmentId,
            manager_id: managerId
          };

          return apiBase.post('/visits/', payload);
        },
        getVisit: function (visitId) {
          return apiBase.get('/visits/' + visitId);
        },
        updateVisit: function (visitId, visit) {
          return apiBase.put('/visits/' + visitId, visit);
        },
        deleteVisit: function (visitId) {
          return apiBase.delete('/visits/' + visitId);
        },
        setVisitPropertyUnits: function (visitId, propertyUnitIds) {
          // TODO: remove when Units feature is complete
          var payload = {
            property_unit_ids: propertyUnitIds
          };

          return apiBase.post(
            '/visits/' + visitId + '/property-units',
            payload
          );
        },
        addVisitPropertyUnit: function (visitId, propertyUnitId) {
          // TODO: remove when Units feature is complete
          return apiBase.post(
            '/visits/' + visitId + '/property-units/' + propertyUnitId
          );
        },
        removeVisitPropertyUnit: function (visitId, propertyUnitId) {
          // TODO: remove when Units feature is complete
          return apiBase.delete(
            '/visits/' + visitId + '/property-units/' + propertyUnitId
          );
        },
        getVisitShownUnits: function (visitId) {
          return apiBase.get('/visits/' + visitId + '/shown-units');
        },
        addVisitShownUnits: function (visitId, unitId) {
          return apiBase.post('/visits/' + visitId + '/shown-units/' + unitId);
        },
        setVisitShownUnits: function (visitId, unitIds) {
          var payload = {
            shown_unit_ids: unitIds
          };

          return apiBase.post('/visits/' + visitId + '/shown-units', payload);
        }
      };
    }
  ]);
})(window.angular);
