/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('UserPhoneModalController', [
    '$scope',
    '$modalInstance',
    'ProfileService',
    function ($scope, $modalInstance, ProfileService) {
      var self = this;

      self.initialize = function () {
        ProfileService.getProfile().success(function (response) {
          $scope.profile = response.profile;
        });
      };

      $scope.clearErrorMessage = function () {
        $scope.errorMessage = null;
      };

      $scope.dismiss = function () {
        $modalInstance.dismiss('closed');
      };

      $scope.savePhoneNumber = function () {
        if (isEmpty($scope.phone)) {
          $scope.errorMessage =
            'Invalid phone number. Must be 10 digits (i.e. 5551234567)';
        } else {
          $scope.isSavingInfo = true;

          $scope.profile.phone = $scope.phone;

          ProfileService.updateProfile($scope.profile)
            .success(function () {
              $modalInstance.close('saved');
            })
            .error(function () {
              $scope.errorMessage =
                "We're having a problem saving your phone number.";
            })
            .finally(function () {
              $scope.isSavingInfo = false;
            });
        }
      };

      self.initialize();
    }
  ]);
})();
