/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('companyApi', [
    'apiBase',
    function (apiBase) {
      return {
        getMyCompany: function () {
          return apiBase.get('/me/company');
        },
        updateProfile: function (company) {
          return apiBase.put('/me/leasing-team/profile', { company: company });
        },
        updateSyncPreferences: function (service, status) {
          return apiBase.put('/me/leasing-team/sync-preferences', {
            service: service,
            status: status
          });
        },
        getSyncPreferences: function () {
          return apiBase.get('/me/leasing-team/sync-preferences');
        },
        getCompanyPreferences: function () {
          return apiBase.get('/me/leasing-team/preferences');
        },
        updateIntegrationPreferences: function (companyId, payload) {
          return apiBase.put(
            '/company/' + companyId + '/integration/preferences',
            payload
          );
        }
      };
    }
  ]);
})();
