import React, { FC } from 'react';

export const MenuIcon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 0H1.5C0.671573 0 0 0.671573 0 1.5V9.5C0 10.3284 0.671573 11 1.5 11H9.5C10.3284 11 11 10.3284 11 9.5V1.5C11 0.671573 10.3284 0 9.5 0Z"
      fill="#70758F"
    />
    <path
      d="M22.5 0H14.5C13.6716 0 13 0.671573 13 1.5V9.5C13 10.3284 13.6716 11 14.5 11H22.5C23.3284 11 24 10.3284 24 9.5V1.5C24 0.671573 23.3284 0 22.5 0Z"
      fill="#70758F"
    />
    <path
      d="M9.5 13H1.5C0.671573 13 0 13.6716 0 14.5V22.5C0 23.3284 0.671573 24 1.5 24H9.5C10.3284 24 11 23.3284 11 22.5V14.5C11 13.6716 10.3284 13 9.5 13Z"
      fill="#70758F"
    />
    <path
      d="M22.5 13H14.5C13.6716 13 13 13.6716 13 14.5V22.5C13 23.3284 13.6716 24 14.5 24H22.5C23.3284 24 24 23.3284 24 22.5V14.5C24 13.6716 23.3284 13 22.5 13Z"
      fill="#70758F"
    />
  </svg>
);
