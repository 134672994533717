import React, { FC, useMemo } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  avatarColorDefault: {
    fontSize: '45px',
    height: '100%',
    width: '100%'
  }
});

interface InitialsAvatarProps {
  color: string;
  firstName: string;
  lastName: string;
}

const InitialsAvatar: React.FC<InitialsAvatarProps> = ({
  color,
  firstName,
  lastName
}) => {
  const classes = useStyles();

  const nameInitials: string = useMemo(
    () =>
      `${firstName} ${lastName}`
        .split(' ')
        .map((str) => (str ? str[0].toUpperCase() : ''))
        .join(''),
    [firstName, lastName]
  );

  if (color === '') {
    color = '#EBEEFE';
  }
  return (
    <>
      <Avatar
        classes={{ colorDefault: classes.avatarColorDefault }}
        role="profile-icon-avatar"
        style={{ backgroundColor: color ?? '#EBEEFE' }}
      >
        {nameInitials}
      </Avatar>
    </>
  );
};

export default InitialsAvatar;
