import isEmpty from 'lodash/isEmpty';

export default function runHandleRouteAuth(app: any) {
  app.run([
    '$rootScope',
    '$location',
    'appDataService',
    'chatTrackingService',
    '$auth',
    'userService',
    'ProfileService',
    'localCache',
    'cacheKeys',
    '_',
    function (
      $rootScope: any,
      $location: any,
      appDataService: any,
      chatTrackingService: any,
      $auth: any,
      userService: any,
      ProfileService: any,
      localCache: any,
      cacheKeys: any,
      _: any
    ) {
      ProfileService.initializeCachedProfile();

      $rootScope.$on(
        '$routeChangeStart',
        function (event: any, nextRoute: any) {
          var currentUser = userService.getUser();
          var isManager =
            currentUser && currentUser.type && currentUser.type === 'manager';

          if (!isManager) {
            appDataService.data.isInitialized = false;
          }

          if (isManager && !appDataService.data.isInitialized) {
            appDataService.initialize();
          }

          if (nextRoute && nextRoute.access && nextRoute.access.requireAuth) {
            var isLoggedIn = $auth.isAuthenticated();

            if (!isLoggedIn) {
              localCache.put(cacheKeys.loginRedirectPath, $location.$$path);

              var renterToken = nextRoute.params.renterToken;
              var prospectKnockID = nextRoute.params.pid;

              if (isEmpty(renterToken)) {
                $location.path('/login');
                return;
              } else if (isEmpty(prospectKnockID)) {
                $location.path('/login');
                return;
              } else {
                $location.path('/authenticating');
                return;
              }
            }

            var needsAuth = 'authUser' in nextRoute.access;

            var isRenterValid =
              needsAuth && nextRoute.access.authUser.indexOf('renter') >= 0;
            var needsManager =
              needsAuth &&
              nextRoute.access.authUser.indexOf('manager') >= 0 &&
              !isRenterValid;
            var needsOwner = nextRoute.access.needsOwner;

            if (isManager) {
              chatTrackingService.trackUser();
            }

            $rootScope.loggedInUser = currentUser;

            var isCompanyAdmin = isManager && currentUser.is_company_admin;

            if (
              (needsManager && !isManager) ||
              (needsOwner && !isCompanyAdmin)
            ) {
              $location.path('/404');
            }
          }
        }
      );
    }
  ]);
}
