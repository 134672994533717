/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('listingApi', [
    'apiBase',
    'HttpCache',
    function (apiBase, HttpCache) {
      var listingCache = new HttpCache(5);

      return {
        getListing: function (listingId) {
          return apiBase.get('/listing/' + listingId, { cache: listingCache });
        },
        getListingsById: function (listingIds, fields) {
          return apiBase.post('/listings', { ids: listingIds, fields: fields });
        },
        getMyListings: function (fields, includeDeleted) {
          var params = {
            fields: JSON.stringify(fields),
            include_deleted: includeDeleted
          };

          return apiBase.get('/me/listings', { params: params });
        },
        getMyActiveListings: function () {
          var params = {
            status: 'active'
          };

          return apiBase.get('/me/listings', { params: params });
        },
        getMyInactiveListings: function (excludeFloorplans) {
          var params = {
            status: 'inactive',
            excludeFloorplans: excludeFloorplans || false
          };

          return apiBase.get('/me/listings', { params: params });
        },
        getListingManager: function (listingId, appointmentKnockId) {
          var params = {
            appointmentKnockId: appointmentKnockId
          };

          return apiBase.get('/listing/' + listingId + '/manager', {
            params: params
          });
        },
        getListingManagerMessagingPreferences: function (listingId) {
          return apiBase.get(
            '/listing/' + listingId + '/manager/messaging/preferences'
          );
        },
        getListingManagerPreferences: function (listingId) {
          return apiBase.get('/listing/' + listingId + '/manager/preferences');
        },
        getListingApplication: function (listingId) {
          return apiBase.get('/listing/' + listingId + '/application');
        },
        setListingManager: function (
          listingId,
          newManagerId,
          transferAppointments
        ) {
          var params = {
            transferAppointments: transferAppointments
          };

          return apiBase.put(
            '/listing/' + listingId + '/manager/' + newManagerId,
            params
          );
        },
        getAvailableTimes: function (listingId, start, end) {
          var url = '/listing/' + listingId + '/availableTimes';
          var config = {
            params: {
              start: start,
              end: end
            }
          };

          return apiBase.get(url, config);
        },
        getListingPrimeTime: function (listingId) {
          return apiBase.get('/listing/' + listingId + '/prime-time');
        },
        getListingPrimeTimeCount: function (listingId) {
          return apiBase.get('/listing/' + listingId + '/prime-time/count');
        },
        postListingPrimeTime: function (listingId, payload) {
          return apiBase.post('/listing/' + listingId + '/prime-time', payload);
        },
        createListing: function (listing, application) {
          return apiBase.post('/listing/', {
            listing: listing,
            application: application
          });
        },
        deletePhoto: function (s3key) {
          return apiBase.post('/listing/photo/delete', { s3key: s3key });
        },
        updateListing: function (listingId, listing, application) {
          var payload = {
            listing: listing,
            application: application
          };

          return apiBase.put('/listing/' + listingId, payload);
        },
        activateListing: function (listingId) {
          return apiBase.put('/listing/' + listingId + '/activate');
        },
        deleteListing: function (listingId) {
          return apiBase.delete('/listing/' + listingId);
        },
        getListingProspects: function (listingId, start, end) {
          var params = {
            start: start,
            end: end
          };

          return apiBase.get('/listing/' + listingId + '/prospects', {
            params: params
          });
        },
        getListingAppointments: function (
          listingId,
          start,
          end,
          statuses,
          includeDeleted
        ) {
          var params = {
            statuses: statuses,
            includeDeleted: includeDeleted,
            start: start,
            end: end
          };

          return apiBase.get('/listing/' + listingId + '/appointments', {
            params: params
          });
        },
        getListingsAppointments: function (
          listingIds,
          statuses,
          includeDeleted,
          managerId,
          start,
          end
        ) {
          var payload = {
            listing_ids: listingIds
          };

          var params = {
            statuses: statuses,
            includeDeleted: includeDeleted,
            manager_id: managerId,
            start: start,
            end: end
          };

          return apiBase.post('/listings/appointments', payload, {
            params: params
          });
        },
        getListingRequirements: function (listingId) {
          return apiBase.get('/listing/' + listingId + '/requirements');
        },
        getListingLeasingTeam: function (listingId) {
          return apiBase.get('/listing/' + listingId + '/leasing-team');
        },
        createListingOccupant: function (listingId, occupant) {
          return apiBase.post('/listing/' + listingId + '/occupants', occupant);
        },
        updateListingOccupant: function (listingId, occupantId, occupant) {
          return apiBase.put(
            '/listing/' + listingId + '/occupant/' + occupantId,
            occupant
          );
        },
        deleteListingOccupant: function (listingId, occupantId) {
          return apiBase.delete(
            '/listing/' + listingId + '/occupant/' + occupantId
          );
        },
        getListingInfo: function (listingId) {
          return apiBase.get('/listing/' + listingId + '/info');
        },
        getListingPropertyUnit: function (listingId) {
          return apiBase.get('/listing/' + listingId + '/property-unit');
        },
        setListingInfo: function (listingId, listingInfo) {
          return apiBase.put('/listing/' + listingId + '/info', listingInfo);
        },
        setListingInfoStatus: function (listingId, status) {
          return apiBase.put('/listing/' + listingId + '/info/status', {
            status: status
          });
        },
        getListingInfoStatus: function (listingId) {
          return apiBase.get('/listing/' + listingId + '/info/status');
        }
      };
    }
  ]);
})();
