/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectExportIndicator', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospects/prospect-export-indicator.html',
      scope: {
        exportLog: '='
      },
      controller: [
        '$scope',
        '$filter',
        function ($scope, $filter) {
          var capitalizeFilter = $filter('capitalize');
          var prospectVendor = capitalizeFilter($scope.exportLog.vendor);
          var prospectVendorId = $scope.exportLog.vendor_prospect_id;

          $scope.data = {
            tooltip: prospectVendor + ' ID: ' + prospectVendorId
          };
        }
      ]
    };
  });
})(window.angular);
