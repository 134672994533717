/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isString from 'lodash/isString';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('brandedNavbar', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/navigation/branded-navbar.html',
      controller: 'BrandedNavbarController',
      scope: {
        leasingTeamPublicId: '='
      }
    };
  });

  app.controller('BrandedNavbarController', [
    '$rootScope',
    '$q',
    '$scope',
    '$routeParams',
    'managerApi',
    'listingApi',
    'communityApi',
    'authenticationService',
    'apiEvents',
    'userService',
    function (
      $rootScope,
      $q,
      $scope,
      $routeParams,
      managerApi,
      listingApi,
      communityApi,
      authenticationService,
      apiEvents,
      userService
    ) {
      var self = this;

      $rootScope.$on(apiEvents.profileUpdated, function () {
        self._initialize();
      });

      self._initialize = function () {
        $scope.isLoggedIn = authenticationService.isLoggedIn();
        $scope.isLoadingProfile = true;

        var isCompanyPage = isString($routeParams.leasingTeamPublicId);

        $scope.isManagerPage =
          isCompanyPage && isString($routeParams.managerMailerId);
        var isCommunityPage =
          !$scope.isManagerPage &&
          !isCompanyPage &&
          isString($routeParams.communityId);
        var isListingPage =
          !isCommunityPage && isString($routeParams.listingId);

        if (!$scope.isLoggedIn) {
          if ($scope.isManagerPage) {
            self._getManagerInfoByMailer();
            return;
          }

          if (isCommunityPage) {
            self._loadCommunity($routeParams.communityId);
            return;
          }

          if (isListingPage) {
            self._getListingInfo().then(function (response) {
              if (response.data.listing.community_id) {
                self._loadCommunity(response.data.listing.community_id);
              } else {
                $scope.isManagerPage = true;
                self._getManagerInfoByListing();
              }
            });
          }
        }
      };

      $scope.openRenterAuthModal = function () {
        authenticationService.openRenterAuthModal();
      };

      self._getManagerInfoByMailer = function () {
        return managerApi
          .getManagerInfoByMailer($routeParams.managerMailerId)
          .success(function (response) {
            $scope.managerProfile = response.manager_info;
          });
      };

      self._getManagerInfoByListing = function () {
        return listingApi
          .getListingManager($routeParams.listingId)
          .then(function (response) {
            $scope.managerProfile = response.data.manager_info;
          })
          .finally(function () {
            $scope.isLoadingProfile = false;
          });
      };

      self._getListingInfo = function () {
        return listingApi.getListing($routeParams.listingId);
      };

      self._loadCommunity = function (communityId) {
        var initPromises = [communityApi.getCommunity(communityId)];

        $q.all(initPromises)
          .then(function (response) {
            var community = response[0].data.community;

            $scope.community = community;

            if (community.logos && community.logos.length > 0) {
              $scope.logo = community.logos[0].url;
              return;
            }

            $scope.logo = null;
          })
          .finally(function () {
            $scope.isLoadingProfile = false;
          });
      };

      self._initialize();
    }
  ]);
})(window.angular);
