/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.factory('conversationPresenceService', [
    '$rootScope',
    function ($rootScope) {
      var self = this;

      self._pusherClient = null;
      self._presenceChannel = null;
      self._subscribedRenterId = null;

      self.events = {
        subscriptionSucceeded: 'pusher:subscription_succeeded',
        memberAdded: 'pusher:member_added',
        memberRemoved: 'pusher:member_removed'
      };

      self.setPusherInstance = function (pusher) {
        self._pusherClient = pusher;
      };

      self.subscribeToRenter = function (renterId) {
        if (!self._pusherClient) {
          throw 'no pusher client has been set';
        }

        if (self._subscribedRenterId) {
          self.unsubscribeFromRenter();
        }

        var channelId = self._getPresenceChannelId(renterId);
        var channel = self._pusherClient.subscribe(channelId);

        self._subscribedRenterId = renterId;

        channel.bind(self.events.subscriptionSucceeded, function (members) {
          $rootScope.$emit(self.events.subscriptionSucceeded, {
            renterId: renterId,
            members: members
          });
        });

        channel.bind(self.events.memberAdded, function (member) {
          $rootScope.$emit(self.events.memberAdded, {
            renterId: renterId,
            member: member
          });
        });

        channel.bind(self.events.memberRemoved, function (member) {
          $rootScope.$emit(self.events.memberRemoved, {
            renterId: renterId,
            member: member
          });
        });

        self._presenceChannel = channel;
      };

      self.unsubscribeFromRenter = function () {
        if (!self._pusherClient || !self._subscribedRenterId) {
          return;
        }

        var channelId = self._getPresenceChannelId(self._subscribedRenterId);
        self._pusherClient.unsubscribe(channelId);
      };

      self._getPresenceChannelId = function (renterId) {
        return 'presence-user-renter-' + renterId;
      };

      return self;
    }
  ]);
})(window.angular);
