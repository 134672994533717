/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
'use strict';

angular.module('knockApp').filter('noFractionCurrency', [
  '$filter',
  '$locale',
  function (filter, locale) {
    var currencyFilter = filter('currency');
    var formats = locale.NUMBER_FORMATS;
    return function (amount, currencySymbol) {
      if (!amount) {
        amount = 0;
      }
      var value = currencyFilter(amount, currencySymbol);
      var sep = value.indexOf(formats.DECIMAL_SEP);
      if (amount >= 0) {
        return value.substring(0, sep);
      }
      return value.substring(0, sep) + ')';
    };
  }
]);
