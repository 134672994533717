import { api } from './api';
import { store } from '../../store';

import { getQueryStringParameter } from '../../utils/prospectUtils';

export const useProspects = () => {
  const {
    useGetAllLossReasonsQuery,
    useAddActivityForProspectsMutation,
    useChangeOwnerMutation,
    useSetStatusAsLostForProspectsMutation,
    useSetLostReasonForProspectsMutation,
    useAddProspectMutation,
    useLazyDownloadProspectsQuery,
    useSetNotAProspectMutation,
    useDeleteProspectsMutation,
    useLazyNearbyPropertiesQuery,
    useAddVisitMutation,
    useUpdateAppointmentStatusMutation,
    useSetVisitShownUnitsMutation,
    useUpdateProspectMutation
  } = api;

  const [changeOwner] = useChangeOwnerMutation();
  const { data: lossReasons, error: lossReasonsError } =
    useGetAllLossReasonsQuery(null);
  const [addActivityForProspects] = useAddActivityForProspectsMutation();
  const [setStatusAsLostForProspects] =
    useSetStatusAsLostForProspectsMutation();
  const [setLostReasonForProspects] = useSetLostReasonForProspectsMutation();
  const [addProspect] = useAddProspectMutation();
  const [setNotAProspect] = useSetNotAProspectMutation();
  const [deleteProspects] = useDeleteProspectsMutation();
  const [
    trigger,
    {
      isLoading: downloading,
      isError: downloadError,
      data: downloadResult,
      isUninitialized: downloadUninitialized
    }
  ] = useLazyDownloadProspectsQuery();

  const [
    getNearbyProperties,
    { isLoading: loadingNearbyProperties, data: nearbyProperties }
  ] = useLazyNearbyPropertiesQuery();

  const [addVisit] = useAddVisitMutation();
  const [updateAppointmentStatus] = useUpdateAppointmentStatusMutation();
  const [setVisitShownUnits] = useSetVisitShownUnitsMutation();
  const [updateProspect] = useUpdateProspectMutation();

  return {
    lossReasons,
    lossReasonsError,
    addActivityForProspects,
    changeOwner,
    setStatusAsLostForProspects,
    setLostReasonForProspects,
    addProspect,
    downloadProspects: {
      download: trigger,
      downloading,
      downloadError,
      downloadResult,
      downloadUninitialized
    },
    setNotAProspect,
    deleteProspects,
    nearbyProperties: nearbyProperties || [],
    getNearbyProperties,
    loadingNearbyProperties,
    addVisit,
    updateAppointmentStatus,
    setVisitShownUnits,
    updateProspect
  };
};

export const usePropertyLayouts = () => {
  const { useGetPropertyLayoutsQuery } = api;
  const { data, error, isFetching } = useGetPropertyLayoutsQuery(null);

  return { layouts: data || [], error, loadingLayouts: isFetching };
};

export const useProspectsReport = () => {
  const { useGetProspectsQuery } = api;

  const newPayload = { ...store.getState().prospectFilter.payload };
  const prospectIdsParam = getQueryStringParameter(
    window.location.search,
    'f_prospectIds'
  );

  if (prospectIdsParam) {
    newPayload.prospect_ids = prospectIdsParam
      .split(',')
      .map((id) => Number(id))
      .filter((id) => !isNaN(id));
    newPayload.statuses = undefined;
  }

  const batchIdParam = getQueryStringParameter(
    window.location.search,
    'batchId'
  );

  if (batchIdParam) {
    newPayload.batch_id = batchIdParam;
  }

  if (prospectIdsParam || batchIdParam) {
    newPayload.created_after = undefined;
    newPayload.created_before = undefined;
    newPayload.property_ids = undefined;
    newPayload.manager_ids = undefined;
    newPayload.contact_types = undefined;
    newPayload.referred_from = undefined;
    newPayload.referred_to = undefined;
    newPayload.bedrooms = undefined;
    newPayload.layout_ids = undefined;
    newPayload.is_waitlist = null;
  }
  if (batchIdParam) {
    const status = getQueryStringParameter(window.location.search, 'status');
    newPayload.statuses = [status] || ['all'];
  }
  const { data, error, refetch, isFetching } = useGetProspectsQuery(newPayload);

  const prospects = data ? data.prospects : [];
  const pageData = data
    ? data.pageData
    : { count: 0, page: 1, rowsPerPage: 500 };

  return {
    loading: isFetching,
    prospects,
    error,
    refetch,
    pageData
  };
};
