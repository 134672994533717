/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import forEach from 'lodash/forEach';
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.controller('SettingsController', [
    '$scope',
    '$location',
    '$modal',
    '$routeParams',
    '$mdDialog',
    '$mdToast',
    '$q',
    'chatTrackingService',
    'ProfileService',
    'userService',
    'notificationsApi',
    'notificationsService',
    'renterApi',
    'confirmationModalFactory',
    'emailValidatorService',
    '_',
    '$rootScope',
    function (
      $scope,
      $location,
      $modal,
      $routeParams,
      $mdDialog,
      $mdToast,
      $q,
      chatTrackingService,
      ProfileService,
      userService,
      notificationsApi,
      notificationsService,
      renterApi,
      confirmationModalFactory,
      emailValidatorService,
      _,
      $rootScope
    ) {
      var self = this;

      $scope.data = {
        selectedTab: 0
      };

      $scope.$watch('data.selectedTab', function () {
        $location.search('tab', $scope.data.selectedTab);
      });

      self._initialize = function () {
        chatTrackingService.hide();
        $rootScope.$on('SalesForceWidgetMounted', () => {
          chatTrackingService.hide();
        });

        if ($routeParams.tab) {
          $scope.data.selectedTab = parseInt($routeParams.tab);
        }

        $scope.password = {
          old: null,
          new: null,
          confirm: null
        };

        var currentUser = userService.getUser();

        $scope.isSocialSignup = currentUser.provider !== 'knock';
        $scope.isManager = currentUser && currentUser.type === 'manager';
        $scope.isRenter = currentUser && currentUser.type === 'renter';
        $scope.teamMembers = [];

        ProfileService.getProfile().success(function (response) {
          $scope.profile = response.profile;

          if ($scope.isManager) {
            $scope.profile.cc_emails = $scope.profile.cc_emails || [];

            ProfileService.getUsername().success(function (res) {
              $scope.profile.username = res.user.username;
            });
          }
        });

        notificationsApi.getPreferences().success(function (response) {
          $scope.notificationPreferences = response.user_preferences;
        });
      };

      self._saveUsername = function () {
        $scope.usernameChangeErrorMessage = '';
        return ProfileService.updateUsername($scope.profile.username)
          .success(function (res) {
            $scope.profile.username = res.user.username;
          })
          .error(function (res, status) {
            if (status === 409) {
              $scope.usernameChangeErrorMessage = 'username is already taken';
              $scope.isError = true;
            } else {
              $scope.usernameChangeErrorMessage =
                "We're having a problem saving your username. Please try again later";
            }
          });
      };

      self._saveSettings = function () {
        return ProfileService.updateProfile($scope.profile).error(function () {
          $scope.emailChangeErrorMessage =
            "We're having a problem saving your profile. Please try again later";
        });
      };

      self._resetPassword = function () {
        return userService
          .resetPassword($scope.password)
          .success(function () {
            $scope.password = {};
          })
          .error(function (error, status) {
            if (status === 409) {
              $scope.passwordChangeErrorMessage =
                'The current password you provided does not match our records.';
            } else {
              $scope.passwordChangeErrorMessage =
                "We're having a problem changing your password. Please try again later";
            }
          });
      };

      $scope.saveChanges = function () {
        if (!self._validatePasswordMatch()) {
          $scope.passwordChangeErrorMessage =
            'New password not saved: New password and confirm password must match.';
          return;
        }

        var accountPromises = [self._saveSettings()];

        if ($scope.isManager) {
          accountPromises.push(self._saveUsername());
        }

        if (
          $scope.password.old ||
          $scope.password.new ||
          $scope.password.confirm
        ) {
          accountPromises.push(self._resetPassword());
        }

        $q.all(accountPromises).then(
          function () {
            $mdToast.show(
              $mdToast
                .simple()
                .content('Your profile is saved!')
                .position('right')
            );
          },
          function () {
            $mdToast.show(
              $mdToast
                .simple()
                .content('There was an error while saving your profile')
                .position('right')
            );
          }
        );
      };

      $scope.showConfirmModal = function () {
        forEach($scope.profile.cc_emails, function (email) {
          if (!emailValidatorService.validate(email)) {
            $scope.invalidEmails = true;
          }

          if (!emailValidatorService.validateMailerConflict(email)) {
            $scope.mailerConflict = true;
          }
        });

        if ($scope.invalidEmails || $scope.mailerConflict) {
          return;
        }

        var confirm = $mdDialog
          .confirm()
          .title('Are you sure you want to update your profile?')
          .ok('Confirm')
          .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
          $scope.saveChanges();
        });
      };

      $scope.openCancelAccountModal = function () {
        if ($scope.isRenter) {
          var message =
            '<h5>Note: Getting too many notifications? You can update those settings from the "Notifications" tab. </h5>' +
            '<p>You will not be able to access your account once you cancel. If you need to re-activate your account later, ' +
            'you will need to email us at <a href="mailto:support@knockrentals.com?Subject=cancel%20account">support@knockrentals.com</a></p>';

          var modal = confirmationModalFactory.createModal(
            'Cancel Account',
            message,
            self._cancelRenterAccount,
            { alertClass: 'alert-info' }
          );

          $modal.open(modal);
        }
      };

      self._cancelRenterAccount = function () {
        renterApi.deleteAccount().success(function () {
          $location.path('/logout');
        });
      };

      self._validatePasswordMatch = function () {
        return $scope.password.new === $scope.password.confirm;
      };

      self._initialize();
    }
  ]);

  app.directive('devicePreferences', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/settings/device-preferences.html',
      controller: [
        '$scope',
        '$mdDialog',
        '$mdToast',
        'deviceApi',
        function ($scope, $mdDialog, $mdToast, deviceApi) {
          $scope.isReady = false;

          var deviceIcons = {
            ios: '/images/iphone-4-icon.png',
            web: '/images/laptop-icon.png'
          };

          deviceApi.getMyDevices().success(function (response) {
            $scope.devices = response.devices;
            forEach($scope.devices, function (device) {
              device.imageUrl = deviceIcons[device.type];
            });

            $scope.isReady = true;
          });

          $scope.displayNameForDeviceType = function (type) {
            if (type === 'ios') {
              return 'iOS';
            }

            if (type === 'web') {
              return 'Browser';
            }
          };

          $scope.saveDeviceOnEnter = function ($event, device) {
            if ($event.keyCode === 13) {
              deviceApi.updateDevice(device.id, device).success(function () {
                $mdToast.showSimple('Saved!');
                device.isEditing = false;
              });
              return;
            }

            if ($event.keyCode === 27) {
              device.isEditing = false;
            }
          };

          $scope.openRevokeDeviceDialog = function ($event, device) {
            function revokeDeviceAccess() {
              deviceApi.revokeDeviceAccess(device.id).success(function () {
                device.client.is_authorized = false;
                $mdToast.showSimple('Revoked');
              });
            }

            var confirm = $mdDialog
              .confirm()
              .title('Confirm revoking device access?')
              .content(
                "By revoking this device's authorization, you will need to log back into that device with your username and password."
              )
              .ariaLabel('Confirm revoking device access?')
              .targetEvent($event)
              .ok('Revoke')
              .cancel('Cancel');
            $mdDialog.show(confirm).then(revokeDeviceAccess);
          };
        }
      ]
    };
  });
})();
