import React from 'react';

export const PersonCircle = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.913 20.876C17.0835 19.0351 14.5954 17.9999 12 17.9999C9.40463 17.9999 6.91645 19.0351 5.087 20.876M12 15C14.8995 15 17.25 12.6495 17.25 9.75C17.25 6.85051 14.8995 4.5 12 4.5C9.10051 4.5 6.75 6.85051 6.75 9.75C6.75 12.6495 9.10051 15 12 15ZM12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
