import React, { FC } from 'react';

import { TourType } from '../../../services/appointmentService';
import { Text } from '@knockrentals/knock-shared-web';

import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import {
  ContentStyles,
  AppointmentScheduleDialogProps
} from './AppointmentScheduleDialog';

type TourTypeSelectorProps = {
  contentStyles: ContentStyles;
  isLoading: boolean;
  inPersonToursEnabled: boolean;
  selfGuidedToursEnabled: boolean;
  liveVideoToursEnabled: boolean;
} & Pick<AppointmentScheduleDialogProps, 'setTourType' | 'tourType'>;

const TourTypeSelector: FC<TourTypeSelectorProps> = ({
  contentStyles,
  isLoading,
  tourType,
  inPersonToursEnabled,
  selfGuidedToursEnabled,
  liveVideoToursEnabled,
  setTourType
}) => {
  return (
    <>
      <Text
        variant="overline"
        color="secondary"
        className={contentStyles.sectionHeader}
      >
        Tour Type
      </Text>
      <RadioGroup
        value={tourType}
        onChange={(e) => setTourType(e.target.value as TourType)}
      >
        <div className={contentStyles.tourTypeContainer}>
          <div>
            <Text variant="body2" color="textSecondary">
              Visit In-person
            </Text>
            <FormControlLabel
              value={TourType.Agent}
              control={<Radio color="primary" />}
              label="Agent-guided Tour"
              disabled={isLoading || !inPersonToursEnabled}
            />
            <FormControlLabel
              value={TourType.Self}
              control={<Radio color="primary" />}
              label="Self-guided Tour"
              disabled={isLoading || !selfGuidedToursEnabled}
            />
          </div>
          <div>
            <Text variant="body2" color="textSecondary">
              View Online
            </Text>
            <FormControlLabel
              value={TourType.Video}
              control={<Radio color="primary" />}
              label="Live Video Tour"
              disabled={isLoading || !liveVideoToursEnabled}
            />
          </div>
        </div>
      </RadioGroup>
    </>
  );
};

export default TourTypeSelector;
