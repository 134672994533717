import { api } from './api';

export const useManager = () => {
  const { useGetCallSettingsQuery } = api;

  const { data, isFetching: callSettingsLoading } = useGetCallSettingsQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true
    }
  );

  return {
    callSettings: data || {
      phoneNumbers: [],
      outboundPhoneNumber: null
    },
    callSettingsLoading
  };
};
