/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular, $) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('divExpandCollapse', function () {
    return {
      restrict: 'E',
      link: function (scope, element) {
        var $element = $(element);

        var $expandToggle = $element.find('.expand-toggle');

        $expandToggle.click(function () {
          $element.find('.target').slideToggle('200');

          if ($element.find('div.target:visible').length > 1) {
            $element.siblings().find('.target').slideUp('slow');
          }

          if ($element.find('.fa').css('transform') === 'none') {
            $element.find('.fa').css('transform', 'rotate(90deg)');
          } else {
            $element.find('.fa').css('transform', '');
          }
        });

        $expandToggle.click();
      }
    };
  });
})(window.angular, window.jQuery);
