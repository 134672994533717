import React from 'react';

export const NoSuggestNearbyCommunitiesIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M51.735 3.19398C39.6801 -3.58432 24.5688 0.779252 17.9828 12.9403C15.58 17.3771 14.644 22.2389 15.0097 26.9541C9.90564 28.5289 5.39029 32.038 2.62368 37.1466C-3.04216 47.6087 0.7719 60.8171 11.1426 66.6484L29.9969 77.2499C40.3676 83.0813 53.3679 79.3273 59.0337 68.8652C59.8971 67.271 60.5403 65.613 60.9738 63.927C67.6052 65.4265 74.6537 62.4363 78.0714 56.1254C82.236 48.4353 79.4325 38.7266 71.8096 34.4403L65.366 30.8172C67.3562 20.3591 62.5914 9.29841 52.8596 3.8263L51.735 3.19398Z"
      fill="#F5F6FF"
    />
    <g clip-path="url(#clip0_49_43797)">
      <path
        d="M45.064 35.1174V26.1174C45.0635 25.9562 45.0239 25.7974 44.9488 25.6547C44.8737 25.512 44.7651 25.3896 44.6325 25.2979C44.4998 25.2062 44.3469 25.148 44.1869 25.1282C44.0268 25.1084 43.8643 25.1276 43.7133 25.1841L32.7133 30.8774C32.5231 30.9487 32.3591 31.0761 32.2431 31.2428C32.1271 31.4096 32.0646 31.6077 32.064 31.8108V37.1174"
        stroke="#4A61E8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M42.064 31.1174H45.064"
        stroke="#4A61E8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.064 35.1174H45.064"
        stroke="#4A61E8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.064 41.1174H28.064"
        stroke="#4A61E8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.064 45.1174H30.064"
        stroke="#4A61E8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35.064 29.6587V25.1187"
        stroke="#4A61E8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35.064 55.1174V39.1174C35.064 38.587 34.8533 38.0783 34.4782 37.7032C34.1031 37.3281 33.5944 37.1174 33.064 37.1174H27.064C26.5335 37.1174 26.0248 37.3281 25.6498 37.7032C25.2747 38.0783 25.064 38.587 25.064 39.1174V55.1174H37.064"
        stroke="#4A61E8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.064 45.1174C38.064 46.9739 38.8015 48.7544 40.1142 50.0672C41.427 51.3799 43.2074 52.1174 45.064 52.1174C46.9205 52.1174 48.701 51.3799 50.0137 50.0672C51.3265 48.7544 52.064 46.9739 52.064 45.1174C52.064 43.2609 51.3265 41.4804 50.0137 40.1677C48.701 38.8549 46.9205 38.1174 45.064 38.1174C43.2074 38.1174 41.427 38.8549 40.1142 40.1677C38.8015 41.4804 38.064 43.2609 38.064 45.1174Z"
        stroke="#4A61E8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55.0641 55.1175L50.0454 50.0989"
        stroke="#4A61E8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_49_43797">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(24.064 24.1174)"
        />
      </clipPath>
    </defs>
  </svg>
);
