import React, { FC } from 'react';
import { QuoteSummaryView } from '../QuoteSummaryView';
import { Dialog } from '@material-ui/core';
import { Quote, Unit } from '../../../ts/models';
import '../_CreateQuote.scss';

export interface QuoteSummaryProps {
  attachQuoteErrorMessage: string;
  attachQuoteToMessage: (quote: Quote) => void;
  isCreateQuoteDialogOpen: boolean;
  isSendQuoteDisabled?: boolean;
  cancelUnifiedQuote: () => void;
  quote: Quote;
  unit: Unit;
}

const QuoteSummary: FC<QuoteSummaryProps> = ({
  attachQuoteErrorMessage,
  attachQuoteToMessage,
  isCreateQuoteDialogOpen,
  isSendQuoteDisabled,
  cancelUnifiedQuote,
  quote,
  unit
}) => {
  return (
    <div className="create-quote">
      <Dialog
        className="create-quote-dialog"
        fullWidth
        onClose={cancelUnifiedQuote}
        open={isCreateQuoteDialogOpen}
        style={{ zIndex: 15003 }}
      >
        <QuoteSummaryView
          attachQuoteErrorMessage={attachQuoteErrorMessage}
          attachQuoteToMessage={attachQuoteToMessage}
          handleOnClose={cancelUnifiedQuote}
          quote={quote}
          unit={unit}
          isSendQuoteDisabled={isSendQuoteDisabled || false}
        />
      </Dialog>
    </div>
  );
};

export default QuoteSummary;
