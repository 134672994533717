// @ts-nocheck
import dayjs from 'dayjs';
import { getDateString } from '../../../utilities/timeUtilities';

export const getLeaseTermLength = (startDate, endDate) => {
  return dayjs(endDate).add(1, 'day').diff(dayjs(startDate), 'month');
};

export const getTermLengthOptions = () => {
  const maxMonths = 24;
  const termLengthOptions = Array.from(
    Array(maxMonths),
    (_, monthIndex) => monthIndex + 1
  );
  return termLengthOptions;
};

export const getMaxEndDate = (startDate, termLength) => {
  if (startDate) {
    return dayjs(startDate)
      .add(termLength + 1, 'month')
      .format('YYYY-MM-DD');
  }
};

export const formattedDateString = (date) => getDateString(date, 'YYYY-MM-DD');

export const validateBlackoutDates = (date, blackoutDatesMap) => {
  const landsOnBlackoutDate = Boolean(
    blackoutDatesMap[getDateString(date, 'YYYY-MM-DD')]
  );

  if (!landsOnBlackoutDate) {
    return date;
  }

  const nextDay = dayjs(date).add(1, 'day').format('YYYY-MM-DD');
  return validateBlackoutDates(nextDay, blackoutDatesMap);
};

export const getInitialEndDate = (startDate, termLength, blackoutDatesMap) => {
  if (startDate) {
    const endDate = dayjs(startDate)
      .add(termLength, 'month')
      .subtract(1, 'day')
      .format('YYYY-MM-DD');

    return validateBlackoutDates(endDate, blackoutDatesMap);
  }
};

export const getMinEndDate = (startDate, termLength) => {
  if (startDate) {
    if (termLength === 1) {
      return dayjs(startDate)
        .add(termLength, 'month')
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    }

    return dayjs(startDate)
      .add(termLength, 'month')
      .subtract(1, 'month')
      .format('YYYY-MM-DD');
  }
};

export const getMinStartDate = () => dayjs().add(1, 'day').format('YYYY-MM-DD');

export const getProRatedDays = (startDate, endDate, termLength) => {
  if (!startDate || !endDate || !termLength) {
    return 0;
  }

  const projectedEndDate = dayjs(startDate)
    .add(termLength, 'month')
    .subtract(1, 'day');

  return dayjs(endDate).diff(projectedEndDate, 'day');
};
