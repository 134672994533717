/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.filter('userList', [
    function () {
      return function (users, maxVisible) {
        maxVisible = maxVisible || 2;

        var visibleUserNames = map(users.slice(0, maxVisible), function (user) {
          return user.name;
        });

        var visibleUserText = visibleUserNames.join(', ');

        if (maxVisible < users.length) {
          return (
            visibleUserText +
            ' and ' +
            (users.length - maxVisible).toString() +
            ' more'
          );
        }

        return visibleUserText;
      };
    }
  ]);
})(window.angular);
