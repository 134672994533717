import React, { useMemo, useState, useEffect, FC, useCallback } from 'react';
import {
  MenuItem,
  Checkbox,
  Button,
  Badge,
  Menu,
  makeStyles
} from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

import { Box, NamedColors, Text } from '@knockrentals/knock-shared-web';
import { colors, useCommonStyles } from '../../commonStyles/commonStyles';
import { MenuOption } from './types';
import ChipSelectDateRange from '../../molecules/ChipSelectDateRange/ChipSelectDateRange';
import dayjs from 'dayjs';
import { DateRangeFilterOption } from '../../molecules/SelectDateRange/SelectDateRange';
import ChipPriceRange from '../../molecules/ChipPriceRange/ChipPriceRange';

const useStyles = makeStyles({
  listPadding: {
    padding: '8px 19px 8px 10px !important'
  },

  menuStyle: {
    border: '1px solid #E2E3E9',
    marginTop: '4px',
    minWidth: '190px'
  },

  subTitle: {
    fontSize: '12px',
    fontWeight: 600,
    padding: '10px 0px 10px 20px',
    color: colors.disabled
  },

  selectChip: {
    height: '32px',
    padding: '4px',
    backgroundColor: colors.chipBackground,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '16px',
    border: `1px solid ${colors.chipBackground}`,
    minWidth: '72px',

    '&:hover': {
      backgroundColor: NamedColors.denim[100]
    },

    '&:disabled': {
      color: colors.disabled,
      backgroundColor: 'inherit',
      border: 'none',

      '& > .MuiButton-label > svg': {
        color: colors.disabled
      }
    }
  },

  selectChipOutlined: {
    backgroundColor: '#EBEEFE',
    borderColor: '#697FF7'
  },

  badgeIcon: {
    marginLeft: '6px',
    '& > span': {
      backgroundColor: '#4A61E8',
      fontSize: '1rem',
      fontWeight: 600,
      position: 'relative',
      transform: 'initial'
    }
  },

  filterName: {
    fontSize: '13px',
    marginLeft: '6px'
  },

  checkbox: {
    '&.Mui-checked': {
      color: '#697FF7'
    }
  },

  filterText: {
    fontFamily: 'Open Sans',
    fontSize: '1.3333rem',
    fontWeight: 400,
    letterSpacing: '0.04166rem',
    marginRight: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  resetButtonActive: {
    color: '#4A61E8'
  },

  bottomBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '68px',
    boxShadow: '0 0 5px -2px #888',
    padding: '0 16px'
  },

  optionsContainer: {
    maxHeight: 390,
    maxWidth: 280,
    overflow: 'auto'
  },

  keyboardArrow: {
    color: colors.secondaryText,
    margin: '6px 3px 6px 3px'
  }
});

interface AvailabilityFiltersProps {
  filterValues: any;
  filterState: MenuOption[];
  optionLabelSelector: (nodes: MenuOption[], useShortLabel?: boolean) => string;
  manualSelection?: boolean;
  hideBadge?: boolean;
  useShortLabel?: boolean;
  className?: string;
  selectedProperty: any;
  selectedFilters?: MenuOption[];
  properties: MenuOption[];
}

interface FilterDateError {
  startDateError?: string;
  endDateError?: string;
}

const AvailabilityFilters: FC<AvailabilityFiltersProps> = ({
  filterValues,
  filterState,
  optionLabelSelector,
  useShortLabel = false,
  className,
  properties,
  selectedProperty
}) => {
  const {
    menu: defaultFilter,
    onApply,
    isDisabled,
    hideSelectAll,
    resetButtonLabel,
    labelForAll,
    label: chipLabel,
    subSection
  } = filterValues;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [appliedFilters, setAppliedFilters] = useState<MenuOption[]>([
    ...filterState
  ]);
  const [appliedPropertyFilters, setAppliedPropertyFilters] = useState<
    MenuOption[]
  >([...properties]);
  const [filters, setFilters] = useState<MenuOption[]>([]);
  const [propertyFilter, setPropertyFilter] = useState<MenuOption[]>([
    { ...selectedProperty }
  ]);
  const [filterDateError, setFilterDateError] = useState<FilterDateError>({});
  const dateFilters = filterState.filter((filter: MenuOption) => {
    return filter.type === 'Avail Date';
  });
  const priceFilters = filterState.filter((filter: MenuOption) => {
    return filter.type === 'Price';
  });
  useEffect(() => {
    setFilters([...filterState]);
  }, [filterState]);

  const isFilterSelected = (filter: MenuOption) => {
    if (chipLabel === 'Property') {
      return !!propertyFilter.find((item) => item?.value === filter?.value);
    } else {
      return !!filters.find((item) => item?.value === filter?.value);
    }
  };

  const handlePropertyOptionClick = (newFilter: MenuOption) => {
    if (newFilter.label.includes('All')) {
      if (propertyFilter.length === defaultFilter.length) {
        setPropertyFilter([]);
      } else {
        setPropertyFilter([...defaultFilter]);
      }
    } else {
      setPropertyFilter((prevFilters) => {
        if (prevFilters.some((item) => item.label.includes('All'))) {
          return prevFilters.filter((item) => !item.label.includes('All'));
        }
        const filterIndex = prevFilters.findIndex(
          (item) => item.value === newFilter.value
        );
        if (filterIndex !== -1) {
          return prevFilters.filter((item) => item.value !== newFilter.value);
        } else {
          return [...prevFilters, newFilter];
        }
      });
      setAppliedPropertyFilters(
        appliedPropertyFilters.filter((item) => {
          return item.value !== newFilter.value;
        })
      );
    }
  };

  const handleFilterOptionClick = (newFilter: MenuOption) => {
    if (chipLabel === 'Property') {
      handlePropertyOptionClick(newFilter);
    } else {
      if (newFilter.label.includes('All')) {
        if (filters.length === defaultFilter.length) {
          setFilters([]);
        } else {
          setFilters([...defaultFilter]);
        }
      } else {
        setFilters((prevFilters) => {
          if (prevFilters.some((item) => item.label.includes('All'))) {
            return prevFilters.filter((item) => !item.label.includes('All'));
          }
          const filterIndex = prevFilters.findIndex(
            (item) => item.value === newFilter.value
          );
          if (filterIndex !== -1) {
            return prevFilters.filter((item) => item.value !== newFilter.value);
          } else {
            return [...prevFilters, newFilter];
          }
        });
        setAppliedFilters(
          appliedFilters.filter((item) => {
            return item.value !== newFilter.value;
          })
        );
      }
    }
  };

  const createdFilters: DateRangeFilterOption[] = [
    { label: 'Today', value: 'today' },
    { label: 'This Month', value: '1month' },
    { label: 'Next Two month', value: '+2month', default: true }
  ];

  const appliedFilterCount = useMemo(() => {
    if (chipLabel === 'Property') {
      const chipValues = propertyFilter.filter((appliedFilter) => {
        return appliedFilter?.type === chipLabel;
      });
      if (chipValues.length > 1) {
        return {
          appliedFilterCount: `+${chipValues.length - 1}`,
          filterCount: chipValues?.length
        };
      } else {
        return { filterCount: chipValues?.length };
      }
    } else {
      const chipValues = filters.filter((appliedFilter) => {
        return appliedFilter.type === chipLabel;
      });
      if (chipValues.length > 1) {
        return {
          appliedFilterCount: `+${chipValues.length - 1}`,
          filterCount: chipValues?.length
        };
      } else {
        return { filterCount: chipValues?.length };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chipLabel, filters, propertyFilter]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: Object, reason: string) => {
    setAnchorEl(null);

    // If we aren't saving filter selections, revert to the original state
    if (reason !== 'apply') {
      if (chipLabel === 'Property') {
        setPropertyFilter([...properties]);
      } else {
        setFilters([...filterState]);
      }
    }
  };

  const handleReset = () => {
    if (chipLabel === 'Property') {
      setPropertyFilter([{ ...selectedProperty }]);
      setAppliedPropertyFilters([{ ...selectedProperty }]);
    } else {
      const removeFilters = filters.filter(
        (filter) => chipLabel !== filter.type
      );
      setFilters([...removeFilters]);
      setAppliedFilters([...removeFilters]);
    }
  };

  const handleClearFilters = () => {
    if (chipLabel === 'Property') {
      setPropertyFilter([]);
      setAppliedPropertyFilters([]);
    } else {
      const removeFilters = filters.filter(
        (filter) => chipLabel !== filter.type
      );
      setFilters([...removeFilters]);
      setAppliedFilters([...removeFilters]);
    }
  };

  const handleApplyFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleClose({}, 'apply');
    if (chipLabel === 'Property') {
      onApply(propertyFilter);
    } else {
      onApply(filters);
    }
  };

  const getFinalChipLabel = () => {
    let finalChipLabel = chipLabel;
    if (chipLabel === 'Property') {
      if (propertyFilter.length > 0) {
        finalChipLabel = `${chipLabel}: ${optionLabelSelector(
          propertyFilter,
          useShortLabel
        )}`;
      } else {
        finalChipLabel = chipLabel;
      }
      return finalChipLabel;
    } else {
      if (filters.length > 0) {
        if (chipLabel) {
          const chipValues = filters.filter((appliedFilter) => {
            return appliedFilter.type === chipLabel;
          });
          if (chipValues.length > 0) {
            if (
              chipValues.find(
                (chip) => chip.type === 'Occupancy' && chip.value === 'occupied'
              )
            ) {
              finalChipLabel = 'Occupied';
            } else if (
              chipValues.find(
                (chip) => chip.type === 'Occupancy' && chip.value === 'vacant'
              )
            ) {
              finalChipLabel = 'Vacant';
            } else if (
              chipValues.find(
                (chip) => chip.type === 'Notice' && chip.value === 'on_notice'
              )
            ) {
              finalChipLabel = 'On Notice';
            } else if (
              chipValues.find(
                (chip) => chip.type === 'Notice' && chip.value === 'no_notice'
              )
            ) {
              finalChipLabel = 'Not On Notice';
            } else {
              if (chipLabel === 'Beds' || chipLabel === 'Baths') {
                finalChipLabel = `${chipLabel}:`;
              } else {
                finalChipLabel = `${chipLabel}: ${optionLabelSelector(
                  chipValues,
                  useShortLabel
                )}`;
              }
            }
          } else {
            finalChipLabel = chipLabel;
          }
        } else {
          if (chipLabel === 'Beds' || chipLabel === 'Baths') {
            finalChipLabel = `${chipLabel}:`;
          } else {
            finalChipLabel = `${optionLabelSelector([filters[0]])}`;
          }
        }
      }

      return finalChipLabel;
    }
  };

  const handleApplyPrice = useCallback(
    (minPrice, maxPrice) => {
      const val = [
        { id: 'maxPrice', type: 'Price', value: maxPrice, label: 'Max Price' },
        { id: 'minPrice', type: 'Price', value: minPrice, label: 'Min Price' }
      ];
      onApply(val);
    },
    [onApply]
  );

  const handleApplyAvailDate = useCallback(
    (startDate, endDate, range) => {
      const val = [
        {
          id: 'startDate',
          type: 'Avail Date',
          value: startDate,
          label: 'Start Date'
        },
        {
          id: 'endDate',
          type: 'Avail Date',
          value: endDate,
          label: 'End Date'
        },
        ...(range
          ? [
              {
                id: 'range',
                type: 'Avail Date',
                value: range,
                label: 'Range'
              }
            ]
          : [])
      ];
      onApply(val);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onApply]
  );

  const dateValidator = (startDate: Date | null, endDate: Date | null) => {
    let isValid = true;

    if (startDate === null && endDate === null) {
      return false;
    }

    let errorState: FilterDateError = {};

    if (startDate !== null && !dayjs(startDate).isValid()) {
      isValid = false;
      errorState.startDateError = 'Please provide a valid date';
    }

    if (endDate !== null && !dayjs(endDate).isValid()) {
      isValid = false;
      errorState.endDateError = 'Please provide a valid date';
    }
    setFilterDateError(errorState);
    return isValid;
  };

  const handleCancelDateFilter = () => {
    setFilterDateError({});
  };

  const finalChipLabel = getFinalChipLabel();
  const calculateRange = () => {
    const startDate = dateFilters.find(
      (startDate) => startDate.label === 'Start Date'
    )?.value;
    const endDate = dateFilters.find(
      (endDate) => endDate.label === 'End Date'
    )?.value;
    const range = dateFilters.find((range) => range.label === 'Range')?.value;
    if (!range && !startDate && !endDate) {
      return '+2month';
    } else if (range) {
      return range;
    } else {
      return null;
    }
  };

  const startDate = () => {
    const date =
      dateFilters.find((startDate) => startDate.label === 'Start Date')
        ?.value ?? null;
    if (date) {
      return dayjs(date).toDate();
    } else {
      return null;
    }
  };

  const endDate = () => {
    const date =
      dateFilters.find((endDate) => endDate.label === 'End Date')?.value ??
      null;
    if (date) {
      return dayjs(date).toDate();
    } else {
      return null;
    }
  };

  if (chipLabel === 'Avail Date') {
    return (
      <Box style={{ margin: '3px' }}>
        <ChipSelectDateRange
          filterOptions={createdFilters}
          startDate={startDate()}
          endDate={endDate()}
          range={calculateRange()}
          chipLabel="Avail Date"
          onApply={handleApplyAvailDate}
          useFutureDates={true}
          disableApply={false}
          allowEmptyDates
          dateValidator={dateValidator}
          onChange={handleCancelDateFilter}
          onCancel={handleCancelDateFilter}
          {...filterDateError}
        />
      </Box>
    );
  }

  if (chipLabel === 'Price') {
    return (
      <ChipPriceRange
        filterOptions={[]}
        maxPrice={
          priceFilters.find((maxPrice) => maxPrice.label === 'Max Price')
            ?.value ?? null
        }
        minPrice={
          priceFilters.find((minPrice) => minPrice.label === 'Min Price')
            ?.value ?? null
        }
        chipLabel="Price"
        allowEmpty={true}
        onApply={(maxPrice, minPrice) => {
          handleApplyPrice(minPrice, maxPrice);
        }}
      />
    );
  }

  const getBadge = () => {
    const bedsChip: string[] = filters
      .filter((filter) => filter.type === 'Beds')
      .map((bed: MenuOption) => bed.value.toString());

    const selectedBedsString =
      bedsChip?.length > 0 ? bedsChip.join(', ') : null;
    const bathChip: string[] = filters
      .filter((filter) => filter.type === 'Baths')
      .map((bath: MenuOption) => bath.value.toString());

    const selectedBathsString =
      bathChip?.length > 0 ? bathChip.join(', ') : null;
    if (chipLabel === 'Baths' && selectedBathsString) {
      return (
        <Text variant="body2" className={classes.filterName}>
          {selectedBathsString}
        </Text>
      );
    }
    if (chipLabel === 'Beds' && selectedBedsString) {
      return (
        <Text variant="body2" className={classes.filterName}>
          {selectedBedsString}
        </Text>
      );
    } else if (
      filters.length > 1 ||
      (propertyFilter?.length > 1 && chipLabel === 'Property')
    ) {
      return (
        <Badge
          className={
            appliedFilterCount?.appliedFilterCount ? classes.badgeIcon : ''
          }
          badgeContent={appliedFilterCount?.appliedFilterCount}
          color="primary"
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <Box className={className ? className : undefined}>
      <Button
        disabled={isDisabled}
        variant={anchorEl ? 'outlined' : 'text'}
        onClick={handleClick}
        classes={{
          root: classes.selectChip,
          outlined: classes.selectChipOutlined
        }}
      >
        <Text variant="body2" className={classes.filterName}>
          {finalChipLabel}
        </Text>
        {getBadge()}
        {anchorEl ? (
          <KeyboardArrowUp className={classes.keyboardArrow} />
        ) : (
          <KeyboardArrowDown className={classes.keyboardArrow} />
        )}
      </Button>

      <Menu
        classes={{ paper: classes.menuStyle }}
        elevation={0}
        MenuListProps={{ role: 'listbox', disablePadding: true }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className={classes.optionsContainer}>
          {!hideSelectAll && (
            <MenuItem
              key="All"
              classes={{ root: classes.listPadding }}
              onClick={handleClearFilters}
            >
              <Checkbox
                className={classes.checkbox}
                size="medium"
                checked={appliedFilterCount?.filterCount === 0}
              />

              <Text variant="body1" className={classes.filterText}>
                {labelForAll}
              </Text>
            </MenuItem>
          )}

          {subSection && (chipLabel === 'Floorplan' || chipLabel === 'Building')
            ? defaultFilter?.map((menu: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    {defaultFilter?.length > 1 ? (
                      <Box className={classes.subTitle}>
                        {menu?.propertyName}
                      </Box>
                    ) : (
                      <></>
                    )}
                    {menu?.data?.map((filter: MenuOption, index: any) => {
                      return (
                        <MenuItem
                          key={`${filter?.value} + ${index}`}
                          classes={{ root: classes.listPadding }}
                          onClick={() => handleFilterOptionClick(filter)}
                        >
                          <Checkbox
                            className={classes.checkbox}
                            data-testid="chip-select-menu-checkbox"
                            size="medium"
                            checked={isFilterSelected(filter)}
                          />

                          <Text variant="body1" className={classes.filterText}>
                            {optionLabelSelector([filter])}
                          </Text>
                        </MenuItem>
                      );
                    })}
                  </React.Fragment>
                );
              })
            : defaultFilter?.map((filter: MenuOption, index: any) => {
                return (
                  <MenuItem
                    key={`${filter?.value} + ${index}`}
                    classes={{ root: classes.listPadding }}
                    onClick={() => handleFilterOptionClick(filter)}
                  >
                    <Checkbox
                      className={classes.checkbox}
                      data-testid="chip-select-menu-checkbox"
                      size="medium"
                      checked={isFilterSelected(filter)}
                    />

                    <Text variant="body1" className={classes.filterText}>
                      {optionLabelSelector([filter])}
                    </Text>
                  </MenuItem>
                );
              })}
        </div>

        <div className={classes.bottomBox}>
          <Button variant="text" color="primary" onClick={handleReset}>
            {resetButtonLabel ? resetButtonLabel : 'Reset'}
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={commonClasses.primaryButton}
            onClick={handleApplyFilters}
          >
            Apply
          </Button>
        </div>
      </Menu>
    </Box>
  );
};

export default AvailabilityFilters;
