/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('notificationBanner', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/notifications/notification-banner.html',
      controller: 'NotificationBannerController',
      scope: {
        notification: '='
      }
    };
  });

  const NotificationBannerController = function ($scope) {};

  NotificationBannerController.$inject = ['$scope'];

  app.controller('NotificationBannerController', NotificationBannerController);
})(window.angular);
