import React, { FC } from 'react';
import { Button, List, ListItem, Typography } from '@material-ui/core';
import { CalendarCashIcon } from '../../icons';

export interface LeaseDetailsDisplayProps {
  leaseEndDate?: string;
  leaseStartDate?: string;
  proRatedDays: number;
  selectedLeaseTerm: number;
  openRentMatrix: () => void;
}

const LeaseDetailsDisplay: FC<LeaseDetailsDisplayProps> = ({
  leaseEndDate,
  leaseStartDate,
  proRatedDays,
  selectedLeaseTerm,
  openRentMatrix
}) => {
  return (
    <div className="lease-details-display">
      <List dense>
        <ListItem disableGutters>
          <Typography variant="body2">
            <span className="lease-details-display-title">Lease Start</span>
            <span className="lease-details-display-value">
              {leaseStartDate}
            </span>
          </Typography>
        </ListItem>

        <ListItem disableGutters>
          <Typography variant="body2">
            <span className="lease-details-display-title">Lease Term</span>
            <span className="lease-details-display-value">
              {selectedLeaseTerm}
            </span>
          </Typography>
        </ListItem>

        <ListItem disableGutters>
          <Typography variant="body2">
            <span className="lease-details-display-title">Lease End</span>
            <span className="lease-details-display-value">{leaseEndDate}</span>
          </Typography>
        </ListItem>
        <ListItem disableGutters>
          <Typography variant="body2">
            <span className="lease-details-display-title">Pro-rated Days</span>
            <span className="lease-details-display-value">
              {proRatedDays > 0 ? `+${proRatedDays}` : proRatedDays}
            </span>
          </Typography>
        </ListItem>
      </List>

      <Button
        className="select-from-rent-matrix-button"
        color="primary"
        onClick={openRentMatrix}
        startIcon={<CalendarCashIcon />}
        variant="text"
      >
        Select from Rent Matrix
      </Button>
    </div>
  );
};

export default LeaseDetailsDisplay;
