/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import findLast from 'lodash/findLast';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('historyService', [
    '$rootScope',
    '$location',
    function ($rootScope, $location) {
      var self = this;

      self._history = [$location.$$path];

      $rootScope.$on('$routeChangeSuccess', function () {
        self._history.push($location.$$path);
      });

      self.back = function () {
        if (self._history.length > 1) {
          var previousUrl = self._history[self._history.length - 2];
          $location.path(previousUrl);

          return;
        }

        $location.path('/');
      };

      self.backToLastWithout = function (match) {
        var routesToCheck =
          self._history.length > 0
            ? self._history.slice(0, self._history.length - 1)
            : [];

        var lastPathWithoutMatch = findLast(routesToCheck, function (path) {
          return path.indexOf(match) === -1;
        });

        if (lastPathWithoutMatch) {
          $location.path(lastPathWithoutMatch);

          return;
        }

        $location.path('/');
      };

      return self;
    }
  ]);
})(window.angular);
