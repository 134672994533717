/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('prospectPrintableDetails', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospects/prospect-printable-details.html',
      controller: 'ProspectPrintableDetails',
      replace: true,
      scope: {
        phone: '=',
        prospect: '=',
        stream: '='
      }
    };
  });
  app.controller('ProspectPrintableDetails', [
    '$rootScope',
    '$scope',
    'unsubscribeHelper',
    function ($rootScope, $scope, unsubscribeHelper) {
      let self = this;

      const _consentStatus = {
        granted: 'granted',
        revoked: 'revoked',
        declined: 'declined'
      };

      const _consentStatusMappings = {
        granted: 'Yes',
        pending: 'No',
        stopped: 'Stopped',
        declined: 'Declined'
      };

      const _personType = {
        prospect: 'prospect',
        resident: 'resident'
      };

      const _idVerifiedStatus = {
        verified: 'scanned_verified',
        notVerified: 'scanned_not_verified'
      };

      const _idVerifiedStatusMappings = {
        valid: 'Valid ID',
        invalid: 'Invalid ID',
        notScanned: 'Not Scanned'
      };

      const _emailstatus = {
        optedIn: 'Subscribed',
        optedOut: 'Unsubscribed'
      };

      $scope.data = {
        isMobileToursEnabled:
          $rootScope.appPreferences.company.enable_mobile_tours,
        emailStatus: {
          status: ''
        },
        idVerifiedStatus: '',
        personType: '',
        smsStatus: ''
      };

      self._getEmailUnsubscribeStatus = () => {
        $scope.$watch(
          'stream.participants',
          function (newParticipants, oldParticipants) {
            const unsubscribeStatus =
              unsubscribeHelper.getUnsubscribeStatusFromStream($scope.stream);
            if (unsubscribeStatus) {
              const optedOut = unsubscribeStatus.opted_out;
              if (optedOut) {
                $scope.data.emailStatus.status = _emailstatus.optedOut;
              } else {
                $scope.data.emailStatus.status = _emailstatus.optedIn;
              }
            }
          },
          true
        );
      };

      self._getIdVerifiedStatus = () => {
        switch ($scope.prospect.profile.id_verified) {
          case _idVerifiedStatus.verified:
            return _idVerifiedStatusMappings.valid;
          case _idVerifiedStatus.notVerified:
            return _idVerifiedStatusMappings.invalid;
          default:
            return _idVerifiedStatusMappings.notScanned;
        }
      };

      self._getSMS = () => {
        switch ($scope.prospect.sms_consent) {
          case _consentStatus.granted:
            return _consentStatusMappings.granted;
          case _consentStatus.revoked:
            return _consentStatusMappings.revoked;
          case _consentStatus.declined:
            return _consentStatusMappings.declined;
          default:
            return _consentStatusMappings.pending;
        }
      };

      self._initialize = () => {
        $scope.data.personType = $scope.prospect.assigned_manager_id
          ? _personType.prospect
          : _personType.resident;
        $scope.data.smsStatus = self._getSMS();
        $scope.data.idVerifiedStatus = self._getIdVerifiedStatus();
        self._getEmailUnsubscribeStatus();
      };

      self._initialize();
    }
  ]);
})(window.angular);
