import React, { FC, useState, useEffect } from 'react';
import { makeStyles, Typography, useMediaQuery } from '@material-ui/core';

import {
  AppBar,
  ThemeProvider,
  Toolbar,
  Divider,
  Box,
  IconButton
} from '@knockrentals/knock-shared-web';
import TopNavBar from './Topbar/TopNavBar';
import { UserProfileType } from './Topbar/types';
import { MenuBurgerIcon } from '../icons';
import LeasingTeamSelector from './LeasingTeamSelector';
import InsideAngularViewportPortal from '../Portals/InsideAngularViewportPortal';
import { Provider } from 'react-redux';
import { store } from '../../../../app/store';
import useLeasingTeamBadges from 'app/services/me/leasingTeam/Badges/hooks';

export interface GlobalNavLeasingTeam {
  id: number;
  name: string;
  publicId: string;
}

interface GlobalNavHeaderNewProps {
  currentLeasingTeam: GlobalNavLeasingTeam | null;
  headerTitle: string;
  headerSubTitle: string;
  leasingTeams: GlobalNavLeasingTeam[];
  leasingTeamsBadgeCounts: {
    [key: string]: number;
  };
  userProfile: UserProfileType;
  userLogout: () => void;
  openKnockAnalytics: () => void;
  openClawApp: () => void;
  isClawAvailable: boolean;
  openProfileAccount: () => void;
  hideAnalytics: boolean;
  hideAccountViews: boolean;
  betaSwitch: (isBetaSwitchClicked?: boolean) => void;
  isRedesignBeta: boolean;
  openKnockVoice: () => void;
  openAdminApp: () => void;
  openSideNavDrawer: () => void;
  browserAudioNotificationsToggle: () => void;
  audioNotificationsEnabled: boolean;
  openProfileSettings: () => void;
  browserNotificationsToggle: () => void;
  desktopNotificationsEnabled: boolean;
  desktopNotificationsUnavailable: boolean;
  hideChangeUser: boolean;
  memberLogin: (selectedUser: number | null) => void;
  hideSettings: boolean;
  inboundBrowserCallEnable: boolean;
  isVoiceAppStatusFeatureEnable: boolean;
  voiceAppStatus: string | undefined;
  focusOnKnockVoice: () => void;
}

const useStyles = makeStyles({
  toolBar: {
    width: '100%'
  },

  subTitle: {
    paddingLeft: '20px',
    color: 'rgba(0, 0, 0, 0.64)',
    marginTop: '-2px'
  },

  logo: {
    marginRight: '20px',
    marginLeft: '16px', //marginTop: '-6px',
    '&.on-mobile': {
      marginRight: '0px'
    }
  },

  menuBurgerIconClass: {
    padding: '16px'
  },

  divider: { height: '40px', width: '1px', border: '1px' },

  globalNavHeaderAppBar: { minHeight: '100%', maxHeight: '100%' },

  globalNavHeaderWrappeNew: {
    position: 'absolute',
    top: 0,
    zIndex: 1300,
    left: '0',
    right: '0',
    height: '64px',
    '& .offset-for-sidenav': { top: '0' },
    '& .MuiAppBar-root': {
      backgroundColor: '#FFFFFF',
      flexDirection: 'unset',
      boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 5px'
    },
    '& .global-nav-header-app-bar': { minHeight: '100%', maxHeight: '100%' },
    '& .MuiFormControl-root': { margin: '0' },
    '& .MuiToolbar-root': { paddingLeft: '0px' }
  }
});

const GlobalNavHeaderNew: FC<GlobalNavHeaderNewProps> = ({
  currentLeasingTeam,
  headerSubTitle,
  leasingTeams,
  userLogout,
  openKnockAnalytics,
  openClawApp,
  isClawAvailable,
  openProfileAccount,
  userProfile,
  hideAnalytics,
  hideAccountViews,
  betaSwitch,
  isRedesignBeta,
  openKnockVoice,
  openAdminApp,
  openSideNavDrawer,
  browserAudioNotificationsToggle,
  audioNotificationsEnabled,
  openProfileSettings,
  browserNotificationsToggle,
  desktopNotificationsEnabled,
  desktopNotificationsUnavailable,
  hideChangeUser,
  memberLogin,
  hideSettings,
  inboundBrowserCallEnable,
  isVoiceAppStatusFeatureEnable,
  voiceAppStatus,
  focusOnKnockVoice
}) => {
  const {
    toolBar,
    subTitle,
    logo,
    menuBurgerIconClass,
    divider,
    globalNavHeaderWrappeNew,
    globalNavHeaderAppBar
  } = useStyles();

  const [leasingTeamsBadgeCounts, setleasingTeamsBadgeCounts] = useState();

  const isMobile = useMediaQuery('(max-width:428px)');

  const { Badges } = useLeasingTeamBadges();

  useEffect(() => {
    setleasingTeamsBadgeCounts(Badges);
  }, [Badges]);

  return (
    <Box data-testid="global-nav-header" className={globalNavHeaderWrappeNew}>
      <AppBar
        position="absolute"
        className={globalNavHeaderAppBar}
        elevation={0}
      >
        <Toolbar className={toolBar}>
          {isMobile && (
            <IconButton
              onClick={openSideNavDrawer}
              className={menuBurgerIconClass}
            >
              <MenuBurgerIcon />
            </IconButton>
          )}

          <img
            src="/images/nav-bar-icons/knock-logo.png"
            alt="logo"
            className={(isMobile ? 'on-mobile ' : '') + logo}
            data-testid="header-logo"
          />

          {!isMobile && (
            <>
              <Divider orientation="vertical" light className={divider} />
              <Typography variant="body2" className={subTitle}>
                {headerSubTitle}
              </Typography>
            </>
          )}
          {!isMobile && leasingTeams && leasingTeams.length > 1 && (
            <LeasingTeamSelector
              currentLeasingTeam={currentLeasingTeam}
              leasingTeams={leasingTeams}
              leasingTeamsBadgeCounts={leasingTeamsBadgeCounts}
            />
          )}
          {userProfile && (
            <TopNavBar
              key={userProfile.id}
              logout={userLogout}
              userProfile={userProfile}
              openKnockAnalytics={openKnockAnalytics}
              openProfileAccount={openProfileAccount}
              hideAnalytics={hideAnalytics}
              openClawApp={openClawApp}
              isClawAvailable={isClawAvailable}
              hideAccountViews={hideAccountViews}
              betaSwitch={betaSwitch}
              isRedesignBeta={isRedesignBeta}
              openKnockVoice={openKnockVoice}
              openAdminApp={openAdminApp}
              browserAudioNotificationsToggle={browserAudioNotificationsToggle}
              audioNotificationsEnabled={audioNotificationsEnabled}
              browserNotificationsToggle={browserNotificationsToggle}
              desktopNotificationsEnabled={desktopNotificationsEnabled}
              desktopNotificationsUnavailable={desktopNotificationsUnavailable}
              hideChangeUser={hideChangeUser}
              openProfileSettings={openProfileSettings}
              memberLogin={memberLogin}
              hideSettings={hideSettings}
              inboundBrowserCallEnable={inboundBrowserCallEnable}
              isVoiceAppStatusFeatureEnable={isVoiceAppStatusFeatureEnable}
              voiceAppStatus={voiceAppStatus}
              focusOnKnockVoice={focusOnKnockVoice}
            />
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default (props: GlobalNavHeaderNewProps) => (
  <InsideAngularViewportPortal>
    <ThemeProvider>
      <Provider store={store}>
        <GlobalNavHeaderNew {...props} />
      </Provider>
    </ThemeProvider>
  </InsideAngularViewportPortal>
);
