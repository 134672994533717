import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import {
  Box,
  Button,
  InputText,
  Text,
  NamedColors,
  Avatar
} from '@knockrentals/knock-shared-web';
import RichTextEditor from '../RichTextEditor';
import { getAuthToken } from '../../services/tokenService';
import { getInitialsFromFullName } from '../../utilities/userUtilities';
import { QuickReply } from './models';
import { DeleteIcon } from '../icons/DeleteIcon';

import { CircularProgressButton } from '../CircularProgressButton';
import { ErrorAlert } from '../../components/ErrorAlert';

const errorColor = '#F44336';

const useStyles = makeStyles({
  dialogTitle: {
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)'
  },

  dialogContent: {
    borderTop: 'none',
    paddingTop: '12px',
    paddingBottom: 0
  },

  dialogContainer: {
    maxWidth: '790px',
    position: 'relative'
  },

  dialogActions: {
    padding: '19px'
  },

  dialogActionsForEditMode: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  textInput: {
    '& .MuiInputLabel-formControl': {
      left: '4px',
      margin: 0
    },

    '& .MuiInputBase-root': {
      marginTop: 0,

      '&.Mui-focused': {
        border: `2px solid ${NamedColors.denim[300]}`,
        borderRadius: '2px'
      },

      '&.Mui-error': {
        border: `2px solid ${errorColor}`,
        borderRadius: '2px'
      },

      '&.Mui-disabled': {
        backgroundColor: NamedColors.slate[100]
      }
    },

    '& .MuiInputBase-input': {
      padding: '9px 0 8px'
    },

    '& .MuiInputBase-formControl': {
      borderColor: NamedColors.slate[300],
      marginTop: '0 !important'
    },

    '& .MuiInputBase-adornedEnd': {
      paddingRight: '8px'
    },

    '& .MuiTypography-root': {
      fontSize: '14px'
    },

    '& .MuiInputBase-multiline': {
      paddingLeft: '8px',
      paddingRight: '8px'
    },

    '& .MuiFormHelperText-root': {
      color: errorColor,
      marginLeft: '4px'
    }
  },

  textInputError: {
    '& .MuiFormLabel-root.Mui-focused': {
      color: errorColor
    }
  },

  titleContainer: {
    alignItems: 'center',
    display: 'flex'
  },

  titleInput: {
    marginBottom: '1rem',
    width: '365px'
  },

  avatarItem: {
    marginBottom: '12px',
    marginLeft: '16px'
  },

  avatar: {
    background: 'rgba(90, 114, 246, 0.08)',
    color: NamedColors.denim[500],
    fontSize: '18px'
  },

  emailMessageContainer: {
    marginBottom: '1.5rem',

    '& .cke': {
      border: `2px solid ${NamedColors.slate[300]} !important`,
      borderRadius: '2px !important'
    },

    '& .cke.cke_focus': {
      borderColor: `${NamedColors.denim[300]} !important`
    }
  },

  emailMessageLabel: {
    color: NamedColors.slate[600],
    left: '4px',
    lineHeight: '22px',
    position: 'relative'
  },

  emailMessageHelperText: {
    color: errorColor,
    float: 'left',
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.4px',
    lineHeight: '16px',
    margin: 0,
    marginLeft: '4px',
    marginTop: '3px',
    position: 'relative',
    textAlign: 'left',
    top: '-18px'
  },

  smsMessageInput: {
    marginBottom: '3px'
  },

  smsMessageCharCount: {
    float: 'right',
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    position: 'relative',
    top: '-20px'
  },

  fieldErrorText: {
    color: errorColor
  },

  emailFieldError: {
    '& .cke.cke_chrome, & .cke.cke_chrome.cke_focus': {
      borderColor: `${errorColor} !important`
    }
  },

  cancelButton: {
    marginRight: '8px'
  },

  deleteQuickReplyButton: {
    '& svg': {
      marginRight: '8px'
    }
  }
});

export enum TitleErrorType {
  Valid,
  Missing,
  Duplicate
}

export const MISSING_TITLE_ERROR = 'Please enter a title';
export const DUPLICATE_TITLE_ERROR =
  'A quick reply with this title already exists';
export const MISSING_MESSAGE_ERROR =
  'At least one Email or SMS message is required';
export const INVALID_SMS_MESSAGE_ERROR = 'The message is too long for SMS';

export interface QuickReplyModalProps {
  open: boolean;
  editMode: boolean;
  quickReply: QuickReply;
  onClose: () => void;
  saveQuickReply: (managerId: number, payload: QuickReply) => void;
  deleteQuickReply: (
    managerId: number,
    messageId: string,
    useDeleteEndpoint: boolean
  ) => void;
  mergeTags: [];
}

export const QuickReplyModal: FC<QuickReplyModalProps> = ({
  open,
  editMode,
  quickReply,
  onClose,
  saveQuickReply,
  deleteQuickReply,
  mergeTags
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [smsMessage, setSMSMessage] = useState<string>('');
  const [currentQuickReply, setCurrentQuickReply] = useState<QuickReply>();
  const [titleErrorType, setTitleErrorType] = useState<TitleErrorType>(
    TitleErrorType.Valid
  );
  const [hasMessageError, setHasMessageError] = useState<boolean>(false);
  const [hasSMSMessageError, setHasSMSMessageError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);

  const modalTitle = editMode ? 'Manage Quick Reply' : 'New Quick Reply';
  const submitButtonText = editMode ? 'Save' : 'Create';
  const avatarSecondaryTitle = editMode ? 'Owner' : 'Creator';
  const titleError =
    titleErrorType === TitleErrorType.Duplicate
      ? DUPLICATE_TITLE_ERROR
      : MISSING_TITLE_ERROR;
  const messageErrorText = MISSING_MESSAGE_ERROR;
  const finalSMSErrorText = hasMessageError
    ? MISSING_MESSAGE_ERROR
    : hasSMSMessageError
    ? INVALID_SMS_MESSAGE_ERROR
    : ' ';

  const MAX_SMS_MESSAGE_LENGTH = 320;

  useEffect(() => {
    setTitle(quickReply.title || '');
    setEmailMessage(quickReply.message || '');
    setSMSMessage(quickReply.message_sms || '');
    setCurrentQuickReply(quickReply);
    setIsOpen(open);
  }, [open, editMode, quickReply]);

  const hasInvalidInput = () => {
    let hasError = false;

    if (title) {
      setTitleErrorType(TitleErrorType.Valid);
    } else {
      setTitleErrorType(TitleErrorType.Missing);
      hasError = true;
    }

    // Make sure any message has actual content. The regex below is from the function for sending email directly from the guest card
    let emailText = emailMessage || '';
    emailText = emailText.replace(/(<([^>]+)>|&nbsp;)/g, '').trim();

    if (emailText || smsMessage.trim()) {
      setHasMessageError(false);
    } else {
      setHasMessageError(true);
      hasError = true;
    }

    if (smsMessage.length <= MAX_SMS_MESSAGE_LENGTH) {
      setHasSMSMessageError(false);
    } else {
      setHasSMSMessageError(true);
      hasError = true;
    }

    return hasError;
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleEmailMessageChange = (editorContent: string) => {
    setEmailMessage(editorContent);
  };

  const handleSMSMessageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSMSMessage(event.target.value);

    if (event.target.value.length > MAX_SMS_MESSAGE_LENGTH) {
      setHasSMSMessageError(true);
    } else {
      setHasSMSMessageError(false);
    }
  };

  const handleOpenDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const handleCancelDelete = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await deleteQuickReply(
        currentQuickReply!.manager_id,
        currentQuickReply!.message_id || '',
        true
      );

      setIsDeleteConfirmationOpen(false);
      handleClose();
    } catch (error) {
      // TODO: Log this somewhere
      console.log('Error deleting quick reply: %o', error);
      setDeleteLoading(false);
      setErrorMessage(
        'An unexpected error occurred while deleting your quick replies. Please try again later.'
      );
    }
  };

  const handleClose = () => {
    setTitleErrorType(TitleErrorType.Valid);
    setHasMessageError(false);
    setHasSMSMessageError(false);
    setSubmitLoading(false);
    setDeleteLoading(false);
    setErrorMessage('');

    onClose();
  };

  const handleSubmit = async () => {
    if (hasInvalidInput()) {
      return;
    }

    try {
      const payload: QuickReply = {
        manager_id: quickReply.manager_id,
        manager_name: quickReply.manager_name,
        title: title.trim(),
        message: emailMessage.trim(),
        message_sms: smsMessage.trim()
      };

      if (editMode) {
        payload.message_id = quickReply.message_id;
      }
      setSubmitLoading(true);
      await saveQuickReply(quickReply.manager_id, payload);
      handleClose();
    } catch (error: any) {
      setSubmitLoading(false);
      if (error.status === 400) {
        setTitleErrorType(TitleErrorType.Duplicate);
      } else {
        setErrorMessage(
          'An unexpected error occurred while saving your quick replies. Please try again later.'
        );
        console.log('Error saving quick reply: %o', error);
      }
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        scroll="paper"
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth="md"
        disableEnforceFocus={true}
        classes={{ paperFullWidth: classes.dialogContainer }}
        style={{ zIndex: 15001 }}
        onClose={handleClose}
      >
        <DialogTitle disableTypography={true} className={classes.dialogTitle}>
          <Text variant="h6">{modalTitle}</Text>
        </DialogTitle>

        <DialogContent dividers={true} className={classes.dialogContent}>
          {errorMessage && <ErrorAlert alertMessage={errorMessage} />}
          <Box className={classes.titleContainer}>
            <InputText
              value={title}
              label="Title"
              className={classNames(classes.textInput, classes.titleInput, {
                [classes.textInputError]:
                  titleErrorType !== TitleErrorType.Valid
              })}
              requiredMessage=" "
              fullWidth={true}
              inputProps={{
                maxLength: 100
              }}
              onChange={handleTitleChange}
              error={titleErrorType !== TitleErrorType.Valid}
              helperText={
                titleErrorType !== TitleErrorType.Valid ? titleError : ' '
              }
            />

            <Box className={classes.avatarItem}>
              <Avatar className={classes.avatar}>
                {getInitialsFromFullName(quickReply.manager_name)}
              </Avatar>
            </Box>

            <Box className={classes.avatarItem}>
              <Text variant="body1">{quickReply.manager_name}</Text>
              <Text variant="body2" color="textSecondary">
                {avatarSecondaryTitle}
              </Text>
            </Box>
          </Box>

          <Box>
            <Text
              variant="subtitle2"
              className={classNames(classes.emailMessageLabel, {
                [classes.fieldErrorText]: hasMessageError
              })}
            >
              Email
            </Text>

            <Box
              className={classNames(classes.emailMessageContainer, {
                [classes.emailFieldError]: hasMessageError
              })}
            >
              <RichTextEditor
                content={emailMessage}
                onChange={handleEmailMessageChange}
                token={getAuthToken() || ''}
                mergeTags={mergeTags}
                height="120px"
                resizeDirection="vertical"
              />
            </Box>
            <p className={classes.emailMessageHelperText}>
              {hasMessageError ? messageErrorText : '\u00a0'}
            </p>
          </Box>

          <Box>
            <InputText
              value={smsMessage}
              label="SMS"
              className={classNames(
                classes.textInput,
                classes.smsMessageInput,
                {
                  [classes.textInputError]:
                    hasMessageError || hasSMSMessageError
                }
              )}
              requiredMessage=" "
              multiline={true}
              rows={2}
              rowsMax={4}
              fullWidth={true}
              onChange={handleSMSMessageChange}
              error={
                hasMessageError || smsMessage.length > MAX_SMS_MESSAGE_LENGTH
              }
              helperText={finalSMSErrorText}
            />

            <Box
              className={classNames(classes.smsMessageCharCount, {
                [classes.fieldErrorText]:
                  smsMessage.length > MAX_SMS_MESSAGE_LENGTH
              })}
            >
              <span>
                {smsMessage.length}/{MAX_SMS_MESSAGE_LENGTH}
              </span>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions
          className={classNames(classes.dialogActions, {
            [classes.dialogActionsForEditMode]: editMode
          })}
        >
          {editMode && (
            <>
              <Button
                variant="text"
                color="secondary"
                data-testid="delete-btn"
                className={classes.deleteQuickReplyButton}
                onClick={handleOpenDeleteConfirmation}
              >
                <DeleteIcon /> Delete
              </Button>

              <Dialog open={isDeleteConfirmationOpen} style={{ zIndex: 15002 }}>
                <DialogTitle>Are you sure?</DialogTitle>

                <DialogContent>
                  <DialogContentText>{`Are you sure you want to delete the "${title}" Quick Reply?`}</DialogContentText>
                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                  <Button
                    data-testid="cancel-btn"
                    variant="outlined"
                    onClick={handleCancelDelete}
                  >
                    Cancel
                  </Button>
                  <CircularProgressButton
                    onClick={handleDelete}
                    shouldShowProgress={isDeleteLoading}
                    progressText="Deleting..."
                  >
                    Delete
                  </CircularProgressButton>
                </DialogActions>
              </Dialog>
            </>
          )}

          <Box>
            <Button
              variant="outlined"
              className={classes.cancelButton}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <CircularProgressButton
              onClick={handleSubmit}
              shouldShowProgress={isSubmitLoading}
              progressText={editMode ? 'Saving...' : 'Creating...'}
            >
              {submitButtonText}
            </CircularProgressButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
