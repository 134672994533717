import React, { FC } from 'react';
import { makeStyles, Link } from '@material-ui/core';

import { Text, ThemeProvider } from '@knockrentals/knock-shared-web';
import { EmptyListIcon, UnreadMessagesIcon } from '../icons';

const useStyles = makeStyles({
  header: {
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    fontSize: '24px'
  },

  link: {
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',

    '&:link, &:visited, &:hover, &:active': {
      // TODO: Replace this with a value from the knock-shared-web default theme when it's available
      color: '#697FF7'
    }
  }
});

export interface TodoEmptyListProps {
  managerId: number;
  managerUnreadMessageTotal: number;
  openManagerInbox: (managerId: number) => void;
}

export const EmptyTodoListMessage: FC<TodoEmptyListProps> = ({
  managerId,
  managerUnreadMessageTotal,
  openManagerInbox
}) => {
  const classes = useStyles();
  const unreadMessagesText = `View ${managerUnreadMessageTotal} Unread ${
    managerUnreadMessageTotal !== 1 ? 'Messages' : 'Message'
  }`;

  return (
    <ThemeProvider>
      <div className="todo-module-empty">
        {managerUnreadMessageTotal > 0 ? (
          <div className="content-container">
            <UnreadMessagesIcon />

            <div className="header-container">
              <Text variant="h4" className={classes.header}>
                You cleared the To-Do list!
              </Text>
              <Text variant="h4" className={classes.header}>
                Time to check your inbox.
              </Text>
            </div>

            <Link
              className={classes.link}
              onClick={() => {
                openManagerInbox(managerId);
                return false;
              }}
              href="#"
            >
              {unreadMessagesText}
            </Link>
          </div>
        ) : (
          <div className="content-container">
            <EmptyListIcon />

            <div className="header-container">
              <Text variant="h4" className={classes.header}>
                You cleared the To-Do list!
              </Text>
            </div>

            <Link
              className={classes.link}
              href="/manager/calendar"
              variant="subtitle2"
            >
              View Upcoming Tours
            </Link>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default (props: TodoEmptyListProps) => (
  <EmptyTodoListMessage {...props} />
);
