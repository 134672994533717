/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function ($) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('keepParams', [
    '$location',
    function ($location) {
      return {
        restrict: 'A',
        link: function (scope, elm, attrs) {
          if ('href' in attrs) {
            var params = $location.search();
            attrs.$set('href', attrs.href + '?' + $.param(params));
          }
        }
      };
    }
  ]);
})(window.jQuery);
