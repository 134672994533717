import React from 'react';

export const CloudUploadIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <path
      d="M9 16.5V10.5"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 13L9 10.5L11.5 13"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5001 11.5H13.2501C14.6494 11.5023 15.8931 10.6088 16.3374 9.28187C16.7817 7.95496 16.3268 6.49264 15.2082 5.65193C14.0896 4.81121 12.5584 4.78088 11.4074 5.57663C10.9434 3.1062 8.71743 1.36428 6.2079 1.50777C3.69837 1.65125 1.68547 3.63552 1.50607 6.14274C1.32666 8.64997 3.03652 10.9006 5.50005 11.4"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
