/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular, $) {
  'use strict';

  var app = angular.module('knock-ColorPicker', []);

  app.directive('colorPicker', function () {
    return {
      restrict: 'E',
      template: '<input id="colorPicker" ng-model="color" />',
      scope: {
        color: '=',
        palette: '='
      },
      link: function (scope, element) {
        $(element).find('#colorPicker').spectrum({
          color: scope.color,
          preferredFormat: 'hex',
          showInitial: true,
          showInput: true,
          palette: scope.palette,
          showPaletteOnly: !!scope.palette
        });
      }
    };
  });
})(window.angular, window.jQuery);
