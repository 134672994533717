/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('schedulingOfferedBy', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/scheduling/scheduling-offered-by.html',
      controller: 'SchedulingOfferedByController',
      scope: {
        listingId: '=',
        hideText: '='
      }
    };
  });

  app.controller('SchedulingOfferedByController', [
    '$scope',
    'listingApi',
    '$routeParams',
    function ($scope, listingApi, $routeParams) {
      var self = this;

      self._initialize = function () {
        listingApi.getListing($scope.listingId).success(function (response) {
          $scope.communityName = response.listing.location.name;
          $scope.communityId = response.listing.floorplan.community_id;

          listingApi
            .getListingLeasingTeam($scope.listingId)
            .success(function (response) {
              $scope.leasingTeam = response.leasing_team;

              var url = $scope.communityId
                ? '/community/' + $scope.communityId
                : response.leasing_team.url;

              if ($routeParams.pid) {
                if (url.indexOf('?') !== -1) {
                  url += '&pid=' + $routeParams.pid;
                } else {
                  url += '?pid=' + $routeParams.pid;
                }
              }

              $scope.url = url;
            });
        });
      };

      self._initialize();
    }
  ]);
})(window.angular);
