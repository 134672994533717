import {
  formatDate,
  getDurationFromNow
} from '../../../LegacyAngularApp/legacy-angular-app/utilities/timeUtilities';

export interface Prospect {
  id: number;
  streamId: string;
  assignedManagerId: number;
  todoStatus: {
    color: string;
    explanation: string;
    liveness: string;
    urgency: number;
  };
  profile: {
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    phone: {
      nationalFormat: string;
      canReceiveCall: boolean;
      canReceiveSMS: boolean;
    } | null;
    targetMoveDate: string;
    idVerified: string;
  };
  hasCallRecording: boolean;
  hasNote: boolean;
  hasAppointments: boolean;
  isDeleted: boolean;
  status: string;
  nextReminderTime: string;
  followUpDate: string;
  createdTime: string;
  lastContactedTime: Date;
  lastContactedTimeDescription: string;
  source: string;
  firstContactResponseTime: number;
  followUpCount: number;
  property: {
    name: string;
  };
  exportLog: any;
  preferences: {
    bedrooms: string;
    floorplan: string;
  };
  propertyId: number;
  manager: {
    id: number;
    fullName: string;
  };
  isWaitlist: boolean;
  pmsId: string;
  smsConsent?: {
    status: string;
  };
  stream_id?: string;
}

export interface PageData {
  count: number;
  page: number;
  rowsPerPage: number;
}

export interface ProspectData {
  prospects: Prospect[];
  pageData: PageData;
}

export interface ActivityData {
  prospect_ids: number[];
  type: string;
  message: string;
  reminder_time: string;
}

export interface ProspectLossReasonData {
  prospect_ids: string[];
  loss_reasons: string[];
}

export interface OwnerData {
  prospectIds: number[];
  newAssignedManagerId: number;
}

export interface ProspectExclusionData {
  prospect_ids: number[];
  excluded_reasons: string[];
}

export interface LostProspectsData {
  prospectIds: string[];
  newStatus: string;
}

export interface DeleteProspectsData {
  prospect_ids: number[];
}

export interface AddProspectData {
  first_contact_type: string;
  is_waitlist: boolean;
  manager_id: number;
  preferences: {
    preferred_property_floorplan_id: null;
    preferred_layout_id?: string;
    max_price?: number;
    min_price?: number;
    bedrooms: string[];
  };
  profile: {
    email?: string;
    first_name: string;
    last_name: string;
    phone: {
      country_code?: string;
      national_format?: string;
      phone_number?: string;
    };
    target_move_date: string;
  };
  property_id: number;
  source: string;
  pms_created_time?: string;
}

export interface Emoji {
  emoji: string;
  names: string[];
  activeSkinTone: string;
}

export interface DownloadProspectsRequest {
  property_id: string;
  manager_id: string;
  status: string;
  include_unassigned: boolean;
  is_waitlist: null | string;
  todo_status_color: null | string;
  prospect_ids: string[] | null;
  last_contacted_time: null | string;
}

export interface DownloadProspectsResponse {
  status_code: string;
  url: string;
}

export interface Layout {
  id: string;
  name: string;
  area: number;
  bedrooms: number;
}

export interface PropertyLayout {
  propertyId: number;
  layouts: Layout[];
}

export const mapLayouts = (response: any): Array<PropertyLayout> => {
  const layouts: Array<PropertyLayout> = [];
  const propertyId = Object.keys(response);
  for (let i = 0; i < propertyId.length; i++) {
    const currentLayouts: Array<Layout> = response[propertyId[i]];
    if (currentLayouts.length === 0) {
      continue;
    }
    layouts.push({
      propertyId: Number(propertyId[i]),
      layouts: currentLayouts.map((l: Layout) => ({
        name: l.name,
        id: l.id,
        area: l.area,
        bedrooms: l.bedrooms
      }))
    });
  }
  return layouts;
};

export const mapProspect = (item: any): Prospect => {
  let bedrooms: string[];
  const prospect: any = { ...item.Prospect };

  prospect.profile = item.Profile;
  prospect.preferences = item.RentalPreferences;

  prospect.manager_info = item.ManagerInfo;
  prospect.follow_up_count = item.follow_up_count;
  prospect.follow_up_date = item.follow_up_date;
  prospect.pms_id = item.pms_id;
  prospect.todo_status = item.todo_status;
  prospect.property = {
    name: item.property_name
  };

  if (prospect.preferences.bedrooms) {
    bedrooms = prospect.preferences.bedrooms.sort(
      (lValue: string, rValue: string) => {
        if (lValue === 'studio' || lValue === '') {
          return -1;
        }

        return parseInt(lValue) - parseInt(rValue);
      }
    );
  } else {
    bedrooms = [];
  }

  prospect.floorplan = prospect.preferences.preferred_layout_id
    ? prospect.preferences.preferred_layout_id
    : '-';

  return {
    id: prospect.id,
    streamId: prospect.stream_id,
    assignedManagerId: prospect.assigned_manager_id,
    todoStatus: prospect.todo_status,
    profile: {
      firstName: prospect.profile.first_name,
      lastName: prospect.profile.last_name,
      fullName:
        `${prospect.profile.first_name} ${prospect.profile.last_name}`.trim(),
      email: prospect.profile.email,
      phone:
        prospect.profile.phone && prospect.profile.phone.id
          ? {
              nationalFormat: prospect.profile.phone.national_format,
              canReceiveCall: prospect.profile.phone.can_receive_call,
              canReceiveSMS: prospect.profile.phone.can_receive_sms
            }
          : null,
      targetMoveDate: prospect.profile.target_move_date
        ? formatDate(prospect.profile.target_move_date)
        : '',
      idVerified: prospect.profile.id_verified || ''
    },
    hasCallRecording: prospect.has_call_recording,
    hasNote: prospect.has_note,
    hasAppointments: prospect.has_appointments,
    isDeleted: prospect.is_deleted,
    status: prospect.status,
    nextReminderTime: prospect.next_reminder_time,
    followUpDate: prospect.follow_up_date,
    createdTime: formatDate(prospect.created_time),
    lastContactedTime: prospect.last_contacted_time,
    lastContactedTimeDescription: getDurationFromNow(
      prospect.last_contacted_time,
      true,
      true,
      true
    ),
    source: prospect.source,
    firstContactResponseTime: prospect.first_contact_response_time,
    followUpCount: prospect.follow_up_count,
    property: {
      name: prospect.property ? prospect.property.name : ''
    },
    preferences: {
      bedrooms: bedrooms.length > 0 ? bedrooms.join(', ') : '-',
      floorplan: prospect.preferences.preferred_layout_id
        ? prospect.preferences.preferred_layout_id
        : '-'
    },
    propertyId: prospect.property_id,
    exportLog: prospect.export_log,
    manager: {
      id: prospect.manager_info.id,
      fullName:
        `${prospect.manager_info.first_name} ${prospect.manager_info.last_name}`.trim()
    },
    isWaitlist: prospect.is_waitlist,
    pmsId: prospect.pms_id,
    smsConsent: item.SMSConsent
  };
};

export interface ReferProspectData {
  prospectId: number;
  propertyId: number;
  isScheduling: boolean;
  note?: string;
  followUpDate?: string;
}

export interface IAddVisit {
  prospect_id: number;
  visit_time: string;
  appointment_id: number;
  extra_status_message?: string;
}

export interface IAppointmentStatus {
  appointmentId: number;
  status: string;
}

export interface ISetVisitShownUnits {
  visitId: number;
  units: string[];
}
