/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import remove from 'lodash/remove';
import indexOf from 'lodash/indexOf';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import filter from 'lodash/filter';
import take from 'lodash/take';
import isArray from 'lodash/isArray';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('imageUploader', [
    '$parse',
    '$timeout',
    '$window',
    '$mdDialog',
    'deviceDetector',
    'excludedPhotosModalFactory',
    'uploadService',
    function (
      $parse,
      $timeout,
      $window,
      $mdDialog,
      deviceDetector,
      excludedPhotosModalFactory,
      uploadService
    ) {
      return {
        restrict: 'E',
        templateUrl: '/angular/views/imageUploader/image-uploader.html',
        scope: {
          images: '=',
          endpoint: '=?',
          customPlaceholder: '=?',
          allowMultiple: '=?',
          acceptImageDescription: '=?',
          imageDescriptionPlaceholder: '=?',
          imageDescriptionMaxLength: '=?',
          imageDescriptionShowCharCount: '=?',
          imageDescriptionTextFieldLabel: '=?',
          onOpenPrompt: '=?',
          maxFileSize: '=?',
          maxFiles: '=?',
          autoUpload: '=?',
          uploadKey: '@?'
        },
        link: function (scope, element) {
          if (!isArray(scope.images)) {
            scope.images = [];
          }

          scope.maxFileSize = scope.maxFileSize || 10;
          scope.maxFiles = scope.maxFiles || 24;
          scope.approvedFileExtensions = /.(jpg|jpeg|png|gif)$/i;
          scope.misFormattedImages = [];

          scope.placeholderText =
            scope.customPlaceholder ||
            'Drag and drop or click here to browse... ';
          scope.dropFieldClass = '';

          scope.imageSortConfig = {
            group: 'images',
            animation: 150
          };

          scope._initializeDragDropHandlers = function () {
            var dropFieldText = element.find('.image-uploader-text')[0];

            dropFieldText.addEventListener(
              'dragover',
              scope._onDragOver,
              false
            );
            dropFieldText.addEventListener(
              'dragleave',
              scope._onDragLeave,
              false
            );
            dropFieldText.addEventListener('drop', scope._onDragDrop, false);
          };

          scope.onOpenPrompt = function ($event) {
            scope.openFilePrompt($event);
          };

          scope._onDragOver = function (event) {
            event.stopPropagation();
            event.preventDefault();

            scope.$apply(function () {
              scope.dropFieldClass = 'drag-over';
            });
          };

          scope._onDragLeave = function (event) {
            event.stopPropagation();
            event.preventDefault();

            scope.$apply(function () {
              scope.dropFieldClass = 'drag-leave';
            });
          };

          scope._onDragDrop = function (event) {
            event.stopPropagation();
            event.preventDefault();
            scope.addImagesFromElement(event.dataTransfer);
            scope.dropFieldClass = 'drag-leave';
          };

          scope.addImagesFromElement = function (element) {
            var elementImages = element.files;

            if (elementImages.length === 0) {
              return;
            }

            var maxImages = scope.allowMultiple ? scope.maxFiles : 1;

            var totalImageCount = elementImages.length + scope.images.length;

            if (totalImageCount > maxImages) {
              elementImages = take(
                elementImages,
                Math.max(1, maxImages - scope.images.length)
              );

              if (scope.allowMultiple) {
                excludedPhotosModalFactory.openModal(
                  maxImages,
                  scope.maxFileSize
                );
              }
            }

            var tooLargeImages = filter(elementImages, function (image) {
              return image.size / 1024 / 1024 > scope.maxFileSize;
            });

            if (tooLargeImages && tooLargeImages.length > 0) {
              excludedPhotosModalFactory.openModal(
                scope.maxFiles,
                scope.maxFileSize
              );
            }

            elementImages = filter(elementImages, function (image) {
              return image.size / 1024 / 1024 <= scope.maxFileSize;
            });

            var imageFiles = map(elementImages, scope._mapLocalImage);

            if (scope.autoUpload) {
              if (!scope.endpoint) {
                throw 'cannot auto upload without endpoint';
              }

              if (!scope.uploadKey) {
                throw 'cannot auto upload without endpoint';
              }

              forEach(imageFiles, scope._startUpload);
            }

            if (scope.allowMultiple) {
              scope.images = scope.images.concat(imageFiles);
            } else {
              scope.images = imageFiles;
            }

            if (scope.misFormattedImages.length) {
              scope._showUnapprovedFileModal(scope.misFormattedImages);
              forEach(scope.misFormattedImages, (image) => {
                scope.removeImage(image);
              });
              scope.misFormattedImages = [];
            }

            scope.$digest();
          };

          scope._startUpload = function (file) {
            file.progress = 5;
            file.isError = false;

            if (!file.name.match(scope.approvedFileExtensions)) {
              file.isError = true;
              scope.misFormattedImages.push(file);
              return;
            }
            uploadService.upload(
              scope.endpoint,
              scope.uploadKey,
              [file],
              function (completeEvent) {
                var imageObject = JSON.parse(completeEvent.target.responseText)
                  .photos[0];
                var existingIndex = indexOf(scope.images, file);

                scope.images.splice(existingIndex, 1, imageObject);
                scope.$apply();
              },
              function (progressEvent) {
                file.progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                scope.$apply();
              },
              function () {
                file.isError = true;
                scope.$apply();
              }
            );
          };

          scope.retryUpload = function () {
            var localImages = filter(scope.images, { isLocal: true });
            forEach(localImages, scope._startUpload);
          };

          scope.openFilePrompt = function ($event) {
            if ($event.clientX === 0 && $event.clientY === 0) {
              // this click came from propagation of the event, return
              return;
            }
            var fileInput = element.find('.image-file-input')[0];

            if (fileInput) {
              if (deviceDetector.browser === 'ie') {
                fileInput.click();
              } else {
                $timeout(function () {
                  fileInput.click();
                });
              }
            }
          };

          scope._mapLocalImage = function (image) {
            image.isLocal = true;
            image.url = $window.URL.createObjectURL(image);

            return image;
          };

          scope.previewImage = function (image) {
            $mdDialog.show({
              controller: [
                '$scope',
                'image',
                function ($scope, image) {
                  $scope.image = image;
                }
              ],
              templateUrl:
                '/angular/views/imageUploader/image-preview-modal.html',
              parent: angular.element(document.body),
              clickOutsideToClose: true,
              locals: {
                image: image.url
              }
            });
          };

          scope.removeImage = function (image) {
            $window.URL.revokeObjectURL(image.url);
            remove(scope.images, image);
          };

          scope.updateImageDescription = function (description, index) {
            scope.images[index]['description'] = description;
          };

          scope._showUnapprovedFileModal = (files) => {
            $mdDialog.show({
              controller: [
                '$scope',
                '$mdDialog',
                'files',
                function ($scope, $mdDialog, files) {
                  $scope.files = files;
                  $scope.closeModal = function () {
                    $mdDialog.hide();
                  };
                }
              ],
              templateUrl:
                '/angular/views/imageUploader/unapproved-file-ext-modal.html',
              parent: angular.element(document.body),
              clickOutsideToClose: true,
              locals: {
                files: files
              }
            });
          };

          scope._initializeDragDropHandlers();
        }
      };
    }
  ]);
})(window.angular);
