/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isNumber from 'lodash/isNumber';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Voice');
  var padWithZero = function (num) {
    if (num < 10) {
      return '0' + num;
    }

    return num.toString();
  };

  app.filter('voiceCallLength', [
    function () {
      return function (lengthInSeconds) {
        if (!isNumber(lengthInSeconds) || lengthInSeconds <= 0) {
          return '00:00';
        }

        var hours = Math.floor(lengthInSeconds / (60 * 60));
        var minutes = Math.floor((lengthInSeconds - hours * 60) / 60);
        var seconds = Math.floor(lengthInSeconds % 60);

        if (hours > 0) {
          return (
            padWithZero(hours) +
            ':' +
            padWithZero(minutes) +
            ':' +
            padWithZero(seconds)
          );
        }

        return padWithZero(minutes) + ':' + padWithZero(seconds);
      };
    }
  ]);
})(window.angular);
