import React, { useLayoutEffect } from 'react';
import { legacyApp } from './legacy-angular-app/appSetup/LegacyApp';

export default function LegacyAngularApp() {
  useLayoutEffect(() => {
    legacyApp.launch();
  }, []);

  return <div id="legacyAngularApp" />;
}
