import remove from 'lodash/remove';
import compact from 'lodash/compact';
import range from 'lodash/range';

(function (angular) {
  var app = angular.module('knockApp');

  app.controller('FloorplanEditorController', [
    'api',
    '$scope',
    '$rootScope',
    '$timeout',
    '$routeParams',
    '$modal',
    '$mdToast',
    '$location',
    'companyApi',
    'managerApi',
    'communityApi',
    'confirmationModalFactory',
    'floorplanModelService',
    'postFloorplanModalService',
    'deleteResourceModalFactory',
    'unitApi',
    function (
      api,
      $scope,
      $rootScope,
      $timeout,
      $routeParams,
      $modal,
      $mdToast,
      $location,
      companyApi,
      managerApi,
      communityApi,
      confirmationModalFactory,
      floorplanModelService,
      postFloorplanModalService,
      deleteResourceModalFactory,
      unitApi
    ) {
      var self = this;

      $scope.newUnitsFeature = $rootScope.featureFlags.NEW_UNITS || false;

      $scope.photoUploadEndpoint = api.host + '/upload/photo';

      // TODO-NU: Remove ranges for editable bed/bath fields
      $scope.bedroomRange = range(9);
      $scope.bathroomRange = range(1, 8.5, 0.5);

      $scope.isNewNavActive = $rootScope.featureFlags.LMA_NEW_NAVIGATION;

      $scope.customPlaceholder =
        'Drag and drop floorplan photos here, or click here to browse...';
      $scope.floorplan = {};
      $scope.maxDescriptionLength = 1000;

      self._initialize = () => {
        companyApi.getCompanyPreferences().success((response) => {
          $scope.allowPost =
            response.preferences.allowed_property_type !== 'none';
        });

        self._getFloorplan();
      };

      self._getFloorplan = () => {
        // New Units floorplan logic
        if ($scope.newUnitsFeature) {
          if (!$routeParams.floorplanId || !$routeParams.propertyId) {
            $scope.loadError =
              'There was an error loading your floorplan. Please try again later.';
            return;
          }
          $scope.isEditing = true;
          unitApi
            .getLayout($routeParams.propertyId, $routeParams.floorplanId)
            .then((response) => {
              $scope.floorplan = response.data.layout;
            });
          // TODO-NU: Remove legacy floorplan code
        } else {
          if ($routeParams.floorplanId) {
            $scope.isEditing = true;
            managerApi
              .getFloorplan($routeParams.floorplanId)
              .success((response) => {
                $scope.floorplan = response.floorplan;
                $scope.floorplan.photos = compact($scope.floorplan.photos);
              })
              .error(() => {
                $scope.loadError =
                  'There was an error loading your floorplan. Please try again later.';
              });
          } else {
            if (!$scope.newUnitsFeature) {
              $scope.floorplan = floorplanModelService.getNewModel();
              $scope.floorplan.community_id = $routeParams.communityId;
            }
          }
        }
      };

      // TODO-NU: Remove ability to delete floorplan
      $scope.deleteFloorplan = () => {
        if ($scope.newUnitsFeature) {
          return;
        }
        const title = 'Delete floorplan';
        const text = 'Are you sure you wish to delete this floorplan?';
        const supportMessage =
          'Please remove floorplan: ' + $scope.floorplan.name;

        if ($rootScope.appPreferences.company.allowed_property_type === 'mf') {
          const modal = confirmationModalFactory.createModal(title, text, () =>
            self._deleteFloorplan()
          );
          $modal.open(modal);
          return;
        }

        deleteResourceModalFactory
          .confirmDeleteResource(title, text, supportMessage)
          .then((password) => self._deleteFloorplan(password));
      };

      self._deleteFloorplan = (password) => {
        if ($scope.newUnitsFeature) {
          return;
        }
        managerApi.deleteFloorplan($scope.floorplan.id, password).then(
          function () {
            $location.url('/manager/listing');
          },
          function () {
            $mdToast.showSimple('Invalid password!');
            $scope.isDeleting = false;
          }
        );
      };

      // TODO-NU: Remove legacy floorplan code
      $scope.postFloorplan = ($event) => {
        if ($scope.newUnitsFeature) return;
        $scope.isPosting = true;

        $scope.saveFloorplan(() => {
          postFloorplanModalService.openModal(
            $event,
            $scope.floorplan,
            self._floorplanPostedSuccess
          );
        });
      };

      $scope.saveFloorplan = (callback) => {
        $scope.isSaving = true;
        $scope.saveError = false;
        $scope.saveSuccess = false;

        if ($scope.newUnitsFeature) {
          const params = {};
          // Do not allow users to update layout with description length > maxDescriptionLength
          params.description = $scope.floorplan.description
            ? $scope.floorplan.description.slice(0, $scope.maxDescriptionLength)
            : '';
          remove($scope.floorplan.images, { isLocal: true });
          if ($scope.floorplan.images) {
            // The image url object that returns from the imageUploader has a different schema than
            // the units-service image object, but both share a key value pair of { url: string, thumbUrl: string }.
            params.images = $scope.floorplan.images.map((image) => ({
              description: image.description,
              url: image.url,
              thumbUrl: image.thumbUrl
            }));
          }

          unitApi
            .updateLayout(
              $routeParams.propertyId,
              $routeParams.floorplanId,
              params
            )
            .then((response) => {
              $scope.layout = response.data.layout;
              $scope.saveSuccess = true;
            })
            .catch(() => {
              $scope.saveError = true;
              $scope.loadError =
                'There was an error updating floorplan. Please try again later.';
            })
            .finally(() => ($scope.isSaving = false));
        } else {
          self._legacySaveFloorplan(callback);
        }
      };

      // TODO: Remove legacy floorplan code
      self._legacySaveFloorplan = (callback) => {
        remove($scope.floorplan.photos, { isLocal: true });

        var promise = null;

        if ($scope.isEditing) {
          promise = managerApi
            .updateFloorplan($scope.floorplan.id, $scope.floorplan)
            .success(() => {
              if (callback) {
                callback();
              }
            });
        } else {
          promise = communityApi
            .createFloorplan($scope.floorplan)
            .success((response) => {
              $scope.floorplan.id = response.floorplan_id;
              $scope.isEditing = true;

              if (callback) {
                callback();
              }
            });
        }

        promise
          .error(() => {
            $scope.saveError = true;
          })
          .finally(() => {
            $scope.isSaving = false;
            $scope.isPosting = false;
            $scope.saveSuccess = true;
          });
      };

      self._floorplanPostedSuccess = function () {
        $timeout(
          function () {
            $location.url('/manager/listing');
          },
          1000,
          true
        );
      };

      self._initialize();
    }
  ]);
})(window.angular);
