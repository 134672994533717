/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular, $) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('largeCarouselModal', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/listingDetails/large-carousel-modal.html',
      controller: 'LargeCarouselModalController',
      link: function (scope) {
        scope.$watch('isVisible', function () {
          if (scope.isVisible) {
            $(document).on('keyup.closeModal', function (keyEvent) {
              if (keyEvent.which === 27) {
                // Escape key
                scope.$apply(function () {
                  scope.close();
                });
              }

              keyEvent.preventDefault();
            });
          } else {
            $(document).unbind('keyup.closeModal');
          }
        });
      },
      scope: {
        images: '=',
        isVisible: '=',
        isPicker: '=?',
        onImagePicked: '=?'
      }
    };
  });

  app.controller('LargeCarouselModalController', [
    '$scope',
    function ($scope) {
      $scope.isVisible = false;

      $scope.close = function () {
        $scope.isVisible = false;
      };

      $scope.pickImage = function (image) {
        if ($scope.onImagePicked) {
          $scope.onImagePicked(image);
        }
        $scope.close();
      };
    }
  ]);
})(window.angular, window.jQuery);
