/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('historyTableSortingFilter', [
    function () {
      return {
        sortResidents: function (property) {
          var sortValue = 0;

          if (property.status === 'inactive') {
            sortValue = 99999; // Make sure it's always at the bottom use a huge positive number
          }
          if (property.status === 'active') {
            sortValue = -77777; // Make sure it's always at the top use a huge negative number
          }
          if (property.status === 'pending') {
            sortValue = -88888; // Make sure it's always below active but above MF listings
          }

          if (property.type === 'community') {
            sortValue = -99999;
          }

          sortValue = sortValue.toString() + property.name;

          return sortValue;
        },
        sortProspects: function (property) {
          var sortValue = 0;

          if (property.type === 'unassigned') {
            sortValue = -66666; // Make sure it's always at the bottom use a huge negative number
          }
          if (property.status === 'inactive') {
            sortValue = 99999; // Make sure it's always at the bottom use a huge positive number
          }
          if (property.status === 'active') {
            sortValue = -77777; // Make sure it's always at the top use a huge negative number
          }
          if (property.status === 'pending') {
            sortValue = -88888; // Make sure it's always below active but above MF listings
          }
          if (property.type === 'community') {
            sortValue = -99999;
          }

          sortValue = sortValue.toString() + property.name;

          return sortValue;
        }
      };
    }
  ]);
})(window.angular);
