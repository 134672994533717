import React, { FC, useState, Fragment } from 'react';
import { map, filter } from 'lodash';
import { Button, LinearProgress, Typography } from '@material-ui/core';

import {
  MergeDefaultType,
  MergeDefaultTypeKeys,
  MergeFieldLabels,
  MergeProspectType
} from './MergeData';
import MergeField from './MergeField';

interface MergeFieldSelectionProps {
  master: MergeProspectType | null;
  secondary: MergeProspectType | null;
  defaults: MergeDefaultType | null;
  setStep: (step: number) => void;
  currentStep: number;
  locked: string[];
  setMergeDecisions: (
    masterId: number,
    secondaryId: number,
    decisions: MergeDefaultType
  ) => void;
  toggleModal: () => void;
  onSuccess: () => void;
}

const MergeFieldSelection: FC<MergeFieldSelectionProps> = ({
  master,
  secondary,
  defaults,
  setStep,
  currentStep,
  locked = [],
  setMergeDecisions,
  toggleModal,
  onSuccess
}) => {
  const [loading, setLoading] = useState(false);
  const [decisions, setDecisions] = useState(defaults);
  const [error, setError] = useState(false);

  const sendMergeDecisions = async () => {
    if (!master || !secondary || !decisions) {
      return;
    }

    setLoading(true);

    try {
      await setMergeDecisions(master.id, secondary.id, decisions);
      onSuccess();
      toggleModal();
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          height: '250px',
          padding: '0 50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography variant="h4" style={{ margin: '-30px 0 30px' }}>
          Merging prospects
        </Typography>
        <LinearProgress style={{ width: '100%' }} />
      </div>
    );
  }

  if (error) {
    return (
      <Fragment>
        <div className="react-modal-body" style={{ paddingBottom: 0 }}>
          <div
            style={{
              color: 'rgb(97, 26, 21)',
              backgroundColor: 'rgb(253,236,234)',
              padding: '6px 16px',
              lineHeight: '1.5'
            }}
          >
            There was a problem merging these prospects.
            <br />
            Please contact support.
          </div>
        </div>
        <div className="react-modal-footer">
          <Button
            onClick={() => toggleModal()}
            className="merge-modal-button secondary"
            variant="outlined"
          >
            Close
          </Button>
        </div>
      </Fragment>
    );
  }

  if (!master || !secondary || !defaults) {
    return (
      <Fragment>
        <div className="react-modal-body">
          There was a problem fetching data to merge these prospects
        </div>
        <div className="react-modal-footer">
          <Button
            onClick={() => setStep(currentStep - 1)}
            className="merge-modal-button secondary"
            variant="outlined"
          >
            Back
          </Button>
        </div>
      </Fragment>
    );
  }

  const unlockedFields = filter(
    decisions,
    (value, key) => locked.indexOf(key) === -1
  );
  const missingValues = unlockedFields
    ? Object.values(unlockedFields).filter((v) => v === null)
    : [];
  const disabled = missingValues.length > 0;

  return (
    <React.Fragment>
      <div
        className="react-modal-body"
        style={{
          flex: '1 1 100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto'
        }}
      >
        <h4 style={{ flex: 0 }}>What information do you want to keep?</h4>
        <table className="merge-field-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Guest Card 1</th>
              <th>Guest Card 2</th>
            </tr>
          </thead>
          <tbody>
            {map(decisions, (value, key: MergeDefaultTypeKeys) => {
              return (
                <MergeField
                  key={key}
                  fieldKey={key}
                  label={MergeFieldLabels[key]}
                  masterId={master.id}
                  masterValue={master[key]}
                  secondaryId={secondary.id}
                  secondaryValue={secondary[key]}
                  defaultValue={value}
                  setDecision={(key, value) =>
                    setDecisions({ ...decisions, [key]: value })
                  }
                  locked={!!locked && locked.indexOf(key) > -1}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="react-modal-footer">
        <Button
          onClick={() => setStep(currentStep - 1)}
          className="merge-modal-button secondary"
          variant="outlined"
        >
          Back
        </Button>
        <Button
          onClick={() => sendMergeDecisions()}
          style={{ marginLeft: '10px' }}
          disabled={disabled}
          className="merge-modal-button primary"
          variant="contained"
          color="primary"
        >
          Merge
        </Button>
      </div>
    </React.Fragment>
  );
};

export default MergeFieldSelection;
