/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
import remove from 'lodash/remove';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('notificationBanners', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/notifications/notification-banners.html',
      controller: 'NotificationBannersController',
      scope: {}
    };
  });

  app.factory('notificationBannersService', function () {
    var self = this;

    self.notificationTypes = {
      call: 'call',
      chat: 'chat',
      appointment: 'appointment',
      batchMessageProgress: 'batchMessageProgress'
    };

    self.notifications = [];

    self.addNotification = function (id, type, data) {
      if (!self.notificationTypes[type]) {
        throw new Error(type + ' is not a valid notification type!');
      }

      self.notifications.push({
        id: id,
        type: type,
        data: data
      });
    };

    self.removeNotification = function (id, type) {
      return remove(self.notifications, function (notification) {
        return notification.id === id && notification.type === type;
      });
    };

    self.updateNotification = function (id, type, data) {
      var notification = self.getNotification(id, type);

      if (!notification) {
        return null;
      }

      notification.data = data;

      return notification;
    };

    self.getNotification = function (id, type) {
      return find(self.notifications, function (notification) {
        return notification.id === id && notification.type === type;
      });
    };

    return self;
  });

  const NotificationBannersController = function (
    $scope,
    notificationBannersService
  ) {
    $scope.notifications = notificationBannersService.notifications;
  };
  NotificationBannersController.$inject = [
    '$scope',
    'notificationBannersService'
  ];

  app.controller(
    'NotificationBannersController',
    NotificationBannersController
  );
})(window.angular);
