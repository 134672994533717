import React, { useState, useEffect } from 'react';
import { Box, Card, Chip } from '@material-ui/core';
import { makeStyles, Text, NamedColors } from '@knockrentals/knock-shared-web';
import { useMarkAsLostContext } from './MarkAsLostDrawerContextProvider';
import { colors } from '../../commonStyles/commonStyles';
import { NearbyCommunity } from 'LegacyAngularApp/legacy-angular-app/services/nearbyCommunitiesService';
import { ErrorAlert } from '../../ErrorAlert';
const useStyles = makeStyles({
  card: {
    padding: '16px',
    marginBottom: '24px',
    '&.content-box': {
      '& :last-child:not(.MuiChip-root)': {
        marginBottom: '0'
      }
    }
  },
  container: {
    minWidth: '672px'
  },
  cardTitle: {
    marginBottom: '16px',
    fontSize: '16px',
    fontWeight: 600
  },
  chip: {
    backgroundColor: NamedColors.slate[100],
    '& .MuiChip-label': {
      fontSize: '13px'
    },
    marginRight: '8px',
    marginBottom: '8px',
    '&.withColor': {
      backgroundColor: NamedColors.denim[50]
    }
  },
  chipContainer: {
    marginBottom: '6px'
  },
  noActiveCard: {
    backgroundColor: '#FAFAFA',
    '& .MuiTypography-body1': {
      color: colors.disabled,
      margin: '0'
    }
  },
  subTitle: {
    color: colors.disabled,
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: '2px'
  },
  value: {
    fontSize: '14px',
    marginBottom: '12px'
  },
  alert: {
    marginBottom: '24px',
    '& .MuiAlert-message': {
      display: 'flex'
    },
    '& .MuiAlertTitle-root': {
      fontWeight: 600,
      marginBottom: '5px !important'
    }
  },
  markAsLostButtonContainer: {
    width: '185px',
    display: 'flex',
    paddingLeft: '16px',
    justifyContent: 'center'
  },
  markAsLostButton: {
    color: NamedColors.denim[600],
    cursor: 'pointer',
    fontSize: '13px'
  }
});

const MarkAsLostSummary = () => {
  const classes = useStyles();
  const {
    getPlainTextFromEmail,
    selectedReasons,
    noReferral,
    sendInfoMail,
    selectedCommunities,
    communities,
    referralNote,
    referralFollowUp,
    bookTourChecked,
    emailMessage,
    emailSubject,
    createReferral,
    referralError,
    informationalEmailError,
    handleMarkAsLost
  } = useMarkAsLostContext();
  const { followUp, date, time } = referralFollowUp;

  const [emailPlainText, setEmailPlainText] = useState<string>('');

  const markAsLostAction = (
    <Box className={classes.markAsLostButtonContainer}>
      <Text className={classes.markAsLostButton} onClick={handleMarkAsLost}>
        Mark as lost
      </Text>
    </Box>
  );

  useEffect(() => {
    setEmailPlainText(getPlainTextFromEmail(emailMessage));
  }, [emailMessage, getPlainTextFromEmail]);

  return (
    <Box className={classes.container}>
      <Card className={classes.card} variant="outlined">
        <Text className={classes.cardTitle}>Mark as Lost</Text>
        <Box>
          {selectedReasons.map((reason: string) => (
            <Chip key={reason} className={classes.chip} label={reason} />
          ))}
        </Box>
      </Card>

      <Card
        className={`${classes.card} ${
          noReferral || !createReferral ? classes.noActiveCard : ''
        } content-box`}
        variant="outlined"
      >
        <Text className={classes.cardTitle}>
          {noReferral || !createReferral
            ? 'No Referral to Nearby Communities'
            : 'Refer to Nearby Communities'}
        </Text>
        {referralError && (
          <ErrorAlert
            className={classes.alert}
            noCollapse
            alertTitle="Unable to refer to nearby communities"
            alertMessage="We ran into an issue when trying to complete your referral. Please try to refer again later or confirm without completing the referral."
          >
            {markAsLostAction}
          </ErrorAlert>
        )}
        {!noReferral && createReferral && (
          <Box>
            <Text className={classes.subTitle}>Communities</Text>
            <Box className={classes.chipContainer}>
              {selectedCommunities.map((id: number) => (
                <Chip
                  key={id}
                  className={`${classes.chip} withColor`}
                  label={
                    communities.find(
                      (community: NearbyCommunity) =>
                        community.property.id === id
                    )?.property.name
                  }
                />
              ))}
            </Box>
            <Text className={classes.subTitle}>Referral Note</Text>
            <Text className={classes.value}>"{referralNote}"</Text>
            {followUp && (
              <>
                <Text className={classes.subTitle}>Follow UP</Text>
                <Text className={classes.value}>
                  Follow up for the receiving team(s) on{' '}
                  {date.toLocaleString().split(`,`)[0]} at {time}
                </Text>
              </>
            )}
            {bookTourChecked && (
              <>
                <Text className={classes.subTitle}>Book tour(s)</Text>
                <Text className={classes.value}>
                  You will be prompted to book tour(s) once this prospect is
                  marked as lost.
                </Text>
              </>
            )}
          </Box>
        )}
      </Card>

      <Card
        className={`${classes.card} ${
          !sendInfoMail || noReferral ? classes.noActiveCard : ''
        } content-box`}
        variant="outlined"
      >
        <Text className={classes.cardTitle}>
          {!sendInfoMail || noReferral
            ? 'No Informational Email'
            : 'Send Informational Email'}
        </Text>
        {informationalEmailError && (
          <ErrorAlert
            className={classes.alert}
            noCollapse
            alertTitle="Unable to send informational email"
            alertMessage="We ran into an issue when trying to send the informational email. Please try to refer again later or confirm without sending the informational email."
          >
            {markAsLostAction}
          </ErrorAlert>
        )}
        {sendInfoMail && !noReferral && (
          <Box>
            <Text className={classes.subTitle}>Communities</Text>
            <Box className={classes.chipContainer}>
              {selectedCommunities.map((id: number) => (
                <Chip
                  key={id}
                  className={`${classes.chip} withColor`}
                  label={
                    communities.find(
                      (community: NearbyCommunity) =>
                        community.property.id === id
                    )?.property.name
                  }
                />
              ))}
            </Box>
            <Text className={classes.subTitle}>Subject</Text>
            <Text className={classes.value}>"{emailSubject}"</Text>

            <Text className={classes.subTitle}>Email</Text>
            <Text className={classes.value}>
              "
              {emailPlainText.length > 170
                ? `${emailPlainText.split('').slice(0, 170).join('')}...`
                : emailPlainText}
              "
            </Text>
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default MarkAsLostSummary;
