/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import includes from 'lodash/includes';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('ListingEditorController', [
    'api',
    'appDataService',
    '$scope',
    '$routeParams',
    '$interval',
    '$mdDialog',
    'imageBucket',
    'companyApi',
    'listingApi',
    'managerApi',
    'photoApi',
    'listingModelService',
    'ListingPreviewService',
    function (
      api,
      appDataService,
      $scope,
      $routeParams,
      $interval,
      $mdDialog,
      imageBucket,
      companyApi,
      listingApi,
      managerApi,
      photoApi,
      listingModelService,
      ListingPreviewService
    ) {
      var self = this;

      $scope.isPosting = false;
      $scope.isSaving = false;

      $scope.imageBucket = imageBucket;
      $scope.photoUploadEndpoint = api.host + '/upload/photo';

      self._initialize = function () {
        companyApi.getCompanyPreferences().success(function (response) {
          $scope.allowPost =
            response.preferences.allowed_property_type !== 'none';
        });

        if ($routeParams.listingId) {
          self._loadExistingListing();
        } else {
          $scope.isNewListing = true;
          $scope.isActiveListing = false;
          $scope.listing = listingModelService.getNewModel();
          $scope.application = {};
        }
      };

      self._loadExistingListing = function () {
        var listingId = $routeParams.listingId;

        listingApi
          .getListing(listingId)
          .success(self._loadListingSuccess)
          .error(self._loadListingError);

        listingApi
          .getListingApplication(listingId)
          .success(self._loadApplicationSuccess);
      };

      self._loadListingSuccess = function (response) {
        $scope.listing = listingModelService.mapStoredListingToViewModel(
          response.listing
        );
        $scope.isActiveListing = $scope.listing.isActive;

        if ($scope.listing.videos.length === 0) {
          $scope.listing.videos = [{ url: '', thumb_url: '' }];
        }
      };

      self._loadListingError = function () {
        $scope.loadError = 'There was an error loading your listing.';
      };

      self._loadApplicationSuccess = function (response) {
        $scope.application = response.application || {};
      };

      $scope.onAddAmenity = function (amenityTitle, amenityType) {
        var topLevelAmenities = [
          'utilities',
          'parking',
          'laundry',
          'appliances',
          'views',
          'furnishing'
        ];
        if (includes(topLevelAmenities, amenityType)) {
          var amenity = {
            name: amenityTitle
          };
          amenity[amenityType === 'utilities' ? 'included' : 'available'] =
            true;

          if (
            amenityType === 'appliances' ||
            amenityType === 'views' ||
            amenityType === 'furnishing'
          ) {
            $scope.listing[amenityType].push(amenity);
          } else {
            $scope.listing[amenityType].types.push(amenity);
          }
        } else {
          $scope.listing.amenities[amenityType].push({
            name: amenityTitle,
            available: true
          });
        }
      };

      $scope.viewPreview = function () {
        ListingPreviewService.openListingPreview($scope.listing);
      };

      $scope.setUploadProgress = function (progress) {
        $scope.uploadProgress = progress;
      };

      $scope.setSaveListingStatus = function (status) {
        if ($scope.saveListingStatus === status) {
          return;
        }

        if (self._processingInterval) {
          $interval.cancel(self._processingInterval);
        }

        $scope.saveListingStatus = status;

        switch (status) {
          case 'saving':
            $scope.saveStatusText = 'Saving listing...';

            self._startProcessingInterval();
            break;
          case 'saving-error':
            $scope.isSaving = false;
            var alert = $mdDialog
              .alert()
              .title('An error occurred while saving your listing.')
              .content('Please try again, or contact support')
              .ok('OK');

            $mdDialog.show(alert);
            $interval.cancel(self._processingInterval);
            break;
          case 'posting-error':
            $scope.isSaving = false;
            var alert = $mdDialog
              .alert()
              .title('An error occurred while posting your listing.')
              .content(
                'Your current progress has been saved. Please try again, or contact support.'
              )
              .ok('OK');

            $mdDialog.show(alert);
            $interval.cancel(self._processingInterval);
            break;
        }
      };

      self._startProcessingInterval = function () {
        self._processingInterval = $interval(function () {
          if ($scope.uploadProgress < 95) {
            $scope.uploadProgress++;
          } else {
            $interval.cancel(self._processingInterval);
          }
        }, 400);
      };

      // Redirect to 404 page if New Units flag is on, else initialize
      appDataService.newUnitsRedirectTo404(self._initialize);
    }
  ]);
})();
