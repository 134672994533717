import React, { FC } from 'react';

export const LeaseLengthIcon: FC = () => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.63665 12.0775C9.36004 11.3301 10.5475 11.2961 11.3125 12.0008C12.0167 12.7657 11.9827 13.9525 11.2358 14.6758C10.5017 15.4117 7.27582 16.0358 7.27582 16.0358C7.27582 16.0358 7.90248 12.8117 8.63665 12.0775Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6083 5.64L14.6408 2.6075C14.8706 2.37774 15.1822 2.24866 15.5071 2.24866C15.832 2.24866 16.1436 2.37774 16.3733 2.6075L17.24 3.47416"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3083 10.405L17.24 3.47415C17.9587 2.75894 19.1203 2.75894 19.8391 3.47415V3.47415C20.5534 4.19331 20.5534 5.35415 19.8391 6.07331L12.9075 13.0041L10.3083 10.405Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6408 6.07333L17.24 8.6725"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6833 12.25C18.6833 15.6175 19.0375 17.5208 17.2183 19.34L16.275 20.2825C16.2159 20.3408 16.1363 20.3734 16.0533 20.3733H1.93748C1.81117 20.3732 1.69735 20.2971 1.64903 20.1804C1.6007 20.0637 1.62739 19.9294 1.71665 19.84L2.21665 19.34C4.10415 17.4525 3.68331 16.245 3.68331 6.19499C3.68331 3.57165 5.63998 2.16665 6.18331 1.62332H9.88998"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
