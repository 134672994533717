import moment from 'moment';
import each from 'lodash/each';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import filter from 'lodash/filter';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
(function (angular) {
  var app = angular.module('knockApp');

  app.factory('prospectAppointmentModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openAddProspectAppointmentModal = function (
        prospectId,
        startTime,
        endTime
      ) {
        return $mdDialog.show({
          controller: 'ProspectAppointmentModalController',
          templateUrl:
            '/angular/views/prospects/prospect-appointment-modal.html',
          clickOutsideToClose: false,
          locals: {
            prospectId: prospectId,
            startTime: startTime,
            endTime: endTime
          }
        });
      };

      self.openAddProspectVisitModal = function (
        prospect,
        visitTime,
        appointmentId
      ) {
        return $mdDialog.show({
          controller: 'ProspectVisitModalController',
          templateUrl: '/angular/views/prospects/prospect-visit-modal.html',
          clickOutsideToClose: true,
          locals: {
            prospect: prospect,
            visitTime: visitTime,
            appointmentId: appointmentId
          }
        });
      };

      return self;
    }
  ]);

  const ProspectVisitModalController = function (
    $rootScope,
    $scope,
    $q,
    $mdDialog,
    $mdToast,
    appDataService,
    visitsService,
    propertyApi,
    prospectsApi,
    timeService,
    prospect,
    visitTime,
    appointmentId
  ) {
    var self = this;

    $scope.viewStates = {
      loading: 'loading',
      saving: 'saving',
      visit: 'visit',
      addShownUnits: 'add-shown-units'
    };

    $scope.state = {
      useNewUnitsFeature: $rootScope.featureFlags.NEW_UNITS || false,
      view: $scope.viewStates.visit,
      prospect: prospect,
      property: find(appDataService.getProperties(), function (property) {
        return property.Property.id === parseInt(prospect.property_id, 10);
      }).Property,
      appointmentId: appointmentId,
      teamMembers: appDataService.getTeamMembers(),
      managerId: prospect ? prospect.assigned_manager_id : null,
      timeOptions: [],
      propertyUnitsData: {
        inputs: [],
        outputs: []
      },
      visit: {
        date: visitTime,
        time: visitTime
      },
      visitPropertyUnitsData: []
    };

    $scope.textConfig = {
      reset: 'Clear',
      nothingSelected: 'Shown units (optional)'
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.saveVisit = function () {
      $scope.state.view = $scope.viewStates.saving;

      var formattedDate = moment($scope.state.visit.date).format('YYYY-MM-DD');
      var formattedTime = moment($scope.state.visit.time).format('HH:mmZ');

      var visitTime = formattedDate + 'T' + formattedTime;

      if ($scope.state.property.preferences.require_prospect_floorplan) {
        prospectsApi
          .updateProspect($scope.state.prospect.id, {
            preferences: $scope.state.prospect.preferences
          })
          .then(
            function () {
              self._createVisit(visitTime);
            },
            function () {
              $mdToast.showSimple('Error adding visit.');
              $scope.state.view = $scope.viewStates.visit;
            }
          );
      } else {
        self._createVisit(visitTime);
      }
    };

    self._createVisit = function (visitTime) {
      visitsService
        .createVisit(
          $scope.state.prospect.id,
          visitTime,
          $scope.state.appointmentId,
          $scope.state.managerId
        )
        .then(
          function (response) {
            $mdDialog.hide(response.data.visit);
          },
          function () {
            $mdToast.showSimple('Error adding visit.');
            $scope.state.view = $scope.viewStates.visit;
          }
        );
    };
  };

  ProspectVisitModalController.$inject = [
    '$rootScope',
    '$scope',
    '$q',
    '$mdDialog',
    '$mdToast',
    'appDataService',
    'visitsService',
    'propertyApi',
    'prospectsApi',
    'timeService',
    'prospect',
    'visitTime',
    'appointmentId'
  ];
  app.controller('ProspectVisitModalController', ProspectVisitModalController);

  app.directive('firstContactDateTimeSelect', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/prospects/first-contact-date-time-select.html',
      scope: {
        firstContactTime: '=',
        firstContactDate: '=',
        firstContactType: '='
      },
      controller: [
        '$scope',
        '$moment',
        'timeService',
        function ($scope, $moment, timeService) {
          var self = this;

          $scope.maxDate = $moment().format();

          $scope.$watch('localFirstContactDate', function (newDate) {
            if (newDate) {
              self.initialize();
            }
          });

          $scope.state = {
            timeOptions: []
          };

          self.initialize = function () {
            $scope.state.timeOptions = [];

            timeService.getActiveTimezone().then(function (timezone) {
              $scope.state.timeOptions = self._generateTimeOptions(timezone.id);
            });

            if ($scope.firstContactTime) {
              var formattedTime = timeService
                .get($scope.firstContactTime)
                .format()
                .split('T')[1];

              $scope.firstContactTime = find(
                $scope.state.timeOptions,
                function (timeOption) {
                  return timeOption.format().split('T')[1] === formattedTime;
                }
              );
            }
          };

          $scope._onDateChange = function () {
            if (!$scope.localFirstContactDate) {
              return;
            }
            /**
             * Since the selected date will be local to the users machine, we will want to parse it using
             * moment which will place it on the select timezone for the users account.
             */
            var selectedDate = new Date($scope.localFirstContactDate);
            var selectedMoment = timeService.get();
            selectedMoment.year(selectedDate.getFullYear());
            selectedMoment.month(selectedDate.getMonth());
            selectedMoment.date(selectedDate.getDate());
            selectedMoment.hours(0);
            selectedMoment.minutes(0);
            selectedMoment.seconds(0);

            $scope.firstContactDate = selectedMoment.format();
          };

          self._generateTimeOptions = function () {
            /**
             * Format selected date in mm/dd/yyyy
             */
            var selectedFormattedDate = timeService
              .get($scope.localFirstContactDate)
              .format('MM/DD/YYYY');
            /**
             * Format current date into similar format as our selected date.
             */
            var currentFormattedDate = timeService.get().format('MM/DD/YYYY');
            var timeSlots = [];
            var timeWalker = timeService.get().startOf('day').hour(7);
            /**
             * If our current date is the same as the selected date, then we cut off time selection at our current time.
             */

            var todayEnd =
              selectedFormattedDate === currentFormattedDate
                ? timeService.get()
                : timeService.get().startOf('day').hour(22);

            while (timeWalker.isBefore(todayEnd)) {
              timeSlots.push(timeService.clone(timeWalker));
              timeWalker.add(5, 'minutes');
            }

            return timeSlots;
          };

          self.initialize();
        }
      ]
    };
  });

  const ProspectAppointmentModalController = function (
    $rootScope,
    $scope,
    $q,
    $routeParams,
    $interpolate,
    $mdDialog,
    $mdToast,
    appDataService,
    timeService,
    userService,
    conversationsService,
    communityApi,
    teamApi,
    appointmentsApi,
    calendarApiService,
    prospectsApi,
    propertyApi,
    searchApi,
    contactTypes,
    prospectId,
    startTime,
    endTime,
    unitApi
  ) {
    var self = this;

    self._currentManager = userService.getScopedUser();

    $scope.viewState = {
      useNewUnitsFeature: $rootScope.featureFlags.NEW_UNITS || false,
      hideAddTour: false,
      hideEmail: false,
      hidePhone: false,
      properties: {},
      floorplans: [],
      contactTypes: contactTypes.get(),
      isGuest: false,
      isMissingPhoneNumber: true,
      isMissingEmail: true
    };
    $scope.data = {
      prospect_id: prospectId,
      manager_id: self._currentManager.id,
      property: null,
      listing_id: null,
      first_contact_type: null,
      source: null,
      appointment: {
        date: null,
        start_time: null,
        end_time: null
      },
      profile: {
        first_name: '',
        last_name: '',
        phone: {},
        email: '',
        target_move_date: null
      },
      preferences: {
        // TODO: remove references to legacy property floorplan field
        preferred_property_floorplan_id: null,
        preferred_layout_id: null
      }
    };

    self.shouldClearEmail = true;
    self.shouldClearPhone = true;

    $scope.ignoreDuplicateProspectModal = false;

    $scope.cancel = function () {
      $mdDialog.cancel();
      if ($rootScope.tour_drawer_opened) {
        $rootScope.$emit('openCompleteTourDrawer');
      }
    };

    $scope.checkForPropertyDuplicates = function () {
      $scope.checkForPhoneDuplicates();
      $scope.checkForEmailDuplicates();
    };

    $scope.checkForPhoneDuplicates = function () {
      if (
        isEmpty($scope.data.profile.phone.phone_number) ||
        isEmpty($scope.data.property)
      ) {
        return;
      }

      var phoneNumber = $scope.data.profile.phone.phone_number;
      $scope.checkingForPhoneDuplicates = !$scope.ignoreDuplicateProspectModal;

      searchApi
        .prospectSearchByProperty($scope.data.property.id, phoneNumber)
        .success(self.handleDuplicatesSearchSuccess);
    };

    $scope.checkForEmailDuplicates = function () {
      if (isEmpty($scope.data.profile.email) || isEmpty($scope.data.property)) {
        return;
      }

      var email = $scope.data.profile.email;
      $scope.checkingForEmailDuplicates = !$scope.ignoreDuplicateProspectModal;

      searchApi
        .prospectSearchByProperty($scope.data.property.id, email)
        .success(self.handleDuplicatesSearchSuccess);
    };

    self.handleDuplicatesSearchSuccess = function (response) {
      var getProspectProperty = function (prospect) {
        if (!prospect.property_id) {
          return;
        }

        var findProperties = function (properties) {
          return find(properties, { id: prospect.property_id });
        };

        return (
          findProperties($scope.viewState.properties.multiFamily) ||
          first(
            $scope.viewState.properties.singleFamily,
            function (managerProperties) {
              return findProperties(managerProperties.properties);
            }
          )
        );
      };

      var doesNotHaveInactiveProperty = function (prospect) {
        return !prospect.property_id || !isEmpty(prospect.property);
      };

      var mapDuplicate = function (result) {
        var prospect = result.hit;
        prospect.property = getProspectProperty(prospect);

        return prospect;
      };

      $scope.duplicates = filter(
        map(response.results.hits, mapDuplicate),
        doesNotHaveInactiveProperty
      );
      $scope.mergeProspect = $scope.data.property
        ? find($scope.duplicates, { property_id: $scope.data.property.id })
        : undefined;
      $scope.showDuplicateModal = !(
        $scope.ignoreDuplicateProspectModal || isEmpty($scope.duplicates)
      );

      $scope.checkingForPhoneDuplicates = false;
      $scope.checkingForEmailDuplicates = false;
    };

    $scope.openProspectTab = function (prospect) {
      window.open('/conversation/' + prospect.stream_id, '_blank');
    };

    $scope.useDuplicateProspect = function (prospect) {
      $scope.viewState.isLoading = true;
      $scope.viewState.isLoadingDuplicate = true;

      prospectsApi.getProspect(prospect.id).success(function (response) {
        self._initProspectData(response.prospect);

        $scope.showDuplicateModal = false;
        $scope.viewState.isLoading = false;
      });
    };

    $scope.ignoreDuplicateProspect = function () {
      $scope.ignoreDuplicateProspectModal = true;
      $scope.showDuplicateModal = false;
    };

    $scope.createProspect = async function () {
      if (self._isInvalidFirstName()) {
        $mdToast.showSimple('First name is invalid!');
        return;
      }
      try {
        $scope.viewState.isCreating = true;
        var createPromise = $scope.viewState.hideAddTour
          ? self._createProspectNoTour()
          : self._createProspectWithTour();
        var response = await createPromise;
        var prospect = response.data.appointment
          ? response.data.appointment.prospect
          : response.data.prospect;
        self._createProspectSuccess(prospect);

        if ($rootScope.tour_drawer_opened) {
          $rootScope.$emit('openCompleteTourDrawer');
        }
      } catch (err) {
        $mdToast.showSimple('Error booking appointment', err);
        console.error(err);
      } finally {
        $scope.viewState.isCreating = false;
      }
    };

    self._createProspectNoTour = function () {
      var payload = {
        profile: {
          first_name: $scope.data.profile.first_name,
          last_name: $scope.data.profile.last_name,
          phone: $scope.data.profile.phone,
          email: $scope.data.profile.email,
          target_move_date: $scope.data.profile.target_move_date
        },
        preferences: {
          // TODO: remove references to legacy property floorplan field
          preferred_property_floorplan_id:
            $scope.data.preferences.preferred_property_floorplan_id,
          preferred_layout_id: $scope.data.preferences.preferred_layout_id
        },
        property_id: $scope.data.property.id,
        manager_id: $scope.data.manager_id,
        source: $scope.data.source,
        first_contact_type: $scope.data.first_contact_type
      };

      return $scope.mergeProspect
        ? prospectsApi.updateProspect($scope.mergeProspect.id, payload)
        : prospectsApi.createNewProspect(payload);
    };

    self._createProspectWithTour = function () {
      var startTime = timeService.combineDateAndTime(
        $scope.data.appointment.date,
        $scope.data.appointment.start_time
      );
      var endTime = timeService.combineDateAndTime(
        $scope.data.appointment.date,
        $scope.data.appointment.end_time
      );

      const tourType = $scope.data.appointment.tour_type;

      var prospectProfile = {
        first_name: $scope.data.profile.first_name,
        last_name: $scope.data.profile.last_name,
        phone: $scope.data.profile.phone,
        email: $scope.data.profile.email,
        target_move_date: $scope.data.profile.target_move_date
      };

      return calendarApiService.addManualAppointment(
        $scope.data.manager_id,
        $scope.data.property.id,
        prospectProfile,
        startTime,
        endTime,
        null,
        null,
        $scope.data.source,
        $scope.data.prospect_id,
        $scope.data.first_contact_type,
        null,
        $scope.data.preferences,
        tourType
      );
    };

    self._createProspectSuccess = function (prospect) {
      $mdDialog.hide(prospect);
      $scope.data.prospect_id = prospect.id;
    };

    $scope.toggleEmail = function () {
      self.shouldClearEmail = !self.shouldClearEmail;
      self.stashedEmail = self.shouldClearEmail
        ? $scope.data.profile.email
        : '';
      $scope.data.profile.email = self.shouldClearEmail
        ? ''
        : self.stashedEmail;
    };

    $scope.togglePhone = function () {
      self.shouldClearPhone = !self.shouldClearPhone;
      self.stashedPhone = self.shouldClearPhone
        ? $scope.data.profile.phone
        : {};
      $scope.data.profile.phone = self.shouldClearPhone
        ? {}
        : self.stashedPhone;
    };

    $scope.sortAfterCurrentManager = function (manager) {
      if (manager.id === self._currentManager.id) {
        return -1;
      }

      return manager.name;
    };

    $scope.startTimeChanged = function () {
      if (
        !$scope.data.appointment.end_time ||
        $scope.data.appointment.start_time.isSameOrAfter(
          $scope.data.appointment.end_time
        )
      ) {
        $scope.data.appointment.end_time = find(
          $scope.viewState.timeChoices,
          function (timeChoice) {
            return timeChoice.isSame(
              timeService
                .get($scope.data.appointment.start_time)
                .add(15, 'minutes')
            );
          }
        );
      }
    };

    $scope.endTimeChanged = function () {
      if (
        $scope.data.appointment.start_time.isSameOrAfter(
          $scope.data.appointment.end_time
        )
      ) {
        $scope.data.appointment.start_time = find(
          $scope.viewState.timeChoices,
          function (timeChoice) {
            return timeChoice.isSame(
              timeService
                .get($scope.data.appointment.end_time)
                .subtract(15, 'minutes')
            );
          }
        );
      }
    };

    $scope.propertyChanged = function () {
      $scope.mergeProspect = null;
      $scope.checkForPropertyDuplicates();

      self._getFloorplans();
    };

    self._initializeTourType = function () {
      const property = $scope.data.property;
      if (property && property.preferences) {
        // A tour is automatically a live video tour where in-person tours have been disabled
        $scope.inPersonEnabled =
          property.preferences.preferences.in_person_tours !== false;
        $scope.videoEnabled =
          !!property.preferences.preferences.live_video_tour_type;
        $scope.selfGuidedEnabled =
          !!property.preferences.preferences.self_guided_tours_enabled;

        $scope.data.appointment.tour_type = null;
        if ($scope.inPersonEnabled) {
          $scope.data.appointment.tour_type = null;
        } else if ($scope.videoEnabled) {
          $scope.data.appointment.tour_type = 'live_video';
        } else if ($scope.selfGuidedEnabled) {
          $scope.data.appointment.tour_type = 'self_guided';
        }
      }
    };

    self._initialize = function () {
      $scope.viewState.isLoading = true;
      $scope.viewState.timeChoices = self._generateTimeSelections();

      if (!startTime) {
        // Round start time to nearest 5 minute interval
        startTime = timeService.get();
        startTime.add('minutes', 5 - (startTime.minute() % 5));
      }

      if (startTime) {
        var startTimeMoment = timeService.get(startTime);

        $scope.data.appointment.start_time = find(
          $scope.viewState.timeChoices,
          function (timeChoice) {
            return (
              startTimeMoment.hour() === timeChoice.hour() &&
              startTimeMoment.minute() === timeChoice.minute()
            );
          }
        );

        if ($scope.data.appointment.start_time) {
          $scope.startTimeChanged();
        }

        $scope.data.appointment.date = startTimeMoment.format();
      }

      if (endTime) {
        var endTimeMoment = timeService.get(endTime);

        $scope.data.appointment.end_time = find(
          $scope.viewState.timeChoices,
          function (timeChoice) {
            return (
              endTimeMoment.hour() === timeChoice.hour() &&
              endTimeMoment.minute() === timeChoice.minute()
            );
          }
        );

        if ($scope.data.appointment.end_time) {
          $scope.endTimeChanged();
        }
      }

      var initPromises = [];

      if ($scope.data.prospect_id) {
        initPromises.push(prospectsApi.getProspect($scope.data.prospect_id));
      }

      $q.all(initPromises)
        .then(function (response) {
          $scope.viewState.teamMembers = appDataService.getTeamMembers();

          var properties = appDataService.getProperties();

          var sfProperties = map(
            filter(properties, function (property) {
              return property.ManagerListing && !property.ManagerCommunity;
            }),
            self._mapSfProperty
          );

          var mfProperties = map(
            filter(properties, function (property) {
              return property.ManagerCommunity;
            }),
            self._mapMfProperty
          );

          $scope.viewState.properties = {
            multiFamily: mfProperties,
            singleFamily: reduce(
              sfProperties,
              function (result, property) {
                result[property.manager.Manager.id] = result[
                  property.manager.Manager.id
                ] || {
                  manager: property.manager,
                  properties: []
                };
                result[property.manager.Manager.id].properties.push(property);

                return result;
              },
              {}
            )
          };

          if (response.length > 0) {
            var prospect = response[0].data.prospect;

            self._initProspectData(prospect);

            if (!$scope.data.property) {
              forEach(
                $scope.viewState.properties.singleFamily,
                function (managerProperties) {
                  $scope.data.property = find(
                    managerProperties.properties,
                    function (sfProperty) {
                      return sfProperty.id === $scope.data.property_id;
                    }
                  );
                }
              );
            }
          }

          self._initializeTourType();
        })
        .finally(function () {
          if (
            !$scope.viewState.hasPropertyAssigned &&
            $scope.viewState.properties.multiFamily.length === 1
          ) {
            $scope.data.property = $scope.viewState.properties.multiFamily[0];
            $scope.propertyChanged();
          }

          $scope.viewState.isLoading = false;
        });
    };

    self._initProspectData = function (prospect) {
      $scope.data.prospect_id = prospect.id;
      $scope.data.profile.first_name = prospect.profile.first_name;
      $scope.data.profile.last_name = prospect.profile.last_name;
      $scope.data.profile.email = prospect.profile.email;
      $scope.data.profile.phone = prospect.profile.phone;
      $scope.data.manager_id = prospect.assigned_manager_id;
      $scope.data.source = prospect.source;
      $scope.data.first_contact_type = prospect.first_contact_type;
      $scope.data.preferences = prospect.preferences;

      if (prospect.property_id) {
        if (prospect.property.type === 'multi-family') {
          $scope.data.property = find($scope.viewState.properties.multiFamily, {
            id: prospect.property_id
          });
        } else {
          each(
            $scope.viewState.properties.singleFamily,
            function (managerProperties) {
              $scope.data.property =
                $scope.data.property ||
                find(managerProperties.properties, {
                  id: prospect.property_id
                });
            }
          );
        }
      }

      $scope.viewState.isGuest = self._isInvalidFirstName();
      $scope.viewState.isMissingPhoneNumber =
        isEmpty($scope.data.profile.phone) ||
        !$scope.data.profile.phone.phone_number;
      $scope.viewState.isMissingEmail = !$scope.data.profile.email;
      $scope.viewState.hasPropertyAssigned = !isEmpty($scope.data.property);

      if ($scope.viewState.hasPropertyAssigned) {
        self._getFloorplans();
      }
    };

    self._mapMfProperty = function (property) {
      var propertyManager = self._getTeamMemberByManagerId(
        property.Property.owning_manager_id
      );

      return {
        manager: propertyManager,
        preferences: property.Property.preferences,
        title: property.Property.data.location.name,
        id: property.Property.id,
        communityId: property.ManagerCommunity.community_id,
        type: 'mf'
      };
    };

    self._mapSfProperty = function (property) {
      var propertyManager = self._getTeamMemberByManagerId(
        property.Property.owning_manager_id
      );

      return {
        manager: propertyManager,
        preferences: property.Property.preferences,
        price: property.Property.data.leasing.monthlyRent,
        title: self._getSingleFamilyListingTitle(property.Property.data),
        id: property.Property.id,
        listingId: property.ManagerListing.listing_id,
        type: 'sf'
      };
    };

    self._getFloorplans = function () {
      $scope.isLoading = true;
      if ($scope.viewState.useNewUnitsFeature) {
        return unitApi
          .getLayouts($scope.data.property.id)
          .then((response) => {
            $scope.viewState.floorplans = response.data.layouts;
          })
          .catch(() => $mdToast.showSimple('Error getting floorplans.'))
          .finally(() => ($scope.isLoading = false));
      } else {
        return propertyApi
          .getPropertyFloorplans($scope.data.property.id)
          .then(function (response) {
            $scope.viewState.floorplans = response.data.floorplans;
          });
      }
    };

    self._getTeamMemberByManagerId = function (managerId) {
      return find($scope.viewState.teamMembers, function (member) {
        return member.Manager.id === managerId;
      });
    };

    self._generateTimeSelections = function () {
      var timeSlots = [];
      var timeWalker = timeService.get().startOf('day').hour(7);
      var todayEnd = timeService.get().startOf('day').hour(22);

      while (timeWalker.isBefore(todayEnd)) {
        timeSlots.push(timeService.clone(timeWalker));
        timeWalker.add(5, 'minutes');
      }

      return timeSlots;
    };

    self._getSingleFamilyListingTitle = function (listing) {
      var titleExp = $interpolate(
        '{{monthlyRent | noFractionCurrency}} - {{bedrooms | bedrooms}} - {{address}}'
      );

      return titleExp({
        monthlyRent: listing.leasing.monthlyRent,
        bedrooms: listing.floorplan.bedrooms,
        address: listing.location.address.raw
      });
    };

    self._isInvalidFirstName = function () {
      return (
        $scope.data.profile.first_name &&
        ($scope.data.profile.first_name.startsWith('Guest') ||
          $scope.data.profile.first_name.startsWith('New') ||
          $scope.data.profile.first_name.startsWith('SMS') ||
          $scope.data.profile.first_name.startsWith('Phone'))
      );
    };

    self._initialize();
  };

  ProspectAppointmentModalController.$inject = [
    '$rootScope',
    '$scope',
    '$q',
    '$routeParams',
    '$interpolate',
    '$mdDialog',
    '$mdToast',
    'appDataService',
    'timeService',
    'userService',
    'conversationsService',
    'communityApi',
    'teamApi',
    'appointmentsApi',
    'calendarApiService',
    'prospectsApi',
    'propertyApi',
    'searchApi',
    'contactTypes',
    'prospectId',
    'startTime',
    'endTime',
    'unitApi'
  ];
  app.controller(
    'ProspectAppointmentModalController',
    ProspectAppointmentModalController
  );
  app.filter('unitListingAvailability', [
    '$filter',
    function ($filter) {
      return function (unitListing) {
        if (!unitListing) {
          return;
        }

        var monthlyRent = unitListing.listing.leasing.monthlyRent;
        var bedrooms = unitListing.listing.floorplan.bedrooms;
        var bathrooms = unitListing.listing.floorplan.bathrooms;
        var squareFeet = unitListing.listing.floorplan.size;
        var unit = unitListing.property_unit.unit;
        var available = $filter('availableDate')(
          unitListing.listing.leasing.availableDate,
          true
        );

        return (
          $filter('currency')(monthlyRent, '$', 0) +
          ' - ' +
          bedrooms +
          'bd ' +
          bathrooms +
          'ba - ' +
          squareFeet +
          ' sqft - Unit ' +
          unit +
          ' - Avail ' +
          available
        );
      };
    }
  ]);

  app.filter('unitAvailability', [
    '$filter',
    function ($filter) {
      return function (unit) {
        if (!unit) {
          return;
        }

        var monthlyRent = unit.hasOwnProperty('displayPrice')
          ? unit.displayPrice
          : 0;
        var bedrooms = unit.hasOwnProperty('bedrooms') ? unit.bedrooms : 0;
        var bathrooms = unit.hasOwnProperty('bathrooms') ? unit.bathrooms : 0;
        var squareFeet = unit.hasOwnProperty('area') ? unit.area : 0;
        var unitName = unit.hasOwnProperty('name') ? unit.name : 'Unknown';
        var unitAvailableOn = unit.hasOwnProperty('availableOn')
          ? unit.availableOn
          : new Date(2099, 11, 31);
        var available = $filter('availableDate')(unitAvailableOn, true);

        return (
          $filter('currency')(monthlyRent, '$', 0) +
          ' - ' +
          bedrooms +
          'bd ' +
          bathrooms +
          'ba - ' +
          squareFeet +
          ' sqft - Unit ' +
          unitName +
          ' - Avail ' +
          available
        );
      };
    }
  ]);

  app.filter('floorplan', [
    '$filter',
    function ($filter) {
      return function (floorplan) {
        if (!floorplan) {
          return;
        }

        var name = floorplan.name;
        var size = floorplan.area ? floorplan.area : floorplan.size;
        var beds = floorplan.bedrooms;

        return (
          name +
          ' ' +
          (beds ? ' - ' + beds + 'bd' : '') +
          (size ? ' - ' + size + ' sq.ft.' : '')
        );
      };
    }
  ]);
})(window.angular);
