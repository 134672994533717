import React from 'react';

export const ChangeOwnerIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.75 8.5C16.6485 8.5 18.1875 6.96098 18.1875 5.0625C18.1875 3.16402 16.6485 1.625 14.75 1.625C12.8515 1.625 11.3125 3.16402 11.3125 5.0625C11.3125 6.96098 12.8515 8.5 14.75 8.5Z"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0718 12.25C10.7423 11.2466 11.7178 10.4854 12.8541 10.0788C13.9903 9.67228 15.2273 9.6419 16.3821 9.99218C17.537 10.3425 18.5487 11.0548 19.2677 12.0241C19.9867 12.9934 20.3749 14.1681 20.3751 15.375"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.625 12.875V20.375"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.625 19.125H14.125C14.125 18.462 13.8616 17.8261 13.3928 17.3572C12.9239 16.8884 12.288 16.625 11.625 16.625H8.5C8.5 15.962 8.23661 15.3261 7.76777 14.8572C7.29893 14.3884 6.66304 14.125 6 14.125H1.625"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 16.625H8.5"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
