/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('dropdownSelector', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/util/dropdown-selector.html',
      scope: {
        selectionModel: '=',
        items: '='
      },
      controller: [
        '$scope',
        function ($scope) {
          $scope.selectItem = function ($event, item) {
            $event.preventDefault();
            $scope.selectionModel = item;

            $scope.isDropdownOpen = false;
          };
        }
      ]
    };
  });
})();
