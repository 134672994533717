/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.factory('transferResidentModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openTransferResidentModal = function (resident, newManagerId) {
        return $mdDialog.show({
          controller: 'TransferResidentController',
          templateUrl:
            '/angular/views/userInteractions/transfer-resident-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            resident: resident,
            newManagerId: newManagerId
          }
        });
      };

      return self;
    }
  ]);

  const TransferResidentController = function (
    $q,
    $scope,
    $mdDialog,
    $mdToast,
    appDataService,
    resident,
    newManagerId,
    userInteractionsService,
    _,
    $rootScope
  ) {
    var self = this;

    $scope.data = {
      teamMembers: [],
      selectedTeamMember: null
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.tryTransfer = function () {
      var newManagerId = $scope.data.selectedTeamMember.ManagerInfo.manager_id;

      userInteractionsService
        .transferResident($scope.resident.id, newManagerId)
        .then(self._transferSuccess, self._transferError);
    };

    self._transferSuccess = function () {
      $mdToast.showSimple('Transferred!');
      $mdDialog.hide();
      setTimeout(() => {
        $rootScope.$emit('TransferGuestCard', newManagerId);
      }, 1000);
    };

    self._transferError = function () {
      $mdToast.showSimple('Error transferring');
    };

    self._initialize = function () {
      $scope.data.isLoading = true;
      $scope.resident = resident;

      self._setTeamMembers();
    };

    self._setTeamMembers = function () {
      $scope.data.teamMembers = appDataService.getTeamMembers();

      $scope.data.selectedTeamMember = _.find(
        $scope.data.teamMembers,
        function (member) {
          return (
            member.Manager.id ===
            (newManagerId ? newManagerId : $scope.prospect.assigned_manager_id)
          );
        }
      );
    };

    self._initialize();
  };

  TransferResidentController.$inject = [
    '$q',
    '$scope',
    '$mdDialog',
    '$mdToast',
    'appDataService',
    'resident',
    'newManagerId',
    'userInteractionsService',
    '_',
    '$rootScope'
  ];

  app.controller('TransferResidentController', TransferResidentController);
})(window.angular);
