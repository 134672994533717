import { useState, SetStateAction, Dispatch } from 'react';
import { createContainer } from 'unstated-next';
import merge from 'lodash/merge';

import {
  PopupSnackbarType,
  usePopupSnackbar
} from '../../hooks/usePopupSnackbar';

import {
  NearbyCommunitiesSearchPreferences,
  NearbyCommunitiesService,
  NearbyCommunity,
  ReferralError,
  ReferProspectPayload
} from '../../services/nearbyCommunitiesService';
import appointmentService, {
  RequestAppointmentResponse,
  TourType,
  TourTypesEnabled,
  _APIv2TourTypeMap
} from '../../services/appointmentService';

// Utility type; deep partial.
// https://stackoverflow.com/questions/61132262/typescript-deep-partial
type DeepPartial<T> = { [P in keyof T]?: DeepPartial<T[P]> };

/*
 * Functionality to do with fetching nearby communities.
 */
const useFetchingNearbyCommunities = (
  nearbyCommunitiesService: NearbyCommunitiesService,
  sourcePropertyId: number,
  sourceProspectId: number
) => {
  const [isFetchingNearbyCommunities, setIsFetchingNearbyCommunities] =
    useState<boolean>(false);
  const [fetchNearbyCommunitiesError, setFetchNearbyCommunitiesError] =
    useState<string | null>(null);
  const [nearbyCommunities, setNearbyCommunities] = useState<
    NearbyCommunity[] | null
  >(null);
  const fetchNearbyCommunities = async (
    preferences?: NearbyCommunitiesSearchPreferences
  ): Promise<void> => {
    setIsFetchingNearbyCommunities(true);

    try {
      const resp = await nearbyCommunitiesService.getNearbyCommunities(
        sourcePropertyId,
        sourceProspectId,
        preferences
      );
      setNearbyCommunities(resp);
      setFetchNearbyCommunitiesError(null);
    } catch (error) {
      console.error(`Error fetching nearby communities: ${error}`);
      setFetchNearbyCommunitiesError(String(error));
    }

    setIsFetchingNearbyCommunities(false);
  };

  const updateCommunity = (
    propertyId: number,
    communityUpdate: DeepPartial<NearbyCommunity>
  ) => {
    if (nearbyCommunities) {
      const referredCommunityIdx = nearbyCommunities.findIndex(
        (community) => community.property.id === propertyId
      );
      if (referredCommunityIdx >= 0) {
        // Update current community with communityUpdate payload
        const oldCommunity = nearbyCommunities[referredCommunityIdx];

        nearbyCommunities[referredCommunityIdx] = merge(
          oldCommunity,
          communityUpdate
        );
        setNearbyCommunities([...nearbyCommunities]);
      }
    }
  };

  const getPropertyName = (propertyId: number): string | undefined => {
    if (nearbyCommunities) {
      const community = nearbyCommunities.find(
        (community) => community.property.id === propertyId
      );
      if (community) {
        return community.property.name;
      }
    }
    return undefined;
  };

  return {
    fetchNearbyCommunities,
    isFetchingNearbyCommunities,
    fetchNearbyCommunitiesError,
    nearbyCommunities,
    getPropertyName,
    _updateCommunity: updateCommunity
  };
};

/**
 * Functionality for controlling the referral dialog.
 * This hook has helpers for:
 * - Triggering the flow for a particular propertyId
 * - Cancelling the flow
 * - submitting the referral
 */
const useReferralDialog = (
  nearbyCommunitiesService: NearbyCommunitiesService,
  targetPropertyId: number | null,
  setTargetPropertyId: Dispatch<SetStateAction<number | null>>,
  sourceProspectId: number,
  updateCommunity: (
    propertyId: number,
    nearbyCommunity: DeepPartial<NearbyCommunity>
  ) => void,
  triggerAppointmentFlow: ReturnType<
    typeof useAppointmentDialog
  >['triggerAppointmentFlow'],
  popupSnackbar: PopupSnackbarType,
  onReferral: () => void
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isReferring, setIsReferring] = useState(false);

  /**
   * given a target property ID, set the state for the referral dialog to start
   * the referral flow to that property ID.
   */
  const triggerReferralFlow = (propertyId: number) => {
    setTargetPropertyId(propertyId);
    setIsOpen(true);
  };

  /**
   * Close the referral dialog.
   */
  const closeReferralDialog = () => {
    setIsOpen(false);
  };

  /**
   * Submit the referral via the refer prospect endpoint.
   * - If it succeeds, closes the dialog.
   * - If it succeeds and isScheduling is true, it opens the scheduling URL in a new tab.
   * - If it fails, it sets a snackbar error message.
   */
  const submitReferral = async (payload: ReferProspectPayload) => {
    if (targetPropertyId) {
      setIsReferring(true);
      try {
        const resp = await nearbyCommunitiesService.referProspect(
          sourceProspectId,
          targetPropertyId,
          payload
        );
        if (!resp.ok) {
          // Note: When adding another error message, please update the confluence documentation
          // for this feature. https://knockr.atlassian.net/l/c/6cNN1XKd
          switch (resp.error) {
            case ReferralError.ALREADY_EXISTS:
              closeReferralDialog();
              throw Error('Person already exists in this property');
            case ReferralError.BAD_TRANSACTION:
              throw Error('Something went wrong, please try again');
            case ReferralError.INVALID_PROSPECT:
              closeReferralDialog();
              throw Error(
                `This prospect (prospectId: ${sourceProspectId}) is invalid.`
              );
            // Before adding another case: did you read the above documentation?
          }
        } else {
          const data = resp.response;
          // Successful response and wanted to schedule? open it up:
          if (payload.isScheduling) {
            // Open appointment dialog
            triggerAppointmentFlow(
              data.sister_property_prospect_renter_id,
              data.sister_property_prospect_knock_id
            );
          }

          // Close the referral dialog.
          closeReferralDialog();
          // Update this sister property to have been referred
          updateCommunity(targetPropertyId, {
            property: {
              can_not_refer: true,
              can_not_refer_reason: 'prospect_exists'
            }
          });

          // refresh the conversation window
          onReferral();
        }
      } catch (e) {
        popupSnackbar.setMessage(String(e));
      }
      setIsReferring(false);
    } else {
      console.warn('Not submitting referral; targetPropertyId is none');
    }
  };

  return {
    isReferralDialogOpen: isOpen,
    referralTargetPropertyId: targetPropertyId,
    //TODO: remove from return once refactored to index.js
    setTargetPropertyId,
    triggerReferralFlow,
    closeReferralDialog,
    submitReferral,
    popupSnackbar,
    isReferring
  };
};

const useAppointmentDialog = (
  referralTargetPropertyId: number | null,
  popupSnackbar: PopupSnackbarType
) => {
  const [sisterRenterId, setSisterRenterId] = useState<number | null>(null);
  const [sisterProspectKnockId, setSisterProspectKnockId] = useState<
    string | null
  >(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSchedulingAppointment, setIsSchedulingAppointment] =
    useState<boolean>(false);
  const [availableTimes, setAvailableTimes] = useState<string[] | null>(null);
  const [tourType, setTourType] = useState<TourType | null>(null);
  const [tourTypesEnabled, setTourTypesEnabled] =
    useState<TourTypesEnabled | null>(null);

  const triggerAppointmentFlow = (
    sisterRenterId: number,
    sisterProspectKnockId: string
  ) => {
    setSisterRenterId(sisterRenterId);
    setSisterProspectKnockId(sisterProspectKnockId);
    setIsOpen(true);
  };
  const cancelAppointmentFlow = () => {
    setIsOpen(false);
  };

  const getAvailableTimes = async (tourType: TourType | null) => {
    if (!referralTargetPropertyId) {
      console.error(
        'referralTargetPropertyId should not be null when opening appointment dialog'
      );
      return;
    }
    if (!sisterRenterId) {
      console.error(
        'sisterRenterId should not be null when opening appointment dialog'
      );
      return;
    }
    if (!tourType) {
      console.error(
        'tour type should not be null when opening appointment dialog'
      );
      return;
    }
    try {
      const resp =
        await appointmentService.getAvailableAppointmentsTimeForProperty(
          referralTargetPropertyId,
          tourType,
          sisterRenterId
        );
      setAvailableTimes(resp);
    } catch (e) {
      popupSnackbar.setMessage('Failed to fetch available times');
    }
  };

  /**
   * Gets the Tour Types available for by propertyId
   * @param propertyId
   * @returns the tour types for the respective property from property.preferences
   */
  const getTourTypesEnabled = async (propertyId: number | null) => {
    if (!propertyId) {
      console.error(
        'propertyId should not be null when openening appointment dialog'
      );
      popupSnackbar.setMessage('Failed to fetch tour types enabled');
      return;
    }
    try {
      const tourTypesEnabled = await appointmentService.getTourTypesEnabled(
        propertyId
      );
      let tourType: TourType = TourType.Agent;

      if (tourTypesEnabled.inPersonToursEnabled) {
        tourType = TourType.Agent;
      } else if (tourTypesEnabled.selfGuidedToursEnabled) {
        tourType = TourType.Self;
      } else if (tourTypesEnabled.liveVideoToursEnabled) {
        tourType = TourType.Video;
      } else {
        cancelAppointmentFlow();
        popupSnackbar.setMessage('No valid tour types are enabled.');
        return;
      }

      setTourType(tourType);
      setTourTypesEnabled(tourTypesEnabled);
    } catch (error) {
      popupSnackbar.setMessage('Failed to fetch tour types enabled');
    }
  };

  /**
   * Triggers an referral appointment request for a given start time.
   * @param startTime ISO String of appointment start time
   */
  const scheduleAppointment = async (startTime: string | null) => {
    if (!referralTargetPropertyId) {
      console.error(
        'referralTargetPropertyId should not be null when opening appointment dialog'
      );
      return;
    }
    if (!sisterProspectKnockId) {
      console.error(
        'sisterRenterId should not be null when opening appointment dialog'
      );
      return;
    }

    if (startTime == null) {
      popupSnackbar.setMessage('Must pick a start time', 'warning');
      return;
    }

    const mappedTourType = tourType ? _APIv2TourTypeMap[tourType] : null;

    setIsSchedulingAppointment(true);
    try {
      const resp = await appointmentService.requestAppointment(
        referralTargetPropertyId,
        sisterProspectKnockId,
        [startTime],
        true,
        mappedTourType
      );
      switch (resp) {
        case RequestAppointmentResponse.NoOp:
          popupSnackbar.setMessage('Unable to create appointment.', 'info');
          break;
        case RequestAppointmentResponse.Confirmed:
          popupSnackbar.setMessage(
            'Confirmed appointment at sister property.',
            'success'
          );
          break;
        case RequestAppointmentResponse.Requested:
          popupSnackbar.setMessage(
            'Requested appointment at sister property.',
            'success'
          );
          break;
      }
      cancelAppointmentFlow();
    } catch (e) {
      popupSnackbar.setMessage(String(e), 'error');
    }
    setIsSchedulingAppointment(false);
  };

  // tour types here, get & set

  return {
    isAppointmentSchedulingDialogOpen: isOpen,
    sisterRenterId,
    sisterProspectKnockId,

    triggerAppointmentFlow,
    cancelAppointmentFlow,
    getAvailableTimes,
    availableTimes,
    tourType,
    setTourType,
    getTourTypesEnabled,
    tourTypesEnabled,
    scheduleAppointment,
    isSchedulingAppointment
  };
};

/*
 * Context builder for all nearby communities functions; referring, fetching, etc.
 */
export interface ContextProps {
  nearbyCommunitiesService: NearbyCommunitiesService;
  sourcePropertyId: number;
  sourceProspectId: number;
  onReferral: () => void;
  setIsConnectedProfilesDialogActive: (isActive: boolean) => void;
  shouldShowConnectedProfiles: boolean;
}

export const useNearbyCommunitiesContext = (props: ContextProps) => {
  const useFetchingNearbyCommunitiesValues = useFetchingNearbyCommunities(
    props.nearbyCommunitiesService,
    props.sourcePropertyId,
    props.sourceProspectId
  );
  const [targetPropertyId, setTargetPropertyId] = useState<number | null>(null);

  // Passing this to useReferralDialog; this is a private method that should not be exposed
  // outside this hook.
  const _updateCommunity = useFetchingNearbyCommunitiesValues._updateCommunity;
  const popupSnackbar = usePopupSnackbar();
  const useAppointmentDialogValues = useAppointmentDialog(
    targetPropertyId,
    popupSnackbar
  );
  const useReferralDialogValues = useReferralDialog(
    props.nearbyCommunitiesService,
    targetPropertyId,
    setTargetPropertyId,
    props.sourceProspectId,
    _updateCommunity,
    useAppointmentDialogValues.triggerAppointmentFlow,
    popupSnackbar,
    props.onReferral
  );
  return {
    ...props,
    ...useFetchingNearbyCommunitiesValues,
    ...useReferralDialogValues,
    ...useAppointmentDialogValues
  };
};

/**
 * unstated-next container code...
 * A container component will use the Provider and a consuming child component
 * will consume the provider with useNearbyCommunities.
 */
export const NearbyCommunitiesContainer = createContainer<
  ReturnType<typeof useNearbyCommunitiesContext>,
  ContextProps
>((props) => {
  if (props === undefined) {
    throw new Error('Must define initial props for NearbyCommunitiesContainer');
  }
  return useNearbyCommunitiesContext(props);
});

export const NearbyCommunitiesProvider = NearbyCommunitiesContainer.Provider;
export const useNearbyCommunities = () => {
  return NearbyCommunitiesContainer.useContainer();
};
