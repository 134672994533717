import React from 'react';

export const UnitLinkIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1119_1297)">
        <path
          d="M6.875 16.4583H4.375C4.04348 16.4583 3.72554 16.3266 3.49112 16.0922C3.2567 15.8578 3.125 15.5398 3.125 15.2083V8.95831"
          stroke="#4A61E8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.625 7.70832L7.9425 1.51665C8.16821 1.32564 8.45432 1.22083 8.75 1.22083C9.04568 1.22083 9.33179 1.32564 9.5575 1.51665L15.6233 6.64915"
          stroke="#4A61E8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.125 1.45831H15.625V3.95831"
          stroke="#4A61E8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.1549 17.1899L13.3557 17.9882C13.0591 18.27 12.6629 18.4226 12.2539 18.4126C11.8449 18.4027 11.4566 18.2308 11.1741 17.9349L10.6149 17.3757C10.319 17.0933 10.1472 16.7049 10.1372 16.2959C10.1272 15.887 10.2798 15.4907 10.5616 15.1941L12.8432 12.9124C13.0974 12.6707 13.4371 12.5397 13.7877 12.5483C14.1384 12.5569 14.4713 12.7043 14.7132 12.9582L15.1524 13.3974"
          stroke="#4A61E8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.8065 10.9474L15.6448 10.1141C15.9416 9.81815 16.3436 9.65198 16.7627 9.65198C17.1818 9.65198 17.5838 9.81815 17.8806 10.1141L18.439 10.6724C18.7349 10.9692 18.901 11.3712 18.901 11.7903C18.901 12.2094 18.7349 12.6114 18.439 12.9082L16.044 15.2999C15.9182 15.4259 15.7688 15.5258 15.6044 15.594C15.4399 15.6622 15.2637 15.6973 15.0856 15.6973C14.9076 15.6973 14.7313 15.6622 14.5669 15.594C14.4025 15.5258 14.2531 15.4259 14.1273 15.2999L13.689 14.8641"
          stroke="#4A61E8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1119_1297">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
