import React from 'react';

export const BuildingIcon = () => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.875 4V19.6"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.125 4H17.875"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.125 19.6V4"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.875 19.6H4.125"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.875 4.00001H4.125L5.375 1.60001H16.625L17.875 4.00001V4.00001Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.625 19.6H20.375"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.875 17.8C12.875 16.8059 12.0355 16 11 16C9.96447 16 9.125 16.8059 9.125 17.8V19.6H12.875V17.8Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.25 6.10001C7.42259 6.10001 7.5625 6.23432 7.5625 6.40001"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9375 6.40001C6.9375 6.23432 7.07741 6.10001 7.25 6.10001"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.25 6.69999C7.07741 6.69999 6.9375 6.56568 6.9375 6.39999"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5625 6.39999C7.5625 6.56568 7.42259 6.69999 7.25 6.69999"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.25 9.70001C7.42259 9.70001 7.5625 9.83433 7.5625 10"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9375 10C6.9375 9.83433 7.07741 9.70001 7.25 9.70001"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.25 10.3C7.07741 10.3 6.9375 10.1657 6.9375 10"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5625 10C7.5625 10.1657 7.42259 10.3 7.25 10.3"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.25 13.3C7.42259 13.3 7.5625 13.4343 7.5625 13.6"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9375 13.6C6.9375 13.4343 7.07741 13.3 7.25 13.3"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.25 13.9C7.07741 13.9 6.9375 13.7657 6.9375 13.6"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5625 13.6C7.5625 13.7657 7.42259 13.9 7.25 13.9"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 6.10001C11.1726 6.10001 11.3125 6.23432 11.3125 6.40001"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6875 6.40001C10.6875 6.23432 10.8274 6.10001 11 6.10001"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 6.69999C10.8274 6.69999 10.6875 6.56568 10.6875 6.39999"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3125 6.39999C11.3125 6.56568 11.1726 6.69999 11 6.69999"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 9.70001C11.1726 9.70001 11.3125 9.83433 11.3125 10"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6875 10C10.6875 9.83433 10.8274 9.70001 11 9.70001"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 10.3C10.8274 10.3 10.6875 10.1657 10.6875 10"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3125 10C11.3125 10.1657 11.1726 10.3 11 10.3"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 13.3C11.1726 13.3 11.3125 13.4343 11.3125 13.6"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6875 13.6C10.6875 13.4343 10.8274 13.3 11 13.3"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 13.9C10.8274 13.9 10.6875 13.7657 10.6875 13.6"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3125 13.6C11.3125 13.7657 11.1726 13.9 11 13.9"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.75 6.10001C14.9226 6.10001 15.0625 6.23432 15.0625 6.40001"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4375 6.40001C14.4375 6.23432 14.5774 6.10001 14.75 6.10001"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.75 6.69999C14.5774 6.69999 14.4375 6.56568 14.4375 6.39999"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0625 6.39999C15.0625 6.56568 14.9226 6.69999 14.75 6.69999"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.75 9.70001C14.9226 9.70001 15.0625 9.83433 15.0625 10"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4375 10C14.4375 9.83433 14.5774 9.70001 14.75 9.70001"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.75 10.3C14.5774 10.3 14.4375 10.1657 14.4375 10"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0625 10C15.0625 10.1657 14.9226 10.3 14.75 10.3"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.75 13.3C14.9226 13.3 15.0625 13.4343 15.0625 13.6"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4375 13.6C14.4375 13.4343 14.5774 13.3 14.75 13.3"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.75 13.9C14.5774 13.9 14.4375 13.7657 14.4375 13.6"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0625 13.6C15.0625 13.7657 14.9226 13.9 14.75 13.9"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
