import axios from 'axios';

import { getAuthToken } from './tokenService';
import { APP_CONFIG } from 'APP_CONFIG';

export interface NextBestActionScoreData {
  prospect_id: number;
  property_id: number;
  pmc_id?: number;
}

export interface NextBestActionScore {
  pmcId: number;
  propertyId: number;
  prospectId: number;
  leadScore: number;
  nba: {
    email: {
      probability: number;
      lift: number;
      liftAdjust: number;
    };
    phone: {
      probability: number;
      lift: number;
      liftAdjust: number;
    };
    text: {
      probability: number;
      lift: number;
      liftAdjust: number;
    };
    wait: {
      probability: number;
      lift: number;
      liftAdjust: number;
    };
  };
  availability: number;
  priority: number;
}

const mapNextBestActionScore = (item: any): NextBestActionScore => {
  return {
    pmcId: item.pmc_id,
    propertyId: item.property_id,
    prospectId: item.prospect_id,
    leadScore: item.lead_score,
    nba: {
      email: {
        probability: item.nba.email.probability,
        lift: item.nba.email.lift,
        liftAdjust: item.nba.email.lift_adjust
      },
      phone: {
        probability: item.nba.phone.probability,
        lift: item.nba.phone.lift,
        liftAdjust: item.nba.phone.lift_adjust
      },
      text: {
        probability: item.nba.text.probability,
        lift: item.nba.text.lift,
        liftAdjust: item.nba.text.lift_adjust
      },
      wait: {
        probability: item.nba.wait.probability,
        lift: item.nba.wait.lift,
        liftAdjust: item.nba.wait.lift_adjust
      }
    },
    availability: item.availability,
    priority: item.priority
  };
};

export const getNbaHotLead = (
  { nba, priority }: NextBestActionScore,
  usePriority: boolean
) => {
  const waitLiftAdjust = nba.wait.liftAdjust;
  const higherLiftAdjust = [
    nba.email.liftAdjust,
    nba.phone.liftAdjust,
    nba.text.liftAdjust
  ].sort((a, b) => (a > b ? -1 : b > a ? 1 : 0))[0];
  if (waitLiftAdjust > higherLiftAdjust) {
    return false;
  }
  if (usePriority) {
    return (
      priority >
      Number(APP_CONFIG.REACT_APP_DEMANDX_HOT_LEAD_PRIORITY_THRESHOLD)
    );
  }
  return (
    higherLiftAdjust >
    Number(APP_CONFIG.REACT_APP_DEMANDX_HOT_LEAD_SCORE_THRESHOLD)
  );
};

export const getNextBestActionScoreByProspect = async (
  data: NextBestActionScoreData
): Promise<NextBestActionScore> => {
  const response = await axios.post(
    `${APP_CONFIG.REACT_APP_DEMANDX_API_BASE_URL}/nba-score/prospects/`,
    data,
    {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'Content-Type': 'application/json'
      }
    }
  );

  return mapNextBestActionScore(response.data);
};

export const getNextBestActionScoreByProspects = async (
  pmcId: number,
  data: NextBestActionScoreData[]
): Promise<NextBestActionScore[]> => {
  const response = await axios.post(
    `${APP_CONFIG.REACT_APP_DEMANDX_API_BASE_URL}/nba-score/prospect-groups/`,
    {
      pmc_id: pmcId,
      prospect: data
    },
    {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
        'Content-Type': 'application/json'
      }
    }
  );

  return response.data.nba_scores.map((item: any) =>
    mapNextBestActionScore(item)
  );
};
