import { api } from './api';

const useUnits = (propertyId: number) => {
  const { useGetUnitsQuery } = api;

  const { data, error } = useGetUnitsQuery(propertyId);
  return {
    units: data,
    error: error
  };
};

export default useUnits;
