import React, { FC, useState, useRef, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  FormHelperText,
  Grow,
  Select,
  Input,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { AddCircleOutline } from '@material-ui/icons';

import {
  makeStyles,
  NamedColors,
  Box,
  Button,
  Text,
  white
} from '@knockrentals/knock-shared-web';
import { colors, useCommonStyles } from '../commonStyles/commonStyles';
import { CircularProgressButton } from '../CircularProgressButton';
import RichTextEditor from '../RichTextEditor';
import { QuickReplyHost } from '../QuickReplies';
import ActiveAttachments from '../ActiveAttachments';
import { useAngularContext } from '../AngularContextProvider';
import {
  BulkAction,
  removeSelectedProspect
} from '../../../../app/features/prospects/selectedProspects';
import { removeSelectedResident } from '../../../../app/features/residents/selectedResidents';
import { Attachment, CCRecipient } from '../../ts/models';
import { AttachmentIcon, SendMessageIcon } from '../icons';
import { useAppDispatch } from '../../../../app/hooks';
import SelectControl, { ISelectItem } from '../SelectControl/SelectControl';

const useStyles = makeStyles(() => ({
  dialogPaperWidth: {
    maxWidth: '858px',
    width: '858px'
  },

  dialogTitle: {
    padding: '16px 16px 0 16px'
  },

  dialogContent: {
    padding: '0 16px'
  },

  recipientContainer: {
    marginTop: '8px',
    borderBottom: `1px solid ${NamedColors.slate[300]}`,
    marginLeft: '5px'
  },

  recipientField: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  recipientFieldLabel: {
    paddingTop: '4px'
  },

  chipContainer: {
    borderBottom: `1px solid ${NamedColors.slate[300]}`,
    paddingBottom: '4px',
    paddingLeft: '4px'
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden'
  },

  chip: {
    backgroundColor: colors.chipBackground,
    color: colors.defaultText,
    fontSize: '13px',
    marginBottom: '8px',
    marginRight: '8px',

    '&:last-child': {
      marginRight: 0
    },

    '& .MuiChip-labelSmall': {
      paddingLeft: '9px',
      paddingRight: '9px'
    }
  },

  chipsSection: {
    backgroundColor: `${white} !important`,
    border: 'none'
  },

  ccContainer: {
    alignItems: 'center',
    display: 'flex',
    marginTop: '8px'
  },

  ccInput: {
    maxHeight: '95px',
    overflowY: 'auto',

    '& .MuiFormControl-root': {
      marginBottom: 0,
      marginRight: 0
    },

    '& .MuiInputBase-root': {
      border: 'none',
      color: colors.defaultText,
      marginTop: '0 !important',
      paddingLeft: '10px',
      paddingRight: 0,

      '& .MuiAutocomplete-input, & .MuiAutocomplete-input:first-child': {
        fontSize: '14px',
        paddingBottom: '9px',
        paddingLeft: '3px',
        paddingTop: '9px'
      }
    },

    '& .MuiInputBase-input': {
      height: 'unset'
    },

    '& .MuiChip-root': {
      fontSize: '13px'
    }
  },

  chipValid: {
    backgroundColor: NamedColors.slate[100],
    color: colors.defaultText,

    '& .MuiChip-deleteIcon': {
      color: NamedColors.denim[500]
    }
  },

  chipInvalid: {
    backgroundColor: NamedColors.apple[100],
    color: NamedColors.apple[900],

    '& .MuiChip-deleteIcon': {
      color: NamedColors.apple[600]
    }
  },

  counter: {
    textAlign: 'right',
    marginRight: '16px'
  },

  ccCount: {
    width: '50px'
  },

  subjectField: {
    width: '100%',
    padding: '2px',

    '& .MuiInputLabel-formControl': {
      top: '23px',
      left: 0,
      color: colors.defaultText,
      fontWeight: 400
    },

    '& .MuiFormLabel-root.Mui-focused': {
      color: `${colors.secondaryText} !important`
    },

    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none'
    },

    '& .MuiFilledInput-underline:after': {
      borderBottom: `1px solid ${NamedColors.slate[300]}`
    },

    '& .MuiFilledInput-underline.Mui-error:after': {
      borderBottom: 'none'
    },

    '& .MuiFilledInput-root': {
      borderColor: NamedColors.slate[300],
      background: NamedColors.slate[50],
      borderBottom: `1px solid ${NamedColors.slate[300]}`,
      margin: 0,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      paddingLeft: 0
    },

    '& .MuiInputBase-input': {
      color: colors.defaultText
    },

    '& .MuiInputBase-formControl.Mui-error': {
      borderBottom: `2px solid ${colors.error}`,
      borderColor: `${colors.error} !important`
    }
  },

  editorContainer: {
    margin: '0px 2px 20px 2px',
    border: `1px solid ${NamedColors.slate[100]}`,
    '& .cke_bottom': {
      borderTop: 'none'
    },
    '& .MuiFormHelperText-root': {
      margin: '0',
      borderTop: `2px solid ${colors.error}`,
      padding: '4px 10px'
    }
  },

  dialogActionsIfComposeEnable: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 16px 16px 16px'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'end',
    padding: '12px 16px 16px 16px'
  },
  actionContainer: {
    display: 'flex',
    gap: '16px'
  },

  cancelButton: {
    border: `1px solid ${NamedColors.denim[600]}`,
    color: NamedColors.denim[600],
    lineHeight: '24px'
  },

  secondaryButton: {
    color: NamedColors.slate[800],

    '& svg': {
      marginRight: '8px'
    },

    '&:focus': {
      color: NamedColors.slate[800],

      '& svg path': {
        stroke: NamedColors.slate[800]
      }
    }
  },

  subTitle: {
    color: colors.secondaryText,
    lineHeight: '20px'
  },
  composeBox: {
    paddingLeft: '10px'
  }
}));

export const MAX_CC_RECIPIENTS = 10;

interface BulkEmailModalProps {
  open: boolean;
  closeModal: (refetch?: boolean, smsOpen?: boolean) => void;
  records: BulkAction[];
  context: string;
  emailDomainBlocklist: string[];
  dialogTitle?: string;
  defaultSubject?: string;
  defaultContent?: string;
  disableCCSupport?: boolean;
  emailFromText?: boolean;
  setSnackMessage: (message: string | null) => void;
}

const BulkEmailModal: FC<BulkEmailModalProps> = ({
  open,
  closeModal,
  records,
  context,
  dialogTitle,
  defaultSubject,
  defaultContent,
  emailDomainBlocklist,
  disableCCSupport,
  emailFromText,
  setSnackMessage
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [subject, setSubject] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [subjectError, setSubjectError] = useState<string | null>(null);
  const [contentError, setContentError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showCCField, setShowCCField] = useState<boolean>(false);
  const [ccRecipients, setCCRecipients] = useState<CCRecipient[]>([]);
  const [ccRecipient, setCCRecipient] = useState<string>('');
  const ccInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [recordsToDisplay, setRecordsToDisplay] = useState<string[]>([]);
  const [composeTextMessage, setComposeTextMessage] = useState<boolean>(false);

  const [sendAs, setSendAs] = useState('owner');

  const sendAsItems: ISelectItem[] = [
    {
      label: 'Prospect Owner',
      value: 'owner'
    },
    {
      label: 'Myself',
      value: 'me'
    }
  ];

  const {
    token,
    mergeTags,
    managerInfo,
    getManagerQuickReplies,
    saveQuickReply,
    deleteQuickReply,
    isCompanyQuickRepliesEnabled,
    handleAttachments,
    deleteAttachment,
    sendBatchMessage,
    cheatProofEngagementScoreEnabled,
    isBulkCommunicationEnhancementEnabled,
    isAgentAttributionEnable
  } = useAngularContext();
  const selectedRecipientNames = new Array<string>();
  const selectedRecipientStreamIds = new Array<string>();
  let usersWithNoEmailCount = 0;
  let usersWithEmailCount = 0;
  let userReceiveTextsOnly = 0;
  let listOfUsersReceiveTextOnly: number[] = [];

  useEffect(() => {
    if (defaultSubject) {
      setSubject(defaultSubject);
    } else {
      setSubject('');
    }

    if (defaultContent) {
      setContent(defaultContent);
    } else {
      setContent('');
    }

    setAttachments([]);
    setShowCCField(false);
    setCCRecipients([]);
    setSubjectError(null);
    setContentError(null);
  }, [open, defaultSubject, defaultContent]);

  records.forEach((item: BulkAction) => {
    if (item.email) {
      selectedRecipientNames.push(item.name);
      selectedRecipientStreamIds.push(item.streamId);
      usersWithEmailCount += 1;
    } else if (item.isOnlyReceiveText) {
      userReceiveTextsOnly += 1;
      listOfUsersReceiveTextOnly.push(item.id);
    } else {
      usersWithNoEmailCount += 1;
    }
  });

  useEffect(() => {
    if (open && selectedRecipientNames.length > 0) {
      setRecordsToDisplay(
        selectedRecipientNames.map((record: string) => record)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const validCCRecipientsCount = ccRecipients.filter(
    (item: CCRecipient) => item.isValid
  ).length;

  const uploadSuccess = (res: any) => {
    const response = JSON.parse(res.target.responseText);
    setAttachments([...attachments, ...response.attachments]);
  };

  const uploadError = () => {
    setSnackMessage('Could not upload attachment. Please try again.');
  };

  const isEmailAddressValid = (emailAddress: string) => {
    // This email address regex pattern is the same one used in messageFormatter.service.js
    const emailPattern =
      '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)])';
    const checkEmailAddress = new RegExp(emailPattern, 'gi');

    return checkEmailAddress.test(emailAddress);
  };

  const isEmailDomainAllowed = (emailAddress: string) => {
    if (!emailDomainBlocklist || emailAddress === null) {
      return true;
    }

    const domain = emailAddress.substring(emailAddress.lastIndexOf('@') + 1);

    return !emailDomainBlocklist.some((pattern: string) =>
      new RegExp(pattern).test(domain)
    );
  };

  const addCCRecipients = (recipients: string[]) => {
    if (recipients.length > 0) {
      const newCCRecipients = [...ccRecipients];
      let formattedRecipient: string;
      let newCCRecipient: CCRecipient;
      let newCCRecipientCount = 0;

      recipients.forEach((recipient: string) => {
        // We want to avoid adding any purely whitespace string to the list
        formattedRecipient = recipient.trim();

        if (formattedRecipient) {
          newCCRecipient = {
            emailAddress: formattedRecipient,
            isValid:
              isEmailAddressValid(formattedRecipient) &&
              isEmailDomainAllowed(formattedRecipient) &&
              !newCCRecipients.find(
                (item: CCRecipient) =>
                  item.emailAddress.toLowerCase() ===
                  formattedRecipient.toLowerCase()
              )
          };

          if (newCCRecipient.isValid) {
            if (
              validCCRecipientsCount + newCCRecipientCount <
              MAX_CC_RECIPIENTS
            ) {
              newCCRecipients.push(newCCRecipient);
              newCCRecipientCount += 1;
            }
          } else {
            newCCRecipients.push(newCCRecipient);
          }
        }
      });

      if (ccInputRef.current) {
        ccInputRef.current.scrollIntoView();
      }

      setCCRecipients(newCCRecipients);
      setCCRecipient('');
    }
  };

  const validInput = () => {
    let isValid = true;

    if (!subject.trim()) {
      setSubjectError('Please add a subject');
      isValid = false;
    } else {
      setSubjectError(null);
    }

    if (!content) {
      setContentError('Please add a message');
      isValid = false;
    } else {
      setContentError(null);
    }

    return isValid;
  };

  const handleShowCCField = () => {
    if (!showCCField) {
      setShowCCField(true);
    }
  };

  const handleChangeCCRecipients = (
    _event: React.ChangeEvent<{}>,
    value: (string | CCRecipient)[],
    _reason: string
  ) => {
    const newCCRecipients = new Array<CCRecipient>();
    let addedRecipients = false;

    value.forEach((recipient: string | CCRecipient) => {
      if (typeof recipient === 'string') {
        if (validCCRecipientsCount < MAX_CC_RECIPIENTS) {
          addCCRecipients([recipient]);
          addedRecipients = true;
        }
      } else {
        newCCRecipients.push(recipient);
        setCCRecipients(newCCRecipients);
      }
    });

    if (!addedRecipients) {
      setCCRecipients(newCCRecipients);
    }
  };

  const handleChangeCCInput = (
    _event: React.ChangeEvent<{}>,
    value: string,
    _reason: string
  ) => {
    let delimiter: string | undefined;

    // If the user pasted in a string of email addresses delimited by a comma or space, we process them immediately. Otherwise we just update the
    // state value. The '> 0' comparison for the index helps ensure we only run this logic if the user pasted text into the field, versus them
    // hitting the Space key after they just typed in an address.
    if (value.indexOf(',') > 0) {
      delimiter = ',';
    } else if (value.indexOf(' ') > 0) {
      delimiter = ' ';
    }

    if (delimiter) {
      addCCRecipients(value.split(delimiter));
    } else {
      setCCRecipient(value);
    }
  };

  const handleCCInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // Add the current address if the user hits the Space key
    if (event.keyCode === 32) {
      addCCRecipients([ccRecipient]);
    }
  };

  const handleCCInputBlur = (_event: React.ChangeEvent<HTMLInputElement>) => {
    // Here we handle the case where the user doesn't hit Enter or the Space key after typing in an address (e.g. pressing Tab, clicking into the
    // rich text input, etc), and we want to capture it before any messages are sent
    if (ccRecipient) {
      addCCRecipients([ccRecipient]);
    }
  };

  const handleChangeSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.currentTarget.value);

    if (event.currentTarget.value) {
      setSubjectError(null);
    }
  };

  const handleChangeContent = (content: string) => {
    setContent(content);

    if (content) {
      setContentError(null);
    }
  };

  const handleAppendText = (message: string, fromQuickReply: boolean) => {
    if (fromQuickReply) {
      setContent(`${content}${message}`);
    } else {
      message = message.replace(/(?:\r\n|\r|\n)/g, '<br />');
      setContent(`${content.trim()}${message}`);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const files: FileList = event.target.files!;

    let filesToUpload: any = files;

    if (files?.length > 0) {
      for (const uploadedFile of filesToUpload) {
        uploadedFile.isLocal = true;
        uploadedFile.mimetype = uploadedFile.type;
        uploadedFile.url = URL.createObjectURL(uploadedFile);
      }
      handleAttachments(filesToUpload, uploadSuccess, undefined, uploadError);
    }
  };

  const handleDeleteAttachment = async (attachment: Attachment) => {
    try {
      await deleteAttachment(attachment.delete_token);

      const newAttachments = [...attachments];
      const deleteIndex: number = newAttachments.findIndex(
        (attachFile: Attachment) =>
          attachFile.delete_token === attachment.delete_token
      );

      newAttachments.splice(deleteIndex, 1);
      setAttachments(newAttachments);

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (err) {
      console.log('handleDeleteAttachment');
    }
  };

  const handleSubmit = async () => {
    try {
      if (!validInput()) {
        return;
      }

      setLoading(true);

      await sendBatchMessage(
        selectedRecipientStreamIds,
        '',
        content,
        subject,
        ['email'],
        attachments,
        '',
        false,
        ccRecipients.flatMap((ccRecipient: CCRecipient) =>
          ccRecipient.isValid ? ccRecipient.emailAddress : []
        ),
        isAgentAttributionEnable,
        isAgentAttributionEnable && sendAs === 'owner'
      );
      const usersToUnselect: number[] = composeTextMessage
        ? records
            .filter((val) => !listOfUsersReceiveTextOnly.includes(val.id))
            .map((user) => user.id)
        : [];

      if (composeTextMessage) {
        if (context === 'Prospects') {
          dispatch(removeSelectedProspect(usersToUnselect));
        } else if (context === 'Residents') {
          dispatch(removeSelectedResident(usersToUnselect));
        }
      }

      const snackBarMessage =
        selectedRecipientStreamIds.length > 1
          ? `Email successfully sent to ${selectedRecipientStreamIds.length} ${
              context === 'Prospects' ? 'prospects' : 'residents'
            }.`
          : `Email successfully sent to ${records[0].name}.`;

      setSnackMessage(snackBarMessage);
      closeModal(true, usersToUnselect.length > 0);
      setComposeTextMessage(false);
    } catch (err) {
      setSnackMessage('Could not send email. Please try again.');
      closeModal();
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    handleClose({}, 'cancel');
  };

  const handleClose = (_event: Object, reason: string) => {
    closeModal(reason === 'submit');
  };

  const updateChipsToDisplay = () => {
    if (recordsToDisplay.length !== selectedRecipientNames.length) {
      return;
    }

    const chipContainer = window.document.getElementById(
      'chip-container-email'
    );
    let visibleChipsWidth = 0;

    if (chipContainer) {
      let firstChipOffsetTop: number = 0;
      let visibleCounter = 1;

      for (let index = 0; index < chipContainer.childNodes.length; index++) {
        const element = chipContainer.childNodes[index] as HTMLDivElement;
        let shouldBreak = false;

        index === 0
          ? (firstChipOffsetTop = element.offsetTop)
          : element.offsetTop === firstChipOffsetTop
          ? visibleCounter++
          : (shouldBreak = true);

        visibleChipsWidth += element.getBoundingClientRect().width;

        if (shouldBreak) {
          break;
        }
      }

      if (visibleCounter !== selectedRecipientNames.length) {
        setRecordsToDisplay((prevState: string[]) => {
          if (chipContainer) {
            const containerWidth = chipContainer.getBoundingClientRect().width;

            if (visibleChipsWidth > containerWidth) {
              visibleCounter--;
            }

            prevState.splice(visibleCounter);
            prevState.push(
              `+${selectedRecipientNames.length - visibleCounter}`
            );
          }

          return [...prevState];
        });
      }
    }
  };

  return (
    <>
      <Dialog
        aria-labelledby="bulk-email-dialog"
        disableEnforceFocus
        open={open}
        classes={{ paperWidthSm: classes.dialogPaperWidth }}
      >
        <DialogTitle className={classes.dialogTitle}>
          {dialogTitle || 'Batch Email'}
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {cheatProofEngagementScoreEnabled && (
            <Box className={commonClasses.bulkAlertBox}>
              <Alert
                severity="warning"
                className={commonClasses.bulkAlertContent}
              >
                Bulk emails do not count as an action taken for{' '}
                {context === 'Prospects' ? ' prospects' : ' residents'}. Only
                individual communication will count towards your engagement
                score.
              </Alert>
            </Box>
          )}
          {isAgentAttributionEnable && (
            <Box className={classes.recipientContainer}>
              <Box>
                <SelectControl
                  id="send-as-select"
                  label="Send as"
                  items={sendAsItems}
                  selectProps={{
                    value: sendAs,
                    inputProps: {
                      'data-testid': 'send-as-input'
                    },
                    onChange: (e) => setSendAs(e.target?.value as string)
                  }}
                  bottomOrigin
                />
              </Box>
            </Box>
          )}

          <Box className={classes.recipientContainer}>
            <Box className={classes.recipientField}>
              <Text variant="body2" className={classes.recipientFieldLabel}>
                To
              </Text>

              {!disableCCSupport && !showCCField && (
                <Button
                  variant="text"
                  onClick={handleShowCCField}
                  className={classes.secondaryButton}
                  data-testid="add-cc-button"
                >
                  <AddCircleOutline /> CC
                </Button>
              )}
            </Box>

            <Box className={classes.chipContainer}>
              <Select
                IconComponent={() => null}
                multiple
                value={recordsToDisplay}
                input={<Input />}
                className={classes.chipsSection}
                renderValue={(selected: any) => (
                  <div id="chip-container-email" className={classes.chips}>
                    {selected.map((value: string, index: number) => {
                      if (index + 1 === selected.length) {
                        updateChipsToDisplay();
                      }

                      return (
                        <Chip
                          key={index}
                          label={value}
                          className={classes.chip}
                          size="small"
                        />
                      );
                    })}
                  </div>
                )}
                disabled
              >
                {recordsToDisplay.map((name: string, index: number) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          {userReceiveTextsOnly > 0 && (
            <FormHelperText
              className={classes.subTitle}
            >{`${userReceiveTextsOnly} ${
              userReceiveTextsOnly > 1 ? `recipients` : `recipient`
            } were excluded because they can only receive texts`}</FormHelperText>
          )}

          {usersWithNoEmailCount > 0 && userReceiveTextsOnly > 0 && (
            <FormHelperText
              className={classes.subTitle}
            >{`${usersWithNoEmailCount} ${
              usersWithNoEmailCount === 1 ? `recipient` : `recipients`
            } were excluded because they cannot receive messages`}</FormHelperText>
          )}

          {usersWithNoEmailCount > 0 && userReceiveTextsOnly === 0 && (
            <FormHelperText
              className={classes.subTitle}
            >{`${usersWithNoEmailCount} recipients were excluded because they cannot receive emails`}</FormHelperText>
          )}

          {showCCField && (
            <Grow in={showCCField}>
              <Box className={classes.ccContainer}>
                <Text variant="body2">CC</Text>

                <Autocomplete
                  multiple={true}
                  data-testid="CCField"
                  options={new Array<CCRecipient>()}
                  value={ccRecipients}
                  freeSolo
                  fullWidth={true}
                  disableClearable={true}
                  className={classes.ccInput}
                  onChange={handleChangeCCRecipients}
                  onInputChange={handleChangeCCInput}
                  renderTags={(
                    value: any[],
                    getTagProps: (arg0: {
                      index: any;
                    }) => JSX.IntrinsicAttributes
                  ) =>
                    value.map((option: CCRecipient, index: any) => {
                      const chipClasses = {
                        root: option.isValid
                          ? classes.chipValid
                          : classes.chipInvalid
                      };

                      return (
                        <Chip
                          key={index}
                          label={option.emailAddress}
                          size="small"
                          {...getTagProps({ index })}
                          classes={chipClasses}
                        />
                      );
                    })
                  }
                  renderInput={(params: any) => {
                    // The Autocomplete component is going to pass various props to the input element being rendered, and some of those we need to
                    // intercept and modify before using them. The updates below provide for required spacing and positioning of the CC count, the
                    // desired handling of the Space key, and disabling the input when the max CC recipients is reached.
                    const newInputProps = { ...params.InputProps };
                    const newInputElementProps = { ...params.inputProps };

                    newInputProps.disableUnderline = true;
                    newInputProps.endAdornment = (
                      <Text
                        variant="caption"
                        className={`${classes.counter} ${classes.ccCount}`}
                        data-testid="CCCount"
                      >
                        {validCCRecipientsCount} / {MAX_CC_RECIPIENTS}
                      </Text>
                    );
                    newInputProps.onKeyDown = handleCCInputKeyDown;
                    newInputProps.onBlur = handleCCInputBlur;
                    newInputElementProps.disabled =
                      validCCRecipientsCount >= MAX_CC_RECIPIENTS;

                    return (
                      <TextField
                        {...params}
                        label=""
                        placeholder={
                          ccRecipients.length === 0 ? 'Add emails' : ''
                        }
                        InputProps={newInputProps}
                        inputProps={newInputElementProps}
                        inputRef={ccInputRef}
                      />
                    );
                  }}
                />
              </Box>
            </Grow>
          )}

          <TextField
            className={classes.subjectField}
            value={subject}
            onChange={handleChangeSubject}
            error={!!subjectError}
            helperText={subjectError}
            variant="filled"
            id="subject"
            label="Subject"
            inputProps={{ 'data-testid': 'email-subject' }}
          />

          <Box className={classes.editorContainer}>
            <RichTextEditor
              content={content}
              onChange={handleChangeContent}
              token={token}
              mergeTags={mergeTags}
              resizeDirection="vertical"
            />
            {contentError && (
              <FormHelperText className="Mui-error">
                {contentError}
              </FormHelperText>
            )}
          </Box>

          {attachments.length > 0 && (
            <ActiveAttachments
              attachments={attachments}
              deleteAttachment={handleDeleteAttachment}
            />
          )}
          <Box>
            <input
              type="file"
              multiple
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />

            <Button
              variant="text"
              className={classes.secondaryButton}
              onClick={() => fileInputRef.current?.click()}
            >
              <AttachmentIcon useNewDesign={true} />
              Attachments
            </Button>

            <QuickReplyHost
              messageMode="email"
              managerInfo={managerInfo}
              mergeTags={mergeTags}
              getManagerQuickReplies={getManagerQuickReplies}
              saveQuickReply={saveQuickReply}
              deleteQuickReply={deleteQuickReply}
              onAppendText={handleAppendText}
              isCompanyQuickRepliesEnabled={isCompanyQuickRepliesEnabled}
            />
          </Box>
        </DialogContent>

        <DialogActions
          className={
            userReceiveTextsOnly > 0
              ? classes.dialogActionsIfComposeEnable
              : classes.dialogActions
          }
        >
          {isBulkCommunicationEnhancementEnabled && userReceiveTextsOnly > 0 && (
            <Box className={classes.composeBox}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="composeRecipient"
                    color="primary"
                    data-testid="compose-sms-checbox"
                    size="medium"
                    checked={composeTextMessage}
                    onChange={(_, checked: boolean) =>
                      setComposeTextMessage(checked)
                    }
                  />
                }
                label={`Compose a text to the ${userReceiveTextsOnly} eligible ${
                  userReceiveTextsOnly === 1 ? `recipient` : `recipients`
                }`}
              />
            </Box>
          )}
          <Box className={classes.actionContainer}>
            <Button
              type="button"
              variant="outlined"
              className={classes.cancelButton}
              onClick={handleCancel}
            >
              Cancel
            </Button>

            <CircularProgressButton
              onClick={handleSubmit}
              shouldShowProgress={loading}
              disabled={!!subjectError || !!contentError}
              progressText={'Sending...'}
              className={`${commonClasses.primaryButton} ${commonClasses.buttonWithIcon}`}
            >
              <SendMessageIcon /> Send ({usersWithEmailCount})
            </CircularProgressButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkEmailModal;
