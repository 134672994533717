/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('smallListingPreview', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/listing/small-listing-preview.html',
      scope: {
        listing: '='
      },
      controller: [
        '$scope',
        '$window',
        function ($scope, $window) {
          if ($scope.listing.coverPhoto) {
            $scope.listingPhoto = $scope.listing.coverPhoto.thumbUrl;
          } else if ($scope.listing.photos.length > 0) {
            $scope.listingPhoto =
              $scope.listing.photos[0].thumbUrl || $scope.listing.photos[0].url;
          } else {
            $scope.listingPhoto = '/images/no-image.png';
          }

          $scope.viewListing = function () {
            if ($scope.listing.id) {
              $window.open('/listing/' + $scope.listing.id);
            }
          };
        }
      ]
    };
  });
})();
