/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('UserLocationLookupApi', [
    '$http',
    function ($http) {
      return {
        getUserLocationData: function () {
          return $http.get('https://www.telize.com/geoip/');
        }
      };
    }
  ]);
})();
