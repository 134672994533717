/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import filter from 'lodash/filter';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectEventAppointmentRequest', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/prospectEvents/prospect-event-appointment-request.html',
      scope: {
        event: '=',
        prospect: '=',
        manager: '=',
        property: '='
      },
      controller: [
        '$scope',
        '$modal',
        'calendarApiService',
        'timeService',
        'confirmationModalFactory',
        function (
          $scope,
          $modal,
          calendarApiService,
          timeService,
          confirmationModalFactory
        ) {
          $scope.viewState = {
            isRequesting: false
          };

          $scope.tryAcceptRequest = function (request) {
            var formattedStartTime = timeService
              .get(request.start_time)
              .format('h:mm a z - ddd, MMM Do');
            var message =
              'Would you like to accept ' +
              $scope.prospect.profile.first_name +
              "'s appointment request for " +
              formattedStartTime +
              '?';

            var modalOptions = {
              cancelClass: 'btn-warning',
              confirmClass: 'btn-primary',
              alertClass: 'alert-success'
            };

            var modal = confirmationModalFactory.createModal(
              'Accept Request',
              message,
              function () {
                var canceledRequests = filter(
                  $scope.event.requested_times,
                  function (canceledRequest) {
                    return canceledRequest.id !== request.id;
                  }
                );

                $scope.isRequesting = true;

                calendarApiService
                  .acceptRequest(request, canceledRequests)
                  .success(function () {
                    $scope.event.isDeleted = true;
                  })
                  .finally(function () {
                    $scope.viewState.isRequesting = false;
                  });
              },
              modalOptions
            );

            $modal.open(modal);
          };
        }
      ]
    };
  });
})(window.angular);
