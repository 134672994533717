/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-PropertyReports');

  app.directive('prospectReportKanbanCard', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/propertyReports/prospect-report-kanban-card.html',
      controller: 'ProspectReportKanbanCardController',
      scope: {
        prospect: '=',
        prospectSelectedChanged: '='
      },
      replace: true
    };
  });

  const ProspectReportKanbanCardController = function ($scope) {
    var self = this;
  };

  ProspectReportKanbanCardController.$inject = ['$scope'];

  app.controller(
    'ProspectReportKanbanCardController',
    ProspectReportKanbanCardController
  );
})(window.angular);
