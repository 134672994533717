/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.directive('userInteractionsCard', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/userInteractions/user-interactions-card.html',
      scope: {
        backgroundColor: '=',
        hideListing: '=?',
        appointment: '='
      },
      controller: 'UserInteractionsCardController'
    };
  });

  app.controller('UserInteractionsCardController', [
    '$q',
    '$scope',
    'propertyApi',
    'prospectsApi',
    'conversationsService',
    function ($q, $scope, propertyApi, prospectsApi, conversationsService) {
      var self = this;

      $scope.openThread = function () {
        var openAsModal = true;
        conversationsService.openThreadById(
          $scope.appointment.stream_id,
          openAsModal
        );
      };

      self._setupScope = function () {
        $scope.propertyData = $scope.appointment.property.data;
        $scope.coverPhoto = $scope.propertyData.coverPhoto
          ? $scope.propertyData.coverPhoto.thumbUrl
          : $scope.propertyData.photos[0].thumbUrl;
      };

      self._initialize = function () {
        $scope.userProfile = $scope.appointment.prospect.profile;

        var setScopeAppointmentProperty = function (propertyId) {
          return propertyApi
            .getProperty(propertyId)
            .success(function (response) {
              $scope.appointment.property = response.property;
            });
        };

        var setAppointmentProspect = function () {
          return prospectsApi
            .getProspect($scope.appointment.prospect_id)
            .success(function (response) {
              $scope.appointment.prospect = response.prospect;

              return setScopeAppointmentProperty(
                $scope.appointment.prospect.property_id
              );
            });
        };

        var getProperty = $scope.appointment.property
          ? $q.when($scope.appointment.property)
          : $scope.appointment.prospect
          ? setScopeAppointmentProperty($scope.appointment.prospect.property_id)
          : setAppointmentProspect();

        getProperty.then(self._setupScope);
      };

      self._initialize();
    }
  ]);
})(window.angular);
