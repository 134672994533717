import React, { useState, useMemo, ChangeEvent } from 'react';
import {
  TextField,
  Grid,
  FormControlLabel,
  Switch,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  DialogActions,
  Box,
  Button,
  DialogContent,
  FormHelperText,
  SvgIcon
} from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { NewCalendarIcon } from '../../icons';
import { makeStyles, Text, NamedColors } from '@knockrentals/knock-shared-web';
import { CircularProgressButton } from '../../CircularProgressButton';
import PhoneInput from '../../PhoneInput/PhoneInput';
import { colors } from '../../commonStyles/commonStyles';
import {
  AddProspectData,
  Layout,
  PropertyLayout,
  Prospect
} from 'app/services/prospects/entities';
import { isDateInvalid } from 'LegacyAngularApp/legacy-angular-app/utilities/timeUtilities';
import { usePropertyLayouts } from 'app/services/prospects/hooks';
import DuplicateDetectionCard from '../DuplicateDetectionCard';
import { useLeasingTeamMembers } from 'app/services/me/leasingTeam/members/hooks';
import { SearchPeopleResult } from 'app/services/me/leasingTeam/members/entities';
import { useAngularContext } from '../../AngularContextProvider';

const { inputError, disabled, defaultText, inputBackground } = colors;

interface PropertyPreferences {
  require_prospect_floorplan?: boolean;
}
export interface Property {
  value: string;
  label: string;
  preferences: PropertyPreferences;
}

export interface Owner {
  value: string;
  label: string;
}

export interface Source {
  value: string;
  label: string;
}

interface ApiResponse {
  data?: SearchPeopleResult;
  error?: unknown;
}

interface ProspectFormProps {
  properties: Property[];
  owners: Owner[];
  sources: Source[];
  handleClose: () => void;
  addProspect: (addProspectPayload: AddProspectData) => void;
  setSnackMessage: (message: string) => void;
  openProspectStreamModal: (streamId: string) => void;
  openUnits: (prospect: Prospect, visit: any) => void;
}

interface FormData {
  firstName: string;
  email?: string;
  phone?: string;
  lastName: string;
  bedrooms: string;
  maxBudget?: number | undefined;
  minBudget?: number | undefined;
  property?: string;
  owner?: string;
  firstContact: string;
  addToWishlist: boolean;
  source: string;
  moveInDate: string;
  floorplan?: string;
}

interface FormErrors {
  firstName?: string;
  phoneOrEmail?: string;
  email?: string;
  phone?: string;
  lastName?: string;
  property?: string;
  owner?: string;
  firstContact?: string;
  source?: string;
  moveInDate?: string;
  floorplan?: string;
  walkinDate?: string;
  walkinTime?: string;
}

interface event {
  value?: any;
  name?: string;
  target: any;
}

interface LayoutOption {
  value: string;
  label: string;
}
interface TimeOption {
  label: string;
  value: string;
}
const ProspectForm = (props: ProspectFormProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      datePicker: {
        margin: '-1px 6px 10px 5px',
        paddingRight: '12px',
        '& .MuiInputLabel-formControl': {
          left: '10px',
          top: '5px',
          fontFamily: 'Open Sans',
          fontSize: '16px',
          fontWeight: 400,
          color: colors.defaultText
        },
        '& .MuiInputBase-formControl.Mui-error': {
          borderBottom: `2px solid ${colors.error}`,
          color: colors.error,
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none'
        },
        '& .MuiInputBase-formControl': {
          borderColor: NamedColors.slate[300],
          background: NamedColors.slate[50],
          height: '56px',
          borderBottom: `1px solid #8D90A5`,
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none'
        },
        '& .MuiFormLabel-root.Mui-focused': {
          top: '20px',
          left: '10px',
          fontSize: '12px',
          color: colors.defaultText
        },
        '& .MuiFormLabel-root.MuiInputLabel-shrink': {
          top: '20px',
          left: '10px',
          fontSize: '12px',
          color: colors.defaultText
        },
        '& .MuiInputBase-input': {
          padding: '23px 0 7px'
        },
        '& .MuiButtonBase-root .MuiSvgIcon-root': {
          color: '#353950'
        },
        '& .MuiIconButton-root': {
          padding: '8px'
        },
        '& .MuiSvgIcon-root': {
          height: '24px',
          width: '24px'
        }
      },
      boxStyle: {
        display: 'flex',
        gap: '15px',
        justifyContent: 'flex-end'
      },
      dialogActions: {
        paddingBottom: '20px',
        paddingRight: '22px'
      },
      subTitle: {
        fontSize: '12px',
        fontWeight: 600,
        color: disabled,
        marginLeft: '7px'
      },
      subTitleContact: {
        fontSize: '12px',
        fontWeight: 600,
        color: disabled,
        marginLeft: '7px',
        marginBottom: '20px'
      },
      subTitlePrefrences: {
        fontSize: '12px',
        fontWeight: 600,
        color: disabled,
        marginLeft: '7px',
        marginTop: '20px'
      },
      textField: {
        border: 'none',
        backgoundColor: inputBackground,
        '& .MuiFilledInput-root': {
          border: 'none',
          backgroundColor: inputBackground,
          '&:hover': {
            backgoundColor: inputBackground
          }
        },
        '& .MuiInputBase-root': {
          border: 'none',
          paddingLeft: '0px'
        },
        top: '-40px',
        height: '40px',
        margin: '4px'
      },
      formControl: {
        width: '100%',
        padding: '0px 6px'
      },
      label: {
        left: '4px',
        top: '8px',
        height: '0px',
        color: defaultText,
        fontWeight: 400,
        '&.Mui-focused': {
          color: '#8D90A5'
        }
      },
      formLabel: {
        top: '9px',
        left: 2,
        fontWeight: 400,
        color: defaultText,
        height: '0px'
      },
      errorFormLabel: {
        top: '9px',
        left: 2,
        fontWeight: 400,
        color: inputError,
        height: '0px'
      },
      textInputError: {
        '& .MuiFormLabel-root.Mui-focused': {
          color: inputError
        }
      },
      selectField: {
        width: '100%',
        border: 'none !important',
        color: 'black !important',
        paddingLeft: '2px',
        backgroundColor: inputBackground,
        '& .MuiInputLabel-formControl': {
          left: '0',
          margin: 0,
          transform: 'none',
          border: 'none !important'
        },
        '& .MuiInputBase-formControl': {
          borderColor: NamedColors.slate[300],
          margin: '0px !important'
        },
        '& .MuiTypography-root': {
          fontSize: '14px'
        },
        '& .MuiFormHelperText-root': {
          color: inputError,
          marginLeft: '4px'
        }
      },
      gridStyle: {
        padding: '10px 12px 20px 1px'
      },
      textGridStyle: {
        padding: '10px 12px 0 1px'
      },
      floorplanSelect: {
        paddingBottom: '20px'
      },
      moveInDateStyle: {
        padding: '0px 5px 20px 6px'
      },
      gridStyleForSelect: {
        height: '110px'
      },
      switchButton: {
        marginLeft: '10px',
        marginTop: '-20px'
      },
      errorText: {
        color: inputError,
        marginTop: '-8px'
      },
      errorFloorplanText: {
        color: inputError,
        marginTop: '-8px',
        marginBottom: '10px'
      },
      errorTextForSelect: {
        color: inputError
      },
      errorTextForDate: {
        color: inputError,
        marginTop: '-7px'
      }
    })
  );

  const {
    subTitle,
    subTitleContact,
    subTitlePrefrences,
    switchButton,
    textField,
    label,
    textInputError,
    selectField,
    formLabel,
    errorFormLabel,
    gridStyle,
    gridStyleForSelect,
    floorplanSelect,
    textGridStyle,
    formControl,
    boxStyle,
    dialogActions,
    errorText,
    errorTextForSelect,
    errorTextForDate,
    datePicker,
    errorFloorplanText
  } = useStyles();
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    bedrooms: '',
    maxBudget: undefined,
    minBudget: undefined,
    property: '',
    owner: '',
    firstContact: '',
    addToWishlist: false,
    source: '',
    moveInDate: ''
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [checked, setChecked] = useState<boolean>(false);
  const [moveInDateValue, setMoveInDateValue] = useState<Date | null>(null);
  const [walkInDate, setWalkInDate] = useState<Date | null>(null);
  const [walkinTime, setWalkinTime] = useState<string>('');

  const [duplicateProspectValue, setDuplicateProspectValue] =
    useState<Array<any> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [propertyFloorplans, setPropertyFloorplans] = useState<LayoutOption[]>(
    []
  );
  const { searchPeople } = useLeasingTeamMembers();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [walkinTimeOptions, setWalkinTimeOptions] = useState<TimeOption[]>([]);

  const { layouts } = usePropertyLayouts();

  const { generateTimeOptions, saveWalkinDetails } = useAngularContext();

  const selectedPropertyPreferences = useMemo<PropertyPreferences>(() => {
    const property = props.properties.find(
      (property: Property) =>
        property.value.toString() === formData.property?.toString()
    );
    if (property !== undefined) {
      const preferences = property.preferences;
      if (preferences.require_prospect_floorplan) {
        const floorplans =
          layouts
            .find(
              (propertyLayout: PropertyLayout) =>
                propertyLayout.propertyId === Number(formData.property)
            )
            ?.layouts.map((layout: Layout) => ({
              value: layout.id,
              label: `${layout.name} - ${
                layout.bedrooms > 0 ? `${layout.bedrooms} bd - ` : ''
              }${layout.area} sq.ft. `
            }))
            .sort(({ value: A }: LayoutOption, { value: B }: LayoutOption) => {
              return B.toLocaleUpperCase().localeCompare(A.toUpperCase());
            }) || [];
        setPropertyFloorplans(floorplans);
      } else {
        setPropertyFloorplans([]);
      }
      return preferences;
    }
    return {};
  }, [formData.property, layouts, props.properties]);

  const getDate = (date?: Date | null) => {
    const currentDate = date ? new Date(date) : new Date();
    const day =
      currentDate.getDate() < 10
        ? `0${currentDate.getDate()}`
        : currentDate.getDate();
    const month =
      currentDate.getMonth() + 1 < 10
        ? `0${currentDate.getMonth() + 1}`
        : currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const formattedDate = `${year}-${month}-${day} 00:00:00`;
    return formattedDate;
  };

  const handleChange = (event: event) => {
    const { name, value } = event?.target;

    if (name === 'firstContact' && value !== 'walk-in') {
      setWalkInDate(null);
      setWalkinTime('');
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      addToWishlist: !checked,
      ...(name === 'property'
        ? {
            floorplan: undefined
          }
        : {})
    }));
    setFormErrors((prevData) => ({
      ...prevData,
      [name]: '',
      phoneOrEmail:
        formData.phone || formData.email ? '' : formErrors.phoneOrEmail
    }));
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const searchPeopleApiCall = async (
    value: string,
    propertyValue: number,
    fieldType: string
  ) => {
    try {
      const response: ApiResponse = await searchPeople({
        field: fieldType,
        person_type: 'prospect',
        property_id: propertyValue,
        query_string: value,
        scope: 'property',
        use_exact_hits: true
      });
      if (response?.data) {
        const duplicateProspect = response?.data.results.hits ?? [];
        setDuplicateProspectValue(duplicateProspect);
        const lengthOfDuplicateProspect = duplicateProspect
          ? Object.keys(duplicateProspect)?.length
          : 0;
        setShowAlert(lengthOfDuplicateProspect > 0);
        return response?.data.results.hits ?? [];
      } else if (response?.error) {
        console.log('Error:', response?.error);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const findDuplicateProspect = async (propertyValue: number | null) => {
    if (propertyValue) {
      if (formData?.phone && !validatePhoneNumber(formData?.phone)) {
        const phoneValue = formData?.phone?.replace(/\D/g, '');
        searchPeopleApiCall(phoneValue, propertyValue, 'phone');
      } else if (formData?.email && validateEmail(formData?.email ?? '')) {
        searchPeopleApiCall(formData?.email, propertyValue, 'email');
      } else {
        setDuplicateProspectValue(null);
        setShowAlert(false);
      }
    }
  };

  const validatePhoneNumber = (phone: string) => {
    const numbersOnly = phone?.match(/\d/g);
    const count = numbersOnly ? numbersOnly.length : 0;
    return count < 10;
  };
  const getFullDate = () => {
    const walkinDataObj = new Date(walkInDate!);
    const year = walkinDataObj!.getFullYear();
    const month = String(walkinDataObj!.getMonth() + 1).padStart(2, '0');
    const day = String(walkinDataObj!.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const createWalkIn = async (prospect_id: string, selectedUser: number) => {
    const fullDate = getFullDate();
    return saveWalkinDetails(prospect_id, selectedUser, fullDate, walkinTime);
  };

  const handleSubmit = async () => {
    const errors: FormErrors = {};
    if (!formData.firstName) {
      errors.firstName = 'Please add a first name';
    }

    if (!formData.lastName) {
      errors.lastName = 'Please add a last name';
    }
    if (formData.firstContact === 'walk-in' && !walkInDate) {
      errors.walkinDate = 'Please add a walk-in date';
    }
    if (formData.firstContact === 'walk-in' && !walkinTime) {
      errors.walkinTime = 'Please add a walk-in time';
    }

    if (!formData.phone && !formData.email) {
      errors.phoneOrEmail = 'Please add at least one contact type';
    }

    if (formData?.phone && validatePhoneNumber(formData?.phone)) {
      errors.phone = 'Invalid phone number';
    }

    if (formData?.email && !validateEmail(formData?.email)) {
      errors.email = 'Invalid email address';
    }

    if (!formData.source) {
      errors.source = 'Please add a source';
    }

    if (!formData.property) {
      errors.property = 'Please add a property';
    }

    if (formErrors.moveInDate) {
      errors.moveInDate = formErrors.moveInDate;
    } else if (!formData.moveInDate) {
      errors.moveInDate = 'Please add a move in Date';
    }

    if (!formData.firstContact) {
      errors.firstContact = 'Please add first contact type';
    }

    if (!formData.owner) {
      errors.owner = 'Please add an owner';
    }

    if (
      !formData.floorplan &&
      selectedPropertyPreferences.require_prospect_floorplan &&
      propertyFloorplans.length > 0
    ) {
      errors.floorplan = 'Please add a preferred floorplan';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const addProspectPayload: AddProspectData = {
      first_contact_type: formData.firstContact,
      is_waitlist: checked ?? formData?.addToWishlist,
      manager_id: Number(formData.owner),
      preferences: {
        preferred_property_floorplan_id: null,
        preferred_layout_id: formData.floorplan,
        max_price: Number(formData?.maxBudget),
        min_price: Number(formData?.minBudget),
        bedrooms: [formData.bedrooms]
      },
      profile: {
        email: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone: {
          national_format: formData.phone,
          phone_number: formData.phone,
          country_code: 'US'
        },
        target_move_date: formData.moveInDate
      },
      property_id: Number(formData.property),
      source: formData.source
    };
    if (formData.firstContact === 'walk-in') {
      addProspectPayload.pms_created_time = `${getFullDate()}T${moment(
        walkinTime
      ).format('HH:mmZ')}`;
    }
    setLoading(true);
    let resultAddProspect: any = await props.addProspect(addProspectPayload);

    if (resultAddProspect?.error) {
      setLoading(false);
      props.setSnackMessage(
        'We were unable to add new prospect. Please try again.'
      );
      props.handleClose();
    } else if (resultAddProspect?.data) {
      if (formData.firstContact === 'walk-in') {
        if (resultAddProspect?.data?.prospect) {
          resultAddProspect = {
            ...resultAddProspect,
            data: {
              ...resultAddProspect.data,
              prospect: {
                ...resultAddProspect.data?.prospect,
                preferences: {
                  ...resultAddProspect.data?.prospect?.preferences,
                  preferred_layout_id: formData.floorplan
                }
              }
            }
          };
        }

        try {
          const { data: visitResponse } = await createWalkIn(
            resultAddProspect?.data?.prospect?.id,
            Number(formData.owner)
          );
          setLoading(false);
          props.setSnackMessage(`Prospect successfully created.`);
          props.openUnits(
            resultAddProspect?.data?.prospect,
            visitResponse.visit
          );
          props.handleClose();
        } catch (err) {
          setLoading(false);
          props.setSnackMessage(`Prospect successfully created.`);
          props.openProspectStreamModal(
            resultAddProspect?.data?.prospect?.stream_id
          );
          props.handleClose();
        }
      } else {
        setLoading(false);
        props.setSnackMessage(`Prospect successfully created.`);
        props.openProspectStreamModal(
          resultAddProspect?.data?.prospect?.stream_id
        );
        props.handleClose();
      }
    }

    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      bedrooms: '',
      maxBudget: undefined,
      minBudget: undefined,
      property: '',
      owner: '',
      firstContact: '',
      addToWishlist: false,
      source: '',
      moveInDate: ''
    });
    setFormErrors({
      firstName: '',
      lastName: '',
      phoneOrEmail: '',
      email: '',
      property: '',
      owner: '',
      firstContact: '',
      source: '',
      moveInDate: ''
    });
  };

  const commonPropsForTextField: any = {
    variant: 'filled',
    fullWidth: true,
    className: textField,
    inputProps: {
      style: {
        backgroundColor: inputBackground
      }
    },
    InputProps: {
      style: {
        border: 'none',
        color: defaultText,
        backgroundColor: inputBackground,
        paddingLeft: '0px !important',
        '& .MuiFilledInput-root': {
          border: 'none',
          backgroundColor: inputBackground
        },
        '& .MuiInputBase-root': {
          border: 'none',
          paddingLeft: '0px !important',
          borderBottomColor: formErrors?.phoneOrEmail && inputError,
          '&.Mui-focused': {
            border: 'none',
            backgroundColor: inputBackground
          }
        }
      }
    },
    InputLabelProps: {
      classes: {
        root: label
      }
    },
    onChange: (e: any) => handleChange(e)
  };

  const firstContact = [
    { label: 'Call', value: 'call' },
    { label: 'Chat', value: 'chat' },
    { label: 'Email', value: 'email' },
    { label: 'SMS', value: 'sms' },
    { label: 'Walk-in', value: 'walk-in' }
  ];

  const bedrooms = [
    { value: 'studio', label: 'Studio' },
    { value: '1bd', label: '1 bedroom' },
    { value: '2bd', label: '2 bedrooms' },
    { value: '3bd', label: '3+ bedrooms' }
  ];

  const handleDateChange = (date: Date | null) => {
    if (isDateInvalid(date)) {
      setFormErrors({
        ...formErrors,
        moveInDate: 'Please add valid move in date' || formErrors?.moveInDate
      });
    } else {
      setFormData({
        ...formData,
        moveInDate: getDate(date)
      });
      setFormErrors({
        ...formErrors,
        moveInDate: date ? '' : formErrors?.moveInDate
      });
    }
  };
  const changeWalkIn = (date: MaterialUiPickersDate) => {
    const newDate = new Date(date!);
    newDate.setHours(0, 0, 0);
    setWalkInDate(newDate);
    setWalkinTime('');
    setFormErrors((prevData) => ({
      ...prevData,
      walkinDate: ''
    }));
    const timeOptions = generateTimeOptions(newDate);
    setWalkinTimeOptions(timeOptions);
  };

  const onBlurPhone = async (phone: string) => {
    const phoneValue = phone?.replace(/\D/g, '');
    if (formData?.property) {
      const result = await searchPeopleApiCall(
        phoneValue,
        Number(formData?.property) ?? null,
        'phone'
      );
      if (
        result?.length === 0 &&
        formData?.email &&
        validateEmail(formData?.email)
      ) {
        searchPeopleApiCall(
          formData?.email,
          Number(formData?.property) ?? null,
          'email'
        );
      }
    }
  };

  const onBlurEmail = async (email: string) => {
    if (formData?.property) {
      const result = await searchPeopleApiCall(
        email,
        Number(formData?.property) ?? null,
        'email'
      );
      if (
        result?.length === 0 &&
        formData?.phone &&
        !validatePhoneNumber(formData?.phone)
      ) {
        const phoneValue = formData?.phone?.replace(/\D/g, '');
        searchPeopleApiCall(
          phoneValue,
          Number(formData?.property) ?? null,
          'phone'
        );
      }
    }
  };

  return (
    <>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Grid container xs={12} justify="flex-start" alignItems="flex-start">
            {showAlert ? (
              <DuplicateDetectionCard
                duplicateDataDetails={duplicateProspectValue}
              />
            ) : (
              <></>
            )}
            <Grid item xs={12}>
              <Grid container item xs={12}>
                <Text className={subTitleContact}>CONTACT</Text>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6} className={gridStyle}>
                  <TextField
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    error={!!formErrors.firstName}
                    {...commonPropsForTextField}
                  />
                  {formErrors?.firstName && (
                    <FormHelperText className={errorText}>
                      {formErrors?.firstName}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={6} className={gridStyle}>
                  <TextField
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    error={!!formErrors.lastName}
                    {...commonPropsForTextField}
                  />
                  {formErrors?.lastName && (
                    <FormHelperText className={errorText}>
                      {formErrors?.lastName}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} className={gridStyle}>
                <PhoneInput
                  name="phone"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleChange(event);
                  }}
                  onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                    onBlurPhone(event?.target?.value);
                  }}
                  error={!!(formErrors.phoneOrEmail || formErrors?.phone)}
                  {...commonPropsForTextField}
                  classForLabel={
                    formErrors?.phoneOrEmail || formErrors.phone
                      ? errorFormLabel
                      : formLabel
                  }
                />
                {(formErrors?.phoneOrEmail || formErrors?.phone) && (
                  <FormHelperText className={errorText}>
                    {formErrors?.phoneOrEmail || formErrors?.phone}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} className={gridStyle}>
                <TextField
                  id="email"
                  label="Email"
                  name="email"
                  error={!!formErrors.phoneOrEmail || formErrors?.email}
                  onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                    onBlurEmail(event?.target?.value);
                  }}
                  {...commonPropsForTextField}
                />
                {(formErrors?.phoneOrEmail || formErrors?.email) && (
                  <FormHelperText className={errorText}>
                    {formErrors?.phoneOrEmail || formErrors?.email}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Text className={subTitle}>DETAILS</Text>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <FormControl
                    variant="filled"
                    className={classNames(formControl, {
                      [textInputError]: formErrors?.property
                    })}
                  >
                    <InputLabel
                      id="property-label"
                      className={
                        formErrors?.property ? errorFormLabel : formLabel
                      }
                    >
                      Property
                    </InputLabel>
                    <Select
                      labelId="property-label"
                      id="property"
                      name="property"
                      label="Property"
                      onChange={(e: ChangeEvent<{ value: any }>) => {
                        handleChange(e);
                        findDuplicateProspect(e?.target?.value);
                      }}
                      error={!!formErrors.property}
                      className={classNames(selectField, {
                        [textInputError]: formErrors?.property
                      })}
                      inputProps={{ 'data-testid': 'property' }}
                    >
                      {props?.properties?.map((property: any, index: any) => (
                        <MenuItem key={index} value={property.value}>
                          {property.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors?.property && (
                      <FormHelperText className={errorTextForSelect}>
                        {formErrors?.property}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl
                    variant="filled"
                    className={classNames(formControl, {
                      [textInputError]: formErrors?.owner
                    })}
                  >
                    <InputLabel
                      id="owner-label"
                      className={formErrors?.owner ? errorFormLabel : formLabel}
                    >
                      Owner
                    </InputLabel>
                    <Select
                      labelId="owner-label"
                      id="owner"
                      name="owner"
                      onChange={handleChange}
                      error={!!formErrors.owner}
                      className={classNames(selectField, {
                        [textInputError]: formErrors?.owner
                      })}
                      inputProps={{ 'data-testid': 'owner' }}
                    >
                      {props?.owners?.map((owner: any, index: any) => (
                        <MenuItem key={index} value={owner.value}>
                          {owner.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors?.owner && (
                      <FormHelperText className={errorTextForSelect}>
                        {formErrors?.owner}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <FormControl
                    variant="filled"
                    className={classNames(formControl, {
                      [textInputError]: formErrors?.source
                    })}
                  >
                    <InputLabel
                      id="sources-label"
                      className={
                        formErrors?.source ? errorFormLabel : formLabel
                      }
                    >
                      Sources
                    </InputLabel>
                    <Select
                      labelId="sources-label"
                      id="sources"
                      name="source"
                      onChange={handleChange}
                      error={!!formErrors.source}
                      className={classNames(selectField, {
                        [textInputError]: formErrors?.source
                      })}
                      inputProps={{ 'data-testid': 'sources' }}
                    >
                      {props?.sources?.map((source: any) => (
                        <MenuItem key={source.value} value={source.value}>
                          {source.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors?.source && (
                      <FormHelperText className={errorTextForSelect}>
                        {formErrors?.source}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl
                    variant="filled"
                    className={classNames(formControl, {
                      [textInputError]: formErrors?.firstContact
                    })}
                    data-testid="firstContactFormControl"
                  >
                    <InputLabel
                      id="firstContact-label"
                      className={
                        formErrors?.firstContact ? errorFormLabel : formLabel
                      }
                    >
                      First Contact
                    </InputLabel>
                    <Select
                      labelId="firstContact-label"
                      id="firstContact"
                      name="firstContact"
                      error={!!formErrors.firstContact}
                      onChange={handleChange}
                      className={classNames(selectField, {
                        [textInputError]: formErrors?.firstContact
                      })}
                      inputProps={{ 'data-testid': 'firstContact' }}
                    >
                      {firstContact?.map((contactType: any) => (
                        <MenuItem
                          key={contactType.value}
                          value={contactType.value}
                        >
                          {contactType.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors?.firstContact && (
                      <FormHelperText className={errorTextForSelect}>
                        {formErrors?.firstContact}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              {formData.firstContact === 'walk-in' && (
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className={classNames(datePicker, {
                          [textInputError]: formErrors?.walkinDate
                        })}
                        margin="normal"
                        id="walkInDate"
                        label={
                          <Box
                            className={
                              formErrors.walkinDate ? errorFormLabel : label
                            }
                          >
                            Walk-in Date
                          </Box>
                        }
                        fullWidth
                        format={'EEE, LLL d, yyyy'}
                        name="walk-in-date"
                        error={!!formErrors.walkinDate}
                        value={walkInDate}
                        helperText=""
                        onChange={(date: MaterialUiPickersDate) =>
                          changeWalkIn(date)
                        }
                        inputProps={{ 'data-testid': 'walk-in-date' }}
                        disableFuture
                      />
                      {formErrors?.walkinDate && (
                        <FormHelperText className={errorTextForDate}>
                          {formErrors?.walkinDate}
                        </FormHelperText>
                      )}
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="filled"
                      className={classNames(formControl, {
                        [textInputError]: formErrors?.walkinTime
                      })}
                    >
                      <InputLabel
                        id="walkinTime-label"
                        className={
                          formErrors?.walkinTime ? errorFormLabel : formLabel
                        }
                      >
                        Walk in time
                      </InputLabel>
                      <Select
                        labelId="walkinTime-label"
                        id="walkinTime"
                        name="walkinTime"
                        error={!!formErrors.walkinTime}
                        onChange={(
                          event: React.ChangeEvent<{ value: any }>
                        ) => {
                          if (event.target.value) {
                            setFormErrors((prevData) => ({
                              ...prevData,
                              walkinTime: ''
                            }));
                            setWalkinTime(event.target.value);
                          }
                        }}
                        value={walkinTime}
                        className={classNames(selectField, {
                          [textInputError]: formErrors?.firstContact
                        })}
                        inputProps={{ 'data-testid': 'walkinTime' }}
                      >
                        {walkinTimeOptions &&
                          walkinTimeOptions.map(
                            (time: TimeOption, index: number) => (
                              <MenuItem key={index} value={time.value}>
                                {time.label}
                              </MenuItem>
                            )
                          )}
                      </Select>
                      {formErrors.walkinTime && (
                        <FormHelperText className={errorTextForSelect}>
                          {formErrors.walkinTime}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Text className={subTitlePrefrences}>PREFERENCES</Text>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6} className={gridStyleForSelect}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classNames(datePicker, {
                        [textInputError]: formErrors?.moveInDate
                      })}
                      margin="normal"
                      id="moveInDate"
                      label={
                        <Box
                          className={
                            formErrors.moveInDate ? errorFormLabel : label
                          }
                        >
                          Move-in Date
                        </Box>
                      }
                      fullWidth
                      format="MM/dd/yyyy"
                      name="moveInDate"
                      error={!!formErrors.moveInDate}
                      value={moveInDateValue}
                      helperText=""
                      keyboardIcon={
                        <SvgIcon>
                          <NewCalendarIcon />
                        </SvgIcon>
                      }
                      KeyboardButtonProps={{
                        'aria-label': 'moveInDate'
                      }}
                      onChange={(date: MaterialUiPickersDate) => {
                        setMoveInDateValue(date);
                        handleDateChange(date);
                      }}
                      inputProps={{ 'data-testid': 'moveInDate' }}
                    />
                    {formErrors?.moveInDate && (
                      <FormHelperText className={errorTextForDate}>
                        {formErrors?.moveInDate}
                      </FormHelperText>
                    )}
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="filled" className={formControl}>
                    <InputLabel id="bedrooms-label" className={formLabel}>
                      Bedrooms
                    </InputLabel>
                    <Select
                      labelId="bedrooms-label"
                      id="bedrooms"
                      name="bedrooms"
                      onChange={handleChange}
                      className={selectField}
                      inputProps={{ 'data-testid': 'bedrooms' }}
                    >
                      {bedrooms?.map((bedroomData: any) => (
                        <MenuItem
                          key={bedroomData.value}
                          value={bedroomData.value}
                        >
                          {bedroomData.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid
                  item
                  xs={6}
                  className={
                    propertyFloorplans.length > 0 ? textGridStyle : gridStyle
                  }
                >
                  <TextField
                    id="minBudget"
                    label="Min Budget"
                    name="minBudget"
                    {...commonPropsForTextField}
                    type="number"
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  className={
                    propertyFloorplans.length > 0 ? textGridStyle : gridStyle
                  }
                >
                  <TextField
                    id="maxBudget"
                    label="Max Budget"
                    name="maxBudget"
                    {...commonPropsForTextField}
                    type="number"
                  />
                </Grid>
              </Grid>
              {selectedPropertyPreferences.require_prospect_floorplan &&
                propertyFloorplans.length > 0 && (
                  <Grid item xs={12} className={floorplanSelect}>
                    <FormControl
                      variant="filled"
                      className={formControl}
                      error={!!formErrors.floorplan}
                    >
                      <InputLabel id="floorplan-label" className={formLabel}>
                        Floorplan
                      </InputLabel>
                      <Select
                        labelId="floorplan-label"
                        id="floorplan"
                        name="floorplan"
                        onChange={handleChange}
                        className={selectField}
                        inputProps={{ 'data-testid': 'floorplan' }}
                      >
                        {propertyFloorplans.map((floorplan: LayoutOption) => (
                          <MenuItem
                            key={floorplan.value}
                            value={floorplan.value}
                          >
                            {floorplan.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {!!formErrors.floorplan && (
                      <FormHelperText className={errorFloorplanText}>
                        {formErrors.floorplan}
                      </FormHelperText>
                    )}
                  </Grid>
                )}
            </Grid>

            <Grid item xs={12} className={switchButton}>
              <FormControlLabel
                control={
                  <Switch
                    name="addToWishlist"
                    checked={!!checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                      handleChange(e);
                    }}
                    color="primary"
                  />
                }
                label="Add to Waitlist"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={dialogActions}>
        <Box className={boxStyle}>
          <Button variant="outlined" onClick={props.handleClose}>
            Cancel
          </Button>
          <CircularProgressButton
            onClick={handleSubmit}
            shouldShowProgress={loading}
            progressText={'Saving...'}
            disabled={showAlert}
          >
            Create
          </CircularProgressButton>
        </Box>
      </DialogActions>
    </>
  );
};

export default ProspectForm;
