import some from 'lodash/some';
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import map from 'lodash/map';
(function (angular) {
  var app = angular.module('knockApp');

  const PropertiesIndexController = function (
    $scope,
    $rootScope,
    $q,
    $mdDialog,
    $mdToast,
    $location,
    $routeParams,
    appDataService,
    managerApi,
    leasingTeamApi,
    listingApi,
    companyApi,
    userService,
    postFloorplanModalService,
    unitApi
  ) {
    var self = this;
    var user = userService.getScopedUser();

    $scope.data = {
      newUnitsFeature: $rootScope.featureFlags.NEW_UNITS || false,
      isLoading: false,
      communities: [],
      communitiesForBinder: [],
      currentCommunity: {},
      floorplansByCommunity: {},
      propertyIds: [],
      floorplansByPropertyId: {},
      listingsByManager: {},
      teamMembers: [],
      hasInactiveListing: false,
      companyPreferences: {}
    };

    $scope.showAddPropertyButton = false;

    $scope.adminView = $routeParams.view === 'yes';
    $scope.isLeasingBinderOpen = false;

    $scope.transferCommunity = (community) => {
      return $mdDialog.show({
        controller: 'TransferCommunityOwnershipController',
        locals: { community: community },
        templateUrl:
          '/angular/views/listingIndex/transfer-community-modal.html',
        clickOutsideToClose: true
      });
    };

    $scope.reloadData = () => self._initialize();

    $scope.editCommunity = (community) =>
      $location.url('/manager/community/' + community.id);

    $scope.addFloorplan = (community) =>
      $location.url('/manager/community/' + community.id + '/addFloorplan');

    $scope.editFloorplan = (floorplan, propertyId) => {
      if ($scope.data.newUnitsFeature && propertyId) {
        $location.url('/property/' + propertyId + '/floorplan/' + floorplan.id);
      } else {
        $location.url('/manager/community/floorplan/' + floorplan.id);
      }
    };

    $scope.postFloorplanListing = ($event, floorplan) => {
      if (!$scope.data.newUnitsFeature) {
        postFloorplanModalService.openModal(
          $event,
          floorplan,
          $scope.reloadData
        );
      }
    };

    $scope.newListing = () => $location.url('/manager/listing/create');

    $scope.newCommunity = () => $location.url('/manager/community/create');

    $scope.showFloorplanHeader = (community) =>
      $scope.data.newUnitsFeature
        ? $scope.data.floorplansByPropertyId[community.property_id] &&
          $scope.data.floorplansByPropertyId[community.property_id].length > 0
        : $scope.data.floorplansByCommunity[community.id] &&
          $scope.data.floorplansByCommunity[community.id].length > 0;

    $scope.openLeasingBinder = function (community) {
      $scope.data.currentCommunity = community;
      $scope.isLeasingBinderOpen = true;
    };

    $scope.closeLeasingBinder = function () {
      $scope.isLeasingBinderOpen = false;
      $scope.$apply();
    };

    $scope.getPropertiesForBinder = async function () {
      return await managerApi.getMyCommunities(true, true, true);
    };

    self._initialize = () => {
      $scope.data.isLoading = true;
      self._setTeamMembers();

      var initPromises = [
        self._getMyCommunities(),
        self._getMyInactiveListings(),
        self._getCompanyPreferences()
      ];

      $q.all(initPromises)
        // We need the Community data to be loaded in order to get new units floorplans
        // So self._getMyFloorplans, and then set isLoading to false
        .then(() => self._getMyFloorplans())
        .finally(() => ($scope.data.isLoading = false));
    };

    self._setTeamMembers = () => {
      $scope.data.teamMembers = map(
        appDataService.getTeamMembers(),
        function (member) {
          return {
            managerId: member.Manager.id,
            info: member.ManagerInfo
          };
        }
      );

      $scope.data.currentManager = find(
        $scope.data.teamMembers,
        function (member) {
          return member.managerId === user.id;
        }
      );
    };

    self._getMyCommunities = function () {
      return managerApi.getMyCommunities().then(function (response) {
        $scope.data.communities = response.data.communities;
        if ($scope.data.newUnitsFeature) {
          $scope.data.propertyIds = map(
            response.data.communities,
            'property_id'
          );
        }
      });
    };

    self._getMyFloorplans = function () {
      if ($scope.data.newUnitsFeature) {
        const deferred = $q.defer();
        if ($scope.data.propertyIds) {
          $scope.data.propertyIds.map((propertyId) => {
            return unitApi
              .getLayouts(propertyId)
              .then((response) => {
                $scope.data.floorplansByPropertyId[propertyId] =
                  response.data.layouts;
                return deferred.promise;
              })
              .catch((error) =>
                $mdToast.showSimple('Error loading layouts: ', error)
              );
          });
        }
        // TODO-NU: Remove legacy floorplan code
      } else {
        return managerApi.getMyFloorplans().then(function (response) {
          $scope.data.floorplansByCommunity = groupBy(
            response.data.floorplans,
            'community_id'
          );
        });
      }
    };

    self._getMyInactiveListings = () => {
      var excludeInactiveFloorplans = true;

      return listingApi
        .getMyInactiveListings(excludeInactiveFloorplans)
        .then(function (response) {
          $scope.data.listingsByManager = response.data.listings;
          $scope.data.hasInactiveListing = some(
            $scope.data.listingsByManager,
            (member) => member.listings.length > 0
          );
        });
    };

    self._getCompanyPreferences = () => {
      return companyApi.getCompanyPreferences().then((response) => {
        $scope.data.companyPreferences = response.data.preferences;
      });
    };

    self._initialize();
  };

  PropertiesIndexController.$inject = [
    '$scope',
    '$rootScope',
    '$q',
    '$mdDialog',
    '$mdToast',
    '$location',
    '$routeParams',
    'appDataService',
    'managerApi',
    'leasingTeamApi',
    'listingApi',
    'companyApi',
    'userService',
    'postFloorplanModalService',
    'unitApi'
  ];
  app.controller('PropertiesIndexController', PropertiesIndexController);

  const TransferCommunityOwnershipController = function (
    $scope,
    $mdDialog,
    $mdToast,
    appDataService,
    community,
    leasingTeamApi,
    communityApi
  ) {
    var self = this;

    $scope.data = {
      isLoading: false,
      isSaving: false,
      newOwner: null
    };

    self._initialize = function () {
      $scope.data.isLoading = true;

      $scope.teamMembers = map(
        appDataService.getTeamMembers(),
        function (member) {
          return {
            name:
              member.ManagerInfo.first_name +
              ' ' +
              member.ManagerInfo.last_name,
            id: member.ManagerInfo.manager_id
          };
        }
      );

      communityApi.getCommunityManager(community.id).success(function (res) {
        $scope.data.newOwner = find($scope.teamMembers, function (member) {
          return member.id === res.manager_info.manager_id;
        });

        $scope.data.isLoading = false;
      });
    };

    $scope.transferOwnership = function (newOwner) {
      $scope.data.isSaving = true;

      communityApi
        .transferCommunity(community.id, newOwner.id)
        .success(function () {
          $mdToast.showSimple('Transferred!');
        })
        .error(function () {
          $mdToast.showSimple('unable to transfer!');
        })
        .finally(function () {
          $scope.data.isSaving = false;
        });
    };

    $scope.close = function () {
      $mdDialog.cancel();
    };

    self._initialize();
  };

  TransferCommunityOwnershipController.$inject = [
    '$scope',
    '$mdDialog',
    '$mdToast',
    'appDataService',
    'community',
    'leasingTeamApi',
    'communityApi'
  ];
  app.controller(
    'TransferCommunityOwnershipController',
    TransferCommunityOwnershipController
  );
})(window.angular);
