import React from 'react';

export const LaundryIcon = () => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2917 1.625H2.70833C2.2481 1.625 1.875 1.9981 1.875 2.45833V19.5417C1.875 20.0019 2.2481 20.375 2.70833 20.375H17.2917C17.7519 20.375 18.125 20.0019 18.125 19.5417V2.45833C18.125 1.9981 17.7519 1.625 17.2917 1.625Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.875 6.625H18.125"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 17.875C12.4162 17.875 14.375 15.9162 14.375 13.5C14.375 11.0838 12.4162 9.125 10 9.125C7.58376 9.125 5.625 11.0838 5.625 13.5C5.625 15.9162 7.58376 17.875 10 17.875Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.625 9.16998L10 13.5"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2725 16.4042L10 13.5"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.73511 14.4783L10.0001 13.5"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.125 1.625V6.625"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5625 4.4375C11.3899 4.4375 11.25 4.29759 11.25 4.125C11.25 3.95241 11.3899 3.8125 11.5625 3.8125"
      stroke="#353950"
      strokeWidth="1.5"
    />
    <path
      d="M11.5625 4.4375C11.7351 4.4375 11.875 4.29759 11.875 4.125C11.875 3.95241 11.7351 3.8125 11.5625 3.8125"
      stroke="#353950"
      strokeWidth="1.5"
    />
    <path
      d="M15.3125 4.4375C15.1399 4.4375 15 4.29759 15 4.125C15 3.95241 15.1399 3.8125 15.3125 3.8125"
      stroke="#353950"
      strokeWidth="1.5"
    />
    <path
      d="M15.3125 4.4375C15.4851 4.4375 15.625 4.29759 15.625 4.125C15.625 3.95241 15.4851 3.8125 15.3125 3.8125"
      stroke="#353950"
      strokeWidth="1.5"
    />
  </svg>
);
