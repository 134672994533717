/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  var app = angular.module('knockApp');

  const ProspectPrintController = function (
    $q,
    $scope,
    $routeParams,
    $moment,
    pageTitleService,
    appDataService,
    prospectsApi,
    managerApi,
    conversationsService
  ) {
    var self = this;

    $scope.data = {
      prospect: null,
      property: null,
      preferredFloorplan: null,
      alternativeContacts: null,
      stream: null,
      isLoaded: false,
      error: false,
      events: [],
      messages: [],
      notes: []
    };

    function _getConversationSuccess(response) {
      if (response.stream != null && response.stream.messages != null) {
        for (var i = 0; i < response.stream.messages.length; i++) {
          var message = response.stream.messages[i];
          if (
            message.activity_type === 'note' ||
            message.activity_type === 'reminder'
          ) {
            $scope.data.notes.push(message);
          } else {
            $scope.data.messages.push(message);
          }
        }

        $scope.data.stream = response.stream;
      }
    }

    function _getConversationError() {
      $scope.data.error = true;
    }

    function _getConversation() {
      return conversationsService
        .getConversation($scope.data.prospect.stream_id)
        .error(_getConversationError)
        .success(_getConversationSuccess);
    }

    self._initialize = function () {
      var prospectId = window.atob($routeParams.prospectKnockId);

      prospectsApi
        .getProspect(prospectId)
        .then(
          function (response) {
            $scope.data.prospect = response.data.prospect;
            $scope.data.property = appDataService.getProperty(
              $scope.data.prospect.property_id
            ).Property;
            $scope.data.manager = appDataService.getTeamMember(
              $scope.data.prospect.assigned_manager_id
            ).ManagerInfo;
            $scope.data.now = $moment().format('MM/DD/YYYY h:mma');
            $scope.data.events = $scope.data.prospect.events;

            var pageTitle =
              $scope.data.prospect.profile.last_name +
              ', ' +
              $scope.data.prospect.profile.first_name +
              ' - ' +
              $scope.data.property.data.location.name +
              ' - ' +
              $moment().format('MM/DD/YYYY h:mma');

            pageTitleService.setPageTitle(pageTitle);
            pageTitleService.lockTitle();

            var initPromises = [];

            if ($scope.data.prospect.preferences.preferred_property_floorplan) {
              initPromises.push(self._getFloorplan());
            }

            initPromises.push(self._getAlternativeContacts());

            $q.all(initPromises).then(
              function () {
                $scope.data.isLoaded = true;
              },
              function () {
                $scope.data.error = true;
              }
            );
          },
          function () {
            $scope.data.error = true;
          }
        )
        .then(function () {
          _getConversation();
        });
    };

    self._getFloorplan = function () {
      return managerApi
        .getFloorplan(
          $scope.data.prospect.preferences.preferrred_property_floorplan
            .floorplan_id
        )
        .then(function (response) {
          $scope.data.preferredFloorplan = response.data.floorplan;
        });
    };

    self._getAlternativeContacts = function () {
      return prospectsApi
        .getProspectAlternativeContacts($scope.data.prospect.id)
        .then(function (response) {
          $scope.data.alternativeContacts =
            response.data.alternative_contacts || [];
        });
    };

    self._initialize();
  };

  ProspectPrintController.$inject = [
    '$q',
    '$scope',
    '$routeParams',
    '$moment',
    'pageTitleService',
    'appDataService',
    'prospectsApi',
    'managerApi',
    'conversationsService'
  ];

  app.controller('ProspectPrintController', ProspectPrintController);
})(window.angular);
