import React, { ChangeEvent, FC } from 'react';
import { TableCell } from '@material-ui/core';

import { InputCheckbox } from '@knockrentals/knock-shared-web';
import { useCommonStyles } from '../../commonStyles/commonStyles';

interface SelectionCellProps {
  id: string;
  label: string;
  selected: boolean;
  onSelect: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SelectionCell: FC<SelectionCellProps> = ({
  id,
  label,
  selected,
  onSelect
}) => {
  const commonClasses = useCommonStyles();
  const handleSelectRow = (event: ChangeEvent<HTMLInputElement>) => {
    onSelect(event);
  };

  return (
    <TableCell
      padding="checkbox"
      className={`${commonClasses.inputCheckbox} ${
        commonClasses.stickyRowOne
      } ${selected ? commonClasses.selectedRow : ''}`}
      data-testid="select-row-cell"
    >
      <InputCheckbox
        id={id}
        data-testid="select-row-checkbox"
        color="primary"
        inputProps={{ 'aria-label': label }}
        checked={selected}
        onChange={handleSelectRow}
      />
    </TableCell>
  );
};

export default SelectionCell;
