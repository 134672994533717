/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import sortBy from 'lodash/sortBy';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('ProspectExportSummaryController', [
    '$scope',
    '$q',
    '$routeParams',
    'apiBase',
    function ($scope, $q, $routeParams, apiBase) {
      var self = this;

      $scope.data = {
        search_response_url: '',
        failures: [],
        request_responses: [],
        summary: []
      };

      self.loadRequestSummary = function (streamId) {
        return apiBase
          .get('/prospects/' + streamId + '/request_response_summary')
          .success(function (response) {
            if (response.summary.failures) {
              $scope.data.failures = sortBy(
                response.summary.failures,
                function (failure) {
                  return failure.modified_time;
                }
              );
            }

            if (response.summary.request_responses) {
              $scope.data.request_responses = sortBy(
                response.summary.request_responses,
                function (request_response) {
                  return request_response.modified_time;
                }
              );
            }

            $scope.data.summary = sortBy(
              $scope.data.failures.concat($scope.data.request_responses),
              function (summary) {
                return summary.created_time;
              }
            );
          });
      };

      self.searchProspect = function (streamId) {
        return apiBase
          .get('/prospects/' + streamId + '/search')
          .success(function (response) {
            $scope.data.search_response_url = response.url;
          })
          .error(function (response) {
            $scope.errorMessage = 'Unable to search prospect from vendor';
          });
      };

      self._initialize = function () {
        $scope.isLoading = true;
        var streamId = $routeParams.streamId;

        var initPromises = [
          self.loadRequestSummary(streamId),
          self.searchProspect(streamId)
        ];

        $q.all(initPromises).finally(function () {
          $scope.isLoading = false;
        });
      };

      self._initialize();
    }
  ]);
})(window.angular);
