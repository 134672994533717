/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  // These filters parameterize the response time despite having access to
  // the prospect because they are used both for standard response times,
  // and business response times.

  // Special casing to handle upstream bad response time values.
  app.filter('unfuckResponseTime', [
    function () {
      return function (responseTime, prospect) {
        // Normalize undefined as null.
        responseTime = responseTime === undefined ? null : responseTime;

        switch (prospect.creation_source) {
          // Response times aren't applicable to these,
          //  but they aren't always correctly set to -1 (N/A):
          case 'manual':
          case 'csv-import':
          case 'vendor-import':
            return -1;

          // Response times should always be instantaneous for these,
          //  but they aren't always correctly set to 0 (Immediate):
          case 'self-schedule':
            return 0;

          default:
            switch (prospect.first_contact_type) {
              // Response times aren't applicable to these,
              //  but they aren't always correctly set to -1 (N/A):
              case 'walk-in':
                return -1;

              // Response times should always be instantaneous for these,
              //  but they aren't always correctly set to 0 (Immediate):
              case 'knock-schedule':
                return 0;

              // Numeric response times should appear as-is, unless they were quickly
              //  followed with an appointment booking, where they should appear instantaneous
              default:
                if (typeof responseTime == 'number') {
                  var quickResponseThreshold = 1 * 30 * 60;
                  var respondedQuickly = responseTime <= quickResponseThreshold;
                  var quickImpliesImmediate = !!prospect.has_appointments;

                  if (respondedQuickly && quickImpliesImmediate) {
                    return 0;
                  }
                }
            }
        }

        // No special cases detected, proceed normally
        return responseTime;
      };
    }
  ]);

  app.filter('prospectCreatedAgo', [
    '$moment',
    function ($moment) {
      return function (prospect) {
        var createdTime = $moment(
          prospect.created_time === undefined ? null : prospect.created_time
        );
        return $moment().diff(createdTime, 'seconds');
      };
    }
  ]);

  app.filter('prospectStillAwaitingResponse', [
    '$filter',
    function ($filter) {
      return function (responseTime, prospect) {
        responseTime = $filter('unfuckResponseTime')(responseTime, prospect);

        if (responseTime === null) {
          var createdAgo = $filter('prospectCreatedAgo')(prospect);
          var tooLateToMeaningfullyRespondThreshold = 48 * 60 * 60;

          return createdAgo < tooLateToMeaningfullyRespondThreshold;
        } else {
          return false;
        }
      };
    }
  ]);

  app.filter('prospectResponseTimeSortable', [
    '$filter',
    function ($filter) {
      return function (responseTime, prospect) {
        responseTime = $filter('unfuckResponseTime')(responseTime, prospect);

        switch (responseTime) {
          case null:
            if (
              $filter('prospectStillAwaitingResponse')(responseTime, prospect)
            ) {
              // Still waiting: very actionable, put first
              return -2;
            } else {
              // Flagged as 'Never' but still actionable, put next
              return -1;
            }

          // Not respondable to, not actionable, put last
          case -2: // Ignored
          case -1: // N/A
            return Infinity;

          // Order by quicker response times in-between
          default:
            return responseTime;
        }
      };
    }
  ]);

  app.filter('prospectResponseTime', [
    '$filter',
    function ($filter) {
      return function (responseTime, prospect) {
        responseTime = $filter('unfuckResponseTime')(responseTime, prospect);

        switch (responseTime) {
          // Not respondable to
          case -2:
            return 'Ignored';
          case -1:
            return 'N/A';

          // Instantaneously responded to
          case 0:
            return 'Immediate';

          // Not responded to:
          //  Show if we are still prompting the LM to contact them,
          //  or if we've judged the attempt as fruitless for the LM.
          case null:
            return $filter('prospectStillAwaitingResponse')(
              responseTime,
              prospect
            )
              ? 'Waiting'
              : 'Never';

          // Responded to, show times (as seconds if sub-minute):
          default:
            return $filter('timeAgoSeconds')(responseTime, responseTime < 60);
        }
      };
    }
  ]);
})(window.angular);
