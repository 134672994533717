import React, { FC } from 'react';

export const HourglassIcon: FC = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.03163 5.38242C5.49496 7.94962 7.12329 9.70802 9.99996 10.6C12.8766 9.70802 14.505 7.94962 14.9683 5.38242C15.0239 5.03521 14.9183 4.68204 14.6792 4.41607C14.4402 4.1501 14.0917 3.99804 13.7258 4.00002H6.27413C5.9082 3.99804 5.55975 4.1501 5.3207 4.41607C5.08165 4.68204 4.976 5.03521 5.03163 5.38242Z"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 1.59998H16.875"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.274 17.2C5.90824 17.2019 5.55996 17.05 5.32093 16.7842C5.08191 16.5184 4.97614 16.1655 5.0315 15.8184C5.49483 13.2512 7.12316 11.4928 9.99983 10.6C12.8765 11.4928 14.5048 13.2512 14.9682 15.8184C15.0235 16.1655 14.9177 16.5184 14.6787 16.7842C14.4397 17.05 14.0914 17.2019 13.7257 17.2H6.274Z"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 19.6H16.875"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
