/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('onOffSwitch', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/util/onOffSwitch.html',
      scope: {
        isOn: '='
      },
      controller: 'OnOffSwitchController'
    };
  });

  app.controller('OnOffSwitchController', [
    '$scope',
    function ($scope) {
      $scope.toggleSwitch = function () {
        $scope.isOn = !$scope.isOn;
      };
    }
  ]);
})();
