import React, { FC } from 'react';

export const ClawIcon: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_49_48581)">
        <path
          d="M23.25 7.75C23.25 9.45 20.713 13.428 19.813 14.786C19.7788 14.8376 19.7324 14.8799 19.6778 14.9091C19.6233 14.9384 19.5624 14.9537 19.5005 14.9537C19.4386 14.9537 19.3777 14.9384 19.3232 14.9091C19.2686 14.8799 19.2222 14.8376 19.188 14.786C18.288 13.429 15.75 9.454 15.75 7.75C15.75 6.75544 16.1451 5.80161 16.8483 5.09835C17.5516 4.39509 18.5054 4 19.5 4C20.4946 4 21.4484 4.39509 22.1517 5.09835C22.8549 5.80161 23.25 6.75544 23.25 7.75Z"
          stroke="#4A61E8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.75 22.75L22.5 17.5"
          stroke="#4A61E8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5549 12.913L15.7419 14.034"
          stroke="#4A61E8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 8.5C6.44347 8.5 7.58807 8.97411 8.43198 9.81802C9.27589 10.6619 9.75 11.8065 9.75 13C9.75 14.921 7.062 18.577 5.841 20.138C5.77085 20.2278 5.68118 20.3004 5.57877 20.3503C5.47637 20.4003 5.36393 20.4262 5.25 20.4262C5.13607 20.4262 5.02363 20.4003 4.92123 20.3503C4.81882 20.3004 4.72915 20.2278 4.659 20.138C3.438 18.576 0.75 14.921 0.75 13C0.75 11.8065 1.22411 10.6619 2.06802 9.81802C2.91193 8.97411 4.05653 8.5 5.25 8.5Z"
          stroke="#4A61E8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.365 5.5C5.29523 5.25584 5.25659 5.00385 5.25 4.75C5.25 3.75544 5.64509 2.80161 6.34835 2.09835C7.05161 1.39509 8.00544 1 9 1C9.99456 1 10.9484 1.39509 11.6517 2.09835C12.3549 2.80161 12.75 3.75544 12.75 4.75C12.5146 6.12569 12.0045 7.43984 11.25 8.614"
          stroke="#4A61E8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 13.375C5.04289 13.375 4.875 13.2071 4.875 13C4.875 12.7929 5.04289 12.625 5.25 12.625"
          stroke="#4A61E8"
          strokeWidth="1.5"
        />
        <path
          d="M5.25 13.375C5.45711 13.375 5.625 13.2071 5.625 13C5.625 12.7929 5.45711 12.625 5.25 12.625"
          stroke="#4A61E8"
          strokeWidth="1.5"
        />
        <path
          d="M9 5.125C8.79289 5.125 8.625 4.95711 8.625 4.75C8.625 4.54289 8.79289 4.375 9 4.375"
          stroke="#4A61E8"
          strokeWidth="1.5"
        />
        <path
          d="M9 5.125C9.20711 5.125 9.375 4.95711 9.375 4.75C9.375 4.54289 9.20711 4.375 9 4.375"
          stroke="#4A61E8"
          strokeWidth="1.5"
        />
        <path
          d="M19.5 8.125C19.2929 8.125 19.125 7.95711 19.125 7.75C19.125 7.54289 19.2929 7.375 19.5 7.375"
          stroke="#4A61E8"
          strokeWidth="1.5"
        />
        <path
          d="M19.5 8.125C19.7071 8.125 19.875 7.95711 19.875 7.75C19.875 7.54289 19.7071 7.375 19.5 7.375"
          stroke="#4A61E8"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_49_48581">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
