/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('navigationHamburger', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/navigation/navigation-hamburger.html',
      transclude: true,
      controller: [
        '$scope',
        function ($scope) {
          $scope.openMenu = function ($mdOpenMenu, ev) {
            $mdOpenMenu(ev);
          };
        }
      ]
    };
  });
})();
