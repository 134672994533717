import { store } from 'app/store';
import React, { FC } from 'react';
import { Provider } from 'react-redux';

import Todo from './Todo/Todo';
import { ThemeProvider } from '@material-ui/core';
import theme from 'Core/Styles/DashboardTheme';

interface ClawDashboardProps {}

const TodoDashboard: FC<ClawDashboardProps> = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Todo />
      </ThemeProvider>
    </Provider>
  );
};
export default TodoDashboard;
