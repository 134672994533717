/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
/* global angular */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  var events = {
    accountActivated: 'account-activated',
    badgeCountsUpdated: 'badgeCountsUpdated',
    calendarProviderUnsynced: 'calendarProviderUnsynced',
    calendarProviderException: 'calendarProviderException',
    openChatThread: 'openChatThread',
    toggleChatRows: 'toggleChatRows',
    startNewChatThread: 'startNewChatThread',
    calendarEventsUpdated: 'calendarEventsUpdated',
    changedListingManager: 'changed-listing-manager',
    'changed-manager-color': 'changed-manager-color',
    prospectUpdated: 'prospectUpdated',
    residentUpdated: 'residentUpdated',
    viewProspect: 'viewProspect',
    viewAppointmentRequests: 'viewAppointmentRequests',
    loggedIn: 'logged-in',
    loggedOut: 'logged-out',
    messageSent: 'messageSent',
    newNotification: 'newNotification',
    toggleNotifications: 'toggleNotifications',
    closeNotifications: 'closeNotifications',
    officeHoursUpdated: 'officeHoursUpdated',
    phoneVerificationUpdated: 'phoneVerificationUpdated',
    profileUpdated: 'profileUpdated',
    listingInfoNotesChanged: 'listing-info-notes-changed',
    listingInfoPendingStatusChanged: 'listing-info-pending-status-changed',
    syncedExternalCalendar: 'syncedExternalCalendar',
    unsyncedExternalCalendar: 'unsyncedExternalCalendar',
    userTimezoneChanged: 'user-timezone-changed',
    teamMemberChanged: 'teamMemberChanged',
    shareLoginChanged: 'shareLoginChanged',
    gotSidebarTodoData: 'gotSidebarTodoData',
    featureFlagsInitialized: 'feature-flags-initialized',
    appDataInitialized: 'appDataInitialized'
  };

  app.constant('apiEvents', events);
})(window.angular);
