import React from 'react';

export const DeleteIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9375 15.75H5.0625C4.44118 15.75 3.9375 15.2463 3.9375 14.625V4.5H14.0625V14.625C14.0625 15.2463 13.5588 15.75 12.9375 15.75Z"
      stroke="#70758F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.3125 12.375V7.875"
      stroke="#70758F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6875 12.375V7.875"
      stroke="#70758F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.6875 4.5H16.3125"
      stroke="#70758F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6875 2.25H7.3125C6.69118 2.25 6.1875 2.75368 6.1875 3.375V4.5H11.8125V3.375C11.8125 2.75368 11.3088 2.25 10.6875 2.25Z"
      stroke="#70758F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
