import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const AvailabilityPathIcon: React.FC = () => {
  return (
    <SvgIcon fontSize="large">
      <path d="M2.19991 8.8249L9.69991 2.59157C9.77213 2.53048 9.86366 2.49696 9.95824 2.49696C10.0528 2.49696 10.1444 2.53048 10.2166 2.59157L17.7166 8.8249C17.8406 8.92717 17.9839 9.00364 18.1379 9.04984C18.2919 9.09605 18.4536 9.11105 18.6134 9.09398C18.7733 9.07691 18.9282 9.0281 19.0689 8.95041C19.2097 8.87273 19.3336 8.76773 19.4332 8.64157C19.6389 8.38344 19.7357 8.05525 19.703 7.72685C19.6703 7.39844 19.5107 7.09576 19.2582 6.88323L11.7582 0.641567C11.2505 0.225896 10.6145 -0.0012207 9.95824 -0.0012207C9.30202 -0.0012207 8.66603 0.225896 8.15824 0.641567L0.658244 6.88323C0.405758 7.09576 0.246191 7.39844 0.213506 7.72685C0.180821 8.05525 0.277601 8.38344 0.483244 8.64157C0.58292 8.76773 0.706772 8.87273 0.847544 8.95041C0.988315 9.0281 1.14318 9.07691 1.30305 9.09398C1.46293 9.11105 1.6246 9.09605 1.7786 9.04984C1.93261 9.00364 2.07584 8.92717 2.19991 8.8249Z" />
      <path d="M18.5667 18.3332V12.4998C18.5603 12.2493 18.5031 12.0027 18.3985 11.7749C18.294 11.5471 18.1442 11.343 17.9584 11.1748L11.0417 5.34151C10.7502 5.09589 10.3812 4.96118 10 4.96118C9.61881 4.96118 9.24987 5.09589 8.95835 5.34151L2.04168 11.1748C1.85582 11.343 1.70606 11.5471 1.60148 11.7749C1.49691 12.0027 1.43971 12.2493 1.43335 12.4998V18.3332C1.43335 18.7752 1.60894 19.1991 1.9215 19.5117C2.23407 19.8242 2.65799 19.9998 3.10002 19.9998H7.55002C7.77103 19.9998 7.98299 19.912 8.13927 19.7558C8.29555 19.5995 8.38335 19.3875 8.38335 19.1665V14.5832C8.38335 14.3622 8.47115 14.1502 8.62743 13.9939C8.78371 13.8376 8.99567 13.7498 9.21668 13.7498H10.8834C11.1044 13.7498 11.3163 13.8376 11.4726 13.9939C11.6289 14.1502 11.7167 14.3622 11.7167 14.5832V19.1665C11.7167 19.3875 11.8045 19.5995 11.9608 19.7558C12.117 19.912 12.329 19.9998 12.55 19.9998H17.0334C17.4519 19.9663 17.8423 19.7759 18.1266 19.4669C18.4109 19.1579 18.568 18.753 18.5667 18.3332Z" />
    </SvgIcon>
  );
};
