import React, { FC, ChangeEvent } from 'react';
import {
  Box,
  makeStyles,
  Text,
  NamedColors
} from '@knockrentals/knock-shared-web';
import { useCompleteDrawerContext } from './CompleteTourDrawerContextProvider';

import {
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup
} from '@material-ui/core';

import { CalendarRejectedIcon, CalendarApprovedIcon } from '../../icons';

interface LabelComponentProps {
  title: string;
  subTitle: string;
}

const useStyles = makeStyles(() => ({
  boxStyle: {
    display: 'flex',
    '& .MuiTypography-body1': {
      fontSize: '15px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontWeight: 400
    }
  },
  visitedStyle: {
    display: 'flex',
    width: '300px',
    height: '82px',
    border: '1px solid',
    borderRadius: '8px',
    padding: '8px 12px 8px 12px',
    color: NamedColors.slate[500],
    borderColor: NamedColors.slate[300],
    marginTop: '18px',
    marginLeft: '6px',
    marginRight: '6px',
    marginBottom: '-14px'
  },
  svg: {
    width: '58px',
    height: '58px',
    paddingTop: '6px'
  },
  colorSelectedBlue: {
    backgroundColor: '#eef9ff',
    borderColor: '#9ad8fc'
  },
  colorSelectedRed: {
    backgroundColor: '#fff5f0',
    borderColor: '#ffc0a0'
  },
  FormControlLabelClass: {
    '& .MuiIconButton-colorSecondary': {
      color: '#4a61e8'
    }
  },
  labelTitle: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '14px',
    color: NamedColors.slate[800]
  }
}));

const CompleteTourBox: FC = () => {
  const classes = useStyles();

  const { tourResult, setData, SetFinishButtonDisableClicked } =
    useCompleteDrawerContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setData({ tourResult: event.target.value });
    SetFinishButtonDisableClicked(false);
  };

  const LabelComponent: FC<LabelComponentProps> = ({ title, subTitle }) => {
    return (
      <Box data-testid="label-component">
        <Text
          variant="body2"
          color="textSecondary"
          data-testid="label-component-box"
          className={classes.labelTitle}
        >
          {title}
        </Text>
        {subTitle}
      </Box>
    );
  };

  return (
    <Box className={classes.boxStyle} data-testid="complete-tour-box">
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue="top"
          onChange={handleChange}
        >
          <Box
            className={`${classes.visitedStyle} ${
              tourResult === 'visited' ? classes.colorSelectedBlue : null
            }`}
            data-testid="complete-tour-box-left"
          >
            <Box className={classes.svg}>
              <CalendarApprovedIcon />
            </Box>

            <FormControlLabel
              value="visited"
              className={classes.FormControlLabelClass}
              control={<Radio />}
              label={
                <LabelComponent
                  title={'Visited'}
                  subTitle={'The prospect completed this tour.'}
                />
              }
              labelPlacement="start"
            />
          </Box>

          <Box
            className={`${classes.visitedStyle} ${
              tourResult === 'no_show' ? classes.colorSelectedRed : null
            }`}
            data-testid="complete-tour-box-right"
          >
            <Box className={classes.svg}>
              <CalendarRejectedIcon />
            </Box>
            <FormControlLabel
              value="no_show"
              className={classes.FormControlLabelClass}
              control={<Radio />}
              label={
                <LabelComponent
                  title={'No Show'}
                  subTitle={'The prospect did not show up for this tour.'}
                />
              }
              labelPlacement="start"
            />
          </Box>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default CompleteTourBox;
