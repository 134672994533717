/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('screeningModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openScreeningModal = function () {
        return $mdDialog.show({
          controller: 'ScreeningModalController',
          templateUrl: '/angular/views/listingIndex/screening-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true
        });
      };

      return self;
    }
  ]);

  app.controller('ScreeningModalController', [
    '$scope',
    '$mdDialog',
    'imageService',
    'managerApi',
    function ($scope, $mdDialog, imageService, managerApi) {
      $scope.experianImage = imageService.createBucketUrl('experian_logo');
      $scope.checkrImage = imageService.createBucketUrl('checkr_logo');

      $scope.markAsInterested = function () {
        $mdDialog.hide();
        managerApi.sendFeatureFeedback('screening');

        var alert = $mdDialog.alert({
          title: 'Screening',
          content: "Awesome, we'll update you when we release this feature!",
          ok: 'Close'
        });

        $mdDialog.show(alert);
      };

      $scope.close = function () {
        $mdDialog.cancel();
      };
    }
  ]);
})(window.angular);
