/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('listingStatusSelect', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/listingIndex/listing-status-select.html',
      controller: 'ListingStatusSelectController'
    };
  });

  app.controller('ListingStatusSelectController', [
    '$scope',
    function ($scope) {
      $scope.validListingStatuses = {
        pending: {
          label: 'Pending',
          icon: 'fa-warning',
          buttonClass: 'md-warn-light',
          textClass: 'md-warn-light'
        },
        hidden: {
          label: 'Hidden',
          icon: 'fa-snapchat-ghost',
          buttonClass: 'md-dull',
          textClass: 'md-dull'
        },
        active: {
          label: 'Active',
          icon: 'fa-check',
          buttonClass: 'md-primary',
          textClass: 'md-primary'
        }
      };
    }
  ]);
})(window.angular);
