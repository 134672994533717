/* eslint-disable */

(function (angular) {
  'use strict';

  var app = angular.module('knock-PropertyReports');

  const ResidentPageController = function (
    $rootScope,
    $scope,
    $location,
    appDataService,
    conversationsService,
    userService,
    $auth,
    managerApi,
    attachmentService,
    streamCarouselService,
    emailValidatorService,
    chatTrackingService,
    timeService
  ) {
    if (
      !$rootScope.featureFlags.RESIDENT_PAGE_REDESIGN &&
      localStorage.getItem('ProspectResidentBeta') !== 'true'
    ) {
      if ($location.search) {
        $location.url(`/reports/residents${window.location.search}`);
      } else {
        $location.url('/reports/residents');
      }
    }

    chatTrackingService.hide();
    $rootScope.$on('SalesForceWidgetMounted', () => {
      chatTrackingService.hide();
    });
    const currentUser = userService.getScopedUser();

    $scope.location = $location;
    $scope.currentUserId = currentUser.id;
    $scope.properties = appDataService.getProperties();
    $scope.managers = appDataService.getTeamMembers();
    $scope.timeService = timeService;
    $scope.token = $auth.getToken();
    $scope.managerInfo = {};
    $scope.mergeTags = [];
    $scope.isCompanyQuickRepliesEnabled =
      $rootScope.featureFlags.COMPANY_QUICK_REPLIES;
    $scope.cheatProofEngagementScoreEnabled =
      $rootScope.appPreferences.company.enable_cheatproof_resident_engagement_score;
    $scope.leasingTeamId = appDataService.getCurrentLeasingTeamId();
    $scope.getManagerQuickReplies = managerApi.getManagerQuickReplies;
    $scope.getManagerQuickReplies = function () {
      return managerApi.getManagerQuickRepliesBulkMessaging(
        $scope.leasingTeamId
      );
    };
    $scope.updateManagerQuickReply = managerApi.updateManagerQuickReply;
    $scope.saveQuickReply = managerApi.updateManagerQuickReply;
    $scope.deleteManagerQuickReply = managerApi.deleteManagerQuickReply;
    $scope.handleAttachments = attachmentService.uploadByFile;
    $scope.deleteAttachment = attachmentService.deleteAttachment;
    $scope.sendBatchMessage = conversationsService.sendMessageBatch;
    $scope.isBulkCommunicationEnhancementEnabled =
      $rootScope.featureFlags.BULK_COMMUNICATION_ENHANCEMENT;

    $scope.isAgentAttributionEnable = $rootScope.featureFlags.AGENT_ATTRIBUTION;

    $scope.emailAttachments = {
      local: [],
      remote: []
    };

    $scope.allowCreateResident =
      $rootScope.appPreferences.company.allowed_property_type === 'mf';
    $scope.validateEmail = emailValidatorService.validate;
    $scope.emailDomainBlocklist = $rootScope.emailDomainBlocklist;

    $scope.openResidentStreamModal = function (streamId) {
      conversationsService.openThreadById(streamId, true);
    };

    $scope.openCarousel = function (streamIds) {
      if (streamIds.length > 0) {
        streamCarouselService.showCarousel(streamIds);
      }
    };

    $scope.removeQueryStringParam = function (paramName) {
      if (paramName) {
        $location.search({ [paramName]: null });
        $scope.$apply();
      }
    };

    let self = this;

    self._getManagerInfo = function () {
      const teamMembers = appDataService.getTeamMembers().map((member) => {
        return {
          id: member.Manager.id,
          name:
            member.ManagerInfo.first_name + ' ' + member.ManagerInfo.last_name
        };
      });

      $scope.managerInfo = teamMembers.find(
        (member) => member.id === userService.getScopedUser().id
      );
    };
    self._getMergeTags = function () {
      $scope.mergeTags = appDataService.getEmailMergeTags('prospect');
      $scope.mergeTags.forEach((mergeTag) => {
        mergeTag.value = `#${mergeTag.name}`;
      });
    };

    self._initialize = function () {
      self._getManagerInfo();
      self._getMergeTags();
    };

    self._initialize();
  };

  ResidentPageController.$inject = [
    '$rootScope',
    '$scope',
    '$location',
    'appDataService',
    'conversationsService',
    'userService',
    '$auth',
    'managerApi',
    'attachmentService',
    'streamCarouselService',
    'emailValidatorService',
    'chatTrackingService',
    'timeService'
  ];

  app.controller('ResidentPageController', ResidentPageController);
})(window.angular);
