/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.filter('limitAndEllipsis', function () {
    return function (input, chars) {
      var limitInput = input;

      if (isNaN(chars)) {
        return limitInput;
      }

      if (chars <= 0) {
        return '';
      }

      if (limitInput && limitInput.length >= chars) {
        limitInput = limitInput.substring(0, chars);

        limitInput = limitInput.trim();

        return limitInput + '...';
      }

      return limitInput;
    };
  });
})();
