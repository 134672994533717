/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import filter from 'lodash/filter';
import map from 'lodash/map';
import find from 'lodash/find';
import includes from 'lodash/includes';
import range from 'lodash/range';
(function (angular, $) {
  'use strict';

  var app = angular.module('knockApp');

  const LeasingTeamPageController = function (
    $window,
    $scope,
    $routeParams,
    $filter,
    $timeout,
    $location,
    chatTrackingService,
    localCache,
    cacheKeys,
    userService,
    pageTitleService,
    teamApi,
    uiGmapIsReady,
    mapUtilitiesService,
    mapService,
    directionsService,
    _,
    $rootScope
  ) {
    var self = this;
    var orderByFilter = $filter('orderBy');

    $scope.managerMailerId = $routeParams.managerMailerId;
    $scope.companyName = $routeParams.companyName;
    $scope.isProfileOwner = false;
    $scope.isExternal = $routeParams.isExternal === 'true';

    $scope.sortByChoices = [
      { sorting: 'leasing.monthlyRent', title: 'Lowest Price' },
      { sorting: '-leasing.monthlyRent', title: 'Highest Price' },
      { sorting: 'leasing.availableDate', title: 'Available Date' }
    ];

    $scope.maxPriceChoices = [
      { title: 'Any price', max: null },
      { title: 'Less than $500', max: 500 },
      { title: 'Less than $1,000', max: 1000 },
      { title: 'Less than $1,500', max: 1500 },
      { title: 'Less than $2,000', max: 2000 }
    ];

    $scope.minBedsChoices = range(0, 5);

    $scope.filters = {
      sortBy: $scope.sortByChoices[0].sorting,
      maxPrice: $scope.maxPriceChoices[0].max,
      minBeds: $scope.minBedsChoices[0]
    };

    $scope.$watch(
      'filters',
      function () {
        self._filterListingsAndMarkers();
      },
      true
    );

    $scope.$watch('viewState', function () {
      $timeout(
        function () {
          $scope.showMap = $scope.viewState === 'map';
        },
        100,
        true
      );

      $location.search('view', $scope.viewState);
    });

    $scope.map = {
      markers: [],
      zoom: 4
    };

    $scope.map.center = localCache.get(cacheKeys.userLocation) || {
      latitude: 39,
      longitude: -98,
      zoom: $scope.map.zoom
    };

    self._initialize = function () {
      chatTrackingService.hide();
      $rootScope.$on('SalesForceWidgetMounted', () => {
        chatTrackingService.hide();
      });
      $scope.isLoading = true;
      $scope.currentUser = userService.getUser();

      var validViewStates = ['list', 'map'];

      $scope.viewState = includes(validViewStates, $routeParams.view)
        ? $routeParams.view
        : 'map';

      teamApi
        .getTeamPageData(
          $routeParams.leasingTeamId,
          $routeParams.managerMailerId
        )
        .then(
          function (response) {
            self._loadListingsSuccess(response.data);

            $scope.team = {
              name: response.data.name
            };

            pageTitleService.setPageTitle(
              $scope.team.name + ' - Schedule a tour today'
            );

            $scope.isLoading = false;
          },
          function () {
            self._loadProfileFailure();
          }
        );
    };

    self._loadCompanyPreferencesSuccess = function (response) {
      $scope.showPriceRanges = response.company.show_listing_price_ranges;
    };

    $(window).resize(function () {
      if (window.innerWidth < 600) {
        $scope.$apply(function () {
          $scope.viewState = 'list';
        });
      }
    });

    $scope.setFilter = function ($event, filterKey, filterValue) {
      $event.preventDefault();
      $scope.filters[filterKey] = filterValue;
    };

    $scope.viewListing = function (listing, showApplication) {
      var queryParams = $location.search();

      function getListingUrl(params) {
        delete params.view;
        var url = '/listing/' + listing.id + '?' + $.param(params);
        return showApplication ? url + '#showApplication' : url;
      }

      if ($scope.isExternal) {
        queryParams.isExternal = true;
        queryParams.companyName = $scope.companyName;
        $location.url(getListingUrl(queryParams));
      } else {
        $window.open(getListingUrl(queryParams));
      }
    };

    $scope.applyNow = function (listing) {
      if (listing.application.link) {
        $window.open(listing.application.link);
        return;
      }

      if (listing.application.instructions) {
        $scope.viewListing(listing, true);
      }
    };

    $scope.setMarkerHighlighted = function ($event, listing, isHighlighted) {
      var marker = find($scope.map.markers, { id: listing.id });

      if (marker) {
        marker.options.labelStyle['background-image'] = isHighlighted
          ? 'url(/images/marker_green.png)'
          : 'url(/images/marker.png)';
      }
    };

    $scope.openDirectionsPage = function () {
      directionsService.openDirectionsPage($scope.profile.location.address.raw);
    };

    self._loadListingsSuccess = function (res) {
      $scope.activeListings = map(res.listings, function (listing) {
        return {
          id: listing.id,
          location: listing.location,
          photos: listing.photos,
          leasing: listing.leasing,
          floorplan: listing.floorplan,
          coverPhoto: listing.coverPhoto,
          isPending: listing.status === 'pending',
          application: listing.leasing.application
        };
      });

      $scope.hasActivelisting = $scope.activeListings.length > 0;

      self._filterListingsAndMarkers();

      uiGmapIsReady.promise().then(function (instance) {
        $scope.mapInstance = instance[0].map;

        self._filterListingsAndMarkers();
      });
    };

    self._filterListingsAndMarkers = function () {
      $scope.filteredListings = filter(
        $scope.activeListings,
        function (listing) {
          var passesPrice =
            !$scope.filters.maxPrice ||
            listing.leasing.monthlyRent <= $scope.filters.maxPrice;
          var passesBeds = listing.floorplan.bedrooms >= $scope.filters.minBeds;

          return passesPrice && passesBeds;
        }
      );

      $scope.filteredListings = orderByFilter(
        $scope.filteredListings,
        $scope.filters.sortBy
      );
      $scope.map.markers = map(
        $scope.filteredListings,
        self._mapListingToMarker
      );

      $timeout(
        function () {
          mapService.zoomToContext(
            $scope.mapInstance,
            $scope.map.markers,
            null,
            true
          );
        },
        1000,
        true
      );
    };

    self._mapListingToMarker = function (listing, index) {
      var coords = listing.location.geo.coordinates;
      return mapUtilitiesService.createListingMarker(
        listing.id,
        coords,
        listing,
        '/views/leasingTeam/leasing-team-map-info-window.html',
        index + 1
      );
    };

    self._loadProfileFailure = function () {
      $scope.profileLoadError = true;
    };

    self._initialize();
  };

  LeasingTeamPageController.$inject = [
    '$window',
    '$scope',
    '$routeParams',
    '$filter',
    '$timeout',
    '$location',
    'chatTrackingService',
    'localCache',
    'cacheKeys',
    'userService',
    'pageTitleService',
    'teamApi',
    'uiGmapIsReady',
    'mapUtilitiesService',
    'mapService',
    'directionsService',
    '_',
    '$rootScope'
  ];

  app.controller('LeasingTeamPageController', LeasingTeamPageController);
})(window.angular, window.jQuery);
