import React, { FC } from 'react';
import { useProspects } from 'app/services/prospects/hooks';
import {
  Checkbox,
  ListItem,
  List,
  ListItemText,
  Box,
  Slide
} from '@material-ui/core';

import { makeStyles, Text } from '@knockrentals/knock-shared-web';
import CheckboxSkeleton from '../../Skeleton/CheckboxSkeleton';
import { useMarkAsLostContext } from './MarkAsLostDrawerContextProvider';
const useStyles = makeStyles({
  paneContentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around'
  },
  title: {
    fontSize: '14px'
  },
  listContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  listItem: {
    width: '50%'
  },
  alert: {
    marginBottom: '24px'
  },
  alertTitle: {
    fontWeight: 600
  }
});

const LossReasonsStep: FC = () => {
  const classes = useStyles();

  const { selectedReasons, setSelectedReasons, slideDirection, setErrorAlert } =
    useMarkAsLostContext();

  const { lossReasons, lossReasonsError } = useProspects();

  const falseArray = React.useMemo<Array<undefined | number>>(() => {
    const arr = new Array(70);
    arr.fill(0);
    return arr;
  }, []);

  const handleToggle = (option: string) => {
    if (selectedReasons.indexOf(option) !== -1) {
      setSelectedReasons((prevState: string[]) =>
        prevState.filter((value: string) => value !== option)
      );
    } else {
      setSelectedReasons((prevState: string[]) => {
        prevState.push(option);
        return [...prevState];
      });
    }
  };

  React.useEffect(() => {
    if (lossReasonsError) {
      setErrorAlert({
        title: 'Unable to load loss reasons',
        message: 'Please try again later'
      });
    }
  }, [lossReasonsError, setErrorAlert]);
  return (
    <Slide direction={slideDirection} in={true}>
      <Box>
        <Box>
          <Text className={classes.title} color="secondary">
            Select the reasons this prospect did not continue to lease at this
            property.
          </Text>
        </Box>
        <Box className={classes.paneContentContainer}>
          {lossReasons === undefined ? (
            falseArray.map((_: undefined | number, i: number) => {
              return (
                <CheckboxSkeleton
                  animate={lossReasonsError === undefined}
                  key={'sk' + i}
                />
              );
            })
          ) : (
            <List className={classes.listContainer}>
              {lossReasons.map((value: string, i: number) => (
                <ListItem
                  key={value}
                  role={undefined}
                  className={classes.listItem}
                >
                  <Checkbox
                    edge="start"
                    checked={selectedReasons.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    onClick={() => handleToggle(value)}
                    color="primary"
                    inputProps={{
                      'aria-labelledby': `checkbox-list-label-${value}`
                    }}
                  />
                  <ListItemText
                    id={`checkbox-list-label-${value}`}
                    primary={value}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Box>
    </Slide>
  );
};

export default LossReasonsStep;
