import React, { FC } from 'react';

import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Text
} from '@knockrentals/knock-shared-web';
import { NotesSection } from '../models';
import { GreenCheckIcon } from '../../icons';

const useStyles = makeStyles(() => ({
  sectionHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px'
  },

  sectionContent: {
    display: 'flex',
    marginBottom: '10px',

    '&:last-child': {
      marginBottom: 0
    },

    '& svg': {
      marginRight: '10px',
      marginTop: '4px',
      minHeight: '16px',
      minWidth: '18px'
    }
  },

  noteContent: {
    wordBreak: 'break-all'
  }
}));

interface NotesCardProps {
  section: NotesSection;
  className?: string;
}

export const NotesCard: FC<NotesCardProps> = ({ section, className }) => {
  const classes = useStyles();

  return (
    <Card
      key={section.title}
      className={className ? className : undefined}
      elevation={0}
    >
      <CardContent>
        <Box className={classes.sectionHeader}>
          <Text variant="body2">{section.title}</Text>
          {section.icon}
        </Box>

        {section.items.map((item: string, index: number) => {
          return item ? (
            <Box key={index} className={classes.sectionContent}>
              <GreenCheckIcon />
              <Text variant="body1" className={classes.noteContent}>
                {item}
              </Text>
            </Box>
          ) : null;
        })}
      </CardContent>
    </Card>
  );
};
