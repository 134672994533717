/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import includes from 'lodash/includes';
import isString from 'lodash/isString';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('applicationNotificationService', [
    'applicationNotificationModalService',
    'userService',
    'appDataService',
    function (
      applicationNotificationModalService,
      userService,
      appDataService
    ) {
      var self = this;
      var channelName = 'public-application-notifications';

      self._client = null;
      self._channel = null;

      self.events = {
        displayModal: 'display-modal'
      };

      self.levels = {
        update: 'update',
        info: 'info',
        warning: 'warning',
        error: 'error'
      };

      self.initialize = function (client) {
        if (!self._client) {
          self._client = client;
        }

        if (!self._channel) {
          self._channel = self._client.subscribe(channelName);
          self._channel.bind(self.events.displayModal, self._displayModal);
        }
      };

      self._shouldDisplayModal = function (modalPayload) {
        const user = userService.getScopedUser();

        if (isEmpty(user)) {
          return;
        }

        const userTypes = modalPayload.userTypes || ['manager', 'renter'];
        if (!includes(userTypes, user.type)) {
          return;
        }

        // Filter out based on filter criteria, if provided.
        // filters uses "OR" logic. If any of the criteria are true, the modal will be displayed.
        if (modalPayload.filters) {
          // Managers
          // If this user is any of these managers, return true.
          const managerIds = modalPayload.filters.managerIds;
          if (managerIds && managerIds.length && Array.isArray(managerIds)) {
            if (managerIds.includes(String(user.id))) {
              return true;
            }
          }

          // Leasing Teams
          // If this user is a member of any of these leasing teams, return true.
          const leasingTeamIds = modalPayload.filters.leasingTeamIds;
          if (
            leasingTeamIds &&
            leasingTeamIds.length &&
            Array.isArray(leasingTeamIds)
          ) {
            const currentLeasingTeamId =
              appDataService.getCurrentLeasingTeamId();
            if (leasingTeamIds.includes(String(currentLeasingTeamId))) {
              return true;
            }
          }

          // Properties
          // If this user is a manager at any of these properties, return true.
          const propertyIds = modalPayload.filters.propertyIds;
          if (propertyIds && propertyIds.length && Array.isArray(propertyIds)) {
            for (let propertyId of propertyIds) {
              if (appDataService.getProperty(String(propertyId)) !== null) {
                return true;
              }
            }
          }

          // Companies
          // If this user is a part of any of these companies, return true.
          const companyIds = modalPayload.filters.companyIds;
          if (companyIds && companyIds.length && Array.isArray(companyIds)) {
            if (companyIds.includes(String(user.company_id))) {
              return true;
            }
          }
        } else {
          // No filters provided? Default to true.
          return true;
        }
        // No filters passed? Return false.
        return false;
      };

      self._displayModal = function (data) {
        if (!self._shouldDisplayModal(data)) {
          return;
        }

        var level = self.levels[data.level];
        var title = data.title || 'Message from Knock';
        var text = data.text;
        var bullets = data.bullets || null;
        var askForRefresh = isBoolean(data.askForRefresh)
          ? data.askForRefresh
          : false;
        var defaultLabel = askForRefresh ? 'Refresh' : 'Okay';

        var buttonLabel = data.buttonLabel || defaultLabel;

        if (!isString(level)) {
          throw 'invalid application notification level: ' + data.level;
        }

        if (!isString(text) || text.length === 0) {
          throw 'invalid application notification message: ' + data.text;
        }

        applicationNotificationModalService.displayModal({
          level: level,
          title: title,
          text: text,
          bullets: bullets,
          askForRefresh: askForRefresh,
          buttonLabel: buttonLabel
        });
      };

      return self;
    }
  ]);
})(window.angular);
