/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('kanbanSection', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/kanban/kanban-section.html',
      transclude: true,
      replace: true
    };
  });
})(window.angular);
