import React, { FC, useState } from 'react';
import classnames from 'classnames';

import { MergeDefaultTypeKeys } from './MergeData';

type ValueTypes =
  | string
  | number
  | number[]
  | boolean
  | string[]
  | null
  | undefined;

interface MergeFieldProps {
  label: string;
  fieldKey: MergeDefaultTypeKeys;
  masterId: number;
  masterValue: ValueTypes;
  secondaryId: number;
  secondaryValue: ValueTypes;
  defaultValue: number | null | undefined;
  setDecision: (
    key: MergeDefaultTypeKeys,
    value: number | null | undefined
  ) => void;
  locked: boolean;
}

const MergeField: FC<MergeFieldProps> = ({
  label,
  fieldKey,
  masterValue,
  masterId,
  secondaryValue,
  secondaryId,
  defaultValue,
  setDecision,
  locked
}) => {
  const [selected, setSelected] = useState<number | null | undefined>(
    defaultValue
  );

  const handleSelection = (id: number | null | undefined) => {
    setSelected(id);
    setDecision(fieldKey, id);
  };

  const masterActive =
    selected === masterId || (secondaryId === null && masterId !== null);
  const secondaryActive =
    selected === secondaryId || (masterId === null && secondaryId !== null);

  return (
    <tr>
      <td>{label}</td>
      <td
        className={classnames({
          active: masterActive,
          disabled: locked
        })}
        onClick={() => handleSelection(selected === masterId ? null : masterId)}
      >
        <div className="icon-container">
          {masterActive ? (
            locked ? (
              <i className="fa fa-lock" />
            ) : (
              <i className="fa fa-check" />
            )
          ) : null}
        </div>
        {typeof masterValue !== 'undefined' && masterValue !== null ? (
          fieldKey === 'address' ? (
            <span dangerouslySetInnerHTML={{ __html: masterValue as string }} />
          ) : (
            masterValue.toString()
          )
        ) : (
          ''
        )}
      </td>
      <td
        className={classnames({
          active: secondaryActive,
          disabled: locked
        })}
        onClick={() =>
          handleSelection(selected === secondaryId ? null : secondaryId)
        }
      >
        <div className="icon-container">
          {secondaryActive ? (
            locked ? (
              <i className="fa fa-lock" />
            ) : (
              <i className="fa fa-check" />
            )
          ) : null}
        </div>
        {typeof secondaryValue !== 'undefined' && secondaryValue !== null ? (
          fieldKey === 'address' ? (
            <span
              dangerouslySetInnerHTML={{ __html: secondaryValue as string }}
            />
          ) : (
            secondaryValue.toString()
          )
        ) : (
          ''
        )}
      </td>
    </tr>
  );
};

export default MergeField;
