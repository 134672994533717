import React from 'react';

export const RealEstateIcon = () => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 16.5V6M3 16.5L1.5 15M3 16.5L4.5 15M3 6L4.5 7.5M3 6L1.5 7.5M6 3H16.5M6 3L7.5 1.5M6 3L7.5 4.5M16.5 3L15 4.5M16.5 3L15 1.5M8.5 9.7V14.5C8.5 15.0523 8.94772 15.5 9.5 15.5H13.5C14.0523 15.5 14.5 15.0523 14.5 14.5V9.7M16.5 11.5L12.1687 7.6C11.7885 7.25808 11.2115 7.25808 10.8313 7.6L6.5 11.5"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
