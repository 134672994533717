import dayjs from 'dayjs';

export const getInitialsFromFullName = (name: string): string => {
  let initials = '';

  if (name) {
    const nameParts = name.split(' ');

    if (nameParts.length === 1) {
      initials = `${nameParts[0][0] || ''}`;
    } else {
      initials = `${nameParts[0][0] || ''}${nameParts[1][0] || ''}`;
    }

    initials = initials.toUpperCase();
  }

  return initials;
};

export const displayPrice = (price: number): string => {
  return price ? `$${Number(price).toLocaleString()}` : '';
};

export const truncateString = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
};
export const differnceInDateFromToday = (input: string) => {
  const todayObject = dayjs(dayjs().format('YYYY-MM-DD'));
  const inputDate = dayjs(input);
  return inputDate.diff(todayObject, 'day');
};
