import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, AxiosRequestHeaders } from 'axios';

import { getAuthToken } from 'LegacyAngularApp/legacy-angular-app/services/tokenService';
import { APP_CONFIG } from 'APP_CONFIG';

const apiV2AxiosInstance = axios.create({ baseURL: APP_CONFIG.API.host });

export const axiosBaseQuery =
  (): BaseQueryFn =>
  async ({ noAuthAsLeasingTeam, ...requestOpts }) => {
    try {
      const token = getAuthToken();
      const scopedLeasingTeamId = window.sessionStorage.scopedLeasingTeamId;
      const headers: AxiosRequestHeaders = { Authorization: `Bearer ${token}` };

      if (scopedLeasingTeamId && !noAuthAsLeasingTeam) {
        headers['X-Knock-Auth-As-Leasing-Team'] = scopedLeasingTeamId;
      }

      const result = await apiV2AxiosInstance({
        ...requestOpts,
        headers
      });

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      return {
        error: { status: err.response?.status, data: err.response?.data }
      };
    }
  };
