import React, { FC } from 'react';

export const CalendarCashIcon: FC = () => (
  <svg
    className="calendar-cash-icon"
    width="18"
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0625 10.6875H12.5378C12.3037 10.6872 12.0769 10.7685 11.8964 10.9174C11.7158 11.0662 11.5927 11.2733 11.5484 11.5031C11.504 11.7328 11.541 11.9709 11.6532 12.1763C11.7653 12.3817 11.9455 12.5416 12.1628 12.6285L13.7108 13.2473C13.9275 13.3345 14.1072 13.4944 14.219 13.6996C14.3307 13.9049 14.3676 14.1426 14.3233 14.372C14.2789 14.6014 14.1561 14.8083 13.9759 14.9571C13.7958 15.1059 13.5694 15.1873 13.3358 15.1875H11.8125"
      stroke="#697FF7"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9375 10.6875V10.125"
      stroke="#697FF7"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9375 15.75V15.1875"
      stroke="#697FF7"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.4375 12.9375C8.4375 14.131 8.91161 15.2756 9.75552 16.1195C10.5994 16.9634 11.744 17.4375 12.9375 17.4375C14.131 17.4375 15.2756 16.9634 16.1195 16.1195C16.9634 15.2756 17.4375 14.131 17.4375 12.9375C17.4375 11.744 16.9634 10.5994 16.1195 9.75552C15.2756 8.91161 14.131 8.4375 12.9375 8.4375C11.744 8.4375 10.5994 8.91161 9.75552 9.75552C8.91161 10.5994 8.4375 11.744 8.4375 12.9375Z"
      stroke="#697FF7"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.1875 12.9375H1.6875C1.38913 12.9375 1.10298 12.819 0.892005 12.608C0.681026 12.397 0.5625 12.1108 0.5625 11.8125V2.82373C0.5625 2.52536 0.681026 2.23921 0.892005 2.02824C1.10298 1.81726 1.38913 1.69873 1.6875 1.69873H11.8125C12.1109 1.69873 12.397 1.81726 12.608 2.02824C12.819 2.23921 12.9375 2.52536 12.9375 2.82373V6.18748"
      stroke="#697FF7"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.5625 5.0625H12.9375"
      stroke="#697FF7"
      strokeWidth="1.125"
      strokeLinejoin="round"
    />
    <path
      d="M3.93225 2.8125V0.5625"
      stroke="#697FF7"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.55725 2.8125V0.5625"
      stroke="#697FF7"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
