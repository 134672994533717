import React, { FC } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: React.ReactNode;
}

const InsideAngularViewportPortal: FC<PortalProps> = ({ children }) => {
  return (
    <>
      {createPortal(
        children,
        window.document.getElementById('angular-viewport') ||
          window.document.body
      )}
    </>
  );
};

export default InsideAngularViewportPortal;
