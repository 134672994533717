/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('residentCard', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/residents/resident-card.html',
      controller: 'ResidentCardController',
      scope: {
        resident: '=',
        residentSelectedChanged: '='
      },
      replace: true
    };
  });

  const ResidentCardController = function (
    $scope,
    residentService,
    conversationsService
  ) {
    $scope.daysUntilLeaseEnd = $scope.resident.lease_end
      ? residentService.getDaysUntilLeaseEnd($scope.resident)
      : null;

    $scope.openResidentStream = function ($event) {
      if ($event.target.className !== 'md-container') {
        conversationsService.openThreadById($scope.resident.stream_id, true);
      }
    };
  };

  ResidentCardController.$inject = [
    '$scope',
    'residentService',
    'conversationsService'
  ];

  app.controller('ResidentCardController', ResidentCardController);
})(window.angular);
