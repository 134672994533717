/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Todo');

  app.factory('todoService', [
    '$rootScope',
    'apiBase',
    'userService',
    'prospectHistoryService',
    'prospectAppointmentModalFactory',
    function (
      $rootScope,
      apiBase,
      userService,
      prospectHistoryService,
      prospectAppointmentModalFactory
    ) {
      var self = this;

      self.events = {
        openTodoListItem: 'open-todo-list-item',
        reloadDashboard: 'reloadDashboard'
      };

      self.getUser = function () {
        return userService.getUser();
      };

      self.reloadDashboard = function () {
        $rootScope.$emit(self.events.reloadDashboard);
      };

      self.getTodoPreferences = function (managerId) {
        return apiBase.get('/manager/' + managerId + '/todo-preferences');
      };

      self.updateTodoPreferences = function (managerId, preferences) {
        return apiBase.post(
          '/manager/' + managerId + '/todo-preferences',
          preferences
        );
      };

      self.openEditModal = function (todoItems) {
        return prospectHistoryService.openEditProspectsModal(
          map(todoItems, 'info')
        );
      };

      self.addAppointmentForProspect = function (prospectId) {
        return prospectAppointmentModalFactory.openAddProspectAppointmentModal(
          prospectId
        );
      };

      return self;
    }
  ]);
})(window.angular);
