import React, { FC, useEffect, useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  Typography
} from '@material-ui/core';

import { CircularProgressButton } from '../../CircularProgressButton';
import { ErrorAlert } from '../ErrorAlert';
import { Quote, Unit } from '../../../ts/models';
import { CurrencyDisplay } from '../../Currency';
import { DocumentIcon } from '../../icons/DocumentIcon';
import { CloseIcon } from '../../icons/CloseIcon';
import { getShortFilename } from '../utils';
import { formatDate } from 'LegacyAngularApp/legacy-angular-app/utilities/timeUtilities';

export interface QuoteSummaryViewProps {
  attachQuoteErrorMessage: string;
  attachQuoteToMessage: (quote: Quote) => void;
  isSendQuoteDisabled?: boolean;
  handleOnClose: () => void;
  quote: Quote;
  unit: Unit;
}

const QuoteSummaryView: FC<QuoteSummaryViewProps> = ({
  attachQuoteErrorMessage,
  attachQuoteToMessage,
  handleOnClose,
  quote,
  unit,
  isSendQuoteDisabled
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (attachQuoteErrorMessage) {
      setIsLoading(false);
    }
  }, [attachQuoteErrorMessage]);

  const handleOnClickAttachQuote = () => {
    setIsLoading(true);
    attachQuoteToMessage(quote);
    handleOnClose();
  };

  return (
    <React.Fragment>
      <DialogTitle disableTypography>
        <Typography variant="h6">
          Quote Created
          <IconButton
            className="quote-summary-close-button"
            size="small"
            onClick={handleOnClose}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        <ErrorAlert alertMessage={attachQuoteErrorMessage} />

        <Typography variant="body2">
          You have just created a quote for this prospect! This quote will now
          appear in the details section of the prospect card.
        </Typography>

        <Link
          className="quote-summary-view-pdf-link"
          color="primary"
          href={quote.url}
          rel="noopener noreferrer"
          target="_blank"
          underline="hover"
          variant="subtitle2"
        >
          <DocumentIcon />
          {getShortFilename(quote.url)}
        </Link>

        <Typography variant="subtitle2">Quote Summary</Typography>

        <List dense>
          <ListItem disableGutters>
            <Typography variant="body2">
              <span className="quote-summary-display-title">Apt #</span>
              <span className="quote-summary-display-value">{unit?.name}</span>
            </Typography>
          </ListItem>

          <ListItem disableGutters>
            <Typography variant="body2">
              <span className="quote-summary-display-title">Building</span>
              <span className="quote-summary-display-value">
                {unit?.buildingName}
              </span>
            </Typography>
          </ListItem>

          <ListItem disableGutters>
            <Typography variant="body2">
              <span className="quote-summary-display-title">Monthly Rent</span>
              <CurrencyDisplay
                className="unit-details-value"
                value={quote.quotedRent}
              />
            </Typography>
          </ListItem>

          <ListItem disableGutters>
            <Typography variant="body2">
              <span className="quote-summary-display-title">Lease Term</span>
              <span className="quote-summary-display-value">
                {quote.leaseTermMonths} months
              </span>
            </Typography>
          </ListItem>

          <ListItem disableGutters>
            <Typography variant="body2">
              <span className="quote-summary-display-title">Lease Start</span>
              <span className="quote-summary-display-value">
                {formatDate(quote.leaseStartDate)}
              </span>
            </Typography>
          </ListItem>

          <ListItem disableGutters>
            <Typography variant="body2">
              <span className="quote-summary-display-title">Lease End</span>
              <span className="quote-summary-display-value">
                {formatDate(quote.leaseEndDate)}
              </span>
            </Typography>
          </ListItem>
        </List>
      </DialogContent>

      <DialogActions>
        <CircularProgressButton
          onClick={handleOnClickAttachQuote}
          disabled={isSendQuoteDisabled || false}
          progressText={'Attaching quote...'}
          shouldShowProgress={isLoading}
          tooltipText={isSendQuoteDisabled ? 'No email or SMS on file' : ''}
        >
          Attach to Message
        </CircularProgressButton>
      </DialogActions>
    </React.Fragment>
  );
};

export default QuoteSummaryView;
