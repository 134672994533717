import React, { FC } from 'react';

export const HappyIcon: FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18.4375C14.6599 18.4375 18.4375 14.6599 18.4375 10C18.4375 5.3401 14.6599 1.5625 10 1.5625C5.3401 1.5625 1.5625 5.3401 1.5625 10C1.5625 14.6599 5.3401 18.4375 10 18.4375Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.1875 7.46875C7.34283 7.46875 7.46875 7.59467 7.46875 7.75C7.46875 7.90533 7.34283 8.03125 7.1875 8.03125C7.03217 8.03125 6.90625 7.90533 6.90625 7.75C6.90625 7.59467 7.03217 7.46875 7.1875 7.46875"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8125 7.46875C12.9678 7.46875 13.0938 7.59467 13.0938 7.75C13.0938 7.90533 12.9678 8.03125 12.8125 8.03125C12.6572 8.03125 12.5312 7.90533 12.5312 7.75C12.5312 7.59467 12.6572 7.46875 12.8125 7.46875"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5361 12.25C13.6815 13.9746 11.9232 15.0656 9.99856 15.0656C8.07389 15.0656 6.3156 13.9746 5.46106 12.25"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
