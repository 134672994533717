import React, { FC } from 'react';

import { ThemeProvider } from '@knockrentals/knock-shared-web';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Resident {
  id: number;
  name: string;
  hotLead?: boolean;
}
interface ResidentListProps {
  residents: Resident[];
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    fontWeight: 400
  },
  namesContainer: {
    display: 'flex',
    alignItems: 'center'
  }
});

const ResidentList: FC<ResidentListProps> = ({ residents }) => {
  const { container, namesContainer } = useStyles();

  return (
    <Box className={container}>
      {residents.slice(0, 2).map((resident: Resident, index: number) => {
        return (
          <Box key={resident.id} className={namesContainer}>
            <Box component="span">{resident.name}</Box>
            {residents.length > 2 || (index === 0 && residents.length > 1)
              ? ','
              : ''}
            &nbsp;
          </Box>
        );
      })}
      {residents.length > 2 && (
        <Box className={namesContainer}>and {residents?.length - 2} more</Box>
      )}
    </Box>
  );
};

export default (props: ResidentListProps) => (
  <ThemeProvider>
    <ResidentList {...props} />
  </ThemeProvider>
);
