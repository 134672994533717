/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import filter from 'lodash/filter';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('renterAppointmentsService', [
    '$window',
    'appointmentsApi',
    'timeService',
    function ($window, appointmentsApi, timeService) {
      var renterAppointmentsService = {};

      renterAppointmentsService.getAppointments = function (
        startTime,
        callback
      ) {
        var appointmentsQuery = {
          start: startTime,
          renterInterested: true
        };

        appointmentsApi
          .getAppointments(appointmentsQuery)
          .success(function (response) {
            var appointments = response.appointments;

            var pending = filter(appointments, { status: 'pending' });
            var confirmed = filter(appointments, { status: 'confirmed' });

            // TODO CONFIRMED
            var visited = [];

            callback({
              all: appointments,
              confirmed: confirmed,
              pending: pending,
              visited: visited
            });
          });
      };

      renterAppointmentsService.viewAppointmentListing = function (
        appointment
      ) {
        $window.open('/listing/' + appointment.listing.id);
      };

      return renterAppointmentsService;
    }
  ]);
})();
