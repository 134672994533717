/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('messageEditor', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/message-editor.html',
      controller: 'MessageEditorController',
      scope: {
        prospect: '=',
        conversation: '=',
        message: '=',
        hideSendButton: '=?'
      }
    };
  });

  app.controller('MessageEditorController', [
    '$scope',
    'userService',
    'conversationsService',
    function ($scope, userService, conversationsService) {
      var self = this;

      self._messageTargetCharacterLimits = {
        sms: 150
      };

      $scope.currentUser = userService.getUser();
      $scope.emojiPickerContext = 'MessageEditor';

      $scope.data = {
        maxCharacters: self._messageTargetCharacterLimits.sms,
        messageText: $scope.message.text
      };

      $scope.$watch('data.messageText', function () {
        $scope.message.text = $scope.data.messageText;
      });

      self._initialize = function () {
        if (!$scope.message.target || $scope.message.target === 'sms') {
          if ($scope.prospect) {
            if (!$scope.prospect.profile.phone) {
              $scope.message.target = 'email';
            }

            $scope.isRenterMailer = conversationsService.isRenterMailer(
              $scope.prospect.profile.email
            );

            if ($scope.isRenterMailer) {
              $scope.message.target = 'sms';
            }
          }
        }
      };

      $scope.tryUpdateMaxCharacters = function () {
        self._updateMaxCharacters();
      };

      self._updateMaxCharacters = function () {
        if ($scope.message.target === 'sms') {
          $scope.message.text = $scope.message.text.substr(
            0,
            self._messageTargetCharacterLimits.sms
          );
          $scope.data.maxCharacters = self._messageTargetCharacterLimits.sms;
        }
      };

      self._initialize();
    }
  ]);
})(window.angular);
