(function (angular, $) {
  const app = angular.module('knock-Conversations');

  app.directive('conversationIframeManager', [
    'MessageFormatterService',
    function () {
      return {
        restrict: 'E',
        templateUrl:
          '/angular/views/conversations/conversation-iframe-manager.html',
        scope: {
          stream: '=',
          message: '=',
          isEmail: '=',
          thisUser: '=',
          otherUser: '=',
          onSetReplyingTo: '='
        },
        link: function (scope, element) {
          let $iframe = $(element).find('iframe');

          scope.$watch(
            'data.parsedMessageHtml',
            function (newMessage, oldMessage) {
              if (newMessage && oldMessage) {
                var messageBody = scope.data.parsedMessageHtml;
                var messageFooter = !scope.message.footer
                  ? ''
                  : scope.message.footer;
                var messageFull = messageBody + messageFooter;
                $iframe.attr('srcdoc', messageFull);
              }
            }
          );

          $iframe.load(function () {
            let contents = $(this).contents();

            contents.find('head').prepend('<base target="_blank" />');

            contents
              .find('head')
              .append(
                $(
                  '<style type="text/css">' +
                    'html { color: #2c2d30; font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; font-size: 13px; }\n' +
                    'body { margin: 0 0 10px; padding: 0; }' +
                    '</style>'
                )
              );

            $iframe.removeClass('ng-hide');
            const contentHeight = contents.find('html').outerHeight();
            if (!scope.data.isFullMessage) $iframe.addClass('ng-hide');
            $iframe.css('height', contentHeight + 5);
          });
        },
        controller: 'ConversationIframeManagerController'
      };
    }
  ]);

  app.controller('ConversationIframeManagerController', [
    '$scope',
    'MessageFormatterService',
    function ($scope, MessageFormatterService) {
      $scope.data = {
        parsedMessageHtml: '',
        isFullMessage: false,
        decodedText: ''
      };

      $scope.data.isFullMessage = !$scope.data.isFullMessage;

      $scope.$watch('message', function (newMessage, oldMessage) {
        if (newMessage && oldMessage) {
          const friendlyHtml = MessageFormatterService.convertToFriendlyUrl(
            $scope.message.html,
            true
          );
          $scope.data.parsedMessageHtml =
            MessageFormatterService.parseMessageHtml(friendlyHtml);

          $scope.data.decodedText = MessageFormatterService.decodeText(
            $scope.message.text
          );
          $scope.data.isFullMessage = !$scope.message.text;
        }
      });

      $scope.showFullMessage = function (message) {
        $scope.data.isFullMessage = !$scope.data.isFullMessage;
      };
    }
  ]);
})(window.angular, window.jQuery);
