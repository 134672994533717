import React, { FC } from 'react';

export const VerticalDotsIcon: FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 17.5C12.5 16.1193 11.3807 15 10 15C8.61929 15 7.5 16.1193 7.5 17.5C7.5 18.8807 8.61929 20 10 20C11.3807 20 12.5 18.8807 12.5 17.5Z"
      fill="#353950"
    />
    <path
      d="M12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5C11.3807 12.5 12.5 11.3807 12.5 10Z"
      fill="#353950"
    />
    <path
      d="M12.5 2.5C12.5 1.11929 11.3807 0 10 0C8.61929 0 7.5 1.11929 7.5 2.5C7.5 3.88071 8.61929 5 10 5C11.3807 5 12.5 3.88071 12.5 2.5Z"
      fill="#353950"
    />
  </svg>
);
