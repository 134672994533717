import { api as baseApi } from 'app/services/api';
import { getAuthToken } from '../../../../../LegacyAngularApp/legacy-angular-app/services/tokenService';
import { APP_CONFIG } from 'APP_CONFIG';
const API_HOST = APP_CONFIG.API.host as string;

export const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBadges: build.query<any, void>({
      query: () => {
        return {
          url: `${API_HOST}/me/leasing-teams/badges`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAuthToken()}`
          }
        };
      },
      transformResponse: (data: any) => {
        return data.leasing_team_badges || [];
      }
    })
  })
});
