import React, { FC } from 'react';

import { IconButton } from '@knockrentals/knock-shared-web';
import { ReferIcon } from './ReferIcon';
import { Tooltip } from '../Tooltip';

export interface ReferIconProps {
  tooltipText: string;
  isDisabled?: boolean;
  handleOnClick?: () => void;
}

export const ReferIconButton: FC<ReferIconProps> = ({
  tooltipText,
  isDisabled,
  handleOnClick = () => {}
}) => (
  <Tooltip title={tooltipText}>
    <span>
      <IconButton
        name="referIconButton"
        disabled={isDisabled}
        onClick={handleOnClick}
      >
        <ReferIcon />
      </IconButton>
    </span>
  </Tooltip>
);
