/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('colorConversionService', [
    function () {
      function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      }

      return {
        rbgToHex: function (r, g, b) {
          return (
            '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
          );
        },
        hexToRgb: function (hex) {
          var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
          return result
            ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
              }
            : null;
        }
      };
    }
  ]);
})(window.angular);
