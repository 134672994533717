/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import sample from 'lodash/sample';
import find from 'lodash/find';
import remove from 'lodash/remove';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('teamMemberAppointments', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/calendar/team-member-appointments.html',
      controller: 'TeamMemberAppointmentsController',
      scope: {
        appointments: '=',
        requests: '=',
        proposals: '=',
        onCalendarUpdated: '=',
        teamMember: '=',
        teamMembers: '=',
        calendarSettings: '='
      }
    };
  });

  const TeamMemberAppointmentsController = function (
    $rootScope,
    $scope,
    userService,
    managerCalendarEventsService,
    managerCalendarService,
    colorConversionService
  ) {
    var self = this;

    $scope.user = userService.getUser();

    $scope.requests = $scope.requests || {};
    $scope.appointments = $scope.appointments || [];
    $scope.proposals = $scope.proposals || {};
    $scope.managerColorRgb = colorConversionService.hexToRgb(
      $scope.teamMember.color
    );

    $scope.onSelectedChanged = function () {
      var isSelected =
        $scope.calendarSettings.selectedManagers[$scope.teamMember.managerId];

      if (isSelected) {
        managerCalendarEventsService.eventSourceWasAdded(
          'teamMember' + $scope.teamMember.managerId
        );
      } else {
        managerCalendarEventsService.eventSourceWasRemoved(
          'teamMember' + $scope.teamMember.managerId
        );
      }
    };

    var eventHandlers = [];

    self.addEventHandler = function (event, handler) {
      eventHandlers.push($rootScope.$on(event, handler));
    };

    $scope.$on('$destroy', function () {
      forEach(eventHandlers, function (destroy) {
        destroy();
      });
    });

    var eventTypes = managerCalendarEventsService.eventTypes;

    self.addEventHandler(
      eventTypes.pendingRequest.wasAccepted,
      function (event, request) {
        var requestKey = managerCalendarService.getRequestsObjectKey(
          request.prospect_id,
          request.listing_id
        );
        if ($scope.requests && $scope.requests[requestKey]) {
          delete $scope.requests[requestKey];

          request.status = 'confirmed';

          $scope.appointments = $scope.appointments || [];
          $scope.appointments.push({ Appointment: request });

          self._sortAppointments();

          $scope.requestCount = $scope.requests
            ? keys($scope.requests).length
            : 0;
        }
      }
    );

    self.addEventHandler(
      eventTypes.confirmedAppointment.wasCanceled,
      function (event, appointmentToRemove) {
        remove($scope.appointments, function (appointment) {
          return appointment.Appointment.id === appointmentToRemove.id;
        });

        self._sortAppointments();
      }
    );

    self.addEventHandler(
      eventTypes.confirmedAppointment.changedManager,
      function (event, changedAppointment, newManager) {
        if (newManager.managerId !== $scope.teamMember.managerId) {
          remove($scope.appointments, function (appointment) {
            return appointment.Appointment.id === changedAppointment.id;
          });
        } else {
          $scope.appointments = $scope.appointments || [];
          $scope.appointments.push({ Appointment: changedAppointment });
        }

        self._sortAppointments();
      }
    );

    self.addEventHandler(
      eventTypes.confirmedAppointment.changedTime,
      function (event, changedAppointment, newTimes) {
        var appointment = find($scope.appointments, function (appointment) {
          return appointment.Appointment.id === changedAppointment.id;
        });

        if (appointment) {
          appointment.Appointment.start_time = newTimes.start.format();
          appointment.Appointment.end_time = newTimes.end.format();
        }

        self._sortAppointments();
      }
    );

    self.addEventHandler(
      eventTypes.confirmedAppointment.wasAddedManually,
      function (event, appointment) {
        if ($scope.teamMember.managerId === appointment.manager_id) {
          $scope.appointments = $scope.appointments || [];

          $scope.appointments.push({ Appointment: appointment });
        }

        self._sortAppointments();
      }
    );

    self.addEventHandler(
      eventTypes.appointmentProposals.wereProposed,
      function (event, proposals) {
        var sampleProposal = sample(proposals);
        var requestKey = managerCalendarService.getRequestsObjectKey(
          sampleProposal.prospect_id,
          sampleProposal.listing_id
        );

        if ($scope.requests && requestKey in $scope.requests) {
          delete $scope.requests[requestKey];
        }

        if (sampleProposal.manager_id === $scope.teamMember.managerId) {
          var appointmentProposals = map(proposals, function (proposal) {
            return { Appointment: proposal };
          });

          $scope.proposals = $scope.proposals || {};
          $scope.proposals[requestKey] = appointmentProposals;

          $scope.proposalCount = $scope.proposals
            ? keys($scope.proposals).length
            : 0;
          $scope.requestCount = $scope.requests
            ? keys($scope.requests).length
            : 0;
        }
      }
    );

    self.addEventHandler(
      eventTypes.managerProfile.wasUpdated,
      function (event, managerInfo) {
        if ($scope.teamMember.managerId === managerInfo.manager_id) {
          $scope.teamMember = {
            managerId: managerInfo.manager_id,
            firstName: managerInfo.first_name,
            lastName: managerInfo.last_name,
            color: managerInfo.color
          };
        }
      }
    );

    self._sortAppointments = function () {
      $scope.sortedAppointments = sortBy(
        $scope.appointments,
        function (appointment) {
          return appointment.Appointment.start_time;
        }
      );
    };

    $scope.$watch('requests', function (requests) {
      $scope.requestCount = requests ? keys(requests).length : 0;
    });

    $scope.$watch('proposals', function (proposals) {
      $scope.proposalCount = proposals ? keys(proposals).length : 0;
    });

    $scope.$watch('appointments', function () {
      self._sortAppointments();
    });
  };

  TeamMemberAppointmentsController.$inject = [
    '$rootScope',
    '$scope',
    'userService',
    'managerCalendarEventsService',
    'managerCalendarService',
    'colorConversionService'
  ];

  app.controller(
    'TeamMemberAppointmentsController',
    TeamMemberAppointmentsController
  );
})(window.angular);
