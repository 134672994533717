/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Account');

  app.directive('accountPreferencesNotifications', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/account/preferences/account-preferences-notifications.html',
      controller: 'AccountPreferencesNotificationsController',
      scope: {
        preferences: '=',
        managerId: '='
      }
    };
  });

  app.controller('AccountPreferencesNotificationsController', [
    '$scope',
    '$mdToast',
    'notificationsApi',
    'notificationsService',
    function ($scope, $mdToast, notificationsApi, notificationsService) {
      var self = this;

      $scope.periodChoices = [
        { description: 'Immediately', period: '0:00:00' },
        { description: 'After 15 minutes', period: '0:15:00' },
        { description: 'Never', period: null }
      ];

      // Determine Initial Value for Notification Switches ->
      notificationsService
        .getClientPreferences()
        .then(function (clientPreferences) {
          $scope.preferences.clientPreferences = clientPreferences;
        });
      // <- Determine Initial Value for Notification Switches

      $scope.savePreferences = function () {
        $scope.isSavingPreferences = true;

        return notificationsApi
          .updatePreferences(
            $scope.preferences.unread_message_period,
            $scope.preferences.clientPreferences
          )
          .success(function () {
            $mdToast.show(
              $mdToast
                .simple()
                .content('Notification preferences saved!')
                .position('top right')
            );
          })
          .error(function () {
            $mdToast.show(
              $mdToast
                .simple()
                .content('There was a problem saving your preferences.')
                .position('top right')
            );
          })
          .finally(function () {
            $scope.isSavingPreferences = false;
          });
      };
    }
  ]);
})(window.angular);
