import React, { FC } from 'react';

export const EmptyListIcon: FC = () => (
  <svg
    width="183"
    height="180"
    viewBox="0 0 183 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="empty-list-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.877 7.05349C90.569 -8.03667 56.2241 1.88228 41.1658 29.208C35.6772 39.1679 33.5084 50.0657 34.2909 60.6251C22.6754 64.1964 12.3795 72.1092 6.04755 83.5995C-6.90705 107.108 1.63611 136.689 25.1292 149.671L69.0312 173.931C92.5243 186.913 122.071 178.379 135.026 154.871C136.989 151.308 138.459 147.605 139.457 143.842C154.561 147.256 170.703 140.492 178.554 126.244C188.077 108.965 181.797 87.2216 164.529 77.6793L149.526 69.3892C154.136 45.923 143.416 21.1658 121.37 8.98362L117.877 7.05349Z"
      fill="#EBEEFE"
    />
    <path
      d="M83.7286 98.4088C87.2292 101.909 91.2817 104.571 94.9947 105.809C98.7076 107.047 101.777 106.759 103.527 105.008C105.277 103.258 105.565 100.189 104.328 96.4758C103.09 92.7629 100.428 88.7103 96.9276 85.2097C93.427 81.7091 89.3745 79.0472 85.6615 77.8095C81.9486 76.5719 78.8793 76.8599 77.129 78.6102C75.3787 80.3605 75.0907 83.4297 76.3284 87.1427C77.566 90.8556 80.2279 94.9082 83.7286 98.4088V98.4088Z"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.6422 81.9631L52.4889 122.57C52.0308 123.561 51.8878 124.668 52.0791 125.743C52.2704 126.818 52.7868 127.808 53.5586 128.58C54.3304 129.351 55.3205 129.868 56.3951 130.059C57.4697 130.25 58.5771 130.107 59.5679 129.649L100.175 106.514"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M105.412 74.8521C105.536 74.9759 105.635 75.123 105.702 75.285C105.769 75.447 105.804 75.6207 105.804 75.796C105.804 75.9714 105.769 76.1451 105.702 76.307C105.635 76.469 105.536 76.6162 105.412 76.74"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M103.528 74.852C103.778 74.6023 104.117 74.4621 104.47 74.4621C104.823 74.4621 105.162 74.6023 105.412 74.852"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M103.528 76.7261C103.279 76.475 103.139 76.1357 103.139 75.7821C103.139 75.4285 103.279 75.0892 103.528 74.8381"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M105.412 76.7261C105.162 76.9758 104.823 77.1161 104.47 77.1161C104.117 77.1161 103.778 76.9758 103.528 76.7261"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M120.498 59.7565C120.622 59.8801 120.72 60.0269 120.787 60.1886C120.855 60.3503 120.889 60.5237 120.889 60.6987C120.889 60.8738 120.855 61.0472 120.787 61.2089C120.72 61.3706 120.622 61.5174 120.498 61.6409"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M118.614 59.7562C118.738 59.6321 118.885 59.5337 119.046 59.4665C119.208 59.3993 119.381 59.3647 119.557 59.3647C119.732 59.3647 119.905 59.3993 120.067 59.4665C120.228 59.5337 120.375 59.6321 120.499 59.7562"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M118.614 61.6409C118.49 61.5174 118.391 61.3706 118.324 61.2089C118.257 61.0472 118.223 60.8738 118.223 60.6987C118.223 60.5237 118.257 60.3503 118.324 60.1886C118.391 60.0269 118.49 59.8801 118.614 59.7565"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M120.499 61.6399C120.249 61.8896 119.91 62.0298 119.557 62.0298C119.203 62.0298 118.864 61.8896 118.614 61.6399"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M120.498 97.4656C120.622 97.5895 120.72 97.7366 120.788 97.8986C120.855 98.0606 120.889 98.2342 120.889 98.4096C120.889 98.585 120.855 98.7586 120.788 98.9206C120.72 99.0826 120.622 99.2297 120.498 99.3536"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M118.614 97.4661C118.864 97.2164 119.203 97.0762 119.557 97.0762C119.91 97.0762 120.249 97.2164 120.499 97.4661"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M118.614 99.3536C118.49 99.2297 118.391 99.0826 118.324 98.9206C118.257 98.7586 118.222 98.585 118.222 98.4096C118.222 98.2342 118.257 98.0606 118.324 97.8986C118.391 97.7366 118.49 97.5895 118.614 97.4656"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M120.499 99.3531C120.249 99.6028 119.91 99.7431 119.557 99.7431C119.203 99.7431 118.864 99.6028 118.614 99.3531"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.0159 63.5242C79.2649 63.7752 79.4047 64.1145 79.4047 64.4682C79.4047 64.8218 79.2649 65.1611 79.0159 65.4121"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.1321 63.5246C77.3821 63.275 77.721 63.1347 78.0743 63.1347C78.4276 63.1347 78.7665 63.275 79.0165 63.5246"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.1315 65.4121C77.0074 65.2883 76.9089 65.1412 76.8417 64.9792C76.7744 64.8172 76.7398 64.6435 76.7398 64.4682C76.7398 64.2928 76.7744 64.1191 76.8417 63.9571C76.9089 63.7952 77.0074 63.648 77.1315 63.5242"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.0165 65.4121C78.7665 65.6618 78.4276 65.802 78.0743 65.802C77.721 65.802 77.3821 65.6618 77.1321 65.4121"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M95.0445 50.1416C95.795 56.8612 95.1523 63.6634 93.1565 70.1234"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.243 55.0405L106.358 64.4661"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M131.996 87.0933C125.277 86.3428 118.475 86.9855 112.015 88.9813"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M127.097 73.8958L117.672 75.7802"
      stroke="#697FF7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
