/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  /**
   * @deprecated
   * Use authenticationService.handleAuthenticatedRedirection() instead.
   * I am leaving this here while the other use cases are investigated.
   */
  app.controller('OBSOLETE_AuthRedirectController', [
    'authenticationService',
    function (authenticationService) {
      authenticationService.handleAuthenticatedRedirection();
    }
  ]);
})(window.angular);
