import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { Snackbar, makeStyles } from '@material-ui/core';
import { Alert } from '@knockrentals/knock-shared-web';
import { PopupSnackbarType } from '../hooks/usePopupSnackbar';

const useSnackbarStyles = makeStyles(() => ({
  snackbar: {
    // 1300 (dialog height) + 1
    zIndex: 1301
  }
}));

const PopupSnackbar: FC<PopupSnackbarType> = (popupSnackbar) => {
  const snackbarStyles = useSnackbarStyles();
  const component = (
    <Snackbar
      className={snackbarStyles.snackbar}
      open={popupSnackbar.isOpen}
      autoHideDuration={3000}
      onClose={() => popupSnackbar.close()}
    >
      <Alert severity={popupSnackbar.popupSeverity}>
        {popupSnackbar.popupMessage}
      </Alert>
    </Snackbar>
  );
  return ReactDOM.createPortal(component, document.body);
};

export default PopupSnackbar;
