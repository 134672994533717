/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('teamApi', [
    'apiBase',
    function (apiBase) {
      return {
        memberLogin: function (managerId) {
          var payload = {
            manager_id: managerId
          };

          return apiBase.post('/auth/team/', payload);
        },
        getTeamMembers: function () {
          return apiBase.get('/me/leasing-team/members');
        },
        getTeamMemberRelays: function () {
          return apiBase.get('/me/leasing-team/members/relay');
        },
        getTeamProperties: function (excludeDeleted) {
          return apiBase.get('/me/leasing-team/properties', {
            params: { exclude_deleted: excludeDeleted }
          });
        },
        getMyTeamAppointments: function (start, end) {
          var params = {
            start: start,
            end: end
          };

          return apiBase.get('/me/leasing-team/appointments', {
            params: params
          });
        },
        getMyTeamAppointmentsByRenter: function (renterId) {
          return apiBase.get(
            '/me/leasing-team/renter/' + renterId + '/appointments'
          );
        },
        getAvailableColors: function () {
          return apiBase.get('/me/leasing-team/colors');
        },
        getTeamPageData: function (leasingTeamId, managerMailerId) {
          var url = '/leasing-teams/' + leasingTeamId + '/listings';

          if (managerMailerId) {
            url += '?manager_mailer_username=' + managerMailerId;
          }

          return apiBase.get(url);
        },
        getTeamCustomFieldsData: function (leasingTeamId) {
          return apiBase.get(
            '/leasing-teams/' + leasingTeamId + '/custom-fields'
          );
        }
      };
    }
  ]);
})(window.angular);
