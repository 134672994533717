import React, { FC, useMemo } from 'react';
import { Box, Chip, Link, Tooltip, TableCell } from '@material-ui/core';
import classnames from 'classnames';

import {
  IconButton,
  makeStyles,
  NamedColors,
  Text
} from '@knockrentals/knock-shared-web';
import { Resident } from '../../../../../app/services/residents/entities';
import { colors, useCommonStyles } from '../../commonStyles/commonStyles';
import { useAngularContext } from '../../AngularContextProvider';
import { EnvelopeIcon, PhoneIcon, PhoneSMSIcon } from '../../icons';

const useStyles = makeStyles(() => ({
  reduceText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  nameColumn: {
    maxWidth: '200px'
  },

  contactColumn: {
    width: '120px'
  },

  contactAction: {
    '&:hover': {
      backgroundColor: 'transparent'
    },

    '& svg': {
      width: '20px'
    }
  },

  contactActionEnabled: {
    '& svg path': {
      stroke: NamedColors.denim[600]
    }
  },

  contactActionDisabled: {
    '& svg path': {
      stroke: NamedColors.slate[200]
    }
  },

  todoColumn: {
    width: '100px'
  },

  todoIcon: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    display: 'flex',
    justifySelf: 'center'
  },

  todoNeedsContact: {
    backgroundColor: colors.error
  },

  todoNeedsContactSoon: {
    backgroundColor: NamedColors.banana[500]
  },
  todoGreen: {
    backgroundColor: NamedColors.grass[600]
  },

  chip: {
    fontSize: '13px',
    textTransform: 'capitalize',

    '& .MuiChip-label': {
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  },

  statusNew: {
    backgroundColor: NamedColors.violet[100],
    color: NamedColors.violet[900]
  },

  statusCurrent: {
    backgroundColor: NamedColors.grass[100],
    color: NamedColors.grass[900]
  },

  statusUndecided: {
    backgroundColor: NamedColors.banana[100],
    color: NamedColors.banana[900]
  },

  statusRenewing: {
    backgroundColor: NamedColors.violet[100],
    color: NamedColors.violet[900]
  },

  statusTransfer: {
    backgroundColor: NamedColors.sky[100],
    color: NamedColors.sky[900]
  },

  statusOnNotice: {
    backgroundColor: NamedColors.apple[100],
    color: NamedColors.apple[900]
  },

  statusFormer: {
    backgroundColor: NamedColors.slate[100],
    color: NamedColors.slate[900]
  },

  propertyColumn: {
    maxWidth: '150px'
  },

  ownerColumn: {
    maxWidth: '150px'
  }
}));

interface ResidentCellsProps {
  resident: Resident;
  selected: boolean;
}

const ResidentCells: FC<ResidentCellsProps> = ({ resident, selected }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { properties, openResidentStreamModal } = useAngularContext();

  return useMemo(() => {
    const handleContactActionClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      const messageMode = event.currentTarget.getAttribute('data-message-mode');

      window.sessionStorage.setItem(
        'conversationMessageMode',
        messageMode || ''
      );
      openResidentStreamModal(resident.streamId);
    };

    const getResidentPropertyName = (propertyId: number) => {
      const residentProperty = properties.find(
        (property: any) => property.Property.id === propertyId
      );

      return residentProperty
        ? residentProperty.Property.data.location.name
        : 'N/A';
    };

    const getTodoClass = (color: string) => {
      let className = `${classes.todoIcon}`;

      switch (color) {
        case 'green':
          className += ` ${classes.todoGreen}`;
          break;
        case 'red':
          className += ` ${classes.todoNeedsContact}`;
          break;
        case 'yellow':
          className += ` ${classes.todoNeedsContactSoon}`;
          break;
      }

      return className;
    };

    const getStatusInfo = (status: string) => {
      const statusInfo = {
        className: `${classes.chip} `,
        label: status
      };

      switch (status) {
        case 'new':
          statusInfo.className += classes.statusNew;

          break;
        case 'current':
          statusInfo.className += classes.statusCurrent;

          break;
        case 'undecided':
          statusInfo.className += classes.statusUndecided;

          break;
        case 'renewing':
          statusInfo.className += classes.statusRenewing;

          break;
        case 'transfer':
          statusInfo.className += classes.statusTransfer;
          statusInfo.label = 'transferring';

          break;
        case 'on-notice':
          statusInfo.className += classes.statusOnNotice;
          statusInfo.label = 'on notice';

          break;
        case 'former':
          statusInfo.className += classes.statusFormer;
          statusInfo.label = 'former';

          break;
        default:
          statusInfo.className += classes.statusFormer;

          break;
      }

      return statusInfo;
    };

    const statusInfo = getStatusInfo(resident.status);
    const propertyName = getResidentPropertyName(resident.residence.propertyId);
    const managerName = `${resident.managerInfo.firstName} ${resident.managerInfo.lastName}`;

    return (
      <>
        <TableCell
          data-testid="fullname-cell"
          padding="none"
          className={`${classes.nameColumn} ${classes.reduceText} ${
            commonClasses.stickyRowTwo
          } ${selected ? commonClasses.selectedRow : ''}`}
        >
          <Link
            className={commonClasses.link}
            onClick={() => openResidentStreamModal(resident.streamId)}
            variant="subtitle2"
            href="#"
            title={resident.profile.fullName}
          >
            {resident.profile.fullName}
          </Link>
        </TableCell>
        <TableCell className={classes.contactColumn} data-testid="contact-cell">
          <IconButton
            size="small"
            onClick={handleContactActionClick}
            disabled={!resident.profile.phone?.canReceiveCall}
            className={classnames(
              classes.contactAction,
              resident.profile.phone?.canReceiveCall
                ? classes.contactActionEnabled
                : classes.contactActionDisabled
            )}
            data-message-mode="call"
          >
            <PhoneIcon />
          </IconButton>

          <IconButton
            size="small"
            onClick={handleContactActionClick}
            disabled={resident.smsConsent?.status !== 'granted'}
            className={classnames(
              classes.contactAction,
              resident.smsConsent?.status === 'granted'
                ? classes.contactActionEnabled
                : classes.contactActionDisabled
            )}
            data-message-mode="sms"
          >
            <PhoneSMSIcon />
          </IconButton>

          <IconButton
            size="small"
            onClick={handleContactActionClick}
            disabled={!resident.profile.email}
            className={classnames(
              classes.contactAction,
              resident.profile.email
                ? classes.contactActionEnabled
                : classes.contactActionDisabled
            )}
            data-message-mode="email"
          >
            <EnvelopeIcon />
          </IconButton>
        </TableCell>
        <TableCell data-testid="todo-cell" className={classes.todoColumn}>
          <Tooltip
            title={resident.todoStatus?.explanation || 'Good to go!'}
            aria-label={resident.todoStatus?.explanation || 'Good to go!'}
          >
            <Box
              component="span"
              className={getTodoClass(resident.todoStatus?.color || 'green')}
            />
          </Tooltip>
        </TableCell>
        <TableCell data-testid="stage-cell">
          <Chip
            size="small"
            className={statusInfo.className}
            label={statusInfo.label}
          />
        </TableCell>
        <TableCell
          data-testid="owner-cell"
          className={`${classes.ownerColumn} ${classes.reduceText}`}
        >
          <Text
            variant="body2"
            variantMapping={{ body2: 'span' }}
            title={managerName}
          >
            {managerName}
          </Text>
        </TableCell>
        <TableCell data-testid="last-contact-cell">
          <>
            {resident.isDeleted ? <i className="fa fa-trash fa-fw" /> : ''}

            <Text variant="body2">
              {resident.lastContactedTimeDescription}
              {resident.lastContactedTime ? ' ago' : ''}
            </Text>
          </>
        </TableCell>
        {properties.length > 1 && (
          <TableCell
            data-testid="property-cell"
            className={`${classes.propertyColumn} ${classes.reduceText}`}
          >
            <Text
              variant="body2"
              variantMapping={{ body2: 'span' }}
              title={propertyName}
            >
              {propertyName}
            </Text>
          </TableCell>
        )}
        <TableCell data-testid="building-cell">
          <Text variant="body2">
            {resident.building ? resident.building : '-'}
          </Text>
        </TableCell>
        <TableCell data-testid="unit-cell">
          <Text variant="body2">{resident.residence.unit || '-'}</Text>
        </TableCell>
        <TableCell data-testid="leasestart-cell">
          <Text variant="body2">{resident.leaseStart}</Text>
        </TableCell>
        <TableCell data-testid="leaseend-cell">
          <Text variant="body2">{resident.leaseEnd}</Text>
        </TableCell>
      </>
    );
  }, [
    resident,
    classes,
    commonClasses,
    properties,
    openResidentStreamModal,
    selected
  ]);
};

export default ResidentCells;
