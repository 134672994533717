import React, { FC } from 'react';

export const QuoteIcon: FC = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.8125 6.0625H11.6875M3.8125 9.4375H8.3125M3.8125 12.8125H6.625M6.625 16.1875H2.6875C2.38913 16.1875 2.10298 16.069 1.892 15.858C1.68103 15.647 1.5625 15.3609 1.5625 15.0625V2.6875C1.5625 2.38913 1.68103 2.10298 1.892 1.892C2.10298 1.68103 2.38913 1.5625 2.6875 1.5625H10.6592C10.9574 1.56256 11.2434 1.68099 11.4543 1.89175L13.6083 4.04575C13.819 4.25665 13.9374 4.54259 13.9375 4.84075V6.625M13.9823 11.7206V11.1581M13.9823 16.7831V16.2206M15.1073 11.7206H13.5825C13.3489 11.7208 13.1225 11.8022 12.9423 11.951C12.7622 12.0998 12.6394 12.3067 12.5951 12.5361C12.5507 12.7655 12.5876 13.0033 12.6993 13.2085C12.8111 13.4137 12.9908 13.5737 13.2075 13.6609L14.7555 14.2804C14.9723 14.3676 15.152 14.5276 15.2638 14.7328C15.3755 14.938 15.4124 15.1757 15.368 15.4052C15.3237 15.6346 15.2009 15.8414 15.0207 15.9902C14.8406 16.139 14.6142 16.2205 14.3805 16.2206H12.8573M13.9823 18.4706C16.4675 18.4706 18.4823 16.4559 18.4823 13.9706C18.4823 11.4854 16.4675 9.47064 13.9823 9.47064C11.497 9.47064 9.48227 11.4854 9.48227 13.9706C9.48227 16.4559 11.497 18.4706 13.9823 18.4706Z"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
