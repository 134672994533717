import angular from 'angular';

const app = angular.module('knockApp');

app.directive('leasingTeamNavbar', function () {
  return {
    restrict: 'E',
    templateUrl: '/angular/views/navigation/leasing-team-navbar.html',
    controller: 'LeasingTeamNavbarController'
  };
});

app.controller('LeasingTeamNavbarController', [
  '$rootScope',
  '$scope',
  'authenticationService',
  'cacheKeys',
  'localStorageService',
  '$mdDialog',
  'appDataService',
  'leasingTeamApi',
  function (
    $rootScope: any,
    $scope: any,
    authenticationService: any,
    cacheKeys,
    localStorageService,
    $mdDialog: any,
    appDataService: any,
    leasingTeamApi: any
  ): void {
    $scope.currentLeasingTeamId = appDataService.getCurrentLeasingTeamId();

    $scope.leasingTeams = appDataService.getLeasingTeams();

    function getBadgesAtInterval() {
      leasingTeamApi.getMyLeasingTeamsBadges().then((response: any) => {
        $scope.leasingTeamBadgeCounts = response.data.leasing_team_badges;
      });
    }

    /* 2020-FEB: This highwater mark on # of leasing-teams was put in place
       in order to reduce strain on the system. As this feature wasn't originally
       intended to have a massive 10-20 teams for multi-team users and this results
       in lots of hits on our heaviest end-point in core and will take down the system.

       In addition, we took out the auto 30-sec refresh
    */
    if ($scope.leasingTeams.length < 8) {
      getBadgesAtInterval();
      //$interval(getBadgesAtInterval, 30000);
    }

    $scope.setDisplayName = (teamName: String) => {
      const splitTeamName = teamName.split(' ');
      if (splitTeamName.length > 1) {
        return (
          splitTeamName[0].charAt(0).toUpperCase() +
          splitTeamName[1].charAt(0).toUpperCase()
        );
      } else if (splitTeamName[0].length > 1) {
        return splitTeamName[0].slice(0, 2).toUpperCase();
      } else {
        return splitTeamName[0].toUpperCase();
      }
    };

    $scope.switchLeasingTeam = (
      leasingTeamId: number,
      leasingTeamPublicId: string | undefined
    ) => {
      if (leasingTeamPublicId) {
        // the selected leasing team
        window.open(`/dashboard?lt=${leasingTeamPublicId}`, '_blank');
        return;
      }

      // selected leasing team
      leasingTeamApi
        .switchLeasingTeam(leasingTeamId)
        .then((res: any) => {
          localStorageService.set(
            cacheKeys.refreshToken,
            res.data.refresh_token
          );
          authenticationService.loginWithToken(res.data.token);
          window.location.href = '/dashboard';
        })
        .catch(function () {
          $scope.memberLoginError = 'unable to switch, please try again later';
        });
    };

    $scope.joinNewLeasingTeamInfoModal = () => {
      const infoModal = $mdDialog.alert({
        title: 'Join New Leasing Team',
        content:
          'If you need access to a different leasing team, please contact your supervisor to request an access change.',
        ok: 'CLOSE'
      });

      return $mdDialog.show(infoModal);
    };
  }
]);
