/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import filter from 'lodash/filter';
import map from 'lodash/map';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('managerOfficeHours', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/manager/directives/officeHours.html',
      controller: 'ManagerOfficeHoursController',
      scope: {
        onStartSave: '=',
        onSaveComplete: '='
      }
    };
  });

  app.controller('ManagerOfficeHoursController', [
    '$q',
    '$scope',
    '$rootScope',
    'timeService',
    'managerApi',
    'officeHoursModel',
    'apiEvents',
    function (
      $q,
      $scope,
      $rootScope,
      timeService,
      managerApi,
      officeHoursModel,
      apiEvents
    ) {
      $scope.titleFormat = 'dddd';

      if ($scope.onStartSave) {
        var originalSave = $scope.onStartSave;
        $scope.onStartSave = function () {
          originalSave();
          $scope.save();
        };
      }

      managerApi.getManagerHours().success(function (response) {
        var officeHoursMap = keyBy(angular.copy(officeHoursModel), 'weekday');
        var officeHours = response.office_hours;

        if (officeHours.length > 0) {
          officeHours.forEach(function (day) {
            var weekday = timeService.get(day.start_time).isoWeekday();
            var date = timeService.get().isoWeekday(weekday);

            var startTime = timeService.get(
              combineDateAndTime(date, day.start_time)
            );
            var endTime = timeService.get(
              combineDateAndTime(date, day.end_time)
            );

            officeHoursMap[weekday] = {
              startTime: startTime,
              endTime: endTime,
              isActive: true
            };
          });
        }

        $scope.officeHours = sortBy(values(officeHoursMap), function (day) {
          return timeService.get(day.startTime).isoWeekday();
        });
      });

      $scope.timezones = timeService.getTimezones();
      var possiblyDeferredTimezone = timeService.getActiveTimezone();

      $q.when(possiblyDeferredTimezone).then(function (activeTimezone) {
        $scope.activeTimezone = activeTimezone;
      });

      $scope.$watch('activeTimezone', function (newTimezone, oldTimezone) {
        if (
          isEmpty(oldTimezone) ||
          isEmpty(newTimezone) ||
          newTimezone === oldTimezone
        ) {
          return;
        }

        timeService.setTimezone(newTimezone);

        each($scope.officeHours, function (hours) {
          hours.startTime = timeService.get(
            hours.startTime,
            null,
            newTimezone.id
          );
          hours.endTime = timeService.get(hours.endTime, null, newTimezone.id);
        });

        // need to emit change to root scope because angular still considers two moments w/different timezones to be equal
        $rootScope.$emit(apiEvents.userTimezoneChanged);
      });

      $scope.save = function () {
        $scope.isSaving = true;
        $scope.errorMessage = null;
        $scope.successMessage = null;

        var payload = map($scope.officeHours, function (interval) {
          return {
            start_time: interval.startTime.format(),
            end_time: interval.endTime.format(),
            is_active: interval.isActive
          };
        });

        managerApi
          .updateManagerHours(payload)
          .success(function () {
            $scope.successMessage = 'Office hours saved!';
            $scope.isSaving = false;

            $rootScope.$emit(
              apiEvents.officeHoursUpdated,
              filter($scope.officeHours, { isActive: true })
            );

            if ($scope.onSaveComplete) {
              $scope.onSaveComplete();
            }
          })
          .error(function (error) {
            $scope.errorMessage =
              'There was an error saving your office hours. Please try again later.';
            $scope.isSaving = false;
          });
      };

      function combineDateAndTime(date, time) {
        return (
          timeService.get(date).format('YYYY-MM-DD') +
          'T' +
          timeService.get(time).format('HH:mm:ssZ')
        );
      }
    }
  ]);
})();
