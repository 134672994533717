import React, { FC, ChangeEvent } from 'react';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import OptionLabel from './OptionLabel';

export interface LeaseTermSelectProps {
  onChange: (e: ChangeEvent<{ value: unknown }>) => void;
  selectedLeaseTerm: number;
  termLenthOptions: number[];
}

const LeaseTermSelect: FC<LeaseTermSelectProps> = ({
  onChange,
  selectedLeaseTerm,
  termLenthOptions
}) => {
  return (
    <div className="lease-term-select-wrapper">
      <InputLabel shrink id="lease-term-select-label">
        <OptionLabel>Lease Term</OptionLabel>
      </InputLabel>
      <Select
        className="lease-term-select"
        labelId="lease-term-select-label"
        onChange={onChange}
        value={selectedLeaseTerm}
      >
        {termLenthOptions.map((term: number) => (
          <MenuItem key={term} value={term}>
            {`${term} month${term > 1 ? 's' : ''}`}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default LeaseTermSelect;
