export type OptOutReason = 'not-looking' | 'too-many-emails' | 'custom';

const BASE_URL = process.env.REACT_APP_EMAIL_SERVICE_URL;

export const giveFeedbackCall = async (
  reason: OptOutReason,
  customReason: string | undefined,
  unsubscribeKey: string
) => {
  const data = new FormData();
  data.set(
    'custom_reason',
    reason === 'custom' ? customReason || 'custom' : reason
  );

  let resp = null;
  try {
    resp = await fetch(`${BASE_URL}/unsubscribe/${unsubscribeKey}/feedback`, {
      method: 'POST',
      body: data
    });
  } catch (e) {
    console.error('Failed to give feedback:', e);
    throw new Error('Bad request');
  }

  if (!resp) {
    console.error('Error giving feedback');
    throw new Error('Error giving feedback');
  }

  const respData = await resp.json();
  if (!resp.ok) {
    console.error('Error giving feedback');
    throw new Error(respData.error);
  }
};

export const resubscribeCall = async (unsubscribeKey: string) => {
  let resp = null;
  try {
    const body = new FormData();
    body.append('unsubscribe_key', unsubscribeKey);
    resp = await fetch(`${BASE_URL}/unsubscribe/`, {
      body,
      method: 'DELETE'
    });
  } catch (e) {
    console.error('Failed to resubscribe', e);
    throw new Error('Bad request');
  }

  if (!resp) {
    console.error('Error resubscribing');
    throw new Error('Error resubscribing');
  }

  if (!resp.ok) {
    console.error('Error resubscribing');
    const respData = await resp.json();
    throw new Error(respData.error);
  }
};

export const unsubscribeCall = async (unsubscribeKey: string) => {
  let resp = null;
  try {
    resp = await fetch(`${BASE_URL}/unsubscribe/`, {
      method: 'POST',
      body: JSON.stringify({ unsubscribe_key: unsubscribeKey })
    });
  } catch (e) {
    console.error('Failed to unsubscribe', e);
    throw new Error('Bad request');
  }

  if (!resp) {
    console.error('Error unsubscribing');
    throw new Error('Error subscribing');
  }

  if (!resp.ok) {
    console.error(`Error unsubscribing: ${resp.status}: ${resp.statusText}`);
    try {
      const respData = await resp.json();
      throw new Error(respData.error);
    } catch (e) {
      throw new Error(`Error code ${resp.status}`);
    }
  }
};
