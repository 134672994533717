import React, { FC } from 'react';

export const CalendarIcon: FC = () => (
  <svg
    className="calendar-icon"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.751953 5.25C0.751953 4.42157 1.42353 3.75 2.25195 3.75H21.752C22.5804 3.75 23.252 4.42157 23.252 5.25V21.75C23.252 22.5784 22.5804 23.25 21.752 23.25H2.25195C1.42353 23.25 0.751953 22.5784 0.751953 21.75V5.25Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.751953 9.75H23.252"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.75195 6V0.75"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.252 6V0.75"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
