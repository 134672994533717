/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
(function (angular, $) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectUnitPreference', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospects/prospect-unit-preference.html',
      controller: 'ProspectUnitPreferenceController',
      scope: {
        interests: '=',
        showLayouts: '=',
        unitListings: '=',
        singleSelect: '='
      }
    };
  });

  app.controller('ProspectUnitPreferenceController', [
    '$scope',
    '$filter',
    function ($scope, $filter) {
      var self = this;
      var unitListingFilter = $filter('unitListingAvailability');

      $scope.$watch('unitListings', function () {
        if ($scope.unitListings) {
          self._initialize();
        }
      });

      $scope.$watch('data.outputs', function () {
        if ($scope.data) {
          $scope.interests = map($scope.data.outputs, 'val');
        }
      });

      $scope.textConfig = {
        reset: 'Clear',
        nothingSelected: 'Shown units (optional)'
      };

      self._initialize = function () {
        var inputs = [];
        var unitListings = map(
          sortBy($scope.unitListings, 'listing.leasing.monthlyRent'),
          self._mapUnitListing
        );

        unitListings.unshift({ name: 'Units', group: true });
        unitListings.push({ group: false });

        inputs = inputs.concat(unitListings);

        if ($scope.singleSelect && inputs.length > 1) {
          inputs[1].ticked = true;
        }

        $scope.data = {
          inputs: inputs,
          outputs: []
        };
      };

      self._mapUnitListing = function (unitListing) {
        var name = unitListingFilter(unitListing);
        var val = unitListing.listing_id;

        return self._getInputItem(name, val);
      };

      self._getInputItem = function (name, val) {
        return {
          name: '<span class="item-text">' + name + '</span>',
          val: val,
          closeIcon:
            '<span class="close-icon" data-id="' + val + '">&times;</span>',
          ticked: false
        };
      };

      $scope.onClick = function (e) {
        if (
          e &&
          e.originalEvent &&
          e.originalEvent.target &&
          e.originalEvent.target.attributes['data-id']
        ) {
          var idToRemove = e.originalEvent.target.attributes['data-id'].value;
          var inputToUpdate = find($scope.data.inputs, { val: idToRemove });

          inputToUpdate.ticked = false;

          e.stopPropagation();

          $('.prospect-unit-preference-container .checkboxLayer').removeClass(
            'show'
          );
        }
      };
    }
  ]);
})(window.angular, window.jQuery);
