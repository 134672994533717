/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import forIn from 'lodash/forIn';
import filter from 'lodash/filter';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
(function (angular) {
  'use strict';

  var app = angular.module('knock-PropertyReports');

  const ResidentReportPageController = function (
    $rootScope,
    $routeParams,
    $location,
    $scope,
    $mdToast,
    $mdDialog,
    $timeout,
    residentsApi,
    residentService,
    streamCarouselService,
    propertyReportsService,
    addResidentModalService
  ) {
    const prospectResidentBetaFlag = localStorage.getItem(
      'ProspectResidentBeta'
    );

    if (
      $rootScope.featureFlags.RESIDENT_PAGE_REDESIGN ||
      prospectResidentBetaFlag === 'true'
    ) {
      if ($location.search) {
        $location.url(`/residents${window.location.search}`);
      } else {
        $location.url('/residents');
      }
      return;
    }

    var self = this;

    var consentRequestButtonIcons = {
      select: 'select',
      send: 'send'
    };

    var residentUpdatedHandler = $rootScope.$on(
      residentService.events.residentUpdated,
      function (event, resident) {
        var residentToUpdateIndex = findIndex($scope.data.residents, {
          id: resident.id
        });

        if (residentToUpdateIndex >= 0) {
          self._search();
        }
      }
    );

    var allResidentsSelectedChangedHandler = $rootScope.$on(
      'allResidentsSelectedChanged',
      function () {
        $scope.data.consentRequestIcon = consentRequestButtonIcons.select;
      }
    );

    var residentSelectedChangedHandler = $rootScope.$on(
      'residentSelectedChanged',
      function () {
        var checkedResidents = self._getCheckedResidents();

        if (checkedResidents.length === 0) {
          self._cancelBulkSMSConsentRequestMode();
        }
      }
    );

    $scope.isPaginationEnabled =
      $rootScope.featureFlags.PROSPECT_RESIDENT_PAGINATION;

    $scope.$on('$destroy', function () {
      residentUpdatedHandler();
      allResidentsSelectedChangedHandler();
      residentSelectedChangedHandler();
    });

    $scope.$watch('data.residents', function () {
      if ($scope.data.residents.length && !$scope.data.residentsLoaded) {
        var residentBuildings = [];

        map($scope.data.residents, function (resident) {
          var building = resident.building;

          if (building && residentBuildings.indexOf(building) === -1) {
            residentBuildings.push(building);
          }
        });

        residentBuildings.forEach(function (building) {
          $scope.filters.buildings.push({
            building: building,
            label: building
          });
        });

        $scope.filters.buildings = sortBy(
          $scope.filters.buildings,
          function (building) {
            var ordered = {
              All: 1
            };

            if (ordered[building.label]) {
              return ordered[building.label];
            }

            if (isNaN(building.label.charAt(0))) {
              return '0000' + building.label;
            }

            return building.label;
          }
        );

        $scope.data.residentsLoaded = true;
      }
    });

    $scope.filters = {
      mode: $routeParams.view || 'table',
      properties: [],
      buildings: [
        {
          building: null,
          label: 'All'
        }
      ],
      statuses: [
        {
          status: null,
          label: 'All'
        },
        {
          status: 'current',
          label: 'Current'
        },
        {
          status: 'current',
          label: 'Undecided',
          undecidedOnly: true
        },
        {
          status: 'renewing',
          label: 'Renewing'
        },
        {
          status: 'on-notice',
          label: 'On Notice'
        },
        {
          status: 'transfer',
          label: 'Transferring'
        }
      ],
      lease_end: '',
      lease_start: '',
      selections: {
        property: null,
        building: null,
        status: null
      },
      batchId: null
    };

    $scope.data = {
      allResidentsSelected: false,
      isConsentRequestsSending: false,
      isLoading: true,
      isLoadingFilters: true,
      residentsLoaded: false,
      residents: [],
      residentsCopy: [],
      totalRows: 0,
      consentRequestIcon: consentRequestButtonIcons.select
    };

    self._initialize = function () {
      self._lastMode = $scope.filters.mode;
      $scope.data.isLoadingFilters = true;

      const residentIdQueryParam = $routeParams.f_residentIds;

      if (residentIdQueryParam) {
        $scope.filters.selections.residentIds = map(
          residentIdQueryParam.split(','),
          function (residentId) {
            return parseInt(residentId);
          }
        );
      }

      const batchIdQueryParam = $routeParams.batchId;

      if (batchIdQueryParam) {
        $scope.filters.batchId = batchIdQueryParam;
      }

      var properties = propertyReportsService.getTeamPropertyFilter(true);

      $scope.filters.properties = [
        {
          name: 'All',
          id: 'all',
          includeUnassigned: true
        }
      ].concat(properties);

      var storedFilters = propertyReportsService.getFilters('residents');

      if (storedFilters) {
        $scope.filters.selections.startDate = storedFilters.startDate;
        $scope.filters.selections.endDate = storedFilters.endDate;
        $scope.filters.selections.managerId = storedFilters.managerId;
        $scope.filters.selections.property = find($scope.filters.properties, {
          id: storedFilters.propertyId
        });
        $scope.filters.selections.status =
          storedFilters.status || $scope.filters.statuses[0];
        $scope.filters.selections.building =
          storedFilters.building || $scope.filters.buildings[0];
      } else {
        $scope.filters.selections.property = $scope.filters.properties[0];
        $scope.filters.selections.status = $scope.filters.statuses[0];
        $scope.filters.selections.building = $scope.filters.buildings[0];
      }

      $scope.data.isLoadingFilters = false;

      self._search();
    };

    $scope.switchMode = function (mode) {
      $location.search('view', mode);

      $timeout(function () {
        $scope.filters.mode = mode;

        if (self._lastMode === 'former' || $scope.filters.mode === 'former') {
          self._search();
        }

        self._lastMode = $scope.filters.mode;
      }, 500);
    };

    $scope.openCarousel = function () {
      var checkedResidents = self._getCheckedResidents();

      if (checkedResidents.length > 0) {
        streamCarouselService.showCarousel(map(checkedResidents, 'stream_id'));
      } else {
        $mdToast.showSimple('No residents selected');
      }
    };

    $scope.addNewResident = function () {
      addResidentModalService.openNewResidentModal(null).then(function () {
        self._search();
      });
    };

    $scope.filtersChanged = function () {
      if ($scope.isPaginationEnabled) {
        $scope.paginationData.offset = 0;
      }

      self._search(true);

      propertyReportsService.saveFilters('residents', {
        propertyId: $scope.filters.selections.property.id,
        mode: $scope.filters.mode,
        status: $scope.filters.status,
        building: $scope.filters.building
      });
    };

    $scope.openSendMessageModal = function () {
      var checkedResidents = self._getCheckedResidents();

      if (checkedResidents.length > 0) {
        propertyReportsService
          .openSendProspectMessageModal(checkedResidents)
          .then(function () {
            self._clearCheckedResidents();
            self._search();
          });
      } else {
        $mdToast.showSimple('No residents selected');
      }
    };

    $scope.openSendResidentMessageModal = function (priority) {
      var checkedResidents = self._getCheckedResidents();

      if (checkedResidents.length > 0 && priority) {
        propertyReportsService
          .openSendResidentMessageModal(checkedResidents, priority)
          .then(function () {
            self._clearCheckedResidents();
            self._search();
          });
      } else {
        $mdToast.showSimple('No residents selected');
      }
    };

    $scope.selectResidentsMissingConsent = function () {
      self._clearCheckedResidents();

      var isMissingConsent = function (resident) {
        return (
          resident.profile.phone &&
          (!resident.sms_consent || resident.sms_consent.status !== 'granted')
        );
      };

      var residentsMissingConsent = filter(
        $scope.data.residentsCopy,
        isMissingConsent
      );

      forIn(residentsMissingConsent, function (resident) {
        resident.isSelected = true;
      });

      if (residentsMissingConsent.length > 0) {
        $scope.data.consentRequestIcon = consentRequestButtonIcons.send;

        $mdToast.showSimple('Click again to send consent request.');
      } else {
        $mdToast.showSimple('All residents already have SMS consent!');
      }
    };

    $scope.sendBulkResidentSMSConsentRequest = function () {
      var checkedResidents = self._getCheckedResidents();
      var checkedResidentsIds = map(checkedResidents, 'id');

      var sendConsentRequest = function () {
        $scope.isConsentRequestsSending = true;
        $mdToast.show(
          $mdToast
            .simple()
            .content(
              'Sending SMS opt-in invitations via email now! Please DO NOT close your browser or refresh this page until you receive confirmation that it is complete.'
            )
            .hideDelay(300000)
        ); // 5 minutes

        return residentsApi
          .sendBulkResidentSMSConsentRequests(checkedResidentsIds)
          .success(function () {
            $mdToast.showSimple('Consent requests sent!');
          })
          .error(function () {
            $mdToast.showSimple('An error occurred sending consent requests');
          })
          .finally(function () {
            $scope.isConsentRequestsSending = false;
            self._cancelBulkSMSConsentRequestMode();
            self._search();
          });
      };

      var confirm = $mdDialog
        .confirm()
        .title(
          'Confirm sending SMS consent request to ' +
            checkedResidentsIds.length +
            ' residents?'
        )
        .ok('Confirm')
        .cancel('Cancel');

      $mdDialog
        .show(confirm)
        .then(sendConsentRequest)
        .catch(self._cancelBulkSMSConsentRequestMode);
    };

    self._cancelBulkSMSConsentRequestMode = function () {
      $scope.data.consentRequestIcon = consentRequestButtonIcons.select;
      self._clearCheckedResidents();
    };

    $scope.paginationData = {
      rowsPerPage: 500,
      offset: 0,
      columnName: 'name',
      columnOrder: 'asc'
    };

    $scope.$on('getPaginationParams', function (e, rowsPerPage, offset) {
      $scope.paginationData = {
        rowsPerPage: rowsPerPage,
        offset,
        columnName: $scope.paginationData.columnName,
        columnOrder: $scope.paginationData.columnOrder
      };
      self._search();
    });

    $scope.$on('getSortParams', function (e, column, columnOrder) {
      $scope.paginationData.columnName = column;
      $scope.paginationData.columnOrder = columnOrder;
      self._search();
    });

    $scope.$on('getRowCount', function (e, rowsPerPage) {
      $scope.paginationData.offset = 0;
      $scope.paginationData.rowsPerPage = rowsPerPage;

      self._search();
    });

    self._search = function (filterChanged) {
      $scope.data.isLoading = true;
      var building = $scope.filters.selections.building
        ? $scope.filters.selections.building.building
        : null;
      var status =
        $scope.filters.mode === 'former'
          ? 'former'
          : $scope.filters.selections.status.status;
      var undecidedOnly =
        $scope.filters.mode === 'former'
          ? false
          : $scope.filters.selections.status.undecidedOnly;
      const batchId = $scope.filters.batchId;
      const residentIds = $scope.filters.selections.residentIds
        ? $scope.filters.selections.residentIds
        : null;
      const propertyId = $scope.filters.selections.property
        ? $scope.filters.selections.property.id
        : null;
      const filters = {
        stage: status || null,
        undecidedOnly: undecidedOnly || false,
        building,
        propertyId,
        residentIds,
        batchId
      };

      if ($scope.isPaginationEnabled) {
        return residentsApi
          .getResidentsData(
            $scope.paginationData.offset,
            $scope.paginationData.rowsPerPage,
            filters,
            $scope.paginationData.columnName,
            $scope.paginationData.columnOrder
          )
          .then(function (response) {
            if (filterChanged) {
              $rootScope.$broadcast(
                'updatePaginationParams',
                1,
                response.data.total_count
              );
            }

            $scope.data.residents = map(
              response.data.resident_reports,
              self._mapResident
            );
            $scope.data.residentsCopy = $scope.data.residents.slice();
            $scope.data.totalRows = response.data.total_count;
          })
          .finally(function () {
            $scope.data.isLoading = false;
          });
      } else {
        return propertyReportsService
          .getResidentReports(
            $scope.filters.selections.property.id,
            status,
            building,
            undecidedOnly,
            residentIds,
            batchId
          )
          .then(function (response) {
            $scope.data.residents = map(
              response.data.resident_reports,
              self._mapResident
            );

            $scope.data.residentsCopy = $scope.data.residents.slice();
            $scope.data.totalRows = $scope.data.residentsCopy.length;
          })
          .finally(function () {
            $scope.data.isLoading = false;
          });
      }
    };

    self._mapResident = function (resident) {
      return {
        id: resident.id,
        todo_status: resident.todo_status || {
          color: 'green',
          explanation: 'Good to go!'
        },
        stream_id: resident.stream_id,
        building: resident.building,
        profile: {
          first_name: resident.profile.first_name,
          last_name: resident.profile.last_name,
          formatted_phone_number: resident.profile.formatted_phone_number,
          email: resident.profile.email,
          phone: resident.profile.phone
        },
        residence: {
          unit: resident.residence.unit,
          property_id: resident.residence.property.id
        },
        modified_time: resident.modified_time,
        source: resident.source,
        lease_start: resident.lease_start,
        lease_end: resident.lease_end,
        sms_consent: resident.sms_consent,
        status: residentService.renderStatusFor(resident),
        has_call_recording: resident.has_call_recording,
        totalUnclaimedPackages: resident.total_unclaimed_packages,
        isPmsResident: includes(
          ['amsi', 'entrata', 'mri', 'realpage', 'resman', 'yardi', 'appfolio'],
          resident.source
        ),
        propertyName: self._getResidentPropertyName(resident)
      };
    };

    self._getCheckedResidents = function () {
      return filter($scope.data.residentsCopy, 'isSelected');
    };

    self._clearCheckedResidents = function () {
      forEach($scope.data.residentsCopy, function (resident) {
        resident.isSelected = false;
      });

      $scope.data.allResidentsSelected = false;
    };

    self._getResidentPropertyName = function (resident) {
      var residentProperty = find($scope.filters.properties, {
        id: resident.residence.property_id
      });
      return residentProperty ? residentProperty.name : 'N/A';
    };

    self._initialize();
  };

  ResidentReportPageController.$inject = [
    '$rootScope',
    '$routeParams',
    '$location',
    '$scope',
    '$mdToast',
    '$mdDialog',
    '$timeout',
    'residentsApi',
    'residentService',
    'streamCarouselService',
    'propertyReportsService',
    'addResidentModalService'
  ];

  app.controller('ResidentReportPageController', ResidentReportPageController);
})(window.angular);
