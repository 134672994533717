/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.factory('userInteractionsService', [
    '$rootScope',
    'listingApi',
    'renterApi',
    'managerApi',
    'prospectsApi',
    'residentsApi',
    'appointmentsApi',
    'calendarApiService',
    'managerCalendarEventsService',
    'viewReviewModalService',
    'transferAppointmentModalFactory',
    'conversationsService',
    'cancelAppointmentModalFactory',
    'teamApi',
    function (
      $rootScope,
      listingApi,
      renterApi,
      managerApi,
      prospectsApi,
      residentsApi,
      appointmentsApi,
      calendarApiService,
      managerCalendarEventsService,
      viewReviewModalService,
      transferAppointmentModalFactory,
      conversationsService,
      cancelAppointmentModalFactory,
      teamApi
    ) {
      var self = this;

      self.events = {
        appointmentUpdated: 'appointmentUpdated',
        appointmentTransferred: 'appointmentTransferred',
        prospectUpdated: 'prospectUpdated',
        prospectTransferred: 'prospectTransferred',
        prospectAppointmentsTransferred: 'prospectAppointmentsTransferred',
        residentUpdated: 'residentUpdated',
        residentTransferred: 'residentTransferred'
      };

      self.getListing = function (listingId) {
        return listingApi.getListing(listingId);
      };

      self.getListings = function () {
        return listingApi.getMyActiveListings();
      };

      self.getCommunities = function () {
        return managerApi.getMyCommunities();
      };

      self.getManager = function (managerId) {
        return managerApi.getManagerInfoById(managerId);
      };

      self.getProspect = function (prospectId) {
        return prospectsApi.getProspect(prospectId);
      };

      self.getResident = function (residentId) {
        return residentsApi.getResident(residentId);
      };

      self.getRenterInfo = function (renterId) {
        return renterApi.getRenterInfoById(renterId);
      };

      self.getActivityStream = function (prospectId) {
        return prospectsApi.getProspectActivity(prospectId);
      };

      self.addNewActivity = function (userInfo, type, message, reminderTime) {
        if (userInfo.residence && userInfo.id) {
          return residentsApi
            .addNewResidentActivity(userInfo.id, type, message, reminderTime)
            .success(function (response) {
              $rootScope.$emit(conversationsService.events.refreshConversation);

              if (reminderTime) {
                $rootScope.$emit(self.events.residentUpdated, userInfo.id);
                managerCalendarEventsService.reminderWasAdded(
                  response.new_activity
                );
              }
            });
        } else if (userInfo.id) {
          return prospectsApi
            .addNewProspectActivity(userInfo.id, type, message, reminderTime)
            .then(function (response) {
              $rootScope.$emit(conversationsService.events.refreshConversation);

              if (reminderTime) {
                $rootScope.$emit(self.events.prospectUpdated, userInfo.id);
                managerCalendarEventsService.reminderWasAdded(
                  response.new_activity
                );
              }
            })
            .catch(function (err) {
              console.error('Error adding prospect activity', err);
              throw new Error(err.data.message);
            });
        } else {
          throw 'residentId or prospectId is required!';
        }
      };

      self.addNewResidentPackages = function (residentId, packagesData) {
        return residentsApi.addNewResidentPackages(residentId, packagesData);
      };

      self.claimResidentPackage = function (residentId, packageId, claimedBy) {
        return residentsApi.claimResidentPackage(
          residentId,
          packageId,
          claimedBy
        );
      };

      self.sendResidentSMSConsentRequest = function (residentId) {
        return residentsApi.sendBulkResidentSMSConsentRequests([residentId]);
      };

      self.updateFollowUp = function (recipient, followUp) {
        if (!recipient || !followUp) {
          throw 'recipient and activity are required!';
        }

        var updatedDate = followUp.calendar_event.date;
        var updatedDescription = followUp.calendar_event.description;

        if (followUp.prospect_id) {
          return prospectsApi
            .updateProspectReminder(
              recipient.id,
              followUp.id,
              updatedDate,
              updatedDescription
            )
            .success(function () {
              $rootScope.$emit(conversationsService.events.refreshConversation);
            });
        } else if (followUp.resident_id) {
          return residentsApi
            .updateResidentReminder(
              recipient.id,
              followUp.id,
              updatedDate,
              updatedDescription
            )
            .success(function () {
              $rootScope.$emit(conversationsService.events.refreshConversation);
              $rootScope.$emit(
                self.events.residentUpdated,
                followUp.resident_id
              );
            });
        } else {
          throw 'Resident id not found!';
        }
      };

      self.deleteFollowUp = function (recipient, followUp) {
        if (followUp.prospect_id) {
          return prospectsApi
            .deleteProspectReminder(recipient.id, followUp.id)
            .success(function () {
              $rootScope.$emit(conversationsService.events.refreshConversation);
            });
        } else if (followUp.resident_id) {
          return residentsApi
            .deleteResidentReminder(recipient.id, followUp.id)
            .success(function () {
              $rootScope.$emit(conversationsService.events.refreshConversation);
              $rootScope.$emit(
                self.events.residentUpdated,
                followUp.resident_id
              );
            });
        }
      };

      self.getAppointments = function (listingId, renterId, status) {
        return appointmentsApi.getAppointments({
          status: status,
          listingId: listingId,
          renterId: renterId
        });
      };

      self.getAppointmentRequests = function (appointmentId) {
        return appointmentsApi.getAppointmentRequests(appointmentId);
      };

      self.getTeamAppointmentsWithRenter = function (renterId) {
        return teamApi.getMyTeamAppointmentsByRenter(renterId);
      };

      self.getProspectStatusTransitions = function (status) {
        return prospectsApi.getStatusTransitions(status);
      };

      self.setProspectLeasedDate = function (prospectId, leasedDate) {
        return prospectsApi.setProspectLeasedDate(prospectId, leasedDate);
      };

      self.setAppointmentSource = function (
        appointmentId,
        newSource,
        renterId
      ) {
        if (!appointmentId || !newSource) {
          throw 'appointmentId and newSource are required!';
        }

        return appointmentsApi
          .setAppointmentSource(appointmentId, newSource)
          .success(function () {
            $rootScope.$emit(
              self.events.appointmentUpdated,
              appointmentId,
              newSource,
              renterId
            );
          });
      };

      self.setProspectStatus = function (prospectId, newStatus, renterId) {
        if (!prospectId || !newStatus) {
          throw 'prospectId and newStatus are required!';
        }

        return prospectsApi
          .setProspectStatus(prospectId, newStatus)
          .success(function () {
            $rootScope.$emit(
              self.events.prospectUpdated,
              prospectId,
              newStatus,
              renterId
            );
          });
      };

      self.updateProspectFirstContactType = function (
        prospectId,
        firstContactType
      ) {
        if (!prospectId || !firstContactType) {
          throw 'prospectId and firstContactType are required!';
        }

        return prospectsApi
          .updateProspectFirstContactType(prospectId, firstContactType)
          .success(function () {
            $rootScope.$emit(
              self.events.prospectUpdated,
              prospectId,
              firstContactType
            );
          });
      };

      self.transferAppointment = function (appointment, newManager, visit) {
        if (!appointment || !newManager) {
          throw 'appointment and newManager are required!';
        }

        newManager.managerId = newManager.manager_id;

        if (visit) {
          visit.manager_id = newManager.manager_id;
        }

        return calendarApiService
          .transferAppointment(appointment, newManager, visit)
          .success(function () {
            $rootScope.$emit(self.events.appointmentTransferred, appointment);
          });
      };

      self.transferProspect = function (prospectId, newManagerId) {
        return prospectsApi
          .transferProspect(prospectId, newManagerId)
          .success(function () {
            $rootScope.$emit(self.events.prospectUpdated, prospectId);
            $rootScope.$emit(
              self.events.prospectTransferred,
              prospectId,
              newManagerId
            );
          });
      };

      self.transferProspectAppointments = function (prospectId, newManagerId) {
        return prospectsApi
          .transferProspectAppointments(prospectId, newManagerId)
          .success(function () {
            $rootScope.$emit(
              self.events.prospectAppointmentsTransferred,
              prospectId,
              newManagerId
            );
          });
      };

      self.transferResident = function (residentId, newManagerId) {
        return residentsApi
          .transferResident(residentId, newManagerId)
          .success(function () {
            $rootScope.$emit(self.events.residentUpdated, residentId);
            $rootScope.$emit(
              self.events.residentTrasferred,
              residentId,
              newManagerId
            );
          });
      };

      self.cancelAppointment = function (appointment, message) {
        return calendarApiService.cancelAppointment(appointment, message);
      };

      self.openReviewModal = function (
        review,
        appointment,
        manager,
        renter,
        listing
      ) {
        viewReviewModalService.openModal(
          review,
          appointment,
          manager,
          renter,
          listing
        );
      };

      self.openTransferAppointmentModal = function (appointment, visit) {
        return transferAppointmentModalFactory.openTransferAppointmentModal(
          appointment,
          appointment.manager_id,
          visit
        );
      };

      self.openCancelAppointmentModal = function (appointment) {
        self.getProspect(appointment.prospect_id).success(function (response) {
          cancelAppointmentModalFactory.createModal(
            response.prospect.profile,
            function (message) {
              self.cancelAppointment(appointment, message);
            }
          );
        });
      };

      return self;
    }
  ]);
})(window.angular);
