import isEmpty from 'lodash/isEmpty';
import forIn from 'lodash/forIn';

export default function runOnRouteChangeStart(app: any) {
  app.run([
    '$rootScope',
    '$location',
    'cacheKeys',
    'localCache',
    '$auth',
    'userService',
    'localStorageService',
    function (
      $rootScope: any,
      $location: any,
      cacheKeys: any,
      localCache: any,
      $auth: any,
      userService: any,
      localStorageService: any
    ) {
      $rootScope.$on(
        '$routeChangeStart',
        function (event: any, nextRoute: any, currentRoute: any) {
          var buildPath = function (route: any) {
            if (isEmpty(route)) {
              return '';
            }

            var path = route.originalPath;
            forIn(route.keys, function (key: any) {
              path = path.replace(':' + key.name, route.params[key.name]);
            });

            return path;
          };

          var currentPath = buildPath(currentRoute);
          var nextPath = buildPath(nextRoute);

          if (nextPath === '/login') {
            var isLoggedIn = $auth.isAuthenticated();
            if (isLoggedIn) {
              /**
               * TODO: Identify if this use case of /auth will cause issues with the auth redirect changes.
               */
              $location.path('/auth');
              return;
            }
          }

          if (nextPath === '/auth' && currentPath !== '/login') {
            /**
             * TODO: Identify if this use case of /auth will cause issues with the auth redirect changes.
             */
            if (currentRoute && currentRoute.params.isExternal === 'true') {
              currentPath +=
                '?isExternal=true&companyName=' +
                currentRoute.params.companyName;
            }
            localCache.put(cacheKeys.loginRedirectPath, currentPath);
          }

          // logged in previously but token is expired, refresh it in place of purging
          if ($auth.getToken() && !$auth.isAuthenticated()) {
            userService.refreshAuthToken(
              localStorageService.get(cacheKeys.refreshToken)
            );
          }
        }
      );
    }
  ]);
}
