/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('renterAppointmentView', function () {
    return {
      restrict: 'E',
      scope: {
        appointment: '='
      },
      templateUrl:
        '/angular/views/renterAppointments/renterAppointmentView.html',
      controller: [
        '$scope',
        'renterAppointmentsService',
        'timeService',
        function ($scope, renterAppointmentsService, timeService) {
          if (isEmpty($scope.appointment)) {
            return;
          }

          var listingTimezone = $scope.appointment.listing.location.timezone;

          $scope.appointmentStart = timeService.get(
            $scope.appointment.start_time,
            null,
            listingTimezone
          );
          $scope.appointmentEnd = timeService.get(
            $scope.appointment.end_time,
            null,
            listingTimezone
          );

          $scope.viewAppointmentListing = function (appointment) {
            renterAppointmentsService.viewAppointmentListing(appointment);
          };
        }
      ]
    };
  });

  app.directive('renterAppointmentRequestsView', function () {
    return {
      restrict: 'E',
      scope: {
        listingRequest: '=',
        showTimes: '='
      },
      templateUrl:
        '/angular/views/renterAppointments/renterAppointmentRequestView.html',
      controller: [
        '$scope',
        'renterAppointmentsService',
        'timeService',
        function ($scope, renterAppointmentsService, timeService) {
          if (isEmpty($scope.listingRequest)) {
            return;
          }

          var listingTimezone = $scope.listingRequest.listing.location.timezone;

          each($scope.listingRequest.requests, function (request) {
            request.startTime = timeService.get(
              request.startTime,
              null,
              listingTimezone
            );
            request.endTime = timeService.get(
              request.endTime,
              null,
              listingTimezone
            );
          });

          $scope.viewAppointmentListing = function (listingRequest) {
            renterAppointmentsService.viewAppointmentListing(listingRequest);
          };
        }
      ]
    };
  });
})();
