/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import isUndefined from 'lodash/isUndefined';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('morePropertyInfoModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function ($event, listing) {
        return $mdDialog.show({
          controller: 'MorePropertyInfoController',
          templateUrl: '/angular/views/listingIndex/more-property-info.html',
          parent: angular.element(document.body),
          locals: { listing: listing },
          targetEvent: $event,
          clickOutsideToClose: false, // set to false so that only $scope.close() can resolve current listing info
          escapeToClose: false // set to false so that only $scope.close() can resolve current listing info
        });
      };

      return self;
    }
  ]);

  app.controller('MorePropertyInfoController', [
    '$rootScope',
    '$scope',
    '$mdDialog',
    '$mdToast',
    'listingApi',
    'listing',
    'apiEvents',
    function (
      $rootScope,
      $scope,
      $mdDialog,
      $mdToast,
      listingApi,
      listing,
      apiEvents
    ) {
      $scope.editingOccupant = null;

      listingApi.getListingInfo(listing.id).success(function (response) {
        $scope.listingInfo = response.listing_info;
        $scope.occupants = response.occupants || [];
        $scope.sendNotifications =
          $scope.listingInfo.occupant_notification_method !== 'none';
      });

      $scope.close = function () {
        var response = {
          listingInfo: $scope.listingInfo,
          occupants: $scope.occupants
        };

        $mdDialog.hide(response);
      };

      $scope.saveOccupant = function () {
        var isCreating = isUndefined($scope.editingOccupant.id);

        if (isCreating) {
          listingApi
            .createListingOccupant(listing.id, $scope.editingOccupant)
            .success(function (response) {
              $scope.occupants.push(response.occupant);
              $scope.editingOccupant = null;
              $mdToast.showSimple('Saved!');
            });
        } else {
          listingApi
            .updateListingOccupant(
              listing.id,
              $scope.editingOccupant.id,
              $scope.editingOccupant
            )
            .success(function () {
              $scope.editingOccupant = null;
              $mdToast.showSimple('Saved!');
            });
        }
      };

      $scope.editOccupant = function (occupant) {
        $scope.editingOccupant = occupant;
      };

      $scope.deleteOccupant = function (occupant) {
        var deletedOccupant = cloneDeep(occupant);
        listingApi
          .deleteListingOccupant(listing.id, occupant.id)
          .success(function () {
            remove($scope.occupants, deletedOccupant);

            var undoToast = $mdToast.simple().content('Deleted').action('Undo');

            $mdToast.show(undoToast).then(function (response) {
              if (response === 'ok') {
                listingApi
                  .updateListingOccupant(
                    listing.id,
                    deletedOccupant.id,
                    deletedOccupant
                  )
                  .success(function () {
                    $scope.occupants.unshift(deletedOccupant);
                    $mdToast.showSimple('Restored!');
                  });
              }
            });
          });
      };

      $scope.addNewOccupant = function () {
        $scope.editingOccupant = {};
      };

      $scope.savePropertyNotes = function () {
        listingApi
          .setListingInfo(listing.id, $scope.listingInfo)
          .success(function () {
            $scope.isEditingPropertyNotes = false;
            $mdToast.showSimple('Saved!');
            $rootScope.$emit(
              apiEvents.listingInfoNotesChanged,
              $scope.listingInfo.notes
            );
          });
      };

      $scope.$watch(
        'listingInfo',
        function (listingInfo, old) {
          if (
            listingInfo === undefined ||
            old === undefined ||
            listingInfo === old ||
            $scope.isEditingPropertyNotes
          ) {
            return;
          }

          listingApi
            .setListingInfo(listing.id, listingInfo)
            .success(function () {
              $mdToast.showSimple('Saved!');
            });
        },
        true
      );
    }
  ]);
})(window.angular);
