/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular, $) {
  'use strict';

  var app = angular.module('knock-MarketingTools');

  app.directive('uploadRecording', [
    '$parse',
    function () {
      return {
        restrict: 'E',
        templateUrl: '/angular/views/marketingTools/upload-recording.html',
        controller: 'UploadRecordingController',
        scope: {
          relayTarget: '=',
          relayId: '=',
          phoneRelay: '='
        },
        link: function (scope, element) {
          var fileInput = $(element.find('.recording-upload')[0]);

          fileInput.bind('change', function () {
            scope.uploadRecording(fileInput[0].files[0]);
            scope.$apply();
          });
        }
      };
    }
  ]);

  app.controller('UploadRecordingController', [
    '$scope',
    '$mdToast',
    '$rootScope',
    'apiEvents',
    'relayApi',
    function ($scope, $mdToast, $rootScope, apiEvents, relayApi) {
      //limit recording uploads to 5mb
      var MAX_AUDIO_FILE_SIZE = 5000000;

      $scope.uploadRecording = function (file) {
        $scope.isUploading = true;

        if (file.size > MAX_AUDIO_FILE_SIZE) {
          $mdToast.showSimple('Please limit your file size to 5MB');
          $scope.isUploading = false;
          return;
        }

        var recordingFile = file;

        relayApi
          .uploadRecording(
            $scope.relayTarget,
            $scope.relayId,
            'greeting_url',
            recordingFile
          )
          .success(function (res) {
            $mdToast.showSimple('Upload Successful');
            $scope.fileName = recordingFile.name;
            $scope.phoneRelay.templates.greeting_url = res.recording_url;
          })
          .error(function () {
            $mdToast.showSimple('There was an error while uploading');
          })
          .finally(function () {
            $scope.isUploading = false;
          });
      };
    }
  ]);
})(window.angular, window.jQuery);
