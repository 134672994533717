/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('OauthProviderApi', [
    'apiBase',
    function (apiBase) {
      return {
        deleteUserProvider: function (providerType) {
          return apiBase.delete('/oauth/provider/' + providerType);
        },
        getUserConnectedProviders: function () {
          return apiBase.get('/oauth/providers');
        },
        getUserConnectedProviderTypes: function () {
          return apiBase.get('/oauth/provider');
        }
      };
    }
  ]);
})();
