import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, NamedColors } from '@knockrentals/knock-shared-web';
import CloseIcon from '@material-ui/icons/Close';
import DialogProfileHeader from './DialogProfileHeader';

const styles = () =>
  createStyles({
    root: {
      margin: 0,
      padding: '18px 16px'
    },
    closeButton: {
      color: NamedColors.slate[800],
      position: 'absolute',
      right: '4px',
      top: '12px'
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  firstName: string;
  lastName: string;
  connectedProfilesCount: number;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)(
  ({
    classes,
    connectedProfilesCount,
    firstName,
    lastName,
    onClose
  }: DialogTitleProps) => {
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <DialogProfileHeader
          firstName={firstName}
          lastName={lastName}
          connectedProfilesCount={connectedProfilesCount}
        />
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          disableRipple
          onClick={onClose}
        >
          <CloseIcon fontSize="large" color="inherit" />
        </IconButton>
      </MuiDialogTitle>
    );
  }
);

export default DialogTitle;
