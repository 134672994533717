/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import groupBy from 'lodash/groupBy';
import every from 'lodash/every';
(function (angular) {
  'use strict';

  var app = angular.module('knock-PropertyReports');

  app.directive('residentReportKanban', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/residents/resident-report-kanban.html',
      controller: 'ResidentReportKanbanController'
    };
  });

  const ResidentReportKanbanController = function ($scope) {};
  ResidentReportKanbanController.$inject = ['$scope'];

  app.controller(
    'ResidentReportKanbanController',
    ResidentReportKanbanController
  );

  app.directive('residentKanbanHeader', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/residents/resident-kanban-header.html',
      scope: {
        residents: '=',
        label: '='
      },
      controller: [
        '$rootScope',
        '$scope',
        function ($rootScope, $scope) {
          var self = this;

          var allSelectedChangedHandlerDestroy = $rootScope.$on(
            'residentSelectedChanged',
            function (event, status) {
              $scope.data.allResidentsSelected[status] = every(
                $scope.data.residentsByStatus[status],
                'isSelected'
              );
            }
          );

          $scope.$on('$destroy', function () {
            allSelectedChangedHandlerDestroy();
          });

          $scope.data = {
            residentsByStatus: {},
            allResidentsSelected: {},
            collapsed: true
          };

          self._initialize = function () {
            $scope.data.residentsByStatus = groupBy($scope.residents, 'status');

            $scope.data.allResidentsSelected = reduce(
              $scope.data.residentsByStatus,
              function (result, section) {
                result[section.status] = false;
                return result;
              },
              {}
            );
          };

          $scope.allResidentsSelectedChanged = function (status) {
            forEach($scope.data.residentsByStatus[status], function (resident) {
              resident.isSelected = $scope.data.allResidentsSelected[status];
            });

            $rootScope.$emit('allResidentsSelectedChanged', status);
          };

          self._initialize();
        }
      ]
    };
  });

  app.directive('residentKanbanColumn', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/residents/resident-kanban-column.html',
      scope: {
        residents: '='
      },
      controller: [
        '$scope',
        function ($scope) {
          var self = this;

          $scope.data = {
            residentsByStatus: []
          };

          self._initialize = function () {
            $scope.data.residentsByStatus = map(
              groupBy($scope.residents, 'status'),
              function (residents, status) {
                return {
                  status: status,
                  residents: residents
                };
              }
            );
          };

          $scope.orderSections = function (section) {
            if (section.status === 'Current') {
              return 'A';
            }

            if (section.status === 'Undecided') {
              return 'B';
            }

            if (section.status === 'Renewing') {
              return 'C';
            }

            if (section.status === 'On Notice') {
              return 'D';
            }

            return 'Z';
          };

          self._initialize();
        }
      ]
    };
  });

  app.directive('residentKanbanSection', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/residents/resident-kanban-section.html',
      scope: {
        residents: '=',
        status: '='
      },
      controller: [
        '$rootScope',
        '$scope',
        function ($rootScope, $scope) {
          $scope.data = {
            allResidentsSelected: false,
            collapsed: true,
            statusClass: $scope.status.toLowerCase().split(' ').join('')
          };

          $scope.toggleCollapsed = function () {
            $scope.data.collapsed = !$scope.data.collapsed;
          };

          $scope.allResidentsSelectedChanged = function () {
            forEach($scope.residents, function (resident) {
              resident.isSelected = $scope.data.allResidentsSelected;
            });

            $rootScope.$emit('allResidentsSelectedChanged', $scope.status);
          };

          $scope.residentSelectedChanged = function () {
            $scope.data.allResidentsSelected = every(
              $scope.residents,
              'isSelected'
            );

            $rootScope.$emit('residentSelectedChanged', $scope.status);
          };

          $rootScope.$on(
            'allResidentsSelectedChanged',
            function (event, status) {
              $scope.residentSelectedChanged();
            }
          );
        }
      ]
    };
  });
})(window.angular);
