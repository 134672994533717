/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
/*Properties*/
require('../../core/scripts/properties/OBSOLETE_rentMatrix.service');
require('../../core/scripts/properties/rentMatrix.service');
require('../../core/scripts/properties/propertyCard.directive');
require('../../core/scripts/properties/propertyFloorplanSelect.directive');

/*Property reports*/
require('../../core/scripts/propertyReports/propertyReports.module');
require('../../core/scripts/propertyReports/propertyReports.service');
require('../../core/scripts/propertyReports/prospectReportFilters.directive');
require('../../core/scripts/propertyReports/prospectReportPage.controller');
require('../../core/scripts/propertyReports/prospectReportTable.controller');
require('../../core/scripts/propertyReports/prospectReportStages.controller');
require('../../core/scripts/propertyReports/prospectReportKanban.directive');
require('../../core/scripts/propertyReports/prospectReportKanbanCard.directive');
