import React, { FC } from 'react';
import classNames from 'classnames';

import './_MergeModal.scss';

const MergeStepLabels = [
  'Find duplicate',
  'Resolve conflicts',
  'Merge & archive'
];

const MergeSteps: FC<{ currentStep: number }> = ({ currentStep }) => (
  <div>
    <h2>Merge Guest Cards</h2>
    <div className="merge-step-container">
      {MergeStepLabels.map((label, i) => (
        <div
          className={classNames('merge-step', {
            active: currentStep === i,
            completed: currentStep > i
          })}
          key={label}
        >
          <div className="step-icon">
            {currentStep > i ? <i className="fa fa-check" /> : i + 1}
          </div>
          <span>{label}</span>
        </div>
      ))}
    </div>
  </div>
);

export default MergeSteps;
