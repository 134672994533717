/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectCard', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospects/prospect-card.html',
      controller: 'ProspectCardController',
      scope: {
        prospect: '=',
        tour_type: '<tourType'
      }
    };
  });

  app.controller('ProspectCardController', [
    '$scope',
    'conversationsService',
    function ($scope, conversationsService) {
      $scope.openThread = function () {
        var openAsModal = true;
        conversationsService.openThreadById(
          $scope.prospect.stream_id,
          openAsModal
        );
      };
    }
  ]);
})(window.angular);
