import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import map from 'lodash/map';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
(function (angular, $) {
  var app = angular.module('knockApp');

  const newProspectService = function ($rootScope, $mdDialog, prospectsApi) {
    var self = this;

    self.prospectExportStatuses = {};

    self.events = {
      prospectExportUpdated: 'prospect-export-updated',
      prospectSyncComplete: 'prospect-sync-complete'
    };

    self.addNewProspect = function (visitTime) {
      return $mdDialog.show({
        controller: 'NewProspectModalController',
        templateUrl: '/angular/views/prospects/new-prospect-modal.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          visitTime: visitTime
        }
      });
    };

    self.updateProspectExport = function (payload) {
      if (payload.operation === 'export-prospect') {
        self.prospectExportStatuses[payload.prospect_id] = payload.status;

        $rootScope.$emit(self.events.prospectExportUpdated, {
          prospectId: payload.prospect_id,
          status: payload.status
        });
      }
    };

    self.getProspectExportStatus = function (prospectId) {
      return self.prospectExportStatuses[prospectId] || null;
    };

    self.syncPMSProspect = function (prospectId, credentialId) {
      return prospectsApi
        .syncPMSProspect(prospectId, credentialId)
        .then(function (response) {
          $rootScope.$emit(self.events.prospectSyncComplete, prospectId);

          return response;
        });
    };

    return self;
  };

  newProspectService.$inject = ['$rootScope', '$mdDialog', 'prospectsApi'];
  app.factory('newProspectService', newProspectService);

  const NewProspectModalController = function (
    $scope,
    $rootScope,
    $timeout,
    $q,
    $mdDialog,
    $routeParams,
    $mdToast,
    appDataService,
    newProspectService,
    userService,
    conversationsService,
    prospectAppointmentModalFactory,
    visitsService,
    shownUnitsModalService,
    teamApi,
    leasingTeamApi,
    searchApi,
    prospectsApi,
    contactTypes,
    visitTime
  ) {
    var self = this;
    var user = userService.getScopedUser();

    $scope.data = {
      useNewUnitsFeature: $rootScope.$root.featureFlags.NEW_UNITS || false,
      prospect: {
        preferences: {
          // TODO: Remove legacy floorplan fields
          preferred_property_floorplan_id: null,
          preferred_layout_id: null
        },
        profile: {
          first_name: '',
          last_name: '',
          phone: {},
          target_move_date: null,
          email: ''
        },
        assigned_manager_id: null,
        source: '',
        first_contact_type: visitTime ? 'walk-in' : '',
        is_waitlist: false
      },
      properties: [],
      teamMembers: [],
      sources: [],
      contactTypes: [],
      duplicates: {
        phone: [],
        email: []
      },
      firstContact: {
        date: visitTime || null,
        time: visitTime || null
      },
      noEmail: false,
      noPhone: false
    };

    $scope.viewState = {
      isCheckingForDuplicates: {
        phone: false,
        email: false
      }
    };

    self._initialize = function () {
      $scope.data.contactTypes = contactTypes.get();

      self._setProperties();
      self._setTeamMembers();
      self._setSources();

      $timeout(
        function () {
          $('#firstName').focus();
        },
        1000,
        true
      );
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    self._setProperties = function () {
      var properties = appDataService.getProperties();

      $scope.data.properties = sortBy(
        map(properties, self._mapProperty),
        'title'
      );
      $scope.data.prospect.property_id = $scope.data.properties[0].id;
    };

    self._mapProperty = function (property) {
      return {
        title: property.Property.data.location.name,
        id: property.Property.id,
        preferences: property.Property.preferences
      };
    };

    self._setTeamMembers = function () {
      $scope.data.teamMembers = sortBy(
        map(appDataService.getTeamMembers(), function (manager) {
          return {
            id: manager.Manager.id,
            name:
              manager.ManagerInfo.first_name +
              ' ' +
              manager.ManagerInfo.last_name
          };
        }),
        'name'
      );

      if ($scope.data.teamMembers.length === 1) {
        $scope.data.prospect.assigned_manager_id = user.id;
      }
    };

    self._setSources = function () {
      $scope.data.sources = map(appDataService.getSources(), 'source_name');
    };

    $scope.propertyRequiresProspectFloorplan = function () {
      if (!$scope.data.prospect.property_id) {
        return;
      }

      var selectedProperty = find($scope.data.properties, {
        id: parseInt($scope.data.prospect.property_id, 10)
      });
      return (
        selectedProperty &&
        selectedProperty.preferences.require_prospect_floorplan
      );
    };

    $scope.clearPhone = function () {
      $scope.data.prospect.profile.phone = {};
      $scope.data.duplicates.phone = [];
    };

    $scope.clearEmail = function () {
      $scope.data.prospect.profile.email = '';
      $scope.data.duplicates.email = [];
    };

    $scope.getDuplicate = function () {
      if ($scope.data.duplicates.email.length > 0) {
        return $scope.data.duplicates.email[0];
      }

      if ($scope.data.duplicates.phone.length > 0) {
        return $scope.data.duplicates.phone[0];
      }

      return null;
    };

    $scope.checkForPropertyDuplicates = function () {
      if (!isEmpty($scope.data.prospect.profile.phone.phone_number)) {
        $scope.checkForPhoneDuplicates();
      }

      if (!isEmpty($scope.data.prospect.profile.email)) {
        $scope.checkForEmailDuplicates();
      }
    };

    $scope.checkForPhoneDuplicates = function () {
      if (isEmpty($scope.data.prospect.profile.phone.phone_number)) {
        return;
      }

      var phoneNumber = $scope.data.prospect.profile.phone.phone_number;

      searchApi
        .prospectSearchByProperty(
          $scope.data.prospect.property_id,
          phoneNumber,
          'phone'
        )
        .then(function (response) {
          self._processDuplicates(response.data.results, 'phone');

          $scope.viewState.isCheckingForDuplicates.phone = false;
          $scope.viewState.isCheckingForDuplicates.email = false;
        });
    };

    $scope.checkForEmailDuplicates = function () {
      if (isEmpty($scope.data.prospect.profile.email)) {
        return;
      }

      var email = $scope.data.prospect.profile.email;

      searchApi
        .prospectSearchByProperty(
          $scope.data.prospect.property_id,
          email,
          'email'
        )
        .then(function (response) {
          self._processDuplicates(response.data.results, 'email');

          $scope.viewState.isCheckingForDuplicates.phone = false;
          $scope.viewState.isCheckingForDuplicates.email = false;
        });
    };

    self._processDuplicates = function (results, key) {
      var getProspectProperty = function (prospect) {
        if (!prospect.property_id) {
          return;
        }

        return find($scope.data.properties, { id: prospect.property_id });
      };

      var doesNotHaveInactiveProperty = function (prospect) {
        return (
          parseInt(prospect.property_id) ===
            parseInt($scope.data.prospect.property_id) &&
          (!prospect.property_id || !isEmpty(prospect.property))
        );
      };

      var mapDuplicate = function (result) {
        var prospect = result.hit;
        prospect.property = getProspectProperty(prospect);

        return prospect;
      };

      $scope.data.duplicates[key] = filter(
        map(results.hits, mapDuplicate),
        doesNotHaveInactiveProperty
      );
    };

    $scope.viewDuplicateProspect = function () {
      conversationsService.openThreadInNewWindow(
        $scope.getDuplicate().stream_id
      );
    };

    $scope.save = function () {
      $scope.viewState.formIsDirty = true;

      if ($scope.addProspectForm.$invalid) {
        $mdToast.showSimple('Some fields are missing or invalid.');
        return;
      }

      $scope.viewState.isSaving = true;

      var payload = {
        preferences: {
          // TODO: remove legacy floorplan field
          preferred_property_floorplan_id:
            $scope.data.prospect.preferences.preferred_property_floorplan_id,
          preferred_layout_id:
            $scope.data.prospect.preferences.preferred_layout_id
        },
        profile: {
          first_name: $scope.data.prospect.profile.first_name,
          last_name: $scope.data.prospect.profile.last_name,
          phone: $scope.data.prospect.profile.phone,
          email: $scope.data.prospect.profile.email,
          target_move_date: $scope.data.prospect.profile.target_move_date
        },
        property_id: $scope.data.prospect.property_id,
        manager_id: $scope.data.prospect.assigned_manager_id,
        source: $scope.data.prospect.source,
        first_contact_type: $scope.data.prospect.first_contact_type,
        pms_created_time: self._getFormattedFirstContactTime(),
        is_waitlist: $scope.data.prospect.is_waitlist
      };

      return prospectsApi.createNewProspect(payload).then(
        function (response) {
          var additionalPromises = [];
          var isAddingWalkIn =
            $scope.data.prospect.first_contact_type === 'walk-in';

          if (isAddingWalkIn) {
            additionalPromises.push(self._saveVisit(response.data.prospect.id));
          }

          // TODO: remove legacy floorplan field, here and within $scope.data.prospect.preferences below
          const selected_preferred_property_floorplan_id =
            $scope.data.prospect.preferences.preferred_property_floorplan_id;
          // new units floorplan field
          const selected_layout_id =
            $scope.data.prospect.preferences.preferred_layout_id;
          $scope.data.prospect = response.data.prospect;

          $scope.data.prospect.preferences = {
            preferred_property_floorplan_id:
              selected_preferred_property_floorplan_id,
            preferred_layout_id: selected_layout_id
          };

          $q.all(additionalPromises).then(
            function (additionalResponses) {
              if (isAddingWalkIn) {
                var walkInCreationResponse = additionalResponses[0];

                shownUnitsModalService
                  .openShownUnitsModal(
                    response.data.prospect,
                    walkInCreationResponse.data.visit
                  )
                  .then(
                    function () {
                      self._prospectCreated(response);
                    },
                    function () {
                      self._prospectCreated(response);
                    }
                  );
              } else {
                $timeout(
                  function () {
                    self._prospectCreated(response);
                  },
                  2000,
                  true
                );
              }
            },
            function () {
              $scope.viewState.isSaving = false;
              $mdToast.showSimple('There was an error adding your prospect.');
            }
          );
        },
        function () {
          $scope.viewState.isSaving = false;
          $mdToast.showSimple('There was an error adding your prospect.');
        }
      );
    };

    self._saveVisit = function (prospectId) {
      var visitTime = self._getFormattedFirstContactTime();

      return visitsService.createVisit(
        prospectId,
        visitTime,
        null,
        $scope.data.prospect.assigned_manager_id
      );
    };

    self._getFormattedFirstContactTime = function () {
      if (!$scope.data.firstContact.date || !$scope.data.firstContact.time) {
        return '';
      }

      var formattedDate = moment($scope.data.firstContact.date).format(
        'YYYY-MM-DD'
      );
      var formattedTime = moment($scope.data.firstContact.time).format(
        'HH:mmZ'
      );

      return formattedDate + 'T' + formattedTime;
    };

    self._prospectCreated = function (response) {
      if ($routeParams.chatThreadId !== response.data.prospect.stream_id) {
        conversationsService.openThreadById(
          response.data.prospect.stream_id,
          true
        );
      }

      $mdDialog.hide(response.data.prospect);
    };

    self._initialize();
  };

  NewProspectModalController.$inject = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$q',
    '$mdDialog',
    '$routeParams',
    '$mdToast',
    'appDataService',
    'newProspectService',
    'userService',
    'conversationsService',
    'prospectAppointmentModalFactory',
    'visitsService',
    'shownUnitsModalService',
    'teamApi',
    'leasingTeamApi',
    'searchApi',
    'prospectsApi',
    'contactTypes',
    'visitTime'
  ];
  app.controller('NewProspectModalController', NewProspectModalController);
})(window.angular, window.jQuery);
