/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
require('../../core/scripts/account/account.module');

require('../../core/scripts/account/accountView.service');
require('../../core/scripts/account/accountView.controller');

require('../../core/scripts/account/accountProfile.directive');
require('../../core/scripts/account/accountChangePassword.controller');

require('../../core/scripts/account/accountPreferences.directive');
require('../../core/scripts/account/preferences/accountPreferencesHours.directive');
require('../../core/scripts/account/preferences/accountPreferencesScheduling.directive');
require('../../core/scripts/account/preferences/accountPreferencesPrescreening.directive');
require('../../core/scripts/account/preferences/accountPreferencesNotifications.directive');
require('../../core/scripts/account/preferences/accountPreferencesTodo.directive');
