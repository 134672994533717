import React, { useState, useEffect, FC, useCallback, useMemo } from 'react';
import { Button, Menu, TextField, makeStyles } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

import { Box, Text, NamedColors } from '@knockrentals/knock-shared-web';
import { colors, useCommonStyles } from '../../commonStyles/commonStyles';

const { defaultText, inputBackground } = colors;

const useStyles = makeStyles({
  menu: {
    border: `1px solid ${NamedColors.slate[200]}`,
    borderRadius: '4px',
    marginTop: '4px',
    width: '450px'
  },

  textField: {
    border: 'none',
    backgoundColor: inputBackground,
    '& .MuiFilledInput-root': {
      border: 'none',
      backgroundColor: inputBackground,
      '&:hover': {
        backgoundColor: inputBackground
      }
    },
    '& .MuiInputLabel-formControl': {
      top: '20px',
      left: '0px'
    },
    '& .MuiInputBase-root': {
      border: 'none',
      paddingLeft: '0px'
    },
    top: '-20px',
    height: '40px',
    margin: '4px'
  },

  selectChip: {
    alignItems: 'center',
    backgroundColor: NamedColors.slate[100],
    border: `1px solid ${NamedColors.slate[100]}`,
    borderRadius: '16px',
    flexDirection: 'row',
    height: '32px',
    minWidth: '72px',
    padding: '4px',
    margin: '4px',

    '&:hover': {
      backgroundColor: NamedColors.denim[100]
    },

    '&:disabled': {
      backgroundColor: 'inherit',
      border: 'none',
      color: colors.disabled,

      '& > .MuiButton-label > svg': {
        color: colors.disabled
      }
    }
  },

  selectChipOutlined: {
    backgroundColor: NamedColors.denim[100],
    borderColor: NamedColors.denim[500]
  },

  filterName: {
    fontSize: '13px',
    marginLeft: '6px'
  },

  filterContainer: {
    padding: '16px'
  },

  dateSelector: {
    padding: '16px'
  },

  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '68px',
    padding: '0 16px'
  },

  cancelButton: {
    marginRight: '12px'
  },

  keyboardArrow: {
    color: colors.secondaryText,
    margin: '6px 3px'
  }
});

interface ChipPriceRangeProps {
  filterOptions: [];
  maxPrice: number | null;
  minPrice: number | null;
  chipLabel: string;
  onApply: (maxPrice: number | null, minPrice: number | null) => void;
  className?: string;
  isDisabled?: boolean;
  useFutureDates?: boolean;
  disableApply?: boolean;
  maxPriceError?: string;
  minPriceError?: string;
  allowEmpty?: boolean;
  onChange?: (event: any) => void;
  onCancel?: () => void;
  onReset?: () => void;
}

const ChipPriceRange: FC<ChipPriceRangeProps> = ({
  filterOptions,
  maxPrice,
  minPrice,
  chipLabel,
  onApply,
  className,
  isDisabled = false,
  onChange,
  onCancel,
  allowEmpty,
  onReset
}: ChipPriceRangeProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMaxPrice, setSelectedMaxPrice] = useState<number | null>(null);
  const [selectedMinPrice, setSelectedMinPrice] = useState<number | null>(null);
  const setFieldDefaults = useCallback(() => {
    setSelectedMaxPrice(maxPrice);
    setSelectedMinPrice(minPrice);
  }, [maxPrice, minPrice]);

  useEffect(() => {
    setFieldDefaults();
  }, [setFieldDefaults, minPrice, maxPrice]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: Object, reason: string) => {
    // Prevents the menu from being closed if the Tab key is pressed
    if (reason !== 'tabKeyDown') {
      if (reason !== 'apply') {
        setFieldDefaults();
      }
      setAnchorEl(null);
      if (onCancel !== undefined) {
        onCancel();
      }
    }
  };

  const handleReset = () => {
    setSelectedMaxPrice(null);
    setSelectedMinPrice(null);
    onApply(null, null);
    handleClose({}, 'apply');
  };

  const handleCancel = () => {
    handleClose({}, 'cancel');
  };

  const handleApply = () => {
    onApply(selectedMaxPrice, selectedMinPrice);
    handleClose({}, 'apply');
  };

  const dateLabel = useMemo<string>(() => {
    let label = chipLabel;
    if (maxPrice && minPrice) {
      label += `: $${minPrice.toString()} - $${maxPrice.toString()}`;
    } else if (allowEmpty) {
      if (minPrice) {
        label += `: >$${minPrice.toString()}`;
      } else if (maxPrice) {
        label += `: <$${maxPrice.toString()}`;
      }
    }
    return label;
  }, [minPrice, maxPrice, allowEmpty, chipLabel]);

  const commonPropsForTextField: any = {
    variant: 'filled',
    fullWidth: true,
    className: classes.textField,
    inputProps: {
      style: {
        backgroundColor: inputBackground
      }
    },
    InputProps: {
      style: {
        border: 'none',
        color: defaultText,
        backgroundColor: inputBackground,
        paddingLeft: '0px !important',
        '& .MuiFilledInput-root': {
          border: 'none',
          backgroundColor: inputBackground
        },
        '& .MuiInputBase-root': {
          border: 'none',
          paddingLeft: '0px !important',
          '&.Mui-focused': {
            border: 'none',
            backgroundColor: inputBackground
          }
        }
      }
    },
    onChange: (e: any) => {
      if (e?.target.name === 'maxPrice') {
        setSelectedMaxPrice(e.target.value);
      }
      if (e?.target.name === 'minPrice') {
        setSelectedMinPrice(e.target.value);
      }
    }
  };
  return (
    <Box className={className ? className : undefined}>
      <Button
        disabled={isDisabled}
        variant={anchorEl ? 'outlined' : 'text'}
        onClick={handleOpen}
        classes={{
          root: classes.selectChip,
          outlined: classes.selectChipOutlined
        }}
      >
        <Text variant="body2" className={classes.filterName}>
          {dateLabel}
        </Text>

        {anchorEl ? (
          <KeyboardArrowUp className={classes.keyboardArrow} />
        ) : (
          <KeyboardArrowDown className={classes.keyboardArrow} />
        )}
      </Button>

      <Menu
        classes={{ paper: classes.menu }}
        elevation={0}
        MenuListProps={{ role: 'listbox', disablePadding: true }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          <Box style={{ display: 'flex', padding: '14px' }}>
            <TextField
              id="minPrice"
              label="Min Price"
              name="minPrice"
              {...commonPropsForTextField}
              value={selectedMinPrice !== null ? selectedMinPrice : ''}
              type="number"
            />
            <TextField
              id="maxPrice"
              label="Max Price"
              name="maxPrice"
              {...commonPropsForTextField}
              value={selectedMaxPrice !== null ? selectedMaxPrice : ''}
              type="number"
            />
          </Box>

          <Box className={classes.actionButtons}>
            <Button
              variant="text"
              color="primary"
              onClick={handleReset}
              data-testid="reset-button"
            >
              Reset
            </Button>

            <Box>
              <Button
                variant="outlined"
                color="primary"
                className={classes.cancelButton}
                onClick={handleCancel}
                data-testid="cancel-button"
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                disabled={
                  false
                  //disableApply !== undefined ? disableApply : isApplyDisabled
                }
                className={commonClasses.primaryButton}
                onClick={handleApply}
                data-testid="apply-button"
              >
                Apply
              </Button>
            </Box>
          </Box>
        </div>
      </Menu>
    </Box>
  );
};

export default ChipPriceRange;
