import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../commonStyles/commonStyles';

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.hintText
  }
}))(Tooltip);

export default DarkTooltip;
