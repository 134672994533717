/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const visitsService = function (
    $rootScope,
    visitsApi,
    managerCalendarEventsService,
    completeAppointmentModalFactory
  ) {
    var self = this;
    self.appointmentToComplete = {};

    self.events = {
      visitCreated: 'visit-event-visit-created',
      visitRemoved: 'visit-event-visit-removed',
      shownUnitsAdded: 'visit-event-shown-units-added'
    };

    self.getVisit = function (visitId) {
      return visitsApi.getVisit(visitId);
    };

    self.createVisit = function (
      prospectId,
      visitTime,
      appointmentId,
      managerId
    ) {
      return visitsApi
        .createVisit(prospectId, visitTime, appointmentId, managerId)
        .then(function (response) {
          response.data.visit.event_type = 'visit';

          $rootScope.$emit(self.events.visitCreated, response.data.visit);
          $rootScope.$emit(
            managerCalendarEventsService.eventTypes.visit.wasAdded,
            response.data.visit
          );

          return response;
        });
    };

    self.deleteVisit = function (visitId) {
      return visitsApi.deleteVisit(visitId).then(function (response) {
        $rootScope.$emit(self.events.visitRemoved, visitId);
        $rootScope.$emit(
          managerCalendarEventsService.eventTypes.visit.wasRemoved,
          visitId
        );

        return response;
      });
    };

    self.completeAppointment = function (prospect, appointment) {
      return completeAppointmentModalFactory.openCompleteAppointmentModal(
        prospect,
        appointment
      );
    };

    self.onShownUnitsAdded = function (event, shownUnits) {
      $rootScope.$emit(self.events.shownUnitsAdded, {
        event: event,
        shownUnits: shownUnits
      });
    };

    self.setAppointmentToComplete = function (data) {
      self.appointmentToComplete = data;
    };

    self.getAppointmentToComplete = function () {
      return self.appointmentToComplete;
    };

    return self;
  };

  visitsService.$inject = [
    '$rootScope',
    'visitsApi',
    'managerCalendarEventsService',
    'completeAppointmentModalFactory'
  ];

  app.factory('visitsService', visitsService);
})(window.angular);
