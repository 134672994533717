import React, { FC } from 'react';
export const LeasingUtilitiesIcon: FC = () => (
  <svg
    width={14}
    height={25}
    viewBox="0 0 14 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.848 12.306C13.0422 11.9904 13.0505 11.5945 12.8698 11.2711C12.689 10.9476 12.3475 10.7471 11.977 10.747H8.50002V1.74701L1.15202 13.689C0.957715 14.0044 0.949337 14.4003 1.13012 14.7237C1.31091 15.0471 1.65254 15.2473 2.02302 15.247H5.50002V24.247L12.848 12.306Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
