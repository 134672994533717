// @ts-nocheck
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export const getDateString = (
  date: MaterialUiPickersDate,
  format: string | null = null
) => {
  return format
    ? moment(date).format(format)
    : moment(date).format('YYYY-MM-DD');
};

export const getDate = (dateString: string | undefined) => {
  return dateString ? moment(dateString).toDate() : null;
};

export const formatDate = (isoDateString: string) => {
  try {
    const date = new Date(isoDateString);
    const [fullYear, month, day] = date.toISOString().split('T')[0].split('-');
    return `${month}/${day}/${fullYear}`;
  } catch (_) {
    return '';
  }
};

export const formatDateString = (dateString: string) => {
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
};

export const getDurationFromNow = (
  input: any,
  small: boolean,
  onlySmallDay: boolean,
  onlyWeeks: boolean
) => {
  if (!input) {
    return 'Never';
  }

  const inputMoment = moment(input);
  const thirtySecondsAgo = moment().subtract(30, 'seconds');

  if (inputMoment.isAfter(moment()) || inputMoment.isAfter(thirtySecondsAgo)) {
    return '1s';
  }

  if (!small) {
    const dayAgo = moment().subtract(1, 'days');

    return inputMoment.isBefore(dayAgo)
      ? inputMoment.format('M/D/YY')
      : moment(input).fromNow();
  }

  const now = moment();
  const difference = now - inputMoment;
  const duration = moment.duration(difference);

  const years = Math.round(duration.asYears());
  const months = Math.round(duration.asMonths());
  const weeks = Math.round(duration.asWeeks());
  const days = Math.round(duration.asDays());
  const hours = Math.round(duration.asHours());
  const minutes = Math.round(duration.asMinutes());
  const seconds = Math.round(duration.asSeconds());

  if ((months || years || (onlySmallDay && days)) && !onlyWeeks) {
    return inputMoment.format('M/D/YY');
  }

  if (weeks && onlyWeeks) {
    return weeks.toString() + 'w';
  }

  if (days) {
    return days.toString() + 'd';
  }

  if (hours) {
    return hours.toString() + 'h';
  }

  if (minutes) {
    return minutes.toString() + 'm';
  }

  if (seconds) {
    return seconds.toString() + 's';
  }

  return 'Just now';
};

export const invalidDateRange = (
  startDate: Date | null,
  endDate: Date | null
) => {
  return (
    startDate == null ||
    endDate === null ||
    isNaN(startDate.getTime()) ||
    isNaN(endDate.getTime())
  );
};

export const isDateInvalid = (date: Date | null) => {
  return isNaN(date?.getTime());
};
