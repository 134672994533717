import React, { FC, useState } from 'react';

import { QuickReplyList } from './QuickReplyList';
import { AvailableQuickReply, ManagerInfo, QuickReply } from './models';
import {
  Button,
  makeStyles,
  NamedColors,
  ThemeProvider
} from '@knockrentals/knock-shared-web';
import { QuickRepliesIcon } from '../icons/QuickRepliesIcon';
import { QuickReplyModal } from './QuickReplyModal';
import { getEmptyQuickReply } from '../../utilities/quickReplyUtilities';

const useStyles = makeStyles({
  quickRepliesButton: {
    color: NamedColors.slate[800],
    minWidth: 'max-content',

    // There is some style somewhere that reduces the height and width of this SVG when the browser is resized, for some reason. So we set the min
    // values below to prevent that from happening.
    '& svg': {
      marginRight: '8px',
      minHeight: '20px',
      minWidth: '20px'
    },

    '&:focus': {
      color: NamedColors.slate[800],

      '& svg path': {
        stroke: NamedColors.slate[800]
      }
    }
  }
});

export interface QuickReplyHostProps {
  isCompanyQuickRepliesEnabled: boolean;
  messageMode: string;
  managerInfo: ManagerInfo;
  getManagerQuickReplies: () => Promise<any>;
  saveQuickReply: (managerId: number, payload: QuickReply) => void;
  deleteQuickReply: (
    managerId: number,
    messageId: string,
    useDeleteEndpoint: boolean
  ) => void;
  onAppendText: (message: string, fromQuickReply: boolean) => void;
  mergeTags: [];
}

export const QuickReplyHost: FC<QuickReplyHostProps> = ({
  isCompanyQuickRepliesEnabled,
  messageMode,
  managerInfo,
  getManagerQuickReplies,
  saveQuickReply,
  deleteQuickReply,
  onAppendText,
  mergeTags
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openQuickReplyModal, setOpenQuickReplyModal] =
    useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedQuickReply, setSelectedQuickReply] = useState<QuickReply>(
    getEmptyQuickReply(managerInfo)
  );

  const handleOpenQuickReplyModalList = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseQuickReplyList = (
    openQuickReplyModal: boolean,
    editMode: boolean = false,
    quickReply?: AvailableQuickReply,
    appendMessage?: boolean
  ) => {
    // When the quick reply list component closes, there are various actions that may need to be taken depending on what the user wants to do. If
    // they want to create or edit a quick reply, we initialize the props for the modal component and set the flag that makes it visible.
    // Otherwise we see if they have selected a quick reply to append to their new message and if so, we add that text.
    if (openQuickReplyModal) {
      const quickReplyToOpen =
        (quickReply as QuickReply) || getEmptyQuickReply(managerInfo);

      setEditMode(editMode);
      setSelectedQuickReply(quickReplyToOpen);
      setOpenQuickReplyModal(true);
    } else if (appendMessage && quickReply) {
      const message =
        messageMode === 'email'
          ? quickReply.message || ''
          : quickReply.message_sms || '';
      onAppendText(message, true);
    }

    setAnchorEl(null);
  };

  const handleCloseQuickReplyModal = () => {
    setOpenQuickReplyModal(false);
  };

  return (
    <ThemeProvider>
      <Button
        variant="text"
        className={classes.quickRepliesButton}
        onClick={handleOpenQuickReplyModalList}
      >
        <QuickRepliesIcon /> Quick Replies
      </Button>

      <QuickReplyList
        anchorEl={anchorEl}
        isCompanyQuickRepliesEnabled={isCompanyQuickRepliesEnabled}
        getManagerQuickReplies={getManagerQuickReplies}
        messageMode={messageMode}
        onClose={handleCloseQuickReplyList}
      />

      <QuickReplyModal
        open={openQuickReplyModal}
        editMode={editMode}
        quickReply={selectedQuickReply}
        onClose={handleCloseQuickReplyModal}
        saveQuickReply={saveQuickReply}
        deleteQuickReply={deleteQuickReply}
        mergeTags={mergeTags}
      />
    </ThemeProvider>
  );
};
