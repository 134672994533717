(function (angular) {
  var app = angular.module('knockApp');

  app.factory('rentMatrixService', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openMatrixModal = function (
        unit,
        clickable,
        propertyId,
        isEntrataIntegration
      ) {
        return $mdDialog.show({
          templateUrl: '/angular/views/properties/rent-matrix-modal.html',
          controller: 'RentMatrixModalController',
          clickOutsideToClose: true,
          locals: {
            unit: unit,
            clickable: clickable || false,
            propertyId,
            isEntrataIntegration
          }
        });
      };

      return self;
    }
  ]);

  function formatRentMatrix(rentMatrix) {
    var dates = Object.keys(rentMatrix);
    var formattedRentMatrix = {};

    /**
     * Formats rent matrix into month -> date -> price so we can easily access it from our table.
     */
    for (var i = 0; i < dates.length; i++) {
      var date = dates[i];
      var prices = rentMatrix[date];
      var monthKeys = Object.keys(prices);

      /**
       * Iterate through our months to begin building our rent matrix.
       */
      for (var j = 0; j < monthKeys.length; j++) {
        var months = monthKeys[j];

        /**
         * Initialize on object for our month so we can add a price to it.
         */
        if (!formattedRentMatrix.hasOwnProperty(months)) {
          formattedRentMatrix[months] = {};
        }

        formattedRentMatrix[months][date] = prices[months];
      }
    }

    return formattedRentMatrix;
  }

  /**
   * Removes any dates from the rent matrix that has no valid months.
   * This means months that are equal to none or dates with no months.
   *
   * ie.
   * {
   *     "08/01/2019": {}
   * }
   *
   * or:
   * {
   *     "08/01/2019": {
   *         "5": "None"
   *     }
   * }
   * @param rentMatrix
   */
  function filterRentMatrix(rentMatrix) {
    var filteredRentMatrix = {};
    var rentMatrixKeys = Object.keys(rentMatrix);

    for (var i = 0; i < rentMatrixKeys.length; i++) {
      /**
       * Get date key so we can use it to retrieve the associated months.
       */
      var dateKey = rentMatrixKeys[i];
      var rentMatrixMonths = Object.keys(rentMatrix[dateKey]);

      /**
       * This will automatically filter out any dates that have an empty object value "{}"
       */
      for (var j = 0; j < rentMatrixMonths.length; j++) {
        /**
         * Get month key so we can use it to retrieve associated prices.
         */
        var monthKey = rentMatrixMonths[j];
        var rentMatrixPrice = rentMatrix[dateKey][monthKey];

        /**
         * If our price is null or not a number we want to check next price.
         * otherwise add it to our "filtered rent matrix"
         */
        if (rentMatrixPrice == null || isNaN(Number(rentMatrixPrice))) {
          continue;
        }

        if (!filteredRentMatrix.hasOwnProperty(dateKey)) {
          filteredRentMatrix[dateKey] = {};
        }

        filteredRentMatrix[dateKey][monthKey] = rentMatrixPrice;
      }
    }
    return filteredRentMatrix;
  }

  app.controller('RentMatrixModalController', [
    '$scope',
    '$rootScope',
    '$mdDialog',
    'unit',
    'clickable',
    'propertyId',
    'isEntrataIntegration',
    '_',
    '$moment',
    'prospectIntegrationsApi',
    function (
      $scope,
      $rootScope,
      $mdDialog,
      unit,
      clickable,
      propertyId,
      isEntrataIntegration,
      _,
      $moment,
      prospectIntegrationsApi
    ) {
      const updateRentMatrix = function (unitRentMatrix) {
        const rentMatrix = filterRentMatrix(unitRentMatrix);
        $scope.rentMatrix = formatRentMatrix(rentMatrix);

        // get array of dates for x-axis of the rent matrix
        $scope.dates = Object.keys(rentMatrix);

        // filter out dates between the current date and unit availability date
        let filterableDateTime = $moment().startOf('day'); // Default to today
        if (unit.availableOn != null && unit.availableOn !== '-') {
          const availableOnTime = $moment(unit.availableOn);
          if (availableOnTime > filterableDateTime) {
            filterableDateTime = availableOnTime;
          }
        }

        $scope.dates = _.filter(Object.keys(rentMatrix), function (date) {
          return $moment(date) >= filterableDateTime;
        });

        // get array of months for the y-axis of the rent matrix
        $scope.months = Object.keys($scope.rentMatrix);
      };

      updateRentMatrix(unit.rentMatrix);

      $scope.today = new Date();
      $scope.leaseStart = $scope.dates[0] || $scope.today;

      $scope.unit = unit;
      $scope.clickable = clickable;
      $scope.loading = false;
      $scope.isEntrataIntegration = isEntrataIntegration;

      $scope.selectRentCell = function (termLength, startDate, monthlyRent) {
        $mdDialog.hide({
          startDate: startDate,
          termLength: Number(termLength),
          monthlyRent: monthlyRent
        });
      };

      $scope.updateStartDate = function (startDate) {
        $scope.loading = true;
        prospectIntegrationsApi
          .getEntrataPricingMatrix(
            propertyId,
            $moment(startDate).format('YYYY-MM-DD'),
            unit.name
          )
          .then((response) => {
            updateRentMatrix(response.data.unit_matrix);
          })
          .catch((err) => {
            console.error(`failed to fetch entrata pricing matrix: ${err}`);
            updateRentMatrix({});
          })
          .finally(() => {
            $scope.loading = false;
          });
      };
    }
  ]);
})(window.angular);
