import React from 'react';

export const SkipIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9681 4.067C19.9621 4.06 19.9611 4.052 19.9541 4.045C19.9471 4.038 19.9391 4.037 19.9331 4.031C15.5352 -0.308162 8.46011 -0.286155 4.08929 4.08028C-0.281544 8.44671 -0.31067 15.5217 4.02406 19.924C4.03306 19.933 4.03606 19.946 4.04506 19.955C4.05406 19.964 4.06606 19.966 4.07606 19.975C8.4694 24.3607 15.5858 24.3558 19.9731 19.9641C24.3605 15.5723 24.3582 8.45592 19.9681 4.067ZM12.0001 4.5C13.3377 4.50091 14.6504 4.86224 15.8001 5.546L5.54706 15.8C4.17455 13.4844 4.15129 10.6102 5.48614 8.27267C6.82099 5.93519 9.30829 4.49461 12.0001 4.5ZM12.0001 19.5C10.6624 19.4985 9.34976 19.1368 8.20006 18.453L18.4541 8.2C19.8253 10.5159 19.8478 13.3897 18.513 15.7267C17.1781 18.0638 14.6915 19.5045 12.0001 19.5Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
