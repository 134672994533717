import some from 'lodash/some';
import size from 'lodash/size';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { map } from 'lodash';
(function (angular) {
  var app = angular.module('knockApp');

  app.directive('fixedNavPanel', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/navigation/fixed-nav-panel.html',
      controller: 'FixedNavPanelController',
      scope: {
        leasingTeams: '=',
        currentLeasingTeam: '='
      }
    };
  });

  const FixedNavPanelController = function (
    $rootScope,
    $routeParams,
    authenticationService,
    $route,
    chatTrackingService,
    $timeout,
    $location,
    $q,
    $scope,
    appDataService,
    apiEvents,
    ProfileService,
    notificationsService,
    conversationsService,
    OauthProviderApi,
    teamApi,
    managerApi,
    dashboardApi,
    userService,
    prospectHistoryService,
    userInteractionsService,
    newProspectService,
    windowManagementService,
    syncCalendarModalFactory,
    conversationTeamInboxSettingsFactory,
    $auth,
    LOGIN_UI_BASE_URL,
    WEBFRONT_BASE_URL
  ) {
    var self = this;
    $scope.user = userService.getScopedUser();

    $scope.isNewProspectPageEnabled =
      $rootScope.featureFlags.PROSPECT_PAGE_REDESIGN;

    $scope.isNewResidentPageEnabled =
      $rootScope.featureFlags.RESIDENT_PAGE_REDESIGN;

    $scope.isLmaNewNavigation =
      $rootScope.featureFlags.LMA_NEW_NAVIGATION || false;

    $scope.inbox = [];

    $rootScope.$on(
      apiEvents.badgeCountsUpdated,
      function (event, notificationCount, messageCount, teamMessageCounts) {
        $scope.notificationCount = notificationCount;
        $scope.teamMessageCounts = teamMessageCounts;

        const visibleOnly = filter(
          Object.keys(teamMessageCounts),
          (id) => teamMessageCounts[id].show_in_sidebar
        );

        const members = $scope.data.teamMembers || [];
        $scope.inbox = filter(
          map(members, (member) => {
            const id = visibleOnly.find(
              (mId) => member.ManagerInfo.manager_id === Number(mId)
            );
            if (id) {
              return {
                id,
                name:
                  $scope.user.id === Number(id)
                    ? 'Me'
                    : member.ManagerInfo.first_name,
                color: member.ManagerInfo.color,
                count: teamMessageCounts[id].unread_messages.total,
                photo: member.ManagerInfo.photo,
                initials: `${member.ManagerInfo.first_name[0]}${
                  member.ManagerInfo.last_name
                    ? member.ManagerInfo.last_name[0]
                    : ''
                }`.toUpperCase()
              };
            }
          }),
          (inbox) => !!inbox
        ).sort((itemA, itemB) => {
          return itemA.name.localeCompare(itemB.name);
        });

        $scope.inbox = [
          $scope.inbox.find((inbox) => Number(inbox.id) === $scope.user.id),
          ...filter(
            $scope.inbox,
            (inbox) => Number(inbox.id) !== $scope.user.id
          )
        ].filter((i) => !!i);
      }
    );

    $rootScope.$on(userInteractionsService.events.prospectUpdated, function () {
      self._getDashboardTodoData();
    });

    $rootScope.$on(prospectHistoryService.events.prospectUpdated, function () {
      self._getDashboardTodoData();
    });

    $rootScope.$on(
      prospectHistoryService.events.prospectsBatchUpdated,
      function () {
        self._getDashboardTodoData();
      }
    );

    $rootScope.$on(conversationsService.events.sendMessage, function () {
      self._getDashboardTodoData();
    });

    $rootScope.$on(conversationsService.events.sendMessageBatch, function () {
      self._getDashboardTodoData();
    });

    $rootScope.$on(
      conversationsService.events.inboxStateChanged,
      function (event, managerId) {
        $scope.data.inboxUserId = managerId;
      }
    );

    $rootScope.$on(apiEvents.teamMemberChanged, function () {
      self._setTeamMembers();
    });

    $rootScope.$on(apiEvents.calendarProviderUnsynced, function () {
      $scope.hasSync = false;
      $scope.hasSyncException = false;
    });

    $rootScope.$on(apiEvents.calendarProviderException, function () {
      $scope.hasSyncException = !!$scope.hasSync;
    });

    $rootScope.$on(apiEvents.shareLoginChanged, function () {
      $scope.data.shareLoginEnabled = !$scope.data.shareLoginEnabled;
    });

    $rootScope.$on(apiEvents.gotSidebarTodoData, function (event, todo) {
      $scope.todoCount = todo.todo_count;
    });

    $scope.data = {
      teamMembers: null,
      profile: null,
      knockMailerId: null,
      isSupportMode: $routeParams.internal === 'true',
      inboxUserId: $routeParams.userId || null,
      isMultiteam: false,
      newUnitsFeature: $rootScope.featureFlags.NEW_UNITS || false,
      inboundBrowserCallsFeature:
        $rootScope.appPreferences.company.enable_browser_calling || false,
      useCookieForToken: $rootScope.featureFlags.USE_COOKIE_FOR_TOKEN || false,
      appPreferences: $rootScope.appPreferences.company
    };

    $scope.memberLogin = function () {
      teamApi
        .memberLogin($scope.data.activeManager.Manager.id)
        .success(function (res) {
          authenticationService.loginWithToken(res.token);
        })
        .error(function () {
          $scope.memberLoginError = 'unable to login, please try again later';
        });
    };

    $scope.logout = function () {
      if (localStorage.getItem('voiceAppStatusOnLocalStorage')) {
        localStorage.removeItem('voiceAppStatusOnLocalStorage');
      }
      $auth.logout();
      window.location.assign(
        `${LOGIN_UI_BASE_URL}/logout?redirect_url=${WEBFRONT_BASE_URL}/login`
      );
    };

    $scope.openTeamInboxSettings = function () {
      return conversationTeamInboxSettingsFactory.openModal();
    };

    $scope.isInternalTrueUrl = $routeParams.internal === 'true' ? true : false;

    $scope.addProspect = function () {
      newProspectService.addNewProspect();
    };

    $scope.openSearch = function () {
      conversationsService.openSearch();
    };

    $scope.closeSlideOutPanel = function () {
      notificationsService.closePanel();
    };

    $scope.openKnockVoice = function (streamId) {
      if ($rootScope.featureFlags.LMA_VOICEAPP_STATUS_ENHANCEMENT) {
        const status = $rootScope.voiceAppStatus;
        windowManagementService.openKnockVoice(
          streamId,
          status,
          $scope.data.useCookieForToken
        );
      } else {
        windowManagementService.openKnockVoice(
          streamId,
          undefined,
          $scope.data.useCookieForToken
        );
      }
    };

    $scope.getLinkClass = function (path, useUrl) {
      var currentPath = useUrl ? $location.url() : $location.path();
      return currentPath.substr(currentPath.lastIndexOf(path)) === path
        ? 'selected'
        : '';
    };

    $scope.getLinkClassRegex = function (regex) {
      var re = new RegExp(regex);
      return $location.url().match(re) ? 'selected' : '';
    };

    $scope.openSyncCalendar = function () {
      syncCalendarModalFactory.openCalendarSyncModal();
    };

    $scope.editCompanyPage = function () {
      $location.path('/company/me/edit');
    };

    $scope.openChat = function () {
      chatTrackingService.show();
    };

    $scope.reload = function () {
      $timeout(function () {
        $route.reload();
      }, 50);
    };

    $scope.openNotificationsPanel = function () {
      notificationsService.togglePanel();
    };

    $scope.changeSelectedManager = function (managerId) {
      conversationsService.openManagerInbox(managerId);
    };

    $scope.isInbox = function () {
      return conversationsService.isInbox();
    };

    //New Left Navigator
    $scope.newNavigation = $rootScope.featureFlags.LMA_NEW_NAVIGATION;
    $scope.goTo = function (path, params) {
      $location.path(path);
      $location.search(params ? params : {});
      $scope.sideNavDrawerOpen = false;
      $scope.$apply();
    };

    $scope.currentPath = $location.path();

    $scope.sideNavDrawerOpen = false;

    $scope.hideResidents =
      $rootScope.appPreferences.company.hide_residents || false;

    $scope.hideLeasingBinder =
      $rootScope.appPreferences.company.hide_property_views || false;

    let queryParams = new URLSearchParams(window.location.search);
    $scope.leasingTeamId = queryParams.get('lt');

    $rootScope.$on('openSideNavDrawer', (_, open) => {
      $scope.sideNavDrawerOpen =
        open === undefined ? !$scope.sideNavDrawerOpen : open;
      $timeout(() => $scope.$apply());
    });
    $scope.closeSideNavDrawer = () => {
      $scope.sideNavDrawerOpen = false;
      $timeout(() => $scope.$apply());
    };

    $rootScope.$on('$routeChangeSuccess', function () {
      $timeout(() => ($scope.currentPath = $location.path()));
    });

    self._initialize = function () {
      self._setTeamMembers();

      var initPromises = [
        self._getProfile(),
        self._getKnockMailerId(),
        self._getConnectedOauthProviders(),
        self._isMultiteamUser()
      ];

      if ($location.path() !== '/dashboard' || $scope.newNavigation) {
        initPromises.push(self._getDashboardTodoData());
      }

      $q.all(initPromises).finally(function () {
        notificationsService.registerUserEventCallback(
          'oauth-sync-exception',
          function () {
            $scope.hasSyncException = true;
          }
        );

        $scope.isLoaded = true;
      });

      conversationsService.initialize();
      authenticationService.initialize();
    };

    self._getProfile = function () {
      return ProfileService.getProfile().success(function (response) {
        $scope.data.profile = response.profile;
      });
    };

    self._setTeamMembers = function () {
      $scope.data.teamMembers = appDataService.getTeamMembers();

      $scope.data.teamMembersNotMe = filter(
        $scope.data.teamMembers,
        function (member) {
          return member.Manager.id !== $scope.user.id;
        }
      );

      $scope.data.activeManager = find(
        $scope.data.teamMembers,
        function (member) {
          return member.Manager.id === $scope.user.id;
        }
      );
    };

    self._getKnockMailerId = function () {
      return managerApi.getMyKnockEmail().success(function (response) {
        var emailRelay = response.knock_email.email_relay;
        $scope.data.knockMailerId = emailRelay
          ? emailRelay.username
          : undefined;
      });
    };

    self._getConnectedOauthProviders = function () {
      return OauthProviderApi.getUserConnectedProviders().success(function (
        response
      ) {
        $scope.providers = response.providers;
        $scope.hasSync = size($scope.providers) > 0;
        $scope.hasSyncException = some($scope.providers, 'has_sync_exception');
      });
    };

    self._getDashboardTodoData = function () {
      return dashboardApi.getDashboardData('todo').success(function (response) {
        $scope.todoCount = response.data.todo.todo_count;
        $rootScope.$emit(apiEvents.gotSidebarTodoData, {
          todo_count: $scope.todoCount
        });
      });
    };

    self._isMultiteamUser = () => {
      $scope.data.isMultiteam = appDataService.isMultiteamUser();
    };

    self._initialize();
  };

  FixedNavPanelController.$inject = [
    '$rootScope',
    '$routeParams',
    'authenticationService',
    '$route',
    'chatTrackingService',
    '$timeout',
    '$location',
    '$q',
    '$scope',
    'appDataService',
    'apiEvents',
    'ProfileService',
    'notificationsService',
    'conversationsService',
    'OauthProviderApi',
    'teamApi',
    'managerApi',
    'dashboardApi',
    'userService',
    'prospectHistoryService',
    'userInteractionsService',
    'newProspectService',
    'windowManagementService',
    'syncCalendarModalFactory',
    'conversationTeamInboxSettingsFactory',
    '$auth',
    'LOGIN_UI_BASE_URL',
    'WEBFRONT_BASE_URL'
  ];

  app.controller('FixedNavPanelController', FixedNavPanelController);
})(window.angular);
