/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('schedulingErrorOverlay', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/scheduling/scheduling-error-overlay.html',
      controller: [
        '$window',
        '$scope',
        function ($window, $scope) {
          $scope.refresh = function () {
            $window.location.reload();
          };
        }
      ]
    };
  });
})(window.angular);
