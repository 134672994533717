/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import identity from 'lodash/identity';
import every from 'lodash/every';
import values from 'lodash/values';
import pickBy from 'lodash/pickBy';

(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.factory('prospectLossReasonsModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function (prospect) {
        return $mdDialog.show({
          controller: 'ProspectLossReasonsController',
          templateUrl:
            '/angular/views/userInteractions/prospect-loss-reasons-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          locals: {
            prospect: prospect
          }
        });
      };

      return self;
    }
  ]);

  app.controller('ProspectLossReasonsController', [
    '$q',
    '$scope',
    '$mdDialog',
    '$mdToast',
    'prospectHistoryService',
    'prospectLossReasonsService',
    'prospect',
    '$rootScope',
    function (
      $q,
      $scope,
      $mdDialog,
      $mdToast,
      prospectHistoryService,
      prospectLossReasonsService,
      prospect,
      $rootScope
    ) {
      var self = this;

      $scope.cancel = function () {
        // fall back to current prospect status to avoid user confusion
        prospectLossReasonsService
          .getProspect(prospect.id)
          .then(function (result) {
            prospect.status = result.data.prospect.status;
            $mdDialog.cancel();
            if ($rootScope.tour_drawer_opened) {
              $rootScope.$emit('openCompleteTourDrawer');
            }
          });
      };

      $scope.noLossReasonsSelected = function () {
        var flags = values($scope.lossReasonMap);
        return every(flags, function (value) {
          return value === false;
        });
      };

      $scope.save = function () {
        $scope.isSaving = true;

        var lossReasons = Object.keys(pickBy($scope.lossReasonMap, identity));

        prospectLossReasonsService
          .setLossReasons(prospect, lossReasons)
          .success(function () {
            $mdDialog.hide();
            $mdToast.showSimple('Prospect loss reasons saved.');
          })
          .error(function () {
            $mdToast.showSimple(
              'There was an error saving prospect loss reasons.'
            );
          })
          .finally(function () {
            if ($rootScope.tour_drawer_opened) {
              $rootScope.$emit('openCompleteTourDrawer');
            }
            $scope.isSaving = false;
          });
      };

      self._initialize = function () {
        var initPromises = [
          prospectLossReasonsService
            .getLossReasons(prospect)
            .then(function (result) {
              $scope.lossReasonMap = result.data.loss_reasons;
            })
        ];

        $q.all(initPromises).finally(function () {
          $scope.isLoaded = true;
        });
      };

      self._initialize();
    }
  ]);

  app.factory('batchProspectsLossReasonsModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function (prospects, prospectStatus) {
        return $mdDialog.show({
          controller: 'BatchProspectsLossReasonsController',
          templateUrl:
            '/angular/views/userInteractions/prospect-loss-reasons-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          locals: {
            prospects: prospects,
            prospectStatus: prospectStatus
          }
        });
      };

      return self;
    }
  ]);

  app.controller('BatchProspectsLossReasonsController', [
    '$rootScope',
    '$q',
    '$scope',
    '$mdDialog',
    '$mdToast',
    'prospectsApi',
    'prospectHistoryService',
    'prospectLossReasonsService',
    'prospects',
    'prospectStatus',
    function (
      $rootScope,
      $q,
      $scope,
      $mdDialog,
      $mdToast,
      prospectsApi,
      prospectHistoryService,
      prospectLossReasonsService,
      prospects,
      prospectStatus
    ) {
      var self = this;

      $scope.cancel = function () {
        $mdDialog.cancel();
      };

      $scope.noLossReasonsSelected = function () {
        var flags = values($scope.lossReasonMap);
        return every(flags, function (value) {
          return value === false;
        });
      };

      $scope.save = function () {
        $scope.isSaving = true;

        var lossReasons = Object.keys(pickBy($scope.lossReasonMap, identity));
        var prospectIds = map(prospects, 'id');

        prospectsApi
          .setProspectLossReasonsBatch(prospectIds, lossReasons)
          .success(function () {
            prospectsApi
              .setProspectStatusBatch(prospectIds, prospectStatus)
              .then(function () {
                $mdDialog.hide();
                $mdToast.showSimple('Saved!');
                $rootScope.$emit(
                  prospectHistoryService.events.prospectsBatchLossReasonsUpdated
                );
              });
          })
          .error(function () {
            $scope.isSaving = false;
            $mdToast.showSimple(
              'There was an error saving prospect loss reasons.'
            );
          });
      };

      $scope.archiveProspect = function () {
        $scope.isSaving = true;
        var prospectIds = map(prospects, 'id');

        $scope.cancel();

        prospectsApi
          .deleteProspects(prospectIds)
          .error(function () {
            $mdToast.showSimple('There was an error saving prospect');
          })
          .finally(function () {
            $scope.isSaving = false;
          });
      };

      self._initialize = function () {
        prospectLossReasonsService.getAllLossReasons().then(function (result) {
          $scope.lossReasonMap = reduce(
            result.data.loss_reasons,
            function (acc, item) {
              acc[item] = false;
              return acc;
            },
            {}
          );

          $scope.archiveProspectIsSelected = false;
          $scope.isLoaded = true;
        });
      };

      self._initialize();
    }
  ]);
})(window.angular);
