/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import keys from 'lodash/keys';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.directive('userInteractionsPanel', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/userInteractions/user-interactions-panel.html',
      controller: 'UserInteractionsPanelController',
      replace: true,
      scope: {
        conversation: '=',
        noModals: '=?'
      }
    };
  });

  const UserInteractionsPanelController = function (
    $rootScope,
    $scope,
    $moment,
    $mdToast,
    $routeParams,
    $location,
    $q,
    $timeout,
    $mdDialog,
    $interval,
    appDataService,
    conversationsService,
    voiceService,
    userInteractionsService,
    prospectHistoryService,
    prospectStatusMap,
    assignPropertyModalService,
    prospectsApi,
    voiceApi,
    unitApi,
    visitsService,
    prospectTransferModalFactory,
    shownUnitsModalService,
    prospectMissingFieldsModalFactory,
    userService,
    prospectAppointmentModalFactory
  ) {
    var self = this;

    self._isCompletingAppointment = false;
    self._isTransferringProspect = false;

    $scope.currentUser = userService.getScopedUser();
    $scope.getUnitsApi = unitApi.getUnits;
    $rootScope.tour_drawer_opened = false;
    $scope.$watch('conversation', function (newConversation, oldConversation) {
      if (
        newConversation &&
        oldConversation &&
        newConversation.id !== oldConversation.id
      ) {
        self._initialize();
      }
    });

    $scope.$watch(
      function () {
        return visitsService.getAppointmentToComplete();
      },
      function (appointmentToComplete) {
        if (appointmentToComplete) {
          const property = appDataService.getProperty(
            $scope.data.prospect.property_id
          );
          const prospectName =
            $scope.data.prospect.profile.first_name +
            ' ' +
            $scope.data.prospect.profile.last_name;
          let managerName = null;
          if (appointmentToComplete && appointmentToComplete.manager_id) {
            const manager =
              $scope.data.teamMembers[appointmentToComplete.manager_id];
            if (manager) {
              managerName =
                manager.ManagerInfo.first_name +
                ' ' +
                manager.ManagerInfo.last_name;
            } else {
              managerName = 'N/A';
            }
          }

          const infoPanelData = {
            id: appointmentToComplete.id,
            prospectId: $scope.data.prospect.id,
            prospectName: prospectName,
            tourType: appointmentToComplete.tour_type,
            tourDateAndTime: appointmentToComplete.start_time,
            tourProperty: property.Property.data.location.name,
            tourPropertyId: property.Property.id,
            tourOwner: managerName,
            shownUnitsRequired:
              property.Property.preferences.require_prospect_floorplan
          };
          self._isCompletingAppointment = true;
          // To open drawer, set required value
          $scope.infoPanelData = infoPanelData;
          $scope.openCompleteTourDrawer =
            self._isCompletingAppointment &&
            $scope.isOpenCompleteTourDrawerEnabled;
          $rootScope.tour_drawer_opened = false;
          $scope.$apply();
        }
      }
    );

    var eventHandlers = [];

    self.addEventHandler = function (event, handler) {
      eventHandlers.push($rootScope.$on(event, handler));
    };

    $scope.$on('$destroy', function () {
      forEach(eventHandlers, function (destroy) {
        destroy();
      });
    });

    self.addEventHandler(
      conversationsService.events.newTeamMessage,
      function (event, newMessage) {
        if (newMessage.stream_id === $scope.conversation.id) {
          if ($scope.conversation.type === 'prospect') {
            self._getProspect();
          } else if ($scope.conversation.type === 'resident') {
            self._getResident();
          }
        }
      }
    );

    self.addEventHandler(
      userInteractionsService.events.residentUpdated,
      function (event) {
        self._getResident();
      }
    );

    self.addEventHandler(
      prospectHistoryService.events.residentUpdated,
      function (event, resident) {
        if ($scope.data.resident && $scope.data.resident.id === resident.id) {
          assign($scope.data.resident, resident);

          $scope.data.otherUser.info.first_name = resident.profile.first_name;
          $scope.data.otherUser.info.last_name = resident.profile.last_name;
          $scope.data.otherUser.info.phone = resident.profile.phone;

          $scope.data.userInfo.phone = resident.profile.phone;
          $scope.data.userInfo.email = resident.profile.email;
        }
      }
    );

    self.addEventHandler(
      conversationsService.events.smsConsentChanged,
      function (event, payload) {
        if (
          payload.type === 'prospect' &&
          $scope.data.prospect &&
          payload.id === $scope.data.prospect.id
        ) {
          if (!$scope.data.prospect.sms_consent) {
            $scope.data.prospect.sms_consent = {};
          }

          $scope.data.prospect.sms_consent.status = payload.status;
          $mdToast.showSimple('SMS consent for this prospect has changed.');
        }

        if (
          payload.type === 'resident' &&
          $scope.data.resident &&
          payload.id === $scope.data.resident.id
        ) {
          if (!$scope.data.resident.sms_consent) {
            $scope.data.resident.sms_consent = {};
          }

          $scope.data.resident.sms_consent.status = payload.status;
          $mdToast.showSimple('SMS consent for this resident has changed.');
        }
      }
    );

    self.addEventHandler(
      prospectHistoryService.events.prospectUpdated,
      function (event, prospect) {
        if ($scope.data.prospect && prospect.id === $scope.data.prospect.id) {
          self._updateProspect();
        }
      }
    );

    self.addEventHandler(
      voiceService.events.callInitiated,
      function (event, streamId) {
        if (streamId === $scope.conversation.id) {
          self._updateProspect();
        }
      }
    );

    self._updateProspect = function () {
      prospectsApi
        .getProspect($scope.data.prospect.id)
        .then(function (response) {
          var prospect = response.data.prospect;

          $scope.data.prospect = prospect;

          $scope.data.otherUser.info = $scope.data.otherUser.info || {};
          $scope.data.otherUser.info.first_name = prospect.profile.first_name;
          $scope.data.otherUser.info.last_name = prospect.profile.last_name;
          $scope.data.otherUser.info.status = prospect.status;
        });
    };

    $scope.data = {
      isLoaded: false,
      listing: null,
      prospect: null,
      resident: null,
      userInfo: null,
      todoStatus: null,
      events: {
        viewProfileDetails: 'viewProfileDetails'
      },
      teamMembers: null,
      managerCommunityIds: [],
      completedTours: []
    };

    $scope.updateProspectListing = function () {
      prospectHistoryService.updateProspect($scope.data.prospect);
    };

    $scope.openAssignPropertyModal = function () {
      assignPropertyModalService.openModal($scope.data.prospect);
    };

    self._initialize = function () {
      $scope.data.otherUser = conversationsService.getOtherUser(
        $scope.conversation
      );

      var initPromises = [];

      if ($scope.conversation.listing_id) {
        initPromises.push(self._getListing());
      } else {
        $scope.data.listing = null;
      }

      if ($scope.data.otherUser.type === 'prospect') {
        initPromises.push(self._getProspect());

        if (!$scope.data.listingsByManager) {
          initPromises.push(self._getListings());
        }

        if (!$scope.data.communities) {
          initPromises.push(self._getCommunities());
        }
      }

      if ($scope.data.otherUser.type === 'resident') {
        initPromises.push(self._getResident());
      }

      if ($scope.data.otherUser.type !== 'manager') {
        self._setTeamMembers();
      }

      $q.all(initPromises).finally(self._loadFinished);
    };

    self._loadFinished = function () {
      $scope.data.isLoaded = true;

      self._updateCommunity();
    };

    self._getListing = function () {
      var listingId = $scope.conversation.listing_id;

      return userInteractionsService
        .getListing(listingId)
        .success(self._getListingSuccess);
    };

    self._getListingSuccess = function (response) {
      $scope.data.listing = response.listing;
    };

    self._getListings = function () {
      return userInteractionsService
        .getListings()
        .success(self._getListingsSuccess);
    };

    self._getListingsSuccess = function (response) {
      $scope.data.listingsByManager = response.listings;
      $scope.data.listingsByPropertyId = self._reduceListings(response);
    };

    self._reduceListings = function (response) {
      return reduce(
        response.listings,
        function (result, listingsByManager) {
          forEach(listingsByManager.listings, function (listing) {
            result[listing.id] = listing;
          });

          return result;
        },
        {}
      );
    };

    self._getCommunities = function () {
      return userInteractionsService
        .getCommunities()
        .success(self._getCommunitiesSuccess);
    };

    self._getCommunitiesSuccess = function (response) {
      $scope.data.communities = response.communities;

      $scope.data.managerCommunityIds = (response.communities || []).map(
        (item) => {
          return item.property_id;
        }
      );
    };

    self._updateCommunity = function () {
      if (
        $scope.data != null &&
        $scope.data.prospect != null &&
        $scope.data.prospect.property_ &&
        $scope.data.prospect.propertytype === 'community'
      ) {
        $scope.data.community = find($scope.data.communities, {
          id: $scope.data.prospect.propertytype_id
        });
      } else {
        $scope.data.community = null;
      }
    };

    self._getRenterInfo = function () {
      return userInteractionsService
        .getRenterInfo($scope.data.otherUser.id)
        .success(self._getRenterInfoSuccess);
    };

    self._getRenterInfoSuccess = function (response) {
      $scope.data.userInfo = response.renter_info;
    };

    self._getRequireMissingFieldsFormModal = function () {
      const property = appDataService.getProperty(
        $scope.data.prospect.property_id
      );

      const requireProspectMoveDate =
        property.Property.preferences.require_prospect_move_in_date;

      const isFormVisible =
        requireProspectMoveDate &&
        $scope.data.prospect.profile.target_move_date == null;

      if (isFormVisible) {
        return prospectMissingFieldsModalFactory.openModal(
          $scope.data.prospect
        );
      }
    };

    self._getProspect = function () {
      return userInteractionsService
        .getProspect($scope.data.otherUser.id)
        .success(self._getProspectSuccess);
    };

    $scope.completeTourActions = (action, id) => {
      $rootScope.tour_drawer_opened = true;
      switch (action) {
        case 'mark-as-lost':
          $timeout(() => {
            $scope.openCompleteTourDrawer = false;
            self._isCompletingAppointment = false;
            $scope.infoPanelData = {};
            $scope.data.completedTours.push(id);
            $rootScope.$emit(
              'CompleteTour-MarkAsLost',
              $scope.data.prospect.id
            );
            $scope.$apply();
          }, 1000);
          break;
        case 'schedule-tour':
          $timeout(() => {
            $scope.openCompleteTourDrawer = false;
            self._isCompletingAppointment = false;
            $scope.infoPanelData = {};
            $scope.$apply();
            $scope.data.completedTours.push(id);
            prospectAppointmentModalFactory.openAddProspectAppointmentModal(
              $scope.data.prospect.id
            );
          }, 1000);
          break;
        case 'step':
          $scope.data.completedTours.push(id);
          $scope.completeAppoiments();
          break;
        default:
          break;
      }
      $rootScope.tour_drawer_opened = false;
      $scope.openCompleteTourDrawer = false;
      self._isCompletingAppointment = false;
      visitsService.setAppointmentToComplete(null);
      $scope.$apply();
    };

    self._getProspectSuccess = function (response) {
      $scope.data.prospect = response.prospect;
      $scope.data.prospectStatuses = keys(prospectStatusMap);
      $scope.data.userInfo = $scope.data.prospect;

      if ($scope.noModals) {
        return;
      }

      var needsToTransferFromHub =
        !self._isTransferringProspect &&
        $scope.data.teamMembers[$scope.data.prospect.assigned_manager_id]
          .Manager.is_hub_account;

      var modalPromises = [];

      const requireMissingFieldsFormModal =
        self._getRequireMissingFieldsFormModal();

      if (requireMissingFieldsFormModal) {
        modalPromises.push(requireMissingFieldsFormModal);
      }

      if (needsToTransferFromHub) {
        self._isTransferringProspect = true;

        modalPromises.push(
          prospectTransferModalFactory
            .openTransferModal($scope.data.prospect, true)
            .then(
              function () {
                self._isTransferringProspect = false;
              },
              function () {
                self._isTransferringProspect = false;
              }
            )
        );
      }

      $scope.isOpenCompleteTourDrawerEnabled =
        $rootScope.featureFlags.LMA_COMPLETE_TOUR &&
        $rootScope.appPreferences.company.enable_complete_tour_drawer;

      $q.all(modalPromises).finally(function () {
        if (!$rootScope.tour_drawer_opened) {
          self._checkNeedsCompleteAppointment();
        }
        $scope.openCompleteTourDrawer =
          self._isCompletingAppointment &&
          $scope.isOpenCompleteTourDrawerEnabled;
      });
    };

    $rootScope.$on('openCompleteTourDrawer', () => {
      $timeout(() => {
        if ($scope.data.completedTours.length > 0) {
          self._checkNeedsCompleteAppointment();

          $scope.openCompleteTourDrawer =
            self._isCompletingAppointment &&
            $scope.isOpenCompleteTourDrawerEnabled;
        }
      }, 1000);
    });

    $scope.completeAppoiments = function () {
      $timeout(() => {
        if ($scope.openCompleteTourDrawer) {
          self._isCompletingAppointment = false;
          $scope.infoPanelData = {};
          $scope.openCompleteTourDrawer = false;

          $scope.$apply();
          $timeout(() => {
            self._checkNeedsCompleteAppointment();

            $scope.openCompleteTourDrawer =
              self._isCompletingAppointment &&
              $scope.isOpenCompleteTourDrawerEnabled;
            $scope.$apply();
          }, 1000);
        }
      }, 1000);
    };

    self._checkNeedsCompleteAppointment = function () {
      if (!self._isCompletingAppointment) {
        let thresholdDate;
        var appointmentsToComplete = filter(
          $scope.data.prospect.events,
          function (event) {
            const property = appDataService.getProperty(
              $scope.data.prospect.property_id
            );
            const prospectName =
              $scope.data.prospect.profile.first_name +
              ' ' +
              $scope.data.prospect.profile.last_name;

            let managerName = null;

            if ($scope.data.completedTours.includes(event.id)) {
              return false;
            }

            if (event && event.manager_id) {
              const manager = $scope.data.teamMembers[event.manager_id];
              if (manager) {
                managerName =
                  manager.ManagerInfo.first_name +
                  ' ' +
                  manager.ManagerInfo.last_name;
              } else {
                managerName = 'N/A';
              }
            }

            if (
              event.event_type !== 'appointment' ||
              !$moment(event.start_time).isBefore($moment()) ||
              event.status !== 'confirmed'
            ) {
              return false;
            }

            if (!thresholdDate || thresholdDate > event.start_time) {
              thresholdDate = event.start_time;
              $scope.infoPanelData = {
                id: event.id,
                prospectId: $scope.data.prospect.id,
                prospectName: prospectName,
                tourType: event.tour_type,
                tourDateAndTime: event.start_time,
                tourProperty: property.Property.data.location.name,
                tourPropertyId: property.Property.id,
                tourOwner: managerName,
                shownUnitsRequired:
                  property.Property.preferences.require_prospect_floorplan
              };
            }

            return true;
          }
        );

        if (appointmentsToComplete.length === 0) {
          $rootScope.tour_drawer_opened = false;
          return;
        }

        self._isCompletingAppointment = true;

        if (!$scope.isOpenCompleteTourDrawerEnabled) {
          visitsService
            .completeAppointment(
              $scope.data.prospect,
              appointmentsToComplete[0]
            )
            .then(
              function (outcome) {
                if (outcome.status === 'visited') {
                  var index = findIndex(
                    $scope.data.prospect.events,
                    function (event) {
                      return (
                        event.event_type === 'appointment' &&
                        event.id === outcome.visit.appointment_id
                      );
                    }
                  );

                  $scope.data.prospect.events[index] = outcome.visit;
                  self._openShownUnitsModal(outcome.visit);
                }

                if (outcome.status === 'no-show') {
                  appointmentsToComplete[0].status = 'no-show';
                }

                self._isCompletingAppointment = false;
              },
              function () {
                self._isCompletingAppointment = false;
              }
            );
        }
      }
    };

    self._openShownUnitsModal = function (event) {
      shownUnitsModalService
        .openShownUnitsModal($scope.data.prospect, event)
        .then(function (shownUnits) {
          visitsService.onShownUnitsAdded(event, shownUnits);
        });
    };

    self._getResident = function () {
      return userInteractionsService
        .getResident($scope.data.otherUser.id)
        .success(self._getResidentSuccess);
    };

    self._getResidentSuccess = function (response) {
      $scope.data.resident = response.resident;
      $scope.data.userInfo = $scope.data.resident;
      $scope.data.todoStatus = response.todo_status;
    };

    self._setTeamMembers = function () {
      $scope.data.teamMembers = reduce(
        appDataService.getTeamMembers(),
        function (result, member) {
          result[member.Manager.id] = member;

          return result;
        },
        {}
      );
    };

    self._initialize();
  };

  UserInteractionsPanelController.$inject = [
    '$rootScope',
    '$scope',
    '$moment',
    '$mdToast',
    '$routeParams',
    '$location',
    '$q',
    '$timeout',
    '$mdDialog',
    '$interval',
    'appDataService',
    'conversationsService',
    'voiceService',
    'userInteractionsService',
    'prospectHistoryService',
    'prospectStatusMap',
    'assignPropertyModalService',
    'prospectsApi',
    'voiceApi',
    'unitApi',
    'visitsService',
    'prospectTransferModalFactory',
    'shownUnitsModalService',
    'prospectMissingFieldsModalFactory',
    'userService',
    'prospectAppointmentModalFactory'
  ];

  app.controller(
    'UserInteractionsPanelController',
    UserInteractionsPanelController
  );
})(window.angular);
