import React, { FC, ReactElement } from 'react';
import { IconButton } from '@material-ui/core';

import TooltipContainer from '../../molecules/TooltipContainer/TooltipContainer';
import { makeStyles, NamedColors } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles(() => ({
  iconButton: {
    '&.MuiIconButton-root:hover': {
      backgroundColor: NamedColors.denim[50]
    }
  }
}));

interface ActionButtonProps {
  onClick: () => void;
  tooltipText: string;
  children: ReactElement;
}

const ActionButton: FC<ActionButtonProps> = ({
  onClick,
  tooltipText,
  children
}) => {
  const classes = useStyles();

  return (
    <TooltipContainer tooltip={tooltipText}>
      <IconButton
        onClick={() => onClick()}
        className={classes.iconButton}
        data-testid="icon-button"
      >
        {children}
      </IconButton>
    </TooltipContainer>
  );
};

export default ActionButton;
