/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('inactiveListingRow', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/listingIndex/inactive-listing-row.html',
      controller: 'InactiveListingRowController',
      scope: {
        listing: '=',
        disablePosting: '=',
        onDelete: '='
      }
    };
  });

  app.controller('InactiveListingRowController', [
    '$scope',
    '$mdDialog',
    '$location',
    'confirmationModalFactory',
    'listingApi',
    'accessControl',
    'upgradeModalService',
    function (
      $scope,
      $mdDialog,
      $location,
      confirmationModalFactory,
      listingApi,
      accessControl,
      upgradeModalService
    ) {
      var self = this;

      try {
        $scope.bannerImage = $scope.listing.coverPhoto
          ? $scope.listing.coverPhoto.thumbUrl
          : $scope.listing.photos[0].thumbUrl;
      } catch (e) {
        $scope.bannerImage = '/images/no-image.png';
      }

      $scope.editListing = function (view, reactivate) {
        var url = '/listing/' + $scope.listing.id + '/edit';

        if (reactivate) {
          url += '?reactivate=true';
        }
        if (view) {
          url += '#' + view;
        }

        $location.url(url);
      };

      $scope.activateListing = function () {
        accessControl
          .canAddNewListing()
          .then(
            self._openReactivateView,
            self._openUpgradeUserSubscriptionModal
          );
      };

      self._openReactivateView = function () {
        $scope.editListing('leasing', true);
      };

      self._openUpgradeUserSubscriptionModal = function () {
        upgradeModalService.openUpgradeToProModal();
      };

      $scope.tryDeleteListing = function () {
        var listingName = $scope.listing.location.name || 'this listing';

        var confirm = $mdDialog
          .confirm()
          .title('Delete listing')
          .content('Are you sure you wish to delete ' + listingName + '?')
          .ok('Yes')
          .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
          self._deleteListing();
        });
      };

      self._deleteListing = function () {
        $scope.errorMessage = null;

        listingApi
          .deleteListing($scope.listing.id)
          .success(self._deleteListingSuccess)
          .error(self._deleteListingError);
      };

      self._deleteListingSuccess = function () {
        $scope.onDelete($scope.listing);
      };

      self._deleteListingError = function () {
        $scope.errorMessage =
          'There was an error deleting your listing. Please try again later';
      };
    }
  ]);
})();
