/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('editListingLeasing', function () {
    return {
      restrict: 'E',
      scope: {
        listing: '=',
        application: '=',
        continueHandler: '='
      },
      templateUrl: '/angular/views/listing/directives/edit-leasing.html',
      controller: 'EditListingLeasingController',
      controllerAs: 'editLeasingCtrl'
    };
  });

  app.directive('leaseLengthsEditor', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/listing/editor/lease-lengths-editor.html',
      scope: {
        onClick: '=?',
        leaseLengths: '=',
        upchargesEnabled: '='
      }
    };
  });

  app.controller('EditListingLeasingController', [
    '$scope',
    'listingApi',
    'editListingCompletionHandler',
    function ($scope, listingApi, editListingCompletionHandler) {
      var self = this;

      self.completionHandler = editListingCompletionHandler.leasing;

      $scope.dateOpened = false;
      $scope.dateFormat = 'MM/dd/yyyy';

      $scope.leaseMonthsInvalid = _hasNoTruthyValue(
        $scope.listing.leasing.terms.leaseLengths
      );
      $scope.leaseMonthsDirty = false;

      $scope.setLeaseMonthsDirty = function () {
        $scope.leaseMonthsDirty = true;
      };

      $scope.$watch('leasingForm.$invalid', function () {
        self.completionHandler.isComplete = !$scope.leasingForm.$invalid;
      });

      $scope.$watch(
        'listing.leasing.terms.leaseLengths',
        function (leaseLengths) {
          if (!$scope.leaseMonthsDirty) {
            return;
          }

          $scope.leaseMonthsInvalid = _hasNoTruthyValue(leaseLengths);
        },
        true
      );

      function _hasNoTruthyValue(toCheck) {
        return isEmpty(filter(toCheck, 'isAvailable'));
      }
    }
  ]);
})();
