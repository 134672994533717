/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import firebase from 'firebase/app';
import 'firebase/messaging';

(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const browserNotificationsService = function (
    $timeout,
    $q,
    appDataService,
    notificationsApi,
    localStorageService,
    apiBase
  ) {
    var self = this;

    try {
      self._messaging = firebase.messaging();
    } catch (e) {
      console.warn('This browser does not support the Push API', e);
    }

    self._justPlayedSound = false;
    self._tokenKey = 'notifications_token';

    if (self._messaging != null) {
      self._messaging.onTokenRefresh(function () {
        self._messaging
          .getToken()
          .then(function (refreshedToken) {
            self._sendTokenToServer(refreshedToken);
          })
          .catch(function (err) {
            console.error('Unable to retrieve refreshed token', err);
          });
      });
    }

    self.registerServiceWorker = function () {
      return navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then(function (registration) {
          console.log('Service worker successfully registered.');
          return registration;
        })
        .catch(function (err) {
          console.error('Unable to register service worker.', err);
        });
    };

    self.setUpSWMessageListener = function (registration) {
      navigator.serviceWorker.addEventListener('message', function (event) {
        var payload = event.data['firebase-messaging-msg-data'];
        console.log(
          '[firebase-messaging-sw.js] Received foreground message ',
          payload
        );
        Notification.requestPermission(function (permission) {
          if (permission === 'granted') {
            var body = payload.data.body;
            var title = payload.data.title;
            var tag = payload.data.tag;
            var conversationUrl = '/conversation/' + tag;

            var options = {
              body: body,
              tag: tag,
              requireInteraction: true,
              icon: '/images/icon.png'
            };

            registration.showNotification(title, options);
          }
        });
      });
    };

    if (navigator.serviceWorker) {
      self.registerServiceWorker().then(function (registration) {
        self.setUpSWMessageListener(registration);
      });
    }

    self.toggleNotifications = function (active) {
      return self._messaging
        .requestPermission()
        .then(function () {
          return self._messaging.getToken();
        })
        .then(function (currentToken) {
          if (currentToken) {
            self._storeToken(currentToken);

            if (!active) {
              console.log('SET: ', currentToken);
              self._sendTokenToServer(currentToken);
            } else {
              console.log('DEL: ', currentToken);
              self.deleteNotificationsToken(currentToken);
            }
          } else {
            console.log('Token not available');
          }
        });
    };

    self._sendTokenToServer = function (currentToken) {
      self._storeToken(currentToken);

      self._registerDevice().then(
        function () {},
        function (error) {
          self._removeStoredToken();
          console.log(
            'Error registering device token: ' +
              currentToken +
              ' | ' +
              JSON.stringify(error)
          );
        }
      );
    };

    self.deleteNotificationsToken = function (currentToken) {
      var deferred = $q.defer();

      var token = currentToken || self._getStoredToken();

      if (!token) {
        deferred.resolve();
        return deferred.promise;
      }

      return self._messaging.deleteToken(token).then(function () {
        self._removeStoredToken();
      });
    };

    self.toggleAudioNotifications = function () {
      appDataService.data.preferences.audioNotificationsEnabled =
        !appDataService.data.preferences.audioNotificationsEnabled;

      return notificationsApi.updatePreferences(null, null, {
        'manager-audio-notifications-enabled':
          appDataService.data.preferences.audioNotificationsEnabled
      });
    };

    self._registerDevice = function () {
      var token = self._getStoredToken();

      if (!token) {
        throw new Error('no firebase token stored!');
      }

      return apiBase.post('/user/notifications/device', {
        device_registration_id: token
      });
    };

    self.getTokenSentToServer = function () {
      return localStorageService.get('notifications_token');
    };

    self._getStoredToken = function () {
      return localStorageService.get(self._tokenKey);
    };

    self._storeToken = function (token) {
      localStorageService.set(self._tokenKey, token);
    };

    self._removeStoredToken = function () {
      localStorageService.remove(self._tokenKey);
    };

    self.playSound = function (notificationType) {
      if (
        self._justPlayedSound ||
        !appDataService.data.preferences.audioNotificationsEnabled
      ) {
        return;
      }

      var audioFile =
        notificationType === 'message'
          ? '/media/notification.mp3'
          : '/media/phone_call.mp3';
      var audioBlock = document.getElementById('notificationAudio');

      self._justPlayedSound = true;

      audioBlock.innerHTML =
        '' +
        '<audio autoplay="autoplay">' +
        '<source src="' +
        audioFile +
        '" type="audio/mpeg" />' +
        '<embed hidden="true" autostart="true" loop="false" src="' +
        audioFile +
        '" />' +
        '</audio>';

      $timeout(function () {
        self._justPlayedSound = false;
      }, 8000);
    };
    return self;
  };

  browserNotificationsService.$inject = [
    '$timeout',
    '$q',
    'appDataService',
    'notificationsApi',
    'localStorageService',
    'apiBase'
  ];
  app.factory('browserNotificationsService', browserNotificationsService);
})(window.angular);
