import React from 'react';

export const PoolIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.25 22.445C20.799 22.955 19.278 20.509 19.278 20.509C18.8993 21.1104 18.3778 21.6088 17.7597 21.9597C17.1417 22.3105 16.4465 22.503 15.736 22.52C15.0649 22.5066 14.4097 22.3128 13.8393 21.959C13.2689 21.6051 12.8042 21.1043 12.494 20.509V20.509C12.0372 21.1029 11.4567 21.5903 10.7928 21.9376C10.1289 22.2848 9.39736 22.4836 8.649 22.52C7.94305 22.4856 7.25508 22.2857 6.64066 21.9363C6.02624 21.587 5.50259 21.0981 5.112 20.509V20.509C5.112 20.509 3.148 23 0.75 22.485"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.25 17.945C20.799 18.455 19.278 16.009 19.278 16.009C18.8993 16.6104 18.3778 17.1088 17.7597 17.4597C17.1417 17.8105 16.4465 18.003 15.736 18.02C15.0649 18.0066 14.4097 17.8128 13.8393 17.459C13.2689 17.1051 12.8042 16.6043 12.494 16.009C12.0372 16.6029 11.4567 17.0903 10.7928 17.4376C10.1289 17.7848 9.39736 17.9836 8.649 18.02C7.94305 17.9856 7.25508 17.7857 6.64066 17.4363C6.02624 17.087 5.50259 16.5981 5.112 16.009V16.009C5.112 16.009 3.148 18.5 0.75 17.985"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 11.195C20.9497 11.195 22.125 10.0197 22.125 8.57C22.125 7.12025 20.9497 5.945 19.5 5.945C18.0503 5.945 16.875 7.12025 16.875 8.57C16.875 10.0197 18.0503 11.195 19.5 11.195Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.37903 14L11.21 11.6L8.40003 8.394C8.09807 8.04896 7.87922 7.63929 7.76029 7.19647C7.64135 6.75365 7.62551 6.28946 7.71396 5.83957C7.80241 5.38967 7.99282 4.96603 8.27054 4.6012C8.54826 4.23636 8.90592 3.94004 9.31603 3.735L13.579 1.6C13.7554 1.51006 13.9478 1.45592 14.1452 1.44071C14.3426 1.42549 14.541 1.44949 14.7291 1.51134C14.9171 1.57318 15.0911 1.67163 15.2409 1.80103C15.3907 1.93042 15.5135 2.08819 15.602 2.26525C15.6906 2.4423 15.7432 2.63513 15.7569 2.83263C15.7706 3.03012 15.745 3.22837 15.6817 3.41594C15.6184 3.60351 15.5186 3.7767 15.3881 3.92551C15.2575 4.07432 15.0988 4.19582 14.921 4.283L11.521 5.983C11.4191 6.03512 11.3303 6.10987 11.2617 6.20153C11.193 6.2932 11.1463 6.39935 11.1249 6.51187C11.1036 6.62439 11.1083 6.7403 11.1386 6.85072C11.169 6.96115 11.2242 7.06318 11.3 7.149L16.677 13.294"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
