/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('messagingApi', [
    'apiBase',
    function (apiBase) {
      return {
        sendMessage: function (
          recipientType,
          recipientId,
          listingId,
          messageText,
          messageHtml,
          targets
        ) {
          var message = {
            recipient: {
              type: recipientType,
              id: recipientId
            },
            listing_id: listingId,
            message_text: messageText,
            message_html: messageHtml,
            targets: targets
          };

          return apiBase.post('/messaging/send', { message: message });
        },
        getConversations: function () {
          return apiBase.get('/messaging/conversations');
        },
        getTeamMemberConversations: function (managerId) {
          return apiBase.get('/messaging/' + managerId + '/conversations');
        },
        getEmailMergeTags: function (
          managerId = undefined,
          streamId = undefined
        ) {
          const url =
            managerId && streamId
              ? `/chats/${streamId}/merge-tags/values?managerId=${managerId}`
              : `/chats-batch/merge-tags`;
          return apiBase.get(url);
        },
        getEmailBlocklist: function () {
          return apiBase.get('/email/blocklist');
        }
      };
    }
  ]);
})();
