import React, { FC } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { Button, ThemeProvider } from '@knockrentals/knock-shared-web';
import { GuestcardIcon } from './icons';
import { Tooltip } from './Tooltip';

import './_ViewConnectedProfiles.scss';

interface ViewConnectedProfilesProps {
  connectedProfilesCount: number;
  isCrossSellEnabled: boolean;
  viewProfiles: (isActive: boolean) => void;
}

export const LEARN_MORE_LINK = {
  SUPPORT: 'https://kb.realpage.com/articles/Solution/360-Guestcard',
  PRODUCT: 'https://www.knockcrm.com/product/centralized-leasing'
};

export const TOOL_TIP_TEXT = {
  PROFILES: 'View connected profiles',
  NO_PROFILES: 'No connected profiles'
};

const useStyles = makeStyles((theme) => ({
  viewConnectedProfilesButton: {
    color: theme.palette.primary.dark,
    filter: 'none',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    padding: 0,
    textTransform: 'none',
    '&:disabled': {
      color: theme.palette.text.hint
    },
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  viewConnectedProfilesLearnMoreLink: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.secondary,
      textDecoration: `none`
    },
    '&:focus': {
      color: theme.palette.text.secondary,
      textDecoration: `none`
    },
    '&:active': {
      color: theme.palette.text.secondary,
      textDecoration: `none`
    },
    '&:visited': {
      color: theme.palette.text.secondary,
      textDecoration: `none`
    }
  }
}));

const getLearnMoreLink = (isCrossSellEnabled: boolean) =>
  isCrossSellEnabled ? LEARN_MORE_LINK.SUPPORT : LEARN_MORE_LINK.PRODUCT;

const getConfig = (count: number, isEnabled: boolean) => ({
  isDisabled: count === 0,
  learnMoreLink: getLearnMoreLink(isEnabled),
  toolTipText: count > 0 ? TOOL_TIP_TEXT.PROFILES : TOOL_TIP_TEXT.NO_PROFILES
});

export const ViewConnectedProfiles: FC<ViewConnectedProfilesProps> = ({
  connectedProfilesCount,
  isCrossSellEnabled,
  viewProfiles
}) => {
  const classes = useStyles();
  const { learnMoreLink, isDisabled, toolTipText } = getConfig(
    connectedProfilesCount,
    isCrossSellEnabled
  );

  return (
    <div className="view-connected-profiles-container">
      <div>
        <GuestcardIcon height={'16'} width={'16'} />
        <Tooltip title={toolTipText}>
          <span>
            <Button
              className={classes.viewConnectedProfilesButton}
              disabled={isDisabled}
              disableRipple
              name="connectedProfilesButton"
              onClick={() => viewProfiles(true)}
              variant="text"
            >
              {`360 Profile (${connectedProfilesCount})`}
            </Button>
          </span>
        </Tooltip>
      </div>
      <Link
        className={classes.viewConnectedProfilesLearnMoreLink}
        href={learnMoreLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        Learn More
      </Link>
    </div>
  );
};

export default (props: ViewConnectedProfilesProps) => (
  <ThemeProvider>
    <ViewConnectedProfiles {...props} />
  </ThemeProvider>
);
