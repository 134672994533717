export default function runInitializeAppDataService(app: any) {
  app.run([
    '$rootScope',
    'userService',
    'companyApi',
    'appDataService',
    function (
      $rootScope: any,
      userService: any,
      companyApi: any,
      appDataService: any
    ) {
      appDataService.initialize();
    }
  ]);
}
