import React, { FC, useState, useRef, useMemo } from 'react';
import {
  Avatar,
  Popover,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  makeStyles,
  IconButton
} from '@material-ui/core';
import { ProfileMenuProps } from '../types';

const useStyles = makeStyles({
  boxStyle: {
    width: '300px'
  },
  iconButtonStyleNew: {
    width: '32px',
    height: '32px',
    marginLeft: '18px'
  },
  avatarColorDefault: {
    fontSize: '20px',
    height: '48px',
    width: '48px',
    color: '#697FF7',
    backgroundColor: '#EBEEFE'
  },
  betaVersionText: {
    color: '#999999',
    fontSize: '12px',
    marginLeft: '16px',
    paddingTop: '16px',
    paddingBottom: '8px'
  }
});

export const ProfileMenu: FC<ProfileMenuProps> = ({
  userProfile,
  logout,
  hideAccountViews,
  openProfileAccount
}) => {
  const [open, setOpen] = useState(false);

  const profileRef = useRef<HTMLButtonElement>(null);

  const nameInitials: string = useMemo(
    () =>
      `${userProfile.first_name} ${userProfile.last_name}`
        .split(' ')
        .map((str) => (str ? str[0].toUpperCase() : ''))
        .join(''),
    [userProfile.first_name, userProfile.last_name]
  );

  const { boxStyle, avatarColorDefault, iconButtonStyleNew } = useStyles();

  return (
    <>
      <IconButton
        className={iconButtonStyleNew}
        aria-describedby="popover-profile"
        ref={profileRef}
        onClick={() => setOpen(!open)}
        component="span"
        data-testid="profile-icon-buton"
      >
        <Avatar
          classes={{ colorDefault: avatarColorDefault }}
          role="profile-icon-avatar"
        >
          {nameInitials}
        </Avatar>
      </IconButton>
      <Popover
        open={open}
        anchorEl={profileRef.current}
        onClose={() => setOpen(!open)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        role="profile-menu-popover"
      >
        <Box className={boxStyle}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar classes={{ colorDefault: avatarColorDefault }}>
                  {nameInitials}
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={`${userProfile.first_name} ${userProfile.last_name}`}
              />
            </ListItem>

            {!hideAccountViews && (
              <ListItem role="button" onClick={() => openProfileAccount()}>
                <ListItemText primary="Account" color="inherit" />
              </ListItem>
            )}
            <ListItem component="a" onClick={logout} role="button">
              <ListItemText primary="Sign Out" color="inherit" />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </>
  );
};
