/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import includes from 'lodash/includes';
import find from 'lodash/find';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('stateSelector', function () {
    return {
      restrict: 'E',
      template:
        '<md-select ng-model="address.administrative_area" ng-change="updateCountry()">' +
        '<md-optgroup ng-repeat="country in countries" label="{{country.name}}">' +
        '<md-option ng-repeat="state in country.states">{{state}}</md-option>' +
        '</md-optgroup>' +
        '</md-select>',
      scope: {
        address: '='
      },
      replace: true,
      controller: [
        '$scope',
        'CountryStatesListService',
        function ($scope, CountryStatesListService) {
          $scope.updateCountry = function () {
            var stateCountry = find($scope.countries, function (country) {
              return includes(
                country.states,
                $scope.address.administrative_area
              );
            });

            $scope.address.country = stateCountry.name;
          };

          $scope.countries = [
            {
              name: 'United States',
              states: CountryStatesListService.getUnitedStateStatesArray()
            },
            {
              name: 'Canada',
              states: CountryStatesListService.getCanadaStateArray()
            }
          ];
        }
      ]
    };
  });
})(window.angular);
