/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Account');

  app.directive('accountPreferences', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/account/account-preferences.html',
      controller: 'AccountPreferencesController'
    };
  });

  const AccountPreferencesController = function (
    $scope,
    $q,
    $mdToast,
    appDataService,
    accountViewService,
    timeService,
    userService
  ) {
    var self = this;
    var currentUser = userService.getScopedUser();

    $scope.data = {
      selectedManager: null,
      teamMembers: [],
      managerData: {
        officeHours: null
      }
    };

    $scope.onManagerChanged = function () {
      self._getSelectedManagerData();
    };

    $scope.save = function () {
      $scope.isSaving = true;

      var savePromises = [
        self._saveManagerOfficeHours(),
        self._saveManagerSchedulingPreferences(),
        self._saveManagerRequirements(),
        self._saveNotificationPreferences(),
        self._saveManagerTodoPreferences()
      ];

      $q.all(savePromises)
        .then(
          function success() {
            $mdToast.showSimple('Account updated!');
          },
          function error() {
            $mdToast.showSimple('There was an error updating your account.');
          }
        )
        .finally(function () {
          $scope.isSaving = false;
        });
    };

    self._initialize = function () {
      self._setTeamMembers();
      self._initFinished();
    };

    self._initFinished = function () {
      $scope.onManagerChanged();
    };

    self._setTeamMembers = function () {
      $scope.data.teamMembers = appDataService.getTeamMembers();
      $scope.data.selectedManager = find(
        $scope.data.teamMembers,
        function (member) {
          return member.Manager.id === currentUser.id;
        }
      );
    };

    self._getSelectedManagerData = function () {
      $scope.isLoadingSelected = true;

      var preferencesPromises = [
        self._getManagerOfficeHours(),
        self._getManagerSchedulingPreferences(),
        self._getManagerRequirements(),
        self._getManagerNotificationPreferences(),
        self._getManagerTodoPreferences()
      ];

      $q.all(preferencesPromises).finally(function () {
        $scope.isLoadingSelected = false;
      });
    };

    self._getManagerOfficeHours = function () {
      return accountViewService
        .getOfficeHours($scope.data.selectedManager.Manager.id)
        .success(function (response) {
          $scope.data.managerData.officeHours = response.office_hours;
        });
    };

    self._getManagerSchedulingPreferences = function () {
      return accountViewService
        .getSchedulingPreferences($scope.data.selectedManager.Manager.id)
        .success(function (response) {
          $scope.data.managerData.schedulingPreferences =
            response.manager_preferences;
        });
    };

    self._getManagerRequirements = function () {
      return accountViewService
        .getPrescreeningRequirements($scope.data.selectedManager.Manager.id)
        .success(function (response) {
          $scope.data.managerData.managerRequirements =
            response.manager_requirements;
        });
    };

    self._getManagerNotificationPreferences = function () {
      return accountViewService
        .getNotificationPreferences($scope.data.selectedManager.Manager.id)
        .success(function (response) {
          $scope.data.managerData.notificationPreferences =
            response.preferences;
        });
    };

    self._getManagerTodoPreferences = function () {
      return accountViewService
        .getTodoPreferences($scope.data.selectedManager.Manager.id)
        .success(function (response) {
          $scope.data.managerData.todoPreferences =
            response.manager_todo_preferences;
        });
    };

    self._saveManagerOfficeHours = function () {
      return $q.all([
        accountViewService.updateOfficeHours(
          $scope.data.selectedManager.Manager.id,
          $scope.data.managerData.officeHours
        ),
        timeService.setTimezone(timeService._activeTimezone)
      ]);
    };

    self._saveManagerSchedulingPreferences = function () {
      return accountViewService.updateSchedulingPreferences(
        $scope.data.selectedManager.Manager.id,
        $scope.data.managerData.schedulingPreferences
      );
    };

    self._saveManagerRequirements = function () {
      return accountViewService.updatePrescreeningRequirements(
        $scope.data.selectedManager.Manager.id,
        $scope.data.managerData.managerRequirements
      );
    };

    self._saveNotificationPreferences = function () {
      var events = $scope.data.managerData.notificationPreferences.events;
      var unreadMessagePeriod =
        $scope.data.managerData.notificationPreferences.unread_message_period;
      var clientPreferences =
        $scope.data.managerData.notificationPreferences.clientPreferences;

      return accountViewService.updateNotificationPreferences(
        $scope.data.selectedManager.Manager.id,
        events,
        unreadMessagePeriod,
        clientPreferences
      );
    };

    self._saveManagerTodoPreferences = function () {
      return accountViewService.updateTodoPreferences(
        $scope.data.selectedManager.Manager.id,
        $scope.data.managerData.todoPreferences
      );
    };

    self._initialize();
  };

  AccountPreferencesController.$inject = [
    '$scope',
    '$q',
    '$mdToast',
    'appDataService',
    'accountViewService',
    'timeService',
    'userService'
  ];

  app.controller('AccountPreferencesController', AccountPreferencesController);
})(window.angular);
