/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  let app = angular.module('knockApp');

  app.factory('apiLoggingInterceptor', [
    '$log',
    'api',
    '$injector',
    '$q',
    'localStorageService',
    'cacheKeys',
    '$location',
    function (
      $log,
      api,
      $injector,
      $q,
      localStorageService,
      cacheKeys,
      $location
    ) {
      let refreshingToken = false;
      let afterAuthPromise = null;

      function authPromise() {
        // we use $injector here to avoid circular dependency
        let $auth = $injector.get('$auth');
        let userService = $injector.get('userService');
        let authenticationService = $injector.get('authenticationService');

        return $q.when().then(function () {
          let authToken = $auth.getToken();
          if (authToken) {
            // If the access token is expired, we should try to
            // get a new one with the refresh token.
            if (hasTokenExpired(authToken)) {
              if (refreshingToken) {
                // if refreshing auth is currently running, return promise
                return afterAuthPromise;
              } else {
                refreshingToken = true;
                const refreshToken = localStorageService.get(
                  cacheKeys.refreshToken
                );
                // using finally here to ensure that refreshingToken is set back to false after function
                // completes, regardless of success of error to not block subsequent auth request
                afterAuthPromise = userService
                  .refreshAuthToken(refreshToken)
                  .finally(function (resp) {
                    refreshingToken = false;
                  });

                return afterAuthPromise;
              }
            }
          }
        });
      }

      return {
        request: function (config) {
          if (api.loggingEnabled && startsWith(config.url, api.host)) {
            $log.info('Request sent to "' + config.url + '":');
            $log.info(config);
          }
          // prevents running auth method on token refresh request
          if (config.url.indexOf('/auth/refresh') > -1) {
            return config;
          }

          return authPromise().then(function () {
            return config;
          });
        },
        response: function (response) {
          const config = response.config;

          if (api.loggingEnabled && startsWith(config.url, api.host)) {
            $log.info('Response received from "' + config.url + '":');
            $log.info(response);
          }

          return response;
        },
        responseError: function (err) {
          // @FIXME
          // This is breaking login fail redirect flow, comment out until we find a proper fix
          //if (api.loggingEnabled){
          //$log.warn('Error in response from "'+ response.config.url+'":');
          //$log.warn(response);
          //}

          // We need to support the immediate revoking of refresh tokens by handling 401
          // (unauthorized) errors that can occur on any API call. Consider the case where a user is
          // logged in, closes the browser, and then revisits the UI after a refresh token is
          // revoked. We don't want the user to have to wait the entire refresh interval, while all
          // API calls are failing, before they are redirected to the login screen.

          // Sometimes APIv2 endpoints return 401 error when tokens are fine
          // but users are not allowed to perform some actions or view some data.
          // APIv2 raises UnauthorizedActionException exception and sends response with 401 status code
          // In those cases we do not need to log user out but rather handle an error, show a message and let user
          // continue working with other parts of the app.

          if (
            err.status === 401 &&
            err.data['message'] !== 'UNAUTHORIZED_ACTION'
          ) {
            // Log out user so that invalid tokens don't cause UI issues to persist
            let $rootScope = $injector.get('$rootScope');
            let $auth = $injector.has('$auth') ? $injector.get('$auth') : null;
            let localCache = $injector.has('$localCache')
              ? $injector.get('localCache')
              : null;
            let apiEvents = $injector.has('apiEvents')
              ? $injector.get('apiEvents')
              : null;
            let chatTrackingService = $injector.has('$chatTrackingService')
              ? $injector.get('chatTrackingService')
              : null;
            let notificationsService = $injector.has('notificationsService')
              ? $injector.get('notificationsService')
              : null;
            let conversationsService = $injector.has('conversationsService')
              ? $injector.get('conversationsService')
              : null;
            let browserNotificationsService = $injector.has(
              'browserNotificationsService'
            )
              ? $injector.get('browserNotificationsService')
              : null;
            let titleService = $injector.has('titleService')
              ? $injector.get('titleService')
              : null;

            if ($auth.isAuthenticated()) {
              let logoutEventId = Math.random();
              if (localCache) {
                localCache.put('do-logout', logoutEventId);
              }
              try {
                if (notificationsService) {
                  let clientLogoutEvent =
                    notificationsService.clientEvents.userLoggedOut;
                  notificationsService.emitClientEvent(
                    clientLogoutEvent,
                    logoutEventId
                  );
                  notificationsService.unsubscribe();
                }
                conversationsService.unsubscribeSocketClient();
                authenticationService.unsubscribeForceLogout();
              } catch (e) {
                console.warn(
                  'Error occurred while un-subscribing from notificationService and conversationService.',
                  e
                );
              }

              $auth.logout().then(function () {
                $rootScope.$emit(apiEvents.loggedOut);
                if (chatTrackingService) {
                  chatTrackingService.shutdown();
                }
                if (browserNotificationsService) {
                  browserNotificationsService.deleteNotificationsToken();
                }
                if (titleService) {
                  titleService.clearBadgeCount();
                }
                if (localCache) {
                  localCache.clear();
                }
                $location.path('/login');
                window.location.reload();
              });
            }
          }
          return $q.reject(err);
        }
      };
    }
  ]);

  function startsWith(string, compare) {
    return string.indexOf(compare) === 0;
  }
  /*
   * Checks jwt token expiration.
   * Most of the logic here is ripped from Satellizer save the expiration
   * parsing; the identity service returns an ISO 8601 timestamp when
   * Satellizer expects a unix timestamp.
   */
  function hasTokenExpired(token) {
    if (token.split('.').length === 3) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');

      // This is the only difference; new Date(...).getTime();
      const exp = new Date(JSON.parse(atob(base64)).exp).getTime() / 1000;

      if (exp) {
        return Math.round(new Date().getTime() / 1000) >= exp;
      } else {
        console.error('No expiration in token');
      }
    } else {
      console.error('Bad token format');
    }
    // If the token is invalid, we should say it has expired.
    // That way, we can get a new token.
    return true;
  }
})();
