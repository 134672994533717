import React, { FC } from 'react';

export const KnockCloseIcon: FC = () => {
  return (
    <g id="knock-close-icon">
      <path
        id="Vector"
        d="M8.75 15.25L15.25 8.75M8.75 8.75L15.25 15.25"
        stroke="#EE223D"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  );
};
