/* eslint-disable */

(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const LeasingBrochureController = function ($rootScope, $scope, $location) {
    if (process.env.NODE_ENV !== 'development') {
      $location.url('/');
    }
  };

  LeasingBrochureController.$inject = ['$rootScope', '$scope', '$location'];

  app.controller('LeasingBrochureController', LeasingBrochureController);
})(window.angular);
