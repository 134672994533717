/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.directive('appointmentCard', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/userInteractions/appointment-card.html',
      controller: 'AppointmentCardController',
      scope: {
        appointment: '=',
        prospect: '=',
        manager: '=?',
        property: '=?'
      }
    };
  });

  app.controller('AppointmentCardController', [
    '$rootScope',
    '$scope',
    '$q',
    '$moment',
    '$mdToast',
    '$mdDialog',
    'userInteractionsService',
    'prospectHistoryService',
    'managerCalendarEventsService',
    'propertyApi',
    'appointmentsApi',
    function (
      $rootScope,
      $scope,
      $q,
      $moment,
      $mdToast,
      $mdDialog,
      userInteractionsService,
      prospectHistoryService,
      managerCalendarEventsService,
      propertyApi,
      appointmentsApi
    ) {
      var self = this;

      $scope.isLoading = false;
      $scope.loadError = false;

      var changedManagerHandler = $rootScope.$on(
        managerCalendarEventsService.eventTypes.confirmedAppointment
          .changedManager,
        function (event, appointment, newManager) {
          if (appointment.id === $scope.appointment.id) {
            $scope.manager = newManager;
          }
        }
      );

      var canceledHandler = $rootScope.$on(
        managerCalendarEventsService.eventTypes.confirmedAppointment
          .wasCanceled,
        function (event, appointment) {
          if (appointment.id === $scope.appointment.id) {
            $scope.appointment.status = 'canceled';
          }
        }
      );

      $scope.$on('$destroy', function () {
        changedManagerHandler();
        canceledHandler();
      });

      $scope.matchingPropertyUnits = function (searchText) {
        if (isEmpty(searchText)) {
          return $scope.propertyUnitListings;
        }

        return filter($scope.propertyUnitListings, function (unitListing) {
          return (
            unitListing.property_unit.unit &&
            new RegExp(searchText).test(unitListing.property_unit.unit)
          );
        });
      };

      $scope.openShownUnitsModal = function () {
        $mdDialog.show({
          controller: 'ShownUnitsModalController',
          templateUrl: '/angular/views/userInteractions/shown-units-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          locals: {
            prospect: $scope.prospect,
            appointment: $scope.appointment
          }
        });
      };

      $scope.tryChangeAppointmentTime = function () {
        prospectHistoryService
          .openChangeAppointmentTimeModal(
            $scope.appointment,
            $scope.property.preferences.preferences
          )
          .then(function (newTimes) {
            $scope.appointment.start_time = $moment(newTimes.start).format();
            $scope.appointment.end_time = $moment(newTimes.end).format();

            $mdToast.showSimple('Appointment updated!');
          });
      };

      $scope.tryTransferAppointment = function () {
        userInteractionsService.openTransferAppointmentModal(
          $scope.appointment
        );
      };

      $scope.tryCancelAppointment = function () {
        userInteractionsService.openCancelAppointmentModal($scope.appointment);
      };

      self._initialize = function () {
        var initPromises = [];

        if (isEmpty($scope.manager)) {
          initPromises.push(self._getManager());
        }

        if (isEmpty($scope.property)) {
          initPromises.push(self._getProperty());
        }

        if ($scope.appointment.status === 'pending') {
          self._getRequests();
        }

        if (initPromises.length > 0) {
          $scope.isLoading = true;

          $q.all(initPromises).finally(function () {
            $scope.isLoading = false;
          });
        }

        $scope.isPastAppointment =
          $scope.appointment.start_time < $moment().format();
      };

      self._getManager = function () {
        return userInteractionsService
          .getManager($scope.appointment.manager_id)
          .success(self._getManagerSuccess)
          .error(self._loadError);
      };

      self._getProperty = function () {
        return propertyApi
          .getProperty($scope.prospect.property_id)
          .success(self._getPropertySuccess)
          .error(self._loadError);
      };

      self._getRequests = function () {
        self._getRequestsSuccess($scope.appointment.requested_times);
      };

      self._getManagerSuccess = function (response) {
        $scope.manager = response.info[0];
      };

      self._getPropertySuccess = function (response) {
        $scope.property = response.property;
      };

      self._getRequestsSuccess = function (response) {
        $scope.requests = response.requests;
      };

      self._loadError = function () {
        $scope.loadError = true;
      };

      self._initialize();
    }
  ]);
})(window.angular);
