/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import remove from 'lodash/remove';
(function (angular, $) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('conversationInboxRow', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/conversation-inbox-row.html',
      controller: 'ConversationInboxRowController',
      replace: true,
      scope: {
        conversation: '=',
        property: '=',
        selectedIds: '='
      }
    };
  });

  app.controller('ConversationInboxRowController', [
    '$rootScope',
    '$scope',
    '$routeParams',
    '$q',
    'conversationsService',
    'prospectHistoryService',
    'userInteractionsService',
    'prospectsApi',
    '$moment',
    'imageBucket',
    'MessageFormatterService',
    function (
      $rootScope,
      $scope,
      $routeParams,
      $q,
      conversationsService,
      prospectHistoryService,
      userInteractionsService,
      prospectsApi,
      $moment,
      imageBucket,
      MessageFormatterService
    ) {
      var self = this;

      self._deviceWidth = window.screen.width;

      var routeChangeHandler = $rootScope.$on(
        '$routeChangeSuccess',
        function () {
          self._setIsOpen();
        }
      );

      var unreadCountsUpdatedHandler = $rootScope.$on(
        conversationsService.events.updateTeamUnreadCounts,
        function () {
          $scope.conversation.isUnread = conversationsService.isUnread(
            $scope.conversation,
            $scope.thisUser.id
          );
        }
      );

      var prospectUpdatedHandler = $rootScope.$on(
        conversationsService.events.participantUpdated,
        function (event, payload) {
          if (
            !(
              payload.participant_type === 'renter' ||
              payload.participant_type === 'prospect'
            )
          ) {
            return;
          }

          if (
            conversationsService.isParticipant(
              payload.participant_type,
              payload.participant_id,
              $scope.conversation
            )
          ) {
            prospectsApi
              .getProspect(payload.participant_id)
              .then(function (response) {
                var prospect = response.data.prospect;

                $scope.otherUser.info = $scope.otherUser.info || {};
                $scope.otherUser.info.first_name = prospect.profile.first_name;
                $scope.otherUser.info.last_name = prospect.profile.last_name;
                $scope.otherUser.info.status = prospect.status;

                if (prospect.property_id) {
                  if (prospect.propertytype === 'multi-family') {
                    $scope.community = prospect.propertydata;
                  } else {
                    $scope.property = prospect.propertydata;
                  }
                }

                if (prospect.source) {
                  $scope.otherUser.info.source = prospect.source;
                  $scope.otherUser.info.parsedSource =
                    conversationsService.cleanSourceForImage(
                      $scope.otherUser.info.source
                    );
                }
              });
          }
        }
      );

      var appointmentUpdatedHandler = $rootScope.$on(
        userInteractionsService.events.appointmentUpdated,
        function (event, appointmentId, newSource, prospectId) {
          var isProspectThread =
            $scope.otherUser && $scope.otherUser.type === 'prospect';

          if (isProspectThread && $scope.otherUser.id === prospectId) {
            $scope.otherUser.info.source = newSource;
            $scope.otherUser.info.parsedSource =
              conversationsService.cleanSourceForImage(
                $scope.otherUser.info.source
              );
          }
        }
      );

      $scope.$on('$destroy', function () {
        routeChangeHandler();
        prospectUpdatedHandler();
        appointmentUpdatedHandler();
        unreadCountsUpdatedHandler();
      });

      $scope.tryOpenConversation = function () {
        conversationsService.openThreadById($scope.conversation.id, true);

        $scope.conversation.isUnread = false;
      };

      $scope.selectedChanged = function () {
        if ($scope.conversation.isSelected) {
          $scope.selectedIds.push($scope.conversation.id);
        } else {
          remove($scope.selectedIds, function (selectedId) {
            return selectedId === $scope.conversation.id;
          });
        }
      };

      $scope.getMessagePreview = function () {
        if (!$scope.conversation.last_message) {
          return '';
        }

        if ($scope.conversation.last_message.type === 'call') {
          return 'New call';
        }

        let messagePreview =
          $scope.conversation.last_message.html ||
          $scope.conversation.last_message.text;

        messagePreview =
          MessageFormatterService.parseMessageHtml(messagePreview);

        return $(`<div>${messagePreview}</div>`).text();
      };

      self._initialize = function () {
        $scope.imageBucket = imageBucket;

        $scope.thisUser = conversationsService.getMyUser($scope.conversation);
        $scope.otherUser = conversationsService.getOtherUser(
          $scope.conversation
        );

        var prospectPromises = [];

        // Temp UI to resolve API bug that prevents $scope.otherUser from dependably appending .status

        if (
          $scope.otherUser.type === 'prospect' &&
          !$scope.otherUser.info.status
        ) {
          prospectPromises.push(
            userInteractionsService
              .getProspect($scope.otherUser.id)
              .success(function (response) {
                var prospect = $scope.otherUser;

                if (prospect.id === response.prospect.id) {
                  prospect.info.status = response.prospect.status;
                }
              })
          );
        }

        try {
          $scope.conversation.isUnread = conversationsService.isUnread(
            $scope.conversation,
            $scope.thisUser.id
          );
        } catch (e) {
          $scope.conversation.isUnread = false;
        }

        $scope.dateIsInvalid = !$moment(
          $scope.conversation.last_message_time
        ).isValid();

        if ($scope.otherUser.info && $scope.otherUser.info.source) {
          $scope.otherUser.info.parsedSource =
            conversationsService.cleanSourceForImage(
              $scope.otherUser.info.source
            );
        }

        if ($scope.property) {
          self._setPropertyPhoto();
        }

        $q.all(prospectPromises);

        self._setIsOpen();
      };

      self._setIsOpen = function () {
        $scope.isOpen = $routeParams.chatThreadId === $scope.conversation.id;
      };

      self._setPropertyPhoto = function () {
        if ($scope.property.data.coverPhoto) {
          $scope.propertyPhoto = $scope.property.data.coverPhoto;
        } else if ($scope.property.data.photos) {
          $scope.propertyPhoto = $scope.property.data.photos[0];
        }
      };

      self._initialize();
    }
  ]);
})(window.angular, window.jQuery);
