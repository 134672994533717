/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('renterApi', [
    'apiBase',
    'HttpCache',
    function (apiBase, HttpCache) {
      var renterCache = new HttpCache(60);

      return {
        getRenterInfo: function () {
          return apiBase.get('/renter/me/info');
        },
        getRenterInfoById: function (renterId) {
          return apiBase.get('/renter/' + renterId + '/info', {
            cache: renterCache
          });
        },
        activateAccount: function (password, extra) {
          var payload = {
            password: password,
            extra: extra
          };

          return apiBase.put('/renter/me/activate', payload);
        },
        deleteAccount: function () {
          return apiBase.delete('/renter/me');
        }
      };
    }
  ]);
})();
