/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knock-PropertyReports');

  app.factory('propertyReportsService', [
    '$rootScope',
    '$location',
    'appDataService',
    'localStorageService',
    'userService',
    'apiBase',
    'teamApi',
    'messagingModalFactory',
    'editProspectsModalFactory',
    function (
      $rootScope,
      $location,
      appDataService,
      localStorageService,
      userService,
      apiBase,
      teamApi,
      messagingModalFactory,
      editProspectsModalFactory
    ) {
      var self = this;
      var user = userService.getScopedUser();

      self.getTeamMemberFilter = function () {
        return teamApi.getTeamMembers().then(function (response) {
          var teamMembers = [{ label: 'All', id: 'all' }];

          var mappedMembers = map(response.data.members, function (member) {
            return {
              id: member.Manager.id,
              label: [
                member.ManagerInfo.first_name,
                member.ManagerInfo.last_name
              ].join(' ')
            };
          });

          mappedMembers = sortBy(mappedMembers, function (member) {
            if (member.id === user.id) {
              return '00000';
            }

            return member.label;
          });

          return teamMembers.concat(mappedMembers);
        });
      };

      self.getTeamPropertyFilter = function (excludeMeta) {
        var allProperties = appDataService.data.properties;
        var properties = !excludeMeta
          ? [
              {
                name: 'All',
                id: 'all',
                includeUnassigned: true
              },
              {
                name: 'All active',
                id: 'active',
                includeUnassigned: true
              },
              {
                name: 'Unassigned',
                id: 'unassigned',
                includeUnassigned: true
              }
            ]
          : [];

        var mappedProperties = map(allProperties, function (property) {
          var propertyStatus = function (property) {
            if (property.Property.type === 'multi-family') {
              return 'active';
            }

            return property.ManagerListing.is_active ? 'active' : 'inactive';
          };

          return {
            id: property.Property.id,
            type: property.Property.type,
            status: propertyStatus(property),
            name: property.Property.data.location.name
          };
        });

        mappedProperties = sortBy(mappedProperties, function (property) {
          if (property.type === 'multi-family') {
            return '00000' + property.name;
          }

          return property.name;
        });

        return properties.concat(mappedProperties);
      };

      self.getResidentReports = function (
        propertyId,
        status,
        building,
        undecidedOnly,
        residentIds,
        batchId
      ) {
        var params = {
          property_id: propertyId,
          status: status,
          building: building,
          undecided_only: undecidedOnly,
          resident_ids: residentIds,
          batch_id: batchId
        };

        return apiBase.get('/me/property-reports/residents', {
          params: params
        });
      };

      self.getProspectReports = async function (
        createdStart,
        createdEnd,
        propertyId,
        managerId,
        status,
        includeUnassigned,
        todoStatusColor,
        prospectIds,
        lastContactedTime,
        isWaitlist,
        batchId,
        isPaginationEnabled,
        useLegacyEndpoint,
        pageNumber,
        rowsPerPage,
        columnName,
        columnOrder
      ) {
        var params;

        if (isPaginationEnabled && !useLegacyEndpoint) {
          params = {
            created_before: createdEnd,
            created_after: createdStart,
            statuses: [status],
            is_waitlist: isWaitlist ? true : null,
            page: pageNumber,
            per_page: rowsPerPage
          };

          if (prospectIds) {
            params.prospect_ids = prospectIds;
          }

          if (batchId) {
            params.batch_id = batchId;
          }

          if (managerId !== 'all') {
            params.manager_id = managerId;
          }

          if (propertyId !== 'all' && propertyId !== 'active') {
            params.property_id = propertyId;
          }

          if (columnName && columnOrder) {
            params.sort = columnName;
            params.sort_direction = columnOrder;
          }

          const response = await apiBase.post(
            '/me/property-reports/v2/prospects',
            params
          );

          return {
            data: {
              page_data: response.data.page_data,
              prospects_report: response.data.prospect_report.map((item) => {
                const newProspect = { ...item.Prospect };
                newProspect.profile = item.Profile;
                newProspect.preferences = item.RentalPreferences;
                newProspect.follow_up_count = item.follow_up_count;
                newProspect.follow_up_date = item.follow_up_date;
                newProspect.pms_id = item.pms_id;
                newProspect.todo_status = item.todo_status;
                newProspect.first_contact_response_time =
                  item.first_contact_response_time;
                newProspect.property = {
                  name: item.property_name
                };

                return newProspect;
              })
            }
          };
        } else {
          params = {
            created_start: createdStart,
            created_end: createdEnd,
            property_id: propertyId,
            manager_id: managerId,
            status: status,
            include_unassigned: includeUnassigned,
            todo_status_color: todoStatusColor,
            prospect_ids: prospectIds,
            last_contacted_time: lastContactedTime,
            is_waitlist: isWaitlist ? true : null,
            batch_id: batchId
          };

          return apiBase.post('/me/property-reports/prospects', params);
        }
      };

      self.getProspectReportCSV = function (
        createdStart,
        createdEnd,
        propertyId,
        managerId,
        status,
        includeUnassigned,
        isWaitlist,
        todoStatusColor,
        prospectIds,
        lastContactedTime,
        batchId
      ) {
        var params = {
          created_start: createdStart,
          created_end: createdEnd,
          property_id: propertyId,
          manager_id: managerId,
          status: status,
          include_unassigned: includeUnassigned,
          is_waitlist: isWaitlist ? true : null,
          todo_status_color: todoStatusColor,
          prospect_ids: prospectIds,
          last_contacted_time: lastContactedTime,
          batch_id: batchId
        };

        return apiBase.post('/me/property-reports/prospects/csv', params);
      };

      self.saveFilters = function (view, filters) {
        localStorageService.set('report-filters-' + view, filters);
      };

      self.getFilters = function (view) {
        return localStorageService.get('report-filters-' + view);
      };

      self.openSendProspectMessageModal = function (prospects) {
        var recipients = map(prospects, function (prospect) {
          return {
            id: prospect.id,
            threadId: prospect.stream_id,
            info: prospect.profile,
            targetType: 'prospect'
          };
        });

        return messagingModalFactory.openProspectMessageModal(recipients);
      };

      self.openSendResidentMessageModal = function (residents, priority) {
        var recipients = map(residents, function (resident) {
          return {
            id: resident.id,
            threadId: resident.stream_id,
            info: resident.profile,
            sms_consent: resident.sms_consent,
            targetType: 'resident'
          };
        });

        return messagingModalFactory.openResidentMessageModal(
          recipients,
          priority
        );
      };

      self.openEditProspectsModal = function (prospects) {
        return editProspectsModalFactory.openModal(prospects);
      };

      self.viewProspectDetails = function (prospectIds) {
        var prospectIdsQueryParam = prospectIds.join(',');
        $location
          .path(
            $rootScope.featureFlags.PROSPECT_PAGE_REDESIGN ||
              localStorage.getItem('ProspectResidentBeta') === 'true'
              ? '/prospects'
              : '/reports/prospects'
          )
          .search({ f_prospectIds: prospectIdsQueryParam });
      };

      self.viewResidentDetails = function (residentIds) {
        const residentIdsQueryParam = residentIds.join(',');

        $location
          .path(
            $rootScope.featureFlags.RESIDENT_PAGE_REDESIGN ||
              localStorage.getItem('ProspectResidentBeta') === 'true'
              ? '/residents'
              : '/reports/residents'
          )
          .search({ f_residentIds: residentIdsQueryParam });
      };

      return self;
    }
  ]);
})(window.angular, window.objectHash);
