/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('renterAppointmentCondensed', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/renterAppointments/condensed-appointment-view.html'
    };
  });

  app.directive('renterRequestCondensed', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/renterAppointments/condensed-appointment-view.html',
      controller: [
        '$scope',
        function ($scope) {
          $scope.isRequest = true;

          var request = $scope.listingRequest.requests[0];
          request.listing = $scope.listingRequest.listing;

          $scope.appointment = request;
        }
      ]
    };
  });

  app.controller('AppointmentsController', [
    '$scope',
    'timeService',
    'renterAppointmentsService',
    'directionsService',
    '_',
    'listingApi',
    'uiGmapIsReady',
    'mapService',
    'localCache',
    'cacheKeys',
    '$interval',
    'conversationsService',
    'chatTrackingService',
    '$rootScope',
    function (
      $scope,
      timeService,
      renterAppointmentsService,
      directionsService,
      _,
      listingApi,
      uiGmapIsReady,
      mapService,
      localCache,
      cacheKeys,
      $interval,
      conversationsService,
      chatTrackingService,
      $rootScope
    ) {
      var self = this;
      $scope.isLoading = true;

      $scope.map = {};
      // default values
      $scope.map.markers = [];
      $scope.map.zoom = 4;
      $scope.loadingProgressPercent = 0;

      $scope.map.center = localCache.get(cacheKeys.userLocation) || {
        latitude: 39,
        longitude: -98,
        zoom: $scope.map.zoom
      };

      $scope.markersEvents = {
        click: function (gMarker, eventName, model) {
          $scope.$apply(function () {
            $scope.selectAppointment(model.data);
          });
        }
      };

      var startTime = timeService.now().subtract(1, 'months').startOf('day');

      chatTrackingService.hide();
      $rootScope.$on('SalesForceWidgetMounted', () => {
        chatTrackingService.hide();
      });

      renterAppointmentsService.getAppointments(
        startTime,
        function (appointments) {
          $scope.appointments = appointments;

          var mapAppointmentToMarker = function (appointment) {
            var coordinates = appointment.listing.location.geo.coordinates;
            return {
              longitude: coordinates[0],
              latitude: coordinates[1],
              id: appointment.listing_id,
              templateUrl: '/angular/views/renterAppointments/infoWindow.html',
              data: appointment,
              icon: '/images/marker_cal.png'
            };
          };

          var requestsByListing = groupBy(
            $scope.appointments.pending,
            'listing_id'
          ); // jshint ignore:line

          $scope.requests = map(requestsByListing, function (listingRequests) {
            var reqs = map(listingRequests, function (request) {
              return {
                start_time: request.start_time,
                end_time: request.end_time
              };
            });

            return {
              listing: listingRequests[0].listing,
              requests: reqs
            };
          });

          uiGmapIsReady.promise().then(function (instance) {
            $scope.mapInstance = instance[0].map;
            mapService.pan(
              $scope.mapInstance,
              $scope.map.center.latitude,
              $scope.map.center.longitude,
              $scope.map.center.zoom
            );

            var markers = map(
              $scope.appointments.confirmed,
              mapAppointmentToMarker
            );
            markers = markers.concat(
              map($scope.appointments.pending, mapAppointmentToMarker)
            );
            markers = markers.concat(
              map($scope.appointments.visited, mapAppointmentToMarker)
            );
            // show progress bar
            self._startLoadingBar();

            // remove dup markers
            $scope.map.markers = uniq(markers, 'id');

            $scope.zoomToMarkers();
          });

          self._startLoadingBar = function () {
            if (self._loadingIndicatorInterval) {
              $interval.cancel(self._loadingIndicatorInterval);
              $scope.loadingProgressPercent = 0;
            }

            self._loadingIndicatorInterval = $interval(function () {
              if ($scope.loadingProgressPercent >= 100) {
                $interval.cancel(self._loadingIndicatorInterval);
              } else {
                $scope.loadingProgressPercent += 3;
              }
            }, 10);
          };

          $scope.isLoading = false;
        }
      );

      $scope.zoomToMarkers = function () {
        mapService.zoomToContext($scope.mapInstance, $scope.map.markers);
      };

      $scope.viewAppointmentListing = function (appointment) {
        renterAppointmentsService.viewAppointmentListing(appointment);
      };

      $scope.selectAppointment = function (appointment) {
        var coordinates = appointment.listing.location.geo.coordinates;

        mapService.pan($scope.mapInstance, coordinates[1], coordinates[0], 14);

        $scope.selectedAppointment = appointment;
        $scope.selectedRequest = null;
      };

      $scope.selectRequest = function (request) {
        var coordinates = request.listing.location.geo.coordinates;

        mapService.pan($scope.mapInstance, coordinates[1], coordinates[0], 14);

        $scope.selectedRequest = request;
        $scope.selectedAppointment = null;
      };

      $scope.clearSelectedAppointment = function () {
        $scope.selectedAppointment = null;
        $scope.selectedRequest = null;
      };

      $scope.openDirectionsPage = function (appointment) {
        directionsService.openDirectionsPage(
          appointment.listing.location.address.raw
        );
      };

      $scope.openMessageThread = function (appointment) {
        conversationsService.openThreadById(appointment.stream_id);
      };
    }
  ]);
})(window.angular);
