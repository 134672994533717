import React, { FC, useMemo } from 'react';

import {
  Box,
  Slide,
  Chip,
  TextField,
  FormHelperText,
  Divider
} from '@material-ui/core';
import { useMarkAsLostContext } from './MarkAsLostDrawerContextProvider';
import {
  Text,
  makeStyles,
  NamedColors,
  Tooltip
} from '@knockrentals/knock-shared-web';
import { colors } from '../../commonStyles/commonStyles';
import { NearbyCommunity } from 'LegacyAngularApp/legacy-angular-app/services/nearbyCommunitiesService';
import { ReferralFollowUpProps } from './MarkAsLostDrawer';
import ReferralOptions from './ReferralOptions';
import Option from './Option';
import { REFERRAL_NOTE_CHARACTER_LIMIT } from '../../../constants/constants';

const useStyles = makeStyles({
  title: {
    fontSize: '16px',
    fontWeight: 600,
    color: colors.defaultText
  },
  chipBox: {
    padding: '16px 0px 16px 0px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px'
  },
  Box: {
    padding: '16px 0px 0 0px'
  },
  chip: {
    fontSize: '13px',
    fontWeight: 600,
    color: NamedColors.denim[600],
    backgroundColor: NamedColors.slate[50],
    margin: '5',
    cursor: 'pointer',
    '&:hover': {
      textDecorationLine: 'underline',
      backgroundColor: NamedColors.denim[50],
      color: NamedColors.denim[600]
    },
    '&:focus': {
      textDecorationLine: 'underline',
      backgroundColor: NamedColors.denim[50],
      color: NamedColors.denim[600]
    }
  },
  tooltip: {
    fontSize: '10px'
  },
  subtitle: {
    fontSize: '14px',
    color: colors.secondaryText,
    marginTop: '8px'
  },
  textfield: {
    width: '100%',
    marginBottom: 0,
    '& .MuiInputBase-root': {
      border: `none`,
      color: colors.defaultText,
      backgroundColor: '#FAFAFA',
      '&.MuiInputLabel-root.Mui-focused': {
        color: colors.secondaryText,
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: '0.15px'
      }
    },
    '& .MuiInputLabel-formControl.MuiInputLabel-filled.MuiInputLabel-shrink': {
      color: colors.secondaryText
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: `1px solid ${NamedColors.slate[300]}`
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: `1px solid ${NamedColors.slate[300]}`
    },
    '& .MuiInputLabel-formControl': {
      top: '26px',
      left: '0',
      color: colors.disabled,
      fontSize: '16px',
      fontWeight: 400
    }
  },
  helperBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5px'
  },
  FormHelperText: {
    color: colors.secondaryText
  },
  divider: {
    marginTop: '28px'
  }
});

interface SelectedCommunityType {
  propertyName: string;
  propertyId: number;
}

const CreateReferralStep: FC = () => {
  const classes = useStyles();
  const {
    slideDirection,
    selectedCommunities,
    communities,
    openLeasingBinder,
    referralFollowUp,
    setReferralFollowUp,
    referralNote,
    setReferralNote,
    bookTourChecked,
    setBookTourChecked
  } = useMarkAsLostContext();

  const { followUp } = referralFollowUp;

  const selectedCommunity = useMemo(() => {
    return communities
      .filter(
        (community: NearbyCommunity) =>
          selectedCommunities.indexOf(community.property.id) > -1
      )
      .map((community: NearbyCommunity) => {
        return {
          propertyName: community.property.name,
          propertyId: community.property.id
        };
      });
  }, [selectedCommunities, communities]);

  const handleToggle = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setReferralFollowUp((prevState: ReferralFollowUpProps) => ({
      ...prevState,
      followUp: checked
    }));
  };

  return (
    <Slide direction={slideDirection} in={true}>
      <Box>
        <Text className={classes.title}>Communities</Text>
        <Box className={classes.chipBox}>
          {selectedCommunity &&
            selectedCommunity.map((community: SelectedCommunityType) => (
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                arrow
                title="Open Leasing Binder"
                aria-label="open-leasing-binder"
                key={community.propertyId}
              >
                <Chip
                  label={community.propertyName}
                  className={classes.chip}
                  component="a"
                  clickable
                  onClick={() => {
                    openLeasingBinder(community.propertyId);
                  }}
                />
              </Tooltip>
            ))}
        </Box>
        <Box className={classes.Box}>
          <Text className={classes.title}>Referral Note</Text>
          <Text className={classes.subtitle}>
            Please include a note to the receiving team(s) about this referral.
            This note will be added to the conversation thread in all
            guestcards.
          </Text>
        </Box>

        <Box>
          <TextField
            label="Referral Note"
            className={classes.textfield}
            multiline
            variant="filled"
            value={referralNote}
            data-testid="referral-note"
            inputProps={{
              maxLength: REFERRAL_NOTE_CHARACTER_LIMIT
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setReferralNote(event.target.value)
            }
          />
        </Box>
        <Box className={classes.helperBox}>
          <FormHelperText className={classes.FormHelperText}>
            *Required
          </FormHelperText>
          {referralNote && referralNote.length >= 475 && (
            <FormHelperText
              className={classes.FormHelperText}
            >{`${referralNote.length}/${REFERRAL_NOTE_CHARACTER_LIMIT}`}</FormHelperText>
          )}
        </Box>
        <Divider className={classes.divider} />
        <Option
          title="Schedule Follow Up"
          subtitle="Set a reminder for the receiving team(s) to follow up with this prospect."
          checked={followUp}
          toggle={handleToggle}
        />
        {followUp && <ReferralOptions />}

        <Option
          title="Book Tour(s)"
          checked={bookTourChecked}
          disabled={false}
          subtitle="Turning this on will prompt you to schedule tour(s) once the referral has been submitted."
          toggle={(
            evt: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
          ) => {
            setBookTourChecked(checked);
          }}
        />
      </Box>
    </Slide>
  );
};
export default CreateReferralStep;
