/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import filter from 'lodash/filter';
(function (angular) {
  'use strict';

  var app = angular.module('knock-ProspectHistory');

  app.factory('editProspectsModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function (prospects) {
        // This modal will only resolve when cancelled or saved from the
        // EditProspectsController.
        // If a new modal is opened, the promise will never resolve.
        return new Promise((resolve, reject) => {
          $mdDialog.show({
            controller: 'EditProspectsController',
            templateUrl:
              '/angular/views/prospectHistory/edit-prospects-modal.html',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
              prospects: prospects,
              resolve,
              reject
            }
          });
        });
      };

      return self;
    }
  ]);

  app.controller('EditProspectsController', [
    '$rootScope',
    '$scope',
    '$mdToast',
    '$mdDialog',
    'prospects',
    'resolve',
    'reject',
    'prospectHistoryService',
    'deleteResourceModalFactory',
    'batchProspectsLossReasonsModalFactory',
    '_',
    function (
      $rootScope,
      $scope,
      $mdToast,
      $mdDialog,
      prospects,
      resolve,
      reject,
      prospectHistoryService,
      deleteResourceModalFactory,
      batchProspectsLossReasonsModalFactory,
      _
    ) {
      var self = this;

      $scope.prospects = prospects;

      $scope.cheatproofEnabled =
        $rootScope.appPreferences.company.enable_cheatproof_engagement_score;

      $scope.editor = {
        state: 'selecting',
        status: 'open',
        source: '',
        propertyId: null,
        reminder: {
          time: null,
          message: ''
        },
        teamMember: '',
        isSaving: false,
        saveError: false,
        unassignedProspects: filter($scope.prospects, { property_id: null })
      };

      $scope.openLostModal = function () {
        batchProspectsLossReasonsModalFactory.openModal(prospects, 'lost');
      };

      $scope.setEditorState = function (newState) {
        $scope.editor.state = newState;
      };

      $scope.cancel = function () {
        $mdDialog.cancel();
        reject();
      };

      $scope.save = function () {
        $scope.editor.isSaving = true;
        $scope.editor.saveError = false;

        if ($scope.editor.state === 'status') {
          self._saveStatus();
          return;
        }

        if ($scope.editor.state === 'source') {
          self._saveSource();
          return;
        }

        if ($scope.editor.state === 'property') {
          self._saveProperty();
          return;
        }

        if ($scope.editor.state === 'reminder') {
          self._addReminder();
          return;
        }

        if ($scope.editor.state === 'owner') {
          self._updateManager();
          return;
        }

        if ($scope.editor.state === 'archive') {
          self._archive();
          return;
        }

        $scope.editor.isSaving = false;

        throw 'Cannot save from state: ' + $scope.editor.state;
      };

      self._saveStatus = function () {
        var status = $scope.editor.status;

        prospectHistoryService
          .setProspectStatusBatch(prospects, status)
          .then(self._saveSuccess, self._saveError);
      };

      self._saveSource = function () {
        var source = $scope.editor.source;

        prospectHistoryService
          .setProspectSourceBatch(prospects, source)
          .then(self._saveSuccess, self._saveError);
      };

      self._saveProperty = function () {
        var propertyId = $scope.editor.propertyId;

        prospectHistoryService
          .setProspectPropertyBatch(
            $scope.editor.unassignedProspects,
            propertyId
          )
          .then(self._saveSuccess, self._saveError);
      };

      self._addReminder = function () {
        var reminder = $scope.editor.reminder;

        prospectHistoryService
          .addProspectReminderBatch(prospects, reminder)
          .then(self._saveSuccess, self._saveError);
      };

      self._updateManager = function () {
        var teamMember = $scope.editor.teamMember;

        prospectHistoryService
          .setProspectManagerBatch(prospects, teamMember)
          .then(self._saveSuccess, self._saveError);
      };

      self._archive = function () {
        if ($scope.prospects.length > 3) {
          var title = 'Delete prospects';
          var text =
            'Are you sure you wish to archive these ' +
            $scope.prospects.length +
            ' prospects?';
          var supportMessage = 'Please remove prospects';

          deleteResourceModalFactory
            .confirmDeleteResource(title, text, supportMessage)
            .then(function (password) {
              prospectHistoryService
                .archiveProspects($scope.prospects, password)
                .then(self._saveSuccess, self._saveError);
            });

          return;
        }

        prospectHistoryService
          .archiveProspects($scope.prospects)
          .then(self._saveSuccess, self._saveError);
      };

      self._saveSuccess = function () {
        $mdDialog.hide($scope.prospects);
        resolve();

        if ($scope.editor.state === 'archive') {
          $mdToast.showSimple('Archived!');
        } else {
          $mdToast.showSimple('Saved!');
        }
      };

      self._saveError = function () {
        reject();
        $scope.editor.saveError = true;
        $scope.editor.isSaving = false;
      };
    }
  ]);
})(window.angular);
