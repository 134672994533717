/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const HeadshotsController = function ($window) {
    $window.location.href =
      'https://drive.google.com/drive/folders/0B1SIYIiksHXReExPeWx1b2Z3UUk';
  };

  HeadshotsController.$inject = ['$window'];

  app.controller('HeadshotsController', HeadshotsController);
})(window.angular);
