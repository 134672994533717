/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
require('../../core/scripts/conversations/conversations.module');
require('../../core/scripts/conversations/conversations.service');
require('../../core/scripts/conversations/pusherInstance.service');
require('../../core/scripts/conversations/conversationModal.service');
require('../../core/scripts/conversations/conversationPresence.service');
require('../../core/scripts/conversations/conversationInbox.directive');
require('../../core/scripts/conversations/conversationInboxFunctions.directive');
require('../../core/scripts/conversations/conversationInboxRow.directive');
require('../../core/scripts/conversations/conversationInboxPagination.directive');
require('../../core/scripts/conversations/conversationSearchResult.directive');
require('../../core/scripts/conversations/conversationDetails.directive');
require('../../core/scripts/conversations/conversation.directive');
require('../../core/scripts/conversations/conversationMessageText.directive');
require('../../core/scripts/conversations/conversationIFrame.directive');
require('../../core/scripts/conversations/conversationIFrameManager.directive');
require('../../core/scripts/conversations/conversationTypingIndicator.directive');
require('../../core/scripts/conversations/conversationPage.controller');
require('../../core/scripts/conversations/conversationTeamInboxSettings.controller');
require('../../core/scripts/conversations/activeAttachment.directive');
require('../../core/scripts/conversations/conversationTextArea.directive');
require('../../core/scripts/conversations/voiceAudioPlayback.directive');
require('../../core/scripts/conversations/addAttachmentDialog.controller');
require('../../core/scripts/conversations/attachmentsPicker.directive');
require('../../core/scripts/conversations/messageEditor.directive');
require('../../core/scripts/conversations/unreadCounts.service');
require('../../core/scripts/conversations/shareSMSOptInModal.controller');
require('../../core/scripts/conversations/streamCarousel.service');
require('../../core/scripts/conversations/streamRepository.service');
require('../../core/scripts/conversations/emailUnsubscribeStatus.directive');

require('../../core/scripts/conversations/messages/chatMessage.directive');
require('../../core/scripts/conversations/messages/chatAttachment.directive');
require('../../core/scripts/conversations/messages/notificationMessage.directive');
require('../../core/scripts/conversations/messages/activityMessage.directive');
require('../../core/scripts/conversations/messages/callMessage.directive');
