/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Todo');

  app.directive('todoListItem', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/todo/todo-list-item.html',
      controller: 'TodoListItemController',
      scope: {
        item: '=',
        type: '@'
      }
    };
  });

  app.controller('TodoListItemController', [
    '$scope',
    '$timeout',
    'todoService',
    'conversationsService',
    function ($scope, $timeout, todoService, conversationsService) {
      var self = this;

      self._initialize = function () {
        $scope.data = {
          conversation: null
        };

        $scope.viewState = {
          showConversation: false
        };
      };

      $scope.openConversation = function () {
        $scope.viewState.showConversation = true;

        if (isEmpty($scope.data.conversation)) {
          self._getConversation();
        }
      };

      $scope.closeConversation = function () {
        $scope.viewState.showConversation = false;
        $scope.data.conversation = null;
      };

      self._getConversation = function () {
        return conversationsService
          .getConversation($scope.item.stream_id)
          .success(function (response) {
            $scope.data.conversation = response.stream;
          });
      };

      self._initialize();
    }
  ]);
})(window.angular);
