import React from 'react';

export const BuildingAgentIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 5.66669C14.6046 5.66669 15.5 4.77126 15.5 3.66669C15.5 2.56212 14.6046 1.66669 13.5 1.66669C12.3954 1.66669 11.5 2.56212 11.5 3.66669C11.5 4.77126 12.3954 5.66669 13.5 5.66669Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 16.6667L15.5 11.6667H16.5V9.66669C16.5 8.87104 16.1839 8.10798 15.6213 7.54537C15.0587 6.98276 14.2956 6.66669 13.5 6.66669C12.7044 6.66669 11.9413 6.98276 11.3787 7.54537C10.8161 8.10798 10.5 8.87104 10.5 9.66669V11.6667H11.5L12 16.6667H15Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.33325 8.85822H7.33325V2.35822C7.33325 2.22561 7.28057 2.09843 7.18681 2.00466C7.09304 1.91089 6.96586 1.85822 6.83325 1.85822H2.83325C2.70064 1.85822 2.57347 1.91089 2.4797 2.00466C2.38593 2.09843 2.33325 2.22561 2.33325 2.35822V8.85822Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33325 4.35822H7.33325"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33325 6.35822H7.33325"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.16667 8.85999L1.5 8.85999"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.83325 8.85822H7.33325"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
