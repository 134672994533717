/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectEvent', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospectEvents/prospect-event.html',
      scope: {
        event: '=',
        prospect: '=',
        manager: '=?',
        property: '=?'
      },
      controller: [
        '$rootScope',
        '$scope',
        '$q',
        'appDataService',
        'userInteractionsService',
        'propertyApi',
        'managerCalendarEventsService',
        function (
          $rootScope,
          $scope,
          $q,
          appDataService,
          userInteractionsService,
          propertyApi,
          managerCalendarEventsService
        ) {
          var self = this;

          var appointmentTransferedDestroy = $rootScope.$on(
            userInteractionsService.events.appointmentTransferred,
            function (_e, appointment) {
              self._handleEventChanged(
                appointment.event_type,
                appointment.id,
                appointment
              );
            }
          );

          var appointmentUpdatedDestroy = $rootScope.$on(
            userInteractionsService.events.appointmentUpdated,
            function (_e, appointment) {
              self._handleEventChanged(
                appointment.event_type,
                appointment.id,
                appointment
              );
            }
          );

          var appointmentCanceledDestroy = $rootScope.$on(
            managerCalendarEventsService.eventTypes.confirmedAppointment
              .wasCanceled,
            function (_e, appointment) {
              self._handleEventDeleted('appointment', appointment.id);
            }
          );

          $scope.$on('$destroy', function () {
            appointmentTransferedDestroy();
            appointmentUpdatedDestroy();
            appointmentCanceledDestroy();
          });

          $scope.viewState = {
            loadError: false
          };

          self._handleEventChanged = function (eventType, eventId, event) {
            if (
              $scope.event.event_type === eventType &&
              $scope.event.id === eventId
            ) {
              $scope.event = event;
              self._initialize(true);
            }
          };

          self._handleEventDeleted = function (eventType, eventId) {
            if (
              $scope.event.event_type === eventType &&
              $scope.event.id === eventId
            ) {
              $scope.event.status = 'cancelled';
            }
          };

          self._initialize = function (reload) {
            if (isEmpty($scope.manager) || reload) {
              if ($scope.event.manager_id) {
                $scope.manager = appDataService.getTeamMember(
                  $scope.event.manager_id
                )?.ManagerInfo || { first_name: 'N/A', last_name: '' };
              } else {
                $scope.manager = appDataService.getTeamMember(
                  $scope.prospect.assigned_manager_id
                )?.ManagerInfo;
              }
            }

            if (
              (isEmpty($scope.property) || reload) &&
              $scope.prospect.property_id
            ) {
              $scope.property = appDataService.getProperty(
                $scope.prospect.property_id
              ).Property;
            }
          };

          self._initialize();
        }
      ]
    };
  });
})(window.angular);
