import React, { ChangeEvent, FC } from 'react';
import { CircularProgress, List, Typography } from '@material-ui/core';

import ConcessionItem from './ConcessionItem';
import ConcessionsAlert from './ConcessionsAlert';
import { ConcessionsStatusMessage } from '../CreateQuoteView';

export interface ConcessionsProps {
  concessions: { id: string; description: string }[];
  concessionsStatusMessage: string;
  selectedConcessions: { [k: string]: boolean };
  setSelectedConcessions: (
    callback: (prevState: { [k: string]: boolean }) => {}
  ) => void;
}

const Concessions: FC<ConcessionsProps> = ({
  concessions,
  concessionsStatusMessage = '',
  selectedConcessions,
  setSelectedConcessions
}) => {
  const handleOnChangeConcession = (
    e: ChangeEvent<{ name: string; checked: boolean }>
  ) => {
    const { name, checked } = e.target;

    setSelectedConcessions((prevState: { [k: string]: boolean }) => ({
      ...prevState,
      [name]: checked
    }));
  };

  return (
    <section className="concessions">
      <Typography variant="subtitle2">Concessions</Typography>

      <List>
        {concessions.map((concession) => (
          <ConcessionItem
            concessionId={concession.id}
            description={concession.description}
            handleOnChange={handleOnChangeConcession}
            isChecked={Boolean(selectedConcessions[concession.id])}
            key={concession.id}
          />
        ))}
      </List>

      <ConcessionsAlert concessionsStatusMessage={concessionsStatusMessage} />

      {concessionsStatusMessage === ConcessionsStatusMessage.LOADING && (
        <CircularProgress size={20} thickness={2} variant="indeterminate" />
      )}
    </section>
  );
};

export default Concessions;
