import React, { FC } from 'react';
import dayjs from 'dayjs';
import { Box, makeStyles, Text } from '@knockrentals/knock-shared-web';
import { PropertyHour } from '../models';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const useStyles = makeStyles(() => ({
  hours: {
    display: 'flex'
  },

  weekdayName: {
    width: '60px'
  }
}));

interface PropertyHoursProps {
  hours: PropertyHour[];
}

export const PropertyHours: FC<PropertyHoursProps> = ({ hours }) => {
  const classes = useStyles();

  const getWeekdayName = (weekday: number) => {
    let weekdayName: string;

    switch (weekday) {
      case 1:
        weekdayName = 'Mon';
        break;
      case 2:
        weekdayName = 'Tues';
        break;
      case 3:
        weekdayName = 'Wed';
        break;
      case 4:
        weekdayName = 'Thurs';
        break;
      case 5:
        weekdayName = 'Fri';
        break;
      case 6:
        weekdayName = 'Sat';
        break;
      case 7:
        weekdayName = 'Sun';
        break;
      default:
        weekdayName = '';
        break;
    }

    return weekdayName;
  };

  const getFormattedTime = (timeValue: string) => {
    const time = dayjs(timeValue, 'HH:mm:ss');

    const hours = time.hour();

    let amOrPM = hours >= 12 ? ' pm' : ' am';

    return `${hours % 12 || 12}:${time
      .minute()
      .toString()
      .padStart(2, '0')}${amOrPM}`;
  };

  // Since the property hours data could include invalid days and duplicate days, we filter those out to present a nicer UI
  const hoursMap: { [key: string]: PropertyHour } = {};

  hours.forEach((propertyHour: PropertyHour) => {
    if (
      propertyHour.weekday >= 1 &&
      propertyHour.weekday <= 7 &&
      !hoursMap[propertyHour.weekday]
    ) {
      hoursMap[propertyHour.weekday] = propertyHour;
    }
  });

  const validPropertyHours = Object.keys(hoursMap).map(
    (key: string) => hoursMap[key]
  );

  return (
    <Box>
      {validPropertyHours.map((hours: PropertyHour, index: number) => (
        <Box key={index} className={classes.hours}>
          <Text variant="subtitle2" className={classes.weekdayName}>
            {getWeekdayName(hours.weekday)}
          </Text>
          <Text variant="body2">{`${getFormattedTime(
            hours.start_time
          )} - ${getFormattedTime(hours.end_time)}`}</Text>
        </Box>
      ))}
    </Box>
  );
};
