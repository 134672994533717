/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import some from 'lodash/some';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('floorplanRow', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/listing/listingIndex/floorplanRow.html',
      controller: 'FloorplanRowController',
      scope: {
        floorplan: '=',
        onDelete: '=',
        onPost: '='
      }
    };
  });

  app.controller('FloorplanRowController', [
    '$scope',
    '$modal',
    '$location',
    'managerApi',
    'confirmationModalFactory',
    'postFloorplanModalService',
    'accessControl',
    'upgradeModalService',
    function (
      $scope,
      $modal,
      $location,
      managerApi,
      confirmationModalFactory,
      postFloorplanModalService,
      accessControl,
      upgradeModalService
    ) {
      var self = this;

      $scope.thumbImage =
        $scope.floorplan.photos.length > 0
          ? $scope.floorplan.photos[0].thumbUrl
          : '/images/no-image.png';

      $scope.postListing = function ($event) {
        accessControl
          .canAddNewListing()
          .then(function () {
            postFloorplanModalService.openModal(
              $event,
              $scope.floorplan,
              $scope.onPost
            );
          })
          .catch(self._openUpgradeUserSubscriptionModal);
      };

      self._openUpgradeUserSubscriptionModal = function () {
        upgradeModalService.openUpgradeToProModal();
      };

      $scope.editFloorplan = function () {
        $location.url('/manager/community/floorplan/' + $scope.floorplan.id);
      };

      $scope.tryDeleteFloorplan = function () {
        var modal = confirmationModalFactory.createModal(
          'Delete Floorplan',
          'Are you sure you want to delete ' + $scope.floorplan.name + '?',
          self._deleteFloorplan,
          {}
        );

        $modal.open(modal);
      };

      self._deleteFloorplan = function () {
        $scope.errorMessage = null;
        managerApi
          .deleteFloorplan($scope.floorplan.id)
          .success(self._deleteFloorplanSuccess)
          .error(self._deleteFloorplanError);
      };

      self._deleteFloorplanSuccess = function () {
        $scope.onDelete($scope.floorplan);
      };

      self._deleteFloorplanError = function () {
        $scope.errorMessage =
          'There was an error deleting this floorplan. Please try again later.';
      };
    }
  ]);

  app.factory('postFloorplanModalService', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function ($event, floorplan, onPost) {
        var postFloorplanModal = {
          templateUrl:
            '/angular/views/listing/listingIndex/postFloorplanModal.html',
          controller: 'PostFloorplanModalController',
          parent: angular.element(document.body),
          targetEvent: $event,
          locals: {
            floorplan: floorplan,
            onPost: onPost
          },
          clickOutsideToClose: true
        };

        return $mdDialog.show(postFloorplanModal);
      };

      return self;
    }
  ]);

  app.factory('editFloorplanModalService', [
    '$mdDialog',
    function ($mdDialog) {
      var EditFloorplanModalService = {};

      EditFloorplanModalService.events = {
        availableTimeUpdated: 'availableTimeUpdated'
      };

      EditFloorplanModalService.openModal = function ($event, listing) {
        var editFloorplanModal = {
          templateUrl:
            '/angular/views/listing/listingIndex/editFloorplanModal.html',
          controller: 'EditFloorplanListingModalController',
          parent: angular.element(document.body),
          targetEvent: $event,
          locals: {
            listing: listing
          },
          clickOutsideToClose: true
        };

        return $mdDialog.show(editFloorplanModal);
      };

      return EditFloorplanModalService;
    }
  ]);

  app.controller('PostFloorplanModalController', [
    '$scope',
    '$mdDialog',
    '$moment',
    'managerApi',
    'communityApi',
    'listingModelService',
    'floorplan',
    'onPost',
    'propertyApi',
    function (
      $scope,
      $mdDialog,
      $moment,
      managerApi,
      communityApi,
      listingModelService,
      floorplan,
      onPost,
      propertyApi
    ) {
      var self = this;

      $scope.dateFormat = 'MM/dd/yyyy';

      $scope.floorplan = floorplan;

      $scope.unit = null;
      $scope.leaseMonthsInvalid = false;
      $scope.minDate = $moment().format('YYYY-MM-DD');

      $scope.post = function () {
        $scope.errorMessage = null;
        $scope.isSaving = true;

        var leasingPayload = self._preparePayload();

        managerApi
          .createFloorplanListing($scope.floorplan.id, leasingPayload)
          .success(self._postSuccess)
          .error(self._postError);
      };

      self._getCommunity = function () {
        return communityApi
          .getCommunity($scope.floorplan.community_id)
          .success(function (response) {
            self.community = response.community;
            $scope.leasingInfo = self.community.leasing;
            $scope.leaseMonthsDirty = some(
              $scope.leasingInfo.terms.leaseLengths,
              'isAvailable'
            );
          });
      };

      self._initialize = function () {
        self._getCommunity();
      };

      self._preparePayload = function () {
        return {
          unit: $scope.unitText,
          leasing: $scope.leasingInfo,
          application: $scope.application
        };
      };

      self._postSuccess = function () {
        onPost($scope.floorplan);
        $mdDialog.hide();
      };

      self._postError = function () {
        $scope.errorMessage =
          'There was an error posting your floorplan listing. Please try again later.';
        $scope.isSaving = false;
      };

      $scope.dismiss = function () {
        $mdDialog.cancel();
      };

      $scope.$watch(
        'leaseMonths',
        function (leaseMonths) {
          if (!$scope.leaseMonthsDirty) {
            return;
          }

          for (var key in leaseMonths) {
            if (leaseMonths.hasOwnProperty(key)) {
              if (leaseMonths[key]) {
                $scope.leaseMonthsInvalid = false;
                return;
              }
            }
          }

          $scope.leaseMonthsInvalid = true;
        },
        true
      );

      self._initialize();
    }
  ]);

  app.controller('EditFloorplanListingModalController', [
    '$scope',
    '$rootScope',
    '$mdDialog',
    '$moment',
    'listingModelService',
    'editFloorplanModalService',
    'listing',
    'listingApi',
    function (
      $scope,
      $rootScope,
      $mdDialog,
      $moment,
      listingModelService,
      editFloorplanModalService,
      listing,
      listingApi
    ) {
      var self = this;

      $scope.dateFormat = 'MM/dd/yyyy';

      $scope.listing = listing;

      $scope.leaseMonthsInvalid = false;

      $scope.minDate = $moment().format('YYYY-MM-DD');

      $scope.save = function () {
        $scope.errorMessage = null;
        $scope.isSaving = true;

        listingApi
          .updateListing($scope.listing.id, $scope.listing, $scope.application)
          .success(self._postSuccess)
          .error(self._postError);
      };

      self._postSuccess = function (updatedListing) {
        $scope.dismiss();
        $rootScope.$emit(
          editFloorplanModalService.events.availableTimeUpdated,
          updatedListing.listing
        );
      };

      self._postError = function () {
        $scope.errorMessage =
          'There was an error saving your changes. Please try again later.';
        $scope.isSaving = false;
      };

      $scope.dismiss = function () {
        $mdDialog.hide($scope.listing);
      };

      $scope.$watch(
        'listing.leasing.terms.leaseLengths',
        function (leaseLengths) {
          for (var key in leaseLengths) {
            if (leaseLengths.hasOwnProperty(key)) {
              if (leaseLengths[key].isAvailable) {
                $scope.leaseMonthsInvalid = false;
                return;
              }
            }
          }

          $scope.leaseMonthsInvalid = true;
        },
        true
      );
    }
  ]);
})(window.angular);
