import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles, Button, Text } from '@knockrentals/knock-shared-web';
import { colors } from '../../commonStyles/commonStyles';
import { ErrorAlert } from '../../ErrorAlert';
import { Quote } from '../../../ts/models';
import { LoadingOverlay } from '../../LoadingOverlay/LoadingOverlay';

const useStyles = makeStyles(() => ({
  paperWidth: {
    width: '450px'
  },

  dialogTitle: {
    alignItems: 'center',
    color: colors.secondaryText,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 24px 12px 24px'
  },

  titleText: {
    color: colors.defaultText
  },

  closeButton: {
    left: '12px',
    position: 'relative'
  },

  errorAlert: {
    borderRadius: '8px',
    marginBottom: '20px'
  },

  dialogActions: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
    padding: '16px'
  },

  cancelButton: {
    padding: '7px 15px'
  },

  loadingIndicator: {
    zIndex: 1300
  }
}));

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'cs-quotes': any;
    }
  }
}

interface CreateQuotePromptModalProps {
  open: boolean;
  onSubmit: (quote: Quote) => void;
  onClose: (reason: string) => void;
  prospectIntegrationsApi: any;
  prospectId: number;
  propertyId: string;
  propertyName: string;
  vendorProspectId: number;
  vendorPropertyId: number;
  vendorCompanyId: number;
}

const CreateQuotePromptModal: FC<CreateQuotePromptModalProps> = ({
  open,
  onSubmit,
  onClose,
  prospectIntegrationsApi,
  prospectId,
  propertyId,
  propertyName,
  vendorProspectId,
  vendorPropertyId,
  vendorCompanyId
}) => {
  const classes = useStyles();
  const [realpageQuote, setRealPageQuote] = useState<any>(null);
  const [savingQuote, setSavingQuote] = useState<boolean>(false);
  const [quoteError, setQuoteError] = useState<boolean>(false);

  const quoteWidgetStyles =
    '.cs-quotes raul-button .r-button--secondary .r-button__content { ' +
    'width: 135px; color: #fff !important; background-color: #4A61E8; border-radius: 4px; height: 36px; font-size: 13px; ' +
    "font-family: 'Open Sans'; font-weight: 600; letter-spacing: 0.46px;} .r-modal__container--media .r-modal-header__close { top: -16px; right: -16px }";

  const handleSubmit = (unifiedQuote: any) => {
    onSubmit(unifiedQuote);
  };

  const handleClose = () => {
    setRealPageQuote(null);
    setQuoteError(false);

    onClose('close');
  };

  const saveRealpageQuote = async () => {
    if (!realpageQuote) {
      console.log('No quote was generated, skipping quote summary view.');
      return;
    }

    setQuoteError(false);
    setSavingQuote(true);

    try {
      const response = await prospectIntegrationsApi.postRealPageUnifiedQuotes(
        prospectId,
        propertyId,
        realpageQuote
      );

      const unifiedQuote = {
        quotedRent: response.data.quote.quoted_rent,
        leaseTermMonths: response.data.quote.lease_term_months,
        leaseStartDate: response.data.quote.lease_start_date,
        leaseEndDate: response.data.quote.lease_end_date,
        url: response.data.quote.url,
        unit: {
          name: response.data.unit.unit.name,
          buildingName: response.data.unit.building.building.name,
          displayPrice: ''
        }
      };

      handleSubmit(unifiedQuote);
    } catch (error) {
      console.log('Error saving RealPage quote:', error);
      setQuoteError(true);
    } finally {
      setSavingQuote(false);
    }
  };

  const addQuotesClosedListener = () => {
    const quotesEl = document.getElementById('quotes');

    if (quotesEl) {
      quotesEl.addEventListener('csQuotesClosed', () => saveRealpageQuote());
    }
  };

  const addQuoteCreatedListener = () => {
    const quotesEl = document.getElementById('quotes');

    if (quotesEl) {
      quotesEl.addEventListener('onCreateQuoteSuccess', (e: any) => {
        if (e.detail && e.detail.length > 0) {
          const realpageQuote = e.detail[0];
          let effectiveRent = 0;

          if (
            realpageQuote.monthlyCharges &&
            realpageQuote.monthlyCharges.length > 0
          ) {
            realpageQuote.monthlyCharges.forEach((charge: any) => {
              effectiveRent += charge.amount;
            });
          }

          if (!effectiveRent) {
            effectiveRent = realpageQuote.rent;
          }

          setRealPageQuote({
            quote_id: realpageQuote.quoteId,
            unit_id: realpageQuote.unitId,
            unit_number: realpageQuote.unitNumber,
            building_number: realpageQuote.buildingNumber,
            created_date: realpageQuote.createDate,
            expiration_date: realpageQuote.expirationDate,
            rent: realpageQuote.rent,
            deposit: realpageQuote.deposit,
            lease_start_date: realpageQuote.leaseStartDate,
            lease_term_months: realpageQuote.leaseTermMonths,
            move_in_date: realpageQuote.moveInDate,
            move_out_date: realpageQuote.moveOutDate,
            guestcard_id: realpageQuote.guestCard.guestCardId,
            effective_rent: effectiveRent
          });
        }
      });
    }
  };

  useEffect(() => {
    (async () => {
      const waitForElement = (selector: string) => {
        return new Promise((resolve) => {
          if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
          }

          const observer = new MutationObserver((_mutations) => {
            if (document.querySelector(selector)) {
              observer.disconnect();
              resolve(null);
            }
          });

          observer.observe(document.body, {
            childList: true,
            subtree: true
          });
        });
      };

      if (open) {
        await waitForElement('#quotes');

        addQuotesClosedListener();
        addQuoteCreatedListener();
      }
    })();
  });

  return (
    <Dialog open={open} classes={{ paperWidthSm: classes.paperWidth }}>
      <LoadingOverlay open={savingQuote} className={classes.loadingIndicator} />

      <DialogTitle className={classes.dialogTitle} disableTypography={true}>
        <Text variant="h6" className={classes.titleText}>
          Create Quote
        </Text>

        <IconButton
          data-testid="close-button"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers={false}>
        {quoteError && (
          <ErrorAlert
            alertMessage="We ran into an issue generating this quote. Please try again later."
            className={classes.errorAlert}
          />
        )}

        <Text variant="body1">{`Would you like to create a quote for a unit at ${propertyName}?`}</Text>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          className={classes.cancelButton}
          onClick={handleClose}
        >
          Cancel
        </Button>

        <cs-quotes
          css-styles={quoteWidgetStyles}
          id="quotes"
          company-id={vendorCompanyId}
          property-id={vendorPropertyId}
          guest-card-id={vendorProspectId}
          source="os"
        />
      </DialogActions>
    </Dialog>
  );
};

export default CreateQuotePromptModal;
