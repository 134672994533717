/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('voiceAudioPlayback', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/voice-audio-playback.html',
      controller: 'VoiceAudioPlaybackController',
      scope: {
        audio: '=',
        audioUrl: '='
      }
    };
  });

  app.controller('VoiceAudioPlaybackController', [
    '$scope',
    '$interval',
    'ngAudio',
    function ($scope, $interval, ngAudio) {
      var self = this;

      $scope.isAudioLoaded = false;

      $scope.$watch('audioUrl', function (oldUrl, newUrl) {
        if (oldUrl && newUrl && oldUrl !== newUrl) {
          $scope.audio = {
            remaining: 0,
            currentTime: 0
          };
          self._loadMessageAudio();
        }
      });

      $scope.togglePlay = function () {
        if (!$scope.audio || !$scope.audio.canPlay) {
          self._loadMessageAudio();
          $scope.audio.paused = true;
        }

        if ($scope.audio.paused) {
          $scope.audio.play();
        } else {
          $scope.audio.pause();
        }
      };

      self._loadMessageAudio = function () {
        $scope.audio = ngAudio.load($scope.audioUrl);
      };
    }
  ]);
})(window.angular);
