/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectExportStatus', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospects/prospect-export-status.html',
      scope: {
        prospect: '=',
        exportStatus: '='
      },
      controller: [
        '$scope',
        '$rootScope',
        '$timeout',
        'newProspectService',
        'prospectHistoryService',
        'prospectsApi',
        function (
          $scope,
          $rootScope,
          $timeout,
          newProspectService,
          prospectHistoryService,
          prospectsApi
        ) {
          $scope.data = {
            isAmsi: false,
            isSaving: false,
            hasFailed: false,
            exporting: false,
            exportStatus: null,
            exportClicked: false,
            exportTimeoutMessage: '',
            currentIntegrations: null,
            updatedIntegrations: null
          };

          const tooltipText = {
            COPY: 'Copy to clipboard',
            COPIED: 'Copied to clipboard',
            ERROR: 'Unable to copy to clipboard'
          };

          $scope.clipboardTooltipText = tooltipText.COPY;

          $scope.setClipboardToolTipText = async (text) => {
            try {
              const clipboard = await navigator.clipboard.readText();
              if (clipboard !== text) {
                $scope.clipboardTooltipText = tooltipText.COPY;
              }
            } catch (_e) {
              $scope.clipboardTooltipText = tooltipText.ERROR;
            }
          };

          $scope.setClipboardText = async (text) => {
            try {
              await navigator.clipboard.writeText(text);
              $scope.clipboardTooltipText = tooltipText.COPIED;
            } catch (_e) {
              $scope.clipboardTooltipText = tooltipText.ERROR;
            }
          };

          $scope.setIntegrationMapping = function (
            integrationVendor,
            integrationCredentialId
          ) {
            $scope.data.isSaving = true;
            $scope.data.exportClicked = true;

            prospectsApi
              .setProspectIntegrationMapping(
                $scope.prospect.id,
                integrationVendor,
                integrationCredentialId
              )
              .success(function () {
                $scope.data.isSaving = false;
              });
          };

          $rootScope.$on(
            prospectHistoryService.events.prospectUpdated,
            function () {
              $scope.data.isSaving = true;

              self._checkIntegrations();
            }
          );

          $rootScope.$on(
            newProspectService.events.prospectExportUpdated,
            function (event, data) {
              if (
                data.prospectId === $scope.prospect.id &&
                $scope.data.isAmsi &&
                $scope.data.hasFailed
              ) {
                $scope.data.exportStatus =
                  newProspectService.getProspectExportStatus(
                    $scope.prospect.id
                  );

                if (
                  data.status === 'completed' &&
                  $scope.data.isSaving &&
                  $scope.data.exporting
                ) {
                  prospectsApi
                    .getProspect($scope.prospect.id)
                    .then(function (response) {
                      $scope.data.updatedIntegrations =
                        response.data.prospect.integrations;

                      if (
                        $scope.data.currentIntegrations &&
                        $scope.data.updatedIntegrations
                      ) {
                        $scope.prospect.integrations =
                          $scope.data.updatedIntegrations;

                        if (
                          JSON.stringify($scope.data.currentIntegrations) !==
                          JSON.stringify($scope.data.updatedIntegrations)
                        ) {
                          $scope.data.isSaving = false;
                          $scope.data.exporting = false;
                          $scope.data.exportTimeoutMessage = '';
                          self._clearSetTimeout();
                        }
                      }
                    });
                }
              }
            }
          );

          self._setTimeout = null;

          self._clearSetTimeout = function () {
            if (self._setTimeout) {
              $timeout.cancel(self._setTimeout);
              self._setTimeout = null;
            }
          };

          self._checkIntegrations = function () {
            $scope.data.currentIntegrations = $scope.prospect.integrations;

            $scope.data.currentIntegrations.forEach(function (integration) {
              if (integration.vendor === 'amsi') {
                $scope.data.isAmsi = true;
              } else {
                return;
              }
              if (integration.state === 'failed') {
                $scope.data.hasFailed = true;
              }
              if ($scope.data.hasFailed) {
                $scope.data.exporting = true;

                self._clearSetTimeout();

                self._setTimeout = $timeout(function () {
                  $scope.data.isSaving = false;
                  $scope.data.exporting = false;
                  $scope.data.exportTimeoutMessage = 'Export is queued.';
                }, 24000);
              }
            });
          };

          self._initialize = function () {
            $scope.data.currentIntegrations = $scope.prospect.integrations;
          };

          self._initialize();
        }
      ]
    };
  });

  app.filter('exportedProspectInfo', function () {
    return function (exportedProspect) {
      var vendorFields = {
        amsi: 'AMSI P-code',
        entrata: 'Entrata Lead ID',
        mri: 'MRI Primary Name ID',
        realpage: 'RP Guestcard ID',
        resman: 'Resman P-code',
        yardi: 'Yardi P-code'
      };

      let display_vendor_prospect_id =
        exportedProspect.vendor_prospect_id || 'N/A';

      if (
        exportedProspect.related_vendor_prospect_ids &&
        exportedProspect.related_vendor_prospect_ids.length > 1
      ) {
        display_vendor_prospect_id =
          exportedProspect.related_vendor_prospect_ids.join(', ');
      }

      if (exportedProspect.vendor === 'appfolio') {
        return;
      }

      return (
        vendorFields[exportedProspect.vendor] +
        ': ' +
        display_vendor_prospect_id
      );
    };
  });

  app.filter('prospectExportVendorName', function () {
    var vendorNames = {
      amsi: 'AMSI',
      entrata: 'Entrata',
      mri: 'MRI',
      realpage: 'RealPage',
      resman: 'Resman',
      yardi: 'Yardi',

      knock: 'Knock'
    };

    return function (vendor) {
      return vendorNames[vendor] || vendor;
    };
  });
})(window.angular);
