import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  Button,
  makeStyles,
  NamedColors,
  ThemeProvider,
  Box,
  white,
  Tooltip
} from '@knockrentals/knock-shared-web';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Theme,
  IconButton,
  Paper
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DataTable, { Options } from 'material-table';

import { FloorplanIcon } from '../icons/FloorplanIcon';
import { FloorplanType, LayoutType, UnitType, UnitListingType } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: '890px',
    minHeight: '400px'
  },
  dialogContentRoot: {
    padding: '8px 18px'
  },
  boxStyle: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center'
  },
  formControl: {
    minWidth: 120,
    borderColor: NamedColors.slate[300]
  },
  selectEmpty: {
    marginTop: '10px',
    borderColor: NamedColors.slate[300]
  },
  button: {
    color: NamedColors.slate[800],

    '& svg': {
      marginRight: '8px'
    },

    '&:focus': {
      color: NamedColors.slate[800],

      '& svg path': {
        stroke: NamedColors.slate[800]
      }
    }
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0)
  },
  copyButton: {
    background: '#2EB88F',
    color: white,
    lineHeight: '25px',
    height: '25px',
    width: '25px',
    fontSize: '14px',
    margin: '2px',
    '&:hover': {
      background: '#2EB88F'
    }
  },
  quoteButton: {
    background: '#3FA9F5',
    color: white,
    lineHeight: '25px',
    height: '25px',
    width: '25px',
    fontSize: '14px',
    margin: '2px',
    '&:hover': {
      background: '#3FA9F5'
    }
  },
  formControlLabel: {
    left: '0',
    margin: 0,
    transform: 'none',
    fontWeight: 600,
    marginBottom: '6px'
  },
  muiListItemSelected: {
    backgroundColor: white,
    '&:hover': {
      backgroundColor: white
    }
  },
  tooltip: {
    zIndex: 15001
  }
}));

const tableOptions: Options<any> = {
  draggable: false,
  grouping: false,
  headerStyle: {
    borderBottom: `1px solid ${NamedColors.slate[600]}`,
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '24px',
    paddingBottom: '12px'
  },
  padding: 'dense',
  paging: false,
  rowStyle: {
    height: '65px'
  },
  search: false,
  sorting: false,
  toolbar: false,
  thirdSortClick: false,
  actionsColumnIndex: -1
};

const layouts: LayoutType[] = [
  { title: 'All', value: -1, compare: '>=' },
  { title: 'Studio', value: 0, compare: '===' },
  { title: '1 bedroom', value: 1, compare: '===' },
  { title: '2 bedroom', value: 2, compare: '===' },
  { title: '3+ bedroom', value: 3, compare: '>=' }
];

interface ShareListingProps {
  appProperties: any;
  getPropertyUnitListings: (propertyId: any) => Promise<any>;
  pasteUnitListingLink: (unitlisting: UnitListingType, flag: string) => void;
  generateQuote: (unit: UnitType) => void;
  quoteEnable: boolean;
}

const ShareListingModal: FC<ShareListingProps> = ({
  appProperties,
  getPropertyUnitListings,
  pasteUnitListingLink,
  generateQuote,
  quoteEnable
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [units, setUnits] = useState<UnitType[]>([]);
  const [allUnits, setAllUnits] = useState<UnitType[]>([]);
  const [selectedLayout, setSelectedLayout] = useState<number>(-1);
  const [floorPlans, setFloorPlans] = useState<any>([]);
  const [selectedFloorPlan, setSelectedFloorplan] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const availableDate = (availableDateString: string) => {
    const currentDate: any = Date.now();
    const availableDate: any = new Date(availableDateString);

    if (availableDate <= currentDate) {
      return 'Today';
    } else {
      const monthStr: string = availableDate.toLocaleString('default', {
        month: 'short'
      });
      return `${monthStr} ${availableDate.getDate()}, ${availableDate.getFullYear()}`;
    }
  };

  const setFloorplans = (units: UnitType[]) => {
    const floorPlans = units.map((unit: UnitType) => unit.listing.floorplan);
    const floorplansArr = floorPlans.reduce(
      (acc: FloorplanType[], obj: FloorplanType) => {
        const found = acc.some((item: FloorplanType) => item.id === obj.id);
        if (!found) acc.push(obj);
        return acc;
      },
      []
    );
    setFloorPlans(floorplansArr);
  };

  const getUnits = useCallback(
    async (propertyId: string) => {
      try {
        setLoading(true);
        const { data } = await getPropertyUnitListings(propertyId);
        setLoading(false);
        setAllUnits(data.unit_listings);
        setUnits(data.unit_listings);
        setFloorplans(data.unit_listings);
      } catch (err) {
        setLoading(false);
      }
    },
    [getPropertyUnitListings]
  );
  const pasteLink = (flag: string, data: any = null) => {
    if (flag === 'listing') {
      pasteUnitListingLink(data, flag);
    } else {
      const userSelectedProperty = appProperties.find(
        (propertyData: any) => propertyData.Property.id === selectedProperty
      );
      pasteUnitListingLink(userSelectedProperty, flag);
    }
    setOpen(!open);
  };

  const initQuote = (unit: UnitType) => {
    setOpen(!open);
    generateQuote(unit);
  };

  useEffect(() => {
    setSelectedProperty(appProperties[0].Property.id);
  }, [appProperties]);

  const getColumns = () => {
    return [
      {
        title: 'Unit#',
        field: 'unit',
        render: (rowData: UnitType) => {
          return rowData?.listing.location?.address?.unit || 'none';
        }
      },
      {
        title: 'Building',
        field: 'building',
        render: (rowData: UnitType) => {
          return rowData?.listing.external?.building?.number || '-';
        }
      },
      {
        title: 'Price',
        field: 'price',
        render: (rowData: UnitType) => {
          const currencyOptins = {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          };
          return rowData.listing.leasing?.monthlyRent
            ? rowData.listing.leasing.monthlyRent.toLocaleString(
                'en-US',
                currencyOptins
              )
            : '';
        }
      },
      {
        title: 'Bd/Ba',
        field: 'bdba',
        render: (rowData: UnitType) => {
          const bd: string = rowData.listing.floorplan?.bedrooms
            ? `${rowData.listing.floorplan.bedrooms} bd`
            : '';
          const ba: string = rowData.listing.floorplan?.bathrooms
            ? `${rowData.listing.floorplan.bathrooms} ba`
            : '';
          return `${bd} ${ba}`;
        }
      },
      {
        title: 'Floorplan',
        field: 'floorplan',
        render: (rowData: UnitType) => {
          return rowData.listing?.floorplan?.name || '';
        }
      },
      {
        title: 'Sq Ft',
        field: 'sqfoot',
        headerStyle: {
          padding: '0'
        },
        render: (rowData: UnitType) => {
          return rowData.listing?.floorplan?.size || 0;
        }
      },
      {
        title: 'Avail Date',
        field: 'availDate',
        headerStyle: {
          padding: '0'
        },
        render: (rowData: UnitType) => {
          return rowData.listing?.leasing?.availableDate
            ? availableDate(rowData.listing.leasing.availableDate)
            : '';
        }
      },
      {
        title: '',
        field: 'action',
        render: (rowData: UnitType) => {
          return (
            <>
              {quoteEnable && (
                <Tooltip
                  classes={{ popper: classes.tooltip }}
                  title="Generate quote"
                  placement="left-start"
                >
                  <IconButton
                    data-testid="quote-enable-button"
                    aria-label={'quote button'}
                    className={classes.quoteButton}
                    onClick={() => initQuote(rowData)}
                  >
                    <i className="fa fa-dollar " />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                classes={{ popper: classes.tooltip }}
                title="Paste link"
                placement="left-start"
              >
                <IconButton
                  aria-label={'copy button'}
                  className={classes.copyButton}
                  onClick={() => pasteLink('listing', rowData.listing)}
                >
                  <i className="fa fa-paste " />
                </IconButton>
              </Tooltip>
            </>
          );
        }
      }
    ];
  };
  const openAvailabilityDialog = () => {
    getUnits(selectedProperty);
    setOpen(!open);
  };
  const propertyChange = (event: any) => {
    setSelectedProperty(event.target.value);
    getUnits(event.target.value);
  };
  const handleChange = (flag: string, event: any) => {
    let floorPlanFilter: string = selectedFloorPlan;
    let layoutFilter: number = selectedLayout;

    if (flag === 'layout') {
      layoutFilter = event.target.value;
      setSelectedLayout(layoutFilter);
    } else {
      floorPlanFilter = event.target.value;
      setSelectedFloorplan(floorPlanFilter);
    }

    let newUits = allUnits.filter((unit: UnitType) =>
      layoutFilter === -1
        ? unit.listing.floorplan.bedrooms >= layoutFilter
        : unit.listing.floorplan.bedrooms === layoutFilter
    );

    if (floorPlanFilter) {
      newUits = newUits.filter(
        (unit: UnitType) => unit.listing.floorplan.id === floorPlanFilter
      );
    }
    setUnits(newUits);
  };

  return (
    <ThemeProvider>
      <Button
        variant="text"
        className={classes.button}
        onClick={openAvailabilityDialog}
        data-testid="available-button"
      >
        <FloorplanIcon /> Availability
      </Button>
      <Dialog
        classes={{ paperWidthMd: classes.dialog }}
        maxWidth="md"
        style={{ zIndex: 15001 }}
        aria-labelledby="available-unit-dialog-title"
        open={open}
      >
        <DialogTitle id="available-unit-dialog-title">
          Current availabilities
          <IconButton
            aria-label={'close button'}
            className={classes.closeButton}
            data-testid="close-button"
            onClick={() => setOpen(!open)}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <Box className={classes.boxStyle}>
            <Box>
              <FormControl className={classes.formControl}>
                <InputLabel
                  classes={{ formControl: classes.formControlLabel }}
                  id="property-dropdown"
                >
                  Property
                </InputLabel>
                <Select
                  labelId="property-dropdown"
                  date-testid="property-dropdown"
                  value={selectedProperty}
                  onChange={propertyChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={{
                    style: { zIndex: 15001 }
                  }}
                >
                  {appProperties &&
                    appProperties.map((appProprty: any) => (
                      <MenuItem
                        key={appProprty.Property.id}
                        classes={{
                          selected: classes.muiListItemSelected
                        }}
                        value={appProprty.Property.id}
                      >
                        {appProprty.Property.data?.location?.name || ''}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel
                  classes={{ formControl: classes.formControlLabel }}
                  id="layout-dropdown"
                >
                  Layout
                </InputLabel>
                <Select
                  labelId="layout-dropdown"
                  data-testid="layout-dropdown"
                  value={selectedLayout}
                  onChange={(event: any) => handleChange('layout', event)}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={{
                    style: { zIndex: 15001 }
                  }}
                >
                  {layouts.map((layout: LayoutType, index: number) => (
                    <MenuItem key={index} value={layout.value}>
                      {layout.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel
                  classes={{ formControl: classes.formControlLabel }}
                  id="floorplan-dropdown"
                >
                  Floor Plan
                </InputLabel>
                <Select
                  labelId="floorplan-dropdown"
                  data-testid="floorplan-dropdown"
                  value={selectedFloorPlan}
                  onChange={(event: any) => handleChange('floor-plan', event)}
                  displayEmpty
                  className={classes.selectEmpty}
                  MenuProps={{
                    style: { zIndex: 15001 }
                  }}
                >
                  <MenuItem value={''}>All</MenuItem>
                  {floorPlans.map(
                    (floorPlan: FloorplanType) =>
                      floorPlan.name && (
                        <MenuItem key={floorPlan.id} value={floorPlan.id}>
                          {floorPlan.name}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box className={classes.iconBox}>
              <Tooltip
                classes={{ popper: classes.tooltip }}
                title="Paste link"
                placement="left-start"
              >
                <IconButton
                  aria-label={'copy button'}
                  className={classes.copyButton}
                  onClick={() => pasteLink('property')}
                  data-testid="property-paste-button"
                >
                  <i className="fa fa-paste " />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <DataTable
              components={{
                Container: (props) => <Paper {...props} elevation={0} />
              }}
              isLoading={loading}
              columns={getColumns()}
              data={units}
              options={tableOptions}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};
export default ShareListingModal;
