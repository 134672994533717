import ReactDOM from 'react-dom';
import React, {
  FC,
  useEffect,
  useState,
  createContext,
  useContext
} from 'react';
import { Alert, Text } from '@knockrentals/knock-shared-web';

interface Notification {
  text: string;
  duration: number;
}

const useCreateNotificationContext = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [activeNotificationIndex, setActiveNotificationIndex] = useState(-1);

  const addNotification = (notification: Notification) => {
    setNotifications([...notifications, notification]);
    if (activeNotificationIndex < 0) {
      setActiveNotificationIndex(0);
    }
  };

  const incrementNotification = () => {
    setActiveNotificationIndex(activeNotificationIndex + 1);
  };

  return {
    addNotification,
    notifications,
    incrementNotification,
    activeNotificationIndex
  };
};

type NotificationContextType = ReturnType<typeof useCreateNotificationContext>;
const NotificationContext = createContext<NotificationContextType>(
  {} as NotificationContextType
);

export const NotificationProvider = (props: any) => {
  return (
    <NotificationContext.Provider value={useCreateNotificationContext()}>
      {props.children}
      {ReactDOM.createPortal(<Notifications />, document.body)}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const {
    notifications,
    addNotification,
    incrementNotification,
    activeNotificationIndex
  } = useContext(NotificationContext);

  return {
    notify: (message: string, duration: number = 3000) => {
      console.log(message);
      addNotification({
        text: message,
        duration
      });
    },
    notifications,
    incrementNotification,
    activeNotificationIndex
  };
};

const Notifications: FC = () => {
  const { notifications, incrementNotification, activeNotificationIndex } =
    useNotification();

  const [isVisible, setIsVisible] = useState(false);
  const [isReady, setIsReady] = useState(true);
  const [lastNotificationIndex, setLastNotificationIndex] = useState(
    activeNotificationIndex
  );

  let notification: Notification | null = null;

  if (
    notifications &&
    notifications.length > 0 &&
    activeNotificationIndex > -1
  ) {
    notification = notifications[activeNotificationIndex];
  }

  useEffect(() => {
    if (notification && lastNotificationIndex < activeNotificationIndex) {
      if (isReady) {
        setIsReady(false);
        setTimeout(() => {
          setIsVisible(true);
        }, 100);
      } else if (isVisible) {
        setTimeout(() => {
          // Animate out. Once out of view, pop off for the next
          setIsVisible(false);
          setTimeout(() => {
            setLastNotificationIndex(activeNotificationIndex);
            incrementNotification();
            setIsReady(true);
          }, 500);
        }, notification.duration);
      }
    }
  }, [
    notification,
    isVisible,
    isReady,
    activeNotificationIndex,
    incrementNotification,
    lastNotificationIndex
  ]);

  if (!notification) {
    return null;
  }

  return (
    <Alert
      style={{
        position: 'fixed',
        left: '20px',
        right: '20px',
        bottom: '20px',
        transitionDuration: '500ms',
        transitionProperty: 'all',
        transform: isVisible ? 'translateY(0)' : 'translateY(250px)'
      }}
      color="error"
    >
      <Text variant="body1">{notification.text}</Text>
    </Alert>
  );
};
