/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
require('./helpers/jQueryDependencies');
require('lodash');
require('spectrum-colorpicker/spectrum.css');
require('spectrum-colorpicker');
require('./helpers/momentDependencies');
require('./helpers/pusherDependencies');

require('../bootstrapDependencies');

/**
 * Other Dependencies
 */
require('sha1');
window.ZeroClipboard = require('zeroclipboard');
require('sortablejs');
require('vanilla-autofill-event');
require('object-hash/dist/object_hash');
window.taTools = {};

require('./helpers/angularModules');
