/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import includes from 'lodash/includes';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('conversationInboxFunctions', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/conversations/conversation-inbox-functions.html',
      controller: 'ConversationInboxFunctionsController',
      scope: {
        streamType: '=',
        streams: '=',
        selectedStreams: '=',
        managerId: '='
      }
    };
  });

  app.controller('ConversationInboxFunctionsController', [
    '$scope',
    '$mdDialog',
    '$mdToast',
    'conversationsService',
    'streamCarouselService',
    function (
      $scope,
      $mdDialog,
      $mdToast,
      conversationsService,
      streamCarouselService
    ) {
      $scope.$watch('selectedStreams.selectedIds.length', function (newCount) {
        $scope.selectedStreams.allSelected =
          newCount > 0 && newCount === $scope.streams.length;
      });

      $scope.allSelectedChanged = function () {
        forEach($scope.streams, function (stream) {
          stream.isSelected = $scope.selectedStreams.allSelected;
        });

        $scope.selectedStreams.selectedIds = $scope.selectedStreams.allSelected
          ? map($scope.streams, 'id')
          : [];
      };

      $scope.markSelectedAsUnread = function () {
        conversationsService
          .markAsUnreadByIds(
            $scope.selectedStreams.selectedIds,
            $scope.streamType,
            $scope.managerId
          )
          .success(function () {
            forEach($scope.streams, function (stream) {
              if (includes($scope.selectedStreams.selectedIds, stream.id)) {
                stream.isUnread = true;
              }
            });
          });
      };

      $scope.openSelectedInCarousel = function () {
        var selectedConversations = $scope.selectedStreams.selectedIds;

        if (selectedConversations.length > 0) {
          streamCarouselService.showCarousel(
            map(selectedConversations, null, null)
          );
        } else {
          $mdToast.showSimple('No prospects selected');
        }
      };

      $scope.tryOpenAllInCarousel = function () {
        var activePageConversations = map($scope.streams, 'id');

        if (activePageConversations.length > 0) {
          streamCarouselService.showCarousel(
            map(activePageConversations, null, null)
          );
        } else {
          $mdToast.showSimple('No prospects selected');
        }
      };

      $scope.markSelectedAsRead = function () {
        conversationsService
          .markAsReadByIds($scope.selectedStreams.selectedIds)
          .success(function () {
            forEach($scope.streams, function (stream) {
              if (includes($scope.selectedStreams.selectedIds, stream.id)) {
                stream.isUnread = false;
              }
            });
          });
      };

      $scope.tryMarkAllAsUnread = function () {
        var confirmation = $mdDialog
          .confirm()
          .title('Mark all as read')
          .content(
            'Are you sure you want to mark all ' +
              $scope.streamType +
              ' messages as read?'
          )
          .ok('Yes')
          .cancel('Cancel');

        $mdDialog.show(confirmation).then(function () {
          conversationsService
            .markAsReadByType($scope.streamType, $scope.managerId)
            .success(function () {
              forEach($scope.streams, function (stream) {
                stream.isUnread = false;
              });
            });
        });
      };
    }
  ]);
})(window.angular);
