export default function configureTheming(app: any) {
  app.config([
    '$mdThemingProvider',
    function ($mdThemingProvider: any) {
      $mdThemingProvider.definePalette('knockPrimaryTheme', {
        '50': '8ddbaf',
        '100': '7cd5ab',
        '200': '67cda7',
        '300': '50c4a4',
        '400': '3db29c',
        '500': '2EB88F',
        '600': '258684',
        '700': '1e767b',
        '800': '186771',
        '900': '135a68',
        A100: 'A7FFEB',
        A200: '64FFDA',
        A400: '1DE9B6',
        A700: '00BFA5',
        contrastDefaultColor: 'light', // whether, by default, text (contrast)
        // on this palette should be dark or light
        contrastDarkColors: [
          '50',
          '100', //hues which contrast should be 'dark' by default
          '200',
          '300',
          '400',
          'A100'
        ],
        contrastLightColors: undefined // could also specify this if default was 'dark'
      });

      $mdThemingProvider.theme('default').primaryPalette('knockPrimaryTheme');
    }
  ]);
}
