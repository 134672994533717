/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('deleteResourceModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.confirmDeleteResource = function (title, text, supportMessage) {
        return $mdDialog.show({
          templateUrl:
            '/angular/views/inlineDocumentEditor/delete-resource-modal.html',
          controller: 'DeleteResourceModalController',
          clickOutsideToClose: true,
          locals: {
            title: title,
            text: text,
            supportMessage: supportMessage
          }
        });
      };

      return self;
    }
  ]);

  app.controller('DeleteResourceModalController', [
    '$scope',
    '$mdDialog',
    'chatTrackingService',
    'title',
    'text',
    'supportMessage',
    function (
      $scope,
      $mdDialog,
      chatTrackingService,
      title,
      text,
      supportMessage
    ) {
      $scope.data = {
        password: '',
        title: title,
        text: text
      };

      $scope.submitPassword = function () {
        $mdDialog.hide($scope.data.password);
      };

      $scope.cancel = function () {
        $mdDialog.cancel();
      };

      $scope.openSupport = function () {
        $mdDialog.cancel();
        chatTrackingService.showMessage('Support', supportMessage);
      };
    }
  ]);
})(window.angular);
