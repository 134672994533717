/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('residentEvent', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/residentEvents/resident-event.html',
      scope: {
        event: '=',
        resident: '='
      }
    };
  });
})(window.angular);
