/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import { UserManager } from 'oidc-client-ts';

(function (angular) {
  'use strict';

  var app = angular.module('knock-Authentication');

  app.factory('managerAuthModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openManagerAuthModal = function () {
        return $mdDialog.show({
          controller: 'ManagerAuthController',
          templateUrl: '/angular/views/authentication/manager-auth-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true
        });
      };

      return self;
    }
  ]);

  app.controller('ManagerAuthController', [
    '$scope',
    '$location',
    '$mdDialog',
    'authenticationService',
    'localStorageService',
    'LOGIN_UI_BASE_URL',
    'REALPAGE_LOGIN_URL',
    'AUTH_REDIRECT_URL',
    'AUTH_CLIENT_ID',
    'UNIFIED_API_URL',
    'UNIFIED_SCOPE',
    function (
      $scope,
      $location,
      $mdDialog,
      authenticationService,
      localStorageService,
      LOGIN_UI_BASE_URL,
      REALPAGE_LOGIN_URL,
      AUTH_REDIRECT_URL,
      AUTH_CLIENT_ID,
      UNIFIED_API_URL,
      UNIFIED_SCOPE
    ) {
      var self = this;

      self._initialize = async function () {
        if (window.location.search.match(/\?code=/) !== null) {
          await handleOidcCallback();
        }
      };

      const userManager = new UserManager({
        authority: REALPAGE_LOGIN_URL,
        client_id: AUTH_CLIENT_ID,
        redirect_uri: AUTH_REDIRECT_URL,
        response_type: 'code',
        scope: UNIFIED_SCOPE,
        response_mode: 'query'
      });

      function navigateTo(url) {
        $location.url(url);
      }

      $scope.loginUIBaseURL = LOGIN_UI_BASE_URL;

      $scope.form = {
        username: '',
        password: '',
        error: false,
        isLoading: false
      };

      $scope.openRequestManagerAccountModal = function () {
        $mdDialog.cancel();
        authenticationService.openRequestManagerAccountModal();
      };

      $scope.openRenterAuthModal = function () {
        $mdDialog.cancel();
        authenticationService.openRenterAuthModal('login');
      };

      $scope.forgotPassword = function () {
        $mdDialog.cancel();
        authenticationService.openForgotPasswordModal(
          'manager',
          $scope.form.username
        );
      };

      $scope.realPageLogin = function () {
        userManager.stopSilentRenew();
        userManager.clearStaleState();
        userManager.signinRedirect();
      };

      $scope.emailLogin = function () {
        $scope.form.isLoading = true;
        $scope.form.error = false;

        authenticationService
          .emailLogin($scope.form.username, $scope.form.password, 'manager')
          .then(self._emailLoginSuccess, self._emailLoginError)
          .catch(self._emailLoginUnhandledError);
      };

      async function handleOidcCallback() {
        await userManager
          .signinRedirectCallback()
          .then(async (response) => {
            const realPageAccessToken = response['access_token'];
            const { accessToken } = await getKnockAccessToken(
              realPageAccessToken
            );

            window.location.href = `/autologin?token=${accessToken}`;
          })
          .catch((error) => {
            userManager.removeUser();
            console.log('Error :>> ', error);
          });
      }

      async function getKnockAccessToken(access_token) {
        return await fetch(`${UNIFIED_API_URL}/token`, {
          headers: { Authorization: `Bearer ${access_token}` }
        })
          .then((response) => response.json())
          .then((response) => response)
          .catch((error) => {
            console.log('Error :>> ', error);
          });
      }

      self._emailLoginSuccess = function (response) {
        if (response.data.access_token) {
          const sub = JSON.parse(
            window.atob(response.data.access_token.split('.')[1])
          ).sub;

          if (sub.type === 'manager' && sub.role !== 'agent') {
            self._emailLoginError('bad role');
            return;
          }
        }

        $mdDialog.hide();
        authenticationService.handleAuthenticatedRedirection();
      };

      self._emailLoginError = function (response) {
        $scope.form.isLoading = false;
        $scope.form.error = true;

        if (response === 'bad role') {
          $scope.form.errorMessage =
            'Master and admin users cannot login to agent web app!';
          localStorageService.clearAll();
          return;
        }

        const errorMessages = authenticationService.getErrorMessages('login');

        if (authenticationService.isPrivateBrowsingError(response)) {
          $scope.form.errorMessage = errorMessages.privateBrowsingErrorMessage;
          return;
        }

        if (response.status === 401) {
          $scope.form.errorMessage = errorMessages.invalidLoginMessage;
          return;
        } else if (response.status === 403) {
          $scope.form.errorMessage =
            errorMessages.migratedUserLoginErrorMessage;
          return;
        } else if (response.status === 429) {
          $scope.form.errorMessage =
            errorMessages.loginAttemptLimitErrorMessage;
          return;
        } else if (response.status === 409) {
          // Force password reset
          $location.url(
            `/change-password?username=${$scope.form.username}&redirect=/login`
          );
          return;
        }

        $scope.form.errorMessage = errorMessages.errorMessage;
      };

      self._emailLoginUnhandledError = function (response) {
        console.log(`Unhandled login error: ${response}`);
        $scope.form.errorMessage =
          authenticationService.getErrorMessages('login').errorMessage;
      };

      self._initialize();
    }
  ]);
})(window.angular);
