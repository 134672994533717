/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.factory('userInteractionsResidentPackageModalService', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.dialogPromise = null;
      self.isOpen = false;

      self.openModal = function (userForResidentPackage) {
        if (self.isOpen) {
          return;
        }

        self.dialogPromise = $mdDialog.show({
          templateUrl:
            '/angular/views/userInteractions/add-resident-package-modal.html',
          controller: 'UserInteractionsResidentPackageController',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            resident: userForResidentPackage
          }
        });

        self.isOpen = true;

        self.dialogPromise.finally(function () {
          self.isOpen = false;
        });

        return self.dialogPromise;
      };

      return self;
    }
  ]);

  app.controller('UserInteractionsResidentPackageController', [
    '$rootScope',
    '$scope',
    '$mdDialog',
    '$mdToast',
    '$moment',
    'appDataService',
    'conversationsService',
    'userService',
    'userInteractionsService',
    'resident',
    '_',
    function (
      $rootScope,
      $scope,
      $mdDialog,
      $mdToast,
      $moment,
      appDataService,
      conversationsService,
      userService,
      userInteractionsService,
      resident,
      _
    ) {
      var self = this;

      var currentUser = userService.getScopedUser();

      var defaultSmsMessageText =
        'Hi ' +
        resident.profile.first_name +
        '. A new package has arrived for you. Please stop by the leasing office to pick it up.';
      var defaultEmailMessageSubject = 'New Package For You to Pick Up';
      var defaultEmailMessageHtml =
        'Hi ' +
        resident.profile.first_name +
        ',<br>A new package has arrived for you. Please stop by the leasing office to pick it up.<br>Thank you!';

      $scope.views = {
        packages: 'packages',
        messages: 'messages'
      };

      $scope.state = {
        currentView: $scope.views.packages,
        emailMessageHtml: defaultEmailMessageHtml,
        emailMessageSubject: defaultEmailMessageSubject,
        hideEmail: false,
        hideSms: false,
        isAddingResidentPackages: false,
        hasSmsConsent: resident.sms_consent.has_consent,
        maxSmsCharacters: 150,
        packagesData: [],
        resident: resident,
        smsMessageText: defaultSmsMessageText
      };

      function timeFromMinutes(minutes) {
        var adjustedMinutes = Math.floor(minutes / 5) * 5;
        var dayFloor = $moment().startOf('day');

        return dayFloor.add(adjustedMinutes, 'minutes').format('h:mma');
      }

      $scope.packageTypes = ['Amazon', 'UPS', 'USPS', 'Fedex', 'DHL', 'Other'];
      $scope.teamMembers = appDataService.getTeamMembers();
      $scope.timeDropdownValues = _.map(
        _.range(0, 24 * 60, 5),
        timeFromMinutes
      );

      $scope.addPackage = function () {
        var now = $moment();

        var newParcel = {
          type: $scope.packageTypes[0],
          receivedOnDate: now.toDate(),
          receivedOnTime: timeFromMinutes(now.hours() * 60 + now.minutes()), // timeFromMinutes will adjust receivedOnTime to align with dropdown options
          receivedByManagerId: currentUser.id,
          externalPackageId: null
        };

        $scope.state.packagesData.push(newParcel);
      };

      $scope.removeParcel = function (parcelIndex) {
        _.pullAt($scope.state.packagesData, parcelIndex);
      };

      $scope.tryAddResidentPackages = function () {
        $scope.state.isAddingResidentPackages = true;

        _.forEach($scope.state.packagesData, function (packageData) {
          var receivedOnDateString = $moment(packageData.receivedOnDate).format(
            'YYYY-MM-DD'
          );

          packageData.receivedAt = $moment(
            receivedOnDateString + 'T' + packageData.receivedOnTime,
            'YYYY-MM-DDThh:mm a'
          );
        });

        userInteractionsService
          .addNewResidentPackages(resident.id, $scope.state.packagesData)
          .success(self._addNewResidentPackagesSuccess)
          .catch(function () {
            $mdToast.showSimple(
              'An error has occurred, please contact support.'
            );
          })
          .finally(function () {
            $scope.state.isAddingResidentPackages = false;
          });
      };

      $scope.sendResidentPackageSMS = function () {
        $scope.state.isSendingSMS = true;

        conversationsService
          .sendMessage(
            resident.stream_id,
            $scope.state.smsMessageText,
            null,
            null,
            null,
            ['sms']
          )
          .then(
            function () {
              $scope.state.hideSms = true;
              $mdToast.showSimple('Sent SMS!');

              if ($scope.state.hideEmail) {
                $scope.closeModal();
              }
            },
            function () {
              $mdToast.showSimple(
                'An error has occurred, please contact support.'
              );
            }
          )
          .finally(function () {
            $scope.state.isSendingSMS = false;
          });
      };

      $scope.sendResidentPackageEmail = function () {
        $scope.state.isSendingEmail = true;

        conversationsService
          .sendMessage(
            resident.stream_id,
            '',
            $scope.state.emailMessageHtml,
            $scope.state.emailMessageSubject,
            null,
            ['email']
          )
          .then(
            function () {
              $scope.state.hideEmail = true;
              $mdToast.showSimple('Sent email!');

              if ($scope.state.hideSms) {
                $scope.closeModal();
              }
            },
            function () {
              $mdToast.showSimple(
                'An error has occurred, please contact support.'
              );
            }
          )
          .finally(function () {
            $scope.state.isSendingEmail = false;
          });
      };

      $scope.sendResidentSMSConsentRequest = function () {
        var sendConsentRequest = function () {
          $scope.isAddingResidentPackages = true;

          return userInteractionsService
            .sendResidentSMSConsentRequest(resident.id)
            .success(function () {
              $mdToast.showSimple('Consent requests sent!');
            })
            .error(function () {
              $mdToast.showSimple('An error occurred sending consent requests');
            })
            .finally(function () {
              $scope.isAddingResidentPackages = false;
              $scope.closeModal();
            });
        };

        var confirm = $mdDialog
          .confirm()
          .title(
            'Confirm sending SMS consent request to ' +
              resident.profile.first_name +
              ' ' +
              resident.profile.last_name +
              '?'
          )
          .ok('Confirm')
          .cancel('Cancel');

        $mdDialog
          .show(confirm)
          .then(sendConsentRequest)
          .catch(self._cancelBulkSMSConsentRequestMode);
      };

      $scope.closeModal = function () {
        $mdDialog.cancel();
      };

      self._addNewResidentPackagesSuccess = function (response) {
        $scope.state.isAddingResidentPackages = false;
        $scope.state.currentView = $scope.views.messages;

        $rootScope.$emit('residentPackagesAdded', response.resident_packages);
        $mdToast.showSimple('Resident packages saved!');
      };

      self._initialize = function () {
        $scope.state.newResidentPackage = {
          message: '',
          reminderTime: null
        };

        $scope.addPackage();
      };

      self._initialize();
    }
  ]);
})(window.angular);
