import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Chip, TextField } from '@material-ui/core';
import useUnits from 'app/services/units/hooks';
import { makeStyles, NamedColors } from '@knockrentals/knock-shared-web';
import { colors } from '../../commonStyles/commonStyles';
import { Autocomplete } from '@material-ui/lab';
import { UnitType } from '../Availability/types';
import { useCompleteDrawerContext } from './CompleteTourDrawerContextProvider';

interface Option {
  value: string;
  label: string;
  name: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  formLabel: {
    top: 20,
    fontWeight: 400,
    fontSize: 12
  },
  menuItemHover: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  errorText: {
    color: theme.palette.error.main
  },
  popoverContent: {},
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '98%',
    paddingTop: '20px'
  },
  keyboardArrow: {
    color: '#5C5C5C',
    margin: '6px 3px 6px 3px'
  },
  fixedFooter: {
    position: 'sticky',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '12px'
  },
  textField: {
    width: '100%',
    marginBottom: 0,

    '& .MuiInputBase-root': {
      border: `none`,
      color: colors.defaultText,
      backgroundColor: '#FAFAFA',
      height: 'auto',
      minHeight: '58px',

      '&.MuiInputLabel-root.Mui-focused': {
        color: colors.secondaryText,
        backgroundColor: 'blue',
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: '0.15px'
      }
    },

    '& .MuiInputLabel-formControl.MuiInputLabel-filled.MuiInputLabel-shrink': {
      color: colors.secondaryText
    },

    '& .MuiFilledInput-underline:after': {
      borderBottom: `1px solid ${NamedColors.slate[300]}`
    },

    '& .MuiFilledInput-underline:before': {
      borderBottom: `1px solid ${NamedColors.slate[300]}`
    },

    '& .MuiInputLabel-formControl': {
      top: '26px',
      left: '0',
      color: colors.disabled,
      fontSize: '16px',
      fontWeight: 400
    },

    '& .MuiSvgIcon-root': {
      color: NamedColors.denim[500],
      height: '16px !important',
      width: '16px !important'
    },

    '& .MuiChip-deleteIcon': {
      color: NamedColors.denim[500],
      height: '16px !important',
      width: '16px !important'
    }
  },
  textFieldError: {
    width: '100%',
    marginBottom: 0,

    '& .MuiInputBase-root': {
      border: `none`,
      color: colors.defaultText,
      backgroundColor: '#FAFAFA',
      height: 'auto',
      minHeight: '58px',

      '&.MuiInputLabel-root.Mui-focused': {
        color: colors.secondaryText,
        backgroundColor: 'blue',
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: '0.15px'
      }
    },

    '& .MuiInputLabel-formControl.MuiInputLabel-filled.MuiInputLabel-shrink': {
      color: colors.secondaryText
    },

    '& .MuiFilledInput-underline:after': {
      borderBottom: `2px solid ${NamedColors.carrot[600]}`
    },

    '& .MuiFilledInput-underline:before': {
      borderBottom: `2px solid ${NamedColors.carrot[600]}`
    },

    '& .MuiInputLabel-formControl': {
      top: '26px',
      left: '0',
      color: colors.disabled,
      fontSize: '16px',
      fontWeight: 400
    },

    '& .MuiSvgIcon-root': {
      color: NamedColors.denim[500],
      height: '16px !important',
      width: '16px !important'
    },

    '& .MuiChip-deleteIcon': {
      color: NamedColors.denim[500],
      height: '16px !important',
      width: '16px !important'
    }
  },
  formLabelFocused: {
    top: '10px !important',
    left: '10px !important',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.15px'
  },
  textFieldActive: {
    '& .MuiInputBase-root': {
      borderBottom: `1px solid blue !important`,
      backgroundColor: NamedColors.denim[50]
    }
  },
  deletableColorPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  deleteIconSmall: {
    width: '16px !important',
    height: '16px !important',
    color: NamedColors.denim[500]
  },
  popperContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,
    maxWidth: '100%',
    '& .MuiPaper-root': {
      width: '630px !important',
      marginTop: '0px',
      borderBottom: `1px solid ${NamedColors.slate[50]}`
    }
  },
  autocomplete: {
    '& .MuiAutocomplete-clearIndicatorDirty': {
      color: NamedColors.denim[500]
    },
    '& .MuiAutocomplete-option': {
      backgroundColor: 'transparent'
    },
    '& .MuiAutocomplete-option.Mui-selected': {
      backgroundColor: NamedColors.slate[100]
    },
    '& .MuiAutocomplete-option.Mui-focusVisible': {
      backgroundColor: NamedColors.slate[50]
    },
    '& .MuiAutocomplete-option:hover': {
      backgroundColor: NamedColors.slate[50]
    }
  },
  selectedOption: {
    backgroundColor: 'transparent !important'
  }
}));

const ShowUnitsStep: React.FC = () => {
  const {
    infoPanelData: { tourPropertyId: propertyId },
    setData,
    finishButtonDisableClicked,
    step,
    showRequiredUnitError
  } = useCompleteDrawerContext();
  const { units } = useUnits(propertyId);
  const classes = useStyles();
  const [unitOptions, setUnitOptions] = useState<Option[]>([]);
  const [selectedUnits, setSelectedUnits] = useState<Option[]>([]);

  useEffect(() => {
    if (units?.data?.length > 0) {
      const unitOption = units?.data?.map((unit: UnitType) => {
        return {
          value: unit.id,
          label: `${unit.name}${
            unit.layoutName ? ` - ${unit.layoutName}` : ''
          }${unit.bedrooms ? ` - ${unit.bedrooms}bd` : ''} ${
            unit.bathrooms ? ` ${unit.bathrooms}ba` : ''
          }${unit.area ? ` - ${unit.area}sqft` : ''}${
            unit.type ? ` - ${unit.type}` : ''
          }`,
          ...unit
        };
      });
      setUnitOptions(unitOption);
    }
  }, [units]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      // This block will be executed on subsequent renders, not on the first render
      if (selectedUnits.length === 0) {
        setData({ shownUnits: selectedUnits });
      }
    } else {
      isFirstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnits]);

  const handleChange = (_: any, newValue: Option[]) => {
    setData({ shownUnits: newValue.map((e) => e.value) });
  };

  return (
    <form
      className={classes.container}
      data-testid="shown-units-step"
      onSubmit={(e) => e.preventDefault()}
    >
      <Autocomplete
        multiple
        id="units"
        className={classes.autocomplete}
        classes={{ option: classes.selectedOption }}
        options={unitOptions}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        onChange={handleChange}
        renderInput={(params) => (
          <div>
            <TextField
              {...params}
              variant="filled"
              className={
                (finishButtonDisableClicked &&
                  selectedUnits.length === 0 &&
                  step === 1) ||
                showRequiredUnitError
                  ? classes.textFieldError
                  : classes.textField
              }
              label="Units"
            />
          </div>
        )}
        renderTags={(value: Option[], getTagProps) =>
          value.map((option: Option, index: number) => {
            const tagProps = getTagProps({ index });
            return (
              <Chip
                key={index}
                label={option.name}
                size="small"
                onDelete={() => {
                  const updatedUnits = value.filter(
                    (selectedUnit) => selectedUnit.value !== option.value
                  );
                  setSelectedUnits(updatedUnits);
                }}
                {...tagProps}
              />
            );
          })
        }
        getOptionSelected={(option, value) => option.value === value.value}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox checked={selected} color="primary" />
            {option.label}
          </React.Fragment>
        )}
        style={{ width: '100%' }}
      />
    </form>
  );
};

export default ShowUnitsStep;
