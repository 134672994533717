/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import sortBy from 'lodash/sortBy';
import reduce from 'lodash/reduce';
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.directive('teamMemberSelect', function () {
    return {
      restrict: 'E',
      template:
        '<md-input-container>' +
        '<label>{{ label || "Team Member" }}</label>' +
        '<md-select ng-model="ngModel" ng-change="managerChanged()">' +
        '<md-option ng-repeat="teamMember in teamMembers" ng-value="{{teamMember.Manager.id}}">{{teamMember.ManagerInfo.first_name}} {{teamMember.ManagerInfo.last_name}}</md-option>' +
        '</md-select>' +
        '</md-input-container>',
      scope: {
        ngModel: '=',
        onChanged: '=?',
        label: '=?'
      },
      replace: true,
      controller: [
        '$scope',
        'appDataService',
        function ($scope, appDataService) {
          var self = this;

          $scope.onChanged = $scope.onChanged || function () {};

          $scope.managerChanged = function () {
            $scope.onChanged($scope.ngModel);
          };

          self._initialize = function () {
            self._setTeamMembers();
          };

          self._setTeamMembers = function () {
            var teamMembers = reduce(
              appDataService.getTeamMembers(),
              function (result, member) {
                result[member.Manager.id] = member;

                return result;
              },
              {}
            );

            $scope.teamMembers = sortBy(teamMembers, function (teamMember) {
              return teamMember.ManagerInfo.first_name;
            });
          };

          self._initialize();
        }
      ]
    };
  });
})(window.angular);
