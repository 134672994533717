/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
require('../../core/scripts/user/profile.service');
require('../../core/scripts/user/logout.controller');
require('../../core/scripts/user/navbar.controller');
require('../../core/scripts/user/userDisplay.directives');
require('../../core/scripts/user/activateRenterAccount.controller');
require('../../core/scripts/user/pastNotifications.controller');
require('../../core/scripts/user/resetForgottenPassword.controller');

require('../../core/scripts/user/accessControl.service');
require('../../core/scripts/user/userInfoCard.directive');

require('../../core/scripts/user/appointmentRequirementsModal/appointmentRequirementsModal.service');
require('../../core/scripts/user/appointmentRequirementsModal/appointmentRequirementsModal.controller');

require('../../core/scripts/signup/signup.service');
require('../../core/scripts/signup/renterSignup.directive');
require('../../core/scripts/signup/managerSignup.directive');

require('../../core/scripts/signup/signupModal.service');
require('../../core/scripts/signup/signupModal.controller');

require('../../core/scripts/user/upgradeModal/upgradeModal.controller');
require('../../core/scripts/user/upgradeModal/upgradeModal.service');

require('../../core/scripts/user/userPhoneModal/userPhoneModal.service');
require('../../core/scripts/user/userPhoneModal/userPhoneModal.controller');

require('../../core/scripts/user/userListingCard.directive');
