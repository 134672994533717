import React, { FC } from 'react';

import { ThemeProvider } from '@knockrentals/knock-shared-web';
import { Box, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HotLeadIcon } from '../../icons';
import { colors } from '../../commonStyles/commonStyles';
import DarkTooltip from '../../Tooltips/DarkTooltip';

interface Prospect {
  id: number;
  name: string;
  hotLead?: boolean;
}
interface ProspectListProps {
  prospects: Prospect[];
  isDemandXDemoEnabled?: boolean;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    fontWeight: 400
  },
  hotLeadText: {
    fontWeight: 700,
    marginLeft: '3px',
    whiteSpace: 'nowrap'
  },
  namesContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  hotLeadIcon: {
    stroke: colors.defaultText
  }
});

const ProspectList: FC<ProspectListProps> = ({
  prospects,
  isDemandXDemoEnabled
}) => {
  const { container, hotLeadText, namesContainer, hotLeadIcon } = useStyles();

  if (isDemandXDemoEnabled) {
    const newProspectsDemandXDemoKeys = Object.keys(prospects);
    if (newProspectsDemandXDemoKeys.length > 0) {
      for (let i = 0; i < newProspectsDemandXDemoKeys.length; i++) {
        if (prospects[i].name.toLowerCase().startsWith('k')) {
          prospects[i].hotLead = true;
        }
      }
    }
    prospects = prospects.sort((prospectA, prospectB) => {
      if (prospectA.hotLead && prospectB.hotLead) {
        return 0;
      }
      if (prospectA.hotLead && !prospectB.hotLead) {
        return -1;
      }
      return 1;
    });
  }

  return (
    <Box className={container}>
      {prospects.slice(0, 2).map((prospect: Prospect, index: number) => {
        return (
          <Box key={prospect.id} className={namesContainer}>
            {prospect.hotLead && (
              <DarkTooltip
                data-testid="hot-lead-tooltip"
                title="Hot Lead"
                aria-label="hot-lead"
                arrow
              >
                <SvgIcon fontSize="small" className={hotLeadIcon}>
                  <HotLeadIcon />
                </SvgIcon>
              </DarkTooltip>
            )}
            <Box
              component="span"
              className={prospect.hotLead ? hotLeadText : ''}
            >
              {prospect.name}
            </Box>
            {prospects.length > 2 || (index === 0 && prospects.length > 1)
              ? ','
              : ''}
            &nbsp;
          </Box>
        );
      })}
      {prospects.length > 2 && (
        <Box className={namesContainer}>and {prospects.length - 2} more</Box>
      )}
    </Box>
  );
};

export default (props: ProspectListProps) => (
  <ThemeProvider>
    <ProspectList {...props} />
  </ThemeProvider>
);
