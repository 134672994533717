import React, { FC, useEffect } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  alertBox: {
    position: 'absolute',
    zIndex: 5,
    justifyContent: 'center',
    marginLeft: '330px',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    borderRadius: '10px',
    '& .MuiAlert-icon': {
      marginTop: '5px'
    }
  },
  alertTitle: {
    marginTop: '5px'
  }
});

interface unitLinkAlertProps {
  unitName: string | undefined;
  closeAlert: () => void;
}

const UnitLinkAlert: FC<unitLinkAlertProps> = ({ unitName, closeAlert }) => {
  const classes = useStyles();

  useEffect(() => {
    const timeId = setTimeout(() => {
      closeAlert();
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [closeAlert]);

  return (
    <Alert className={classes.alertBox} severity="success">
      <AlertTitle className={classes.alertTitle}>
        Unit {unitName} link copied to clipboard!
      </AlertTitle>
    </Alert>
  );
};

export default UnitLinkAlert;
