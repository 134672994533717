/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectEventCreationSource', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/prospectEvents/prospect-event-creation-source.html',
      scope: {
        event: '='
      },
      controller: ['$scope', function ($scope) {}]
    };
  });
})(window.angular);
