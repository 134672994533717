import React, { FC } from 'react';

export const PhoneActionIcon: FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1495_17871)">
      <path
        d="M10.6875 11.8125V15.3885C10.6875 15.6576 10.6345 15.9241 10.5315 16.1728C10.4284 16.4214 10.2774 16.6473 10.0871 16.8376C9.89675 17.0279 9.67078 17.1788 9.4221 17.2817C9.17342 17.3847 8.90689 17.4376 8.63775 17.4375H3.73725C3.46811 17.4376 3.20158 17.3847 2.9529 17.2817C2.70422 17.1788 2.47825 17.0279 2.2879 16.8376C2.09756 16.6473 1.94656 16.4214 1.84354 16.1728C1.74052 15.9241 1.6875 15.6576 1.6875 15.3885V3.73725C1.6875 3.19362 1.90346 2.67226 2.28786 2.28786C2.67226 1.90346 3.19362 1.6875 3.73725 1.6875H5.0625"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6875 14.0625H10.6875"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1875 0.5625H8.4375C7.81618 0.5625 7.3125 1.06618 7.3125 1.6875V8.4375C7.3125 9.05882 7.81618 9.5625 8.4375 9.5625H15.1875C15.8088 9.5625 16.3125 9.05882 16.3125 8.4375V1.6875C16.3125 1.06618 15.8088 0.5625 15.1875 0.5625Z"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5625 2.8125H14.0625"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5625 5.0625H14.0625"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5625 7.3125H11.25"
        stroke="#353950"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1495_17871">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
