/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { Box, Chip } from '@material-ui/core';

import {
  makeStyles,
  NamedColors,
  Text,
  Tooltip
} from '@knockrentals/knock-shared-web';
import dayjs from 'dayjs';
import { colors } from '../../commonStyles/commonStyles';
import {
  differnceInDateFromToday,
  displayPrice,
  truncateString
} from '../../../utilities/userUtilities';
import Table, { ColumnType } from '../../Table';
import { BuildingType, MenuOption, ProspectType, UnitType } from './types';
import ActionButton from './ActionButton';
import { QuoteIcon, UnitLinkIcon } from '../../icons';
import {
  availDateCheck,
  checkBedValueWithUnit,
  checkFloorplanMatch,
  checkUnitValueInBudget
} from './Utils';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    '& .MuiPaper-root': {
      flex: 1,
      display: 'flex',

      '& .MuiTableContainer-root': {
        flex: 1
      }
    }
  },

  actionColumn: {
    display: 'flex'
  },

  subTitle: {
    color: colors.disabled,
    fontSize: '12px',
    fontWeight: 600,
    paddingTop: '20px'
  },

  noMatchChip: {
    background: '#FFE7DA',
    color: NamedColors.carrot[900],
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '13px'
    },
    letterSpacing: '0.16px'
  },

  closeMatchChip: {
    background: NamedColors.banana[100],
    color: NamedColors.banana[900],
    letterSpacing: '0.16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px !important'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '13px'
    }
  },

  goodMatchChip: {
    background: '#DCF6EE',
    color: NamedColors.grass[900],
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '13px'
    },
    letterSpacing: '0.16px'
  },

  greatMatchChip: {
    background: '#DBF0FE',
    color: NamedColors.sky[900],
    letterSpacing: '0.16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10px'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '13px'
    }
  },

  chip: {
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',
      '& .MuiChip-label': {
        paddingLeft: '4px',
        paddingRight: '4px'
      }
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '8px',
      '& .MuiChip-label': {
        paddingLeft: '4px',
        paddingRight: '4px'
      }
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '10px',
      '& .MuiChip-label': {
        paddingLeft: '6px',
        paddingRight: '6px'
      }
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '10px',
      '& .MuiChip-label': {
        paddingLeft: '8px',
        paddingRight: '8px'
      }
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '14px',
      '& .MuiChip-label': {
        paddingLeft: '10px',
        paddingRight: '10px'
      }
    }
  },

  availableStatusChip: {
    backgroundColor: NamedColors.grass[100],
    color: NamedColors.grass[900]
  },

  leasedStatusChip: {
    backgroundColor: NamedColors.apple[100],
    color: NamedColors.apple[900]
  },

  reservedStatusChip: {
    backgroundColor: NamedColors.banana[100],
    color: NamedColors.banana[900]
  },

  matchedChip: {
    backgroundColor: NamedColors.violet[100],
    color: NamedColors.violet[900]
  },

  tooltip: {
    fontSize: '10px',
    fontWeight: 600,
    padding: '4px 8px',
    top: '-10px'
  },

  unmatchedChip: {
    backgroundColor: NamedColors.slate[50],
    color: NamedColors.slate[900]
  }
}));

interface AvailabilityTableProps {
  units: UnitType[];
  loading: boolean;
  buildings: BuildingType;
  prospect: ProspectType;
  onCopyUnitLink: (unit: UnitType) => void;
  onCreateQuote: (unit: UnitType) => void;
  filteredData: UnitType[];
  filterState: MenuOption[];
  propertyFilter: MenuOption[];
}

const AvailabilityTable: FC<AvailabilityTableProps> = ({
  units,
  loading,
  buildings,
  prospect,
  onCopyUnitLink,
  onCreateQuote,
  filteredData,
  filterState,
  propertyFilter
}) => {
  const classes = useStyles();
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [rows, setRows] = useState<UnitType[]>([...filteredData]);

  const setInitialSorting = (units: UnitType[]) => {
    if (rows?.length === 0) {
      return;
    }

    const hasNonUndeterminedMatch = units.some((unit: UnitType) => {
      return unit.match !== 'Undetermined';
    });

    const columnToSort = hasNonUndeterminedMatch ? 'match' : 'name';
    setSortColumn(columnToSort);
    const sortedUnits = [...units].sort((a: UnitType, b: UnitType) => {
      return compareValues(a, b, columnToSort);
    });
    return sortedUnits;
  };

  useEffect(() => {
    const sortedfilteredData = setInitialSorting(filteredData);
    setRows(sortedfilteredData ? sortedfilteredData : filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  const compareValues = (a: any, b: any, columnId: string) => {
    const handleEmptyValues = (aValue: any, bValue: any) => {
      if (aValue === '-' || aValue === '' || !aValue) return 1;
      if (bValue === '-' || bValue === '' || !bValue) return -1;
      return 0;
    };

    switch (columnId) {
      case 'match':
        const matchOrder: any = {
          'Great Match': 1,
          'Good Match': 2,
          'Close Match': 3,
          'No Match': 4
        };
        return (matchOrder[a.match] || 5) - (matchOrder[b.match] || 5);

      case 'name':
        const aName = isNaN(a.name) ? a.name || '' : a.name;
        const bName = isNaN(b.name) ? b.name || '' : b.name;

        // Compare alphanumerically
        const isANumber = !isNaN(aName) && !isNaN(bName);

        if (isANumber) {
          // If both are numbers, sort them as numbers
          return aName - bName;
        } else {
          // If either or both are not numbers, sort them as strings
          return aName.localeCompare(bName);
        }

      case 'displayPrice':
        return (
          handleEmptyValues(a.displayPrice, b.displayPrice) ||
          Number(a.displayPrice) - Number(b.displayPrice)
        );

      case 'bedrooms':
        return (
          handleEmptyValues(a.bedrooms, b.bedrooms) ||
          Number(a.bedrooms) - Number(b.bedrooms)
        );

      case 'bathrooms':
        return (
          handleEmptyValues(a.bathrooms, b.bathrooms) ||
          Number(a.bathrooms) - Number(b.bathrooms)
        );

      case 'area':
        return (
          handleEmptyValues(a.area, b.area) || Number(a.area) - Number(b.area)
        );

      case 'availableOn':
        return (a['availableOn'] || '').localeCompare(b['availableOn'] || '');

      case 'status':
        return (a.unitStatus || '').localeCompare(b.unitStatus || '');

      case 'bldg':
        return (
          handleEmptyValues(a.bldg, b.bldg) ||
          String(a.bldg || '').localeCompare(String(b.bldg || ''))
        );

      case 'occupied':
        return (a.occupiedStatus || '').localeCompare(b.occupiedStatus || '');

      case 'noticeGiven':
        return (
          handleEmptyValues(a.noticeStatus, b.noticeStatus) ||
          (a.noticeStatus || '').localeCompare(b.noticeStatus || '')
        );

      case 'layoutName':
        return (
          handleEmptyValues(a.layoutName, b.layoutName) ||
          (a.layoutName || '').localeCompare(b.layoutName || '')
        );

      default:
        return (a[columnId] || '').localeCompare(b[columnId] || '');
    }
  };

  const handleCopyUnitLink = (unit: UnitType) => {
    onCopyUnitLink(unit);
  };

  const handleCreateQuote = (unit: UnitType) => {
    onCreateQuote(unit);
  };

  const handleSort = (columnId: string) => {
    if (columnId) {
      const unitstoSort = rows?.length > 0 ? rows : units;
      if (sortColumn === columnId) {
        const sortedUnits = [...unitstoSort].sort((a, b) => {
          const comparison = compareValues(a, b, columnId);
          return sortOrder === 'asc' ? -comparison : comparison;
        });
        setRows(sortedUnits);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setSortOrder('asc');
        setSortColumn(columnId);
        const sortedUnits = [...unitstoSort].sort((a, b) => {
          return compareValues(a, b, columnId);
        });
        setRows(sortedUnits);
      }
    }
  };

  const renderMatchedChip = (value: string) => {
    return (
      <Tooltip
        title="Preference Match"
        arrow
        classes={{ tooltip: classes.tooltip }}
      >
        <Chip
          label={value}
          className={`${classes.chip} ${classes.matchedChip}`}
        />
      </Tooltip>
    );
  };

  const renderUnmatchedChip = (value: string) => {
    return (
      <Chip
        label={value}
        className={`${classes.chip} ${classes.unmatchedChip}`}
      />
    );
  };

  const showDate = (date: string) => {
    if (differnceInDateFromToday(date) <= 0) {
      return 'Today';
    } else {
      return dayjs(date).format('MM/DD/YYYY');
    }
  };

  let bedroomPreferences: Number[] = [];

  if (prospect && prospect.preferences?.bedrooms) {
    bedroomPreferences = prospect.preferences?.bedrooms.map(
      (bedroom: string) => {
        const str = bedroom.split('bd');
        return Number(str[0]);
      }
    );
  }

  const displayableStatus = (unit: UnitType) => {
    if (unit.leased) {
      unit.unitStatus = 'Leased';
      return (
        <Chip
          label="Leased"
          className={`${classes.chip} ${classes.leasedStatusChip}`}
        />
      );
    }
    if (unit.available) {
      unit.unitStatus = 'Available';
      return (
        <Chip
          label="Available"
          className={`${classes.chip} ${classes.availableStatusChip}`}
        />
      );
    }
    if (unit.reserved) {
      unit.unitStatus = 'Reserved';
      return (
        <Chip
          label="Reserved"
          className={`${classes.chip} ${classes.reservedStatusChip}`}
        />
      );
    }
    unit.unitStatus = 'Other';
    return renderUnmatchedChip('Other');
  };
  const propertyColumn =
    propertyFilter?.length > 1 || propertyFilter?.length === 0
      ? {
          id: 'property',
          label: 'Property',
          value: (unit: UnitType) => {
            return unit?.propertyName ?? '';
          }
        }
      : null;

  const columns: ColumnType[] = [
    {
      id: 'action',
      label: 'Actions',
      value: (unit: UnitType) => {
        const quoteActionTooltip = unit.hasGuestCard
          ? 'Create Quote'
          : 'Refer in order to create a quote';

        return (
          <span className={classes.actionColumn}>
            <ActionButton
              onClick={() => handleCopyUnitLink(unit)}
              tooltipText="Copy Unit Link"
            >
              <UnitLinkIcon />
            </ActionButton>

            {!(unit.reserved || unit.leased) && unit.available && (
              <ActionButton
                onClick={() => handleCreateQuote(unit)}
                tooltipText={quoteActionTooltip}
              >
                <QuoteIcon />
              </ActionButton>
            )}
          </span>
        );
      }
    },
    {
      id: 'name',
      sortable: true,
      label: 'Unit',
      value: (unit: UnitType) => unit.name || '-'
    },
    {
      id: 'match',
      sortable: true,
      label: 'Match',
      value: (unit: UnitType) => {
        if (!prospect) {
          return '-';
        }

        if (unit.preferenceUndeterminedStatus) {
          unit.match = 'Undetermined';
          return <Chip label="Undetermined" className={classes.chip} />;
        } else {
          const matchCount = unit.preferenceMatchCount || 0;
          let chip = <></>;

          if (matchCount === 4) {
            unit.match = 'Great Match';
            chip = (
              <Chip
                className={classes.greatMatchChip}
                label="Great Match"
              ></Chip>
            );
          } else if (matchCount === 3 || matchCount === 2) {
            unit.match = 'Good Match';
            chip = (
              <Chip className={classes.goodMatchChip} label="Good Match"></Chip>
            );
          } else if (matchCount === 1) {
            unit.match = 'Close Match';
            chip = (
              <Chip
                className={classes.closeMatchChip}
                label="Close Match"
              ></Chip>
            );
          } else if (matchCount === 0) {
            unit.match = 'No Match';
            chip = (
              <Chip className={classes.noMatchChip} label="No Match"></Chip>
            );
          }

          return chip;
        }
      }
    },
    {
      id: 'displayPrice',
      sortable: true,
      label: 'Price',
      value: (unit: UnitType) => {
        const isUnitInBudget = checkUnitValueInBudget(unit, prospect);
        const value =
          unit.displayPrice && Number(unit.displayPrice) > 0
            ? displayPrice(Number(unit.displayPrice))
            : '-';
        return isUnitInBudget
          ? renderMatchedChip(value)
          : renderUnmatchedChip(value);
      }
    },
    {
      id: 'bedrooms',
      sortable: true,
      label: 'Beds',
      value: (unit: UnitType) => {
        const isBedCountSame = checkBedValueWithUnit(unit, bedroomPreferences);
        const value = unit.bedrooms ? `${unit.bedrooms} bd` : '-';
        return isBedCountSame
          ? renderMatchedChip(value)
          : renderUnmatchedChip(value);
      }
    },
    {
      id: 'bathrooms',
      sortable: true,
      label: 'Baths',
      value: (unit: UnitType) => (unit.bathrooms ? `${unit.bathrooms} ba` : '-')
    },
    {
      id: 'area',
      sortable: true,
      label: 'Sq Ft',
      value: (unit: UnitType) => unit.area || '-'
    },
    {
      id: 'layoutName',
      sortable: true,
      label: 'Floorplan',
      value: (unit: UnitType) => {
        const isFloorPlanMatch = checkFloorplanMatch(unit, prospect);
        const value = unit.layoutName
          ? truncateString(unit.layoutName, 14)
          : '-';
        return isFloorPlanMatch
          ? renderMatchedChip(value)
          : renderUnmatchedChip(value);
      }
    },
    {
      id: 'bldg',
      sortable: true,
      label: 'Bldg',
      value: (unit: UnitType) => {
        let building = '-';
        if (unit.buildingId) {
          building = buildings[unit.buildingId] || '-';
          unit.bldg = buildings[unit.buildingId] || '-';
        }
        return building;
      }
    },
    {
      id: 'availableOn',
      sortable: true,
      label: 'Avail Date',
      value: (unit: UnitType) => {
        const isAvailDateMatch = availDateCheck(unit, prospect);
        const value = unit.availableOn ? showDate(unit.availableOn) : `-`;
        return isAvailDateMatch
          ? renderMatchedChip(value)
          : renderUnmatchedChip(value);
      }
    },
    {
      id: 'status',
      sortable: true,
      label: 'Status',
      value: (unit: UnitType) => displayableStatus(unit)
    },
    {
      id: 'occupied',
      sortable: true,
      label: 'Occupancy',
      value: (unit: UnitType) => {
        unit.occupiedStatus = unit.occupied ? 'Occupied' : 'Vacant';
        return unit.occupied ? 'Occupied' : 'Vacant';
      }
    },
    {
      id: 'noticeGiven',
      sortable: true,
      label: 'Notice',
      value: (unit: UnitType) => {
        unit.noticeStatus = unit.noticeGiven ? 'On Notice' : '-';
        return unit.noticeGiven ? 'On Notice' : '-';
      }
    }
  ];

  if (propertyColumn) {
    columns.splice(1, 0, propertyColumn);
  }

  return (
    <Box className={classes.container}>
      <Text className={classes.subTitle} data-testid="all-units-title">
        ALL UNITS
      </Text>
      <Table
        columns={columns}
        loading={loading}
        rows={rows}
        handleSort={handleSort}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
      />
    </Box>
  );
};
export default AvailabilityTable;
