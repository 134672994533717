import React, { ChangeEvent, FC, useMemo } from 'react';
import { TableRow } from '@material-ui/core';

import { makeStyles, NamedColors } from '@knockrentals/knock-shared-web';
import SelectionCell from './SelectionCell';
import ProspectCells from './ProspectCells';
import { useAppDispatch } from '../../../../../app/hooks';
import {
  addSelectedProspect,
  removeSelectedProspect
} from '../../../../../app/features/prospects/selectedProspects';
import { Prospect } from '../../../../../app/services/prospects/entities';

const useStyles = makeStyles(() => ({
  selectedRow: {
    backgroundColor: NamedColors.denim[50]
  }
}));

interface ProspectRowProps {
  prospect: Prospect;
  selected: boolean;
  isHotLead: boolean;
}

const ProspectRow: FC<ProspectRowProps> = ({
  prospect,
  selected,
  isHotLead
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  return useMemo(() => {
    const handleSelectRow = (event: ChangeEvent<HTMLInputElement>) => {
      const prospectId = event.target.attributes.getNamedItem('id')?.value;

      if (prospectId) {
        if (event.target.checked) {
          dispatch(addSelectedProspect(prospectId));
        } else {
          dispatch(removeSelectedProspect(prospectId));
        }
      }
    };

    const label = `select-prospect-${prospect.profile.fullName}`;

    return (
      <TableRow
        key={prospect.id}
        className={selected ? classes.selectedRow : undefined}
        data-testid="prospect-row"
      >
        <SelectionCell
          id={prospect.id.toString()}
          label={label}
          selected={selected}
          onSelect={handleSelectRow}
        />
        <ProspectCells
          prospect={prospect}
          selected={selected}
          isHotLead={isHotLead}
        />
      </TableRow>
    );
  }, [prospect, selected, classes.selectedRow, dispatch, isHotLead]);
};

export default ProspectRow;
