import React, { FC } from 'react';

import { Button } from '@knockrentals/knock-shared-web';
import { Tooltip } from '../Tooltip';
import './_ConnectedProfilesButton.scss';

interface Props {
  buttonText?: string;
  handleOnClick?: () => void;
  tooltipText: string;
}

export const ConnectedProfilesButton: FC<Props> = ({
  buttonText,
  handleOnClick = () => {},
  tooltipText
}) => (
  <Tooltip title={tooltipText}>
    <span>
      <Button
        className="connected-profiles-button"
        disableRipple
        name="connectedProfilesButton"
        onClick={handleOnClick}
        title={'connected-profiles-button'}
        variant="contained"
      >
        {buttonText}
      </Button>
    </span>
  </Tooltip>
);
