import React, { FC } from 'react';

export const InactiveShareBrochureIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11.25 5.62305H22.272C22.8096 5.62249 23.2467 6.05642 23.25 6.59405V21.153C23.2462 21.6903 22.8092 22.1236 22.272 22.123H1.728C1.19075 22.1236 0.753849 21.6903 0.75 21.153V6.59405C0.753301 6.05642 1.19036 5.62249 1.728 5.62305H5.25M12 12.373H20.25M6.75 16.873H20.25M3.75 5.62305V4.12305C3.75 2.88041 4.75736 1.87305 6 1.87305C7.24264 1.87305 8.25 2.88041 8.25 4.12305V10.873C8.25 11.7015 7.57843 12.373 6.75 12.373C5.92157 12.373 5.25 11.7015 5.25 10.873V8.62305"
      stroke="#999999"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
