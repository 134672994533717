require('../../core/scripts/residents/resident.service');
require('../../core/scripts/residents/residentStatus.filter');
require('../../core/scripts/residentHistory/residentHistory.module');
require('../../core/scripts/residents/residentReportPage.controller');
require('../../core/scripts/residents/residentPage.controller');
require('../../core/scripts/residents/residentReportTable.controller');
require('../../core/scripts/residents/residentReportStages.controller');
require('../../core/scripts/residents/residentReportKanban.directive');
require('../../core/scripts/residents/residentCard.directive');
require('../../core/scripts/residentEvents/residentEvent.directive');
require('../../core/scripts/residentEvents/residentEventDelivery.directive');
require('../../core/scripts/residentEvents/residentEventReminder.directive');
require('../../core/scripts/residents/userList.filter');
require('../../core//scripts/propertyReports/propertyReportPagination.controller');
