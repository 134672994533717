/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.service('viewReviewModalService', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function (
        review,
        appointment,
        manager,
        renter,
        listing
      ) {
        if (!review) {
          throw 'review is required';
        }

        if (!appointment) {
          throw 'appointment is required';
        }

        if (!manager) {
          throw 'manager is required';
        }

        if (!renter) {
          throw 'renter is required';
        }

        $mdDialog.show({
          controller: 'ViewReviewModalController',
          templateUrl: '/angular/views/reviews/view-review-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            appointment: appointment,
            review: review,
            listing: listing,
            manager: manager,
            renter: renter
          }
        });
      };

      return self;
    }
  ]);

  app.controller('ViewReviewModalController', [
    '$scope',
    '$window',
    '$mdDialog',
    'listingApi',
    'renterProfileModalService',
    'appointment',
    'review',
    'manager',
    'renter',
    'listing',
    function (
      $scope,
      $window,
      $mdDialog,
      listingApi,
      renterProfileModalService,
      appointment,
      review,
      manager,
      renter,
      listing
    ) {
      $scope.listing = listing;
      $scope.manager = manager;
      $scope.renter = renter;
      $scope.review = review;
      $scope.appointment = appointment;

      if (!$scope.listing) {
        $scope.isLoadingListing = true;

        listingApi
          .getListing(appointment.listing_id)
          .success(function (response) {
            $scope.listing = response.listing;
          })
          .finally(function () {
            $scope.isLoadingListing = false;
          });
      }

      $scope.reviewSections = {
        price: {
          label: 'Price',
          verdict: $scope.review.price
        },
        location: {
          label: 'Location',
          verdict: $scope.review.location
        },
        condition: {
          label: 'Condition',
          verdict: $scope.review.condition
        },
        amenities: {
          label: 'Amenities',
          verdict: $scope.review.amenities
        },
        layout: {
          label: 'Layout',
          verdict: $scope.review.layout
        }
      };

      $scope.openRenterProfileModal = function () {
        if ($scope.listing) {
          renterProfileModalService.openModal($scope.renter, $scope.listing.id);
        }
      };

      $scope.dismiss = function () {
        $mdDialog.cancel();
      };

      $scope.viewListing = function () {
        if ($scope.listing) {
          $window.open('/listing/' + $scope.listing.id);
        }
      };
    }
  ]);
})(window.angular);
