/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import reduce from 'lodash/reduce';
import keyBy from 'lodash/keyBy';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('calendarApiService', [
    'managerCalendarEventsService',
    'calendarDataRepository',
    'appointmentsApi',
    'listingApi',
    'propertyApi',
    function (
      managerCalendarEventsService,
      calendarDataRepository,
      appointmentsApi,
      listingApi,
      propertyApi
    ) {
      var self = this;

      self.addManualAppointment = function (
        managerId,
        propertyId,
        renterInfo,
        startTime,
        endTime,
        layouts,
        listingIds,
        source,
        prospectId,
        firstContactType,
        note,
        preferences,
        tourType
      ) {
        return appointmentsApi
          .addManualAppointment(
            managerId,
            propertyId,
            renterInfo,
            startTime,
            endTime,
            layouts,
            listingIds,
            source,
            prospectId,
            firstContactType,
            note,
            preferences,
            tourType
          )
          .success(function (response) {
            calendarDataRepository.addAppointment(response.appointment);
            managerCalendarEventsService.confirmedAppointmentWasAddedManually(
              response.appointment
            );
          });
      };

      self.acceptRequest = function (request, canceledRequests) {
        return appointmentsApi
          .confirmAppointment(request.request_time_id || request.id)
          .success(function () {
            managerCalendarEventsService.pendingRequestWasAccepted(request);

            forEach(canceledRequests, function (canceledRequest) {
              managerCalendarEventsService.pendingRequestWasCanceled(
                canceledRequest
              );
            });
          });
      };

      self.rejectRequests = function (requests, message) {
        return appointmentsApi
          .cancelRequests(requests[0].id, message)
          .success(function () {
            forEach(requests, function (request) {
              managerCalendarEventsService.pendingRequestWasCanceled(request);
            });
          });
      };

      self.cancelAppointment = function (appointment, message) {
        return appointmentsApi
          .cancelAppointment(appointment.id, null, message)
          .success(function () {
            managerCalendarEventsService.confirmedAppointmentWasCanceled(
              appointment
            );
          });
      };

      self.transferAppointment = function (
        appointment,
        newActiveManager,
        visit
      ) {
        return appointmentsApi
          .setAppointmentManager(
            appointment.id,
            newActiveManager.managerId,
            true
          )
          .success(function () {
            appointment.manager_id = newActiveManager.managerId;

            if (visit) {
              managerCalendarEventsService.visitedAppointmentChangedManager(
                appointment,
                newActiveManager,
                visit
              );
            } else {
              managerCalendarEventsService.confirmedAppointmentChangedManager(
                appointment,
                newActiveManager
              );
            }
          });
      };

      self.changeAppointmentTime = function (
        appointment,
        startTime,
        endTime,
        tourType
      ) {
        return appointmentsApi
          .updateAppointmentTime(appointment.id, startTime, endTime, tourType)
          .success(function () {
            appointment.start_time = startTime.format();
            appointment.end_time = endTime.format();
            if (tourType !== undefined) {
              appointment.tour_type = tourType;
            }
            managerCalendarEventsService.confirmedAppointmentChangedTime(
              appointment,
              { start: startTime, end: endTime }
            );
          });
      };

      self.proposeAppointmentTimes = function (
        prospectId,
        newProposedTimes,
        managerMessage
      ) {
        return appointmentsApi
          .proposeAppointmentTimes(prospectId, newProposedTimes, managerMessage)
          .success(function (response) {
            var oldRequests = calendarDataRepository.getRequests(prospectId);
            managerCalendarEventsService.newAppointmentTimesWereProposed(
              response.proposals,
              oldRequests
            );
          });
      };

      self.getRequiredListings = function (listingIds) {
        var desiredFields = [
          'id',
          'location',
          'photos',
          'leasing',
          'floorplan',
          'coverPhoto'
        ];

        var listingIdsToRequest = filter(listingIds, function (listingId) {
          return !calendarDataRepository.getListing(listingId);
        });

        return listingApi
          .getListingsById(listingIdsToRequest, desiredFields)
          .then(function (response) {
            calendarDataRepository.setListings(
              keyBy(response.data.listings, 'id')
            );
            return response;
          });
      };

      self.getRequiredProperties = function (propertyIds) {
        var propertyIdsToRequest = filter(propertyIds, function (propertyId) {
          return !calendarDataRepository.getProperty(propertyId);
        });

        if (propertyIdsToRequest && propertyIdsToRequest.length > 0) {
          return propertyApi
            .getPropertiesById(propertyIdsToRequest)
            .then(function (response) {
              calendarDataRepository.setProperties(
                keyBy(response.data.properties, 'id')
              );
              return response;
            });
        }
      };

      self.getProspects = function (start, end) {
        return appointmentsApi
          .getAppointmentProspects(start, end)
          .success(function (response) {
            calendarDataRepository.setProspects(response.renters);
          });
      };

      self._reduceListings = function (response) {
        return reduce(
          response.listings,
          function (result, listingsByManager) {
            forEach(listingsByManager.listings, function (listing) {
              result[listing.id] = listing;
            });

            return result;
          },
          {}
        );
      };

      return self;
    }
  ]);
})(window.angular);
