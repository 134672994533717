/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('confirmPassword', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$validators.confirmPassword = function (modelValue, viewValue) {
          var password = scope.$eval(attrs.confirmPassword);
          return password === viewValue;
        };
      }
    };
  });

  app.controller('ResetForgottenPasswordController', [
    '$scope',
    '$routeParams',
    'userService',
    function ($scope, $routeParams, userService) {
      var resetToken = $routeParams.resetId;

      $scope.isChangingPassword = false;
      $scope.passwordSuccessfullyReset = false;

      $scope.password = {
        new: '',
        confirm: ''
      };

      $scope.resetPassword = function () {
        if (resetToken && $scope.password.new === $scope.password.confirm) {
          $scope.isChangingPassword = true;
          userService
            .resetForgottenPassword(resetToken, $scope.password.new)
            .then((response) => {
              if (response.status !== 201) {
                throw response;
              }

              $scope.passwordSuccessfullyReset = true;
            })
            .catch((error) => {
              $scope.isChangingPassword = false;
              $scope.errorMessage =
                error.data.message ||
                'There was a problem resetting your password. Please try again later.';
            });
        }
      };
    }
  ]);

  app.controller('ChangePasswordController', [
    '$scope',
    '$routeParams',
    '$location',
    'userService',
    function ($scope, $routeParams, $location, userService) {
      if (userService.getUser()) {
        localStorage.clear();
        window.location.reload();
        return;
      }

      const redirectTo = $routeParams.redirect;
      const username = $routeParams.username;

      $scope.isChangingPassword = false;
      $scope.passwordSuccessfullyReset = false;

      $scope.data = {
        username: username || '',
        oldPassword: '',
        newPassword: '',
        confirm: ''
      };

      $scope.resetPassword = function () {
        if (
          $scope.data.username &&
          $scope.data.oldPassword &&
          $scope.data.newPassword === $scope.data.confirm
        ) {
          $scope.isChangingPassword = true;

          userService
            .changePassword($scope.data)
            .then((response) => {
              if (response.status !== 200) {
                throw response;
              }

              $scope.passwordSuccessfullyReset = true;

              if (redirectTo) {
                setTimeout(() => {
                  window.location.href = redirectTo;
                }, 2000);
              }
            })
            .catch((error) => {
              $scope.isChangingPassword = false;
              $scope.errorMessage =
                error.data.message ||
                'There was a problem resetting your password. Please try again later.';
            });
        }
      };
    }
  ]);
})();
