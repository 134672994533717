/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isBoolean from 'lodash/isBoolean';
import every from 'lodash/every';
import each from 'lodash/each';
import filter from 'lodash/filter';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.service('renterProfileEditorModalService', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function () {
        return $mdDialog.show({
          controller: 'RenterProfileEditorModalController',
          templateUrl:
            '/angular/views/profile/renter-profile-editor-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true
        });
      };

      return self;
    }
  ]);

  app.controller('RenterProfileEditorModalController', [
    '$scope',
    '$mdDialog',
    '$moment',
    'ProfileService',
    'photoApi',
    'phoneVerificationModalFactory',
    function (
      $scope,
      $mdDialog,
      $moment,
      profileService,
      photoApi,
      phoneVerificationModalFactory
    ) {
      var self = this;

      $scope.openImagePrompt = function () {
        throw 'Not implemented. Must be overridden.';
      };

      $scope.tryOpenImagePrompt = function ($event) {
        if (!$scope.isUploadingPhoto) {
          $scope.openImagePrompt($event);
        }
      };

      $scope.$watch('localProfilePhoto', function () {
        if ($scope.localProfilePhoto && $scope.localProfilePhoto.length > 0) {
          $scope.profile.photo = $scope.localProfilePhoto[0].url;

          if ($scope.localProfilePhoto[0].isLocal) {
            $scope.isUploadingPhoto = true;
            $scope.photoError = null;

            photoApi
              .uploadPhotos('renter-profile', $scope.localProfilePhoto)
              .success(function (response) {
                $scope.profile.photo = response.photos[0].url;
                profileService.updateProfile($scope.profile);
              })
              .error(function () {
                $scope.photoError =
                  'There was an error uploading your profile photo. Please try again later.';
              })
              .finally(function () {
                $scope.isUploadingPhoto = false;
                $scope.localProfilePhoto = [];
              });
          }
        }
      });

      $scope.$watch(
        'profile.pets',
        function (newRenterPets, oldRenterPets) {
          if (!newRenterPets) {
            return;
          }

          var pets = filter(newRenterPets, function (hasPet, pet) {
            return pet !== 'none' && pet !== 'notes';
          });

          var choseNone = newRenterPets.none && !oldRenterPets.none;

          if (choseNone) {
            each($scope.profile.pets, function (hasPet, pet) {
              if (pet !== 'none' && pet !== 'notes') {
                $scope.profile.pets[pet] = false;
              }
            });
          } else {
            $scope.profile.pets.none = every(pets, function (pet) {
              return isBoolean(pet) && !pet;
            });
          }
        },
        true
      );

      $scope.localProfilePhoto = [];

      self._initialize = function () {
        $scope.isLoadingProfile = true;

        var forceProfileRefresh = true;
        profileService
          .getProfile(forceProfileRefresh)
          .success(function (response) {
            $scope.profile = response.profile;
            $scope.profile.target_move_date = $moment(
              $scope.profile.target_move_date
            ).format();

            $scope.profile.originalPhoneNumber = response.profile.phone_number;

            if ($scope.profile.verification_method) {
              if ($scope.profile.verification_method === 'none') {
                $scope.isPendingVerification = true;
              } else {
                $scope.isVerified = true;
              }
            }
          })
          .error(function () {
            $scope.errorMessage =
              'There was an error loading your profile. Please try again later.';
          })
          .finally(function () {
            $scope.isLoadingProfile = false;
          });
      };

      $scope.dismiss = function () {
        $mdDialog.cancel();
      };

      $scope.saveProfile = function (callback) {
        $scope.isSavingProfile = true;

        if (
          $scope.profile.originalPhoneNumber !== $scope.profile.phone_number
        ) {
          $scope.profile.verification_method = null;
        }

        $scope.profile.originalPhoneNumber = $scope.profile.phone_number;

        profileService
          .updateProfile($scope.profile)
          .success(function () {
            $scope.dismiss();
            if (callback) {
              callback();
            }
          })
          .error(function () {
            $scope.saveError =
              'There was an error saving your profile. Please try again later.';
          })
          .finally(function () {
            $scope.isSavingProfile = false;
          });
      };

      $scope.tryPhoneVerification = function () {
        if ($scope.isVerified) {
          return;
        }

        $scope.saveProfile(function () {
          if ($scope.isPendingVerification) {
            self._openVerificationConfirmationModal();
          } else {
            self._openPhoneVerificationModal();
          }
        });

        $scope.dismiss();
      };

      self._openPhoneVerificationModal = function () {
        phoneVerificationModalFactory
          .openVerificationModal($scope.profile.phone_number)
          .then(
            function (response) {
              if (response.success) {
                $scope.isPendingVerification = true;
                self._openVerificationConfirmationModal();
              } else {
                $scope.saveErrorMessage =
                  'There was an error sending your verification code. Please try again later.';
              }
            },
            function () {
              $scope.saveErrorMessage =
                'There was an error sending your verification code. Please try again later.';
            }
          );
      };

      self._openVerificationConfirmationModal = function () {
        phoneVerificationModalFactory
          .openVerificationConfirmationModal($scope.profile.phone_number)
          .then(function (response) {
            $scope.saveErrorMessage = null;

            $scope.isVerified = response.confirmed;
            $scope.isPendingVerification = !$scope.isVerified;

            if (!$scope.isVerified) {
              $scope.saveErrorMessage =
                'There was an error validating your phone number. Either the code has expired or was incorrect.';
            } else {
              $scope.profile.verification_method = 'text';
              profileService.updateProfile($scope.profile);
            }
          });
      };

      self._initialize();
    }
  ]);
})(window.angular);
