/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('userImage', function () {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<div class="user-image" ng-class="sizeClass"' +
        " ng-style=\"{'background-image': 'url({{src}})'}\"></div>",
      scope: {
        src: '=',
        size: '@'
      },
      controller: [
        '$scope',
        'defaultProfileImageUrl',
        function ($scope, defaultProfileImageUrl) {
          $scope.src = $scope.src || defaultProfileImageUrl;

          $scope.size = $scope.size || 'small';
          $scope.sizeClass = $scope.size + '-user-image';
        }
      ]
    };
  });
})(window.angular);
