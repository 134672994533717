/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('listingApplicationSent', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/listing/listingDetails/application-sent.html'
    };
  });

  app.directive('listingApplicationInReview', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/listing/listingDetails/application-in-review.html'
    };
  });

  app.directive('listingApplicationRejected', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/listing/listingDetails/application-rejected.html'
    };
  });

  app.directive('listingApplicationAccepted', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/listing/listingDetails/application-accepted.html'
    };
  });

  app.directive('listingApplicationVisited', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/listing/listingDetails/application-visited.html'
    };
  });
})();
