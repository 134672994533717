/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('QueuedRequestController', [
    '$scope',
    '$routeParams',
    '$auth',
    '$location',
    'propertyApi',
    'appointmentsApi',
    'localStorageService',
    function (
      $scope,
      $routeParams,
      $auth,
      $location,
      propertyApi,
      appointmentsApi,
      localStorageService
    ) {
      var self = this;

      self._initialize = function () {
        if ($auth.isAuthenticated()) {
          self._getQueuedRequest();
        } else {
          $scope.hasAuthenticationError = true;
        }
      };

      self._getQueuedRequest = function () {
        return appointmentsApi
          .getQueuedRequest($routeParams.queuedRequestId)
          .success(self._getQueuedRequestSuccess);
      };

      self._getQueuedRequestSuccess = function (response) {
        var queuedRequest = response.queued_request;
        localStorageService.set('queued-request', queuedRequest);
        $location.search('queuedRequestId', null);

        if (!isEmpty(queuedRequest.listing_ids)) {
          $location.path('/listing/' + queuedRequest.listing_ids[0]);
        } else {
          propertyApi
            .getProperty(queuedRequest.property_id)
            .success(function (response) {
              var property = response.property;

              if (property.type === 'multi-family') {
                $location.path('/community/' + property.data.id);
              } else {
                $location.path('/listing/' + property.data.id);
              }
            });
        }
      };

      self._initialize();
    }
  ]);
})(window.angular);
