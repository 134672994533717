/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var callStatuses = {
    queued: 'Connecting...',
    initiated: 'Initiated!',
    ringing: 'Ringing...',
    'in-progress': 'In progress...',
    busy: 'Busy',
    failed: 'Failed',
    'no-answer': 'No answer',
    canceled: 'Canceled',
    completed: 'Call ended'
  };

  var app = angular.module('knock-Voice');

  app.filter('voiceCallStatus', [
    function () {
      return function (statusId) {
        return callStatuses[statusId];
      };
    }
  ]);
})(window.angular);
