/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import some from 'lodash/some';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { APP_CONFIG } from 'APP_CONFIG';

(function (angular) {
  'use strict';

  var app = angular.module('knock-Todo');

  app.directive('prospectFunctions', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/todo/prospect-functions.html',
      controller: 'ProspectFunctionsController',
      scope: {
        prospect: '=',
        teamMembers: '=?',
        conversation: '='
      }
    };
  });

  const ProspectFunctionsController = function (
    $scope,
    $rootScope,
    $mdDialog,
    $mdToast,
    $filter,
    $moment,
    appDataService,
    todoService,
    conversationsService,
    prospectHistoryService,
    prospectLossReasonsService,
    userInteractionsService,
    transferProspectModalFactory,
    prospectAppointmentModalFactory,
    prospectLeasedDateModalFactory,
    unsubscribeHelper,
    propertyApi,
    timeService,
    managerApi,
    $timeout
  ) {
    var self = this;

    var prospectTransferredHandlerDestroy = $rootScope.$on(
      userInteractionsService.events.prospectTransferred,
      function (event, prospectId, newManagerId) {
        if ($scope.prospect.id === prospectId) {
          $scope.prospect.assigned_manager_id = newManagerId;
          $scope.data.assignedManagerId = newManagerId;
        }
      }
    );
    $scope.mergeTags = [];
    $scope.$on('$destroy', function () {
      prospectTransferredHandlerDestroy();
    });
    $scope.timeService = timeService;

    $scope.data = {
      assignedManagerId: $scope.prospect.assigned_manager_id,
      teamMembers: $scope.teamMembers || {},
      teamMembersArray: Object.values($scope.teamMembers) || [],
      prospectStatuses: []
    };

    self._getMergeTags = function () {
      $scope.mergeTags = appDataService.getEmailMergeTags('prospect');
      $scope.mergeTags.forEach((mergeTag) => {
        mergeTag.value = `#${mergeTag.name}`;
      });
    };

    $scope.$watch('prospect.id', function () {
      $scope.data.assignedManagerId = $scope.prospect.assigned_manager_id;
      self._getPossibleProspectStatuses($scope.prospect.status);
    });

    self._getProperty = function () {
      return propertyApi
        .getProperty($scope.prospect.property_id)
        .success(function (response) {
          $scope.data.property = response.property;
        });
    };
    $scope.propertyAmenities = [];
    self._initialize = function () {
      self._getPossibleProspectStatuses($scope.prospect.status);
      self._setTeamMembers();
      self._getProperty();
      self._getMergeTags();
    };

    $scope.$watch(
      'data.assignedManagerId',
      function (newAssignedManagerId, oldAssignedManagerId) {
        if (
          !newAssignedManagerId ||
          newAssignedManagerId === oldAssignedManagerId ||
          newAssignedManagerId === $scope.prospect.assigned_manager_id
        ) {
          return;
        }

        transferProspectModalFactory
          .openTransferProspectModal($scope.prospect, newAssignedManagerId)
          .then(
            function () {
              $scope.prospect.assigned_manager_id =
                $scope.data.assignedManagerId;
            },
            function () {
              $scope.data.assignedManagerId =
                $scope.prospect.assigned_manager_id;
            }
          );
      }
    );

    $scope.sendEmail = conversationsService.sendMessage;

    $scope.getMyCommunities = function () {
      return managerApi.getMyCommunities(false, false, false, true);
    };

    $scope.addAppointment = function () {
      todoService.addAppointmentForProspect($scope.prospect.id);
    };

    self.updateProspectStatus = function (newStatus) {
      function updateProspect() {
        $scope.prospect.status = newStatus;

        prospectHistoryService
          .updateProspect($scope.prospect)
          .then(function () {
            $mdToast.showSimple('Prospect status updated!');
          });
      }

      const isLeasingProviderOnSite =
        $scope.data.property?.data.leasing?.provider === 'On-Site';

      if (isLeasingProviderOnSite) {
        const applicantPendingCompletionStatuses = [
          'application-in-progress',
          'in-review',
          'applied-pending-signature'
        ];

        if (
          applicantPendingCompletionStatuses.includes(newStatus) ||
          applicantPendingCompletionStatuses.includes($scope.prospect.status)
        ) {
          const href = `${APP_CONFIG.REACT_APP_ONSITE_HOST}/web/deep_link/lease_details?knock_prospect_id=${$scope.prospect.id}`;
          const alert = $mdDialog.alert({
            title: 'Update prospect in On-Site',
            content:
              'Go to On-Site to update the prospect’s application status. <br>' +
              `<a target="_blank" href="${href}">Open Prospect in On-Site</a>`,
            ok: 'Got it'
          });

          return $mdDialog.show(alert);
        }
      }

      if (newStatus === 'visited' && isEmpty($scope.prospect.appointments)) {
        return prospectAppointmentModalFactory
          .openAddProspectAppointmentModal($scope.prospect.id)
          .then(updateProspect);
      }

      if (newStatus === 'applied-leased') {
        var allowedVendors = []; // No integrated properties should be able to change status to leased, for now

        var prospectVendors = map($scope.prospect.integrations, 'vendor');

        var cannotManuallyChangeToLeased =
          $scope.prospect.integrations &&
          prospectVendors.length > 0 &&
          !some(prospectVendors, function (prospectVendor) {
            return includes(allowedVendors, prospectVendor);
          });

        if (cannotManuallyChangeToLeased) {
          var vendorLabels = {
            amsi: 'AMSI',
            entrata: 'Entrata',
            mri: 'MRI',
            realpage: 'RealPage',
            resman: 'Resman',
            yardi: 'Yardi'
          };

          var pmsName = vendorLabels[prospectVendors[0]];
          var pmsId = $scope.prospect.integrations[0].vendor_prospect_id;

          var pmsAlert = $mdDialog.alert({
            title: 'Update prospect in ' + pmsName,
            content:
              'In order to mark this prospect as leased, please complete their lease signing within ' +
              pmsName +
              ' and it will reflect here. The ' +
              pmsName +
              ' ID is: ' +
              pmsId,
            ok: 'Got it'
          });

          return $mdDialog.show(pmsAlert);
        }

        return prospectLeasedDateModalFactory
          .openLeasedDateModal($scope.prospect)
          .then(function (newLeasedDate) {
            $scope.prospect.leased_date = newLeasedDate;
            updateProspect();
          });
      }

      updateProspect();
    };

    $scope.openMarkAsLostDrawer = false;

    $scope.closeMarkAsLostDrawer = (isBookTourselected = false) => {
      $timeout(() =>
        $scope.$apply(() => {
          $scope.openMarkAsLostDrawer = false;
          $rootScope.$emit('closeAllMarkAsLostModals');
          if (!isBookTourselected && $rootScope.tour_drawer_opened) {
            $rootScope.$emit('openCompleteTourDrawer');
          }
        })
      );
    };
    $scope.closeScheduleTour = () => {
      if ($rootScope.tour_drawer_opened) {
        $rootScope.$emit('openCompleteTourDrawer');
      }
    };

    //In case of duplicated modal this should close them all
    $rootScope.$on('closeAllMarkAsLostModals', () => {
      if ($scope.openMarkAsLostDrawer) {
        console.log('CachingCloseModal');
        $scope.closeMarkAsLostDrawer(true);
      }
    });

    $scope.isReferralsEnabled =
      $rootScope.appPreferences.company.enable_property_referrals;

    $scope.isMarkAsLostDrawerEnabled =
      $rootScope.featureFlags.CROSS_SELL_AUTOMATION;

    $scope.openLeasingBinder = (property) => {
      $rootScope.$broadcast('markAsLostLeasingBinderOpen', { property });
    };

    $scope.disableLostStatus = (status) => {
      if (status === 'lost') {
        return (
          $scope.prospect.enable_cheatproof_engagement_score &&
          $scope.prospect.disable_lost_status
        );
      } else {
        return false;
      }
    };

    $rootScope.$on('CompleteTour-MarkAsLost', (_, id) => {
      if (id === $scope.prospect.id) {
        $scope.updateProspectStatus('lost');
        $timeout(() => $scope.$apply());
      }
    });

    $scope.updateProspectStatus = function (newStatus) {
      if (
        newStatus === 'lost' &&
        $rootScope.featureFlags.CROSS_SELL_AUTOMATION
      ) {
        $scope.openMarkAsLostDrawer = true;
        return;
      }
      function isInTheFuture(appointment) {
        return (
          $moment(appointment.start_time) > $moment() &&
          appointment.status === 'confirmed'
        );
      }

      if (prospectLossReasonsService.statusIsLost(newStatus)) {
        // Don't allow lost or applied-lost if there's a future appointment:
        if (some($scope.prospect.appointments, isInTheFuture)) {
          var upcomingAppointmentAlert = $mdDialog.alert({
            title: 'Upcoming Appointment',
            content:
              'You have an upcoming appointment with this prospect. You must cancel the appointment, or change the status after the appointment.',
            ok: 'Got it'
          });

          return $mdDialog.show(upcomingAppointmentAlert);
        }
        // get user's email unsubscribe status
        let userUnsubscribed = false;
        if ($scope.conversation) {
          const unsubscribeStatus =
            unsubscribeHelper.getUnsubscribeStatusFromStream(
              $scope.conversation
            );
          if (unsubscribeStatus) {
            userUnsubscribed = unsubscribeStatus.opted_out;
          }
        }

        // Cheat-proof handling
        // But, if user is unsubscribed, do not restrict changing to Lost
        if (
          $scope.prospect.enable_cheatproof_engagement_score &&
          !userUnsubscribed
        ) {
          conversationsService
            .getProspectEngagementSettings($scope.prospect.id)
            .then(function (response) {
              if (
                response.data.engagement_settings
                  .enable_cheatproof_engagement_score &&
                response.data.engagement_settings.disable_lost_status
              ) {
                var disableLostStatusAlert = $mdDialog.alert({
                  title: 'Marking Lost Requires Communication',
                  content:
                    'You are not able to mark prospects as lost unless you have previously communicated with them.',
                  ok: 'Got it'
                });

                return $mdDialog.show(disableLostStatusAlert);
              } else {
                self._openProspectLossReasonsModal(newStatus);
              }
            });

          return;
        }

        // don't update prospect status if model is cancelled
        self._openProspectLossReasonsModal(newStatus);

        return;
      }

      self.updateProspectStatus(newStatus);
    };

    self._openProspectLossReasonsModal = function (newStatus) {
      prospectLossReasonsService
        .openProspectLossReasonsModal($scope.prospect)
        .then(function () {
          self.updateProspectStatus(newStatus);
        });
    };

    $scope.tryDeleteProspect = function () {
      var confirm = $mdDialog
        .confirm()
        .title('Archive prospect')
        .content('Are you sure you wish to archive this prospect?')
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        prospectHistoryService.deleteProspect($scope.prospect);
      });
    };

    self._getPossibleProspectStatuses = function (prospectStatus) {
      return userInteractionsService
        .getProspectStatusTransitions(prospectStatus)
        .success(self._getPossibleProspectStatusesSuccess);
    };

    self._getPossibleProspectStatusesSuccess = function (response) {
      var statusFilter = $filter('prospectStatus');

      $scope.data.prospectStatuses = sortBy(
        response.statuses,
        function (status) {
          return statusFilter(status);
        }
      );
    };

    self._setTeamMembers = function () {
      $scope.data.teamMembersArray = [];

      $scope.data.teamMembers = reduce(
        appDataService.getTeamMembers(),
        function (result, member) {
          result[member.Manager.id] = member;
          $scope.data.teamMembersArray.push(member);
          return result;
        },
        {}
      );
    };

    self._initialize();
  };

  ProspectFunctionsController.$inject = [
    '$scope',
    '$rootScope',
    '$mdDialog',
    '$mdToast',
    '$filter',
    '$moment',
    'appDataService',
    'todoService',
    'conversationsService',
    'prospectHistoryService',
    'prospectLossReasonsService',
    'userInteractionsService',
    'transferProspectModalFactory',
    'prospectAppointmentModalFactory',
    'prospectLeasedDateModalFactory',
    'unsubscribeHelper',
    'propertyApi',
    'timeService',
    'managerApi',
    '$timeout'
  ];

  app.controller('ProspectFunctionsController', ProspectFunctionsController);
})(window.angular);
