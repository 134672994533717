import React, { FC, useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import {
  makeStyles,
  Button,
  Box,
  Text,
  ThemeProvider,
  NamedColors
} from '@knockrentals/knock-shared-web';
import { DatePicker } from './DatePicker';
import { CircularProgressButton } from './CircularProgressButton';

const useStyles = makeStyles({
  paperWidth: {
    width: '500px',
    maxHeight: '450px'
  },
  datePicker: {
    width: '50%',
    '& .MuiInputLabel-formControl': {
      left: 0,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      transform: 'none',
      marginBottom: '0'
    },
    '& .MuiInputBase-formControl': {
      borderColor: NamedColors.slate[300]
    }
  },
  dialogTitle: {
    padding: '16px 12px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)'
  },
  dialogContent: {
    padding: '12px'
  },
  timeBox: {
    width: '50%',
    marginTop: '9px'
  },
  fullBox: {
    width: '100%'
  },
  fullBoxWithFlex: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    marginBottom: '15px',
    '& .MuiFormControl-root': {
      margin: 0
    }
  },
  select: {
    width: '100%',
    maxHeight: '35px',
    border: `2px solid ${NamedColors.slate[300]}`
  },
  actionBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '15px'
  },
  inputLabel: {
    marginBottom: '5px',
    fontSize: '16px',
    fontWeight: 600
  }
});

interface TeamMember {
  id: number;
  name: string;
}
interface TimeOption {
  label: string;
  value: string;
}
interface FloorPlanType {
  id: number;
  floorPlanText: string;
  name?: string;
  bedrooms?: number;
  size?: number;
  property_floorplan_id?: number;
  area?: number;
}
interface WalkinModalProps {
  generateTimeOptions: (date: Date) => TimeOption[];
  requireFloorPlan: boolean;
  getFloorPlans: () => Promise<any>;
  useNewUnitsFeature: boolean;
  saveWalkinDetails: (data: any) => Promise<any>;
  walkinDataSaving: boolean;
  teamMembers: TeamMember[];
  selectedFloorPlanId: number;
  managerId: number;
  closeWalkinModal: () => void;
}

const WalkinModal: FC<WalkinModalProps> = ({
  generateTimeOptions,
  requireFloorPlan,
  getFloorPlans,
  useNewUnitsFeature,
  teamMembers,
  saveWalkinDetails,
  walkinDataSaving,
  selectedFloorPlanId,
  managerId,
  closeWalkinModal
}) => {
  const [walkinDate, setWalkinDate] = useState<Date | null>(null);
  const [walkinTime, setWalkinTime] = useState<string>('');
  const [walkinTimeOptions, setWalkinTimeOptions] = useState<TimeOption[]>([]);
  const [selectedFloorPlan, setSelectedFloorPlan] = useState<number | string>(
    selectedFloorPlanId || ''
  );
  const [floorPlans, setFloorPlans] = useState<FloorPlanType[]>([]);
  const [selectedUser, setSelectedUser] = useState<number | string>(
    managerId || ''
  );
  const classes = useStyles();

  const changeDate = (date: any) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0);
    const timeOptions = generateTimeOptions(newDate);
    setWalkinTimeOptions(timeOptions);
    setWalkinDate(newDate);
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const submitHandler = () => {
    saveWalkinDetails({
      walkinDate: walkinDate ? formatDate(new Date(walkinDate)) : '',
      walkinTime: walkinTime,
      selectedFloorPlan,
      selectedUser
    });
  };
  useEffect(() => {
    const fetchFloorPlans = async () => {
      const { data } = await getFloorPlans();
      let plans = [];
      if (useNewUnitsFeature) {
        plans = data.layouts.map((floorPlan: FloorPlanType) => {
          let floorPlanText = floorPlan?.name ? floorPlan?.name : '';
          floorPlanText += floorPlan?.bedrooms
            ? ` - ${floorPlan?.bedrooms} bd`
            : '';
          floorPlanText += floorPlan?.area
            ? ` - ${floorPlan.area} sq. ft.`
            : '';
          return {
            floorPlanText,
            id: floorPlan?.id
          };
        });
      } else {
        plans = data.floorplans.map((floorPlan: FloorPlanType) => {
          let floorPlanText = floorPlan?.name ? floorPlan?.name : '';
          floorPlanText += floorPlan?.bedrooms
            ? ` - ${floorPlan?.bedrooms} bd`
            : '';
          floorPlanText += floorPlan?.size
            ? ` - ${floorPlan.size} sq. ft.`
            : '';
          return {
            floorPlanText,
            id: floorPlan?.property_floorplan_id
          };
        });
      }
      setFloorPlans(plans);
    };
    requireFloorPlan && fetchFloorPlans();
  }, [requireFloorPlan, useNewUnitsFeature, getFloorPlans]);
  return (
    <ThemeProvider>
      <Dialog
        classes={{ paperWidthSm: classes.paperWidth }}
        open={true}
        onClose={closeWalkinModal}
      >
        <DialogTitle className={classes.dialogTitle}>Add Walk in</DialogTitle>
        <DialogContent className={classes.dialogContent} dividers={true}>
          <Box className={classes.fullBoxWithFlex}>
            <DatePicker
              className={classes.datePicker}
              format={'EEE, LLL d, yyyy'}
              data-tesid="date-picker"
              error={false}
              label={<Text variant="subtitle1">Walk in date</Text>}
              name={'walk-in-date'}
              initialFocusedDate={Date.now()}
              onChange={(date) => changeDate(date)}
              value={walkinDate}
              disableFuture
            />
            <Box className={classes.timeBox}>
              <InputLabel id="walkin-dropdown" className={classes.inputLabel}>
                Walk in time
              </InputLabel>
              <Select
                labelId="walkin-dropdown"
                date-testid="walkin-dropdown"
                className={classes.select}
                displayEmpty
                MenuProps={{
                  style: { zIndex: 15001 }
                }}
                onChange={(event: React.ChangeEvent<{ value: any }>) => {
                  setWalkinTime(event.target.value);
                }}
                value={walkinTime}
              >
                {walkinTimeOptions &&
                  walkinTimeOptions.map((time: TimeOption, index: number) => (
                    <MenuItem key={index} value={time.value}>
                      {time.label}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Box>
          {requireFloorPlan && (
            <Box className={classes.fullBoxWithFlex}>
              <Box className={classes.fullBox}>
                <InputLabel
                  id="floor-plan-dropdown"
                  className={classes.inputLabel}
                >
                  Preferred Floor Plan
                </InputLabel>
                <Select
                  labelId="floor-plan-dropdown"
                  date-testid="floor-plan-dropdown"
                  className={classes.select}
                  value={selectedFloorPlan}
                  displayEmpty
                  MenuProps={{
                    style: { zIndex: 15001 }
                  }}
                  onChange={(event: React.ChangeEvent<{ value: any }>) => {
                    setSelectedFloorPlan(event.target.value);
                  }}
                >
                  {floorPlans &&
                    floorPlans.map((floorPlan: FloorPlanType) => (
                      <MenuItem key={floorPlan.id} value={floorPlan.id}>
                        {floorPlan.floorPlanText}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            </Box>
          )}
          <Box className={classes.fullBoxWithFlex}>
            <Box className={classes.fullBox}>
              <InputLabel id="owner-dropdown" className={classes.inputLabel}>
                Owner
              </InputLabel>
              <Select
                labelId="owner-dropdown"
                date-testid="owner-dropdown"
                className={classes.select}
                displayEmpty
                MenuProps={{
                  style: { zIndex: 15001 }
                }}
                value={selectedUser}
                onChange={(event: React.ChangeEvent<{ value: any }>) => {
                  setSelectedUser(event.target.value);
                }}
              >
                {teamMembers &&
                  teamMembers.map((team: TeamMember) => (
                    <MenuItem value={team.id} key={team.id}>
                      {team.name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className={classes.actionBox}>
            <Button variant="outlined" onClick={closeWalkinModal}>
              Cancel
            </Button>
            <CircularProgressButton
              onClick={submitHandler}
              shouldShowProgress={walkinDataSaving}
              progressText={'Saving...'}
              disabled={
                !walkinDate || !walkinTime || !selectedUser ? true : false
              }
            >
              Save
            </CircularProgressButton>
          </Box>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};
export default WalkinModal;
