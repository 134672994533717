/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import forEach from 'lodash/forEach';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('fullCalendarService', [
    '$rootScope',
    'managerCalendarEventsService',
    'managerCalendarService',
    'calendarDataRepository',
    'calendarApiService',
    function (
      $rootScope,
      managerCalendarEventsService,
      managerCalendarService,
      calendarDataRepository,
      calendarApiService
    ) {
      var self = this;

      self.calendarElement = null;

      self.setCalendarElement = function (calendarElement) {
        self.calendarElement = calendarElement;
      };

      self.renderAppointment = function (appointment) {
        self.renderCalendarEvent(
          managerCalendarService.mapAppointment(appointment)
        );
      };

      self.renderVisit = function (visit) {
        self.renderCalendarEvent(managerCalendarService.mapVisit(visit));
      };

      self.renderCalendarEvent = function (calendarEvent) {
        self.calendarElement.fullCalendar('renderEvent', calendarEvent);
      };

      self.removeCalendarEvent = function (calendarEventId) {
        self.calendarElement.fullCalendar('removeEvents', calendarEventId);
      };

      $rootScope.$on(
        managerCalendarEventsService.eventTypes.pendingRequest.wasCanceled,
        function (scopeEvent, appointment) {
          self.removeCalendarEvent(appointment.id);
        }
      );

      $rootScope.$on(
        managerCalendarEventsService.eventTypes.pendingRequest.wasAccepted,
        function (scopeEvent, appointment) {
          appointment.status = 'confirmed';

          self.removeCalendarEvent(appointment.id);
          self.renderAppointment(appointment);
        }
      );

      $rootScope.$on(
        managerCalendarEventsService.eventTypes.confirmedAppointment
          .wasCanceled,
        function (scopeEvent, appointment) {
          self.removeCalendarEvent(appointment.id);
        }
      );

      $rootScope.$on(
        managerCalendarEventsService.eventTypes.confirmedAppointment
          .wasAddedManually,
        function (scopeEvent, appointment) {
          if (
            !calendarDataRepository.getProperty(
              appointment.prospect.property_id
            )
          ) {
            calendarApiService
              .getRequiredProperties(appointment.prospect.property_id)
              .then(function () {
                self.renderAppointment(appointment);
              });
          } else {
            self.renderAppointment(appointment);
          }
        }
      );

      $rootScope.$on(
        managerCalendarEventsService.eventTypes.confirmedAppointment
          .changedManager,
        function (scopeEvent, appointment) {
          self.removeCalendarEvent(appointment.id);
          self.renderAppointment(appointment);
        }
      );

      $rootScope.$on(
        managerCalendarEventsService.eventTypes.confirmedAppointment
          .changedTime,
        function (scopeEvent, appointment) {
          self.removeCalendarEvent(appointment.id);
          self.renderAppointment(appointment);
        }
      );

      $rootScope.$on(
        managerCalendarEventsService.eventTypes.appointmentProposals
          .wereProposed,
        function (scopeEvent, proposals, oldRequests) {
          forEach(oldRequests, function (request) {
            self.removeCalendarEvent(request.id);
          });
        }
      );

      $rootScope.$on(
        managerCalendarEventsService.eventTypes.visitedAppointment
          .changedManager,
        function (scopeEvent, appointment, newManager, visit) {
          self.removeCalendarEvent(appointment.id);
          self.removeCalendarEvent('visit-' + visit.id);
          self.renderVisit(visit);
        }
      );

      return self;
    }
  ]);
})(window.angular);
