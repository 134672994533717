import React, { FC } from 'react';

export const DollarIcon: FC = () => (
  <svg
    width={20}
    height={22}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.75 4.125H9C8.27065 4.125 7.57118 4.41473 7.05546 4.93046C6.53973 5.44618 6.25 6.14565 6.25 6.875C6.25 11 13.75 11 13.75 15.125C13.75 15.8543 13.4603 16.5538 12.9445 17.0695C12.4288 17.5853 11.7293 17.875 11 17.875H6.25"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 4.125V1.625"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 17.875V20.375"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
