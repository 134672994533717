/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('timeService', [
    '$q',
    '$moment',
    'userService',
    'managerApi',
    function ($q, $moment, userService, managerApi) {
      var self = this;

      self._timezones = [
        { name: 'Hawaii', id: 'Pacific/Honolulu' },
        { name: 'Alaska', id: 'America/Anchorage' },
        { name: 'Pacific', id: 'America/Los_Angeles' },
        { name: 'Mountain', id: 'America/Denver' },
        { name: 'Central', id: 'America/Chicago' },
        { name: 'Eastern', id: 'America/New_York' },
        { name: 'Arizona', id: 'America/Phoenix' },
        { name: 'Regina / Saskatchewan / CST', id: 'America/Regina' }
      ];

      self._timezoneAbbrs = {
        EST: 'Eastern Standard Time',
        EDT: 'Eastern Daylight Time',
        CST: 'Central Standard Time',
        CDT: 'Central Daylight Time',
        MST: 'Mountain Standard Time',
        MDT: 'Mountain Daylight Time',
        PST: 'Pacific Standard Time',
        PDT: 'Pacific Daylight Time'
      };

      self._initialize = function () {
        $moment.tz.setDefault(self._getDefaultTimezone().id);

        self._activeTimezone = null;
        self.getActiveTimezone().then(function (timezone) {
          self._activeTimezone = timezone;
          $moment.tz.setDefault(timezone.id);
        });

        return self;
      };

      self.now = function () {
        return $moment();
      };

      self.clone = function (moment) {
        return $moment(moment);
      };

      self.range = function (start, end) {
        return $moment().range(start, end);
      };

      self.combineDateAndTime = function (date, time) {
        var dateMoment = self.get(date);
        var timeMoment = self.get(time);

        return (
          dateMoment.format('YYYY-MM-DD') +
          'T' +
          timeMoment.format('HH:mm:ss') +
          dateMoment.format('Z')
        );
      };

      self.getTimezones = function () {
        return self._timezones;
      };

      self.getTimezoneAbbreviation = function (momentReadableTime, timezone) {
        var moment = $moment(momentReadableTime);
        var momentTimezone = $moment.tz.zone(timezone);
        return momentTimezone ? momentTimezone.abbr(moment) : 'PDT';
      };

      self.getFullTimezoneName = () => {
        const timezoneName = self._activeTimezone.id || $moment.tz.guess(true);
        if (timezoneName === 'America/Phoenix') {
          return 'Arizona (MST) Time';
        } else {
          const abbr = $moment.tz($moment(), timezoneName).format('zz');
          if (abbr && self._timezoneAbbrs[abbr]) {
            return self._timezoneAbbrs[abbr];
          }
        }
      };

      self.getActiveTimezone = function () {
        if (self._activeTimezone) {
          var deferred = $q.defer();
          deferred.resolve(self._activeTimezone);

          return deferred.promise;
        }

        var currentUser = userService.getUser();

        if (currentUser && currentUser.type === 'manager') {
          return managerApi.getPreferredTimezone().then(function (response) {
            var timezoneId = response.data.timezone;
            var timezone = find(self.getTimezones(), { id: timezoneId });

            if (!timezone) {
              self.setTimezone(self._getDefaultTimezone());
            } else {
              self._setTimezone(timezone);
            }

            return timezone;
          });
        }

        var defaultDeferred = $q.defer();
        defaultDeferred.resolve(self._getDefaultTimezone());

        return defaultDeferred.promise;
      };

      self._getDefaultTimezone = function () {
        // Use moment.tz.guess to guess the user's tz based on Internationalization API
        // The 'true' arg prevents caching. If undefined/ null, use default 'America/Los_Angeles'
        // https://momentjs.com/timezone/docs/#/using-timezones/guessing-user-timezone/
        return (
          find(self.getTimezones(), { id: moment.tz.guess(true) }) ||
          find(self.getTimezones(), { id: 'America/Los_Angeles' })
        );
      };

      self.setTimezone = function (timezone) {
        self._setTimezone(timezone);

        return managerApi.setPreferredTimezone(self._activeTimezone.id);
      };

      self._setTimezone = function (timezone) {
        if (isEmpty(timezone)) {
          return;
        }

        self._activeTimezone = timezone;
        $moment.tz.setDefault(self._activeTimezone.id);
      };

      self.get = function (momentReadableTime, format, timezone) {
        var moment;

        var timezoneId =
          timezone ||
          (self._activeTimezone ? self._activeTimezone.id : null) ||
          self._getDefaultTimezone().id;

        if (angular.isDefined(format)) {
          moment = $moment(momentReadableTime, format);
        } else {
          moment = $moment(momentReadableTime);
        }

        if (moment.isValid() && angular.isDefined(timezoneId)) {
          moment = moment.tz(timezoneId);
        }

        return moment;
      };

      return self._initialize();
    }
  ]);

  const userTime = function (timeService) {
    return function (timeString, format) {
      return timeService.get(timeString).format(format);
    };
  };

  userTime.$inject = ['timeService'];

  app.filter('userTime', userTime);
})(window.angular);
