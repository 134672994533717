/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import map from 'lodash/map';
(function (angular, $) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('activeAdsService', [
    'apiBase',
    function (apiBase) {
      var self = this;

      self.getActiveAds = function (
        status,
        managerId,
        page,
        resultsPerPage,
        fields
      ) {
        var params = {
          page: page,
          results_per_page: resultsPerPage,
          status: status,
          fields: fields ? JSON.stringify(fields) : fields
        };

        return apiBase.get('/manager/' + managerId + '/listings', {
          params: params
        });
      };

      return self;
    }
  ]);

  const ActiveAdsController = function (
    $scope,
    $timeout,
    $q,
    $location,
    $routeParams,
    $mdToast,
    appDataService,
    activeAdsService,
    userService,
    teamApi,
    managerApi,
    companyApi,
    listingApi,
    postFloorplanModalService,
    listingReadyModalFactory
  ) {
    var self = this;
    var user = userService.getScopedUser();

    var newlyCreatedListingId = $routeParams.newListingId || null;

    $scope.tabs = {};

    $scope.data = {
      isLoading: false,
      isInitializing: true,
      pagination: {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 25
      },
      filters: {
        selectedManager: null,
        status: 'active'
      },
      listings: [],
      communities: [],
      floorplansByCommunity: {},
      teamMembers: [],
      companyPreferences: null,
      hideResidentsForMultiFamily: false,
      hideSyncToggle: true,
      gotSyncPrefs: false,
      syncToHotpads: false,
      coverPhotoPicker: {
        isVisible: false,
        images: []
      }
    };

    $scope.postFloorplanListing = function ($event, floorplan) {
      postFloorplanModalService.openModal($event, floorplan, function () {
        $timeout($scope.resetPage, 1000, true);
      });
    };

    $scope.newListing = function () {
      $location.url('/manager/listing/create');
    };

    $scope.newCommunity = function () {
      $location.url('/manager/community/create');
    };

    $scope.sortManagers = function (manager) {
      if (manager.managerId === user.id) {
        return 'AAAAAA';
      }

      return manager.info.first_name + manager.info.last_name;
    };

    $scope.resetPage = function () {
      $scope.data.pagination.currentPage = 1;
      $scope.pageChanged();
    };

    $scope.pageChanged = function () {
      self._getActiveAds();
    };

    self._initialize = function () {
      $scope.data.isLoading = true;
      $scope.data.isInitializing = true;

      self._setTeamMembers();

      var initPromises = [
        self._getActiveAds(),
        self._getCompanyPreferences(),
        self._getMyCommunities(),
        self._getMyFloorplans(),
        self._getHotpadsSyncPreference()
      ];

      $q.all(initPromises).finally(function () {
        $scope.data.isInitializing = false;
      });
      if (newlyCreatedListingId) {
        listingApi
          .getListing(newlyCreatedListingId)
          .success(function (response) {
            listingReadyModalFactory.openListingReadyModal(response.listing);
          });
      }
    };

    self._getActiveAds = function () {
      $scope.data.isLoading = true;

      var desiredFields = [
        'id',
        'coverPhoto',
        'description',
        'external',
        'leasing',
        'location',
        'photos',
        'status',
        'debug',
        'floorplan',
        'pets',
        'status_updates',
        'social',
        'community_id',
        'amenities',
        'views',
        'appliances',
        'furnishing',
        'parking',
        'utilities',
        'laundry',
        'hasPrimeTime'
      ];

      return activeAdsService
        .getActiveAds(
          $scope.data.filters.status,
          $scope.data.filters.selectedManager.managerId,
          $scope.data.pagination.currentPage,
          $scope.data.pagination.itemsPerPage,
          desiredFields
        )
        .then(function (response) {
          $scope.data.listings = response.data.results.listings;
          $scope.data.pagination.totalItems = response.data.results.total;
        })
        .finally(function () {
          $scope.data.isLoading = false;
          $('.active-ads-listings-container').animate({ scrollTop: 0 }, 'fast');
        });
    };

    self._setTeamMembers = function () {
      $scope.data.teamMembers = map(
        appDataService.getTeamMembers(),
        function (member) {
          return {
            managerId: member.Manager.id,
            info: member.ManagerInfo
          };
        }
      );

      $scope.data.filters.selectedManager = find(
        $scope.data.teamMembers,
        function (member) {
          return member.managerId === user.id;
        }
      );
    };

    self._getCompanyPreferences = function () {
      return companyApi.getCompanyPreferences().then(function (response) {
        $scope.data.companyPreferences = response.data.preferences;
        $scope.data.hideResidentsForMultiFamily =
          !response.data.preferences.multi_family_residents_enabled;
        $scope.data.hideSyncToggle =
          !response.data.preferences.modify_syndication_enabled;
      });
    };

    self._getMyCommunities = function () {
      return managerApi.getMyCommunities().then(function (response) {
        $scope.data.communities = response.data.communities;
      });
    };

    self._getMyFloorplans = function () {
      return managerApi.getMyFloorplans().then(function (response) {
        $scope.data.floorplansByCommunity = groupBy(
          response.data.floorplans,
          'community_id'
        );
      });
    };

    self._getHotpadsSyncPreference = function () {
      $scope.data.gotSyncPrefs = false;

      companyApi.getSyncPreferences().then(function (response) {
        $scope.data.gotSyncPrefs = true;
        $scope.data.syncToHotpads =
          response.data.sync_preferences.hotpads === 'active';
      });
    };

    $scope.updateHotpadsSyncPreference = function () {
      var service = 'hotpads';
      var serviceStatus = $scope.data.syncToHotpads ? 'active' : 'inactive';
      companyApi.updateSyncPreferences(service, serviceStatus);

      throw (
        'feed sync preferences set to ' +
        serviceStatus +
        ' by ' +
        JSON.stringify(userService.getUser())
      );
    };

    appDataService.newUnitsRedirectTo404(self._initialize);
  };
  ActiveAdsController.$inject = [
    '$scope',
    '$timeout',
    '$q',
    '$location',
    '$routeParams',
    '$mdToast',
    'appDataService',
    'activeAdsService',
    'userService',
    'teamApi',
    'managerApi',
    'companyApi',
    'listingApi',
    'postFloorplanModalService',
    'listingReadyModalFactory'
  ];

  app.controller('ActiveAdsController', ActiveAdsController);

  app.factory('shareListingModalFactory', [
    '$modal',
    'userService',
    function ($modal, userService) {
      var self = this;

      self.openShareModal = function (listing) {
        $modal.open({
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl:
            '/angular/views/listing/listingIndex/shareListingModal.html',
          controller: 'ShareListingModalController',
          size: 'sm',
          resolve: {
            listing: function () {
              return listing;
            },
            manager: function () {
              return userService.getUser();
            }
          }
        });
      };

      return self;
    }
  ]);
})(window.angular, window.jQuery);
