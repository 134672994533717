/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
require('../../core/scripts/todo/todo.module');
require('../../core/scripts/todo/todo.service');
require('../../core/scripts/todo/modules');
require('../../core/scripts/conversations/conversations.service');

require('../../core/scripts/todo/todoDashboard.controller');
require('../../core/scripts/todo/todoListItem.directive');
require('../../core/scripts/todo/todoIcon.directive');

require('../../core/scripts/todo/prospectFunctions.directive');
require('../../core/scripts/todo/residentFunctions.directive');
require('../../core/scripts/todo/prospectTodoCard.directive');
require('../../core/scripts/todo/residentTodoCard.directive');
require('../../core/scripts/todo/residentFollowupList.directive');
