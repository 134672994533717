import React, { FC } from 'react';

export const SettingsIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.546 2.438C10.9171 2.84995 11.4455 3.08514 12 3.08514C12.5545 3.08514 13.0829 2.84995 13.454 2.438L14.4 1.4C14.9556 0.785143 15.8379 0.586359 16.6035 0.903581C17.369 1.2208 17.8521 1.9854 17.81 2.813L17.739 4.213C17.7112 4.76527 17.9183 5.30354 18.309 5.69483C18.6997 6.08613 19.2377 6.29396 19.79 6.267L21.19 6.196C22.017 6.15523 22.7804 6.63886 23.0968 7.40407C23.4132 8.16928 23.2143 9.05078 22.6 9.606L21.558 10.546C21.1466 10.9176 20.9118 11.4461 20.9118 12.0005C20.9118 12.5549 21.1466 13.0834 21.558 13.455L22.6 14.395C23.2149 14.9506 23.4136 15.8329 23.0964 16.5985C22.7792 17.364 22.0146 17.8471 21.187 17.805L19.787 17.734C19.2335 17.7055 18.6938 17.913 18.3019 18.3049C17.91 18.6968 17.7025 19.2365 17.731 19.79L17.802 21.19C17.8387 22.0132 17.3563 22.7714 16.595 23.0869C15.8337 23.4024 14.9564 23.2078 14.4 22.6L13.459 21.559C13.0876 21.1476 12.5593 20.9128 12.005 20.9128C11.4507 20.9128 10.9224 21.1476 10.551 21.559L9.606 22.6C9.0504 23.2107 8.17154 23.4075 7.40854 23.0921C6.64554 22.7767 6.1622 22.0168 6.2 21.192L6.272 19.792C6.30047 19.2385 6.09302 18.6988 5.7011 18.3069C5.30918 17.915 4.76952 17.7075 4.216 17.736L2.816 17.807C1.98873 17.8502 1.22381 17.3681 0.905883 16.6031C0.587955 15.8382 0.785828 14.9559 1.4 14.4L2.441 13.46C2.85242 13.0884 3.08722 12.5599 3.08722 12.0055C3.08722 11.4511 2.85242 10.9226 2.441 10.551L1.4 9.606C0.787888 9.05075 0.590308 8.17096 0.906222 7.40729C1.22214 6.64363 1.98351 6.16054 2.809 6.2L4.209 6.271C4.76359 6.30016 5.30448 6.09243 5.69699 5.69954C6.0895 5.30665 6.2967 4.76557 6.267 4.211L6.2 2.81C6.16129 1.98487 6.64431 1.22412 7.40754 0.908163C8.17076 0.592207 9.05017 0.788946 9.606 1.4L10.546 2.438Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 16.501C14.4853 16.501 16.5 14.4863 16.5 12.001C16.5 9.51572 14.4853 7.501 12 7.501C9.51472 7.501 7.5 9.51572 7.5 12.001C7.5 14.4863 9.51472 16.501 12 16.501Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
