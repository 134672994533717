/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('adminTemplateEditor', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/settings/manager/admin-template-editor.html',
      controller: 'AdminTemplateEditorController'
    };
  });

  app.controller('AdminTemplateEditorController', [
    '$scope',
    '$mdToast',
    '$mdDialog',
    '$location',
    '$moment',
    'managerApi',
    'companyApi',
    'userService',
    '$q',
    'imageBucket',
    function (
      $scope,
      $mdToast,
      $mdDialog,
      $location,
      $moment,
      managerApi,
      companyApi,
      userService,
      $q,
      imageBucket
    ) {
      var self = this;
      var managerInfo, managerId;

      $scope.EMAIL_MAX_LENGTH = 1500;
      $scope.AUTO_RESPONSE_MAX_LENGTH = 1500;
      $scope.SMS_MAX_LENGTH = 140;
      $scope.SMS_MAX_LENGTH_CUSTOM = 150;
      $scope.updateFinished = true;
      $scope.imageBucket = imageBucket;

      //sms template preview static variables
      $scope.staticAppointment = [
        'Appt time: 3:04 pm Monday Mar 14',
        'View details or reschedule: http://bit.ly/1KvVhiz',
        'Add to calendar: http://bit.ly/1KvVhiz'
      ];
      $scope.staticHello = 'Hello Clay,';
      $scope.staticMessage = '*Message*';
      $scope.staticLink = 'http://bit.ly/1KvVhiz Thanks';

      self._initialize = function () {
        managerInfo = userService.getUser();
        managerId = managerInfo.id;
        $scope.managerFirstName = managerInfo.first_name;
        $scope.managerLastName = managerInfo.last_name;

        var p1 = self._getKnockEmailById(managerId);
        var p2 = self._getCompany();

        $q.all([p1, p2]).then(self._buildResponderUrl);

        self._getManagerTemplates().then(
          function (templates) {
            $scope.templates = templates.data.templates;

            var appointmentFollowup =
              $scope.templates['renter-appointment-followup'];

            $scope.postTourMessageDelay = find(
              $scope.postTourMessageDurations,
              function (duration) {
                return duration.minutes === appointmentFollowup.delay_minutes;
              }
            );
          },
          function () {
            self._fireToast(
              'There was an error while retrieving your templates'
            );
          }
        );
      };

      $scope.updateManagerTemplates = function () {
        self._updateManagerTemplates($scope.templates);
      };

      self._getManagerTemplates = function () {
        return managerApi.getManagerTemplates();
      };

      self._getKnockEmailById = function (managerId) {
        return managerApi
          .getKnockEmailById(managerId)
          .success(function (response) {
            $scope.knockEmail = response.knock_email;
            $scope.include_all_listings =
              response.knock_email.preferences.email_include_all_listings;
            $scope.mailerReminderDelay = find(
              $scope.mailerDurations,
              function (duration) {
                return (
                  duration.minutes ===
                  response.knock_email.preferences.reminder_delay_minutes
                );
              }
            );
          });
      };

      self._getCompany = function () {
        return companyApi.getMyCompany().success(function (response) {
          self.leasingTeam = response.leasing_team;
        });
      };

      $scope.$watch('include_all_listings', function () {
        self._buildResponderUrl();
      });

      self._buildResponderUrl = function () {
        if (
          isUndefined(self.leasingTeam) ||
          isUndefined($scope.knockEmail) ||
          isUndefined($scope.include_all_listings)
        ) {
          return;
        }

        var baseUrl = self.leasingTeam.url;

        $scope.responderUrl = $scope.include_all_listings
          ? baseUrl
          : baseUrl + '/' + $scope.knockEmail.email_relay.username;
      };

      $scope.mailerDurations = [
        { minutes: null, text: 'Never' },
        { minutes: 1, text: '1 minute' },
        { minutes: 15, text: '15 minutes' },
        { minutes: 30, text: '30 minutes' },
        { minutes: 60, text: '1 hour' },
        { minutes: 60 * 24, text: '24 hours' }
      ];

      self._updateManagerTemplates = function (newTemplates) {
        var includeAllListings = $scope.include_all_listings;

        $scope.updateFinished = false;

        var updateAutoResponderPromise = managerApi.updateAutoResponder(
          managerId,
          includeAllListings,
          $scope.mailerReminderDelay.minutes
        );
        var updateManagerTemplatesPromise =
          managerApi.updateManagerTemplates(newTemplates);

        var updateTemplatePromises = [
          updateAutoResponderPromise,
          updateManagerTemplatesPromise
        ];

        $q.all(updateTemplatePromises).then(
          function () {
            self._fireToast('Your templates have been saved!');
            $scope.updateFinished = true;
          },
          function () {
            self._fireToast('There was an error while saving your templates');
          }
        );
      };

      self._fireToast = function (message) {
        $mdToast.showSimple(message);
      };

      self._initialize();
    }
  ]);
})(window.angular);
