import React from 'react';

export const LockShieldIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.625 10.625C6.625 9.79657 7.29657 9.125 8.125 9.125H13.875C14.7034 9.125 15.375 9.79657 15.375 10.625V13.875C15.375 14.7034 14.7034 15.375 13.875 15.375H8.125C7.29657 15.375 6.625 14.7034 6.625 13.875V10.625Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 11.9375C10.8274 11.9375 10.6875 12.0774 10.6875 12.25C10.6875 12.4226 10.8274 12.5625 11 12.5625C11.1726 12.5625 11.3125 12.4226 11.3125 12.25C11.3127 12.1671 11.2799 12.0874 11.2212 12.0288C11.1626 11.9701 11.0829 11.9373 11 11.9375V11.9375"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.875 9.125V7.25C7.875 5.52411 9.27411 4.125 11 4.125C12.7259 4.125 14.125 5.52411 14.125 7.25V9.125"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 20.375C5.43667 18.8908 1.625 15.5917 1.625 9.75V1.625H20.375V9.75C20.375 15.5875 16.5692 18.8892 11 20.375Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
