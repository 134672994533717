import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../../../app/store';
import {
  ThemeProvider,
  Text,
  makeStyles,
  RadioGroup,
  Grid,
  Button as KnockButton,
  NamedColors
} from '@knockrentals/knock-shared-web';
import { colors } from '../../commonStyles/commonStyles';
import {
  FormControlLabel,
  Radio,
  SvgIcon,
  TextField,
  Button,
  CircularProgress
} from '@material-ui/core';
import { KnockCloseIcon } from '../../icons';
import { useManager } from 'app/services/manager/hooks';

const useStyles = makeStyles({
  title: {
    fontSize: '16px !important',
    fontWeight: 600,
    color: colors.defaultText,
    marginRight: '10px'
  },
  subTitle: {
    margin: '5px 0 10px'
  },
  button: {
    backgroundColor: '#2EB88F',
    '&:hover': {
      backgroundColor: '#2EB88F'
    },
    '& .MuiButton-label': {
      fontSize: '24px'
    },
    height: '36px',
    width: '90px'
  },
  container: {
    marginLeft: '18px'
  },
  textField: {
    marginBottom: '5px',
    minWidth: '360px',
    '& .MuiInputBase-root': {
      borderTop: '0',
      borderLeft: '0',
      borderRight: '0',
      border: '1px solid',
      borderRadius: '0',
      '&:focus': {}
    },
    '& .MuiInput-root': {
      borderBottom: `1px solid ${NamedColors.slate[300]}`
    },
    '& .Mui-focused.MuiInput-root': {
      borderBottom: '2px solid #00AF85'
    },
    '& input': {
      height: '30px',
      color: colors.defaultText
    }
  },
  formLabel: {
    '& .MuiFormControlLabel-label': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  defaultFormLabel: {
    color: colors.defaultText
  },
  closeIcon: {
    fontSize: '21px'
  },
  addButton: {
    height: '30px',
    minWidth: '0px',
    padding: '5px'
  },
  radioButton: {
    '& svg': {
      fill: NamedColors.denim[600],
      height: '20px',
      width: '20px'
    }
  },
  titleContainer: {
    display: 'flex',
    marginTop: '15px',
    alignItems: 'center'
  }
});

interface CallSettingsProps {
  setCallSettings: (
    outboundPhoneNumber: string | null,
    phoneNumbers: string[],
    isError: boolean
  ) => void;
}

interface OutboundNumber {
  [key: string]: string;
}
interface OutboundNumberError {
  [key: string]: number;
}

const CallSettings: FC<CallSettingsProps> = ({ setCallSettings }) => {
  const classes = useStyles();
  const [callOption, setCallOption] = useState<string>('knock');
  const [outboundNumbers, setOutboundNumbers] = useState<OutboundNumber>({});
  const [outboundNumbersErrors, setOutboundNumbersErrors] =
    useState<OutboundNumberError>({});

  const { callSettings, callSettingsLoading } = useManager();

  const handleChangeCallOption = (
    e: ChangeEvent<{ name?: string | undefined; value: string }>
  ) => {
    e.persist();
    setCallOption(e.target.value);
  };

  const getNumbers = (phoneNumber: string) =>
    phoneNumber
      .replace('(', '')
      .replace(')', '')
      .replace(' ', '')
      .replace('-', '')
      .replace('+1', '')
      .replace('+', '');

  const validator = (phoneNumber: string): string | undefined => {
    if (phoneNumber === '') {
      return phoneNumber;
    }
    const numbers = getNumbers(phoneNumber);
    if (isNaN(Number(numbers)) || getNumbers(phoneNumber).length > 10) {
      return undefined;
    }
    const phone = phoneNumber.split('');

    const positions: {
      [key: number]: string;
    } = {
      0: '(',
      4: ')',
      5: ' ',
      9: '-'
    };

    for (let i = 0; i < phone.length; i++) {
      const element = phone[i];
      if (positions[i] !== undefined && positions[i] !== element) {
        phone.splice(i, 0, positions[i]);
        return validator(phone.join(''));
      }
    }
    return phone.join('');
  };

  const option = useMemo<string | null>(() => {
    if (Object.keys(outboundNumbers).length === 0) {
      return null;
    }

    return callOption === 'knock'
      ? null
      : `+1${getNumbers(outboundNumbers[callOption])}`;
  }, [outboundNumbers, callOption]);
  const numbers = useMemo<string[]>(
    () =>
      Object.values(outboundNumbers).map(
        (phoneNumber) => `+1${getNumbers(phoneNumber)}`
      ),
    [outboundNumbers]
  );

  const handleChangeOutboundNumber = (
    e: ChangeEvent<{ name: string; value: string }>
  ) => {
    e.persist();

    const phone = validator(e.target.value.trim());

    setOutboundNumbers((prevState) => ({
      ...prevState,
      [e.target.name]: phone === undefined ? prevState[e.target.name] : phone
    }));
  };

  const handleAddExtraOutboundNumber = () => {
    setOutboundNumbers((prevState) => {
      const nameNumber =
        Object.keys(prevState).length === 0
          ? 0
          : Number(
              Object.keys(prevState)
                .sort()
                [Object.keys(prevState).length - 1].split('_')[1]
            );
      return { ...prevState, [`number_${nameNumber + 1}`]: '' };
    });
  };

  const handleDelete = (name: string) => {
    if (callOption === name) {
      setCallOption('knock');
    }

    setOutboundNumbers((prevState) => {
      const prev = { ...prevState };
      delete prev[name];
      return prev;
    });

    setOutboundNumbersErrors((prevState) => {
      const prev = { ...prevState };
      delete prev[name];
      return prev;
    });
  };

  const handleDetectError = (name: string, state?: number) => {
    if (getNumbers(outboundNumbers[name]).length < 10) {
      setOutboundNumbersErrors((prevState) => ({
        ...prevState,
        [name]: state || 0
      }));
    } else {
      setOutboundNumbersErrors((prevState) => {
        const prev = { ...prevState };
        delete prev[name];
        return prev;
      });
    }
  };

  useEffect(() => {
    setCallSettings(
      option,
      numbers,
      Object.keys(outboundNumbersErrors).length > 0
    );
  }, [option, numbers, outboundNumbersErrors, setCallSettings]);

  useEffect(() => {
    Object.keys(outboundNumbers).forEach((element) => {
      handleDetectError(element);
    });

    //If we add the handleDetectError a warning appears
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outboundNumbers]);

  useEffect(() => {
    if (callSettingsLoading === false) {
      const phoneNumbers = callSettings?.phoneNumbers || [];

      if (callSettings?.outboundPhoneNumber === null) {
        setCallOption('knock');
      } else {
        setCallOption(
          `number_${
            phoneNumbers.indexOf(callSettings?.outboundPhoneNumber || '') + 1
          }`
        );
      }

      setOutboundNumbers(
        phoneNumbers.reduce(
          (prev: OutboundNumber, number: string, i: number) => {
            prev[`number_${i + 1}`] =
              validator(getNumbers(number)) || getNumbers(number);
            return prev;
          },
          {}
        )
      );
    }

    //If we add the validator a warning appears
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSettings, callSettingsLoading]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.titleContainer}>
        <Text className={classes.title}>Call Settings</Text>{' '}
        {callSettingsLoading && <CircularProgress size={16} />}
      </Grid>
      <Grid item xs={12}>
        <Text className={classes.subTitle}>
          Set the default phone you want to use when placing outbound browser
          calls. Add up to 3 alternate phone numbers.
        </Text>
      </Grid>
      {!callSettingsLoading && (
        <Grid item xs={12}>
          <RadioGroup
            aria-labelledby="call-options-label"
            name="call-options"
            onChange={handleChangeCallOption}
            value={callOption}
          >
            <FormControlLabel
              value="knock"
              control={<Radio className={classes.radioButton} />}
              className={classes.defaultFormLabel}
              label="Knock Voice App"
            />
            {Object.keys(outboundNumbers).map((name: string) => (
              <FormControlLabel
                key={name}
                value={name}
                control={<Radio className={classes.radioButton} />}
                className={classes.formLabel}
                label={
                  <>
                    <TextField
                      className={classes.textField}
                      name={name}
                      value={outboundNumbers[name]}
                      onChange={handleChangeOutboundNumber}
                      onBlur={() => handleDetectError(name, 1)}
                      error={outboundNumbersErrors[name] === 1}
                      helperText={
                        outboundNumbersErrors[name] === 1
                          ? 'Please provide a valid phone number.'
                          : ''
                      }
                    />

                    <Button
                      className={classes.addButton}
                      onClick={() => handleDelete(name)}
                    >
                      <SvgIcon className={classes.closeIcon}>
                        <KnockCloseIcon />
                      </SvgIcon>
                    </Button>
                  </>
                }
              />
            ))}
          </RadioGroup>
        </Grid>
      )}

      {!callSettingsLoading && Object.keys(outboundNumbers).length < 3 && (
        <Grid item xs={12}>
          <KnockButton
            className={classes.button}
            onClick={handleAddExtraOutboundNumber}
          >
            +
          </KnockButton>
        </Grid>
      )}
    </Grid>
  );
};

export default (props: CallSettingsProps) => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <CallSettings {...props} />
      </ThemeProvider>
    </Provider>
  );
};
