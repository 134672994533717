import React, { FC } from 'react';

const TickMarkIcon: FC = () => {
  return (
    <svg
      width={18}
      height={13}
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.94053 6.68542L0.940535 6.68541L0.938137 6.68301C0.843845 6.58872 0.810761 6.50317 0.810761 6.38474C0.810761 6.26631 0.843846 6.18076 0.938136 6.08647C1.02453 6.00008 1.09673 5.97442 1.20401 5.97871C1.36151 5.98501 1.43777 6.03009 1.49415 6.08647L5.46687 10.0592L6.04017 10.6325L6.61346 10.0592L15.7345 0.938137C15.8288 0.843846 15.9144 0.810761 16.0328 0.810761C16.1512 0.810761 16.2368 0.843844 16.3311 0.938138C16.4106 1.0177 16.4438 1.09421 16.4388 1.22552C16.4318 1.40554 16.3812 1.48454 16.3311 1.53468L6.31817 11.5476C6.23755 11.6282 6.19163 11.6492 6.18498 11.6519C6.15596 11.6635 6.11093 11.675 6.04017 11.675C5.9694 11.675 5.92437 11.6635 5.89536 11.6519C5.88873 11.6492 5.84316 11.6284 5.76316 11.5486C5.76283 11.5482 5.76249 11.5479 5.76216 11.5476L0.94053 6.68542Z"
        fill="#4A61E8"
        stroke="#4A61E8"
        strokeWidth="1.62152"
      />
    </svg>
  );
};
export default TickMarkIcon;
