export default function configureAuthorization(app: any) {
  app.config([
    '$authProvider',
    'authApi',
    function ($authProvider: any, authApi: any) {
      $authProvider.tokenPrefix = authApi.tokenPrefix;
      $authProvider.tokenName = authApi.tokenName;
      $authProvider.loginUrl = authApi.host + '/auth/validate';
      $authProvider.signupUrl = authApi.host + '/auth/register';

      // Configure social login
      // Please DO NOT touch the / in the redirectUri. Facebook and LinkedIn will cry without it!
      var hostOrigin =
        window.location.origin ||
        window.location.protocol + '//' + window.location.host;

      $authProvider.facebook({
        clientId: authApi.facebook.clientId,
        redirectUri: hostOrigin + '/auth',
        url: authApi.host + '/auth/facebook',
        requiredUrlParams: ['display', 'scope', 'auth_type'],
        authType: 'rerequest'
      });

      $authProvider.linkedin({
        clientId: authApi.linkedin.clientId,
        redirectUri: hostOrigin + '/auth',
        url: authApi.host + '/auth/linkedin'
      });
    }
  ]);
}
