/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import angular from 'angular';

(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('listingPhotos', function () {
    return {
      restrict: 'E',
      scope: {
        listing: '='
      },
      templateUrl: '/angular/views/listing/directives/photos.html',
      controller: 'ListingPhotosController',
      controllerAs: 'listingPhotosCtrl'
    };
  });

  app.controller('ListingPhotosController', [
    '$scope',
    'photoDropService',
    function ($scope, photoDropService) {
      var self = this;

      self.photos = photoDropService.getCollection('listing');
    }
  ]);

  app.directive('editListingPhotos', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/listing/directives/edit-photos.html'
    };
  });
})();
