import '../../styles/prospects/ProspectsMissingFieldsForm.scss';

(function (angular) {
  var app = angular.module('knock-Conversations');

  const prospectMissingFieldsModalFactory = function ($mdDialog) {
    const self = this;

    self.openModal = function (prospect) {
      return $mdDialog.show({
        controller: 'ProspectMissingFieldsFormModalController',
        templateUrl:
          '/angular/views/prospects/prospect-missing-fields-form-modal.html',
        clickOutsideToClose: true,
        locals: {
          prospect
        }
      });
    };

    return self;
  };

  prospectMissingFieldsModalFactory.$inject = ['$mdDialog'];

  app.factory(
    'prospectMissingFieldsModalFactory',
    prospectMissingFieldsModalFactory
  );

  app.controller('ProspectMissingFieldsFormModalController', [
    '$scope',
    'prospectHistoryService',
    'appDataService',
    'prospect',
    function ($scope, prospectHistoryService, appDataService, prospect) {
      $scope.prospect = prospect;
      $scope.isSaving = false;
      $scope.isComplete = false;
      $scope.error = null;

      const name = $scope.prospect.profile.first_name;
      $scope.name =
        name != null && name.trim() !== ''
          ? name
          : $scope.prospect.profile.username;

      $scope.onSaveRequiredFields = async () => {
        $scope.isSaving = true;

        if ($scope.prospect.profile.target_move_date == null) {
          $scope.isSaving = false;
          $scope.error = 'Please selected a move date.';
          return;
        }

        try {
          await prospectHistoryService.updateProspect($scope.prospect);
          $scope.isSaving = false;
          $scope.isComplete = true;
          $scope.error = null;
        } catch (e) {
          $scope.error = 'An error occurred.';
          $scope.isSaving = false;
        }
      };
    }
  ]);
})(window.angular);
