import React, { FC, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Tooltip as ShareWebTooltip } from '@knockrentals/knock-shared-web';

const useStylesTooltip = makeStyles({
  tooltip: {
    'font-size': '10px',
    'font-weight': 600,
    'line-height': '14px',
    'max-width': '150px',
    padding: '4px 8px',
    top: (props: any) => props.verticalOffset
  }
});

interface TooltipProps {
  children: ReactElement<any, any>;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  title: string;
  verticalOffset?: string;
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  placement,
  title,
  verticalOffset
}) => (
  <ShareWebTooltip
    classes={useStylesTooltip({ verticalOffset })}
    placement={placement}
    title={title}
    arrow
  >
    {children}
  </ShareWebTooltip>
);
