import React, { FC } from 'react';

export const BatchEmailIcon: FC = () => {
  return (
    <g id="batch-email">
      <path
        id="Vector"
        d="M16.5 11.5V15.5C16.5 15.7652 16.3946 16.0196 16.2071 16.2071C16.0196 16.3946 15.7652 16.5 15.5 16.5H9.5C9.23478 16.5 8.98043 16.3946 8.79289 16.2071C8.60536 16.0196 8.5 15.7652 8.5 15.5V11.5M16.5 11.5C16.5 11.2348 16.3946 10.9804 16.2071 10.7929C16.0196 10.6054 15.7652 10.5 15.5 10.5H9.5C9.23478 10.5 8.98043 10.6054 8.79289 10.7929C8.60536 10.9804 8.5 11.2348 8.5 11.5M16.5 11.5L13.03 13.6667C12.8711 13.766 12.6874 13.8187 12.5 13.8187C12.3126 13.8187 12.1289 13.766 11.97 13.6667L8.5 11.5M8.63467 8.49667C8.1388 7.88376 7.47421 7.42958 6.72303 7.19027C5.97185 6.95095 5.16701 6.937 4.40798 7.15012C3.64896 7.36325 2.96902 7.79411 2.4522 8.38947C1.93539 8.98482 1.60435 9.71856 1.5 10.5M15 8.5C15.002 7.87498 14.8081 7.26504 14.4455 6.75595C14.0829 6.24687 13.5698 5.86425 12.9785 5.6619C12.3871 5.45956 11.7472 5.44766 11.1487 5.62789C10.5502 5.80812 10.0233 6.17141 9.642 6.66667M7.75 3.75C7.75 4.99264 6.74264 6 5.5 6C4.25736 6 3.25 4.99264 3.25 3.75C3.25 2.50736 4.25736 1.5 5.5 1.5C6.74264 1.5 7.75 2.50736 7.75 3.75ZM13.75 3.25C13.75 4.2165 12.9665 5 12 5C11.0335 5 10.25 4.2165 10.25 3.25C10.25 2.2835 11.0335 1.5 12 1.5C12.9665 1.5 13.75 2.2835 13.75 3.25Z"
        stroke="#5C5C5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  );
};
