// @ts-nocheck
import { /* posthog , */ POSTHOG_NO_CAPTURE } from 'posthog-js';
import md5 from 'md5';
import { toSnake } from 'convert-keys';
// models
import { UISConfigData, UISEventData, UISTransformedEventData } from './models';

/**
 * Initializes the PostHog client. Reads directly from the REACT_APP_POSTHOG_TOKEN env var.
 * Includes user identification.
 *
 * Source: https://docs.posthog.com/#/integrations/js-integration?id=identifying-users
 */
export const init = (config: UISConfigData): void => {
  console.log('Initialized posthog (no op)');
  return;
  //const { token, host, env, product, user, ...rest } = config;
  //try {
  //  posthog.init(token, {
  //    api_host: host,
  //    loaded: () => {
  //      posthog.identify(user.id);
  //    }
  //  });

  //  // set additional user data if provided
  //  user && posthog.people.set(toSnake({ ...user }));

  //  // set static properties to appear on each capture call
  //  rest
  //    ? posthog.register(toSnake({ env, product, ...rest }))
  //    : posthog.register(toSnake({ env, product }));
  //} catch (error) {
  //  // TODO: web-shared error handling?
  //  console.error(error);
  //}
};

/**
 * Called on logout.  Wipes the stored distinct id's from the previously logged-in user.  This is helpful
 * when multiple users share the same machine.  By calling reset(), we do not have to worry about different user logins
 * being grouped together under the same actions.
 *
 * Source: https://docs.posthog.com/#/integrations/js-integration?id=reset-after-logout
 */
export const reset = (): void => {
  console.log('Reset posthog (no op)');
  return;
  //try {
  //  posthog.reset();
  //} catch (error) {
  //  // TODO: web-shared error handling?
  //  console.error(error);
  //}
};

/**
 * Capture any custom event data to posthog. Event object data is provided by Product team.
 *
 * Source: https://docs.posthog.com/#/integrations/js-integration?id=sending-events
 *
 * @param interaction { UISEventData } object of key/value pairs to be attached to the event instance.
 * @example
*     tagEvent({
        featureProduct: 'insights',
        featureClicked: 'button_send_report',
        featureParent: 'button_share_report',
        featureGrandparent: 'row_expand_property',
        value: true,
    })
 */
export const tagEvent = (event: UISEventData): void => {
  const transformedEvent: UISTransformedEventData = toSnake(
    _transformEvent(event)
  );

  try {
    // posthog.capture(event.featureClicked, { ...transformedEvent });
    console.group('posthog');
    console.log('Got event:', event.featureClicked, transformedEvent);
    console.log('...but not sending');
    console.groupEnd();
  } catch (error) {
    // TODO: web-shared error handling?
    console.error(error);
  }
};

/**
 * Signifies to UIS module to ignore the auto-capture of the event interaction.  Allows for
 * the sending of only custom captureEvents which will prevent the out-of-the-box events from
 * firing to posthog
 *
 * Returns the string 'posthog-no-capture' if included in the className prop on a component.
 * Note - to use this function on a react component, you'll need the clsx library.  See example.
 *
 * Source: https://docs.posthog.com/#/integrations/js-integration?id=ignorecensure-elements
 *
 * If not a react component, include noCapture() within the classes on a DOM element.
 * @example
 *   <TextField
 *     className={clsx(noCapture())}
 *     variant="standard"
 *     label="Email"
 *     fullWidth
 *     InputLabelProps={{ shrink: true }}
 *     placeholder={'knock@knockrentals.com'}
 *   />
 */
export const noCapture = (): string | null => {
  return POSTHOG_NO_CAPTURE;
};

/**
 * Private helper function to add unique identifiers to raw uis event data.
 */
const _transformEvent = (event: UISEventData): UISTransformedEventData => {
  const { featureGrandparent, featureParent, featureClicked } = event;

  // hash parent feature + grandparent feature
  const referrerParentId = featureGrandparent
    ? md5(`${featureParent}-${featureGrandparent}`)
    : md5(`${featureParent}`);

  // hash clicked feature with above hashed parent + grandparent
  const referrerId = md5(`${featureClicked}-${referrerParentId}`);

  return {
    ...event,
    referrerId,
    referrerParentId
  };
};
