import React, { FC } from 'react';

export const NewCalendarIcon: FC = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3125 5.8125H4.6875C4.06618 5.8125 3.5625 6.31618 3.5625 6.9375V19.3125C3.5625 19.9338 4.06618 20.4375 4.6875 20.4375H19.3125C19.9338 20.4375 20.4375 19.9338 20.4375 19.3125V6.9375C20.4375 6.31618 19.9338 5.8125 19.3125 5.8125Z"
      stroke="#5C5C5C"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5625 10.3125H20.4375"
      stroke="#353950"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.0625 7.5V3.5625"
      stroke="#353950"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9375 7.5V3.5625"
      stroke="#353950"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.21875 13.6875C7.06342 13.6875 6.9375 13.5616 6.9375 13.4062C6.9375 13.2509 7.06342 13.125 7.21875 13.125"
      stroke="#353950"
      strokeWidth="1.125"
    />
    <path
      d="M7.21875 13.6875C7.37408 13.6875 7.5 13.5616 7.5 13.4062C7.5 13.2509 7.37408 13.125 7.21875 13.125"
      stroke="#353950"
      strokeWidth="1.125"
    />
    <path
      d="M7.21875 17.625C7.06342 17.625 6.9375 17.4991 6.9375 17.3438C6.9375 17.1884 7.06342 17.0625 7.21875 17.0625"
      stroke="#353950"
      strokeWidth="1.125"
    />
    <path
      d="M7.21875 17.625C7.37408 17.625 7.5 17.4991 7.5 17.3438C7.5 17.1884 7.37408 17.0625 7.21875 17.0625"
      stroke="#353950"
      strokeWidth="1.125"
    />
    <path
      d="M12 13.6875C11.8447 13.6875 11.7188 13.5616 11.7188 13.4062C11.7188 13.2509 11.8447 13.125 12 13.125"
      stroke="#353950"
      strokeWidth="1.125"
    />
    <path
      d="M12 13.6875C12.1553 13.6875 12.2812 13.5616 12.2812 13.4062C12.2812 13.2509 12.1553 13.125 12 13.125"
      stroke="#353950"
      strokeWidth="1.125"
    />
    <path
      d="M12 17.625C11.8447 17.625 11.7188 17.4991 11.7188 17.3438C11.7188 17.1884 11.8447 17.0625 12 17.0625"
      stroke="#353950"
      strokeWidth="1.125"
    />
    <path
      d="M12 17.625C12.1553 17.625 12.2812 17.4991 12.2812 17.3438C12.2812 17.1884 12.1553 17.0625 12 17.0625"
      stroke="#353950"
      strokeWidth="1.125"
    />
    <path
      d="M16.7812 13.6875C16.6259 13.6875 16.5 13.5616 16.5 13.4062C16.5 13.2509 16.6259 13.125 16.7812 13.125"
      stroke="#353950"
      strokeWidth="1.125"
    />
    <path
      d="M16.7812 13.6875C16.9366 13.6875 17.0625 13.5616 17.0625 13.4062C17.0625 13.2509 16.9366 13.125 16.7812 13.125"
      stroke="#353950"
      strokeWidth="1.125"
    />
    <path
      d="M16.7812 17.625C16.6259 17.625 16.5 17.4991 16.5 17.3438C16.5 17.1884 16.6259 17.0625 16.7812 17.0625"
      stroke="#353950"
      strokeWidth="1.125"
    />
    <path
      d="M16.7812 17.625C16.9366 17.625 17.0625 17.4991 17.0625 17.3438C17.0625 17.1884 16.9366 17.0625 16.7812 17.0625"
      stroke="#353950"
      strokeWidth="1.125"
    />
  </svg>
);
