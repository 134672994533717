import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { colors } from './commonStyles/commonStyles';
import { makeStyles, NamedColors, Text } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginTop: '14px',
    border: `1px solid ${NamedColors.slate[200]}`,
    boxShadow: 'none'
  },
  tableContainer: {
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      borderRadius: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: NamedColors.slate[700]
    },
    '&::-webkit-scrollbar-track-piece:start': {
      marginTop: '50px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '4px'
    }
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px'
  },
  title: {
    color: colors.defaultText,
    fontSize: '18px',
    lineHeight: '24px',
    textTransform: 'none'
  },
  subTitle: {
    color: colors.disabled,
    fontSize: '12px',
    fontWeight: 600
  },
  tbodyBox: {
    height: '200px',
    overflow: 'auto'
  },
  table: {
    '& .MuiTableCell-root': {
      padding: '16px'
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-body': {
          color: colors.defaultText,
          fontWeight: '400'
        }
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiTableCell-root': {
        padding: '0px'
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-body': {
            fontSize: '8px'
          }
        }
      }
    },
    [theme.breakpoints.up('sm')]: {
      '& .MuiTableCell-root': {
        padding: '0px'
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-body': {
            fontSize: '10px'
          }
        }
      }
    },
    [theme.breakpoints.up('md')]: {
      '& .MuiTableCell-root': {
        padding: '0px'
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-body': {
            fontSize: '10px'
          }
        }
      }
    },
    [theme.breakpoints.up('lg')]: {
      '& .MuiTableCell-root': {
        padding: '0px'
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-body': {
            fontSize: '12px'
          }
        }
      }
    },
    [theme.breakpoints.up('xl')]: {
      '& .MuiTableCell-root': {
        padding: '10px'
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-body': {
            fontSize: '14px'
          }
        }
      }
    }
  },
  tableBody: {
    overflow: 'auto'
  },
  tableHeadCell: {
    color: colors.defaultText,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',
      '& .MuiTableCell-root': {
        padding: '5px'
      }
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '10px',
      '& .MuiTableCell-root': {
        padding: '5px'
      }
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '12x',
      '& .MuiTableCell-root': {
        padding: '5px'
      }
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px',
      '& .MuiTableCell-root': {
        padding: '5px'
      }
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '14px',
      '& .MuiTableCell-root': {
        padding: '5px'
      }
    }
  },
  tableHeadCellLoading: {
    color: colors.disabled,
    fontSize: '14px',
    fontWeight: 600
  },
  skeletonCell: {
    width: '700px'
  },
  skeletonLoadingIndicator: {
    backgroundColor: NamedColors.slate[200],
    borderRadius: '10px',
    height: '20px',
    '&::after': {
      background: `linear-gradient(90deg, transparent, ${NamedColors.slate[300]}, transparent)`
    }
  },
  subTitle2: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',
      padding: '2px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '10px',
      padding: '4px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '12x',
      padding: '5px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '12px',
      padding: '10px'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '14px',
      padding: '14px'
    }
  }
}));

export interface ColumnType {
  id: string;
  label: string;
  minWidth?: string;
  align?: 'right';
  sortable?: boolean;
  value: (row: any) => string | ReactElement;
}

interface TableProps {
  columns: ColumnType[];
  loading: boolean;
  rows: any;
  handleSort?: (id: string) => void;
  sortColumn?: string | null;
  sortOrder?: string;
}

const loaders = Array(12).fill(1);

const Table: FC<TableProps> = ({
  columns,
  loading,
  rows,
  handleSort,
  sortColumn,
  sortOrder
}) => {
  const classes = useStyles();

  const containerRef = useRef<HTMLElement | null>(null);
  const tableRef = useRef<HTMLElement | null>(null);

  const [readyToDisplay, setReadyToDisplay] = useState<boolean>(false);

  useEffect(() => {
    //Using flex to fill the remaing space also makes the table to expand with the number of items on it
    //so it is needed to set a fixed height at the beginning
    if (containerRef.current && tableRef.current) {
      tableRef.current.setAttribute(
        'style',
        `height:${containerRef.current?.getBoundingClientRect().height}px `
      );
      setReadyToDisplay(true);
    }
  }, [containerRef, tableRef]);

  return (
    <Paper className={classes.paper} ref={containerRef}>
      <TableContainer innerRef={tableRef} className={classes.tableContainer}>
        <MuiTable
          stickyHeader
          aria-label="sticky table"
          className={classes.table}
        >
          <TableHead>
            <TableRow>
              {columns?.map((cell: ColumnType, i: number) => {
                const labelElement = (
                  <Text variant="subtitle2" className={classes.subTitle2}>
                    {cell.label ?? ''}
                  </Text>
                );
                return (
                  <TableCell key={`cell-` + i}>
                    {cell?.sortable ? (
                      <TableSortLabel
                        onClick={() =>
                          handleSort ? handleSort(cell.id) : undefined
                        }
                        active={cell?.id === sortColumn}
                        direction={
                          sortOrder === 'asc'
                            ? 'asc'
                            : sortOrder === 'desc'
                            ? 'desc'
                            : undefined
                        }
                      >
                        {labelElement}
                      </TableSortLabel>
                    ) : (
                      labelElement
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {loading &&
              readyToDisplay &&
              loaders.map((_v: any, i: number) => (
                <TableRow key={i}>
                  <TableCell
                    colSpan={columns.length}
                    className={`rowLoader ${classes.skeletonCell}`}
                  >
                    <Skeleton
                      variant="rect"
                      data-testid="loading-skeleton"
                      className={classes.skeletonLoadingIndicator}
                    />
                  </TableCell>
                </TableRow>
              ))}
            {readyToDisplay &&
              rows?.map((row: any, index: number) => (
                <TableRow key={index}>
                  {columns.map((column) => {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth || 'auto' }}
                      >
                        {column.value(row)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  );
};
export default Table;
