import React, { FC, useEffect, useState } from 'react';

import { Box, makeStyles, NamedColors } from '@knockrentals/knock-shared-web';
import { Property, AmenitiesSection } from '../models';
import {
  BuildingNatureIcon,
  ParkingIcon,
  LockShieldIcon,
  PetIcon,
  GaugeIcon,
  FlowerIcon,
  LaundryIcon,
  BlankAmenitiesTabIcon
} from '../../icons';
import LeasingBinderNoContent from '../LeasingBinderNoContent';
import { AmenitiesCard } from './AmenitiesCard';

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px'
  },

  columns: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    width: '100%'
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '33.33%'
  },

  card: {
    border: `1px solid ${NamedColors.slate[100]}`,
    marginBottom: '16px',

    '&:last-child': {
      marginBottom: 0
    },

    '& .MuiCardContent-root:last-child': {
      paddingBottom: '16px'
    }
  }
}));

interface AmenitiesSectionType {
  [key: string]: AmenitiesSection[];
}

export interface LeasingBinderAmenitiesProps {
  property: Property;
}

export const LeasingBinderAmenities: FC<LeasingBinderAmenitiesProps> = ({
  property
}) => {
  const classes = useStyles();
  const [columnSections, setColumnSections] = useState<AmenitiesSectionType>(
    {}
  );

  useEffect(() => {
    const getSection = (key: string) => {
      let section: AmenitiesSection | undefined;
      let sectionItems = new Array<string>();

      switch (key) {
        case 'communityFacilities':
          if (property.amenities.recreation) {
            for (const item of property.amenities.recreation) {
              if (item.available) {
                sectionItems.push(item.name);
              }
            }

            if (sectionItems.length > 0) {
              section = {
                title: 'COMMUNITY FACILITIES',
                icon: <BuildingNatureIcon />,
                items: sectionItems
              };
            }
          }

          break;
        case 'pets':
          if (property.pets.allowed) {
            const keys = Object.keys(property.pets.allowed);

            for (const key of keys) {
              if (property.pets.allowed[key]) {
                sectionItems.push(key.replace('_', ' '));
              }
            }
          }

          if (
            sectionItems.length > 0 ||
            property.pets.notes ||
            property.pets.fee
          ) {
            section = {
              title: 'PETS',
              icon: <PetIcon />,
              items: sectionItems,
              notes: property.pets.notes,
              additionalInfo: property.pets.fee ? 'Additional Fees' : undefined
            };
          }

          break;
        case 'parking':
          if (property.parking.types) {
            for (const item of property.parking.types) {
              if (item.available) {
                sectionItems.push(item.name);
              }
            }
          }

          if (sectionItems.length > 0 || property.parking.notes) {
            section = {
              title: 'PARKING',
              icon: <ParkingIcon />,
              items: sectionItems,
              notes: property.parking.notes
            };
          }

          break;
        case 'security':
          if (property.amenities.security) {
            for (const item of property.amenities.security) {
              if (item.available) {
                sectionItems.push(item.name);
              }
            }

            if (sectionItems.length > 0) {
              section = {
                title: 'SECURITY',
                icon: <LockShieldIcon />,
                items: sectionItems
              };
            }
          }

          break;
        case 'laundry':
          if (property.laundry.types) {
            for (const item of property.laundry.types) {
              if (item.available) {
                sectionItems.push(item.name);
              }
            }
          }

          if (sectionItems.length > 0 || property.laundry.notes) {
            section = {
              title: 'LAUNDRY',
              icon: <LaundryIcon />,
              items: sectionItems,
              notes: property.laundry.notes
            };
          }

          break;
        case 'appliances':
          if (property.appliances) {
            for (const item of property.appliances) {
              if (item.available) {
                sectionItems.push(item.name);
              }
            }

            if (sectionItems.length > 0) {
              section = {
                title: 'APPLIANCES',
                icon: <GaugeIcon />,
                items: sectionItems
              };
            }
          }

          break;
        case 'externalAmenities':
          if (property.amenities.exterior) {
            for (const item of property.amenities.exterior) {
              if (item.available) {
                sectionItems.push(item.name);
              }
            }

            if (sectionItems.length > 0) {
              section = {
                title: 'EXTERNAL AMENITIES',
                icon: <FlowerIcon />,
                items: sectionItems
              };
            }
          }

          break;
      }

      return section;
    };

    let newSections: AmenitiesSectionType = {};
    const keys = [
      'communityFacilities',
      'laundry',
      'pets',
      'security',
      'appliances',
      'parking',
      'externalAmenities'
    ];

    let section: AmenitiesSection | undefined;
    let index = 0;

    for (const key of keys) {
      section = getSection(key);

      if (section) {
        if (newSections[index % 3] === undefined) {
          newSections[index % 3] = [];
        }
        newSections[index % 3].push(section);
        index++;
      }
    }
    setColumnSections(newSections);
  }, [property]);

  return (
    <Box className={classes.mainContainer} data-testid="AmenitiesSectionTitle">
      {Object.keys(columnSections).length === 0 ? (
        <LeasingBinderNoContent
          icon={<BlankAmenitiesTabIcon />}
          primaryText="No amenity information yet!"
        />
      ) : (
        <Box className={classes.columns}>
          {Object.keys(columnSections).map((key: string) => (
            <Box className={classes.column} key={key}>
              {columnSections[key].map((section: AmenitiesSection) => (
                <AmenitiesCard
                  key={section.title}
                  section={section}
                  className={classes.card}
                />
              ))}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
