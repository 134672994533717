import React from 'react';

export const NoSearchResultsIcon = () => (
  <svg
    width="122"
    height="121"
    viewBox="0 0 122 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.1651 5.29049C60.0827 -4.87697 37.4157 1.6684 27.5368 19.91C23.9326 26.5652 22.5285 33.8579 23.0771 40.9307C15.421 43.2928 8.64793 48.5566 4.49803 56.2194C-4.00074 71.9125 1.72034 91.7252 17.2765 100.472L45.5579 116.374C61.114 125.121 80.6144 119.491 89.1131 103.797C90.4082 101.406 91.373 98.9191 92.0232 96.3901C101.97 98.6393 112.543 94.154 117.67 84.6877C123.917 73.1526 119.711 58.5895 108.277 52.16L98.6116 46.7253C101.597 31.0382 94.4497 14.4471 79.8519 6.23896L78.1651 5.29049Z"
      fill="#F5F6FF"
    />
    <g clipPath="url(#clip0_2576_296530)">
      <path
        d="M58.0351 54.0723C59.5086 55.2727 61.3326 55.9613 63.2318 56.0341C65.1311 56.1068 67.0024 55.5598 68.5634 54.4756C69.0914 54.137 69.7242 54.001 70.3446 54.093C70.9651 54.1849 71.5313 54.4984 71.9384 54.9756L77.9218 61.7073C79.1424 63.0802 79.8167 64.8535 79.8168 66.6906V76.0406C79.8168 76.7036 79.5534 77.3395 79.0845 77.8084C78.6157 78.2772 77.9798 78.5406 77.3168 78.5406C76.6537 78.5406 76.0178 78.2772 75.549 77.8084C75.0801 77.3395 74.8168 76.7036 74.8168 76.0406V66.6906C74.8181 66.0763 74.5933 65.483 74.1851 65.0239L69.4601 59.7106C67.1124 60.8224 64.4981 61.2479 61.919 60.938C59.3399 60.628 56.9009 59.5953 54.8834 57.9589"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.3168 43.5405H42.3168V53.5405H47.3168V43.5405Z"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.0668 49.7905L47.3168 52.2905V44.7905L56.0668 47.2905V49.7905Z"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.8168 51.5752V72.2902L43.5668 78.5402"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.8168 78.5405V72.2905L56.0668 78.5405"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.1918 51.0405C66.608 51.0405 68.5668 49.0818 68.5668 46.6655C68.5668 44.2493 66.608 42.2905 64.1918 42.2905C61.7755 42.2905 59.8168 44.2493 59.8168 46.6655C59.8168 49.0818 61.7755 51.0405 64.1918 51.0405Z"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2576_296530">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(41.0668 40.207)"
        />
      </clipPath>
    </defs>
  </svg>
);
