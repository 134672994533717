/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('ListingPreviewService', [
    '$window',
    'localCache',
    function ($window, localCache) {
      return {
        getListingToPreview: function () {
          return localCache.get('knock-listing-preview');
        },
        openListingPreview: function (listing) {
          localCache.put('knock-listing-preview', listing);
          $window.open('/manager/listing/preview');
        }
      };
    }
  ]);

  app.controller('ListingPreviewController', [
    '$scope',
    'ListingPreviewService',
    function ($scope, ListingPreviewService) {
      $scope.listing = ListingPreviewService.getListingToPreview();
    }
  ]);
})();
