import React, { FC, useState } from 'react';
import { Button } from '@material-ui/core';

import Modal from './Modal';
import MergeSteps from './MergeSteps';

import {
  MergeDefaultType,
  MergeOptionType,
  MergeProspectType,
  MergeDataType
} from './MergeData';
import MergeProspectSelection from './MergeProspectSelection';
import MergeFieldSelection from './MergeFieldSelection';

import './_MergeModal.scss';

interface MergeModalProps {
  proposedMaster: any;
  mergeOptions: MergeOptionType[] | null;
  toggleModal: () => void;
  showMergeModal: boolean;
  getMergeDecisions: (masterId: number, secondaryId: number) => MergeDataType;
  setMergeDecisions: (
    masterId: number,
    secondaryId: number,
    defaults: MergeDefaultType
  ) => void;
  onSuccess: () => void;
}

const MergeModal: FC<MergeModalProps> = ({
  proposedMaster,
  mergeOptions,
  toggleModal,
  showMergeModal,
  getMergeDecisions,
  setMergeDecisions,
  onSuccess
}) => {
  const [master, setMaster] = useState<MergeProspectType | null>(null);
  const [proposedSecondary, setProposedSecondary] =
    useState<MergeOptionType | null>(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [secondary, setSecondary] = useState<MergeProspectType | null>(null);
  const [defaults, setDefaults] = useState<MergeDefaultType | null>(null);
  const [locked, setLocked] = useState<string[]>([]);
  const [error, setError] = useState(false);

  if (!showMergeModal || !proposedMaster) {
    return null;
  }

  const fetchMergeDecisions = async () => {
    if (!proposedMaster || !proposedSecondary) {
      return;
    }

    try {
      const {
        decisions: { defaults, master, secondary, locked }
      } = await getMergeDecisions(proposedMaster.id, proposedSecondary.id);

      setDefaults(defaults);
      setMaster(master);
      setSecondary(secondary);
      setLocked(locked);
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  if (error) {
    return (
      <Modal
        style={{
          height: 'auto',
          minHeight: '200px',
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <div className="react-modal-body">
          <div
            style={{
              color: 'rgb(97, 26, 21)',
              backgroundColor: 'rgb(253,236,234)',
              padding: '6px 16px',
              lineHeight: '1.5'
            }}
          >
            There was a problem preparing these prospects for merging.
            <br />
            Please try again later.
          </div>
        </div>
        <div className="react-modal-footer">
          <Button
            onClick={() => toggleModal()}
            className="merge-modal-button secondary"
            variant="outlined"
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      style={{
        height: 'auto',
        minHeight: '350px',
        maxHeight: '90vh',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div className="react-modal-header" style={{ flex: '0' }}>
        <MergeSteps currentStep={currentStep} />
      </div>
      {currentStep === 1 && (
        <MergeProspectSelection
          proposedMaster={{
            id: proposedMaster.id,
            firstName: proposedMaster.profile.first_name,
            lastName: proposedMaster.profile.last_name,
            email: proposedMaster.profile.email,
            phoneNumber: proposedMaster.profile.formatted_phone_number,
            source: proposedMaster.profile.source,
            streamId: proposedMaster.stream_id
          }}
          proposedSecondary={proposedSecondary}
          mergeOptions={mergeOptions}
          setSecondary={setProposedSecondary}
          nextStep={fetchMergeDecisions}
          cancel={() => toggleModal()}
        />
      )}
      {secondary && currentStep === 2 && (
        <MergeFieldSelection
          master={master}
          secondary={secondary}
          defaults={defaults}
          setStep={setCurrentStep}
          currentStep={currentStep}
          locked={locked}
          setMergeDecisions={setMergeDecisions}
          toggleModal={toggleModal}
          onSuccess={onSuccess}
        />
      )}
    </Modal>
  );
};

export default MergeModal;
