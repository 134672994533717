/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
(function (angular, $) {
  'use strict';

  var app = angular.module('knock-MarketingTools');

  app.directive('marketingToolsCraigslist', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/marketingTools/marketing-tools-craigslist.html',
      controller: 'MarketingToolsCraigslistController',
      scope: {}
    };
  });

  app.controller('MarketingToolsCraigslistController', [
    '$scope',
    '$filter',
    '$mdToast',
    '$timeout',
    'marketingToolsApi',
    'emailValidatorService',
    function (
      $scope,
      $filter,
      $mdToast,
      $timeout,
      marketingToolsApi,
      emailValidatorService
    ) {
      var self = this;

      $scope.data = {
        propertyEmails: [],
        editingPropertyId: null,
        editedEmail: ''
      };

      self._initialize = function () {
        $scope.loadCommunitiesAndCraigslistAccounts();
      };

      $scope.loadCommunitiesAndCraigslistAccounts = function () {
        marketingToolsApi
          .getCommunitiesToCraigslistLogins()
          .then(function (response) {
            $scope.data.propertyEmails = map(
              response.data.communitiesToCraigslistAccounts,
              function (clData) {
                return clData;
              }
            );

            $scope.sortProperties();
          });
      };

      $scope.sortProperties = function () {
        $scope.data.propertyEmails.sort(function (propertyA, propertyB) {
          return propertyA.communityName > propertyB.communityName ? 1 : -1;
        });
      };

      $scope.startEditingCustomEmail = function (propertyData) {
        $scope.data.editingPropertyId = propertyData.propertyId;
        $scope.data.editedEmail = propertyData.customCraigslistEmail;
        $timeout(
          function () {
            $('#customEmailField').focus().val('').val($scope.data.editedEmail);
          },
          1,
          true
        );
      };

      $scope.stopEditing = function () {
        $scope.data.editingPropertyId = null;
      };

      $scope.saveEditedEmail = function (propertyData) {
        if (
          ($scope.data.editedEmail || !propertyData.customCraigslistEmail) &&
          !$scope.isValidEmail()
        ) {
          $mdToast.showSimple('Invalid email.');
          return;
        }

        if ($scope.data.editedEmail) {
          self._save(propertyData);
          return;
        }

        if (propertyData.customCraigslistEmail) {
          self._delete(propertyData);
        }
      };

      self._save = function (propertyData) {
        var payload = {
          propertyId: $scope.data.editingPropertyId,
          email: $scope.data.editedEmail
        };

        marketingToolsApi.setCraigslistCredentials(payload).then(
          function () {
            $mdToast.showSimple('Saved!');
            propertyData.customCraigslistEmail = $scope.data.editedEmail;
            $scope.stopEditing();
          },
          function (error) {
            if (error.status === 409) {
              $mdToast.showSimple(
                'Email is already in use at another property.'
              );
            }
          }
        );
      };

      self._delete = function (propertyData) {
        marketingToolsApi
          .deleteCraigslistCredentials(
            propertyData.customCraigslistEmailDeleteId
          )
          .then(
            function () {
              $mdToast.showSimple('Deleted!');
              propertyData.customCraigslistEmail = '';
              $scope.stopEditing();
            },
            function () {
              $mdToast.showSimple('Error removing email.');
            }
          );
      };

      $scope.isValidEmail = function () {
        return emailValidatorService.validate($scope.data.editedEmail);
      };

      self._initialize();
    }
  ]);
})(window.angular, window.jQuery);
