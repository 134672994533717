import React, { FC } from 'react';

export const QuickRepliesIcon: FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5625 8.89225C1.6453 7.69927 1.99871 6.5408 2.59591 5.50474C3.19312 4.46869 4.01844 3.58225 5.00927 2.91267C6.0001 2.24309 7.13041 1.80796 8.31445 1.64029C9.4985 1.47262 10.7052 1.57681 11.843 1.94496C12.9807 2.31311 14.0197 2.93555 14.8811 3.76506C15.7425 4.59457 16.4037 5.60936 16.8144 6.73245C17.2252 7.85554 17.3748 9.05744 17.2519 10.247C17.129 11.4365 16.7368 12.5824 16.105 13.5978L18.4172 18.4548L13.5618 16.1425C13.1392 16.4039 12.6933 16.6256 12.2298 16.8048M8.08911 8.32967L2.77589 13.4982C2.72397 13.5488 2.68862 13.6132 2.6743 13.6833C2.65999 13.7534 2.66735 13.8261 2.69545 13.8921C2.72356 13.9581 2.77116 14.0146 2.83223 14.0543C2.8933 14.0941 2.9651 14.1153 3.03858 14.1154L5.12025 14.1154L5.12025 18.4547L10.4335 13.2861C10.4854 13.2355 10.5207 13.1711 10.5351 13.101C10.5494 13.0309 10.542 12.9583 10.5139 12.8922C10.4858 12.8262 10.4382 12.7697 10.3771 12.73C10.3161 12.6903 10.2442 12.669 10.1708 12.669H8.08911L8.08911 8.32967Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
