import { useState } from 'react';
import { AlertProps } from '@knockrentals/knock-shared-web/dist/components/Alert/Alert';

type Severity = AlertProps['severity'];

export const usePopupSnackbar = () => {
  const [popupSeverity, setPopupSeverity] = useState<Severity>('info');
  const [popupMessage, setPopupMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const popupSnackbar = {
    popupMessage,
    popupSeverity,
    isOpen: snackbarOpen,
    setMessage: (msg: string, severity: Severity = 'info') => {
      setPopupSeverity(severity);
      setPopupMessage(msg);
      setSnackbarOpen(true);
    },
    close: () => setSnackbarOpen(false),
    open: () => setSnackbarOpen(true)
  };

  return popupSnackbar;
};

export type PopupSnackbarType = ReturnType<typeof usePopupSnackbar>;
