/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('listingPreview', [
    'editListingCompletionHandler',
    function (editListingCompletionHandler) {
      return {
        restrict: 'E',
        scope: {
          listing: '=',
          saveHandler: '=',
          isSaving: '=',
          saveError: '=',
          saveSuccess: '='
        },
        templateUrl: '/angular/views/listing/directives/preview.html',
        link: function (scope) {
          scope.completionHandler = editListingCompletionHandler.listing;
        }
      };
    }
  ]);
})();
