/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('shareApi', [
    'apiBase',
    function (apiBase) {
      return {
        sendEmail: function (recipientEmail, listingId) {
          var payload = {
            to: recipientEmail,
            listingId: listingId
          };

          return apiBase.post('/share/email', payload);
        },
        makeListingShortLink: function (listingId) {
          var payload = {
            listingId: listingId
          };

          return apiBase.post('/share/shortlink/listing', payload);
        }
      };
    }
  ]);
})();
