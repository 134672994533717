/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  angular.module('knock-ResidentHistory', [
    'LocalStorageModule',
    'angular-momentjs',
    'smart-table'
  ]);
})(window.angular);
