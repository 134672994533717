/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('userInfoCard', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/user/user-info-card.html',
      scope: {
        userInfo: '=',
        listingId: '=',
        managerId: '=?',
        newChat: '=?',
        noProfileLink: '='
      },
      controller: [
        '$rootScope',
        '$scope',
        'schedulingRequirementsService',
        'renterProfileModalService',
        'userService',
        'conversationsService',
        'apiEvents',
        function (
          $rootScope,
          $scope,
          schedulingRequirementsService,
          renterProfileModalService,
          userService,
          conversationsService,
          apiEvents
        ) {
          var self = this;
          var user = userService.getScopedUser();

          $scope.isLoggedIn = !isEmpty(user);

          $scope.isMessageOwner = true;

          if (!isUndefined($scope.managerId)) {
            $scope.isMessageOwner = user.id === $scope.managerId;
          }

          self.userId = $scope.userInfo.renter_id || $scope.userInfo.manager_id;

          $scope.name =
            $scope.userInfo.first_name + ' ' + $scope.userInfo.last_name;

          if ($scope.name.length > 14) {
            $scope.name = $scope.name.substr(0, 14) + '...';
          }

          $scope.openChatThread = function () {
            if (!schedulingRequirementsService.requireLoggedIn()) {
              return;
            }

            schedulingRequirementsService
              .ensureRenterPhoneNumber()
              .then(function () {
                if ($scope.newChat) {
                  $scope.showChatBox = true;
                } else {
                  $scope.openRenterProfileModal();
                }
              });
          };

          $scope.openRenterProfileModal = function () {
            //visitorInteractionsService.openConversationByIds($scope.userInfo.renter_id, $scope.listingId, 'renter');
          };
        }
      ]
    };
  });
})(window.angular);
