import React from 'react';

const SuccessIcon = () => (
  <svg
    width="238"
    height="234"
    viewBox="0 0 238 234"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M153.257 9.14793C117.769 -10.4355 73.1245 2.45799 53.541 37.9464C46.4036 50.8805 43.5802 65.0308 44.5923 78.7408C29.4921 83.3825 16.1052 93.6616 7.86988 108.585C-8.97764 139.116 2.11466 177.523 32.6451 194.371L89.8211 225.922C120.352 242.77 158.759 231.677 175.607 201.147C178.159 196.522 180.07 191.716 181.369 186.831C201.005 191.269 222.001 182.479 232.215 163.969C244.599 141.528 236.446 113.297 214.004 100.913L194.467 90.132C200.468 59.6592 186.544 27.5163 157.894 11.7066L153.257 9.14793Z"
      fill="#F0F2FF"
    />
    <path
      d="M67 118.664L113.479 72.1463C114.167 71.4622 114.984 70.9206 115.882 70.5523C116.781 70.1841 117.743 69.9964 118.713 70.0001C119.684 70.0037 120.645 70.1986 121.54 70.5737C122.436 70.9487 123.248 71.4965 123.932 72.1857L170.45 118.664"
      stroke="#435CEF"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M155.671 103.901V163H81.7783V103.876"
      stroke="#435CEF"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M133.503 163V140.832C133.503 136.913 131.946 133.154 129.175 130.382C126.403 127.611 122.644 126.054 118.725 126.054C114.805 126.054 111.046 127.611 108.275 130.382C105.503 133.154 103.946 136.913 103.946 140.832V163"
      stroke="#435CEF"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M109.488 102.038C109.488 104.488 110.461 106.837 112.194 108.57C113.926 110.302 116.275 111.275 118.725 111.275C121.175 111.275 123.524 110.302 125.256 108.57C126.988 106.837 127.961 104.488 127.961 102.038C127.961 99.5887 126.988 97.2394 125.256 95.5072C123.524 93.775 121.175 92.8018 118.725 92.8018C116.275 92.8018 113.926 93.775 112.194 95.5072C110.461 97.2394 109.488 99.5887 109.488 102.038V102.038Z"
      stroke="#435CEF"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SuccessIcon;
