/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import includes from 'lodash/includes';
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.directive('sourceSelect', function () {
    return {
      restrict: 'E',
      template:
        '<md-input-container>' +
        '<label>Source</label>' +
        '<md-select ng-model="ngModel" ng-change="sourceChanged()">' +
        '<md-option ng-repeat="source in enabledSourceNames | orderBy" ng-value="source">{{source}}</md-option>' +
        '</md-select>' +
        '</md-input-container>',
      scope: {
        ngModel: '=',
        onChanged: '=?'
      },
      replace: true,
      controller: [
        '$scope',
        'appDataService',
        function ($scope, appDataService) {
          var self = this;

          $scope.onChanged = $scope.onChanged || function () {};

          $scope.sourceChanged = function () {
            $scope.onChanged($scope.ngModel);
          };

          self._initialize = function () {
            self._setSources();
          };

          self._setSources = function () {
            $scope.enabledSourceNames = map(
              appDataService.getSources(),
              'source_name'
            );

            if (
              $scope.ngModel &&
              !includes($scope.enabledSourceNames, $scope.ngModel)
            ) {
              $scope.enabledSourceNames.push($scope.ngModel);
            }
          };

          self._initialize();
        }
      ]
    };
  });
})(window.angular);
