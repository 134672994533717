/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('prospectPrintableEvent', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospects/prospect-printable-events.html',
      controller: 'ProspectPrintableEvent',
      replace: true,
      scope: {
        events: '=',
        prospect: '=',
        manager: '='
      }
    };
  });
  app.controller('ProspectPrintableEvent', [
    '$moment',
    '$filter',
    'timeService',
    '$scope',
    function ($moment, $filter, timeService, $scope) {
      var currencyFilter = $filter('currency');

      $scope.data = {
        events: []
      };

      function _getManagersFullName(manager) {
        return manager.first_name + ' ' + manager.last_name;
      }

      function _getJoinedShownUnits(units) {
        var joinedShownUnits = '';

        for (var i = 0; i < units.length; i++) {
          var shownUnit = units[i];
          if (i !== 0) {
            joinedShownUnits += ', ';
          }
          joinedShownUnits += shownUnit.property_unit.unit;
        }

        return joinedShownUnits;
      }

      function _getAppointmentMessage(event, manager) {
        var managersFullName = _getManagersFullName(manager);
        var isPastAppointment = timeService
          .get(event.start_time)
          .isBefore(timeService.get());

        var message = '';

        if (event.status === 'no-show') {
          message = 'Missed appointment';
        } else if (event.status === 'cancelled') {
          message = 'Cancelled appointment';
        } else {
          if (isPastAppointment) {
            message = 'Toured';
          } else {
            message = 'Touring';
          }
        }

        message += ' (Owner: ' + managersFullName + ').';

        return message;
      }

      function _getVisitMessage(event, manager) {
        var managersFullName = _getManagersFullName(manager);
        var message = 'Walk-in ';
        if (event.appointment_id) {
          message = 'Completed appointment ';
        }

        message += ' (Owner: ' + managersFullName;
        if (event.visit_property_units.length) {
          message +=
            ', Shown units: ' +
            _getJoinedShownUnits(event.visit_property_units);
        }
        message += ').';

        return message;
      }

      function _getReminderMessage(manager) {
        var managersFullName = _getManagersFullName(manager);
        return 'Follow up (Owner: ' + managersFullName + ').';
      }

      function _getAppointmentRequestMessage(prospect, manager) {
        var managersFullName = _getManagersFullName(manager);
        return (
          'Pending: Tour requested with ' +
          managersFullName +
          ' from ' +
          prospect.first_name +
          '.'
        );
      }

      function _getApplicationMessage(event) {
        var message = '';

        switch (event.application_event_type) {
          case 'applied':
            message = 'Application submitted.';
            break;
          case 'cancelled':
            message = 'Application cancelled.';
            break;
          case 'approved':
            message = 'Application approved.';
            break;
          case 'denied':
            message = 'Application denied.';
            break;
        }
        return message;
      }

      function _getQuoteMessage(event) {
        return (
          'Quote ID: ' +
          event.display_id +
          ' on ' +
          $moment(event.quote_date).format('ddd MM/DD/YYYY') +
          ', Unit: ' +
          event.unit +
          ' - ' +
          currencyFilter(event.quoted_rent, '$')
        );
      }

      function _getEventMessage(event, prospect, manager) {
        var message = '';

        switch (event.event_type) {
          case 'appointment':
            message = _getAppointmentMessage(event, manager);
            break;
          case 'reminder':
            message = _getReminderMessage(manager);
            break;
          case 'visit':
            message = _getVisitMessage(event, manager);
            break;
          case 'appointment-request':
            message = _getAppointmentRequestMessage(prospect, manager);
            break;
          case 'application':
            message = _getApplicationMessage(event);
            break;
          case 'quote':
            message = _getQuoteMessage(event);
            break;
          case 'leased':
            message = 'Lease signed.';
            break;
          case 'moved-in':
            message = 'Moved in.';
            break;
        }

        var eventMessage = {
          date: event.event_time,
          message: message
        };

        return eventMessage;
      }

      function initialize() {
        for (var i = 0; i < $scope.events.length; i++) {
          var event = $scope.events[i];
          var eventMessage = _getEventMessage(
            event,
            $scope.prospect,
            $scope.manager
          );
          $scope.data.events.push(eventMessage);
        }
      }

      initialize();
    }
  ]);
})(window.angular);
