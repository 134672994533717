import React, { FC } from 'react';

export const EditIcon: FC = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4917 5.50832C21.9183 4.93739 21.1405 4.61929 20.3314 4.62476C19.5223 4.63024 18.7489 4.95885 18.1833 5.53749L6.1 17.6208L4.625 23.375L10.3792 21.8992L22.4625 9.81582C23.0413 9.25049 23.37 8.47721 23.3755 7.66815C23.381 6.8591 23.0628 6.08144 22.4917 5.50832Z"
      stroke="#697FF7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8384 5.8833L22.1167 10.1616"
      stroke="#697FF7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.10083 17.62L10.3833 21.895"
      stroke="#697FF7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
