import React from 'react';

export const ProspectsIcon = () => (
  <svg
    width="122"
    height="121"
    viewBox="0 0 122 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.1651 5.29049C60.0827 -4.87697 37.4157 1.6684 27.5368 19.91C23.9326 26.5652 22.5285 33.8579 23.0771 40.9307C15.421 43.2928 8.64793 48.5566 4.49803 56.2194C-4.00074 71.9125 1.72034 91.7252 17.2765 100.472L45.5579 116.374C61.114 125.121 80.6144 119.491 89.1131 103.797C90.4082 101.406 91.373 98.9191 92.0232 96.3901C101.97 98.6393 112.543 94.154 117.67 84.6877C123.917 73.1526 119.711 58.5895 108.277 52.16L98.6116 46.7253C101.597 31.0382 94.4497 14.4471 79.8519 6.23896L78.1651 5.29049Z"
      fill="#F5F6FF"
    />
    <g clipPath="url(#clip0_2576_297694)">
      <path
        d="M48 53.2515C50.0711 53.2515 51.75 51.5725 51.75 49.5015C51.75 47.4304 50.0711 45.7515 48 45.7515C45.9289 45.7515 44.25 47.4304 44.25 49.5015C44.25 51.5725 45.9289 53.2515 48 53.2515Z"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48 55.7515C46.3424 55.7515 44.7527 56.4099 43.5806 57.582C42.4085 58.7541 41.75 60.3439 41.75 62.0015V65.7515H44.25L45.5 75.7515H50.5"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73 53.2515C75.0711 53.2515 76.75 51.5725 76.75 49.5015C76.75 47.4304 75.0711 45.7515 73 45.7515C70.9289 45.7515 69.25 47.4304 69.25 49.5015C69.25 51.5725 70.9289 53.2515 73 53.2515Z"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73 55.7515C74.6576 55.7515 76.2473 56.4099 77.4194 57.582C78.5915 58.7541 79.25 60.3439 79.25 62.0015V65.7515H76.75L75.5 75.7515H70.5"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.5 50.7515C63.2614 50.7515 65.5 48.5129 65.5 45.7515C65.5 42.99 63.2614 40.7515 60.5 40.7515C57.7386 40.7515 55.5 42.99 55.5 45.7515C55.5 48.5129 57.7386 50.7515 60.5 50.7515Z"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.25 62.0015C69.25 59.6808 68.3281 57.4552 66.6872 55.8143C65.0462 54.1733 62.8206 53.2515 60.5 53.2515C58.1794 53.2515 55.9538 54.1733 54.3128 55.8143C52.6719 57.4552 51.75 59.6808 51.75 62.0015V65.7515H55.5L56.75 78.2515H64.25L65.5 65.7515H69.25V62.0015Z"
        stroke="#4A61E8"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2576_297694">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(40.5 39.5015)"
        />
      </clipPath>
    </defs>
  </svg>
);
