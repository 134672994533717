import React, { FC } from 'react';
import { ThemeProvider } from '@knockrentals/knock-shared-web';
import ResultsNavigation from './ResultsNavigation';
import RangeBounds from './RangeBounds';
import RowsPerPageSelect from './RowsPerPageSelect';
import './_TablePaginationControls.scss';

interface TablePaginationProps {
  getRangeBounds(totalRows: number): { rowStart: number; rowEnd: number };
  onChangeResultsPageNavigation(value: number): void;
  onChangeRowsPerPage(value: unknown, allRowsSelected: boolean): void;
  pageNumber: number;
  rowsPerPage: number;
  totalRows: number;
  isPaginationEnabled: boolean;
}

const TablePagination: FC<TablePaginationProps> = ({
  getRangeBounds,
  onChangeResultsPageNavigation,
  onChangeRowsPerPage,
  pageNumber = 1,
  rowsPerPage,
  totalRows,
  isPaginationEnabled
}) => {
  return (
    <div className="table-pagination-controls">
      <RowsPerPageSelect
        onChangeRowsPerPage={onChangeRowsPerPage}
        selectedOptionValue={rowsPerPage}
        totalRows={totalRows}
        isPaginationEnabled={isPaginationEnabled}
      />

      {totalRows > 0 && (
        <React.Fragment>
          <RangeBounds
            rangeBounds={getRangeBounds(totalRows)}
            total={totalRows}
          />

          <ResultsNavigation
            onChangeResultsPageNavigation={onChangeResultsPageNavigation}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default (props: TablePaginationProps) => (
  <ThemeProvider>
    <TablePagination {...props} />
  </ThemeProvider>
);
