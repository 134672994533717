import React, { FC } from 'react';
export const AnalyticsIcon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 0.75V9.75H22.5C22.5 4.77944 18.4706 0.75 13.5 0.75Z"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4999 14.25V5.25C6.55931 5.25034 3.07719 7.81415 1.9068 11.5769C0.736407 15.3397 2.14995 19.4263 5.3951 21.6617C8.64026 23.8971 12.9623 23.7615 16.0609 21.327L10.4999 14.25Z"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 12.75H13.5L19.061 19.827C21.2341 18.1219 22.5023 15.5122 22.5 12.75Z"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
