import React, { FC, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';

import {
  Box,
  IconButton,
  makeStyles,
  NamedColors
} from '@knockrentals/knock-shared-web';
import { VerticalDotsIcon } from '../icons/VerticalDotsIcon';
import { PersonSecureIcon } from '../icons/PersonSecureIcon';
import { PhoneActionIcon } from '../icons/PhoneActionIcon';
import { AttachmentIcon } from '../icons/AttachmentIcon';
import { HappyIcon } from '../icons/HappyIcon';

const useStyles = makeStyles({
  menuIcon: {
    padding: '6px'
  },

  menu: {
    '& .MuiListItem-root': {
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0.4px',
      padding: '8px 16px 8px 16px',
      textAlign: 'left',

      color: NamedColors.slate[800],

      '& svg': {
        marginRight: '8px',
        width: '20px'
      },

      '&:focus': {
        color: NamedColors.slate[800],

        '& svg path': {
          stroke: NamedColors.slate[800]
        }
      }
    }
  }
});

export interface ConversationOverflowMenuItem {
  label: string;
  value: number;
  icon: string;
  disabled: boolean;
  visible: boolean;
}

export interface ConversationOverflowMenuProps {
  menuItems: ConversationOverflowMenuItem[];
  onMenuItemClick: (menuItem: ConversationOverflowMenuItem) => void;
}

export const ConversationOverflowMenu: FC<ConversationOverflowMenuProps> = ({
  menuItems,
  onMenuItemClick
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const getIcon = (icon: string): JSX.Element | undefined => {
    switch (icon) {
      case 'PersonSecure':
        return <PersonSecureIcon useNewDesign={true} />;
      case 'PhoneAction':
        return <PhoneActionIcon />;
      case 'Attachment':
        return <AttachmentIcon useNewDesign={true} />;
      case 'Emoji':
        return <HappyIcon />;
      default:
        return undefined;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (menuItem: ConversationOverflowMenuItem) => {
    onMenuItemClick(menuItem);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-label="action menu"
        aria-haspopup="true"
        className={classes.menuIcon}
        onClick={handleClick}
      >
        <VerticalDotsIcon />
      </IconButton>

      <Menu
        id="ConversationFooterActionMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        className={classes.menu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div>
          {menuItems.map((menuItem: ConversationOverflowMenuItem) => {
            if (menuItem.visible) {
              return (
                <MenuItem
                  key={menuItem.value}
                  disabled={menuItem.disabled}
                  onClick={() => handleMenuItemClick(menuItem)}
                >
                  {getIcon(menuItem.icon) || ''} {menuItem.label}
                </MenuItem>
              );
            } else {
              return null;
            }
          })}
        </div>
      </Menu>
    </Box>
  );
};
