/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const craigslistApi = function (apiBase) {
    return {
      getListingAdBody: function (listingId) {
        return apiBase.get('/craigslist/template/' + listingId);
      }
    };
  };

  craigslistApi.$inject = ['apiBase'];

  app.factory('craigslistApi', craigslistApi);
})(window.angular);
