class TitleService {
  _notificationBadgeCount: number = 0;
  _messageBadgeCount: number = 0;
  _teamBadgeCount: number = 0;
  _isTitleLocked: boolean = false;
  _defaultTitle: string = 'Knock | Better leasing results';
  _title: string = this._defaultTitle;
  _counts: Object = {};
  _todo: number = 0;

  getTitle(): string {
    return this._title;
  }

  getRenderedTitle(): string {
    const todoCount = this._todo;
    return todoCount !== 0 ? `(${todoCount}) ${this._title}` : this._title;
  }

  getNotificationBadgeCount(): number {
    return this._notificationBadgeCount;
  }

  getMessageBadgeCount(): number {
    return this._messageBadgeCount;
  }

  getTeamBadgeCount(): number {
    return this._teamBadgeCount;
  }

  lockTitle(): void {
    this._isTitleLocked = true;
  }

  unlockTitle(): void {
    this._isTitleLocked = false;
  }

  isTitleLocked(): boolean {
    return this._isTitleLocked;
  }

  setTitle(title: string): void {
    if (this.isTitleLocked()) {
      return;
    }

    this._title = title;
    this.updateDocumentTitle();
  }

  setTodoCount(count: number) {
    this._todo = count;
    this.updateDocumentTitle();
  }

  setMessageBadgeCount(count: number): void {
    this._messageBadgeCount = Math.max(count, 0);
    this.updateDocumentTitle();
  }

  setTeamBadgeCounts(counts: Object, currentUserCount: number): void {
    this._counts = counts;
    this._teamBadgeCount = Math.max(currentUserCount, 0);
    this.updateDocumentTitle();
  }

  setNotificationBadgeCount(count: number): void {
    this._notificationBadgeCount = Math.max(count, 0);
    this.updateDocumentTitle();
  }

  getTotalBadgeCount(): number {
    return (
      this._messageBadgeCount +
      this._teamBadgeCount +
      this._notificationBadgeCount
    );
  }

  getCounts(): Object {
    return this._counts;
  }

  clearBadgeCount(): void {
    this._notificationBadgeCount = 0;
    this._teamBadgeCount = 0;
    this._messageBadgeCount = 0;
    this.updateDocumentTitle();
  }

  updateDocumentTitle(): void {
    document.title = this.getRenderedTitle();
  }
}

const titleService = new TitleService();

export { titleService, TitleService };
