/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular, $) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('scrollToItem', [
    function () {
      return {
        restrict: 'A',
        scope: {
          scrollTo: '@'
        },
        link: function (scope, element) {
          element.on('click', function () {
            $('html, body').animate(
              { scrollTop: $(scope.scrollTo).offset().top },
              'slow'
            );
          });
        }
      };
    }
  ]);
})(window.angular, window.jQuery);
