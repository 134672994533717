import React, { FC } from 'react';
import classnames from 'classnames';

export interface GuestCardIconProps {
  className?: string;
  height?: string;
  strokeColor?: string;
  width?: string;
}

export const GuestcardIcon: FC<GuestCardIconProps> = ({
  className,
  height = '24',
  strokeColor = '#353950',
  width = '24'
}) => (
  <svg
    className={classnames('guestcard-icon', className)}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 11.25C9.69975 11.25 10.875 10.0747 10.875 8.625C10.875 7.17525 9.69975 6 8.25 6C6.80025 6 5.625 7.17525 5.625 8.625C5.625 10.0747 6.80025 11.25 8.25 11.25Z"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 16.5C3.75 14.0147 5.76472 12 8.25 12C10.7353 12 12.75 14.0147 12.75 16.5H3.75Z"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.25 9H18.75"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.25 12H20.25"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.75 3H2.25C1.42157 3 0.75 3.67157 0.75 4.5V19.5C0.75 20.3284 1.42157 21 2.25 21H5.25C5.25 20.1716 5.92157 19.5 6.75 19.5C7.57843 19.5 8.25 20.1716 8.25 21H15.75C15.75 20.1716 16.4216 19.5 17.25 19.5C18.0784 19.5 18.75 20.1716 18.75 21H21.75C22.5784 21 23.25 20.3284 23.25 19.5V4.5C23.25 3.67157 22.5784 3 21.75 3Z"
      stroke={strokeColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
