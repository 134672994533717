import React, { FC, useState, useEffect } from 'react';
import Editor from 'ckeditor4-react';

import { APP_CONFIG } from '../../../APP_CONFIG';

import './_RichTextEditor.scss';

interface RichTextEditorProps {
  onChange: (editorContent: string) => any;
  content?: string | null | undefined;
  token: string;
  mergeTags: [];
  height?: string;
  resizeDirection?: string;
}

const imageUploadUrl = `${APP_CONFIG.API.host}/upload/attachment`;
const MaxFileSize = 5; // In MB

const RichTextEditor: FC<RichTextEditorProps> = ({
  content: initialContent = '',
  onChange,
  token,
  mergeTags,
  height,
  resizeDirection
}) => {
  const [content, setContent] = useState(initialContent);
  const [filesUploaded, setFilesUploaded] = useState<
    { url: string; token: string }[]
  >([]);

  /*
  We don't want useEffect to fire every time initialContent is updated--only when
  content and initialContent don't match due to an update to the message text
  triggered outside of the component, where handleChange doesn't get called in here
  (on send, on adding quick replies or SMS links, etc.).
  Otherwise, we get the dreaded jumping cursor :(
  */
  const conditionalInitialContentUpdate = () => {
    if (content !== initialContent) {
      setContent(initialContent);
    }
  };

  useEffect(conditionalInitialContentUpdate, [initialContent]);

  const indexArrayFeed = (data: any) => {
    for (let i = 0; i < data.length; i++) {
      data[i]['id'] = i;
    }
  };

  const mentionsCaseSensitive = () => {
    return false;
  };

  const getFeed = (inputDict: any, callback: any) => {
    if (!mergeTags) return [];

    let query = inputDict.query;
    const data = mergeTags.filter((item: any) => {
      let itemName = item.name;
      if (!mentionsCaseSensitive()) {
        itemName = itemName.toLowerCase();
        query = query.toLowerCase();
      }
      return itemName.indexOf(query) === 0;
    });

    indexArrayFeed(data);
    data.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    return callback(data);
  };

  const handleChange = async (editor: any) => {
    const newContent = editor.getData();
    const newFiles = [...filesUploaded];

    for (let i = 0; i < filesUploaded.length; i++) {
      const { url, token: fileToken } = filesUploaded[i];
      if (newContent && !newContent.includes(url)) {
        try {
          await fetch(`${imageUploadUrl}/${fileToken}`, {
            method: 'delete',
            headers: new Headers({ Authorization: `Bearer ${token}` })
          });

          newFiles.splice(i, 1);
        } catch (e) {
          console.error(e);
        }
      }
    }

    setContent(newContent);
    setFilesUploaded(newFiles);
    !!onChange && onChange(newContent);
  };

  return (
    <Editor
      data={content}
      config={{
        imageUploadUrl,
        removeDialogTabs:
          'image:advanced;link:advanced;link:target;table:advanced',
        embed_provider:
          '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
        uploadUrl: imageUploadUrl, // including for initial impl. docs on these two params look identical

        resize_maxHeight: 500,
        resize_minWidth: 2000,
        resize_dir: resizeDirection ? resizeDirection : 'both',
        height: height ? height : undefined,
        notification_duration: 3000,

        disableNativeSpellChecker: false,

        fileBrowserUploadMethod: 'xhr',
        fileTools_requestHeaders: { Authorization: `Bearer ${token}` },
        fileTools_defaultFileName: 'files',
        toolbar: [
          { name: 'type', items: ['Font', 'FontSize'] },
          {
            name: 'texttools',
            items: ['Bold', 'Italic', 'Underline', 'Strike']
          },
          { name: 'colors', items: ['TextColor', 'BGColor'] },
          {
            name: 'alignment',
            items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight']
          },
          { name: 'lists', items: ['NumberedList', 'BulletedList'] },
          { name: 'indentation', items: ['Indent', 'Outdent'] },
          {
            name: 'links',
            items: ['Link', 'Unlink']
          },
          {
            name: 'insert',
            items: ['Image', 'EmojiPanel']
          }
        ],

        mentions: [
          {
            feed: getFeed,
            minChars: 0,
            marker: '#',
            caseSensitive: mentionsCaseSensitive(),
            itemTemplate:
              '<li data-id="{id}"><strong>{name}</strong> <br/> <i>{description}</i></li>',
            outputTemplate: '{value}'
          }
        ],
        on: {
          change: (event: any) => {
            handleChange(event.editor);
          },
          paste: (event: any) => {
            if (
              !!mergeTags &&
              (event.data.type === 'html' || event.data.type === 'text') &&
              event.data.dataValue
            ) {
              // resolve merge tags in pasted text
              mergeTags.forEach((mergeTag: any) => {
                event.data.dataValue = event.data.dataValue.replaceAll(
                  `#${mergeTag.name}`,
                  mergeTag.value
                );
              });
            }

            for (
              let i = 0;
              i < event.data.dataTransfer.getFilesCount();
              i += 1
            ) {
              const file: File = event.data.dataTransfer.getFile(i);

              if (!RegExp(/^.*\/(jpg|jpeg|png|gif)$/).test(file.type)) {
                event.editor.showNotification(
                  'This file type is currently unsupported for inline attachments.',
                  'info',
                  3000
                );
                event.stop();
                event.cancel();
                console.error(
                  `Upload stopped. Unsupported file type: ${file.type}`
                );
                continue;
              }

              const fileSize = file.size / 1024 / 1024;

              if (fileSize > MaxFileSize) {
                event.editor.showNotification(
                  `This file exceeds the ${MaxFileSize}MB size limit`,
                  'info',
                  3000
                );
                console.error(`Upload stopped. Large file: ${fileSize}MB`);
                event.stop();
                event.cancel();
              }
            }
          },
          fileUploadResponse: (event: any) => {
            event.stop();
            const {
              data: {
                fileLoader: { xhr }
              }
            } = event;
            const { attachments } = JSON.parse(xhr.response);
            const { url, delete_token } = attachments[0];
            event.data.url = url;
            setFilesUploaded([...filesUploaded, { url, token: delete_token }]);
            // this is a crappy hack, but without the setTimeout this seems to fire
            // before the image is added to the editor contents, so ~* shrug *~
            setTimeout(handleChange, 1000, event.editor);
          },
          notificationShow: (event: any) => {
            if (event.data.notification.type === 'warning') {
              event.data.notification.duration = 3000;
            }
          },
          dialogShow: (event: any) => {
            if (event.data.definition.title === 'Image Properties') {
              event.stop();
              event.cancel();
              event.data.hide();
            }
          },
          contentDom: (event: any) => {
            const editable = event.editor.editable();
            const doc = event.editor.document;
            editable.attachListener(doc, 'input', (evt: any) =>
              handleChange(event.editor)
            );
          }
        }
      }}
    />
  );
};

export default RichTextEditor;
