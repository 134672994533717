/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('ProfileService', [
    'localStorageService',
    '$q',
    '$rootScope',
    'userService',
    'apiBase',
    'cacheKeys',
    'apiEvents',
    function (
      localStorageService,
      $q,
      $rootScope,
      userService,
      apiBase,
      cacheKeys,
      apiEvents
    ) {
      var self = this;
      var profileCacheKey = cacheKeys.knockProfile;

      self.getProfile = function (forceRefresh) {
        self._requireAuthenticated();

        var deferredProfile = $q.defer();
        var promise = deferredProfile.promise;

        // Provide success() and error() functions to be consistent with other API access points in the app
        promise.success = function (callback) {
          promise.then(function (response) {
            callback(response);
          });
          return promise;
        };

        promise.error = function (callback) {
          promise.then(null, function (response) {
            callback(response);
          });
          return promise;
        };

        var cachedProfile = self._getCachedProfile();

        if (cachedProfile && !forceRefresh) {
          deferredProfile.resolve({ profile: cachedProfile });
        } else {
          apiBase
            .get('/profile')
            .success(function (response) {
              self._setCachedProfile(response.profile);
              deferredProfile.resolve(response);
            })
            .error(function (error) {
              deferredProfile.reject(error);
            });
        }

        return deferredProfile.promise;
      };

      self.updateProfile = function (updatedProfile) {
        self._requireAuthenticated();

        self._setCachedProfile(updatedProfile);
        $rootScope.$emit(apiEvents.profileUpdated);

        return apiBase.put('/profile', updatedProfile);
      };

      self.initializeCachedProfile = function () {
        self._clearCachedProfile();
      };

      self.getUsername = function () {
        return apiBase.get('/profile/username');
      };

      self.updateUsername = function (username) {
        var payload = {
          username: username
        };

        return apiBase.post('/profile/username', payload);
      };

      self._getCachedProfile = function () {
        var profileJson = localStorageService.get(profileCacheKey);

        if (!profileJson) {
          return null;
        }

        var cachedProfile = JSON.parse(profileJson);
        var user = userService.getScopedUser();
        var idMismatch =
          cachedProfile.renter_id !== user.id &&
          cachedProfile.manager_id !== user.id; // jshint ignore: line
        var cachedUserType = cachedProfile.renter_id ? 'renter' : 'manager'; // jshint ignore: line

        // If we aren't the user that's cached, clear the cache!
        if (cachedUserType !== user.type || idMismatch) {
          self._clearCachedProfile();
          return null;
        }

        return cachedProfile;
      };

      self._setCachedProfile = function (profile) {
        localStorageService.set(profileCacheKey, JSON.stringify(profile));
      };

      self._clearCachedProfile = function () {
        localStorageService.remove(profileCacheKey);
      };

      self._requireAuthenticated = function () {
        var authPayload = userService.getUser();

        if (!authPayload) {
          self._clearCachedProfile();
          throw 'Must be logged in to use profile service';
        }
      };

      self._clearCachedProfile = function () {
        localStorageService.remove(profileCacheKey);
      };

      return self;
    }
  ]);
})(window.angular);
