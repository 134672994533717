/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('notificationBannerCall', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/notifications/notification-banner-call.html',
      scope: {
        activeCall: '='
      },
      replace: true,
      controller: [
        '$scope',
        'voiceService',
        'conversationsService',
        function ($scope, voiceService, conversationsService) {
          $scope.displayName = $scope.activeCall.info.name;

          $scope.openChatThread = function (newWindow) {
            if (newWindow) {
              conversationsService.openThreadInNewWindow(
                $scope.activeCall.streamId
              );
            } else {
              var openAsModal = true;
              conversationsService.openThreadById(
                $scope.activeCall.streamId,
                openAsModal
              );
            }
          };
        }
      ]
    };
  });
})(window.angular);
