import React, {
  FC,
  useState,
  useMemo,
  ChangeEvent,
  useCallback,
  useEffect
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText
} from '@material-ui/core';
import {
  makeStyles,
  NamedColors,
  Box,
  Button,
  Text,
  CircularProgress
} from '@knockrentals/knock-shared-web';
import { extractAvailableTimes } from 'app/utils/dateUtils';
import { colors } from '../commonStyles/commonStyles';
import { StyledDatePicker } from '../DatePicker/DatePicker';
import FormTextField from '../Inputs/FormTextField';
import appointmentService, {
  TourTypesEnabled
} from 'LegacyAngularApp/legacy-angular-app/services/appointmentService';
import dayjs from 'dayjs';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CircularProgressButton } from '../CircularProgressButton';
import useProperties from 'app/services/property/hooks';
import { ErrorAlert } from '../ErrorAlert';

const useStyles = makeStyles({
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px'
  },
  formControl: {
    width: '100%'
  },
  formLabel: {
    top: '9px',
    left: '-3px',
    fontWeight: 400,
    color: colors.secondaryText,
    height: '0px'
  },
  selectField: {
    width: '100%',
    border: 'none !important',
    color: 'black !important',
    backgroundColor: colors.inputBackground,

    '& .MuiFormLabel-filled': {
      left: '-3px'
    },
    '& .MuiSelect-root': {
      paddingLeft: '1px'
    },
    '& .MuiInputLabel-formControl': {
      left: '0',
      margin: 0,
      transform: 'none',
      border: 'none !important'
    },
    '& .MuiInputBase-formControl': {
      borderColor: NamedColors.slate[300],
      margin: '0px !important'
    },
    '& .MuiTypography-root': {
      fontSize: '14px'
    },
    '& .MuiFormHelperText-root': {
      color: colors.inputError,
      marginLeft: '4px'
    }
  },
  rightPadding: {
    paddingRight: '8px'
  },
  leftPadding: {
    paddingLeft: '8px'
  },
  textFieldContainer: {
    marginTop: '13px'
  },
  container: {
    '& .MuiFormControl-root': {
      marginBottom: '4px'
    }
  },
  cancelButton: {
    marginRight: '16px'
  },
  noBackdrop: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent'
    },
    '& .MuiPaper-root': {
      boxShadow: 'none'
    }
  },
  content: {
    paddingBottom: '20px'
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 0'
  },
  timeError: {
    color: colors.error
  }
});

export interface ScheduleTourProperty {
  name: string;
  id: number;
  tourTypes?: TourTypesEnabled;
}

export interface Manager {
  manager_id: number;
  first_name: string;
  last_name: string;
}
export interface ScheduleTourProps {
  property: ScheduleTourProperty;
  countLabel?: string;
  prospect: {
    knockId: string;
    renterId: number;
  };
  noBackdrop?: boolean;
  timeZone: string;
  closeScheduleTour: () => void;
}

interface ItemValue {
  label: string;
  value: string | null;
  id: number;
}

interface ScheduleTourData {
  startTime: string;
  selectedDate: string;
  tourType: number;
}

const ScheduleTour: FC<ScheduleTourProps> = ({
  countLabel,
  property,
  noBackdrop,
  prospect,
  timeZone,
  closeScheduleTour
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  const [tourData, setTourData] = useState<ScheduleTourData>({
    startTime: '',
    tourType: 1,
    selectedDate: ''
  });

  const [dateError, setDateError] = useState<string | undefined>();
  const [timeError, setTimeError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const [availableTimes, setAvailableTimes] = useState<string[]>([]);

  const { startTime, selectedDate, tourType } = tourData;
  const { knockId, renterId } = prospect;

  const { getAvailableAppointmentsTimeForProperty } = useProperties();

  const stringSelectedDate = useMemo<string>(() => {
    return new Date(selectedDate).toLocaleDateString(undefined, {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  }, [selectedDate]);

  const tourTypes = useMemo<ItemValue[]>(() => {
    const items: ItemValue[] = [];
    const {
      inPersonToursEnabled,
      liveVideoToursEnabled,
      selfGuidedToursEnabled
    } = property.tourTypes || {};
    if (selfGuidedToursEnabled) {
      items.push({
        label: 'Self-Guided Tour',
        value: 'self_guided',
        id: 2
      });
    }

    if (liveVideoToursEnabled) {
      items.push({
        label: 'Live Video Tour',
        value: 'live_video',
        id: 3
      });
    }

    let isDefault = false;

    if (inPersonToursEnabled) {
      items.push({
        label: 'In-Person Tour',
        value: null,
        id: 1
      });
      isDefault = true;
    }

    if (!isDefault) {
      setTourData((prevState) => ({ ...prevState, tourType: items[0].id }));
    }
    return items;
  }, [property.tourTypes]);

  const { minDate, maxDate, availableTimesMap } = useMemo(() => {
    const extracted = extractAvailableTimes(availableTimes, timeZone);
    setTourData((prevState) => ({
      ...prevState,
      selectedDate: extracted.minDate.toDateString()
    }));
    return extracted;
  }, [availableTimes, timeZone]);

  const handleGetAvailableTimes = useCallback(async () => {
    setLoading(true);
    try {
      const times = await getAvailableAppointmentsTimeForProperty({
        propertyId: property.id,
        params: {
          tourType:
            tourTypes.find((tour) => tour.id === tourType)?.value || null,
          renterId: renterId
        }
      });

      const allTimes = times.data?.availableTimes || [];

      await setAvailableTimes(allTimes);
      setLoading(false);
      return allTimes;
    } catch (error) {
      console.error('Error getting available times for property', error);
      setLoading(false);
    }

    return [];
  }, [
    tourType,
    tourTypes,
    property,
    getAvailableAppointmentsTimeForProperty,
    renterId
  ]);

  const handleChange = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    e.persist();

    if (e.target.name === 'tourType') {
      handleGetAvailableTimes();
    }

    setTourData((prevState) => ({
      ...prevState,
      [e.target.name || '']: e.target.value
    }));
  };

  const handleChangeDate = (date: MaterialUiPickersDate) => {
    let dateError = !dayjs(date).isValid()
      ? 'Please add a valid date'
      : undefined;
    if (dayjs(date).isValid()) {
      const now = dayjs(new Date());
      if (dayjs(date).diff(now, 'hour') <= -24) {
        dateError = 'Please add a valid date';
      }
    }

    setDateError(dateError);

    setTourData((prevState) => ({
      ...prevState,
      selectedDate: date?.toISOString() || ''
    }));
  };
  const handleCancel = () => {
    setOpen(false);
    if (!noBackdrop) {
      closeScheduleTour();
    }
  };

  const handleSubmit = async () => {
    setProcessing(true);
    try {
      await appointmentService.requestAppointment(
        property.id,
        knockId,
        [startTime],
        true,
        tourTypes.find((tour) => tour.id === tourType)?.value || null
      );
    } catch (error) {
      console.error('BookTour Error', error);
    }
    if (!noBackdrop) {
      closeScheduleTour();
    }
    setOpen(false);
    setProcessing(false);
  };

  useEffect(() => {
    handleGetAvailableTimes();
  }, [handleGetAvailableTimes]);

  useEffect(() => {
    const times = availableTimesMap[stringSelectedDate] || [];

    if (times.length > 0) {
      setTourData((prevState) => ({
        ...prevState,
        startTime: times[0].toISOString()
      }));
      setTimeError(false);
    } else if (selectedDate !== '') {
      setTimeError(true);
    }
  }, [stringSelectedDate, availableTimesMap, selectedDate]);

  useEffect(() => {
    let tomorrowsDate = dayjs(dayjs().toISOString().split('T')[0]);
    tomorrowsDate = tomorrowsDate.date(tomorrowsDate.date() + 1);

    const tomorrowsTimes =
      availableTimesMap[
        new Date(tomorrowsDate.toISOString()).toLocaleDateString(undefined, {
          timeZone,
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })
      ] || [];

    if (tomorrowsTimes.length > 0) {
      setTourData((prevState) => ({
        ...prevState,
        startTime: tomorrowsTimes[0].toISOString(),
        selectedDate: tomorrowsDate.toISOString()
      }));
    } else if (Object.keys(availableTimesMap).length > 0) {
      setTourData((prevState) => ({
        ...prevState,
        startTime:
          availableTimesMap[Object.keys(availableTimesMap)[0]][0].toISOString(),
        selectedDate:
          availableTimesMap[Object.keys(availableTimesMap)[0]][0].toISOString()
      }));
    }
  }, [availableTimesMap, timeZone]);

  return (
    <Dialog open={open} className={noBackdrop ? classes.noBackdrop : ''}>
      <DialogTitle>Schedule Tour</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Text>
              Please fill out the tour details below. We will send a
              confirmation email to the prospect once the tour has been booked.
            </Text>
          </Grid>

          <Grid item xs={12} className={classes.textFieldContainer}>
            <FormTextField
              variant="filled"
              fullWidth
              label="Property"
              value={property.name}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="times-label" className={classes.formLabel}>
                Tour Type
              </InputLabel>
              <Select
                labelId="tour-type-label"
                id="tour-type"
                name="tourType"
                onChange={handleChange}
                className={classes.selectField}
                inputProps={{ 'data-testid': 'tour-type' }}
                value={tourType}
              >
                {tourTypes.map((tour: ItemValue) => (
                  <MenuItem key={tour.label} value={tour.id}>
                    {tour.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {loading ? (
            <Grid item xs={12} className={classes.progressContainer}>
              <CircularProgress />
            </Grid>
          ) : availableTimes.length === 0 ? (
            <>
              <Grid item xs={12} className={classes.progressContainer}>
                <ErrorAlert
                  severity="info"
                  alertMessage="No availability for the given tour type. Please select a different tour type."
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6} className={classes.rightPadding}>
                <StyledDatePicker
                  id="date"
                  minDate={minDate}
                  maxDate={maxDate}
                  name="selectedDate"
                  label="Date"
                  value={selectedDate}
                  onChange={handleChangeDate}
                  error={!!dateError}
                  helperText={dateError}
                />
              </Grid>
              <Grid item xs={6} className={classes.leftPadding}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel
                    id="start-times-label"
                    className={classes.formLabel}
                  >
                    Start Time
                  </InputLabel>
                  <Select
                    labelId="start-times-label"
                    id="start-times"
                    name="startTime"
                    onChange={handleChange}
                    className={classes.selectField}
                    inputProps={{ 'data-testid': 'start-time' }}
                    value={startTime}
                    error={timeError}
                  >
                    {availableTimesMap[stringSelectedDate]?.map((time, idx) => (
                      <MenuItem key={idx} value={time.toISOString()}>
                        {new Date(time).toLocaleTimeString('en-US', {
                          hour: 'numeric',
                          minute: '2-digit',
                          timeZone
                        })}
                      </MenuItem>
                    ))}
                  </Select>
                  {timeError && (
                    <FormHelperText className={classes.timeError}>
                      No tour hours available for this date, please select
                      another date.
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Box>{countLabel && <Text>{countLabel}</Text>}</Box>
        <Box>
          <Button
            className={classes.cancelButton}
            disabled={loading || processing}
            variant="outlined"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <CircularProgressButton
            progressText="Booking..."
            shouldShowProgress={processing}
            onClick={handleSubmit}
            disabled={
              !!dateError || loading || availableTimes.length === 0 || timeError
            }
          >
            Book
          </CircularProgressButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleTour;
