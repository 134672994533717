/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import map from 'lodash/map';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('schedulingService', [
    '$location',
    '$routeParams',
    '$auth',
    'localCache',
    'timeService',
    'appointmentsApi',
    'apiBase',
    'ProfileService',
    function (
      $location,
      $routeParams,
      $auth,
      localCache,
      timeService,
      appointmentsApi,
      apiBase,
      profileService
    ) {
      var self = this;

      self._getPrimeTimesCacheKey = function (propertyId) {
        return 'prime-time-request-times-selection-' + propertyId;
      };

      self._getChosenTimesCacheKey = function (propertyId) {
        return 'request-time-selections-' + propertyId;
      };

      self.timeCache = {
        setPrimeTime: function (timeSelection, propertyId) {
          localCache.putSession(
            self._getPrimeTimesCacheKey(propertyId),
            timeSelection
          );
        },
        getPrimeTime: function (propertyId) {
          return localCache.get(self._getPrimeTimesCacheKey(propertyId));
        },
        setChosenTimes: function (timeSelections, propertyId) {
          localCache.putSession(
            self._getChosenTimesCacheKey(propertyId),
            timeSelections
          );
        },
        getChosenTimes: function (propertyId) {
          var cachedTimeSelections = localCache.get(
            self._getChosenTimesCacheKey(propertyId)
          );
          return map(cachedTimeSelections, function (selection) {
            return { selection: timeService.get(selection.selection) };
          });
        },
        clearCachedTimes: function (propertyId) {
          localCache.del(self._getPrimeTimesCacheKey(propertyId));
          localCache.del(self._getChosenTimesCacheKey(propertyId));
        }
      };

      self.requestAppointment = function (
        timeSelections,
        propertyId,
        listingIds,
        isRescheduling,
        profile
      ) {
        var requestTimes = self._getRequestTimes(timeSelections);
        var source = self.getSource();
        var prospectKnockId = self.getProspectKnockId();
        var knockAttributionParent = self.getKnockAttributionParent();

        return appointmentsApi
          .requestAppointment(
            propertyId,
            requestTimes,
            listingIds,
            source,
            isRescheduling,
            prospectKnockId,
            profile,
            knockAttributionParent
          )
          .success(function (response) {
            if (response.access_token) {
              $auth.setToken(response.access_token);
              profileService.updateProfile(profile);
            }

            self.timeCache.clearCachedTimes(propertyId);
          });
      };

      self.requestQueuedAppointment = function (
        queuedRequestId,
        queuedRequestListingId
      ) {
        return appointmentsApi
          .requestQueuedAppointment(queuedRequestId)
          .success(function () {
            self.timeCache.clearCachedTimes(queuedRequestListingId);
          });
      };

      self._getRequestTimes = function (timeSelections) {
        return map(timeSelections, function (timeSlot) {
          return { start_time: timeSlot.selection.format() };
        });
      };

      self.getSource = function () {
        return $location.search().s;
      };

      self.getProspectKnockId = function () {
        return $routeParams.pid;
      };

      self.getKnockAttributionParent = function () {
        return $routeParams.parent;
      };

      return self;
    }
  ]);
})(window.angular);
