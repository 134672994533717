/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('officeHoursModel', [
    'timeService',
    function (timeService) {
      var officeHours = [];

      var monday = timeService.now().isoWeekday(1);
      var sunday = timeService.now().isoWeekday(7);

      var week = timeService.range(monday, sunday);

      week.by('days', function (day) {
        var startTime = timeService.clone(day).hour(9).minute(0).second(0);
        var endTime = timeService.clone(day).hour(17).minute(0).second(0);

        officeHours.push({
          weekday: day.isoWeekday(),
          startTime: startTime,
          endTime: endTime,
          isActive: false
        });
      });

      return officeHours;
    }
  ]);
})();
