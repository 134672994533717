import React, { FC } from 'react';
import InsideBodyPortal from '../../Portals/InsideBodyPortal';
import { Snackbar, IconButton } from '@material-ui/core';
import { makeStyles, NamedColors } from '@knockrentals/knock-shared-web';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  snackBar: {
    backgroundColor: NamedColors.slate[900]
  },

  snackbarCloseIconButton: {
    marginRight: '10px'
  }
}));
interface SnackbarMessageProps {
  snackMessage: string | null;
  handleCloseAlert: () => void;
}
const SnackbarMessage: FC<SnackbarMessageProps> = ({
  snackMessage,
  handleCloseAlert
}) => {
  const classes = useStyles();

  return (
    <InsideBodyPortal>
      <Snackbar
        ContentProps={{
          classes: {
            root: classes.snackBar
          }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!snackMessage}
        message={snackMessage}
        action={
          <IconButton
            className={classes.snackbarCloseIconButton}
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseAlert}
          >
            <Close />
          </IconButton>
        }
      />
    </InsideBodyPortal>
  );
};
export default SnackbarMessage;
