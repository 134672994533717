/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('locationService', [
    '$window',
    '$q',
    'localCache',
    'cacheKeys',
    function ($window, $q, localCache, cacheKeys) {
      var self = this;

      return {
        getLocation: function () {
          var cacheKey = cacheKeys.userLocation;

          self.location = localCache.get(cacheKey) || {};

          if (isEmpty(self.location)) {
            // knock headquarters, yo

            self.location = {
              latitude: 47.648,
              longitude: -122.346,
              accuracy: 1000
            };

            localCache.put(cacheKey, self.location);
          }

          return self.location;
        }
      };
    }
  ]);
})();
