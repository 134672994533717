import React, { FC, useState, useRef, useMemo, useEffect } from 'react';
import {
  Avatar,
  Popover,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  makeStyles,
  IconButton,
  Switch,
  Grid
} from '@material-ui/core';
import { Provider } from 'react-redux';
import { store } from 'app/store';
import { ProfileMenuProps } from '../types';
import { NamedColors, Text, Tooltip } from '@knockrentals/knock-shared-web';
import {
  PersonCircle,
  AlertDisabledIcon,
  PeopleIcon,
  SettingsIcon,
  LogoutIcon,
  AlertOnIcon,
  AlertOffIcon,
  SoundOffIcon,
  SoundOnIcon
} from 'LegacyAngularApp/legacy-angular-app/components/icons';
import { colors } from 'LegacyAngularApp/legacy-angular-app/components/commonStyles/commonStyles';
import ChangeUserModal from './ChangeUserModal';

const useStyles = makeStyles({
  boxStyle: {
    width: '332px'
  },
  listItemHover: {
    padding: '8px 0px 8px 28px !important',
    '&:hover': {
      backgroundColor: `${NamedColors.slate[50]} !important`
    }
  },
  listItemHoverUserDetail: {
    '&:hover': {
      backgroundColor: `${NamedColors.slate[50]} !important`
    }
  },
  iconButtonStyle: {
    width: '48px',
    height: '48px',
    marginLeft: '18px'
  },
  avatarColorDefault: {
    fontSize: '20px',
    height: '48px',
    width: '48px'
  },
  betaVersionText: {
    color: '#999999',
    fontSize: '12px',
    marginLeft: '16px',
    paddingTop: '16px',
    paddingBottom: '8px'
  },

  tooltip: {
    fontSize: '10px',
    fontWeight: 600,
    padding: '4px 8px',
    top: '-25px'
  },
  onTitle: {
    color: NamedColors.denim[600],
    fontWeight: 600
  },
  disabledTitle: {
    color: colors.secondaryText,
    fontWeight: 600
  },
  offTitle: {
    color: colors.error,
    fontWeight: 600
  },
  alignAlertGrid: {
    textAlign: 'right',
    paddingRight: '20px'
  },
  alignSoundGrid: {
    textAlign: 'left',
    paddingLeft: '20px'
  },
  listItemNotification: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    padding: '8px 16px 8px 16px !important'
  },
  iconButtonHover: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  profileNameAndEmail: {
    paddingLeft: '8px'
  },
  listItemIcon: {
    paddingRight: '16px',
    minWidth: '40px !important'
  }
});

const ProfileMenu: FC<ProfileMenuProps> = ({
  userProfile,
  logout,
  hideAccountViews,
  openProfileAccount,
  betaSwitch,
  isRedesignBeta,
  browserAudioNotificationsToggle,
  audioNotificationsEnabled,
  openProfileSettings,
  browserNotificationsToggle,
  desktopNotificationsEnabled,
  desktopNotificationsUnavailable,
  hideChangeUser,
  memberLogin,
  hideSettings
}) => {
  const {
    boxStyle,
    iconButtonStyle,
    avatarColorDefault,
    betaVersionText,
    tooltip,
    onTitle,
    offTitle,
    alignSoundGrid,
    alignAlertGrid,
    listItemHover,
    listItemNotification,
    profileNameAndEmail,
    iconButtonHover,
    disabledTitle,
    listItemHoverUserDetail,
    listItemIcon
  } = useStyles();

  const [open, setOpen] = useState(false);
  const [alertDisabled, setAlertDisabled] = useState(
    desktopNotificationsUnavailable ?? false
  );

  const [betaClicked, setBetaClicked] = useState(
    localStorage.getItem('ProspectResidentBeta') === 'true'
  );
  const [soundOn, setSoundOn] = useState<string | null>(
    localStorage.getItem('AudioNotificationsEnabled')
  );
  const [alertOn, setAlertOn] = useState<string | null>(
    localStorage.getItem('DesktopNotificationEnabled')
  );
  const [imgLoadError, setImgLoadError] = useState(false);
  const [showChangeUserModal, setShowChangeUserModal] = useState(false);
  const profileRef = useRef<HTMLButtonElement>(null);
  const defaultImageFileName = 'profile_placeholder.png';

  const handleCloseChangeUserModal = () => {
    setShowChangeUserModal(false);
  };

  const nameInitials: string = useMemo(
    () =>
      `${userProfile.first_name} ${userProfile.last_name}`
        .split(' ')
        .map((str) => (str ? str[0].toUpperCase() : ''))
        .join(''),
    [userProfile.first_name, userProfile.last_name]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    betaSwitch(event.target.checked);
    setBetaClicked(event.target.checked);
  };

  useEffect(() => {
    const val = localStorage.getItem('AudioNotificationsEnabled');
    setSoundOn(val);
  }, [audioNotificationsEnabled]);

  useEffect(() => {
    const val = localStorage.getItem('DesktopNotificationEnabled');
    setAlertOn(val);
  }, [desktopNotificationsEnabled]);

  useEffect(() => {
    setAlertDisabled(desktopNotificationsUnavailable);
  }, [desktopNotificationsUnavailable]);

  const isProfilePhotoPresent = useMemo(() => {
    if (userProfile.photo) {
      let isDefaultIMGPresent =
        userProfile.photo.indexOf(defaultImageFileName) !== -1;
      return !imgLoadError && !isDefaultIMGPresent;
    } else {
      return false;
    }
  }, [imgLoadError, userProfile]);

  const setAudioNotification = async () => {
    try {
      await browserAudioNotificationsToggle();
      const val = localStorage.getItem('AudioNotificationsEnabled');
      setSoundOn(val);
    } catch (error) {
      console.error('Error while toggling audio notifications:', error);
    }
  };

  const setBrowserNotification = async () => {
    try {
      await browserNotificationsToggle();
      const val = localStorage.getItem('DesktopNotificationEnabled');
      setAlertOn(val);
    } catch (error) {
      console.error('Error while toggling browser notifications:', error);
    }
  };

  const getAlertLabel = () => {
    if (alertDisabled) {
      return (
        <>
          Alerts <span className={disabledTitle}>Off</span>
        </>
      );
    }
    if (alertOn === 'true') {
      return (
        <>
          Alerts <span className={onTitle}>On</span>
        </>
      );
    } else {
      return (
        <>
          Alerts <span className={offTitle}>Off</span>
        </>
      );
    }
  };

  const getSoundLabel = () => {
    if (soundOn === 'true') {
      return (
        <>
          Sound <span className={onTitle}>On</span>
        </>
      );
    } else {
      return (
        <>
          Sound <span className={offTitle}>Off</span>
        </>
      );
    }
  };

  return (
    <>
      {showChangeUserModal && (
        <ChangeUserModal
          showChangeUserModal={showChangeUserModal}
          closeModal={() => handleCloseChangeUserModal()}
          userProfile={userProfile}
          memberLogin={memberLogin}
        />
      )}
      <Tooltip title="Account & Settings" arrow classes={{ tooltip: tooltip }}>
        <IconButton
          className={iconButtonStyle}
          aria-describedby="popover-profile"
          ref={profileRef}
          onClick={() => setOpen(!open)}
          component="span"
          data-testid="profile-icon-button"
        >
          <Avatar
            classes={{ colorDefault: avatarColorDefault }}
            role="profile-icon-avatar"
            style={{ backgroundColor: userProfile?.color ?? '#EBEEFE' }}
          >
            {isProfilePhotoPresent ? (
              <img
                src={userProfile?.photo}
                alt="User"
                height={50}
                width={50}
                onError={() => {
                  setImgLoadError(true);
                }}
              />
            ) : (
              nameInitials
            )}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={profileRef.current}
        onClose={() => setOpen(!open)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        role="profile-menu-popover"
      >
        <Box className={boxStyle}>
          <List>
            <ListItem className={listItemHoverUserDetail}>
              <ListItemAvatar>
                <Avatar
                  classes={{ colorDefault: avatarColorDefault }}
                  role="profile-icon-avatar"
                  style={{ backgroundColor: userProfile?.color ?? '#EBEEFE' }}
                >
                  {isProfilePhotoPresent ? (
                    <img
                      src={userProfile?.photo}
                      alt="User"
                      height={50}
                      width={50}
                      onError={() => {
                        setImgLoadError(true);
                      }}
                    />
                  ) : (
                    nameInitials
                  )}
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={`${userProfile.first_name} ${userProfile.last_name}`}
                secondary={`${userProfile?.email}`}
                className={profileNameAndEmail}
              />
            </ListItem>

            <Divider />

            {!hideAccountViews && (
              <ListItem
                role="button"
                onClick={() => {
                  openProfileAccount();
                  setOpen(false);
                }}
                className={listItemHover}
              >
                <ListItemIcon className={listItemIcon}>
                  <PersonCircle />
                </ListItemIcon>
                <ListItemText primary="Account" color="inherit" />
              </ListItem>
            )}
            {hideChangeUser && (
              <ListItem
                role="button"
                onClick={() => {
                  setShowChangeUserModal(true);
                  setOpen(false);
                }}
                className={listItemHover}
              >
                <ListItemIcon className={listItemIcon}>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Change User" color="inherit" />
              </ListItem>
            )}
            {/* add here 234 */}
            {!hideSettings && (
              <ListItem
                role="button"
                onClick={() => {
                  openProfileSettings();
                  setOpen(false);
                }}
                className={listItemHover}
              >
                <ListItemIcon className={listItemIcon}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" color="inherit" />
              </ListItem>
            )}
            <ListItem
              component="a"
              onClick={logout}
              role="button"
              className={listItemHover}
            >
              <ListItemIcon className={listItemIcon}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Log Out" color="inherit" />
            </ListItem>
            <Divider />

            <ListItem className={listItemNotification}>
              <Grid container xs={12}>
                <Grid
                  xs={6}
                  item
                  className={alignAlertGrid}
                  onClick={() => setBrowserNotification()}
                >
                  {alertDisabled ? (
                    <>
                      <Tooltip
                        title={'Notifications are not supported'}
                        arrow
                        classes={{ tooltip: tooltip }}
                      >
                        <ListItemIcon>
                          <IconButton className={iconButtonHover}>
                            <AlertDisabledIcon />
                          </IconButton>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={getAlertLabel()} color="inherit" />
                    </>
                  ) : (
                    <>
                      <Tooltip
                        title={
                          alertOn === 'true'
                            ? 'Disable Notifications'
                            : 'Enable Notifications'
                        }
                        arrow
                        classes={{ tooltip: tooltip }}
                      >
                        <ListItemIcon>
                          <IconButton className={iconButtonHover}>
                            {alertOn === 'true' ? (
                              <AlertOnIcon />
                            ) : (
                              <AlertOffIcon />
                            )}
                          </IconButton>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={getAlertLabel()} color="inherit" />
                    </>
                  )}
                </Grid>
                <Grid
                  xs={6}
                  item
                  className={alignSoundGrid}
                  onClick={() => setAudioNotification()}
                >
                  <Tooltip
                    title={
                      soundOn === 'true'
                        ? 'Disable Notification Sounds'
                        : 'Enable Notification Sounds'
                    }
                    arrow
                    classes={{ tooltip: tooltip }}
                  >
                    <ListItemIcon>
                      <IconButton className={iconButtonHover}>
                        {soundOn === 'true' ? (
                          <SoundOnIcon />
                        ) : (
                          <SoundOffIcon />
                        )}
                      </IconButton>
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={getSoundLabel()} color="inherit" />
                </Grid>
              </Grid>
            </ListItem>

            {isRedesignBeta && (
              <>
                <Divider />
                <Text className={betaVersionText}>BETA VERSIONS</Text>
                <ListItem className={listItemHover}>
                  <ListItemText
                    primary="Prospects & Residents Pages"
                    color="inherit"
                  />
                  <ListItemIcon>
                    <Switch
                      size="small"
                      color="primary"
                      checked={betaClicked}
                      onChange={handleChange}
                    />
                  </ListItemIcon>
                </ListItem>
              </>
            )}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default (props: ProfileMenuProps) => {
  return (
    <Provider store={store}>
      <ProfileMenu {...props} />
    </Provider>
  );
};
