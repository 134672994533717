import angular from 'angular';

(function () {
  var app = angular.module('knock-UserInteractions');
  app.factory('availableUnitsModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openAvailableUnitsModal = function (ev, scope) {
        return $mdDialog.show({
          templateUrl: '/angular/views/util/available-units-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          preserveScope: true,
          targetEvent: ev,
          multiple: true,
          skipHide: true,
          fullscreen: true,
          scope: scope
        });
      };
      self.close = function () {
        $mdDialog.hide();
      };

      return self;
    }
  ]);
})();
