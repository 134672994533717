import { configureStore } from '@reduxjs/toolkit';
import prospectFilterReducer from 'app/features/prospects/filter';
import selectedProspectsReducer from 'app/features/prospects/selectedProspects';
import residentFilterReducer from 'app/features/residents/filter';
import selectedResidentReducer from 'app/features/residents/selectedResidents';
import { api } from './services/api';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    prospectFilter: prospectFilterReducer,
    selectedProspects: selectedProspectsReducer,
    residentFilter: residentFilterReducer,
    selectedResidents: selectedResidentReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
