import React from 'react';

export const CalendarApprovedIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="8" fill="#DBF0FE" />
    <g clipPath="url(#clip0_14_75537)">
      <path
        d="M23.25 29.25C23.25 30.8413 23.8821 32.3674 25.0074 33.4926C26.1326 34.6179 27.6587 35.25 29.25 35.25C30.8413 35.25 32.3674 34.6179 33.4926 33.4926C34.6179 32.3674 35.25 30.8413 35.25 29.25C35.25 27.6587 34.6179 26.1326 33.4926 25.0074C32.3674 23.8821 30.8413 23.25 29.25 23.25C27.6587 23.25 26.1326 23.8821 25.0074 25.0074C23.8821 26.1326 23.25 27.6587 23.25 29.25Z"
        stroke="#087DD1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.924 27.506L29.019 31.379C28.9544 31.4648 28.8721 31.5358 28.7777 31.5872C28.6834 31.6386 28.5791 31.6691 28.4719 31.6768C28.3648 31.6845 28.2572 31.6691 28.1565 31.6317C28.0557 31.5943 27.9642 31.5358 27.888 31.46L26.388 29.96"
        stroke="#087DD1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 29.25H14.25C13.8522 29.25 13.4706 29.092 13.1893 28.8107C12.908 28.5294 12.75 28.1478 12.75 27.75V15.765C12.75 15.3672 12.908 14.9856 13.1893 14.7043C13.4706 14.423 13.8522 14.265 14.25 14.265H27.75C28.1478 14.265 28.5294 14.423 28.8107 14.7043C29.092 14.9856 29.25 15.3672 29.25 15.765V20.25"
        stroke="#087DD1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 18.75H29.25"
        stroke="#087DD1"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M17.243 15.75V12.75"
        stroke="#087DD1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.743 15.75V12.75"
        stroke="#087DD1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14_75537">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
);
