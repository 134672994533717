import { api as baseApi } from 'app/services/api';

export const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOneSiteProspectPageUrl: build.query<string, number>({
      query: (prospectId) => ({
        url: `/prospect/${prospectId}/prospect-page/oll`,
        method: 'GET'
      }),
      transformResponse: (data: any) => data.url
    })
  })
});
