import React, { FC } from 'react';

export const AlertDisabledIcon: FC = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="none" />
    <path
      d="M22 2.5L1 23.5M9.499 22C9.75989 22.8887 10.5753 23.4992 11.5015 23.4992C12.4277 23.4992 13.2431 22.8887 13.504 22M11.5 3.25V1M9.999 19H20.499C20.499 19 18.999 17.8 18.999 10.75C18.9873 10.4433 18.9563 10.1377 18.906 9.835M15.711 4.55C13.414 2.99091 10.4436 2.82819 7.99005 4.12705C5.53647 5.4259 4.00106 7.97384 3.999 10.75V16"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(12, 12)"
    />
  </svg>
);
