import React, { FC } from 'react';
import {
  makeStyles,
  Text,
  NamedColors,
  Box
} from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  gridRow: {
    marginTop: '20px',
    marginBottom: '25px'
  },
  title: {
    marginLeft: '22px',
    display: 'inline-flex',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    letter: '0.4px'
  },
  titleIcon: {
    position: 'absolute',
    color: NamedColors.slate[800]
  },
  boxStyle: {
    marginTop: '40px',
    marginBottom: '20px',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '23px',
    marginRight: '56px',
    position: 'relative'
  }
});

interface InfoPanelDataProps {
  icon: React.ReactElement;
  title: any;
  id: number;
}
interface InfoPanelProps {
  InfoPanelData: InfoPanelDataProps[];
}

const InfoPanel: FC<InfoPanelProps> = ({ InfoPanelData }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxStyle} data-testid="info-panel-box">
      {InfoPanelData.map((item) =>
        item.title ? (
          <Box key={item.id}>
            <Box className={classes.titleIcon} data-testid="info-panel-icon">
              {item.icon}
            </Box>
            <Text className={classes.title} data-testid="info-panel-title">
              {item.title}
            </Text>
          </Box>
        ) : null
      )}
    </Box>
  );
};

export default InfoPanel;
