/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('zipValidatorService', function () {
    var self = this;
    self.ZIP_REGEX = /(^\d{5}(-\d{4})?$)|(^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$)/;

    self.validate = function (zip) {
      return self.ZIP_REGEX.test(zip);
    };

    return self;
  });

  app.directive('zipValidator', [
    'zipValidatorService',
    function (zipValidatorService) {
      return {
        require: 'ngModel',
        restrict: '',
        link: function (scope, elm, attrs, ctrl) {
          ctrl.$validators.zip = function (modelValue) {
            return (
              ctrl.$isEmpty(modelValue) ||
              zipValidatorService.validate(modelValue)
            );
          };
        }
      };
    }
  ]);
})();
