import React, { FC } from 'react';
import classnames from 'classnames';
import { Theme } from '@material-ui/core';

import {
  Button,
  makeStyles,
  NamedColors,
  ThemeProvider
} from '@knockrentals/knock-shared-web';
import { PersonSecureIcon } from '../icons/PersonSecureIcon';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: NamedColors.slate[800],

    '& svg': {
      marginRight: '8px'
    },

    '&:focus': {
      color: NamedColors.slate[800],

      '& svg path': {
        stroke: NamedColors.slate[800]
      }
    }
  },

  disabled: {
    '& svg path': {
      stroke: theme.palette.action.disabled
    }
  }
}));

export interface SelfVerifyIdButtonProps {
  disable: boolean;
  onClick: () => void;
  isEmailAvailable: string | null;
  isPlaidIdvEnabled: boolean;
}

export const SelfVerifyIdButton: FC<SelfVerifyIdButtonProps> = ({
  disable,
  onClick,
  isEmailAvailable,
  isPlaidIdvEnabled
}) => {
  const classes = useStyles();

  const handleClick = () => {
    if (!disable) {
      onClick();
    }
  };
  const disabled = (!isEmailAvailable && isPlaidIdvEnabled) || disable;
  return (
    <ThemeProvider>
      <div
        title={
          !isEmailAvailable && isPlaidIdvEnabled
            ? 'Email required to send self-scan link'
            : ''
        }
      >
        <Button
          variant="text"
          className={classnames(classes.button, {
            [classes.disabled]: disabled
          })}
          onClick={handleClick}
          disabled={disabled}
        >
          <PersonSecureIcon useNewDesign={true} /> Self-Verify ID
        </Button>
      </div>
    </ThemeProvider>
  );
};
