/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import includes from 'lodash/includes';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import compact from 'lodash/compact';
import cloneDeep from 'lodash/cloneDeep';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('listingModelService', [
    'floorplanModelService',
    'amenitiesModelService',
    'DebugInfoService',
    function (floorplanModelService, amenitiesModelService, debugInfoService) {
      var ListingModel = function () {
        this.debug = debugInfoService.getDebugInfoObject();

        this.location = {
          name: '',
          propertyType: '',
          address: {
            unit: '',
            street: '',
            city: '',
            state: 'WA',
            zip: ''
          }
        };

        this.social = {
          website: '',
          facebook: '',
          twitter: ''
        };

        this.floorplan = floorplanModelService.getNewModel();

        this.pets = {
          allowed: {
            small_dogs: false,
            large_dogs: false,
            cats: false
          },
          notes: ''
        };

        this.description = {
          short: '',
          full: ''
        };

        this.extra = {
          isSmokingAllowed: null,
          hasWheelchairAccess: null,
          yearBuilt: '',
          sharedUnit: false
        };

        this.utilities = {
          types: [
            { name: 'Water', included: false },
            { name: 'Sewage', included: false },
            { name: 'Garbage', included: false },
            { name: 'Gas', included: false },
            { name: 'Electricity', included: false },
            { name: 'Cable', included: false },
            { name: 'Internet', included: false }
          ],
          estimatedCost: ''
        };

        this.parking = {
          types: [
            { name: 'Garage', available: false },
            { name: 'Detached Garage', available: false },
            { name: 'Covered', available: false },
            { name: 'Off-Street', available: false },
            { name: 'On-Street', available: false }
          ],
          notes: ''
        };

        this.furnishing = [
          { name: 'Furnished', available: false },
          { name: 'Unfurnished', available: false }
        ];

        this.laundry = {
          types: [
            { name: 'In Unit', available: false },
            { name: 'Shared', available: false }
          ],
          notes: ''
        };

        this.appliances = [
          { name: 'Dishwasher', available: false },
          { name: 'Refrigerator', available: false },
          { name: 'Garbage disposal', available: false },
          { name: 'Microwave', available: false },
          { name: 'Range/Oven', available: false }
        ];

        this.views = [
          { name: 'City', available: false },
          { name: 'Mountain', available: false },
          { name: 'Water', available: false },
          { name: 'Park', available: false }
        ];

        this.leasing = {
          availableDate: '',
          application: {
            fee: ''
          },
          terms: {
            deposit: '',
            leaseLengths: [
              { leaseLength: 'Month to month', isAvailable: false },
              { leaseLength: '3', lengthUnit: 'months', isAvailable: false },
              { leaseLength: '6', lengthUnit: 'months', isAvailable: false },
              { leaseLength: '9', lengthUnit: 'months', isAvailable: false },
              { leaseLength: '9+', lengthUnit: 'months', isAvailable: false },
              { leaseLength: '12', lengthUnit: 'months', isAvailable: false },
              { leaseLength: '12+', lengthUnit: 'months', isAvailable: false }
            ],
            notes: ''
          },
          monthlyRent: ''
        };

        this.amenities = amenitiesModelService.getNewModel();

        this.photos = [];

        this.coverPhoto = {
          url: '',
          thumbUrl: ''
        };

        this.videos = [{ url: '', thumb_url: '' }];
      };

      var ListingModelService = {};

      ListingModelService.getNewModel = function () {
        return new ListingModel();
      };

      ListingModelService.mapStoredListingToViewModel = function (listing) {
        var mappedListing = cloneDeep(listing);
        var listingModel = ListingModelService.getNewModel();

        mappedListing.amenities.exterior = _mapCollectionToViewModel(
          listing.amenities.exterior,
          listingModel.amenities.exterior
        );
        mappedListing.amenities.heatingAndCooling = _mapCollectionToViewModel(
          listing.amenities.heatingAndCooling,
          listingModel.amenities.heatingAndCooling
        );
        mappedListing.amenities.wiring = _mapCollectionToViewModel(
          listing.amenities.wiring,
          listingModel.amenities.wiring
        );
        mappedListing.amenities.additional = _mapCollectionToViewModel(
          listing.amenities.additional,
          listingModel.amenities.additional
        );
        mappedListing.amenities.security = _mapCollectionToViewModel(
          listing.amenities.security,
          listingModel.amenities.security
        );
        mappedListing.amenities.recreation = _mapCollectionToViewModel(
          listing.amenities.recreation,
          listingModel.amenities.recreation
        );
        mappedListing.views = _mapCollectionToViewModel(
          listing.views,
          listingModel.views
        );
        mappedListing.appliances = _mapCollectionToViewModel(
          listing.appliances,
          listingModel.appliances
        );
        mappedListing.furnishing = _mapCollectionToViewModel(
          listing.furnishing,
          listingModel.furnishing
        );
        mappedListing.laundry.types = _mapCollectionToViewModel(
          listing.laundry.types,
          listingModel.laundry.types
        );
        mappedListing.parking.types = _mapCollectionToViewModel(
          listing.parking.types,
          listingModel.parking.types
        );
        mappedListing.utilities.types = _mapCollectionToViewModel(
          listing.utilities.types,
          listingModel.utilities.types,
          'included'
        );

        if (!mappedListing.leasing.terms.leaseLengths) {
          mappedListing.leasing.terms.leaseLengths =
            listingModel.leasing.terms.leaseLengths;
        }

        mappedListing.photos = compact(listing.photos);
        mappedListing.videos = compact(listing.videos);

        return mappedListing;
      };

      function _mapCollectionToViewModel(
        theArray,
        modelCollection,
        availableFieldName
      ) {
        var newCollection = [];
        availableFieldName = availableFieldName || 'available';

        if (isUndefined(theArray)) {
          return modelCollection;
        }

        if (!isString(theArray[0])) {
          if (theArray.length >= modelCollection.length) {
            return theArray;
          }
        }

        modelCollection.forEach(function (modelItem) {
          if (!includes(theArray, modelItem.name)) {
            newCollection.push(modelItem);
          } else {
            var model = { name: modelItem.name };
            model[availableFieldName] = true;

            newCollection.push(model);
          }
        });

        return newCollection;
      }

      return ListingModelService;
    }
  ]);
})();
