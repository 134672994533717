/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import values from 'lodash/values';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import find from 'lodash/find';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Voice');

  const voiceService = function (
    $rootScope,
    $interval,
    $location,
    $mdDialog,
    chatTrackingService,
    notificationBannersService,
    voiceApi,
    browserNotificationsService,
    _,
    appDataService
  ) {
    var self = this;

    var TwilioDevice = require('twilio-client').Device;

    self._client = null;
    self._twilioDevice = null;
    self._privateChannel = null;
    self._enableBrowserCalling = null;

    self.events = {
      incomingCall: 'incoming-call',
      statusChanged: 'status-updated',
      callInitiated: 'call-initiated'
    };

    self.callStatuses = {
      active: {
        queued: 'queued',
        initiated: 'initiated',
        ringing: 'ringing',
        inProgress: 'in-progress'
      },
      inactive: {
        canceled: 'canceled',
        completed: 'completed',
        busy: 'busy',
        failed: 'failed',
        noAnswer: 'no-answer'
      }
    };

    self.callDirections = {
      outgoing: 'outgoing',
      incoming: 'incoming'
    };

    self.subscribedManagerIds = [];
    self.activeCalls = [];

    self.getActiveCallForStream = function (streamId) {
      return find(self.activeCalls, { streamId: streamId });
    };

    self.placeCall = function (recipientType, recipientId, streamId, source) {
      return voiceApi
        .placeCall(recipientType, recipientId, streamId, source)
        .then(function (response) {
          if (response.data.status_code !== 'ok') {
            self.showErrorDialog(response.data.status_code);
            return;
          }

          response.data.recipient.source =
            response.data.recipient.source || source;

          var activeCall = self.createCallObject(
            response.data.call_sid,
            streamId,
            response.data.recipient,
            self.callDirections.outgoing,
            false,
            recipientType === 'resident'
          );
          self.addCall(activeCall);

          return activeCall;
        });
    };

    self.placeCallViaBrowser = function (
      recipientType,
      recipientName,
      streamId,
      source,
      callbackOnCallPlaced,
      setBrowserCallTransient
    ) {
      if (!self._enableBrowserCalling) {
        return;
      }
      if (!self._twilioDevice || self._twilioDevice.status() === 'offline') {
        self._setupTwilioDevice();
        var isCallPlaced = false;
        self._twilioDevice.on('ready', function (device) {
          if (!isCallPlaced) {
            isCallPlaced = true;
            self._connectTwilioOutboundCall(
              recipientType,
              recipientName,
              streamId,
              source,
              callbackOnCallPlaced,
              setBrowserCallTransient
            );
          }
        });
      } else if (self._twilioDevice.status() === 'ready') {
        self._connectTwilioOutboundCall(
          recipientType,
          recipientName,
          streamId,
          source,
          callbackOnCallPlaced,
          setBrowserCallTransient
        );
      }
    };

    self._setupTwilioDevice = function () {
      self._twilioDevice = new TwilioDevice();
      return voiceApi.generateVoiceClientToken().then(function (response) {
        self._twilioDevice.setup(response.data.token, {
          // More recent web browsers won’t show the custom message, because it was considered to be a security issue
          closeProtection:
            'A call is currently in progress. Leaving or reloading this page will end the call.'
        });
      });
    };

    self._connectTwilioOutboundCall = function (
      recipientType,
      recipientName,
      streamId,
      source,
      callbackOnCallPlaced,
      setBrowserCallTransient
    ) {
      setBrowserCallTransient(true);
      var callConnection = self._twilioDevice.connect({
        'knock-stream-id': streamId,
        'knock-manager-id': $rootScope.loggedInUser.id
      });
      callConnection.on('accept', function (hasEarlyMedia) {
        setBrowserCallTransient(false);
        var recipientInfo = {
          name: recipientName,
          source: source
        };

        var activeCall = self.createCallObject(
          callConnection.parameters.CallSid,
          streamId,
          recipientInfo,
          self.callDirections.outgoing,
          false,
          recipientType === 'resident',
          callConnection
        );

        self.addCall(activeCall);

        callbackOnCallPlaced(activeCall);
      });
      callConnection.on('disconnect', () => setBrowserCallTransient(false));
      callConnection.on('reject', () => setBrowserCallTransient(false));
      callConnection.on('error', () => setBrowserCallTransient(false));
    };

    self.endCall = function (callId) {
      return voiceApi.endCall(callId).then(function () {
        var activeCall = find(self.activeCalls, { callId: callId });

        if (activeCall) {
          activeCall.statusChanged({
            status: self.callStatuses.inactive.completed
          });
        }
      });
    };

    self.showErrorDialog = function (errorCode) {
      var content, okayLabel, cancelLabel;

      var okayFunc = function () {
        $mdDialog.hide();
      };

      var cancelFunc = function () {
        $mdDialog.hide();
      };

      if (errorCode === 'missing_sender_phone') {
        content = 'You do not have a phone number configured for your account.';
        okayLabel = 'Configure one now';
        cancelLabel = "I'll do it later";

        okayFunc = function () {
          $location.path('/manager/account/profile');
        };
      } else {
        content =
          'An unknown error has occurred, please try again later. If the error persists, please contact Knock support';
        okayLabel = 'Contact support';
        cancelLabel = 'Close';

        okayFunc = function () {
          chatTrackingService.show();
        };
      }

      var dialog = $mdDialog
        .confirm()
        .title('Could not place call!')
        .content(content)
        .ok(okayLabel)
        .cancel(cancelLabel);

      $mdDialog.show(dialog).then(okayFunc, cancelFunc);
    };

    self.createCallObject = function (
      callId,
      streamId,
      info,
      direction,
      isNewProspect,
      isResidentStream,
      twilioConnection
    ) {
      return {
        callId: callId,
        streamId: streamId,
        isNewProspect: isNewProspect,
        isResidentStream: isResidentStream,
        twilioConnection: twilioConnection,
        info: info,
        status: self.callStatuses.active.queued,
        isActive: true,
        isVisible: true,
        callLength: 0,
        direction: direction,
        statusChanged: function (data) {
          this.status = data.status;

          if (self._isInactiveCallStatus(this.status)) {
            this.unsubscribe();
            return;
          }

          if (this.status === self.callStatuses.active.initiated) {
            $rootScope.$emit(self.events.callInitiated, this.streamId);
          }
        },
        subscribe: function () {
          var channel = self._client.subscribe(
            self._callStatusChannel(this.callId)
          );
          channel.bind(
            self.events.statusChanged,
            this.statusChanged.bind(this)
          );
          this.isActive = true;

          this._callLengthInterval = $interval(
            function () {
              this.callLength++;
            }.bind(this),
            1000
          );
        },
        unsubscribe: function () {
          self._client.unsubscribe(self._callStatusChannel(this.callId));
          this.isActive = false;

          $interval.cancel(this._callLengthInterval);

          if (!this.isVisible) {
            self._removeCall(this.callId);
          }
        },
        hide: function () {
          this.isVisible = false;
          self._removeCall(this.callId);
        }
      };
    };

    self._removeCall = function (callId) {
      remove(self.activeCalls, function (activeCall) {
        return activeCall.callId === callId;
      });

      notificationBannersService.removeNotification(
        callId,
        notificationBannersService.notificationTypes.call
      );
    };

    self.initialize = function (client, channel) {
      if (isEmpty(self._client)) {
        self._client = client;
      }

      if (isEmpty(self._privateChannel)) {
        self._privateChannel = channel;
        channel.bind(self.events.incomingCall, self._handleIncomingCall);
      }

      if (isEmpty(self._enableBrowserCalling)) {
        appDataService.untilInitialized().then(() => {
          self._enableBrowserCalling =
            $rootScope.appPreferences.company.enable_browser_calling || false;
        });
      }
    };

    self.setSubscribedManagerIds = function (subscribedManagerIds) {
      self.subscribedManagerIds = subscribedManagerIds;
    };

    self._callStatusChannel = function (callSid) {
      return 'call-' + callSid;
    };

    self._handleIncomingCall = function (data) {
      var managerId = data.manager_id;

      if (!includes(self.subscribedManagerIds, managerId)) {
        return;
      }

      browserNotificationsService.playSound('phone');

      var callId = data.call_sid;
      var streamId = data.stream_id;
      var caller = data.caller;
      var isNewProspect = data.is_new_prospect;
      var isResidentStream = data.is_resident_stream;

      var newCall = self.createCallObject(
        callId,
        streamId,
        caller,
        self.callDirections.incoming,
        isNewProspect,
        isResidentStream
      );
      self.addCall(newCall);
    };

    self._isInactiveCallStatus = function (status) {
      return includes(values(self.callStatuses.inactive), status);
    };

    self.addCall = function (call) {
      call.subscribe();
      self.activeCalls.push(call);

      notificationBannersService.addNotification(
        call.callId,
        notificationBannersService.notificationTypes.call,
        call
      );
    };

    self.isClientOnACall = function () {
      return voiceApi
        .getClientHoldStatus()
        .then((response) => {
          return response.status === 204;
        })
        .catch(() => {
          return false;
        });
    };

    return self;
  };

  voiceService.$inject = [
    '$rootScope',
    '$interval',
    '$location',
    '$mdDialog',
    'chatTrackingService',
    'notificationBannersService',
    'voiceApi',
    'browserNotificationsService',
    '_',
    'appDataService'
  ];

  app.factory('voiceService', voiceService);
})(window.angular);
