/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import range from 'lodash/range';
import map from 'lodash/map';
import forEach from 'lodash/forEach';

(function (angular) {
  'use strict';

  var app = angular.module('knock-Account');

  app.directive('accountPreferencesTodo', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/account/preferences/account-preferences-todo.html',
      controller: 'AccountPreferencesTodoController',
      scope: {
        preferences: '='
      }
    };
  });

  app.controller('AccountPreferencesTodoController', [
    '$scope',
    '$moment',
    function ($scope, $moment) {
      var self = this;

      var createLastContactedChoice = function (hours) {
        var hoursString = hours.toString();

        if (hoursString.length === 1) {
          hoursString = '0' + hoursString;
        }

        return {
          label: hours.toString() + (hours === 1 ? ' HOUR' : ' HOURS'),
          value: hoursString + ':00:00'
        };
      };

      var createFollowUpChoice = function (days) {
        var valueInHours = days * 24;

        return {
          label: days.toString() + (days === 1 ? ' DAY PRIOR' : ' DAYS PRIOR'),
          value: valueInHours.toString() + ':00:00'
        };
      };

      self._initialize = function () {
        forEach($scope.preferences, $scope.updateChoices);
      };

      $scope.updateChoices = function (preference) {
        var yellowHours = $moment
          .duration(preference.yellowThreshold)
          .asHours();
        var redHours = $moment.duration(preference.redThreshold).asHours();

        preference.choices = {
          yellow: map(range(1, 89), createLastContactedChoice),
          red: map(range(yellowHours + 1, 97), createLastContactedChoice)
        };

        if (redHours <= yellowHours) {
          preference.redThreshold = preference.choices.red[0].value;
        }
      };

      self._initialize();
    }
  ]);
})(window.angular);
