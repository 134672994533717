/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('phoneInput', function () {
    return {
      restrict: 'AE',
      template:
        '<input mask="(999) 999-9999" clean="true" type="tel" ng-pattern="countryPhoneRegex" ng-model="phone.national_format" />',
      replace: true,
      scope: {
        phone: '=?'
      },
      link: function (scope) {
        scope.phone = scope.phone || {};
        scope.phone.country_code = scope.phone.country_code || 'US';
        scope.phone.phone_number = scope.phone.phone_number || '';
        scope.phone.national_format = scope.phone.national_format || '';

        // add more country codes here as they become relevant

        if (
          scope.phone.country_code === 'US' ||
          scope.phone.country_code === 'CA'
        ) {
          scope.countryPhoneRegex = /(\+1)?\s?\(?\d{3}\)?\s?\d{3}-?\d{4}$/;
        } else {
          scope.countryPhoneRegex = /\+?\d+/;
          scope.countryMask = '9';
        }
        scope.$watch('phone.national_format', function (phoneNumber) {
          scope.phone.phone_number = phoneNumber;
        });
      }
    };
  });

  app.filter('phone', [
    function () {
      return function (phone) {
        if (isEmpty(phone)) {
          return;
        }

        return phone.national_format;
      };
    }
  ]);

  app.filter('phoneNumber', [
    '$interpolate',
    function ($interpolate) {
      return function (phoneNumber) {
        if (isEmpty(phoneNumber)) {
          return;
        }

        if (phoneNumber.substr(0, 2) === '+1') {
          phoneNumber = phoneNumber.substr(2);
        }

        phoneNumber = phoneNumber.replace(/\D/g, '');

        if (phoneNumber.length < 10) {
          throw 'Phone number must be 10 digits';
        }

        phoneNumber.slice(-10);

        var npa = phoneNumber.substr(0, 3);
        var nxx = phoneNumber.substr(3, 3);
        var xxxx = phoneNumber.substr(6, 4);

        var template = $interpolate('({{npa}}) {{nxx}}-{{xxxx}}');
        return template({ npa: npa, nxx: nxx, xxxx: xxxx });
      };
    }
  ]);
})(window.angular);
