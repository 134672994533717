import range from 'lodash/range';
import map from 'lodash/map';
import find from 'lodash/find';

(function (angular) {
  const app = angular.module('knockApp');

  const quotesService = function (
    $q,
    apiBase,
    OBSOLETE_rentMatrixService,
    $mdDialog,
    addAttachmentDialogFactory,
    conversationsService,
    attachmentService
  ) {
    const self = this;

    self.events = {
      quoteAdded: 'quoteAdded'
    };

    self.createQuote = async (unitListing, prospect, unitListings) => {
      const clickable = true;

      const response = await OBSOLETE_rentMatrixService.getHasRentMatrix(
        prospect.property_id
      );
      const hasRentMatrix =
        response && response.data && response.data.has_rent_matrix
          ? response.data.has_rent_matrix
          : false;

      let deposit = unitListing.listing.leasing.terms.deposit;

      if (hasRentMatrix) {
        const rentMatrixSelection =
          await OBSOLETE_rentMatrixService.openMatrixModal(
            prospect.property_id,
            unitListing.listing_id,
            clickable,
            prospect.profile.target_move_date
          );

        if (unitListing.listing_id !== rentMatrixSelection.listingId) {
          unitListing = unitListings.find(
            (unitListing) =>
              unitListing.listing_id === rentMatrixSelection.listingId
          );

          deposit = unitListing.listing.leasing.terms.deposit;
        }

        self.openGenerateQuoteModal(
          unitListing,
          prospect,
          {
            start: rentMatrixSelection.startDate,
            termLength: rentMatrixSelection.leaseTerm,
            monthlyRent: rentMatrixSelection.rent
          },
          deposit,
          hasRentMatrix
        );
        return;
      }

      if (!hasRentMatrix) {
        self.openGenerateQuoteModal(
          unitListing,
          prospect,
          null,
          deposit,
          hasRentMatrix
        );
      }
    };

    self.openGenerateQuoteModal = function (
      unitListing,
      prospect,
      lease,
      deposit,
      hasRentMatrix
    ) {
      return $mdDialog.show({
        controller: 'GenerateQuoteModalController',
        templateUrl: '/angular/views/quotes/generate-quote-modal.html',
        clickOutsideToClose: true,
        locals: {
          unitListing: unitListing,
          prospect: prospect,
          lease: lease,
          deposit: deposit,
          hasRentMatrix: hasRentMatrix
        }
      });
    };

    self.regenerateQuoteURL = function (prospectQuoteID) {
      return apiBase.put('/quotes/' + prospectQuoteID + '/url');
    };

    self.uploadQuote = function (quoteUrl) {
      const defer = $q.defer();

      attachmentService.uploadByUrl(quoteUrl, function (response) {
        const json = JSON.parse(response.target.responseText);
        const attachment = map(json.attachments, function (attachment) {
          attachment.type = attachmentService.getAttachmentType(
            attachment.mimetype
          );
          attachment.isRenaming = false;
          attachment.newFilename = attachment.filename;
          attachment.editedFilename = attachment.filename;
          attachment.canEditFilename = attachment.type === 'pdf';

          return attachment;
        })[0];

        defer.resolve(attachment);
      });

      return defer.promise;
    };

    self.postQuote = function (
      prospectId,
      listingId,
      monthlyRent,
      leaseStartDate,
      leaseTermMonths,
      leaseEndDate,
      deposit,
      quoteExpirationHours,
      upchargeAmount,
      quoteUpchargesEnabled
    ) {
      const payload = {
        prospect_id: prospectId,
        listing_id: listingId,
        monthly_rent: monthlyRent,
        lease_start_date: leaseStartDate,
        lease_term_months: leaseTermMonths,
        lease_end_date: leaseEndDate,
        deposit: deposit,
        quote_expiration_hours: quoteExpirationHours,
        upcharge_amount: upchargeAmount,
        quote_upcharges_enabled: quoteUpchargesEnabled
      };

      return apiBase.post('/quotes', payload);
    };

    self.sendQuote = function (quoteId, target) {
      const payload = {
        target: target
      };

      return apiBase.post('/quotes/' + quoteId + '/send', payload);
    };

    self.getProspectQuotes = function (prospectId) {
      return apiBase.get('/prospects/' + prospectId + '/quotes');
    };

    self.getProperty = function (propertyId) {
      return apiBase.get('/properties/' + propertyId);
    };

    self.getMappedUpchargeLeaseTermLengths = () => {
      return {
        'Month to month': 1,
        '2': 2,
        '3': 3,
        '4': 4,
        '5': 5,
        '6': 6,
        '7': 7,
        '8': 8,
        '9': 9,
        '10': 10,
        '11': 11,
        '12': 12
      };
    };

    return self;
  };

  quotesService.$inject = [
    '$q',
    'apiBase',
    'OBSOLETE_rentMatrixService',
    '$mdDialog',
    'addAttachmentDialogFactory',
    'conversationsService',
    'attachmentService'
  ];

  app.factory('quotesService', quotesService);

  const GenerateQuoteModalController = function (
    $rootScope,
    $scope,
    $moment,
    $mdDialog,
    $mdToast,
    quotesService,
    unitListing,
    prospect,
    lease,
    deposit,
    hasRentMatrix,
    appDataService
  ) {
    $scope.data = {
      unitListing: unitListing,
      prospect: prospect,
      lease: lease,
      leaseSelections: {
        start: lease ? $moment(lease.start).add(12, 'hours').format() : null,
        termLength: lease ? lease.termLength : 12,
        termLengthOptions: map(range(1, 25), function (index) {
          return index;
        }),
        end: null,
        minEndDate: null,
        maxEndDate: null
      },
      deposit: deposit,
      realpage: prospect.integrations.some(function (integration, key) {
        return integration['vendor'] === 'realpage';
      }),
      quote: null,
      isGeneratingQuote: false,
      isUploadingQuote: false,
      error: false,
      hasRentMatrix: hasRentMatrix,
      upchargeAmount: null,
      propertyLeaseLengths: null,
      baseMonthlyRent: null,
      displayedMonthlyRent: null,
      includeUpcharges: null,
      quoteUpchargesEnabled: false
    };

    const self = this;

    /*
    we are replacing the monthlyRent scope field that displays on quote modals with
    displayedMonthlyRent here. if the QUOTE_UPCHARGES flag is enabled, we also
    need to track baseMonthlyRent, as both will be displayed on the modal.
    the includeUpcharges flag determines how these amounts are calculated;
    when includeUpcharge is true, baseMonthlyRent represents the listed unit price,
    and displayedMonthlyRent = baseMonthlyRent + upchargeAmount;
    when false, displayedMonthlyRent represents the listed unit price,
    and baseMonthlyRent = displayedMonthlyRent - upchargeAmount
    */

    self._initialize = () => {
      $scope.data.baseMonthlyRent =
        $scope.data.lease && $scope.data.lease.monthlyRent
          ? $scope.data.lease.monthlyRent
          : $scope.data.unitListing.listing.leasing.monthlyRent;
      $scope.data.displayedMonthlyRent = $scope.data.baseMonthlyRent;
      if ($rootScope.featureFlags.QUOTE_UPCHARGES) {
        $scope.quoteUpchargesEnabled = true;
        const propertyLeasing = appDataService.getProperty(prospect.property_id)
          .Property.data.leasing;
        if (propertyLeasing.hasOwnProperty('terms')) {
          $scope.data.propertyLeaseLengths = propertyLeasing.terms.leaseLengths;
          if (propertyLeasing.terms.includeUpcharges) {
            $scope.data.includeUpcharges =
              propertyLeasing.terms.includeUpcharges;
          }
        }
      }
    };

    $scope.$watchGroup(
      ['data.leaseSelections.start', 'data.leaseSelections.termLength'],
      function () {
        if (
          $scope.data.leaseSelections.start &&
          $scope.data.leaseSelections.termLength
        ) {
          $scope.data.leaseSelections.end = $moment(
            $scope.data.leaseSelections.start
          )
            .add($scope.data.leaseSelections.termLength, 'months')
            .subtract(1, 'days')
            .format();

          $scope.data.leaseSelections.minEndDate = $moment(
            $scope.data.leaseSelections.start
          )
            .add($scope.data.leaseSelections.termLength, 'months')
            .subtract(1, 'months')
            .format();

          $scope.data.leaseSelections.maxEndDate = $moment(
            $scope.data.leaseSelections.start
          )
            .add($scope.data.leaseSelections.termLength + 1, 'months')
            .format();
        }

        // update base and displayed monthy rent amounts based on changes
        // to upcharge amount if the QUOTE_UPCHARGES flag is enabled
        if (
          $scope.quoteUpchargesEnabled &&
          $scope.data.leaseSelections.termLength &&
          $scope.data.baseMonthlyRent
        ) {
          const mappedTermLengths =
            quotesService.getMappedUpchargeLeaseTermLengths();

          if ($scope.data.propertyLeaseLengths) {
            const termLength = find(
              $scope.data.propertyLeaseLengths,
              function (leaseOption) {
                if ($scope.data.leaseSelections.termLength > 12) {
                  return leaseOption.leaseLength === '12+';
                }
                return (
                  $scope.data.leaseSelections.termLength ===
                  mappedTermLengths[leaseOption.leaseLength]
                );
              }
            );

            if (termLength) {
              if (
                termLength.isAvailable &&
                termLength.upchargeAmount &&
                termLength.upchargeAmount !== 0
              ) {
                $scope.data.upchargeAmount = termLength.upchargeAmount;
                if ($scope.data.includeUpcharges) {
                  $scope.data.displayedMonthlyRent =
                    $scope.data.baseMonthlyRent + $scope.data.upchargeAmount;
                } else {
                  $scope.data.baseMonthlyRent =
                    $scope.data.displayedMonthlyRent -
                    $scope.data.upchargeAmount;
                }
              } else {
                if ($scope.data.includeUpcharges) {
                  $scope.data.displayedMonthlyRent =
                    $scope.data.baseMonthlyRent;
                } else {
                  $scope.data.baseMonthlyRent =
                    $scope.data.displayedMonthlyRent;
                }
                $scope.data.upchargeAmount = null;
              }
            }
          }
        }
      }
    );

    $scope.generateQuote = function () {
      if (!$scope.validateDeposit()) {
        return;
      }
      $scope.data.error = false;
      $scope.data.isGeneratingQuote = true;

      const quoteExpirationHours = $rootScope.appPreferences.company
        .quote_expiration_hours
        ? $rootScope.appPreferences.company.quote_expiration_hours
        : 48;

      quotesService
        .postQuote(
          $scope.data.prospect.id,
          $scope.data.unitListing.listing.id,
          $scope.data.displayedMonthlyRent,
          $moment($scope.data.leaseSelections.start).format(),
          $scope.data.leaseSelections.termLength,
          $moment($scope.data.leaseSelections.end).format(),
          $scope.data.deposit,
          quoteExpirationHours,
          $scope.data.upchargeAmount,
          $scope.quoteUpchargesEnabled
        )
        .then(function (response) {
          $scope.data.isGeneratingQuote = false;

          if (response.status === 400) {
            $scope.data.error = response.data.error_message;
            console.log(`Error generating quote: ${$scope.data.error}`);
            $mdToast.showSimple('Error generating quote!');
          } else {
            $scope.data.quote = response.data.quote;

            $mdToast.showSimple('Quote generated!');
            $rootScope.$emit(
              quotesService.events.quoteAdded,
              $scope.data.quote
            );
          }
        });
    };

    $scope.sendQuote = function () {
      if ($scope.data.isUploadingQuote) {
        return;
      }

      $scope.data.isUploadingQuote = true;

      quotesService
        .uploadQuote($scope.data.quote.url)
        .then(function (attachment) {
          $scope.data.isUploadingQuote = false;
          $rootScope.$emit('attachmentAdded', {
            attachment: attachment,
            stream_id: prospect.stream_id
          });
          $mdDialog.hide(attachment);
        });
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.validateDeposit = function () {
      $scope.data.error = false;
      if ($scope.data.realpage) {
        const DEPOSIT_REGEXP = /^[0-9]{0,4}$/;
        if (!DEPOSIT_REGEXP.test($scope.data.deposit)) {
          $scope.data.error =
            'Deposit must be 1-4 integers, and exclude any non-numeric characters such as $ , or .';
          return false;
        }
        return true;
      }
      return true;
    };

    self._initialize();
  };

  GenerateQuoteModalController.$inject = [
    '$rootScope',
    '$scope',
    '$moment',
    '$mdDialog',
    '$mdToast',
    'quotesService',
    'unitListing',
    'prospect',
    'lease',
    'deposit',
    'hasRentMatrix',
    'appDataService'
  ];
  app.controller('GenerateQuoteModalController', GenerateQuoteModalController);
})(window.angular);
