import React, { FC, useState, useMemo, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  MenuItem,
  Theme,
  SvgIcon
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { NewCalendarIcon } from '../../icons';
import classNames from 'classnames';
import {
  makeStyles,
  NamedColors,
  Box,
  Button
} from '@knockrentals/knock-shared-web';
import { CircularProgressButton } from '../../CircularProgressButton';
import { colors } from '../../commonStyles/commonStyles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { isDateInvalid } from 'LegacyAngularApp/legacy-angular-app/utilities/timeUtilities';
import { useAngularContext } from '../../AngularContextProvider';
import { useResidents } from 'app/services/residents/hooks';
import PhoneInput from '../../PhoneInput/PhoneInput';
import { AddResidentData } from 'app/services/residents/entities';

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    width: '50%',
    margin: '6px 0 10px 0',
    padding: '0',

    '& .MuiInputLabel-formControl': {
      left: '10px',
      top: '21px',
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontWeight: 400,
      color: colors.defaultText
    },
    '& .MuiInputBase-formControl.Mui-error': {
      borderBottom: '2px solid #EE223D',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none'
    },
    '& .MuiInputBase-formControl': {
      borderColor: NamedColors.slate[300],
      background: NamedColors.slate[50],
      height: '60px',
      borderBottom: `1px solid ${NamedColors.slate[300]}`,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      top: '30px',
      left: '7px',
      fontSize: '12px',
      color: colors.secondaryText
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
      top: '30px',
      left: '7px',
      fontSize: '12px',
      color: colors.secondaryText
    },
    '& .MuiInputBase-input': {
      padding: '23px 0 7px'
    },
    '& .MuiButtonBase-root .MuiSvgIcon-root': {
      color: '#353950'
    },
    '& .MuiIconButton-root': {
      padding: '8px'
    },
    '& .MuiSvgIcon-root': {
      height: '24px',
      width: '24px'
    }
  },
  dialogContent: {
    padding: '0 16px',
    overflowY: 'revert'
  },
  dialogTitle: {
    padding: '16px 0 0 16px'
  },
  textField: {
    width: '100%',
    margin: '0 0 16px 0',
    maxHeight: '56px',
    '& .MuiInputLabel-formControl': {
      top: '23px',
      left: '0px',
      color: '#212121',
      fontWeight: 400
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: `${colors.secondaryText} `
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: `1px solid ${NamedColors.slate[300]}`
    },
    '& .MuiFilledInput-root': {
      borderColor: NamedColors.slate[300],
      background: '#FAFAFA',
      borderBottom: `1px solid ${NamedColors.slate[300]}`,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      paddingLeft: '0',
      maxHeight: '56px'
    },
    '& .MuiInputBase-input': {
      color: colors.defaultText
    }
  },
  fieldError: {
    '& .MuiInputLabel-formControl': {
      color: colors.inputError
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: colors.inputError
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: `1px solid ${colors.inputError}`
    },
    '& .MuiFilledInput-root': {
      borderBottom: `1px solid ${colors.inputError}`
    }
  },
  inputsContainer: {
    display: 'flex',
    gap: '5px'
  },
  actionBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    padding: '0 17px 12px 0',
    marginTop: '15px'
  },
  circularProgressButton: {
    background: NamedColors.denim[600]
  },
  cancelButton: {
    border: `1px solid ${NamedColors.denim[600]}`,
    color: NamedColors.denim[600],
    lineHeight: '24px'
  },
  alert: {
    backgroundColor: NamedColors.slate[900]
  },
  alertIcon: {
    marginRight: '10px'
  },
  phoneField: {
    border: 'none',
    backgoundColor: colors.inputBackground,
    '& .MuiFilledInput-root': {
      border: 'none',
      backgroundColor: colors.inputBackground,
      '&:hover': {
        backgoundColor: colors.inputBackground
      }
    },
    '& .MuiInputBase-root': {
      border: 'none',
      paddingLeft: '0px'
    }
  }
}));

interface PropertyValue {
  id: number;
  name: string;
}

interface AddResidentModalProps {
  closeModal: (withEffect?: boolean | undefined) => void;
  open: boolean;
}

const AddResidentModal: FC<AddResidentModalProps> = ({ closeModal, open }) => {
  const { properties, currentUserId, validateEmail, openResidentStreamModal } =
    useAngularContext();
  const { addResident } = useResidents();

  const countryCode = 'US';

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(false);
  const [property, setProperty] = useState<string>('');
  const [propertyError, setPropertyError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [unit, setUnit] = useState('');
  const [unitError, setUnitError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [leaseStartDate, setLeaseStartDate] = useState<Date | null>(null);
  const [leaseStartDateError, setLeaseStartDateError] = useState<string | null>(
    null
  );
  const [leaseEndDate, setLeaseEndDate] = useState<Date | null>(null);
  const [leaseEndDateError, setLeaseEndDateError] = useState<string | null>(
    null
  );
  const [snackMessage, setSnackMessage] = React.useState<string | null>(null);

  const classes = useStyles();

  const filteredProperties = useMemo(() => {
    return properties
      .map((property: any) => ({
        id: property.Property.id,
        name: property.Property.data.location.name
      }))
      .sort(({ name: nameA }: PropertyValue, { name: nameB }: PropertyValue) =>
        nameA.toLocaleUpperCase().localeCompare(nameB.toLocaleUpperCase())
      );
  }, [properties]);

  const validData = useCallback(() => {
    let countErrors = 0;
    const numbersOnly = phone?.match(/\d/g);
    const count = numbersOnly ? numbersOnly.length : 0;
    if (!name) {
      setNameError(true);
      countErrors++;
    } else {
      setNameError(false);
    }
    if (!lastName) {
      setLastNameError(true);
      countErrors++;
    } else {
      setLastNameError(false);
    }

    if (!property) {
      setPropertyError(true);
      countErrors++;
    } else {
      setPropertyError(false);
    }

    if (!unit) {
      setUnitError(true);
      countErrors++;
    } else {
      setUnitError(false);
    }

    if (email && !validateEmail(email)) {
      setEmailError(true);
      countErrors++;
    } else {
      setEmailError(false);
    }
    if (phone && count < 10) {
      setPhoneError(true);
      countErrors++;
    } else {
      setPhoneError(false);
    }

    return countErrors === 0;
  }, [name, lastName, phone, email, property, validateEmail, unit]);

  const handleDateChange = (date: MaterialUiPickersDate, from?: string) => {
    if (isDateInvalid(date)) {
      if (from === 'startLease') {
        setLeaseStartDateError('Please add a valid lease start date');
      } else {
        setLeaseEndDateError('Please add a valid lease end date');
      }
      return;
    }

    from === 'startLease' ? setLeaseStartDate(date) : setLeaseEndDate(date);

    setLeaseEndDateError(null);
    setLeaseStartDateError(null);
  };

  const getUTCDate = (date?: Date | null) => {
    const currentDate = date ? new Date(date) : new Date();
    const day = currentDate.getUTCDate();
    const month = currentDate.getUTCMonth() + 1;
    const year = currentDate.getUTCFullYear();
    const formattedDate = `${year}-${month}-${day} 00:00:00`;
    return new Date(formattedDate).toISOString();
  };

  const clear = () => {
    setName('');
    setNameError(false);
    setLastName('');
    setLastNameError(false);
    setEmail('');
    setEmailError(false);
    setPhone('');
    setPhoneError(false);
    setProperty('');
    setPropertyError(false);
    setUnit('');
    setUnitError(false);

    setLeaseEndDate(null);
    setLeaseStartDate(null);
    setLeaseEndDateError(null);
    setLeaseStartDateError(null);
  };
  const submitHandler = async () => {
    if (!validData()) {
      return;
    }
    const addResidentPayload: AddResidentData = {
      manager_id: currentUserId,
      property_id: Number(property),
      profile: {
        first_name: name,
        last_name: lastName
      },
      unit
    };

    if (leaseStartDate) {
      addResidentPayload.lease_start = getUTCDate(leaseStartDate);
    }

    if (leaseEndDate) {
      addResidentPayload.lease_end = getUTCDate(leaseEndDate);
    }

    if (phone) {
      addResidentPayload.profile.phone = {
        country_code: countryCode,
        national_format: phone,
        phone_number: phone
      };
    }

    if (email) {
      addResidentPayload.profile.email = email;
    }

    setIsLoading(true);
    const addResidentResponse: any = await addResident(addResidentPayload);

    if (addResidentResponse.error) {
      setSnackMessage(
        addResidentResponse.error.data?.message ||
          'We were unable create the resident. Please try again.'
      );
    } else {
      openResidentStreamModal(addResidentResponse.data.resident.stream_id);
    }

    setIsLoading(false);
    closeModal(true);
    clear();
  };

  const handleCloseModalGlobal = (event?: any, reason?: string) => {
    if (reason === 'backdropClick') {
      return;
    }
    closeModal();
    clear();
  };

  const handleCloseAlert = () => {
    setSnackMessage(null);
  };

  const handlePropertyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProperty(e.target.value);
    setPropertyError(false);
  };

  const handleNameChange = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    setName(value);
    setNameError(false);
  };

  const handleLastNameChange = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(value);
    setLastNameError(false);
  };

  const handleEmailChange = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(value);
    setEmailError(false);
  };

  const handleChangeUnit = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    setUnit(value);
    setUnitError(false);
  };
  const handlePhoneChange = (phone?: string) => {
    setPhone(phone || '');
    setPhoneError(false);
  };

  const commonPropsForTextField: any = {
    variant: 'filled',
    fullWidth: true,
    className: classNames(classes.textField, {
      [classes.fieldError]: phoneError
    }),
    inputProps: {
      className: classNames(classes.textField, {
        [classes.fieldError]: phoneError
      }),
      error: false,
      helperText: ''
    }
  };

  return (
    <>
      <Snackbar
        ContentProps={{
          classes: {
            root: classes.alert
          }
        }}
        data-testid="snack-alert-message"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!snackMessage}
        message={snackMessage}
        action={
          <IconButton
            className={classes.alertIcon}
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseAlert}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleCloseModalGlobal}
      >
        <DialogTitle className={classes.dialogTitle}>New Resident</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            fullWidth
            id="select-property"
            label="Property"
            variant="filled"
            className={classNames(classes.textField, {
              [classes.fieldError]: propertyError
            })}
            value={property}
            select
            onChange={handlePropertyChange}
          >
            {filteredProperties.map((property: PropertyValue) => (
              <MenuItem key={property.id} value={property.id}>
                {property.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            id="unit"
            label="Unit"
            variant="filled"
            className={classNames(classes.textField, {
              [classes.fieldError]: unitError
            })}
            value={unit}
            onChange={handleChangeUnit}
          />
          <Box className={classes.inputsContainer}>
            <TextField
              className={classNames(classes.textField, {
                [classes.fieldError]: nameError
              })}
              value={name}
              onChange={handleNameChange}
              variant="filled"
              id="first_name"
              label="First Name"
              inputProps={{ 'data-testid': 'firstName' }}
            />
            <TextField
              className={classNames(classes.textField, {
                [classes.fieldError]: lastNameError
              })}
              value={lastName}
              onChange={handleLastNameChange}
              variant="filled"
              id="last_name"
              label="Last Name"
              inputProps={{ 'data-testid': 'lastName' }}
            />
          </Box>
          <TextField
            className={classNames(classes.textField, {
              [classes.fieldError]: emailError
            })}
            value={email}
            onChange={handleEmailChange}
            variant="filled"
            error={false}
            id="email"
            label="Email"
            inputProps={{ 'data-testid': 'email' }}
          />

          <PhoneInput
            name="phone"
            onChange={(e: any) => {
              handlePhoneChange(e.target.value);
            }}
            {...commonPropsForTextField}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box className={classes.inputsContainer}>
              <KeyboardDatePicker
                className={classes.datePicker}
                margin="normal"
                id="lease-start-date"
                label="Lease Start Date"
                format="MM/dd/yyyy"
                error={!!leaseStartDateError}
                helperText={leaseStartDateError}
                value={leaseStartDate}
                keyboardIcon={
                  <SvgIcon>
                    <NewCalendarIcon />
                  </SvgIcon>
                }
                KeyboardButtonProps={{
                  'aria-label': 'lease start date'
                }}
                onChange={(date: MaterialUiPickersDate) =>
                  handleDateChange(date, 'startLease')
                }
                inputProps={{ 'data-testid': 'lease-start-date' }}
              />
              <KeyboardDatePicker
                className={classes.datePicker}
                margin="normal"
                id="lease-end-date"
                label="Lease End Date"
                format="MM/dd/yyyy"
                error={!!leaseEndDateError}
                helperText={leaseEndDateError}
                value={leaseEndDate}
                keyboardIcon={
                  <SvgIcon>
                    <NewCalendarIcon />
                  </SvgIcon>
                }
                KeyboardButtonProps={{
                  'aria-label': 'lease end date'
                }}
                onChange={(date: MaterialUiPickersDate) =>
                  handleDateChange(date)
                }
                inputProps={{ 'data-testid': 'lease-end-date' }}
              />
            </Box>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Box className={classes.actionBox}>
            <Button
              type="button"
              onClick={handleCloseModalGlobal}
              disabled={isLoading}
              variant="outlined"
              className={classes.cancelButton}
            >
              Cancel
            </Button>{' '}
            <CircularProgressButton
              className={classes.circularProgressButton}
              onClick={submitHandler}
              shouldShowProgress={isLoading}
              progressText={'Saving...'}
            >
              Save
            </CircularProgressButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default AddResidentModal;
