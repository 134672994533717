/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('apiBase', [
    '$http',
    '$rootScope',
    'api',
    function ($http, $rootScope, api) {
      $rootScope.apiUnfulfilledRequestCount = 0;

      var decrementUnfulfilledRequestCount = function () {
        $rootScope.apiUnfulfilledRequestCount = Math.max(
          0,
          $rootScope.apiUnfulfilledRequestCount - 1
        );
      };

      /**
       * Success and and error polyfills to prevent large code base changes after
       * angular 1.3.6 to 1.7.7 migration
       * TODO: phase success and error out.
       */
      const success = function (fn) {
        this.then(function (response) {
          fn(response.data, response.status, response.headers);
        });
        return this;
      };

      const error = function (fn) {
        this.then(null, function (response) {
          fn(response.data, response.status, response.headers);
        });
        return this;
      };

      return {
        get: function (route, config) {
          $rootScope.apiUnfulfilledRequestCount++;

          var url = transformUrl(api.host, route);
          var promise = $http.get(url, config);
          promise.then(decrementUnfulfilledRequestCount);
          promise.success = success;
          promise.error = error;
          return promise;
        },
        getArrayBuffer: function (route, config) {
          $rootScope.apiUnfulfilledRequestCount++;

          config = config || {};
          config.responseType = 'arraybuffer';
          var url = transformUrl(api.host, route);
          var promise = $http.get(url, config);
          promise.then(decrementUnfulfilledRequestCount);
          promise.success = success;
          promise.error = error;
          return promise;
        },
        post: function (route, data, config) {
          $rootScope.apiUnfulfilledRequestCount++;

          var url = transformUrl(api.host, route);

          var promise = $http.post(url, data, config);
          promise.then(decrementUnfulfilledRequestCount);
          promise.success = success;
          promise.error = error;
          return promise;
        },
        put: function (route, data, config) {
          $rootScope.apiUnfulfilledRequestCount++;

          var url = transformUrl(api.host, route);

          var promise = $http.put(url, data, config);
          promise.then(decrementUnfulfilledRequestCount);
          promise.success = success;
          promise.error = error;
          return promise;
        },
        patch: function (route, data, config) {
          $rootScope.apiUnfulfilledRequestCount++;

          var url = transformUrl(api.host, route);

          var promise = $http.patch(url, data, config);
          promise.then(decrementUnfulfilledRequestCount);
          promise.success = success;
          promise.error = error;
          return promise;
        },
        delete: function (route, config) {
          $rootScope.apiUnfulfilledRequestCount++;

          var url = transformUrl(api.host, route);

          var promise = $http.delete(url, config);
          promise.then(decrementUnfulfilledRequestCount);
          promise.success = success;
          promise.error = error;
          return promise;
        }
      };
    }
  ]);

  function transformUrl(host, route) {
    return host + route;
  }
})();
