import find from 'lodash/find';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import sample from 'lodash/sample';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  var app = angular.module('knockApp');

  app.controller('CalendarPopoverController', [
    '$modal',
    '$scope',
    'calendarDataRepository',
    'prospectsApi',
    'timeService',
    'confirmationModalFactory',
    'calendarApiService',
    function (
      $modal,
      $scope,
      calendarDataRepository,
      prospectsApi,
      timeService,
      confirmationModalFactory,
      calendarApiService
    ) {
      $scope.event = $scope.$parent.event;

      if ($scope.event.status === 'pending') {
        $scope.appointment = calendarDataRepository.getAppointmentRequest(
          parseInt($scope.event.appointmentId),
          parseInt($scope.event.requestTimeId)
        );
      } else {
        $scope.appointment = calendarDataRepository.getAppointment(
          parseInt($scope.event.appointmentId)
        );
      }

      $scope.data = {
        property: calendarDataRepository.getProperty(
          $scope.appointment.prospect.property_id
        )
      };

      if ($scope.appointment) {
        prospectsApi
          .getProspect($scope.appointment.prospect.id)
          .then(function (response) {
            $scope.appointment.prospect = response.data.prospect;
          });
      }

      $scope.close = function () {
        $scope.closePopovers();
      };

      $scope.tryAcceptRequest = function () {
        if (
          $scope.appointment.status !== 'pending' ||
          !$scope.appointment.prospect
        ) {
          return;
        }

        var formattedStartTime = timeService
          .get($scope.appointment.start_time)
          .format('h:mm a z - ddd, MMM Do');
        var message =
          'Would you like to accept ' +
          $scope.appointment.prospect.profile.first_name +
          "'s appointment request for " +
          formattedStartTime +
          '?';

        var modalOptions = {
          cancelClass: 'btn-warning',
          confirmClass: 'btn-primary',
          alertClass: 'alert-success'
        };

        var modal = confirmationModalFactory.createModal(
          'Accept Request',
          message,
          function () {
            $scope.isAccepting = true;

            calendarApiService
              .acceptRequest($scope.appointment)
              .finally(function () {
                $scope.isAccepting = false;
              });
          },
          modalOptions
        );

        $modal.open(modal);
      };
    }
  ]);

  app.controller('CalendarPrimeTimePopoverController', [
    '$scope',
    'calendarDataRepository',
    'listingApi',
    function ($scope, calendarDataRepository, listingApi) {
      $scope.event = $scope.$parent.event;
      $scope.primeTime = calendarDataRepository.getPrimeTime($scope.event.id);
      $scope.listing = calendarDataRepository.getMappedListing(
        $scope.primeTime.listing_id
      );
      $scope.listingFailed = !$scope.listing;

      listingApi.getListingInfo($scope.listing.id).success(function (response) {
        var listingInfo = response.listing_info;
        var occupants = response.occupants;

        if (!isEmpty(listingInfo)) {
          $scope.listingHasNotes =
            listingInfo.notes && !isEmpty(listingInfo.notes.text);
          $scope.listingIsPending = listingInfo.pending_status === 'pending';
        }

        $scope.listingHasOccupants = occupants && occupants.length > 0;
      });
    }
  ]);

  app.directive('calendarConfirmedAppointment', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/calendar/sidebar-confirmed-appointment.html',
      scope: {
        appointment: '=',
        teamMembers: '=',
        activeManagerId: '=',
        managerColorRgb: '='
      },
      controller: [
        '$q',
        '$scope',
        '$timeout',
        'userService',
        'timeService',
        'propertyApi',
        'prospectsApi',
        function (
          $q,
          $scope,
          $timeout,
          userService,
          timeService,
          propertyApi,
          prospectsApi
        ) {
          var self = this;
          var BACKGROUND_OPACITY = 0.05;
          var ENTERED_BORDER_OPACITY = 0.7;
          var LEFT_BORDER_OPACITY = 0.12;

          $scope.user = userService.getUser();
          $scope.hideListing = true;

          $scope.popover = {
            template: 'more-popover.html',
            activeManager: null
          };

          $scope.mouseEnteredBorder = function () {
            $scope.borderColor =
              '1px solid rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              ENTERED_BORDER_OPACITY +
              ')';
          };

          $scope.mouseLeftBorder = function () {
            $scope.borderColor =
              '1px solid rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              LEFT_BORDER_OPACITY +
              ')';
          };

          $scope.formattedStartTimeHour = timeService
            .get($scope.appointment.Appointment.start_time)
            .format('h:mma');
          $scope.formattedStartTimeDay = timeService
            .get($scope.appointment.Appointment.start_time)
            .format('ddd');
          $scope.formattedStartTimeMonth = timeService
            .get($scope.appointment.Appointment.start_time)
            .format('MMM Do');

          $scope.$watch(
            'appointment',
            function () {
              $scope.formattedStartTimeHour = timeService
                .get($scope.appointment.Appointment.start_time)
                .format('h:mma');
              $scope.formattedStartTimeDay = timeService
                .get($scope.appointment.Appointment.start_time)
                .format('ddd');
              $scope.formattedStartTimeMonth = timeService
                .get($scope.appointment.Appointment.start_time)
                .format('MMM Do');
            },
            true
          );

          self._initialize = function () {
            self._setColors();

            var setAppointmentProperty = $scope.appointment.property
              ? $q.when($scope.appointment.property)
              : $scope.appointment.prospect
              ? self._setScopeAppointmentProperty(
                  $scope.appointment.prospect.property_id
                )
              : self._setAppointmentProspectAndProperty();

            setAppointmentProperty.then(self._setupScope);
          };

          self._setColors = function () {
            $scope.startTimeDisplayColor =
              'rgb(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ')';
            $scope.backgroundColor =
              'rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              BACKGROUND_OPACITY +
              ')';
            $scope.borderColor =
              '1px solid rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              LEFT_BORDER_OPACITY +
              ')';
          };

          self._setScopeAppointmentProperty = function (propertyId) {
            return propertyApi
              .getProperty(propertyId, true)
              .success(function (response) {
                $scope.property = response.property;
              });
          };

          self._setAppointmentProspectAndProperty = function () {
            return prospectsApi
              .getProspect($scope.appointment.Appointment.prospect_id)
              .success(function (response) {
                $scope.appointment.Appointment.prospect = response.prospect;

                return self._setScopeAppointmentProperty(
                  $scope.appointment.Appointment.prospect.property_id
                );
              });
          };

          self._initialize();
        }
      ]
    };
  });

  app.directive('calendarRequestedAppointment', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/calendar/sidebar-requested-appointment.html',
      scope: {
        requests: '=',
        managerColorRgb: '='
      },
      controller: [
        '$q',
        '$scope',
        'timeService',
        '$mdToast',
        'userService',
        'propertyApi',
        'prospectsApi',
        'rejectRequestsModalFactory',
        'appointmentProposalsModalFactory',
        'confirmAppointmentModalFactory',
        'managerCalendarService',
        'calendarApiService',
        function (
          $q,
          $scope,
          timeService,
          $mdToast,
          userService,
          propertyApi,
          prospectsApi,
          rejectRequestsModalFactory,
          appointmentProposalsModalFactory,
          confirmAppointmentModalFactory,
          managerCalendarService,
          calendarApiService
        ) {
          var self = this;
          var BACKGROUND_OPACITY = 0.05;
          var ENTERED_BORDER_OPACITY = 0.7;
          var LEFT_BORDER_OPACITY = 0.12;

          $scope.user = userService.getUser();
          $scope.hideListing = true;

          $scope.appointmentRequests = map(
            $scope.requests,
            'AppointmentRequest'
          );
          $scope.appointmentRequestTimes = sortBy(
            map($scope.requests, 'AppointmentRequestTime'),
            'start_time'
          );

          var sampleRequest = sample($scope.appointmentRequests);

          $scope.prospect = sampleRequest.prospect;
          $scope.prospectId = $scope.prospect.id;

          if (sampleRequest.expiration_time) {
            var ms = timeService
              .get(sampleRequest.expiration_time)
              .diff(timeService.now());
            var dayInMillis = 24 * 60 * 60 * 1000;

            var hours = ms / 1000 / 60 / 60;
            var minutes = Math.ceil((hours * 60) % 60);

            if (hours < 1) {
              $scope.remainingString = minutes + ' m to respond';
              $scope.minutesColor = 'red';
            } else {
              $scope.remainingString = Math.floor(hours) + ' hr to respond';
            }

            $scope.requestExpiry = Math.floor((ms / dayInMillis) * 100);
          }

          $scope.$watch('appointmentRequestTimes', function (requests) {
            // this bit needs to be in a $watch to allow correct rendering when requests are added asynchronously
            forEach(requests, function (request) {
              request.formattedStartTime = timeService
                .get(request.start_time)
                .format('h:mma ddd, MMM Do');
            });
          });

          $scope.toggleShowRequestTimes = function () {
            $scope.showRequestTimes = !$scope.showRequestTimes;
          };

          $scope.openAppointmentProposalModal = function () {
            self._openProposal();
          };

          $scope.tryRejectRequests = function () {
            var prospect = sampleRequest.prospect;
            rejectRequestsModalFactory.createModal(
              prospect.profile,
              self._rejectRequests,
              self._openProposal
            );
          };

          $scope.mouseEnteredBorder = function () {
            $scope.borderColor =
              '1px solid rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              ENTERED_BORDER_OPACITY +
              ')';
          };

          $scope.mouseLeftBorder = function () {
            $scope.borderColor =
              '1px solid rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              LEFT_BORDER_OPACITY +
              ')';
          };

          $scope.mouseEnteredRequest = function (request) {
            managerCalendarService.setRequestHighlighted(request);
          };

          $scope.mouseLeftRequest = function (request) {
            managerCalendarService.clearHighlightedRequest(request);
          };

          self._openProposal = function () {
            appointmentProposalsModalFactory.createModal(
              $scope.property,
              $scope.prospectId,
              self._deleteRequest
            );
          };

          self._rejectRequests = function (managerMessage) {
            calendarApiService
              .rejectRequests($scope.appointmentRequests, managerMessage)
              .success(self._deleteRequest);
          };

          self._deleteRequest = function () {
            $scope.isDeleted = true;
          };

          self._acceptRequest = function (request) {
            var canceledRequests = filter(
              $scope.appointmentRequests,
              function (canceledRequest) {
                return canceledRequest.id !== request.id;
              }
            );

            $scope.isRequesting = true;

            return calendarApiService
              .acceptRequest(request, canceledRequests)
              .then(function (response) {
                console.log(response.status);
                if (response.status !== 200) {
                  $mdToast.showSimple(
                    'An error occurred, if this problem persists please contact support.'
                  );
                }
                $scope.isDeleted = true;
              })
              .catch(function (error) {
                $mdToast.showSimple(
                  'An error occurred, if this problem persists please contact support.'
                );
                console.error(error);
              })
              .finally(function () {
                $scope.isRequesting = false;
              });
          };

          $scope.tryAcceptRequest = function (request) {
            confirmAppointmentModalFactory.createModal(
              $scope.prospect.profile,
              request,
              self._acceptRequest
            );
          };

          self._initialize = function () {
            self._setColors();

            var setAppointmentProperty = sampleRequest.property
              ? $q.when(sampleRequest.property)
              : sampleRequest.prospect
              ? self._setScopeAppointmentProperty(
                  sampleRequest.prospect.property_id
                )
              : self._setAppointmentProspectAndProperty();

            setAppointmentProperty.then(self._setupScope);
          };

          self._setColors = function () {
            $scope.requestButtonColor =
              'rgb(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ')';
            $scope.backgroundColor =
              'rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              BACKGROUND_OPACITY +
              ')';
            $scope.borderColor =
              '1px solid rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              LEFT_BORDER_OPACITY +
              ')';
            $scope.dividerColor =
              '1px solid rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              LEFT_BORDER_OPACITY +
              ')';
          };

          self._setScopeAppointmentProperty = function (propertyId) {
            return propertyApi
              .getProperty(propertyId, true)
              .success(function (response) {
                $scope.property = response.property;
              });
          };

          self._setAppointmentProspectAndProperty = function () {
            return prospectsApi
              .getProspect(sampleRequest.Appointment.prospect_id)
              .success(function (response) {
                var propertyId = response.prospect.property_id;

                return self._setScopeAppointmentProperty(propertyId);
              });
          };

          self._initialize();
        }
      ]
    };
  });

  app.directive('calendarProposedAppointments', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/calendar/sidebar-proposed-appointments.html',
      scope: {
        proposals: '=',
        managerColorRgb: '='
      },
      controller: [
        '$q',
        '$scope',
        'timeService',
        'userService',
        'calendarDataRepository',
        'propertyApi',
        'prospectsApi',
        function (
          $q,
          $scope,
          timeService,
          userService,
          calendarDataRepository,
          propertyApi,
          prospectsApi
        ) {
          var self = this;
          var BACKGROUND_OPACITY = 0.05;
          var ENTERED_BORDER_OPACITY = 0.7;
          var LEFT_BORDER_OPACITY = 0.12;

          $scope.user = userService.getUser();
          $scope.hideListing = true;

          $scope.appointmentProposals = sortBy(
            map($scope.proposals, 'Appointment'),
            'start_time'
          );

          $scope.sampleProposal = sample($scope.appointmentProposals);

          self._initialize = function () {
            self._setColors();
            self._getProposalManagerInfo();

            var setAppointmentProperty = $scope.sampleProposal.property
              ? $q.when($scope.sampleProposal.property)
              : $scope.sampleProposal.prospect
              ? self._setScopeAppointmentProperty(
                  $scope.sampleProposal.prospect.property_id
                )
              : self._setAppointmentProspectAndProperty();

            setAppointmentProperty.then(self._setupScope);
          };

          self._setColors = function () {
            $scope.backgroundColor =
              'rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              BACKGROUND_OPACITY +
              ')';
            $scope.borderColor =
              '1px solid rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              LEFT_BORDER_OPACITY +
              ')';
            $scope.dividerColor =
              '1px solid rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              LEFT_BORDER_OPACITY +
              ')';
          };

          self._getProposalManagerInfo = function () {
            var teamMember = find(
              calendarDataRepository.getTeamMembers(),
              function (teamMember) {
                return (
                  teamMember.managerId === $scope.sampleProposal.manager_id
                );
              }
            );

            $scope.proposalManagerInfo = teamMember
              ? teamMember
              : {
                  firstName: $scope.user.first_name,
                  lastName: $scope.user.last_name
                };
          };

          $scope.mouseEnteredBorder = function () {
            $scope.borderColor =
              '1px solid rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              ENTERED_BORDER_OPACITY +
              ')';
          };

          $scope.mouseLeftBorder = function () {
            $scope.borderColor =
              '1px solid rgba(' +
              $scope.managerColorRgb.r +
              ',' +
              $scope.managerColorRgb.g +
              ',' +
              $scope.managerColorRgb.b +
              ',' +
              LEFT_BORDER_OPACITY +
              ')';
          };

          $scope.$watch('appointmentProposals', function (proposals) {
            // this bit needs to be in a $watch to allow correct rendering when proposals are added asynchronously
            forEach(proposals, function (proposal) {
              proposal.formattedStartTime = timeService
                .get(proposal.start_time)
                .format('h:mma ddd, MMM Do');
            });
          });

          self._setScopeAppointmentProperty = function (propertyId) {
            return propertyApi
              .getProperty(propertyId, true)
              .success(function (response) {
                $scope.property = response.property;
              });
          };

          self._setAppointmentProspectAndProperty = function () {
            return prospectsApi
              .getProspect($scope.sampleProposal.prospect_id)
              .success(function (response) {
                var propertyId = response.prospect.property_id;

                return self._setScopeAppointmentProperty(propertyId);
              });
          };

          self._initialize();
        }
      ]
    };
  });

  app.controller('TransferAppointmentManagerModalController', [
    '$scope',
    '$modalInstance',
    'calendarApiService',
    'calendarDataRepository',
    'appointment',
    'newActiveManagerId',
    'onSuccess',
    'onCancel',
    function (
      $scope,
      $modalInstance,
      calendarApiService,
      calendarDataRepository,
      appointment,
      newActiveManagerId,
      onSuccess,
      onCancel
    ) {
      var newActiveManager = find(calendarDataRepository.getTeamMembers(), {
        managerId: newActiveManagerId
      });

      $scope.cancelTransfer = function () {
        onCancel();
        $modalInstance.dismiss();
      };

      $scope.transferAppointment = function () {
        calendarApiService
          .transferAppointment(appointment, newActiveManager)
          .success(function (response) {
            onSuccess(response);
            $modalInstance.dismiss();
          });
      };
    }
  ]);
})(window.angular);
