/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('loginModalService', [
    '$modal',
    function ($modal) {
      var loginModalService = {};

      loginModalService.openModal = function (userOptions) {
        var defaultOptions = {
          // (BS) TODO: There is a bug in our angular dependencies that affects
          // event handling when animations are used with modals. Disabling
          // animations as a workaround, to permanently fix this we will need
          // to update our version of angular or rewrite this modal as an
          // mdDialog.
          animation: false,
          templateUrl: '/angular/views/user/loginModal.html',
          controller: 'loginModalController',
          size: 'sm'
        };

        var options = angular.extend(defaultOptions, userOptions);
        return $modal.open(options);
      };

      return loginModalService;
    }
  ]);
})();
