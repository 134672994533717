import React, { FC, useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Chip,
  Divider,
  Select,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Input,
  Typography
} from '@material-ui/core';
import classNames from 'classnames';
import {
  makeStyles,
  Button,
  Box,
  ThemeProvider,
  NamedColors,
  white
} from '@knockrentals/knock-shared-web';
import { createStyles, Theme } from '@material-ui/core/styles';
import { useAngularContext } from '../../AngularContextProvider';
import { CircularProgressButton } from '../../CircularProgressButton';
import { useProspects } from 'app/services/prospects/hooks';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { colors, useCommonStyles } from '../../commonStyles/commonStyles';
import { calculateItemsToDisplay, sortTextBySize } from './utils';
const errorColor = '#F44336';
const { inputError } = colors;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    paperWidth: {
      width: '600px',
      maxHeight: '450px'
    },
    formLabel: {
      top: 20,
      left: 2,
      fontWeight: 400
    },

    dialogContent: {
      padding: '0 16px 0 16px'
    },

    errorText: {
      color: errorColor
    },
    textInput: {
      width: '100%',
      border: 'none !important',
      color: 'black !important',
      padding: '0px',
      '& .MuiInputLabel-formControl': {
        left: '0',
        margin: 0,
        transform: 'none',
        border: 'none !important'
      },
      '& .MuiInputBase-formControl': {
        borderColor: NamedColors.slate[300],
        margin: '0px !important'
      },
      '& .MuiTypography-root': {
        fontSize: '14px'
      },
      '& .MuiFormHelperText-root': {
        color: inputError,
        marginLeft: '4px'
      },
      '& .MuiListItem-button': {
        '&:hover': {
          backgroundColor: 'black',
          boxShadow: 'none'
        }
      },
      '& .MuiFilledInput-root': {
        backgroundColor: colors.inputBackground
      }
    },
    textInputError: {
      '& .MuiFormLabel-root.Mui-focused': {
        color: errorColor
      }
    },
    boxStyle: {
      display: 'flex',
      gap: '15px',
      justifyContent: 'flex-end'
    },

    formControl: {
      width: '100%',
      marginBottom: 0,

      '& .MuiInputBase-formControl': {
        marginTop: '0 !important'
      },

      '& .MuiInputBase-root': {
        borderBottom: 'none',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        background: white,
        margin: 0,
        padding: 0
      }
    },
    chipsSection: {
      backgroundColor: `${white} !important`,
      border: 'none'
    },

    dialogActions: {
      paddingBottom: '20px',
      paddingRight: '22px'
    },
    alert: {
      backgroundColor: colors.snackbarBackground
    },
    alertIcon: {
      marginRight: '10px'
    },
    menuItemHover: {
      '&:hover': {
        backgroundColor: '#F5F6FF' // Change this to your desired color
      }
    },
    menuItemSelected: {
      backgroundColor: `${colors.defaultText} !important` // Change this to your desired selected color
    },
    cancelButton: {
      border: `1px solid ${NamedColors.denim[600]}`,
      color: NamedColors.denim[600],
      lineHeight: '24px'
    },
    chipContainer: {
      borderBottom: `1px solid ${NamedColors.slate[300]}`,
      paddingBottom: '4px',
      paddingLeft: '4px'
    },

    chips: {
      display: 'flex',
      overflow: 'hidden'
    },

    chip: {
      backgroundColor: colors.chipBackground,
      color: colors.defaultText,
      fontSize: '12px',
      marginRight: '8px',

      '&:last-child': {
        marginRight: 0
      },

      '& .MuiChip-labelSmall': {
        paddingLeft: '9px',
        paddingRight: '9px',
        paddingTop: '2px'
      }
    }
  })
);

interface ProfileProspect {
  fullName: string;
}

interface Profile {
  id: number;
  profile: ProfileProspect;
}
interface ChangeOwnerModalProps {
  showChangeOwnerModal: boolean;
  closeModal: (withEffect?: boolean | undefined) => void;
  prospectsToShow: Profile[];
}

const ChangeOwnerModal: FC<ChangeOwnerModalProps> = ({
  showChangeOwnerModal,
  closeModal,
  prospectsToShow
}) => {
  const classes = useStyles();
  const {
    boxStyle,
    paperWidth,
    formLabel,
    textInput,
    textInputError,
    dialogActions,
    alert,
    alertIcon,
    menuItemHover
  } = useStyles();
  const commonClasses = useCommonStyles();
  const [owners, setOwners] = useState<Array<{ value: number; label: string }>>(
    []
  );
  const [selectedOwner, setSelectedOwner] = useState<string>('');
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);
  const [recordsToDisplay, setRecordsToDisplay] = useState<string[]>([]);
  const [loading, setIsLoading] = useState<boolean>(false);
  const { managers } = useAngularContext();
  const selectedProspects = prospectsToShow.map((prospect) => prospect?.id);
  const [snackMessage, setSnackMessage] = useState<string | null>(null);
  const [filtered, setFiltered] = useState<boolean>(false);

  const handleChange = (event: any) => {
    setSelectedOwner(event.target.value);
    setHasValidationError(false);
  };

  const handleClose = () => {
    closeModal();
  };
  const { changeOwner } = useProspects();

  const updateChipsToDisplay = () => {
    if (recordsToDisplay.length !== prospectsToShow.length || filtered) {
      return;
    }
    const calculation = calculateItemsToDisplay<string>(
      recordsToDisplay,
      'chip-container'
    );

    if (calculation) {
      const { toDisplay, toHide } = calculation;
      setFiltered(true);
      setRecordsToDisplay([
        ...toDisplay,
        ...(toHide === recordsToDisplay.length
          ? [`${toHide} Prospect${toHide > 1 ? 's' : ''}`]
          : []),
        ...(toHide > 0 && toHide !== recordsToDisplay.length
          ? [`+${toHide}`]
          : [])
      ]);
    }
  };

  const submitHandler = async () => {
    if (!selectedOwner) {
      setHasValidationError(true);
      return;
    } else {
      setIsLoading(true);
      const resultChangeOwner = await changeOwner({
        newAssignedManagerId: Number(selectedOwner),
        prospectIds: selectedProspects
      });

      if (resultChangeOwner.hasOwnProperty('error')) {
        setSnackMessage('We were unable to change owners. Please try again.');
      } else {
        const newOwnerFullName =
          owners.find(
            (m: { value: number; label: string }) =>
              m.value.toString() === selectedOwner.toString()
          )?.label || 'unknown';
        if (selectedProspects.length === 1) {
          setSnackMessage(
            `The owner for ${prospectsToShow[0].profile.fullName} has now been changed to ${newOwnerFullName}.`
          );
        } else {
          setSnackMessage(
            `The owner for ${selectedProspects.length} prospects has now been changed to ${newOwnerFullName}.`
          );
        }
      }
      setIsLoading(false);
      setHasValidationError(false);
      closeModal(true);
    }
  };

  useEffect(() => {
    setSelectedOwner('');
    setFiltered(false);
  }, [showChangeOwnerModal]);

  useEffect(() => {
    const sortedManagers = managers.sort((lValue: any, rValue: any) =>
      lValue.ManagerInfo.first_name.localeCompare(rValue.ManagerInfo.first_name)
    );

    const managersToSelect = sortedManagers.map((manager: any) => ({
      label: `${manager.ManagerInfo.first_name} ${manager.ManagerInfo.last_name}`,
      value: manager.ManagerInfo.manager_id?.toString()
    }));

    setOwners(managersToSelect);
  }, [managers]);

  const handleCloseAlert = () => {
    setSnackMessage(null);
  };

  useEffect(() => {
    if (showChangeOwnerModal && prospectsToShow.length > 0) {
      setRecordsToDisplay(
        prospectsToShow
          .map((record: Profile) => {
            return record.profile.fullName;
          })
          .sort(sortTextBySize('asc'))
      );
    }
  }, [showChangeOwnerModal, prospectsToShow]);

  return (
    <ThemeProvider>
      <Snackbar
        ContentProps={{
          classes: {
            root: alert
          }
        }}
        data-testid="snack-alert-message"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!snackMessage}
        message={snackMessage}
        action={
          <IconButton
            className={alertIcon}
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseAlert}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      <Dialog
        open={showChangeOwnerModal}
        classes={{ paperWidthSm: paperWidth }}
        onClose={handleClose}
      >
        <DialogTitle>Change Owner</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.chipContainer}>
            <FormControl className={classes.formControl}>
              <Typography
                variant="caption"
                id="chip-label"
                variantMapping={{ caption: 'div' }}
              >
                Prospects
              </Typography>

              <Select
                IconComponent={() => null}
                multiple
                className={classes.chipsSection}
                value={recordsToDisplay}
                input={<Input />}
                renderValue={(selected: any) => (
                  <div id="chip-container" className={classes.chips}>
                    {selected.map((value: string, index: number) => {
                      if (index + 1 === selected.length) {
                        updateChipsToDisplay();
                      }

                      return (
                        <Chip
                          key={index}
                          label={value}
                          className={classes.chip}
                          size="small"
                        />
                      );
                    })}
                  </div>
                )}
                disabled
              >
                {recordsToDisplay.map((name: string, index: number) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <form className={classes.container}>
            <FormControl
              variant="filled"
              className={classNames(textInput, {
                [textInputError]: true
              })}
            >
              <InputLabel id="change-owner-label" className={formLabel}>
                Owner
              </InputLabel>
              <Select
                labelId="change-owner-label"
                id="change-owner"
                value={selectedOwner}
                onChange={handleChange}
                error={hasValidationError}
                className={classNames(textInput, {
                  [textInputError]: true
                })}
                inputProps={{ 'data-testid': 'change-owner' }}
              >
                {owners.map((owner, index) => (
                  <MenuItem
                    key={index}
                    value={owner.value}
                    classes={{ root: menuItemHover }}
                  >
                    {owner.label}
                  </MenuItem>
                ))}
              </Select>
              {hasValidationError ? (
                <FormHelperText className={classes.errorText}>
                  Please select an owner
                </FormHelperText>
              ) : (
                <></>
              )}
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions className={dialogActions}>
          <Box className={boxStyle}>
            <Button
              variant="outlined"
              onClick={handleClose}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <CircularProgressButton
              className={commonClasses.primaryButton}
              onClick={submitHandler}
              shouldShowProgress={loading}
              progressText={'Saving...'}
            >
              Save ({Object.keys(selectedProspects).length})
            </CircularProgressButton>
          </Box>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ChangeOwnerModal;
