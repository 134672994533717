import React, { FC } from 'react';

export const SoundOnIcon: FC = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#EBEEFE" />
    <path
      d="M6 6.00003L12.6 1.80003C13.0545 1.45895 13.6627 1.40397 14.171 1.65803C14.6792 1.91223 15.0002 2.43179 15 3.00003V15C15 15.5682 14.679 16.0876 14.1708 16.3417C13.6626 16.5958 13.0545 16.5409 12.6 16.2L6 12M6 6.00003H3C2.17157 6.00003 1.5 6.67161 1.5 7.50003V10.5C1.5 11.3285 2.17157 12 3 12H6M6 6.00003V12M21.463 12.75C22.8444 10.5849 22.8444 7.81512 21.463 5.65003M18.562 11.441C19.6045 10.1578 19.6045 8.31931 18.562 7.03603"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(12, 14)"
    />
  </svg>
);
