import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

import { QuoteErrorMessages } from './validation';
import { getProRatedDays } from './utils';

import LeaseDetailsDisplay from './LeaseDetailsDisplay';
import LeaseDetailsOptions from './LeaseDetailsOptions';

export interface LeaseDetailsProps {
  blackoutDatesMap: { [key: string]: string };
  endDate?: string;
  errorMessages: QuoteErrorMessages;
  leaseEndDate?: string;
  leaseStartDate?: string;
  hasValidRentMatrix: boolean;
  openRentMatrix: () => void;
  setErrorMessages: (errorMessages: any) => void;
  setLeaseStartDate: (dateString: string) => void;
  setLeaseEndDate: (dateString: string) => void;
  selectedLeaseTerm: number;
  setSelectedLeaseTerm: (leaseTerm: number) => void;
  startDate?: string;
}

const LeaseDetails: FC<LeaseDetailsProps> = ({
  blackoutDatesMap,
  errorMessages,
  leaseStartDate,
  leaseEndDate,
  hasValidRentMatrix,
  openRentMatrix,
  setErrorMessages,
  setLeaseStartDate,
  setLeaseEndDate,
  selectedLeaseTerm,
  setSelectedLeaseTerm
}) => {
  const proRatedDays = getProRatedDays(
    leaseStartDate,
    leaseEndDate,
    selectedLeaseTerm
  );

  return (
    <section className="lease-details">
      <Typography variant="subtitle2">Lease Details</Typography>

      {hasValidRentMatrix ? (
        <LeaseDetailsDisplay
          leaseStartDate={leaseStartDate}
          leaseEndDate={leaseEndDate}
          openRentMatrix={openRentMatrix}
          proRatedDays={proRatedDays}
          selectedLeaseTerm={selectedLeaseTerm}
        />
      ) : (
        <LeaseDetailsOptions
          blackoutDatesMap={blackoutDatesMap}
          errorMessages={errorMessages}
          leaseStartDate={leaseStartDate}
          leaseEndDate={leaseEndDate}
          proRatedDays={proRatedDays}
          selectedLeaseTerm={selectedLeaseTerm}
          setErrorMessages={setErrorMessages}
          setLeaseStartDate={setLeaseStartDate}
          setLeaseEndDate={setLeaseEndDate}
          setSelectedLeaseTerm={setSelectedLeaseTerm}
        />
      )}
    </section>
  );
};

export default LeaseDetails;
