/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('mapUtilitiesService', [
    '$window',
    function ($window) {
      var self = this;

      self.createListingLabelOptions = function (number) {
        var label = number ? number.toString() : '';
        var isDoubleDigits = number && number >= 10;

        var backgroundStyle = {
          'background-image': 'url(' + '/images/marker.png)',
          'background-size': 'cover',
          'background-position': 'top left',
          'background-repeat': 'no-repeat'
        };

        return {
          labelContent: label,
          labelAnchor: '13 12',
          labelClass: isDoubleDigits
            ? 'marker-label-double-digit'
            : 'marker-label-single-digit',
          labelStyle: backgroundStyle,
          labelInBackground: false
        };
      };

      self.createListingMarker = function (
        id,
        coords,
        data,
        templateUrl,
        numberLabel
      ) {
        var options = self.createListingLabelOptions(numberLabel);

        var iconImage = {
          url: '/images/1x1.png',
          scaledSize: new $window.google.maps.Size(1, 1)
        };

        return {
          longitude: coords[0],
          latitude: coords[1],
          id: id,
          templateUrl: templateUrl,
          data: data,
          icon: iconImage,
          options: options
        };
      };

      return self;
    }
  ]);
})();
