/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import includes from 'lodash/includes';
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.factory('prospectLossReasonsService', [
    '$rootScope',
    'prospectLossReasonsModalFactory',
    'batchProspectsLossReasonsModalFactory',
    'prospectsApi',
    function (
      $rootScope,
      prospectLossReasonsModalFactory,
      batchProspectsLossReasonsModalFactory,
      prospectsApi
    ) {
      var self = this;

      self.openProspectLossReasonsModal = function (prospect) {
        return prospectLossReasonsModalFactory.openModal(prospect);
      };

      self.openBatchProspectsLossReasonsModal = function (
        prospects,
        prospectStatus
      ) {
        return batchProspectsLossReasonsModalFactory.openModal(
          prospects,
          prospectStatus
        );
      };

      self.getProspectLossReasons = prospectsApi.getProspectLossReasons;

      self.getLossReasons = function (prospect) {
        return prospectsApi.getProspectLossReasons(prospect.id);
      };

      self.setLossReasons = function (prospect, lossReasons) {
        return prospectsApi.setProspectLossReasons(prospect.id, lossReasons);
      };

      self.getAllLossReasons = function () {
        return prospectsApi.getAllLossReasons();
      };

      self.getProspect = function (prospectId) {
        return prospectsApi.getProspect(prospectId);
      };

      self.statusIsLost = function (status) {
        return includes(['applied-lost', 'lost'], status);
      };

      return self;
    }
  ]);
})(window.angular);
