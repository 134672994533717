export default function configurePusher(app: any) {
  app.config([
    'pusherInstanceServiceProvider',
    'pusherKey',
    'api',
    function (pusherInstanceServiceProvider: any, pusherKey: any, api: any) {
      pusherInstanceServiceProvider.setPusherSettings(
        pusherKey,
        api.host + '/chats/auth'
      );
    }
  ]);
}
