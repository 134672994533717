/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
'use strict';

angular.module('knockApp').filter('fromNow', [
  '$moment',
  function ($moment) {
    return function (input, small, onlySmallDay, onlyWeeks) {
      if (!input) {
        return 'Never';
      }

      var inputMoment = $moment(input);

      var thirtySecondsAgo = $moment().subtract(30, 'seconds');

      if (
        inputMoment.isAfter($moment()) ||
        inputMoment.isAfter(thirtySecondsAgo)
      ) {
        return '1s';
      }

      if (!small) {
        var dayAgo = $moment().subtract(1, 'days');
        return inputMoment.isBefore(dayAgo)
          ? inputMoment.format('M/D/YY')
          : $moment(input).fromNow();
      }

      var now = $moment();

      var difference = now - inputMoment;
      var duration = $moment.duration(difference);

      var years = Math.round(duration.asYears());
      var months = Math.round(duration.asMonths());
      var weeks = Math.round(duration.asWeeks());
      var days = Math.round(duration.asDays());
      var hours = Math.round(duration.asHours());
      var minutes = Math.round(duration.asMinutes());
      var seconds = Math.round(duration.asSeconds());

      if ((months || years || (onlySmallDay && days)) && !onlyWeeks) {
        return inputMoment.format('M/D/YY');
      }

      if (weeks && onlyWeeks) {
        return weeks.toString() + 'w';
      }

      if (days) {
        return days.toString() + 'd';
      }

      if (hours) {
        return hours.toString() + 'h';
      }

      if (minutes) {
        return minutes.toString() + 'm';
      }

      if (seconds) {
        return seconds.toString() + 's';
      }

      return 'Just now';
    };
  }
]);
