/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('managerScheduling', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/preferences/manager-scheduling.html',
      controller: 'ManagerSchedulingSettingsController',
      scope: {
        settings: '='
      }
    };
  });

  app.controller('ManagerSchedulingSettingsController', [
    '$scope',
    'timeService',
    function ($scope, timeService) {
      var self = this;

      $scope.settings = $scope.settings || {
        all_showings: true,
        tour_duration: 15,
        adjacent_showing_interval: null,
        concurrent_showing_visitors: null,
        availability_buffer: 1
      };

      self._initialize = function () {
        $scope.$watch('settings.all_showings', function () {
          if ($scope.settings.all_showings) {
            $scope.settings.adjacent_showing_interval =
              $scope.settings.tour_duration;
            $scope.settings.concurrent_showing_visitors =
              $scope.settings.concurrent_showing_visitors || 1;
          }
        });

        if (!isNumber($scope.settings.tour_duration)) {
          var tourDurationMoment = timeService.get(
            $scope.settings.tour_duration,
            'HH:mm:ss'
          );
          $scope.settings.tour_duration =
            tourDurationMoment.hours() * 60 + tourDurationMoment.minutes();
        }

        if (!isNumber($scope.settings.availability_buffer)) {
          if (isString($scope.settings.availability_buffer)) {
            // If availability_buffer is 24 hours (the max), the string looks like '1 day, 00:00'
            var bufferString = $scope.settings.availability_buffer;
            var bufferRegex = /day/;
            var bufferHours;

            if (bufferRegex.test(bufferString)) {
              bufferHours = 24;
            } else {
              var bufferMoment = timeService.get(bufferString, 'HH:mm:ss');
              bufferHours = bufferMoment.hours();
            }

            $scope.settings.availability_buffer = bufferHours;
          } else {
            $scope.settings.availability_buffer = 1;
          }
        }
      };

      $scope.setAllShowings = function (val) {
        $scope.settings.all_showings = val;
      };

      self._initialize();
    }
  ]);
})(window.angular);
