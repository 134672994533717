/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('syncProspectButton', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospects/sync-prospect-button.html',
      scope: {
        prospect: '=',
        credentialId: '='
      },
      controller: [
        '$rootScope',
        '$scope',
        '$mdToast',
        'newProspectService',
        function ($rootScope, $scope, $mdToast, newProspectService) {
          $scope.data = {
            isSyncing: false
          };

          $scope.syncProspect = function () {
            if ($scope.data.isSyncing) {
              return;
            }

            $scope.data.isSyncing = true;

            newProspectService
              .syncPMSProspect($scope.prospect.id, $scope.credentialId)
              .then(
                function () {
                  $mdToast.showSimple('Prospect synced!');
                  $scope.data.isSyncing = false;
                },
                function () {
                  $mdToast.showSimple('Error syncing prospect.');
                  $scope.data.isSyncing = false;
                }
              );
          };
        }
      ]
    };
  });
})(window.angular);
