/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('residentEventDelivery', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/residentEvents/resident-event-delivery.html',
      scope: {
        delivery: '=',
        resident: '='
      },
      controller: [
        '$scope',
        'appDataService',
        'userInteractionsService,',
        function ($scope, appDataService, userInteractionsService) {
          var receivedByManagerInfo = appDataService.getTeamMember(
            $scope.delivery.received_by
          ).ManagerInfo;

          $scope.receivedByManagerName =
            receivedByManagerInfo.first_name +
            ' ' +
            receivedByManagerInfo.last_name;

          $scope.confirmClaim = function () {
            $scope.isClaimingDelivery = true;

            userInteractionsService
              .claimResidentDelivery($scope.resident.id, $scope.delivery.id)
              .then(function () {
                $scope.delivery.claimed_at = new Date();
              })
              .finally(function () {
                $scope.isConfirmingDeliveryClaim = false;
                $scope.isClaimingDelivery = false;
              });
          };
        }
      ]
    };
  });
})(window.angular);
