import React from 'react';

import Unsubscribe from './Unsubscribe';
import FeedbackSubmitted from './FeedbackSubmitted';
import Resubscribe from './Resubscribe';
import LandingPage from './LandingPage';

import {
  Card,
  CardActions,
  Text,
  Button,
  ThemeProvider
} from '@knockrentals/knock-shared-web';

import './Unsubscribe.scss';
import {
  OptOutReason,
  giveFeedbackCall,
  resubscribeCall,
  unsubscribeCall
} from './unsubscribeService';
import { useNotification, NotificationProvider } from './notifyHook';

type View = 'feedback' | 'feedbackSubmitted' | 'resubscribe' | 'landing';

const viewFeedback = 'feedback',
  viewFeedbackSubmitted = 'feedbackSubmitted',
  viewResubscribe = 'resubscribe',
  viewLanding = 'landing';

const UnsubscribeContainer = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [view, setView] = React.useState<View>(viewLanding);

  const { notify } = useNotification();

  const params = new URLSearchParams(document.location.search);
  const unsubscribeKey = params.get('key');

  const submitFeedback = async (
    reason?: OptOutReason,
    customReason?: string
  ) => {
    if (!reason) {
      return;
    }
    if (reason === 'custom' && !customReason) {
      notify('If you select Other, you must provide a reason');
      return;
    }
    if (!unsubscribeKey) {
      // Could not find key in query params
      notify('Unable to provide feedback');
      return;
    }
    setIsLoading(true);
    try {
      await giveFeedbackCall(reason, customReason, unsubscribeKey);
      setView(viewFeedbackSubmitted);
    } catch (e) {
      notify(`Unable to provide feedback: ${(e as Error).message}`);
    }
    setIsLoading(false);
  };

  const resubscribe = async () => {
    if (!unsubscribeKey) {
      notify('Unable to resubscribe');
      return;
    }
    setIsLoading(true);
    try {
      await resubscribeCall(unsubscribeKey);
      setView(viewResubscribe);
    } catch (e) {
      notify(`Unable to resubscribe: ${(e as Error).message}`);
    }
    setIsLoading(false);
  };

  const unsubscribe = async () => {
    if (!unsubscribeKey) {
      notify('Unable to resubscribe: invalid unsubscribe link');
      return;
    }

    setIsLoading(true);
    try {
      await unsubscribeCall(unsubscribeKey);
      setView(viewFeedback);
    } catch (e) {
      notify(`Unable to unsubscribe: ${(e as Error).message}`);
    }
    setIsLoading(false);
  };

  return (
    <div className="unsubscribe-container">
      {view === viewLanding && (
        <div
          className="unsubscribe-content"
          style={{
            textAlign: 'center'
          }}
        >
          If you are a resident you will continue to receive direct
          communication and important resident communications.
        </div>
      )}
      <Card>
        {view === viewLanding && (
          <LandingPage isLoading={isLoading} unsubscribeHandler={unsubscribe} />
        )}
        {view === viewFeedbackSubmitted && <FeedbackSubmitted />}
        {view === viewFeedback && (
          <Unsubscribe isLoading={isLoading} submitFeedback={submitFeedback} />
        )}
        {view === viewResubscribe && <Resubscribe />}
        {view !== viewResubscribe && view !== viewLanding ? (
          <CardActions>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <div
                style={{
                  marginTop: '-5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Text variant="body2">Made a mistake? </Text>
                <Button variant="text" onClick={() => resubscribe()}>
                  Subscribe Again
                </Button>
              </div>
              <Text variant="caption">
                You will be resubscribed if you initiate future communications.
              </Text>
            </div>
          </CardActions>
        ) : null}
      </Card>
    </div>
  );
};

export default (props: any) => {
  return (
    <ThemeProvider>
      <NotificationProvider>
        <UnsubscribeContainer />
      </NotificationProvider>
    </ThemeProvider>
  );
};
