/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('changeTimeModalFactory', [
    '$mdDialog',
    '$moment',
    function ($mdDialog, $moment) {
      var self = this;

      self.openChangeTimeModal = function (event, appointment, preferences) {
        if (isEmpty(event)) {
          event = {
            start: $moment(appointment.start_time),
            end: $moment(appointment.end_time)
          };
        }

        if (isEmpty(event.start)) {
          event.start = $moment(appointment.start_time);
        }

        if (isEmpty(event.end)) {
          event.end = $moment(appointment.end_time);
        }

        return $mdDialog.show({
          controller: 'ChangeTimeModalController',
          templateUrl: '/angular/views/calendar/confirm-change-time-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          locals: { event: event, appointment: appointment, preferences }
        });
      };

      return self;
    }
  ]);

  app.controller('ChangeTimeModalController', [
    '$scope',
    'timeService',
    'event',
    'appointment',
    'calendarApiService',
    '$mdDialog',
    '$moment',
    'preferences',
    function (
      $scope,
      timeService,
      event,
      appointment,
      calendarApiService,
      $mdDialog,
      $moment,
      preferences
    ) {
      var self = this;
      var DATE_FORMAT_STRING = 'ddd, MMM D, YYYY';

      $scope.tour_type = appointment.tour_type;
      if (preferences) {
        $scope.inPersonEnabled = preferences.in_person_tours !== false;
        $scope.videoEnabled = !!preferences.live_video_tour_type;
        $scope.selfGuidedEnabled = !!preferences.self_guided_tours_enabled;
      }
      $scope.newEventTimes = {
        date: event.start.format(DATE_FORMAT_STRING),
        start: event.start,
        end: event.end
      };

      $scope.startTimeChanged = function () {
        var newEnd = $moment($scope.newEventTimes.start).add(
          $scope.tourLength,
          'minutes'
        );

        $scope.newEventTimes.end = find(
          $scope.timeChoices,
          function (timeChoice) {
            return (
              timeChoice.hour() === newEnd.hour() &&
              timeChoice.minute() === newEnd.minute()
            );
          }
        );
      };

      $scope.updateTourLength = function () {
        $scope.tourLength = $scope.newEventTimes.end.diff(
          $scope.newEventTimes.start,
          'minutes'
        );
      };

      $scope.close = function () {
        $mdDialog.cancel();
      };

      $scope.confirm = function () {
        var start = timeService.get($scope.newEventTimes.start);
        var end = timeService.get($scope.newEventTimes.end);

        var dateChanged =
          $scope.newEventTimes.date !==
          $scope.newEventTimes.start.format(DATE_FORMAT_STRING);
        let startDate = $scope.newEventTimes.start;
        let endDate = $scope.newEventTimes.end;

        if (dateChanged) {
          startDate = timeService.combineDateAndTime(
            dayjs($scope.newEventTimes.date).format('YYYY-MM-DD'),
            $scope.newEventTimes.start
          );
          endDate = timeService.combineDateAndTime(
            dayjs($scope.newEventTimes.date).format('YYYY-MM-DD'),
            $scope.newEventTimes.end
          );
        }

        if (start.isAfter(end) || start.isSame(end)) {
          $scope.startAfterEndError = true;
          return;
        }

        let tourType = undefined;

        if ($scope.tour_type !== appointment.tour_type) {
          tourType = $scope.tour_type;
        }

        calendarApiService.changeAppointmentTime(
          appointment,
          startDate,
          endDate,
          tourType
        );
        $mdDialog.hide($scope.newEventTimes);
      };

      self._initialize = function () {
        $scope.timeChoices = self._generateTimeChoices(
          timeService.get($scope.newEventTimes.start).startOf('day'),
          timeService.get($scope.newEventTimes.end).endOf('day')
        );

        $scope.newEventTimes.start = find(
          $scope.timeChoices,
          function (timeChoice) {
            return (
              timeChoice.hour() === event.start.hour() &&
              timeChoice.minute() === event.start.minute()
            );
          }
        );

        if (!$scope.newEventTimes.start) {
          $scope.newEventTimes.start = event.start;
        }

        $scope.newEventTimes.end = find(
          $scope.timeChoices,
          function (timeChoice) {
            return (
              timeChoice.hour() === event.end.hour() &&
              timeChoice.minute() === event.end.minute()
            );
          }
        );

        if (!$scope.newEventTimes.end) {
          $scope.newEventTimes.end = event.end;
        }

        $scope.updateTourLength();
      };

      self._generateTimeChoices = function (start, end) {
        var timeSlots = [];
        var timeWalker = timeService.get(start).startOf('day').hour(7);
        var todayEnd = timeService.get(end).startOf('day').hour(22);

        while (timeWalker.isBefore(todayEnd)) {
          timeSlots.push(timeService.clone(timeWalker));
          timeWalker.add(5, 'minutes');
        }

        return timeSlots;
      };

      self._initialize();
    }
  ]);
})(window.angular);
