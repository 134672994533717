import React, { FC } from 'react';
import { makeStyles, SvgIcon, Box } from '@material-ui/core';
import { ThemeProvider } from '@knockrentals/knock-shared-web';
import { BatchEmailIcon } from '../../icons';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative'
  },
  batchEmailIcon: {
    fill: 'none',
    position: 'absolute',
    left: '-18px',
    top: '-1px'
  }
}));

const BatchEmailIndicator: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container} component="span">
      <SvgIcon
        viewBox="0 0 18 18"
        className={classes.batchEmailIcon}
        fontSize="small"
      >
        <BatchEmailIcon />
      </SvgIcon>
      Batch
    </Box>
  );
};

export default () => (
  <ThemeProvider>
    <BatchEmailIndicator />
  </ThemeProvider>
);
