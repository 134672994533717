import React, { FC, useState } from 'react';
import { TextField } from '@material-ui/core';

interface CharacterCountTextFieldProps {
  label: string;
  variant?: string;
  placeholder?: string;
  value: string;
  maxLength?: number;
  showCharCount?: boolean;
  imageIndex?: number;
  onChange: (s: string, i: number | undefined) => void;
}

const CharacterCountTextField: FC<CharacterCountTextFieldProps> = ({
  label,
  variant = 'outlined',
  placeholder,
  value,
  maxLength,
  showCharCount = false,
  imageIndex,
  onChange,
  ...other
}) => {
  const [transientValue, setTransientValue] = useState<string>(value || '');
  return (
    <>
      <TextField
        fullWidth
        label={label}
        value={transientValue}
        variant={variant as any}
        placeholder={placeholder}
        className="character-count-text-field"
        inputProps={{
          maxLength,
          className: 'character-count-text-field-input',
          'data-testid': 'character-count-text-field'
        }}
        InputLabelProps={{
          shrink: true,
          className: 'character-count-text-field-input-label'
        }}
        onChange={(e) => {
          const val = e.target.value;
          setTransientValue(val);
          onChange && onChange(val, imageIndex);
        }}
        {...other}
      />
      {showCharCount && maxLength && (
        <div className="character-count-text-field-char-count">{`${transientValue.length}/${maxLength}`}</div>
      )}
    </>
  );
};

export default CharacterCountTextField;
