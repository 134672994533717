/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.factory('conversationModalService', [
    '$rootScope',
    function ($rootScope) {
      var self = this;

      self.events = {
        openConversationModal: 'openConversationModal'
      };

      self.openConversationModal = function (conversationId) {
        $rootScope.$emit(self.events.openConversationModal, conversationId);
      };

      return self;
    }
  ]);

  app.directive('conversationModal', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/conversation-modal.html',
      controller: 'ConversationModalController'
    };
  });

  app.controller('ConversationModalController', [
    '$rootScope',
    '$scope',
    '$timeout',
    '$location',
    'conversationModalService',
    'streamRepositoryService',
    'pageTitleService',
    'prospectHistoryService',
    function (
      $rootScope,
      $scope,
      $timeout,
      $location,
      conversationModalService,
      streamRepositoryService,
      pageTitleService,
      prospectHistoryService
    ) {
      var self = this;

      var prospectDeletedHandler = $rootScope.$on(
        prospectHistoryService.events.prospectDeleted,
        function () {
          $scope.closeModal();
        }
      );

      var residentDeletedHandler = $rootScope.$on(
        prospectHistoryService.events.residentDeleted,
        function () {
          $scope.closeModal();
        }
      );

      $scope.$on('$destroy', function () {
        prospectDeletedHandler();
        residentDeletedHandler();
      });

      $scope.data = {
        conversation: null,
        isLoading: false
      };

      $scope.closeModal = function () {
        if ($scope.data.conversation) {
          $location.update_path(self._lastPath, true);
        }

        $scope.data.conversation = null;
      };

      $rootScope.$on(
        conversationModalService.events.openConversationModal,
        function (event, conversationId) {
          self._lastPath = $location.path();

          $location.update_path('/conversation/' + conversationId, true);
          $location.search('');

          self._loadConversation(conversationId);
        }
      );

      self._loadConversation = function (conversationId) {
        $scope.data.isLoading = true;

        streamRepositoryService.getStream(conversationId, true).then(
          function (response) {
            if ($scope.data.conversation) {
              $scope.data.conversation = null;

              $timeout(function () {
                self._assignConversation(response.data.stream);
              }, 500);

              return;
            }

            self._assignConversation(response.data.stream);
          },
          function () {
            $scope.data.isLoading = false;
          }
        );
      };

      self._assignConversation = function (conversation) {
        $scope.data.conversation = conversation;

        var prospectParticipant = find(
          conversation.participants,
          function (participant) {
            return ['prospect', 'resident'].indexOf(participant.type) >= 0;
          }
        );

        if (prospectParticipant) {
          var pageTitle =
            prospectParticipant.info.first_name +
            ' ' +
            prospectParticipant.info.last_name +
            ' | Knock';
          pageTitleService.setPageTitle(pageTitle);
        }

        $scope.data.isLoading = false;
      };
    }
  ]);
})(window.angular);
