/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('PhoneVerificationModalController', [
    '$scope',
    '$modalInstance',
    'verificationApi',
    'phoneNumber',
    'responsePromise',
    function (
      $scope,
      $modalInstance,
      verificationApi,
      phoneNumber,
      responsePromise
    ) {
      var self = this;

      $scope.phoneNumber = phoneNumber;

      $scope.title = 'Security Code';
      $scope.message =
        "Knock helps build trust and prevent spam by making sure you're communicating with a real person." +
        ' We’ll send you a code by SMS or read it to you over the phone. Enter the code below to confirm that you’re the' +
        ' person on the other end. Message and Data rates may apply.';

      $scope.smsVerifyText = 'Verify via SMS';
      $scope.callVerifyText = 'Verify via Call';
      $scope.cancelText = 'Cancel';

      $scope.sendVerificationSMS = function () {
        $scope.isSendingCode = true;
        verificationApi
          .verifySMS($scope.phoneNumber)
          .success(function (response) {
            responsePromise.resolve(response);
          })
          .error(function (error) {
            responsePromise.reject(error);
          })
          .finally(function () {
            $modalInstance.close('sms');
          });
      };

      $scope.sendVerificationCall = function () {
        verificationApi
          .verifyCall($scope.phoneNumber)
          .success(function (response) {
            responsePromise.resolve(response);
          })
          .error(function (error) {
            responsePromise.reject(error);
          })
          .finally(function () {
            $modalInstance.close('phone');
          });
      };

      $scope.cancelVerification = function () {
        $modalInstance.close('canceled');
      };
    }
  ]);
})();
