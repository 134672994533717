/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import every from 'lodash/every';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
(function (angular, $) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('kanbanColumn', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/kanban/kanban-column.html',
      controller: 'KanbanColumnController',
      transclude: true,
      replace: true,
      link: function (scope, element) {
        element.on('scroll', function () {
          var scrollPos = $(this).scrollTop();
          var kanbanSections = $(this).find('.kanban-section');

          kanbanSections.each(function () {
            var sectionTopPos = $(this).position().top;
            var sectionHeading = $(this).find('.status-heading');
            var sectionStatus = sectionHeading.data('status');

            var columnIndex = $(this)
              .parents('resident-kanban-column')
              .attr('data-index');

            var headingsWrapper = $(this)
              .parents('.kanban-columns')
              .siblings('.kanban-headers');

            var sectionHeadingHolder = headingsWrapper
              .children()
              .eq(columnIndex)
              .find('.section-heading-holder');
            var sectionHeadingHolderText =
              sectionHeadingHolder.find('.heading-text');
            var sectionToggle = sectionHeadingHolder.find('md-checkbox');

            if (sectionTopPos < 0) {
              $(this).addClass('active');

              if ($(this).hasClass('active-section')) {
                sectionHeadingHolder.addClass('active');
                sectionHeadingHolderText.html(sectionHeading.html());
                sectionHeadingHolder.attr('data-status', sectionStatus);
              } else {
                sectionHeadingHolderText.html('');
                sectionHeadingHolder.removeClass('active');
                sectionHeadingHolder.removeAttr('data-status');
              }
            } else {
              $(this).removeClass('active');
            }

            sectionToggle.each(function () {
              if ($(this).hasClass(sectionHeadingHolder.attr('data-status'))) {
                $(this).show();
              } else {
                $(this).hide();
              }
            });

            if (scrollPos <= 0) {
              sectionHeadingHolderText.html('');
              sectionHeadingHolder.removeClass('active');
            }
          });
        });
      }
    };
  });

  const KanbanColumnController = function ($scope) {
    var self = this;

    $scope.allCardsSelected = {};
    $scope.cardSelectedChangedHandlers = {};

    self._initialize = function () {
      $scope.getCardCountsByStatus = function (cards) {
        return reduce(
          cards,
          function (result, card) {
            result[card.status] =
              result[card.status] === undefined ? 1 : result[card.status] + 1;

            return result;
          },
          {}
        );
      };

      $scope.getCardsByStatus = function (cards) {
        var cardsByStatus = groupBy(cards, function (card) {
          return card.status;
        });

        return map(cardsByStatus, function (cards, key) {
          $scope.cardSelectedChangedHandlers[key] = function (cards) {
            $scope.allCardsSelected[key] = every(
              self._getCards(cards, key),
              'isSelected'
            );
          };

          return {
            cards: cards,
            key: key
          };
        });
      };

      forEach($scope.cardSelectedChangedHandlers, function (handler) {
        handler();
      });
    };

    $scope.allCardsSelectedChanged = function (cards, key) {
      forEach(self._getCards(cards, key), function (card) {
        card.isSelected = $scope.allCardsSelected[key];
      });
    };

    self._getCards = function (cards, key) {
      return find(cards, { key: key }).cards;
    };

    self._initialize();
  };
  KanbanColumnController.$inject = ['$scope'];

  app.controller('KanbanColumnController', KanbanColumnController);
})(window.angular, window.jQuery);
