import React, { FC, useEffect, useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  FormHelperText,
  Snackbar,
  IconButton,
  Popover,
  TextareaAutosize,
  MenuItem,
  Select,
  FormControl,
  Input,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import {
  makeStyles,
  NamedColors,
  Box,
  Button,
  white
} from '@knockrentals/knock-shared-web';
import { Alert } from '@material-ui/lab';
import { colors } from '../commonStyles/commonStyles';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useProspectsReport } from 'app/services/prospects/hooks';
import { Prospect, Emoji } from '../../../../app/services/prospects/entities';
import { CircularProgressButton } from '../CircularProgressButton';
import EmojiPicker from 'emoji-picker-react';

import CloseIcon from '@material-ui/icons/Close';
import { EmojiPickerIcon } from '../icons/EmojiPickerIcon';
import { QuickReplyHost } from '../QuickReplies';
import { useAngularContext } from '../AngularContextProvider';
import { SendMessageIcon } from '../icons/SendMessageIcon';
import { useCommonStyles } from '../commonStyles/commonStyles';
import { removeSelectedProspect } from 'app/features/prospects/selectedProspects';
import { AttachmentIcon } from '../icons';
import { Attachment } from '../../ts/models';
import ActiveAttachments from '../ActiveAttachments';

const useStyles = makeStyles(() => ({
  textarea: {
    width: '100%',
    backgroundColor: NamedColors.slate[50],
    borderBottom: `1px solid ${NamedColors.slate[300]}`,
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    margin: '20px 0',
    paddingTop: '15px',
    paddingLeft: '8px',
    minHeight: '56px',
    color: colors.defaultText,
    fontSize: '16px',
    marginBottom: '0',
    letterSpacing: '0.5px',
    '&::placeholder': {
      color: colors.disabled,
      fontSize: '16px'
    }
  },
  textareaError: {
    borderBottom: `2px solid ${colors.error}`
  },
  userBox: {
    marginTop: '25px',
    borderBottom: `1px solid ${NamedColors.slate[300]}`,
    paddingBottom: '7px'
  },
  userText: {
    color: colors.secondaryText,
    fontSize: '14px',
    lineHeight: '12px',
    paddingBottom: '5px'
  },
  dialogPaperWidth: {
    width: '650px',
    maxWidth: '650px'
  },
  dialogTitle: {
    padding: '16px 12px 0px 16px'
  },
  dialogContent: {
    padding: '0 16px'
  },
  CircularProgressButton: {
    background: NamedColors.denim[600],
    '&.Mui-disabled': {
      color: colors.disabled
    },
    '&.Mui-disabled svg path': {
      stroke: colors.disabled
    }
  },
  cancelButton: {
    border: `1px solid ${NamedColors.denim[600]}`,
    color: NamedColors.denim[600],
    lineHeight: '24px'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '32px 5px 16px 10px'
  },
  actionConatiner: {
    display: 'flex',
    gap: '16px',
    marginRight: '8px',
    marginLeft: '0'
  },
  button: {
    color: NamedColors.slate[800],

    '& svg': {
      marginRight: '8px'
    },

    '&:focus': {
      color: NamedColors.slate[800],

      '& svg path': {
        stroke: NamedColors.slate[800]
      }
    }
  },
  snackBar: {
    backgroundColor: NamedColors.slate[900]
  },
  snackbarCloseIconButton: {
    marginRight: '10px'
  },
  subTitle: {
    color: colors.secondaryText,
    lineHeight: '20px'
  },
  helperBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  chipContainer: {
    borderBottom: `1px solid ${NamedColors.slate[300]}`,
    marginTop: '20px',
    paddingBottom: '4px',
    paddingLeft: '4px'
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden'
  },

  chip: {
    backgroundColor: colors.chipBackground,
    color: colors.defaultText,
    fontSize: '12px',
    marginRight: '8px',

    '&:last-child': {
      marginRight: 0
    },

    '& .MuiChip-labelSmall': {
      paddingLeft: '9px',
      paddingRight: '9px',
      paddingTop: '2px'
    }
  },
  formControl: {
    width: '100%',
    marginBottom: 0,

    '& .MuiInputBase-formControl': {
      marginTop: '5px !important'
    },

    '& .MuiInputBase-root': {
      borderBottom: 'none',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      background: white,
      margin: 0,
      padding: 0
    }
  },
  chipsSection: {
    backgroundColor: `${white} !important`,
    border: 'none'
  },
  buttons: {
    paddingTop: '10px'
  },
  dialogActionsIfComposeEnable: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 16px 16px 16px'
  },
  secondaryButton: {
    color: NamedColors.slate[800],

    '& svg': {
      marginRight: '8px'
    },

    '&:focus': {
      color: NamedColors.slate[800],

      '& svg path': {
        stroke: NamedColors.slate[800]
      }
    }
  }
}));

interface ProspectBulkSmsModalProps {
  openModal: boolean;
  closeModal: (load?: boolean | undefined, emailOpen?: boolean) => void;
}

const BulkSmsModal: FC<ProspectBulkSmsModalProps> = ({
  closeModal,
  openModal
}) => {
  const smsLength = 320;
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string | null>(null);
  const [composeEmail, setComposeEmail] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const {
    cheatProofEngagementScoreEnabled,
    isBulkCommunicationEnhancementEnabled,
    handleAttachments,
    deleteAttachment
  } = useAngularContext();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { selectedProspects } = useAppSelector(
    (state) => state.selectedProspects
  );
  const { prospects } = useProspectsReport();

  const [snackMessage, setSnackMessage] = useState<string | null>(null);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [recordsToDisplay, setRecordsToDisplay] = useState<string[]>([]);

  const [messageErrorFileUpload, setmessageErrorFileUpload] = useState<
    string | null
  >(null);

  const selectedProspectsWithEmailOnly = prospects.filter(
    (prospect: Prospect) =>
      selectedProspects[prospect.id] &&
      prospect.profile?.email &&
      prospect.smsConsent?.status !== 'granted'
  );
  const selectedProspectsWithNoEmailAndText = prospects.filter(
    (prospect: Prospect) =>
      selectedProspects[prospect.id] &&
      prospect.smsConsent?.status !== 'granted' &&
      !prospect.profile?.email
  );

  const selectedProspectsForBulkSMS = prospects
    .filter(
      (prospect: Prospect) =>
        selectedProspects[prospect.id] &&
        prospect.smsConsent?.status === 'granted'
    )
    .map((prospect: Prospect) => prospect.profile?.fullName);

  useEffect(() => {
    if (openModal) {
      setRecordsToDisplay([...selectedProspectsForBulkSMS]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    if (message.length > smsLength) {
      setMessageError(
        'You have exceeded the character limit for a text message.'
      );
    }
  }, [message]);

  const updateChipsToDisplay = () => {
    if (recordsToDisplay.length !== selectedProspectsForBulkSMS.length) {
      return;
    }

    const chipContainer = window.document.getElementById('chip-container');
    let visibleChipsWidth = 0;

    if (chipContainer) {
      let firstChipOffsetTop: number = 0;
      let visibleCounter = 1;

      for (let index = 0; index < chipContainer.childNodes.length; index++) {
        const element = chipContainer.childNodes[index] as HTMLDivElement;
        let shouldBreak = false;

        index === 0
          ? (firstChipOffsetTop = element.offsetTop)
          : element.offsetTop === firstChipOffsetTop
          ? visibleCounter++
          : (shouldBreak = true);

        visibleChipsWidth += element.getBoundingClientRect().width;

        if (shouldBreak) {
          break;
        }
      }

      if (visibleCounter !== selectedProspectsForBulkSMS.length) {
        setRecordsToDisplay((prevState: string[]) => {
          if (chipContainer) {
            const containerWidth = chipContainer.getBoundingClientRect().width;

            if (visibleChipsWidth > containerWidth) {
              visibleCounter--;
            }

            prevState.splice(visibleCounter);
            prevState.push(
              `+${selectedProspectsForBulkSMS.length - visibleCounter}`
            );
          }

          return [...prevState];
        });
      }
    }
  };

  const numberOfProspectsWithSms = selectedProspectsForBulkSMS.length;

  const usersCountWithEmail: number = selectedProspectsWithEmailOnly.length;
  const usersCountWithNoSMS: number =
    selectedProspectsWithNoEmailAndText.length;

  const handleChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
    if (event.target.value.length > 320) {
      setMessageError(
        'You have exceeded the character limit for a text message.'
      );
      return;
    }
    setMessageError(null);
  };

  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setmessageErrorFileUpload('');
  };

  const appendEmoji = (emoji: Emoji) => {
    const newMessage = `${message}${emoji.emoji}`;
    setMessage(newMessage);
    message.length < 320 && emoji.emoji && setMessageError('');
  };

  const statesReset = () => {
    setMessage('');
    setMessageError('');
    setLoading(false);
    setAnchorEl(null);
    setAttachments([]);
  };

  const onAppendText = (quickReplyMessage: string, _: boolean) => {
    setMessage(`${message} ${quickReplyMessage}`);
    message.length < 320 && quickReplyMessage && setMessageError('');
  };

  const cancelHandler = () => {
    statesReset();
    setAttachments([]);
    closeModal();
  };

  const {
    mergeTags,
    managerInfo,
    getManagerQuickReplies,
    saveQuickReply,
    deleteManagerQuickReply,
    isCompanyQuickRepliesEnabled,
    sendBatchMessage
  } = useAngularContext();

  const handleClose = (load?: boolean, emailOpen?: boolean) => {
    closeModal(load, emailOpen);
    statesReset();
  };
  const handleSubmit = async () => {
    try {
      if (!message) {
        setMessageError('Please compose a text message');
        return;
      }
      setLoading(true);
      const selectedStreamIds = prospects
        .filter(
          (prospect: Prospect) =>
            selectedProspects[prospect.id] &&
            prospect.smsConsent?.status === 'granted'
        )
        .map((prospect: Prospect) => prospect?.streamId);
      const usersToUnselect: number[] = composeEmail
        ? prospects
            .filter(
              (prospect: Prospect) =>
                selectedProspects[prospect.id] &&
                (!prospect.profile.email ||
                  prospect.smsConsent?.status === 'granted')
            )
            .map((prospect: Prospect) => prospect.id)
        : [];
      if (composeEmail) {
        dispatch(removeSelectedProspect(usersToUnselect));
      }

      await sendBatchMessage(
        selectedStreamIds,
        message,
        '',
        '',
        ['sms'],
        attachments,
        ''
      );
      const snackBarMessage =
        selectedStreamIds.length > 1
          ? `Text successfully sent to ${numberOfProspectsWithSms} prospects.`
          : `Text successfully sent to ${selectedProspectsForBulkSMS[0]}.`;
      setSnackMessage(snackBarMessage);
      handleClose(true, usersToUnselect.length > 0);
      setComposeEmail(false);
      setAttachments([]);
    } catch (err) {
      console.error('Unable to process SMS', err);
      setSnackMessage('Could not send text message. Please try again.');
      statesReset();
    } finally {
      setLoading(false);
    }
  };

  const uploadSuccess = (res: any) => {
    const response = JSON.parse(res.target.responseText);
    setAttachments([...attachments, ...response.attachments]);
  };

  const uploadError = () => {
    setSnackMessage('Could not upload attachment. Please try again.');
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setmessageErrorFileUpload(null);
    const files: FileList = event.target.files!;

    let filesToUpload: any = files;

    let isUploadFileReady: any[] = [];

    if (files?.length > 0) {
      for (const uploadedFile of filesToUpload) {
        if (checkUploadFile(uploadedFile)) {
          uploadedFile.isLocal = true;
          uploadedFile.mimetype = uploadedFile.type;
          uploadedFile.url = URL.createObjectURL(uploadedFile);

          isUploadFileReady.push(uploadedFile);
        }
      }
    }

    if (isUploadFileReady.length > 0) {
      handleAttachments(
        isUploadFileReady,
        uploadSuccess,
        undefined,
        uploadError
      );
    }
  };

  const isFileSizeValid = (uploadedFileSize: number) => {
    const MAX_FILE_SIZE: number = 25 * 1048576;
    return MAX_FILE_SIZE >= uploadedFileSize;
  };

  const isFileValid = (mimetype: string) => {
    return (
      mimetype.search('image/.*') === 0 ||
      mimetype.search('video/.*') === 0 ||
      mimetype === 'application/pdf'
    );
  };

  const checkUploadFile = (uploadedFile: any) => {
    const uploadedFileSize: number = uploadedFile.size;
    const mimeType: string = uploadedFile?.type;

    if (isFileSizeValid(uploadedFileSize)) {
      if (isFileValid(mimeType)) {
        return true;
      } else {
        setmessageErrorFileUpload(
          ' Accepted file types are image, video, or PDF only.'
        );
        return false;
      }
    } else {
      setmessageErrorFileUpload(
        'Files over 25MB size not permitted. Please upload smaller files.'
      );
      return false;
    }
  };

  const handleDeleteAttachment = async (attachment: Attachment) => {
    try {
      await deleteAttachment(attachment.delete_token);

      const newAttachments = [...attachments];
      const deleteIndex: number = newAttachments.findIndex(
        (attachFile: Attachment) =>
          attachFile.delete_token === attachment.delete_token
      );

      newAttachments.splice(deleteIndex, 1);
      setAttachments(newAttachments);
      setmessageErrorFileUpload(null);

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (err) {
      console.error(err);
      console.log('Error Not able to attach a file');
    }
  };

  const openPopover = Boolean(anchorEl);
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Snackbar
        ContentProps={{
          classes: {
            root: classes.snackBar
          }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!snackMessage}
        message={snackMessage}
        action={
          <IconButton
            className={classes.snackbarCloseIconButton}
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackMessage(null)}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      <Dialog
        aria-labelledby="dialog-title"
        disableEnforceFocus
        open={openModal}
        classes={{ paperWidthSm: classes.dialogPaperWidth }}
      >
        <DialogTitle className={classes.dialogTitle}>Batch Text</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {cheatProofEngagementScoreEnabled && (
            <Box className={commonClasses.bulkAlertBox}>
              <Alert
                severity="warning"
                className={commonClasses.bulkAlertContent}
              >
                Bulk texts do not count as an action taken for prospects. Only
                individual communication will count towards your engagement
                score.
              </Alert>
            </Box>
          )}
          <Box className={classes.chipContainer}>
            <FormControl className={classes.formControl}>
              <Typography
                variant="caption"
                id="chip-label"
                variantMapping={{ caption: 'div' }}
              >
                Prospects
              </Typography>

              <Select
                IconComponent={() => null}
                multiple
                value={recordsToDisplay}
                input={<Input />}
                className={classes.chipsSection}
                renderValue={(selected: any) => (
                  <div id="chip-container" className={classes.chips}>
                    {selected.map((value: string, index: number) => {
                      if (index + 1 === selected.length) {
                        updateChipsToDisplay();
                      }

                      return (
                        <Chip
                          key={index}
                          label={value}
                          className={classes.chip}
                          size="small"
                        />
                      );
                    })}
                  </div>
                )}
                disabled
              >
                {recordsToDisplay.map((name: string, index: number) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {usersCountWithEmail > 0 && (
            <FormHelperText className={classes.subTitle}>{`${
              usersCountWithEmail === 1
                ? `${usersCountWithEmail} recipient was`
                : `${usersCountWithEmail} recipients were`
            }  excluded because they can only receive emails`}</FormHelperText>
          )}
          {usersCountWithNoSMS > 0 && (
            <FormHelperText className={classes.subTitle}>{`${
              usersCountWithNoSMS === 1
                ? `${usersCountWithNoSMS} recipient was`
                : `${usersCountWithNoSMS} recipients were`
            }  excluded because they cannot receive messages`}</FormHelperText>
          )}
          <TextareaAutosize
            aria-label="message"
            value={message}
            onChange={handleChangeInput}
            className={`${classes.textarea} ${
              messageError ? classes.textareaError : ''
            } `}
            placeholder="Add a message"
          />
          <Box className={classes.helperBox}>
            <FormHelperText error={!!messageError}>
              {messageError ? messageError : `${message.length} / 320`}
            </FormHelperText>
          </Box>
          <Box className={classes.buttons}>
            {attachments.length > 0 && (
              <ActiveAttachments
                attachments={attachments}
                deleteAttachment={handleDeleteAttachment}
              />
            )}
            <input
              type="file"
              accept="image/*, video/*, application/pdf"
              multiple
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileUpload}
              data-testid="attachments-input"
            />
            <Button
              variant="text"
              className={classes.secondaryButton}
              onClick={() => fileInputRef.current?.click()}
              data-testid="attachments-input-button"
            >
              <AttachmentIcon useNewDesign={true} />
              Attachments
            </Button>
            <Button
              variant="text"
              aria-describedby="emoji"
              className={classes.button}
              onClick={handlePopover}
            >
              <EmojiPickerIcon />
              Emojis
            </Button>
            <QuickReplyHost
              messageMode="sms"
              managerInfo={managerInfo}
              mergeTags={mergeTags}
              getManagerQuickReplies={getManagerQuickReplies}
              saveQuickReply={saveQuickReply}
              deleteQuickReply={deleteManagerQuickReply}
              onAppendText={onAppendText}
              isCompanyQuickRepliesEnabled={isCompanyQuickRepliesEnabled}
            />

            <Popover
              id="emoji"
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              <EmojiPicker onEmojiClick={appendEmoji} />
            </Popover>
            <FormHelperText className="Mui-error">
              {messageErrorFileUpload}
            </FormHelperText>
          </Box>
        </DialogContent>
        <DialogActions
          className={
            usersCountWithEmail > 0
              ? classes.dialogActionsIfComposeEnable
              : classes.dialogActions
          }
        >
          {isBulkCommunicationEnhancementEnabled && usersCountWithEmail > 0 && (
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="composeRecipient"
                    color="primary"
                    data-testid="compose-sms-checbox"
                    size="medium"
                    checked={composeEmail}
                    onChange={(_, checked: boolean) => setComposeEmail(checked)}
                  />
                }
                label={`Compose an email to the ${usersCountWithEmail} eligible ${
                  usersCountWithEmail === 1 ? `recipient` : `recipients`
                }`}
              />
            </Box>
          )}
          <Box className={classes.actionConatiner}>
            <Button
              type="button"
              variant="outlined"
              className={classes.cancelButton}
              onClick={cancelHandler}
            >
              Cancel
            </Button>
            <CircularProgressButton
              onClick={handleSubmit}
              shouldShowProgress={loading}
              disabled={!!messageError}
              progressText={'Sending...'}
              className={classes.CircularProgressButton}
            >
              <SendMessageIcon /> &nbsp;Send ({numberOfProspectsWithSms})
            </CircularProgressButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default BulkSmsModal;
