import React, { FC } from 'react';
import { makeStyles, SvgIcon, Typography, Box } from '@material-ui/core';
import { ThemeProvider, NamedColors } from '@knockrentals/knock-shared-web';
import { HotLeadIcon } from '../icons';

const useStyles = makeStyles(() => ({
  hotLeadContainer: {
    display: 'flex',
    marginTop: '12px'
  },
  hotLeadText: {
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: '14px',
    paddingLeft: '6px',
    color: NamedColors.carrot[600]
  },
  hotLeadIcon: {
    fontSize: '22px',
    paddingLeft: '4px',
    stroke: NamedColors.carrot[600]
  }
}));

const HotLeadIndicator: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.hotLeadContainer}>
      <SvgIcon className={classes.hotLeadIcon}>
        <HotLeadIcon />
      </SvgIcon>
      <Typography className={classes.hotLeadText}>Hot Lead</Typography>
    </Box>
  );
};

export default () => (
  <ThemeProvider>
    <HotLeadIndicator />
  </ThemeProvider>
);
