/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const completeAppointmentModalFactory = function ($mdDialog) {
    var self = this;

    self.openCompleteAppointmentModal = function (prospect, appointment) {
      return $mdDialog.show({
        controller: 'CompleteAppointmentModalController',
        templateUrl: '/angular/views/prospects/complete-appointment-modal.html',
        clickOutsideToClose: true,
        locals: {
          prospect: prospect,
          appointment: appointment
        }
      });
    };

    return self;
  };
  completeAppointmentModalFactory.$inject = ['$mdDialog'];
  app.factory(
    'completeAppointmentModalFactory',
    completeAppointmentModalFactory
  );

  const CompleteAppointmentModalController = function (
    $scope,
    $mdDialog,
    $mdToast,
    visitsService,
    appointmentsApi,
    prospect,
    appointment
  ) {
    $scope.data = {
      prospect: prospect,
      appointment: appointment,
      isSaving: false
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.setAppointmentVisited = function () {
      $scope.data.isSaving = true;

      return visitsService
        .createVisit(
          $scope.data.prospect.id,
          $scope.data.appointment.start_time,
          $scope.data.appointment.id
        )
        .then(
          function (response) {
            response.data.visit.event_type = 'visit';
            $mdDialog.hide({ status: 'visited', visit: response.data.visit });
          },
          function () {
            $scope.data.isSaving = false;
            $mdToast.showSimple('Error changing appointment to Visited');
          }
        );
    };

    $scope.setAppointmentNoShow = function () {
      $scope.data.isSaving = true;

      return appointmentsApi
        .setAppointmentStatus($scope.data.appointment.id, 'no-show')
        .then(
          function () {
            $mdDialog.hide({ status: 'no-show', visit: null });
          },
          function () {
            $scope.data.isSaving = false;
            $mdToast.showSimple('Error changing appointment to No Show');
          }
        );
    };
  };

  CompleteAppointmentModalController.$inject = [
    '$scope',
    '$mdDialog',
    '$mdToast',
    'visitsService',
    'appointmentsApi',
    'prospect',
    'appointment'
  ];
  app.controller(
    'CompleteAppointmentModalController',
    CompleteAppointmentModalController
  );
})(window.angular);
