/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
require('../../core/scripts/data/appData.service');
require('../../core/scripts/data/appLoading.directive');
require('../../core/scripts/data/contactTypes.data');

require('../../core/scripts/reviews/starRating.directive');
require('../../core/scripts/reviews/appointmentReview.controller');
require('../../core/scripts/reviews/viewReviewModal.controller');

require('../../core/scripts/appointments/getAppointment.controller');
require('../../core/scripts/appointments/queuedRequest.controller');
require('../../core/scripts/appointments/renterAppointments/renterAppointments.controller');
require('../../core/scripts/appointments/renterAppointments/renterAppointments.service');
require('../../core/scripts/appointments/renterAppointments/renterAppointmentView/renterAppointmentView.controller');
