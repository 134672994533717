/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('fullCalendar', [
    '$window',
    '$modal',
    '$rootScope',
    '$moment',
    'newEventModalService',
    'syncCalendarModalFactory',
    'timeService',
    'fullCalendarService',
    function (
      $window,
      $modal,
      $rootScope,
      $moment,
      newEventModalService,
      syncCalendarModalFactory,
      timeService,
      fullCalendarService
    ) {
      return {
        restrict: 'E',
        scope: {
          calendarEvents: '=',
          onEventClicked: '=',
          onViewRender: '=',
          onEventRender: '=',
          onEventAfterRender: '=',
          onEventTimeChanged: '=',
          onSwitchContext: '=',
          onDayClicked: '=',
          onRangeSelected: '='
        },
        link: function (scope, element) {
          var $ = $window.jQuery;

          var calendarUpdatedHandler = $rootScope.$on(
            'calendarEventsUpdated',
            function () {
              var calendar = $(element);
              // Replace the events when we update the event array model
              calendar.fullCalendar('removeEvents');
              scope.calendarEvents.forEach(function (eventSource) {
                calendar.fullCalendar('addEventSource', eventSource);
              });
              calendar.fullCalendar('rerenderEvents');
            }
          );

          scope.$on('$destroy', calendarUpdatedHandler);

          function initializeCalendar() {
            var headerOptions = {
              left: '',
              center: 'prev title next',
              right: 'agendaDay,agendaWeek,month'
            };

            var nowString = timeService.get().format('HH:mm:00');

            // Limit agenda view to 6am to 10pm
            var viewOptions = {
              agenda: {
                allDaySlot: true,
                allDayText: 'Reminders',
                minTime: '6:00:00',
                maxTime: '22:00:00',
                slotDuration: '00:15:00',
                scrollTime: nowString
              }
            };

            var titleFormat = {
              week: 'MMM Do'
            };

            var calendarElement = $(element);

            timeService.getActiveTimezone().then(function (timezoneData) {
              calendarElement.fullCalendar({
                header: headerOptions,
                fixedWeekCount: false,
                eventSources: scope.calendarEvents,
                eventClick: eventClicked,
                eventDrop: scope.onEventTimeChanged,
                eventResize: scope.onEventTimeChanged,
                eventRender: eventRendered,
                eventAfterRender: eventAfterRendered,
                dayClick: dayClicked,
                views: viewOptions,
                viewRender: scope.onViewRender,
                defaultView: 'agendaWeek',
                editable: true,
                timezone: timezoneData.id,
                windowResize: onWindowResize,
                titleFormat: titleFormat,
                selectHelper: true,
                selectable: true,
                select: selected,
                selectConstraint: {
                  start: '6:00:00',
                  end: '22:00:00'
                }
              });

              fullCalendarService.setCalendarElement(calendarElement);

              var toolbarLeft = $('.fc-toolbar .fc-left');
              var newEventButton = createNewEventButton();
              var syncButton = createSyncButton();

              toolbarLeft.append(newEventButton);
              toolbarLeft.append(syncButton);

              onWindowResize();
            });
          }

          function createNewEventButton() {
            var newEventButton = $(
              '<button class="btn calendar-header-btn"><i class="fa fa-calendar-times-o"></i> Add Busy Time</button>'
            );

            newEventButton.click(function () {
              var calendar = $(element);
              var defaultStartTime = $moment().minute(0).add(1, 'hour');
              var defaultEndTime = $moment(defaultStartTime).add(15, 'minutes');

              newEventModalService.openModal(
                calendar,
                { start: defaultStartTime, end: defaultEndTime },
                null,
                true
              );
            });

            return newEventButton;
          }

          function createSyncButton() {
            var syncButton = $(
              '<button class="btn calendar-header-btn">Sync</button>'
            );

            syncButton.click(function () {
              syncCalendarModalFactory.openCalendarSyncModal();
            });

            return syncButton;
          }

          function onWindowResize() {
            var calendar = $(element);
            var windowHeight = $(window).height();

            calendar.fullCalendar(
              'option',
              'contentHeight',
              windowHeight - 125
            );
          }

          function dayClicked(date) {
            scope.onDayClicked(date, $(element));
          }

          function eventClicked(calendarEvent, jsEvent, view) {
            //jshint validthis: true
            scope.onEventClicked(calendarEvent, $(element), view, $(this));
          }

          function selected(start, end) {
            scope.onRangeSelected(start, end, $(element));
          }

          function eventRendered(event, eventElement, view) {
            scope.onEventRender(event, eventElement, view, $(element));
          }

          function eventAfterRendered(event, eventElement, view) {
            scope.onEventAfterRender(event, eventElement, view, $(element));
          }

          initializeCalendar();
        }
      };
    }
  ]);
})(window.angular);
