import React, { FC } from 'react';

export const ShareBrochureIcon: FC = () => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 4.62305H22.272C22.8096 4.62249 23.2467 5.05642 23.25 5.59405V20.153C23.2462 20.6903 22.8092 21.1236 22.272 21.123H1.728C1.19075 21.1236 0.753849 20.6903 0.75 20.153V5.59405C0.753301 5.05642 1.19036 4.62249 1.728 4.62305H5.25M12 11.373H20.25M6.75 15.873H20.25M3.75 4.62305V3.12305C3.75 1.88041 4.75736 0.873047 6 0.873047C7.24264 0.873047 8.25 1.88041 8.25 3.12305V9.87305C8.25 10.7015 7.57843 11.373 6.75 11.373C5.92157 11.373 5.25 10.7015 5.25 9.87305V7.62305"
      stroke="#4A61E8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
