/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import isArray from 'lodash/isArray';
import every from 'lodash/every';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('emailList', [
    'emailValidatorService',
    function (emailValidatorService) {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
          function trimmed(email) {
            return email.trim();
          }

          ngModel.$validators.invalidEmails = function (modelValue, viewValue) {
            if (isEmpty(viewValue)) {
              return true;
            }

            var ccEmails = map(viewValue.split(','), trimmed);

            return every(ccEmails, function (ccEmail) {
              return emailValidatorService.validate(ccEmail);
            });
          };

          ngModel.$validators.knockMailer = function (modelValue, viewValue) {
            if (isEmpty(viewValue)) {
              return true;
            }

            var emails = map(viewValue.split(','), trimmed);

            return every(emails, function (ccEmail) {
              return ccEmail.search('@mailer.knockrentals.com') < 0;
            });
          };

          ngModel.$parsers.push(function (viewValue) {
            if (isEmpty(viewValue)) {
              return [];
            }

            return map(viewValue.split(', '), trimmed);
          });

          ngModel.$formatters.push(function (value) {
            if (isArray(value)) {
              return value.join(', ');
            }

            return undefined;
          });

          ngModel.$isEmpty = function (value) {
            return isEmpty(value);
          };
        }
      };
    }
  ]);

  app.filter('commaList', [
    function () {
      return function (value) {
        if (isArray(value)) {
          return value.join(', ');
        }

        return value;
      };
    }
  ]);
})(window.angular);
