(function (angular) {
  var app = angular.module('knockApp');

  app.factory('confirmAppointmentModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.createModal = function (prospectProfile, request, confirmHandler) {
        return $mdDialog.show({
          controller: 'ConfirmAppointmentModalController',
          templateUrl: '/angular/views/calendar/confirm-appointment-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            confirmHandler: confirmHandler || null,
            request: request || null,
            prospectProfile: prospectProfile || null
          }
        });
      };

      return self;
    }
  ]);

  app.controller('ConfirmAppointmentModalController', [
    '$scope',
    '$mdDialog',
    'timeService',
    'prospectProfile',
    'request',
    'confirmHandler',
    function (
      $scope,
      $mdDialog,
      timeService,
      prospectProfile,
      request,
      confirmHandler
    ) {
      const formattedStartTime = timeService
        .get(request.start_time)
        .format('h:mm a z - ddd, MMM Do');

      $scope.message =
        'Would you like to accept ' +
        prospectProfile.first_name +
        "'s appointment request for " +
        formattedStartTime +
        '?';

      $scope.confirmAppointment = function () {
        if (confirmHandler) {
          confirmHandler(request);
        }

        $mdDialog.hide();
      };
      $scope.dismiss = function () {
        $mdDialog.cancel();
      };
    }
  ]);
})(window.angular);
