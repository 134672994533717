import React, { FC, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  TextField,
  FormHelperText
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import {
  makeStyles,
  Button,
  Box,
  Text,
  NamedColors
} from '@knockrentals/knock-shared-web';
import { CircularProgressButton } from '../../CircularProgressButton';
import { ErrorAlert } from '../../ErrorAlert';
import nearbyCommunitiesService from '../../../services/nearbyCommunitiesService';
import { colors, useCommonStyles } from '../../commonStyles/commonStyles';
import { REFERRAL_NOTE_CHARACTER_LIMIT } from '../../../constants/constants';
import { QuoteIcon } from '../../icons';

const useStyles = makeStyles(() => ({
  paperWidth: {
    width: '600px'
  },

  dialogTitle: {
    alignItems: 'center',
    color: colors.secondaryText,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 24px 12px 24px'
  },

  titleText: {
    color: colors.defaultText
  },

  closeButton: {
    left: '12px',
    position: 'relative'
  },

  dialogContent: {
    paddingBottom: 0,
    paddingTop: 0
  },

  textField: {
    width: '100%',
    marginBottom: 0,

    '& .MuiInputBase-root': {
      border: `none`,
      color: colors.defaultText,
      backgroundColor: '#FAFAFA',

      '&.MuiInputLabel-root.Mui-focused': {
        color: colors.secondaryText,
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: '0.15px'
      }
    },

    '& .MuiInputLabel-formControl.MuiInputLabel-filled.MuiInputLabel-shrink': {
      color: colors.secondaryText
    },

    '& .MuiFilledInput-underline:after': {
      borderBottom: `1px solid ${NamedColors.slate[300]}`
    },

    '& .MuiFilledInput-underline:before': {
      borderBottom: `1px solid ${NamedColors.slate[300]}`
    },

    '& .MuiInputLabel-formControl': {
      top: '26px',
      left: '0',
      color: colors.disabled,
      fontSize: '16px',
      fontWeight: 400
    }
  },

  helperBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5px'
  },

  formHelperText: {
    color: colors.secondaryText
  },

  alert: {
    backgroundColor: NamedColors.denim[100],
    borderRadius: '8px',

    '& .MuiAlertTitle-root': {
      marginBottom: 0
    },

    '& .MuiAlert-icon': {
      paddingTop: '12px'
    }
  },

  errorAlert: {
    borderRadius: '8px',
    marginBottom: '20px'
  },

  description: {
    color: colors.secondaryText,
    marginBottom: '-8px',
    marginTop: '16px'
  },

  dialogActions: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
    padding: '16px'
  },

  standardButton: {
    padding: '7px 15px'
  }
}));

interface CreateReferralModalProps {
  open: boolean;
  onClose: (prospectId?: number) => void;
  prospectId: number;
  propertyId: number;
  propertyName: string;
}

const CreateReferralModal: FC<CreateReferralModalProps> = ({
  open,
  onClose,
  prospectId,
  propertyId,
  propertyName
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [referralNote, setReferralNote] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const [referralError, setReferralError] = useState<boolean>(false);

  const handleSubmit = async () => {
    setReferralError(false);
    setSaving(true);

    const response = await nearbyCommunitiesService.referProspect(
      prospectId,
      propertyId,
      {
        note: referralNote,
        isScheduling: false
      }
    );

    if (response.ok) {
      setReferralNote('');
      setSaving(false);

      onClose(response.response.sister_property_prospect_id);
    } else {
      setReferralError(true);
      setSaving(false);
    }
  };

  const handleClose = () => {
    setReferralNote('');
    setReferralError(false);
    onClose();
  };

  return (
    <Dialog open={open} classes={{ paperWidthSm: classes.paperWidth }}>
      <DialogTitle className={classes.dialogTitle} disableTypography={true}>
        <Text variant="h6" className={classes.titleText}>
          {`Refer to ${propertyName}`}
        </Text>

        <IconButton
          data-testid="close-button"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent} dividers={false}>
        {referralError && (
          <ErrorAlert
            alertMessage="We ran into an issue when trying to complete your referral. Please try again later."
            className={classes.errorAlert}
          />
        )}

        <Alert className={classes.alert} severity="info" icon={<QuoteIcon />}>
          <AlertTitle>
            <Text variant="body2">
              In order to create a quote for this unit, you will need to refer
              them to this property
            </Text>
          </AlertTitle>
        </Alert>

        <Box className={classes.description}>
          <Text variant="body1">
            Please include a note to the receiving team(s) about this referral.
            This note will be added to the conversation thread in all
            guestcards.
          </Text>
        </Box>

        <Box>
          <TextField
            label="Referral Note"
            className={classes.textField}
            multiline
            variant="filled"
            value={referralNote}
            data-testid="referral-note"
            inputProps={{
              maxLength: REFERRAL_NOTE_CHARACTER_LIMIT
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setReferralNote(event.target.value)
            }
          />
        </Box>

        <Box className={classes.helperBox}>
          {referralNote.length >= 475 && (
            <FormHelperText
              className={classes.formHelperText}
            >{`${referralNote.length}/${REFERRAL_NOTE_CHARACTER_LIMIT}`}</FormHelperText>
          )}
        </Box>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          onClick={handleClose}
          className={classes.standardButton}
        >
          Cancel
        </Button>

        <CircularProgressButton
          dataTestId="submit-button"
          onClick={handleSubmit}
          shouldShowProgress={saving}
          disabled={referralNote.length === 0}
          progressText={'Creating referral...'}
          className={`${classes.standardButton} ${commonClasses.primaryButton}`}
        >
          Create Referral
        </CircularProgressButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateReferralModal;
