import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Card,
  Text,
  makeStyles,
  NamedColors,
  CardContent
} from '@knockrentals/knock-shared-web';
import { Property, PropertyHour } from '../models';
import {
  BlankOverviewTabIcon,
  BuildingAgentIcon,
  BuildingIcon,
  ClockIcon,
  FitnessIcon,
  ProspectNumberIcon,
  ResidentNumberIcon,
  LaundryIcon,
  MonitorIcon,
  ParkingIcon,
  PetIcon,
  PhoneIcon,
  PinIcon,
  PoolIcon
} from '../../icons';
import LeasingBinderNoContent from '../LeasingBinderNoContent';
import { PropertyHours } from './PropertyHours';
import { Chip, Link } from '@material-ui/core';
import { OverviewInfoCard } from './OverviewInfoCard';
import { PieLineIcon } from '../../icons/PieLineIcon';

const useStyles = makeStyles(() => ({
  mainContainer: {
    padding: '24px'
  },
  card: {
    border: `1px solid ${NamedColors.slate[100]}`,
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: 0
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '16px'
    }
  },
  sectionHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
    '&.descSectionTitle': {
      marginBottom: '10px'
    }
  },
  subSection: {
    alignItems: 'flex-start',
    display: 'flex',
    marginBottom: '24px',
    '&:last-child': {
      marginBottom: 0
    },
    '& svg': {
      marginRight: '12px'
    },
    '& .MuiTypography-overline': {
      fontWeight: 600,
      marginBottom: '6px'
    }
  },
  subSectionContent: {
    alignItems: 'center'
  },
  summaryInfo: {
    marginBottom: '8px',
    marginTop: '10px'
  },
  generalSection: {
    display: 'flex',
    gap: '16px'
  },
  generalSectionColumn: {
    width: '50%'
  },
  website: {
    wordBreak: 'break-all'
  },
  chip: {
    backgroundColor: NamedColors.slate[50],
    fontSize: '13px',
    height: 'auto',
    marginRight: '8px',
    marginTop: '8px',
    '& .MuiChip-label': {
      padding: '7px 8px',
      whiteSpace: 'pre-wrap',
      '&:first-letter': {
        textTransform: 'capitalize'
      }
    },
    '&:last-child': {
      marginRight: 0
    },
    '& svg': {
      marginLeft: '8px',
      minWidth: '16px',
      width: '16px'
    }
  },
  column: {
    width: '33.33%',
    paddingBottom: '16px'
  },
  secondCard: {
    marginTop: '16px'
  }
}));

interface FeaturedAmenity {
  [key: string]: React.ReactElement | undefined;
}

const amenitiesLabel: { [key: string]: string } = {
  gym: 'Fitness center',
  pets: 'Pet friendly',
  pool: 'Pool'
};
export interface LeasingBinderOverviewProps {
  property: Property;
}

export const LeasingBinderOverview: FC<LeasingBinderOverviewProps> = ({
  property
}) => {
  const classes = useStyles();
  const [featuredAmenities, setFeaturedAmenities] = useState<FeaturedAmenity>(
    {}
  );
  const [prospectForwardingNumber, setProspectForwardingNumber] =
    useState<string>('');
  const [residentForwardingNumber, setResidentForwardingNumber] =
    useState<string>('');
  const [officeHours, setOfficeHours] = useState<PropertyHour[]>([]);
  const [touringHours, setTouringHours] = useState<PropertyHour[]>([]);

  const getAmenityIcon = (amenityName: string) => {
    let icon: React.ReactElement | undefined;

    switch (amenityName) {
      case 'gym':
        icon = <FitnessIcon />;
        break;
      case 'laundry':
        icon = <LaundryIcon />;
        break;
      case 'parking':
        icon = <ParkingIcon />;
        break;
      case 'pets':
        icon = <PetIcon />;
        break;
      case 'pool':
        icon = <PoolIcon />;
        break;
    }

    return icon;
  };

  useEffect(() => {
    const featuredAmenities: FeaturedAmenity = {};
    let amenityLabel: string;
    let officeHours: PropertyHour[];
    let touringHours: PropertyHour[];

    if (property.featured_amenities) {
      Object.keys(property.featured_amenities).forEach((key: string) => {
        for (const amenity of property.featured_amenities[key].types) {
          if (amenity.available) {
            amenityLabel = amenitiesLabel[key]
              ? amenitiesLabel[key]
              : `${amenity.name.toLowerCase()} ${key}`;
            if (!featuredAmenities[amenityLabel]) {
              featuredAmenities[amenityLabel] = getAmenityIcon(key);
            }
          }
        }
      });
    }

    if (
      property.property_forwarding_numbers &&
      property.property_forwarding_numbers.forwarding_number
    ) {
      setProspectForwardingNumber(
        property.property_forwarding_numbers.forwarding_number
      );
    } else {
      setProspectForwardingNumber('');
    }

    if (
      property.property_forwarding_numbers &&
      property.property_forwarding_numbers.resident_forwarding_number
    ) {
      setResidentForwardingNumber(
        property.property_forwarding_numbers.resident_forwarding_number
      );
    } else {
      setResidentForwardingNumber('');
    }

    if (property.property_office_hours) {
      officeHours = property.property_office_hours.sort(
        (lValue: PropertyHour, rValue: PropertyHour) => {
          return lValue.weekday - rValue.weekday;
        }
      );

      setOfficeHours(officeHours);
    }

    if (property.property_touring_hours) {
      touringHours = property.property_touring_hours.sort(
        (lValue: PropertyHour, rValue: PropertyHour) => {
          return lValue.weekday - rValue.weekday;
        }
      );

      setTouringHours(touringHours);
    }

    setFeaturedAmenities(featuredAmenities);
  }, [property]);

  const getSummaryInfo = () => {
    let summaryInfo = '';

    if (property.location.yearBuilt) {
      summaryInfo += `Built in ${property.location.yearBuilt},`;
    }

    if (property.location.numberOfUnits) {
      summaryInfo += `${property.location.numberOfUnits} Units,`;
    }

    if (summaryInfo) {
      summaryInfo = summaryInfo
        .slice(0, -1)
        .replace(/,/g, ` ${String.fromCharCode(8226)} `);
    }

    return summaryInfo;
  };

  const getFormattedWebsite = () => {
    let formattedWebsite = property.social.website.replace(/https?:\/\//, '');

    if (formattedWebsite.endsWith('/')) {
      formattedWebsite = formattedWebsite.slice(0, formattedWebsite.length - 1);
    }

    return formattedWebsite;
  };

  const getCityStateZip = () => {
    let cityStateZip = '';

    if (
      property?.location?.address?.city ||
      property?.location?.address?.state ||
      property?.location?.address?.zip
    ) {
      if (property.location.address.city.trim()) {
        cityStateZip += property.location.address.city.trim();
      }

      if (property.location.address.state.trim()) {
        cityStateZip += ', ' + property.location.address.state.trim();
      }

      if (property.location.address.zip.trim()) {
        cityStateZip += ' ' + property.location.address.zip.trim();
      }
    }

    return cityStateZip.trim();
  };

  const getFormattedPhoneNumber = (phoneNumber: string) => {
    let formattedNumber = phoneNumber.trim();

    if (phoneNumber.startsWith('+')) {
      formattedNumber = phoneNumber.slice(2);
    }

    if (formattedNumber.length === 10) {
      formattedNumber = `(${formattedNumber.slice(
        0,
        3
      )}) ${formattedNumber.slice(3, 6)} - ${formattedNumber.slice(6)}`;
    }

    return formattedNumber;
  };

  const checkForDescriptionData = () => {
    return (
      (property.description && !!property.description.short) ||
      summaryInfo ||
      Object.keys(featuredAmenities).length > 0
    );
  };

  const checkForGeneralData = () => {
    let hasAddress = false;
    let hasPhoneNumbers = false;
    let hasOfficeHours = false;
    let hasTouringHours = false;
    let hasGeneralSectionData = false;
    let hasPropertyWebsite = false;
    if (property.location && property.location.address) {
      if (
        (property.location.address.street &&
          property.location.address.street.trim()) ||
        (property.location.address.city &&
          property.location.address.city.trim()) ||
        (property.location.address.state &&
          property.location.address.state.trim()) ||
        (property.location.address.zip && property.location.address.zip.trim())
      ) {
        hasAddress = true;
      }
    }

    if (property.property_forwarding_numbers) {
      if (
        property.property_forwarding_numbers.forwarding_number ||
        property.property_forwarding_numbers.resident_forwarding_number
      ) {
        hasPhoneNumbers = true;
      }
    }

    if (
      property.property_office_hours &&
      property.property_office_hours.length > 0
    ) {
      hasOfficeHours = true;
    }

    if (
      property.property_touring_hours &&
      property.property_touring_hours.length > 0
    ) {
      hasTouringHours = true;
    }
    if (property.social && !!property.social.website) {
      hasPropertyWebsite = true;
    }

    if (
      (property.social && !!property.social.website) ||
      hasAddress ||
      hasPhoneNumbers ||
      (property.property_office_hours &&
        property.property_office_hours.length > 0) ||
      (property.property_touring_hours &&
        property.property_touring_hours.length > 0)
    ) {
      hasGeneralSectionData = true;
    }

    return {
      hasAddress,
      hasPhoneNumbers,
      hasOfficeHours,
      hasTouringHours,
      hasGeneralSectionData,
      hasPropertyWebsite
    };
  };

  const summaryInfo = getSummaryInfo();
  const cityStateZip = getCityStateZip();
  const hasDescriptionSectionData = checkForDescriptionData();
  const {
    hasGeneralSectionData,
    hasAddress,
    hasOfficeHours,
    hasTouringHours,
    hasPhoneNumbers,
    hasPropertyWebsite
  } = checkForGeneralData();

  return (
    <Box className={classes.mainContainer} data-testid="OverviewTab">
      {!hasDescriptionSectionData && !hasGeneralSectionData ? (
        <LeasingBinderNoContent
          icon={<BlankOverviewTabIcon />}
          primaryText="No general information yet!"
        />
      ) : (
        <>
          {hasDescriptionSectionData && (
            <Card className={classes.card} elevation={0}>
              <CardContent>
                <Box className={`${classes.sectionHeader} descSectionTitle`}>
                  <Text variant="body2" data-testid="DescriptionSectionTitle">
                    DESCRIPTION
                  </Text>
                  <BuildingIcon />
                </Box>

                <Text variant="body1">{property.description.short}</Text>

                {summaryInfo && (
                  <Text
                    variant="caption"
                    paragraph={true}
                    className={classes.summaryInfo}
                  >
                    {summaryInfo}
                  </Text>
                )}

                <Box>
                  {Object.keys(featuredAmenities).map((key: string) => (
                    <Chip
                      key={key}
                      avatar={featuredAmenities[key]}
                      label={key}
                      className={classes.chip}
                    />
                  ))}
                </Box>
              </CardContent>
            </Card>
          )}
          {/* Phone numbers column*/}
          <Box className={classes.generalSection}>
            {hasPhoneNumbers && (
              <Box className={classes.column}>
                <OverviewInfoCard title="PHONE NUMBERS" icon={<PhoneIcon />}>
                  {prospectForwardingNumber && (
                    <Box className={classes.subSection}>
                      <Box>
                        <ProspectNumberIcon />
                      </Box>

                      <Box className={classes.subSectionContent}>
                        <Text variant="overline" paragraph={true}>
                          Prospects
                        </Text>
                        <Text variant="body1">
                          {getFormattedPhoneNumber(prospectForwardingNumber)}
                        </Text>
                      </Box>
                    </Box>
                  )}

                  {residentForwardingNumber && (
                    <Box className={classes.subSection}>
                      <Box>
                        <ResidentNumberIcon />
                      </Box>

                      <Box className={classes.subSectionContent}>
                        <Text variant="overline" paragraph={true}>
                          Residents
                        </Text>
                        <Text variant="body1">
                          {getFormattedPhoneNumber(residentForwardingNumber)}
                        </Text>
                      </Box>
                    </Box>
                  )}
                  {property.tracking_number && (
                    <Box className={classes.subSection}>
                      <Box>
                        <PieLineIcon />
                      </Box>
                      <Box className={classes.subSectionContent}>
                        <Text variant="overline" paragraph={true}>
                          Tracking Number
                        </Text>
                        <Text variant="body1">
                          {getFormattedPhoneNumber(property.tracking_number)}
                        </Text>
                      </Box>
                    </Box>
                  )}
                </OverviewInfoCard>
              </Box>
            )}
            {/* Property Website and Address column*/}
            {(hasAddress || hasPropertyWebsite) && (
              <Box className={classes.column}>
                {/* Property Website */}
                {hasPropertyWebsite && (
                  <OverviewInfoCard
                    title="PROPERTY WEBSITE"
                    icon={<MonitorIcon />}
                  >
                    <Box className={classes.subSection}>
                      <Box className={classes.subSectionContent}>
                        <Text variant="body1" className={classes.website}>
                          <Link href={property.social.website} target="_blank">
                            {getFormattedWebsite()}
                          </Link>
                        </Text>
                      </Box>
                    </Box>
                  </OverviewInfoCard>
                )}
                {/* Address */}
                {hasAddress && (
                  <OverviewInfoCard
                    title="ADDRESS"
                    icon={<PinIcon />}
                    className={hasPropertyWebsite ? classes.secondCard : ''}
                  >
                    <Box className={classes.subSection}>
                      <Box className={classes.subSectionContent}>
                        {property.location.name && (
                          <Text variant="body1">{property.location.name}</Text>
                        )}
                        {property.location.address.street && (
                          <Text variant="body1">
                            {property.location.address.street}
                          </Text>
                        )}
                        {cityStateZip && (
                          <Text variant="body1">{cityStateZip}</Text>
                        )}
                      </Box>
                    </Box>
                  </OverviewInfoCard>
                )}
              </Box>
            )}
            {/* Touring Hours and Office Hours Column*/}
            {(hasOfficeHours || hasTouringHours) && (
              <Box className={classes.column}>
                {/* Office Hours */}
                {hasOfficeHours && (
                  <OverviewInfoCard title="OFFICE HOURS" icon={<ClockIcon />}>
                    <Box className={classes.subSection}>
                      <Box className={classes.subSectionContent}>
                        <PropertyHours hours={officeHours} />
                      </Box>
                    </Box>
                  </OverviewInfoCard>
                )}
                {/* Touring Hours */}
                {hasTouringHours && (
                  <OverviewInfoCard
                    title="TOURING HOURS"
                    icon={<BuildingAgentIcon />}
                    className={hasOfficeHours ? classes.secondCard : ''}
                  >
                    <Box className={classes.subSection}>
                      <Box className={classes.subSectionContent}>
                        <PropertyHours hours={touringHours} />
                      </Box>
                    </Box>
                  </OverviewInfoCard>
                )}
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
