import React from 'react';
import ConfirmationIcon from './ConfirmationIcon';

import {
  Text,
  InputCheckbox,
  Button,
  InputText
} from '@knockrentals/knock-shared-web';

import { OptOutReason } from './unsubscribeService';

const Unsubscribe = (props: {
  isLoading: boolean;
  submitFeedback: (
    reason?: OptOutReason,
    customReason?: string
  ) => Promise<any>;
}) => {
  const { submitFeedback, isLoading } = props;

  const [reason, setReason] = React.useState<OptOutReason | null>(null);
  const [customReason, setCustomReason] = React.useState('');
  return (
    <div className="unsubscribe-content">
      <div className="icon-container">
        <ConfirmationIcon />
      </div>
      <div>
        <Text variant="h3" color="textPrimary">
          Successfully unsubscribed
        </Text>
        <Text
          variant="subtitle1"
          color="textSecondary"
          style={{ paddingTop: '8px', paddingBottom: '8px' }}
        >
          If you have a moment, share the reason you're leaving:
        </Text>
        <div style={{ paddingBottom: '16px' }}>
          <InputCheckbox
            checked={reason === 'not-looking'}
            label="I am no longer looking for an apartment"
            onClick={() => setReason('not-looking')}
          />
          <InputCheckbox
            checked={reason === 'too-many-emails'}
            label="I get too many emails"
            onClick={() => setReason('too-many-emails')}
          />
          <InputCheckbox
            checked={reason === 'custom'}
            label="Other"
            onClick={() => setReason('custom')}
          />
          {reason === 'custom' ? (
            <InputText
              onInput={(e) =>
                setCustomReason((e.target as any).value.substring(0, 139))
              }
              value={customReason}
              multiline
              rows="3"
              fullWidth
            />
          ) : null}
        </div>
        <Button
          fullWidth
          disabled={isLoading}
          onClick={() => submitFeedback(reason || undefined, customReason)}
        >
          Send Feedback
        </Button>
      </div>
    </div>
  );
};

export default Unsubscribe;
