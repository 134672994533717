/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.service('renterProfileModalService', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function (renterInfo, listingId) {
        if (!renterInfo) {
          throw 'renter info required';
        }

        var renterProfileModal = {
          controller: 'RenterProfileModalController',
          templateUrl: '/angular/views/profile/renter-profile-modal.html',
          parent: angular.element(document.body),
          locals: { renterInfo: renterInfo, listingId: listingId }
        };

        return $mdDialog.show(renterProfileModal);
      };

      return self;
    }
  ]);

  app.controller('RenterProfileModalController', [
    '$rootScope',
    '$scope',
    '$mdDialog',
    'renterInfo',
    'listingId',
    'petsEditorService',
    'apiEvents',
    function (
      $rootScope,
      $scope,
      $mdDialog,
      renterInfo,
      listingId,
      petsEditorService,
      apiEvents
    ) {
      $scope.renterInfo = renterInfo;
      $scope.listingId = listingId;
      $scope.petsDisplay = petsEditorService.reduceRenterInfoPetTypes(
        $scope.renterInfo.pets
      );

      $scope.dismiss = function () {
        $mdDialog.hide();
      };

      var openChatThreadHandler = $rootScope.$on(
        apiEvents.openChatThread,
        $scope.dismiss
      );

      $scope.$on('$destroy', openChatThreadHandler);
    }
  ]);
})(window.angular);
