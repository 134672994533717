import find from 'lodash/find';
import * as uis from '../../../services';
import { APP_CONFIG } from 'APP_CONFIG';

(function (angular) {
  var app = angular.module('knockApp');

  const appDataService = function (
    $rootScope,
    $q,
    $location,
    notificationsApi,
    userService,
    teamApi,
    leasingTeamApi,
    companyApi,
    messagingApi,
    MAINTENANCE_MODE,
    featureFlagService,
    apiEvents,
    ProfileService,
    pusherInstanceService
  ) {
    var self = this;

    self.data = {
      isInitialized: false,
      initializationPromise: null,
      isLoading: false,
      isError: false,
      properties: [],
      members: [],
      sources: [],
      leasingTeams: [],
      preferences: {
        audioNotificationsEnabled: false
      },
      currentLeasingTeam: null,
      currentCompany: null,
      user: null
    };

    self.untilInitialized = function () {
      if (!this.initializationPromise) {
        // Return empty promise :'(
        return new Promise((res) => res());
      }
      return this.initializationPromise;
    };

    self.initialize = function () {
      $rootScope.appPreferences = {};
      $rootScope.featureFlags = {};

      self.data.isInitialized = true;
      self.data.isLoading = true;
      self.data.isError = false;

      if (MAINTENANCE_MODE) {
        self._isInitialized();

        return;
      }

      var user = userService.getUser();

      if (user && user.type === 'manager') {
        this.initializationPromise = self._getInitializationData().then(
          function () {
            self._isInitialized();
          },
          function () {
            self.data.isInitialized = false;
            self.data.isLoading = false;
            self.data.isError = true;
          }
        );
      } else {
        self.data.isLoading = false;
      }
    };

    //When the route changes without alter the global state
    //in case of a change of leasing team this will reload
    //the appData
    self._lastLT = userService.getScopedLeasingTeamId();
    $rootScope.$on('$locationChangeSuccess', () => {
      const currentLT = userService.getScopedLeasingTeamId();
      if (self._lastLT !== currentLT) {
        self._lastLT = currentLT;
        self.initialize();
      }
    });

    self._isInitialized = function () {
      self.data.isInitialized = true;
      self.data.isLoading = false;
      self.data.isError = false;
      $rootScope.$broadcast(apiEvents.appDataInitialized);
      self._initializePostHog();
      self._initializeAppCues();
      self._initializeHeapCall();
      self._getVoiceAppStatusFromPusher();
    };

    self._initializePostHog = function () {
      const user = userService.getUser();
      if (!user) {
        return;
      }
      uis.init({
        user: { ...user },
        host: APP_CONFIG.UIS.host,
        token: APP_CONFIG.UIS.token
      });
    };

    self._initializeAppCues = function () {
      const user = userService.getUser();
      if (user && window.Appcues) {
        const { id, company_id, role, type } = user;
        const leasing_team_id = self.getCurrentLeasingTeamId();
        const data = { role, company_id, leasing_team_id, type };
        window.Appcues.identify(id, data);
      }
    };

    self._initializeHeapCall = function () {
      const { user } = self.data;
      window.heap.identify(user.user_id);
      const userProperties = {
        Username: user.username,
        'User ID': user.id,
        Company: self.data.currentCompany.name,
        'Company ID': self.data.currentCompany.company_id,
        'User Level': user.type,
        LeasingTeamId: self.data.currentLeasingTeam.id,
        LeasingTeamName: self.data.currentLeasingTeam.name
      };
      window.heap.addUserProperties(userProperties);
    };

    self._getInitializationData = function () {
      var initPromises = [
        self._getProperties(),
        self._getTeamMembers(),
        self._getSources(),
        self._getCompanyPreferences(),
        self._getNotificationPreferences(),
        self._getCurrentLeasingTeamId(),
        self._getLeasingTeams(),
        self._getEmailMergeTags(),
        self._getEmailBlocklist(),
        self._getFeatureFlags(),
        self._getUserName(),
        self._getVoiceAppStatus(),
        self._getVoiceAppStatusFromPusher()
      ];

      return $q.all(initPromises);
    };
    self._getVoiceAppStatus = () => {
      userService.getVoiceAppStatus();
    };

    self._getVoiceAppStatusFromPusher = function () {
      const user = userService.getUser();
      if (user) {
        const pusherClient = pusherInstanceService.getInstance();
        pusherClient.subscribe(`voice-app-user-status-${user.id}`);
        pusherClient.bind('voice-app-user-status', self._voiceAppUserStatus);
      }
    };
    self._voiceAppUserStatus = function (voiceAppStatus) {
      // console.log(localStorage.getItem('voiceAppStatusOnLocalStorage'));
      localStorage.setItem(
        'voiceAppStatusOnLocalStorage',
        voiceAppStatus.status
      );
    };

    self._getFeatureFlags = async () => {
      // Get feature flag values, and assign them to an object set on $rootScope
      // To access feature flags in components, inject $rootScope and access
      // $rootScope.featureFlags.FEATURE_NAME, ex: $rootScope.featureFlags.NEW_UNITS
      await featureFlagService.initialize();
      return Promise.all(
        Object.keys(featureFlagService.FEATURES).map(async (feature) => {
          const value = await featureFlagService.get(
            featureFlagService.FEATURES[feature],
            false
          );
          $rootScope.featureFlags[feature] = value;
        })
      ).then(() => {
        $rootScope.$emit(apiEvents.featureFlagsInitialized);
      });
    };

    self._getProperties = function () {
      var excludeDeleted = true;

      return teamApi
        .getTeamProperties(excludeDeleted)
        .then(function (response) {
          self.data.properties = response.data.properties;
        });
    };

    self._getTeamMembers = function () {
      return teamApi.getTeamMembers().then(function (response) {
        self.data.teamMembers = response.data.members;
      });
    };

    self._getUserName = function () {
      return ProfileService.getUsername().then((response) => {
        self.data.user = response.data.user;
      });
    };

    self._getSources = function () {
      return leasingTeamApi
        .getMyLeasingTeamMarketingSources()
        .then(function (response) {
          self.data.sources = response.data.marketing_sources;
        });
    };

    self._getCompanyPreferences = function () {
      return companyApi.getCompanyPreferences().then(function (response) {
        $rootScope.$broadcast('receivedCompanyPreferences', {
          preferences: response.data.preferences
        });
        $rootScope.appPreferences.company = response.data.preferences;
      });
    };

    self._getEmailMergeTags = () => {
      return messagingApi
        .getEmailMergeTags()
        .then(function (response) {
          $rootScope.emailMergeTags = response.data.merge_tags;
        })
        .catch((e) => {
          console.error('Failed to retrieve merge tags', e);
        });
    };

    self._getEmailBlocklist = () => {
      return messagingApi
        .getEmailBlocklist()
        .then((response) => {
          $rootScope.emailDomainBlocklist = response.data.domain_blocklist;
        })
        .catch((e) => {
          console.error('Failed to retrieve email blocklist', e);
        });
    };

    self._getNotificationPreferences = function () {
      return notificationsApi.getPreferences().then(function (response) {
        self.data.preferences.audioNotificationsEnabled =
          response.data.user_preferences.client_preferences[
            'manager-audio-notifications-enabled'
          ];
      });
    };

    self._getCurrentLeasingTeamId = () => {
      return leasingTeamApi.getMyLeasingTeam().then((response) => {
        self.data.currentLeasingTeam = response.data.leasing_team;
        self.data.currentCompany = response.data.company;
        self.data.currentLeasingTeamId = response.data.leasing_team.id;
      });
    };

    self._getLeasingTeams = () => {
      return leasingTeamApi.getMyLeasingTeams().then((response) => {
        self.data.leasingTeams = response.data.leasing_teams;

        const altIds = {};
        self.data.leasingTeams.forEach(
          (leasingTeam) =>
            (altIds[leasingTeam.public_id] = leasingTeam.manager_id)
        );
        userService.setAltIds(altIds);
      });
    };
    self.getEmailMergeTags = (emailTargetType) => {
      const mergeTags = $rootScope.emailMergeTags;
      return mergeTags[emailTargetType] || mergeTags.default;
    };

    self.getProperty = function (propertyId) {
      return find(self.getProperties(), function (property) {
        return property.Property.id === propertyId;
      });
    };

    self.getProperties = function () {
      return self.data.properties;
    };

    self.getTeamMember = function (managerId) {
      return find(self.getTeamMembers(), function (teamMember) {
        return teamMember.Manager.id === managerId;
      });
    };

    self.getTeamMembers = function () {
      return self.data.teamMembers;
    };

    self.getSources = function () {
      return self.data.sources;
    };

    self.getCurrentLeasingTeamId = () => {
      return self.data.currentLeasingTeamId;
    };

    self.getLeasingTeams = () => {
      return self.data.leasingTeams;
    };

    self.getLeasingTeamsBadges = () => self.data.leasingTeamBadgeCounts;

    self.isMultiteamUser = () => {
      return self.data.leasingTeams && self.data.leasingTeams.length > 1;
    };

    self.newUnitsRedirectTo404 = (callback) => {
      // redirect to our 404 page if the New Units flag is turned on
      self.untilInitialized().then(() => {
        if ($rootScope.featureFlags.NEW_UNITS) {
          $location.path('/angular/views/404.html');
        } else {
          callback();
        }
      });
    };

    return self;
  };
  appDataService.$inject = [
    '$rootScope',
    '$q',
    '$location',
    'notificationsApi',
    'userService',
    'teamApi',
    'leasingTeamApi',
    'companyApi',
    'messagingApi',
    'MAINTENANCE_MODE',
    'featureFlagService',
    'apiEvents',
    'ProfileService',
    'pusherInstanceService'
  ];
  app.factory('appDataService', appDataService);
})(window.angular);
