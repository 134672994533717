/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('TransferListingModalController', [
    '$scope',
    '$modalInstance',
    'listingApi',
    'listing',
    'oldActiveManagerId',
    'newActiveManagerId',
    'onSuccess',
    'onCancel',
    function (
      $scope,
      $modalInstance,
      listingApi,
      listing,
      oldActiveManagerId,
      newActiveManagerId,
      onSuccess,
      onCancel
    ) {
      var self = this;

      $scope.cancelTransfer = function () {
        onCancel();
        $modalInstance.dismiss();
      };

      $scope.transferListing = function () {
        $scope.isTransferring = true;

        listingApi
          .setListingManager(listing.id, newActiveManagerId, true)
          .success(self._transferListingSuccess)
          .finally(function () {
            $scope.isTransferring = false;
          });
      };

      self._transferListingSuccess = function (response) {
        onSuccess(response);
        $modalInstance.dismiss();
      };
    }
  ]);
})(window.angular);
