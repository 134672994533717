/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
require('../../core/scripts/notifications/notificationsPanel.directive');
require('../../core/scripts/notifications/applicationNotification.service');
require('../../core/scripts/notifications/applicationNotificationModal.controller');
require('../../core/scripts/notifications/notificationBanners.directive');
require('../../core/scripts/notifications/notificationBanner.directive');
require('../../core/scripts/notifications/notificationBannerCall.directive');
require('../../core/scripts/notifications/notificationBannerChat.directive');
require('../../core/scripts/notifications/notificationBannerAppointment.directive');
require('../../core/scripts/notifications/notificationBannerBatchMessage.directive');
