import React, { FC, useMemo, useState, useEffect, useCallback } from 'react';
import {
  Drawer,
  Box,
  Grid,
  Divider,
  IconButton,
  Snackbar
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import { Provider } from 'react-redux';
import { store } from '../../../../../app/store';
import {
  makeStyles,
  Button,
  Text,
  ThemeProvider
} from '@knockrentals/knock-shared-web';
import { colors, useCommonStyles } from '../../commonStyles/commonStyles';
import LossReasonsStep from './LossReasonsStep';
import SelectNearbyPropertyStep from './SelectNearbyPropertyStep';
import InformationalEmailStep from './InfomationalEmailStep';
import { MarkAsLostDrawerProvider } from './MarkAsLostDrawerContextProvider';
import { Alert, AlertTitle } from '@material-ui/lab';
import CreateReferralStep from './CreateReferralStep';
import {
  NearbyCommunity,
  ReferProspectResponse,
  multipleRefersForProspect
} from 'LegacyAngularApp/legacy-angular-app/services/nearbyCommunitiesService';
import CloseConfirmationModal from './CloseConfirmationModal';
import MarkAsLostSummary from './MarkAsLostSummary';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import dayjs from 'dayjs';
import { ActivityData, Prospect } from 'app/services/prospects/entities';
import { CircularProgressButton } from '../../CircularProgressButton';
import { useProspects } from 'app/services/prospects/hooks';
import ScheduleTour from '../../Modals/ScheduleTour';
import useProperties from 'app/services/property/hooks';
import { TourTypesEnabled } from 'app/services/property/entities';

dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles({
  title: {
    color: colors.disabled
  },
  subtitle: {
    fontWeight: 600
  },
  container: {
    maxWidth: '720px',
    height: '100%'
  },
  sectionContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  contentContainer: {
    height: '100%',
    padding: '24px',
    overflowY: 'scroll'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px'
  },
  section: {
    padding: '24px'
  },
  backButton: {
    width: '90px'
  },
  nextButtons: {
    marginLeft: '8px',
    minWidth: '90px'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px'
  },
  iconButton: {
    height: 'fit'
  },
  icon: {
    color: colors.secondaryText
  },
  alert: {
    marginBottom: '24px'
  },
  alertTitle: {
    fontWeight: 600
  },
  nextButtonsContainer: {
    display: 'flex'
  },
  toast: {
    backgroundColor: colors.snackbarBackground
  }
});

export interface MarkAsLostDrawerProps {
  closeDrawer: (bookTourChecked: boolean) => void;
  open: boolean;
  openLeasingBinder: (property: number) => void;
  mergeTags: [];
  prospect: Prospect;
  sendEmail: (
    streamId: string,
    messageText: string | null,
    messageHtml: string,
    messageSubject: string,
    attachments: any[],
    target: string[],
    asManagerId?: string | null
  ) => Promise<any>;
  timeService: any;
  isReferralsEnabled: any;
  getMyCommunities: any;
  closeScheduleTour: () => void;
}

interface ErrorAlert {
  title: string;
  message: string;
}

interface CommunitiyAllSelectedStates {
  allSelected: boolean;
  indeterminateSelect: boolean;
}

export interface ReferralFollowUpProps {
  followUp: boolean;
  date: Date;
  time: string;
  note: string;
  dateError?: string;
}

export const getCurrentFutureDate = () => {
  const date = new Date();

  date.setDate(date.getDate() + 1);
  return date;
};

export const defaultReferralFollowUp: ReferralFollowUpProps = {
  followUp: false,
  date: getCurrentFutureDate(),
  time: '9:00 am',
  note: ''
};

type Order = 'asc' | 'desc';
export interface CommunitySortProps {
  column: string;
  order: Order;
  page: number;
  rows: 50 | 100;
}

const defaulSortProps: CommunitySortProps = {
  column: 'distance',
  order: 'asc',
  page: 0,
  rows: 50
};

export const getPlainTextFromEmail = (email: string) => {
  return email
    .trim()
    .replace(/<\/?[^>]+(>|$)/g, '') //Remove HTML Tags
    .replace(/(&quot;|&#34;)/g, '"')
    .replace(/(&#39;|&apos;)/g, "'")
    .replace(/(&nbsp;|&#160;)/g, ' ')
    .replace(/(&lt;|&#60;)/g, '<')
    .replace(/(&gt;|&#62;)/g, '>')
    .replace(/(&amp;|&#38;)/g, '&')
    .replace(/(&cent;|&#162;)/g, '¢')
    .replace(/(&pound;|&#163;)/g, '');
};

interface PropertyTourType {
  [key: number]: TourTypesEnabled | undefined;
}
interface ReferredProspects {
  [key: number]: {
    knockId: string;
    renterId: number;
  };
}

const MarkAsLostDrawer: FC<MarkAsLostDrawerProps> = ({
  closeDrawer,
  open,
  openLeasingBinder,
  mergeTags,
  prospect,
  sendEmail,
  timeService,
  isReferralsEnabled,
  getMyCommunities,
  closeScheduleTour
}) => {
  const classes = useStyles();
  const [step, setStep] = useState<number>(1);
  const commonClasses = useCommonStyles();
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [selectedCommunities, setSelectedCommunities] = useState<number[]>([]);
  const [communities, setCommunities] = useState<NearbyCommunity[]>([]);
  const [slideDirection, setSlideDirection] = useState<'right' | 'left'>(
    'left'
  );
  const [errorAlert, setErrorAlert] = useState<ErrorAlert | undefined>();
  const [communitiyAllSelectedStates, setAllSelectedState] =
    useState<CommunitiyAllSelectedStates>({
      allSelected: false,
      indeterminateSelect: false
    });

  const [createReferral, setCreateReferral] = useState<boolean>(false);
  const [noReferral, setNoReferral] = useState<boolean>(false);
  const [referralFollowUp, setReferralFollowUp] =
    useState<ReferralFollowUpProps>(defaultReferralFollowUp);
  const [bookTourChecked, setBookTourChecked] = useState<boolean>(false);
  const [referralNote, setReferralNote] = useState<string>('');
  const [sendInfoMail, setSendInfoMail] = useState<boolean>(false);
  const [emailMessage, setEmailMessage] = useState<string>('');

  const [emailSubject, setEmailSubject] = useState<string>(
    'Sorry to see you go!'
  );
  const [showCloseConfirmationModal, setCloseConfirmationModal] =
    useState(false);

  const [isEmailMessageEmpty, setIsEmailMessageEmpty] =
    useState<boolean>(false);

  const [isSubjectEmpty, setIsSubjectEmpty] = useState<boolean>(false);

  const [nextButtonTitle, setNextButtonTitle] = useState<string>('Next');
  const [communitySort, setCommunitySort] =
    useState<CommunitySortProps>(defaulSortProps);

  const [informationalEmailError, setInformationalError] =
    useState<boolean>(false);
  const [referralError, setReferralError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [tourTypes, setTourTypes] = useState<PropertyTourType>({});
  const [showScheduleTourModals, setShowScheduleTourModals] =
    useState<boolean>(false);
  const [referredProspects, setReferredProspects] = useState<ReferredProspects>(
    {}
  );
  const [timeZone, setTimeZone] = useState<string | null>(null);

  const { getTourTypesEnabled } = useProperties();
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  const [referralsEnabled, setReferralsEnabled] = useState<boolean>(false);

  const { setLostReasonForProspects, addActivityForProspects, updateProspect } =
    useProspects();

  const view = useMemo<{ title: string; component: React.ReactNode }>(() => {
    switch (step) {
      case 1:
        return {
          title: 'Loss Reasons',
          component: <LossReasonsStep />
        };
      case 2:
        return {
          title: 'Suggest Nearby Communities',
          component: <SelectNearbyPropertyStep />
        };
      case 3:
        return {
          title: 'Create Referral',
          component: <CreateReferralStep />
        };
      case 4:
        return {
          title: 'Informational Email',
          component: <InformationalEmailStep mergeTags={mergeTags} />
        };
      case 5:
        return {
          title: 'Loss Summary',
          component: <MarkAsLostSummary />
        };
      default:
        return { title: '', component: React.Fragment };
    }
  }, [step, mergeTags]);

  const nextButtonEnabled = useMemo(() => {
    switch (step) {
      case 1: //Loss reasons step
        if (selectedReasons.length === 0) {
          return false;
        }
        return true;
      case 2: //Select Nearby Properties step
        return selectedCommunities.length > 0;
      case 3:
        return referralNote.trim() !== '';
      case 4:
        return (
          !isSubjectEmpty &&
          !isEmailMessageEmpty &&
          getPlainTextFromEmail(emailMessage).trim() !== ''
        );
      default:
        return true;
    }
  }, [
    step,
    selectedReasons,
    selectedCommunities,
    referralNote,
    isSubjectEmpty,
    isEmailMessageEmpty,
    emailMessage
  ]);

  const shouldDisableNextButton = useMemo(() => {
    if (step === 2) {
      return !(nextButtonEnabled && (createReferral || sendInfoMail));
    } else {
      return !nextButtonEnabled || informationalEmailError || referralError;
    }
  }, [
    step,
    nextButtonEnabled,
    createReferral,
    sendInfoMail,
    informationalEmailError,
    referralError
  ]);

  const getTourTypesForProperties = useCallback(async () => {
    const reqs = selectedCommunities.map(
      (id: number) =>
        new Promise<PropertyTourType>(async (resolve) => {
          try {
            const tourType = await getTourTypesEnabled(id);
            resolve({
              [id]: tourType.data
            });
          } catch (err) {
            console.error('Error getting tour types', err);
            resolve({
              [id]: undefined
            });
          }
        })
    );
    const responses = await Promise.all(reqs);

    setTourTypes(
      responses.reduce(
        (prev: PropertyTourType, current: PropertyTourType) => ({
          ...prev,
          ...current
        }),
        {}
      )
    );
  }, [selectedCommunities, getTourTypesEnabled]);

  const handleRefer = () =>
    new Promise<ReferProspectResponse[]>(async (resolve, reject) => {
      if (createReferral) {
        try {
          const results = await multipleRefersForProspect(
            prospect.id,
            selectedCommunities,
            {
              note: referralNote,
              isScheduling: true
            }
          );

          setReferredProspects(
            results.reduce(
              (
                prev: ReferredProspects,
                current: ReferProspectResponse,
                i: number
              ) => {
                return {
                  ...prev,
                  [selectedCommunities[i]]: current.ok
                    ? {
                        knockId:
                          current.response.sister_property_prospect_knock_id,
                        renterId:
                          current.response.sister_property_prospect_renter_id
                      }
                    : {
                        knockId: '',
                        renterId: 0
                      }
                };
              },
              {}
            )
          );

          resolve(results);
        } catch (error) {
          setReferralError(true);
          reject(error);
        }
      } else {
        resolve([]);
      }
    });

  const handleInformationalEmail = () =>
    new Promise(async (resolve, reject) => {
      if (sendInfoMail) {
        try {
          await sendEmail(
            prospect.stream_id || '',
            '',
            emailMessage,
            emailSubject,
            [],
            ['email']
          );
          resolve('');
        } catch (error) {
          setInformationalError(true);
          reject(error);
        }
      } else {
        resolve('');
      }
    });

  const handleMarkAsLost = async () => {
    setLoading(true);
    const lostProspectsPayload = {
      prospect_ids: [prospect.id.toString()],
      loss_reasons: selectedReasons
    };

    const resultSetLostReasonForProspect = await setLostReasonForProspects(
      lostProspectsPayload
    );
    if (resultSetLostReasonForProspect.hasOwnProperty('error')) {
      console.error('Error setting loss reasons for prospects.');
    }

    const resultSetStatusAsLost = await updateProspect({
      ...prospect,
      status: 'lost'
    });
    if (resultSetStatusAsLost.hasOwnProperty('error')) {
      handleClose('confirm');
      setToastMessage(
        'Could not mark the prospect as lost. Please try again later.'
      );
      return;
    }

    handleClose('confirm');
  };

  const handleFollowUps = async (prospectIds: number[]) => {
    let followUpDate = dayjs(referralFollowUp.date);

    const selectedTime = referralFollowUp.time.split(' ')['0'];
    const hours = Number(selectedTime.split(':')[0]);
    const minutes = Number(selectedTime.split(':')[1]);

    followUpDate = followUpDate.second(0);
    followUpDate = followUpDate.millisecond(0);
    followUpDate = followUpDate.hour(hours);
    followUpDate = followUpDate.minute(minutes);

    if (
      referralFollowUp.time.split(' ')[1] === 'pm' &&
      selectedTime.split(':')[0] !== '12'
    ) {
      followUpDate = followUpDate.hour(hours + 12);
    }

    const activeTimeZone = (await timeService.getActiveTimezone()).id;

    followUpDate = followUpDate.tz(activeTimeZone, true);

    try {
      const followUpPayload: ActivityData = {
        prospect_ids: prospectIds,
        type: 'reminder',
        message: referralFollowUp.note,
        reminder_time: followUpDate.format()
      };
      await addActivityForProspects(followUpPayload);
    } catch (error) {
      console.error('Error scheduling follow ups:', error);
    }
  };

  const handleConfirm = async () => {
    let referResponses: ReferProspectResponse[] = [];

    try {
      const processes = await Promise.all([
        handleRefer(),
        handleInformationalEmail()
      ]);
      referResponses = processes[0];
    } catch (error) {
      setLoading(false);
      console.error('Confirm error:', error);
      return;
    }

    if (referralFollowUp.followUp) {
      await handleFollowUps(
        referResponses.map((refer: ReferProspectResponse) =>
          refer.ok ? refer.response.sister_property_prospect_id : 0
        )
      );
    }

    handleMarkAsLost();
  };
  const handleNext = async () => {
    if (step === 5 || (step === 1 && referralsEnabled)) {
      setLoading(true);
      handleConfirm();
      return;
    }

    setErrorAlert(undefined);
    setSlideDirection('left');
    switch (step) {
      case 1:
        if (selectedReasons.length === 0) {
          return;
        }
        break;
      case 2:
        if (selectedCommunities.length === 0) {
          return;
        }
        if (!createReferral && !sendInfoMail) {
          return setStep(5);
        }
        break;
      default:
        break;
    }

    setStep((prevState: number) => {
      let next = 1;
      if (
        (prevState === 2 && !createReferral && sendInfoMail) ||
        (prevState === 3 && !sendInfoMail)
      ) {
        next = 2;
      }
      return prevState + next;
    });
  };
  const handleNextBox = useCallback(() => {
    if (step === 4 && !nextButtonEnabled) {
      setIsSubjectEmpty(emailSubject.trim() === '');
      setIsEmailMessageEmpty(getPlainTextFromEmail(emailMessage).trim() === '');
    }
  }, [step, emailSubject, emailMessage, nextButtonEnabled]);
  const handleBack = () => {
    setErrorAlert(undefined);
    setSlideDirection('right');
    setNextButtonTitle('Next');
    if (step === 5) {
      setInformationalError(false);
      setReferralError(false);
    }
    setStep((prevState: number) => {
      if (prevState === 2 && step === 2) {
        return prevState - 1;
      }
      if (
        noReferral ||
        (prevState === 4 && !createReferral) ||
        (!createReferral && !sendInfoMail)
      ) {
        setNoReferral(false);
        return 2;
      }
      if (prevState === 5 && !sendInfoMail) {
        return prevState - 2;
      }
      return prevState - 1;
    });
  };
  const handleNoReferral = () => {
    setNoReferral(true);
    setStep(5);
  };

  const handleClose = (reason?: string) => {
    if (step === 1 || reason === 'confirm') {
      if (bookTourChecked && reason === 'confirm') {
        setShowScheduleTourModals(true);
      }
      closeAllDrawers();
    } else {
      setCloseConfirmationModal(true);
    }
  };

  const closeModal = () => {
    setCloseConfirmationModal(false);
  };

  const closeAllDrawers = () => {
    setCloseConfirmationModal(false);
    //Reset states
    setStep(1);
    setSelectedReasons([]);

    setErrorAlert(undefined);
    setSlideDirection('left');
    setCommunitySort(defaulSortProps);
    setAllSelectedState({
      allSelected: false,
      indeterminateSelect: false
    });
    setCreateReferral(false);
    setSendInfoMail(false);
    setEmailMessage('');
    setEmailSubject('Sorry to see you go!');
    setIsSubjectEmpty(false);
    setReferralNote('');
    closeDrawer(bookTourChecked);
    setReferralFollowUp(defaultReferralFollowUp);
    setBookTourChecked(false);
    setLoading(false);
    setInformationalError(false);
    setReferralError(false);
    setBookTourChecked(false);
  };

  const referralFollowUpTimes = useMemo<string[]>(() => {
    const now = dayjs(new Date());
    const selectedDate = dayjs(referralFollowUp.date);
    let startFrom = 7;
    const endTo = 22;
    let currentDate = false;

    if (selectedDate.diff(now, 'hour') <= 0) {
      startFrom = dayjs().hour();
      if (dayjs().minute() > 30) {
        startFrom++;
      } else {
        currentDate = true;
      }

      if (startFrom > 22) {
        setReferralFollowUp((prevState: ReferralFollowUpProps) => ({
          ...prevState,
          date: getCurrentFutureDate()
        }));
        return [];
      }
    }
    const times: string[] = [];

    for (let i = startFrom; i < endTo; i++) {
      if (i - 12 < 0) {
        times.push(`${i}:00 am`, `${i}:30 am`);
      } else {
        times.push(
          `${i - 12 === 0 ? '12' : i - 12}:00 pm`,
          `${i - 12 === 0 ? '12' : i - 12}:30 pm`
        );
      }
    }
    times.push(`10:00 pm`);

    if (currentDate) {
      times.shift();
    }

    if (!times.includes(referralFollowUp.time)) {
      setReferralFollowUp((prevState: ReferralFollowUpProps) => ({
        ...prevState,
        time: times[0]
      }));
    }

    return times;
  }, [referralFollowUp]);

  useEffect(() => {
    if (step === 5) {
      setNextButtonTitle('Confirm');
    } else {
      if (!isReferralsEnabled) {
        setReferralsEnabled(true);
        setNextButtonTitle('Mark as Lost');
      } else {
        setNextButtonTitle('Next');
      }
    }
  }, [step, isReferralsEnabled]);
  useEffect(() => {
    if (createReferral) {
      getTourTypesForProperties();
    }
  }, [createReferral, selectedCommunities, getTourTypesForProperties]);
  useEffect(() => {
    if (open === true) {
      setSelectedCommunities([]);
      setShowScheduleTourModals(false);
      setTourTypes({});
    }
    timeService
      .getActiveTimezone()
      .then((res: { id: string }) => {
        setTimeZone(res.id);
      })
      .catch((err: any) => {
        console.error('Error getting activeTimeZone', err);
      });
  }, [open, timeService]);

  return (
    <MarkAsLostDrawerProvider
      context={{
        selectedCommunities,
        setSelectedCommunities,
        communities,
        setCommunities,
        selectedReasons,
        setSelectedReasons,
        slideDirection,
        setErrorAlert,
        errorAlert,
        ...communitiyAllSelectedStates,
        setAllSelectedState,
        createReferral,
        setCreateReferral,
        openLeasingBinder,
        referralFollowUp,
        setReferralFollowUp,
        referralFollowUpTimes,
        sendInfoMail,
        setSendInfoMail,
        emailMessage,
        emailSubject,
        setEmailMessage,
        setEmailSubject,
        communitySort,
        setCommunitySort,
        referralNote,
        setReferralNote,
        noReferral,
        bookTourChecked,
        setBookTourChecked,
        getPlainTextFromEmail,
        isEmailMessageEmpty,
        setIsEmailMessageEmpty,
        isSubjectEmpty,
        setIsSubjectEmpty,
        prospect,
        sendEmail,
        informationalEmailError,
        referralError,
        handleMarkAsLost,
        getMyCommunities
      }}
    >
      <Snackbar
        message={toastMessage}
        ContentProps={{
          classes: {
            root: classes.toast
          }
        }}
        open={!!toastMessage}
        onClose={() => {
          setToastMessage(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      />
      <Drawer anchor="right" open={open} disableEnforceFocus>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.sectionContainer}>
            <Box>
              <Box className={classes.headerContainer}>
                <Box>
                  <Text variant="caption" className={classes.title}>
                    Lost Prospect
                  </Text>
                  <Text className={classes.subtitle}>{view.title}</Text>
                </Box>
                <IconButton
                  data-testid="close-button"
                  onClick={() => handleClose('close-button')}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>
              </Box>
              <Divider />
            </Box>
            <Box
              className={`${classes.contentContainer} ${
                step !== 1 ? commonClasses.invisibleScroll : ''
              }`}
            >
              <>
                {!!errorAlert && (
                  <Alert className={classes.alert} severity="error">
                    <AlertTitle className={classes.alertTitle}>
                      {errorAlert.title}
                    </AlertTitle>
                    {errorAlert.message}
                  </Alert>
                )}
                {view.component}
              </>
            </Box>
            <Box>
              <Divider />
              <Box className={classes.actionsContainer}>
                <Box>
                  {step !== 1 && (
                    <Button
                      data-testid="back-button"
                      variant="text"
                      onClick={handleBack}
                      className={classes.backButton}
                      disabled={loading}
                      startIcon={<ArrowBackIcon />}
                    >
                      Back
                    </Button>
                  )}
                </Box>
                <Box className={classes.nextButtonsContainer}>
                  {step === 2 && (
                    <Button
                      data-testid="no-referral-button"
                      variant="outlined"
                      onClick={handleNoReferral}
                      className={classes.nextButtons}
                    >
                      No Referral
                    </Button>
                  )}

                  <Box onClick={handleNextBox}>
                    <CircularProgressButton
                      dataTestId="next-button"
                      disabled={shouldDisableNextButton}
                      onClick={handleNext}
                      className={classes.nextButtons}
                      shouldShowProgress={loading}
                      endIcon={
                        step < 5 && !referralsEnabled ? (
                          <ArrowForwardIcon />
                        ) : undefined
                      }
                      progressText="Confirming Loss..."
                    >
                      {nextButtonTitle}
                    </CircularProgressButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Drawer>
      {showCloseConfirmationModal && (
        <CloseConfirmationModal
          closeModal={closeModal}
          closeDrawerFromCloseConfirmationModal={closeAllDrawers}
        />
      )}

      {showScheduleTourModals &&
        selectedCommunities.map((id: number, i: number) => {
          const community = communities.find(
            (community) => community.property.id === id
          );
          if (!community || !referredProspects[id]) {
            return null;
          }

          return (
            <ScheduleTour
              key={`tour-for-${id}`}
              timeZone={timeZone || ''}
              prospect={referredProspects[id]}
              noBackdrop={i !== 0}
              closeScheduleTour={closeScheduleTour}
              countLabel={
                selectedCommunities.length > 1
                  ? `${selectedCommunities.length - i} of ${
                      selectedCommunities.length
                    }`
                  : ''
              }
              property={{
                name: community.property.name,
                id: community.property.id,
                tourTypes: tourTypes[id]
              }}
            />
          );
        })}
    </MarkAsLostDrawerProvider>
  );
};

export default (props: MarkAsLostDrawerProps) => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <MarkAsLostDrawer {...props} />
      </ThemeProvider>
    </Provider>
  );
};
