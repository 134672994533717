import React, { ChangeEvent, FC } from 'react';
import { makeStyles, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles({
  rowsPerPage: {
    '& .MuiSelect-root': {
      fontSize: '12px',
      width: '40px'
    },

    '&.MuiInputBase-root': {
      border: 'none',
      paddingLeft: 0,
      color: 'rgba(0, 0, 0, 0.87)'
    },

    '&.MuiFilledInput-root': {
      backgroundColor: 'transparent'
    },

    '& .MuiFilledInput-input': {
      padding: `9px 20px 9px 4px`
    },

    '& .MuiSelect-icon': {
      top: 'calc(50% - 8px)',
      color: 'rgba(0, 0, 0, 0.54)'
    },

    '&.MuiFilledInput-underline:before': {
      border: 'none'
    },

    '&.MuiFilledInput-underline:after': {
      border: 'none'
    },

    '&.MuiFilledInput-underline:hover:before': {
      border: 'none'
    }
  },

  rowsPerPageItem: {
    '& .MuiListItem-root': {
      fontSize: '12px'
    },

    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },

    '& .MuiListItem-root:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }
});

const ROWS_PER_PAGE_BASE_OPTIONS = [
  {
    value: 100,
    label: '100'
  },
  {
    value: 500,
    label: '500'
  },
  {
    value: 1000,
    label: '1000'
  }
];

interface RowsPerPageSelectProps {
  onChangeRowsPerPage(value: unknown, allRowsSelected: boolean): void;
  selectedOptionValue: number;
  totalRows: number;
  isPaginationEnabled: boolean;
}

const RowsPerPageSelect: FC<RowsPerPageSelectProps> = ({
  onChangeRowsPerPage,
  selectedOptionValue,
  totalRows,
  isPaginationEnabled
}) => {
  const classes = useStyles();

  const getRowsPerPageOptions = (totalCount: number) => {
    const all = {
      value: totalCount,
      label: 'All'
    };

    if (isPaginationEnabled) {
      return ROWS_PER_PAGE_BASE_OPTIONS;
    } else {
      return [...ROWS_PER_PAGE_BASE_OPTIONS, all];
    }
  };

  const handleRowsPerPageChange = (event: ChangeEvent<{ value: unknown }>) => {
    let allRowsSelected = false;

    if (
      !ROWS_PER_PAGE_BASE_OPTIONS.some(
        (option) => option.value === event.target.value
      )
    ) {
      allRowsSelected = true;
    }

    onChangeRowsPerPage(event.target.value, allRowsSelected);
  };

  const selectOptions = getRowsPerPageOptions(totalRows);

  return (
    <span className="rows-per-page">
      <label>Rows per page:</label>

      <Select
        className={classes.rowsPerPage}
        label="Rows per page"
        MenuProps={{
          className: classes.rowsPerPageItem,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
        onChange={handleRowsPerPageChange}
        value={selectedOptionValue}
        variant="filled"
      >
        {selectOptions.map((option) => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </span>
  );
};

export default RowsPerPageSelect;
