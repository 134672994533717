/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import remove from 'lodash/remove';
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('ListingEditorService', [
    'api',
    'uploadService',
    '$location',
    'timeService',
    function (api, uploadService, $location) {
      return {
        navigateToListingRoute: function (listingId) {
          $location.url('/manager/listing?newListingId=' + listingId);
        },
        navigateToEditListingRoute: function (listingId, reactivate) {
          var url = '/listing/' + listingId + '/edit';
          if (reactivate) {
            url += '?reactivate=true';
          }
          $location.url(url);
        },
        setStatusToSavingListing: function ($scope) {
          $scope.$parent.isSaving = true;
          $scope.$parent.setSaveListingStatus('saving');
        },
        setStatusToActivating: function ($scope) {
          $scope.$parent.isPosting = true;
          $scope.$parent.setSaveListingStatus('activating');
        },
        setStatusToSavingError: function ($scope) {
          $scope.$parent.setSaveListingStatus('saving-error');
        },
        setStatusToPostingError: function ($scope) {
          $scope.$parent.setSaveListingStatus('posting-error');
        }
      };
    }
  ]);

  app.directive('listingSaveButtons', function () {
    return {
      restrict: 'E',
      templateUrl:
        '/angular/views/listing/listingEditor/listing-save-buttons.html',
      controller: [
        '$scope',
        '$location',
        'listingApi',
        'ListingEditorService',
        'timeService',
        function (
          $scope,
          $location,
          listingApi,
          ListingEditorService,
          timeService
        ) {
          var self = this;

          $scope.isNew =
            $location.url().indexOf('/manager/listing/create') >= 0;
          $scope.isActive = !$scope.isNew && $scope.$parent.listing.isActive;

          $scope.postListing = function () {
            $scope.$parent.listing.status = 'active';

            $scope.saveListing().then(
              function () {
                $scope.$parent.isPosting = false;

                var listingId = $scope.$parent.listing.id;
                ListingEditorService.navigateToListingRoute(listingId, true);
              },
              function () {
                $scope.saveError = true;
                ListingEditorService.setStatusToPostingError($scope);
              }
            );
          };

          $scope.saveListing = function () {
            if ($scope.isNew) {
              return self.createListing();
            } else {
              return self.saveListing();
            }
          };

          $scope.saveAndExit = function () {
            self.saveListing().then(function () {
              $location.url('/manager/listing');
            });
          };

          self.saveListing = function () {
            ListingEditorService.setStatusToSavingListing($scope);

            var listing = self.cleanListingForUpload($scope.$parent.listing);

            return listingApi
              .updateListing(listing.id, listing, $scope.application)
              .success(self.saveListingSuccess)
              .error(function () {
                $scope.saveError = true;
                ListingEditorService.setStatusToSavingError($scope);
              })
              .finally(function () {
                $scope.$parent.isSaving = false;
              });
          };

          self.createListing = function () {
            ListingEditorService.setStatusToSavingListing($scope);

            var listing = self.cleanListingForUpload($scope.$parent.listing);

            return listingApi
              .createListing(listing, $scope.application)
              .success(self.saveListingSuccess)
              .error(function () {
                $scope.saveError = true;
                ListingEditorService.setStatusToSavingError($scope);
              })
              .finally(function () {
                $scope.$parent.isSaving = false;
              });
          };

          self.saveListingSuccess = function (response) {
            $scope.$parent.listing = response.listing;
            $scope.saveSuccess = true;
            $scope.saveError = false;

            if ($scope.isNew) {
              ListingEditorService.navigateToEditListingRoute(
                $scope.$parent.listing.id
              );
            }
          };

          self.saveListingError = function () {
            $scope.saveError = true;
            $scope.saveSuccess = false;
          };

          self.cleanListingForUpload = function (listing) {
            listing.location.name = listing.location.address.street;

            if (listing.leasing.availableDate) {
              listing.leasing.availableDate = timeService
                .get(listing.leasing.availableDate)
                .startOf('day')
                .format();
            } else {
              listing.leasing.availableDate = timeService
                .now()
                .startOf('day')
                .format();
            }

            remove(listing.photos, { isLocal: true });

            return listing;
          };
        }
      ]
    };
  });
})();
