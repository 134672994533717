/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import remove from 'lodash/remove';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('managerPrescreening', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/preferences/manager-prescreening.html',
      scope: {
        managerRequirements: '='
      },
      controller: [
        '$scope',
        '$location',
        function ($scope, $location) {
          var self = this;

          $scope.path = $location.path();

          $scope.help = {
            phoneNumbers: 'Require renters to have verified phone numbers',
            petPolicy: 'Require pet ownership screening',
            monthlyIncome: 'Require minimum monthly income',
            criminalHistory: 'Require criminal history screening',
            evictionHistory: 'Require eviction history screening',
            moveDates: 'Require expected move-in date'
          };

          self._initialize = function () {
            $scope.minimumIncomeCheckbox =
              $scope.managerRequirements.income_multiplier > 0;
            $scope.minimumIncomeMultiplier = 2;
          };

          $scope.$watch('minimumIncomeCheckbox', function (isChecked) {
            var minimumSafeRequirement = Math.max(
              $scope.managerRequirements.income_multiplier,
              $scope.minimumIncomeMultiplier
            );
            $scope.managerRequirements.income_multiplier = isChecked
              ? minimumSafeRequirement
              : 0;
          });

          $scope.showNextAddCustomScreeningBox = function () {
            var customRequirement = {};
            customRequirement.isEditing = true;
            customRequirement.desired_answer = true;

            $scope.managerRequirements.custom_requirements.push(
              customRequirement
            );
          };

          $scope.removeCustomQuestion = function (custom) {
            remove($scope.managerRequirements.custom_requirements, custom);
          };

          self._initialize();
        }
      ]
    };
  });
})(window.angular);
