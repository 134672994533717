/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('autoExpand', function () {
    return {
      restrict: 'A',
      link: function (scope, element) {
        function update() {
          var scrollTop = window.pageYOffset,
            scrollLeft = window.pageXOffset;

          element.css('height', 'auto');
          var height = element[0].scrollHeight;

          if (height > 0) {
            element.css('height', height + 'px');
          }

          window.scrollTo(scrollLeft, scrollTop);
        }

        element.on('input propertychange', update);
      }
    };
  });
})();
