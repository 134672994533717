import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface BulkActionResident {
  id: number;
  name: string;
}

export interface SelectedResident {
  [key: number]: BulkActionResident;
}

export interface SelectedResidents {
  selectedResidents: SelectedResident;
  selectAll: boolean;
  selectAllIndeterminate: boolean;
}

export const initialSelectedResidents: SelectedResidents = {
  selectedResidents: {},
  selectAll: false,
  selectAllIndeterminate: false
};

export const selectedResidents = createSlice({
  name: 'selectedResidents',
  initialState: initialSelectedResidents,
  reducers: {
    addSelectedResident: (state, action: PayloadAction<BulkActionResident>) => {
      state.selectedResidents[action.payload.id] = action.payload;

      if (state.selectAll) {
        state.selectAllIndeterminate = true;
      }
    },
    removeSelectedResident: (
      state,
      action: PayloadAction<number | number[]>
    ) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((itemId) => {
          delete state.selectedResidents[itemId];
        });
      } else {
        delete state.selectedResidents[action.payload];
      }
      if (state.selectAll) {
        state.selectAllIndeterminate = true;
      }
    },
    selectAllResidents: (state, action: PayloadAction<SelectedResident>) => {
      state.selectedResidents = action.payload;
      state.selectAll = true;
      state.selectAllIndeterminate = false;
    },
    reset: (state) => {
      state.selectedResidents = {};
      state.selectAll = false;
      state.selectAllIndeterminate = false;
    }
  }
});

export const {
  addSelectedResident,
  removeSelectedResident,
  selectAllResidents,
  reset: resetSelectedResidents
} = selectedResidents.actions;

export default selectedResidents.reducer;
