/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import uniq from 'lodash/uniq';
import remove from 'lodash/remove';
import includes from 'lodash/includes';
import transform from 'lodash/transform';
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const unreadCountsService = function (
    $rootScope,
    apiBase,
    voiceService,
    userService
  ) {
    var self = this;

    self._unreadCounts = {};

    self.initialize = function () {
      if (!userService.getUser()) {
        return;
      }

      if (userService.getUser().type === 'manager') {
        return self._getTeamUnreadCounts().success(function (response) {
          var subscribedManagerIds = transform(
            response.unread_message_counts_by_manager_id,
            function (result, unreadCount, managerId) {
              if (unreadCount.show_in_sidebar) {
                result.push(parseInt(managerId));
              }

              return result;
            },
            []
          );

          voiceService.setSubscribedManagerIds(subscribedManagerIds);
          self._unreadCounts = response.unread_message_counts_by_manager_id;

          self._emitUpdate();
        });
      }
    };

    self._getTeamUnreadCounts = function () {
      return apiBase.get('/team/chats/unread_count');
    };

    self._emitUpdate = function () {
      $rootScope.$emit('updateTeamUnreadCounts', self._unreadCounts);
    };

    self.isUnread = function (conversationId, managerId) {
      return (
        includes(
          self._unreadCounts[managerId].unread_messages.prospect,
          conversationId
        ) ||
        includes(
          self._unreadCounts[managerId].unread_messages.resident,
          conversationId
        )
      );
    };

    self.onStreamRead = function (readReceipt) {
      var ownerUnreadStreams = self._unreadCounts[readReceipt.owner.id];
      var removed = remove(
        ownerUnreadStreams.unread_messages[readReceipt.stream_type],
        function (unreadStreamId) {
          return unreadStreamId === readReceipt.stream_id;
        }
      );

      if (removed.length > 0) {
        ownerUnreadStreams.unread_messages.total =
          ownerUnreadStreams.unread_messages.prospect.length +
          ownerUnreadStreams.unread_messages.resident.length;

        self._emitUpdate();
      }
    };

    self.markStreamAsUnread = function (managerId, streamType, streamId) {
      self.markStreamsAsUnread(managerId, streamType, [streamId]);
    };

    self.markStreamsAsUnread = function (managerId, streamType, streamIds) {
      var ownerUnreadStreams = self._unreadCounts[managerId];
      var originalCount = ownerUnreadStreams.unread_messages[streamType].length;

      ownerUnreadStreams.unread_messages[streamType] = uniq(
        ownerUnreadStreams.unread_messages[streamType].concat(streamIds)
      );

      if (
        ownerUnreadStreams.unread_messages[streamType].length !== originalCount
      ) {
        ownerUnreadStreams.unread_messages.total =
          ownerUnreadStreams.unread_messages.prospect.length +
          ownerUnreadStreams.unread_messages.resident.length;

        self._emitUpdate();
      }
    };

    self.markAllAsRead = function (managerId, streamType) {
      var ownerUnreadStreams = self._unreadCounts[managerId];
      ownerUnreadStreams.unread_messages[streamType] = [];

      ownerUnreadStreams.unread_messages.total =
        ownerUnreadStreams.unread_messages.prospect.length +
        ownerUnreadStreams.unread_messages.resident.length;

      self._emitUpdate();
    };

    self.getCachedUnreadCounts = function () {
      return self._unreadCounts;
    };

    return self;
  };
  unreadCountsService.$inject = [
    '$rootScope',
    'apiBase',
    'voiceService',
    'userService'
  ];

  app.factory('unreadCountsService', unreadCountsService);
})(window.angular);
