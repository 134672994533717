import React, { FC } from 'react';

import { ThemeProvider } from '@knockrentals/knock-shared-web';
import { PropertyHour } from '../LeasingBinder/models';
// import moment from 'moment';

const amenitiesLabel: { [key: string]: string } = {
  gym: 'Fitness center',
  pets: 'Pet friendly',
  pool: 'Pool'
};

export interface LeasingBrochureProps {}

export const LeasingBrochure: FC<LeasingBrochureProps> = () => {
  const property: any = {
    affordableHousing: {
      programs: [
        {
          available: false,
          name: 'Affordable house 1'
        },
        {
          available: false,
          name: 'Affordable house 2'
        }
      ]
    },
    amenities: {
      additional: [
        {
          available: false,
          name: 'Hardwood floor'
        },
        {
          available: false,
          name: 'Tile floor'
        },
        {
          available: false,
          name: 'Granite countertop'
        },
        {
          available: false,
          name: 'Fireplace'
        },
        {
          available: false,
          name: 'Vaulted ceiling'
        },
        {
          available: false,
          name: 'Jacuzzi'
        },
        {
          available: false,
          name: 'Sauna'
        },
        {
          available: false,
          name: 'Hot tub / Spa'
        },
        {
          available: false,
          name: 'Skylights'
        },
        {
          available: true,
          name: 'Non-Smoking Property'
        }
      ],
      exterior: [
        {
          available: false,
          name: 'Private pool'
        },
        {
          available: true,
          name: 'Balcony/patio'
        },
        {
          available: false,
          name: 'Deck'
        },
        {
          available: false,
          name: 'Yard'
        },
        {
          available: false,
          name: 'Yard-Fenced'
        },
        {
          available: true,
          name: 'Pond'
        },
        {
          available: false,
          name: 'Lake'
        },
        {
          available: false,
          name: 'Porch'
        },
        {
          available: true,
          name: 'Garden'
        },
        {
          available: false,
          name: 'Sprinkler system'
        },
        {
          available: true,
          name: 'Greenhouse'
        }
      ],
      heatingAndCooling: [
        {
          available: true,
          name: 'Heat: forced air'
        },
        {
          available: false,
          name: 'Central AC'
        },
        {
          available: false,
          name: 'Air conditioning'
        },
        {
          available: false,
          name: 'Ceiling fans'
        },
        {
          available: false,
          name: 'Double pane / Storm windows'
        }
      ],
      recreation: [
        {
          available: true,
          name: 'Shared pool'
        },
        {
          available: false,
          name: 'Fitness center'
        },
        {
          available: false,
          name: 'Clubhouse'
        },
        {
          available: true,
          name: 'Playground'
        },
        {
          available: false,
          name: 'Barbecue'
        },
        {
          available: false,
          name: 'Business center'
        },
        {
          available: true,
          name: 'Tennis court'
        },
        {
          available: true,
          name: 'Basketball court'
        },
        {
          available: false,
          name: 'Sports court'
        },
        {
          available: false,
          name: 'Golf course'
        },
        {
          available: false,
          name: 'Dock'
        }
      ],
      security: [
        {
          available: true,
          name: 'Elevator'
        },
        {
          available: false,
          name: 'Security system'
        },
        {
          available: true,
          name: 'Controlled access'
        },
        {
          available: true,
          name: 'Gated entry'
        },
        {
          available: false,
          name: 'Doorman'
        },
        {
          available: false,
          name: 'Disability access'
        }
      ],
      wiring: [
        {
          available: false,
          name: 'Cable-ready'
        },
        {
          available: false,
          name: 'High-speed internet'
        },
        {
          available: false,
          name: 'Wired'
        },
        {
          available: false,
          name: 'Intercom system'
        }
      ]
    },
    appliances: [
      {
        available: true,
        name: 'Dishwasher'
      },
      {
        available: true,
        name: 'Refrigerator'
      },
      {
        available: false,
        name: 'Garbage disposal'
      },
      {
        available: false,
        name: 'Microwave'
      },
      {
        available: true,
        name: 'Range/Oven'
      }
    ],
    costables: [
      {
        isCovered: false,
        name: 'Covered cost one'
      },
      {
        isCovered: false,
        name: 'Covered cost dos'
      }
    ],
    coverPhoto: {},
    customDetails: [],
    description: {
      short: 'Lovely small building in Seattle.'
    },
    doorway: {},
    editorType: 'manual',
    extra: {},
    featured_amenities: {
      gym: {
        types: [
          {
            available: true,
            name: 'Fitness Center'
          }
        ]
      },
      laundry: {
        types: [
          {
            available: true,
            name: 'In Unit'
          },
          {
            available: false,
            name: 'Shared'
          },
          {
            available: true,
            name: 'this is a really really really long description of a featured laundry amenity to say that you will love doing laundry at our community'
          }
        ]
      },
      parking: {
        types: [
          {
            available: false,
            name: 'Garage'
          },
          {
            available: false,
            name: 'Covered'
          },
          {
            available: false,
            name: 'Off-Street'
          },
          {
            available: false,
            name: 'On-Street'
          }
        ]
      },
      pets: {
        types: [
          {
            available: true,
            name: 'Small Dogs'
          },
          {
            available: true,
            name: 'Large Dogs'
          },
          {
            available: false,
            name: 'Cats'
          }
        ]
      },
      pool: {
        types: [
          {
            available: true,
            name: 'Private Pool'
          },
          {
            available: true,
            name: 'Shared Pool'
          }
        ]
      },
      special: {
        types: [
          {
            available: true,
            name: 'This Is A Really Really Really Long Description Of An Amenity To Say That Pets Are Are Are Allowed At This Property'
          }
        ]
      }
    },
    floorplan: {},
    floorplans: [],
    furnishing: [
      {
        available: false,
        name: 'Furnished'
      },
      {
        available: true,
        name: 'Unfurnished'
      }
    ],
    key_selling_points: {
      community: ['A very fun place!'],
      location: ['Close to Climate Pledge Arena', 'Walkable'],
      units: ['New construction']
    },
    laundry: {
      notes: 'No starch please',
      types: [
        {
          available: true,
          name: 'In Unit'
        },
        {
          available: false,
          name: 'Shared'
        }
      ]
    },
    leasing: {
      application: {
        fee: 'About 29.95',
        instructions:
          'This is an example of a really long application description that takes up a lot of the page and changes the length of the content within the columns.',
        link: 'https://www.microsoft.com'
      },
      provider: 'CustomURL',
      qualificationCriteria: "Don't be evil",
      terms: {
        breakPenalty: "None, we're nice like that",
        deposit: '475',
        includeUpcharges: false,
        leaseLengths: [
          {
            isAvailable: true,
            leaseLength: 'Month to month'
          },
          {
            isAvailable: true,
            leaseLength: '3',
            lengthUnit: 'months'
          },
          {
            isAvailable: true,
            leaseLength: '6',
            lengthUnit: 'months'
          },
          {
            isAvailable: true,
            leaseLength: '9',
            lengthUnit: 'months'
          },
          {
            isAvailable: true,
            leaseLength: '9+',
            lengthUnit: 'months'
          },
          {
            isAvailable: true,
            leaseLength: '12',
            lengthUnit: 'months'
          },
          {
            isAvailable: true,
            leaseLength: '12+',
            lengthUnit: 'months'
          }
        ],
        leasingSpecial: 'Blue Light',
        notes: 'Sample leasing notes'
      }
    },
    location: {
      address: {
        city: 'Seattle',
        house: '',
        neighborhood: 'Lower Queen Anne',
        raw: ' 123 Queen Ave N Seattle WA 98103',
        state: 'WA',
        street: '123 Queen Ave N',
        zip: '98103'
      },
      geo: {
        coordinates: [-122.3570761, 47.6193175],
        type: 'point'
      },
      name: 'Uptown Apartments',
      needs_review: false,
      numberOfUnits: 20,
      timezone: 'America/Los_Angeles',
      yearBuilt: 2013
    },
    notes: "You want to live uptown, it's the place to be.",
    parking: {
      notes: 'Safe place to park',
      types: [
        {
          available: false,
          name: 'Garage'
        },
        {
          available: true,
          name: 'Covered'
        },
        {
          available: true,
          name: 'Off-Street'
        },
        {
          available: false,
          name: 'On-Street'
        }
      ]
    },
    pets: {
      allowed: {
        cats: true,
        large_dogs: true,
        none: false,
        small_dogs: true
      },
      deposit: '10',
      fee: '25',
      notes: 'Note about pets',
      rent: '120'
    },
    photos: [
      {
        url: 'https://ccnp-knockphotos.s3.amazonaws.com/knock/community/246502/9e8aad9a4a6345af7b0e697727118e9c.png'
      },
      {
        url: 'https://ccnp-knockphotos.s3.amazonaws.com/knock/community/246502/9e8aad9a4a6345af7b0e697727118e9c.png'
      },
      {
        url: 'https://ccnp-knockphotos.s3.amazonaws.com/knock/community/246502/9e8aad9a4a6345af7b0e697727118e9c.png'
      },
      {
        url: 'https://ccnp-knockphotos.s3.amazonaws.com/knock/community/246502/9e8aad9a4a6345af7b0e697727118e9c.png'
      },
      {
        url: 'https://ccnp-knockphotos.s3.amazonaws.com/knock/community/246502/9e8aad9a4a6345af7b0e697727118e9c.png'
      },
      {
        url: 'https://ccnp-knockphotos.s3.amazonaws.com/knock/community/246502/9e8aad9a4a6345af7b0e697727118e9c.png'
      },
      {
        url: 'https://ccnp-knockphotos.s3.amazonaws.com/knock/community/246502/9e8aad9a4a6345af7b0e697727118e9c.png'
      },
      {
        url: 'https://ccnp-knockphotos.s3.amazonaws.com/knock/community/246502/9e8aad9a4a6345af7b0e697727118e9c.png'
      },
      {
        url: 'https://ccnp-knockphotos.s3.amazonaws.com/knock/community/246502/9e8aad9a4a6345af7b0e697727118e9c.png'
      },
      {
        url: 'https://ccnp-knockphotos.s3.amazonaws.com/knock/community/246502/9e8aad9a4a6345af7b0e697727118e9c.png'
      },
      {
        url: 'https://ccnp-knockphotos.s3.amazonaws.com/knock/community/246502/9e8aad9a4a6345af7b0e697727118e9c.png'
      }
      // url: 'https://ccnp-knockphotos.s3.amazonaws.com/knock/community/236187/6b1b8a1cea39df65deb61541142d4b93.jpg'
    ],
    property_custom_fees: [
      {
        amount: '$321',
        name: 'Some random charge that has a super long name to verify word wrapping is working as expected in the brochure'
      }
    ],
    property_forwarding_numbers: {
      forwarding_number: '+12066564285'
    },
    property_touring_hours: [
      {
        end_time: '17:00:00',
        start_time: '09:00:00',
        timezone: 'US/Pacific',
        weekday: 1
      },
      {
        end_time: '17:00:00',
        start_time: '09:00:00',
        timezone: 'US/Pacific',
        weekday: 2
      },
      {
        end_time: '17:00:00',
        start_time: '09:00:00',
        timezone: 'US/Pacific',
        weekday: 3
      },
      {
        end_time: '17:00:00',
        start_time: '09:00:00',
        timezone: 'US/Pacific',
        weekday: 4
      },
      {
        end_time: '17:00:00',
        start_time: '09:00:00',
        timezone: 'US/Pacific',
        weekday: 5
      },
      {
        end_time: '17:00:00',
        start_time: '09:00:00',
        timezone: 'US/Pacific',
        weekday: 6
      },
      {
        end_time: '17:00:00',
        start_time: '09:00:00',
        timezone: 'US/Pacific',
        weekday: 7
      }
    ],
    social: {
      facebook: '',
      knock_email: 'uptown@stage.knck.io',
      shortlink: 'https://stage.knockrentals.com/community/f11edac50947027b',
      website: 'https://google.com'
    },
    utilities: {
      estimatedCost: '199.99',
      types: [
        {
          included: true,
          name: 'Water'
        },
        {
          included: true,
          name: 'Sewage'
        },
        {
          included: true,
          name: 'Garbage'
        },
        {
          included: true,
          name: 'Some utility which is included that has a long enough value that it will wrap around in its column, which we want to confirm is handled correctly in the brochure PDF.'
        },
        {
          included: false,
          name: 'Electricity'
        },
        {
          included: false,
          name: 'Cable'
        },
        {
          included: false,
          name: 'Internet'
        },
        {
          included: true,
          name: 'Shopping, Award-Winning Schools, Local Museums And Parks Are All Close At Hand. Located Just Minutes From Major Employers Like Google, Apple And Dell'
        }
      ]
    },
    videos: [
      {
        thumb_url: '',
        url: ''
      }
    ],
    views: [
      {
        available: false,
        name: 'City'
      },
      {
        available: false,
        name: 'Mountain'
      },
      {
        available: false,
        name: 'Water'
      },
      {
        available: false,
        name: 'Park'
      }
    ],
    website: {
      primaryColor: '#00A8BC'
    }
  };

  // const cityStateZip = getCityStateZip();
  const companyLogoURL =
    'https://www.knockcrm.com/assets/images/logos/highmark_blue.png';
  const featuredAmenities = new Array<string>();

  Object.keys(property.featured_amenities).forEach(
    (key: string, index: number) => {
      let amenityLabel: string;

      for (const amenity of property.featured_amenities[key].types) {
        if (amenity.available) {
          amenityLabel = amenitiesLabel[key]
            ? amenitiesLabel[key]
            : `${amenity.name.toLowerCase()} ${key}`;

          if (featuredAmenities.indexOf(amenityLabel) === -1) {
            featuredAmenities.push(amenityLabel);
          }
        }
      }
    }
  );

  featuredAmenities.push(...featuredAmenities);

  const touringHours = property.property_touring_hours.sort(
    (lValue: PropertyHour, rValue: PropertyHour) => {
      return lValue.weekday - rValue.weekday;
    }
  );

  // Since the property hours data could include invalid days and duplicate days, we filter those out to present a nicer UI
  const hoursMap: { [key: string]: PropertyHour } = {};

  touringHours.forEach((propertyHour: PropertyHour) => {
    if (
      propertyHour.weekday >= 1 &&
      propertyHour.weekday <= 7 &&
      !hoursMap[propertyHour.weekday]
    ) {
      hoursMap[propertyHour.weekday] = propertyHour;
    }
  });

  const validPropertyHours = Object.keys(hoursMap).map(
    (key: string) => hoursMap[key]
  );

  const allowedPets = Object.keys(property.pets.allowed).filter(
    (item: string) => property.pets.allowed[item]
  );

  allowedPets.push(...allowedPets);

  const includedUtilities = property.utilities.types.filter(
    (item: any) => item.included
  );

  includedUtilities.push(...includedUtilities);

  // const getCityStateZip = () => {
  //   let cityStateZip = '';
  //
  //   if (
  //     property.location.address.city ||
  //     property.location.address.state ||
  //     property.location.address.zip
  //   ) {
  //     if (property.location.address.city.trim()) {
  //       cityStateZip += property.location.address.city.trim();
  //     }
  //
  //     if (property.location.address.state.trim()) {
  //       cityStateZip += ', ' + property.location.address.state.trim();
  //     }
  //
  //     if (property.location.address.zip.trim()) {
  //       cityStateZip += ' ' + property.location.address.zip.trim();
  //     }
  //   }
  //
  //   return cityStateZip.trim();
  // };

  // const getFormattedWebsite = () => {
  //   let formattedWebsite = property.social.website.replace(/https?:\/\//, '');
  //
  //   if (formattedWebsite.endsWith('/')) {
  //     formattedWebsite = formattedWebsite.slice(0, formattedWebsite.length - 1);
  //   }
  //
  //   return formattedWebsite;
  // };
  //
  // const getFormattedPhoneNumber = (phoneNumber: string) => {
  //   let formattedNumber = phoneNumber.trim();
  //
  //   if (phoneNumber.startsWith('+')) {
  //     formattedNumber = phoneNumber.slice(2);
  //   }
  //
  //   if (formattedNumber.length === 10) {
  //     formattedNumber = `(${formattedNumber.slice(
  //       0,
  //       3
  //     )}) ${formattedNumber.slice(3, 6)} - ${formattedNumber.slice(6)}`;
  //   }
  //
  //   return formattedNumber;
  // };

  // const getWeekdayName = (weekday: number) => {
  //   let weekdayName: string;
  //
  //   switch (weekday) {
  //     case 1:
  //       weekdayName = 'Mon';
  //       break;
  //     case 2:
  //       weekdayName = 'Tues';
  //       break;
  //     case 3:
  //       weekdayName = 'Wed';
  //       break;
  //     case 4:
  //       weekdayName = 'Thurs';
  //       break;
  //     case 5:
  //       weekdayName = 'Fri';
  //       break;
  //     case 6:
  //       weekdayName = 'Sat';
  //       break;
  //     case 7:
  //       weekdayName = 'Sun';
  //       break;
  //     default:
  //       weekdayName = '';
  //       break;
  //   }
  //
  //   return weekdayName;
  // };
  //
  // const getFormattedTime = (timeValue: string) => {
  //   const time = moment(timeValue, 'HH:mm:ss');
  //   const hours = time.hours();
  //   let amOrPM = hours >= 12 ? ' pm' : ' am';
  //
  //   return `${hours % 12 || 12}:${time
  //     .minutes()
  //     .toString()
  //     .padStart(2, '0')}${amOrPM}`;
  // };

  return (
    <ThemeProvider>
      <div id="leasing-brochure">
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap"
          rel="stylesheet"
        />

        <style>
          {`
            .viewport-container {
              position: unset !important;
            }
  
            .viewport-content {
              height: 100% !important;
            }

            html, body {
              margin: 0;
              padding: 0;
            }
  
            @page {
              size: A4;
              margin: 0 0 3mm 0 !important;
            }

            div {
              font-size: 10px;
            }

            .brochure-h5, div {
              color: #212121;
              font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
              margin: 0;
            }
  
            .brochure-h5 {
              font-size: 24px;
              font-weight: 600;
              line-height: 1.1;
              letter-spacing: 0.18px;
            }

            .brochure-main-container {
              display: flex;
              flex-direction: column;
            }
  
            .brochure-accent-bar {
              background-color: #00A8BC; /* ACCENT-COLOR-TOKEN */
              height: 16px;
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              print-color-adjust: exact;
              -webkit-print-color-adjust: exact;
            }

            .brochure-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 28px;
              margin-top: 34px;
            }
  
            .brochure-application-button {
              color: #FFF !important;
              background-color: #4A61E8;
              border-color: transparent;
              padding: 4px 12px;
              font-size: 13px;
              min-width: 64px;
              box-sizing: border-box;
              font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
              font-weight: 600;
              line-height: 22px;
              border-radius: 4px;
              letter-spacing: 0.4px;
              text-transform: unset;
              text-decoration: none !important;
            }

            .brochure-content {
              padding-left: 46px;
              padding-right: 46px;
              display: flex;
              flex-direction: column;
              margin-top: 8px;
            }
  
            .brochure-columns {
              columns: 2;
              column-gap: 5%;
              column-fill: balance;
            }
  
            .brochure-section {
              margin-bottom: 20px;
              break-inside: avoid;
            }
  
            .brochure-section:last-child {
              margin-bottom: 0;
            }

            .brochure-section-heading {
              font-weight: 600;
              line-height: 10px;
              letter-spacing: 1.2px;
              text-align: left;
              margin-bottom: 12px;
            }
  
            .brochure-subsection {
              margin-bottom: 12px;
            }
  
            .brochure-subsection-heading {
              color: #999999;
              font-weight: 400;
              line-height: 10px;
              letter-spacing: 0.4px;
              text-align: left;
              margin-bottom: 2px;
            }
  
            .brochure-subsection-content {
              font-weight: 400;
              line-height: 14px;
              letter-spacing: 0.4px;
              text-align: left;
              word-wrap: break-word;
            }
  
            .brochure-desc {
              margin-top: 10px;
            }
  
            .brochure-desc-small-space {
              margin-top: 4px;
            }
  
            .brochure-chip {
              color: #212121;
              background-color: #00A8BC0C; /* ACCENT-COLOR-TOKEN */
              border: none;
              cursor: default;
              display: inline-flex;
              outline: 0;
              padding: 0;
              box-sizing: border-box;
              align-items: center;
              border-radius: 16px;
              vertical-align: middle;
              justify-content: center;
              text-decoration: none;
              margin-right: 8px;
              margin-bottom: 8px;
              text-transform: capitalize;
              white-space: pre-wrap;
            }

            .brochure-chip span {
              padding: 3px 8px 3px 8px;
            }
  
            .brochure-chip:last-child {
              margin-right: 0;
            }
  
            .brochure-hours {
              display: flex;
            }
  
            .brochure-weekday-name {
              color: #999999;
              width: 60px;
            }
  
            .brochure-additional-page {
              margin-top: 34px;
            }
  
            .brochure-photo {
              margin-bottom: 16px;
              margin-right: 16px;
              max-height: 250px;
              vertical-align: middle;
            }
  
            .brochure-footer {
              display: flex;
              justify-content: center;
              position: fixed;
              bottom: 0;
              top: 0;
              left: 25%;
              right: 25%;
              margin-bottom: 24px;
            }

            .brochure-page-break-before {
              break-before: page;
            }

            .brochure-logo {
              margin-top: auto;
              max-height: 40px;
            }

            @media print {
              .brochure-application-button {
                background-color: #4A61E8 !important;
                print-color-adjust: exact;
                -webkit-print-color-adjust: exact;
              }

              .brochure-application-button span {
                color: #FFF !important;
              }

              .brochure-subsection-heading, .brochure-weekday-name {
                color: #999999 !important;
                print-color-adjust: exact;
                -webkit-print-color-adjust: exact;
              }

              .brochure-chip {
                background-color: #00A8BC0C !important; /* ACCENT-COLOR-TOKEN */
                print-color-adjust: exact;
                -webkit-print-color-adjust: exact;
              }

              .brochure-page-break-before {
                break-before: page;
              }

              a[href]:after {
                content: none !important;
              }
            }
          `}
        </style>

        <div className="brochure-main-container">
          <div className="brochure-content">
            <div className="brochure-header">
              <h5 className="brochure-h5">PROPERTY-NAME-TOKEN</h5>

              <a
                className="brochure-application-button"
                href="https://google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Apply Now</span>
              </a>
            </div>

            <div className="brochure-columns">
              <div className="brochure-section">
                <div className="brochure-section-heading">CONTACT</div>

                <div className="brochure-subsection">
                  <div className="brochure-subsection-heading">Website</div>
                  <div className="brochure-subsection-content">
                    WEBSITE-HOST-TOKEN
                  </div>
                </div>

                <div className="brochure-subsection">
                  <div className="brochure-subsection-heading">
                    Phone Number
                  </div>
                  <div className="brochure-subsection-content">
                    FORWARDING-PHONE-NUMBER-TOKEN
                  </div>
                </div>

                <div className="brochure-subsection">
                  <div className="brochure-subsection-heading">Address</div>
                  <div className="brochure-subsection-content">
                    ADDRESS-LINE-1-TOKEN
                  </div>
                  <div className="brochure-subsection-content">
                    ADDRESS-LINE-2-TOKEN
                  </div>
                </div>
              </div>

              <div className="brochure-section">
                <div className="brochure-section-heading">APPLICATION</div>

                <div className="brochure-subsection">
                  <div className="brochure-subsection-heading">Link</div>
                  <div className="brochure-subsection-content">
                    APPLICATION-LINK-TOKEN
                  </div>

                  <div className="brochure-desc">
                    This is an example of a really long application description
                    that takes up a lot of the page and changes the length of
                    the content within the columns.
                  </div>
                </div>
              </div>

              <div className="brochure-section">
                <div className="brochure-section-heading">TOURING HOURS</div>

                {validPropertyHours.map(
                  (_hours: PropertyHour, index: number) => (
                    <div key={index} className="brochure-hours">
                      <div className="brochure-weekday-name">Thurs</div>

                      <div>10:00 am - 6:00 pm</div>
                    </div>
                  )
                )}
              </div>

              <div className="brochure-section">
                <div className="brochure-section-heading">AMENITIES</div>

                <div className="brochure-subsection">
                  {featuredAmenities.map((item: string, index: number) => {
                    return (
                      <div key={index} className="brochure-chip">
                        <span>{item}</span>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="brochure-section">
                <div className="brochure-section-heading">PETS</div>

                <div>
                  {allowedPets.length === 0 && (
                    <div className="brochure-desc-small-space">
                      No Pets Allowed
                    </div>
                  )}

                  {allowedPets.length > 0 &&
                    allowedPets.map((item: string, index: number) => {
                      return (
                        <div key={index} className="brochure-chip">
                          <span>PET-AMENITY-NAME-TOKEN</span>
                        </div>
                      );
                    })}
                </div>

                <div className="brochure-desc-small-space">PET-NOTES-TOKEN</div>
              </div>

              <div className="brochure-section">
                <div className="brochure-section-heading">UTILITIES</div>

                <div>
                  {includedUtilities.length === 0 && (
                    <div className="brochure-desc-small-space">
                      No Utilities Included
                    </div>
                  )}

                  {includedUtilities.map((item: any, index: number) => {
                    return (
                      <div key={index} className="brochure-chip">
                        <span>{item.name}</span>
                      </div>
                    );
                  })}
                </div>

                <div className="brochure-desc-small-space">
                  UTILITY-ESTIMATE-TOKEN
                </div>
              </div>

              <div className="brochure-section">
                <div className="brochure-section-heading">FEES</div>

                <div className="brochure-subsection">
                  <div className="brochure-subsection-heading">
                    Application Fee
                  </div>
                  <div className="brochure-subsection-content">
                    APPLICATION-FEE-TOKEN
                  </div>
                </div>

                <div className="brochure-subsection">
                  <div className="brochure-subsection-heading">
                    Security Deposit
                  </div>
                  <div className="brochure-subsection-content">
                    SECURITY-DEPOSIT-TOKEN
                  </div>
                </div>

                {property.property_custom_fees.map(
                  (item: any, index: number) => {
                    return (
                      <div key={index} className="brochure-subsection">
                        <div className="brochure-subsection-heading">
                          CUSTOM-FEE-NAME-TOKEN
                        </div>
                        <div className="brochure-subsection-content">
                          CUSTOM-FEE-AMOUNT-TOKEN
                        </div>
                      </div>
                    );
                  }
                )}

                <div className="brochure-subsection">
                  <div className="brochure-subsection-heading">Pet Deposit</div>
                  <div className="brochure-subsection-content">
                    PET-DEPOSIT-AMOUNT-TOKEN
                  </div>
                </div>

                <div className="brochure-subsection">
                  <div className="brochure-subsection-heading">
                    Pet Fee (Non Refundable)
                  </div>
                  <div className="brochure-subsection-content">
                    PET-FEE-AMOUNT-TOKEN
                  </div>
                </div>

                <div className="brochure-subsection">
                  <div className="brochure-subsection-heading">Pet Rent</div>
                  <div className="brochure-subsection-content">
                    PET-RENT-AMOUNT-TOKEN
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="brochure-main-container brochure-page-break-before">
          <div className="brochure-content">
            <div className="brochure-additional-page">
              <div className="brochure-section-heading">PHOTOS</div>

              <div>
                {property.photos.slice(0, 8).map((item: any, index: number) => {
                  return (
                    <img
                      key={index}
                      src={item.url}
                      alt="View of property"
                      className="brochure-photo"
                    />
                  );
                })}
              </div>
            </div>
          </div>

          {property.photos.length > 8 && (
            <div className="brochure-main-container brochure-page-break-before">
              <div className="brochure-content">
                <div className="brochure-additional-page">
                  <div className="brochure-section-heading">PHOTOS</div>

                  <div>
                    {property.photos
                      .slice(8)
                      .map((item: any, index: number) => {
                        return (
                          <img
                            key={index}
                            src={item.url}
                            alt="View of property"
                            className="brochure-photo"
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="brochure-accent-bar" />
        <div className="brochure-footer">
          <img
            src={companyLogoURL}
            alt="Company logo"
            className="brochure-logo"
          />
        </div>
      </div>
    </ThemeProvider>
  );
};
