/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.filter('address', [
    '$interpolate',
    function ($interpolate) {
      return function (addressObject) {
        var addressTemplate = $interpolate(
          '{{street}}, {{city}} {{state}} {{zip}}'
        );

        return addressTemplate(addressObject);
      };
    }
  ]);
})(window.angular);
