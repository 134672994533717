/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Conversations');

  app.directive('conversationDetails', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/conversation-details.html',
      controller: 'ConversationDetailsController',
      replace: true,
      scope: {
        conversation: '='
      }
    };
  });

  app.controller('ConversationDetailsController', [
    '$rootScope',
    '$scope',
    '$q',
    'conversationsService',
    'userInteractionsService',
    'prospectHistoryService',
    'prospectStatusMap',
    'assignPropertyModalService',
    'streamRepositoryService',
    'unsubscribeHelper',
    'userService',
    'prospectsApi',
    function (
      $rootScope,
      $scope,
      $q,
      conversationsService,
      userInteractionsService,
      prospectHistoryService,
      prospectStatusMap,
      assignPropertyModalService,
      streamRepositoryService,
      unsubscribeHelper,
      userService,
      prospectsApi
    ) {
      var self = this;
      var eventHandlers = [];

      $scope.currentUser = userService.getScopedUser();

      self.addEventHandler = function (event, handler) {
        eventHandlers.push($rootScope.$on(event, handler));
      };

      const emailUnsubscribeStatusChangedDestroy = $rootScope.$on(
        conversationsService.events.emailUnsubscribeStatusChanged,
        function (event, payload) {
          self._handleEmailUnsubscribeStatusChanged(payload);
        }
      );

      $scope.$on('$destroy', function () {
        forEach(eventHandlers, function (destroy) {
          destroy();
        });
        emailUnsubscribeStatusChangedDestroy();
      });

      $scope.$watch(
        'conversation',
        function (newConversation, oldConversation) {
          if (newConversation && oldConversation) {
            self._initialize();
          }
        }
      );

      self.addEventHandler(
        prospectHistoryService.events.prospectUpdated,
        function (event, prospect) {
          if ($scope.data.prospect && $scope.data.prospect.id === prospect.id) {
            $scope.data.prospect = prospect;
            $scope.data.otherUser.info.first_name = prospect.profile.first_name;
            $scope.data.otherUser.info.last_name = prospect.profile.last_name;
            $scope.data.listing = prospect.listing;

            if (prospect.profile.phone) {
              $scope.data.otherUser.info.phone = prospect.profile.phone;
              $scope.data.userInfo.phone = prospect.profile.phone;
            } else {
              $scope.data.otherUser.info.phone = null;
              $scope.data.userInfo.phone = null;
            }

            $scope.data.userInfo.email = prospect.profile.email;

            self._updateCommunity();
          }
        }
      );

      self.addEventHandler(
        prospectHistoryService.events.residentUpdated,
        function (event, resident) {
          if ($scope.data.resident && $scope.data.resident.id === resident.id) {
            assign($scope.data.resident, resident);

            $scope.data.otherUser.info = resident.profile;
            $scope.data.userInfo.profile = resident.profile;
          }
        }
      );

      self.addEventHandler('reloadProspectDetails', function () {
        if ($scope.data.prospect && $scope.data.prospect.id) {
          self._initialize();
        }
      });

      self.addEventHandler('reloadResidentDetails', function () {
        if ($scope.data.resident && $scope.data.resident.id) {
          self._initialize();
        }
      });

      $scope.data = {
        listing: null,
        prospect: null,
        resident: null,
        userInfo: null,
        connectedProfiles: []
      };

      $scope.updateProspect = function () {
        prospectHistoryService.updateProspect($scope.data.prospect);
      };

      $scope.openAssignPropertyModal = function () {
        assignPropertyModalService.openModal($scope.data.prospect);
      };

      self._initialize = function () {
        $scope.data.isLoaded = false;
        $scope.data.otherUser = conversationsService.getOtherUser(
          $scope.conversation
        );

        var initPromises = [];

        if ($scope.conversation.listing_id) {
          initPromises.push(self._getListing());
        } else {
          $scope.data.listing = null;
        }

        if ($scope.data.otherUser.type === 'prospect') {
          initPromises.push(self._getProspect());

          if (!$scope.data.listingsByManager) {
            initPromises.push(self._getListings());
          }

          if (!$scope.data.communities) {
            initPromises.push(self._getCommunities());
          }
        }

        if ($scope.data.otherUser.type === 'resident') {
          initPromises.push(self._getResident());
        }

        $q.all(initPromises).finally(self._loadFinished);
      };

      self._loadFinished = function () {
        $scope.data.isLoaded = true;
        self._updateCommunity();
      };

      self._getListing = function () {
        var listingId = $scope.conversation.listing_id;

        return userInteractionsService
          .getListing(listingId)
          .success(self._getListingSuccess);
      };

      self._getListingSuccess = function (response) {
        $scope.data.listing = response.listing;
      };

      self._getListings = function () {
        return userInteractionsService
          .getListings()
          .success(self._getListingsSuccess);
      };

      self._getListingsSuccess = function (response) {
        $scope.data.listingsByManager = response.listings;
        $scope.data.listingsByPropertyId = self._reduceListings(response);
      };

      self._getConnectedProfiles = async (prospectId) => {
        let profiles = [];

        const { data } = await prospectsApi.getConnectedProfiles(prospectId);

        profiles = data.connected_profiles.map((c) => ({
          communityName: c.community_name,
          guestcardUrl: c.guestcard_url,
          lastActive: c.last_active,
          personType: c.person_type,
          status: c.status,
          statusLabel: c.status_label,
          todoStatus: c.todo_status,
          personId: c.person_id
        }));
        $scope.data.connectedProfiles = profiles;
        $rootScope.$emit('prospect-connected-profiles', profiles);
      };

      self._reduceListings = function (response) {
        return reduce(
          response.listings,
          function (result, listingsByManager) {
            forEach(listingsByManager.listings, function (listing) {
              result[listing.id] = listing;
            });

            return result;
          },
          {}
        );
      };

      self._getCommunities = function () {
        return userInteractionsService
          .getCommunities()
          .success(self._getCommunitiesSuccess);
      };

      self._getCommunitiesSuccess = function (response) {
        $scope.data.communities = response.communities;
      };

      self._updateCommunity = function () {
        if (
          $scope.data != null &&
          $scope.data.prospect != null &&
          $scope.data.prospect.community_id
        ) {
          $scope.data.community = find($scope.data.communities, {
            id: $scope.data.prospect.community_id
          });
        } else {
          $scope.data.community = null;
        }
      };

      self._getRenterInfo = function () {
        return userInteractionsService
          .getRenterInfo($scope.data.otherUser.id)
          .success(self._getRenterInfoSuccess);
      };

      self._getRenterInfoSuccess = function (response) {
        $scope.data.userInfo = response.renter_info;
      };

      self._getProspect = function () {
        $scope.prospectId = null;
        return streamRepositoryService
          .getProspect($scope.data.otherUser.id, true)
          .success(self._getProspectSuccess);
      };

      $scope.prospectId = null;
      self._getProspectSuccess = function (response) {
        $scope.data.prospect = response.prospect;
        $scope.data.userInfo = $scope.data.prospect;
        $scope.prospectId = $scope.data.prospect.id;
        self._getConnectedProfiles($scope.prospectId);
        self._getPossibleProspectStatuses($scope.data.prospect.status);
      };

      self._getPossibleProspectStatuses = function (prospectStatus) {
        return userInteractionsService
          .getProspectStatusTransitions(prospectStatus)
          .success(self._getPossibleProspectStatusesSuccess);
      };

      self._getPossibleProspectStatusesSuccess = function (response) {
        $scope.data.prospectStatuses = response.statuses;
      };

      self._getResident = function () {
        return streamRepositoryService
          .getResident($scope.data.otherUser.id, true)
          .success(self._getResidentSuccess);
      };

      self._getResidentSuccess = function (response) {
        $scope.data.resident = response.resident;
        $scope.data.userInfo = $scope.data.resident;
      };

      self._handleEmailUnsubscribeStatusChanged = function (payload) {
        // Handle pusher update
        const { stream_id, reason, opted_out } = payload;

        // If this payload applies to current stream, we can update the stream unsubscribe status.
        if ($scope.conversation.id === stream_id) {
          unsubscribeHelper.setUnsubscribeStatusForStream($scope.conversation, {
            reason,
            opted_out
          });
        }
      };
    }
  ]);
})(window.angular);
