/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('listingResult', function () {
    return {
      restrict: 'E',
      scope: {
        listing: '=',
        iconText: '@iconText',
        hideIconText: '=?'
      },
      templateUrl: '/angular/views/directives/listing/listingResult.html',
      controller: 'ListingResultController',
      controllerAs: 'listingResultCtrl'
    };
  });

  app.controller('ListingResultController', [
    '$scope',
    '$filter',
    'timeService',
    function ($scope, $filter, timeService) {
      var noFractionCurrencyFilter = $filter('noFractionCurrency');
      var bedroomFilter = $filter('bedrooms');
      var bathroomFilter = $filter('bathrooms');
      var dateFilter = $filter('date');
      var squareFeetFilter = $filter('squareFeet');

      $scope.monthlyPrice = noFractionCurrencyFilter(
        $scope.listing.leasing.monthlyRent
      );
      $scope.bedrooms = bedroomFilter($scope.listing.floorplan.bedrooms);
      $scope.bathrooms = bathroomFilter($scope.listing.floorplan.bathrooms);
      $scope.size = squareFeetFilter($scope.listing.floorplan.size);
      $scope.address = [
        $scope.listing.location.address.house,
        $scope.listing.location.address.street
      ].join(' ');

      try {
        if ($scope.listing.coverPhoto) {
          $scope.bannerImage = $scope.listing.coverPhoto.thumbUrl;
        } else if ($scope.listing.photos.length > 0) {
          $scope.bannerImage = $scope.listing.photos[0].thumbUrl;
        } else {
          $scope.bannerImage = '/images/no-image.png';
        }
      } catch (e) {
        $scope.bannerImage = '/images/no-image.png';
      }

      $scope.city = $scope.listing.location.address.city;
      $scope.state = $scope.listing.location.address.state;
      $scope.zip = $scope.listing.location.address.zip;

      if (!$scope.listing.leasing.availableDate) {
        // jshint ignore: line
        $scope.availableDate = 'Today';
      } else {
        var available = timeService.get($scope.listing.leasing.availableDate); // jshint ignore: line

        if (timeService.now().isAfter(available)) {
          $scope.availableDate = 'Today';
        } else {
          $scope.availableDate = dateFilter(available.format(), 'MMM dd');
        }
      }

      $scope.iconText = $scope.iconText || 'Schedule Now';
    }
  ]);
})();
