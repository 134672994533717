import React, { FC } from 'react';

import { CircularProgress, Alert } from '@knockrentals/knock-shared-web';
import NearbyCommunitiesListItem from './NearbyCommunitiesListItem';

import { useNearbyCommunities } from './useNearbyCommunities';

/**
 * Consumes useNearbyCommunities to render a list of nearby
 * communities (using NearbyCommunitiesListItem).
 *
 * Handles loading and error states from fetching the nearby communities list.
 * - Loading will show a loader
 * - Error will show the error
 * - If there were no communities found, it will show "No nearby communities".
 */
const NearbyCommunitiesList: FC<any> = (_) => {
  const {
    nearbyCommunities,
    fetchNearbyCommunitiesError,
    isFetchingNearbyCommunities,
    triggerReferralFlow,
    setIsConnectedProfilesDialogActive,
    shouldShowConnectedProfiles
  } = useNearbyCommunities();

  if (isFetchingNearbyCommunities) {
    return <CircularProgress />;
  } else if (fetchNearbyCommunitiesError) {
    return (
      <Alert severity="error">
        Could not fetch nearby communities: {fetchNearbyCommunitiesError}
      </Alert>
    );
  }

  if (!nearbyCommunities || nearbyCommunities.length === 0) {
    return (
      <Alert severity="info">
        No nearby communities for this property. To configure nearby
        communities, visit the Admin page.
      </Alert>
    );
  }

  // Happy path; communities are loaded
  return (
    <div>
      {nearbyCommunities.map((community) => (
        <NearbyCommunitiesListItem
          key={community.property.id}
          community={community}
          setIsConnectedProfilesDialogActive={
            setIsConnectedProfilesDialogActive
          }
          shouldShowConnectedProfiles={shouldShowConnectedProfiles}
          triggerReferral={triggerReferralFlow}
        />
      ))}
    </div>
  );
};

export default NearbyCommunitiesList;
