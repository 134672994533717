export enum ProspectActions {
  Stack,
  EMail,
  Text,
  FollowUp,
  ChangeOwner,
  MarkAsLost,
  Export,
  SMSOptIn,
  MarkAsNotAProspect
}
