import React from 'react';

export const ReleaseNotesIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.375 3.87415H3.875C3.18464 3.87415 2.625 4.43379 2.625 5.12415V20.1241C2.625 20.8145 3.18464 21.3741 3.875 21.3741H20.125C20.8154 21.3741 21.375 20.8145 21.375 20.1241V5.12415C21.375 4.43379 20.8154 3.87415 20.125 3.87415H11.375M6.375 17.6241H15.125M6.375 13.8741H17.625M17.625 10.1241H13.875M11.375 11.3741L8.875 9.49998L6.375 11.375V3.24998C6.37478 3.08407 6.44053 2.92489 6.55776 2.8075C6.675 2.69011 6.8341 2.62415 7 2.62415H10.75C11.0952 2.62415 11.375 2.90397 11.375 3.24915V11.3741Z"
      stroke="#5C5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
