export interface CompanyQuickReply {
  company_id: number;
  id: number;
  message?: string;
  message_sms?: string;
  title: string;
}

export interface QuickReply {
  created_time?: Date;
  manager_id: number;
  manager_name: string;
  message: string;
  message_sms: string;
  message_to_display?: string;
  message_id?: string;
  photo?: string;
  title: string;
}

export interface ManagerQuickReply {
  manager_id: number;
  messages: QuickReply[];
}

export interface ManagerInfo {
  id: number;
  name: string;
}

export type AvailableQuickReply = Partial<QuickReply> &
  Partial<CompanyQuickReply>;

export enum QUICK_REPLY_TYPES {
  COMPANY_QUICK_REPLIES = 'companyQuickReplies',
  PROPERTY_QUICK_REPLIES = 'quickReplies'
}
export interface AvailableQuickReplies {
  [QUICK_REPLY_TYPES.COMPANY_QUICK_REPLIES]: AvailableQuickReply[];
  [QUICK_REPLY_TYPES.PROPERTY_QUICK_REPLIES]: AvailableQuickReply[];
}
