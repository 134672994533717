import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
  DialogActions,
  Select,
  FormControl,
  MenuItem,
  Input,
  Typography,
  Snackbar,
  IconButton,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import {
  makeStyles,
  NamedColors,
  Box,
  Button,
  white
} from '@knockrentals/knock-shared-web';
import { CircularProgressButton } from '../../CircularProgressButton';
import { colors, useCommonStyles } from '../../commonStyles/commonStyles';
import { BulkAction } from '../../../../../app/features/prospects/selectedProspects';
import { ProspectExclusionData } from '../../../../../app/services/prospects/entities';
import TooltipContainer from '../../molecules/TooltipContainer/TooltipContainer';
import { InfoIcon } from '../../icons';

const useStyles = makeStyles(() => ({
  dialog: {
    width: '600px'
  },

  dialogTitle: {
    padding: '16px 16px 0 16px'
  },

  dialogContent: {
    padding: '0 16px 0 16px'
  },

  formControl: {
    marginBottom: 0,

    '& .MuiInputBase-formControl': {
      marginTop: '0 !important'
    },

    '& .MuiInputBase-root': {
      borderBottom: 'none',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      background: white,
      margin: 0,
      padding: 0
    }
  },

  chipContainer: {
    borderBottom: `1px solid ${NamedColors.slate[300]}`,
    marginTop: '20px',
    paddingBottom: '4px'
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden'
  },

  chip: {
    backgroundColor: colors.chipBackground,
    color: colors.defaultText,
    fontSize: '13px',
    marginRight: '8px',

    '&:last-child': {
      marginRight: 0
    },

    '& .MuiChip-labelSmall': {
      paddingLeft: '9px',
      paddingRight: '9px'
    }
  },

  description: {
    marginBottom: '12px',
    marginTop: '16px',
    letterSpacing: '0.5px'
  },

  excludedReasons: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 'unset',
      marginRight: 'unset'
    }
  },

  excludedReasonLabel: {
    width: 'fit-content'
  },

  duplicateOrErrorOption: {
    alignItems: 'center',
    display: 'inline-flex'
  },

  duplicateOrErrorTooltipIcon: {
    marginLeft: '12px'
  },

  dialogActions: {
    alignItems: 'unset',
    gap: '8px',
    padding: '12px 16px 16px 16px'
  },

  cancelButton: {
    border: `1px solid ${NamedColors.denim[600]}`,
    color: NamedColors.denim[600],
    lineHeight: '24px'
  },

  alert: {
    backgroundColor: colors.snackbarBackground
  },

  alertIcon: {
    marginRight: '10px'
  }
}));

interface MarkAsNotAProspectModalProps {
  open: boolean;
  closeModal: (refetch?: boolean) => void;
  records: BulkAction[];
  setNotAProspect: (payload: ProspectExclusionData) => Promise<any>;
  hideNameChips?: boolean;
}

const MarkAsNotAProspectModal: FC<MarkAsNotAProspectModalProps> = ({
  open,
  closeModal,
  records,
  setNotAProspect,
  hideNameChips = false
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [excludedReason, setExcludedReason] = useState<string>('');
  const [snackMessage, setSnackMessage] = useState<string>('');
  const [recordsToDisplay, setRecordsToDisplay] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const excludedReasons = [
    { label: 'Vendor', value: 'Vendor' },
    { label: 'Existing Resident', value: 'Resident' },
    { label: 'Not a prospective renter', value: 'Not renting' },
    { label: 'Realtor / Locator', value: 'Realtor/Locator' },
    { label: 'Other', value: 'Other' },
    { label: 'Spam', value: 'Spam' },
    { label: 'Duplicate / Error', value: 'Duplicate' }
  ];

  const duplicateOrErrorTooltipText =
    'If using a Property Management System (PMS), this selection will cause the guestcard in your PMS to be canceled.';

  useEffect(() => {
    if (open && records.length > 0) {
      if (!hideNameChips) {
        setRecordsToDisplay(records.map((record: BulkAction) => record.name));
      }
    }
  }, [open, records, hideNameChips]);

  const handleChangeExcludedReason = (event: ChangeEvent<HTMLInputElement>) => {
    setExcludedReason(event.target.value);
  };

  const handleSubmit = async () => {
    const prospectIds = records.map((record: BulkAction) => record.id);
    setIsLoading(true);

    try {
      const response = await setNotAProspect({
        prospect_ids: prospectIds,
        excluded_reasons: [excludedReason]
      });

      if (response && response.error) {
        setSnackMessage(
          'We were unable to update the prospects. Please try again.'
        );
      } else {
        if (prospectIds.length > 1) {
          setSnackMessage(
            `${prospectIds.length} prospects updated successfully.`
          );
        } else {
          setSnackMessage(`${records[0].name} was updated successfully.`);
        }
      }
    } catch (error) {
      setSnackMessage(
        'We were unable to update the prospects. Please try again.'
      );
    } finally {
      handleClose({}, 'submit');
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    handleClose({}, 'cancel');
  };

  const handleClose = (event: Object, reason: string) => {
    closeModal(reason === 'submit');
    setExcludedReason('');
  };

  const handleCloseAlert = () => {
    setSnackMessage('');
  };

  const updateChipsToDisplay = () => {
    if (recordsToDisplay.length !== records.length) {
      return;
    }

    const chipContainer = window.document.getElementById('chip-container');
    let visibleChipsWidth = 0;

    if (chipContainer) {
      let firstChipOffsetTop: number = 0;
      let visibleCounter = 1;

      for (let index = 0; index < chipContainer.childNodes.length; index++) {
        const element = chipContainer.childNodes[index] as HTMLDivElement;
        let shouldBreak = false;

        index === 0
          ? (firstChipOffsetTop = element.offsetTop)
          : element.offsetTop === firstChipOffsetTop
          ? visibleCounter++
          : (shouldBreak = true);

        visibleChipsWidth += element.getBoundingClientRect().width;

        if (shouldBreak) {
          break;
        }
      }

      if (visibleCounter !== records.length) {
        setRecordsToDisplay((prevState: string[]) => {
          if (chipContainer) {
            const containerWidth = chipContainer.getBoundingClientRect().width;

            if (visibleChipsWidth > containerWidth) {
              visibleCounter--;
            }

            prevState.splice(visibleCounter);
            prevState.push(`+${records.length - visibleCounter}`);
          }

          return [...prevState];
        });
      }
    }
  };

  return (
    <>
      <Snackbar
        ContentProps={{
          classes: {
            root: classes.alert
          }
        }}
        data-testid="snack-alert-message"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!snackMessage}
        message={snackMessage}
        action={
          <IconButton
            className={classes.alertIcon}
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseAlert}
          >
            <Close />
          </IconButton>
        }
      />

      <Dialog
        aria-labelledby="not-a-prospect-dialog"
        open={open}
        classes={{ paperWidthSm: classes.dialog }}
        onClose={handleClose}
      >
        <DialogTitle className={classes.dialogTitle}>
          Not a Prospect
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {!hideNameChips && (
            <Box className={classes.chipContainer}>
              <FormControl className={classes.formControl}>
                <Typography
                  variant="caption"
                  id="chip-label"
                  variantMapping={{ caption: 'div' }}
                >
                  Prospects
                </Typography>

                <Select
                  IconComponent={() => null}
                  multiple
                  value={recordsToDisplay}
                  input={<Input />}
                  renderValue={(selected: any) => (
                    <div id="chip-container" className={classes.chips}>
                      {selected.map((value: string, index: number) => {
                        if (index + 1 === selected.length) {
                          updateChipsToDisplay();
                        }

                        return (
                          <Chip
                            key={index}
                            label={value}
                            className={classes.chip}
                            size="small"
                          />
                        );
                      })}
                    </div>
                  )}
                  disabled
                >
                  {recordsToDisplay.map((name: string, index: number) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          <Box>
            <Typography variant="body1" className={classes.description}>
              Please indicate why these guestcards are not prospects and we will
              remove them from your reports. Keep in mind that this should not
              be used to mark a prospect as lost or when someone leases.
            </Typography>

            <RadioGroup
              aria-label="exclusion reasons"
              defaultValue="0"
              className={classes.excludedReasons}
              onChange={handleChangeExcludedReason}
              color="primary"
            >
              {excludedReasons.map((option, index) => {
                return (
                  <Box
                    key={index}
                    className={
                      option.value === 'Duplicate'
                        ? classes.duplicateOrErrorOption
                        : undefined
                    }
                  >
                    <FormControlLabel
                      id={option.label}
                      value={option.value}
                      className={classes.excludedReasonLabel}
                      control={
                        <Radio
                          color="primary"
                          aria-checked={excludedReason === option.value}
                        />
                      }
                      label={option.label}
                    />

                    {option.value === 'Duplicate' && (
                      <TooltipContainer tooltip={duplicateOrErrorTooltipText}>
                        <span className={classes.duplicateOrErrorTooltipIcon}>
                          <InfoIcon />
                        </span>
                      </TooltipContainer>
                    )}
                  </Box>
                );
              })}
            </RadioGroup>
          </Box>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            type="button"
            onClick={handleCancel}
            variant="outlined"
            className={classes.cancelButton}
          >
            Cancel
          </Button>

          <CircularProgressButton
            className={commonClasses.primaryButtonRed}
            disabled={!excludedReason}
            onClick={handleSubmit}
            shouldShowProgress={isLoading}
            progressText={'Marking not a prospect...'}
          >
            Mark Not a Prospect{hideNameChips ? '' : ` (${records.length})`}
          </CircularProgressButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MarkAsNotAProspectModal;
