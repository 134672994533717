/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('accessControl', [
    '$q',
    'subscriptionsApi',
    function ($q, subscriptionsApi) {
      var self = this;

      self._verifyAddNewListingAccess = function (deferredAccessControl) {
        deferredAccessControl.resolve();
      };

      self._verifyAddNewUserAccess = function (deferredAccessControl) {
        deferredAccessControl.resolve();
      };

      return {
        canAddNewListing: function () {
          var deferredAccessControl = $q.defer();
          self._verifyAddNewListingAccess(deferredAccessControl);
          return deferredAccessControl.promise;
        },
        canAddNewUser: function () {
          var deferredAccessControl = $q.defer();
          self._verifyAddNewUserAccess(deferredAccessControl);
          return deferredAccessControl.promise;
        }
      };
    }
  ]);
})();
