/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('propertyCard', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/properties/property-card.html',
      controller: 'PropertyCardController',
      scope: {
        property: '='
      }
    };
  });

  app.controller('PropertyCardController', [
    '$scope',
    function ($scope) {
      var photo =
        $scope.property.data.coverPhoto ||
        $scope.property.data.photos[0].thumbUrl;

      $scope.cardStyle = {
        'background-image': 'url(' + photo + ')',
        'background-position': 'center',
        'background-size': 'contain',
        'background-repeat': 'no-repeat',
        'min-width': '140px',
        'min-height': '80px',
        'border-radius': '6px'
      };
    }
  ]);
})(window.angular);
