import React, { FC } from 'react';

import { Box, makeStyles, Text } from '@knockrentals/knock-shared-web';
import { colors } from '../../commonStyles/commonStyles';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    minWidth: '73px'
  },

  icon: {
    marginTop: '3px'
  },

  label: {
    color: colors.secondaryText,
    paddingLeft: '6px'
  }
});

interface LabelWithIconProps {
  label: string;
  icon: React.ReactElement;
}

const LabelWithIcon: FC<LabelWithIconProps> = ({ label, icon }) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.icon}>{icon}</Box>
      <Text variant="body2" className={classes.label}>
        {label}
      </Text>
    </Box>
  );
};

export default LabelWithIcon;
