/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.controller('signupModalController', [
    '$scope',
    '$auth',
    '$modalInstance',
    '$location',
    'localCache',
    'cacheKeys',
    'loginModalService',
    'message',
    'restrictToSignupType',
    'authenticationService',
    function (
      $scope,
      $auth,
      $modalInstance,
      $location,
      localCache,
      cacheKeys,
      loginModalService,
      message,
      restrictToSignupType,
      authenticationService
    ) {
      $scope.message = message;
      $scope.restrictToSignupType = restrictToSignupType;

      $scope.onSignup = function () {
        $scope.dismiss();
        authenticationService.handleAuthenticatedRedirection();
      };

      $scope.dismiss = function () {
        $modalInstance.dismiss('closed');
      };

      $scope.switchToLogin = function () {
        $modalInstance.dismiss('closed');
        loginModalService.openModal();
      };
    }
  ]);
})();
