import React, { FC } from 'react';
import { Button } from '@knockrentals/knock-shared-web';

interface Props {
  unsubscribeHandler: Function;
  isLoading: boolean;
}
const LandingPage: FC<Props> = ({ unsubscribeHandler, isLoading }) => {
  return (
    <div>
      <Button disabled={isLoading} onClick={() => unsubscribeHandler()}>
        Click to Unsubscribe
      </Button>
    </div>
  );
};

export default LandingPage;
