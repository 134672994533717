/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('communityApi', [
    'apiBase',
    function (apiBase) {
      return {
        createCommunity: function (community) {
          return apiBase.post('/community', { community: community });
        },
        getCommunity: function (communityId) {
          return apiBase.get('/community/' + communityId);
        },
        getCommunityManager: function (communityId) {
          return apiBase.get('/community/' + communityId + '/manager');
        },
        getCommunityCompany: function (communityId) {
          return apiBase.get('/community/' + communityId + '/company');
        },
        getCommunityListings: function (
          communityId,
          includeInactive,
          includeHidden
        ) {
          var params = {
            includeInactive: includeInactive,
            includeHidden: includeHidden
          };

          return apiBase.get('/community/' + communityId + '/listings', {
            params: params
          });
        },
        updateCommunity: function (communityId, payload) {
          return apiBase.put('/community/' + communityId, {
            community: payload
          });
        },
        deleteCommunity: function (communityId, password) {
          return apiBase.delete(
            '/community/' + communityId + '?password=' + password
          );
        },
        deletePhoto: function (s3key) {
          return apiBase.post('/community/photo/delete', { s3key: s3key });
        },
        createFloorplan: function (floorplan) {
          return apiBase.post('/community/floorplan', { floorplan: floorplan });
        },
        getCommunityTemplate: function (communityId) {
          return apiBase.get('/community/' + communityId + '/templates');
        },
        getCommunityTemplates: function (communityIds) {
          var payload = {
            ids: communityIds
          };

          return apiBase.post('/communities/templates', payload);
        },
        updateCommunityTemplate: function (communityId, payload) {
          return apiBase.put(
            '/community/' + communityId + '/templates',
            payload
          );
        },
        getCommunityTemplatePreview: function () {
          return apiBase.get('/community/template-preview/mailer');
        },
        transferCommunity: function (communityId, newManagerId) {
          return apiBase.put(
            '/community/' + communityId + '/transfer/' + newManagerId
          );
        }
      };
    }
  ]);
})(window.angular);
