/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('currencyInput', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/controls/currency-input.html',
      require: 'ngModel',
      scope: {
        ngModel: '='
      },
      controller: [
        '$scope',
        function ($scope) {
          $scope.numberOnlyRegex = /^[0-9]+$/;
        }
      ]
    };
  });
})();
