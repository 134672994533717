/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular, Raven) {
  'use strict';
  var app = angular.module('knockApp');

  app.factory('appointmentsApi', [
    'apiBase',
    'HttpCache',
    function (apiBase, HttpCache) {
      var appointmentsCache = new HttpCache(60);

      return {
        getAppointment: function (appointmentId) {
          return apiBase.get('/appointments/' + appointmentId, {
            cache: appointmentsCache
          });
        },
        getAppointments: function (query) {
          return apiBase.get('/appointments', {
            params: {
              start: query.start,
              end: query.end,
              status: query.status,
              listing_id: query.listingId,
              renter_id: query.renterId,
              appointment_id: query.appointmentId,
              property_id: query.property_id
            }
          });
        },
        getAppointmentProspects: function (start, end) {
          var params = {
            start: start,
            end: end
          };

          return apiBase.get('/appointments/prospects', { params: params });
        },
        getAppointmentRequests: function (appointmentId) {
          return apiBase.get('/appointments/' + appointmentId + '/requests');
        },
        confirmAppointment: function (appointmentRequestId) {
          return apiBase.post(
            '/appointment-requests/' + appointmentRequestId + '/confirm'
          );
        },
        confirmAppointmentProposal: function (appointmentId, proposalKnockIds) {
          var payload = {
            proposalIds: proposalKnockIds
          };

          return apiBase.post(
            '/appointment-requests/' + appointmentId + '/confirm',
            payload
          );
        },
        requestAppointment: function (
          propertyId,
          requestedTimes,
          listingIds,
          source,
          isRescheduling,
          prospectKnockId,
          profile,
          knockAttributionParent
        ) {
          if (!profile) {
            try {
              throw Error('profile is required!');
            } catch (e) {
              Raven.captureException(e);
            }
          }

          var payload = {
            property_id: propertyId,
            requested_times: requestedTimes,
            listing_ids: listingIds,
            is_rescheduling: isRescheduling,
            prospect_knock_id: prospectKnockId,
            knock_attribution_parent: knockAttributionParent,
            profile: profile
          };

          var options = {
            params: {
              s: source
            }
          };

          return apiBase.post('/appointments/request', payload, options);
        },
        setAppointmentStatus: function (appointmentId, status) {
          var payload = {
            status: status
          };

          return apiBase.put(
            '/appointments/' + appointmentId + '/status',
            payload
          );
        },
        requestQueuedAppointment: function (queuedRequestId) {
          return apiBase.post(
            '/appointments/queued-request/' + queuedRequestId,
            {}
          );
        },
        resendQueuedRequestEmail: function (queuedRequestId) {
          return apiBase.post(
            '/appointments/queued-request/' + queuedRequestId + '/resend'
          );
        },
        setShownListingsForAppointment: function (appointmentId, listingIds) {
          var payload = {
            listing_ids: listingIds
          };

          return apiBase.put(
            '/appointments/' + appointmentId + '/listings',
            payload
          );
        },
        addListingToAppointment: function (appointmentId, listingId) {
          var payload = {
            listing_id: listingId
          };

          return apiBase.post(
            '/appointments/' + appointmentId + '/listings',
            payload
          );
        },
        removeListingFromAppointment: function (appointmentId, listingId) {
          return apiBase.delete(
            '/appointments/' + appointmentId + '/listings/' + listingId
          );
        },
        proposeAppointmentTimes: function (
          prospectId,
          proposedTimes,
          managerMessage
        ) {
          var payload = {
            prospect_id: prospectId,
            proposed_times: proposedTimes,
            message: managerMessage
          };

          return apiBase.post('/appointments/proposals', payload);
        },
        addManualAppointment: function (
          managerId,
          propertyId,
          prospectProfile,
          startTime,
          endTime,
          layouts,
          listingIds,
          source,
          prospectId,
          firstContactType,
          note,
          preferences,
          tourType
        ) {
          var payload = {
            manager_id: managerId,
            property_id: propertyId,
            prospect_id: prospectId,
            prospect: {
              first_name: prospectProfile.first_name,
              last_name: prospectProfile.last_name,
              email: prospectProfile.email,
              phone: prospectProfile.phone,
              target_move_date: prospectProfile.target_move_date
            },
            start_time: startTime,
            end_time: endTime,
            preferences: preferences,
            layouts: layouts,
            listing_ids: listingIds,
            source: source,
            first_contact_type: firstContactType,
            note: note,
            tour_type: tourType
          };

          return apiBase.post('/appointments', payload);
        },
        updateAppointmentTime: function (
          appointmentId,
          startTime,
          endTime,
          tourType
        ) {
          var payload = {
            start_time: startTime,
            end_time: endTime
          };

          if (tourType !== undefined) {
            payload.tour_type = tourType;
          }

          return apiBase.put(
            '/appointments/' + appointmentId + '/times',
            payload
          );
        },
        cancelAppointment: function (
          appointmentId,
          appointmentKnockId,
          message
        ) {
          var params = {
            appointmentId: appointmentKnockId,
            managerMessage: message
          };
          return apiBase.delete('/appointments/' + appointmentId, {
            params: params
          });
        },
        cancelRequests: function (requestId, managerMessage) {
          var payload = {
            manager_message: managerMessage
          };

          return apiBase.post(
            '/appointment-requests/' + requestId + '/reject',
            payload
          );
        },
        getQueuedRequest: function (queuedRequestId) {
          return apiBase.get('/appointments/queued-request/' + queuedRequestId);
        },
        setRenterNotInterested: function (appointmentId) {
          return apiBase.put('/appointments/' + appointmentId + '/disinterest');
        },
        setRenterApplied: function (appointmentId) {
          return apiBase.put('/appointments/' + appointmentId + '/apply');
        },
        setAppointmentManager: function (appointmentId, newManagerId) {
          return apiBase.put(
            '/appointments/' + appointmentId + '/manager/' + newManagerId
          );
        },
        setAppointmentSource: function (appointmentId, source) {
          return apiBase.put('/appointments/' + appointmentId + '/source', {
            source: source
          });
        },
        getValidAppointmentSources: function () {
          return apiBase.get('/appointments/sources');
        },
        getReview: function (appointmentId) {
          return apiBase.get('/appointments/' + appointmentId + '/review');
        },
        saveReview: function (appointmentId, token, reviewPayload) {
          var payload = {
            review: reviewPayload
          };

          var params = {
            token: token
          };

          return apiBase.put(
            '/appointments/' + appointmentId + '/review',
            payload,
            { params: params }
          );
        },
        getLiveVideoTourUrl: (appointmentUuid) => {
          return apiBase.get(
            `/appointments/uuid/${appointmentUuid}/live-video-tour-url`
          );
        },
        sendConfirmation: (appointmentId) => {
          return apiBase.post(`/appointments/${appointmentId}/confirmation`);
        }
      };
    }
  ]);
})(window.angular, window.Raven);
