/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.filter('monthlyRent', [
    '$filter',
    function ($filter) {
      return function (listing, showPriceRange) {
        var currency = $filter('currency');
        var lowPrice = currency(listing.leasing.monthlyRent, '$', 0);

        if (!showPriceRange || !listing.leasing.monthlyRentHigh) {
          return lowPrice;
        }

        var highPrice = currency(listing.leasing.monthlyRentHigh, '$', 0);

        return lowPrice + '-' + highPrice;
      };
    }
  ]);
})(window.angular);
