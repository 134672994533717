/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Authentication');

  app.factory('forgotPasswordModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openForgotPasswordModal = function (userType, username) {
        if (!userType) {
          throw 'userType is required';
        }

        return $mdDialog.show({
          controller: 'ForgotPasswordController',
          templateUrl:
            '/angular/views/authentication/forgot-password-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            userType: userType,
            username: username
          }
        });
      };

      return self;
    }
  ]);

  app.controller('ForgotPasswordController', [
    '$scope',
    '$mdDialog',
    'authenticationService',
    'userType',
    'username',
    function ($scope, $mdDialog, authenticationService, userType, username) {
      var self = this;

      const standardErrorMessage =
        'There was an error submitting your reset request.';

      $scope.form = {
        userType: userType,
        username: username || '',
        isLoading: false,
        submitSuccess: false,
        submitError: false
      };

      $scope.close = function () {
        $mdDialog.hide();
      };

      $scope.submitForgotPassword = function () {
        $scope.form.isLoading = true;
        $scope.form.submitError = false;

        authenticationService
          .sendForgotPasswordRequest($scope.form.userType, $scope.form.username)
          .then(
            self._submitForgotPasswordSuccess,
            self._submitForgotPasswordError
          )
          .catch(self._submitForgotPasswordUnhandledError)
          .finally(function () {
            $scope.form.isLoading = false;
          });
      };

      self._submitForgotPasswordSuccess = function () {
        $scope.form.submitSuccess = true;
      };

      self._submitForgotPasswordError = function (response) {
        $scope.form.submitError = true;

        if (response.status === 403) {
          $scope.form.errorMessage =
            'If you have a RealPage account, try resetting your password from the RealPage Unified Platform.';
          return;
        } else {
          $scope.form.errorMessage = standardErrorMessage;
          return;
        }
      };

      self._submitForgotPasswordUnhandledError = function () {
        $scope.form.submitError = true;
        $scope.form.errorMessage = standardErrorMessage;
      };
    }
  ]);
})(window.angular);
