import React, { FC } from 'react';

export const UnreadMessagesIcon: FC = () => (
  <svg
    width="183"
    height="180"
    viewBox="0 0 183 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="unread-messages-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.877 7.05351C90.5693 -8.03667 56.2244 1.88225 41.1661 29.208C35.6776 39.1679 33.5088 50.0655 34.2913 60.6249C22.6756 64.1962 12.3796 72.109 6.04754 83.5995C-6.90704 107.108 1.63612 136.689 25.1292 149.671L69.0311 173.931C92.5241 186.913 122.071 178.379 135.025 154.871C136.989 151.308 138.459 147.605 139.457 143.842C154.56 147.256 170.703 140.492 178.554 126.244C188.077 108.965 181.797 87.2215 164.529 77.6792L149.527 69.3893C154.136 45.9229 143.416 21.1658 121.37 8.98354L117.877 7.05351Z"
      fill="#EBEEFE"
    />
    <g clipPath="url(#clip0_267_7223)">
      <path
        d="M94.4963 112.49V127.49"
        stroke="#697FF7"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.4963 77.4896H119.496C122.148 77.4896 124.692 78.5432 126.567 80.4186C128.443 82.2939 129.496 84.8375 129.496 87.4896V109.99C129.496 110.653 129.233 111.289 128.764 111.757C128.295 112.226 127.659 112.49 126.996 112.49H74.4963V87.4896C74.4963 84.8375 73.4427 82.2939 71.5673 80.4186C69.692 78.5432 67.1484 77.4896 64.4963 77.4896Z"
        stroke="#697FF7"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.4963 112.49H56.9963C56.3332 112.49 55.6974 112.226 55.2285 111.757C54.7597 111.289 54.4963 110.653 54.4963 109.99V87.4896C54.4963 84.8375 55.5498 82.2939 57.4252 80.4186C59.3006 78.5432 61.8441 77.4896 64.4963 77.4896C67.1484 77.4896 69.692 78.5432 71.5673 80.4186C73.4427 82.2939 74.4963 84.8375 74.4963 87.4896V112.49Z"
        stroke="#697FF7"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.4963 87.4896V52.4896"
        stroke="#697FF7"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.4963 67.4896H106.996C108.985 67.4896 110.893 66.6994 112.3 65.2929C113.706 63.8864 114.496 61.9787 114.496 59.9896C114.496 58.0005 113.706 56.0928 112.3 54.6863C110.893 53.2798 108.985 52.4896 106.996 52.4896H89.4963V67.4896Z"
        stroke="#697FF7"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_267_7223">
        <rect
          width="80"
          height="80"
          fill="white"
          transform="translate(51.9963 49.9896)"
        />
      </clipPath>
    </defs>
  </svg>
);
