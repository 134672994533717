/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const CalendarVisitPopoverController = function (
    $scope,
    conversationsService,
    appointmentsApi,
    transferAppointmentModalFactory
  ) {
    var event = $scope.$parent.event;

    $scope.data = {
      visit: event.visit
    };

    if ($scope.data.visit.appointment_id) {
      appointmentsApi
        .getAppointment($scope.data.visit.appointment_id)
        .then(function (response) {
          $scope.data.appointment = response.data.appointment;
        });
    }

    $scope.openStream = function () {
      conversationsService.openThreadById(
        $scope.data.visit.prospect.stream_id,
        true
      );
    };

    $scope.tryTransferAppointment = function () {
      transferAppointmentModalFactory.openTransferAppointmentModal(
        $scope.data.appointment,
        $scope.data.appointment.manager_id,
        $scope.data.visit
      );
    };
  };

  CalendarVisitPopoverController.$inject = [
    '$scope',
    'conversationsService',
    'appointmentsApi',
    'transferAppointmentModalFactory'
  ];

  app.controller(
    'CalendarVisitPopoverController',
    CalendarVisitPopoverController
  );
})(window.angular);
