import isString from 'lodash/isString';
import forIn from 'lodash/forIn';
import angular from 'angular';

var app = angular.module('knockApp');

var notificationEvents = {
  managerAppointmentCancellation: 'manager-appointment-cancellation',
  managerAppointmentConfirmation: 'manager-appointment-confirmation',
  managerAppointmentRequestCancelled: 'manager-appointment-request-cancelled',
  managerAppointmentRequested: 'manager-appointment-requested',
  managerExpiringRequest: 'manager-expiring-request',
  managerTransferredListing: 'manager-transferred-listing',
  managerTransferredAppointment: 'manager-transferred-appointment',
  managerTeamInvitation: 'manager-team-invitation',
  managerProspectCreated: 'manager-prospect-created',
  renterAppointmentCancellation: 'renter-appointment-cancellation',
  renterAppointmentConfirmation: 'renter-appointment-confirmation',
  renterAppointmentChanged: 'renter-appointment-changed',
  renterAppointmentsProposed: 'renter-appointments-proposed',
  renterRequestDenied: 'renter-request-denied',
  renterRequestedApplicationInstructions:
    'renter-requested-application-instructions',
  managerNewRentalApplication: 'manager-new-rental-application',
  renterListingDeactivated: 'renter-listing-deactivated'
};

var clientEvents = {
  userLoggedOut: 'client-logged-out'
};

app.factory('notificationsService', [
  '$rootScope',
  '$location',
  'notificationsApi',
  'pusherInstanceService',
  'applicationNotificationService',
  'userService',
  'localCache',
  'apiEvents',
  function (
    $rootScope,
    $location,
    notificationsApi,
    pusherInstanceService,
    applicationNotificationService,
    userService,
    localCache,
    apiEvents
  ) {
    var self = this;
    self.isConfigured = false;

    self._configure = function () {
      self.pusher = pusherInstanceService.getInstance();

      try {
        var privateUserNotificationsChannel =
          self._getPrivateUserNotificationsChannel();
        self.notificationChannel = self.pusher.subscribe(
          privateUserNotificationsChannel
        );

        var privateUserChannel = self._getPrivateUserChannel();
        self.userChannel = self.pusher.subscribe(privateUserChannel);

        self._registerClientEvents();

        applicationNotificationService.initialize(self.pusher);
      } catch (e) {}
    };

    self._getPrivateUserNotificationsChannel = function () {
      var user = userService.getScopedUser();

      if (!user) {
        throw new Error('User required to generate channel id');
      }

      return 'private-' + user.type + '-' + user.id + '-notifications';
    };

    self._getPrivateUserChannel = function () {
      var user = userService.getScopedUser();

      if (!user) {
        throw new Error('User required to generate channel id');
      }

      return 'private-' + user.type + '-' + user.id;
    };

    self._registerEventCallback = function (event, callback) {
      self.pusher.bind(event, callback);
    };

    self._registerClientEvents = function () {
      self.pusher.bind(clientEvents.userLoggedOut, function (logoutEventId) {
        if (localCache.get('do-logout') === logoutEventId) {
          $rootScope.$emit(apiEvents.loggedOut);
          $location.path('/');
        }
      });
    };

    self._registerCallbackWithNotificationEvents = function (callback) {
      forIn(notificationEvents, function (event) {
        self.pusher.bind(event, function (rawNotification) {
          $rootScope.$emit(apiEvents.newNotification, rawNotification);
          callback(rawNotification);
        });
      });
    };

    var NotificationsService = {};

    NotificationsService.updatePusher = function () {
      self._configure();
    };

    NotificationsService.createNotificationModel = function (rawNotification) {
      var args = rawNotification.web_args; // jshint ignore: line
      if (isString(args)) {
        args = JSON.parse(args);
      }

      return {
        args: args,
        eventName: rawNotification.event,
        time: rawNotification.created_time, // jshint ignore:line
        id: rawNotification.id,
        isRead: rawNotification.is_read, // jshint ignore:line
        isArchived: rawNotification.is_archived // jshint ignore:line
      };
    };

    NotificationsService.registerUserEventCallback = function (
      event,
      callback
    ) {
      if (!self.isConfigured) {
        self._configure();
      }

      self._registerEventCallback(event, callback);
    };

    NotificationsService.registerNotificationCallback = function (
      newNotificationCallback
    ) {
      if (!self.isConfigured) {
        self._configure();
      }

      self._registerCallbackWithNotificationEvents(newNotificationCallback);
    };

    NotificationsService.unsubscribe = function () {
      if (!self.isConfigured) {
        self._configure();
      }

      var privateUserChannel = self._getPrivateUserNotificationsChannel();
      self.pusher.unsubscribe(privateUserChannel);
    };

    NotificationsService.clientEvents = clientEvents;

    NotificationsService.emitClientEvent = function (clientEvent, data) {
      self.userChannel.trigger(clientEvent, data || {});
    };

    NotificationsService.togglePanel = function () {
      $rootScope.$emit(apiEvents.toggleNotifications);
    };

    NotificationsService.closePanel = function () {
      $rootScope.$emit(apiEvents.closeNotifications);
    };

    NotificationsService.refreshClientPreferences = function () {
      NotificationsService._clientPreferences = null;
      return NotificationsService.getClientPreferences();
    };

    NotificationsService.getClientPreferences = function () {
      if (NotificationsService._clientPreferences) {
        return Promise.resolve(NotificationsService._clientPreferences);
      }

      return notificationsApi
        .getPreferences()
        .then(function (response) {
          var preferences = response.data.user_preferences.client_preferences;
          NotificationsService._clientPreferences = preferences;
          return preferences;
        })
        .catch(function (error) {
          console.error(
            'Failed to retrieve client notification preferences',
            error
          );
        });
    };

    return NotificationsService;
  }
]);
