import React, { FC } from 'react';

import { ThemeProvider } from '@knockrentals/knock-shared-web';

import { Provider } from 'react-redux';
import { AngularContextProvider } from './AngularContextProvider';
import { store } from '../../../app/store';
import ViewProspectInOneSiteBody from './ViewProspectInOneSiteBody';

interface ViewProspectInOneSiteProps {
  prospectId: number;
}

const ViewProspectInOneSite: FC<ViewProspectInOneSiteProps> = ({
  prospectId
}) => (
  <AngularContextProvider
    context={{
      prospectId
    }}
  >
    <Provider store={store}>
      <ThemeProvider>
        <ViewProspectInOneSiteBody prospectId={prospectId} />
      </ThemeProvider>
    </Provider>
  </AngularContextProvider>
);

export default ViewProspectInOneSite;
