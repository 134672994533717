import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './AxiosBaseQuery';

const baseQueryWithRetry = retry(axiosBaseQuery(), { maxRetries: 0 });

export const api = createApi({
  reducerPath: 'apiV2',
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({})
});
