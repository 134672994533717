import React, { FC, useState, ReactElement } from 'react';
import { Menu, MenuItem, Button, Theme } from '@material-ui/core';

import {
  Box,
  IconButton,
  makeStyles,
  NamedColors
} from '@knockrentals/knock-shared-web';
import { SettingsSliderIcon, VerticalDotsIcon } from '../icons';
import { colors, useCommonStyles } from '../commonStyles/commonStyles';
import { ProspectFiltersDrawer } from './ProspectFilters/ProspectFiltersDrawer';
import TooltipContainer from '../molecules/TooltipContainer/TooltipContainer';

const useStyles = makeStyles((theme: Theme) => ({
  filtersButton: {
    '& svg': {
      minWidth: '22px'
    },

    '& .buttonLabel': {
      marginLeft: '8px',

      '&::before': {
        content: '"Filters"'
      },

      [theme.breakpoints.down(960)]: {
        marginLeft: 0,

        '&::before': {
          content: '""'
        }
      }
    },

    [theme.breakpoints.down(960)]: {
      minWidth: 0
    }
  },

  filters: {
    display: 'flex',
    flexGrow: 1,
    marginLeft: '12px'
  },

  filterSelect: {
    marginRight: '12px',

    '&:last-child': {
      marginRight: 0
    }
  },

  actions: {
    display: 'flex',
    marginLeft: '12px'
  },

  actionButton: {
    color: colors.defaultText,
    marginRight: '6px',

    '&:hover': {
      backgroundColor: NamedColors.slate[100]
    },

    [theme.breakpoints.down(600)]: {
      display: 'none'
    }
  },

  visibleAction: {
    [theme.breakpoints.up(600)]: {
      display: 'none'
    }
  },

  menuIcon: {
    padding: '6px'
  },

  overflowMenu: {
    '& .MuiListItem-root': {
      color: colors.defaultText,
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      fontSize: '13px',
      fontWeight: 600,
      letterSpacing: '0.4px',
      lineHeight: '22px',
      padding: '8px 16px 8px 16px',
      textAlign: 'left',

      '& svg': {
        marginRight: '8px',
        width: '20px'
      },

      '&:hover': {
        backgroundColor: NamedColors.slate[100]
      }
    }
  },

  filtersBreakpointNone: {
    display: 'none'
  },

  filtersBreakpointXl: {
    [theme.breakpoints.down(1500)]: {
      display: 'none'
    }
  },

  filtersBreakpointLg: {
    [theme.breakpoints.down(1280)]: {
      display: 'none'
    }
  },

  filtersBreakpointMd: {
    [theme.breakpoints.down(1100)]: {
      display: 'none'
    }
  },

  filtersBreakpointSm: {
    [theme.breakpoints.down(900)]: {
      display: 'none'
    }
  },

  filtersBreakpointXs: {
    [theme.breakpoints.down(650)]: {
      display: 'none'
    }
  }
}));

export interface IAction {
  label: string;
  value: number;
  icon: ReactElement;
  onClick: () => void;
  selectedItemsCount?: number;
  showInOverflowMenu?: boolean;
  disabled?: boolean;
  tooltip?: string;
  hide?: boolean;
  id?: string;
}

interface IActionBarProps {
  showFilterButton?: boolean;
  filters?: ReactElement[];
  actions?: IAction[];
  className?: string;
}

const ActionBar: FC<IActionBarProps> = ({
  showFilterButton,
  filters,
  actions,
  className
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const visibleActions = new Array<IAction>();
  const overflowMenuActions = new Array<IAction>();

  const handleCloseFilters = () => {
    setFiltersOpen(false);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (action: IAction) => {
    action.onClick();
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getFullLabel = (action: IAction) => {
    let label = action.label;

    if (
      action.selectedItemsCount !== undefined &&
      action.selectedItemsCount > 0
    ) {
      label += ` (${action.selectedItemsCount})`;
    }

    return label;
  };

  if (actions) {
    for (const action of actions) {
      if (!action.hide) {
        if (action.showInOverflowMenu) {
          overflowMenuActions.push(action);
        } else {
          visibleActions.push(action);
        }
      }
    }
  }

  return (
    <Box className={className ? className : undefined} data-testid="action-bar">
      {showFilterButton && (
        <Button
          variant="text"
          className={classes.filtersButton}
          data-testid="filter-action-button"
          onClick={() => setFiltersOpen(true)}
        >
          <SettingsSliderIcon id="prospect-filter-action-icon" />
          <span className="buttonLabel"></span>
        </Button>
      )}

      {filters && (
        <Box className={classes.filters}>
          {filters.map((filter: ReactElement, index: number) => {
            let className = classes.filterSelect;

            switch (index) {
              case 0:
                className += ` ${classes.filtersBreakpointXs}`;
                break;
              case 1:
                className += ` ${classes.filtersBreakpointSm}`;
                break;
              case 2:
                className += ` ${classes.filtersBreakpointMd}`;
                break;
              case 3:
                className += ` ${classes.filtersBreakpointLg}`;
                break;
              case 4:
              case 5:
                className += ` ${classes.filtersBreakpointXl}`;
                break;
              default:
                className += ` ${classes.filtersBreakpointNone}`;
            }

            return (
              <span className={className} key={index}>
                {filter}
              </span>
            );
          })}
        </Box>
      )}

      {actions && (
        <Box className={classes.actions} id="actions-container">
          {visibleActions.length > 0 && (
            <>
              {visibleActions.map((action: IAction) => {
                const disabled =
                  action.selectedItemsCount === 0 || action.disabled;

                return (
                  <TooltipContainer
                    key={action.value}
                    tooltip={action.tooltip || ''}
                  >
                    <Button
                      onClick={action.onClick}
                      disabled={disabled}
                      variant="text"
                      data-testid={action.id}
                      className={`${classes.actionButton} ${
                        commonClasses.buttonWithIcon
                      } ${disabled ? commonClasses.disabledIcon : ''}`}
                    >
                      {action.icon}
                      {getFullLabel(action)}
                    </Button>
                  </TooltipContainer>
                );
              })}
            </>
          )}

          {overflowMenuActions.length > 0 && (
            <>
              <IconButton
                aria-label="action menu"
                aria-haspopup="true"
                className={classes.menuIcon}
                id="action-menu"
                data-testid="actions-menu-button"
                onClick={handleMenuClick}
              >
                <VerticalDotsIcon />
              </IconButton>

              <Menu
                id="ActionOverflowMenu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                getContentAnchorEl={null}
                className={classes.overflowMenu}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <div>
                  {overflowMenuActions.map((action: IAction) => {
                    const disabled =
                      action.selectedItemsCount === 0 || action.disabled;

                    const className = `${
                      action.showInOverflowMenu ? '' : classes.visibleAction
                    } ${disabled ? commonClasses.disabledIcon : ''}`;

                    return (
                      <TooltipContainer
                        key={action.value}
                        tooltip={action.tooltip || ''}
                      >
                        <MenuItem
                          key={action.value}
                          disabled={disabled}
                          data-testid={action.id}
                          className={className ? className : undefined}
                          onClick={() => handleMenuItemClick(action)}
                        >
                          {action.icon} {getFullLabel(action)}
                        </MenuItem>
                      </TooltipContainer>
                    );
                  })}
                </div>
              </Menu>
            </>
          )}
        </Box>
      )}

      <ProspectFiltersDrawer open={filtersOpen} onClose={handleCloseFilters} />
    </Box>
  );
};

export default ActionBar;
