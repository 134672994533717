import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import {
  makeStyles,
  NamedColors,
  Box,
  Button,
  Text
} from '@knockrentals/knock-shared-web';
import { CircularProgressButton } from '../CircularProgressButton';
import { colors } from '../commonStyles/commonStyles';

interface SmsOptInModalProps {
  openModal: boolean;
  closeModal: (insertLink?: boolean | undefined) => void;
}

const useStyles = makeStyles(() => ({
  dialogPaperWidth: {
    width: '444px'
  },
  dialogTitle: {
    padding: '16px 24px'
  },
  dialogContent: {
    padding: '16px 24px'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'end',
    padding: '20px 24px'
  },
  text: {
    letterSpacing: '0.5px'
  },
  alertBox: {
    backgroundColor: NamedColors.denim[50],
    marginTop: '16px',
    '& .MuiTypography-root': {
      color: colors.secondaryText,
      fontSize: '14px',
      padding: '16px'
    }
  },
  cancelButton: {
    border: `1px solid ${NamedColors.denim[600]}`,
    color: NamedColors.denim[600],
    lineHeight: '24px'
  },
  CircularProgressButton: {
    background: NamedColors.denim[600]
  }
}));

const SmsOptInModal: React.FC<SmsOptInModalProps> = ({
  openModal,
  closeModal
}) => {
  const classes = useStyles();

  const submitHandler = () => {
    closeModal(true);
  };

  return (
    <Dialog
      disableEnforceFocus
      open={openModal}
      classes={{ paperWidthSm: classes.dialogPaperWidth }}
    >
      <DialogTitle className={classes.dialogTitle}>SMS Opt In</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Text className={classes.text}>
          To allow residents to opt in to receive SMS messages, links will be
          generated for each individual recipient and inserted into a batch
          email.
        </Text>
        <Box className={classes.alertBox}>
          <Text>
            “To allow us to send text messages, follow this link: #SMSOptinLink”
          </Text>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          type="button"
          variant="outlined"
          data-testid="cancel-button"
          className={classes.cancelButton}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <CircularProgressButton
          onClick={submitHandler}
          shouldShowProgress={false}
          disabled={false}
          progressText={'Inserting...'}
          className={classes.CircularProgressButton}
        >
          Insert
        </CircularProgressButton>
      </DialogActions>
    </Dialog>
  );
};
export default SmsOptInModal;
