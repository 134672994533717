import React from 'react';

export const BookTourIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="leader">
        <path
          id="Vector"
          d="M20.651 23.25C19.7058 20.5586 17.1665 18.7554 14.314 18.75H14.308C11.4557 18.7556 8.91682 20.5588 7.972 23.25M5.864 20.338C3.67157 19.8969 1.48944 21.1394 0.75 23.25M23.25 23.25V0.75H10.5L13.5 3.75L10.5 6.75H23.25M14.308 17.25C16.5862 17.25 18.433 15.4032 18.433 13.125C18.433 10.8468 16.5862 9 14.308 9C12.0298 9 10.183 10.8468 10.183 13.125C10.183 15.4032 12.0298 17.25 14.308 17.25ZM4.975 18.75C6.63185 18.75 7.975 17.4069 7.975 15.75C7.975 14.0931 6.63185 12.75 4.975 12.75C3.31815 12.75 1.975 14.0931 1.975 15.75C1.975 17.4069 3.31815 18.75 4.975 18.75Z"
          stroke="#697FF7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
