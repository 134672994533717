import React, { FC, ChangeEvent } from 'react';
import {
  Box,
  makeStyles,
  Text,
  NamedColors
} from '@knockrentals/knock-shared-web';

import {
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup
} from '@material-ui/core';

import { HappyIcon } from '../../icons/HappyIcon';
import { Block } from '@material-ui/icons';
import { useCompleteDrawerContext } from './CompleteTourDrawerContextProvider';

interface LabelComponentProps {
  title: string;
  subTitle: string;
}

const useStyles = makeStyles(() => ({
  boxStyle: {
    display: 'flex',
    '& .MuiTypography-body1': {
      fontSize: '15px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontWeight: 400
    }
  },
  visitedStyle: {
    display: 'flex',
    width: '300px',
    height: '82px',
    border: '1px solid',
    borderRadius: '8px',
    padding: '8px 12px 8px 12px',
    color: NamedColors.slate[500],
    borderColor: NamedColors.slate[300],
    marginTop: '18px',
    marginLeft: '6px',
    marginRight: '6px',
    marginBottom: '-14px'
  },
  svg: {
    width: '58px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '6px 0',
    borderRadius: '10px',
    '&.optionOne': {
      backgroundColor: NamedColors.grass[100],
      '& svg': {
        '& path': {
          stroke: NamedColors.grass[600]
        }
      }
    },
    '&.optionTwo': {
      backgroundColor: NamedColors.apple[100],
      '& svg': {
        stroke: NamedColors.apple[600],
        fill: 'transparent'
      },
      marginTop: '8px',
      width: '62px'
    },
    '& svg': {
      height: '24px',
      width: '24px'
    }
  },
  optionOneSelected: {
    backgroundColor: NamedColors.grass[50],
    borderColor: NamedColors.grass[200]
  },
  optionTwoSelected: {
    backgroundColor: NamedColors.apple[50],
    borderColor: NamedColors.apple[200]
  },
  colorSelectedBlue: {
    backgroundColor: '#eef9ff',
    borderColor: '#9ad8fc'
  },
  colorSelectedRed: {
    backgroundColor: '#fff5f0',
    borderColor: '#ffc0a0'
  },
  FormControlLabelClass: {
    '& .MuiIconButton-colorSecondary': {
      color: '#4a61e8'
    }
  },
  labelTitle: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '14px',
    color: NamedColors.slate[800]
  }
}));

const ProspectInterestBox: FC = () => {
  const classes = useStyles();
  const { setData, prospectInterest, SetFinishButtonDisableClicked } =
    useCompleteDrawerContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setData({ prospectInterest: event.target.value });
    SetFinishButtonDisableClicked(false);
  };

  const LabelComponent: FC<LabelComponentProps> = ({ title, subTitle }) => {
    return (
      <Box data-testid="label-component">
        <Text
          variant="body2"
          color="textSecondary"
          data-testid="label-component-box"
          className={classes.labelTitle}
        >
          {title}
        </Text>
        {subTitle}
      </Box>
    );
  };

  return (
    <Box className={classes.boxStyle} data-testid="prospect-interest-step">
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="Prospect Interest"
          name="prospectInterest"
          onChange={handleChange}
          value={prospectInterest || 'top'}
        >
          <Box
            className={`${classes.visitedStyle} ${
              prospectInterest === 'still_interested'
                ? classes.optionOneSelected
                : null
            }`}
            data-testid="prospect-interest-box-left"
          >
            <Box className={classes.svg + ' optionOne'}>
              <HappyIcon />
            </Box>
            <FormControlLabel
              value="still_interested"
              className={classes.FormControlLabelClass}
              control={
                <Radio checked={prospectInterest === 'still_interested'} />
              }
              label={
                <LabelComponent
                  title={'Still Interested'}
                  subTitle={'The prospect is still interested.'}
                />
              }
              labelPlacement="start"
            />
          </Box>

          <Box
            className={`${classes.visitedStyle} ${
              prospectInterest === 'lost' ? classes.optionTwoSelected : null
            }`}
            data-testid="complete-tour-box-right"
          >
            <Box className={classes.svg + ' optionTwo'}>
              <Block />
            </Box>
            <FormControlLabel
              value="lost"
              className={classes.FormControlLabelClass}
              control={<Radio checked={prospectInterest === 'lost'} />}
              label={
                <LabelComponent
                  title={'Mark as Lost'}
                  subTitle={'This prospect is no longer interested.'}
                />
              }
              labelPlacement="start"
            />
          </Box>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default ProspectInterestBox;
