/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import { titleService } from '../../../../../Core/Services/TitleService';

(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('pageTitleService', [
    '$rootScope',
    'apiEvents',
    'conversationsService',
    'userService',
    function ($rootScope, apiEvents, conversationsService, userService) {
      var self = this;

      self.setPageTitle = function (pageTitle) {
        titleService.setTitle(pageTitle);
      };

      self.setMessageBadgeCount = function (count) {
        this.setMessageBadgeCount(count);
        self._emitBadgeUpdate();
      };

      self.lockTitle = function () {
        titleService.lockTitle();
      };

      self.unlockTitle = function () {
        titleService.unlockTitle();
      };

      self.setTeamBadgeCounts = function (counts) {
        var currentUser = userService.getScopedUser();

        if (currentUser.type === 'renter' || counts[currentUser.id] == null) {
          return;
        }

        titleService.setTeamBadgeCounts(
          counts,
          counts[currentUser.id].unread_messages.total
        );

        self._emitBadgeUpdate();
      };

      self.setNotificationBadgeCount = function (count) {
        titleService.setNotificationBadgeCount(count);
        self._emitBadgeUpdate();
      };

      self._emitBadgeUpdate = function () {
        $rootScope.$emit(
          apiEvents.badgeCountsUpdated,
          titleService.getNotificationBadgeCount(),
          titleService.getMessageBadgeCount(),
          titleService.getCounts()
        );
      };

      $rootScope.$on(
        conversationsService.events.updateTeamUnreadCounts,
        function (event, counts) {
          self.setTeamBadgeCounts(counts);
        }
      );

      $rootScope.$on(apiEvents.gotSidebarTodoData, (_, { todo_count }) => {
        titleService.setTodoCount(todo_count);
      });

      $rootScope.$on(
        conversationsService.events.updateRenterUnreadCount,
        function (event, count) {
          self.setMessageBadgeCount(count);
        }
      );

      return self;
    }
  ]);
})(window.angular);
