/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.filter('timeAgoSeconds', function () {
    return function (input, includeSeconds) {
      if (input === 0) {
        return 'Immediate';
      }

      var hoursSeconds = 60 * 60;
      var daysSeconds = 24 * hoursSeconds;

      var days = Math.floor(input / daysSeconds);
      var hours = Math.floor((input - days * daysSeconds) / hoursSeconds);
      var minutes = Math.floor(
        (input - days * daysSeconds - hours * hoursSeconds) / 60
      );
      var seconds = input % 60;

      var timeString = includeSeconds
        ? Math.round(seconds).toString() + 's'
        : '';

      if (minutes || !includeSeconds) {
        timeString = minutes.toString() + 'm ' + timeString;
      }

      if (hours) {
        timeString = hours.toString() + 'h ' + timeString;
      }

      if (days) {
        timeString = days.toString() + 'd ' + timeString;
      }

      return timeString.trim();
    };
  });
})(window.angular);
