import React, { FC, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import {
  Button,
  NamedColors,
  Text,
  ThemeProvider,
  makeStyles
} from '@knockrentals/knock-shared-web';
import { colors, usersColorPalette } from '../../commonStyles/commonStyles';
import { ArrowDropDown, Close } from '@material-ui/icons';

const useStyles = makeStyles({
  colorOption: {
    height: '40px',
    width: '40px',
    borderRadius: '8px'
  },
  colorOptionContainer: {
    '&.selected': {
      border: `2px solid ${NamedColors.slate[800]} !important`,
      padding: '2px'
    },
    '&:hover': {
      border: `2px solid ${NamedColors.slate[300]}`,
      padding: '2px'
    },

    padding: '4px',
    borderRadius: '20%',
    cursor: 'pointer'
  },
  paletteRow: {
    marginBottom: '16px',
    display: 'flex',
    '& .color-option:not(:last-child)': {
      marginRight: '12px'
    }
  },
  openButton: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '16px',
    cursor: 'pointer'
  },
  palette: {
    margin: '24px 0 16px 0'
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: '18px',
      fontWeight: 600
    }
  },
  cancelButton: {
    color: NamedColors.denim[600]
  },
  saveButton: {
    backgroundColor: NamedColors.denim[600],
    marginLeft: '16px !important'
  },
  actionsContainer: {
    padding: '8px 16px 16px 16px'
  }
});

interface ColorOptionProps {
  color: string;
  onClick?: () => void;
  selected?: boolean;
}

const ColorOption: FC<ColorOptionProps> = ({ color, onClick, selected }) => {
  const classes = useStyles();
  return (
    <Box
      className={
        onClick
          ? `${classes.colorOptionContainer} color-option ${
              selected ? 'selected' : ''
            }`
          : ''
      }
    >
      <Box
        data-testid="color-option-box"
        className={`${classes.colorOption} `}
        onClick={onClick}
        style={{ backgroundColor: color }}
      />
    </Box>
  );
};

interface ColorPickerModalProps {
  color: string;
  setColor: (color: string) => void;
}

const ColorPickerModal: React.FC<ColorPickerModalProps> = ({
  color,
  setColor
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedColor, setSelectedColor] = useState<string>(color);

  const handleSave = () => {
    setOpen(false);
    setColor(selectedColor);
  };

  const handleCancel = () => {
    setSelectedColor(color);
    setOpen(false);
  };
  return (
    <ThemeProvider>
      <Box data-testid="color-picker-container">
        <Box
          role="button"
          className={classes.openButton}
          onClick={() => {
            setOpen(true);
          }}
        >
          <ColorOption color={color} />
          <ArrowDropDown fontSize="large" htmlColor={NamedColors.slate[800]} />
        </Box>
        <Dialog open={open}>
          <DialogTitle>
            <Box className={classes.title}>
              <Text>Color</Text>
              <IconButton onClick={handleCancel}>
                <Close htmlColor={colors.secondaryText} />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Text>Select your color representation.</Text>
            <Box className={classes.palette}>
              {usersColorPalette.map((colors: string[], i: number) => (
                <Box key={`palette-key-${i}`} className={classes.paletteRow}>
                  {colors.map((color: string) => (
                    <ColorOption
                      key={color}
                      selected={selectedColor === color}
                      color={color}
                      onClick={() => {
                        setSelectedColor(color);
                      }}
                    />
                  ))}
                </Box>
              ))}
            </Box>
          </DialogContent>
          <DialogActions className={classes.actionsContainer}>
            <Button
              data-testid="close"
              onClick={handleCancel}
              variant="outlined"
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Button
              data-testid="save"
              onClick={handleSave}
              className={classes.saveButton}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default ColorPickerModal;
