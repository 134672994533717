/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('managerProfileModalFactory', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openManagerProfileModal = function (managerId) {
        return $mdDialog.show({
          controller: 'ManagerProfileController',
          templateUrl: '/angular/views/profile/manager-profile-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            managerId: managerId
          }
        });
      };

      return self;
    }
  ]);

  app.controller('ManagerProfileController', [
    '$q',
    '$scope',
    '$mdDialog',
    'managerId',
    'managerApi',
    'ProfileService',
    'photoApi',
    'managerPreferencesModalFactory',
    'phoneVerificationModalFactory',
    'chatTrackingService',
    function (
      $q,
      $scope,
      $mdDialog,
      managerId,
      managerApi,
      profileService,
      photoApi,
      managerPreferencesModalFactory,
      phoneVerificationModalFactory,
      chatTrackingService
    ) {
      var self = this;

      $scope.$watch('localProfilePhoto', function () {
        if ($scope.localProfilePhoto && $scope.localProfilePhoto.length > 0) {
          $scope.profile.photo = $scope.localProfilePhoto[0].url;

          if ($scope.localProfilePhoto[0].isLocal) {
            $scope.isUploadingPhoto = true;
            $scope.photoError = null;

            photoApi
              .uploadPhotos('manager-profile', $scope.localProfilePhoto)
              .success(function (response) {
                $scope.profile.photo = response.photos[0].url;
                profileService.updateProfile($scope.profile);
              })
              .error(function () {
                $scope.photoError =
                  'There was an error uploading your profile photo. Please try again later.';
              })
              .finally(function () {
                $scope.isUploadingPhoto = false;
                $scope.localProfilePhoto = [];
              });
          }
        }
      });

      $scope.openImagePrompt = function () {
        throw 'not implemented. should be overriden by image upload directive';
      };

      $scope.tryPhoneVerification = function () {
        if ($scope.isVerified) {
          return;
        }

        $scope.save(function () {
          if ($scope.isPendingVerification) {
            self._openVerificationConfirmationModal();
          } else {
            self._openPhoneVerificationModal();
          }

          $mdDialog.cancel();
        });
      };

      self._openPhoneVerificationModal = function () {
        phoneVerificationModalFactory
          .openVerificationModal($scope.profile.phone_number)
          .then(
            function (response) {
              if (response.success) {
                $scope.isPendingVerification = true;
                self._openVerificationConfirmationModal();
              } else {
                $scope.saveErrorMessage =
                  'There was an error sending your verification code. Please try again later.';
              }
            },
            function () {
              $scope.saveErrorMessage =
                'There was an error sending your verification code. Please try again later.';
            }
          );
      };

      self._openVerificationConfirmationModal = function () {
        phoneVerificationModalFactory
          .openVerificationConfirmationModal($scope.profile.phone_number)
          .then(function (response) {
            $scope.saveErrorMessage = null;

            $scope.isVerified = response.confirmed;
            $scope.isPendingVerification = !$scope.isVerified;

            if (!$scope.isVerified) {
              $scope.saveErrorMessage =
                'There was an error validating your phone number. Either the code has expired or was incorrect.';
            } else {
              $scope.profile.verification_method = 'text';
              profileService.updateProfile($scope.profile);
            }
          });
      };

      $scope.editPreferences = function () {
        $mdDialog.cancel();
        managerPreferencesModalFactory.openManagerPreferencesModal();
      };

      $scope.close = function () {
        $mdDialog.cancel();
      };

      $scope.save = function (callback) {
        $scope.isSaving = true;

        var savePromises = [
          self._updateProfile(),
          self._updateMessagingSettings()
        ];

        $q.all(savePromises)
          .then(
            function () {
              $mdDialog.hide();
              if (callback) {
                callback();
              }
            },
            function () {
              $scope.saveError = true;
            }
          )
          .finally(function () {
            $scope.isSaving = false;
          });
      };

      self._initialize = function () {
        $scope.isLoadingProfile = true;

        var initPromises = [self._getManagerProfile()];

        $q.all(initPromises).then(function () {
          $scope.isLoading = false;
        });
      };

      self._getManagerProfile = function () {
        return profileService
          .getProfile()
          .success(function (response) {
            $scope.profile = response.profile;

            if ($scope.profile.verification_method) {
              if ($scope.profile.verification_method === 'none') {
                $scope.isPendingVerification = true;
              } else {
                $scope.isVerified = true;
              }
            }
          })
          .finally(function () {
            $scope.isLoadingProfile = false;
          });
      };

      self._updateProfile = function () {
        chatTrackingService.trackUser($scope.profile);
        return profileService.updateProfile($scope.profile);
      };

      self._initialize();
    }
  ]);
})(window.angular);
