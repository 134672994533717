/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('prospectExcludedButton', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospects/prospect-excluded-button.html',
      scope: {
        prospect: '=',
        conversation: '='
      },
      controller: [
        '$scope',
        '$mdDialog',
        'conversationsService',
        'prospectsApi',
        'prospectExcludedModalFactory',
        'unsubscribeHelper',
        function (
          $scope,
          $mdDialog,
          conversationsService,
          prospectsApi,
          prospectExcludedModalFactory,
          unsubscribeHelper
        ) {
          $scope.tryToggleProspectExcluded = function () {
            if ($scope.prospect.is_excluded) {
              $scope.prospect.is_excluded = false;

              prospectsApi.setProspectNotExcluded($scope.prospect.id);
            } else {
              // get user's email unsubscribe status
              let userUnsubscribed = false;
              if ($scope.conversation) {
                const unsubscribeStatus =
                  unsubscribeHelper.getUnsubscribeStatusFromStream(
                    $scope.conversation
                  );
                if (unsubscribeStatus) {
                  userUnsubscribed = unsubscribeStatus.opted_out;
                }
              }

              // Cheat-proof handling
              // But, if user is unsubscribed, do not restrict changing to Lost
              if (
                $scope.prospect.enable_cheatproof_engagement_score &&
                !userUnsubscribed
              ) {
                conversationsService
                  .getProspectEngagementSettings($scope.prospect.id)
                  .then(function (response) {
                    $scope.prospect.enable_cheatproof_engagement_score =
                      response.data.engagement_settings.enable_cheatproof_engagement_score;
                    $scope.prospect.disable_is_excluded =
                      response.data.engagement_settings.disable_is_excluded;

                    if (
                      $scope.prospect.enable_cheatproof_engagement_score &&
                      $scope.prospect.disable_is_excluded
                    ) {
                      var disableNotProspectNote = $mdDialog.alert({
                        title:
                          "Marking 'Not a prospect' Requires Communication",
                        content:
                          'You are not able to mark as “not prospect” unless you have previously communicated with them',
                        ok: 'Got it'
                      });

                      return $mdDialog.show(disableNotProspectNote);
                    } else {
                      prospectExcludedModalFactory.openProspectExcludedModal(
                        $scope.prospect
                      );
                    }
                  });

                return;
              }

              prospectExcludedModalFactory.openProspectExcludedModal(
                $scope.prospect
              );
            }
          };
        }
      ]
    };
  });

  const prospectExcludedModalFactory = function ($mdDialog) {
    var self = this;

    self.openProspectExcludedModal = function (prospect) {
      return $mdDialog.show({
        controller: 'ProspectExcludedModalController',
        templateUrl: '/angular/views/prospects/prospect-excluded-modal.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          prospect: prospect
        }
      });
    };

    return self;
  };

  prospectExcludedModalFactory.$inject = ['$mdDialog'];
  app.factory('prospectExcludedModalFactory', prospectExcludedModalFactory);

  const ProspectExcludedModalController = function (
    $scope,
    $mdDialog,
    $mdToast,
    prospectsApi,
    prospectHistoryService,
    prospect
  ) {
    $scope.data = {
      isSaving: false,
      prospect: prospect,
      selectedReason: null,
      reasonOptions: [
        { label: 'Vendor', value: 'Vendor' },
        { label: 'Existing Resident', value: 'Resident' },
        { label: 'Not a prospective renter', value: 'Not renting' },
        { label: 'Realtor/Locator', value: 'Realtor/Locator' },
        { label: 'Other', value: 'Other' },
        { label: 'Spam', value: 'Spam' },
        { label: 'Duplicate/Error', value: 'error' }
      ]
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.save = function () {
      if (!$scope.data.selectedReason) {
        return;
      }

      $scope.data.isSaving = true;

      if ($scope.data.selectedReason === 'error') {
        prospectHistoryService.deleteProspect($scope.data.prospect).then(
          function () {
            $scope.data.prospect.is_deleted = true;
            $mdDialog.hide($scope.data.selectedReason);
          },
          function () {
            $mdToast.showSimple('Error archiving prospect!');
            $scope.data.isSaving = false;
          }
        );

        return;
      }

      prospectsApi
        .setProspectExcluded($scope.data.prospect.id, [
          $scope.data.selectedReason
        ])
        .then(
          function () {
            $scope.data.prospect.is_excluded = true;
            $mdDialog.hide($scope.data.selectedReason);
          },
          function () {
            $mdToast.showSimple("Error setting 'Not a prospect' reason!");
            $scope.data.isSaving = false;
          }
        );
    };
  };

  ProspectExcludedModalController.$inject = [
    '$scope',
    '$mdDialog',
    '$mdToast',
    'prospectsApi',
    'prospectHistoryService',
    'prospect'
  ];
  app.controller(
    'ProspectExcludedModalController',
    ProspectExcludedModalController
  );
})(window.angular);
