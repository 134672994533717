/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.directive('timeSlider', [
    '$rootScope',
    '$filter',
    'timeService',
    function ($rootScope, $filter, timeService) {
      var timeToInt = function (time) {
        var moment = timeService.get(time);
        return moment.hour() * 60 + moment.minute();
      };

      var getTimeFromMinutesInt = function (date, timeInteger) {
        var minutes = timeInteger % 60;
        var hours = (timeInteger - minutes) / 60;

        return timeService.get(date).startOf('day').hour(hours).minute(minutes);
      };

      var format = function (date) {
        return $filter('moment')(date, 'h:mm a');
      };

      return {
        restrict: 'E',
        scope: {
          interval: '=',
          titleFormat: '=',
          alwaysOn: '='
        },
        templateUrl: '/angular/views/directives/timeSlider.html',
        link: function (scope) {
          var startTime = timeService.get(scope.interval.startTime);
          var endTime = timeService.get(scope.interval.endTime);

          var title = startTime.format(scope.titleFormat);
          var interval = [timeToInt(startTime), timeToInt(endTime)];
          var isActive = scope.interval.isActive || !!scope.alwaysOn;

          scope.sliderDay = {
            title: title,
            interval: interval,
            start: startTime,
            end: endTime,
            formattedStart: format(startTime),
            formattedEnd: format(endTime),
            isActive: isActive
          };

          scope.$watch('sliderDay.interval', function (newValue) {
            scope.sliderDay.start = getTimeFromMinutesInt(
              startTime,
              newValue[0]
            );
            scope.sliderDay.end = getTimeFromMinutesInt(endTime, newValue[1]);

            scope.sliderDay.formattedStart = format(scope.sliderDay.start);
            scope.sliderDay.formattedEnd = format(scope.sliderDay.end);

            scope.interval.startTime = scope.sliderDay.start;
            scope.interval.endTime = scope.sliderDay.end;
          });

          scope.$watch('sliderDay.isActive', function (newValue) {
            scope.interval.isActive = newValue;
          });

          var timezoneChangedHandler = $rootScope.$on(
            'user-timezone-changed',
            function () {
              scope.sliderDay.interval = [
                timeToInt(scope.interval.startTime),
                timeToInt(scope.interval.endTime)
              ];
            }
          );

          scope.$on('$destroy', timezoneChangedHandler);
        }
      };
    }
  ]);
})();
