export const LEASE_START_DATE = 'leaseStartDate';
export const LEASE_END_DATE = 'leaseEndDate';

export type QuoteErrorMessages = {
  [LEASE_START_DATE]?: string;
  [LEASE_END_DATE]?: string;
};

export const validateLeaseDates = (
  leaseStartDate: string | undefined,
  leaseEndDate: string | undefined
) => {
  const errorMessages: QuoteErrorMessages = {};

  if (!leaseStartDate) {
    errorMessages[LEASE_START_DATE] = 'Please select a Lease Start Date.';
  }

  if (!leaseEndDate) {
    errorMessages[LEASE_END_DATE] = 'Please select a Lease End Date.';
  }

  const hasErrors = Object.keys(errorMessages).length;

  return hasErrors ? { errorMessages } : {};
};
