import React, { FC, useState, useEffect } from 'react';
import { makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import {
  AppBar,
  ThemeProvider,
  Toolbar,
  denim
} from '@knockrentals/knock-shared-web';
import CustomRenderOption from './CustomRenderOption';
import TopNavBar from './Topbar/TopNavBar';
import './_GlobalNavHeader.scss';
import { UserProfileType } from './Topbar/types';
import { Provider } from 'react-redux';
import { store } from '../../../../app/store';
import useLeasingTeamBadges from 'app/services/me/leasingTeam/Badges/hooks';

export interface GlobalNavLeasingTeam {
  id: number;
  name: string;
  publicId: string;
}

interface GlobalNavHeaderProps {
  currentLeasingTeam: GlobalNavLeasingTeam | null;
  headerTitle: string;
  headerSubTitle: string;
  leasingTeams: GlobalNavLeasingTeam[];
  leasingTeamsBadgeCounts: {
    [key: string]: number;
  };
  userProfile: UserProfileType;
  userLogout: () => void;
  openKnockAnalytics: () => void;
  openClawApp: () => void;
  isClawAvailable: boolean;
  openProfileAccount: () => void;
  hideAnalytics: boolean;
  hideAccountViews: boolean;
  betaSwitch: (isBetaSwitchClicked?: boolean) => void;
  isRedesignBeta: boolean;
  browserAudioNotificationsToggle: () => void;
  audioNotificationsEnabled: boolean;
  openProfileSettings: () => void;
  browserNotificationsToggle: () => void;
  desktopNotificationsEnabled: boolean;
  desktopNotificationsUnavailable: boolean;
  hideChangeUser: boolean;
  memberLogin: (selectedUser: number | null) => void;
}

const useStyles = makeStyles({
  toolBar: {
    width: '100%'
  },

  subTitle: {
    paddingLeft: '10px',
    color: 'rgba(0, 0, 0, 0.64)'
  },

  option: {
    backgroundColor: 'transparent',
    display: 'inherit',
    padding: 0,

    '&[data-focus=true]': {
      backgroundColor: 'inherit'
    },

    '&:hover': {
      backgroundColor: denim[50]
    }
  }
});

const GlobalNavHeader: FC<GlobalNavHeaderProps> = ({
  currentLeasingTeam,
  headerTitle,
  headerSubTitle,
  leasingTeams,
  userLogout,
  openKnockAnalytics,
  openClawApp,
  isClawAvailable,
  openProfileAccount,
  userProfile,
  hideAnalytics,
  hideAccountViews,
  betaSwitch,
  isRedesignBeta
}) => {
  const isOptionDisabled = (leasingTeamOption: GlobalNavLeasingTeam) =>
    currentLeasingTeam ? leasingTeamOption.id === currentLeasingTeam.id : false;

  const { option, toolBar, subTitle } = useStyles();

  const [leasingTeamsBadgeCounts, setleasingTeamsBadgeCounts] = useState();

  const { Badges } = useLeasingTeamBadges();

  useEffect(() => {
    setleasingTeamsBadgeCounts(Badges);
  }, [Badges]);

  return (
    <div
      data-testid="global-nav-header"
      className="global-nav-header-wrapper offset-for-sidenav"
    >
      <AppBar
        position="absolute"
        className="global-nav-header-app-bar"
        elevation={0}
      >
        <Toolbar className={toolBar}>
          <Typography className="global-nav-header-title" variant="h5" noWrap>
            {headerTitle}
          </Typography>
          <Typography variant="body2" className={subTitle}>
            {headerSubTitle}
          </Typography>

          {leasingTeams && leasingTeams.length > 1 && (
            <div className="global-nav-header-leasing-team-selection-wrapper">
              <Autocomplete
                autoComplete={true}
                selectOnFocus={false}
                classes={{ option }}
                getOptionLabel={(option) => option.name}
                getOptionDisabled={(option) => isOptionDisabled(option)}
                id="global-nav-header-leasing-team-selection"
                noOptionsText={'No leasing teams'}
                options={leasingTeams}
                value={currentLeasingTeam}
                renderOption={(option) => {
                  return (
                    <CustomRenderOption
                      option={option}
                      badgeCount={
                        (leasingTeamsBadgeCounts &&
                          leasingTeamsBadgeCounts[option.publicId]) ||
                        0
                      }
                    />
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    className="global-nav-leasing-team-option"
                    variant={'outlined'}
                    {...params}
                  />
                )}
              />
            </div>
          )}

          {userProfile && (
            <TopNavBar
              key={userProfile.id}
              logout={userLogout}
              userProfile={userProfile}
              openKnockAnalytics={openKnockAnalytics}
              openProfileAccount={openProfileAccount}
              hideAnalytics={hideAnalytics}
              openClawApp={openClawApp}
              isClawAvailable={isClawAvailable}
              hideAccountViews={hideAccountViews}
              betaSwitch={betaSwitch}
              isRedesignBeta={isRedesignBeta}
            />
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default (props: GlobalNavHeaderProps) => (
  <ThemeProvider>
    <Provider store={store}>
      <GlobalNavHeader {...props} />
    </Provider>
  </ThemeProvider>
);
