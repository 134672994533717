/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function () {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('editListingCompletionHandler', function () {
    return {
      basics: {
        isComplete: false
      },
      amenities: {
        isComplete: false
      },
      photos: {
        isComplete: false
      },
      leasing: {
        isComplete: false
      },
      listing: {
        isComplete: false
      }
    };
  });
})();
