/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-UserInteractions');

  app.factory('assignPropertyModalService', [
    '$mdDialog',
    function ($mdDialog) {
      var self = this;

      self.openModal = function (prospect) {
        $mdDialog.show({
          templateUrl:
            '/angular/views/userInteractions/assign-property-modal.html',
          controller: 'assignPropertyModalController',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          locals: {
            prospect: prospect
          }
        });
      };

      return self;
    }
  ]);

  app.controller('assignPropertyModalController', [
    '$scope',
    '$mdDialog',
    '$mdToast',
    'prospect',
    'prospectHistoryService',
    function ($scope, $mdDialog, $mdToast, prospect, prospectHistoryService) {
      var self = this;

      $scope.data = {
        isLoading: false,
        isSavingProspect: false,
        teamMembers: null,
        prospect: null
      };

      $scope.saveProspect = function () {
        $scope.data.isSavingProspect = true;

        prospectHistoryService
          .updateProspect($scope.data.prospect)
          .success(self._saveProspectSuccess)
          .error(self._saveProspectError);
      };

      $scope.closeModal = function () {
        $mdDialog.hide();
      };

      self._saveProspectSuccess = function () {
        $scope.data.isSavingProspect = false;
        $scope.closeModal();
      };

      self._saveProspectError = function () {
        $scope.data.isSavingProspect = false;
        $mdToast.showSimple(
          'There was an error while assigning a property to prospect'
        );
      };

      self._initialize = function () {
        $scope.data.prospect = prospect;
      };

      self._initialize();
    }
  ]);
})(window.angular);
