/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knock-Todo');

  app.directive('residentFunctions', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/todo/resident-functions.html',
      controller: 'ResidentFunctionsController',
      scope: {
        resident: '=',
        teamMembers: '=?'
      }
    };
  });

  const ResidentFunctionsController = function (
    $scope,
    $rootScope,
    $mdDialog,
    $mdToast,
    $filter,
    $moment,
    appDataService,
    todoService,
    userInteractionsService,
    transferResidentModalFactory,
    _
  ) {
    var self = this;

    var residentTransferredHandlerDestroy = $rootScope.$on(
      userInteractionsService.events.residentTransferred,
      function (event, residentId, newManagerId) {
        if ($scope.resident.id === residentId) {
          $scope.resident.added_by = newManagerId;
          $scope.data.transferToManagerId = newManagerId;
        }
      }
    );

    $scope.$on('$destroy', function () {
      residentTransferredHandlerDestroy();
    });

    $scope.data = {
      transferToManagerId: null,
      teamMembers: $scope.teamMembers || {},
      residentStatuses: [],
      teamMembersArray: Object.values($scope.teamMembers) || []
    };

    $scope.$watch('resident.id', function () {
      $scope.data.transferToManagerId = $scope.resident.added_by;
    });

    $scope.$watch(
      'data.transferToManagerId',
      function (newTransferToManagerId, oldTransferToManagerId) {
        if (
          !newTransferToManagerId ||
          newTransferToManagerId === oldTransferToManagerId ||
          newTransferToManagerId === $scope.resident.added_by
        ) {
          return;
        }

        transferResidentModalFactory
          .openTransferResidentModal($scope.resident, newTransferToManagerId)
          .then(
            function () {
              $scope.resident.added_by = $scope.data.transferToManagerId;
            },
            function () {
              $scope.data.transferToManagerId = $scope.resident.added_by;
            }
          );
      }
    );

    self._initialize = function () {
      self._setTeamMembers();
    };

    $scope.getTeamMemberName = function (teamMember) {
      return _.isEmpty(teamMember)
        ? ''
        : teamMember.ManagerInfo.first_name +
            ' ' +
            teamMember.ManagerInfo.last_name;
    };

    self._setTeamMembers = function () {
      $scope.data.teamMembersArray = [];
      $scope.data.teamMembers = _.reduce(
        appDataService.getTeamMembers(),
        function (result, member) {
          result[member.Manager.id] = member;
          $scope.data.teamMembersArray.push(member);
          return result;
        },
        {}
      );
    };

    self._initialize();
  };

  ResidentFunctionsController.$inject = [
    '$scope',
    '$rootScope',
    '$mdDialog',
    '$mdToast',
    '$filter',
    '$moment',
    'appDataService',
    'todoService',
    'userInteractionsService',
    'transferResidentModalFactory',
    '_'
  ];

  app.controller('ResidentFunctionsController', ResidentFunctionsController);
})(window.angular);
