import React from 'react';

export const MenuSquareIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.25532 0H1.2766C0.571551 0 0 0.571551 0 1.2766V4.25532C0 4.96036 0.571551 5.53192 1.2766 5.53192H4.25532C4.96036 5.53192 5.53192 4.96036 5.53192 4.25532V1.2766C5.53192 0.571551 4.96036 0 4.25532 0Z"
      fill="#5C5C5C"
    />
    <path
      d="M4.25532 7.23404H1.2766C0.571551 7.23404 0 7.80559 0 8.51064V11.4894C0 12.1944 0.571551 12.766 1.2766 12.766H4.25532C4.96036 12.766 5.53192 12.1944 5.53192 11.4894V8.51064C5.53192 7.80559 4.96036 7.23404 4.25532 7.23404Z"
      fill="#5C5C5C"
    />
    <path
      d="M4.25532 14.4681H1.2766C0.571551 14.4681 0 15.0396 0 15.7447V18.7234C0 19.4284 0.571551 20 1.2766 20H4.25532C4.96036 20 5.53192 19.4284 5.53192 18.7234V15.7447C5.53192 15.0396 4.96036 14.4681 4.25532 14.4681Z"
      fill="#5C5C5C"
    />
    <path
      d="M11.4894 0H8.51064C7.80559 0 7.23404 0.571551 7.23404 1.2766V4.25532C7.23404 4.96036 7.80559 5.53192 8.51064 5.53192H11.4894C12.1944 5.53192 12.766 4.96036 12.766 4.25532V1.2766C12.766 0.571551 12.1944 0 11.4894 0Z"
      fill="#5C5C5C"
    />
    <path
      d="M11.4894 7.23404H8.51064C7.80559 7.23404 7.23404 7.80559 7.23404 8.51064V11.4894C7.23404 12.1944 7.80559 12.766 8.51064 12.766H11.4894C12.1944 12.766 12.766 12.1944 12.766 11.4894V8.51064C12.766 7.80559 12.1944 7.23404 11.4894 7.23404Z"
      fill="#5C5C5C"
    />
    <path
      d="M11.4894 14.4681H8.51064C7.80559 14.4681 7.23404 15.0396 7.23404 15.7447V18.7234C7.23404 19.4284 7.80559 20 8.51064 20H11.4894C12.1944 20 12.766 19.4284 12.766 18.7234V15.7447C12.766 15.0396 12.1944 14.4681 11.4894 14.4681Z"
      fill="#5C5C5C"
    />
    <path
      d="M18.7234 0H15.7447C15.0396 0 14.4681 0.571551 14.4681 1.2766V4.25532C14.4681 4.96036 15.0396 5.53192 15.7447 5.53192H18.7234C19.4284 5.53192 20 4.96036 20 4.25532V1.2766C20 0.571551 19.4284 0 18.7234 0Z"
      fill="#5C5C5C"
    />
    <path
      d="M18.7234 7.23404H15.7447C15.0396 7.23404 14.4681 7.80559 14.4681 8.51064V11.4894C14.4681 12.1944 15.0396 12.766 15.7447 12.766H18.7234C19.4284 12.766 20 12.1944 20 11.4894V8.51064C20 7.80559 19.4284 7.23404 18.7234 7.23404Z"
      fill="#5C5C5C"
    />
    <path
      d="M18.7234 14.4681H15.7447C15.0396 14.4681 14.4681 15.0396 14.4681 15.7447V18.7234C14.4681 19.4284 15.0396 20 15.7447 20H18.7234C19.4284 20 20 19.4284 20 18.7234V15.7447C20 15.0396 19.4284 14.4681 18.7234 14.4681Z"
      fill="#5C5C5C"
    />
  </svg>
);
