/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import filter from 'lodash/filter';
import map from 'lodash/map';
import every from 'lodash/every';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import remove from 'lodash/remove';
import findIndex from 'lodash/findIndex';

(function (angular) {
  'use strict';

  var app = angular.module('knock-PropertyReports');

  const ProspectReportPageController = function (
    $rootScope,
    $timeout,
    $scope,
    $q,
    $routeParams,
    $moment,
    $mdToast,
    $location,
    propertyReportsService,
    prospectAppointmentModalFactory,
    conversationsService,
    prospectHistoryService,
    pagePreferencesService,
    timeService,
    timedSyncService,
    streamCarouselService
  ) {
    const prospectResidentBetaFlag = localStorage.getItem(
      'ProspectResidentBeta'
    );

    if (
      $rootScope.featureFlags.PROSPECT_PAGE_REDESIGN ||
      prospectResidentBetaFlag === 'true'
    ) {
      if ($location.search) {
        $location.url(`/prospects${window.location.search}`);
      } else {
        $location.url('/prospects');
      }
      return;
    }

    var self = this;
    self._mappedTeamMembers = {};

    var prospectUpdatedHandler = $rootScope.$on(
      prospectHistoryService.events.prospectUpdated,
      function (event, prospect) {
        var prospectsToUpdate = remove($scope.data.prospects, {
          id: prospect.id
        });

        if (prospectsToUpdate.length > 0) {
          self._search();
        }
      }
    );

    var prospectsBatchUpdatedHandler = $rootScope.$on(
      prospectHistoryService.events.prospectsBatchLossReasonsUpdated,
      function () {
        self._search();
      }
    );

    var prospectDeletedHandler = $rootScope.$on(
      prospectHistoryService.events.prospectDeleted,
      function (event, prospectObj) {
        remove($scope.data.prospects, { id: prospectObj.id });
      }
    );

    var newMessageHandler = $rootScope.$on(
      conversationsService.events.newTeamMessage,
      function (event, message) {
        timedSyncService.requestSync(
          'prospectReport-messages',
          function () {
            var otherUser = conversationsService.getOtherUser(message);

            var shouldUpdate =
              message.sender_type === 'manager' &&
              otherUser.type === 'prospect' &&
              find($scope.data.prospects, { id: otherUser.id });

            if (shouldUpdate) {
              self._search();
            }
          },
          5000
        );
      }
    );

    $scope.$on(
      'getPaginationParams',
      function (e, rowsPerPage, offset, pageNumber) {
        $scope.paginationData.rowsPerPage = rowsPerPage;
        $scope.paginationData.pageNumber = pageNumber;

        self._search(true);
      }
    );

    $scope.$on('getRowCount', function (e, rowsPerPage) {
      $scope.paginationData.pageNumber = 1;
      $scope.paginationData.rowsPerPage = rowsPerPage;

      self._search(true);
    });

    $scope.$on('getSortParams', function (e, columnName, columnOrder) {
      $scope.paginationData.columnName = columnName;
      $scope.paginationData.columnOrder = columnOrder;

      self._search(true);
    });

    $scope.$on('$destroy', function () {
      prospectsBatchUpdatedHandler();
      prospectUpdatedHandler();
      prospectDeletedHandler();
      newMessageHandler();
    });

    $scope.filters = {
      teamMembers: [],
      properties: [],
      selections: {
        status: 'active',
        property: null,
        managerId: 'all',
        startDate: null,
        endDate: null,
        isWaitlist: null,
        prospectIds: null,
        customDateFilter: null
      },
      customDateFilters: [],
      batchId: null,
      mode: $routeParams.view || 'table'
    };

    $scope.data = {
      allProspectsSelected: false,
      isLoading: true,
      isLoadingFilters: true,
      prospects: [],
      prospectsCopy: [],
      totalRows: 0
    };

    $scope.paginationData = {
      rowsPerPage: 500,
      pageNumber: 1,
      columnName: '',
      columnOrder: ''
    };

    $scope.isPaginationEnabled =
      $rootScope.featureFlags.PROSPECT_RESIDENT_PAGINATION;

    $scope.addProspect = function () {
      prospectAppointmentModalFactory
        .openAddProspectAppointmentModal()
        .then(function () {
          self._search();
        });
    };

    $scope.statusFilterChanged = function (newStatus) {
      $scope.filters.selections.status = newStatus;
      $scope.filtersChanged();
    };

    $scope.$watch('filters.selections.customDateFilter', (newName, oldName) => {
      // this is to keep the prospect search from firing when the
      // custom filter is first initialized but before it has a value, thank you angular
      if (oldName && newName && newName !== oldName) {
        self._customFilterChanged();
      }
    });

    $scope.filtersChanged = function () {
      if ($scope.filters.selections.prospectIds) {
        $scope.filters.selections.prospectIds = null;
        $location.search({ f_prospectIds: null });
      }

      if ($scope.isPaginationEnabled) {
        $scope.paginationData.pageNumber = 1;
      }

      self._search(false, true);

      pagePreferencesService.urlEncodePagePreferences({
        status: $scope.filters.selections.status,
        propertyId: $scope.filters.selections.property.id,
        managerId: $scope.filters.selections.managerId,
        startDate: timeService
          .get($scope.filters.selections.startDate)
          .format('MM-DD-YYYY'),
        endDate: timeService
          .get($scope.filters.selections.endDate)
          .format('MM-DD-YYYY'),
        isWaitlist: $scope.filters.selections.isWaitlist,
        customDateFilter: $scope.filters.selections.customDateFilter
      });
    };

    $scope.allProspectsSelectedChanged = function () {
      forEach($scope.data.prospectsCopy, function (prospect) {
        prospect.isSelected = $scope.data.allProspectsSelected;
      });
    };

    $scope.prospectSelectedChanged = function () {
      $scope.data.allProspectsSelected = every(
        $scope.data.prospectsCopy,
        'isSelected'
      );
    };

    $scope.getProspectManager = function (prospect) {
      return find($scope.filters.teamMembers, {
        id: prospect.assigned_manager_id
      });
    };

    $scope.openProspectStreamModal = function (prospect) {
      conversationsService.openThreadById(prospect.stream_id, true);
    };

    $scope.openCarousel = function () {
      var checkedProspects = self._getCheckedProspects();

      if (checkedProspects.length > 0) {
        streamCarouselService.showCarousel(map(checkedProspects, 'stream_id'));
      } else {
        $mdToast.showSimple('No prospects selected');
      }
    };

    $scope.openSendEmailModal = function () {
      var checkedProspects = self._getCheckedProspects();

      if (checkedProspects.length > 0) {
        propertyReportsService
          .openSendProspectMessageModal(checkedProspects, 'email')
          .then(function () {
            self._clearCheckedProspects();
            self._search();
          });
      } else {
        $mdToast.showSimple('No prospects selected');
      }
    };

    $scope.openEditProspectsModal = function () {
      var checkedProspects = self._getCheckedProspects();

      if (checkedProspects.length > 0) {
        propertyReportsService
          .openEditProspectsModal(checkedProspects)
          .then(function () {
            self._clearCheckedProspects();
            self._search();
          });
      } else {
        $mdToast.showSimple('No prospects selected');
      }
    };

    $scope.getProspectReportCSV = function () {
      var filterSelections = $scope.filters.selections;

      // The datepickers don't honor any sort of timezone settings, so allow moment to set filters
      // to manager's timezone by wrapping with toDateString() to kick out browser tz data
      const start = timeService
        .get(new Date(filterSelections.startDate).toDateString())
        .startOf('day')
        .format();
      const end = timeService
        .get(new Date(filterSelections.endDate).toDateString())
        .endOf('day')
        .format();

      return propertyReportsService
        .getProspectReportCSV(
          start,
          end,
          filterSelections.property.id,
          filterSelections.managerId,
          filterSelections.status,
          filterSelections.property.includeUnassigned,
          filterSelections.isWaitlist,
          null,
          null,
          null,
          $scope.filters.batchId
        )
        .then(function (response) {
          window.location.assign(response.data.url);
        });
    };

    $scope.switchMode = function (mode) {
      $location.search('view', mode);

      $timeout(function () {
        $scope.filters.mode = mode;

        self._lastMode = $scope.filters.mode;
      }, 500);
    };

    self._getCheckedProspects = function () {
      return filter($scope.data.prospectsCopy, 'isSelected');
    };

    self._clearCheckedProspects = function () {
      forEach($scope.data.prospectsCopy, function (prospect) {
        prospect.isSelected = false;
      });

      $scope.data.allProspectsSelected = false;
    };

    self._customFilterChanged = function () {
      const selectedIndex = findIndex($scope.filters.customDateFilters, {
        name: $scope.filters.selections.customDateFilter
      });

      $scope.filters.selections.startDate = timeService
        .get($scope.filters.customDateFilters[selectedIndex]['start_date'])
        .format();
      $scope.filters.selections.endDate = timeService
        .get($scope.filters.customDateFilters[selectedIndex]['end_date'])
        .format();
      $scope.filtersChanged();
    };

    self._initialize = function () {
      $scope.data.isLoading = true;
      $scope.data.isLoadingFilters = true;

      var prospectIdQueryParam = $routeParams.f_prospectIds;

      if (prospectIdQueryParam) {
        $scope.filters.selections.prospectIds = map(
          prospectIdQueryParam.split(','),
          function (prospectId) {
            return parseInt(prospectId);
          }
        );

        if ($scope.filters.selections.prospectIds.length > 200) {
          $location.search('f_prospectIds', null);
          $timeout(self._loadInitData, 100, true);
          self._loadInitData();
          return;
        }
      }

      self._lastMode = $scope.filters.mode;
      self._loadInitData();
    };

    self._loadInitData = function () {
      var propertyQueryParam = $routeParams.f_propertyId;
      var properties = propertyReportsService.getTeamPropertyFilter();

      $scope.filters.batchId = $routeParams.batchId;

      var filterPromises = [propertyReportsService.getTeamMemberFilter()];

      $q.all(filterPromises).then(function (response) {
        $scope.filters.teamMembers = response[0];
        $scope.filters.properties = properties;

        self._mappedTeamMembers = keyBy($scope.filters.teamMembers, 'id');

        $scope.filters.selections.property = $scope.filters.properties[0];

        var storedFilters = pagePreferencesService.urlDecodePagePreferences();

        $scope.filters.selections.status =
          storedFilters.status || $scope.filters.selections.status;
        $scope.filters.selections.managerId =
          storedFilters.managerId || $scope.filters.selections.managerId;

        $scope.filters.selections.isWaitlist =
          storedFilters.isWaitlist || $scope.filters.selections.isWaitlist;

        $scope.filters.customDateFilters =
          $rootScope.appPreferences.company.custom_prospect_report_filter;

        if ($scope.filters.customDateFilters.length < 1) {
          $scope.filters.selections.startDate = timeService
            .get()
            .startOf('day')
            .subtract(14, 'days')
            .format();
          $scope.filters.selections.endDate = timeService
            .get()
            .endOf('day')
            .format();
        } else {
          const defaultIndex = findIndex($scope.filters.customDateFilters, {
            is_default: true
          });
          $scope.filters.selections.startDate = timeService
            .get($scope.filters.customDateFilters[defaultIndex]['start_date'])
            .format();
          $scope.filters.selections.endDate = timeService
            .get($scope.filters.customDateFilters[defaultIndex]['end_date'])
            .format();
          $scope.filters.selections.customDateFilter =
            storedFilters.customDateFilter ||
            $scope.filters.customDateFilters[defaultIndex]['name'];
        }

        if (storedFilters.startDate) {
          $scope.filters.selections.startDate = timeService
            .get(storedFilters.startDate)
            .format();
        }

        if (storedFilters.endDate) {
          $scope.filters.selections.endDate = timeService
            .get(storedFilters.endDate)
            .format();
        }

        if (storedFilters.propertyId) {
          var sameProperty = function (property) {
            // storedFilters.propertyId may be a string like 'all' or 'unassigned'
            return (
              property.id ===
              (parseInt(storedFilters.propertyId) || storedFilters.propertyId)
            );
          };

          $scope.filters.selections.property = find(
            $scope.filters.properties,
            sameProperty
          );
        }

        if (propertyQueryParam) {
          var queriedProperty = find($scope.filters.properties, {
            id: parseInt(propertyQueryParam)
          });

          if (queriedProperty) {
            $scope.filters.selections.property = queriedProperty;
          }
        }

        $scope.data.isLoadingFilters = false;

        self._search();
      });
    };

    self._search = function (paginationEvent, filterChanged) {
      $scope.data.isLoading = true;
      var searchQuery;
      var useLegacyEndpoint = false;

      if ($scope.filters.batchId) {
        useLegacyEndpoint = true;
        searchQuery = self._searchProspectBatchId();
      } else if ($scope.filters.selections.prospectIds) {
        searchQuery = self._searchProspectIds();
      } else {
        searchQuery = self._searchWithFilters();
      }

      var isInternal = !!$routeParams.internal;

      searchQuery
        .then(
          function (response) {
            $scope.data.prospects = map(
              response.data.prospects_report,
              self._mapProspect
            );

            // Show spam if you came in through a batch ID
            if (!isInternal && !$scope.filters.batchId) {
              $scope.data.prospects = filter(
                $scope.data.prospects,
                self._filterSpamExcluded
              );
            }

            $scope.data.prospectsCopy = $scope.data.prospects.slice();

            if ($scope.isPaginationEnabled && !useLegacyEndpoint) {
              // We reset the page number to 1 if a filter changes in order for the range values above the table to be calculated correctly. This
              // also matches the logic when the rows per page value changes. In both cases, we want to present a correct representation of the
              // record set being viewed. We send the rows per page value in case the user had selected 'All' for the size, in which case we need
              // the current record count so 'All' stays selected.
              if (filterChanged) {
                $rootScope.$broadcast(
                  'updatePaginationParams',
                  1,
                  response.data.page_data.count
                );
              }

              $scope.data.totalRows = response.data.page_data.count;
            } else {
              $scope.data.totalRows = $scope.data.prospectsCopy.length;
            }
          },
          function (error) {
            // TODO: We should inform the user something went wrong and maybe log it somewhere
            console.log('Error fetching prospect data: %o', error);

            $scope.data.prospects = [];
            $scope.data.prospectsCopy = [];
            $scope.data.totalRows = 0;
          }
        )
        .finally(function () {
          if (paginationEvent) {
            $scope.$apply(() => {
              $scope.data.isLoading = false;
            });
          } else {
            $scope.data.isLoading = false;
          }
        });
    };

    self._searchProspectIds = function () {
      return propertyReportsService.getProspectReports(
        null,
        null,
        null,
        null,
        $scope.filters.selections.status,
        true,
        null,
        $scope.filters.selections.prospectIds,
        null,
        null,
        null,
        $scope.isPaginationEnabled,
        false,
        $scope.paginationData.pageNumber,
        $scope.paginationData.rowsPerPage,
        $scope.paginationData.columnName,
        $scope.paginationData.columnOrder
      );
    };

    self._searchProspectBatchId = function () {
      return propertyReportsService.getProspectReports(
        null,
        null,
        null,
        null,
        $scope.filters.selections.status,
        true,
        null,
        null,
        null,
        null,
        $scope.filters.batchId,
        $scope.isPaginationEnabled
      );
    };

    self._searchWithFilters = function () {
      var filterSelections = $scope.filters.selections;

      // The datepickers don't honor any sort of timezone settings, so allow moment to set filters
      // to manager's timezone by wrapping with toDateString() to kick out browser tz data
      var start = timeService
        .get(new Date(filterSelections.startDate).toDateString())
        .startOf('day')
        .format();
      var end = timeService
        .get(new Date(filterSelections.endDate).toDateString())
        .endOf('day')
        .format();

      return propertyReportsService.getProspectReports(
        start,
        end,
        filterSelections.property.id,
        filterSelections.managerId,
        filterSelections.status,
        filterSelections.property.includeUnassigned,
        null,
        null,
        null,
        filterSelections.isWaitlist,
        $scope.filters.batchId,
        $scope.isPaginationEnabled,
        false,
        $scope.paginationData.pageNumber,
        $scope.paginationData.rowsPerPage,
        $scope.paginationData.columnName,
        $scope.paginationData.columnOrder
      );
    };

    self._mapProspect = function (prospect) {
      return {
        id: prospect.id,
        stream_id: prospect.stream_id,
        assigned_manager_id: prospect.assigned_manager_id,
        todo_status: prospect.todo_status || {
          color: 'green',
          explanation: 'Good to go!'
        },
        profile: {
          first_name: prospect.profile.first_name,
          last_name: prospect.profile.last_name,
          email: prospect.profile.email,
          phone:
            prospect.profile.phone && prospect.profile.phone.id
              ? {
                  national_format: prospect.profile.phone.national_format,
                  can_receive_sms: prospect.profile.phone.can_receive_sms
                }
              : null,
          target_move_date: prospect.profile.target_move_date
        },
        has_call_recording: prospect.has_call_recording,
        has_note: prospect.has_note,
        has_appointments: prospect.has_appointments,
        is_deleted: prospect.is_deleted,
        excluded_reasons: prospect.excluded_reasons,
        status: prospect.status,
        next_reminder_time: prospect.next_reminder_time,
        follow_up_date: prospect.follow_up_date,
        created_time: prospect.created_time,
        last_contacted_time: prospect.last_contacted_time,
        source: prospect.source,
        first_contact_response_time: prospect.first_contact_response_time,
        follow_up_count: prospect.follow_up_count,
        property: {
          name: prospect.property ? prospect.property.name : ''
        },
        preferences: {
          bedrooms: prospect.preferences.bedrooms
            ? sortBy(prospect.preferences.bedrooms, function (beds) {
                if (beds === 'studio' || !beds) {
                  return -1;
                }

                return parseInt(beds[0]);
              })
            : []
        },
        property_id: prospect.property_id,
        export_log: prospect.export_log,
        manager: self._mappedTeamMembers[prospect.assigned_manager_id],
        is_waitlist: prospect.is_waitlist
      };
    };

    self._filterSpamExcluded = function (prospect) {
      var spam = false;
      forEach(prospect.excluded_reasons, function (excludedFor) {
        if (excludedFor.reason === 'Spam') {
          spam = true;
        }
      });
      if (!spam) {
        return prospect;
      }
    };

    self._initialize();
  };

  ProspectReportPageController.$inject = [
    '$rootScope',
    '$timeout',
    '$scope',
    '$q',
    '$routeParams',
    '$moment',
    '$mdToast',
    '$location',
    'propertyReportsService',
    'prospectAppointmentModalFactory',
    'conversationsService',
    'prospectHistoryService',
    'pagePreferencesService',
    'timeService',
    'timedSyncService',
    'streamCarouselService'
  ];

  app.controller('ProspectReportPageController', ProspectReportPageController);
})(window.angular);
