/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  app.factory('LoadingProgressService', [
    '$rootScope',
    function ($rootScope) {
      /**
       * Usage:
       *  var minimumLoadingPercentage = 5; // loading bar starts at 5%
       *  var progressService = new LoadingProgressService(minimumLoadingPercentage);
       *
       *  // make requests
       *
       *  progressService.go();
       *
       *  function onUpdate (newPercent) {
       *      $scope.loadingPercentage = newPercent;
       *  }
       *
       *  progressService.registerUpdateFunc(onUpdate);
       *
       * @param minimumLoadingPercentage
       * @constructor
       */

      var ProgressService = function (minimumLoadingPercentage) {
        this.requestCount = $rootScope.apiUnfulfilledRequestCount;
        this.loadingPercentage = minimumLoadingPercentage || 0;
      };

      ProgressService.prototype.go = function () {
        var self = this;

        self.maxRequestCount =
          $rootScope.apiUnfulfilledRequestCount - self.requestCount;
        self.dividend = self.maxRequestCount;

        $rootScope.$watch(
          'apiUnfulfilledRequestCount',
          function (newCount, oldCount) {
            if (newCount > oldCount) {
              return;
            }

            self.dividend -= Math.max(1, oldCount - newCount);
            self.loadingPercentage = Math.min(
              99,
              (1 - self.dividend / self.maxRequestCount) * 100
            );
            self.onUpdate();
          }
        );
      };

      ProgressService.prototype.registerUpdateFunc = function (onUpdateFunc) {
        this.updateFunc = onUpdateFunc;
      };

      ProgressService.prototype.onUpdate = function () {
        this.updateFunc(this.loadingPercentage);
      };

      return ProgressService;
    }
  ]);
})(window.angular);
